import {
  cn,
  useAutomationWorkflow,
  React,
  DiscountFormIcon,
  ActionNodeDetailsLog,
  ActionNodeDetailsLogCard,
  dayjs,
} from 'pages/automationWorkflow/export';
import { IAutomationLogResult } from 'pages/automationWorkflow/interface';

interface DiscountActionNodeProps {
  ResultIcon: JSX.Element | null;
  data: any;
}

interface IDiscount {
  id: number;
  price_rule_id: number;
  code: string;
  usage_count: number;
  created_at: string;
  updated_at: string;
}

export const DiscountActionNode: React.FC<DiscountActionNodeProps> = ({
  ResultIcon,
  data,
}) => {
  const { automationFlowDisplayViewType } = useAutomationWorkflow();
  const isLogView = automationFlowDisplayViewType === 'viewLog';

  const discountData =
    data?.eCommerce?.discount?.component?.definition?.stored_data
      ?.discount_payload;

  const renderDetailsLog = () => {
    const logResult: IAutomationLogResult = data?.component_computation_info;
    const status = logResult?.current_component_state;
    const errorMessage =
      status === 'failed' && logResult && logResult?.result?.pop();
    const discountWebHookResult: IDiscount =
      logResult?.result?.length &&
      JSON.parse(logResult.result[0])?.discount_code;
    const renderDiscountLogCardView = () => {
      return (
        <div className='font-medium space-y-2 text-gray-700 border-t'>
          <p className='text-sm text-gray-500 font-normal'>
            Discount Code -{' '}
            <span className='font-medium text-gray-700'>
              {discountWebHookResult?.code}
            </span>
          </p>

          {discountData?.discount_type && (
            <p className='text-sm text-gray-500 font-normal'>
              Customer will get discount -{' '}
              <span className='font-medium text-gray-700'>
                {discountData?.discount_type}
              </span>
            </p>
          )}

          {discountData?.amount && (
            <p className='text-sm text-gray-500 font-normal'>
              Amount -{' '}
              <span className='font-medium text-gray-700'>
                {discountData?.amount}
              </span>
            </p>
          )}

          {discountData?.discount_on && (
            <p className='text-sm text-gray-500 font-normal'>
              Discount type -{' '}
              <span className='font-medium text-gray-700 capitalize'>
                {discountData?.discount_on?.split('_').join(' ')}
              </span>
            </p>
          )}

          {discountData?.discount_apply_count && (
            <p className='text-sm text-gray-500 font-normal'>
              Apply discount on -{' '}
              <span className='font-medium text-gray-700'>
                {discountData?.discount_apply_count}
              </span>
            </p>
          )}

          <p className='text-sm text-gray-500 font-normal'>
            Usages count -{' '}
            <span className='font-medium text-gray-700'>
              {discountWebHookResult?.usage_count}
            </span>
          </p>

          <p className='text-sm text-gray-500 font-normal'>
            Discount code created at -{' '}
            <span className='font-medium text-gray-700'>
              {dayjs(discountWebHookResult?.created_at).format(
                'MMM D, YYYY [at] hh:mm A'
              )}
            </span>
          </p>

          <p className='text-sm text-gray-500 font-normal'>
            Discount code updated at -{' '}
            <span className='font-medium text-gray-700'>
              {dayjs(discountWebHookResult?.updated_at).format(
                'MMM D, YYYY [at] hh:mm A'
              )}
            </span>
          </p>
        </div>
      );
    };
    if (!logResult) {
      return (
        <div>
          {' '}
          <p> No Log resutl </p>{' '}
        </div>
      );
    }
    return (
      <ActionNodeDetailsLogCard
        date={logResult?.ends_at}
        errorMessage={errorMessage}
        cardTitle='Discount'
        ResultIcon={ResultIcon}
        render={renderDiscountLogCardView}
      />
    );
  };
  return (
    <div>
      <div className='flex items-center mb-3'>
        <div className='mr-3'>
          <DiscountFormIcon />
        </div>
        <div className='grid grid-flow-row gap-1'>
          <div className='text-sm font-semibold leading-4 text-gray-500'>
            Create Discount
          </div>
          <div
            className={cn([
              'text-xs leading-4 text-gray-400',
              isLogView ? 'hidden' : '',
            ])}
          >
            (Change)
          </div>
        </div>
      </div>
      {isLogView ? (
        <ActionNodeDetailsLog render={renderDetailsLog} actionData={data} />
      ) : null}
      <div className='mt-3 flex gap-2 ltr:pl-2 rtl:pr-2 border border-gray-200 rounded-md'>
        {isLogView ? ResultIcon : null}
        <div>
          {discountData?.discount_on && (
            <div className='py-3 border-b'>
              <p className='text-sm text-gray-500 font-normal'>
                Discount type -{' '}
                <span className='font-medium text-gray-700'>
                  {discountData?.discount_on}
                </span>
              </p>
            </div>
          )}
          {discountData?.amount && (
            <div className='py-3 border-b'>
              <p className='text-sm text-gray-500 font-normal'>
                Amount -{' '}
                <span className='font-medium text-gray-700'>
                  {discountData?.amount}
                </span>
              </p>
            </div>
          )}
          {discountData?.discount_type && (
            <div className='py-3 border-b'>
              <p className='text-sm text-gray-500 font-normal'>
                Customer will get discount -{' '}
                <span className='font-medium text-gray-700'>
                  {discountData?.discount_type}
                </span>
              </p>
            </div>
          )}
          {discountData?.discount_apply_count && (
            <div className='py-3 border-b'>
              <p className='text-sm text-gray-500 font-normal'>
                Apply discount on -{' '}
                <span className='font-medium text-gray-700'>
                  {discountData?.discount_apply_count}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
