import React, { useEffect, useState } from 'react';
import PermissionProjectHeader from '../component/PermissionProjectHeader';
import { Spinner } from 'evergreen-ui';
import userRoleIcon from '../../../assets/images/userRoleIcon.svg';
import admingTagIcon from '../../../assets/images/admin-tag-icon.svg';

import PermissionProjectTable from '../component/PermissionProjectTable';
import { connect } from 'react-redux';
import { HasPermission, ProUser } from '../../../../components';
import { checkPermission } from '../../../../utilities/utils';
import SettingsAdminGroups from '../component/SettingsAdminGroups';

interface props {
  fetchProjectAccess: (projectId: number) => void;
  fetchProject: () => void;
  previousProject: any;
  selectedProject: any;
  projects: any[];
  setSelectedProject: (project: any) => void;
  projectRolesData: any[];
  fetchPermissionRole: (projectId: number) => void;
  userRolesType: { id: number; name: string }[];
  fetchProjectAccessLoader: boolean;
  shareProject: any;
  shareProjectLoader: boolean;
  permissionList: any;
  deleteAdminRole: (id: number, roleId: number) => void;
  fetchAdminGroup: (id: number) => void;
  adminGroup: any[];
  deleteAdminGroup: (projectId: number, groupId: number) => void;
  createAdminGroup: (projectId: number, name: string) => void;
  createAdminGroupLoader: boolean;
  editAdminGroup: (projectId: number, groupId: number, name: string) => void;
  assignAdminGroup: (projectId: number, adminId: number, tags: any) => void;
}

const ProjectPermission: React.FC<props> = ({
  fetchProjectAccess,
  fetchProject,
  previousProject,
  selectedProject,
  projects,
  setSelectedProject,
  projectRolesData,
  fetchPermissionRole,
  userRolesType,
  fetchProjectAccessLoader,
  shareProject,
  shareProjectLoader,
  permissionList,
  deleteAdminRole,
  fetchAdminGroup,
  adminGroup,
  deleteAdminGroup,
  createAdminGroup,
  createAdminGroupLoader,
  editAdminGroup,
  assignAdminGroup,
}) => {
  const updateInitialInfo = async () => {
    await fetchProject();
    if (!previousProject && selectedProject === null) {
      setSelectedProject(projects[0]);
      await fetchProjectAccess(projects[0].id);
      await fetchPermissionRole(projects[0].id);
      await fetchAdminGroup(projects[0].id);
    }

    if (previousProject) {
      const filteredProject = projects.filter(
        (project) => project.id === previousProject.id
      )[0];
      await setSelectedProject(filteredProject);
      await fetchProjectAccess(filteredProject.id);
      await fetchPermissionRole(filteredProject.id);
      await fetchAdminGroup(filteredProject.id);
    }
    setIsMounted(true);
  };
  useEffect(() => {
    updateInitialInfo();
    // eslint-disable-next-line
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  const hasWriteAccess = checkPermission(permissionList, 'write:settings');

  return (
    <HasPermission shortCode={'read:settings'} isSettingsView>
      <ProUser handleCancel={() => window.history.back()}>
        <>
          <div className='permission-project-settings'>
            <PermissionProjectHeader
              title='Project Permission'
              subtitle={`Permission Settings for ${
                selectedProject?.name || 'Loading...'
              }`}
              icon={userRoleIcon}
              content={<div />}
            />
            {(fetchProjectAccessLoader || !isMounted) && (
              <div className='permission-loader'>
                <Spinner />
              </div>
            )}
            {!fetchProjectAccessLoader && isMounted && (
              <PermissionProjectTable
                role={projectRolesData}
                hasWriteAccess={hasWriteAccess}
                userRolesType={userRolesType}
                shareProject={(email: string, roleId: number) =>
                  shareProject(selectedProject.id, email, roleId)
                }
                adminGroup={adminGroup}
                shareProjectLoader={shareProjectLoader}
                deleteAdminRole={(adminId) =>
                  deleteAdminRole(selectedProject.id, adminId)
                }
                assignAdminGroup={(adminId: number, tags: any) =>
                  assignAdminGroup(selectedProject.id, adminId, tags)
                }
              />
            )}
          </div>
          <br />
          {/*manage admin tag*/}
          <div className='permission-project-settings'>
            <PermissionProjectHeader
              title='Admin Group'
              subtitle={`Manage Admin Group for ${
                selectedProject?.name || 'Loading...'
              }`}
              icon={admingTagIcon}
              content={<div />}
            />
            {!isMounted && (
              <div className='permission-loader'>
                <Spinner />
              </div>
            )}
            {isMounted && (
              <SettingsAdminGroups
                adminGroup={adminGroup}
                deleteAdminGroup={(groupId: number) =>
                  deleteAdminGroup(selectedProject.id, groupId)
                }
                createAdminGroup={(name: string) =>
                  createAdminGroup(selectedProject.id, name)
                }
                hasWriteAccess={hasWriteAccess}
                createAdminGroupLoader={createAdminGroupLoader}
                editAdminGroup={(groupId: number, name: string) =>
                  editAdminGroup(selectedProject.id, groupId, name)
                }
              />
            )}
          </div>
        </>
      </ProUser>
    </HasPermission>
  );
};

const mapState = (state: any) => ({
  projects: state.dashboard.projects,
  selectedProject: state.settings.selectedProject,
  previousProject: state.dashboard.selectedProject,
  projectRolesData: state.settings.currentProjectRoles,
  userRolesType: state.settings.userRolesType,
  fetchProjectAccessLoader: state.loading.effects.settings.fetchProjectAccess,
  shareProjectLoader: state.loading.effects.settings.shareProject,
  permissionList: state.dashboard.permission.list || [],
  adminGroup: state.settings.adminGroup,
  createAdminGroupLoader: state.loading.effects.settings.createAdminGroup,
});

const mapDispatch = (dispatch: any) => ({
  fetchProjectAccess: (projectId: number) =>
    dispatch.settings.fetchProjectAccess(projectId),
  fetchPermissionRole: (projectId: number) =>
    dispatch.settings.fetchPermissionRole(projectId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (payload: any) =>
    dispatch.settings.setSelectedProject(payload),
  shareProject: (id: number, email: string, roleId: number) =>
    dispatch.settings.shareProject({ id, email, roleId }),
  deleteAdminRole: (id: number, roleId: number) =>
    dispatch.settings.deleteAdminRole({ id, roleId }),
  fetchAdminGroup: (id: number) => dispatch.settings.fetchAdminGroup(id),
  deleteAdminGroup: (projectId: number, groupId: number) =>
    dispatch.settings.deleteAdminGroup({ projectId, groupId }),
  createAdminGroup: (projectId: number, name: string) =>
    dispatch.settings.createAdminGroup({ projectId, name }),
  editAdminGroup: (projectId: number, groupId: number, name: string) =>
    dispatch.settings.editAdminGroup({ projectId, groupId, name }),
  assignAdminGroup: (projectId: number, adminId: number, tags: any) =>
    dispatch.settings.assignAdminGroup({ projectId, adminId, tags }),
});

const ProjectPermissionContainer = connect(
  mapState,
  mapDispatch
)(ProjectPermission);

export default ProjectPermissionContainer;
