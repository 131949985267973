import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { subDays } from 'date-fns';
import { getReportingVariableNameFromEndpoint } from '../../../utilities/utils';
import _ from 'lodash';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import { exportCSV } from '../../../utilities/utils';

const initialState = {
  data: null,
  currentTab: {
    id: 0,
    name: 'Customer Metrics',
  },
  dateRange: [
    {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
      key: 'selection',
    },
  ],
  platformData: {
    id: null,
    type: 'all',
    title: 'All Channels',
  },
  platformsToFilter: [],
  userCount: null,
  interactiveUserCount: null,
  referralGrowthData: null,
  intentGrowthData: null,
  languageData: null,
  timezoneData: null,
  countryData: null,
  heatmapData: null,
  genderData: null,
  postbackVTextData: null,
  triggeredMsgReferralnKeyword: null,
  triggeredMsgIntentnPostback: null,
  ticketResolvedCount: null,
  uniqueTicketCount: null,
  avgTicketPerUser: null, // Not being used
  avgTicketPerOverallUser: null,
  ticketCreatedCount: null,
  avgProcessFRT: null,
  textualVsAutomation: [{ textual_message: 0, automated: 0 }],
  ticketTagCloud: [],
  ticketListTable: [],
  agentList: [],
  averageResolveTime: null,
  averageQueueTime: null,
  agentStats: [],
  conversationLogs: [],
  intentList: [],
  csatTotalCount: null,
  csatRespondedCount: null,
  csatTotalResponseCount: null,
  csatAvgRating: null,
  csatResponseList: [],
  csatResponseListTotal: 0,
  csatResponseDist: [],
  isBusinessHourEnabled: false,
  whatsappMetricsReport: null,
  isWhatsappBsbIntegration: false,
  whatsappTemplateMetrics: [],
  totalDataOfTemplateMetrics: 0,
  templateMetricsTableOffset: 0,
  selectedAnalytics: null,
  whatsappAccountInfo: null,
  selectedAnalyticsId: null,
  selectedPlatformId: null,
  selectedReportingViewMode: 'reporting',
  templateAnalyticsLogs: [],
  templateAnalyticsLogsTotalData: 0,
  selectedLogsData: null,
  selectedWhatsappTemplateName: null,
};

export const reporting = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      //key value
      return { ...state, [payload.key]: payload.value };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    //user metrics
    async fetchUserReportData(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */

      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(
                  new Date(rootState.reporting.dateRange[0].startDate)
                ).format('YYYY-MM-DDTHH:mm:ss'),
                end: dayjs(
                  new Date(rootState.reporting.dateRange[0].endDate)
                ).format('YYYY-MM-DDTHH:mm:ss'),
                business_hour_status: rootState.reporting.isBusinessHourEnabled,
              },
            }
          );
          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: null,
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataDefaultAppend(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                business_hour_status: rootState.reporting.isBusinessHourEnabled,
              },
            }
          );

          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: [],
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataUserMetricsLineGraph(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
              },
            }
          );
          const graphList = [
            'get_language_metrics',
            'get_timezone_metrics',
            'get_country_metrics',
            'get_ticket_avg_queue_time',
            'get_ticket_avg_resolve_time',
          ];

          if (res.data.success) {
            if (graphList.indexOf(payload.endpoint) !== -1) {
              let dataFinal = { ...res.data.dataSource };
              if (_.isEmpty(res.data.dataSource.max)) {
                dataFinal.max = {
                  name: 'Not Available',
                  value: 0,
                  growth: '+0.00%',
                };
              }
              dispatch.reporting.updateStateData({
                key: getReportingVariableNameFromEndpoint(payload.endpoint),
                value: dataFinal,
              });
            }
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportHeatmap(projectId, rootState) {
      /*
      payload= projectId
       */
      try {
        if (projectId) {
          const res = await axios.get(config.reporting.heatmap(projectId), {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          });
          if (res.data.success) {
            let defaultHeatmapData = Array(7)
              .fill(0)
              .map((x) => new Array(24).fill(0));
            const payloadLocal = res.data.dataSource
              .filter((e) => e.day < 7)
              .filter((e) => e.hour < 24);
            payloadLocal.forEach((elem) => {
              defaultHeatmapData[elem.day][elem.hour] = elem.count;
            });
            dispatch.reporting.updateStateData({
              key: 'heatmapData',
              value: defaultHeatmapData,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: 'heatmapData',
              value: null,
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAutomationTicketTable(payload, rootState) {
      /*
      payload= {projectId: num, groupId: number || null, limit: number, offset: number }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.automationTicketTable(payload.projectId),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                agent_id: payload.groupId,
                limit: payload.limit,
                offset: payload.offset,
              },
            }
          );
          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: 'ticketListTable',
              value: res.data.dataSource,
            });
            dispatch.reporting.updateStateData({
              key: 'totalTicketList',
              value: res.data.total,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: 'ticketListTable',
              value: [],
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchUserReportDataOnTabClick(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
              },
            }
          );
          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: null,
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportCustomerTicket(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          config.reporting.exportTicket(payload.projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              agent_id: payload.groupId,
            },
          }
        );
        if (res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        // console.log(err.response);
        return false;
      }
    },
    async exportAgentDetails(projectId, rootState) {
      /*
      payload= projectId : number
       */
      try {
        const res = await axios.get(
          config.reporting.exportAgentDetails(projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DDTHH:mm:ss')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DDTHH:mm:ss')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportIncomingVsAutomationChart(projectId, rootState) {
      /*
      projectId: num
       */
      try {
        const res = await axios.get(
          config.reporting.exportIncomingVsAutomationChart(projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `incoming-vs-automation_${
              rootState.dashboard.selectedProject.name
            }-${dayjs(rootState.reporting.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            )} to ${dayjs(rootState.reporting.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            )}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportTagCloud(projectId, rootState) {
      /*
      projectId: num
       */
      try {
        const res = await axios.get(
          config.reporting.exportTagCloud(projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `tag-list_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DDTHH:mm:ss')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DDTHH:mm:ss')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchConversationLog(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          config.reporting.conversationLog(payload.projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              agent_id: payload.agentId,
              intent: payload.intent,
              limit: payload.limit,
              offset: payload.offset,
            },
          }
        );
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'conversationLogs',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'conversationLogs',
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchIntentReporting(projectId) {
      /*
        projectId : num
       */
      try {
        if (projectId) {
          const res = await axios.get(config.reporting.intent(projectId));
          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: 'intentList',
              value: res.data.dataSource,
            });
          } else {
            dispatch.reporting.updateStateData({
              key: 'intentList',
              value: [],
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    //csat
    async fetchCsatStack({ teamId, url }, rootState) {
      try {
        const res = await axios.get(config.reporting.csat(teamId, url), {
          params: {
            platform: rootState.reporting.platformData.id || null,
            start: dayjs(rootState.reporting.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            end: dayjs(rootState.reporting.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
          },
        });
        if (!!res && res.data.success) {
          if (url === 'csat-total-count') {
            dispatch.reporting.updateStateData({
              key: 'csatTotalCount',
              value: res.data.dataSource,
            });
          } else if (url === 'csat-responded-count') {
            dispatch.reporting.updateStateData({
              key: 'csatRespondedCount',
              value: res.data.dataSource,
            });
          } else if (url === 'csat-average-rating') {
            dispatch.reporting.updateStateData({
              key: 'csatAvgRating',
              value: res.data.dataSource,
            });
          } else if (url === 'csat-response-count') {
            dispatch.reporting.updateStateData({
              key: 'csatTotalResponseCount',
              value: res.data.dataSource,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCsatResponseList(
      { teamId, search, limit, offset, agentsIds = [], ratings = [] },
      rootState
    ) {
      try {
        const res = await axios.get(config.reporting.csatResponseList(teamId), {
          params: {
            platform: rootState.reporting.platformData.id,
            start: dayjs(rootState.reporting.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            end: dayjs(rootState.reporting.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            search: search,
            limit: limit,
            offset: offset,
            agents: agentsIds.toString(),
            ratings: ratings.toString(),
          },
        });
        if (!!res && res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'csatResponseList',
            value: res.data.dataSource,
          });
          dispatch.reporting.updateStateData({
            key: 'csatResponseListTotal',
            value: res.data.total,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCsatResponseDist(teamId, rootState) {
      try {
        const res = await axios.get(config.reporting.csatResponseDist(teamId), {
          params: {
            platform: rootState.reporting.platformData.id,
            start: dayjs(rootState.reporting.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            end: dayjs(rootState.reporting.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
          },
        });
        if (!!res && res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'csatResponseDist',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    async exportCSAT(
      { projectId, search = '', offset = 0, agentsIds = [], ratings = [] },
      rootState
    ) {
      /*
      payload=
      projectId : number
      search: filterFormat?.customerName,
      offset: 0,
      agentsIds: filterFormat?.agent ?? [],
      ratings: filterFormat.rating ?? [],
       */
      try {
        const res = await axios.get(config.reporting.exportCSAT(projectId), {
          params: {
            platform: rootState.reporting.platformData.id,
            start: dayjs(rootState.reporting.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            end: dayjs(rootState.reporting.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            ),
            search,
            ratings: ratings.toString(),
            agentsIds: agentsIds.toString(),
          },
        });
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DDTHH:mm:ss')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DDTHH:mm:ss')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
        toaster.warning('Failed', {
          description: 'Failed to Export Data due to an exception',
        });
      }
    },
    async exportCSATSummary(projectId, rootState) {
      /*
      payload= projectId : number
       */
      try {
        const res = await axios.get(
          config.reporting.exportCSATSummary(projectId),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reporting.dateRange[0].startDate
            ).format('YYYY-MM-DDTHH:mm:ss')} to ${dayjs(
              rootState.reporting.dateRange[0].endDate
            ).format('YYYY-MM-DDTHH:mm:ss')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
        toaster.warning('Failed', {
          description: 'Failed to Export Data due to an exception',
        });
      }
    },

    // whatsapp metics
    async fetchWhatsappConversationMetrics(payload, rootState) {
      /*
      payload= {projectId: num, groupId: number || null, limit: number, offset: number }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.conversationMetrics(payload.projectId),
            {
              params: {
                platform: rootState.reporting.platformData.id,
                start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
                end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                  'YYYY-MM-DDTHH:mm:ss'
                ),
              },
            }
          );
          if (res.data.success) {
            dispatch.reporting.updateStateData({
              key: 'whatsappMetricsReport',
              value: res.data.dataSource,
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchTemplateMetrics(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          config.reporting.templateMetrics(
            rootState.dashboard.selectedProject.id
          ),
          {
            params: {
              platform: rootState.reporting.platformData.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              limit: payload.limit,
              offset: payload.offset,
              search: payload.search,
              status: payload.status,
              category: payload.category,
            },
          }
        );
        if (res.data.success) {
          const { template_analytics, total } = res.data.dataSource;
          dispatch.reporting.updateStateData({
            key: 'whatsappTemplateMetrics',
            value: template_analytics,
          });
          dispatch.reporting.updateStateData({
            key: 'totalDataOfTemplateMetrics',
            value: total,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'whatsappTemplateMetrics',
            value: [],
          });
          dispatch.reporting.updateStateData({
            key: 'totalDataOfTemplateMetrics',
            value: 0,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchTemplateAnalyticsLogs(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          config.reporting.templateAnalyticsLogs(
            rootState.dashboard.selectedProject.id
          ),
          {
            params: {
              platform: rootState.reporting.platformData?.id,
              start: dayjs(rootState.reporting.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reporting.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              limit: payload.limit,
              offset: payload.offset,
              template_id: rootState.reporting.selectedAnalyticsId,
              search: payload.search,
              status: payload.status,
              sources: payload.sources,
            },
          }
        );

        if (res.data.success) {
          const { template_reports, total } = res.data.dataSource;

          dispatch.reporting.updateStateData({
            key: 'templateAnalyticsLogsTotalData',
            value: total,
          });
          dispatch.reporting.updateStateData({
            key: 'templateAnalyticsLogs',
            value: template_reports,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'templateAnalyticsLogsTotalData',
            value: 0,
          });
          dispatch.reporting.updateStateData({
            key: 'templateAnalyticsLogs',
            value: [],
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchAgentList(teamId) {
      /*
      payload= teamId : number
       */
      //note: this effect is for Reporting
      try {
        const res = await axios.get(config.reporting.agent(teamId));

        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: null,
          });
        }
        return { result: res.data.dataSource, error: null };
      } catch (err) {
        return {
          result: null,
          error: err.response?.data?.error,
          code: err.response?.data?.code,
          statusCode: err.response?.status,
        };
      }
    },
  }),
};
