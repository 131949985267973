import { ReactElement } from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from 'utilities/utils';

interface Props {
  title: string;
  value: boolean;
  subTitle: ReactElement;
  warningMsg?: string;
  handleOnChange: (val: boolean) => void;
}

const SingleOptionItem: React.FC<Props> = ({
  title,
  value,
  subTitle,
  warningMsg = '',
  handleOnChange,
}) => {
  const getWarningMsg = () => {
    if (!value && warningMsg)
      return (
        <p className='text-sm flex font-normal text-red-500 leading-5 pb-1'>
          {warningMsg}
        </p>
      );
    else return '';
  };
  return (
    <>
      <div className='w-full   px-4'>
        <Switch.Group as='div' className='flex items-center justify-between'>
          <span className='flex-grow flex flex-col'>
            <Switch.Label
              as='span'
              className='text-sm flex flex-col font-medium text-gray-900'
              passive
            >
              <p className='text-sm flex font-medium text-gray-700 leading-5 pb-1'>
                {title}
              </p>
              <span className='flex'>{subTitle}</span>
              <span>{getWarningMsg()}</span>
            </Switch.Label>
          </span>
          <Switch
            checked={value}
            onChange={(val: boolean) => {
              handleOnChange(val);
            }}
            className={classNames(
              value ? 'bg-green-500' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-7 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 '
            )}
          >
            <span
              aria-hidden='true'
              className={classNames(
                value
                  ? 'ltr:translate-x-5 rtl:-translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-6 w-6  rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        </Switch.Group>
      </div>
    </>
  );
};

export default SingleOptionItem;
