import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, Spinner } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';
import useTranslation from 'components/customHooks/useTranslation';

const VideoUploadField = ({
  video,
  handleDrop,
  handleClear,
  isDisabled = false,
  hasWriteAccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    onDrop: (files) => {
      if (hasWriteAccess) {
        setLoading(true);
        handleDrop(files[0]);
        setTimeout(() => setLoading(false), 1500);
      }
    },
    // Error handle added on smart block component
    // onDropRejected: () => {
    //   toaster.danger('Failed', {
    //     description: 'Video too large. Maximum image size is 50MB',
    //     duration: 1,
    //   });
    // },
    multiple: false,
    maxSize: 52428800,
  });

  const VideoStyle = { maxHeight: 200, borderRadius: 5 };

  return (
    <section className='image-upload'>
      <div
        {...getRootProps()}
        tabIndex={-1}
        className={`${
          isDisabled ? 'cursor-not-allowed bg-gray-100' : ''
        } image-upload__field`}
      >
        {!video && !loading && (
          <Fragment>
            <input {...getInputProps()} disabled={isDisabled} />
            <Icon icon='mobile-video' size={50} />
            <p>{t("Drag 'n' Drop your video file")}</p>
            <p>{t('Maximum size 50 MB allowed')}</p>
          </Fragment>
        )}

        {loading && <Spinner />}

        {video && !loading && (
          <Fragment>
            {hasWriteAccess && (
              <IconButton
                disabled={isDisabled}
                icon='cross'
                appearance='minimal'
                intent={'danger'}
                onClick={() => handleClear()}
              />
            )}
            <video src={video} style={VideoStyle} />
          </Fragment>
        )}
      </div>
    </section>
  );
};

VideoUploadField.propTypes = {
  video: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
VideoUploadField.defaultProps = {
  hasWriteAccess: true,
};

export default VideoUploadField;
