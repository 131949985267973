import { MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import useDebounce from 'components/customHooks/useDebounce';
import {
  ICustomerOrderProperty,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import React from 'react';
import { connect } from 'react-redux';
import EmptyScreen from './EmptyScreen';
import OrderCard from './OrderCard';
import { sortOrdersFirstByStatus } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  customerOrderList: ICustomerOrderProperty[];
  fetchCustomerOrder: (
    payload:
      | {
          customerId: number;
          orderId: number;
        }
      | { customerId: number }
  ) => void;
  selectedTicket: TicketInterface;
  isOrderListLoading: boolean;
  setDisplayScreen: (payload: string) => void;
  setSelectedOrder: (payload: string | null) => void;
}

const OrderList: React.FC<Props> = ({
  customerOrderList,
  fetchCustomerOrder,
  selectedTicket,
  isOrderListLoading,
  setDisplayScreen,
  setSelectedOrder,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState<string>('');
  const searchDebounce = useDebounce(search, 1500);

  const renderSpinnerView = () => {
    return (
      <div className='flex items-center justify-center w-10 h-10'>
        <div
          className='animate-spin h-5 w-5 rounded-full border
            border-t-transparent border-b-green-500 border-l-green-500 border-r-green-500 '
        ></div>
      </div>
    );
  };

  const renderSearchInputView = () => {
    return (
      <div className='px-3 mb-2'>
        <div className='relative rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='number'
            value={search}
            onWheel={(e) => e.currentTarget?.blur()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
            placeholder={t('Search by order ID')}
          />
        </div>
      </div>
    );
  };

  const renderOrderListHeaderView = () => {
    return (
      <div className='flex items-center gap-2 font-semibold text-sm leading-5 text-gray-900 w-[95%] cursor-default'>
        <ArrowLeftIcon
          className='w-4 h-4 cursor-pointer rtl:rotate-180'
          onClick={() => {
            fetchCustomerOrder({
              customerId: selectedTicket.customer_id,
            });
            setDisplayScreen('');
          }}
        />
        <span>{t('All Orders')}</span>
      </div>
    );
  };

  React.useEffect(() => {
    fetchCustomerOrder({
      customerId: selectedTicket?.customer_id,
      orderId: parseInt(search),
    });
    // eslint-disable-next-line
  }, [searchDebounce]);

  return (
    <>
      <div className='flex items-center justify-between p-3'>
        {renderOrderListHeaderView()}
      </div>
      {renderSearchInputView()}
      <div className='px-3'>
        {!isOrderListLoading &&
          sortOrdersFirstByStatus(customerOrderList)?.map((order) => {
            return (
              <OrderCard
                order={order}
                setDisplayScreen={setDisplayScreen}
                setSelectedOrder={setSelectedOrder}
                key={order.id}
              />
            );
          })}
      </div>
      <div className='w-full p-3 flex justify-center'>
        {isOrderListLoading && renderSpinnerView()}
        {!isOrderListLoading && customerOrderList.length === 0 && (
          <EmptyScreen
            displayText={`${t('Nothing found related to')} <b>${search}</b>`}
            isOrderlist={true}
          />
        )}
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  customerOrderList: state.ecommerce.customerOrderList,
  selectedTicket: state.inbox.selectedTicket,
  isOrderListLoading: state.loading.effects.ecommerce.fetchCustomerOrder,
});
const mapDispatch = (dispatch: any) => ({
  fetchCustomerOrder: (
    payload: { customerId: number; orderId: number } | { customerId: number }
  ) => dispatch.ecommerce.fetchCustomerOrder(payload),
});

export default connect(mapState, mapDispatch)(OrderList);
