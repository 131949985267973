import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'evergreen-ui';

const PopupPhoneContent = ({ inputVal, handleChange }) => {
  return (
    <Fragment>
      <h5>Call to Number</h5>
      <TextInput
        placeholder='Insert a phone number'
        value={inputVal}
        onChange={(e) => handleChange(e.target.value)}
      />
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>
          Users can directly make a phone call by clicking on the button.
        </span>
      </p>
    </Fragment>
  );
};

PopupPhoneContent.propTypes = {
  inputVal: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PopupPhoneContent;
