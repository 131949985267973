import React from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from '../../../../../utilities/utils';

interface Props {
  checked: boolean;
  height?: string;
  width?: string;
  className?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const CustomSwitch: React.FC<Props> = ({
  checked = false,
  height = 6,
  width = 11,
  disabled = false,
  className,
  onChange,
}) => {
  return (
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={() => onChange(!checked)}
      className={classNames(
        checked ? 'bg-primary' : 'bg-gray-200',
        `relative inline-flex flex-shrink-0 h-${height} w-${width} border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${
          !!className ? className : ''
        }`
      )}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={classNames(
          checked ? 'ltr:translate-x-5 rtl:-translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};
