import React from 'react';
import { SideSheet as OrderModal } from '../../../../../../library';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import Modal from '../../../../../components/elements/redesign/Modal';
import {
  SelectedProductDataProps,
  ShopifyUpdateOrderCustomerDataProps,
} from '../eCommerceOrderCreate/interfaces';
import { orderData } from '../../../../../../utilities/content';

interface Prop {
  isSameShippingAddress: boolean;
  updateOrderOpen: boolean;
  setUpdateOrderOpen: (value: boolean) => void;
  customerId: number;
  clearState: () => void;
  customerData: ShopifyUpdateOrderCustomerDataProps;
  teamId: number;
  updateShopifyOrder: (
    teamId: number,
    customerId: number,
    orderId: string
  ) => Promise<any>;
  ticketId: number;
  eComOrderId?: string | number;
  orderId: string;
  createOrderLoading: boolean;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
  updateMinimizeModal: () => void;
  updateStateData: (key: string, value: any) => void;
  calculateUpdateOrderBegin: (
    customerId: number,
    orderId: string
  ) => Promise<any>;
  calculateUpdateOrderEnd: (
    customerId: number,
    orderId: string
  ) => Promise<any>;
}

const ShopifyOrderUpdate: React.FC<Prop> = ({
  isSameShippingAddress,
  updateOrderOpen,
  setUpdateOrderOpen,
  customerId,
  clearState,
  customerData,
  teamId,
  updateShopifyOrder,
  ticketId,
  eComOrderId,
  orderId,
  createOrderLoading,
  updateCart,
  updateMinimizeModal,
  updateStateData,
  calculateUpdateOrderBegin,
  calculateUpdateOrderEnd,
}) => {
  const [step, setStep] = React.useState(1);
  const [showCloseWarningModal, setShowCloseWarningModal] =
    React.useState(false);
  const containerRef = React.createRef();
  type createOrderStateType =
    | 'Loading'
    | 'Try Again'
    | 'Exit'
    | 'Next'
    | 'Update Order';
  const [createdOrderState, setCreatedOrderState] =
    React.useState<createOrderStateType>('Next');

  const handleConfirm = async () => {
    if (createdOrderState === 'Exit') {
      setUpdateOrderOpen(false);
      setTimeout(() => {
        setStep(1);
      }, 1000);
    } else if (createdOrderState === 'Try Again') {
      setStep(1);
      setCreatedOrderState('Next');
    } else {
      switch (step) {
        case 1:
          // call api to store
          setStep(2);
          break;
        case 2:
          // update delivery information
          setStep(3);
          setCreatedOrderState('Update Order');
          break;
        case 3:
          setStep(4);
          break;
      }
    }
  };

  const handleCancel = () => {
    if (createdOrderState !== 'Exit' && createdOrderState !== 'Try Again') {
      switch (step) {
        case 2:
          // update delivery information
          setStep(1);
          break;
        case 3:
          setStep(2);
          break;
        case 4:
          setStep(3);
      }
    }
  };

  const getOrderHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: `Update Order-${eComOrderId}`,
          description:
            'Click on the search bar below to browse your inventory. A selected product from the inventory will be added to the cart. You can further customize the order from there.',
        };
      case 2:
        return {
          title: 'Customer Information',
          description:
            'Please complete the required fields for order placement',
        };
      case 3:
        return {
          title: 'Order Summary',
          description:
            'Please check the information before confirming the order.',
        };
    }
  };

  React.useEffect(() => {
    //we need this to scroll to top for each step
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, [step]);

  const checkStep1NextButton = () => {
    const cart: SelectedProductDataProps[] =
      customerData?.visibleCalculatedProducts || [];
    let flag: boolean = false;
    if (cart.length > 0) {
      cart.forEach((product) => {
        if (product.has_variations && !product.variant_id) {
          flag = true;
        }
      });
      return flag;
    }
    return true;
  };

  const checkStep2NextButton = () => {
    const shippingMandatoryKey = ['first_name', 'address_one'];
    let flag = false;
    shippingMandatoryKey.forEach((elem) => {
      if (
        !(
          customerData &&
          customerData?.shipping_address &&
          customerData?.shipping_address[elem]
        )
      ) {
        flag = true;
      }
    });
    return flag;
  };

  const checkNextButtonAllow = () => {
    switch (step) {
      case 1:
        return checkStep1NextButton();
      case 2:
        return checkStep2NextButton();
      case 3:
      case 4:
        return false;
      default:
        return true;
    }
  };

  const handleCloseModal = () => {
    if (customerData?.visibleCalculatedProducts?.length > 0) {
      setUpdateOrderOpen(false);
      updateMinimizeModal();
      setShowCloseWarningModal(true);
    } else {
      setUpdateOrderOpen(false);
      clearState();
      setStep(1);
      updateCart(customerId, []);
    }
  };
  const handleMinimizeModal = () => {
    setUpdateOrderOpen(false);
    updateMinimizeModal();
  };

  return (
    <div>
      <OrderModal
        open={updateOrderOpen}
        ref={containerRef}
        hasMinimizeButton={true}
        handleClose={handleCloseModal}
        handleMinimize={handleMinimizeModal}
        closeOnExternalClick={false}
        disableConfirm={checkNextButtonAllow() ? true : createOrderLoading}
        title={getOrderHeaderInfo(step).title}
        description={getOrderHeaderInfo(step).description}
        confirmText={createdOrderState}
        cancelText={'Back'}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        hideCancel={
          step === 1 ||
          createdOrderState === 'Loading' ||
          createdOrderState === 'Exit' ||
          createdOrderState === 'Try Again'
        }
        hasDropdown={false}
        handleChange={(data: any) => updateStateData('orderStatus', data)}
      >
        <Wrapper
          step={step}
          orderId={orderId}
          customerId={customerId}
          eComOrderId={eComOrderId}
          calculateUpdateOrderBegin={async () => {
            if (customerId && orderId) {
              setCreatedOrderState('Loading');
              let response = await calculateUpdateOrderBegin(teamId, orderId);
              if (!response?.success) {
                setStep(4);
                setCreatedOrderState('Exit');
              } else {
                setCreatedOrderState('Next');
              }
              return response;
            }
            return null;
          }}
          updateOrderAPI={async () => {
            setCreatedOrderState('Loading');
            let res = await updateShopifyOrder(teamId, customerId, orderId);
            if (res.success) {
              updateCart(customerId, []);
              clearState();
              setCreatedOrderState('Exit');
            } else {
              setCreatedOrderState('Try Again');
            }
            return res;
          }}
          finishCalculationApi={async () => {
            if (teamId && orderId) {
              setCreatedOrderState('Loading');
              let response = await calculateUpdateOrderEnd(teamId, orderId);
              if (!response?.success) {
                setCreatedOrderState('Try Again');
              } else {
                setCreatedOrderState('Next');
              }
              return response;
            }
            return null;
          }}
        />
      </OrderModal>
      <Modal
        isShown={showCloseWarningModal}
        setShown={setShowCloseWarningModal}
        intent={'warning'}
        title={orderData.discardOrderUpdation.title}
        description={orderData.discardOrderUpdation.description}
        deleteTitle='Cancel Order Update'
        cancelTitle='Cancel'
        hasConfirmText={false}
        onCancelCallback={() => {
          setUpdateOrderOpen(true);
        }}
        onDeleteCallback={() => {
          clearState();
          setStep(1);
          updateCart(customerId, []);
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  teamId: state.dashboard?.selectedProject?.id || null,
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  customerData: state.crmEcommerce.customerData,
  createOrderLoading: state.loading.effects.crmEcommerce.createOrder,
});

const mapDispatch = (dispatch: any) => ({
  clearState: () => dispatch.crmEcommerce.clearState(),
  updateShopifyOrder: (teamId: number, customerId: number, orderId: string) =>
    dispatch.crmEcommerce.updateShopifyOrder({
      teamId,
      customerId,
      orderId,
    }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
  updateMinimizeModal: () => dispatch.crmEcommerce.updateMinimizeModal(),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
  calculateUpdateOrderBegin: (teamId: number, orderId: string) =>
    dispatch.crmEcommerce.calculateUpdateOrderBegin({ teamId, orderId }),
  calculateUpdateOrderEnd: (customerId: number, orderId: string) =>
    dispatch.crmEcommerce.calculateUpdateOrderEnd({
      customerId,
      orderId,
    }),
});

const ShopifyOrderUpdateContainer = connect(
  mapState,
  mapDispatch
)(ShopifyOrderUpdate);
export default ShopifyOrderUpdateContainer;
