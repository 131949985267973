import {
  toast,
  useDispatch,
  useSelector,
  UtilityContents,
  UtilityFunctions,
} from 'pages/automationWorkflow/export';
import {
  NodeType,
  IUserFlowTableDataTypes,
  toggleUserActionFlowTypes,
  EcommerceStore,
} from 'pages/automationWorkflow/interface';

const useAutomationDashboard = () => {
  const dispatch = useDispatch();
  const { platformList, selectedProject } = useSelector(
    (state: any) => state.dashboard
  );
  const {
    integratedEcommerceList,
    userActionFlowList,
    selectedUserActionFlowData,
  } = useSelector((state: any) => state.workflowAutomation);

  // Destructure required state loading from the workflowAutomation
  const {
    fetchUserActionFlowList: isFetchUserActionFlowListLoading,
    updateSelectedAutomationFlow: isUpdateSelectedAutomationFlowLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  const fetchIntegratedEcommerceData = async (): Promise<boolean> => {
    const { id } = selectedProject;
    await dispatch.workflowAutomation.fetchMultiEcommerceData(id);
    return true;
  };

  const getConnectedEcommerce = (id: number) => {
    if (!integratedEcommerceList || integratedEcommerceList?.length < 1)
      return null;

    return (
      integratedEcommerceList.find(
        (ecommerce: EcommerceStore) => ecommerce?.alice_store_id === id
      ) ?? null
    );
  };

  const fetchUserActionFlowData = (offsetValue: number) => {
    const { id } = selectedProject;
    dispatch.workflowAutomation.fetchUserActionFlowList({
      projectId: id,
      offset: offsetValue,
      limit: UtilityContents?.UserActionFlowGetterApiDataLimit,
    });
  };

  const updateSelectedUserActionFlowData = (
    flowData: IUserFlowTableDataTypes | null
  ) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedUserActionFlowData',
      value: flowData && Object.keys(flowData).length ? flowData : {},
    });
  };

  const resetPrimitiveStateData = () => {
    updateSelectedUserActionFlowData(null);
    updateWorkflowModalVisibility(null);
  };

  const fetchSelectedActionFlowData = (flowIdInfo: IUserFlowTableDataTypes) => {
    dispatch.workflowAutomation.fetchSelectedActionFlow(flowIdInfo?.id);
    updateSelectedWorkflowName(flowIdInfo?.name);
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'isNewWorkFlowActive',
      value: flowIdInfo?.is_active,
    });
  };

  const editSelectedUserWorkflowActivation = async (
    payload: toggleUserActionFlowTypes
  ) => {
    try {
      const getUpdateRes =
        await dispatch.workflowAutomation.updateSelectedUserWorkflowInfo({
          payload,
          projectId: selectedProject?.id,
        });
      if (getUpdateRes && Object.keys(getUpdateRes?.dataSource).length !== 0) {
        toast({
          title: 'Workflow updated successfully',
          description: 'Workflow status changed successfully',
        });
        const updatedData = userActionFlowList.map((item: any) => {
          if (item?.id === getUpdateRes?.dataSource?.id) {
            return {
              ...item,
              is_active: getUpdateRes?.dataSource?.is_active,
            };
          }
          return item;
        });
        dispatch.workflowAutomation.updateUserActionFlowList(updatedData);
      }
    } catch (err) {
      toast({
        variant: 'destructive',
        title: 'Workflow update failed',
        description: 'Please try agin after some time',
      });
      console.log(err);
    }
  };
  const updateSelectedWorkflowName = (selectedName: string) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'automationName',
      value: selectedName,
    });
  };

  const handleViewSelectedAutomationHistories = (
    updateData: IUserFlowTableDataTypes
  ) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'automationFlowDisplayViewType',
      value: 'viewHistories',
    });
    updateSelectedWorkflowName(updateData?.name);
    updateSelectedUserActionFlowData(updateData);
  };

  //Handle Whatsapp Template
  const handleWhatsappTemplate = async () => {
    const tempChannelList = platformList
      .filter((channel: any) => channel?.platform_type === 'whatsapp_bsp')
      .map((channel: any) => ({
        label: channel?.title,
        id: channel?.id,
      }));

    dispatch.workflowAutomation.updateChannelList(tempChannelList);
  };

  const handleSelectedEcommerce = async (
    flowIdInfo: IUserFlowTableDataTypes
  ) => {
    const selectedEcommerce: EcommerceStore | null = isNaN(
      flowIdInfo?.trigger_match_unique_ref
    )
      ? null
      : getConnectedEcommerce(flowIdInfo?.trigger_match_unique_ref);

    if (!selectedEcommerce) {
      toast({
        title: 'No Active Ecommerce Found',
        description: 'Please connect an ecommerce to activate',
      });
      return;
    }

    await dispatch.workflowAutomation.updateSelectedEcommerce(
      selectedEcommerce
    );
  };

  const handleUpdatedSelectedUserFlowData = async (
    flowIdInfo: IUserFlowTableDataTypes
  ) => {
    const selectedEcommerce: EcommerceStore | null = isNaN(
      flowIdInfo?.trigger_match_unique_ref
    )
      ? null
      : getConnectedEcommerce(flowIdInfo?.trigger_match_unique_ref);

    if (!selectedEcommerce) {
      toast({
        title: 'No Active Ecommerce Found',
        description: 'Please connect an ecommerce to activate',
      });
      return;
    }

    await dispatch.workflowAutomation.updateSelectedEcommerce(
      selectedEcommerce
    );

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'isAutomationEditable',
      value: true,
    });
    updateSelectedWorkflowName(flowIdInfo?.name);
    handleWhatsappTemplate();

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'isNewWorkFlowActive',
      value: flowIdInfo?.is_active,
    });
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedAutomationId',
      value: flowIdInfo?.id,
    });

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedTriggerTab',
      value: selectedEcommerce?.ecommerce_type,
    });
    const selectedAutomationNodeData =
      await dispatch.workflowAutomation.fetchSelectedActionFlow({
        flowId: flowIdInfo?.id,
        projectId: selectedProject?.id,
      });
    if (selectedAutomationNodeData.length) {
      const selectedTriggerData = selectedAutomationNodeData.filter(
        (workFlowData: any) => workFlowData?.type === NodeType.TRIGGER
      );
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedTrigger',
        value: selectedTriggerData[0]?.data,
      });
      //Fetch Sepecific Executor Data
      if (selectedTriggerData.length !== 0) {
        dispatch.workflowAutomation.fetchAutomationViewData({
          selectedEcommerce,
          projectId: selectedProject?.id,
        });
        dispatch.workflowAutomation.fetchSelectedTriggerDetails({
          ecommerceName: selectedEcommerce,
          selectedTriggerCode: selectedTriggerData[0]?.data?.trigger_code,
          projectId: selectedProject?.id,
        });
      }
    }
  };
  const handleDeleteWorkflowConfirmation = (
    updateData: IUserFlowTableDataTypes
  ) => {
    updateWorkflowModalVisibility('Delete');
    updateSelectedUserActionFlowData(updateData);
    updateSelectedWorkflowName(updateData?.name);
  };

  const handleDeleteSelctedActionWorkflow = async () => {
    try {
      await dispatch.workflowAutomation.deleteSelectedUserActionWorkflow({
        flowId: selectedUserActionFlowData?.id,
        projectId: selectedProject?.id,
        trigger_match_unique_ref:
          selectedUserActionFlowData?.trigger_match_unique_ref ?? '',
      });

      // If the deletion is successful, update UI and userActionFlowList
      let tempCreatedUserWorkflowList: any[] = [];
      for (let i = 0; i < userActionFlowList.length; i++) {
        if (selectedUserActionFlowData?.id !== userActionFlowList[i].id) {
          tempCreatedUserWorkflowList.push(userActionFlowList[i]);
        }
      }
      dispatch.workflowAutomation.updateUserActionFlowList(
        tempCreatedUserWorkflowList
      );
      updateWorkflowModalVisibility(null);
    } catch (err: any) {
      // Handle errors
      updateWorkflowModalVisibility(null);
    }
  };

  const updateWorkflowModalVisibility = (name: string | null) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedUserWorkflowMode',
      value: name !== null ? name : null,
    });
  };

  const updateWorkflowPrimitiveDataAfterRename = (
    flowInfo: IUserFlowTableDataTypes
  ) => {
    updateWorkflowModalVisibility('Rename');
    updateSelectedUserActionFlowData(flowInfo);
  };

  const handleSelectedUserWorkflowName = (value: string) => {
    const updatedSelectedUserActionFlowData = {
      ...selectedUserActionFlowData,
      name: value,
    };
    updateSelectedUserActionFlowData(updatedSelectedUserActionFlowData);
  };

  const editSelectedUserWorkflowRename = async () => {
    try {
      const payload = {
        flowId: selectedUserActionFlowData?.id,
        name: selectedUserActionFlowData?.name,
        trigger_code: selectedUserActionFlowData?.trigger_code,
        is_active: selectedUserActionFlowData?.is_active,
        trigger_match_unique_ref:
          selectedUserActionFlowData?.trigger_match_unique_ref ?? '',
      };
      const getUpdateRes =
        await dispatch.workflowAutomation.updateSelectedUserWorkflowInfo({
          payload,
          projectId: selectedProject?.id,
        });

      if (getUpdateRes && Object.keys(getUpdateRes?.dataSource).length !== 0) {
        toast({
          title: 'Workflow updated successfully',
          description: 'Workflow renamed successfully',
        });
        const updatedData = UtilityFunctions.updateUserWorkflowListAfterRename(
          userActionFlowList,
          getUpdateRes
        );
        dispatch.workflowAutomation.updateUserActionFlowList(updatedData);
      }
      updateWorkflowModalVisibility(null);
    } catch (err) {
      console.log(err);
      toast({
        variant: 'destructive',
        title: 'Workflow update failed',
        description: 'Please try agin after some time',
      });
      resetPrimitiveStateData();
    }
  };

  const updateWorkflowDashboardTableOffset = (data: number) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'workflowDashboardTableOffset',
      value: data,
    });
    fetchUserActionFlowData(data);
  };

  return {
    handleSelectedEcommerce,
    fetchIntegratedEcommerceData,
    handleViewSelectedAutomationHistories,
    fetchUserActionFlowData,
    updateSelectedWorkflowName,
    fetchSelectedActionFlowData,
    handleSelectedUserWorkflowName,
    updateWorkflowModalVisibility,
    handleDeleteWorkflowConfirmation,
    handleUpdatedSelectedUserFlowData,
    isFetchUserActionFlowListLoading,
    handleDeleteSelctedActionWorkflow,
    editSelectedUserWorkflowActivation,
    updateWorkflowDashboardTableOffset,
    updateWorkflowPrimitiveDataAfterRename,
    editSelectedUserWorkflowRename,
    isUpdateSelectedAutomationFlowLoading,
    getConnectedEcommerce,
  };
};

export default useAutomationDashboard;
