import React from 'react';
import { connect } from 'react-redux';
import {
  getUpdatedPlatformIconOutlined,
  getPlatformInfoTitle,
} from '../../../../utilities/utils';

interface props {
  name: string;
  platform: any;
  id: number;
  secondary_id: string;
  currentTicket: any;
  region: string;
  country: string;
}

const CRMRightInfoBar: React.FC<props> = ({
  id,
  name,
  platform,
  secondary_id,
  currentTicket,
  region,
  country,
}) => {
  return (
    <div className='customer-info__action-bar customer-info__right-topbar'>
      <div className='right-side-info-bar__header__user-info'>
        {secondary_id &&
        ['instagram_messenger', 'instagram_feed'].includes(platform?.type) ? (
          <div>
            <p>
              <a
                target='_blank'
                rel='noreferrer'
                href={`https://instagram.com/${secondary_id}`}
              >
                {secondary_id}
              </a>
            </p>
          </div>
        ) : (
          <div>{!!currentTicket && <p>{name || 'Anonymous User'}</p>}</div>
        )}

        <div>
          {!!currentTicket && (
            <p className='right-side-info-bar__header__user-info-location'>
              {region ? region : ''}
              {region && country ? ', ' : ''} {country ? country : ''}
            </p>
          )}
        </div>
      </div>
      {!!currentTicket && (
        <div className='right-side-info-bar__header__user-platform'>
          <img
            src={getUpdatedPlatformIconOutlined(platform?.type || 'webchat')}
            width={12}
            height={12}
            loading='lazy'
            alt='channel'
          />
          &nbsp;
          <p className='ticket-card__content-left-info-subtitle'>
            {getPlatformInfoTitle(platform) || 'Webchat'}
          </p>
        </div>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  id: state.crm.currentTicket?.id,
  name: state.crm.customerAttributes?.fixed.full_name,
  platform: state.crm.customerMeta?.platform,
  secondary_id: state.crm.customerMeta?.secondary_id,
  currentTicket: state.crm.currentTicket,
  region: state.crm.customerAttributes?.variable.region || '',
  country: state.crm.customerAttributes?.variable.country || '',
});

const CRMRightInfoBarContainer = connect(mapState)(CRMRightInfoBar);
export default CRMRightInfoBarContainer;
