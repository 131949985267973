import React from 'react';
import useTranslation from 'components/customHooks/useTranslation';
import CustomComboBox from 'components/elements/CustomComboBox';

interface CityDetailsProps {
  id: number;
  name: string;
}

interface Props {
  disable?: boolean;
  loading?: boolean;
  placeholder: string;
  selected: CityDetailsProps | null;
  options: CityDetailsProps[];
  handleChange: (value: { id: number; name: string }) => void;
}

const CityListDropDown: React.FC<Props> = ({
  selected,
  options,
  handleChange,
  disable = false,
  loading = false,
  placeholder = 'Search Your City',
}) => {
  const { t } = useTranslation();
  const handleCountryChange = (id: any) => {
    const city = options.filter(
      (option: CityDetailsProps) => option?.id === id
    );
    if (!!city && city?.length > 0) {
      const cityOption = city[0];
      handleChange({
        id: cityOption?.id,
        name: cityOption?.name,
      });
    }
  };

  const renderCityListView = () => {
    return (
      <CustomComboBox
        placeholder={placeholder}
        items={options}
        titleIndex='name'
        valueIndex='id'
        loading={loading}
        disable={disable}
        value={selected?.id ?? ''}
        notFoundMessage='No City found.'
        onChange={(value: any) => {
          handleCountryChange(value);
        }}
      />
    );
  };

  return (
    <div className='relative'>
      <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
        {t('City')}
        <span className='text-red-500'>*</span>
      </label>
      {renderCityListView()}
    </div>
  );
};

export default CityListDropDown;
