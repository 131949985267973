import React from 'react';
import DeliveryPresentDetails from '../../eCommerceOrderCreate/components/DeliveryPresentDetails';
import DeliveryShippingtDetails from '../../eCommerceOrderCreate/components/DeliveryShippingDetails';
import DeliveryPayment from '../components/DeliveryPayment';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { CustomerDataProps, ShopifyShippingMethodProps } from '../../eCommerceOrderCreate/interfaces';
import Discounts from '../components/Discounts';
import ApplyTax from '../components/ApplyTax';
import OrderNote from '../components/OrderNote'

interface Prop {
  customerData: CustomerDataProps;
  updateAddress: (key: string, value: string, mainKey: string) => void;
  updateStateData: (key: string, value: any) => void;
  shippingMethod: ShopifyShippingMethodProps;
  shippingCost: number;
  isSameShippingAddress: boolean;
  shippingMethodList: ShopifyShippingMethodProps[];
  currencySymbol: string;
  countryDetails: {
    country: string;
    country_iso_code: string;
    phone_code: string;
  };
  customerId: number;
  calculateShopifyDraftOrder: (teamId: number, customerId: number) => Promise<any>;
  calculateFinalShopifyDraftOrder: (teamId: number, customerId: number) => Promise<any>;
  shopifyDiscountType: string;
  shopifyDiscountAmount: string;
  applyTax: boolean;
  orderNote?: string;
  teamId: number;
}

const DeliveryInformation: React.FC<Prop> = ({
  customerData,
  updateAddress,
  updateStateData,
  shippingMethod,
  shippingCost,
  isSameShippingAddress,
  shippingMethodList,
  currencySymbol,
  countryDetails,
  customerId,
  calculateShopifyDraftOrder,
  calculateFinalShopifyDraftOrder,
  shopifyDiscountAmount,
  shopifyDiscountType,
  applyTax,
  orderNote,
  teamId,
}) => {
  const setShippingFromBilling = (checked: boolean) => {
    if (checked && customerData && customerData?.billing_address !== customerData.shipping_address) {
      let data: CustomerDataProps = cloneDeep(customerData);
      data.shipping_address = data?.billing_address;
      updateStateData('customerData', data);
    }
  };

  const getCurrentShippingMethod = (calculatedOrder: any, previousMethod: any) => {
    let idx = 0;
    calculatedOrder.available_shipping_lines.forEach((method: any, index: number) => {
      if (method.method_id === previousMethod?.method_id) {
        idx = index;
      }
    });
    return calculatedOrder.available_shipping_lines[idx];
  };

  const handleOrderCalculation = async () => {
    let previousShippingMethod = shippingMethod;
    let previousShippingCost = shippingCost;
    let calculatedOrder = await calculateShopifyDraftOrder(teamId, customerId);
    if (calculatedOrder) {
      updateStateData('shippingMethod', getCurrentShippingMethod(calculatedOrder, previousShippingMethod));
      updateStateData('applyTax', calculatedOrder.apply_tax);
      if (previousShippingCost) {
        updateStateData('shippingCost', previousShippingCost);
      }
    }
  };

  React.useEffect(() => {
    if (isSameShippingAddress) {
      if (!!customerData && !!customerData.billing_address && !customerData.billing_address.country) {
        customerData.billing_address.country = countryDetails.country || 'singapore';
      }
      let data: any = !!customerData ? cloneDeep(customerData) : {};
      data['shipping_address'] = data?.billing_address;
      updateStateData('customerData', data);
    }
    if (customerId) {
      handleOrderCalculation();
    }
  
    setShippingFromBilling(isSameShippingAddress);
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {!!customerData && customerData?.billing_address && (
        <DeliveryPresentDetails
          data={customerData?.billing_address}
          handleChange={(key: string, value: string) => {
            if (isSameShippingAddress) {
              updateAddress(key, value, 'shipping_address');
            }
            updateAddress(key, value, 'billing_address');
          }}
          countryDetails={countryDetails}
        />
      )}
      {!!customerData && customerData?.shipping_address && (
        <DeliveryShippingtDetails
          data={
            isSameShippingAddress
              ? customerData?.billing_address
              : customerData?.shipping_address
          }
          isSameShippingAddress={isSameShippingAddress}
          updateIsSameBillingAddress={(checked: boolean) => {
            updateStateData('isSameShippingAddress', checked);
            setShippingFromBilling(checked);
          }}
          handleChange={(key: string, value: string) => {
            updateAddress(key, value, 'shipping_address');
          }}
        />
      )}
      <DeliveryPayment
        shippingMethodList={shippingMethodList}
        shippingMethod={shippingMethod}
        shippingCost={shippingCost}
        handleChange={(key: string, value: any) => {
          updateStateData(key, value);
        }}
        currencySymbol={currencySymbol}
        calculateFinalShopifyDraftOrder={() => calculateFinalShopifyDraftOrder(teamId, customerId)}
        handleOrderCalculation={handleOrderCalculation}
      />
      <OrderNote
        orderNote={orderNote}
        handleChange={(key: string, value: string) =>
          updateStateData(key, value)
        }
      />
      <Discounts
        shopifyDiscountType={shopifyDiscountType}
        shopifyDiscountAmount={shopifyDiscountAmount}
        handleChange={(key: string, value: string) =>
          updateStateData(key, value)
        }
      />
      <ApplyTax
        taxApplied={applyTax}
        handleChange={(key: string, value: boolean) =>
          updateStateData(key, value)
        }
      />
    </div>
  );
};

const mapState = (state: any) => ({
  orderNote: state.crmEcommerce.orderNote,
  customerData: state.crmEcommerce.customerData,
  shippingMethod: state.crmEcommerce.shippingMethod,
  shippingCost: state.crmEcommerce.shippingCost,
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  shippingMethodList: state.crmEcommerce.shippingMethodList,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  countryDetails: state.settings.countryDetails,
  shopifyDiscountType: state.crmEcommerce.shopifyDiscountType,
  shopifyDiscountAmount: state.crmEcommerce.shopifyDiscountAmount,
  applyTax: state.crmEcommerce.applyTax,
  teamId: state.dashboard.selectedProject.id,
});

const mapDispatch = (dispatch: any) => ({
  updateAddress: (key: string, value: string, mainKey: string) =>
    dispatch.crmEcommerce.updateAddress({ key, value, mainKey }),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
  calculateShopifyDraftOrder: (teamId: number, customerId: number) =>
    dispatch.crmEcommerce.calculateShopifyDraftOrder({ teamId, customerId, }),
  calculateFinalShopifyDraftOrder: (teamId: number, customerId: number) =>
    dispatch.crmEcommerce.calculateFinalShopifyDraftOrder({ teamId, customerId, }),
});

const DeliveryInformationContainer = connect(
  mapState,
  mapDispatch
)(DeliveryInformation);
export default DeliveryInformationContainer;
