import React, { useState } from 'react';
import VisiblePasswordField from './VisiblePasswordField';
import SettingsFormContainer from './SettingsFormContainer';

const SettingsUserPassword = ({
  title,
  subtitle,
  icon,
  handleSave,
  infoUpdateLoader,
}) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [passWordMatch, setPassWordMatch] = useState(false);

  const handleSubmit = async () => {
    const success = await handleSave({
      old_password: oldPassword,
      password: newPassword,
    });
    if (success) {
      setIsEditing(false);
      setOldPassword('');
      setNewPassword('');
      setVerifyPassword('');
    }
  };
  const handleCancel = () => {
    setOldPassword('');
    setNewPassword('');
    setVerifyPassword('');
    setIsEditing(false);
  };

  const checkVerifyPassword = (newPassword, verifyPassword) => {
    if (newPassword === verifyPassword) {
      setIsEditing(true);
      setPassWordMatch(false);
    } else {
      setIsEditing(false);
      setPassWordMatch(true);
    }
  };

  return (
    <SettingsFormContainer
      id='password'
      title={title}
      description={subtitle}
      icon={icon}
      hasData={true}
      infoLoader={false}
      infoUpdateLoader={infoUpdateLoader}
      isInfoChanged={isEditing}
      handleSave={handleSubmit}
      handleCancel={handleCancel}
    >
      <div className='max-w-xl space-y-4'>
        <VisiblePasswordField
          label='Old Password'
          type='password'
          name='old_password'
          value={oldPassword}
          handleChange={(e) => setOldPassword(e.target.value)}
          placeHolder='Provide Old Password to Change the Password...'
        />
        <VisiblePasswordField
          label='New password'
          type='password'
          name='password'
          value={newPassword}
          handleChange={(e) => {
            setNewPassword(e.target.value);
            checkVerifyPassword(e.target.value, verifyPassword);
          }}
          placeHolder='Provide New Password...'
        />
        <VisiblePasswordField
          label='Confirm Password'
          type='password'
          name='verify-new-password'
          value={verifyPassword}
          handleChange={(e) => {
            setVerifyPassword(e.target.value);
            checkVerifyPassword(newPassword, e.target.value);
          }}
          placeHolder='Provide New Password to Change the Password...'
          passWordMatch={passWordMatch}
        />
      </div>
    </SettingsFormContainer>
  );
};
export default SettingsUserPassword;
