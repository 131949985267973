import { FC } from 'react';
import {
  Select,
  usePolicy,
  SelectValue,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectContent,
} from '../../export';

interface Props {
  target: string;
  handleTargetUpdate: (target: string) => void;
}

const TargetSelection: FC<Props> = ({ target, handleTargetUpdate }) => {
  const { inputFieldClass } = usePolicy();
  return (
    <div>
      <Select
        value={target}
        onValueChange={(value: string) => handleTargetUpdate(value)}
      >
        <SelectTrigger className={`${inputFieldClass} h-8 w-52 mr-2 subtitle-regular  focus:border-primary active:border-primary focus-visible:outline-none `}>
          <SelectValue
            // defaultValue={target}
            placeholder='Select target'
          >
            {target === 'resolution_time'
              ? 'Resolution time'
              : target === 'first_response_time'
              ? 'First response time'
              : ''}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className='mt-1 bg-white'>
          <SelectGroup className='text-sm text-textPrimary'>
            <SelectItem
              value='first_response_time'
              className='hover:bg-background-hover'
            >
              First response time
            </SelectItem>
            <SelectItem
              value='resolution_time'
              className='hover:bg-background-hover'
            >
              Resolution time
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default TargetSelection;
