import { IEcommerceProductData } from 'pages/inbox/inboxInterface';
import React from 'react';

interface Props {
  product: IEcommerceProductData;
  currencySymbol: string;
  isSummary?: boolean;
}

const ProductCard: React.FC<Props> = ({
  product,
  currencySymbol,
  isSummary = true,
}) => {
  const renderProductInfoView = () => {
    return (
      <div className='w-[63%] flex flex-col justify-center'>
        <div className='text-blue-500 text-sm font-medium w-full truncate'>
          {product?.product_name}
        </div>
        <div className='flex text-gray-500 text-xs flex flex-wrap'>
          <span className='mr-1'>Qt:{product?.quantity} </span>
          {product?.variant_attributes?.map((attribute, index) => {
            return (
              <span className='mr-1 max-w-40 truncate text-xs' key={index}>
                | {attribute?.option}{' '}
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const renderProductImageView = () => {
    return (
      <div className='w-[17%]'>
        <img
          src={product?.product_images[0]}
          className='w-[32px] h-[32px] border border-gray-100 rounded-md object-cover'
          alt='product-pic'
        />
      </div>
    );
  };

  const renderTotalPriceView = () => {
    return (
      <div className='w-[20%] flex justify-end items-start'>
        <p className='text-gray-900'>
          {currencySymbol}
          {product?.total_cost}
        </p>
      </div>
    );
  };

  return (
    <div className='mb-1'>
      <div
        className={`flex pb-4 ${
          !isSummary ? 'border-b border-gray-200' : ''
        }  w-full gap-2`}
      >
        {renderProductImageView()}
        {renderProductInfoView()}
        {renderTotalPriceView()}
      </div>
    </div>
  );
};

export default ProductCard;
