import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import {
  Badge,
  Button,
  Checkbox,
  ChevronDown,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  getHookFormRules,
  useFormContext,
  useState,
} from '../../export';
import React from 'react';

export const MultiSelect: React.FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();

  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const hookFormValue = form.getValues(currentFieldPath);
  const [currentFieldValue, setCurrentFieldValue] = useState(
    Array.isArray(hookFormValue) ? hookFormValue : []
  );
  const [open, setOpen] = React.useState(false);

  const handleOnSelect = (currentOption: any) => {
    let selectedOptions: string[] = currentFieldValue;
    if (currentFieldValue.includes(currentOption.name)) {
      selectedOptions = selectedOptions.filter((v) => v !== currentOption.name);
    } else {
      selectedOptions.push(currentOption.name);
    }
    if (selectedOptions.length) {
      form.clearErrors(currentFieldPath);
    } else {
      form.trigger(currentFieldPath, { shouldFocus: true });
    }
    setCurrentFieldValue(selectedOptions);
    form.setValue(currentFieldPath, selectedOptions);
  };

  const renderSelectedItems = () => {
    if (!currentFieldValue?.length) {
      return data.placeholder || 'Select Options';
    }
    const tooltipView = (
      <div className='max-w-300px] flex w-fit flex-col flex-wrap gap-1 bg-slate-800 p-2 text-white'>
        {currentFieldValue?.map((v, idx) => (
          <p key={idx + v}> {v} </p>
        ))}
      </div>
    );

    return (
      <TooltipProviderCustomised asChild content={tooltipView}>
        <div className='px-2 border-0 rounded-md shadow-none bg-zinc-100 text-zinc-900 hover:bg-zinc-100'>
          + {currentFieldValue?.length} Selected
        </div>
      </TooltipProviderCustomised>
    );
  };

  if (formActionType === 'VIEW') {
    return (
      <div className='flex flex-col gap-3 p-3 border border-gray-200 rounded-md'>
        <p className='text-xs text-zinc-500'>
          {data?.label_agent || data.name}
        </p>
        <div className='flex flex-wrap gap-2'>
          {currentFieldValue ? (
            currentFieldValue?.map((v: string, i: number) => {
              return (
                <Badge
                  className='text-sm font-medium bg-gray-100 shadow-none hover:bg-gray-100'
                  key={v + i}
                >
                  {v}
                </Badge>
              );
            })
          ) : (
            <span className='text-sm text-zinc-500'>(Empty)</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <FormField
      control={form.control}
      disabled={data.is_readonly}
      name={valuePath + data.slug}
      rules={getHookFormRules(data)}
      render={() => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || data?.name}
            {data.is_required && <span className='text-destructive'>*</span>}
          </FormLabel>
          <FormControl>
            <Popover open={open} modal={true} onOpenChange={setOpen}>
              <PopoverTrigger asChild disabled={shouldDisableInput}>
                <Button
                  type='button'
                  variant='outline'
                  role='combobox'
                  aria-expanded={open}
                  className={`w-full justify-between font-normal ${
                    open ? 'border border-ring' : ''
                  }`}
                >
                  <div className='text-muted-foreground'>
                    {renderSelectedItems()}
                  </div>

                  <ChevronDown className='w-4 h-4 opacity-50 shrink-0' />
                </Button>
              </PopoverTrigger>
              <PopoverContent className=' bg-white p-0 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-[26rem]'>
                <Command>
                  <CommandInput
                    placeholder='Search...'
                    className='border-none h-9 focus:ring-0'
                  />
                  <CommandList className='max-h-[300px] min-w-[90vw] lg:min-w-form-width'>
                    <CommandEmpty>No Option found.</CommandEmpty>
                    <CommandGroup>
                      {data?.choices.map((option, index) => (
                        <CommandItem
                          key={index}
                          value={option.name}
                          onSelect={() => {
                            handleOnSelect(option);
                          }}
                          className='flex items-center gap-2'
                        >
                          <div className='flex items-center'>
                            <Checkbox
                              checked={currentFieldValue.includes(option.name)}
                              className={cn(
                                'data-[state=checked]:bg-[#04B25F] rounded-sm data-[state=checked]:text-white'
                              )}
                            />
                          </div>
                          {option.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default MultiSelect;
