import useTranslation from 'components/customHooks/useTranslation';
import CustomComboBox from 'components/elements/CustomComboBox';
import { SelectedTeamInterface } from 'index';
import { Input } from 'libraryV2/ui/input';
import { IZidAddress } from 'pages/inbox/inboxInterface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CountryWithPhoneCode } from 'utilities/utils';
import CityListDropDown from './CityListDropDown';
import CountryListDropdown from './CountryListDropdown';

interface CountryDetailsProps {
  id: number;
  name: string;
  code: string;
  mobile_country_code: string;
  capital: string;
}

interface CityDetailsProps {
  id: number;
  name: string;
}

interface Props {
  key: string;
  isBilling: boolean;
  billingInformation: IZidAddress;
  shippingInformation: IZidAddress;
  selectedTeam: SelectedTeamInterface;
  handleSetZidObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const OrderForm: React.FC<Props> = ({
  key,
  isBilling,
  selectedTeam,
  billingInformation,
  shippingInformation,
  handleSetZidObjectInformation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState<CityDetailsProps[]>([]);
  const [countryList, setCountryList] = useState<CountryDetailsProps[]>([]);
  const [cityApiLoading, setCityApiLoading] = useState(false);
  const [isRecieverInfoSameAsCustomer, setIsRecieverInfoSameAsCustomer] =
    useState(false);
  const [countryApiLoading, setCountryApiLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] =
    useState<CountryDetailsProps | null>(null);
  const currentState = isBilling ? billingInformation : shippingInformation;

  const { full_name, email, reciever, mobile_country_code, mobile_number } =
    currentState;
  const selectedAliceStoreId = useSelector(
    (state: any) => state.ecommerce.selectedAliceStoreId
  );

  const updateCityList = async () => {
    if (!selectedCountry || !selectedCountry.hasOwnProperty('id')) return;
    setCityApiLoading(true);
    const res = await dispatch.ecommerce.fetchEcommerceCityList({
      projectId: selectedTeam?.id,
      countryId: selectedCountry?.id,
      alice_store_id: selectedAliceStoreId,
    });
    if (!!res) {
      setCityList([...res]);
    }
    setCityApiLoading(false);
  };

  const updateCountryList = async () => {
    setCountryApiLoading(true);
    const res = await dispatch.ecommerce.fetchEcommerceCountryList({
      projectId: selectedTeam?.id,
      alice_store_id: selectedAliceStoreId,
    });
    if (!!res) {
      setCountryList([...res]);
    }
    if (!!currentState) {
      const countrySelected = res.filter(
        (country: CountryDetailsProps) =>
          country?.id === currentState?.country?.id
      );

      setSelectedCountry(
        !!countrySelected && countrySelected.length > 0
          ? countrySelected[0]
          : null
      );
    }
    setCountryApiLoading(false);
  };

  useEffect(() => {
    if (!!selectedCountry && selectedCountry.hasOwnProperty('id')) {
      setCityList([]);
      updateCityList();
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  useEffect(() => {
    if (isRecieverInfoSameAsCustomer) {
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
        childObjectKeyName: 'reciever_full_name',
        value: full_name,
      });
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
        childObjectKeyName: 'reciever_email',
        value: email,
      });
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
        childObjectKeyName: 'reciever_mobile_country_code',
        value: mobile_country_code,
      });
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
        childObjectKeyName: 'reciever_mobile_number',
        value: mobile_number,
      });
    }
    //eslint-disable-next-line
  }, [
    email,
    full_name,
    mobile_number,
    mobile_country_code,
    isRecieverInfoSameAsCustomer,
  ]);

  useEffect(() => {
    updateCountryList();
    //eslint-disable-next-line
  }, []);

  const renderInputValuesView = (
    inputName: string,
    labelName: string,
    placeHolder: string
  ) => {
    return (
      <div key={inputName} className='flex flex-col w-full'>
        <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
          {t(labelName)}
          <span className='text-red-500'>*</span>
        </label>
        <Input
          type={
            inputName.endsWith('mobile_number') ||
            inputName.endsWith('mobile_country_code')
              ? 'number'
              : 'text'
          }
          className=' focus:border-green-500 focus:ring-1 focus:ring-green-500 mb-2'
          placeholder={t(placeHolder)}
          name={inputName}
          value={
            inputName.startsWith('reciever_') && !!reciever
              ? //@ts-ignore
                reciever[inputName.replace('reciever_', '')]
              : //@ts-ignore
                currentState[inputName]
          }
          onChange={(e) => {
            handleSetZidObjectInformation({
              objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
              childObjectKeyName: e.target.name,
              value: e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const handleCountryChange = (value: any) => {
    if (!!value) {
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address', // need to modify if billing address added in future
        childObjectKeyName: 'country',
        value,
      });
      setSelectedCountry(value);
      handleSetZidObjectInformation({
        objectKeyName: isBilling ? 'billing_address' : 'shipping_address', // need to modify if billing address added in future
        childObjectKeyName: 'city',
        value: '',
      });
    }
  };

  const renderAddressFieldsView = () => {
    return (
      <div className='mb-3'>
        <CountryListDropdown
          loading={countryApiLoading}
          selected={selectedCountry}
          options={countryList}
          handleChange={(value) => {
            handleCountryChange(value);
          }}
        />

        {/* City Dropdown */}
        <div className='w-full my-2'>
          <CityListDropDown
            loading={cityApiLoading}
            disable={!selectedCountry}
            placeholder='Search Your City'
            selected={currentState?.city}
            options={cityList}
            handleChange={(value) => {
              handleSetZidObjectInformation({
                objectKeyName: isBilling
                  ? 'billing_address'
                  : 'shipping_address', // need to modify if billing address added in future
                childObjectKeyName: 'city',
                value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div key={key}>
      <p className=' capitalize text-gray-900 text-sm font-medium pb-2'>
        {isBilling ? '' : t('Shipping Information')}
      </p>

      <div className=' rounded-md border border-gray-200 p-2 relative'>
        {renderInputValuesView('full_name', 'First name', 'ex: John')}

        {renderInputValuesView('email', 'Email', 'ex: john@email.com')}

        <div className='w-full'>
          <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
            {t('Country Code')}
            <span className='text-red-500'>*</span>
          </label>
          <CustomComboBox
            value={currentState['mobile_country_code']}
            items={CountryWithPhoneCode}
            titleIndex='title'
            valueIndex='code'
            className='py-2 my-2'
            onChange={(value: any) => {
              handleSetZidObjectInformation({
                objectKeyName: isBilling
                  ? 'billing_address'
                  : 'shipping_address',
                childObjectKeyName: 'mobile_country_code',
                value,
              });
            }}
          />
        </div>

        {renderInputValuesView(
          'mobile_number',
          'Phone Number',
          'Phone number without country code'
        )}
      </div>

      <p className='py-2 capitalize text-gray-600 text-sm font-medium'>
        {t(isBilling ? 'Billing' : 'Shipping')} {t('Address')}
      </p>
      <div className=' rounded-md border border-gray-200 p-2 relative'>
        {renderAddressFieldsView()}

        {renderInputValuesView('address_one', 'Address', 'Enter your address')}
      </div>

      <div className=' py-2 rounded-md flex justify-between items-center'>
        <p className=' capitalize text-gray-900 text-sm font-medium'>
          {t('Reciever Information')}
          <span className='text-sm font-medium text-red-500'>*</span>
        </p>
        <div className='flex items-center justify-end'>
          <input
            id='checkbox-1'
            type='checkbox'
            checked={isRecieverInfoSameAsCustomer}
            onChange={(event) => {
              setIsRecieverInfoSameAsCustomer(event.target.checked);
            }}
            className='w-4 h-4 mr-2 border-gray-300 rounded outline-none focus:ring-0 ring-offset-0 text-primary'
          />
          <span className='text-sm font-medium text-gray-600'>
            {t('Same as shipping')}
          </span>
        </div>
      </div>
      {!isRecieverInfoSameAsCustomer && (
        <div className=' rounded-md border border-gray-200 p-2 relative'>
          {renderInputValuesView(
            'reciever_full_name',
            'Name',
            'Enter your reciever name'
          )}
          {renderInputValuesView(
            'reciever_email',
            'Email',
            'Enter reciever email address'
          )}
          <div className='w-full'>
            <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
              {t('Country Code')}
              <span className='text-red-500'>*</span>
            </label>
            <CustomComboBox
              value={reciever['mobile_country_code']}
              items={CountryWithPhoneCode}
              titleIndex='title'
              valueIndex='code'
              className='py-2 my-2'
              onChange={(value: string) => {
                handleSetZidObjectInformation({
                  objectKeyName: isBilling
                    ? 'billing_address'
                    : 'shipping_address',
                  childObjectKeyName: 'reciever_mobile_country_code',
                  value,
                });
              }}
            />
          </div>
          {renderInputValuesView(
            'reciever_mobile_number',
            'Mobile Number',
            'Enter mobile number'
          )}
        </div>
      )}
    </div>
  );
};

export default OrderForm;
