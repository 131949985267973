import React from 'react';
// @ts-ignore
import { BlocksData } from '../../../../utilities/content';

interface Props {
  id: number;
  value: string;
  operation: string;
  handleActionOnChange: (actionName: string, index: number) => void;
  handleValueOnChange: (value: string, index: number) => void;
}

const AttributeValueField: React.FC<Props> = ({
  id,
  value,
  operation,
  handleActionOnChange,
  handleValueOnChange,
}) => {
  return (
    <>
      <div className='relative block w-full pl-3 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'>
        <div className='absolute inset-y-0 flex items-center left-3'>
          <select
            value={operation}
            className='absolute h-full py-0 pl-3 text-gray-500 border-gray-300 focus:ring-primary focus:border-primary pr-7 bg-gray-50 sm:text-sm rounded-l-md'
            onChange={(event) => handleActionOnChange(event.target.value, id)}
          >
            {BlocksData.setAttributeBlock.operation.map(
              (elem: string, index) => (
                <option key={index} value={elem}>
                  {elem.toLocaleUpperCase()}
                </option>
              )
            )}
          </select>
        </div>
        <input
          disabled={operation === 'clear'}
          type='text'
          className={`focus:ring-primary focus:border-primary w-full block pl-32 border-gray-300 rounded-md ${
            operation === 'clear' ? 'bg-gray-50' : ''
          }`}
          placeholder={
            operation !== 'clear' ? 'Provide an attribute value' : ''
          }
          value={value}
          onChange={(event) => handleValueOnChange(event.target.value, id)}
        />
      </div>
    </>
  );
};

export default AttributeValueField;
