interface Props {
  className?: string;
}

export const AvatarIcon = () => {
  return (
    <svg
      width='10'
      height='11'
      className='inline'
      viewBox='0 0 10 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.99998 4.3001C6.15977 4.3001 7.09998 3.3599 7.09998 2.2001C7.09998 1.0403 6.15977 0.100098 4.99998 0.100098C3.84018 0.100098 2.89998 1.0403 2.89998 2.2001C2.89998 3.3599 3.84018 4.3001 4.99998 4.3001Z'
        fill='#6B7280'
      />
      <path
        d='M0.0999756 10.6001C0.0999756 7.8939 2.29378 5.7001 4.99998 5.7001C7.70617 5.7001 9.89998 7.8939 9.89998 10.6001H0.0999756Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const AssignAgentIcon = () => {
  return (
    <svg
      width='12'
      height='10'
      viewBox='0 0 12 10'
      fill='none'
      className='inline'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.75 0.916748V2.08341M7.75 4.41675V5.58342M7.75 7.91675V9.08342M1.91667 0.916748C1.27233 0.916748 0.75 1.43908 0.75 2.08341V3.83341C1.39433 3.83341 1.91667 4.35575 1.91667 5.00008C1.91667 5.64441 1.39433 6.16675 0.75 6.16675V7.91675C0.75 8.56108 1.27233 9.08342 1.91667 9.08342H10.0833C10.7277 9.08342 11.25 8.56108 11.25 7.91675V6.16675C10.6057 6.16675 10.0833 5.64441 10.0833 5.00008C10.0833 4.35575 10.6057 3.83341 11.25 3.83341V2.08341C11.25 1.43908 10.7277 0.916748 10.0833 0.916748H1.91667Z'
        stroke='#6B7280'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const EmptyState: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      width='100'
      height='54'
      className={className}
      viewBox='0 0 100 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M95.8084 1.25146C98.1233 1.25146 100 3.12812 100 5.44308C100 7.75805 98.1233 9.6347 95.8084 9.6347H71.8563C74.1712 9.6347 76.0479 11.5113 76.0479 13.8263C76.0479 16.1413 74.1712 18.0179 71.8563 18.0179H85.0299C87.3449 18.0179 89.2216 19.8946 89.2216 22.2095C89.2216 24.5245 87.3449 26.4012 85.0299 26.4012H78.9378C76.0189 26.4012 73.6527 28.2778 73.6527 30.5928C73.6527 32.1361 74.8503 33.5333 77.2455 34.7844C79.5605 34.7844 81.4371 36.661 81.4371 38.976C81.4371 41.291 79.5605 43.1676 77.2455 43.1676H27.5449C25.2299 43.1676 23.3533 41.291 23.3533 38.976C23.3533 36.661 25.2299 34.7844 27.5449 34.7844H4.19162C1.87665 34.7844 0 32.9077 0 30.5928C0 28.2778 1.87665 26.4012 4.19162 26.4012H28.1437C30.4587 26.4012 32.3353 24.5245 32.3353 22.2095C32.3353 19.8946 30.4587 18.0179 28.1437 18.0179H13.1737C10.8587 18.0179 8.98204 16.1413 8.98204 13.8263C8.98204 11.5113 10.8587 9.6347 13.1737 9.6347H37.1257C34.8108 9.6347 32.9341 7.75805 32.9341 5.44308C32.9341 3.12812 34.8108 1.25146 37.1257 1.25146H95.8084ZM95.8084 18.0179C98.1233 18.0179 100 19.8946 100 22.2095C100 24.5245 98.1233 26.4012 95.8084 26.4012C93.4934 26.4012 91.6168 24.5245 91.6168 22.2095C91.6168 19.8946 93.4934 18.0179 95.8084 18.0179Z'
        fill='#F3F4F6'
      />
      <path
        d='M44.012 41.9699C55.0908 41.9699 64.0719 32.9887 64.0719 21.91C64.0719 10.8312 55.0908 1.8501 44.012 1.8501C32.9333 1.8501 23.9521 10.8312 23.9521 21.91C23.9521 32.9887 32.9333 41.9699 44.012 41.9699Z'
        fill='white'
        stroke='#9CA3AF'
        strokeWidth='2.5'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M40.7977 37.4752C41.8509 37.6696 42.9223 37.7706 44.0121 37.7783C52.7759 37.7783 59.8803 30.6738 59.8803 21.91C59.8803 13.1462 52.7759 6.04175 44.0121 6.04175C41.7608 6.04175 39.619 6.51057 37.679 7.3559C34.3078 8.8249 31.5459 11.4309 29.8779 14.6896C28.7693 16.8555 28.1438 19.3097 28.1438 21.91C28.1438 24.2693 28.6587 26.5083 29.5822 28.5209C30.2415 29.9577 31.1092 31.279 32.1464 32.4463'
        fill='white'
      />
      <path
        d='M40.7977 37.4752C41.8509 37.6696 42.9223 37.7706 44.0121 37.7783C52.7759 37.7783 59.8803 30.6738 59.8803 21.91C59.8803 13.1462 52.7759 6.04175 44.0121 6.04175C41.7608 6.04175 39.619 6.51057 37.679 7.3559C34.3078 8.8249 31.5459 11.4309 29.8779 14.6896C28.7693 16.8555 28.1438 19.3097 28.1438 21.91C28.1438 24.2693 28.6587 26.5083 29.5822 28.5209C30.2415 29.9577 31.1092 31.279 32.1464 32.4463'
        stroke='#9CA3AF'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M34.0105 34.2302C35.2968 35.2757 36.75 36.1238 38.3239 36.7284'
        stroke='#9CA3AF'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M60.4791 37.7783L64.0719 41.3711'
        stroke='#9CA3AF'
        strokeWidth='2.5'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M63.4913 40.7905C62.3579 41.9238 62.3579 43.7614 63.4913 44.8947L70.1292 51.5326C71.2625 52.666 73.1001 52.666 74.2334 51.5326C75.3668 50.3993 75.3668 48.5617 74.2334 47.4284L67.5955 40.7905C66.4622 39.6571 64.6246 39.6571 63.4913 40.7905Z'
        fill='white'
        stroke='#9CA3AF'
        strokeWidth='2.5'
      />
      <path
        d='M66.4672 41.97L73.054 48.5568'
        stroke='white'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M40.1198 15.0239C40.1198 21.9688 45.7498 27.5987 52.6947 27.5987C54.0589 27.5987 55.3725 27.3815 56.6026 26.9797C54.5947 31.9622 49.7141 35.4794 44.012 35.4794C36.5179 35.4794 30.4427 29.4042 30.4427 21.9101C30.4427 15.1194 35.431 9.49379 41.9432 8.49756C40.7862 10.4007 40.1198 12.6345 40.1198 15.0239Z'
        fill='white'
      />
      <path
        d='M44.3114 10.832C43.5489 10.832 42.804 10.907 42.0835 11.0501M39.9071 11.7159C35.8108 13.4373 32.9342 17.4874 32.9342 22.2093'
        stroke='#D1D5DB'
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M76.1532 22.076H71.2575M79.9402 17.4189H69.6553H79.9402ZM84.1318 17.4189H82.8017H84.1318Z'
        stroke='#D1D5DB'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.261 35.2498H17.3653M19.4611 29.9939H9.17623H19.4611ZM5.68867 29.9939H3.16101H5.68867Z'
        stroke='#D1D5DB'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
