/**
 * Created by shuvo on 13/8/21.
 */
import React from 'react';
import dayJs from 'dayjs';
import { Link } from '../../../../../library';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  histories: Array<any>;
}

const BillingHistory: React.FC<Props> = ({ histories }) => {
  const { t } = useTranslation();
  return (
    <section aria-labelledby='billing-history-heading'>
      <div className='pt-6 bg-white shadow sm:rounded-md sm:overflow-hidden'>
        <div className='px-4 sm:px-6'>
          <h2
            id='billing-history-heading'
            className='text-lg font-medium leading-6 text-gray-900'
          >
            {t('Billing history')}
          </h2>
          <p className='mt-1 text-sm text-gray-500'>
            {t(`Click on the view receipt button if you want to download your
            receipt. If you want any refund,`)}{' '}
            <Link
              url='https://airtable.com/shrvMCwEUGQU7TvRR'
              openInNewTab={true}
            >
              {t('contact us.')}
            </Link>
          </p>
        </div>
        <div className='flex flex-col mt-6'>
          <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <div className='overflow-hidden border-t border-gray-200'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Description')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Period')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('MAU Usage')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Amount')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Card')}
                      </th>

                      {/*
                                         `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                         */}
                      <th
                        scope='col'
                        className='relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        <span className='sr-only'>{t('View receipt')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {histories.map((payment) => (
                      <tr key={payment.id}>
                        <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
                          {payment.main_package?.name}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          {dayJs(
                            parseInt(payment.billing_start_date) * 1000
                          ).format('DD MMM, YYYY')}{' '}
                          -{' '}
                          {dayJs(
                            parseInt(payment.billing_end_date) * 1000
                          ).format('DD MMM, YYYY')}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          {payment.MAU_usage}
                        </td>

                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          ${payment.billing_amount}
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                          **** {payment.card_id}
                        </td>
                        <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                          <Link
                            url={payment.stripe_invoice_url}
                            openInNewTab={true}
                            children='View receipt'
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BillingHistory;
