/* eslint-disable no-useless-escape */
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Input,
  useFormContext,
} from '../../export';

export default function UrlField({
  data,
  formActionType,
  valuePath,
}: IFormFieldComponent): JSX.Element {
  const form = useFormContext();
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const { name: label, placeholder, is_required: required } = data;

  const emailFieldRules = {
    required: {
      value: !!data.is_required,
      message: `${data?.name} is required.`,
    },
    pattern: {
      value:
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,6}|:[0-9]{3,4})\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/,
      message: 'Invalid URL.',
    },
  };

  const isFormViewMode = formActionType === 'VIEW';

  const renderUrlField = () => (
    <FormField
      control={form.control}
      name={currentFieldPath}
      disabled={data.is_readonly}
      rules={emailFieldRules}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || label}
            {required && <span className='text-red-500'>*</span>}
          </FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              {...field}
              className='focus:border-ring focus-visible:ring-0'
            />
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderUrlFieldView = () => {
    const value = form.getValues(currentFieldPath);
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'> {data?.label_agent || label} </p>
        <p className='text-zinc-900 text-sm'>
          {value ? (
            <a
              target='_blank'
              href={value}
              rel='nooepner noreferrer'
              className='text-blue-600'
            >
              {value}
            </a>
          ) : (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  return isFormViewMode ? renderUrlFieldView() : renderUrlField();
}
