import { Switch } from '@headlessui/react';
import { useState } from 'react';
interface Props {
  disabled?: boolean;
  isEnabled: boolean;
  classNames?: string;
  onChange: (value: boolean) => void;
  isLoading?: boolean;
}

function classNamesConcat(
  ...classes: (string | undefined | false | null)[]
): string {
  return classes.filter(Boolean).join(' ');
}

const SwitchComponent: React.FC<Props> = ({
  isEnabled,
  onChange,
  disabled = false,
  classNames = '',
  isLoading = false,
}) => {
  const [isChecked, setIsChecked] = useState(isEnabled);

  const handleChange = (checked: boolean) => {
    setIsChecked(checked);
    onChange(checked);
  };

  return (
    <Switch
      disabled={disabled}
      checked={isChecked}
      onChange={handleChange}
      className={classNamesConcat(
        isChecked && !disabled ? 'bg-primary' : 'bg-gray-200',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out without-ring ',
        isLoading ? 'cursor-not-allowed' : '',
        classNames
      )}
    >
      <span className='sr-only'>Use setting</span>
      <span
        className={classNamesConcat(
          isChecked
            ? 'translate-x-5 rtl:-left-1/2'
            : 'translate-x-0 rtl:right-1/2',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          className={classNamesConcat(
            isChecked
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden='true'
        >
          <svg
            className='w-3 h-3 text-gray-400'
            fill='none'
            viewBox='0 0 12 12'
          >
            <path
              d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
              stroke='currentColor'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
        <span
          className={classNamesConcat(
            isChecked
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden='true'
        >
          <svg
            className={classNamesConcat(
              'w-3 h-3 ',
              disabled ? 'text-gray-400' : 'text-primary'
            )}
            fill='currentColor'
            viewBox='0 0 12 12'
          >
            <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
          </svg>
        </span>
      </span>
    </Switch>
  );
};

export default SwitchComponent;
