import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  title: string;
  subTitle: string;
}

const Header: React.FC<Props> = ({ title, subTitle }) => {
  const { t } = useTranslation();
  return (
    <div className='items-center mx-2 mb-3 text-center sm:mx-auto sm:w-full sm:max-w-lg'>
      <h2 className='text-2xl font-extrabold text-gray-900'>{t(title)}</h2>
      <p className='text-gray-600 text-md'>{t(subTitle)}</p>
    </div>
  );
};
export default Header;
