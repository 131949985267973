import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import {
  classNames,
  getCssForReadAccessOnly,
} from '../../../../utilities/utils';
import formIcon from '../assets/images/form-icon.svg';
import inventoryCreateIcon from '../assets/images/inventory-create-icon.svg';

const DataLabCard = ({
  key,
  type,
  title,
  keyCount,
  subTitle,
  createdAt,
  entryCount,
  clickCount,
  handleKeyEdit,
  handleInfoEdit,
  hasWriteAccess,
  handleDeleteLab,
  selectedProject,
  hasDeleteAccess,
  handleClickAction,
  dataReadAccessOnly,
}) => {
  const { t } = useTranslation();
  return (
    <li
      key={key}
      className='col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'
    >
      <div className='flex items-center justify-between w-full p-6 ltr:space-x-6'>
        <img
          onClick={handleClickAction}
          className='flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full cursor-pointer'
          src={type === 'form' ? formIcon : inventoryCreateIcon}
          alt='icon'
        />
        <div
          onClick={handleClickAction}
          className='flex-1 truncate cursor-pointer rtl:mr-3'
        >
          <div className='flex items-center ltr:space-x-3'>
            <h3 className='text-sm font-medium text-gray-900 truncate'>
              {title.length === 0 ? t('No Title Available') : title}
            </h3>
          </div>
          <p className='mt-1 text-xs font-medium text-gray-500 truncate'>
            {subTitle.length === 0 ? t('No Description Provided') : subTitle}
          </p>
        </div>
        <Menu
          as='div'
          className='relative inline-block ltr:text-left rtl:text-right'
        >
          {({ open }) => (
            <>
              <div>
                <Menu.Button className='flex items-center p-2 -m-2 text-gray-400 rounded-full hover:text-gray-600'>
                  <span className='sr-only'>{t('Open options')}</span>
                  <EllipsisVerticalIcon
                    className='w-5 h-5'
                    aria-hidden='true'
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items
                  static
                  className='absolute ltr:right-0 rtl:left-0 z-10 w-56 mt-2 ltr:origin-top-right rtl:origin-top-left bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                >
                  <div className='py-1'>
                    <Menu.Item disabled={dataReadAccessOnly}>
                      {({ active }) => (
                        <span
                          onClick={handleInfoEdit}
                          className={classNames(
                            getCssForReadAccessOnly(active, dataReadAccessOnly),
                            'flex px-4 py-2 text-sm'
                          )}
                        >
                          <PencilSquareIcon
                            className={classNames(
                              'w-5 h-5 ltr:mr-3 rtl:ml-3 group-hover:text-gray-500',
                              dataReadAccessOnly
                                ? 'text-gray-200 cursor-not-allowed'
                                : 'text-gray-400'
                            )}
                            aria-hidden='true'
                          />
                          {t('Edit Info')}
                        </span>
                      )}
                    </Menu.Item>
                    <Menu.Item disabled={!hasWriteAccess}>
                      {({ active }) => (
                        <span
                          onClick={handleKeyEdit}
                          className={classNames(
                            getCssForReadAccessOnly(active, !hasWriteAccess),
                            'flex px-4 py-2 text-sm'
                          )}
                        >
                          <PencilSquareIcon
                            className={classNames(
                              'w-5 h-5 ltr:mr-3 rtl:ml-3 group-hover:text-gray-500',
                              dataReadAccessOnly
                                ? 'text-gray-200 cursor-not-allowed'
                                : 'text-gray-400'
                            )}
                            aria-hidden='true'
                          />
                          {t('Edit Key')}
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                  <div className='py-1'>
                    <Menu.Item disabled={!hasDeleteAccess}>
                      {({ active }) => (
                        <span
                          onClick={handleDeleteLab}
                          className={classNames(
                            getCssForReadAccessOnly(active, !hasDeleteAccess),
                            'flex px-4 py-2 text-sm'
                          )}
                        >
                          <TrashIcon
                            className={classNames(
                              'w-5 h-5 ltr:mr-3 rtl:ml-3 group-hover:text-gray-500',
                              dataReadAccessOnly
                                ? 'text-gray-200 cursor-not-allowed'
                                : 'text-gray-400'
                            )}
                            aria-hidden='true'
                          />
                          {t('Delete')}
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </li>
  );
};
DataLabCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleKeyEdit: PropTypes.func,
  handleInfoEdit: PropTypes.func,
  handleDeleteLab: PropTypes.func,
  handleClickAction: PropTypes.func,
  keyCount: PropTypes.number,
  entryCount: PropTypes.number,
  clickCount: PropTypes.number,
  hasWriteAccess: PropTypes.bool,
  selectedProject: PropTypes.number,
  dataReadAccessOnly: PropTypes.bool,
};

DataLabCard.defaultProps = {
  keyCount: null,
  entryCount: null,
  clickCount: null,
  hasWriteAccess: false,
  dataReadAccessOnly: false,
};

export default DataLabCard;
