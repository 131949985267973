import Link from '../../../library/link';

export const getModalCustomFooter = (
  description: string,
  link: string,
  linkTitle: string
) => {
  return (
    <div className='flex rtl:justify-start ltr:justify-end mt-2'>
      <label
        htmlFor='remember_me'
        className='block ltr:ml-2 rtl:mr-2 rtl:text-right text-sm text-gray-900'
      >
        {description}{' '}
        <Link url={link} openInNewTab={true}>
          {linkTitle}
        </Link>
      </label>
    </div>
  );
};
