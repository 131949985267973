import TextWithTooltip from 'library/text';
import React from 'react';
import ReactTooltip from 'react-tooltip';

interface Prop {
  tags: TicketTagInterface[];
}

const Tag: React.FC<Prop> = ({ tags }) => {
  const getVisibleTags = () => {
    return tags
      ?.slice(0, 2)
      .map((ticketTag: TicketTagInterface, index: number) => (
        <div key={index} className='flex'>
          <div
            data-testid='tag'
            className='px-1 py-0.5 bg-gray-100 text-sm 
            font-medium text-gray-800 content-center rounded w-20'
          >
            {ticketTag?.name.length > 12 ? (
              <TextWithTooltip
                dataFor={`ticket-card-${index}`}
                dataTip={ticketTag?.name}
                text={ticketTag?.name}
                toolTipPosition='right'
                className='text-sm
            font-medium text-gray-800 content-center'
              />
            ) : (
              ticketTag?.name
            )}
          </div>
        </div>
      ));
  };

  const getRemainingTags = () => {
    let remainingTags = '';
    tags?.slice(2).forEach((tag: TicketTagInterface) => {
      remainingTags += tag.name + '\n';
    });
    return remainingTags;
  };

  return (
    <>
      {!!tags && tags?.length > 0 && getVisibleTags()}
      {!!tags && tags?.length > 2 && (
        <div
          data-for='tags-counter'
          data-tip={getRemainingTags()}
          className='px-1 py-0.5 bg-gray-100 text-sm cursor-default
          font-medium text-gray-800 content-center rounded'
        >
          {'+' + (tags?.length - 2)}
          <ReactTooltip
            id='tags-counter'
            place='bottom'
            type='dark'
            effect='solid'
            backgroundColor='#4B5563'
            multiline={true}
            className='max-w-[40%] sm:text-sm
            whitespace-pre-wrap break-words'
          />
        </div>
      )}
    </>
  );
};

export default Tag;
