import React from 'react';
import ECustomerListLoader from './ECustomerListLoader';
import NewAlertModal from '../../../../../library/modal/NewAlertModal';
import useEcommerceCustomer from '../../../hooks/useEcommerceCustomer';
import NotFoundView from '../../../../../components/utilityComponent/NotFoundView';

import { ArrowLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { EmailIcon } from 'pages/inbox/assets/iconComponent/EmailIcon';
import { PhoneIcon } from 'pages/inbox/assets/iconComponent/PhoneIcon';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CustomerInfoInterface,
  IEcommerceCustomer,
} from 'pages/inbox/inboxInterface';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';

interface Props {
  customerInfo: CustomerInfoInterface;
  selectedProject: SelectedTeamInterface;
  closeCustomerConnection: (value: boolean) => void;
  fetchEcommerceCustomerList: (teamId: number, searchQuery: string) => any;
}

const ECustomerConnection: React.FC<Props> = ({
  customerInfo,
  selectedProject,
  closeCustomerConnection,
  fetchEcommerceCustomerList,
}) => {
  const { t } = useTranslation();
  const {
    ecommerceData,
    handleCustomerClick,
    isConnectEcommerceCustomerLoading,
    isfetchEcommerceCustomerListLoading,
  } = useEcommerceCustomer();
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<null | IEcommerceCustomer>(null);

  const [inputQuery, setInputQuery] = React.useState('');
  const [customerList, setCustomerList] = React.useState(
    ecommerceData?.ecommerceCustomerList || []
  );

  const [isSearchingEcommerceCustomer, setIsSearchingEcommerceCustomer] =
    React.useState(false);

  const searchEcommerceCustomer = async () => {
    let filteredCustomer = filterEcommerceCustomer();

    if (filteredCustomer.length === 0) {
      let data = await fetchEcommerceCustomerList(
        selectedProject?.id,
        inputQuery
      );

      if (data?.success) {
        setCustomerList(data?.dataSource);
      }
    } else {
      setCustomerList([]);
    }
    setIsSearchingEcommerceCustomer(false);
  };

  const filterEcommerceCustomer = () => {
    let filteredCustomer = [];
    if (inputQuery.length !== 0) {
      filteredCustomer = ecommerceData?.ecommerceCustomerList?.filter(
        (ecustomers: IEcommerceCustomer) => {
          return (
            ecustomers?.full_name
              .toLowerCase()
              .includes(inputQuery.toLowerCase()) ||
            ecustomers?.ecommerce_account_email
              .toLowerCase()
              .includes(inputQuery.toLowerCase()) ||
            ecustomers?.ecommerce_account_phone
              .toLowerCase()
              .includes(inputQuery.toLowerCase())
          );
        }
      );
      return filteredCustomer;
    }
  };

  React.useEffect(() => {
    let debounceFunction: any;

    let filteredCustomer = [];
    setCustomerList(ecommerceData?.ecommerceCustomerList);

    if (inputQuery.length !== 0) {
      filteredCustomer = filterEcommerceCustomer();

      if (filteredCustomer?.length === 0) {
        setIsSearchingEcommerceCustomer(true);
      }
      setCustomerList(filteredCustomer);
    } else setCustomerList(ecommerceData?.ecommerceCustomerList);

    if (inputQuery.length !== 0 && filteredCustomer.length === 0) {
      setIsSearchingEcommerceCustomer(true);
      debounceFunction = setTimeout(() => {
        searchEcommerceCustomer();
      }, 400);
    }

    return () => clearTimeout(debounceFunction);

    //eslint-disable-next-line
  }, [inputQuery]);

  React.useEffect(() => {
    if (inputQuery.length === 0) {
      setCustomerList(ecommerceData?.ecommerceCustomerList);
    }
    //eslint-disable-next-line
  }, [ecommerceData]);

  const getHeaderElementView = () => {
    return (
      <div className='flex gap-3 items-center p-3 pb-1'>
        <ArrowLeftIcon
          className='h-6 w-6 rtl:rotate-180'
          onClick={() => closeCustomerConnection(false)}
        />
        <span className='text-base font-semibold text-gray-700'>
          {t('Connect Customer')}
        </span>
      </div>
    );
  };

  const getSearchBarView = () => {
    return (
      <div className='relative p-3'>
        <div className='absolute inset-y-0 ltr:left-0 rtl:right-4 flex items-center pl-6 pointer-events-none'>
          <MagnifyingGlassIcon
            className='w-5 h-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          autoComplete='off'
          autoFocus={false}
          onChange={(e) => setInputQuery(e.target.value)}
          id='customer_search'
          className='block w-full ltr:pl-10 rtl:pr-8 border-gray-300 rounded-md focus:border-green-300 focus:ring-green-300 sm:text-sm'
          placeholder={t('Search by name, email or phone')}
        />
      </div>
    );
  };

  const getCustomerInformationCardView = () => {
    return (
      <div className='overflow-auto h-[calc(100vh-46px)] scrollbar-hide pb-28'>
        {!!customerList &&
          customerList.length === 0 &&
          !isSearchingEcommerceCustomer &&
          inputQuery.length !== 0 && <NotFoundView searchString={inputQuery} />}
        <>
          {!!customerList &&
            customerList.length !== 0 &&
            customerList.map((customer: IEcommerceCustomer, index: number) => (
              <div
                key={index}
                onClick={() => setSelectedCustomer(customer)}
                className='p-4 border-b border-gray-100 hover:bg-gray-100'
              >
                <span className='text-sm font-medium text-gray-700'>
                  {customer?.full_name || 'Unknown User'}
                </span>
                <div className='flex gap-2 items-center mt-[1px]'>
                  <EmailIcon />
                  <span className='font-normal text-gray-500'>
                    {customer?.ecommerce_account_email}
                  </span>
                </div>
                {!!customer?.ecommerce_account_phone &&
                  customer.ecommerce_account_phone.length !== 0 && (
                    <div className='flex gap-2 items-center mt-1'>
                      <PhoneIcon />
                      <span className='font-normal text-gray-500'>
                        {customer?.ecommerce_account_phone}
                      </span>
                    </div>
                  )}
              </div>
            ))}
        </>
      </div>
    );
  };

  const getConfirmationModal = () => {
    return (
      <NewAlertModal
        intent='primary'
        title={t('Connect Customer?')}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={t('Connect')}
        isShown={!!selectedCustomer}
        onCancelComplete={() => setSelectedCustomer(null)}
        onConfirm={() => {
          handleCustomerClick(selectedCustomer);
          if (!isConnectEcommerceCustomerLoading) {
            setSelectedCustomer(null);
            closeCustomerConnection(false);
          }
        }}
        isConfirmationLoading={isConnectEcommerceCustomerLoading}
        description={t(
          `You're about to connect {{${
            "<span class='text-gray-500 text-sm font-semibold'>" +
            (customerInfo?.full_name || 'Myalice Customer') +
            '</span>'
          }}} to {{${
            "<span class='text-gray-500 text-sm font-semibold'>" +
            (selectedCustomer?.full_name || 'Ecommerce Customer') +
            '</span>'
          }}}. Do you want to continue?`
        )}
      />
    );
  };
  return (
    <div className='absolute top-0 right-0 w-full h-full bg-white'>
      {getHeaderElementView()}
      {getSearchBarView()}
      {isfetchEcommerceCustomerListLoading && <ECustomerListLoader />}
      {!isfetchEcommerceCustomerListLoading && getCustomerInformationCardView()}
      {getConfirmationModal()}
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchEcommerceCustomerList: (teamId: number, searchQuery: string) =>
    dispatch.ecommerce.fetchEcommerceCustomerList({
      projectId: teamId,
      searchQuery: searchQuery,
    }),
});

export default connect(mapState, mapDispatch)(ECustomerConnection);
