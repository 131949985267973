import React from 'react';

interface Prop {
  shopifyDiscountAmount: string;
  shopifyDiscountType: string;
  handleChange: (key: string, value: string) => void;
}

const Discounts: React.FC<Prop> = ({
  shopifyDiscountAmount,
  shopifyDiscountType,
  handleChange,
}) => {
  let discountTypes = ['PERCENTAGE', 'FIXED_AMOUNT'];
  return (
    <div className='px-6 pt-4 pb-6 mt-0 border-b'>
      <label
        htmlFor='country'
        className='block text-sm font-medium text-gray-700'
      >
        Discounts
      </label>
      <div className='flex'>
        <div className='flex-1'>
          <select
            name='coupons'
            value={shopifyDiscountType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange('shopifyDiscountType', e.target.value)
            }
            className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
          >
            <option value=''>Choose discount type...</option>
            {discountTypes.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className='relative flex-1 ml-5'>
          <input
            disabled={shopifyDiscountType?.length === 0}
            type='text'
            placeholder='Provide amount...'
            value={shopifyDiscountAmount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='shopifyDiscountAmount'
            className='block w-full px-3 mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          {shopifyDiscountType === 'PERCENTAGE' && (
            <p className='absolute text-gray-500 top-4 right-3'>%</p>
          )}
          {shopifyDiscountType === 'FIXED_AMOUNT' && (
            <p className='absolute text-gray-500 top-4 right-3'>TK</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discounts;
