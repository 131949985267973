import { Position, useReactFlow, getConnectedEdges, useStore } from 'reactflow';
import {
  React,
  useMemo,
  useCallback,
  CustomHandle,
  NodeDecorator,
  UtilityContents,
  ConditionNodePreview,
  useReactFlowGraphServices,
  LogViewConditionNodePreview,
  useAutomationWorkflow,
  CircleAlert,
  CircleCheck,
} from '../../../../export';

interface Props {
  id: string;
  type: string;
  data: any;
  position: { x: number; y: number };
}

type TConditionResultTypes = 'success' | 'failed' | 'pending' | 'unknown';
const selector = (s: any) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const ConditionNode: React.FC<Props> = ({ id, type, data, position }) => {
  const { updatePrimitiveStateData, isViewLog } = useAutomationWorkflow();
  const {
    automationNodes,
    // automationEdges,
    addConditionOrActionButton,
  } = useReactFlowGraphServices();

  const currentConditionNode = automationNodes.find((v: any) => v.id === id);

  const conditionNodeResult = currentConditionNode?.component_computation_info;

  const currentStatus: TConditionResultTypes =
    currentConditionNode?.component_computation_info?.current_component_state ||
    'unknown';

  const { deleteElements } = useReactFlow();

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
    updatePrimitiveStateData({
      key: 'isNodeDeleteConfirmed',
      value: false,
    });
  }, [id, deleteElements, updatePrimitiveStateData]);

  const { nodeInternals, edges } = useStore(selector);

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(id);
    const connectedEdges = getConnectedEdges([node], edges);
    return connectedEdges.length < 3;
    //@ts-ignore
  }, [nodeInternals, edges, id]);

  const handleOnClick = useCallback(
    (sourcehandleId) => {
      updatePrimitiveStateData({ key: 'selectedNodeId', value: id });
      updatePrimitiveStateData({
        key: 'selectedSourceHandle',
        value: sourcehandleId,
      });
      updatePrimitiveStateData({
        key: 'selectedAction',
        value: sourcehandleId.includes('success_sourceHandle_')
          ? 'on_success'
          : 'on_failure',
      });
      if (isHandleConnectable) {
        addConditionOrActionButton(id, automationNodes, sourcehandleId);
      }
    },
    // eslint-disable-next-line
    [automationNodes]
  );
  /**
   *
   * @param status
   * @description status have 3 state : true,false,undefined

   * @returns
   */
  const getButtonIconAndStyle = (status: TConditionResultTypes) => {
    switch (status) {
      case 'failed': {
        return {
          icon: <CircleAlert className='h-4 w-4 text-red-500' />,
          buttonStyles: {
            backgroundColor: '#FFF0F0',
            borderColor: '#FF5757',
          },
        };
      }
      case 'success': {
        return {
          icon: <CircleCheck className='h-4 w-4 text-[#04A056]' />,
          buttonStyles: {
            backgroundColor: '#F0FDF4',
            borderColor: '#22C55E',
          },
        };
      }
      default: {
        return {
          icon: null,
          buttonStyles: {
            backgroundColor: '#F3F4F6',
            color: '#374151',
            borderWidth: 0,
          },
        };
      }
    }
  };

  const renderThenButton = ({ icon, buttonStyles }: any) => {
    return (
      <button
        disabled={isViewLog}
        style={isViewLog ? buttonStyles : {}}
        className={`flex items-center gap-2 font-semibold justify-center p-1 bg-yellow-100 border rounded-md focus:border-yellow-400 w-[9.5rem]`}
        onClick={() => handleOnClick(`success_sourceHandle_${id}`)}
      >
        {isViewLog ? icon : null}
        Then
      </button>
    );
  };

  const renderOtherwiseButton = ({ icon, buttonStyles }: any) => {
    return (
      <button
        disabled={isViewLog}
        style={isViewLog ? buttonStyles : {}}
        onClick={() => handleOnClick(`fail_sourceHandle_${id}`)}
        className='flex items-center gap-2 font-semibold justify-center p-1 bg-gray-100 border rounded-md w-[9.5rem] focus:border-gray-400'
      >
        {isViewLog ? icon : null}
        Otherwise
      </button>
    );
  };

  const renderConditionButtons = () => {
    let thenResult = conditionNodeResult ? currentStatus : 'unknown';
    let otherWiseResult: TConditionResultTypes = 'unknown';
    if (thenResult === 'success') {
      otherWiseResult = 'failed';
    } else if (thenResult === 'failed') {
      otherWiseResult = 'success';
    }

    const thenButtonStyle = getButtonIconAndStyle(thenResult);
    const otherwiseButtonStyle = getButtonIconAndStyle(otherWiseResult);
    return (
      <>
        {renderThenButton(thenButtonStyle)}
        {renderOtherwiseButton(otherwiseButtonStyle)}
      </>
    );
  };

  return (
    <NodeDecorator nodeType={'condition-node'} nodeId={id} onDelete={onDelete}>
      <div className='w-auto h-auto mb-2'>
        <CustomHandle
          type='target'
          isConnectable={3}
          position={Position.Top}
          id={`targetHandle_${id}`}
          className={UtilityContents.NodeTopMiddleHandleClass}
        />
        <div>
          <div className={`flex w-full px-4 py-2`}>
            {isViewLog ? (
              <LogViewConditionNodePreview
                nodeId={id}
                nodeType={type}
                conditionData={data || []}
                logResult={conditionNodeResult}
              />
            ) : (
              <ConditionNodePreview conditionData={data || []} />
            )}
          </div>
          <div
            className={`flex justify-between w-full px-4 border-t-2 border-gray-100 pt-3`}
          >
            {renderConditionButtons()}
          </div>
        </div>
        <CustomHandle
          type='source'
          isConnectable={3}
          position={Position.Bottom}
          id={`success_sourceHandle_${id}`}
          className={UtilityContents.NodeBottomLeftHandleClass}
        />
        <CustomHandle
          type='source'
          isConnectable={3}
          position={Position.Bottom}
          id={`fail_sourceHandle_${id}`}
          className={UtilityContents.NodeBottomRightHandleClass}
        />
      </div>
    </NodeDecorator>
  );
};

export default ConditionNode;
