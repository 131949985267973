import React from 'react';
import { ScheduleTimeProps } from '../interface';
import ScheduleDropdown from './ScheduleDropdown';

interface Props {
  type: 'date' | 'time';
  setScheduleTime: (payload: ScheduleTimeProps) => void;
  scheduleTime: ScheduleTimeProps;
}

const DatePicker: React.FC<Props> = ({
  type,
  setScheduleTime,
  scheduleTime,
}) => {
  const isDate = type === 'date';
  const isLeapYear = () => {
    let year = Number(scheduleTime.year ?? new Date().getFullYear());
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    return 28 + Number(isLeapYear);
  };

  const monthDetails = [
    { name: 'Jan', value: 'january', day: 31 },
    { name: 'Feb', value: 'february', day: isLeapYear() },
    { name: 'Mar', value: 'march', day: 31 },
    { name: 'Apr', value: 'april', day: 30 },
    { name: 'May', value: 'may', day: 31 },
    { name: 'June', value: 'june', day: 30 },
    { name: 'July', value: 'july', day: 31 },
    { name: 'Aug', value: 'august', day: 31 },
    { name: 'Sep', value: 'september', day: 30 },
    { name: 'Oct', value: 'october', day: 31 },
    { name: 'Nov', value: 'november', day: 30 },
    { name: 'Dec', value: 'december', day: 31 },
  ];
  const today = new Date();
  const currentMonth = today
    .toLocaleString('default', { month: 'long' })
    .toLowerCase();

  const timeTable = {
    hour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    minute: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    others: ['AM', 'PM'],
  };

  return (
    <div className=''>
      <div className='flex gap-2'>
        <ScheduleDropdown
          type={isDate ? 'date' : 'hour'}
          data={monthDetails}
          time={timeTable}
          selectedMonth={scheduleTime.month?.toString() ?? 'january'}
          selectedValue={isDate ? scheduleTime.date : scheduleTime.hour}
          setScheduleTime={setScheduleTime}
          scheduleTime={scheduleTime}
        />
        <ScheduleDropdown
          type={isDate ? 'month' : 'minute'}
          data={monthDetails}
          time={timeTable}
          selectedMonth={scheduleTime.month?.toString() ?? currentMonth}
          selectedValue={isDate ? scheduleTime.month : scheduleTime.minute}
          setScheduleTime={setScheduleTime}
          scheduleTime={scheduleTime}
        />
        <ScheduleDropdown
          type={isDate ? 'year' : 'others'}
          data={monthDetails}
          time={timeTable}
          selectedMonth={currentMonth}
          selectedValue={isDate ? scheduleTime.year : scheduleTime.others}
          setScheduleTime={setScheduleTime}
          scheduleTime={scheduleTime}
        />
      </div>
    </div>
  );
};

export default DatePicker;
