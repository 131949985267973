import React from 'react';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import config from 'utilities/config';
// import { handleFileUpload } from '../../../utilities/utils';
import pdfIcon from '../../pages/builder/assets/images/icons/pdf.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import CreateButtonFragment from '../elements/CreateButtonFragment';
import { Button } from 'evergreen-ui';
import PDFUploadField from 'components/utilityComponent/PDFUploaderField';
import { FormDataAxios } from '../../../utilities/utils';

const getMaxPDFFileSize = (platformType) => {
  switch (platformType) {
    case 'whatsapp_bsp':
      // change the first number to set limitation for file size '6 *----*----';
      return 75 * 1024 * 1024;
    default:
      // change the first number to set limitation for file size '6 *----*----';
      return 7 * 1024 * 1024;
  }
};

const PDFBlock = ({
  id,
  save,
  data,
  saveBlock,
  saveLoader,
  sequences,
  handleUpdate,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  apiList,
  handleAPIButtonElementCreate,
  type,
  platformType,
  subscriptionPlanType,
}) => {
  const maxFileSize = getMaxPDFFileSize(platformType);
  const file = data.urls[0];
  const [currentTab, setCurrentTab] = React.useState(-2);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let tabIndex = data.urls[0] !== '' ? 0 : -1;
    setCurrentTab(tabIndex);
    // eslint-disable-next-line
  }, []);

  const handleFileUpload = (file, callback) => {
    if (!file) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.misc.file(), formData)
      .then((res) => {
        callback(res.data.url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        callback(null);
        setLoading(false);
      });
  };

  const handleImageDrop = (file) => {
    const callback = (file) => handleUpdate(id, file);
    handleFileUpload(file, callback);
  };

  const handleImageClear = () => {
    handleUpdate(id, '');
  };

  return (
    <BlockContainer
      id={id}
      title='PDF'
      subtitle='Send a PDF to your customers.'
      save={save}
      isLoading={saveLoader}
      icon={pdfIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        {currentTab === -1 &&
          (!!data.api ? (
            <>
              <FetchFromAPIButtonSection
                blockId={id}
                hasWriteAccess={hasWriteAccess}
                apiButtonData={data.api}
                handleDelete={handleAPIButtonElementCreate}
              />
            </>
          ) : (
            data.urls[0] === '' &&
            hasWriteAccess && (
              <div className='gallery-block'>
                <CreateButtonFragment
                  type={type}
                  hasAPIButton={['enterprise', 'business'].includes(
                    subscriptionPlanType
                  )}
                  buttonTitle={'Upload an PDF file'}
                  APIButtonTitle={'Upload an PDF from API'}
                  buttonCount={0}
                  handleCreate={() => setCurrentTab(0)}
                  apiButtonData={data.api}
                  apiList={apiList}
                  handleAPIButtonElementCreate={handleAPIButtonElementCreate}
                  blockId={id}
                />
              </div>
            )
          ))}
        {currentTab === 0 && (
          <>
            <Button
              onClick={() => {
                setCurrentTab(-1);
                handleImageClear();
              }}
              appearance='minimal'
              intent={'success'}
              iconBefore='arrow-left'
            >
              {' '}
              Back
            </Button>
            <br />
            <br />
            <PDFUploadField
              maxFileSize={maxFileSize}
              file={file}
              hasWriteAccess={hasWriteAccess}
              handleDrop={handleImageDrop}
              handleClear={handleImageClear}
              isLoading={loading}
            />
          </>
        )}
      </>
    </BlockContainer>
  );
};

PDFBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
  platformType: PropTypes.string,
};

export default PDFBlock;
