// TODO: tobe refactored

import useTranslation from 'components/customHooks/useTranslation';
import dayJs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import React, { useEffect } from 'react';
import DatalabListPopoverView from '../../../datalab/DatalabListPopoverView';
import AttachmentPreview from './AttachmentPreview';
import ChatboxNavElements from './ChatboxNavElements';
import ChatboxReplyOptions from './ChatboxReplyOptions';
import SavedReplies from './SavedReplies';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import useEmailSignature from 'components/customHooks/useEmailSignature';
import { SelectedTeamInterface } from 'index';
import { Button } from 'library';
import {
  CustomerInfoInterface,
  IDatalabList,
  SavedRepliesInterface,
  SendEmailMessageRequestBodyInterface,
  SendGmailMessageRequestBodyInterface,
  SendMessageRequestBodyInterface,
  TicketActionInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { getLangDirection } from 'pages/inbox/utils/functions';
import {
  classNames,
  customEmailChannelTypes,
  emailChannels,
} from 'utilities/utils';
import { UppyAwsS3SuccessfulResponse } from '../../../../inboxInterface';
import RecipientEmailView from './RecipientEmailView';

dayJs.extend(relativeTime);
dayJs.extend(updateLocale);

dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%dm',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});
interface Props {
  userId: number;
  lineCount: number;
  selectedQueueType: string;
  chatboxActiveNav: string;
  conversationData: any;
  conversationLoading: boolean;
  selectedTicket: TicketInterface;
  customerInformation: CustomerInfoInterface;
  savedReplies: SavedRepliesInterface[];
  ticketActions: TicketActionInterface[];
  selectedProject: SelectedTeamInterface;
  handleEcommerceIconClick: () => void;
  setLineCount: (value: number) => void;
  updateSateData: (key: string, value: string) => void;
  handleMessengerChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => void;
  handleEmailChannelMessage: (
    messageRequestBody:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface
  ) => void;
  handleTicketAssignment: (agentId: number) => void;
  updateShowUpgradeModal: (payload: boolean) => void;
  ticketIsResolvedStatus: number;
  datalabList: IDatalabList[];
  updateSelectedNav: (value: number) => void;
  handleRightbarAppearence: (value: boolean) => void;
  updateInboxDatalabStateData: (key: string, value: any) => void;
  savedReplyAttachments: any;
  setSavedReplyAttachments: any;
}

const EmailChatBox: React.FC<Props> = ({
  userId,
  lineCount,
  setLineCount,
  savedReplies,
  ticketActions,
  selectedTicket,
  conversationData,
  selectedProject,
  updateSateData,
  chatboxActiveNav,
  selectedQueueType,
  savedReplyAttachments,
  setSavedReplyAttachments,
  customerInformation,
  conversationLoading,
  handleTicketAssignment,
  handleEcommerceIconClick,
  handleEmailChannelMessage,
  handleMessengerChannelMessage,
  updateShowUpgradeModal,
  ticketIsResolvedStatus,
  datalabList,
  updateSelectedNav,
  handleRightbarAppearence,
  updateInboxDatalabStateData,
}) => {
  const { t } = useTranslation();
  const { getEmailSignature } = useEmailSignature();
  const [inputValue, setInputValue] = React.useState('');
  const [inputEvent, setInputEvent] = React.useState<any>(null);
  const [showableSavedReply, setShowableSavedReply] =
    React.useState(savedReplies);
  const [isSavedReplyOpened, setIsSavedReplyOpened] = React.useState(false);
  const [isDatalabOpened, setIsDatalabOpened] = React.useState(false);
  const [showableDatalabList, setShowableDatalabList] =
    React.useState(datalabList);

  const [attachmentList, setAttachmentList] = React.useState<any>([]);

  // this state for email navbar to check expanded or collaspe state
  const [chevronIconStatus, setChevronIconStatus] = React.useState('down');

  const [defaultHeight, setDefaultHeight] = React.useState(230);
  const [chatBoxExtraHeight, setExtraHeight] = React.useState(0);

  // cc & bc states
  const [ccEmails, setCcEmails] = React.useState('');
  const [bcEmails, setBcEmails] = React.useState('');
  const [recipient, setRecipient] = React.useState('');
  const [isCcEnabled, setCcEnable] = React.useState(false);
  const [isBcEnabled, setBcEnable] = React.useState(false);

  const [emailSubject, setEmailSubject] = React.useState('');

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  // let platformType = selectedTicket?.customer_platform_type;
  let isResolved = selectedTicket?.is_resolved;
  let selectedNavIsNote = chatboxActiveNav === 'Note';

  //////////helper functions

  // const updateRecipientByCustomerInformation = () => {
  //   if (
  //     !!selectedTicket?.customer_email &&
  //     isValidEmail(selectedTicket?.customer_email)
  //   ) {
  //     setRecipient(selectedTicket?.customer_email);
  //   }
  // };

  const setCursorToDefaultPosition = () => {
    if (!!textAreaRef.current) {
      setTimeout(() => {
        if (!textAreaRef.current) return;
        textAreaRef.current.focus();
        textAreaRef.current.selectionStart = 0;
        textAreaRef.current.selectionEnd = 0;
        textAreaRef.current.scrollTop = 0;
      }, 10); // to wait for other state to change
    }
  };

  let isDirectMessageAllowed = () => {
    let directAction = ticketActions.filter(
      (actionItems) =>
        actionItems.action === 'direct_message' && actionItems.is_allowed
    );
    return directAction.length > 0;
  };

  const shouldDisableSendingView =
    !isDirectMessageAllowed() && chatboxActiveNav !== 'Note';

  const setInitialState = () => {
    setInputEvent(null);
    setAttachmentList([]);
    setIsSavedReplyOpened(false);
    setShowableSavedReply(savedReplies);
    setShowableDatalabList(datalabList);
  };

  const getAttachmentLists = () => {
    let list: string[] = [];
    attachmentList.forEach((attachment: UppyAwsS3SuccessfulResponse) =>
      list.push(attachment.uploadURL)
    );
    return list;
  };

  const shouldRenderToAndSubjectView = () => {
    let shouldReturn =
      chevronIconStatus === 'down' &&
      chatboxActiveNav === 'Email' &&
      !isResolved;
    if (selectedQueueType !== 'unassigned') {
      shouldReturn = shouldReturn && userId === selectedTicket?.assigned_agent;
    }
    return shouldReturn && !shouldDisableSendingView;
  };

  const processDatalabListOnInputChange = (value: string) => {
    let updatedDatalabList: IDatalabList[] = datalabList;
    if (value.length !== 0) {
      updatedDatalabList = datalabList.filter((datalab: IDatalabList) =>
        datalab?.title.toLowerCase().includes(value.toLowerCase())
      );
    }
    setShowableDatalabList(updatedDatalabList);
  };

  const processSendReplyData = (value: string) => {
    if (!textAreaRef || !textAreaRef.current) return;
    const caretPosition = textAreaRef?.current.selectionStart;
    const textBeforeCaret = value.substring(0, caretPosition);

    // Find the last occurrence of "#"
    const lastHashIndex = textBeforeCaret.lastIndexOf('#');
    let textAfterHash = '';
    if (lastHashIndex !== -1) {
      // Extract the text after "#"
      textAfterHash = textBeforeCaret.slice(lastHashIndex + 1) ?? '';
      textAfterHash = !!textAfterHash ? textAfterHash.trim() : textAfterHash;
    }

    let savedData = savedReplies;

    if (textAfterHash.length !== 0 && isSavedReplyOpened) {
      savedData = savedData.filter((reply) =>
        reply.title.toLowerCase().includes(textAfterHash.toLowerCase())
      );
    }
    setShowableSavedReply(savedData);
  };

  const getTextAreaHeight = () => {
    const isDefaultChatbox =
      chevronIconStatus === 'up' || chatboxActiveNav === 'Note';
    const defaultChatboxHeight =
      attachmentList.length > 0 && !selectedNavIsNote ? 85 : 132;
    const expandedChatHeight = attachmentList.length > 0 ? 11 : 61;

    const height = isDefaultChatbox ? defaultChatboxHeight : expandedChatHeight;

    return height + (lineCount - 1) * 16;
  };

  const getTextAreaParentHeight = () => {
    const isChatboxExpanded = shouldRenderToAndSubjectView();

    const defaultChatHeight =
      attachmentList.length > 0 && !selectedNavIsNote ? 104 : 152;
    const expandedChatHeight = attachmentList.length > 0 ? 38 : 86;

    const height = isChatboxExpanded ? expandedChatHeight : defaultChatHeight;

    return height + (lineCount - 1) * 16;
  };

  /////////////  handlers functions

  const handleChildClick = () => {
    setTimeout(() => {
      //@ts-ignore
      if (textAreaRef) {
        textAreaRef?.current?.focus();
      }
    }, 500);
  };

  const handleLineCount = () => {
    if (
      (userId === selectedTicket?.assigned_agent && !isResolved) ||
      selectedQueueType === 'unassigned'
    ) {
      setLineCount(10);
    } else {
      setLineCount(1);
    }
  };

  const handleChatOptionsPopoverBottomPosition = (optionName: string) => {
    switch (optionName) {
      case 'chatboxReplyOptions':
      case 'savedReply':
      case 'datalab':
        return chevronIconStatus === 'down' &&
          selectedQueueType !== 'unassigned'
          ? 'bottom-[25rem]'
          : 'bottom-64';
      default:
        return '';
    }
  };

  const handleUppyAttachmentFiles = (
    uploadedAttachmentList: UppyAwsS3SuccessfulResponse[]
  ) => {
    let updatedData = [...uploadedAttachmentList, ...attachmentList];
    setAttachmentList(updatedData);
  };
  const handleAttachmentsFromSavedReply = (
    uploadedAttachmentList: SavedRepliesInterface['attachments']
  ) => {
    const formatedList = uploadedAttachmentList.map((v) => ({
      ...v,
      uploadURL: v.url,
    }));
    let updatedData = formatedList.concat(attachmentList);
    setAttachmentList(updatedData);
  };

  const handleEmailBoxHeight = (chevronIconStatus: string) => {
    if (
      chevronIconStatus === 'up' &&
      !isResolved
      // userId === selectedTicket?.assigned_agent
    ) {
      setLineCount(10);
      setChevronIconStatus('down');
    } else {
      setCcEnable(false);
      setBcEnable(false);
      setLineCount(1);
      setChevronIconStatus('up');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    setInputEvent(event);
    if (event.key === 'Enter' && isSavedReplyOpened) {
      event.preventDefault();
      setIsSavedReplyOpened(false);
      return;
    } else if (event.key === 'Enter') {
      setLineCount(lineCount + 1);
      return;
    }
    if (
      inputValue === '' &&
      event.key === '/' &&
      selectedTicket.customer_platform_type === 'whatsapp_bsp'
    ) {
      setIsSavedReplyOpened(false);
    }
    if (event.key === '#') {
      setIsSavedReplyOpened(true);
    }
  };

  const handleInputChange = (e: any) => {
    let lineCountLocal = e.target.value.split('\n')?.length || lineCount;
    if (lineCountLocal + lineCount > 10) {
      lineCountLocal = 10;
    }
    if (e.target.value === '') {
      setIsSavedReplyOpened(false);
    }
    setInputValue(e.target.value);
    setLineCount(lineCountLocal);
    if (isSavedReplyOpened) processSendReplyData(e.target.value);
    if (isDatalabOpened) processDatalabListOnInputChange(e.target.value);
  };

  const handleSendButton = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();

    const getLastValidConversation = (conversations: any[]): any | null => {
      for (let i = 0; i < conversations.length; i++) {
        if (conversations[i].type === 'message') {
          return conversations[i];
        }
      }
      return null;
    };

    let payload:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface = {
      text: inputValue,
      to: recipient,
      cc_emails: ccEmails,
      bcc_emails: bcEmails,
      subject:
        conversationData[conversationData.length - 1]?.subject ?? emailSubject,
      thread_id: conversationData[conversationData.length - 1]?.thread_id ?? '',
      attachment: getAttachmentLists(),
      attachment_type: attachmentList.length !== 0 ? 'file' : null,
      action: selectedNavIsNote ? 'write_note' : 'direct_message',
    };

    if (
      customEmailChannelTypes.includes(selectedTicket?.customer_platform_type)
    ) {
      const lastValidConversation = !conversationData
        ? null
        : getLastValidConversation(conversationData);
      payload = {
        ...payload,
        ...{
          subject:
            !!conversationData && conversationData?.length > 0
              ? 'Re:' +
                (conversationData[conversationData.length - 1]?.subject ??
                  emailSubject)
              : emailSubject,
          attachments: getAttachmentLists(),
          recipient_emails: recipient,
          references:
            !!conversationData && conversationData?.length > 0
              ? `${
                  !!lastValidConversation
                    ? lastValidConversation?.dataV2?.email_data?.references ??
                      ''
                    : ''
                } ${lastValidConversation?.conversation_id ?? ''}`
              : '',
          in_reply_to: lastValidConversation?.conversation_id ?? '',
        },
      };
      if (!payload.attachment_type) {
        const { attachment_type, ...newPayload } = payload;
        payload = { ...newPayload };
      }
    }

    handleEmailChannelMessage(payload);
    setInputValue('');
    handleLineCount();
    setAttachmentList([]);
    setCcEmails('');
    setBcEmails('');
    setCcEnable(false);
    setBcEnable(false);
    setEmailSubject('');
    setIsSavedReplyOpened(false);
  };

  const handleRemoveAttachment = (removeIndex: number) => {
    let updatedAttachmentList = attachmentList.filter(
      (attachment: any, index: number) => index !== removeIndex
    );
    setAttachmentList(updatedAttachmentList);
  };

  ////////// render functions

  const renderInputTextArea = () => {
    return (
      <div
        // inline style is being used so that we can control the chatbox height according to line height
        // TODO: check if we can do it with tailwind css
        style={{
          height: getTextAreaParentHeight(),
        }}
        className='px-4 py-3'
      >
        <textarea
          ref={textAreaRef}
          disabled={shouldDisbaleChatboxArea()}
          id='email-reply-input'
          // inline style is being used so that we can control the chatbox height according to line height
          // TODO: check if we can do it with tailwind css
          style={{
            height: getTextAreaHeight(),
          }}
          value={inputValue}
          className={`w-full border-none outline-none text-sm resize-none focus:border-0 focus:border-none rtl:text-right focus:outline-none focus:outline-0 without-ring scroll-none ${
            selectedNavIsNote ? 'bg-yellow-100' : 'bg-white'
          } ${
            shouldDisbaleChatboxArea() ? 'cursor-not-allowed' : 'cursor-text'
          }`}
          placeholder={
            selectedNavIsNote
              ? t('Your note is visible to you and your teammates only...')
              : isResolved
              ? t('Send a new message to reopen the chat')
              : t('Type # for saved replies or write a new email')
          }
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onKeyDown={setInputEvent}
          dir={getLangDirection(inputValue)}
        />
      </div>
    );
  };

  const rederReplyOptionsAndSendButtonView = () => {
    return (
      <div
        className={`flex  h-10 px-4 ${
          chatboxActiveNav !== 'Note' ? 'justify-between' : 'justify-end'
        }`}
      >
        {chatboxActiveNav !== 'Note' && (
          <div className='flex items-center'>
            <ChatboxReplyOptions
              inputValue={inputValue}
              setInputValue={setInputValue}
              selectedProject={selectedProject}
              selectedTicket={selectedTicket}
              chatboxActiveNav={chatboxActiveNav}
              isSavedReplyOpened={isSavedReplyOpened}
              isDatalabListOpened={isDatalabOpened}
              setIsDatalabListOpened={(value: boolean) => {
                setIsDatalabOpened(value);
                setIsSavedReplyOpened(false);
              }}
              handleChildClick={handleChildClick}
              setIsSavedReplyOpened={(value: boolean) => {
                setIsSavedReplyOpened(value);
                setIsDatalabOpened(false);
              }}
              handleEcommerceIconClick={() => {
                handleEcommerceIconClick();
                setIsSavedReplyOpened(false);
                setIsDatalabOpened(false);
              }}
              handleWhatsapptemplatePopover={() => {
                setIsSavedReplyOpened(false);
              }}
              shouldDisbaleChatboxArea={shouldDisbaleChatboxArea}
              handleMessengerChannelMessage={handleMessengerChannelMessage}
              handleFeedChannelMessage={handleEmailChannelMessage}
              updateShowUpgradeModal={updateShowUpgradeModal}
              popoverBottomPosition={handleChatOptionsPopoverBottomPosition(
                'chatboxReplyOptions'
              )}
              handleUppyAttachmentFiles={handleUppyAttachmentFiles}
            />
          </div>
        )}
        <Button
          intent='primary'
          className='py-0 mb-[8px] ltr:ml-auto rtl:mr-auto'
          onClick={handleSendButton}
          isDisabled={
            inputValue.length === 0 &&
            (selectedNavIsNote ? true : attachmentList.length === 0)
          }
        >
          {selectedNavIsNote ? t('Save Note') : t('Send')}
        </Button>
      </div>
    );
  };

  const renderAssignToMeView = () => {
    return (
      <div className='absolute flex flex-col items-center justify-center w-full h-[192px] bg-white/[.7] rounded-b-md'>
        <Button intent='primary' onClick={() => handleTicketAssignment(userId)}>
          {t('Assign to me')}
        </Button>
        <span className='mt-3 text-xs font-medium text-gray-500'>
          {t('Click the button to join the conversation')}
        </span>
      </div>
    );
  };

  const renderPlatformRestrictionView = () => {
    return (
      <div className='absolute flex items-center text-red-500 justify-center w-full h-[192px] bg-red-50 rounded-b-md'>
        <ExclamationCircleIcon className='w-4 h-4 ' />
        <span className='ltr:ml-2 rtl:mr-2 text-sm font-medium'>
          {t(
            'You can not reply this conversation due to platform restrictions.'
          )}
        </span>
      </div>
    );
  };

  const renderCarbonCopyView = () => {
    return (
      <>
        {isCcEnabled && (
          <RecipientEmailView
            emails={ccEmails || ''}
            type='Cc'
            onChange={(value: string) => setCcEmails(value.trim())}
            handleEnableChange={(isEnable: boolean) => setCcEnable(isEnable)}
          />
        )}
        {isBcEnabled && (
          <RecipientEmailView
            emails={bcEmails || ''}
            type='Bcc'
            onChange={(value: string) => setBcEmails(value.trim())}
            handleEnableChange={(isEnable: boolean) => setBcEnable(isEnable)}
          />
        )}
      </>
    );
  };

  const renderSubjectView = () => {
    if (
      !!conversationData &&
      Array.isArray(conversationData) &&
      conversationData.length > 0
    ) {
      return (
        <>
          <span className='text-gray-600'>{t('Sub')}</span>
          <p className='ltr:ml-2 rtl:mr-2 overflow-hidden text-gray-900'>
            {conversationData[conversationData.length - 1]?.subject}
          </p>
        </>
      );
    } else {
      return (
        <div className='flex justify-center gap-1 items-center w-full'>
          <span className='text-gray-600'>{t('Sub')}: </span>
          <input
            className=' border-0 p-0 without-ring flex-1 focus:outline-none focus:ring-0 focus:border-transparent'
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            placeholder={t('Type email subject here...')}
          />
        </div>
      );
    }
  };

  const renderToAndSubjectView = () => {
    return (
      <>
        <div className='w-full flex items-center bg-white border-b border-gray-200 '>
          <RecipientEmailView
            type='To'
            width='85%'
            hideBorder={true}
            emails={recipient || ''}
            enableAllRemove={false}
            onChange={(value: string) => setRecipient(value.trim())}
            inputEnable={emailChannels.includes(
              selectedTicket?.customer_platform_type
            )}
          />
          {emailChannels.includes(selectedTicket?.customer_platform_type) && (
            <div
              className={classNames(
                'ltr:ml-auto rtl:mr-auto bg-wihite flex items-center justify-center p-1'
              )}
            >
              <span
                className='text-sm font-medium text-gray-800 p-1 cursor-pointer'
                onClick={() => setCcEnable(!isCcEnabled)}
              >
                {t('Cc')}
              </span>
              <span
                className='text-sm font-medium text-gray-800 p-1 cursor-pointer'
                onClick={() => setBcEnable(!isBcEnabled)}
              >
                {t('Bcc')}
              </span>
            </div>
          )}
        </div>

        {emailChannels.includes(selectedTicket?.customer_platform_type) &&
          renderCarbonCopyView()}
        <div className='flex items-center w-full h-8 px-4 bg-white border-b border-gray-200 border-solid'>
          {renderSubjectView()}
        </div>
      </>
    );
  };

  const renderChatboxActions = () => {
    if (selectedQueueType === 'unassigned' || chatboxActiveNav === 'Note') {
      return null;
    }
    if (chatboxActiveNav === 'Note') {
      return null;
    }
    if (
      userId !== selectedTicket?.assigned_agent &&
      (selectedTicket?.assigned_agent !== 0 ||
        selectedTicket?.assigned_group !== 0)
    ) {
      return renderAssignToMeView();
    } else if (!isDirectMessageAllowed() && chatboxActiveNav !== 'Note') {
      return renderPlatformRestrictionView();
    }
    return null;
  };

  const shouldDisbaleChatboxArea = () => {
    if (selectedQueueType === 'unassigned') return false;
    if (chatboxActiveNav === 'Note') return false;
    return (
      conversationLoading ||
      userId !== selectedTicket?.assigned_agent ||
      (!isDirectMessageAllowed() && chatboxActiveNav !== 'Note')
    );
  };

  //////////// useEffect functions

  useEffect(() => {
    return () => {
      setInitialState();
      setInputValue('');
    };
    //eslint-disable-next-line
  }, []);

  // setting up the chatbox height after successful assign
  useEffect(() => {
    if (
      userId === selectedTicket?.assigned_agent ||
      (selectedTicket?.assigned_agent === 0 &&
        selectedTicket?.assigned_group === 0)
    ) {
      setTimeout(() => handleEmailBoxHeight('up'), 10); // to wait for other state to change
      setCursorToDefaultPosition();
    }
    //eslint-disable-next-line
  }, [selectedTicket?.assigned_agent]);

  useEffect(() => {
    setInitialState();
    handleLineCount();
    const emailSignature = getEmailSignature();
    if (!!emailSignature) {
      setInputValue(`\n\n\n\n\n${emailSignature}`);
      setCursorToDefaultPosition();
    } else setInputValue('');
    // eslint-disable-next-line
  }, [selectedTicket?.id]);

  useEffect(() => {
    setInputValue('');
    if (chatboxActiveNav === 'Note') {
      setLineCount(1);
    } else if (
      (!isResolved && userId === selectedTicket?.assigned_agent) ||
      selectedQueueType === 'unassigned'
    ) {
      setLineCount(shouldDisableSendingView ? 1 : 10);
      setChevronIconStatus('down');
    }
    // eslint-disable-next-line
  }, [chatboxActiveNav]);

  useEffect(() => {
    setShowableSavedReply(savedReplies);
    // eslint-disable-next-line
  }, [savedReplies]);

  useEffect(() => {
    setShowableDatalabList(datalabList);
    // eslint-disable-next-line
  }, [datalabList]);

  useEffect(() => {
    if (!!conversationData && conversationData.length > 0) {
      //as we reversed the conversation list
      const firstConversation = conversationData[conversationData.length - 1];
      const { customer_info } = firstConversation;
      if (!!customer_info?.primary_id) {
        setRecipient(customer_info.primary_id);
      } else if (!firstConversation?.customer_info) {
        setRecipient('');
      }
    } else {
      setRecipient('');
    }
    //eslint-disable-next-line
  }, [conversationData]);

  useEffect(() => {
    let directAction = ticketActions.filter(
      (actionItems) =>
        actionItems.action === 'direct_message' && actionItems.is_allowed
    );

    if (directAction?.length < 1) {
      setLineCount(1);
    }
    //eslint-disable-next-line
  }, [ticketActions]);

  useEffect(() => {
    if (isCcEnabled && isBcEnabled) {
      setExtraHeight(65);
      setDefaultHeight(250);
    } else if (isCcEnabled || isBcEnabled) {
      setExtraHeight(20);
      setDefaultHeight(242);
    } else {
      setExtraHeight(0);
      setDefaultHeight(attachmentList?.length > 0 ? 234 : 230);
    }
  }, [isCcEnabled, isBcEnabled, attachmentList]);

  ///////// main render

  return (
    <>
      {conversationLoading && (
        <div
          data-testid='chatbox-loading'
          style={{ height: 226 + (lineCount - 1) * 16 }}
          className={`w-auto mx-4 border border-gray-200 rounded-lg bottom-2 bg-gray-100 animate-pulse`}
        />
      )}
      {!conversationLoading && (
        <div
          data-testid='email-chat-box'
          className={`w-auto mx-4 ${
            shouldDisableSendingView ? 'border-0' : 'border'
          } border-gray-200 rounded-lg bottom-2 relative`}
          // inline style is being used so that we can control the chatbox height according to line height
          // TODO: check if we can do it with tailwind css
          style={{
            height: shouldDisableSendingView
              ? defaultHeight
              : defaultHeight + chatBoxExtraHeight + (lineCount - 1) * 16,
            top: `-${chatBoxExtraHeight}px`,
          }}
        >
          <div className='h-8 border-b border-gray-200 border-solid rounded-t-lg bg-gray-50 relative'>
            <ChatboxNavElements
              activeTab={chatboxActiveNav}
              selectedQueueType={selectedQueueType}
              selectedTicket={selectedTicket}
              updateSateData={updateSateData}
              chevronIconStatus={chevronIconStatus}
              handleEmailBoxHeight={handleEmailBoxHeight}
              shouldShowChevronIcon={
                !shouldDisableSendingView &&
                ((!isResolved && userId === selectedTicket?.assigned_agent) ||
                  selectedQueueType === 'unassigned')
              }
              ticketIsResolvedStatus={ticketIsResolvedStatus}
              ticketActions={ticketActions}
            />
          </div>
          {/* render cc and bcc view */}
          {shouldRenderToAndSubjectView() && renderToAndSubjectView()}
          <div className='relative'>
            <div
              className={`absolute w-full rounded-b-lg ${
                selectedNavIsNote ? 'bg-yellow-100' : 'bg-white'
              }`}
            >
              {!shouldDisableSendingView && renderInputTextArea()}

              {attachmentList.length > 0 && chatboxActiveNav !== 'Note' && (
                <AttachmentPreview
                  attachmentLists={attachmentList.map(
                    (v: any) => (v.url = v.uploadURL) && v
                  )}
                  handleRemoveAttachment={handleRemoveAttachment}
                />
              )}

              {!shouldDisableSendingView &&
                rederReplyOptionsAndSendButtonView()}
            </div>
            {/* TODO: have to check if userId exist in smart group  */}
            {renderChatboxActions()}
          </div>
          {isSavedReplyOpened && (
            <SavedReplies
              inputEvent={inputEvent}
              inputValue={inputValue}
              platformType={selectedTicket.customer_platform_type}
              setInputValue={setInputValue}
              savedReplies={showableSavedReply}
              setSavedReplyAttachments={handleAttachmentsFromSavedReply}
              bottom={handleChatOptionsPopoverBottomPosition('savedReply')}
              setIsSavedReplyOpened={(value: boolean) => {
                setIsSavedReplyOpened(value);
                document.getElementById('email-reply-input')?.focus();
              }}
            />
          )}
          {isDatalabOpened && (
            <DatalabListPopoverView
              inputEvent={inputEvent}
              inputValue={inputValue}
              setInputValue={setInputValue}
              datalabList={showableDatalabList}
              updateSelectedNav={updateSelectedNav}
              setIsDatalabListOpened={setIsDatalabOpened}
              handleRightbarAppearence={handleRightbarAppearence}
              updateInboxDatalabStateData={updateInboxDatalabStateData}
              bottom={handleChatOptionsPopoverBottomPosition('datalab')}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EmailChatBox;
