import { toaster } from 'evergreen-ui';
import {
  SendMessageRequestBodyInterface,
  TicketInterface,
  WhatsappTemplate,
} from 'pages/inbox/inboxInterface';
import {
  filterWhatsappTemplates,
  hasDynamicUrlButtonType,
} from 'pages/inbox/utils/functions';
import React from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import WhatsappTemplateDetailsModal from './WhatsappTemplateDetailsModal';
import WhatsappTemplateList from './WhatsappTemplateList';

const defaultData = {
  id: 'defaultId',
  name: 'Default name',
  header: {
    type: 'text',
    value: 'Hello there,',
  },
  body: 'Template preview will show here',
  status: 'approved',
  category: 'default',
  language: 'english',
};

interface Props {
  isOpenWhatsappMessage: boolean;
  setIsOpenWhatsappMessage: (payload: boolean) => void;
  whatsappTemplates: WhatsappTemplate[];
  fetchWhatsappTemplateDetails: (payload: TemplateProps) => Promise<string[]>;
  selectedTicket: TicketInterface;
  whatsappTemplateDetails: string[];
  sendMessegengerChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => boolean;
  setInputValue: (payload: string) => void;
  updateWhatsappTemplateDetails: (payload: any) => void;
}

interface TemplateProps {
  channelId: number;
  templateId: string;
}

const WhatappTemplateMessage: React.FC<Props> = ({
  isOpenWhatsappMessage,
  setIsOpenWhatsappMessage,
  whatsappTemplates,
  fetchWhatsappTemplateDetails,
  selectedTicket,
  whatsappTemplateDetails,
  sendMessegengerChannelMessage,
  setInputValue,
  updateWhatsappTemplateDetails,
}) => {
  const [filterTemplateList, setFilterTemplateList] = React.useState<
    WhatsappTemplate[] | null
  >(whatsappTemplates);
  const [step, setStep] = React.useState(1);
  const [variables, setVariables] = React.useState(whatsappTemplateDetails);
  const [selectedTemplate, setSelectedTempleted] =
    React.useState<WhatsappTemplate>(defaultData);
  const [attributeData, setAttributeData] = React.useState({});
  const [dynamicButtonVariableName, setDynamicButtonVariableName] =
    React.useState('');

  const submitTemplateMessage = async () => {
    let payload = {
      action: 'send_template',
      template: selectedTemplate?.id,
      attribute_data: attributeData,
    };
    const res = await sendMessegengerChannelMessage(payload);
    if (res) {
      setIsOpenWhatsappMessage(false);
      setStep(1);
      setInputValue('');
    } else {
      setIsOpenWhatsappMessage(true);
    }
  };

  const changeStep = async (channelId: number, templateId: string) => {
    const payload = {
      channelId: channelId,
      templateId: templateId,
    };
    const res = await fetchWhatsappTemplateDetails(payload);
    if (!!res) {
      const template = whatsappTemplates.find((item) => item.id === templateId);
      if (template) {
        setSelectedTempleted(template);
      }
      updateWhatsappTemplateDetails(res);
      setStep(2);
    } else {
      toaster.danger('Template fetching failed!!');
    }
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <div className='absolute z-10 bottom-[20%]'>
            <WhatsappTemplateList
              filterTemplateList={filterTemplateList}
              setFilterTemplateList={setFilterTemplateList}
              setIsOpenWhatsappMessage={setIsOpenWhatsappMessage}
              changeStep={changeStep}
              selectedTicket={selectedTicket}
              setInputValue={setInputValue}
              whatsappTemplates={whatsappTemplates}
            />
          </div>
        );
      case 2:
        return (
          <Modal width='w-auto' height='h-auto' isOpen={isOpenWhatsappMessage}>
            <WhatsappTemplateDetailsModal
              dynamicButtonVariableName={dynamicButtonVariableName}
              setIsOpenWhatsappMessage={setIsOpenWhatsappMessage}
              setStep={setStep}
              selectedTemplate={selectedTemplate}
              variables={variables}
              setAttributeData={setAttributeData}
              attributeData={attributeData}
              submitTemplateMessage={submitTemplateMessage}
              setInputValue={setInputValue}
            />
          </Modal>
        );
      default:
        return null;
    }
  };

  const setInitialAttribute = () => {
    const initialValue = {};
    whatsappTemplateDetails?.reduce((obj, item) => {
      return {
        ...obj,
        [item]: null,
      };
    }, initialValue);
    setAttributeData(initialValue);

    if (!!selectedTemplate) {
      const templateType = selectedTemplate?.header?.type;
      const templateHeaderValue = selectedTemplate?.header?.value || null;
      switch (templateType) {
        case 'image':
          setAttributeData({ ...attributeData, image: templateHeaderValue });
          break;
        case 'video':
          setAttributeData({ ...attributeData, video: templateHeaderValue });
          break;
        case 'document':
          setAttributeData({ ...attributeData, document: templateHeaderValue });
          break;
        default:
          break;
      }
    }
  };

  React.useEffect(() => {
    setFilterTemplateList(filterWhatsappTemplates(whatsappTemplates));
  }, [whatsappTemplates]);

  const setTemplateVariables = (
    whatsappTemplateDetails: string[],
    selectedTemplate: WhatsappTemplate
  ) => {
    if (
      !!selectedTemplate &&
      hasDynamicUrlButtonType(selectedTemplate) &&
      !!whatsappTemplateDetails
    ) {
      let buttonProperty: any =
        !!selectedTemplate?.buttons && selectedTemplate?.buttons[0];
      const buttonVariableName =
        !!buttonProperty.value.match(/{{.*}}/) &&
        buttonProperty.value.match(/{{.*}}/)[0].replace(/[^a-zA-Z]+/g, '');

      let variables = !!buttonVariableName
        ? whatsappTemplateDetails.filter((variable: string) => {
            return variable !== buttonVariableName;
          })
        : whatsappTemplateDetails;

      if (!!buttonVariableName) {
        setDynamicButtonVariableName(buttonVariableName);
      }
      setVariables(variables);
    } else {
      setVariables(whatsappTemplateDetails);
    }
  };

  React.useEffect(() => {
    setTemplateVariables(whatsappTemplateDetails, selectedTemplate);
    setInitialAttribute();
    // eslint-disable-next-line
  }, [whatsappTemplateDetails, selectedTemplate]);

  return <div>{getComponent()}</div>;
};

const mapState = (state: any) => ({
  whatsappTemplates: state.inbox.whatsappTemplates,
  selectedTicket: state.inbox.selectedTicket,
  whatsappTemplateDetails: state.inbox.whatsappTemplateDetails,
});

const mapDispatch = (dispatch: any) => ({
  fetchWhatsappTemplateDetails: (payload: TemplateProps) =>
    dispatch.inbox.fetchWhatsappTemplateDetails(payload),
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
  updateWhatsappTemplateDetails: (payload: any) =>
    dispatch.inbox.updateWhatsappTemplateDetails(payload),
});

export default connect(mapState, mapDispatch)(WhatappTemplateMessage);
