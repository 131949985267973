import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { CopyBlock, solarizedLight, nord } from 'react-code-blocks';

interface Props {
  theme?: string;
  showLineNum?: boolean;
  hasCustomStyle?: boolean;
  platfromData: webChatResponseDataProps;
}

const Step2: React.FC<Props> = ({
  platfromData,
  theme = 'default',
  showLineNum = true,
  hasCustomStyle = false,
}) => {
  const { t } = useTranslation();
  const checkTheme = () => {
    if (
      theme.toLocaleLowerCase() === 'default' ||
      theme.toLocaleLowerCase() === 'light'
    ) {
      return solarizedLight;
    } else if (theme.toLocaleLowerCase() === 'dark') return nord;

    return solarizedLight;
  };

  const getCustomStyle = () => {
    if (hasCustomStyle) {
      return {
        height: '200px',
        overflowY: 'scroll',
        borderRadius: '6px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05);',
        fontSize: '12px',
      };
    } else return {};
  };
  return (
    <>
      <div className='flex justify-between'>
        <label
          htmlFor='url_whitelist'
          className='block text-sm font-medium text-gray-600'
        >
          {t('Script For Livechat')}
        </label>
        {/* <span className='text-[#0078CF]'>Email Code to Devs</span> */}
      </div>

      <div className=' mt-3' key={'c-003'}>
        <CopyBlock
          text={`// Put this code snippet inside script tag
<script> !function(){var e=document.createElement("div");e.id="myAliceWebChat";var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://livechat.myalice.ai/index.js";var a=document.body.getElementsByTagName("script");(a=a[a.length-1]).parentNode.insertBefore(t,a),a.parentNode.insertBefore(e,a),t.addEventListener("load",function(){MyAliceWebChat.init({selector:"#myAliceWebChat",platformId:'${
            platfromData?.id || ''
          }',primaryId:'${platfromData?.primary_id || ''}',token:'${
            platfromData?.access_token || ''
          }' })})}();</script>`}
          language='javascript'
          theme={checkTheme()}
          showLineNumbers={showLineNum}
          customStyle={getCustomStyle()}
          wrapLongLines={false}
          codeBlock={false}
        />
      </div>
      <div className='mt-4'>
        <div className='flex gap-2 justify-start items-center'>
          <div className='flex justify-center items-center w-8 h-6 rounded-full text-xs bg-gray-100 text-gray-800'>
            1
          </div>

          <p className='leading-5 flex font-normal text-sm text-gray-500'>
            {t(
              'Paste this code in your website footer. The code has been tested safe and works with any language.'
            )}
          </p>
        </div>
        <div className='flex gap-2 justify-start items-center mt-4'>
          <div className='flex justify-center items-center w-6 h-6 rounded-full text-xs bg-gray-100 text-gray-800'>
            2
          </div>

          <p className='leading-5 flex font-normal text-sm text-gray-500'>
            {t(
              'Reload your website. Livechat should appear in the bottom right corner.'
            )}
          </p>
        </div>
      </div>
    </>
  );
};
export default Step2;
