import React from 'react';
import noResultImage from './../../../../assets/images/ecommerceAssets/product-no-result.svg';
import reSearchImage from './../../../../assets/images/ecommerceAssets/product-re-search.svg';
import preSearchImage from './../../../../assets/images/ecommerceAssets/product-pre-search.svg';
import noOrderImage from './../../../../assets/images/ecommerceAssets/no-order-image.svg';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface Prop {
  state: stateType;
  search?: string;
}

type stateType =
  | 're-search'
  | 'pre-search'
  | 'no-result'
  | 'loading'
  | 'no-order';

const ProductState: React.FC<Prop> = ({ state, search }) => {
  const getProductStateImage = (state: stateType) => {
    switch (state) {
      case 'no-result':
        return noResultImage;
      case 'no-order':
        return noOrderImage;
      case 're-search':
        return reSearchImage;
      case 'pre-search':
      default:
        return preSearchImage;
    }
  };
  const getProductStateDescription = (state: stateType) => {
    switch (state) {
      case 'no-result':
        return (
          <span>
            The product you searched for didn’t match <br />
            with anything from your inventory.
          </span>
        );
      case 'no-order':
        return (
          <span>
            No order found for <strong>{search}</strong>
          </span>
        );
      case 'pre-search':
        return (
          <span>
            Type something and the products will <br />
            showup here!
          </span>
        );
      case 'loading':
        return <span>Loading...</span>;
      case 're-search':
      default:
        return (
          <span>
            To select another item continue to <br />
            search and select.
          </span>
        );
    }
  };

  return (
    <div className='mx-6 mt-4 overflow-auto border rounded-md  h-80'>
      <div className='flex flex-col items-center justify-center w-full h-full'>
        {!!state && state !== 'loading' && (
          <img src={getProductStateImage(state)} width={130} alt={state} />
        )}
        {!!state && state === 'loading' && (
          <ArrowPathIcon className='w-10 h-10 text-primary hover:text-primary-hover animate-reverse-spin' />
        )}
        {!!state && (
          <p className='mt-4 font-sans text-sm font-medium text-center text-gray-500'>
            {getProductStateDescription(state)}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductState;
