import React from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

interface Props {
  key: string;
  orderMethodName: string;
  defaultTitle: string;
  methodName: string;
  dataList: string[];
  defaultValue?: string;
  handleSetWooCommerceObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const CustomListBox: React.FC<Props> = ({
  key,
  dataList,
  methodName,
  defaultTitle,
  defaultValue,
  orderMethodName,
  handleSetWooCommerceObjectInformation,
}) => {
  const [selected, setSelected] = React.useState(defaultValue);

  const listBoxButtonName = () => {
    return (
      <Listbox.Button
        className='relative w-full cursor-default rounded-md text-xs
            border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm
            focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500 sm:text-xs'
      >
        {orderMethodName ? (
          <span className='block truncate'>{orderMethodName}</span>
        ) : (
          <span className='text-gray-500'>{defaultTitle}</span>
        )}

        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronDownIcon
            className='h-5 w-5 text-gray-500'
            aria-hidden='true'
          />
        </span>
      </Listbox.Button>
    );
  };

  const listBoxButtonOptions = () => {
    return (
      <Listbox.Options
        className='absolute z-10 max-h-40 w-full text-xs
              overflow-auto rounded-md bg-white py-1 shadow-lg ring-1
              ring-black ring-opacity-5 focus:outline-none sm:text-xs'
      >
        {dataList &&
          dataList.map((title: string, visibleIdx: number) => (
            <Listbox.Option
              key={visibleIdx}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-3 pr-4 text-xs leading-4 ${
                  active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                }`
              }
              value={title}
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block truncate text-xs ${
                      selected ? 'font-semibold' : 'font-normal'
                    }`}
                  >
                    {title}
                  </span>
                  {selected ? (
                    <span className='absolute inset-y-0 right-0 flex items-center text-xs mr-2.5 text-amber-600'>
                      <CheckIcon className='h-4 w-4' aria-hidden='true' />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
      </Listbox.Options>
    );
  };

  return (
    <Listbox
      key={key}
      value={selected}
      onChange={(value) => {
        setSelected(value);

        handleSetWooCommerceObjectInformation({
          objectKeyName: 'wooCommerceOrderInformation',
          childObjectKeyName: methodName,
          value: value,
        });
      }}
    >
      {({ open }) => (
        <div className='relative'>
          {listBoxButtonName()}

          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {listBoxButtonOptions()}
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default CustomListBox;
