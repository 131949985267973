import { LiveChatDataProps } from 'pages/integration/interface';
import { useEffect, useState } from 'react';
import ColorDesignComponent from './colorDesign';
import VisibilityComponent from './visibility';

interface Props {
  channelId: Number;
  liveChatData: LiveChatDataProps;

  updateLiveChatProperty: (key: string, value: any) => void;
  updateBrandData: (key: string, value: any) => void;
  onPreviewChange: (type: string) => void;
}

const Appearance: React.FC<Props> = ({
  liveChatData,
  channelId,
  updateLiveChatProperty,
  updateBrandData,
  onPreviewChange,
}) => {
  const [isFirstComponentOpen, toggleFirstComponnent] =
    useState<boolean>(false);

  const [isSecondComponentOpen, toggleSecondComponnent] =
    useState<boolean>(false);

  useEffect(() => {
    if (isFirstComponentOpen) {
      onPreviewChange('default');
    } else if (isSecondComponentOpen) {
      onPreviewChange('chatHead');
    }

    // eslint-disable-next-line
  }, [isFirstComponentOpen, isSecondComponentOpen]);

  const handleComponentAccordionToggle = (componentNo: number) => {
    if (componentNo === 1) {
      if (!isFirstComponentOpen) {
        toggleFirstComponnent(true);
        toggleSecondComponnent(false);
      } else {
        toggleFirstComponnent(false);
      }
    } else {
      if (!isSecondComponentOpen) {
        toggleFirstComponnent(false);
        toggleSecondComponnent(true);
      } else {
        toggleSecondComponnent(false);
      }
    }
  };

  return (
    <>
      <ColorDesignComponent
        liveChatData={liveChatData}
        channelId={channelId}
        updateBrandData={updateBrandData}
        updateLiveChatData={updateLiveChatProperty}
        isOpen={isFirstComponentOpen}
        handleAccordionClick={() => {
          handleComponentAccordionToggle(1);
        }}
      />
      <span className='h-3 block'></span>
      <VisibilityComponent
        liveChatData={liveChatData}
        updateLiveChatData={updateLiveChatProperty}
        isOpen={isSecondComponentOpen}
        handleAccordionClick={() => {
          handleComponentAccordionToggle(2);
        }}
      />
    </>
  );
};

export default Appearance;
