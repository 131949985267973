import React from 'react';
import Footer from '../../common/components/Footer';

interface Props {}

const Introduction: React.FC<Props> = () => {
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          Integrating Live Chat with Alice will allow you to:
        </p>
        <ul className='mt-3 ml-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>Add a live chat widget on your website.</li>
          <li className='my-2'>
            Engage your website visitors with live conversations.
          </li>
          <li className='my-2'>
            Push custom notifications on different website pages.
          </li>
          <li className='my-2'>
            Manage messages, comments and e-commerce orders from other platforms
            in the same inbox.
          </li>
        </ul>
      </div>
      <Footer channelName='Live Chat' />
    </div>
  );
};
export default Introduction;
