import React from 'react';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  item: stackProps;
  column: number;
}
interface stackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name: string;
  loader: boolean;
  toolTip?: string;
}

const LoaderDataStack = () => (
  <dd className='flex items-baseline justify-start w-full mt-1 md:block lg:flex'>
    <div className='w-full my-2 space-y-2 animate-pulse'>
      <div className='w-4/6 h-4 bg-gray-200 rounded' />
    </div>
  </dd>
);

const DataStackCard: React.FC<Prop> = ({ item, column }) => {
  const { t } = useTranslation();
  return (
    <div className={`px-3 py-5 w-full`}>
      <dt className='flex text-base font-normal text-gray-900'>
        {t(item.name)}
        {!!item.toolTip ? (
          <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(item?.toolTip)}>
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        ) : (
          ''
        )}
      </dt>
      {item.loader && <LoaderDataStack />}
      {item?.current_value === '0' && item?.previous_value === '0' ? (
        <span className='flex items-baseline text-2xl font-semibold text-gray-900'>
          0
        </span>
      ) : (
        <>
          {!item.loader && (
            <dd className='flex items-baseline justify-start mt-1 md:block lg:flex'>
              <div className='flex items-baseline text-2xl font-semibold text-gray-900'>
                {item.current_value || 0}
                <span className='ltr:ml-2 rtl:mr-2 text-sm font-medium text-gray-500'>
                  {t('from')} {item.previous_value || 0}
                </span>
              </div>

              <div
                className={
                  'inline-flex ltr:ml-6 rtl:mr-6 items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                }
              >
                {item.growth_direction === '+' ? (
                  <ArrowSmallUpIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
                    aria-hidden='true'
                  />
                ) : item.growth_direction === '-' ? (
                  <ArrowSmallDownIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
                    aria-hidden='true'
                  />
                ) : (
                  <ArrowSmallUpIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-gray-500'
                    aria-hidden='true'
                  />
                )}

                <span className='sr-only'>
                  {item.growth_direction === '+'
                    ? 'Increased'
                    : item.growth_direction === '-'
                    ? 'Decreased'
                    : ''}{' '}
                  by
                </span>
                {item.growth_value || 'N/A'}
              </div>
            </dd>
          )}
        </>
      )}
    </div>
  );
};

export default DataStackCard;
