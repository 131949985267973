import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';

const initialState = {
  businessHourTotalData: null
};

export const businesshour = {
  state: {
    ...initialState,
  },
  reducers: {
    updateBusinessHour(state, payload) {
      return { ...state, businessHourTotalData: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchBusinessHours(teamId) {
      try {
        const res = await axios.get(config.settings.businessHour(teamId));
        if (res.status === 200) {
          dispatch.businesshour.updateBusinessHour(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },

    async updateBusinessHours(payload) {
      try {
        const res = await axios.patch(
          config.settings.businessHour(payload.teamId),
          payload.data
        );

        if (res.data.success) {
          dispatch.businesshour.fetchBusinessHours(payload.teamId);
          return res.data.success;
        }
      } catch (err) {}
    },
  }),
};
