import React from 'react';
import { classNames } from '../../../../utilities/utils';
import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { subscriptionDetailsType } from '../../../../index';
import useTranslation from 'components/customHooks/useTranslation';
import { navigate } from '@reach/router';
import { kebabCase } from 'lodash';

interface Prop {
  tab: tabProps;
  handleUpdate: () => void;
  currentTab: tabProps;
  subscriptionDetails: subscriptionDetailsType;
}
interface tabProps {
  id: number;
  name: string;
}

const ReportingNavTabElement: React.FC<Prop> = ({
  tab,
  handleUpdate,
  currentTab,
  subscriptionDetails,
}) => {
  const { t } = useTranslation();
  return (
    <button
      type='button'
      disabled={tab.id === currentTab.id}
      onClick={() => navigate(`?tab=${kebabCase(tab.name)}`)}
    >
      <div
        key={tab.name}
        onClick={() => {
          if (tab.id !== currentTab.id) {
            handleUpdate();
          }
        }}
        className={classNames(
          tab.id === currentTab.id
            ? 'bg-gray-100 text-gray-700'
            : 'text-gray-500 hover:text-gray-700',
          'flex px-3 py-2 cursor-pointer font-medium text-sm rounded-md'
        )}
        aria-current={tab.id === currentTab.id ? 'page' : undefined}
      >
        <span>{t(tab.name)}</span>
        {tab.id === 3 &&
          subscriptionDetails?.trial_availed &&
          subscriptionDetails?.trial_remaining > 0 && (
            <span className='w-3.5 h-3.5 m-1 mb-1.5 text-yellow-500'>
              <ChevronDoubleUpIcon />
            </span>
          )}
      </div>
    </button>
  );
};

const mapState = (state: any) => ({
  subscriptionDetails: state.newBilling.subscriptionDetails,
});

export default connect(mapState)(ReportingNavTabElement);
