import React from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import EmptyImage from '../../assets/emptyData.svg';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Spinner from './Spinner';
import { connect } from 'react-redux';
import Pagination from './Pagination';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  tagList: TagInterface[];
  selectedTag: number[];
  setSelectedTag: (payload: number[]) => void;
  checkAll: (checked: boolean) => void;
  setOpenDeleteModal: (payload: boolean) => void;
  openDeleteModal: boolean;
  setDeleteType: (payload: string) => void;
  setSingleTag: (payload: number[]) => void;
  setIsEdit: (payload: boolean) => void;
  setEditData: (payload: { id: number | null; name: string }) => void;
  setOpenCreateModal: (payload: boolean) => void;
  loadingTagList: boolean;
  sliceData: { limit: number; offset: number };
  setSliceData: (payload: { limit: number; offset: number }) => void;
}

const TagList: React.FC<Props> = ({
  tagList,
  selectedTag,
  setSelectedTag,
  checkAll,
  setOpenDeleteModal,
  openDeleteModal,
  setDeleteType,
  setSingleTag,
  setIsEdit,
  setEditData,
  setOpenCreateModal,
  loadingTagList,
  sliceData,
  setSliceData,
}) => {
  const { t } = useTranslation();
  const perPageData = 9;

  const time = (timeStamp: string) => {
    if (
      moment(parseInt(timeStamp)).format('hh:mm a, MMMM Do YYYY') !==
      'Invalid date'
    ) {
      return moment.unix(parseInt(timeStamp)).format('hh:mm a, MMMM Do YYYY');
    } else {
      return '- -';
    }
  };

  const nextPage = () => {
    if (sliceData.limit >= tagList.length) return;

    setSliceData({
      ...sliceData,
      limit: sliceData.limit + perPageData,
      offset: sliceData.offset + perPageData,
    });
  };

  const prevPage = () => {
    setSliceData({
      ...sliceData,
      limit: sliceData.limit - perPageData,
      offset: sliceData.offset - perPageData,
    });
  };

  return (
    <div className='mt-8 flex flex-col border rounded-lg'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
          <div className='relative overflow-hidden rounded-lg max-h-[67.5vh]'>
            <table className='min-w-full table-fixed divide-y divide-gray-300'>
              <thead className='bg-[#F9FAFB]'>
                <tr>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 text-left text-sm
                     font-semibold text-gray-900 backdrop-blur backdrop-filter
                      sm:table-cell'
                  >
                    <input
                      type='checkbox'
                      className='absolute left-4 top-1/2 -mt-2 h-4 w-4
                            rounded border-gray-300 text-green-600
                            focus:ring-green-500 sm:left-6 cursor-pointer'
                      checked={
                        selectedTag.length === tagList.length &&
                        tagList.length !== 0
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        // @ts-ignore
                        checkAll(e.target.checked ? true : false);
                      }}
                    />
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                     bg-gray-50 bg-opacity-100 pr-3 py-3.5 ltr:text-left rtl:text-right text-sm
                      font-medium text-[#6B7280] backdrop-blur backdrop-filter
                       sm:table-cell uppercase'
                  >
                    {t('Tags')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                     font-medium text-[#6B7280] backdrop-blur
                     backdrop-filter sm:table-cell uppercase'
                  >
                    {t('Created At')}
                  </th>
                  <th
                    scope='col'
                    className='sticky top-0 z-10 hidden border-b border-gray-300
                    bg-gray-50 bg-opacity-100 px-3 py-3.5 ltr:text-left rtl:text-right text-sm
                     font-medium text-[#6B7280] backdrop-blur
                     backdrop-filter sm:table-cell uppercase'
                  >
                    {t('Action')}
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {tagList.length > 0 &&
                  tagList
                    .slice(sliceData.offset, sliceData.limit)
                    .map((item, index) => (
                      <tr key={index} className=''>
                        <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                          <input
                            type='checkbox'
                            className='absolute left-4 top-1/2 -mt-2 h-4 w-4
                          rounded border-gray-300 text-green-600 focus:ring-green-500 sm:left-6 cursor-pointer'
                            checked={selectedTag.includes(item.id)}
                            onChange={(e: any) => {
                              setSelectedTag(
                                // @ts-ignore
                                e.target.checked
                                  ? [...selectedTag, item.id]
                                  : selectedTag.filter((i) => i !== item.id)
                              );
                            }}
                          />
                        </td>
                        <td
                          className={classNames(
                            'whitespace-nowrap py-4 pr-3 text-sm font-medium w-[30%] rtl:text-right'
                          )}
                        >
                          {item.name}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[40%] rtl:text-right'>
                          {time(item?.created_at)}
                        </td>
                        <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-[20%] rtl:text-right'>
                          <div className='flex gap-4'>
                            {/* @ts-ignore */}
                            <ReactTooltip
                              id='edit'
                              place='top'
                              type='dark'
                              effect='float'
                            />
                            <PencilIcon
                              data-for='edit'
                              data-tip={t('Edit')}
                              className='w-5 h-5 text-gray-400 cursor-pointer'
                              onClick={() => {
                                setIsEdit(true);
                                setEditData({ id: item.id, name: item.name });
                                setOpenCreateModal(true);
                              }}
                            />
                            {/* @ts-ignore */}
                            <ReactTooltip
                              id='delete'
                              place='top'
                              type='dark'
                              effect='float'
                            />
                            <TrashIcon
                              data-for='delete'
                              data-tip={t('Delete')}
                              className='w-5 h-5 text-gray-400 cursor-pointer hover:text-red-500'
                              onClick={() => {
                                setSingleTag([item.id]);
                                setDeleteType('singleDelete');
                                setOpenDeleteModal(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                {(!tagList || tagList.length === 0) && (
                  <tr>
                    <td colSpan={7}>
                      {loadingTagList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <Spinner
                            color='border-r-transparent border-t-gray-300 border-b-gray-300 border-l-gray-300'
                            size='w-16 h-16'
                          />
                        </div>
                      )}
                      {!loadingTagList && (
                        <div className='w-full flex flex-col items-center justify-center min-h-[500px]'>
                          <img src={EmptyImage} className='w-40 mb-2' alt='' />
                          <p className='text-gray-400 text-sm'>
                            {t('You need to create a tag first')}
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        limit={
          sliceData.limit >= tagList.length ? tagList.length : sliceData.limit
        }
        offset={sliceData.offset}
        total={tagList.length}
        disablePrev={!tagList || tagList.length === 0 || sliceData.offset === 0}
        disableNext={sliceData.limit >= tagList.length}
        next={() => nextPage()}
        prev={() => prevPage()}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  loadingTagList: state.loading.effects.settings.fetchTags,
});
const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(TagList);
