import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageKey } from 'utilities/utils';

const TranslationContext = createContext<any>(null);

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch<any>();
  const dbLanguage = useSelector(
    (state: any) => state.auth?.dashboard_language || null
  );
  const activeNavId = useSelector((state: any) => state.dashboard?.navActiveId);

  const localStorageLanguage = localStorage.getItem('language') || 'english';

  const [dashboardLanguage, setDashboardLanguage] = useState<string>(
    dbLanguage || localStorageLanguage
  );

  const isRtlLanguage = ['arabic'].includes(dashboardLanguage);

  const updateLanguage = async (newLanguage: string) => {
    await dispatch.auth.updateStateData({
      key: 'dashboard_language',
      value: newLanguage,
    });
    setDashboardLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const getUrlParamValue = (): string | null => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('lang') || null;
  };

  useEffect(() => {
    if (dbLanguage !== localStorageLanguage) {
      localStorage.setItem('language', dbLanguage);
    }
    setDashboardLanguage(dbLanguage);
    // eslint-disable-next-line
  }, [dbLanguage]);

  useEffect(() => {
    const paramsValue = getUrlParamValue();
    if (paramsValue) {
      updateLanguage(getLanguageKey(paramsValue.trim().toLowerCase()));
    } else if (dbLanguage) {
      updateLanguage(dbLanguage);
    } else {
      updateLanguage(localStorageLanguage);
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const direction = dashboardLanguage === 'arabic' ? 'rtl' : 'ltr';
    document.body.setAttribute('dir', direction);
    return () => document.body.removeAttribute('dir');
  }, [dashboardLanguage]);

  return (
    <TranslationContext.Provider
      value={{ dashboardLanguage, updateLanguage, isRtlLanguage, activeNavId }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
export default TranslationProvider;
