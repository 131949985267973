import React from 'react';
import NoDataFound from '../../../assets/images/noDataFound3.svg';
import PropTypes from 'prop-types';

const NoDataAvailable = ({ message, height, width }) => (
  <div className='block'>
    <div
      className={'d-flex f-center'}
      style={{ width: '100%', height: height }}
    >
      <div className='centered-text'>
        <img src={NoDataFound} alt={'no data available'} width={width} />
        <p className='mt-4 font-sans text-sm text-gray-500 uppercase text-medium'>
          {message}
        </p>
      </div>
    </div>
  </div>
);

NoDataAvailable.propTypes = {
  message: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

NoDataAvailable.defaultProps = {
  message: 'Seems Like No Data Available Contact Mehran Kader to see data!',
  height: '100vh',
  width: '100%',
};

export default NoDataAvailable;
