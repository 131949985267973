import { nextRandomId, useDispatch } from 'pages/datalab/export';

const useTriggerActions = () => {
  const dispatch = useDispatch();

  const fetchDataLabInfo = (projectId: string, labId: string) => {
    dispatch.datalabFormBuilder.fetchDataLabInfo({ projectId, labId });
  };

  const updateDataLabInfo = (projectId: string, labId: string) => {
    dispatch.datalabFormBuilder.updateDataLabInfo({ projectId, labId });
  };

  const createDataLabField = (labId: string | number, data: any) => {
    let updatedData = { ...data };

    // temp: check if the field `type` is `group` then add the current time stemp with name
    if (data.type === 'group') {
      const currentTimestamp = new Date().getTime();
      updatedData = {
        ...updatedData,
        name: `Group_${currentTimestamp}`,
      };
    }

    dispatch.datalabFormBuilder.createDataLabField({
      labId: +labId,
      data: { ...updatedData },
    });
  };

  const addTempFieldToLabFields = (data: any) => {
    const fieldId = nextRandomId();
    dispatch.datalabFormBuilder.addTempFieldToLabFields({
      ...data,
      id: fieldId,
    });
  };

  const updateDataLabField = (
    labId: string | number,
    fieldId: string | number,
    data: any
  ) => {
    dispatch.datalabFormBuilder.updateDataLabField({
      labId,
      fieldId: fieldId,
      data,
    });
  };

  const deleteDataLabField = (
    labId: string | number,
    fieldId: string | number
  ) => {
    dispatch.datalabFormBuilder.deleteDataLabField({
      labId,
      fieldId: fieldId,
    });
  };

  const updateDataLabFieldOrder = (labId: string | number, data: any) => {
    dispatch.datalabFormBuilder.updateDataLabFieldOrder({
      labId,
      data,
    });
  };

  const updateUnsaveTracker = ({ isDirty = false, field = {} }) => {
    dispatch.datalabFormBuilder.updateUnsaveTracker({ isDirty, field });
  };

  const removeTempFieldToLabField = (name: string) => {
    dispatch.datalabFormBuilder.updateDeleteFieldByName(name);
  };

  return {
    fetchDataLabInfo,
    updateDataLabInfo,
    createDataLabField,
    updateDataLabField,
    deleteDataLabField,
    updateDataLabFieldOrder,
    updateUnsaveTracker,
    addTempFieldToLabFields,
    removeTempFieldToLabField,
  };
};

export default useTriggerActions;
