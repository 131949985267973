const LivechatDefaultData = {
  title: '',
  avatar: 'https://www.google.com/',
  is_published: true,
  connected_nlp_integration_id: '',
  whitelisted_domains: [],

  livechat_data: {
    brand_logo: '',
    brand_color_regular: '#10B981',
    font_color_regular: '#ffffff',
    is_dark_mode_enabled: false,
    is_dark_mode_default: false,
    brand_color_dark_mode: '#008000',
    font_color_dark_mode: '#008000',
    agent_avatar_display_options: 'all',
    should_show_myalice_branding: true,

    is_chat_head_logo_custom: false, //serializers.BooleanField(required=False, default=False)
    chat_head_id: 1, // serializers.IntegerField(required=False, default=1)
    chat_head_url: '', //serializers.CharField(required=False, allow_blank=True, default="")
    chat_head_text: 'Chat with us', //serializers.CharField(required=False, allow_blank=True, default="Chat with us")
    chat_head_size: 'large',
    chat_head_color: '#10B981',
    chat_head_position: 'right',
    chat_head_side_spacing: 32,
    chat_head_bottom_spacing: 32,

    live_chat_visibility_option: 'always',
    live_chat_button_label_text: '',

    social_chat_label_text: 'Chat on Social',
    social_chat_options_list: [],

    isOnline: true,
    header_title: 'Hi there! 👋',
    greetings_view_size: 'minimal',
    header_subtext: 'Lorem Ipsum Dolor',
    header_online_message: 'Available now',
    header_offline_message: 'Temporarily unavailable',

    welcome_prompt_visibility_option: 'always',
    welcome_prompt_online_text:
      'Let us know if we can help you with anything at all.',
    welcome_prompt_offline_text:
      'So we’re away right now, but if you leave us a message we’ll get back to you soon.',
    should_enable_welcome_prompt_mobile: true,

    prechat_survey_visibility_option: 'always',
    prechat_survey_message:
      "To make sure we know who we're talking with, please share the following details so that we can follow up on your query in case you leave the chat.",
    prechat_survey_data_points: [
      {
        title: 'Email',
        attribute: '{{email}}',
      },
      {
        title: 'Full Name',
        attribute: '{{full_name}}',
      },
    ],

    faq_visibility_option: 'always',
    faq_title: 'AMA!',
    faq_pages: [],

    should_play_sound_for_new_messages: true,
    should_allow_incoming_attachments: true,
    should_allow_outgoing_attachments: true,
    should_allow_access_to_conversation_history: true,
    widget_loading_delay: 0,
  },
};

export default LivechatDefaultData;
