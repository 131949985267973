import React from 'react';
import Alert from '../../../old/components/elements/redesign/Alert';
import PasswordField from '../../../components/utilityComponent/PasswordField';

import { Button, Link } from '../../../library';

interface Props {
  email: string;
  password: string;
  firstName: string;
  fullName: string;
  lastName: string;
  content: any;
  handleChange: (name: string, value: string) => void;
  handleSubmit: () => void;
  loading: boolean;
  isDuplicateEmail: boolean;
  isInvalidEmail: boolean;
  isInvalidPassword: boolean;
  isFullNameEmpty: boolean;
  isPhoneEmpty: boolean;
  errorMessage: string;
  hasAppSumoInfo: boolean;
  disableEmail: boolean;
  businessUrl: string;
  phone: string;
  selectedCountry: CountryDataInterface;
}

const ExpoRegisterForm: React.FC<Props> = ({
  email,
  password,
  firstName,
  lastName,
  fullName,
  content,
  handleChange,
  handleSubmit,
  loading,
  isDuplicateEmail = false,
  isInvalidEmail = false,
  isInvalidPassword = false,
  isFullNameEmpty = false,
  errorMessage = '',
  hasAppSumoInfo = false,
  disableEmail = false,
  businessUrl,
  phone,
  selectedCountry,
  isPhoneEmpty = false,
}) => {
  return (
    <div className='mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='space-y-4'>
        <div>
          <label
            htmlFor='full_name'
            className='block text-sm font-medium text-gray-700'
          >
            <span className='mb-1'>Full Name</span>
            <input
              id='full_name'
              name='fullName'
              type='name'
              autoComplete='name'
              value={fullName || ''}
              placeholder='John Doe'
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
            {isFullNameEmpty && errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={errorMessage} />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            <span className='mb-1'>Email Address</span>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              disabled={disableEmail}
              value={email || ''}
              placeholder='you@example.com'
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
            {isInvalidEmail && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={errorMessage} />
              </div>
            ) : (
              ''
            )}
            {isDuplicateEmail ? (
              <div className='mt-3'>
                <Alert
                  intent='danger'
                  text='A user with this email already exists.'
                />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor='Phone'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='flex justify-between mb-1'>
              <span>Phone</span>
            </div>
            <div className='mt-1 rounded-md'>
              <input
                type='number'
                name='phone'
                value={phone || ''}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder='Type here...'
                required
              />
              {isPhoneEmpty && !!errorMessage && (
                <div className='mt-3'>
                  <Alert intent='danger' text={errorMessage} />
                </div>
              )}
            </div>
          </label>
        </div>
        <div>
          <label
            htmlFor='form'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='mb-1'>Password</div>
            <PasswordField
              password={password}
              handleChange={(name: string, value: string) =>
                handleChange(name, value)
              }
              handleSubmit={handleSubmit}
            />
            {isInvalidPassword && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={errorMessage} />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <Button
          onClick={() => handleSubmit()}
          isFullWidth={true}
          isLoading={loading}
          intent='primary'
        >
          Sign up
        </Button>
        <div className='flex justify-center'>
          <label htmlFor='remember_me' className='block text-sm text-gray-500'>
            {content.signupConfirmationInfo.text}{' '}
            <Link url={content.signupConfirmationInfo.link} openInNewTab={true}>
              {content.signupConfirmationInfo.linkTitle}
            </Link>
          </label>
        </div>
      </div>
    </div>
  );
};
export default ExpoRegisterForm;
