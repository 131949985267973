import { LiveChatDataProps, nlpInfoProps } from 'pages/integration/interface';
import { useState } from 'react';
import { classNames } from 'utilities/utils';
import InputTag from '../../common/InputTag';
import SingleOptionItem from '../../common/SingleOptionItem';

import { NotificationSound as audio } from 'utilities/utils';
import { Popover, Transition } from '@headlessui/react';
import ChevronUpDown from 'pages/inbox/assets/iconComponent/ChevronUpDownIcon';
import { CheckIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  updateLiveChatProperty: (key: string, value: any) => void;
  updateBrandData: (key: string, value: any) => void;
  integratedNlpList: nlpInfoProps[];
  liveChatData: LiveChatDataProps;
}

const AdvanceOptionPanel: React.FC<Props> = ({
  liveChatData,
  updateBrandData,
  integratedNlpList,
  updateLiveChatProperty,
}) => {
  const { t } = useTranslation();
  const [isSoundPlaying, setIsSoundPlaying] = useState<boolean>(false);
  audio.onended = () => {
    setIsSoundPlaying(false);
  };
  const playNotificationSound = () => {
    setIsSoundPlaying(true);
    audio.play();
  };
  const audioPlayLabel = () => {
    return isSoundPlaying ? t('Sound is playing') : t('Play Sound');
  };

  const getPopoverView = () => {
    if (!!integratedNlpList && integratedNlpList?.length > 0) {
      return integratedNlpList.map((item: nlpInfoProps, index: number) => {
        return (
          <div
            className='w-full p-2 flex items-center cursor-pointer'
            key={item.id + index}
            onClick={() => {
              updateBrandData('connected_nlp_integration_id', item.id);
            }}
          >
            <span
              className={classNames(
                'text-sm leading-5',
                liveChatData.connected_nlp_integration_id === item.id
                  ? 'font-semibold text-gray-900'
                  : 'font-normal text-gray-600'
              )}
            >
              {item.name}
            </span>
            <CheckIcon
              height={20}
              className={classNames(
                'ltr:ml-auto rtl:mr-auto',
                liveChatData.connected_nlp_integration_id === item.id
                  ? 'inline-block text-gray-900'
                  : 'hidden'
              )}
            />
          </div>
        );
      });
    } else {
      return '';
    }
  };
  const getNlpDropDownText = () => {
    if (liveChatData.connected_nlp_integration_id === '') {
      return 'Select NLP App';
    } else {
      const selectedItem = integratedNlpList.filter(
        (item) => item.id === liveChatData?.connected_nlp_integration_id
      );
      return selectedItem.length > 0 ? selectedItem[0].name : 'Select NLP App';
    }
  };
  return (
    <div className='border border-gray-200 rounded-md'>
      <div className='w-full flex p-4 text-base font-semibold text-gray-600 bg-gray-100 border-b border-gray-200 rounded-t-md'>
        {t('Advanced Options')}
      </div>
      <div className='py-2 mt-1 border-b border-gray-200'>
        <div className='p-4'>
          <div className='flex'>
            <label
              htmlFor='url_whitelist'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Whitelist Domain')}
            </label>
          </div>
          <p className='my-4 flex rtl:text-right text-sm font-normal leading-5 text-gray-500'>
            {t(
              'Your widget will only appear on domains added here (which have the code snippet or plugin installed). Enter an authorized domain below, then press ADD DOMAIN.'
            )}
          </p>
          <InputTag
            handleChange={(key: string, value: string | string[] | boolean) => {
              updateBrandData(key, value);
            }}
            tagList={liveChatData.whitelisted_domains}
          />
        </div>
      </div>
      <div className='py-4 mt-1 border-b border-gray-200'>
        <SingleOptionItem
          title={t('Play sound for new messages')}
          subTitle={
            <p className='pb-1 text-sm font-normal leading-5 text-gray-500'>
              {t(
                'Notification sound for pop-up message and replies from agents.'
              )}{' '}
              <span
                className={classNames(
                  isSoundPlaying ? 'text-green-500' : 'text-blue-400',
                  'text-sm font-normal  cursor-pointer'
                )}
                onClick={() => {
                  if (!isSoundPlaying) playNotificationSound();
                }}
              >
                {t(audioPlayLabel())}
              </span>
            </p>
          }
          value={liveChatData.livechat_data.should_play_sound_for_new_messages}
          handleOnChange={(val: boolean) => {
            updateLiveChatProperty('should_play_sound_for_new_messages', val);
          }}
        />
      </div>
      <div className='py-4 mt-1 border-b border-gray-200'>
        <SingleOptionItem
          title={t('Allow incoming attachments')}
          subTitle={
            <p className='pb-1 text-sm font-normal leading-5 text-gray-500'>
              {t('Let your visitors send you attachments in conversations.')}
            </p>
          }
          warningMsg={t('Attachment icon in widget will be hidden')}
          value={liveChatData.livechat_data.should_allow_incoming_attachments}
          handleOnChange={(val: boolean) => {
            updateLiveChatProperty('should_allow_incoming_attachments', val);
          }}
        />
      </div>

      <div className='py-4 flex mt-1 border-b border-gray-200'>
        <SingleOptionItem
          title={t('Allow access to conversation history')}
          subTitle={
            <p className='pb-1 rtl:text-right  text-sm font-normal leading-5 text-gray-500'>
              {t(
                'Visitors will be able to see and chat back into any previous conversations that are still open.'
              )}
            </p>
          }
          warningMsg={t(
            "Customers can't access previous conversations once they leave your site "
          )}
          value={
            liveChatData.livechat_data
              .should_allow_access_to_conversation_history
          }
          handleOnChange={(val: boolean) => {
            updateLiveChatProperty(
              'should_allow_access_to_conversation_history',
              val
            );
          }}
        />
      </div>
      <div className='p-4 mt-1'>
        <span className='text-sm flex font-medium leading-5'>
          {t('Select NLP App')}
        </span>
        <Popover>
          <Popover.Button
            disabled={!integratedNlpList || integratedNlpList?.length < 1}
            className={
              'w-full border border-gray-300 px-3 py-2 flex items-center rounded-md shadow-sm mt-2'
            }
          >
            <span className='text-gray-500 text-sm font-normal'>
              {t(getNlpDropDownText())}
            </span>
            <ChevronUpDown className='ltr:ml-auto rtl:mr-auto h-4' />
          </Popover.Button>
          <Transition
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
          >
            <Popover.Overlay
              className={'w-full shadow-lg border bg-white rounded-md p-1 mt-1'}
            >
              {getPopoverView()}
            </Popover.Overlay>
          </Transition>
        </Popover>
      </div>
      {/* <div className='p-4 mt-1 border-b border-gray-200'>
        <p className='text-sm font-medium leading-5 text-gray-700'>
          Load the chat widget after a delay
        </p>

        <div className='mt-4'>
          <div className='flex items-center'>
            <div
              className={classNames(
                liveChatData.livechat_data.widget_loading_delay <= 0
                  ? 'bg-gray-300'
                  : 'bg-white',
                'flex items-center border m-0 text-center  rounded-l-md border-gray-300 h-9 w-7 cursor-pointer'
              )}
              onClick={() => {
                const currentValue =
                  liveChatData.livechat_data.widget_loading_delay <= 0
                    ? 0
                    : liveChatData.livechat_data.widget_loading_delay - 1;
                updateLiveChatProperty('widget_loading_delay', currentValue);
              }}
            >
              <span className='m-auto text-lg font-medium text-gray-500'>
                -
              </span>
            </div>
            <input
              type='number'
              value={liveChatData.livechat_data.widget_loading_delay}
              min='0'
              className='inline-block w-16 m-0 text-center border-t border-b border-l-0 border-r-0 border-gray-300 h-9 focus:ring-0 focus:outline-none focus:border-gray-300'
              onChange={(event) => {
                updateLiveChatProperty(
                  'widget_loading_delay',
                  event.target.value
                );
              }}
            />
            <div
              className='flex items-center m-0 text-center border border-gray-300 cursor-pointer rounded-r-md h-9 w-7'
              onClick={() => {
                updateLiveChatProperty(
                  'widget_loading_delay',
                  Number(liveChatData.livechat_data.widget_loading_delay) +
                    Number(1)
                );
              }}
            >
              <span className='m-auto text-lg font-medium text-gray-500'>
                +
              </span>
            </div>
            <span className='ml-4 text-sm font-normal leading-5 text-gray-700'>
              Seconds
            </span>
          </div> */}

      {/* <p className='mt-4 text-sm font-normal leading-5 text-gray-500'>
            Your embeded livechat will be visible immediately after your website
            loads completely
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default AdvanceOptionPanel;
