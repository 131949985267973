import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

const CsatProgressCircle = ({ radious = 100, barSize = 15, progress = 0 }) => {
  const data = [{ name: 'Overall Csat', value: progress }];
  const circleSize = 2 * radious + barSize;
  return (
    <RadialBarChart
      width={circleSize}
      height={circleSize}
      cx={circleSize / 2}
      cy={circleSize / 2}
      innerRadius={radious}
      outerRadius={radious}
      barSize={barSize}
      data={data}
      startAngle={90}
      endAngle={-270}
    >
      <PolarAngleAxis
        type='number'
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        background
        dataKey='value'
        cornerRadius={circleSize / 2}
        fill='#04B25F'
      />
      <text
        x={circleSize / 2}
        y={circleSize / 2}
        textAnchor='middle'
        dominantBaseline='middle'
        className='progress-label'
        style={{ fontSize: '30px', fontWeight: 600, color: '#18181B' }}
      >
        {progress}%
      </text>
      <text
        x={circleSize / 2}
        y={circleSize / 2 + 30}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{ fontSize: '12px', fontWeight: 400, fill: '#71717A' }}
      >
        Overall CSAT Achieved
      </text>
    </RadialBarChart>
  );
};

export default CsatProgressCircle;
