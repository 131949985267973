import React, { useEffect } from 'react';
import copyIcon from '../../../assets/images/copyIcon.svg';
import checkIcon from '../../../assets/images/checkIcon.svg';
import { copyClipboard } from '../../../../../utilities/utils';

interface Prop {
  height?: number;
  textToCopy?: any;
}

const CopyText: React.FC<Prop> = ({ height = 18, textToCopy = '' }) => {
  const [timeLeft, setTimeLeft] = React.useState<any>(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleCopy = () => {
    // alert(textToCopy);
    copyClipboard(textToCopy);
    setTimeLeft(5);
  };
  return (
    <>
      <button
        disabled={timeLeft > 0}
        onClick={() => handleCopy()}
        className='items-center'
      >
        <img
          src={timeLeft > 0 ? checkIcon : copyIcon}
          width={height}
          className='background-none'
          alt='copy'
        />
      </button>
    </>
  );
};

export default CopyText;
