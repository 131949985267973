import React, { ChangeEvent, useEffect, useState } from 'react';
import PermissionProjectHeader from '../component/PermissionProjectHeader';
import PendingProjectTable from '../component/PendingProjectTable';
import { SearchInput, Spinner } from 'evergreen-ui';
import { connect } from 'react-redux';
import pendingIcon from '../../../assets/images/pendingIcon-settings.svg';
import NoDataFragment from '../component/NoDataFraagment';

interface project {
  id: number;
  name: string;
  slug: string;
  image: string;
}

interface permission {
  id: number;
  short_code: string;
  verbose: string;
}

interface role {
  id: number;
  name: string;
  permissions: permission[];
}

interface pendingproject {
  id: number;
  project: project;
  role: role;
}

interface props {
  fetchPendingProjects: () => void;
  pendingProjects: pendingproject[];
  editPendingProject: (id: number, accept: boolean) => void;
  loader: boolean;
  fetchPendingProjectLoader: boolean;
}

const PendingProjectSettings: React.FC<props> = ({
  fetchPendingProjects,
  pendingProjects,
  editPendingProject,
  loader,
  fetchPendingProjectLoader,
}) => {
  useEffect(() => {
    fetchPendingProjects();
    setIsMounted(true);
    // eslint-disable-next-line
  }, []);
  const [filter, setFilter] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  return (
    <div className='permission-project-settings'>
      <PermissionProjectHeader
        title='Pending Projects'
        subtitle='You will find projects that has your access but yet not approved by you'
        icon={pendingIcon}
        content={
          <SearchInput
            placeholder={'Search Projects...'}
            className='alice-rd-search'
            onKeyDown={(e: ChangeEvent<HTMLInputElement> | any) => {
              e.keyCode === 13 && setFilter(e.target.value);
            }}
          />
        }
      />
      {(!isMounted || loader || fetchPendingProjectLoader) && (
        <div className='permission-loader'>
          <Spinner />
        </div>
      )}
      {isMounted && pendingProjects.length === 0 && !loader && (
        <NoDataFragment title='All Pending Project Cleared' />
      )}
      {isMounted && pendingProjects.length > 0 && !loader && (
        <PendingProjectTable
          data={pendingProjects.filter((pendingProject) =>
            pendingProject.project?.name
              ?.toLowerCase()
              .includes(filter.toLowerCase())
          )}
          updatePermission={editPendingProject}
        />
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  pendingProjects: state.settings.pendingProjects,
  loader: state.loading.effects.settings.editPendingProject,
  fetchPendingProjectLoader:
    state.loading.effects.settings.fetchPendingProjects,
});

const mapDispatch = (dispatch: any) => ({
  fetchPendingProjects: () => dispatch.settings.fetchPendingProjects(),
  editPendingProject: (id: number, accept: boolean) =>
    dispatch.settings.editPendingProject({ project_id: id, accept }),
});

const PendingProjectSettingsContainer = connect(
  mapState,
  mapDispatch
)(PendingProjectSettings);

export default PendingProjectSettingsContainer;
