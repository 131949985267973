import { useDispatch, useSelector } from 'pages/automationWorkflow/export';

const useAutomationLog = () => {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const isAutomationLogLoading = useSelector(
    (state: any) => state.loading.models.workflowAutomation
  );
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );

  const selectedLogSummary = workflowAutomationData?.selectedLogSummary;

  // Destructure required state loading from the workflowAutomation
  const {
    getSelectedUserActionFlowLogList: isGetSelectedUserActionFlowLogListLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  const fetchSelectedLogDetails = (flowId: number) => {
    dispatch.workflowAutomation.getSingleWorkflowLog({
      flowId: flowId,
      projectId: selectedProject?.id,
    });
  };

  const updateAutomationflowViewType = (history_id: number) => {
    fetchSelectedLogDetails(history_id);
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'automationFlowDisplayViewType',
      value: 'viewLog',
    });
    const selectedLogHistory: any[] =
      workflowAutomationData?.selectedAutomationLogList?.history_list || [];
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedLogSummary',
      value: selectedLogHistory.find((v) => v?.history_id === history_id) ?? {},
    });
  };

  return {
    selectedLogSummary,
    isAutomationLogLoading,
    fetchSelectedLogDetails,
    updateAutomationflowViewType,
    isGetSelectedUserActionFlowLogListLoading,
  };
};

export default useAutomationLog;
