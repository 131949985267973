import React from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import {
  categoryTypes,
  whatsappTemplateTypes,
  WhatsappTemplateLanguageList,
  WhatsappTemplateMangeActionPermissions,
} from 'pages/integration/utils/content';

import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';

import {
  WhatsappSingleSelectMenu,
  WhatsappSingleSearchableSelectMenu,
} from './WhatsappTemplateSelectionMenu';
import useTranslation from 'components/customHooks/useTranslation';

interface WhatsappTemplateHeaderMenuProps {
  actionType: WhatsappTemplateActionType;
}
const whatsappTemplateHeaderTypes = [
  { label: 'None', value: '' },
  { label: 'Text', value: 'text' },
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'Document', value: 'document' },
];

const WhatsappTemplateHeaderMenu: React.FC<WhatsappTemplateHeaderMenuProps> = ({
  actionType,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateTemplateName,
    updateTemplateCategory,
    updateTemplateLanguage,
    updateTemplateHeaderType,
    updateTemplateType,
  } = useWhatsappTemplateData();
  const LanguageList = Object.keys(WhatsappTemplateLanguageList);
  const isCarouselType = whatsappTemplateState.templateType === 'CAROUSEL';
  const isTemplateActionView = actionType === 'VIEW';
  const isTemplateActionEdit = actionType === 'EDIT';
  const isAuthenticationTemplate =
    whatsappTemplateState?.category === 'AUTHENTICATION';

  const handleTemplateNameChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateTemplateName(ev.target.value);
  };
  const handleCategorySelect = (value: string) => {
    updateTemplateCategory(value);
  };
  const handleLanguageSelect = (value: string) => {
    updateTemplateLanguage(value);
  };
  const handleHeaderTypeSelect = (value: string) => {
    updateTemplateHeaderType(value);
  };

  const shouldHideTemplateHeader = isAuthenticationTemplate || isCarouselType;

  const handleTemplateTypeSelect = (value: string) => {
    if (value === 'CAROUSEL') {
      handleCategorySelect('MARKETING');
      handleHeaderTypeSelect('image');
    }
    updateTemplateType(value);
  };

  const renderTemplateNameInput = () => {
    return (
      <div className='col-span-3'>
        <label
          htmlFor='template-name'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Template Name')}
          <span className='text-red-500'>*</span>
        </label>
        <input
          type='text'
          placeholder={t('Enter Template name')}
          disabled={isTemplateActionView || isTemplateActionEdit}
          value={whatsappTemplateState.name}
          onChange={handleTemplateNameChange}
          name='template-name'
          className={`mt-1 w-full border-gray-300  text-sm rounded-md shadow-sm focus:ring-primary focus:border-primary
              ${
                isTemplateActionView || isTemplateActionEdit
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-white text-gray-700'
              }`}
        />
      </div>
    );
  };

  const renderTemplateCategorySelect = () => {
    let allowedCategories = categoryTypes
      .filter((t) => {
        switch (t) {
          case 'AUTHENTICATION': {
            if (isCarouselType) {
              return false;
            }
            return !WhatsappTemplateMangeActionPermissions.categoryEdit.includes(
              whatsappTemplateState?.status
            );
          }
          default: {
            return true;
          }
        }
      })
      .map((v) => ({
        label: v,
        value: v,
      }));

    const shouldDisableCateorySelection = whatsappTemplateState?.status ?
      isTemplateActionView ||
      !WhatsappTemplateMangeActionPermissions.categoryEdit.includes(
        whatsappTemplateState?.status
      ) : false;

    return (
      <div className='col-span-3'>
        <label
          htmlFor='category'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Category')}
          <span className='text-red-500'>*</span>
        </label>

        <WhatsappSingleSelectMenu
          placeholder={t('Select category')}
          onChange={handleCategorySelect}
          value={whatsappTemplateState.category}
          optionLists={allowedCategories}
          isDisabled={shouldDisableCateorySelection}
        />
      </div>
    );
  };

  const renderHeaderTypeSelect = () => {
    return (
      <div className='col-span-6'>
        <label
          htmlFor='header-type'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Header Type (Optional)')}
        </label>
        <WhatsappSingleSelectMenu
          placeholder={t('Select category')}
          onChange={handleHeaderTypeSelect}
          value={whatsappTemplateState.headerType as string}
          optionLists={whatsappTemplateHeaderTypes}
          isDisabled={isTemplateActionView}
        />
      </div>
    );
  };

  const renderTepmplateTypeSelect = () => {
    return (
      <div className='col-span-6 sm:col-span-3'>
        <label
          htmlFor='header-type'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Template Type')}
          <span className='text-red-500'>*</span>
        </label>
        <WhatsappSingleSelectMenu
          placeholder={t('Select Template Type')}
          onChange={handleTemplateTypeSelect}
          value={whatsappTemplateState.templateType as string}
          optionLists={whatsappTemplateTypes.map((v) => ({
            label: v,
            value: v,
          }))}
          isDisabled={isTemplateActionEdit || isTemplateActionView}
        />
      </div>
    );
  };

  const renderLanguageSelect = () => {
    return (
      <div className={'col-span-3'}>
        <label
          htmlFor='language'
          className='block text-sm font-medium mb-1 text-gray-700 '
        >
          {t('Language')}
          <span className='text-red-500'>*</span>
        </label>
        <WhatsappSingleSearchableSelectMenu
          searchFieldPlaceholder={t('Search Language')}
          optionList={LanguageList.map((lang) => ({
            label: lang,
            value: WhatsappTemplateLanguageList[lang],
          }))}
          value={whatsappTemplateState.language ?? ''}
          onChange={handleLanguageSelect}
          shouldDisableField={isTemplateActionView}
        />
      </div>
    );
  };

  return (
    <div className='p-4 mb-2 shadow-sm bg-white rounded-md'>
      <div className='grid grid-cols-6 gap-4'>
        {renderTemplateNameInput()}
        {renderLanguageSelect()}
        {renderTemplateCategorySelect()}
        {renderTepmplateTypeSelect()}
        {shouldHideTemplateHeader ? null : renderHeaderTypeSelect()}
      </div>
    </div>
  );
};

export default WhatsappTemplateHeaderMenu;
