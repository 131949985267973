import { cn } from 'libraryV2/utils';
import React, { forwardRef, memo } from 'react';

type sizeProps = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type intentProps =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'primary-outlined'
  | 'default'
  | 'minimal';

interface Prop {
  size?: sizeProps;
  isFullWidth?: boolean;
  intent?: intentProps;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  isCapsuleButton?: boolean;
  shouldHaveFocusRing?: boolean;
  iconInRight?: boolean;

  [key: string]: any;
}

const Button: React.FC<Prop> = forwardRef<HTMLButtonElement, Prop>(
  (
    {
      size = 'md',
      intent = 'default',
      icon,
      iconInRight = false,
      isDisabled = false,
      isLoading = false,
      children,
      isFullWidth = false,
      className,
      shouldHaveFocusRing = false,
      isCapsuleButton = false,
      ...rest
    },
    ref
  ) => {
    const getSize = (size: sizeProps) => {
      switch (size) {
        case 'xs':
          return 'px-2.5 py-1.5 text-xs rounded';
        case 'sm':
          return 'px-3 py-1.5 text-sm rounded-md';
        case 'lg':
          return 'px-4 py-2 text-base rounded-md';
        case 'xl':
          return 'px-6 py-3 text-base rounded-md';
        case 'md':
        default:
          return 'px-4 py-2 text-sm  rounded-md';
      }
    };
    const getIntent = (intent: intentProps, isDisabled: boolean) => {
      switch (intent) {
        case 'primary':
          return `shadow-sm border-transparent${
            isDisabled
              ? ' bg-gray-200 hover:bg-gray-300 cursor-not-allowed text-gray-400'
              : `${
                  shouldHaveFocusRing
                    ? ` focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`
                    : ''
                } bg-primary hover:bg-primary-hover text-white`
          }`;
        case 'secondary':
          return `shadow-sm border-transparent${
            isDisabled
              ? ' bg-gray-200 hover:bg-gray-300 cursor-not-allowed text-gray-400'
              : `${
                  shouldHaveFocusRing
                    ? ` focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500`
                    : ''
                } bg-yellow-500 hover:bg-yellow-600 text-white`
          }`;
        case 'danger':
          return `shadow-sm border-transparent${
            isDisabled
              ? ' bg-gray-200 hover:bg-gray-300 cursor-not-allowed text-gray-400'
              : `${
                  shouldHaveFocusRing
                    ? ` focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600`
                    : ''
                } bg-red-600 hover:bg-red-700 text-white`
          }`;
        case 'primary-outlined':
          return `shadow-sm border-transparent${
            isDisabled
              ? ' bg-gray-200 hover:bg-gray-300 cursor-not-allowed text-gray-400 '
              : `${
                  shouldHaveFocusRing
                    ? ` focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200`
                    : ''
                } bg-green-200 text-primary hover:border-primary-hover`
          }`;
        case 'minimal':
          return `shadow-none border-transparent box-shadow-none text-gray-300${
            isDisabled
              ? ' bg-transparent hover:bg-gray-300 hover:border-gray-300 cursor-not-allowed text-gray-400'
              : `${
                  shouldHaveFocusRing
                    ? ` focus:outline-none focus:ring-2 focus:ring-offset-2`
                    : ''
                } bg-transparent text-gray-700`
          }`;
        case 'default':
        default:
          return `border-gray-300${
            isDisabled
              ? ' bg-gray-200 hover:bg-gray-300 hover:border-gray-300 cursor-not-allowed text-gray-400'
              : ' bg-white hover:border-primary'
          }`;
      }
    };

    const getIconClassName = (size: sizeProps) => {
      switch (size) {
        case 'xs':
          if (children) {
            return '-ml-0.5 mr-1 h-4 w-4';
          } else {
            return 'h-4 w-4';
          }
        case 'sm':
          if (children) {
            return '-ml-1 mr-1.5 rtl:ml-1.5 rtl:mr-0 h-5 w-5';
          } else {
            return 'h-5 w-5';
          }
        case 'lg':
          if (children) {
            return 'ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2 h-5 w-5';
          } else {
            return 'h-5 w-5';
          }
        case 'xl':
          if (children) {
            return '-ml-1 mr-3 h-6 w-6';
          } else {
            return 'h-6 w-6';
          }

        case 'md':
        default:
          if (children) {
            return 'ltr:-ml-1 rtl:-mr-1 ltr:mr-1 rtl:ml-1 h-5 w-5';
          } else {
            return 'h-5 w-5';
          }
      }
    };

    const iconAlignment = () => {
      if (iconInRight) {
        return (
          <>
            {!!children && children}{' '}
            {!!icon && (
              <span
                className={`${getIconClassName(size)} `}
                data-testid='icon-id-2'
                style={{ marginLeft: '5px' }}
              >
                {' '}
                {icon}
              </span>
            )}
          </>
        );
      } else {
        return (
          <>
            {!!icon && (
              <span
                className={`${getIconClassName(size)}`}
                data-testid='icon-id-1'
              >
                {' '}
                {icon}
              </span>
            )}{' '}
            {!!children && children}
          </>
        );
      }
    };

    return isCapsuleButton ? (
      <button
        ref={ref}
        data-testid='button-element'
        disabled={isDisabled || isLoading}
        className={cn(
          `inline-flex items-center bg-inherit rounded-md p-1 text-center justify-center font-medium outline-none focus:outline-none transition ease-out duration-200`,
          !!className ? className : ''
        )}
        {...rest}
      >
        {iconAlignment()}
      </button>
    ) : (
      <button
        data-testid='button-element'
        ref={ref}
        disabled={isDisabled || isLoading}
        className={`inline-flex items-center text-center ${getSize(size)} ${
          !!isFullWidth ? 'w-full flex justify-center' : ''
        } border font-medium outline-none ${getIntent(
          intent,
          isDisabled || isLoading
        )} focus:outline-none transition ease-out duration-200 ${
          !!className ? className : ''
        }`}
        {...rest}
      >
        {iconAlignment()}
      </button>
    );
  }
);

export default memo(Button);
