import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { capitalize } from 'lodash';
import { Plus, X } from 'lucide-react';
import useWhatsappTemplateData, {
  TCarouselCardHeaderType,
} from 'pages/integration/hooks/useWhatsappTemplateData';
import { sequenceProps } from 'pages/integration/interface';
import { WHATSAPP_CAROUSEL_CARD_LIMIT } from 'pages/integration/utils/content';
import { FC, useState } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import CarouselTemplateCard from './CarouselTemplateCard';

interface WhatsappCarouselTemplateProps {
  actionType: WhatsappTemplateActionType;
  sequence: sequenceProps[];
}

export const WhatsappCarouselTemplate: FC<WhatsappCarouselTemplateProps> = ({
  actionType,
  sequence,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateActiveCarouselCard,
    addNewCarouselCard,
    deleteCarouselCard,
    updateCarouselTemplateHeaderType,
  } = useWhatsappTemplateData();
  const [deleteCardData, setDeleteCardData] = useState<{
    openDeleteModal: boolean;
    cardId: number | undefined;
  }>({ openDeleteModal: false, cardId: undefined });

  const isTemplateActionView = actionType === 'VIEW';
  const { cards: cardList, activeCarouselCardIndex } = whatsappTemplateState;

  const carouselHeaderType = whatsappTemplateState?.headerType;

  const getCardToolTip = (
    currentInputType: TCarouselCardHeaderType = 'image'
  ) => {
    const shouldHaveTooltip =
      currentInputType === carouselHeaderType || activeCarouselCardIndex === 1;
    if (shouldHaveTooltip) {
      return null;
    }
    const oppositeHeaderType =
      carouselHeaderType === 'image' ? 'video' : 'image';
    return t(
      `${t('First card set with an')} ${t(carouselHeaderType ?? '')} ${t(
        'header—keeping it consistent! To use'
      )} ${t(oppositeHeaderType ?? '')} ${t(
        'headers in this carousel, select'
      )} '${t(capitalize(oppositeHeaderType))}' ${t('for the initial card.')}`
    );
  };

  const renderCarouselCardAddBtn = () => {
    const shouldRenderAddButton =
      cardList.length < WHATSAPP_CAROUSEL_CARD_LIMIT && actionType !== 'VIEW';
    return (
      <div className='w-full relative flex gap-3 flex-wrap'>
        {cardList.map((v) => (
          <div
            key={Math.random()}
            className='group relative w-fit rounded-md h-fit'
          >
            <Button
              variant={'default'}
              onClick={() => {
                updateActiveCarouselCard(v.id);
              }}
              className={cn(
                ' bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 hover:text-textPrimary shadow-sm h-10 w-10',
                {
                  'bg-primary hover:bg-primary text-white hover:text-white':
                    v.id === activeCarouselCardIndex,
                }
              )}
            >
              {v.id}
            </Button>
            {v.id === 1 || isTemplateActionView ? null : (
              <Button
                variant={'default'}
                className='absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white hover:border-none h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5'
                onClick={() => toggleDeleteCardModal(v.id)}
              >
                <X strokeWidth={2.5} className='w-3.5 h-3.5 ' />
              </Button>
            )}
          </div>
        ))}

        {shouldRenderAddButton ? (
          <Button
            variant={'default'}
            type='button'
            onClick={addNewCarouselCard}
            className=' bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 hover:text-textPrimary shadow-sm h-10 w-10 p-3'
          >
            <Plus strokeWidth={3} className='h-5 w-5' />
          </Button>
        ) : null}
      </div>
    );
  };

  const renderCardHeaderTypeSelctor = () => {
    const shouldDisableVideo =
      carouselHeaderType === 'image' && activeCarouselCardIndex !== 1;
    const shouldDisableImage =
      carouselHeaderType === 'video' && activeCarouselCardIndex !== 1;
    const cardHeaderTypes: TCarouselCardHeaderType[] = ['image', 'video'];

    return (
      <div className='flex flex-col gap-2'>
        <p>
          <span>{t('Header Type')}</span>
          <span className='text-[#F36363]'>*</span>
        </p>
        <div className='flex gap-3'>
          {cardHeaderTypes.map((inputType) => {
            const disabledType =
              inputType === 'video' ? shouldDisableVideo : shouldDisableImage;
            return (
              <TooltipProviderCustomised
                key={inputType}
                content={getCardToolTip(inputType)}
              >
                <div
                  className={cn(
                    'flex gap-3 items-center border border-gray-300 p-4 rounded-md',
                    {
                      'border-[#36C17F]': carouselHeaderType === inputType,
                      'border-gray-200 bg-white cursor-not-allowed':
                        disabledType,
                    }
                  )}
                >
                  <input
                    id={`carosuel-card-header-${inputType}`}
                    name={'header-type'}
                    value={inputType}
                    type='radio'
                    className={cn(
                      'without-ring h-4 w-4 text-[#04B25F] border-gray-300',
                      {
                        'cursor-not-allowed': disabledType,
                      }
                    )}
                    disabled={
                      activeCarouselCardIndex !== 1 || isTemplateActionView
                    }
                    onChange={() => {
                      updateCarouselTemplateHeaderType(inputType);
                    }}
                    checked={carouselHeaderType === inputType}
                  />
                  <label
                    htmlFor={`carosuel-card-header-${inputType}`}
                    className={cn(
                      'flex gap-2 text-sm font-medium text-gray-700',
                      {
                        'cursor-not-allowed text-gray-300': disabledType,
                      }
                    )}
                  >
                    <span>
                      {inputType === 'video' ? (
                        <VideoCameraIcon className='h-5 w-5' />
                      ) : (
                        <PhotoIcon className='h-5 w-5' />
                      )}
                    </span>
                    <span className='text-sm font-medium'>
                      {t(capitalize(inputType))}
                    </span>
                  </label>
                </div>
              </TooltipProviderCustomised>
            );
          })}
        </div>
      </div>
    );
  };

  const toggleDeleteCardModal = (cardId?: number | undefined) => {
    if (cardId !== undefined && !deleteCardData.openDeleteModal) {
      setDeleteCardData({ openDeleteModal: true, cardId: cardId });
    } else {
      setDeleteCardData({ openDeleteModal: false, cardId: undefined });
    }
  };

  const handleDeleteCarouselCard = () => {
    if (deleteCardData.cardId) {
      deleteCarouselCard(deleteCardData.cardId);
    }
    toggleDeleteCardModal();
  };

  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteCardData.openDeleteModal}>
        <DialogContent
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 text-lg font-semibold leading-7'>
            {t('Delete Card')} {deleteCardData.cardId} {t('?')}
          </DialogTitle>

          <p className='text-zinc-500 text-sm font-normal'>
            {t(
              'This will permanently delete this card from the carousel and any unsaved modification will be lost. Are you sure you want to delete?'
            )}
          </p>

          <DialogFooter>
            <Button
              onClick={() => toggleDeleteCardModal()}
              type='button'
              variant='default'
              className='bg-[#F4F4F5] text-zinc-900 rtl:ml-2 shadow-none hover:bg-[#F4F4F5]'
            >
              {t('No')}
            </Button>

            <Button
              onClick={() => handleDeleteCarouselCard()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className='h-auto w-full bg-white p-4 flex flex-col gap-4 rounded-md'>
      <div className='flex flex-col gap-3'>
        {renderCarouselCardAddBtn()}
        <p className='text-gray-600 text-sm font-normal leading-5'>
          {t('design up to')} {WHATSAPP_CAROUSEL_CARD_LIMIT}{' '}
          {t(
            `captivating carousels, maintaining consistency in media header format and button types. Remember, Meta approval relies on this compliance so keep it uniform for a hassle-free validation process.`
          )}
        </p>
      </div>
      <hr className='border-gray-300' />
      {renderCardHeaderTypeSelctor()}
      <CarouselTemplateCard
        actionType={actionType}
        sequence={sequence}
        cardId={activeCarouselCardIndex}
      />
      {deleteCardData?.cardId ? renderCardDeleteModal() : null}
    </div>
  );
};
