import { FC, useState } from 'react';
import {
  Label,
  Input,
  Dialog,
  Button,
  PlusIcon,
  Textarea,
  Command,
  Checkbox,
  navigate,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  DialogTitle,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DropdownField,
  GLOBAL_UTILS,
  LOCAL_UTILS,
  useSLACreation,
  LayoutGridIcon,
  TextWithTooltip,
  DialogDescription,
} from '../../export';

interface Props {}
interface ChannelSelectionListProps {
  platformList: any[];
  selectedPlatformList: Array<string | number>;
  onChannelSelect: (id: number | string) => void;
}
interface NoChannelFoundProps {
  handleGoToIntegrationButtonClick: () => void;
}

const SLACreation: FC<Props> = () => {
  const {
    setName,
    formState,
    platformList,
    setDescription,
    isCreationLoading,
    handleOnChannelSelect,
    handleSLACreationSubmitButton,
    handleGoToIntegrationButtonClick,
  } = useSLACreation();

  const [open, setOpen] = useState(false);
  // const {} = useSLACreationForm(createNewSLAPolicy);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='text-white bg-primary'>
          <PlusIcon className='text-white h-4 w-4 mr-[2.5px]' />
          Create New SLA Policy
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px] bg-white'>
        <DialogHeader>
          <DialogTitle>Create New SLA Policy</DialogTitle>
          <DialogDescription className='subtitle-regular'>
            Set up your SLA details below.
          </DialogDescription>
        </DialogHeader>
        <div className='grid gap-4 py-4'>
          <div className='items-center'>
            <Label htmlFor='name' className='text-left label-primary'>
              Name <span className='error'>*</span>
            </Label>
            <Input
              id='name'
              required={true}
              value={formState.name}
              placeholder='Enter SLA name'
              className='col-span-3'
              maxLength={64}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className='items-center'>
            <Label htmlFor='description' className='text-left label-primary'>
              Descriptions
            </Label>
            <Textarea
              id='description'
              placeholder='Enter descriptions'
              className='col-span-3'
              value={formState.description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className='flex flex-col'>
            <Label
              htmlFor='channels'
              className='text-left label-primary mb-1.5'
            >
              Channels
            </Label>
            <DropdownField
              value={LOCAL_UTILS.getPlatformNameList(
                formState.platforms,
                platformList
              )}
              children={
                platformList.length === 0 ? (
                  <NoChannelFound
                    handleGoToIntegrationButtonClick={
                      handleGoToIntegrationButtonClick
                    }
                  />
                ) : (
                  <ChannelSelectionList
                    platformList={platformList}
                    selectedPlatformList={formState.platforms}
                    onChannelSelect={handleOnChannelSelect}
                  />
                )
              }
              childrenName='channel'
              popOverContentClass='w-[23.5rem]'
            />
            <span className='subtitle-regular mt-1.5'>
              Select the channels where tickets will be generated for this SLA.
            </span>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' variant='outline'>
              Cancel
            </Button>
          </DialogClose>
          <Button
            type='submit'
            variant={formState.name.length === 0 ? 'disable' : 'default'}
            isLoading={isCreationLoading}
            disabled={isCreationLoading || formState.name.length === 0}
            onClick={async () => {
              let res: any = await handleSLACreationSubmitButton();
              if (res.status) {
                setOpen(false);
                navigate('/settings/sla-configuration');
              }
            }}
          >
            {isCreationLoading ? 'Creating' : 'Create New SLA'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const NoChannelFound: FC<NoChannelFoundProps> = ({
  handleGoToIntegrationButtonClick,
}) => (
  <Command className='flex flex-col items-center justify-center p-6'>
    <label className='subtitle-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
      <span className='inline-flex justify-center w-full text-center'>
        No channels are available. Please integrate any channel to create SLA
        policy.
      </span>
    </label>
    <Button
      variant='outline'
      size='sm'
      className='mt-4'
      onClick={() => {
        navigate('/integrations/available-integrations');
        handleGoToIntegrationButtonClick();
      }}
    >
      <LayoutGridIcon className='h-[14px] w-[14px]' />
      Go to Integration
    </Button>
  </Command>
);

const ChannelSelectionList: FC<ChannelSelectionListProps> = ({
  platformList,
  onChannelSelect,
  selectedPlatformList,
}) => {
  // Separate selected, enabled, and disabled platforms
  const selectedPlatforms = platformList.filter((platform: any) =>
    selectedPlatformList.includes(platform.id)
  );
  const enabledPlatforms = platformList.filter(
    (platform: any) =>
      !selectedPlatformList.includes(platform.id) && !platform.is_sla_connected
  );
  const disabledPlatforms = platformList.filter(
    (platform: any) => platform.is_sla_connected
  );

  // Combine selected first, then enabled, and finally disabled
  const sortedPlatformList = [
    ...selectedPlatforms,
    ...enabledPlatforms,
    ...disabledPlatforms,
  ];

  return (
    <Command>
      <CommandInput placeholder='Search channels' />
      <CommandList asChild>
        <CommandEmpty>No channel found.</CommandEmpty>
        <CommandGroup>
          {sortedPlatformList.map((platform: any, index: number) => (
            <CommandItem
              asChild
              key={platform.id}
              value={platform.title}
              onSelect={(currentValue) => {
                console.log(platformList[0]);
                // Handle selection logic here
              }}
            >
              <div
                className={`flex items-center space-x-2 cursor-pointer`}
                onClick={() => onChannelSelect(platform.id)} // This allows clicking anywhere in the div to check/uncheck
              >
                <Checkbox
                  id={platform.title}
                  disabled={platform.is_sla_connected}
                  checked={selectedPlatformList.includes(platform.id)}
                  className={`flex-shrink-0 w-[4.5%] ${
                    selectedPlatformList.includes(platform.id)
                      ? 'bg-primary text-white border-transparent'
                      : ''
                  }`}
                  onCheckedChange={() => onChannelSelect(platform.id)} // Toggling checkbox on click
                />
                <img
                  alt='platform'
                  className='flex-shrink-0 w-5 h-5'
                  src={GLOBAL_UTILS.channelInfo(platform.platform_type).image}
                />
                <div className='flex items-center w-[80%] space-x-2'>
                  <label
                    htmlFor={platform.title}
                    className={`flex-grow h-5 text-sm flex items-center font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                      platform.is_sla_connected
                        ? 'text-textPrimary-disable'
                        : 'text-textPrimary'
                    }`}
                    style={{ width: platform.is_sla_connected ? '48%' : '60%' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the outer div's onClick event
                      onChannelSelect(platform.id);
                    }}
                  >
                    <TextWithTooltip
                      text={platform.title}
                      dataFor={platform.title}
                      dataTip={platform.title}
                      shouldShowTooltip={platform.title.length > 37}
                      className='truncate'
                    />
                  </label>
                  {platform.is_sla_connected && (
                    <label
                      htmlFor={'policy_name' + index}
                      className={`w-1/2 text-sm flex h-5 items-center font-normal leading-none truncate cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70`}
                    >
                      <TextWithTooltip
                        text={`Connected ${
                          platform?.sla_policy?.name
                            ? 'to ' + platform.sla_policy.name
                            : ''
                        }`}
                        dataFor={platform?.sla_policy?.name}
                        dataTip={platform?.sla_policy?.name}
                        className='truncate text-textPrimary-disable'
                      />
                    </label>
                  )}
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default SLACreation;
