import React from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import ChevronUpDown from 'pages/inbox/assets/iconComponent/ChevronUpDownIcon';
import { classNames } from 'utilities/utils';
import { CheckIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  handleOrderOnChange: (value: string) => void;
}

const TicketOrder: React.FC<Props> = ({ handleOrderOnChange }) => {
  const [order, setOrder] = React.useState('desc');
  const { t } = useTranslation();

  const options = [
    {
      value: 'desc',
      name: t('Showing newest tickets first'),
    },
    {
      value: 'asc',
      name: t('Showing oldest tickets first'),
    },
  ];

  const getButtonView = () => {
    if (order === 'asc') {
      return (
        <div className='inline-flex gap-2'>
          <ChevronUpDown className='inline-block float-left w-5 h-5' />{' '}
          <span className='float-right ml-2'>{t('Oldest')}</span>
        </div>
      );
    } else if (order === 'desc') {
      return (
        <div className='inline-flex gap-2'>
          <ChevronUpDown className='inline-block float-left w-5 h-5' />
          <span className='float-right'>{t('Newest')}</span>
        </div>
      );
    } else {
      return (
        <div className='inline-block'>
          <ChevronUpDown className='inline-block float-left w-5 h-5' />
          <span className='float-right ml-2'>Unreplied</span>
        </div>
      );
    }
  };

  return (
    <>
      <Popover className='relative inline-block w-full text-left'>
        {({ close }) => (
          <>
            <Popover.Button className='w-full'>
              <div className='flex justify-between  ltr:pl-3 rtl:pr-3 py-1.5 text-gray-500 bg-gray-50 ltr:border-l-2 rtl:border-r-2 border-b-2 border-solid border-gray-100 w-full focus:ring-0 focus:ring-offset-0'>
                {getButtonView()}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Popover.Panel className='absolute z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden ml-2 w-[150%]'>
                {options.map((option, index) => (
                  <div key={index}>
                    <button
                      className={classNames(
                        'bg-white text-gray-900 hover:bg-gray-100',
                        `flex px-4 py-2 text-sm ${
                          option.value === order
                            ? 'font-semibold'
                            : 'font-normal'
                        } text-left relative w-[stretch] w-[-moz-available]`
                      )}
                      onClick={() => {
                        setOrder(option.value);
                        handleOrderOnChange(option.value);
                        close();
                      }}
                    >
                      {option.name}
                      <CheckIcon
                        color='#111827'
                        width={20}
                        height={20}
                        className={classNames(
                          option.value === order ? 'inline' : 'hidden',
                          `absolute ltr:right-2 rtl:left-2`
                        )}
                      />
                    </button>
                  </div>
                ))}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};
export default TicketOrder;
