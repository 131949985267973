import {
  React,
  ConditionHeaderBackArrowIcon,
} from 'pages/automationWorkflow/export';

type Props = {
  title: string;
  previousUIAction: () => void;
};

const ConditionHeader: React.FC<Props> = ({ title, previousUIAction }) => {
  return (
    <div
      className='flex items-center p-3 cursor-pointer border-b'
      onClick={previousUIAction}
    >
      <div>
        <ConditionHeaderBackArrowIcon />
      </div>
      <div className='text-gray-700 font-medium text-sm capitalize'>
        {title}
      </div>
    </div>
  );
};

export default ConditionHeader;
