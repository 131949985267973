import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Modal from './Modal';
import { navigate } from '@reach/router';

interface RemoveamMemberModalType {
  setTeamMemberRemoveCondition: (payload: boolean) => void;
}

const RemoveTeamMemberModal: React.FC<RemoveamMemberModalType> = ({
  setTeamMemberRemoveCondition,
}) => {
  return (
    <Modal>
      <div className='p-5'>
        <div className='p-[24px] flex flex-col justify-center items-center'>
          <ExclamationTriangleIcon className='h-10 w-10 text-yellow-400 bg-yellow-100 rounded-[50%] p-2 mb-3' />
          <p className='text-gray-900 gont-semibold text-lg'>Remove members</p>
          <p className='text-sm text-gray-400 mb-3 text-center'>
            Please remove a member from your team first to decrease the number
            of members from your current plan.
          </p>
          <button
            type='reset'
            className='p-2 bg-yellow-500 rounded-md w-full mb-3 text-white'
            onClick={() => navigate('/settings/team-user')}
          >
            Remove Members
          </button>
          <button
            type='reset'
            className='p-2 border border-gray-300 rounded-md w-full text-gray-500'
            onClick={() => setTeamMemberRemoveCondition(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveTeamMemberModal;
