import React from 'react';

interface RandomlyGeneratedAvatarProps {
  fill?: string;
  className?: string;
}

const RandomlyGeneratedAvatar: React.FC<RandomlyGeneratedAvatarProps> = ({
  fill = '#01906D',
  className = '',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      style={{ background: fill, borderRadius: '50%' }}
      className={className}
    >
      <defs>
        <clipPath id='clipPath'>
          <circle
            id='Ellipse_3'
            data-name='Ellipse 3'
            cx='16'
            cy='16'
            r='16'
            transform='translate(486 443)'
            fill={'currentcolor'}
          />
        </clipPath>
      </defs>
      <g
        id='Mask_Group_1'
        data-name='Mask Group 1'
        transform='translate(-486 -443)'
        clipPath='url(#clip-path)'
      >
        <circle
          id='Ellipse_2'
          data-name='Ellipse 2'
          cx='9.5'
          cy='9.5'
          r='9.5'
          transform='translate(486 443)'
          fill='#FFD82F'
          opacity='0.254'
        />
        <rect
          id='Rectangle_2'
          data-name='Rectangle 2'
          width='43'
          height='20'
          transform='translate(485.636 472.547) rotate(-25)'
          fill='#FFD82F'
          opacity='0.138'
        />
      </g>
    </svg>
  );
};

export default RandomlyGeneratedAvatar;
