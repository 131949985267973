import React from 'react';

const ConversationBarLoader: React.FC = () => {
  return (
    <>
      {/* Loop through 1 - 6 pulsating div */}
      {[...Array(6)].map((index: number) => (
        <div
          key={index}
          className='w-full p-4 mx-auto border-b border-gray-200'
        >
          <div className='flex space-x-4 animate-pulse'>
            <div className='w-10 h-10 bg-gray-200 rounded-full' />
            <div className='flex-1 space-y-4'>
              <div className='h-2 bg-gray-300 rounded w-52' />
              <div className='space-y-3'>
                <div className='h-2 w-[80%] bg-gray-200 rounded' />
                <div className='h-2 w-[90%] bg-gray-200 rounded' />
                <div className='h-2 w-[100%] bg-gray-200 rounded' />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default ConversationBarLoader;
