import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import MainPanel from './components/mainPanel';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { WarningIcon } from './assets/img/icons/svgIcons';
import LivechatDefaultData from '../../../models/LiveChatDefaultData';
import { LiveChatDataProps } from 'pages/integration/interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open?: boolean;
  channelData: channelListProps;

  handleClose: () => void;
  updateStateData: (key: string, value: any) => void;
}

const LiveChatModal: React.FC<Props> = ({
  open = true,
  handleClose,
  channelData,
  updateStateData,
}) => {
  const { t } = useTranslation();
  const [showAlert, setAlert] = useState<boolean>(false);
  const [previousSavedData, updatePreviousSaveData] =
    useState<LiveChatDataProps>(LivechatDefaultData);
  const getPreviousData = (oldData: any) => {
    const previousData = {
      title: oldData.title,
      avatar: oldData.avatar,
      whitelisted_domains: oldData.whitelisted_domains,
      is_published: oldData.is_published,
      connected_nlp_integration_id: oldData.connected_nlp_integration_id,
      livechat_data: {
        ...LivechatDefaultData.livechat_data,
        ...oldData.livechat_data,
      },
    };
    updatePreviousSaveData(cloneDeep(previousData));
    return previousData;
  };

  useEffect(() => {
    updateStateData('liveChatData', {
      is_ecommerce_plugin_channel: channelData?.is_ecommerce_plugin_channel,
      ...getPreviousData(channelData),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWarningView = () => {
    return (
      <div className='p-6 flex flex-col w-[25%] justify-center items-center bg-white rounded-lg m-auto'>
        <div className='p-4 rounded-full flex justify-center items-center bg-[#FFFBEB]'>
          <WarningIcon height='20' width='22' />
        </div>
        <span className='mt-5 text-lg leading-6 font-medium text-gray-900'>
          {t('Changes were not saved')}
        </span>
        <span className='mt-2 text-center text-sm leading-5 font-normal text-gray-500'>
          {t(
            'The customization you have made will be discarded and cannot be reverted. Are you sure you want to continue?'
          )}
        </span>
        <button
          className='mt-6 rounded-md bg-[#F59E0B] text-white w-full p-[9px]'
          onClick={() => {
            handleClose();
          }}
        >
          {t('Discard Changes')}
        </button>

        <button
          className='my-3 rounded-md bg-white  text-gray-700 border border-gray-300 w-full p-[9px]'
          onClick={() => {
            setAlert(false);
          }}
        >
          {t('Cancel')}
        </button>
      </div>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={() => {
          handleClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-middle sm:items-center justify-center min-h-full text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative text-left overflow-hidden  transform transition-all sm:max-w-full sm:w-full sm:p-0'>
                <div>
                  {!showAlert && (
                    <MainPanel
                      channelData={channelData}
                      previousLiveChatData={previousSavedData}
                      handleXMarkIconClick={(isUpdateAble: boolean) => {
                        if (isUpdateAble) {
                          setAlert(true);
                        } else handleClose();
                      }}
                      handlePreviousDataChange={(
                        liveChatData: LiveChatDataProps
                      ) => {
                        updatePreviousSaveData(cloneDeep(liveChatData));
                      }}
                    />
                  )}

                  {showAlert && getWarningView()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: string, value: any) =>
    dispatch.integration.updateStateData({ key, value }),
});

export default connect(mapState, mapDispatch)(LiveChatModal);
