import { XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { useEffect, useState } from 'react';
import { classNames, isValidEmail } from 'utilities/utils';

interface Props {
  type: string;
  width?: string;
  emails: string;
  hideBorder?: boolean;
  inputEnable?: boolean;
  enableAllRemove?: boolean; //enable to remove all emails (false for primary recipient)
  onChange: (emails: string) => void;
  handleEnableChange?: (isEnable: boolean) => void | null;
}

const RecipientEmailView: React.FC<Props> = ({
  type,
  emails,
  onChange,
  width = '100%',
  hideBorder = false,
  inputEnable = true,
  enableAllRemove = true,
  handleEnableChange = null,
}) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState('');
  // const [totalEmails, setTotalEmails] = useState(0);
  const [allEmails, setAllEmails] = useState<string[]>([]);

  useEffect(() => {
    if (!!emails && emails.length > 0) {
      const emailList = emails.trim().split(',') ?? [];
      setAllEmails(emailList);
      // setTotalEmails(emailList.length);
    } else if (emails === '') {
      setAllEmails([]);
      // setTotalEmails(0);
    }
  }, [emails]);

  const handleKeypress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.key === 'Enter' &&
      emailInput.trim().length > 0 &&
      isValidEmail(emailInput.trim())
    ) {
      const emailList = emails
        .split(',')
        .filter((email) => email === emailInput.trim());
      if (emailList.length < 1) {
        if (emails.trim().length > 0) {
          onChange(emails + ',' + emailInput.trim());
        } else {
          onChange(emailInput.trim());
        }
      }
      setEmailInput('');
    }
  };

  const removeRecipientEmails = (index: number) => {
    const emailList = emails.split(',');
    if (index >= 0 && index < emailList.length) {
      emailList.splice(index, 1);
      onChange(emailList.join(','));
    }
  };
  return (
    <div
      className={classNames(
        `flex items-center h-auto min-h-[32px] px-4 bg-white p-1`,
        !hideBorder ? 'border-b border-gray-200 border-solid' : 'border-0'
      )}
      style={{ width }}
    >
      <span className='text-gray-600 ltr:mr-2 rtl:ml-2'>{t(type)}: </span>
      <div className='flex items-center justify-start flex-1 overflow-hidden ltr:mr-2 rtl:ml-2'>
        <div className='flex items-center overflow-x-auto'>
          {allEmails.map((value, index) => (
            <div
              className='flex items-center px-2 my-1 bg-gray-100 rounded ltr:mr-1 rtl:ml-1 whitespace-nowrap'
              key={index}
            >
              <span className='text-sm font-normal text-gray-700'>{value}</span>
              {(index !== 0 || enableAllRemove) && (
                <XMarkIcon
                  className='w-4 h-4 text-gray-400 cursor-pointer ltr:ml-1 rtl:mr-1'
                  onClick={() => removeRecipientEmails(index)}
                />
              )}
            </div>
          ))}
          {allEmails.length < 7 && inputEnable && (
            <input
              className='flex-shrink-0 p-0 border-0 without-ring focus:outline-none focus:ring-0 focus:border-transparent'
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyDown={handleKeypress}
              placeholder={t('Type recipient here')}
            />
          )}
        </div>
      </div>
      {!!handleEnableChange && (
        <XCircleIcon
          className='w-5 h-5 text-gray-500 cursor-pointer ltr:ml-auto rtl:mr-auto'
          onClick={() => handleEnableChange(false)}
        />
      )}
    </div>
  );
};

export default RecipientEmailView;
