import React from 'react';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Audio from '@uppy/audio';
import Transloadit from '@uppy/transloadit';
import { DashboardModal, useUppy } from '@uppy/react';
import { getPreSignedPutUrl } from '../../../../../utilities/awsS3';
import { env as environment } from '../../../../../utilities/config';

import '@uppy/core/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/provider-views/dist/style.css';

interface Props {
  open?: boolean;
  planType?: string;
  fileType?: string;
  platformType: string;
  setUploadImage: (value: boolean) => void;
  sendMessage: (messageRequestBody: {
    message: string | null;
    image: string | null;
    audio: string | null;
    template?: string | null;
  }) => void;
}

interface UppyAwsS3SuccessfulResponse {
  id: string;
  name: string;
  type: string;
  source: string;
  uploadURL: string;
  results: never;
}

interface TransloaditResponse {
  uploadURL: never;
  type: never;
  results: {
    mp3_encoded: [{ url: string }];
  };
}

const UppyImageUploader: React.FC<Props> = ({
  fileType,
  planType,
  sendMessage,
  platformType,
  open = false,
  setUploadImage,
}) => {
  const getAwsS3PreSignedPutUrl = async (fileType: string | undefined) => {
    let rawFileType = fileType;
    let extension = rawFileType?.split('/')[1];
    let crypto = require('crypto');
    let fileName = `${crypto.randomBytes(20).toString('hex')}.${extension}`;

    return await getPreSignedPutUrl(fileName);
  };

  const getAssemblyOptions = () => {
    let params = {
      auth: {
        key: process.env.REACT_APP_UPPY_TRANSLOADIT_KEY,
        expires: undefined,
      },
      steps: {
        ':original': {
          robot: '/upload/handle',
        },
        ogg_encoded: {
          use: ':original',
          robot: '/audio/encode',
          result: true,
          ffmpeg_stack: 'v4.3.1',
          ffmpeg: {
            'q:a': -1,
            'b:a': 62000,
            ar: 22000,
          },
          preset: 'ogg',
        },
        mp3_encoded: {
          use: ':original',
          robot: '/audio/encode',
          result: true,
          ffmpeg_stack: 'v4.3.1',
          preset: 'mp3',
        },
        exported: {
          use: ['mp3_encoded', ':original'],
          robot: '/s3/store',
          credentials: environment,
        },
      },
      // It's more secure to use a template_id and enable
      // Signature Authentication
    };
    return params;
  };

  const uppyImage = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      meta: { type: 'avatar' },
      restrictions: {
        maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['image/*'],
      },
    }).use(AwsS3, {
      // @ts-ignore
      async getUploadParameters(file) {
        return {
          method: 'PUT',
          url: await getAwsS3PreSignedPutUrl(file.type),
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
    return uppyInstance;
  });

  const uppyAudio = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['audio/*'],
      },
    }).use(Audio, {
      showAudioSourceDropdown: true,
      locale: { strings: { pluginNameAudio: 'Record Audio' } },
    });

    // if platformType is whatsapp messenger then transloadit plugin is being used in uppy instance
    if (platformType === 'whatsapp_bsp') {
      return uppyInstance.use(Transloadit, {
        waitForEncoding: true,
        params: getAssemblyOptions(),
      });
    } else {
      return uppyInstance.use(AwsS3, {
        // @ts-ignore
        async getUploadParameters(file) {
          return {
            method: 'PUT',
            url: await getAwsS3PreSignedPutUrl(file.type),
            headers: {
              'Content-Type': file.type,
            },
          };
        },
      });
    }
  });

  const sendAttachment = (
    attachmentList: UppyAwsS3SuccessfulResponse[] | TransloaditResponse[],
    shouldEncode: boolean
  ) => {
    closeModalHandler();
    if (shouldEncode) {
      // encoded response from transloadit
      attachmentList.forEach(
        (attachment: UppyAwsS3SuccessfulResponse | TransloaditResponse) => {
          let audioAttachment = attachment.results.mp3_encoded;
          audioAttachment.forEach((audio) => {
            sendMessage({
              message: null,
              image: null,
              audio: audio.url,
            });
          });
        }
      );
    } else {
      attachmentList.forEach(
        // regular response from uppy
        (attachment: UppyAwsS3SuccessfulResponse | TransloaditResponse) => {
          let attachmentData = attachment.type.split(/[/]/);
          let isAudio = attachmentData[0] === 'audio' || false;
          let isImage = attachmentData[0] === 'image' || false;
          sendMessage({
            message: null,
            image: isImage ? attachment.uploadURL : null,
            audio: isAudio ? attachment.uploadURL : null,
          });
        }
      );
    }
  };

  const closeModalHandler = () => {
    uppyImage.reset();
    uppyAudio.reset();
    setUploadImage(false);
  };

  React.useEffect(() => {
    // result type is set to any because UploadCompleteCallback Type 'UploadResult<Record<string, unknown>, Record<string, unknown>>' is not assignable to type 'UppyAwsS3Response'
    // need to check further
    const sendImage = (result: any) => {
      if (!!result.successful) {
        sendAttachment(result.successful, false);
      }
    };

    // if platformType is whatsapp messenger then transloadit plugin is being used in uppy instance
    const sendAudio = (result: any) => {
      if (platformType === 'whatsapp_bsp' && !!result.transloadit) {
        sendAttachment(result.transloadit, true);
      } else if (!!result.successful) {
        sendAttachment(result.successful, false);
      }
    };
    uppyImage.on('complete', sendImage);
    uppyAudio.on('complete', sendAudio);
    // This is to tell React to remove the old listener if a different function is passed to the `sendAttachment` variable:
    return () => {
      uppyImage.off('complete', sendImage);
      uppyAudio.off('complete', sendAudio);
    };
    // eslint-disable-next-line
  }, [sendAttachment]);

  return (
    <DashboardModal
      open={open}
      uppy={fileType === 'audio' && planType !== 'free' ? uppyAudio : uppyImage}
      plugins={['Url', 'ImageEditor', 'Audio']}
      locale={{
        strings: {
          uploadXFiles: {
            0: 'Send %{smart_count} file',
            1: 'Send %{smart_count} files',
          },
          myDevice: 'Upload Audio',
          browseFiles: 'Upload',
          dropPasteFiles: '%{browseFiles} or drag your image',
          dropPasteImportFiles:
            '%{browseFiles} or drag your audio file. Record your audio by clicking on the button below',
        },
      }}
      proudlyDisplayPoweredByUppy={false}
      onRequestClose={() => closeModalHandler()}
    />
  );
};

export default UppyImageUploader;
