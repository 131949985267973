import React from 'react';
import defaultAvatar from '../../../assets/images/anonymousAvatar.svg';

interface Props {
  avatar: string;
  className?: string;
}

const Avatar: React.FC<Props> = ({
  avatar,
  className = 'content-between object-cover ml-4 rounded-full w-9 h-9',
}) => {
  return (
    <div className='relative'>
      <img
        className={className}
        src={!!avatar ? avatar : defaultAvatar}
        alt='avatar'
      />
    </div>
  );
};

export default Avatar;
