import React from 'react';
import Footer from '../../common/components/Footer';

import { Link } from '../../../../../library';

interface Props {}

const Introduction: React.FC<Props> = () => {
  return (
    <div className='relative'>
      <div className='p-6'>
        <h1 className='text-base font-medium'>
          Integrating WhatsApp with MyAlice will allow you to:
        </h1>
        <ul className='mt-3 ml-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            Respond to Messenger chat messages from MyAlice inbox.
          </li>
          <li className='my-2'>
            Share product images or location using clickable widgets.
          </li>
          <li className='my-2'>
            Use chatbots to create custom user journeys and automate repetitive
            queries.
          </li>
          <li className='my-2'>
            Manage messages, comments and e-commerce orders from other platforms
            in the same inbox.
          </li>
        </ul>
        <br />
        <h1 className='text-base font-medium'>Important to note:</h1>
        <p className='mt-4'>
          WhatsApp is a paid feature and will be added as an ad-ons. Additional
          WhatsApp conversation based cost will be added. You can see pricing
          details{' '}
          <Link
            url='https://drive.google.com/file/d/1InlguuOAMlLTNDKq_7GF8uBm9gA3KFHR/view'
            showUnderline={true}
            openInNewTab={true}
          >
            here
          </Link>
          . The first 1,000 conversations each month will be free, so your
          business can start to build experiences your customers will love
          before having to pay.
        </p>
        <p className='mt-4'>
          To connect WhatsApp, you need to have a verified Facebook Business
          Manager account as well. MyAlice support team will help you in the
          verification and onboarding process.
        </p>
      </div>
      <Footer channelName='Whatsapp' />
    </div>
  );
};
export default Introduction;
