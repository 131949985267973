import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from 'evergreen-ui';
import { SearchFunnelIcon } from '../../../../assets/iconComponent/FilterIcon';
import React from 'react';
import TemplatePreview from './TemplatePreview';
import { filterWhatsappTemplates } from 'pages/inbox/utils/functions';
import openBox from '../../../../assets/images/openBox.svg';
import { TicketInterface, WhatsappTemplate } from 'pages/inbox/inboxInterface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  filterTemplateList: WhatsappTemplate[] | null;
  setFilterTemplateList: (filterList: WhatsappTemplate[] | null) => void;
  setIsOpenWhatsappMessage: (payload: boolean) => void;
  changeStep: (platformId: number, templateId: string) => void;
  selectedTicket: TicketInterface;
  setInputValue: (payload: string) => void;
  whatsappTemplates: WhatsappTemplate[];
}

const WhatsappTemplateList: React.FC<Props> = ({
  filterTemplateList,
  setIsOpenWhatsappMessage,
  changeStep,
  selectedTicket,
  setFilterTemplateList,
  setInputValue,
  whatsappTemplates,
}) => {
  const { t } = useTranslation();
  const [filterKey, setFilterKey] = React.useState('');
  const [hoveredPreviewIndex, setHoveredPreviewIndex] = React.useState(null);

  const searchBar = () => {
    return (
      <div className='p-3'>
        {' '}
        <div className='relative w-full mt-1'>
          <div className='absolute inset-y-0 ltr:left-0 rtl:right-3 flex items-center ltr:pl-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='text'
            name='search'
            id='search'
            className='block w-full ltr:pl-10 rtl:pr-10 border-gray-300 rounded-md focus:border-green-500 focus:ring-green-500 sm:text-sm'
            placeholder={t('Search template')}
            onChange={(e) => {
              if (e.target.value === '') {
                setFilterTemplateList(
                  filterWhatsappTemplates(whatsappTemplates)
                );
                setFilterKey('');
                return;
              }

              if (!!filterTemplateList) {
                let filteredData = filterTemplateList?.filter((elemFilter) =>
                  elemFilter?.name
                    .toLowerCase()
                    .includes(e.target.value.trim().toLowerCase())
                );
                setFilterTemplateList(filteredData);
                setFilterKey(e.target.value);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderEmptyTemplateListView = (
    listOfFilter: WhatsappTemplate[] | null
  ) => {
    return (
      <>
        {!!listOfFilter && listOfFilter.length === 0 && filterKey === '' && (
          <>
            <div className='w-full h-[200px] flex flex-col justify-center items-center px-3 gap-3'>
              <div className='text-gray-300'>
                <img src={openBox} alt='openBox' />
              </div>
              <p className='text-sm text-center text-gray-500'>
                {t('Oops! There are no message templates available.')}
              </p>
            </div>
          </>
        )}
        {!!listOfFilter && listOfFilter.length === 0 && filterKey !== '' && (
          <div className='w-full h-[200px] flex flex-col justify-center items-center px-3'>
            <SearchFunnelIcon className='w-12 h-12 mb-3' />
            <p className='text-sm text-gray-500'>
              {t('Nothing found related to')}{' '}
              <span className='font-medium text-gray-700'>“{filterKey}”</span>
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className='relative bg-white border border-gray-200 rounded-md'>
      <div className=''>
        <div className='flex w-full p-[12px]'>
          <p className='w-[60%] text-gray-800 font-semibold text-sm mb-2'>
            {t('Message Template')}
          </p>
          <div className='w-[40%] flex justify-end'>
            <XMarkIcon
              className='w-5 h-5 text-gray-500 cursor-pointer'
              onClick={() => {
                setIsOpenWhatsappMessage(false);
                setInputValue('');
              }}
            />
          </div>
        </div>
        <div className='max-h-[260px] overflow-auto mb-3 w-[270px]'>
          {!!filterTemplateList &&
            filterTemplateList.length > 0 &&
            filterTemplateList.map((template, index: number) => (
              <div key={index}>
                <div
                  className='px-3 py-[12px] flex hover:bg-gray-200'
                  key={template.id}
                  onClick={() =>
                    changeStep(selectedTicket.customer_platform_id, template.id)
                  }
                  onMouseEnter={() => {
                    // @ts-ignore
                    setHoveredPreviewIndex(index);
                  }}
                  onMouseLeave={() => setHoveredPreviewIndex(null)}
                >
                  <p className='w-[80%] text-gray-900 font-normal text-sm cursor-pointer'>
                    {template.name}
                  </p>
                  <div className='w-[20%] flex justify-end'>
                    <ChevronRightIcon className='w-5 h-5 text-gray-500 cursor-pointer rtl:rotate-180' />
                  </div>
                </div>
                {hoveredPreviewIndex === index && (
                  <TemplatePreview template={template} />
                )}
              </div>
            ))}
          {renderEmptyTemplateListView(filterTemplateList)}
        </div>
        {!!filterTemplateList &&
          filterTemplateList?.length !== 0 &&
          searchBar()}
      </div>
    </div>
  );
};

export default WhatsappTemplateList;
