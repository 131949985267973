import React from 'react';
import { classNames } from '../../../../utilities/utils';

interface Props {
  tabData: tabDataProps[];
  currentTabIndex: tabDataProps;
  handleTabChange: (tab: any) => void;
}

interface tabDataProps {
  id: number;
  name: string;
}

const InputOutputViewNavBar: React.FC<Props> = ({
  tabData,
  currentTabIndex,
  handleTabChange,
}) => {
  return (
    <>
      <div className='sm:hidden'>
        <select
          id='tabs'
          name='tabs'
          className='block w-full border-gray-300 rounded-md focus:border-primary'
          defaultValue={currentTabIndex.name}
        >
          {tabData.map((tab) => (
            <option key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <nav
          className='relative flex divide-x divide-gray-200 rounded-lg shadow'
          aria-label='Tabs'
        >
          {tabData.map((tab, tabIdx) => (
            <button
              key={tab.id}
              onClick={() => {
                if (tab.id !== currentTabIndex.id) {
                  handleTabChange(tab);
                }
              }}
              className={classNames(
                tab.id === currentTabIndex.id
                  ? 'text-gray-900 border-1 border-gray-300 bg-gray-100'
                  : 'text-gray-500 hover:text-gray-700 border-1 border-gray-300',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabData.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.id === currentTabIndex.id ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden='true'
                className={classNames(
                  tab.id === currentTabIndex.id
                    ? 'bg-primary'
                    : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    </>
  );
};

export default InputOutputViewNavBar;
