import moment from 'moment';

export const getIsoDatetimeString = (date: string, timeOffset: string) => {
  if (date.length !== 0) {
    let cleanedOffset = timeOffset;
    let rawOffsetData = timeOffset.split(':');
    if (rawOffsetData[0].length < 3) {
      let hour = rawOffsetData[0].split('');
      cleanedOffset = `${hour[0]}0${hour[1]}:${rawOffsetData[1]}`;
    }
    let isoFormattedDate = !!cleanedOffset
      ? moment(date).format(`YYYY-MM-DDTHH:mm:ss${cleanedOffset}`)
      : null;
    return !!isoFormattedDate ? isoFormattedDate : '';
  } else return '';
};
