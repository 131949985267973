import React from 'react';
import { connect } from 'react-redux';
import { getQueryParameters } from '../../../../utilities/utils';
import { subscriptionDetailsType } from '../../interface';
import moment from 'moment';
import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface paymentCycleProps {
  subscriptionDetails: subscriptionDetailsType;
}

const PaymentCycle: React.FC<paymentCycleProps> = ({ subscriptionDetails }) => {
  const cycleType = getQueryParameters('cycle');
  const { t } = useTranslation();
  return (
    <div className='border border-gray-300 p-5 rounded-md'>
      <div className='flex flex-col'>
        <div className='w-full flex flex-col border-b justify-start'>
          <p className='text-gray-500 text-sm font-normal'>
            {t('New Billing Cycle')}
          </p>
          <p className='text-gray-700 text-sm font-medium capitalize mb-2'>
            {t(cycleType)}
          </p>
          <p className='text-gray-500 text-sm font-normal'>
            {t('Previous Billing Cycle')}
          </p>
          <p className='text-gray-700 text-sm font-medium capitalize mb-2'>
            {t(subscriptionDetails?.project_billing_info?.subscription_plan?.billing_cycle)}
          </p>
        </div>
        <div className='w-full flex flex-col justify-start mt-2'>
          <p className='text-gray-500 text-sm'>{t('New Renewal Date')}</p>
          <p className='text-gray-700 text-sm font-semibold mb-2'>
            {cycleType === 'monthly'
              ? moment().add(1, 'months').format('DD MMM YYYY')
              : moment().add(1, 'years').format('DD MMM YYYY')
            }
          </p>
          <p className='text-gray-500 text-sm'>{t('Previous Renewal Date')}</p>
          <p className='text-gray-700 text-sm font-semibold'>
            {dayjs(parseInt(
              subscriptionDetails?.project_billing_info?.subscription_plan?.billing_end_date
            ) * 1000).format('DD MMM YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
};
const mapState = (state: any) => ({
  subscriptionDetails: state.newBilling.subscriptionDetails,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PaymentCycle);
