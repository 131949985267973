import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ChevronUpIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../../../../utilities/utils';
import { truncate } from './../utils';
import {
  VariantListProps
} from '../interfaces';

interface Prop {
  isDisabled?: boolean;
  options: VariantListProps[];
  variantInfo: any; // its any because we are selecting from product but rendering different variant props, i can go generic but will consume a lot of my time
  handleVariantSelect: (value: VariantListProps) => void;
}

const VariantSelection: React.FC<Prop> = ({
  isDisabled = false,
  options,
  variantInfo,
  handleVariantSelect,
}) => {
  const [search, setSearch] = React.useState('');

  const constructAttributeMap = () => {
    let attributeMap = {};
    options.forEach((option) => {
      attributeMap[option.variant_id] = option.attribute_string;
    });
    return attributeMap;
  };

  const attributeMap = constructAttributeMap();

  return (
    <Listbox
      value={variantInfo}
      onChange={(value: VariantListProps) => {
        handleVariantSelect(value);
        setSearch('');
      }}
      disabled={isDisabled}
    >
      {({ open }) => (
        <>
          <Listbox.Label className='sr-only'>Change Variant</Listbox.Label>
          <div className='relative w-full mb-2 sm:w-auto sm:mb-0'>
            <div
              className={`inline-flex border  ${
                !variantInfo.variant_id ? 'border border-red-500' : ' '
              } rounded-md divide-x divide-gray-100 w-full sm:w-auto`}
            >
              <div className='relative z-0 inline-flex w-full divide-x divide-gray-100 rounded-md sm:w-auto'>
                <div
                  className={`relative w-full sm:w-auto inline-flex items-center bg-white py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-gray-900`}
                >
                  {variantInfo?.variant_id && (
                    <CheckIcon className='w-5 h-5' aria-hidden='true' />
                  )}
                  <p className='ml-2.5 text-sm font-medium'>
                    {truncate(attributeMap[variantInfo?.variant_id], 'Select Variant', 20)}
                  </p>
                </div>
                <Listbox.Button className='relative inline-flex items-center p-2 text-sm font-medium text-gray-900 bg-white border rounded-l-none rounded-r-md hover:bg-gray-50 focus:outline-none focus:z-10'>
                  <span className='sr-only'>Change Variant</span>
                  <ChevronUpIcon
                    className='w-5 h-5 text-gray-500'
                    aria-hidden='true'
                  />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute left-0 z-50 mt-2 overflow-auto origin-top-right transform bg-white divide-y divide-gray-200 rounded-md shadow-lg -translate-y-72 max-h-56 w-72 ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div data-testid='search-container'>
                  {
                    <div className='sticky top-0 z-30 flex px-4 pt-4 pb-2 bg-white'>
                      <div className='relative w-full rounded-md shadow-sm'>
                        <div className='absolute inset-y-0 left-0 flex items-center w-full pl-3 pointer-events-none'>
                          <MagnifyingGlassIcon
                            className='w-5 h-5 text-gray-400'
                            aria-hidden='true'
                          />
                        </div>
                        <input
                          type='search'
                          data-testid='search-input'
                          disabled={false}
                          value={search}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (!!setSearch) {
                              setSearch(e.target.value);
                            }
                          }}
                          className='block w-full pl-10 border-gray-300 rounded-md focus:ring-green-700 focus:border-green-700 sm:text-sm'
                          placeholder={'Search variations...'}
                        />
                      </div>
                    </div>
                  }
                </div>
                {options
                .filter((optionFilter) => 
                  optionFilter
                  .attribute_string
                  .toLowerCase()
                  .includes(search.toLowerCase())
                )
                .map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                    disabled={!option.in_stock}
                  >
                    {({ selected, active, disabled }) => (
                      <div className='flex flex-col'>
                        <div className='flex justify-between'>
                          <p
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {truncate(option.attribute_string, 'Not Available', 20)}
                          </p>
                          {selected ? (
                            <span
                              className={active ? 'text-white' : 'text-primary'}
                            >
                              <CheckIcon
                                className='w-5 h-5'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                          {disabled ? (
                            <span
                              className={active ? 'text-white' : 'text-primary'}
                            >
                              <ExclamationTriangleIcon
                                className='w-5 h-5 text-red-500'
                                aria-hidden='true'
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            disabled ? 'text-red-500': (active ? 'text-white' : 'text-gray-500'),
                            'mt-2'
                          )}
                        >
                          {`Stock: ${option.in_stock ? (option.stock_count <= 0 ? 'Infinite': option.stock_count): 'Out of stock'}`}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default VariantSelection;
