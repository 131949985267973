import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
const VisiblePasswordField = ({
  type,
  label,
  name,
  value,
  handleChange,
  placeHolder,
  passWordMatch,
  ...rest
}) => {
  const { t } = useTranslation();
  const [typeLocal, setTypeLocal] = useState(type);
  return (
    <div>
      <label className='block text-sm font-medium text-gray-700'>
        {t(label)}
      </label>
      <div className='relative mt-1 rounded-md'>
        <input
          type={typeLocal}
          className='block w-full border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
          placeholder={t(placeHolder)}
          name={name}
          value={value}
          onChange={handleChange}
          aria-invalid='true'
          aria-label={label}
          {...rest}
        />
        <div className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3'>
          {typeLocal === 'text' ? (
            <EyeSlashIcon
              role={'button'}
              onClick={() => setTypeLocal('password')}
              className='w-5 h-5 text-gray-500 cursor-pointer'
              aria-hidden='true'
            />
          ) : (
            <EyeIcon
              role={'button'}
              onClick={() => setTypeLocal('text')}
              className='w-5 h-5 text-gray-400 cursor-pointer'
              aria-hidden='true'
            />
          )}
        </div>
      </div>
      {passWordMatch && (
        <p className='mt-2 text-sm text-red-600'>
          {t('Password needs to be same!')}
        </p>
      )}
    </div>
  );
};
export default VisiblePasswordField;
