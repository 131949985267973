import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { classNames } from 'utilities/utils';

interface AlertModalProps {
  isShown: boolean;
  title?: string;
  description?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  intent?: 'primary' | 'danger' | 'warning';
  onConfirm: () => void;
  onCancelComplete?: () => void;
  onToggle: () => void;
  onClose?: () => void;
}

const SelectModal: React.FC<AlertModalProps> = ({
  isShown = false,
  description = ' Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.',
  title = 'Delete',
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  intent = 'primary',
  onToggle,
  onClose,
  onConfirm,
  onCancelComplete,
}) => {
  const cancelButtonRef = React.useRef(null);

  const { t } = useTranslation();

  return (
    <Transition.Root show={isShown} as={React.Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-20 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={() => {
          onToggle();
          if (onClose) {
            onClose();
          }
        }}
      >
        <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
              <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div
                    className={classNames(
                      'flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10',
                      intent === 'primary' && 'text-green-100',
                      intent === 'danger' && 'text-red-100',
                      intent === 'warning' && 'text-yellow-100'
                    )}
                  >
                    <ExclamationTriangleIcon
                      className={classNames(
                        'w-6 h-6',
                        intent === 'primary' && 'text-primary',
                        intent === 'danger' && 'text-red-600',
                        intent === 'warning' && 'text-yellow-600'
                      )}
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      {t(title)}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>{t(description)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-4 py-3 space-x-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                <button
                  type='button'
                  className={classNames(
                    'inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white  border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
                    intent === 'primary' &&
                      'bg-primary focus:ring-primary-hover hover:bg-primary-hover ',
                    intent === 'danger' &&
                      'bg-red-600 focus:ring-red-500 hover:bg-red-700 ',
                    intent === 'warning' &&
                      'bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700 '
                  )}
                  onClick={() => onConfirm()}
                >
                  {t(confirmButtonTitle)}
                </button>
                <button
                  type='button'
                  className='inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={() => {
                    onToggle();
                    if (onCancelComplete) {
                      onCancelComplete();
                    }
                  }}
                  ref={cancelButtonRef}
                >
                  {t(cancelButtonTitle)}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectModal;
