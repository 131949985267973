import React from 'react';
import { SideSheet } from '../../../../../library';
import { connect } from 'react-redux';
import { nlpInfoProps } from '../../../interface';
import WitSetting from '../../common/pages/WitSetting';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  data: nlpInfoProps;
  selectedProject: { id: number };
  handleClose: () => void;
  modifyExistingNlpInfo: (
    payload: object,
    projectId: number,
    integrationId: string
  ) => boolean;
}

const EditWitSideSheet: React.FC<Props> = ({
  isOpen,
  handleClose,
  data,
  selectedProject,
  modifyExistingNlpInfo,
}) => {
  const { t } = useTranslation();
  const [witData, setWitData] = React.useState(data);
  const [isEdited, setIsEdited] = React.useState<any>(null);

  const handleConfirm = async () => {
    let integrationId = witData.id;
    let payload = {
      name: witData.name,
      provider: witData.provider,
      app_id: witData.app_id,
      api_version: witData.api_version,
      access_token: witData.access_token,
      secret_key: witData.secret_key,
      confidence: witData.confidence,
    };
    let res = await modifyExistingNlpInfo(
      payload,
      selectedProject.id,
      integrationId
    );
    if (res) {
      setIsEdited(false);
      handleClose();
    }
  };

  const updateWitData = (key: string = '', value: any) => {
    setIsEdited(true);
    setWitData((prevWitData) => {
      return { ...prevWitData, [key]: value };
    });
  };

  const checkConfirmButton = () => {
    const mandatoryKeys = [
      'name',
      'app_id',
      'access_token',
      'api_version',
      'confidence',
    ];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(witData && witData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const getHeaderInfo = () => {
    return {
      title: t('Wit.ai Settings'),
      description: t('Manage your {{Wit.ai}} settings here'),
    };
  };

  React.useEffect(() => {
    setWitData(data);
  }, [data]);

  return (
    <SideSheet
      open={isOpen}
      hasMinimizeButton={false}
      handleClose={() => handleClose()}
      closeOnExternalClick={false}
      disableConfirm={checkConfirmButton() || !isEdited}
      title={getHeaderInfo().title}
      description={t(getHeaderInfo().description)}
      confirmText={t('Save Changes')}
      handleCancel={() => handleClose()}
      handleConfirm={handleConfirm}
      hideCancel={true}
    >
      <WitSetting
        handleChange={(key: string, value: string | number) =>
          updateWitData(key, value)
        }
        nlpInfo={witData}
      />
    </SideSheet>
  );
};

const mapState = (state: any) => ({
  nlpInfo: state.integration.nlpInfo,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  updateNLPInfo: (key: string, value: string | number) =>
    dispatch.integration.updateNLPInfo({ key, value }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  clearNlpInfo: () => dispatch.integration.clearNlpInfo(),
  modifyExistingNlpInfo: (
    body: object,
    projectId: number,
    integrationId: string
  ) =>
    dispatch.integration.modifyExistingNlpInfo({
      body,
      projectId,
      integrationId,
    }),
});

const WitSideSheetContainer = connect(mapState, mapDispatch)(EditWitSideSheet);

export default WitSideSheetContainer;
