import React from 'react';

interface Prop {
  textSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  url: string;
  openInNewTab?: boolean;
  showUnderline?: boolean;
  className?: string;
  children: React.ReactNode;
  fontWeight?:
    | 'thin'
    | 'extralight'
    | 'light'
    | 'normal'
    | 'medium'
    | 'semibold'
    | 'bold'
    | 'extrabold'
    | 'black';
}

const Link: React.FC<Prop> = ({
  textSize = 'sm',
  url,
  openInNewTab = false,
  fontWeight = 'medium',
  showUnderline = false,
  className,
  children,
}) => {
  return (
    <a
      href={url}
      target={openInNewTab ? '_blank' : '_self'}
      rel='noreferrer noopener'
      className={`text-${textSize} font-${fontWeight} text-link hover:text-link-hover ${
        !!className ? className : ''
      } ${showUnderline ? 'underline' : ''}`}
    >
      {children}
    </a>
  );
};

export default Link;
