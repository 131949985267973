import TextAreaWithEmoji from '../../common/TextAreaWithEmoji';
import Accordion from '../../common/Accordion';
import PreChatSurvey from './preChatSurvey';
import { Switch } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import InputFiledWithLimit from '../../common/InputFieldWithLimit';
import DropDown from '../../common/DropDownWithButton';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  liveChatData: LiveChatDataProps;
  updateLiveChatProperty: (key: string, value: any) => void;
  onPreviewChange: (type: string) => void;
}
const visibilityOption = [
  {
    name: 'Always visible',
    value: 'always',
  },
  {
    name: 'Never visible',
    value: 'never',
  },
];
const ContentPanel: React.FC<Props> = ({
  liveChatData,
  updateLiveChatProperty,
  onPreviewChange,
}) => {
  const { t } = useTranslation();
  const [isFirstComponentOpen, toggleFirstComponnent] =
    useState<boolean>(false);

  const [isSecondComponentOpen, toggleSecondComponnent] =
    useState<boolean>(false);

  useEffect(() => {
    if (isFirstComponentOpen) {
      onPreviewChange('default');
    } else if (isSecondComponentOpen) {
      onPreviewChange('preChat');
    }

    // eslint-disable-next-line
  }, [isFirstComponentOpen, isSecondComponentOpen]);

  const handleComponentAccordionToggle = (componentNo: number) => {
    if (componentNo === 1) {
      if (!isFirstComponentOpen) {
        toggleFirstComponnent(true);
        toggleSecondComponnent(false);
      } else {
        toggleFirstComponnent(false);
      }
    } else {
      if (!isSecondComponentOpen) {
        toggleFirstComponnent(false);
        toggleSecondComponnent(true);
      } else {
        toggleSecondComponnent(false);
      }
    }
  };

  const getCurrentGreetingOptionName = (value: string) => {
    const selectedItem = visibilityOption.filter(
      (data) => data.value === value
    );
    return t(selectedItem[0].name);
  };

  return (
    <>
      <Accordion
        open={isFirstComponentOpen}
        title='Greetings'
        subTitle='Customize how you greet your visitors when they visit your website'
        handleOnToggle={(val) => {
          handleComponentAccordionToggle(1);
        }}
      >
        <div className='grid grid-cols-1 divide-y'>
          <div className='px-5 py-3'>
            <span className='text-sm flex font-medium leading-5 text-gray-700'>
              {t('Greetings Visibility')}
            </span>

            <div className='mt-4 w-full'>
              <DropDown
                classes='w-full flex items-center  text-gray-600 hover:text-gray-600 focus:outline-none'
                align='left-0'
                width='w-full'
                isFullWidth={true}
                buttonComponent={
                  <div className='border w-full border-gray-300 rounded-md ltr:pl-3 rtl:pr-3 ltr:pr-10 py-2 ltr:text-left rtl:text-right focus:outline-none focus:border-gray-300 sm:text-sm'>
                    {getCurrentGreetingOptionName(
                      liveChatData.livechat_data
                        .welcome_prompt_visibility_option
                    )}
                    <span className='absolute inset-y-0 ltr:right-0 rtl:left-2 flex items-center pr-2 pointer-events-none'>
                      <ChevronUpDownIcon
                        className='w-5 h-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </div>
                }
                options={visibilityOption}
                handleDataSelection={(data: any) => {
                  updateLiveChatProperty(
                    'welcome_prompt_visibility_option',
                    data.value
                  );
                }}
                value={
                  liveChatData.livechat_data.welcome_prompt_visibility_option
                }
              />
            </div>

            {/* <div className='mt-4'>
              <DropDownList
                options={visibilityOption}
                selectedValue={getCurrentGreetingOption()}
                titleKeyName={'name'}
                isFullWidth={false}
                buttonClassName={'w-11/12'}
                placeHolder={'Select Visibility'}
                handleSelectedOption={(data: any) => {
                  updateLiveChatProperty(
                    'welcome_prompt_visibility_option',
                    data.value
                  );
                }}
              />
            </div> */}
          </div>

          {/* ============ textboxes =========== */}

          <div className='px-5 py-5'>
            <InputFiledWithLimit
              label='Greetings Title'
              placeHolder=''
              limit={32}
              showEmojiPicker={true}
              value={liveChatData.livechat_data.header_title}
              handleOnChange={(val: string) => {
                updateLiveChatProperty('header_title', val);
              }}
            />
          </div>

          <div className='px-5 py-5'>
            <span className='text-sm flex font-medium leading-5 text-gray-700'>
              {t('Online Greeting')}
            </span>
            <div className='mt-4'>
              <TextAreaWithEmoji
                value={liveChatData.livechat_data.welcome_prompt_online_text}
                limit={112}
                handleOnChange={(val: string) => {
                  updateLiveChatProperty('welcome_prompt_online_text', val);
                }}
              />
            </div>
          </div>

          <div className='px-5 py-5'>
            <span className='text-sm flex font-medium leading-5 text-gray-700'>
              {t('Offline Greeting')}
            </span>
            <div className='mt-4'>
              <TextAreaWithEmoji
                limit={112}
                value={liveChatData.livechat_data.welcome_prompt_offline_text}
                handleOnChange={(val: string) => {
                  updateLiveChatProperty('welcome_prompt_offline_text', val);
                }}
              />
            </div>
          </div>

          <div className='px-5 py-5'>
            <span className='mt-4 text-sm flex font-medium leading-5 text-gray-700'>
              {t('Greetings View')}
            </span>
            <div className='flex mt-4'>
              <div key={0} className='flex'>
                <input
                  id='gt-1'
                  name='gt-1'
                  value='minimal'
                  type='radio'
                  checked={
                    liveChatData.livechat_data[
                      'greetings_view_size'
                    ].toLowerCase() === 'minimal'
                  }
                  className='w-4 h-4 text-green-600 border-gray-300 without-ring'
                  onChange={(e) => {
                    updateLiveChatProperty(
                      'greetings_view_size',
                      e.currentTarget.value.toLowerCase()
                    );
                  }}
                />
                <label htmlFor={'gt-1'} className='ml-3 mr-4'>
                  <div className='p-1 rounded-lg shadow'>
                    <img
                      src='https://s3-ap-southeast-1.amazonaws.com/stage-alice-v3/misc/27205e1c480c11edb3112a3e5f6ac6ad.png'
                      width={200}
                      height={73}
                      alt='greeting_view'
                    />
                  </div>
                </label>
              </div>
              <div key={1} className='flex'>
                <input
                  id='gt-2'
                  name='gt-2'
                  value='extended'
                  type='radio'
                  checked={
                    liveChatData.livechat_data[
                      'greetings_view_size'
                    ].toLowerCase() === 'extended'
                  }
                  className='w-4 h-4 text-green-600 border-gray-300 without-ring'
                  onChange={(e) => {
                    updateLiveChatProperty(
                      'greetings_view_size',
                      e.currentTarget.value.toLowerCase()
                    );
                  }}
                />
                <label htmlFor={'gt-2'} className='ltr:ml-3 rtl:mr-3'>
                  <div className='p-1 rounded-lg shadow'>
                    <img
                      src='	https://s3-ap-southeast-1.amazonaws.com/stage-alice-v3/misc/57d9e772480b11ed914c6a3dbdcc5346.png'
                      alt='greeting_img'
                      width={200}
                      height={180}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className='px-5 py-5'>
            <Switch.Group
              as='div'
              className='flex items-center justify-between'
            >
              <span className='flex flex-col flex-grow'>
                <Switch.Label
                  as='span'
                  className='text-sm rtl:text-right font-medium text-gray-900'
                  passive
                >
                  {t('Show greetings in mobile')}
                </Switch.Label>
              </span>
              <Switch
                checked={
                  liveChatData.livechat_data[
                    'should_enable_welcome_prompt_mobile'
                  ]
                }
                onChange={() => {
                  updateLiveChatProperty(
                    'should_enable_welcome_prompt_mobile',
                    !liveChatData.livechat_data[
                      'should_enable_welcome_prompt_mobile'
                    ]
                  );
                }}
                className={classNames(
                  liveChatData.livechat_data[
                    'should_enable_welcome_prompt_mobile'
                  ]
                    ? 'bg-green-500'
                    : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 '
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    liveChatData.livechat_data[
                      'should_enable_welcome_prompt_mobile'
                    ]
                      ? 'ltr:translate-x-5 rtl:-translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-6 w-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </Accordion>

      <div className='mt-3'>
        <Accordion
          open={isSecondComponentOpen}
          title='Pre-chat Survey'
          subTitle='Collect information before anyone wants to start a chat'
          handleOnToggle={(val) => {
            handleComponentAccordionToggle(2);
          }}
        >
          <div className='px-5 py-3'>
            <PreChatSurvey
              liveChatData={liveChatData}
              updateLiveChatProperty={updateLiveChatProperty}
            />
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default ContentPanel;
