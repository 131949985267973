import React from 'react';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';
import TagList from '../component/tags/TagList';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import CreateTags from '../component/tags/CreateTags';
import DeleteModal from '../component/tags/DeleteModal';
import { toaster } from 'evergreen-ui';
import { Link } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import SwitchWrapper from '../component/notification/SwitchWrapper';
import { useTagSettings } from '../hooks/useTagSettings';

interface Props {
  getTagList: (projectId: number) => boolean;
  selectedProject: SelectedTeamInterface;
  tagList: TagInterface[];
  deleteTags: (payload: {
    projectId: number;
    data: { tags: number[] };
  }) => boolean;
}

const TagManagement: React.FC<Props> = ({
  getTagList,
  selectedProject,
  tagList,
  deleteTags,
}) => {
  const { t } = useTranslation();
  const { isTagMandatoryEnable, updateTagMandatorySetting } = useTagSettings();
  const [selectedTag, setSelectedTag] = React.useState<number[]>([]);
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [filterTags, setFilterTags] = React.useState<TagInterface[] | null>(
    tagList
  );
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState('');
  const [singleTag, setSingleTag] = React.useState<number[]>([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState<{
    id: null | number;
    name: string;
  }>({
    id: null,
    name: '',
  });
  const [sliceData, setSliceData] = React.useState({
    limit: 9,
    offset: 0,
  });

  const checkAll = (checked: boolean) => {
    let payload: number[] = [];
    checked && filterTags && filterTags.map((item) => payload.push(item.id));
    setSelectedTag(payload);
  };

  const deleteAllTag = async (type: string) => {
    let payload = {
      projectId: selectedProject.id,
      data: { tags: type === 'singleDelete' ? singleTag : selectedTag },
    };

    const res = await deleteTags(payload);

    if (res) {
      toaster.success(t('Tag deleted successfully'));
      setOpenDeleteModal(false);
      type === 'singleDelete' ? setSingleTag([]) : setSelectedTag([]);
    } else {
      toaster.danger(t('Could not delete tag. Please try again.'));
      setOpenDeleteModal(false);
    }
  };

  const searchBar = () => {
    return (
      <div className='relative mt-1'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3'>
          <MagnifyingGlassIcon
            className='h-4 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block rounded-md border-gray-300 ltr:pl-10 rtl:pr-10
                  focus:border-green-500 focus:ring-green-500 sm:text-sm w-3/4'
          placeholder={t('Search tags')}
          onChange={(e) => {
            setSliceData({ ...sliceData, limit: 9, offset: 0 });
            setSelectedTag([]);
            if (e.target.value === '') return setFilterTags(tagList);

            if (filterTags) {
              setFilterTags(
                filterTags.filter((elemFilter) =>
                  elemFilter?.name
                    .toLowerCase()
                    .includes(e.target.value.trim().toLowerCase())
                )
              );
            }
          }}
        />
      </div>
    );
  };

  React.useEffect(() => {
    getTagList(selectedProject?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFilterTags(tagList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList]);

  const renderTagMandatorySwitchView = () => {
    return (
      <div className='px-3 py-2 border border-gray-200 rounded-md my-2'>
        <div className='flex items-center px-4 py-4 sm:px-6'>
          <div className='flex items-center  w-full'>
            <div className='min-w-0 px-4 '>
              <div>
                <p className='text-base font-medium text-gray-700 truncate'>
                  <TranslationWrapper text='Require Tag Before Closing Ticket' />
                </p>
                <p className='flex items-center mt-2 text-sm text-gray-500'>
                  <TranslationWrapper text='When enabled, this option will prevent tickets from being closed until at least one tag has been added' />
                </p>
              </div>
            </div>
          </div>
          <div>
            <SwitchWrapper
              disabled={false}
              checked={isTagMandatoryEnable}
              onChange={(checked) => {
                updateTagMandatorySetting(checked);
              }}
              hasBellIcon={false}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='p-4 w-full'>
      <div className='flex mb-6 items-center'>
        <div className='w-1/2'>
          <p className='text-gray-900 text-lg font-semibold mt-3'>
            {t('Manage Tags')}
          </p>
        </div>
        <div className='w-1/2 flex justify-end'>
          <Link
            to={`/projects/${selectedProject.id}/reporting?tab=automation-metrics`}
          >
            <p className='text-[#0078CF] text-[14px] font-medium mt-3 flex gap-2 cursor-pointer'>
              {t('See Tag Reports')}
              <span>
                <ArrowTopRightOnSquareIcon className='w-5 h-5 text-[#0078CF]' />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div>
        {renderTagMandatorySwitchView()}
        <div className='flex mb-5 items-center w-full'>
          <div className='w-1/2'>
            <div>{searchBar()}</div>
          </div>
          <div className='w-1/2 flex justify-end gap-2'>
            {selectedTag.length > 0 && (
              <button
                className={`py-2 px-4 bg-gray-100 border border-gray-200
                text-gray-900
                hover:border-red-500 hover:bg-[#FEE2E2] hover:text-red-700 capitalize
            rounded-md flex gap-2 items-center`}
                onClick={() => {
                  setDeleteType('multipleDelete');
                  setOpenDeleteModal(true);
                }}
              >
                {t(`Delete ({{${selectedTag.length}}})`)}
              </button>
            )}
            {selectedTag.length === 0 && (
              <button
                className='py-2 px-3 bg-[#04B25F] text-white capitalize
            rounded-md flex gap-2 items-center'
                onClick={() => setOpenCreateModal(true)}
              >
                <PlusIcon className='w-4 h-4 text-white' />
                {t('Create New Tag')}
              </button>
            )}
          </div>
        </div>
        {filterTags && (
          <TagList
            tagList={filterTags}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            checkAll={checkAll}
            setOpenDeleteModal={setOpenDeleteModal}
            openDeleteModal={openDeleteModal}
            setDeleteType={setDeleteType}
            setSingleTag={setSingleTag}
            setIsEdit={setIsEdit}
            setEditData={setEditData}
            setOpenCreateModal={setOpenCreateModal}
            sliceData={sliceData}
            setSliceData={setSliceData}
          />
        )}
      </div>
      <CreateTags
        open={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editData={editData}
        setEditData={setEditData}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        deleteAllTag={deleteAllTag}
        type={deleteType}
        setSingleTag={setSingleTag}
        selectedTag={selectedTag}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  tagList: state.settings.tags,
});

const mapDispatch = (dispatch: any) => ({
  getTagList: (projectId: number) => dispatch.settings.fetchTags(projectId),
  deleteTags: (payload: { projectId: number; data: { tags: number[] } }) =>
    dispatch.settings.deleteTags(payload),
});

export default connect(mapState, mapDispatch)(TagManagement);
