import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import {
  Button,
  AutomationHistoryTableData,
  useAutomationHistories,
  LinearLoaderComponent,
} from 'pages/automationWorkflow/export';

const AutomationHistoryListTable = () => {
  const { isGetSelectedUserActionFlowLogListLoading } =
    useAutomationHistories();
  const renderTableHeaderView = () => {
    return (
      <div className='px-4 py-5 border-b'>
        <div className='flex flex-wrap justify-between'>
          <div className='flex flex-col justify-start gap-2'>
            <h3 className='text-gray-600 text-xl font-semibold leading-6'>
              Automation run history
            </h3>
            <p className='text-gray-500 text-sm'>
              Automation runs are deleted 7 days after completion.
            </p>
          </div>
          <div className='flex flex-wrap justify-start gap-2'>
            <Button
              intent='default'
              isDisabled={true}
              size={'sm'}
              onClick={() => console.log('export')}
              className={'w-full sm:w-auto'}
            >
              {
                <ArrowDownTrayIcon
                  className='-ml-0.5 mr-2 h-4 w-4'
                  aria-hidden='true'
                />
              }
              Export
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className='mt-5 overflow-hidden bg-white rounded-lg shadow'>
      {renderTableHeaderView()}
      {isGetSelectedUserActionFlowLogListLoading && <LinearLoaderComponent />}
      {!isGetSelectedUserActionFlowLogListLoading && (
        <AutomationHistoryTableData />
      )}
    </div>
  );
};

export default AutomationHistoryListTable;
