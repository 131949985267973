import { useEffect, useState } from 'react';
import { WhatsappMessageTemplateProps } from 'index';
import { useSelector } from 'react-redux';
import WhatsappTemplatePreview from 'pages/integration/components/integrated/whatsapp/components/WhatsappTemplatePreview';
import { whatsappTemplateApiResponseToState } from 'pages/integration/components/integrated/whatsapp/utils/whatsappUtility';
import { whatsappMessageTemplateProps } from 'pages/integration/interface';
import { IWhatsappStore } from 'pages/integration/hooks/useWhatsappTemplateData';

interface Props {
  title?: string;
  templateId?: number | string;
  width?: string;
  height?: string;
  templateList: WhatsappMessageTemplateProps[] | null;
  hasGreenTick: boolean;
}

const defaultData = {
  header: {
    type: 'text',
    value: '',
  },
  body: '',
  buttons: [],
  footer: '',
} as unknown as whatsappMessageTemplateProps;

const BroadcastPreview: React.FC<Props> = ({
  title = 'Hi There',
  templateId = '0',
  hasGreenTick = false,
  templateList = [],
}) => {
  const teamIcon = useSelector(
    (state: any) => state?.dashboard?.selectedProject?.image
  );
  const [template, setTemplate] = useState(defaultData);
  useEffect(() => {
    const index =
      templateList && templateList.findIndex((data) => data.id === templateId);

    if (
      index !== -1 &&
      Array.isArray(templateList) &&
      templateList &&
      templateList.length > 0
    )
      // @ts-ignore
      setTemplate(templateList[index !== -1 ? index : 0]);
    else setTemplate(defaultData);
  }, [templateId, templateList]);
  return (
    <WhatsappTemplatePreview
      channelIcon={teamIcon}
      channelName={title}
      hasGreenTick={hasGreenTick}
      templateData={
        whatsappTemplateApiResponseToState(
          template
        ) as unknown as IWhatsappStore
      }
    />
  );
};

export default BroadcastPreview;
