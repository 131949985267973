import Processing from '../../../assets/images/processing.gif';

function RequestLoading() {
  return (
    <div className='w-full h-[500px] p-4 flex flex-column justify-center align-content'>
      <img className='m-auto w-72 h-72' src={Processing} alt='' />
      <p className='mb-2 font-sans text-2xl font-bold text-center'>
        Your new team is being created!
      </p>
      <p className='font-sans text-sm text-center text-gray-500'>
        This may take a while.
      </p>
    </div>
  );
}

export default RequestLoading;
