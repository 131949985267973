import { useEffect, useState } from 'react';
import aliceIcon from '../../../assets/images/aliceLogo.svg';
import { SelectedTeamInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';
import { plansProperty } from 'utilities/content';
import Tick from 'assets/icons/component/Tick';
import { classNames } from 'utilities/utils';
import { PlusIcon, Search } from 'lucide-react';
import ZidWithAliceIcon from 'assets/icons/component/ZidWithAlice';
import { navigate, useLocation } from '@reach/router';
import TeamCreationModal from 'pages/settings/teamManagement/TeamCreation';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import useZidConnection from 'pages/integration/hooks/useZidConnection';
import NewAlertModal from 'library/modal/NewAlertModal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ZidIntegration = () => {
  const queryParams = useQuery();
  const storeId = queryParams.get('store_id');
  const store_url = queryParams.get('store_url');
  if (!storeId || !store_url) {
    navigate('/dashboard');
  }

  const { teamList, updatingStoreInfo, setZidStoreInformation } =
    useZidConnection();
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentTeamList, setCurrentTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState<SelectedTeamInterface>();

  const [showTeamCreationModal, setShowTeamCreationModal] = useState(false);

  useEffect(() => {
    setCurrentTeamList(
      teamList.filter((team: SelectedTeamInterface) =>
        team?.name.includes(query)
      )
    );
    //eslint-disable-next-line
  }, [query, teamList]);

  const onSuccessfulIntegration = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  const handleZidConnectRequest = async () => {
    const res = await setZidStoreInformation(selectedTeam, storeId, store_url);
    if (!!res) {
      setShowSuccessModal(true);
    }
  };

  const renderDefaultLogo = () => {
    return (
      <img
        id='teamImage'
        className={`w-10 h-10 inline-block p-2 rounded-md border border-gray-200 `}
        src={aliceIcon}
        alt='team_avatar'
      />
    );
  };

  const renderTeams = (teamElem: SelectedTeamInterface, index: number) => {
    const {
      id,
      name,
      team_members,
      image,
      creator,
      subscription_anchor_time,
      subscription_plan,
    } = teamElem;
    const hasOwnLogo = Boolean(image && image.length !== 0);
    const isDisable =
      !(creator?.is_trial_user && !subscription_anchor_time) &&
      subscription_plan?.name === plansProperty.free_plan.name;
    const teamStatus =
      creator?.is_trial_user && !subscription_anchor_time
        ? t(`{{Free Trial}}. {{${team_members}}} members`)
        : subscription_plan?.name === plansProperty.free_plan.name
        ? t(`Account Restricted`)
        : t(`{{${subscription_plan?.name}}}. {{${team_members}}} members`);
    return isDisable ? null : (
      <div className='border-b border-gray-300' key={id}>
        <div
          className={classNames(
            'flex justify-center w-full h-auto px-2 py-3  align-center rounded-b',
            isDisable ? 'bg-gray-100' : 'bg-white'
          )}
          onClick={() => !isDisable && setSelectedTeam(teamElem)}
          aria-disabled={isDisable}
        >
          <div className='flex justify-center w-full gap-2 cursor-pointer'>
            <div className='w-10'>
              {hasOwnLogo ? (
                <img
                  className={`rounded-md w-10 h-10 ${image ? '' : 'p-1'}`}
                  src={image || aliceIcon}
                  alt=''
                />
              ) : (
                renderDefaultLogo()
              )}
            </div>
            <div className='w-[60%] flex flex-col justify-center px-1'>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger
                    asChild
                    className='float-left bg-transparent rounded-md'
                  >
                    <p className='w-full text-sm text-left font-semibold text-gray-900 truncate'>
                      {name}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent>{name}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <p
                className='w-full text-xs text-gray-500 capitalize truncate'
                role='none'
              >
                {teamStatus}
              </p>
            </div>
            <div className='w-[20%] mb-5 p-1 flex justify-end items-center'>
              {selectedTeam?.id === id && <Tick />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const teamListView = () => {
    return (
      <div className='w-[30vw] rounded-md border border-gray-300'>
        {!!teamList && teamList?.length > 3 && (
          <div className='border-b border-gray-300 flex justify-center items-center w-full'>
            <Search className='w-6 h-6 text-gray-500 mx-3' />
            <input
              autoFocus
              className='h-10 w-full border-0 bg-transparent  pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:ring-offset-0 border-transparent sm:text-sm without-ring'
              placeholder='Search...'
              onChange={(event) => setQuery(event.target.value)}
              onBlur={() => setQuery('')}
            />
          </div>
        )}
        {(!currentTeamList || currentTeamList?.length === 0) && (
          <div className='p-5 flex justify-center items-center border-b border-gray-300'>
            No Team Found
          </div>
        )}
        {!!teamList && teamList?.length > 0 && (
          <div className='max-h-[194px] overflow-y-scroll rounded-b-md'>
            {currentTeamList.map((team: SelectedTeamInterface, index: number) =>
              renderTeams(team, index)
            )}
          </div>
        )}
        {!teamList ||
          (teamList?.length < 1 && (
            <div
              className='p-3 flex justify-start items-center cursor-pointer'
              onClick={() => setShowTeamCreationModal(true)}
            >
              <PlusIcon className='w-5 h-5 mr-2' />
              {t('Create New Team')}
            </div>
          ))}
      </div>
    );
  };
  return (
    <div className='w-full h-[100vh] flex flex-col justify-center items-center bg-white'>
      <ZidWithAliceIcon />
      <div className='text-center my-2'>
        <p className='text-xl font-bold text-gray-800'>Choose Your Team</p>
        <p className='text-sm font-medium text-gray-500'>
          Choose an existing team or create a new team to complete the
          integration.
        </p>
      </div>
      {teamListView()}
      <div className='px-3 py-2 w-full flex justify-center items-center'>
        <button
          disabled={updatingStoreInfo}
          className={classNames(
            'my-2 border-0  px-3 py-2 rounded-md text-center w-[30vw] ',
            !!selectedTeam && !updatingStoreInfo
              ? 'bg-primary text-white cursor-pointer'
              : 'bg-gray-200 text-gray-700 cursor-not-allowed'
          )}
          onClick={() => handleZidConnectRequest()}
        >
          Continue{' '}
          {updatingStoreInfo && (
            <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-gray-500 inline' />
          )}
        </button>
      </div>
      <div className='fixed bottom-4 w-full text-gray-500 text-base font-normal flex justify-center items-center'>
        Want us to set you up?{' '}
        <span
          className='text-blue-500 text-base font-medium ml-1 cursor-pointer'
          onClick={() =>
            window.open('https://www.myalice.ai/support', '_blank')
          }
        >
          Contact Us
        </span>
      </div>
      <TeamCreationModal
        willStaySamePage={true}
        showModal={showTeamCreationModal}
        setShowModal={setShowTeamCreationModal}
        changeTeam={(teamElem) => setSelectedTeam(teamElem)}
      />
      <NewAlertModal
        isShown={showSuccessModal}
        title='Zid Connection'
        description='Your Zid Store Connected SuccessFully'
        shouldShowCancelButton={false}
        confirmButtonTitle='Okay'
        onConfirm={() => onSuccessfulIntegration()}
        onClose={() => onSuccessfulIntegration()}
      />
    </div>
  );
};

export default ZidIntegration;
