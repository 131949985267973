import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import { navigate } from '@reach/router';
import { SallaIntegrationModal } from 'pages/integration/components/availableIntegrations/salla';
import { toast } from 'libraryV2/ui/use-toast';
import { Loader2 } from 'lucide-react';

interface Props {
  open: boolean;
  handleClose: (isConnected: boolean) => void;
}

export const SallaOnboarding: FC<Props> = ({ open = true, handleClose }) => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState();
  const currentProject: SelectedTeamInterface | null = useSelector(
    (state: any) => state?.dashboard?.projects.at(0) ?? null
  );
  const authState = useSelector((state: any) => state.auth);
  const loading = useSelector(
    (state: any) => state.loading.effects?.auth?.updateAccountInfo
  );

  const {
    auth: { updateAccountInfo },
  } = useDispatch();

  const handleSallaLiveChat = async () => {
    // update onboarding status nad navigate to dashboard
    authState['has_completed_onboarding'] = true;
    authState.username = authState.email;
    authState.avatar = '';
    const authResult = await updateAccountInfo(authState);
    if (authResult?.success) {
      navigate('/dashboard');
    }
  };
  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const result = await dispatch.dashboard.fetchTeamDetails(
          currentProject?.id
        );
        setSelectedProject(result);
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Failed to get team information.',
        });
      }
    };
    fetchTeamDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedProject) {
    return null;
  }
  if (loading) {
    return (
      <div className='absolute inset-0 w-screen h-screen bg-gray-300/80 '>
        <Loader2
          className={
            'absolute z-[10] top-1/2 left-1/2 h-16 w-16 text-primary animate-spin'
          }
        />
      </div>
    );
  }
  return (
    <SallaIntegrationModal
      open={open}
      handleClose={() => handleClose(false)}
      selectedProject={selectedProject}
      onComplete={() => {
        handleSallaLiveChat();
        navigate('/dashboard');
      }}
    />
  );
};
export default SallaOnboarding;
