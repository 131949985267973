import React from 'react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';

interface trialModaltype {
  setOpenTrialModal: (value: boolean) => void;
  handleStartTrial: () => boolean;
  fetchAvailablePlans: () => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  fetchPaymentDetails: () => void;
  fetchHistories: (teamId: number) => void;
  fetchProject: () => void;
  projects: SelectedTeamInterface[];
  setSelectedProject: (payload: SelectedTeamInterface) => void;
  fetchEstimation: (payload: number) => void;
  selectedProject: SelectedTeamInterface;
}

const TrialModal: React.FC<trialModaltype> = ({
  setOpenTrialModal,
  handleStartTrial,
  fetchAvailablePlans,
  fetchSubscriptionDetails,
  fetchPaymentDetails,
  fetchHistories,
  fetchProject,
  projects,
  setSelectedProject,
  fetchEstimation,
  selectedProject,
}) => {
  const featureList = [
    'Unlimited Team Size',
    '1000 Free Sessions',
    'Whatsapp',
    'NLP Connection',
    'Product Interaction',
    'CSAT Reports',
    'Custom API',
    'Smart Ticket Assignment',
    'Customer Data Export',
    'Migration Support',
  ];

  const startTrial = async () => {
    const response = await handleStartTrial();

    if (response) {
      toaster.success('14 days trial is activated!', {
        duration: 3,
      });
      setOpenTrialModal(false);
      fetchSubscriptionDetails(selectedProject.id);
      fetchAvailablePlans();
      fetchPaymentDetails();
      fetchHistories(selectedProject.id);
      fetchProject();
      fetchEstimation(selectedProject.id);
      const updatedProject = projects.filter(
        (project) => project.id === selectedProject.id
      );
      if (updatedProject.length > 0) {
        setSelectedProject(updatedProject[0]);
      }
    } else {
      toaster.danger('Traial activation failed!', {
        duration: 3,
      });
      setOpenTrialModal(false);
    }
  };

  return (
    <div className='p-5'>
      <div className='flex w-full justify-end mb-3'>
        <div>
          <XMarkIcon
            className='w-5 h-5 text-gray-500 cursor-pointer'
            onClick={() => setOpenTrialModal(false)}
          />
        </div>
      </div>
      <div className='flex flex-col justify-center'>
        <p className='font-bold text-gray-700 text-lg text-center mb-5'>
          14 Days Trial Of Premium Plan
        </p>
        <div className='p-2'>
          <div className='flex w-full items-center mb-5'>
            <p className='w-[60%] uppercase text-gray-400 font-semibold'>
              Features you’ll unlock
            </p>
            <hr className='w-[40%] border' />
          </div>
          <div>
            {featureList.map((feature) => {
              return (
                <div className='flex mb-4 gap-2'>
                  <CheckCircleIcon className='h-5 text-green-400' />
                  <p className='text-gray-500'>{feature}</p>
                </div>
              );
            })}
          </div>
          <div className='p-2 text-blue-500 rounded-md bg-[#EFF6FF] mb-3 text-[12px]'>
            Your plan will be moved to Free, once your 14 days trial is over.
            Any member more than three will be disabled.
          </div>
          <button
            className='bg-green-500 p-2 w-full text-white rounded-md'
            onClick={() => startTrial()}
          >
            Avail 14 Days Trial
          </button>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  handleStartTrial: () => dispatch.newBilling.handleTrialStart(),
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  fetchPaymentDetails: () => dispatch.newBilling.fetchPaymentDetails(),
  fetchHistories: (teamId: number) =>
    dispatch.newBilling.fetchHistories(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchEstimation: (payload: number) =>
    dispatch.newBilling.fetchEstimatedCost(payload),
});

export default connect(mapState, mapDispatch)(TrialModal);
