export const capitalizeKey = (key: string) => {
  if (typeof key !== 'string') {
    return '';
  }

  const words = key.split('_').map((word) => {
    if (word.length === 0) {
      return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return words.join(' ');
};

export const getColumnTitle = (lab_fields: any[], slug: string) => {
  if (!Array.isArray(lab_fields) || typeof slug !== 'string') {
    return capitalizeKey(slug);
  }

  for (const field of lab_fields) {
    if (field?.slug === slug) {
      return field?.label_agent || field?.name || capitalizeKey(slug);
    }
  }

  return capitalizeKey(slug);
};
