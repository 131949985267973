interface Props {
  width?: string;
  height?: string;
  isOpen: boolean;
  children: JSX.Element;
}

const Modal: React.FC<Props> = ({
  width = 'w-20',
  height = 'h-auto',
  isOpen,
  children,
}) => {
  return (
    <>
      {isOpen && (
        <div
          className='fixed z-10 inset-0 overflow-y-auto'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <div
              className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
              aria-hidden='true'
            />
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            ></span>
            <div
              className={`relative inline-block align-bottom bg-white rounded-lg text-left
          shadow-xl transform transition-all sm:my-8 sm:align-middle ${width} rounded-md ${height}`}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
