import React, { Fragment } from 'react';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: string;
  handleChange: (event: string | null) => void;
}

const FailTextInput: React.FC<props> = ({ value, handleChange }) => {
  return (
    <Fragment>
      <TitleHolderBuilder
        title='Failed Text'
        tooltipInfo='What the bot replies when user inputs wrong information'
      />

      <div className='mt-1'>
        <input
          required={true}
          value={!!value ? value : ''}
          type='text'
          className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.value)
          }
          placeholder='Your input is not valid. Please try again.'
        />
      </div>
    </Fragment>
  );
};

export default FailTextInput;
