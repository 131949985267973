import { useEffect, useState } from 'react';
import {
  ICustomEmailSettings,
  ICustomEmailUserData,
  channelListProps,
} from '../interface';
import { useDispatch, useSelector } from 'react-redux';

export interface IDataObject {
  [key: string]: any;
}

interface UpdateResPonseProps {
  success: boolean;
  message: string;
}
interface Settings {
  imap: ICustomEmailSettings;
  smtp: ICustomEmailSettings;
  userData: ICustomEmailUserData;
}

interface EmailSettingsHook {
  emailSettings: Settings;
  clearAllData: () => void;
  hasSameCredentials: boolean;
  emailCredentialsToUpdate: string;
  isAllFieldValid: (type: string) => boolean;
  isValidDomain: (domain: string) => boolean;
  setCustomEmailSettings: (type: string) => void;
  updateImapSettings: (newSettings: IDataObject) => void;
  updateSmtpSettings: (newSettings: IDataObject) => void;
  updateUserDataSettings: (newSettings: IDataObject) => void;
  createCustomEmailChannel: () => Promise<UpdateResPonseProps>;
  verifyCustomEmailData: (type: string) => Promise<boolean>;
  updateEmailSettings: (channelId: string) => Promise<UpdateResPonseProps>;
  reconnectEmail: (
    channelData: channelListProps
  ) => Promise<UpdateResPonseProps>;
}

const initialData = {
  imap: {
    host: '',
    port: 993,
    email: '',
    security: '',
    username: '',
    password: '',
  },
  smtp: {
    host: '',
    port: 587,
    email: '',
    security: '',
    username: '',
    password: '',
  },
  userData: {
    displayName: 'MyAlice Help',
    channelName: '',
    emailSignature: '',
    integratedNlp: '',
  },
};

const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,}$/;

export const useEmailSettings = (): EmailSettingsHook => {
  const dispatch = useDispatch();
  const integrationStore = useSelector((state: any) => state.integration);
  const dashboardStore = useSelector((state: any) => state.dashboard);

  const [emailSettings, setEmailSettings] = useState<Settings>(initialData);
  const [hasSameCredentials, setHasSameCredentials] = useState<boolean>(false);

  useEffect(() => {
    setHasSameCredentials(
      emailSettings.smtp.username === emailSettings.imap.username &&
        emailSettings.smtp.password === emailSettings.imap.password
    );
  }, [
    emailSettings.smtp.username,
    emailSettings.smtp.password,
    emailSettings.imap.username,
    emailSettings.imap.password,
  ]);

  const setCustomEmailSettings = (type: string) => {
    dispatch.integration.updateStateData({
      key: 'emailCredentialsToUpdate',
      value: type,
    });
  };

  const updateImapSettings = (newSettings: IDataObject) => {
    setEmailSettings((prevSettings) => ({
      ...prevSettings,
      imap: {
        ...prevSettings.imap,
        ...newSettings,
      },
    }));
  };

  const updateSmtpSettings = (newSettings: IDataObject) => {
    setEmailSettings((prevSettings) => ({
      ...prevSettings,
      smtp: {
        ...prevSettings.smtp,
        ...newSettings,
      },
    }));
  };

  const isValidDomain = (domain: string): boolean => {
    return domainPattern.test(domain);
  };

  const isAllFieldValid = (type: string) => {
    if (!type) return false;
    if (type === 'displaySettings') {
      if (
        !emailSettings.userData.displayName ||
        !emailSettings.userData.channelName
      )
        return false;
      return true;
    }

    if (
      //@ts-ignore
      !emailSettings[type].host ||
      //@ts-ignore
      !emailSettings[type].port ||
      //@ts-ignore
      !emailSettings[type].username ||
      //@ts-ignore
      !emailSettings[type].password ||
      //@ts-ignore
      !emailSettings[type].security
    )
      return false;

    //@ts-ignore
    if (!isValidDomain(emailSettings[type].host)) return false;

    return true;
  };

  const updateUserDataSettings = (newSettings: IDataObject) => {
    setEmailSettings((prevSettings) => ({
      ...prevSettings,
      ...{
        userData: {
          ...prevSettings.userData,
          ...newSettings,
        },
      },
    }));
  };

  const clearAllData = () => {
    setEmailSettings(initialData);
  };

  const verifyCustomEmailData = async (type: string) => {
    const payload = {
      projectId: dashboardStore?.selectedProject?.id,
      //@ts-ignore
      data: { ...emailSettings[type], protocol: type },
    };
    const isValid = await dispatch.integration.verifyCustomEmailData(payload);
    return isValid;
  };
  const createCustomEmailChannel = async () => {
    const payload = {
      channelType: 'custom_email',
      teamId: dashboardStore?.selectedProject?.id,
      data: {
        title: emailSettings?.userData?.channelName,
        name: emailSettings?.userData?.displayName,
        connected_nlp_integration_id:
          emailSettings.userData?.integratedNlp ?? '',
        smtp_connection_data: {
          ...emailSettings.smtp,
          protocol: 'smtp',
        },
        imap_connection_data: {
          ...emailSettings.imap,
          protocol: 'imap',
        },
        platform_meta: {
          email_name: emailSettings?.userData?.displayName,
          email_signature: emailSettings?.userData?.emailSignature,
        },

        platform_type: 'custom_email',
      },
    };

    let res = await dispatch.integration.createChannelIntegrations(payload);

    if (res.status === 200) {
      await dispatch.dashboard.setSelectedProject(
        dashboardStore?.selectedProject
      );
      return { success: true, message: 'Success' };
    } else {
      return {
        success: false,
        message: !!res?.data?.error
          ? res?.data?.error
          : 'Something went wrong, please try again.',
      };
    }
  };

  const updateEmailSettings = async (channelId: string) => {
    const projectId = dashboardStore?.selectedProject?.id;
    const payload = {
      channelType: 'custom_email',
      channelId,
      teamId: projectId,
      data: {
        title: emailSettings?.userData?.channelName,
        name: emailSettings?.userData?.displayName,
        connected_nlp_integration_id:
          emailSettings.userData?.integratedNlp ?? '',
        smtp_connection_data: {
          ...emailSettings.smtp,
          protocol: 'smtp',
        },
        imap_connection_data: {
          ...emailSettings.imap,
          protocol: 'imap',
        },
        platform_meta: {
          email_name: emailSettings?.userData?.displayName,
          email_signature: emailSettings?.userData?.emailSignature,
        },
        platform_type: 'custom_email',
      },
      alertMessage: '',
      hideToaster: true,
    };

    const res = await dispatch.integration.editChannel(payload);

    if (res) {
      return { success: true, message: 'Email Updated Successfully' };
    } else {
      return {
        success: false,
        message: !!res?.data?.error
          ? res?.data?.error
          : 'Something went wrong while updating this channel. Please try again later.',
      };
    }
  };

  const reconnectEmail = async (channelData: channelListProps) => {
    const projectId = dashboardStore?.selectedProject?.id;
    const payload = {
      channelId: channelData?.id,
      teamId: projectId,
      payloadData: {
        url: '',
        username: '',
        primary_id: '',
        secondary_id: '',
        primary_token: '',
        avatar: '',
        whitelisted_domains: [],
        title: emailSettings.userData.channelName,
        name: emailSettings.userData.displayName,
        smtp_connection_data: {
          ...emailSettings.smtp,
          protocol: 'smtp',
        },
        imap_connection_data: {
          ...emailSettings.imap,
          protocol: 'imap',
        },
        platform_type: 'custom_email',
      },
      alertMessage: 'Email Reconnect Successfully',
      hideToaster: true,
    };

    const res = await dispatch.integration.reconnectChannel(payload);

    if (res) {
      return { success: true, message: 'Email Reconnect Successfully' };
    } else {
      return {
        success: false,
        message: !!res?.data?.error
          ? res?.data?.error
          : 'Something went wrong while connecting this channel. Please try again later.',
      };
    }
  };

  return {
    clearAllData,
    emailSettings,
    isValidDomain,
    reconnectEmail,
    isAllFieldValid,
    hasSameCredentials,
    updateImapSettings,
    updateSmtpSettings,
    updateEmailSettings,
    verifyCustomEmailData,
    updateUserDataSettings,
    setCustomEmailSettings,
    createCustomEmailChannel,
    emailCredentialsToUpdate: integrationStore.emailCredentialsToUpdate,
  };
};
