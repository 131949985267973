import React from 'react';

interface Props {
  fillColor?: string;
  width?: number;
  height?: number;
}

export const ChannelFunnelIcon: React.FC<Props> = ({
  fillColor = '#9DA3AF',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.00006 8.39233e-05C0.895492 8.39233e-05 6.10352e-05 0.895514 6.10352e-05 2.00008V4.00008C6.10352e-05 5.10465 0.895491 6.00008 2.00006 6.00008H4.00006C5.10463 6.00008 6.00006 5.10465 6.00006 4.00008V2.00008C6.00006 0.895514 5.10463 8.39233e-05 4.00006 8.39233e-05H2.00006Z'
        fill={fillColor}
      />
      <path
        d='M2.00006 8.00008C0.895492 8.00008 6.10352e-05 8.89551 6.10352e-05 10.0001V12.0001C6.10352e-05 13.1047 0.895491 14.0001 2.00006 14.0001H4.00006C5.10463 14.0001 6.00006 13.1047 6.00006 12.0001V10.0001C6.00006 8.89551 5.10463 8.00008 4.00006 8.00008H2.00006Z'
        fill={fillColor}
      />
      <path
        d='M8.00006 2.00008C8.00006 0.895514 8.89549 8.39233e-05 10.0001 8.39233e-05H12.0001C13.1046 8.39233e-05 14.0001 0.895514 14.0001 2.00008V4.00008C14.0001 5.10465 13.1046 6.00008 12.0001 6.00008H10.0001C8.89549 6.00008 8.00006 5.10465 8.00006 4.00008V2.00008Z'
        fill={fillColor}
      />
      <path
        d='M11.0001 8.00008C11.5523 8.00008 12.0001 8.4478 12.0001 9.00008V10.0001H13.0001C13.5523 10.0001 14.0001 10.4478 14.0001 11.0001C14.0001 11.5524 13.5523 12.0001 13.0001 12.0001H12.0001V13.0001C12.0001 13.5524 11.5523 14.0001 11.0001 14.0001C10.4478 14.0001 10.0001 13.5524 10.0001 13.0001V12.0001H9.00006C8.44777 12.0001 8.00006 11.5524 8.00006 11.0001C8.00006 10.4478 8.44778 10.0001 9.00006 10.0001H10.0001V9.00008C10.0001 8.4478 10.4478 8.00008 11.0001 8.00008Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const GroupFunnelIcon: React.FC<Props> = ({
  fillColor = '#9DA3AF',
  width = 18,
  height = 15,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 3C12 4.65685 10.6569 6 9 6C7.34315 6 6 4.65685 6 3C6 1.34315 7.34315 0 9 0C10.6569 0 12 1.34315 12 3Z'
        fill={fillColor}
      />
      <path
        d='M17 5C17 6.10457 16.1046 7 15 7C13.8954 7 13 6.10457 13 5C13 3.89543 13.8954 3 15 3C16.1046 3 17 3.89543 17 5Z'
        fill={fillColor}
      />
      <path
        d='M13 12C13 9.79086 11.2091 8 9 8C6.79086 8 5 9.79086 5 12V15H13V12Z'
        fill={fillColor}
      />
      <path
        d='M5 5C5 6.10457 4.10457 7 3 7C1.89543 7 1 6.10457 1 5C1 3.89543 1.89543 3 3 3C4.10457 3 5 3.89543 5 5Z'
        fill={fillColor}
      />
      <path
        d='M15 15V12C15 10.9459 14.7282 9.9552 14.2507 9.09432C14.4902 9.03275 14.7413 9 15 9C16.6569 9 18 10.3431 18 12V15H15Z'
        fill={fillColor}
      />
      <path
        d='M3.74926 9.09432C3.27185 9.9552 3 10.9459 3 12V15H0V12C0 10.3431 1.34315 9 3 9C3.25871 9 3.50977 9.03275 3.74926 9.09432Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const AgentFunnelIcon: React.FC<Props> = ({
  fillColor = '#9DA3AF',
  width = 16,
  height = 14,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z'
        fill={fillColor}
      />
      <path
        d='M6 8C9.31371 8 12 10.6863 12 14H0C0 10.6863 2.68629 8 6 8Z'
        fill={fillColor}
      />
      <path
        d='M14 4C14 3.44772 13.5523 3 13 3C12.4477 3 12 3.44772 12 4V5H11C10.4477 5 10 5.44771 10 6C10 6.55228 10.4477 7 11 7H12V8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8V7H15C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5H14V4Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const TagFunnelIcon: React.FC<Props> = ({
  fillColor = '#9DA3AF',
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C0.0976 8.51181 -3.1133e-05 8.25584 7.44706e-09 7.99988V3C7.44706e-09 1.34315 1.34315 0 3 0H8.00027C8.2561 6.96388e-05 8.51191 0.0977007 8.70711 0.292893L15.7071 7.29289ZM3 4C3.55228 4 4 3.55228 4 3C4 2.44772 3.55228 2 3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4Z'
        fill={fillColor}
      />
    </svg>
  );
};
