import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import {
  analyzeTemplateBody,
  handleTemplateBodyVariableAutoComplete,
} from '../utils/whatsappUtility';
import { PlusIcon } from '@heroicons/react/24/solid';
import WhatsappTemplateSampleVariables from './WhatsappTemplateSampleVariables';
import useTranslation from 'components/customHooks/useTranslation';
interface WhatsappTemplateBodyProps {
  actionType: WhatsappTemplateActionType;
  initialTemplateBody?: string;
  setHasInvalidBodyVariable: (value: boolean) => void;
}

const WhatsappTemplateBody: React.FC<WhatsappTemplateBodyProps> = ({
  actionType,
  initialTemplateBody,
  setHasInvalidBodyVariable,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateAuthTemplateBody,
    updateTemplateBodyVariables,
    updateTemplateBody,
  } = useWhatsappTemplateData();
  const [maxCount, setMaxCount] = useState(1);
  const templateBody = whatsappTemplateState.body ?? initialTemplateBody ?? '';
  const [cursorPosition, setCursorPosition] = useState(templateBody.length);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isValidTemplateBody, setIsValidTemplateBody] = useState(true);
  const isAuthenticationTemplate =
    whatsappTemplateState.category === 'AUTHENTICATION';
  const isTemplateActionView = actionType === 'VIEW';

  const handleTemplateBodyChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    setCursorPosition(currentCursorPosition);
    if (!value.length) {
      updateTemplateBody(value);
      return;
    }
    const finalTemplateBody = handleTemplateBodyVariableAutoComplete({
      currentText: value,
      position: currentCursorPosition,
      previousText: templateBody,
      nextCount: maxCount,
    });
    updateTemplateBody(finalTemplateBody);
  };

  const processTemplateBody = () => {
    const analyzedData = analyzeTemplateBody({
      bodyText: templateBody,
      position: cursorPosition,
    });

    setIsValidTemplateBody(analyzedData.invalidVariablelist.length === 0);
    setHasInvalidBodyVariable(analyzedData.invalidVariablelist.length === 0);
    setMaxCount(analyzedData.maxVariable + 1);

    const newbodyVariables = Object.keys(
      analyzedData.uniqueVariableList
    ).reduce((acc, key) => {
      acc[key] = whatsappTemplateState.bodyVariables[key] ?? '';
      return acc;
    }, {});

    updateTemplateBodyVariables(newbodyVariables);
  };

  const handleAddVariable = () => {
    updateTemplateBody(templateBody + '{{' + maxCount + '}}');
    textAreaRef?.current?.focus();
  };

  useEffect(() => {
    if (isAuthenticationTemplate) {
      if (whatsappTemplateState?.hasSecurityRecommendation) {
        updateAuthTemplateBody('security_recommendation');
      } else {
        updateAuthTemplateBody('code_text');
      }
      return;
    }
    processTemplateBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    whatsappTemplateState?.category,
    whatsappTemplateState?.language,
    templateBody,
  ]);

  const renderAuthTemplateBody = () => {
    return (
      <div className='block border border-gray-300 rounded-md shadow-sm'>
        <textarea
          id='auth-template-body'
          disabled
          rows={4}
          className='align-top shadow-none p-3 resize-none border-none rounded w-full focus:ring-0 cursor-not-allowed bg-gray-100'
          placeholder={whatsappTemplateState?.authTemplateBody}
        />{' '}
      </div>
    );
  };

  const renderTemplateBody = () => {
    return (
      <div className='flex gap-2 flex-col'>
        <div
          className={`
           relative border border-gray-300 shadow-sm rounded px-3 py-2
           ${
             isValidTemplateBody
               ? 'focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500'
               : 'focus-within:border-red-500 focus-within:ring-1 focus-within:ring-red-500'
           }
          ${
            isTemplateActionView
              ? 'bg-gray-100 cursor-not-allowed'
              : ' cursor-default bg-white'
          }`}
        >
          <textarea
            ref={textAreaRef}
            maxLength={1024}
            id={`wa-template-body`}
            name='text-input-name'
            placeholder={t('Type your message here')}
            value={whatsappTemplateState?.body}
            rows={4}
            disabled={isTemplateActionView}
            className={`resize-y text-sm text-gray-700 font-normal border-0 border-none rounded w-full
             ${
               isTemplateActionView
                 ? 'bg-gray-100 cursor-not-allowed'
                 : 'focus:ring-0 focus:border-none bg-white'
             } text-gray-500 ${''} focus:outline-none focus:ring-1`}
            onChange={handleTemplateBodyChange}
          />
          <div className='h-6 flex gap-1 items-center w-full'>
            <div className='flex w-[50%] justify-start items-center'></div>
            <div className='flex w-[50%] justify-end'>
              <div className={`rounded-md p-1 bg-green-100 text-green-800`}>
                {whatsappTemplateState?.body?.length} /{1024}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            isValidTemplateBody ? 'hidden' : 'visible'
          } w-full text-red-500`}
        >
          {`Please make sure your variables are in order e.g. {{1}}, {{2}}, {{3}}, and have their curly brackets on! e.g. {{1}}.`}
        </div>
        <div className='flex justify-end'>
          <button
            disabled={isTemplateActionView}
            onClick={handleAddVariable}
            className={`flex items-center gap-1 disabled:cursor-not-allowed px-2 py-1 rounded-md ${
              isTemplateActionView
                ? 'text-gray-500 bg-gray-100'
                : 'text-indigo-400 bg-indigo-50'
            }`}
          >
            <PlusIcon
              className={`w-5 font-medium h-5 ${
                isTemplateActionView ? 'text-gray-500' : 'text-indigo-400'
              }`}
            />
            <span>{t('Add Variables')}</span>
          </button>
        </div>
        <div className={`${maxCount === 1 ? 'hidden' : 'visible'}`}>
          <hr className='border-t border-gray-300' />
          <WhatsappTemplateSampleVariables actionType={actionType} />
        </div>
      </div>
    );
  };

  return (
    <div className='p-4 bg-white rounded-md shadow-sm col-span-6 sm:col-span-6 mb-2'>
      <div className='block text-sm font-medium text-gray-700 mb-2'>
        {t('Body')}
        <span className='text-red-500'>*</span>
      </div>

      {isAuthenticationTemplate
        ? renderAuthTemplateBody()
        : renderTemplateBody()}
    </div>
  );
};

export default WhatsappTemplateBody;
