interface Props {
  color?: string;
  className?: string;
}

export const BulkIcon: React.FC<Props> = ({
  color = '#6B7280',
  className = 'w-6 h-6',
}) => {
  return (
    <svg
      width='17'
      height='17'
      className={className}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 1.67H14C14.7345 1.67 15.33 2.26546 15.33 3V14C15.33 14.7345 14.7345 15.33 14 15.33H3C2.26546 15.33 1.67 14.7345 1.67 14V3C1.67 2.26546 2.26546 1.67 3 1.67ZM0 3C0 1.34315 1.34315 0 3 0H14C15.6569 0 17 1.34315 17 3V14C17 15.6569 15.6569 17 14 17H3C1.34315 17 0 15.6569 0 14V3ZM12.0904 6.59043C12.4165 6.26435 12.4165 5.73565 12.0904 5.40957C11.7643 5.08348 11.2357 5.08348 10.9096 5.40957L6.85714 9.46199L5.59043 8.19528C5.26435 7.86919 4.73565 7.86919 4.40957 8.19528C4.08348 8.52137 4.08348 9.05006 4.40957 9.37615L6.26671 11.2333C6.5928 11.5594 7.12149 11.5594 7.44758 11.2333L12.0904 6.59043Z'
        fill={color}
      />
    </svg>
  );
};

export const MagnifyingGlassIcon: React.FC<Props> = ({
  color = '#6B7280',
  className = 'w-6 h-6',
}) => {
  return (
    <svg
      width='17'
      height='17'
      className={className}
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.55703 0C3.3834 0 0 3.3834 0 7.55703C0 11.7306 3.3834 15.1141 7.55703 15.1141C9.3235 15.1141 10.9484 14.508 12.2352 13.4924L15.4823 16.7395C15.8295 17.0867 16.3924 17.0867 16.7396 16.7395C17.0868 16.3923 17.0868 15.8294 16.7396 15.4822L13.4925 12.2351C14.508 10.9484 15.1141 9.32342 15.1141 7.55703C15.1141 3.3834 11.7306 0 7.55703 0ZM1.77812 7.55703C1.77812 4.36543 4.36543 1.77812 7.55703 1.77812C10.7487 1.77812 13.3359 4.36543 13.3359 7.55703C13.3359 10.7487 10.7487 13.3359 7.55703 13.3359C4.36543 13.3359 1.77812 10.7487 1.77812 7.55703Z'
        fill={color}
      />
    </svg>
  );
};

export const BulkExitIcon: React.FC<Props> = ({
  color = '#04B25F',
  className = 'w-5 h-4',
}) => {
  return (
    <svg
      width='20'
      height='18'
      className={className}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 13L19 9M19 9L15 5M19 9L5 9M11 13V14C11 15.6569 9.65686 17 8 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1H8C9.65686 1 11 2.34315 11 4V5'
        stroke={color}
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
