import { Switch } from '@headlessui/react';
import { classNames } from '../../../../../utilities/utils';

interface Props {
  enableBot: boolean;
  setEnableBot: (payload: boolean) => void;
}

const BotSwitch: React.FC<Props> = ({ enableBot, setEnableBot }) => {
  return (
    <Switch
      checked={enableBot}
      onChange={setEnableBot}
      className={classNames(
        enableBot ? 'bg-green-500' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
      )}
    >
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={classNames(
          enableBot ? 'ltr:translate-x-5 rtl:-translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  );
};

export default BotSwitch;
