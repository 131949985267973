import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, toaster, Spinner } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';
import useTranslation from 'components/customHooks/useTranslation';

const ImageUploadField = ({
  image,
  placeholder = 'Upload an image or Drag and Drop an image',
  handleDrop,
  handleClear,
  hasWriteAccess,
  isDisabled = false,
  acceptType = 'image/*',
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptType,
    onDrop: (files) => {
      if (hasWriteAccess) {
        setLoading(true);
        handleDrop(files[0]);
        setTimeout(() => setLoading(false), 1500);
      }
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: t('Image too large. Maximum image size is 7MB'),
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 7340032,
  });

  const ImageStyle = { maxHeight: 200, borderRadius: 5 };

  return (
    <section className='image-upload'>
      <div
        {...getRootProps()}
        tabIndex={-1}
        className={`${
          isDisabled ? 'cursor-not-allowed bg-gray-100' : ''
        } image-upload__field`}
      >
        {!image && !loading && (
          <Fragment>
            <input {...getInputProps()} disabled={isDisabled} />
            <Icon icon='camera' size={50} />
            <p>
              {t(placeholder || 'Upload an image or Drag and Drop an image')}
            </p>
          </Fragment>
        )}

        {loading && <Spinner />}

        {image && !loading && (
          <Fragment>
            {hasWriteAccess && (
              <IconButton
                icon='cross'
                disabled={isDisabled}
                appearance='minimal'
                intent={'danger'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClear();
                }}
              />
            )}
            <img src={image} alt='Block' style={ImageStyle} />
          </Fragment>
        )}
      </div>
    </section>
  );
};

ImageUploadField.propTypes = {
  image: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
ImageUploadField.defaultProps = {
  hasWriteAccess: true,
};

export default ImageUploadField;
