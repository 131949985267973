import { useDispatch, useSelector } from 'react-redux';

const useInboxPreferencesHook = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state: any) => state.auth);

  const handleInboxPreferences = async (selectedPreference: string) => {
    dispatch.auth.updateStateData({
      key: 'inbox_conversation_preference',
      value: selectedPreference,
    });
    await dispatch.auth.updateAccountInfo({
      inbox_conversation_preference: selectedPreference,
    });
  };

  return { authData, handleInboxPreferences };
};

export default useInboxPreferencesHook;
