import {
  React,
  useSelector,
  useAutomationHistories,
  AutomationHistoryHeader,
  AutomationHistoryListTable,
} from 'pages/automationWorkflow/export';

const AutomationHistory = () => {
  const { fetchSelectedUserActionFlowLogData } = useAutomationHistories();

  const { historyTableOffset, selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );

  React.useEffect(() => {
    fetchSelectedUserActionFlowLogData(
      selectedUserActionFlowData,
      historyTableOffset
    );
    // eslint-disable-next-line
  }, [selectedUserActionFlowData, historyTableOffset]);

  return (
    <div className='relative w-full h-full'>
      <AutomationHistoryHeader />
      <div className='px-5'>
        <AutomationHistoryListTable />
      </div>
    </div>
  );
};

export default AutomationHistory;
