import { Button } from 'libraryV2/ui/button';
import React from 'react';
import { Download } from 'lucide-react';
import CsatProgressCircle from './CsatCircleBar';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'libraryV2/ui/progress';
import { cn } from 'libraryV2/utils';
import { CSAT_METRICES_CONTENT } from 'pages/reporting/utility/content';
import useCsatReporting from 'pages/reporting/hooks/useCsatReporting';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';

interface CustomerSatisfactionSummaryProps {}

export interface ICsatDist {
  rating: number;
  count: number;
  percentage: string;
}
const csatRatingsInfo = CSAT_METRICES_CONTENT.ratings;

const SingleCsatRatingBar: React.FC<{ data: ICsatDist }> = ({ data }) => {
  const currentRating =
    csatRatingsInfo.find((r) => r.rating === data.rating) ?? csatRatingsInfo[0];

  return (
    <div className={cn('flex gap-2 p-1 items-center')}>
      <p className='min-w-[18px]'>{`${data.rating}.`}</p>
      <span
        style={{
          backgroundColor: currentRating?.color,
        }}
        className={`h-4 flex-shrink-0 inline-block w-4 rounded`}
      ></span>
      <div className='flex-shrink-0 w-[170px]'>{currentRating.text}</div>
      <TooltipProviderCustomised
        asChild
        content={`Avg. response rate is ${data.percentage}.`}
      >
        <Progress
          value={
            data?.percentage ? Number(data.percentage.replace('%', '')) : 0
          }
          className='bg-zinc-100'
          indicatorClass={currentRating.color}
        />
      </TooltipProviderCustomised>
    </div>
  );
};

const CustomerSatisfactionSummary: React.FC<
  CustomerSatisfactionSummaryProps
> = () => {
  const dispatch = useDispatch();
  const { projectId, csatResponseDist, totalResponseCount } =
    useCsatReporting();

  const currentAvgRating =
    useSelector(
      (state: any) => state.reporting?.csatAvgRating?.current_value
    ) ?? 0;

  csatResponseDist.sort((a, b) => b.rating - a.rating);

  const circleProgress = Math.round((currentAvgRating / 5) * 100);

  return (
    <div className='w-full space-y-4'>
      <div className='w-full flex justify-between items-center'>
        <div>
          <h3 className='text-textPrimary text-lg leading-6'>
            Customer Satisfaction Summary
          </h3>
          <p className='text-textSecondary text-sm'>
            A comprehensive visualization of the overall responsiveness and
            satisfaction of your customers.
          </p>
        </div>
        <Button
          disabled={totalResponseCount < 1}
          variant={'ghost'}
          className={cn(
            'text-textPrimary h-8 py-1.5 px-2.5 border border-border-soft shadow-none gap-2 bg-white',
            {
              'cursor-not-allowed': totalResponseCount < 1,
            }
          )}
          onClick={() => {
            dispatch.reporting.exportCSATSummary(projectId);
          }}
        >
          <Download className='w-4 h-4' />
          <span className='text-xs'>Export</span>
        </Button>
      </div>

      <div className='p-5 bg-white w-full grid grid-cols-12 space-y-5 rounded-md shadow border'>
        <div className='col-span-3 ltr:pl-16 rtl:pr-16'>
          <CsatProgressCircle
            radious={100}
            barSize={18}
            progress={circleProgress}
          />
        </div>
        <div className='col-span-7 flex flex-col gap-2 h-auto pl-4'>
          <p className='text-textSecondary text-sm'>Rating</p>
          <div>
            {csatResponseDist.map((d) => {
              return <SingleCsatRatingBar data={d} />;
            })}
          </div>
        </div>
        <div className='col-span-1 flex flex-col gap-2 h-auto'>
          <p className='text-textSecondary text-sm'>Responses</p>
          <div className='flex flex-col'>
            {csatResponseDist.map((d) => {
              return <p className='p-1 text-textPrimary'> {d.count} </p>;
            })}
          </div>
        </div>
        <div className='col-span-1 flex flex-col gap-2 h-auto'>
          <p className='text-textSecondary text-sm'>Response rate</p>
          <div className='flex flex-col'>
            {csatResponseDist.map((d) => {
              return (
                <p className='p-1 text-textPrimary-disable'> {d.percentage}</p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSatisfactionSummary;
