import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Button } from 'libraryV2/ui/button';
import { navigate } from '@reach/router';
import { AlertTriangle } from 'lucide-react';

const NoPermissionModal: React.FC<{ hasProjectPermission: boolean }> = ({
  hasProjectPermission,
}) => {
  return (
    <Dialog
      defaultOpen={!hasProjectPermission}
      onOpenChange={() => navigate('/')}
    >
      <DialogContent
        className='sm:max-w-[425px] bg-white p-6 flex flex-col gap-8'
        hideCloseButton
      >
        <DialogHeader>
          <div className='flex flex-col items-center gap-2'>
            <div className='flex p-2 rounded-full items-center justify-center bg-red-500/10 '>
              <AlertTriangle className='h-8 w-8 text-red-500' />
            </div>
            <DialogTitle>Permission Denied.</DialogTitle>
          </div>
          <DialogDescription className='text-center'>
            You don't have the access to this team. Please contact with team
            administration.
          </DialogDescription>
        </DialogHeader>
        <div className='flex w-full justify-end'>
          <Button
            onClick={() => navigate('/')}
            className='w-full border-none'
            variant='destructive'
          >
            Okay
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NoPermissionModal;
