import React from 'react';
import { SideSheet } from '../../../../../library';
import { connect } from 'react-redux';
import ChannelCloneDetails from './ChannelCloneDetails';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  channelData: channelListProps;
  selectedProject: { id: number };
  sequences: sequenceProps[];
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleClose: () => void;
  setInitialState: () => void;
  cloneChannel: (
    channelId: number,
    cloneType: string,
    name: string,
    description: string
  ) => Promise<boolean>;
  getIntegratedChannelList: (teamId: number) => void;
}

const CloneChannelSettingData: React.FC<Props> = ({
  isOpen,
  channelData,
  selectedProject,
  handleClose,
  cloneChannel,
  getIntegratedChannelList,
}) => {
  const { t } = useTranslation();
  const initialChannelSettingData = {
    title: '',
    type: channelData.platform_type,
    selected_platform: '',
  };
  const [channelSettingData, setChannelSettingData] = React.useState(
    initialChannelSettingData
  );
  const handleConfirm = async () => {
    let response = await cloneChannel(
      Number(channelData.id),
      channelSettingData.type,
      channelSettingData.title,
      'test'
    );
    if (response) {
      handleClose();
      getIntegratedChannelList(selectedProject.id);
    }
  };
  const getSettingPages = () => {
    return {
      title: 'Clone Your Channel',
      mandatoryFields: ['title'],
      settingPage: (
        <ChannelCloneDetails
          channelCloneDetailsData={channelSettingData}
          channelData={channelData}
          handleChange={(
            key: string,
            value: string | string[] | object | number | boolean
          ) => {
            setChannelSettingData({
              ...channelSettingData,
              [key]: value,
            });
          }}
        />
      ),
    };
  };
  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
        }}
        closeOnExternalClick={false}
        title={t(getSettingPages().title)}
        confirmText={t('Save Changes')}
        cancelText={t('Back')}
        handleCancel={() => {
          handleClose();
        }}
        handleConfirm={handleConfirm}
        hasLeftActionElement={false}
        hideCancel={true}
      >
        {getSettingPages().settingPage}
      </SideSheet>
    </>
  );
};
const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  cloneChannel: (
    channelId: number,
    cloneType: string,
    name: string,
    description: string
  ) =>
    dispatch.channel.cloneChannel({
      channelId,
      cloneType,
      name,
      description,
    }),
  getIntegratedChannelList: (teamId: number) =>
    dispatch.integration.getIntegratedChannelList(teamId),
});

export default connect(mapState, mapDispatch)(CloneChannelSettingData);
