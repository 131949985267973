import { Link } from '@reach/router';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { BotMessageSquareIcon } from 'lucide-react';
import { ICsatTableRowInfo } from 'pages/reporting/interface';
import { CSAT_METRICES_CONTENT } from 'pages/reporting/utility/content';
import { getUserNameInitials } from 'pages/reporting/utility/utils';
import * as GLOBAL_UTILS from 'utilities/utils';

const CSAT_TABLE_CHANNEL_NAME_LIMIT = 20;
const CSAT_TABLE_FEEDBACK_LIMIT = 40;

const truncate = (str: string, limit = 20) =>
  ('' + str).length > limit ? '' + str.slice(0, limit) + '...' : str + '';

const csatRatingsById = CSAT_METRICES_CONTENT.ratings.reduce((p, c) => {
  p[c.rating.toString()] = {
    ...c,
  };
  return p;
}, {} as Record<string, typeof CSAT_METRICES_CONTENT.ratings[0]>);

export const getCSATTableColumns = (
  projectId: number
): ColumnDef<ICsatTableRowInfo>[] => {
  return [
    {
      id: 'customerName',
      header: ({ table }) => (
        <p className='text-textSecondary text-sm'>Customer Name</p>
      ),
      cell: ({ row }) => <p> {row.original?.customer?.full_name ?? ''} </p>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'ticketId',
      header: () => <p className='text-textSecondary'>Ticket ID</p>,
      size: 100,
      cell: ({ row }) => (
        // @ts-expect-error
        <Link
          to={`/projects/${projectId}/inbox?customer_id=${row.original.customer.id}&ticket_id=${row.original.ticket_id}`}
        >
          <p className='text-blue-400'> #{row.original?.ticket_id ?? ''} </p>
        </Link>
      ),
    },
    {
      id: 'channel',
      header: () => <p className='text-textSecondary'>Channel</p>,
      cell: ({ row }) => (
        <div className='flex gap-1 items-center'>
          <img
            alt='platform'
            className='flex-shrink-0 w-4 h-4'
            src={
              GLOBAL_UTILS.channelInfo(row.original?.platform?.platform_type)
                ?.image
            }
          />
          <TooltipProviderCustomised
            content={
              row.original?.platform?.title.length >
              CSAT_TABLE_CHANNEL_NAME_LIMIT
                ? row.original?.platform?.title.length
                : ''
            }
          >
            <span>
              {truncate(
                row.original?.platform?.title,
                CSAT_TABLE_CHANNEL_NAME_LIMIT
              ) || ''}
            </span>
          </TooltipProviderCustomised>
        </div>
      ),
    },
    {
      id: 'agent',
      header: () => <p className='text-textSecondary'>Agent</p>,
      cell: ({ row }) => {
        const sender = row?.original?.sender;
        if (!sender) {
          return (
            <div className='flex gap-1 items-center'>
              <div className='p-1 rounded-full bg-blue-100 border-2 border-blue-300'>
                <BotMessageSquareIcon className='h-4 w-4 text-blue-500' />
              </div>
              <span>Bot</span>
            </div>
          );
        }
        return (
          <div className='flex gap-1 items-center'>
            <Avatar className='h-[22px] w-[22px]'>
              <AvatarImage src={sender?.avatar} />
              <AvatarFallback className='border-zinc-300 border opacity-100 text-xs p-[2px] font-medium bg-zinc-100'>
                {getUserNameInitials(sender?.full_name ?? 'User')}
              </AvatarFallback>
            </Avatar>
            <span>{sender?.full_name}</span>
          </div>
        );
      },
    },
    {
      id: 'rating',
      accessorFn: (row, index) => {
        return csatRatingsById[row?.rating?.toString()]?.text ?? '';
      },
      header: () => <p className='text-textSecondary'>Rating</p>,
      filterFn: (row, columnId, filterValue) => {
        return true;
      },
      // filterFn: 'equalsString',
      cell: ({ row }) => (
        <p> {csatRatingsById[row.original?.rating?.toString()]?.text ?? ''} </p>
      ),
    },
    {
      id: 'feedback',
      header: () => <p className='text-textSecondary'>Feedback</p>,
      cell: ({ row }) => {
        const feedbackText = String(row.original?.feedback || '');
        return (
          <div>
            <TooltipProviderCustomised
              content={
                feedbackText.length > CSAT_TABLE_FEEDBACK_LIMIT
                  ? feedbackText
                  : ''
              }
            >
              <p className='truncate'>
                {feedbackText?.length
                  ? truncate(feedbackText, CSAT_TABLE_FEEDBACK_LIMIT)
                  : '--'}
              </p>
            </TooltipProviderCustomised>
          </div>
        );
      },
    },
    {
      id: 'sentAt',
      header: () => <p className='text-textSecondary'>Sent At</p>,
      cell: ({ row }) => (
        <p>
          {' '}
          {dayjs(+row.original?.created_at * 1000).format('MMM DD, YYYY')}{' '}
        </p>
      ),
    },
    {
      id: 'ratedAt',
      header: () => <p className='text-textSecondary'>Rated At</p>,
      cell: ({ row }) => (
        <p> {dayjs(+row.original?.rated_at * 1000).format('MMM DD, YYYY')} </p>
      ),
    },
  ];
};
