import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CustomExclamationCircleIcon } from '../assets/icons/svgIcons';

interface Props {
  title: string;
  value: number;
  color?: string;
  loading: boolean;
  tooltipText: string;
  className?: string;
}

const NumberCard: React.FC<Props> = ({
  title,
  value,
  color = '#967686',
  loading,
  tooltipText,
  className = '',
}) => {
  return (
    <div className={` bg-white p-6 ${className}`}>
      <div className='block'>
        <h3 className='text-sm font-medium text-gray-900'>
          {title}
          <span
            className='ltr:ml-1 rtl:mr-1'
            data-tip={tooltipText}
            data-for={`${title.trim()}-number-card`}
          >
            <CustomExclamationCircleIcon className='inline w-5 h-5 text-gray-500' />
          </span>
          {/* @ts-ignore */}
          <ReactTooltip
            id={`${title.trim()}-number-card`}
            type='dark'
            effect='solid'
            backgroundColor='#4B5563'
            multiline={true}
            place={'top'}
            className='w-auto break-words whitespace-pre-wrap sm:text-sm'
          />
        </h3>
        {loading && (
          <div className='w-8 h-5 bg-gray-200 rounded mt-2 animate-pulse' />
        )}
        {!loading && (
          <div
            className={`mt-2 text-lg font-semibold`}
            style={{ color: color }}
          >
            <p>{value}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NumberCard;
