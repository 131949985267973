import React, { useState } from 'react';
import { Dialog, toaster } from 'evergreen-ui';
import wooCommerceConnect from '../../../assets/icon/marketplace/woocommerce-connect.svg';
import wooCommerceConnected from '../../../assets/icon/marketplace/woocommerce-connected.svg';
import shopifyConnect from '../../../assets/icon/marketplace/shopify-connect.svg';
import shopifyConnected from '../../../assets/icon/marketplace/shopify-connected.svg';
import searchOrderIcon from '../../../assets/icon/marketplace/searchOrderIcon.svg';
import cross from '../../../assets/icon/marketplace/cross.svg';
import createOrder from '../../../assets/icon/marketplace/createOrder.svg';
import productRecommenderIcon from '../../../assets/icon/marketplace/productRecommender.svg';
import WooCommerceConnectCustomer from './WooCommerceConnectCustomer';
import ShopifyConnectCustomer from './ShopifyConnectCustomer';
import Modal from '../../../../../components/elements/redesign/Modal';
import EcomOrderCreate from '../eCommerceOrderCreate';
import ShopifyOrderCreate from '../shopifyOrderCreate';
import EcommerceSearchOrder from '../eCommerceSearchOrder';
import ProductRecommender from '../productRecommender';
import { messengerChannels } from '../../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  fetchEcommerceCustomerList: (search: string) => void;
  marketPlaceData: any;
  isCustomerLinkedWithEcommerceAccount: any;
  fetchEcommerceCustomerListLoader: boolean;
  customerList: any[];
  currentTicket: any;
  linkCustomerWithCRM: (ecommerceId: number) => Promise<boolean>;
  unlinkCustomerWithCRM: () => Promise<boolean>;
  linkLoader: boolean;
  createOrderMinimized: boolean;
  platformType: string;
}

const RightBarEComActionBar: React.FC<Prop> = ({
  fetchEcommerceCustomerList,
  marketPlaceData,
  isCustomerLinkedWithEcommerceAccount,
  fetchEcommerceCustomerListLoader,
  customerList,
  currentTicket,
  linkCustomerWithCRM,
  unlinkCustomerWithCRM,
  linkLoader,
  createOrderMinimized,
  platformType,
}) => {
  const { t } = useTranslation();
  const [action, setAction] = React.useState('');
  const [showDisconnectWoocommerceModal, setShowDisconnectWoocommerceModal] =
    React.useState(false);
  const [showDisconnectShopifyModal, setShowDisconnectShopifyModal] =
    React.useState(false);
  const hasWooCommerceConnection =
    !!marketPlaceData && marketPlaceData?.ecommerce_type === 'woocommerce';
  const hasShopifyConnection =
    !!marketPlaceData && marketPlaceData?.ecommerce_type === 'shopify';
  const [createOrderOpen, setCreateOrderOpen] = useState(false);
  const [shopifyCreateOrderOpen, setShopifyCreateOrderOpen] = useState(false);
  const [searchOrderOpen, setSearchOrderOpen] = useState(false);
  const [productRecommenderOpen, setProductRecommenderOpen] = useState(false);

  return (
    <div className='flex items-center justify-center p-3 mb-2 bg-white rounded-md shadow'>
      {hasWooCommerceConnection && !isCustomerLinkedWithEcommerceAccount && (
        <>
          <div
            title={'Search Orders'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setSearchOrderOpen(true)}
          >
            <img src={searchOrderIcon} className='w-6 h-6' alt='searchOrder' />
          </div>
          <div
            title={'Connect to WooCommerce'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => {
              setAction('wooCommerceConnect');
              fetchEcommerceCustomerList('');
            }}
          >
            <img src={wooCommerceConnect} alt='connect' />
          </div>
          <div
            title={t('Create Order')}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setCreateOrderOpen(true)}
          >
            <img src={createOrder} className='w-6 h-6' alt='createOrder' />
          </div>
          {messengerChannels.includes(platformType) && (
            <div
              title={'Product Recommender'}
              className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
              onClick={() => setProductRecommenderOpen(true)}
            >
              <img
                src={productRecommenderIcon}
                className='w-6 h-6'
                alt='productRecommender'
              />
            </div>
          )}
        </>
      )}
      {hasWooCommerceConnection && !!isCustomerLinkedWithEcommerceAccount && (
        <>
          <div
            title={'Search Orders'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setSearchOrderOpen(true)}
          >
            <img src={searchOrderIcon} className='w-6 h-6' alt='searchOrder' />
          </div>
          <div
            title={t('Connected to WooCommerce')}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-default'
          >
            <img
              src={wooCommerceConnected}
              className='w-6 h-6'
              alt='connected'
            />
          </div>
          <div
            title={t('Create Order')}
            className='relative p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setCreateOrderOpen(true)}
          >
            <img src={createOrder} className='w-6 h-6' alt='createOrder' />
            {createOrderMinimized && (
              <div className='absolute w-2 h-2 bg-red-500 rounded-md -top-1 -right-1' />
            )}
          </div>
          {messengerChannels.includes(platformType) && (
            <div
              title={'Product Recommender'}
              className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
              onClick={() => setProductRecommenderOpen(true)}
            >
              <img
                src={productRecommenderIcon}
                className='w-6 h-6'
                alt='productRecommender'
              />
            </div>
          )}
          <div
            title={'Disconnect WooCommerce'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setShowDisconnectWoocommerceModal(true)}
          >
            <img src={cross} className='w-6 h-6' alt='disconnect' />
          </div>
        </>
      )}

      {hasShopifyConnection && !isCustomerLinkedWithEcommerceAccount && (
        <>
          <div
            title={'Search Orders'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setSearchOrderOpen(true)}
          >
            <img src={searchOrderIcon} className='w-6 h-6' alt='searchOrder' />
          </div>
          <div
            title={t('Connect to Shopify')}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => {
              setAction('shopifyConnect');
              fetchEcommerceCustomerList('');
            }}
          >
            <img src={shopifyConnect} className='w-6 h-6' alt='connect' />
          </div>
          <div
            title={t('Create Order')}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setShopifyCreateOrderOpen(true)}
          >
            <img src={createOrder} className='w-6 h-6' alt='createOrder' />
          </div>
          {messengerChannels.includes(platformType) && (
            <div
              title={'Product Recommender'}
              className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
              onClick={() => setProductRecommenderOpen(true)}
            >
              <img
                src={productRecommenderIcon}
                className='w-6 h-6'
                alt='productRecommender'
              />
            </div>
          )}
        </>
      )}
      {hasShopifyConnection && !!isCustomerLinkedWithEcommerceAccount && (
        <>
          <div
            title={'Search Orders'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setSearchOrderOpen(true)}
          >
            <img src={searchOrderIcon} className='w-6 h-6' alt='searchOrder' />
          </div>
          <div
            title={'Connected to Shopify'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-default'
          >
            <img src={shopifyConnected} className='w-6 h-6' alt='connected' />
          </div>
          <div
            title={t('Create Order')}
            className='relative p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setShopifyCreateOrderOpen(true)}
          >
            <img src={createOrder} className='w-6 h-6' alt='createOrder' />
            {createOrderMinimized && (
              <div className='absolute w-2 h-2 bg-red-500 rounded-md -top-1 -right-1' />
            )}
          </div>
          {messengerChannels.includes(platformType) && (
            <div
              title={'Product Recommender'}
              className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
              onClick={() => setProductRecommenderOpen(true)}
            >
              <img
                src={productRecommenderIcon}
                className='w-6 h-6'
                alt='productRecommender'
              />
            </div>
          )}
          <div
            title={'Disconnect Shopify'}
            className='p-2.5 ml-2 mr-2 border rounded-md cursor-pointer'
            onClick={() => setShowDisconnectShopifyModal(true)}
          >
            <img src={cross} className='w-6 h-6' alt='disconnect' />
          </div>
        </>
      )}

      <Dialog
        isShown={!!action}
        width={500}
        hasFooter={false}
        hasHeader={false}
        onCloseComplete={() => setAction('')}
      >
        {action === 'wooCommerceConnect' && (
          <WooCommerceConnectCustomer
            handleClose={() => setAction('')}
            fetchEcommerceCustomerList={fetchEcommerceCustomerList}
            fetchEcommerceCustomerListLoader={fetchEcommerceCustomerListLoader}
            customerList={customerList}
            currentTicket={currentTicket}
            linkCustomerWithCRM={async (ecommerceId: number) => {
              let res = await linkCustomerWithCRM(ecommerceId);
              if (res) {
                setAction('');
                toaster.success('Connected!');
              }
            }}
            linkLoader={linkLoader}
          />
        )}
        {action === 'shopifyConnect' && (
          <ShopifyConnectCustomer
            handleClose={() => setAction('')}
            fetchEcommerceCustomerList={fetchEcommerceCustomerList}
            fetchEcommerceCustomerListLoader={fetchEcommerceCustomerListLoader}
            customerList={customerList}
            currentTicket={currentTicket}
            linkCustomerWithCRM={async (ecommerceId: number) => {
              let res = await linkCustomerWithCRM(ecommerceId);
              if (res) {
                setAction('');
                toaster.success('Connected!');
              }
            }}
            linkLoader={linkLoader}
          />
        )}
      </Dialog>
      <Modal
        isShown={showDisconnectWoocommerceModal}
        setShown={setShowDisconnectWoocommerceModal}
        deleteTitle='Yes, disconnect!'
        onDeleteCallback={async () => {
          let res = await unlinkCustomerWithCRM();
          if (res) {
            toaster.success('Disconnected!');
          }
        }}
        title='Disconnect WooCommerce Customer'
        intent='warning'
        description="You have requested to disconnect your WooCommerce customer's account from MyAlice. Are you sure you want to disconnect the link?"
      />
      <Modal
        isShown={showDisconnectShopifyModal}
        setShown={setShowDisconnectShopifyModal}
        deleteTitle='Yes, disconnect!'
        onDeleteCallback={async () => {
          let res = await unlinkCustomerWithCRM();
          if (res) {
            toaster.success('Disconnected!');
          }
        }}
        title='Disconnect Shopify Customer'
        intent='warning'
        description="You have requested to disconnect your Shopify customer's account from MyAlice. Are you sure you want to disconnect the link?"
      />
      <EcomOrderCreate
        createOrderOpen={createOrderOpen}
        ticketId={currentTicket.id}
        customerId={currentTicket.customer.id}
        setCreateOrderOpen={setCreateOrderOpen}
      />
      <ShopifyOrderCreate
        createOrderOpen={shopifyCreateOrderOpen}
        ticketId={currentTicket.id}
        customerId={currentTicket.customer.id}
        setCreateOrderOpen={setShopifyCreateOrderOpen}
      />
      <EcommerceSearchOrder
        searchOrderOpen={searchOrderOpen}
        setSearchOrderOpen={setSearchOrderOpen}
      />
      <ProductRecommender
        productRecommenderOpen={productRecommenderOpen}
        customerId={currentTicket.customer.id}
        setProductRecommenderOpen={setProductRecommenderOpen}
      />
    </div>
  );
};

export default RightBarEComActionBar;
