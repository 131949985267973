import { TrashIcon } from '../../../../export';
import {
  BaseEdge,
  EdgeLabelRenderer,
  // getStraightPath,
  // getSmoothStepPath,
  getBezierPath,
  useReactFlow,
} from 'reactflow';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  selected,
  style,
  markerEnd,
}: any) => {
  const { setEdges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={
          !selected
            ? style
            : { ...style, strokeWidth: '3px', stroke: '#04B25F' }
        }
        markerEnd={markerEnd}
      />
      {selected && (
        <EdgeLabelRenderer>
          <TrashIcon
            style={{
              height: '40px',
              width: '40px',
              padding: '5px',
              color: '#6B7280',
              background: 'white',
              borderRadius: '20px',
              position: 'absolute',
              pointerEvents: 'all',
              border: '2px solid #04B25F',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
            className='nodrag nopan'
            onClick={() => {
              setEdges((es) => es.filter((e) => e.id !== id));
            }}
          />
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default CustomEdge;
