import { useDispatch, useSelector } from 'react-redux';
import { CSAT_METRICES_CONTENT } from 'pages/reporting/utility/content';
import { ICsatTableRowInfo, ITeamAgent } from '../interface';
import { ICsatDist } from '../component/CSAT/components/CustomerSatisfactionSummary';
import { IReprtingPlatforms } from 'pages/reporting/component/Nav/ReportingPlatformMultiSelect';
import { useParams } from '@reach/router';

interface ICsatStateData {
  csatResponseDist: ICsatDist[];
  csatResponseList: ICsatTableRowInfo[];
  csatResponseListTotal: number;
  totalCsatResponseWithoutFilter: number;
  agentList: ITeamAgent[];
  isCsatTableDataLoading: boolean;
}

const useCsatReporting = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const projectId = routeParams.projectId;

  const platformsToFilter: IReprtingPlatforms[] =
    useSelector((state: any) => state?.reporting?.platformsToFilter) ?? [];

  const updatePlatformsToFilter = (plist: any[]) => {
    if (!Array.isArray(plist)) {
      return;
    }
    dispatch.reporting.updateStateData({
      key: 'platformsToFilter',
      value: plist,
    });
  };

  const {
    csatResponseDist = [],
    csatResponseList: csatTableData = [],
    csatResponseListTotal: totalResponseCount = 0,
    totalCsatResponseWithoutFilter,
    agentList = [],
    isCsatTableDataLoading,
  }: ICsatStateData = useSelector((state: any) => ({
    csatResponseDist: state.reporting?.csatResponseDist,
    csatResponseList: state.reporting.csatResponseList,
    csatResponseListTotal: state.reporting?.csatResponseListTotal,
    totalCsatResponseWithoutFilter:
      state.reporting?.csatTotalResponseCount?.current_value,
    agentList: state.reporting?.agentList,
    isCsatTableDataLoading:
      state?.loading?.effects?.reporting?.fetchCsatResponseList,
  }));

  const csatRatingSelectionOptions = CSAT_METRICES_CONTENT.ratings.map((r) => ({
    label: r.text,
    value: r.rating.toString(),
  }));

  const csatAgentSelectionOptions = Array.from(agentList).map((a) => ({
    label: a.full_name.slice(0, 20),
    value: a.id,
    avatar: a.avatar,
  }));

  const handleCsatTableFilter = (
    filterConfig: any,
    paginationState: { pageIndex: number; pageSize: number }
  ) => {
    const filterFormat = filterConfig?.reduce(
      (
        p: Record<string, string | string[]>,
        c: { id: string; value: string | string[] }
      ) => {
        p[c.id] = c.value;
        return p;
      },
      {}
    );

    const filterDataParams = {
      teamId: projectId,
      search: filterFormat?.customerName,
      limit: paginationState?.pageSize ?? 10,
      offset: paginationState?.pageIndex * paginationState?.pageSize,
      agentsIds: filterFormat?.agent ?? [],
      ratings: filterFormat.rating ?? [],
    };

    dispatch.reporting.fetchCsatResponseList(filterDataParams);
    return;
  };

  const exportCSATSummary = () => {
    return dispatch.reporting?.exportCSatSummary(projectId);
  };

  const exportCSATSData = (tableFilterConfig: any) => {
    const filterFormat = tableFilterConfig?.reduce(
      (
        p: Record<string, string | string[]>,
        c: { id: string; value: string | string[] }
      ) => {
        p[c.id] = c.value;
        return p;
      },
      {}
    );

    const filterDataParams = {
      projectId: projectId,
      search: filterFormat?.customerName,
      offset: 0,
      agentsIds: filterFormat?.agent ?? [],
      ratings: filterFormat.rating ?? [],
    };
    return dispatch.reporting?.exportCSAT(filterDataParams);
  };

  const fetchAllCsatData = async (isChannelFilter = false) => {
    dispatch.reporting?.fetchCsatStack({
      teamId: projectId,
      url: 'csat-total-count',
    });
    dispatch.reporting?.fetchCsatStack({
      teamId: projectId,
      url: 'csat-responded-count',
    });
    dispatch.reporting?.fetchCsatStack({
      teamId: projectId,
      url: 'csat-average-rating',
    });
    dispatch.reporting?.fetchCsatStack({
      teamId: projectId,
      url: 'csat-response-count',
    });

    if (isChannelFilter) {
      dispatch.reporting?.fetchCsatResponseList({
        teamId: projectId,
        search: '',
        limit: 10,
        offset: 0,
      });
    }

    dispatch.reporting?.fetchCsatResponseDist(projectId);
  };

  return {
    platformsToFilter,
    updatePlatformsToFilter,
    csatTableData,
    fetchAllCsatData,
    exportCSATSummary,
    exportCSATSData,
    csatResponseDist,
    handleCsatTableFilter,
    csatRatingSelectionOptions,
    csatAgentSelectionOptions,
    projectId,
    isCsatTableDataLoading,
    totalResponseCount,
    totalCsatResponseWithoutFilter,
  };
};

export default useCsatReporting;
