import React from 'react';
import { IntegrationNavigationTabs } from '../../utils/content';
import IntegrationNavigationTabElements from './IntegrationNavigationTabElements';
import { navigate } from '@reach/router';

interface Props {
  currentTab: string;
  selectedPlatformCount: number;
}

const IntegrationNavigationTab: React.FC<Props> = ({
  currentTab,
  selectedPlatformCount,
}) => {
  return (
    <div className='w-full p-3 md:w-auto'>
      <div className='hidden md:block'>
        <div className='border-b border-gray-200'>
          <nav className='flex gap-8 space-x-4' aria-label='Tabs'>
            {IntegrationNavigationTabs.map((tab: any, i) => (
              <IntegrationNavigationTabElements
                key={i}
                id={i}
                tab={tab}
                selectedPlatformCount={selectedPlatformCount}
                handleUpdate={() => {
                  navigate(tab.slug);
                }}
                currentTab={currentTab}
              />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
export default IntegrationNavigationTab;
