import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Combobox, TextInput } from 'evergreen-ui';

const PopupBlockContent = ({
  items,
  inputVal,
  handleChange,
  blockType,
  attributeValue,
  handleInputValueChange,
}) => {
  return (
    <Fragment>
      <h5>Select a sequence</h5>
      <Combobox
        openOnFocus
        width='100%'
        initialSelectedItem={items.filter((itm) => itm.id === inputVal)[0]}
        items={items}
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleChange(selected.id)}
        placeholder='Sequence'
        style={{ borderRadius: '5px' }}
      />
      {blockType === 'quick_reply' && (
        <div className='mt-3'>
          <h5>Input Value</h5>
          <TextInput
            className={
              'rounded w-full focus:outline-none focus:ring-primary focus:border-primary'
            }
            width={'100%'}
            value={attributeValue}
            placeholder='Insert an input value'
            onChange={(e) => {
              handleInputValueChange(e.target.value);
            }}
          />
        </div>
      )}
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>
          The selected sequence will trigger if users choose this reply.
        </span>
      </p>
    </Fragment>
  );
};

PopupBlockContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputValueChange: PropTypes.func,
  attributeValue: PropTypes.string,
  blockType: PropTypes.string,
};

export default PopupBlockContent;
