import React from 'react';

interface props {
  title: string;
  description: string | React.ReactNode;
  image: string;
  childre?: React.ReactNode;
}

const LiveChatModalSteps: React.FC<props> = ({
  title,
  description,
  image,
  children,
}) => {
  return (
    <div className='flex flex-col'>
      <div className='w-[550px] h-[378px]'>
        <img className='m-auto h-[100%]' src={image} alt={'liveChatImage'} />
      </div>
      <div className='flex flex-col gap-9 px-8 py-6'>
        <div className='text-lg w-auto h-[150px]'>
          <p className='text-[#1F2937] font-bold'> {title} </p>
          <p className='text-[#6B7280] font-normal'> {description} </p>
        </div>

        {children}
      </div>
    </div>
  );
};

export default LiveChatModalSteps;
