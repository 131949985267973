import React from 'react';
import InputVariableField from '../InputVariableField';

interface Props {
  id: number;
  name: string;
  attribute: string;
  hasWriteAccess: boolean;
  attributeList: string[];
  showAttributeField: number | null;
  setShowAttributeField: (val: any) => void;
  handleFieldAttributeChange: (id: number, value: string) => void;
}

const LabFieldInput: React.FC<Props> = ({
  id,
  name,
  attribute,
  hasWriteAccess,
  attributeList,
  showAttributeField,
  setShowAttributeField,
  handleFieldAttributeChange,
}) => {
  return (
    <div className='flex' key={id}>
      <div className='flex-1 mr-3 mt-1.5 h-10 bg-gray-50 border border-solid border-gray-200 rounded'>
        <h3 className='text-sm mt-2.5 ml-3 text-gray-500'>
          {name.toUpperCase()}
        </h3>
      </div>
      <div className='flex-1 h-10 mt-1.5 rounded'>
        <InputVariableField
          hasWriteAccess={hasWriteAccess}
          showTitle={false}
          value={attribute}
          onChange={(value) => {
            handleFieldAttributeChange(id, value);
          }}
          attributeList={attributeList}
          showAttribute={id === showAttributeField}
          setShowAttribute={setShowAttributeField}
          handleSelect={(value) => {
            handleFieldAttributeChange(id, value);
          }}
          placeHolder={'stored variable name example: f.name'}
          inputClassName={
            'border rounded border-gray-300 text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-400'
          }
        />
      </div>
    </div>
  );
};

LabFieldInput.defaultProps = {
  hasWriteAccess: false,
};

export default LabFieldInput;
