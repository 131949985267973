import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { connect } from 'react-redux';
import { SideSheet } from '../../../../../library';
import { webChatResponseDataProps } from '../../../interface';
import ApplicationScript from '../../common/pages/ApplicationScript';
import WebchatScript from '../../common/pages/WebchatScript';

interface Props {
  isOpen: boolean;
  platfromType: string;
  channelData: webChatResponseDataProps;
  handleClose: () => void;
}

const PluginScript: React.FC<Props> = ({
  isOpen,
  platfromType,
  channelData,
  handleClose,
}) => {
  const { t } = useTranslation();
  const getTitle = () => {
    switch (platfromType) {
      case 'webchat':
        return {
          title: 'Live Chat Script',
          description:
            'The script needs to be inserted into the footer of the website',
        };
      default:
        return {
          title: 'Mobile App API Key',
          description: 'API key for your Mobile App',
        };
    }
  };

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => handleClose()}
        closeOnExternalClick={false}
        disableConfirm={false}
        title={t(getTitle().title)}
        description={t(getTitle().description)}
        confirmText={'Finish'}
        cancelText={'Back'}
        handleCancel={() => handleClose()}
        handleConfirm={() => handleClose()}
        hasLeftActionElement={false}
        hideCancel={true}
      >
        {platfromType === 'webchat' ? (
          <WebchatScript platfromData={channelData} />
        ) : (
          <ApplicationScript platfromData={channelData} />
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
  sequences: state.builder.sequences,
});

const mapDispatch = (dispatch: any) => ({
  fetchFacebookPages: (id: string, token: string, type: string) =>
    dispatch.integration.fetchFacebookPages({ id, token, type }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
    }),
});

export default connect(mapState, mapDispatch)(PluginScript);
