import { cn } from 'libraryV2/utils';

function Skeleton({
  className,
  style,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-primary/10', className)}
      {...props}
      style={{ ...style }}
    />
  );
}

export { Skeleton };
