import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import LiveChatSteps from './LiveChatSteps';
import { SelectedTeamInterface } from 'index';
import { channelInfo } from 'utilities/utils';
import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedProject: SelectedTeamInterface;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const LivechatModal: React.FC<Props> = ({
  open,
  handleClose,
  selectedProject,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all'>
                  <div className='w-full px-4 py-3 flex justify-start items-center border-b border-gray-200'>
                    <img
                      src={channelInfo('livechat_messenger').image}
                      width={32}
                      height={32}
                      alt='social_channel'
                    />
                    <span className='text-gray-700 font-semibold text-base leading-6 ltr:ml-2 rtl:mr-2'>
                      {t('Integrate Livechat')}
                    </span>

                    <XMarkIcon
                      color='#6B7280'
                      height={20}
                      className='float-right cursor-pointer ltr:ml-auto rtl:mr-auto'
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>
                  <LiveChatSteps
                    teamName={
                      selectedProject.name ? selectedProject.name : 'Your Store'
                    }
                    createChannelIntegrations={createChannelIntegrations}
                    handleClose={() => {
                      handleClose();
                    }}
                    projectId={selectedProject.id}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
});

export default connect(mapState, mapDispatch)(LivechatModal);
