import React from 'react';
import { GiftIcon } from '@heroicons/react/20/solid';
import Button from '../../../../../library/button';
import { InlineAlert } from '../../../../../library';
import { couponProps } from './PlanSelection';

interface Props {
  couponDiscount: couponProps | null;
  couponCode: string;
  handleCouponChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCouponCheck: () => void;
}

const CouponAdd: React.FC<Props> = ({
  couponDiscount,
  couponCode,
  handleCouponChange,
  handleCouponCheck,
}) => {
  return (
    <div className='flex justify-between px-4 py-3 bg-gray-50 sm:px-6'>
      <div>
        <label
          htmlFor='discount'
          className='text-sm font-medium text-gray-700 sr-only'
        >
          Add Discount
        </label>
        <div className='flex rounded-md shadow-sm'>
          <div className='relative flex items-stretch flex-grow focus-within:z-10'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <GiftIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
            </div>
            <input
              type='text'
              name='discount'
              id='discount'
              className={`${
                !couponDiscount || couponDiscount?.success
                  ? 'focus:ring-primary focus:border-primary'
                  : 'focus:ring-red-600 focus:border-red-600'
              } block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300`}
              placeholder='Add your coupon code...'
              disabled={couponDiscount?.success}
              value={couponCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCouponChange(e)
              }
            />
          </div>
          <Button
            size='md'
            intent='primary'
            className='relative -ml-px border border-gray-300 rounded-l-none rounded-r-md'
            isDisabled={couponDiscount?.success}
            onClick={handleCouponCheck}
          >
            Check
          </Button>
        </div>
      </div>

      <div>
        {!!couponDiscount && (
          <InlineAlert
            intent={couponDiscount.success ? 'success' : 'danger'}
            title={
              couponDiscount.success
                ? `${couponDiscount.dataSource.percent_off}% Discount Applied`
                : 'Invalid Coupon'
            }
          />
        )}
      </div>
    </div>
  );
};

export default CouponAdd;
