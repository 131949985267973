import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
import React from 'react';

dayjs.extend(localizedFormat);
dayjs.extend(preParsePostFormat);

interface Props {
  time: number;
}

const DateBar: React.FC<Props> = ({ time }) => {
  const { t, isRtlLanguage } = useTranslation();
  const getDateInfo = () => {
    let now = dayjs().endOf('day');
    let formatted_time = dayjs(time);
    if (isRtlLanguage) {
      formatted_time = dayjs(time).locale('ar');
    }
    let days = now.diff(formatted_time, 'days');

    if (days === 0) {
      return t('Today');
    } else if (days === 1) {
      return t('Yesterday');
    } else {
      let date = formatted_time.format('D MMMM YYYY, dddd');
      return `${date}`;
    }
  };
  return (
    <div className='relative pt-3.5'>
      <div className='absolute inset-0 top-[30%] flex items-center'>
        <div className='w-full border-b border-gray-100' />
      </div>
      <div className='relative flex justify-center'>
        <div className='border-[1px] h-7 bg-white border-gray-300 content-center rounded-full'>
          <div className='flex content-center px-3'>
            <div
              className='text-xs font-medium text-gray-700 bg-white'
              data-testid='text-date-bar'
            >
              {getDateInfo()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateBar;
