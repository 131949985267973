import { Menu, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { toaster } from 'evergreen-ui';
import Button from 'library/button';
import NewAlertModal from 'library/modal/NewAlertModal';
import { EmptyState } from 'pages/inbox/assets/iconComponent/SearchComponentIcons';
import React, { Fragment, useEffect, useState } from 'react';
import { classNames } from 'utilities/utils';
import { SideSheet } from '../../../../../library';

import useTranslation from 'components/customHooks/useTranslation';
import { WhatsappMessageTemplateProps } from 'index';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { startCase } from 'lodash';
import { IWhatsappTemplate } from 'pages/automationWorkflow/interface';
import {
  channelListProps,
  sequenceProps,
  whatsappMessageTemplateProps,
} from 'pages/integration/interface';
import { WhatsappTemplateMangeActionPermissions } from 'pages/integration/utils/content';
import ReactTooltip from 'react-tooltip';
import CreateWhatsAppTemplateV2, {
  WhatsappTemplateActionType,
} from './CreateWhatsAppTemplateV2';

interface Props {
  isOpen: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  channelData: channelListProps;
  sequences: sequenceProps[];
  deleteWhatsappMessageTemplate: (payload: any) => any;
}

const ManageWhatsAppTemplatesData: React.FC<Props> = ({
  isOpen,
  handleConfirm,
  handleClose,
  channelData,
  sequences,
  deleteWhatsappMessageTemplate,
}) => {
  const { t } = useTranslation();
  const [clickedDetails, setClickedDetails] = React.useState<string>('');
  const [isInputFocused, setInputFocus] = React.useState<boolean>(false);
  const [templateActionType, setTemplateActionType] =
    useState<WhatsappTemplateActionType>('VIEW');
  const [templates, setTemplates] = React.useState([
    ...channelData?.whatsapp_message_templates,
  ]);
  const [showDeleteWarning, setShowDeleteWarning] =
    React.useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<whatsappMessageTemplateProps | null>(null);
  useEffect(() => {
    // eslint-disable-next-line
    ReactTooltip.rebuild();
  }, []);

  const handleBack = () => {
    handleTemplateSearch('');
    setClickedDetails('');
  };

  const getTemplateData = () => {
    return channelData.whatsapp_message_templates.filter(
      (e) => e.id === clickedDetails
    )[0];
  };

  const renderEmptyStateView = () => {
    return (
      <tbody>
        <tr>
          <td colSpan={12}>
            <div className='w-full px-4 text-center py-14 sm:px-14'>
              <EmptyState
                className='mx-auto text-gray-400'
                aria-hidden='true'
              />
              <p className='mt-4 text-sm text-gray-500'>
                {t('No Templates Found')}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    );
  };

  const handleTemplateSearch = (query: string) => {
    const filteredData = channelData?.whatsapp_message_templates?.filter(
      (data) =>
        data.name.toLowerCase().includes(query.toLowerCase()) ||
        data.id.toString().toLowerCase().includes(query.toLowerCase()) ||
        data.status.toLowerCase().includes(query.toLowerCase())
    );
    setTemplates(filteredData || []);
  };

  const handleTemplateDelete = async (templateId: number | string) => {
    const response = await deleteWhatsappMessageTemplate({
      channelId: channelData.id,
      templateId,
    });

    if (response?.status === 500) {
      toaster.danger(t('Could not delete the template. Please try again!'));
      return;
    }

    const successMessage = t('Message Template deleted Successfully!!');
    const errorMessage = t(
      response?.data?.error ||
        'Could not delete the template. Please try again!'
    );

    toaster[response?.data?.dataSource?.success ? 'success' : 'danger'](
      response?.data?.dataSource?.success ? successMessage : errorMessage
    );

    if (response?.data?.dataSource?.success) {
      handleClose();
    }
  };

  const renderSearchbarWithButton = () => {
    return (
      <div className='py-2 mt-2 flex items-center'>
        <div
          className={classNames(
            'flex items-center border rounded-md px-3 w-2/3 py-2',
            isInputFocused ? 'border-green-500' : 'border-gray-300'
          )}
        >
          <MagnifyingGlassIcon height={20} color='#9CA3AF' />
          <input
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            className='w-full rounded-md border-0 text-sm  font-normal  px-2 focus:border-transparent focus:ring-0 focus:outline-none focus:ring-offset-0 text-[#111827]'
            placeholder={t('Search templates by name, id')}
            onChange={(event) => {
              handleTemplateSearch(event.target.value || '');
            }}
          />
        </div>
        <Button
          intent='primary'
          size='md'
          className='ltr:ml-auto rtl:mr-auto text-sm py-2'
          onClick={handleConfirm}
        >
          + {t('Create Template')}
        </Button>
      </div>
    );
  };

  const renderTemplateTableMenuActions = (
    template: WhatsappMessageTemplateProps
  ) => {
    const currentTemplateStatus =
      template.status?.toLowerCase() as IWhatsappTemplate['status'];
    const shouldEnableEdit =
      WhatsappTemplateMangeActionPermissions.templateEdit.includes(
        currentTemplateStatus
      );
    return (
      <Menu as='div' className='relative block ltr:text-left rtl:text-right'>
        <Menu.Button className='w-full flex items-center justify-center'>
          <EllipsisHorizontalIcon className='w-5 h-5 text-gray-500' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <Menu.Items className='absolute left-0 z-50 w-24 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='hover:bg-gray-100'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className='block text-left text-gray-800 px-4 py-2 text-sm  w-full'
                    onClick={() => {
                      setClickedDetails(template.id);
                      setTemplateActionType('VIEW');
                    }}
                  >
                    {t('View')}
                  </span>
                )}
              </Menu.Item>
            </div>
            <div
              className={cn('hover:bg-gray-100', {
                hidden:
                  template.category === 'AUTHENTICATION' || !shouldEnableEdit,
              })}
            >
              <Menu.Item>
                {({ active }) => (
                  <span
                    className='block text-left text-gray-800 px-4 py-2 text-sm w-full'
                    onClick={() => {
                      setClickedDetails(template.id);
                      setTemplateActionType('EDIT');
                    }}
                  >
                    {t('Edit')}
                  </span>
                )}
              </Menu.Item>
            </div>

            <div
              className={classNames(
                'hover:bg-gray-100',
                ['pending_deletion'].includes(template?.status.toLowerCase())
                  ? 'hidden'
                  : 'block'
              )}
            >
              <Menu.Item>
                {({ active }) => (
                  <span
                    className='block text-left text-red-500 px-4 py-2 text-sm'
                    onClick={() => {
                      setSelectedTemplate(template);
                      setShowDeleteWarning(true);
                    }}
                  >
                    {t('Delete')}
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderTableBodyView = () => {
    return (
      <tbody className='bg-white divide-y divide-gray-200 max-h-[75vh] overflow-y-auto'>
        {templates.map((template, index) => {
          const templateStatusColor =
            template.status === 'approved'
              ? 'bg-green-100 text-green-800'
              : template.status === 'pending'
              ? 'bg-[#fef9c3] text-[#854d0e]'
              : 'bg-red-100 text-red-800';

          return (
            <>
              <tr key={index} className='relative'>
                <td
                  className={`px-6 py-4 text-sm text-gray-500 whitespace-nowrap rounded-md`}
                >
                  <div className=''>
                    <span
                      data-for={template?.id}
                      data-tip={template?.name}
                      className={`flex truncate ... w-72`}
                    >
                      {template?.name}
                    </span>
                    {template?.name && template.name.length > 50 && (
                      //@ts-ignore
                      <ReactTooltip
                        id={template?.id}
                        type='dark'
                        effect='solid'
                        backgroundColor='#4B5563'
                        multiline={true}
                        place='top'
                        className='w-auto break-words whitespace-pre-wrap sm:text-sm'
                      />
                    )}
                  </div>
                </td>
                <td className='text-sm truncate text-gray-500'>
                  {template?.id}
                </td>
                <td
                  className={`truncate  px-6 py-4 text-sm text-gray-500 flex items-center justify-center `}
                >
                  <TooltipProviderCustomised
                    content={
                      template.status === 'rejected'
                        ? template?.failed_reason
                        : ''
                    }
                  >
                    <span
                      className={`${templateStatusColor} cursor-default inline-flex items-center px-3 py-0.5 rounded-md`}
                    >
                      {t(startCase(template.status))}
                    </span>
                  </TooltipProviderCustomised>
                </td>
                <td
                  className={`px-6 py-4 text-sm text-blue-500 whitespace-nowrap cursor-pointer rounded-md`}
                >
                  {renderTemplateTableMenuActions(template)}
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    );
  };

  const renderTemplates = () => {
    if (templates.length < 1) {
      return renderEmptyStateView();
    } else {
      return renderTableBodyView();
    }
  };

  const deleteWarningModal = () => {
    if (!!selectedTemplate) {
      return (
        <NewAlertModal
          isShown={showDeleteWarning}
          intent='danger'
          title={t('Delete Template?')}
          description={t(
            `You are about to delete {{${selectedTemplate?.name}}}. Do you want to continue?`
          )}
          cancelButtonTitle={t('Cancel')}
          confirmButtonTitle={t('Delete')}
          onToggle={() => setShowDeleteWarning(!showDeleteWarning)}
          onConfirm={() => {
            handleTemplateDelete(selectedTemplate?.id);
            setSelectedTemplate(null);
          }}
          onClose={() => {
            setShowDeleteWarning(false);
            setSelectedTemplate(null);
          }}
        />
      );
    }
    return;
  };

  const renderTableView = () => {
    return (
      <div className='mx-4 border border-gray-200 rounded-md'>
        <table className='min-w-full relative divide-y rounded-md border-collapse border-none border-spacing-0   divide-gray-200'>
          <thead className='bg-gray-50 w-full'>
            <tr>
              <th
                scope='col'
                className='flex rounded-tl-md px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
              >
                {t('TEMPLATE NAME')}
              </th>
              <th className='text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'>
                {t('TEMPLATE ID')}
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase'
              >
                {t('STATUS')}
              </th>
              <th
                scope='col'
                className='flex rounded-tr-md px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase'
              >
                {t('ACTIONS')}
              </th>
            </tr>
          </thead>
          {renderTemplates()}
        </table>
      </div>
    );
  };

  return (
    <>
      {deleteWarningModal()}
      {!clickedDetails ? (
        <SideSheet
          open={isOpen && !showDeleteWarning}
          hasMinimizeButton={false}
          handleClose={() => {
            handleClose();
          }}
          closeOnExternalClick={false}
          title={t('WhatsApp Message Templates')}
          confirmText={t('Create New Message Template')}
          hasFooter={false}
          handleConfirm={handleConfirm}
          hasLeftActionElement={false}
          hideCancel={true}
          headerClassNames='border-0 bg-white'
          isDescriptionFullSize={true}
          description={renderSearchbarWithButton()}
          width='md:max-w-3xl'
        >
          {renderTableView()}
        </SideSheet>
      ) : (
        <>
          <CreateWhatsAppTemplateV2
            isOpen={isOpen}
            handleClose={handleBack}
            channelData={channelData}
            templateData={getTemplateData()}
            actionType={templateActionType}
            sequence={sequences}
          />
        </>
      )}
    </>
  );
};

export default ManageWhatsAppTemplatesData;
