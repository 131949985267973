import React from 'react';
import emptyState from './../../../assets/images/emptyStateProductInteraction.svg';
import { ClockIcon } from '@heroicons/react/24/outline';
import dayJs from 'dayjs';
import { Link } from '../../../../../../library';
import { connect } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { navigate } from '@reach/router';
import Modal from 'pages/billing/component/Modal';
import TrialModal from 'pages/billing/component/TrialModal';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  data: productProps[];
  currentTabId: number;
  currencySymbol: string;
  selectedProject: SelectedTeamInterface;
  userInfo: UserPropertyType;
}

interface productProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  unit_price: number;
  timestamp: number;
}

interface EmptyStateProps {
  currentTabId: number;
}

const getEmptyText = (currentTabId: number) => {
  switch (currentTabId) {
    case 0:
      return 'This customer didn’t purchase any product.';
    case 1:
      return 'This customer didn’t add any product in cart.';
    case 2:
      return 'This customer didn’t view any product.';
    default:
      return 'Not Available';
  }
};

const EmptyState: React.FC<EmptyStateProps> = ({ currentTabId }) => {
  return (
    <div className='flex items-center justify-center p-4 flex-column'>
      <img width={65} src={emptyState} alt={'empty'} />
      <p className='px-6 mt-2 text-xs font-medium text-center text-gray-500'>
        {getEmptyText(currentTabId)}
      </p>
    </div>
  );
};

const ProductInteractionList: React.FC<Prop> = ({
  data,
  currentTabId,
  currencySymbol,
  selectedProject,
  userInfo,
}) => {
  const { t } = useTranslation();
  const [openTrialModal, setOpenTrialModal] = React.useState(false);

  const overlay = (
    setOpenTrialModal: (payload: boolean) => void,
    userInfo: UserPropertyType,
    selectedProject: SelectedTeamInterface
  ) => {
    return (
      <div className='z-10 relative'>
        <div
          className='absolute w-full h-full'
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div className='flex items-center flex-col h-96 bg-[#ffffffe6]'>
            <LockClosedIcon className='w-10 h-10 text-gray-400 p-2 bg-gray-100 rounded-[50%] mt-5' />
            {userInfo.email === selectedProject?.creator?.email ? (
              <p>
                {t('Unlock with')}{' '}
                <span
                  className='text-blue-500 cursor-pointer'
                  onClick={() => navigate('/settings/billing')}
                >
                  {t('Premium Plan')}
                </span>
              </p>
            ) : (
              <p>{t('Unlock with Premium Plan')}</p>
            )}
            {!userInfo.trial_availed &&
              userInfo.email === selectedProject?.creator?.email && (
                <p
                  className='text-blue-500 cursor-pointer'
                  onClick={() => setOpenTrialModal(true)}
                >
                  {t('Avail Trial')}
                </p>
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='relative inline-block'>
      {selectedProject?.has_ecommerce_connected &&
        selectedProject?.subscription_plan?.name === 'free' &&
        overlay(setOpenTrialModal, userInfo, selectedProject)}
      {!!data && data.length === 0 && (
        <EmptyState currentTabId={currentTabId} />
      )}
      <ul className='grid grid-cols-2 gap-x-4'>
        {data.length > 0 &&
          data.map((product: any, index: number) => (
            <li key={index} className='relative mb-3'>
              <div className='block w-full h-24 overflow-hidden bg-gray-100 rounded-lg  aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500'>
                <img
                  src={
                    product?.product_images.length > 0
                      ? product.product_images[0]
                      : emptyState
                  }
                  alt=''
                  className='object-cover transform scale-150 pointer-events-none group-hover:opacity-75'
                />
                <button
                  type='button'
                  className='absolute inset-0 focus:outline-none'
                >
                  <span className='sr-only'>
                    {t('View details for')}{' '}
                    {t(product?.product_name || 'Not Available')}
                  </span>
                </button>
              </div>
              <div className='relative flex flex-column'>
                <div className='absolute -top-7 right-1.5'>
                  <span className='inline-flex items-center px-2 py-0.25 rounded-sm text-xs font-normal bg-green-100 text-green-800'>
                    {currencySymbol}
                    {product?.unit_price || 0}
                  </span>
                </div>
                <div className='mt-2'>
                  <Link
                    url={product.product_link}
                    textSize={'sm'}
                    fontWeight={'normal'}
                    openInNewTab={true}
                  >
                    {product.product_name}
                  </Link>
                  <p className='flex items-center mt-1 font-normal text-gray-500 pointer-events-none text-xss'>
                    <ClockIcon className='w-3 h-3 mr-1' />
                    {!!product?.timestamp
                      ? dayJs(product.timestamp).format('hh:mm A, DD MMM YYYY')
                      : 'Not Available'}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
      {openTrialModal && (
        <Modal>
          <TrialModal setOpenTrialModal={setOpenTrialModal} />
        </Modal>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.settings.selectedProject,
  userInfo: state.auth,
});
const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(ProductInteractionList);
