import React from 'react';
import BlockContainer from './BlockContainer';
import { Icon, Card, Pane, Radio } from 'evergreen-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getButtonTypes, reorderList } from '../../../utilities/utils';
import ButtonGroup from '../elements/ButtonGroup';
import ImageUploadField from '../../../components/utilityComponent/ImageUploadField';
import PropTypes from 'prop-types';
import { handleImageUpload } from '../../../utilities/utils';
import '../../assets/styles/blocks/gallery.scss';
import galleryIcon from '../../pages/builder/assets/images/icons/gallery.svg';
import CreateButtonFragment from '../elements/CreateButtonFragment';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';

const GalleryBlock = ({
  id,
  data,
  blockData,
  save,
  saveLoader,
  sequences,
  labsData,
  saveBlock,
  apiList,
  platformType,
  handleAPIButtonElementCreate,
  handleTemplateDelete,
  handleButtonDelete,
  handleCreate,
  handleUpdate,
  handleGalleryDropUpdate,
  handleButtonDropUpdate,
  handleButtonCreate,
  deleteBlock,
  copyBlock,
  moveBlock,
  type,
  ratio,
  updateGalleryBlockData,
  hasWriteAccess,
  subscriptionPlanType,
}) => {
  const handleImageDrop = (index, image) => {
    const callback = (image) => handleUpdate(id, index, image, 'image');
    handleImageUpload(image, callback);
  };

  const handleImageClear = (index) => {
    handleUpdate(id, index, '', 'image');
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      data,
      result.source.index,
      result.destination.index
    );

    handleGalleryDropUpdate(id, items);
  };

  const isFacebookMessenger = platformType === 'facebook_messenger';
  const isViber = platformType === 'viber_messenger';
  const isWhatsApp = platformType === 'whatsapp_messenger';
  const isWhatsAppBSP = platformType === 'whatsapp_bsp';
  const isTelegram = platformType === 'telegram_messenger';
  return (
    <BlockContainer
      id={id}
      title='Gallery'
      subtitle='Send images with buttons to showcase different products and services.'
      save={save}
      isLoading={saveLoader}
      icon={galleryIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='gallery-block'>
        <DragDropContext
          onDragEnd={(result) => hasWriteAccess && onDragEnd(result)}
        >
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className='gallery-block__droppable'
              >
                {data.map((gallery, i) => (
                  <Draggable key={i} draggableId={`gallery-${i}`} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='gallery-block__draggable'
                      >
                        <Card className='gallery-block__item' key={i}>
                          <ImageUploadField
                            image={gallery.image}
                            hasWriteAccess={hasWriteAccess}
                            handleDrop={(image) => handleImageDrop(i, image)}
                            handleClear={() => handleImageClear(i)}
                          />
                          <div className='mt-1'>
                            <input
                              required={true}
                              value={gallery.title}
                              type='text'
                              disabled={!hasWriteAccess}
                              className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                              onChange={(e) =>
                                handleUpdate(id, i, e.target.value, 'title')
                              }
                              placeholder={
                                isWhatsApp || isWhatsAppBSP
                                  ? 'Header text*'
                                  : 'Title*'
                              }
                            />
                          </div>
                          <input
                            required={true}
                            value={gallery.subtitle}
                            type='text'
                            disabled={!hasWriteAccess}
                            className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                            onChange={(e) =>
                              handleUpdate(id, i, e.target.value, 'subtitle')
                            }
                            placeholder={
                              isWhatsApp || isWhatsAppBSP
                                ? 'Text to send with the button replies*'
                                : 'Subtitle*'
                            }
                          />
                          {!isWhatsApp && !isWhatsAppBSP && (
                            <div className='mt-1'>
                              <input
                                required={false}
                                value={gallery.url}
                                type='url'
                                disabled={!hasWriteAccess}
                                className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                                onChange={(e) =>
                                  handleUpdate(id, i, e.target.value, 'url')
                                }
                                placeholder='Add an image URL (optional)'
                              />
                            </div>
                          )}
                          {isFacebookMessenger && (
                            <div className='aspect-ratio__container'>
                              <p>Image Aspect Ratio:</p>
                              <Pane display={'flex'}>
                                <Radio
                                  marginRight={10}
                                  disabled={!hasWriteAccess}
                                  onChange={() =>
                                    updateGalleryBlockData(
                                      id,
                                      'image_aspect_ratio',
                                      'square'
                                    )
                                  }
                                  label='Square'
                                  checked={ratio === 'square'}
                                />
                                <Radio
                                  label='Horizontal '
                                  disabled={!hasWriteAccess}
                                  onChange={() =>
                                    updateGalleryBlockData(
                                      id,
                                      'image_aspect_ratio',
                                      'horizontal'
                                    )
                                  }
                                  checked={ratio === 'horizontal'}
                                />
                              </Pane>
                            </div>
                          )}
                          <ButtonGroup
                            blockId={id}
                            type={type}
                            hasWebViewHeight={true}
                            hasMessengerExtension={true}
                            sequences={sequences}
                            hasWriteAccess={hasWriteAccess}
                            formsData={
                              labsData &&
                              labsData.filter((l) => l.type === 'form')
                            }
                            allowedTypes={getButtonTypes(
                              platformType,
                              subscriptionPlanType
                            )}
                            buttonTitle={'Create a Button'}
                            maxButtonCount={isTelegram ? 10 : 3}
                            buttonList={gallery.buttons}
                            handleCreate={(id) => handleButtonCreate(id, i)}
                            handleUpdate={(id, data, changeKey) =>
                              handleUpdate(id, i, data, changeKey)
                            }
                            handleDelete={(blockId, buttonIndex) =>
                              handleButtonDelete(blockId, buttonIndex, i)
                            }
                            handleDropUpdate={(blockId, data) =>
                              handleButtonDropUpdate(blockId, i, data)
                            }
                            dragDirection='vertical'
                          />
                          {hasWriteAccess && (
                            <div className={'gallery-block__item-delete'}>
                              <Icon
                                onClick={() => handleTemplateDelete(id, i)}
                                icon='trash'
                                size={12}
                              />
                            </div>
                          )}
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {!!blockData.api ? (
                  <FetchFromAPIButtonSection
                    blockId={id}
                    hasWriteAccess={hasWriteAccess}
                    apiButtonData={blockData.api}
                    handleDelete={handleAPIButtonElementCreate}
                  />
                ) : (
                  data.length === 0 &&
                  hasWriteAccess && (
                    <CreateButtonFragment
                      type={type}
                      hasAPIButton={['enterprise', 'business'].includes(
                        subscriptionPlanType
                      )}
                      buttonTitle={'Create a Gallery Item'}
                      APIButtonTitle={'Create an API Gallery Item'}
                      buttonCount={data.length}
                      handleCreate={() => handleCreate(id)}
                      apiButtonData={blockData.api}
                      apiList={apiList}
                      handleAPIButtonElementCreate={
                        handleAPIButtonElementCreate
                      }
                      blockId={id}
                    />
                  )
                )}
                {data.length > 0 &&
                  data.length < (isViber ? 8 : 10) &&
                  data.length < (isWhatsApp ? 1 : 10) &&
                  data.length < (isWhatsAppBSP ? 1 : 10) &&
                  data.length < (isTelegram ? 1 : 10) &&
                  hasWriteAccess && (
                    <Card
                      className='gallery-block__item-add'
                      onClick={() => handleCreate(id)}
                    >
                      <Icon icon='plus' size={80} />
                    </Card>
                  )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </BlockContainer>
  );
};

GalleryBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  apiList: PropTypes.arrayOf(PropTypes.object),
  platformType: PropTypes.string,
  blockData: PropTypes.object.isRequired,
  labsData: PropTypes.array,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleButtonCreate: PropTypes.func,
  handleTemplateDelete: PropTypes.func,
  handleButtonDelete: PropTypes.func,
  handleAPIButtonElementCreate: PropTypes.func,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  updateGalleryBlockData: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
GalleryBlock.defaultProps = {
  platformType: 'facebook_messenger',
  updateGalleryBlockData: () => {},
  hasWriteAccess: false,
};

export default GalleryBlock;
