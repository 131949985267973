import { useState } from 'react';
import './style.scss';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  color?: string;
  bgColor?: string;
  onValueChange: (value: string) => void;
}

const RangeSlider: React.FC<props> = ({
  min = 0,
  max = 100,
  step = 1,
  value = 0,
  color = '#04B25F',
  bgColor = '#D1D5DB',
  onValueChange,
}) => {
  const { isRtlLanguage } = useTranslation();
  const handleBackgroundChange = (val: number) => {
    const actualPercentage = (100 * (val - min)) / (max - min);
    const percentage = isRtlLanguage
      ? 100 - actualPercentage
      : actualPercentage;
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${color} ${percentage}%, ${bgColor} ${
      percentage + 0.06
    }%)`;
    return bg;
  };

  const [gradiantBackground, setGradiantBackground] = useState<string>(
    handleBackgroundChange(value)
  );

  return (
    <div
      className='alice-range-slider_wrapper flex relative'
      style={{ background: gradiantBackground }}
    >
      <input
        className='alice-range-slider absolute rtl:left-0 bg-right'
        type='range'
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(event) => {
          onValueChange(event.target.value);
          setGradiantBackground(
            handleBackgroundChange(Number(event.target.value))
          );
        }}
      />
    </div>
  );
};

export default RangeSlider;
