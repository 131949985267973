import React from 'react';
import RangeSlider from '../components/RangeSlider';
import { createNlpRequestProps } from '../../../interface';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  errorMessage?: string;
  nlpInfo: createNlpRequestProps;
  handleChange: (key: string, value: string | number) => void;
}

const WitSetting: React.FC<Props> = ({
  success = true,
  errorMessage = 'Something went wrong while creating NLP channle. Try again',
  nlpInfo,
  handleChange,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My wit app')}
            value={nlpInfo?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='name'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
        </div>
      </div>
      <div className='p-6 border-t'>
        <p className='text-sm font-medium text-gray-500'>
          {t('You need to create an App in')}
          <a
            href='https://wit.ai/'
            target='_blank'
            className='text-blue-500 hover:text-blue-800'
            rel='noreferrer'
          >
            {` wit.ai `}
          </a>
          ({t(`first to connect it with ${partnerName}.`)}
          {t(
            'Go to your app settings and collect the "App ID", "Server Access Token" & "Version".'
          )}
          ) {t('Find the details')}
          <a
            href='https://docs.myalice.ai/chatbot-automation/natural-language-processing-nlp/connect-wit-nlp'
            target='_blank'
            className='text-blue-500 hover:text-blue-800'
            rel='noreferrer'
          >
            {' '}
            {t('here')}{' '}
          </a>
          {t('on how to get these values.')}
        </p>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='app_id'
            className='block text-sm font-medium text-gray-700'
          >
            {t('App Id')}*
          </label>
          <input
            type='text'
            placeholder={t('App ID found in your wit.app')}
            value={nlpInfo.app_id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='app_id'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='access_token'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Server Access Token')}*
          </label>
          <input
            type='text'
            placeholder={t('Server Access Token found in your wit.app')}
            value={nlpInfo.access_token}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='access_token'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
        </div>
      </div>
      {/* version will be added later */}
      {/* <div className='border-t'>
        <label
          htmlFor='api_version'
          className='block mt-6 ml-6 text-sm font-medium text-gray-700'
        >
          Version
        </label>
        <div className='p-6'>
          <select
            name='api_version'
            value={'202109'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            className='block w-full px-3 mb-3 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
          >
            <option value=''>Choose version from here</option>
            {versions.map((item: string, index: number) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className='border-t'>
        <label
          htmlFor='nlp_confidence'
          className='flex mt-6 ltr:ml-6 rtl:mr-6 text-sm font-medium text-gray-700'
        >
          {t(`NLP Response With Confidence.`)} {nlpInfo.confidence}
        </label>
        <RangeSlider
          min={0}
          max={1}
          step={0.1}
          currentValue={nlpInfo.confidence}
          defaultValue={0}
          handleChange={(value) => handleChange('confidence', value)}
        />
        <p className='px-6 flex mt-2 text-sm text-gray-500'>
          {t(
            `Minimum confidence value for ${partnerName} Automation to respond to the message. Ideally you want to keep the confidence >0.9 if you have low amount of training data. If you have high amount of training data, we recommend you to keep it in between 0.7-0.9.`
          )}
        </p>
      </div>
    </>
  );
};
export default WitSetting;
