import React from 'react';

interface Prop {
  orderNote?: string;
  handleChange: (key: string, value: string) => void;
}

const OrderNote: React.FC<Prop> = ({
  orderNote,
  handleChange,
}) => {
  return (
    <div className='px-6 pt-4 pb-6 mt-0 border-b'>
      <label
        htmlFor='country'
        className='block text-sm font-medium text-gray-700'
      >
        Note
      </label>
      <div className='flex'>
        <div className='relative flex-1'>
            <input
                type='text'
                placeholder='Write your notes here'
                value={orderNote}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e.target.name, e.target.value)
                }
                name='orderNote'
                className='block w-full px-3 mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
            />
        </div>
      </div>
    </div>
  );
};

export default OrderNote;
