import React from 'react';
import DatePicker from './DatePicker';
import Spinner from './Spinner';
import { ScheduleTimeProps } from '../interface';
import { SelectedTeamInterface } from 'index';
import Modal from './content/Modal';
import { XMarkIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  createCampaign: (payload: string) => void;
  setScheduleTimeStamp: (payload: string) => void;
  gmtTime: string;
  isBroadcasting: boolean;
  selectedProject: SelectedTeamInterface;
}

const ScheduleBoradcast: React.FC<Props> = ({
  createCampaign,
  setScheduleTimeStamp,
  gmtTime,
  isBroadcasting,
  selectedProject,
}) => {
  const { t } = useTranslation();
  const [scheduleTime, setScheduleTime] = React.useState<ScheduleTimeProps>({
    date: dayjs().format('DD').toLocaleLowerCase(),
    month: dayjs().format('MMMM').toLocaleLowerCase(),
    year: dayjs().format('YYYY').toLocaleLowerCase(),
    hour: dayjs().format('h'),
    minute: dayjs().format('m'),
    others: dayjs().format('A'),
  });
  const [openScheduleModal, setOpenScheduleModal] = React.useState(false);

  const ref: any = React.useRef();

  const hourFormat = (
    hour: number | string | null,
    others: number | string | null
  ) => {
    if (others === 'PM' && Number(hour) < 12) {
      return Number(hour) + 12;
    } else if (others === 'AM' && hour === 12) {
      return 0;
    }
    return hour;
  };

  const timeFormat = (time: number | string | null) => {
    // @ts-ignore
    if (time < 9) return '0' + time;
    return time;
  };

  const capitalize = (str: string | number | null, lower: boolean) =>
    // @ts-ignore
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );

  const formatTime = () => {
    const gmtDate =
      capitalize(scheduleTime.month, false) +
      ' ' +
      scheduleTime.date +
      ',' +
      ' ' +
      scheduleTime.year +
      ' ' +
      timeFormat(hourFormat(scheduleTime.hour, scheduleTime.others)) +
      ':' +
      timeFormat(scheduleTime.minute) +
      ':00' +
      ' ' +
      'GMT' +
      selectedProject.timezone_offset;

    setScheduleTimeStamp(gmtDate);
    return gmtDate;
  };

  const months = [
    { name: 'JAN', value: 'january' },
    { name: 'FEB', value: 'february' },
    { name: 'MAR', value: 'march' },
    { name: 'APR', value: 'april' },
    { name: 'MAY', value: 'may' },
    { name: 'JUNE', value: 'june' },
    { name: 'JULY', value: 'july' },
    { name: 'AUG', value: 'august' },
    { name: 'SEP', value: 'september' },
    { name: 'OCT', value: 'october' },
    { name: 'NOV', value: 'november' },
    { name: 'DEC', value: 'december' },
  ];

  React.useEffect(() => {
    formatTime();
    // eslint-disable-next-line
  }, [scheduleTime]);

  const renderBroadcastScheduleTime = () => {
    return (
      <>
        {t('The broadcast will be sent on')}{' '}
        <span className='text-gary-800 font-semibold'>
          {scheduleTime.date}{' '}
          {
            months[
              months.findIndex((item) => item.value === scheduleTime.month)
            ]?.name
          }{' '}
          {scheduleTime.year},{' '}
          {Number(scheduleTime.hour) < 10
            ? '0' + scheduleTime.hour
            : scheduleTime.hour}
          :
          {Number(scheduleTime.minute) < 10
            ? '0' + scheduleTime.minute
            : scheduleTime.minute}{' '}
          {scheduleTime.others} (GMT{gmtTime.split(':')[0]})
        </span>
        .
      </>
    );
  };

  return (
    <div className='w-full max-w-sm'>
      <>
        <button
          onClick={() => {
            setOpenScheduleModal(true);
          }}
          className={`
                group inline-flex items-center ltr:rounded-l-md rtl:rounded-r-md bg-[#04B25F] px-3 py-2
                text-sm text-white hover:text-opacity-100 focus:outline-none
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 h-9`}
        >
          {isBroadcasting && (
            <span className=''>
              <Spinner
                color='border-r-transparent border-t-white border-b-white border-l-white mr-2'
                size='w-4 h-4'
              />
            </span>
          )}
          {t('Schedule')}
        </button>

        <Modal open={openScheduleModal} setOpen={setOpenScheduleModal}>
          <div className='bg-white p-4 relative w-[376px] border border-gray-200 rounded-md shadow'>
            <p className='flex justify-between relative'>
              <span className='text-gray-900 text-base font-medium leading-5 mb-6 mt-2'>
                {t('Schedule')}
              </span>
              <XMarkIcon
                className='w-4 h-4 text-gray-500 cursor-pointer absolute ltr:right-0 rtl:left-0'
                onClick={() => {
                  setOpenScheduleModal(false);
                }}
              />
            </p>
            <div className='border border-gray-300 rounded-lg mb-3'>
              <div className='p-3'>
                <p className='text-gray-600 text-xs leading-4 font-medium rtl:text-right'>
                  {t('Select Date')}
                </p>
                <DatePicker
                  type='date'
                  setScheduleTime={setScheduleTime}
                  scheduleTime={scheduleTime}
                />
              </div>
              <div className='border-t border-t-gray-300 p-3'>
                <p className='text-gray-600 text-xs leading-4 font-medium rtl:text-right'>
                  {t('Select Time')}
                </p>
                <DatePicker
                  type='time'
                  setScheduleTime={setScheduleTime}
                  scheduleTime={scheduleTime}
                />
              </div>
              <div className='border-t border-t-gray-300 p-3'>
                <p className='text-gray-600 text-xs leading-4 font-medium rtl:text-right pb-1'>
                  {t('Time Zone')}
                </p>
                <button
                  className='w-full rounded-md py-2 px-3 bg-gray-200 text-gray-500 text-sm leading-5 text-left
                            border border-[#D1D5DB]'
                  disabled
                >
                  GMT{gmtTime}
                </button>
              </div>
            </div>
            <p className='text-gray-500 text-sm leading-5 mb-3 rtl:text-right'>
              {renderBroadcastScheduleTime()}
            </p>
            <button
              className='bg-[#04B25F] text-white py-2 px-3 w-full
                          rounded-md flex gap-2 justify-center items-center'
              onClick={() => {
                createCampaign('schedule');
              }}
              ref={ref}
            >
              {isBroadcasting && (
                <Spinner
                  color='border-r-transparent border-t-white border-b-white border-l-white'
                  size='w-4 h-4'
                />
              )}
              {t('Confirm Schedule')}
            </button>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default ScheduleBoradcast;
