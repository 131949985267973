import { ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { getMonth, getYear } from 'date-fns';
import ar from 'date-fns/locale/ar';
import enUS from 'date-fns/locale/en-US';
import { range } from 'lodash';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
interface Prop {
  value?: string;
  title?: string;
  disable?: boolean;
  className?: string;
  placeholder: string;
  popUpPosition?: any;
  showIcon?: boolean;
  calendarClassName?: string;
  showTimeInput?: boolean;
  showTimeSelect?: boolean;
  dateTimeFormat?: string;
  shouldCloseOnSelect?: boolean;
  showTimeSelectOnly?: boolean;
  enableDefaultDate?: boolean;
  handleOnchange: (value: any) => void;
}

const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const yearList = range(2019, getYear(new Date()) + 1, 1);
const inputClassName =
  'w-auto py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm';

const DateTimeSelect: React.FC<Prop> = ({
  value,
  title,
  className,
  placeholder,
  handleOnchange,
  disable = false,
  showIcon = false,
  showTimeInput = false,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  shouldCloseOnSelect = false,
  calendarClassName = 'bg-white',
  dateTimeFormat = 'MMMM d, yyyy',
  enableDefaultDate = true,
  popUpPosition = 'top-end',
}) => {
  const { isRtlLanguage } = useTranslation();
  const getDateObjectfromDateString = () => {
    if (!!value) {
      return new Date(value);
    }
    return null;
  };

  const [startDate, setStartDate] = React.useState(
    enableDefaultDate ? new Date() : getDateObjectfromDateString()
  );
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const customHeader = (date: any, changeYear: any, changeMonth: any) => {
    return (
      <div className='flex justify-center gap-3 m-2.5'>
        <select
          value={monthList[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(monthList.indexOf(value))
          }
          className={inputClassName}
        >
          {monthList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
          className={`${inputClassName} `}
        >
          {yearList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <>
      {!!title && (
        <p className='block text-sm font-medium text-gray-600'>{title}</p>
      )}
      <div className='relative'>
        <DatePicker
          locale={isRtlLanguage ? ar : enUS}
          tabIndex={-1}
          disabled={disable}
          selected={startDate}
          showPopperArrow={false}
          placeholderText={placeholder}
          showTimeSelect={showTimeSelect}
          showTimeInput={showTimeInput}
          showTimeSelectOnly={showTimeSelectOnly}
          shouldCloseOnSelect={shouldCloseOnSelect}
          timeIntervals={10}
          popperPlacement={popUpPosition}
          calendarClassName={calendarClassName}
          popperClassName='bg-white p-0 mb-[2px] z-60'
          dateFormat={`${
            showTimeInput || showTimeSelect
              ? 'MMMM d, yyyy h:mm aa'
              : dateTimeFormat
          }`}
          onChange={(value: any) => {
            setStartDate(value);
            handleOnchange(!!value ? value.toString() : '');
          }}
          renderCustomHeader={({ date, changeYear, changeMonth }) =>
            customHeader(date, changeYear, changeMonth)
          }
          className={`w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm ${
            disable ? 'bg-gray-300 cursor-not-allowed' : ''
          } ${!!className ? className : ''}`}
          onCalendarOpen={() => setIsCalendarOpen(true)}
          onCalendarClose={() => setIsCalendarOpen(false)}
        />
        <div className='absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer'>
          {!isCalendarOpen ? (
            <ChevronUpDownIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          ) : (
            <XCircleIcon className='w-5 h-5 text-gray-500' aria-hidden='true' />
          )}
        </div>
      </div>
    </>
  );
};

export default DateTimeSelect;
