import React, { useState } from 'react';
import EmptypageIcon from '../../../../assets/icons/emptyPageIcon.svg';
import SavedReplySheet from './SavedReplySheet';
import { Button } from 'libraryV2/ui/button';
import { PlusIcon } from 'lucide-react';
interface EmptyPageProps {}

const EmptySavedRepliesPage: React.FC<EmptyPageProps> = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <img src={EmptypageIcon} alt='EMPTY_PAGE' />
      <h3 className='mt-[18px]'>No Saved Replies Found</h3>
      <span className='subtitle-medium mt-1 mb-4 text-center mx-auto'>
        Create a saved reply to make your interactions more efficient.
      </span>
      <Button
        className='text-white hover:text-white bg-primary gap-1'
        onClick={() => setOpen(true)}
      >
        <PlusIcon strokeWidth='2.5' className='text-white h-4 w-4' />
        <span>Create New Saved Reply</span>
      </Button>
      <SavedReplySheet isOpen={open} setIsOpen={setOpen} actionType='CREATE' />
    </div>
  );
};

export default EmptySavedRepliesPage;
