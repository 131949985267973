import React from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utilities/utils';
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import RandomlyGeneratedAvatar from 'assets/icons/component/RandomlyGeneratedAvatar';
import useTranslation from 'components/customHooks/useTranslation';

interface UserListBoxProps {
  users: GroupMembers[];
  onUserAdd: (user: GroupMembers) => void;
}

const UserListBox: React.FC<UserListBoxProps> = ({ users, onUserAdd }) => {
  const { t } = useTranslation();
  return (
    <Listbox value={null} onChange={onUserAdd}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700'>
            {t('Add Member')}
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full py-2 ltr:pl-3 rtl:pl-10 ltr:pr-10 rtl:pr-3 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm'>
              <span className='flex items-center'>
                <MagnifyingGlassIcon className='w-5 h-5 text-gray-500' />
                <span className='block ltr:ml-3 rtl:mr-3 text-gray-500 truncate'>
                  {t('Add a member')}
                </span>
              </span>
              <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronUpDownIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {users.map((user) => (
                  <Listbox.Option
                    key={user.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={user}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          {user.avatar && (
                            <img
                              src={user.avatar}
                              alt=''
                              className='flex-shrink-0 w-6 h-6 rounded-full'
                            />
                          )}
                          {!user.avatar && (
                            <RandomlyGeneratedAvatar
                              fill='#01906D'
                              className='w-6 h-6'
                            />
                          )}
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ltr:ml-3 rtl:mr-3 block truncate'
                            )}
                          >
                            {user.full_name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='w-5 h-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
                {users.length === 0 && (
                  <Listbox.Option
                    disabled
                    key={-1}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={null}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                          {t('No User Available')}
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default UserListBox;
