import React from 'react';
import Footer from '../../common/components/Footer';

interface Props {}

const Introduction: React.FC<Props> = () => {
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          Integrating BetterDocs with Alice will allow you to:
        </p>
        <ul className='mt-3 ml-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            Connect your knowledge base or documentation with MyAlice.
          </li>
          <li className='my-2'>
            Enable your users to search for articles or help guide directly from
            live chat.
          </li>
        </ul>
      </div>
      <Footer channelName='BetterDocs' />
    </div>
  );
};
export default Introduction;
