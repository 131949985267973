import {
  CheckCircleIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { channelListProps } from 'pages/integration/interface';
import { FC, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { copyClipboard } from 'utilities/utils';

interface SallaIntegrationStep2Props {
  platformData: channelListProps;
  selectedProject: SelectedTeamInterface;
}

interface SallaIntegrationInfoLabelProps {
  label: string;
  value: string;
}
export const SallaIntegrationInfoLabel: FC<SallaIntegrationInfoLabelProps> = ({
  label,
  value,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const handleCopyLink = () => {
    copyClipboard(value);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3500);
  };
  const renderCopyLinkIcon = () => {
    return (
      <div
        className='cursor-pointer'
        onClick={() => handleCopyLink()}
        data-for={'copy-link' + label}
        data-tip
      >
        {!isCopied && (
          <ClipboardDocumentIcon className='text-gray-500' height={20} />
        )}
        {isCopied && (
          <CheckCircleIcon className='text-green-500 stroke-2' height={20} />
        )}
        {/* @ts-ignore */}
        <ReactTooltip
          id={'copy-link' + label}
          type='dark'
          effect='solid'
          backgroundColor='#4B5563'
          multiline={false}
          className='w-auto break-words whitespace-pre-wrap sm:text-sm'
        >
          {isCopied ? t('Copied!') : t('Copy')}
        </ReactTooltip>
      </div>
    );
  };

  return (
    <div className='w-full flex flex-col gap-1'>
      <p className='text-sm flex font-medium text-gray-600 leading-5'>
        {t(label)}
        <span className='text-[#f36363]'>*</span>
      </p>
      <div className='flex items-center border-gray-300 border rounded-md'>
        <input
          disabled
          className='bg-gray-100 flex-grow cursor-disabled border-0 ltr:border-r rtl:border-l border-gray-300 py-2 ltr:pl-3 rtl:pr-3 ltr:rounded-l-md rtl:rounded-r-md text-sm text-gray-500'
          type='text'
          value={value}
        />
        <div className='px-3'>{renderCopyLinkIcon()}</div>
      </div>
    </div>
  );
};

const SallaIntegrationStep2: FC<SallaIntegrationStep2Props> = ({
  platformData,
  selectedProject,
}) => {
  const apiKey = selectedProject?.api_key ?? '';
  const platformId = platformData?.id ?? '';
  const token = platformData?.primary_token ?? '';
  const primaryId = platformData?.primary_id ?? '';
  return (
    <div className='flex h-auto w-full flex-col gap-6'>
      <SallaIntegrationInfoLabel label='API Key' value={apiKey} />
      <SallaIntegrationInfoLabel label='Platform ID' value={platformId} />
      <SallaIntegrationInfoLabel label='Token' value={token} />
      <SallaIntegrationInfoLabel label='Primary ID' value={primaryId} />
    </div>
  );
};
export default SallaIntegrationStep2;
