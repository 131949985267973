import React from 'react';
import { Icon } from 'evergreen-ui';

interface Prop {
  children: any;
  header: string;
  isOpen?: boolean;
  intent?: 'active' | 'default';
  hasCollapseBorder?: boolean;
  titleFontSize?: number;
  hasSharpEdge?: boolean;
}

const Collapse: React.FC<Prop> = ({
  children,
  header,
  isOpen = false,
  intent = 'default',
  hasCollapseBorder = false,
  titleFontSize,
  hasSharpEdge = false,
}) => {
  const [isShown, setIsShown] = React.useState(!!isOpen);
  return (
    <div
      className={`collapse-element ${
        !isShown && hasCollapseBorder ? 'border-default-card' : ''
      }`}
    >
      <div
        className={`collapse-element__header ${
          isShown &&
          `collapse-element__header-${intent} ${
            isShown
              ? 'border-default-card-active'
              : hasCollapseBorder
              ? 'border-default-card'
              : ''
          }`
        }`}
        style={!!hasSharpEdge ? { borderRadius: 0 } : {}}
        onClick={() => {
          setIsShown(!isShown);
        }}
      >
        <p
          className='collapse-element__header-title'
          style={!!titleFontSize ? { fontSize: titleFontSize } : {}}
        >
          {header}
        </p>
        <div className='collapse-element__header-arrow'>
          <Icon
            style={{ marginTop: 3 }}
            icon={isShown ? 'minus' : 'plus'}
            size={16}
          />
        </div>
      </div>
      {isShown && <div className='collapse-element__body'>{children}</div>}
    </div>
  );
};

export default Collapse;
