import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import { Switch, Textarea, TextInputField } from 'evergreen-ui';
import ButtonGroup from '../elements/ButtonGroup';
import PropTypes from 'prop-types';
import InputVariableField from '../elements/InputVariableField';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnChangeAttribute,
  updateOnChangeText,
  updateOnSelectAttribute,
} from '../../../utilities/utils';
import qrIcon from '../../pages/builder/assets/images/icons/quickreply.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import TextCharInfo from '../elements/TextCharInfo';

const QuickReplyBlock = ({
  id,
  data,
  save,
  sequences,
  saveBlock,
  saveLoader,
  type,
  updateButtonsBlock,
  updateButtonElements,
  setVariable,
  handleInputUpdate,
  createButtonElement,
  deleteButtonElement,
  attributeList,
  apiList,
  maxButtonCount,
  deleteBlock,
  copyBlock,
  moveBlock,
  handleAPIButtonElementCreate,
  hasWriteAccess,
  hasSaveAttribute,
  platformType,
  subscriptionPlanType,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showTitleAttribute, setShowTitleAttribute] = useState(false);
  const [showAttributeField, setShowAttributeField] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [showTitleAPI, setShowTitleAPI] = useState(false);
  const [maxCount] = useState(620);
  const [titleMaxCount] = useState(20);

  const isWhatsApp = platformType === 'whatsapp_messenger';
  const isWhatsAppBSP = platformType === 'whatsapp_bsp';

  const setText = (val) => handleInputUpdate(val, id, 'text');
  const setTitle = (val) => handleInputUpdate(val, id, 'title');
  const setMaxButton = (channel) => {
    switch (channel) {
      case 'whatsapp_bsp':
        return 10;
      case 'viber':
        return 12;
      default:
        return 13;
    }
  };

  const getAllowedTypes = () => {
    switch (platformType) {
      case 'viber_messenger':
        return ['Sequence', 'Basic', 'URL'];
      case 'whatsapp_messenger':
      case 'whatsapp_bsp':
        return ['Sequence'];
      default:
        return ['Sequence', 'Basic'];
    }
  }

  return (
    <BlockContainer
      id={id}
      title={isWhatsAppBSP ? 'List Reply' : 'Quick Reply'}
      subtitle='Send a list of replies that your customers can choose from.'
      save={save}
      isLoading={saveLoader}
      icon={qrIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='flex flex-col'>
        {(isWhatsApp || isWhatsAppBSP) && (
          <React.Fragment>
            <AttributeAPIContainer
              attributeList={attributeList}
              showAttribute={showTitleAttribute}
              setShowAttribute={setShowTitleAttribute}
              apiList={apiList.filter((api) => api.return_type === 'text')}
              showAPI={showTitleAPI}
              setShowAPI={setShowTitleAPI}
              handleSelect={(value) =>
                (data.title + value).length + 2 <= titleMaxCount &&
                updateOnSelectAttribute(
                  value,
                  showTitleAttribute,
                  showTitleAPI,
                  setShowTitleAttribute,
                  setShowTitleAPI,
                  setTitle,
                  data.title,
                  apiList
                )
              }
            >
              <div className='mt-1'>
                <div className='mb-1'>
                  <TextCharInfo
                    data={String(data.title)}
                    count={titleMaxCount}
                  />
                </div>
                <TextInputField
                  label='List Reply Button Text'
                  required={true}
                  value={data.title}
                  type='text'
                  disabled={!hasWriteAccess}
                  className='block w-full border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                  onChange={(e) =>
                    e.target.value.length <= titleMaxCount &&
                    updateOnChangeText(
                      e.target.value,
                      setShowTitleAttribute,
                      setShowTitleAPI,
                      setTitle
                    )
                  }
                />
              </div>
            </AttributeAPIContainer>
          </React.Fragment>
        )}
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (data.text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              data.text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            rows={3}
            value={data.text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm cursor-text'
            placeholder={'Write your message here'}
          />
        </AttributeAPIContainer>
        <div>
          <TextCharInfo data={data.text} count={maxCount} />
        </div>
        {!!data.api ? (
          <FetchFromAPIButtonSection
            hasWriteAccess={hasWriteAccess}
            blockId={id}
            apiButtonData={data.api}
            handleDelete={handleAPIButtonElementCreate}
          />
        ) : (
          <ButtonGroup
            blockId={id}
            type={type}
            sequences={sequences}
            buttonTitle={'Create a Reply'}
            APIButtonTitle={'Create an API Reply'}
            maxButtonCount={setMaxButton(platformType)}
            buttonList={data.buttons}
            handleDropUpdate={updateButtonElements}
            handleCreate={createButtonElement}
            handleDelete={deleteButtonElement}
            handleUpdate={updateButtonsBlock}
            allowedTypes={getAllowedTypes()}
            hasAPIButton={['enterprise', 'business'].includes(
              subscriptionPlanType
            )}
            apiButtonData={data.api}
            apiList={apiList}
            hasWriteAccess={hasWriteAccess}
            handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          />
        )}
        {hasWriteAccess && hasSaveAttribute && (
          <div className='inline-flex items-center'>
            <Switch
              marginTop={10}
              marginBottom={10}
              height={24}
              checked={data.save}
              onChange={(e) => handleInputUpdate(e.target.checked, id, 'save')}
            />{' '}
            &nbsp;Save this value
          </div>
        )}
        {data.save && (
          <InputVariableField
            value={data.attribute ? data.attribute : ''}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttribute={showAttributeField}
            setShowAttribute={setShowAttributeField}
            handleSelect={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            onChange={(value) =>
              updateOnChangeAttribute(value, setShowAttributeField, setVariable)
            }
            placeHolder={'for example: f.name'}
          />
        )}
      </div>
    </BlockContainer>
  );
};

QuickReplyBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  updateButtonsBlock: PropTypes.func,
  createButtonElement: PropTypes.func,
  deleteButtonElement: PropTypes.func,
  maxButtonCount: PropTypes.number.isRequired,
  setVariable: PropTypes.func,
  handleInputUpdate: PropTypes.func,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
  hasSaveAttribute: PropTypes.bool,
  platformType: PropTypes.string,
};

QuickReplyBlock.defaultProps = {
  maxButtonCount: 11,
  hasWriteAccess: false,
  hasSaveAttribute: true,
};

export default QuickReplyBlock;
