import React, { Fragment } from 'react';
import '../../assets/styles/elements/input-variable.scss';
import { TextInput } from 'evergreen-ui';
import PropTypes from 'prop-types';
import AttributeAPIContainer from './AttributeAPIContainer';
import TitleHolderBuilder from './TitleHolderBuilder';

const InputVariableField = ({
  value,
  onChange,
  showTitle,
  placeHolder,
  attributeList,
  showAttribute,
  setShowAttribute,
  handleSelect,
  hasWriteAccess,
  inputClassName,
}) => {
  return (
    <Fragment>
      {showTitle ? (
        <TitleHolderBuilder
          title='Store the input to an attribute'
          tooltipInfo='Users Reply will be saved in this variable which you can use later on in bot'
        />
      ) : (
        ''
      )}
      <div className='variable__input'>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          handleSelect={handleSelect}
        >
          <TextInput
            name='text-input-name'
            placeholder={placeHolder}
            value={value}
            height={40}
            disabled={!hasWriteAccess}
            className={inputClassName}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </AttributeAPIContainer>
      </div>
    </Fragment>
  );
};

InputVariableField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  inputClassName: PropTypes.string,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  showAttribute: PropTypes.bool,
  setShowAttribute: PropTypes.func,
  handleSelect: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
};
InputVariableField.defaultProps = {
  placeHolder: 'for example: f.name',
  inputClassName: 'custom-input-style',
  hasWriteAccess: false,
  showTitle: true,
};

export default InputVariableField;
