import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import { MoreHorizontal, Settings2, SquarePen } from 'lucide-react';
import { IEcommerceChannelProperty } from 'pages/integration/interface';
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../../../libraryV2/ui/dropdown-menu';

interface Props {
  elementData: IEcommerceChannelProperty;
  onActionClick: (action: string) => void;
}

const EcommerceCardActionDropdown: React.FC<Props> = ({
  elementData,
  onActionClick,
}) => {
  const { t, isRtlLanguage } = useTranslation();

  return (
    <>
      <div className='py-1'>
        <DropdownMenu>
          <DropdownMenuTrigger
            asChild
            className='absolute ltr:right-2 rtl:left-2 top-0'
          >
            <Button variant='ghost' className='h-8 w-8 p-0'>
              <span className='sr-only'>{t('Open menu')}</span>
              <MoreHorizontal className='h-5 w-5' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align={isRtlLanguage ? 'start' : 'end'}
            className='bg-white min-w-[180px] relative -top-2'
          >
            <DropdownMenuItem
              className='flex items-center gap-2 hover:rounded-md rtl:justify-end'
              onClick={() => onActionClick('edit')}
            >
              <SquarePen className='w-4 h-4 rtl:order-1' />
              {t('Edit')}
            </DropdownMenuItem>

            {/* Comment it for now */}
            {/* {elementData?.ecommerce_type === 'salla' && (
              <DropdownMenuItem
                className='flex items-center gap-2 hover:rounded-md rtl:justify-end'
                onClick={() => onActionClick('credentials')}
              >
                <Info className='w-4 h-4 rtl:order-1' />
                {t('Credentials')}
              </DropdownMenuItem>
            )} */}
            <DropdownMenuItem
              className='flex items-center gap-2 hover:rounded-md rtl:justify-end'
              onClick={() => onActionClick('preferences')}
            >
              <Settings2 className='w-4 h-4 rtl:order-1' />
              {t('Channel Preferences')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};
export default EcommerceCardActionDropdown;
