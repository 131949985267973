import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover, Pane, Position } from 'evergreen-ui';
import ButtonPopup from './ButtonPopup';
import EcommerceActionButtonPopup from './EcommerceActionButtonPopup';

const ButtonFragment = ({
  id,
  index,
  sequences,
  blockType,
  inputValue,
  formsData,
  title,
  description,
  hasDelete,
  value,
  category,
  formSequence,
  hasMessengerExtension,
  messengerExtensions,
  singleElem,
  handleDelete,
  handleUpdate,
  hasWebViewHeight,
  webViewHeight,
  type,
  allowedTypes,
  hasWriteAccess,
  showIndex,
}) => {
  const [popOverShown, setPopOverShown] = useState(false);
  const closePopover = () => {
    setPopOverShown(false);
  };
  const getSubtitle = (val, type) => {
    if (type === 'basic') return 'Basic';
    switch (typeof val) {
      case 'number':
        if (type === 'form') {
          const form = formsData.filter((f) => f.id === value)[0];
          return form ? form.title : 'Click to Update';
        }
        const seq = sequences.filter((s) => s.id === value)[0];
        return seq ? seq.title : 'Click to Update';
      case 'string':
        return val === '' ? 'Click to Update' : val;
      default:
        return val;
    }
  };

  const subtitle = getSubtitle(value, type);
  return (
    <div className='border-gray-200 button-group__child'>
      <Popover
        isShown={hasWriteAccess && popOverShown}
        content={
          <Pane
            width={320}
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
          >
            {category === 'ecommerce' ? (
              <EcommerceActionButtonPopup
                id={id}
                index={index}
                inputValue={inputValue}
                category={category}
                blockType={blockType}
                hasMessengerExtension={hasMessengerExtension}
                hasWebViewHeight={hasWebViewHeight}
                webViewHeight={webViewHeight}
                messengerExtensions={messengerExtensions}
                title={title}
                sequences={sequences}
                selectedType={type}
                value={value}
                closePopover={closePopover}
                handleChange={handleUpdate}
              />
            ) : (
              <ButtonPopup
                id={id}
                index={index}
                blockType={blockType}
                inputValue={inputValue}
                hasMessengerExtension={hasMessengerExtension}
                messengerExtensions={messengerExtensions}
                hasWebViewHeight={hasWebViewHeight}
                webViewHeight={webViewHeight}
                closePopover={closePopover}
                title={title}
                description={description}
                sequences={sequences}
                formsData={formsData}
                allowedTypes={allowedTypes}
                selectedType={type}
                value={value}
                formSequence={formSequence}
                handleChange={handleUpdate}
              />
            )}
          </Pane>
        }
        position={Position.TOP_LEFT}
      >
        <Pane>
          <div onClick={() => setPopOverShown(true)}>
            <div className={'button-group__child-title'}>
              {showIndex && <>{id}.&nbsp;</>}
              {!!title
                ? title.length > 100
                  ? title.substring(0, 100) + '...'
                  : title
                : 'Provide Title'}
            </div>
            <div className={'button-group__child-subtitle'}>
              {subtitle.length > 80
                ? subtitle.substring(0, 80) + '...'
                : subtitle}
            </div>
          </div>
        </Pane>
      </Popover>
      {!!hasDelete && hasWriteAccess && !singleElem && (
        <div className={'button-group__child__absolute'}>
          <Icon onClick={() => handleDelete(index)} icon='trash' size={12} />
        </div>
      )}
    </div>
  );
};

ButtonFragment.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number.isRequired,
  sequences: PropTypes.array.isRequired,
  formsData: PropTypes.array,
  blockType: PropTypes.string,
  inputValue: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formSequence: PropTypes.number,
  type: PropTypes.string.isRequired,
  hasMessengerExtension: PropTypes.bool.isRequired,
  messengerExtensions: PropTypes.bool,
  singleElem: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  allowedTypes: PropTypes.array.isRequired,
  hasWebViewHeight: PropTypes.bool,
  webViewHeight: PropTypes.string,
  hasDelete: PropTypes.bool,
  hasWriteAccess: PropTypes.bool,
  showIndex: PropTypes.bool,
};
ButtonFragment.defaultProps = {
  singleElem: false,
  hasWebViewHeight: false,
  hasWriteAccess: false,
  hasDelete: true,
};

export default ButtonFragment;
