import { useSelector, useDispatch } from 'react-redux';
import { usePolicy } from '../export';
import { IPolicy, ISLAData, IEscalation, IReminder } from '../interface';
const useSLAConfig = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(
    (state: any) => state.loading.effects.settingsSLAConfiguration
  );
  const dashboardData = useSelector((state: any) => state.dashboard);
  const slaStateData = useSelector(
    (state: any) => state.settingsSLAConfiguration
  );
  const { defaultPolicies } = usePolicy();

  const { updateSLAPolicy } = loadingState;
  const { slaData, localSLAData } = slaStateData;
  const { selectedProject } = dashboardData;

  const clearSLAData = () => {
    dispatch.settingsSLAConfiguration.clearState();
  };

  const hasSLADataChanged = (): boolean => {
    for (const key in slaData) {
      if (slaData.hasOwnProperty(key)) {
        if (
          JSON.stringify(localSLAData[key]) !== JSON.stringify(slaData[key])
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const hasEmptyEscalationValues = () => {
    if (localSLAData.escalations.length === 0) return false;
    return localSLAData?.escalations.some(
      (escalation: IEscalation) =>
        escalation.priority.length === 0 ||
        escalation.target === '' ||
        escalation.time === null ||
        escalation.time === undefined ||
        escalation.persons.length === 0
    );
  };

  const hasZeroResponseTimeInPolicy = () => {
    if (localSLAData?.policies.length === 0) return false;
    return localSLAData?.policies.some(
      (policy: IPolicy) =>
        policy.first_response_time === 0 || policy.resolution_time === 0
    );
  };

  const hasEmptyReminderValues = () => {
    if (localSLAData.reminders.length === 0) return false;
    return localSLAData?.reminders.some(
      (reminder: IReminder) =>
        reminder.priority.length === 0 ||
        reminder.target === '' ||
        reminder.time === null ||
        reminder.time === undefined ||
        reminder.persons.length === 0
    );
  };

  const handleSLAUpdateSaveButton = async (): Promise<{ status: boolean }> => {
    const requestBody: ISLAData = localSLAData;

    const response: { status: boolean } =
      await dispatch.settingsSLAConfiguration.updateSLAPolicy({
        teamId: selectedProject?.id,
        requestBody: requestBody,
      });

    return response;
  };

  const handlePolicyDataOnChange = (
    priority: number,
    key: keyof IPolicy,
    value: number | boolean
  ) => {
    const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

    const updatePolicy = (
      policyList: IPolicy[],
      priority: number,
      key: keyof IPolicy,
      value: number | boolean
    ) => {
      const updatedPolicyList = deepCopy(policyList); // Create a deep copy
      const index = updatedPolicyList.findIndex(
        (policy: IPolicy) => policy.priority === priority
      );

      if (index >= 0) {
        updatedPolicyList[index] = {
          ...updatedPolicyList[index],
          [key]: value,
        };
      } else {
        updatedPolicyList.push({
          priority,
          first_response_time: 0,
          resolution_time: 0,
          should_consider_business_hour: false,
          [key]: value,
        });
      }

      try {
        dispatch.settingsSLAConfiguration.updateLocalSLAData({
          ...deepCopy(localSLAData),
          policies: updatedPolicyList,
        });
      } catch (error) {
        console.error('Error updating localSLAData:', error);
      }
    };

    if (localSLAData?.policies && localSLAData.policies.length === 0) {
      updatePolicy(defaultPolicies, priority, key, value);
    } else {
      updatePolicy([...localSLAData?.policies], priority, key, value);
    }
  };

  const handleEscalationDataOnChange = (
    index: number,
    key: keyof IEscalation,
    value: number | string | Array<number | string>
  ) => {
    let copiedEscalationData = [...localSLAData?.escalations];
    const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

    const updatedEscalationList = deepCopy(copiedEscalationData); // Create a deep copy
    if (index >= 0) {
      updatedEscalationList[index] = {
        ...updatedEscalationList[index],
        [key]: value,
      };
    }

    try {
      dispatch.settingsSLAConfiguration.updateLocalSLAData({
        ...deepCopy(localSLAData),
        escalations: updatedEscalationList,
      });
    } catch (error) {
      console.error('Error updating localSLAData:', error);
    }
  };

  const handleRemindersDataOnChange = (
    index: number,
    key: keyof IEscalation,
    value: number | string | Array<number | string>
  ) => {
    let copiedReminderData = [...localSLAData?.reminders];
    const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

    const updatedReminderList = deepCopy(copiedReminderData); // Create a deep copy
    if (index >= 0) {
      updatedReminderList[index] = {
        ...updatedReminderList[index],
        [key]: value,
      };
    }

    try {
      dispatch.settingsSLAConfiguration.updateLocalSLAData({
        ...deepCopy(localSLAData),
        reminders: updatedReminderList,
      });
    } catch (error) {
      console.error('Error updating localSLAData:', error);
    }
  };

  return {
    slaData,
    localSLAData,
    clearSLAData,
    hasSLADataChanged,
    hasEmptyReminderValues,
    hasEmptyEscalationValues,
    handlePolicyDataOnChange,
    handleSLAUpdateSaveButton,
    hasZeroResponseTimeInPolicy,
    handleEscalationDataOnChange,
    handleRemindersDataOnChange,
    isSLAUpdateLoading: updateSLAPolicy,
  };
};

export default useSLAConfig;
