import { Switch } from '@headlessui/react';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from 'utilities/utils';

interface SwitchWrapperProps {
  checked: boolean;
  disabled: boolean;
  hasBellIcon?: boolean;
  onChange: (value: boolean) => void;
}

const SwitchWrapper: React.FC<SwitchWrapperProps> = ({
  checked,
  disabled,
  onChange,
  hasBellIcon = true,
}) => {
  return (
    <Switch
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className={classNames(
        checked ? 'bg-primary' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
      )}
    >
      <span className='sr-only'>Notification setting</span>
      <span
        className={classNames(
          checked
            ? 'translate-x-5 rtl:-left-1/2'
            : 'translate-x-0 rtl:right-1/2',
          'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      >
        <span
          className={classNames(
            checked
              ? 'opacity-0 ease-out duration-100'
              : 'opacity-100 ease-in duration-200',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
          )}
          aria-hidden='true'
        >
          <XMarkIcon className='w-3 h-3 text-gray-400' />
        </span>
        <span
          className={classNames(
            checked
              ? 'opacity-100 ease-in duration-200'
              : 'opacity-0 ease-out duration-100',
            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
          )}
          aria-hidden='true'
        >
          {hasBellIcon && <BellIcon className='w-3 h-3 text-primary' />}
        </span>
      </span>
    </Switch>
  );
};

export default SwitchWrapper;
