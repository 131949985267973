import React from 'react';
import CreateOrderSelectedLists from '../components/CreateOrderSelectedLists';
import {
  CreditCardIcon,
  MapPinIcon,
  EnvelopeIcon,
  PhoneIcon,
  UserIcon,
  CubeIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';
import { connect } from 'react-redux';
import {
  CustomerDataProps,
  PaymentMethodProps,
  ShippingMethodProps
} from '../interfaces';

interface Prop {
  paymentMethod: PaymentMethodProps;
  shippingMethod: ShippingMethodProps;
  shippingCost: string;
  customerData: CustomerDataProps;
  currencySymbol: string;
  selectedCouponCode: string;
}

const CreateOrderSummary: React.FC<Prop> = ({
  shippingCost,
  paymentMethod,
  shippingMethod,
  customerData,
  currencySymbol,
  selectedCouponCode,
}) => {
  const renderAddressWithComma = (key: any) => {
    if (key) {
      return key + ',';
    }
    return '';
  };

  const getTotalCost = () => {
    let total: number = 0;
    customerData?.alice_cart.forEach(
      (product) => (total = total + product.unit_price * product.quantity)
    );
    if (!!shippingCost) {
      total = total + parseFloat(shippingCost);
    }
    return total.toFixed(2);
  };

  return (
    <>
      <div className='p-6'>
        <p className='font-sans text-sm font-medium leading-5'>
          Product Selected
        </p>
        <CreateOrderSelectedLists
          productData={customerData?.alice_cart || []}
          currencySymbol={currencySymbol}
        />
      </div>
      <div className='p-6 mt-4'>
        <div>
          <p className='text-sm font-medium leading-6 text-gray-900'>
            Applicant Information
          </p>
        </div>
        <div className='border-gray-200'>
          {!!customerData && (
            <dl>
              <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Billing Information
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span className='flex items-center'>
                    <UserIcon className='w-4 h-4 mr-2' />
                    {customerData?.billing_address?.first_name +
                      ' ' +
                      customerData?.billing_address?.last_name}
                  </span>
                  {
                    customerData?.billing_address?.email &&
                    <span className='flex items-center'>
                      <EnvelopeIcon className='w-4 h-4 mr-2' />{' '}
                      {renderAddressWithComma(
                        customerData?.billing_address?.email
                      )}
                    </span>
                  }
                  {
                    customerData?.billing_address?.phone &&
                    <span className='flex items-center'>
                      <PhoneIcon className='w-4 h-4 mr-2' />{' '}
                      {renderAddressWithComma(
                        customerData?.billing_address?.phone
                      )}
                    </span>
                  }
                  <div className='flex items-start mt-2'>
                    <div className='flex-none'>
                      <MapPinIcon className='w-4 h-4 mr-2 mt-0.5' />
                    </div>
                    <div className='flex-grow'>
                      {renderAddressWithComma(
                        customerData?.billing_address?.address_one
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.billing_address?.address_two
                      )}
                      {renderAddressWithComma(
                        customerData?.billing_address?.city
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.billing_address?.state
                      )}
                      {renderAddressWithComma(
                        customerData?.billing_address?.postcode
                      )}{' '}
                      {customerData?.billing_address?.country}
                    </div>
                  </div>
                </dd>
              </div>
              <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Shipping Information
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span className='flex items-center'>
                    <UserIcon className='w-4 h-4 mr-2' />
                    {customerData?.shipping_address?.first_name +
                      ' ' +
                      customerData?.shipping_address?.last_name}
                  </span>
                  <div className='flex items-start mt-2'>
                    <div className='flex-none'>
                      <MapPinIcon className='w-4 h-4 mr-2 mt-0.5' />
                    </div>
                    <div className='flex-grow'>
                      {renderAddressWithComma(
                        customerData?.shipping_address?.address_one
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.address_two
                      )}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.city
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.state
                      )}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.postcode
                      )}{' '}
                      {customerData?.shipping_address?.country}
                    </div>
                  </div>
                </dd>
              </div>
              <div className='py-4 border-b sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Payment Method
                </dt>
                <dd className='flex items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <CreditCardIcon className='w-4 h-4 mr-2' />
                  <span>{paymentMethod?.method_title}</span>
                </dd>
                <dt className='text-sm font-medium text-gray-500'>
                  Shipping Method
                </dt>
                <dd className='flex items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <CubeIcon className='w-4 h-4 mr-2' />
                  <span>{shippingMethod?.method_title}</span>
                </dd>
                <dt className='text-sm font-medium text-gray-500'>
                  Discount Code
                </dt>
                <dd className='flex items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <CurrencyDollarIcon className='w-4 h-4 mr-2' />
                  <span>{selectedCouponCode || 'Not Applicable'}</span>
                </dd>
              </div>
              <div className='flex py-4 mt-4 sm:py-5 space-between'>
                <dt className='text-sm font-medium text-gray-700'>
                  Total Bill
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span className='inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800'>
                    {currencySymbol}
                    {getTotalCost() || 0}
                  </span>
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  customerData: state.crmEcommerce.customerData,
  paymentMethod: state.crmEcommerce.paymentMethod,
  shippingMethod: state.crmEcommerce.shippingMethod,
  shippingCost: state.crmEcommerce.shippingCost,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  selectedCouponCode: state.crmEcommerce.selectedCouponCode,
});

const mapDispatch = (dispatch: any) => ({});

const CreateOrderSummaryContainer = connect(
  mapState,
  mapDispatch
)(CreateOrderSummary);
export default CreateOrderSummaryContainer;
