import React from 'react';
import Introduction from './Introduction';
import ViberSetting from '../../common/pages/ViberSetting';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { CustomSwitch as Switch } from '../../common/components/CustomSwitch';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;
  integratedNlpList: { id: string; name: string; provider: string }[];

  //functions
  handleClose: () => void;
  setIsSideSheetOpen: (val: string) => void;
  getIntegratedNlpList: (teamId: number) => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

interface selectedProjectProps {
  id: number;
}

const initialSettingData = {
  name: '',
  title: '',
  primary_token: '',
  is_published: true,
  connected_nlp_integration_id: '',
};

const ViberSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  integratedNlpList,
  setIsSideSheetOpen,
  getIntegratedNlpList,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [viberSettingData, setViberSettingData] =
    React.useState(initialSettingData);

  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'ViberSideSheet/node/viber-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';

  const clearState = () => {
    setViberSettingData(initialSettingData);
    setStep(1);
    setLoading(false);
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2: {
        setLoading(true);
        let payloadData = {
          title: viberSettingData.title,
          primary_token: viberSettingData.primary_token,
          connected_nlp_integration_id:
            viberSettingData.connected_nlp_integration_id,
          is_published: viberSettingData.is_published,
        };
        let res = await createChannelIntegrations(
          'viber_messenger',
          selectedProject.id,
          payloadData
        );
        if (res.status === 200) {
          setLoading(false);
          setIsSideSheetOpen('');
          if (!!callbackUrl) navigate(callbackUrl);
          else navigate(`/integrations/integrated`);
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? t(
                  'Something went wrong while integrating the channel. Try again later.'
                )
              : res.data.error
          );
        }
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
    }
  };

  const checkFinishButton = () => {
    const mandatoryKeys = ['title', 'primary_token'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(viberSettingData && viberSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return checkFinishButton();
      default:
        return false;
    }
  };

  const getIsPublishedSwitch = () => {
    return (
      <div className='flex relative'>
        <label className='font-medium text-gray-700'>
          {t('Publish Channel')}
        </label>
        <Switch
          className={'ltr:ml-3 rtl:mr-3'}
          checked={viberSettingData?.is_published}
          onChange={() => {
            setViberSettingData({
              ...viberSettingData,
              is_published: !viberSettingData?.is_published,
            });
          }}
        />
      </div>
    );
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: t(`Connect Viber Messenger with {{${partnerName}}}`),
        };
      case 2:
        return {
          title: t('Viber Settings'),
        };
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getIntegratedNlpList(selectedProject.id);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
          clearState();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={getHeaderInfo(step).title}
        confirmText={step === 1 ? t('Next') : t('Finish')}
        cancelText={t('Back')}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hasLeftActionElement={true}
        leftActionElement={getIsPublishedSwitch()}
        hideLeftActionElement={step === 1}
        hideCancel={step === 1}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>{t('creating channel...')}</span>
              </div>
            ) : (
              <ViberSetting
                success={success}
                errorMessage={errorMessage}
                viberSettingData={viberSettingData}
                integratedNlpList={integratedNlpList}
                handleChange={(key: string, value: string) => {
                  setViberSettingData({ ...viberSettingData, [key]: value });
                }}
              />
            )}
          </>
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
});

export default connect(mapState, mapDispatch)(ViberSideSheet);
