import React from 'react';
import { ExclamationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Button } from 'library';

import ReopenRadioAgents from './Fragments/ReopenRadioAgents';
import ReopenRadioGroups from './Fragments/ReopenRadioGroups';
import { classNames } from 'utilities/utils';

interface ReopenSideSheetProps {
  agents: AgentInterface[];
  groups: GroupInterface[];
  authId: number;
  reOpenTicket: (
    note: string,
    agentId: number | null,
    groupId: number | null,
    isReopenAnswer: boolean
  ) => void;
}

const EmptyList = ({ title }: { title: string }) => {
  return (
    <div className='flex items-center justify-center h-full text-gray-400'>
      <ExclamationCircleIcon className='w-5 h-5 mr-2 text-yellow-400' /> {title}
    </div>
  );
};

const ReopenSideSheet: React.FC<ReopenSideSheetProps> = ({
  agents,
  groups,
  authId,
  reOpenTicket,
}) => {
  const [state, setState] = React.useState<{
    search: string;
    agent: AgentInterface | null;
    group: GroupInterface | null;
    note: string;
  }>({
    search: '',
    agent: null,
    group: null,
    note: '',
  });

  const handleOnAgentChange = (agent: AgentInterface) => {
    setState((state) => ({ ...state, agent: agent, group: null }));
  };

  const handleOnGroupChange = (group: GroupInterface) => {
    setState((state) => ({ ...state, agent: null, group: group }));
  };

  const handleOnNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setState((state) => ({ ...state, note: e.target.value }));

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState((state) => ({ ...state, search: e.target.value }));

  const handleReopenAnswer = () => {
    if (state.agent) {
      reOpenTicket(state.note, state.agent?.admin?.id || null, null, true);
    } else {
      reOpenTicket(state.note, null, state.group?.id || null, true);
    }
  };

  const handleReopen = () => {
    if (state.agent) {
      reOpenTicket(state.note, state.agent?.admin?.id || null, null, false);
    } else {
      reOpenTicket(state.note, null, state.group?.id || null, false);
    }
  };

  const isSelfAssignReopen = state.agent && state.agent.admin.id === authId;

  return (
    <div className='px-6 pt-6'>
      <p className='text-base font-semibold text-gray-800'>Reopen Ticket</p>
      <p className='mb-4 text-sm text-gray-600'>
        Assign Ticket to a agent or group
      </p>
      {/* search */}
      <div className='relative mt-1 rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          type='text'
          autoFocus
          value={state.search}
          onChange={handleOnSearch}
          className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
          placeholder='Search Agent Or Group to Assign...'
        />
      </div>

      {/* agent list */}
      <p className='mt-4 text-lg font-medium text-gray-800'>Agents</p>
      <div className='mt-2 overflow-x-hidden overflow-y-auto max-h-[13rem]'>
        {agents.filter((e) =>
          e.admin.full_name.toLowerCase().includes(state.search.toLowerCase())
        ).length === 0 && <EmptyList title='No Agents Available' />}
        {agents.filter((e) =>
          e.admin.full_name.toLowerCase().includes(state.search.toLowerCase())
        ).length > 0 && (
          <ReopenRadioAgents
            data={agents.filter((e) =>
              e.admin.full_name
                .toLowerCase()
                .includes(state.search.toLowerCase())
            )}
            selected={state.agent}
            onChange={handleOnAgentChange}
          />
        )}
      </div>

      {/* groups list */}
      <p className='mt-4 text-lg font-medium text-gray-800'>Groups</p>
      <div className='mt-2 overflow-x-hidden overflow-y-auto max-h-[13rem]'>
        {groups.filter((e) =>
          e.name.toLowerCase().includes(state.search.toLowerCase())
        ).length === 0 && <EmptyList title='No Groups Available' />}
        {groups.filter((e) =>
          e.name.toLowerCase().includes(state.search.toLowerCase())
        ).length > 0 && (
          <ReopenRadioGroups
            data={groups.filter((e) =>
              e.name.toLowerCase().includes(state.search.toLowerCase())
            )}
            selected={state.group}
            onChange={handleOnGroupChange}
          />
        )}
      </div>

      <div className='sticky bottom-0 bg-white'>
        <p className='pt-4 text-sm font-medium text-gray-600'>Note</p>
        <div className='relative mt-1 rounded-md shadow-sm'>
          <textarea
            className='block w-full p-2 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
            placeholder='Type note here...'
            value={state.note}
            onChange={handleOnNoteChange}
          />
        </div>
        <div
          className={classNames(
            'flex py-4 w-full',
            isSelfAssignReopen ? 'justify-between' : 'justify-end'
          )}
        >
          {isSelfAssignReopen && (
            <Button
              isDisabled={!(state.agent || state.group)}
              onClick={handleReopenAnswer}
            >
              Reopen &amp; Answer
            </Button>
          )}
          <Button
            isDisabled={!(state.agent || state.group)}
            onClick={handleReopen}
            intent='primary'
          >
            Reopen
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReopenSideSheet;
