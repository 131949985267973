import { Redirect, navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import { Component } from 'react';
import { connect } from 'react-redux';
import { authData } from '../../utilities/content';
import AuthHeader from './components/AuthHeader';
import ForgetPasswordVerify from './components/ForgetPasswordVerify';

class VerifyPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: '',
      password: '',
      confirmPassword: '',
      loading: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResetRequestSubmit = () => {
    this.setState({ loading: true });
    this.props
      .resetRequest({
        email: this.props.auth.email,
      })
      .then((success) => {
        if (success) {
          toaster.success('Please check your email for further instruction!');
          this.setState({ email: '', loading: false });
          navigate(`/reset-verify`);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  handleResetVerifySubmit = () => {
    if (this.state.password === this.state.confirmPassword) {
      this.setState({ loading: true });
      this.props
        .resetVerify({
          code: this.state.verifyCode,
          password: this.state.password,
        })
        .then((success) => {
          if (success) {
            toaster.success('Password reset! You can log in now.');
            this.setState({
              verifyCode: '',
              password: '',
              confirmPassword: '',
              loading: false,
            });
            navigate(`/reset-success`);
          } else {
            this.setState({ loading: false });
          }
        });
    } else {
      toaster.danger('Password do not match!');
    }
  };

  componentDidMount() {
    const [, query] = this.props.location.search.split('?code=');
    if (query) {
      this.setState({ verifyCode: query });
    }
  }

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='flex flex-col justify-center min-h-screen py-8 bg-white sm:px-6 lg:px-8'>
        <AuthHeader title={authData.verify.title} />
        <ForgetPasswordVerify
          handleChange={this.handleChange}
          verifyCode={this.state.verifyCode}
          password={this.state.password}
          confirmPassword={this.state.confirmPassword}
          loading={this.state.loading}
          handleResendMail={() => this.handleResetRequestSubmit()}
          handleSubmit={() => this.handleResetVerifySubmit()}
          content={authData}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  resetVerify: (payload) => dispatch.auth.resetVerify(payload),
  resetRequest: (payload) => dispatch.auth.resetRequest(payload),
});

export default connect(mapState, mapDispatch)(VerifyPassword);
