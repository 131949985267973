import React, { Component } from 'react';
import { Card, Button, toaster } from 'evergreen-ui';
import './assets/styles/style.scss';
import APIResource from './components/APIResource';
import APIKeyValuePair from './components/APIKeyValuePair';
// import APIBody from './components/APIBody';
import APICard from '../builder/components/SequenceCard';
import APIMeta from '../builder/components/SequenceMeta';
import { connect } from 'react-redux';
import NoDataAvailable from '../builder/components/NoDataAvailable';
import APIReturnType from './components/APIReturnType';

import { HasPermission, ProUser } from '../../../components';
import { checkPermission } from '../../../utilities/utils';
import APIFuseSearchComponent from './components/APIFuseSearchComponent.tsx';
import ModifyAPI from './components/ModifyAPI';

class APIFuse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      readAccess: 'read:custom_api',
      writeAccess: 'write:custom_api',
      sequenceAcitivityLoader: false,
      sequenceAcitivityModal: false,
      editId: 0,
      editTitle: '',
      title: '',
    };
  }
  async componentDidMount() {
    if (this.props.projects.length > 0) {
      const project = this.props.projects.filter(
        (p) => parseInt(this.props.projectId) === p.id
      )[0];
      await this.props.setSelectedProject(project);
    }

    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (hasPermission) {
      //this.props.fetchAttributes(this.props.selectedPlatform.id);
      await this.props.fetchAPI(this.props.selectedProject.id);
    }
  }

  handleCreateAPIModal = () => {
    this.setState({
      sequenceAcitivityModal: true,
      editId: 0,
    });
  };

  handleApiDelete = (id) => {
    this.props.deleteAPI(this.props.selectedProject.id, id);
    toaster.success('Deleted Successful', {
      duration: 1,
    });
  };

  handleActiveBlock = (id) => {
    this.props.setSelectedAPI(id);
  };

  handleApiNameSubmit = async (id) => {
    const res = await this.props.editAPI(this.props.selectedProject.id, id);
    toaster.success('Rename Successful', {
      duration: 1,
    });
    return res;
  };

  render() {
    const { selectedApi } = this.props;
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    return (
      <HasPermission shortCode={this.state.readAccess}>
        <ProUser handleCancel={() => window.history.back()}>
          <main className='apifuse-main'>
            <ModifyAPI
              isShown={this.state.sequenceAcitivityModal}
              isLoading={this.state.sequenceAcitivityLoader}
              updateState={(state, value) => this.setState({ [state]: value })}
              status={this.state.editId > 0 ? 'edit' : 'create'}
              handleAPIAdd={async (title) =>
                await this.props.createAPI(this.props.selectedProject.id, title)
              }
              editId={this.state.editId}
              handleRename={async (editId, title) => {
                this.props.updateSelectedAPIkey('title', title);
                return await this.props.saveAPI();
              }}
              editTitle={this.state.editTitle}
            />
            <div className='apifuse-main__wrapper'>
              <div className='apifuse-main__sequence'>
                <APIMeta
                  title='Your APIs'
                  subtitle='Your bot consists of sequence. Sequences are like individual pages on a website.'
                />
                {this.props.apis.map(
                  (api, i) =>
                    api.is_default && (
                      <APICard
                        key={i}
                        id={api.id}
                        activeSequenceId={this.props.selectedApi}
                        handleActiveBlock={this.handleActiveBlock}
                        handleEditTitle={(value) =>
                          this.setState({ editTitle: value })
                        }
                        handleEditId={(value) =>
                          this.setState({ editId: value })
                        }
                        handleModal={(value) =>
                          this.setState({ sequenceAcitivityModal: value })
                        }
                        handleDelete={this.handleApiDelete}
                        title={api.title}
                        isEditable={hasWriteAccess}
                        isMovable={false}
                      />
                    )
                )}
                {hasWriteAccess && (
                  <Button
                    className='btn-full btn-light'
                    iconBefore='add'
                    height={40}
                    onClick={this.handleCreateAPIModal}
                  >
                    Add New API
                  </Button>
                )}
                <APIFuseSearchComponent
                  handleFilter={(evt) =>
                    this.setState({ search: evt.target.value })
                  }
                  value={this.state.search}
                />
                {!!this.props.apis &&
                  this.props.apis
                    .filter((elem) => elem.title.includes(this.state.search))
                    .map(
                      (block, i) =>
                        !block.is_default && (
                          <APICard
                            key={i}
                            id={block.id}
                            isMovable={false}
                            title={block.title}
                            activeSequenceId={
                              this.props.selectedApi
                                ? this.props.selectedApi.id
                                : 0
                            }
                            isEditable={
                              hasWriteAccess ? !block.is_default : false
                            }
                            handleDelete={this.handleApiDelete}
                            handleSequenceNameSubmit={
                              this.handleSequenceNameSubmit
                            }
                            handleActiveBlock={this.handleActiveBlock}
                            handleEditId={(value) =>
                              this.setState({ editId: value })
                            }
                            handleEditTitle={(value) =>
                              this.setState({ editTitle: value })
                            }
                            handleModal={(value) =>
                              this.setState({ sequenceAcitivityModal: value })
                            }
                          />
                        )
                    )}
              </div>

              {this.props.apis.length > 0 && (
                <div className='apifuse-main__content'>
                  {!!selectedApi && (
                    <Card>
                      <APIResource
                        title={'API Information'}
                        subtitle={
                          'Provide Title, Method Types and Url for the api.'
                        }
                        apiTitle={selectedApi.title}
                        handleChangeApiData={this.props.updateSelectedAPIkey}
                        httpMethod={selectedApi.http_method}
                        url={selectedApi.url}
                        hasWriteAccess={hasWriteAccess}
                      />
                      <APIKeyValuePair
                        title={'Header'}
                        subtitle={
                          'Provide Header Information as key value pair below.'
                        }
                        data={selectedApi.headers}
                        attributeList={this.props.attributes}
                        hasWriteAccess={hasWriteAccess}
                        handleCreatePair={() =>
                          this.props.createSelectedAPIKeyValuePair('headers')
                        }
                        handleDeleteKeyValuePair={(index) =>
                          this.props.deleteSelectedAPIKeyValuePair(
                            'headers',
                            index
                          )
                        }
                        handleChangeKeyValuePair={(subtype, value, index) =>
                          this.props.updateSelectedAPIKeyValuePair(
                            'headers',
                            subtype,
                            value,
                            index
                          )
                        }
                      />
                      <APIKeyValuePair
                        title={'Body'}
                        subtitle={
                          'Provide Body Information as key value pair below.'
                        }
                        data={selectedApi.query_params}
                        attributeList={this.props.attributes}
                        hasWriteAccess={hasWriteAccess}
                        handleCreatePair={() =>
                          this.props.createSelectedAPIKeyValuePair(
                            'query_params'
                          )
                        }
                        handleDeleteKeyValuePair={(index) =>
                          this.props.deleteSelectedAPIKeyValuePair(
                            'query_params',
                            index
                          )
                        }
                        handleChangeKeyValuePair={(subtype, value, index) =>
                          this.props.updateSelectedAPIKeyValuePair(
                            'query_params',
                            subtype,
                            value,
                            index
                          )
                        }
                      />
                      {/*{(selectedApi.http_method === 'POST') && (*/}
                      {/*<APIBody*/}
                      {/*title={'Body'}*/}
                      {/*subtitle={'Body is only available in POST method'}*/}
                      {/*data={selectedApi.json_body}*/}
                      {/*handleChangeApiData={this.props.updateSelectedAPIkey}*/}
                      {/*hasWriteAccess={hasWriteAccess}*/}
                      {/*/>*/}
                      {/*)}*/}
                      <APIReturnType
                        title={'Used For'}
                        subtitle={'Which block this API will be use in'}
                        data={selectedApi.return_type}
                        updateSelectedAPIkey={this.props.updateSelectedAPIkey}
                        hasWriteAccess={hasWriteAccess}
                      />
                      {hasWriteAccess && !selectedApi.saveStatus && (
                        <div className='api-fuse__save-container'>
                          <Button
                            onClick={() => this.props.saveAPI()}
                            iconBefore='endorsed'
                            appearance='primary'
                            intent='success'
                          >
                            Save
                          </Button>
                          &nbsp; &nbsp;
                          <Button
                            onClick={() =>
                              this.props.fetchAPI(this.props.selectedProject.id)
                            }
                            iconBefore='cross'
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </Card>
                  )}
                </div>
              )}
              {this.props.apis.length === 0 && (
                <div className='apifuse-main__content'>
                  <NoDataAvailable
                    message={
                      'There is No API Available, Please Click "Add New API"'
                    }
                    height={'60vh'}
                    width={'50%'}
                  />
                </div>
              )}
            </div>
          </main>
        </ProUser>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  apis: state.api.apis,
  selectedApi: state.api.selectedApi,
  loaders: state.loading,
  attributes: state.builder.attributes,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchAPI: (projectId) => dispatch.api.fetchAPI(projectId),
  createAPI: (projectId, title) => dispatch.api.createAPI({ projectId, title }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  editAPI: (projectId, apiId) => dispatch.api.editAPI({ projectId, apiId }),
  deleteAPI: (projectId, apiId) => dispatch.api.deleteAPI({ projectId, apiId }),
  setSelectedAPI: (apiId) => dispatch.api.setSelectedAPI(apiId),
  updateSelectedAPIkey: (changeKey, value) =>
    dispatch.api.updateSelectedAPIkey({ changeKey, value }),
  updateSelectedAPIKeyValuePair: (type, subtype, value, index) =>
    dispatch.api.updateSelectedAPIKeyValuePair({ type, subtype, value, index }),
  deleteSelectedAPIKeyValuePair: (type, index) =>
    dispatch.api.deleteSelectedAPIKeyValuePair({ type, index }),
  createSelectedAPIKeyValuePair: (type) =>
    dispatch.api.createSelectedAPIKeyValuePair({ type }),
  saveAPI: () => dispatch.api.saveAPI({}),
  fetchAttributes: (platformId) => dispatch.builder.fetchAttributes(platformId),
});

const APIFuseContainer = connect(mapState, mapDispatch)(APIFuse);

export default APIFuseContainer;
