import React from 'react';
import FacebookLogin from 'react-facebook-login';
import FacebookLoginForBusiness from 'utilities/FacebookLoginForBusiness';
import instagramLogo from '../../../assets/images/instaChat.svg';
import config from '../../../../../utilities/config';
import Footer from './Footer';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { getMetaPermissionScope } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { status as environment } from '../../../../../utilities/config';

interface Props {
  processInstagramResponse: (response: any) => any;
  processInstagramResponseForBusiness: (response: any) => any;
  loading: boolean;
  type: string;
}

const InstagramConnection: React.FC<Props> = ({
  processInstagramResponse,
  processInstagramResponseForBusiness,
  type,
  loading = false,
}) => {
  const appId = config.misc.facebookAppId();
  const { t } = useTranslation();

  const renderInstagramLoginButton = () => {
    if (environment === 'production') {
      return (
        <FacebookLoginForBusiness
          handleFacebookToken={processInstagramResponseForBusiness}
          platformType='instagram'
          buttonText={t('Continue with Instagram')}
          buttonClassName='instagram-button-connect'
        />
      )
    } else {
      return (
        <FacebookLogin
          appId={!!appId ? appId : ''}
          isDisabled={false}
          autoLoad={false}
          fields='email'
          scope={getMetaPermissionScope(type)}
          callback={processInstagramResponse}
          cssClass='instagram-button-connect'
          textButton={t('Connect With Instagram')}
        />
      )
    }
  };

  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>{t('fetching page list...')}</span>
          </div>
        )}
        {!loading && (
          <>
            <img
              src={instagramLogo}
              className='m-auto text-center'
              alt={'instagram logo'}
            />
            <p className='mt-3 text-xl font-medium text-center'>
              {t('Connect With Instagram!')}
            </p>
            <p className='mt-3 text-sm font-normal text-center text-gray-00'>
              {t(
                `Connect your {{Instagram account}}, you can edit the details later. Please provide us the required permission to get started!`
              )}
            </p>
            <br />
            <div className='flex justify-center'>
              {renderInstagramLoginButton()}
            </div>
          </>
        )}
      </div>
      <Footer
        channelName={
          type === 'instagram_messenger'
            ? 'Instagram Messenger'
            : 'Instagram Feed'
        }
      />
    </>
  );
};
export default InstagramConnection;
