import React from 'react';
import Accordion from './Accordion';
import { PlusIcon } from '@heroicons/react/20/solid';

interface Props {
  isWhatsappConnected: boolean;
  isWhatsappTemplatesCreated: boolean;
  isCardAdded: boolean;
}

const Setup: React.FC<Props> = ({
  isWhatsappConnected,
  isWhatsappTemplatesCreated,
  isCardAdded,
}) => {
  return (
    <>
      <div className='w-full h-[90vh] flex items-center justify-center'>
        <div className='bg-white p-8 border border-gray-200 rounded-lg shadow-sm w-[60%] m-auto'>
          {/* header */}
          <div className='w-full'>
            <span className='block leading-5 text-base font-medium text-gray-900'>
              Setup WhatsApp Broadcasting
            </span>
            <span className='block leading-5 text-sm font-normal text-gray-500 mt-1'>
              Complete these three steps to start broadcasting to your
              customers.
            </span>

            <div className='mt-6'>
              <Accordion
                title='Connect your WhatsApp'
                description='Broadcasting is available to WhatsApp Business
                            Accounts only. Please connect your WhatsApp Business
                            to start broadcasting to your customers'
                isCompleted={isWhatsappConnected}
                btnText='Connect WhatsApp Business'
                btnUrl='/integrations/integrated'
              />
              <Accordion
                title='Create Message Templates'
                description='You can broadcast Meta-approved message templates
                            only to your subscribers.'
                isCompleted={isWhatsappTemplatesCreated}
                btnText='Create message template'
                btnUrl='/integrations/integrated'
              />
              <Accordion
                title='Add Payment Information'
                description='Please make sure that you’ve added a valid payment
                            method on MyAlice.'
                isCompleted={isCardAdded}
                btnText='Add payment information'
                btnUrl='/settings/billing'
              />
            </div>

            {/** bottom */}
            <div className='flex mt-10'>
              <a
                href='https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-whatsapp-business/difference-between-whatsapp-business-app-vs-whatsapp-business-api#_4mh8p3tf10tu'
                className='text-sm text-[#0078CF] leading-5 font-medium'
              >
                Read our Guide
              </a>
              <button className='p-3 rounded-lg ml-auto flex items-center justify-center bg-gray-300'>
                <PlusIcon
                  color='#4B5562'
                  width={15}
                  height={15}
                  className='mr-2'
                />
                <span className='text-gray-600 text-sm font-normal leading-5'>
                  Create a broadcast
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setup;
