import { isEmpty } from 'lodash';
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  cn,
  dayjs,
} from '../../../../export';
import { ArrowRight, CircleAlert, CircleCheck } from 'lucide-react';
import {
  IAutomationLogResult,
  ISingleCondtionLogData,
} from 'pages/automationWorkflow/interface';
import { FC } from 'react';

interface ConditionNodeDetailsLogProps {
  logResult: IAutomationLogResult;
  conditionData: any;
}

export const ConditionNodeDetailsLog: FC<ConditionNodeDetailsLogProps> = ({
  logResult,
  conditionData,
}) => {
  const hasLogResult = !isEmpty(logResult);
  const isConditionSuccess = logResult?.current_component_state === 'success';
  const getSlectedValuesTuple = (comparison: any) => {
    return comparison?.col_2
      ?.map((item: { label: string }) => item.label)
      .join(', ');
  };

  const getWebhookValues = (result: IAutomationLogResult) => {
    return `${result?.webhook_data}`;
  };

  const renderConditionLogResultIcon = (
    singleLogData: ISingleCondtionLogData
  ) => {
    const conditionResult: string =
      singleLogData?.component_computation_info?.current_component_state;
    switch (conditionResult) {
      case 'success': {
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#04A056]' />
        );
      }
      case 'failed': {
        return (
          <CircleAlert className='h-4 self-center flex-shrink-0 w-4 text-red-500' />
        );
      }
      case 'pending': {
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#F59E0B]' />
        );
      }
      default:
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#A1A1AA]' />
        );
    }
  };

  const renderUserConditionLog = (conditionData: ISingleCondtionLogData) => {
    if (!conditionData?.component_code) {
      return null;
    }
    const conditionDate =
      conditionData?.component_computation_info?.ends_at ||
      conditionData?.component_computation_info?.starts_at ||
      new Date().toISOString();
    return (
      <div className='flex flex-col border border-zinc-200 p-3 gap-1.5 bg-white rounded-lg'>
        {/* time */}
        <div className='flex w-full justify-between'>
          <span className='text-xs text-zinc-400 italic'>User Value</span>
          <span className='text-xs text-zinc-500'>
            {dayjs(conditionDate).format('MMM D, YYYY [at] hh:mm A')}
          </span>
        </div>
        {/* condition inof */}
        <div className='flex gap-2'>
          <span className='text-zinc-500'>{conditionData.component_name}</span>
          <span className='text-indigo-400 text-sm italic'>
            {conditionData?.col_1?.label}
          </span>
        </div>
        {/* condition value */}
        <div>
          <span className='text-zinc-900 text-sm font-medium'>
            ({getSlectedValuesTuple(conditionData)})
          </span>
        </div>
      </div>
    );
  };

  const renderWebhookConditionLog = (conditionData: ISingleCondtionLogData) => {
    if (!conditionData?.component_code) {
      return null;
    }
    return (
      <div className='flex flex-col border border-zinc-200 p-3 gap-1.5 bg-white rounded-lg'>
        {/* time */}
        <div className='flex w-full justify-between'>
          <span className='text-xs text-zinc-400 italic'>Webhook Value</span>
        </div>
        {/* condition value */}
        <div>
          <span className='text-zinc-900 text-sm font-medium'>
            ({getWebhookValues(conditionData.component_computation_info)})
          </span>
        </div>
      </div>
    );
  };

  const renderAndConditionBlock = (andBlockData: ISingleCondtionLogData[]) => {
    if (!Array.isArray(andBlockData)) {
      return null;
    }
    return andBlockData
      .filter((v) => v?.component_code)
      .map((v: ISingleCondtionLogData, idx) => (
        <div key={v.name + idx}>
          <div className='bg-zinc-50 p-3 flex rounded-xl justify-start gap-3'>
            <div>{renderConditionLogResultIcon(v)}</div>
            <div className='flex flex-col gap-3 w-full'>
              {renderUserConditionLog(v)}
              {renderWebhookConditionLog(v)}
            </div>
          </div>
        </div>
      ));
  };

  const renderOrSeparator = (index: number, length: number) => {
    if (index + 1 >= length) {
      return null;
    }
    return (
      <div className='flex flex-row flex-nowrap items-center'>
        <div className='w-full'>
          <hr />
        </div>
        <span className='px-3 py-0.5 border text-xs border-zinc-400 rounded-xl'>
          OR
        </span>
        <div className='w-full'>
          <hr />
        </div>
      </div>
    );
  };

  const renderConditonLogs = (conditions: ISingleCondtionLogData[][]) => {
    return conditions.map((andBlock, index, blockList) => {
      return (
        <div className='flex flex-col gap-3'>
          {renderAndConditionBlock(andBlock)}
          {renderOrSeparator(index, blockList.length)}
        </div>
      );
    });
  };

  return (
    <Sheet modal={true}>
      <SheetTrigger asChild className={hasLogResult ? '' : 'hidden'}>
        <button
          className={cn(
            'relative flex gap-1 w-full justify-center items-center py-2 rounded-md px-1',
            isConditionSuccess
              ? 'bg-zinc-100 text-zinc-900'
              : 'bg-[#FFF0F0] text-[#FF0000]'
          )}
        >
          <div className='pl-1 hidden'>
            <CircleAlert size={20} className='' />
            <p className='ml-2'> N conditions haven't matched.</p>
          </div>
          <span className='p-0 font-semibold'>View Logs</span>
          <ArrowRight size={16} />
        </button>
      </SheetTrigger>
      <SheetContent
        className='bg-white sm:max-w-[480px] overflow-y-auto'
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle className='text-zinc-900 text-lg font-semibold'>
            Activity Logs
          </SheetTitle>
          <SheetDescription className='text-sm text-zinc-500 leading-5'>
            You can check your automation run history from here.
          </SheetDescription>

          {renderConditonLogs(conditionData)}
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
