import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utilities/utils';

interface SingleItemProps {
  label: string;
  count?: number;
  showCount?: boolean;
  isSelected?: boolean;
  countColorClass?: string;
  handleClickEvent: () => void;
}
const SingleItemComponent: React.FC<SingleItemProps> = ({
  label,
  count = 0,
  showCount = false,
  isSelected = false,
  countColorClass = 'text-gray-600',
  handleClickEvent,
}) => {
  return (
    <div
      className='flex justify-between w-full py-2 cursor-pointer'
      onClick={() => {
        handleClickEvent();
      }}
    >
      <span
        className={classNames(
          'text-sm  leading-5 flex gap-2',
          isSelected
            ? 'text-gray-900 font-semibold'
            : 'text-gray-600 font-normal'
        )}
      >
        <span>{label}</span>
        <CheckIcon
          color='#111827'
          width={20}
          height={20}
          className={classNames(isSelected ? 'inline' : 'hidden')}
        />
      </span>
      <span
        className={classNames(
          `text-sm ${countColorClass} font-normal leading-5 float-right flex items-center gap-2`,
          showCount ? 'inline' : 'hidden'
        )}
      >
        {label === 'Unassigned' && count > 0 && (
          <div className='w-2 h-2 bg-red-500 rounded-full'></div>
        )}
        {count}
      </span>
    </div>
  );
};

export default SingleItemComponent;
