import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import { WhatsappMessageTemplateProps } from 'index';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  title: string;
  handleChange: (payload: any) => void;
  data: WhatsappMessageTemplateProps[] | null;
  selectedTemplate: string | number;
}

const WhatsappTemplateDropdown: React.FC<Props> = ({
  title,
  handleChange,
  data,
  selectedTemplate,
}) => {
  const [templateList, setTemplateList] = React.useState(data);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTemplateList(data);
  }, [data]);

  const filterTemplateList = (e: any) => {
    if (e.target.value === '') {
      setTemplateList(data);
    } else {
      let filteredTemplates = data?.filter(
        (template: WhatsappMessageTemplateProps) => {
          return template.name.includes(e.target.value);
        }
      );
      setTemplateList(filteredTemplates || []);
    }
  };

  const searchBarForTemplates = () => {
    return (
      <div className='sticky top-0 h-11 z-10 w-full bg-white px-2 mt-1'>
        <div className='pointer-events-none absolute inset-y-0 h-11 left-2.5 flex items-center pl-3 top-0'>
          <MagnifyingGlassIcon
            className='h-4 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block rounded-md border-gray-300 pl-10 h-11
                  focus:border-green-500 focus:ring-green-500 sm:text-sm w-full'
          placeholder={t('Search templates')}
          onChange={(e) => {
            filterTemplateList(e);
          }}
        />
      </div>
    );
  };

  const handleSelectTemplate = (value: string) => {
    handleChange(value);
    setTemplateList(data);
  };

  return (
    <Listbox
      onChange={(value: string) => {
        handleSelectTemplate(value);
      }}
      disabled={templateList ? false : true}
    >
      {({ open }) => (
        <div className='mb-5 rtl:text-right'>
          <Listbox.Label className='text-sm text-gray-600 font-medium'>
            {t(title)}
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button
              className='relative w-full cursor-default rounded-md
            border border-gray-300 bg-white py-2 ltr:pl-3 rtl:pr-3 ltr:pr-10 rtl:pl-10 ltr:text-left rtl:text-right shadow-sm
            focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500 sm:text-sm'
            >
              {data?.filter((item) => item.id === selectedTemplate)[0]?.name ||
                t('Select a message template')}
              <span className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2'>
                <ChevronDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className='absolute z-10 my-1 max-h-40 w-full
              overflow-auto rounded-md bg-white pb-1 text-base shadow-lg ring-1
              ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                <>
                  {!!data && !!data?.length && searchBarForTemplates()}
                  {!!templateList &&
                    templateList?.map((item) => (
                      <Listbox.Option
                        key={item.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 ltr:pl-3 rtl:pr-3 ltr:pr-9 rtl:pl-9'
                          )
                        }
                        value={item.id}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item.name}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-gray-600',
                                  'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </>
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default WhatsappTemplateDropdown;
