import React from 'react';
import { Avatar } from 'library';

interface ReopenRadioGroupsProps {
  data: GroupInterface[];
  selected: GroupInterface | null;
  onChange: (value: GroupInterface) => void;
}

const ReopenRadioGroups: React.FC<ReopenRadioGroupsProps> = ({
  data,
  selected,
  onChange,
}) => {
  return (
    <fieldset className='px-2'>
      <div className='mt-4 border-b border-gray-200 divide-y divide-gray-200'>
        {data.map((group, index) => (
          <div key={index} className='relative flex items-center py-3'>
            <div className='flex-1 min-w-0 text-sm'>
              <label
                htmlFor={`side-${group.id}`}
                className='flex items-center justify-between font-medium text-gray-700 select-none'
              >
                <div className='flex items-center'>
                  <Avatar size='sm' src={group.image || ''} name={group.name} />
                  &nbsp;&nbsp;{group?.name}
                </div>
                {group.is_smart && (
                  <div className='inline-flex ml-2 uppercase items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-primary'>
                    smart
                  </div>
                )}
              </label>
            </div>
            <div className='flex items-center h-5 ml-3'>
              <input
                id={`side-${group.id}`}
                name='group'
                type='radio'
                checked={!!selected && group.id === selected.id}
                onChange={() => {
                  onChange(group);
                  // we needed inline function because we needed group data from this mapping
                }}
                className='w-4 h-4 border-gray-300 text-primary focus:ring-primary'
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default ReopenRadioGroups;
