import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Row } from '@tanstack/react-table';

import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { SquarePen, Trash } from 'lucide-react';
import { useWebhooks } from '../../hooks/useWebhooks';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { useState } from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import WebhookModal, { IWebhookData } from '../webhookModal';

interface DataTableRowActionsProps {
  row: Row<IWebhookData>;
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
  const { t, isRtlLanguage } = useTranslation();
  const { deleteSelectedWebhook, webhookModalAction, setWebhookModalAction } =
    useWebhooks();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteRow = () => {
    deleteSelectedWebhook(Number(row.original.id!));
    setDeleteModal(false);
  };

  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete Webhook?')}
          </DialogTitle>

          <p className='text-zinc-500 text-sm rtl:text-right font-normal'>
            {t(
              'This will permanently delete the Webhook. Are you sure you want to delete?'
            )}
          </p>

          <DialogFooter className='rtl:gap-2 rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-[#F4F4F5] text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-[#F4F4F5]'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              onClick={() => handleDeleteRow()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 data-[state=open]:bg-muted'
          >
            <DotsHorizontalIcon className='h-4 w-4' />
            <span className='sr-only'>{t('Open menu')}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align={isRtlLanguage ? 'start' : 'end'}
          className='w-[160px] bg-white border border-zinc-200'
        >
          <DropdownMenuItem
            onSelect={() => {
              setIsModalOpen(true);
              setWebhookModalAction('EDIT');
            }}
            className='hover:bg-zinc-100 rounded flex gap-2 rtl:text-right rtl:justify-end'
          >
            <SquarePen className='w-4 h-4 rtl:order-1' />
            <span>{t('Edit')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator className='bg-zinc-200' />
          <DropdownMenuItem
            onSelect={(e) => {
              setDeleteModal(true);
            }}
            className='hover:bg-zinc-100 rounded flex gap-2 rtl:text-right rtl:justify-end'
          >
            <Trash className='w-4 h-4 rtl:order-1' />
            <span>{t('Delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {renderCardDeleteModal()}
      {webhookModalAction && isModalOpen && (
        <WebhookModal
          actionType={webhookModalAction as 'EDIT'}
          isOpen={isModalOpen}
          setIsOpen={(status) => {
            setIsModalOpen(status);
            setWebhookModalAction('CREATE');
          }}
          data={row.original as unknown as IWebhookData}
        />
      )}
    </div>
  );
}
