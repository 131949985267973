import React from 'react';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import {
  BulkExitIcon,
  BulkIcon,
  MagnifyingGlassIcon,
} from 'pages/inbox/assets/iconComponent/TopBarIcons';
import {
  ISearchState,
  TicketFilterInterface,
} from 'pages/inbox/inboxInterface';
import { getWhatsappAndEmailChannelCount } from 'pages/inbox/utils/functions';
import ReactTooltip from 'react-tooltip';
import { classNames } from 'utilities/utils';
import whatsappTicketCreationIcon from '../../../assets/images/whatsappTicketCreationIcon.svg';
import GlobalSearch from './GlobalSearch';
import TicketCreationModal from './TicketCreation';
import TicketQueueV2 from './TicketQueue_v2';

interface Props {
  queueType: string;
  createWhatsappTicket: (
    teamId: number,
    whatsappChannelId: string,
    phone: string | null
  ) => void;
  ticketLoading: boolean;
  totalTicketCount: number;
  searchState: ISearchState;
  isBulkActionActive: boolean;
  hasSupervisorAccess: boolean;
  ticketIsResolvedStatus: number;
  selectedProject: SelectedTeamInterface;
  createWhatsappTicketLoading: boolean;
  showBulkIcon: () => boolean;
  handleBulkToggle: () => void;
  handleSearchStateChange: () => void;
  handleClosedTicketQueue: (queue: string) => void;
  updateSateData: (key: string, value: any) => void;
  handleTicketQueueOnChange: (queue: string) => void;
  handleApplyOrClearFilter: (filterData: TicketFilterInterface) => void;
  whatsappCustomerListLoading: boolean;
  getWhatsappCustomerList: (
    teamId: number,
    whatsappChannelId: string | number,
    searchName: string
  ) => void;
}

const LeftBarHeader: React.FC<Props> = ({
  queueType,
  searchState,
  showBulkIcon,
  ticketLoading,
  updateSateData,
  selectedProject,
  totalTicketCount,
  handleBulkToggle,
  isBulkActionActive,
  hasSupervisorAccess,
  createWhatsappTicket,
  ticketIsResolvedStatus,
  handleSearchStateChange,
  handleClosedTicketQueue,
  getWhatsappCustomerList,
  handleApplyOrClearFilter,
  handleTicketQueueOnChange,
  createWhatsappTicketLoading,
  whatsappCustomerListLoading,
}) => {
  const { t } = useTranslation();
  const [isSearchButtonClicked, setIsSearchButtonClicked] =
    React.useState(false);

  const { emailChannelCount, whatsAppChannelCount } =
    getWhatsappAndEmailChannelCount(selectedProject);

  const [
    hasCreateWhatsappTicketButtonClicked,
    setHasCreateWhatsappTicketButtonClicked,
  ] = React.useState(false);

  const getLeftTopView = () => {
    if (searchState?.isSearchApplied) {
      return (
        <div className='flex gap-[14px] items-center'>
          <ArrowLeftIcon
            width={20}
            color={'#6B7280'}
            className='rtl:rotate-180'
            onClick={() => handleSearchStateChange()}
          />
          <span className='text-base font-medium text-gray-800 '>
            {t('Search Results')}
          </span>
        </div>
      );
    } else {
      return (
        <TicketQueueV2
          ticketLoading={ticketLoading}
          isDisabled={isBulkActionActive}
          totalTicketCount={totalTicketCount}
          hasSupervisorAccess={hasSupervisorAccess}
          handleFilterClick={handleApplyOrClearFilter}
          handleClosedTicketQueue={handleClosedTicketQueue}
          handleTicketQueueOnChange={handleTicketQueueOnChange}
        />
      );
    }
  };

  const renderWaTicketCreationModalView = () => {
    return (
      <TicketCreationModal
        isOpen={hasCreateWhatsappTicketButtonClicked}
        setModalOpen={(val: boolean) => {
          setHasCreateWhatsappTicketButtonClicked(val);
        }}
      />
    );
  };

  const whatsappTicketCreationButtonView = () => {
    return (
      <>
        <button
          className='p-1 active:text-primary'
          onClick={() => {
            setHasCreateWhatsappTicketButtonClicked(
              !hasCreateWhatsappTicketButtonClicked
            );
          }}
        >
          <img
            className={'marker:text-gray-100 w-4 h-4'}
            src={whatsappTicketCreationIcon}
            alt='whatsappTicketCreation'
          />
        </button>
      </>
    );
  };

  const getTopBarIcons = () => {
    if (!isBulkActionActive) {
      return (
        <>
          {queueType !== 'bot' &&
            (emailChannelCount > 0 || whatsAppChannelCount > 0) &&
            whatsappTicketCreationButtonView()}
          <button
            className={classNames(
              'p-1 cursor-pointer',
              showBulkIcon() ? 'block' : 'hidden'
            )}
            onClick={() => {
              handleBulkToggle();
            }}
          >
            <BulkIcon className='w-4 h-4' />
          </button>
          <button
            className='p-1 cursor-pointer'
            onClick={() => {
              setIsSearchButtonClicked(true);
            }}
          >
            <MagnifyingGlassIcon className='w-4 h-4' />
          </button>
        </>
      );
    } else {
      return (
        <button
          data-for='bulk-exit'
          data-tip={t('Exit Select Mode')}
          className='p-1 ml-auto cursor-pointer'
          onClick={() => {
            handleBulkToggle();
          }}
        >
          <BulkExitIcon />
          {/* @ts-ignore */}
          <ReactTooltip id='bulk-exit' effect='solid' />
        </button>
      );
    }
  };

  return (
    <div className='flex flex-col items-center justify-between w-full h-16 px-3 ltr:border-l-2 rtl:border-r-2 border-b border-solid lg:flex-row border-gray-200'>
      {getLeftTopView()}
      <GlobalSearch
        open={isSearchButtonClicked}
        selectedProject={selectedProject}
        setOpen={(isOpen: boolean) => {
          setIsSearchButtonClicked(isOpen);
        }}
      />
      {/* button icons */}
      <div className='flex gap-2'>{getTopBarIcons()}</div>
      {/* whatsapp */}

      {renderWaTicketCreationModalView()}
    </div>
  );
};
export default LeftBarHeader;
