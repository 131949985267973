import React from 'react';

interface Prop {
  isBold?: boolean;
  title: string;
  value: any;
}

const compName: React.FC<Prop> = ({ isBold = false, title, value }) => {
  return (
    <div className='order-right-bar__order-card__summary__info-container'>
      <p
        className={`order-right-bar__order-card__summary__info-container-title ${
          isBold ? 'bold' : ''
        }`}
      >
        {title}:
      </p>
      <p
        className={`order-right-bar__order-card__summary__info-container-subtitle ${
          isBold ? 'bold' : ''
        }`}
      >
        {value}
      </p>
    </div>
  );
};

export default compName;
