import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import LoaderLine from '../../old/components/loaders/LoaderLine';

interface Prop {
  shopifyConnectCallback: (params: string) => Promise<boolean>;
  location: {
    search: string;
  };
}

const ShopifyCallbackURL: React.FC<Prop> = ({
  shopifyConnectCallback,
  location,
}) => {
  const updateShopifydata = async () => {
    const res: boolean = await shopifyConnectCallback(location.search);
    if (!res) {
      toaster.danger('Failed', {
        description:
          'We failed to update shopify data to our backend properly. Please remove and try again',
      });
    }
    navigate('/settings/marketplace');
  };
  React.useEffect(() => {
    updateShopifydata();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex items-center justify-center w-screen h-screen'>
      <div>
        <LoaderLine />
        <p>Redirecting...</p>
      </div>
    </div>
  );
};

const mapState = () => ({});

const mapDispatch = (dispatch: any) => ({
  shopifyConnectCallback: (params: string) =>
    dispatch.marketplace.shopifyConnectCallback(params),
});

const ShopifyCallbackURLContainer = connect(
  mapState,
  mapDispatch
)(ShopifyCallbackURL);

export default ShopifyCallbackURLContainer;
