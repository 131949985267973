import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import LoadingChartView from './LoadingChartView';
import EmptyView from '../common/EmptyView';
import useTranslation from 'components/customHooks/useTranslation';
import { PlatformStats } from '../interface';

interface Props {
  loading: boolean;
  data: PlatformStats[];
}

const SolidCircleIcon = (props: { color: string; size: number }) => {
  return (
    <div
      style={{
        height: props.size + 'px',
        width: props.size + 'px',
        backgroundColor: props.color,
        borderRadius: '50%',
      }}
    ></div>
  );
};

const ChartView: React.FC<Props> = ({ data, loading }) => {
  const { t, isRtlLanguage } = useTranslation();

  const loadingView = () => {
    return <LoadingChartView />;
  };
  const emptyView = () => {
    return <EmptyView msg={t('No data available')} />;
  };

  const renderChartLegends = (props: any) => {
    const { payload } = props;
    return (
      <ul className='w-full flex justify-center gap-3'>
        {payload?.map((entry: any, index: number) => (
          <li key={`item-${index}`} className='flex items-center gap-2'>
            <SolidCircleIcon size={16} color={payload[index].color!} />
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const mainChartView = () => {
    if (loading) return loadingView();
    else if (!data || data?.length < 1) {
      return emptyView();
    } else
      return (
        <div className='px-2'>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart
              style={{ direction: isRtlLanguage ? 'rtl' : 'ltr' }}
              width={200}
              height={140}
              data={data}
              margin={{
                top: 16,
                right: 24,
                left: 24,
                bottom: 16,
              }}
            >
              <CartesianGrid horizontal={true} />
              <XAxis
                reversed={isRtlLanguage}
                dataKey='platform_name'
                height={50}
              />
              <YAxis
                tickMargin={isRtlLanguage ? 30 : 10}
                orientation={isRtlLanguage ? 'right' : 'left'}
                type='number'
                domain={[
                  (dataMin: number) => Math.ceil(dataMin),
                  (dataMax: number) => dataMax + 50,
                ]}
              />
              <Tooltip cursor={{ color: '#F3F4F6', opacity: 0.2 }} />
              <Legend content={renderChartLegends} />
              <Bar
                barSize={20}
                dataKey='assigned'
                fill='#04B25F'
                name={t('Ticket Assigned')}
              />
              <Bar
                barSize={20}
                dataKey='unassigned'
                fill='#0EA5E9'
                name={t('Ticket Unassigned')}
              />
              <Bar
                barSize={20}
                dataKey='unreplied'
                fill='#F59E0B'
                name={t('Ticket Unreplied')}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
  };
  return (
    <div className={`rounded-lg bg-white shadow divide-y`}>
      <div className='py-4 px-6'>
        <span className='text-base font-medium text-gray-700'>
          {t('Channel Reports')}
        </span>
        <span className='text-xs font-normal text-gray-600 block mt-1'>
          {t('Report of channel wise tickets.')}
        </span>
      </div>
      {mainChartView()}
    </div>
  );
};

export default ChartView;
