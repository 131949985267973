import { useDispatch, useSelector } from 'react-redux';
interface ChatBotSettingsProps {
  keyword: boolean;
  button: boolean;
  referral: boolean;
}

interface ChatbotSettingsHookProps {
  settings: ChatBotSettingsProps;
  getChatBotSettings: () => Promise<void>;
  updateChatBotSettings: (data: ChatBotSettingsProps) => Promise<void>;
}

export const useChatBotSettings = (): ChatbotSettingsHookProps => {
  const dispatch = useDispatch();
  const settingsData = useSelector((state: any) => state.settings);
  const dashboardData = useSelector((state: any) => state.dashboard);

  const getChatBotSettings = async () => {
    await dispatch.settings.fetchChatBotSettings(
      dashboardData?.selectedProject?.id
    );
  };

  const updateChatBotSettings = async (data: ChatBotSettingsProps) => {
    await dispatch.settings.updateChatBotSettings({
      projectId: dashboardData?.selectedProject?.id,
      settings: data,
    });
  };
  return {
    settings: settingsData.chatBotSettings,
    updateChatBotSettings,
    getChatBotSettings,
  };
};
