import React from 'react';
import { connect } from 'react-redux';
import InformationForm from './InformationForm';
import ChannelConnect from './ChannelSelect';
import { Icon } from 'evergreen-ui';
import { countryDataInterface } from './interfaces';
import RequestLoading from './RequestLoading';
import SuccessModal from './SuccessModal';
import UnsuccessModal from './TryAgain';

import {
  teamCreationProps,
  categoryType,
  TimezoneDetailsProps,
} from './interfaces';

const TeamCreation: React.FC<teamCreationProps> = ({
  getCountryData,
  country,
  showModal,
  setShowModal,
  fetchTimezoneList,
  timezoneList,
  changeTeam,
  willStaySamePage = false,
}) => {
  const [step, setStep] = React.useState(1);
  const [selectedCountry, setSelectedCountry] =
    React.useState<countryDataInterface>({
      country: 'Singapore',
      country_iso_code: 'SG',
      currency_code: 'SGD',
      currency_name: 'Dollar',
      currency_symbol: '$',
      flag_base64:
        'iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NzkxOEIzQzE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NzkxOEIzRDE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc3OTE4QjNBMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc3OTE4QjNCMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k5fIFwAAAflJREFUeNrslk1rE1EUhp97Z5Jp0dTEL7S0GEFcKK4CLgouunARQcEK4kKqtTsVi8WFFUS6EDdiUXDvX9CFgkJXirjQoggiKoqSGIuYtpOk6SRzxzMTK/0BlwrSA5d5mYF5zrnnPXdG1fYMloBeHO3TDjEfv0CXh96Vh6AFLVmOA0phKTKyym4itIb6YsZ8LZEeG8U9WiS4fodw5m0CVZl1Hbgx1uA6uYRSaWUWb+oa3s2rmDfvaD16gNq2la57t1D5fsz7T7C01EnAQiRgU6qQOnyQ9PkRwulnNMcnSZ86g9qUJXw4DY1FUieHUH3bE20NTL2BPrA/ke37j1Ebs6TODic9DqbuEs3+JHX6OFrAkV+3AnaXhVo2T896onKZ5vCYtMCg9+6TLQ5ojl6ClIvanLNYcXc34fOXnUwGBzBUcAYKeLcnJYkfeDcu4544gvn23Zq7nStbdk6Ia73wxQxazOQOFVGu9PbJU6jVBVYmdn0kjo9+VVHptA1uoPzewoK8OENdIFWp7uIF3GOHaJ6bIHz9Cr1jN1Gl0gHmZJvbbRtgX9UKxTkRG2RMDEGA+fBZ3JxD5/uIag2bB8fK9s4rM79QFZGNp/nvIzEVMS+e2SiyDY4PgjlX9WScFTdWKxzNP4o18Br4/wPHXyf/z++Iv0rMhPVbgAEAAN6l96444okAAAAASUVORK5CYII=',
      id: 191,
      iso_alpha3: 'SGP',
      iso_numeric: 702,
      phone_code: '+65',
    });
  const [countryList, setCountryList] = React.useState<countryDataInterface[]>(
    []
  );
  const [teamName, setTeamName] = React.useState<string>('');
  const [businessUrl, setBusinessUrl] = React.useState('');
  const selectTeamName = (value: string) => setTeamName(value);
  const [timeZone, setTimeZone] = React.useState<TimezoneDetailsProps>({
    city: 'Singapore',
    timezone_alias: 'Asia/Singapore',
    timezone_offset: '+8:00',
    timezone_offset_type: 'UTC',
  });
  const [newTeamData, setNewTeamData] = React.useState(null);
  const [teamNameEmpty, setTeamNameEmpty] = React.useState('');
  const [urlValidMessage, setUrlValidMessage] = React.useState('');

  const BusinessCategories = [
    { id: 1, name: 'E-commerce', emoji: '🛍️' },
    { id: 2, name: 'FMCG', emoji: '🛒' },
    { id: 3, name: 'Financial Institution', emoji: '🏦' },
    { id: 4, name: 'Travel and Logistic', emoji: '🏖️' },
    { id: 6, name: 'Entertainment and Hospitality', emoji: '🏨' },
    { id: 7, name: 'Service Industry', emoji: '🏭' },
    { id: 8, name: 'Education', emoji: '🏫' },
    { id: 9, name: 'Other', emoji: '🤔' },
  ];

  const [selectedCategory, setSelectedCategory] =
    React.useState<categoryType | null>(null);

  React.useEffect(() => {
    fetchCountryData();
    fetchTimezoneList();
    // eslint-disable-next-line
  }, []);

  const checkValidurl = (str: string) => {
    let givenUrl = str;
    if (givenUrl === '') return true;
    if (givenUrl && givenUrl.includes('https://')) {
      givenUrl = str.replace('https://', '');
    }
    let pattern = new RegExp(
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(givenUrl);
  };

  const handleStep1NextClick = () => {
    !teamName
      ? setTeamNameEmpty('Team name cannot be empty')
      : setTeamNameEmpty('');
    !checkValidurl(businessUrl)
      ? setUrlValidMessage('Please give a valid url')
      : setUrlValidMessage('');

    if (
      teamName &&
      timeZone.timezone_alias &&
      timeZone.timezone_offset &&
      timeZone.timezone_offset_type &&
      checkValidurl(businessUrl)
    ) {
      return setStep(step + 1);
    }
  };

  const handlePrevious = () => setStep(1);

  const fetchCountryData = async () => {
    let data = await getCountryData();
    setCountryList(data);
    const currCountryData = data.filter((c) => c.country === country);
    if (currCountryData.length > 0) {
      setSelectedCountry(currCountryData[0]);
    } else {
      const defaultCountry = {
        id: 191,
        country: 'Singapore',
        country_iso_code: 'SG',
        iso_alpha3: 'SGP',
        iso_numeric: 702,
        phone_code: '+65',
        currency_code: 'SGD',
        currency_name: 'Dollar',
        currency_symbol: '$',
        flag_base64:
          'iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NzkxOEIzQzE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NzkxOEIzRDE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc3OTE4QjNBMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc3OTE4QjNCMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k5fIFwAAAflJREFUeNrslk1rE1EUhp97Z5Jp0dTEL7S0GEFcKK4CLgouunARQcEK4kKqtTsVi8WFFUS6EDdiUXDvX9CFgkJXirjQoggiKoqSGIuYtpOk6SRzxzMTK/0BlwrSA5d5mYF5zrnnPXdG1fYMloBeHO3TDjEfv0CXh96Vh6AFLVmOA0phKTKyym4itIb6YsZ8LZEeG8U9WiS4fodw5m0CVZl1Hbgx1uA6uYRSaWUWb+oa3s2rmDfvaD16gNq2la57t1D5fsz7T7C01EnAQiRgU6qQOnyQ9PkRwulnNMcnSZ86g9qUJXw4DY1FUieHUH3bE20NTL2BPrA/ke37j1Ebs6TODic9DqbuEs3+JHX6OFrAkV+3AnaXhVo2T896onKZ5vCYtMCg9+6TLQ5ojl6ClIvanLNYcXc34fOXnUwGBzBUcAYKeLcnJYkfeDcu4544gvn23Zq7nStbdk6Ia73wxQxazOQOFVGu9PbJU6jVBVYmdn0kjo9+VVHptA1uoPzewoK8OENdIFWp7uIF3GOHaJ6bIHz9Cr1jN1Gl0gHmZJvbbRtgX9UKxTkRG2RMDEGA+fBZ3JxD5/uIag2bB8fK9s4rM79QFZGNp/nvIzEVMS+e2SiyDY4PgjlX9WScFTdWKxzNP4o18Br4/wPHXyf/z++Iv0rMhPVbgAEAAN6l96444okAAAAASUVORK5CYII=',
      };
      setSelectedCountry(defaultCountry);
    }
  };

  const handleTimezoneChange = (data: any) => {
    setTimeZone({
      timezone_alias: data.timezone_alias,
      timezone_offset_type: data.timezone_offset_type,
      timezone_offset: data.timezone_offset,
      city: data.city,
    });
  };

  const showLoadingModal = () => setStep(3);
  const showSuccessModal = () => setStep(4);
  const showUnsuccessModal = () => setStep(5);
  const tryAgain = () => setStep(1);
  const saveNewTeamData = (value: any) => setNewTeamData(value);

  const closeModal = () => {
    setShowModal();
    setStep(1);
    clearData();
  };

  const clearData = () => {
    setTeamName('');
    setTimeZone({
      city: 'Singapore',
      timezone_alias: 'Asia/Singapore',
      timezone_offset: '+8:00',
      timezone_offset_type: 'UTC',
    });
    setSelectedCountry({
      country: 'Singapore',
      country_iso_code: 'SG',
      currency_code: 'SGD',
      currency_name: 'Dollar',
      currency_symbol: '$',
      flag_base64:
        'iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NzkxOEIzQzE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NzkxOEIzRDE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc3OTE4QjNBMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc3OTE4QjNCMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k5fIFwAAAflJREFUeNrslk1rE1EUhp97Z5Jp0dTEL7S0GEFcKK4CLgouunARQcEK4kKqtTsVi8WFFUS6EDdiUXDvX9CFgkJXirjQoggiKoqSGIuYtpOk6SRzxzMTK/0BlwrSA5d5mYF5zrnnPXdG1fYMloBeHO3TDjEfv0CXh96Vh6AFLVmOA0phKTKyym4itIb6YsZ8LZEeG8U9WiS4fodw5m0CVZl1Hbgx1uA6uYRSaWUWb+oa3s2rmDfvaD16gNq2la57t1D5fsz7T7C01EnAQiRgU6qQOnyQ9PkRwulnNMcnSZ86g9qUJXw4DY1FUieHUH3bE20NTL2BPrA/ke37j1Ebs6TODic9DqbuEs3+JHX6OFrAkV+3AnaXhVo2T896onKZ5vCYtMCg9+6TLQ5ojl6ClIvanLNYcXc34fOXnUwGBzBUcAYKeLcnJYkfeDcu4544gvn23Zq7nStbdk6Ia73wxQxazOQOFVGu9PbJU6jVBVYmdn0kjo9+VVHptA1uoPzewoK8OENdIFWp7uIF3GOHaJ6bIHz9Cr1jN1Gl0gHmZJvbbRtgX9UKxTkRG2RMDEGA+fBZ3JxD5/uIag2bB8fK9s4rM79QFZGNp/nvIzEVMS+e2SiyDY4PgjlX9WScFTdWKxzNP4o18Br4/wPHXyf/z++Iv0rMhPVbgAEAAN6l96444okAAAAASUVORK5CYII=',
      id: 191,
      iso_alpha3: 'SGP',
      iso_numeric: 702,
      phone_code: '+65',
    });
    setUrlValidMessage('');
    setTeamNameEmpty('');
    setBusinessUrl('');
    setSelectedCategory(null);
  };

  return (
    <>
      {showModal && countryList ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-[35%] my-6 mx-auto'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='relative flex-auto'>
                  {step !== 3 && (
                    <button
                      className='absolute top-2 right-1.5 h-8 w-8'
                      onClick={() => closeModal()}
                    >
                      <Icon
                        icon={'cross'}
                        style={{ cursor: 'pointer' }}
                        size={16}
                        className='text-gray-600'
                      />
                    </button>
                  )}
                  {step === 1 && (
                    <InformationForm
                      onNextClick={handleStep1NextClick}
                      selectCountry={setSelectedCountry}
                      selectedCountry={selectedCountry}
                      countryList={countryList}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      businessCategories={BusinessCategories}
                      setBusinessUrl={setBusinessUrl}
                      businessUrl={businessUrl}
                      teamName={teamName}
                      setTeamName={selectTeamName}
                      handleTimezoneChange={handleTimezoneChange}
                      timeZone={timeZone}
                      timeZoneList={timezoneList}
                      errorMassage={teamNameEmpty}
                      validUrl={urlValidMessage}
                    />
                  )}
                  {step === 2 && (
                    <ChannelConnect
                      onPreviousClick={handlePrevious}
                      teamName={teamName}
                      selectedCountry={selectedCountry}
                      selectedBusinessUrl={businessUrl}
                      selectedBusinessType={selectedCategory}
                      selectedTimeZone={timeZone}
                      showLoadingModal={showLoadingModal}
                      showSuccessModal={showSuccessModal}
                      showUnsuccessModal={showUnsuccessModal}
                      saveNewTeamData={saveNewTeamData}
                    />
                  )}
                  {step === 3 && <RequestLoading />}
                  {step === 4 && newTeamData && (
                    <SuccessModal
                      willStaySamePage={willStaySamePage}
                      changeTeam={changeTeam}
                      newTeamData={newTeamData}
                      closeModal={closeModal}
                      teamName={teamName}
                    />
                  )}
                  {step === 5 && (
                    <UnsuccessModal
                      closeModal={closeModal}
                      tryAgain={tryAgain}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
};

const mapState = (state: any) => ({
  country: state.auth.country,
  timezoneList: state.teamManagement.timezoneList,
});

const mapDispatch = (dispatch: any) => ({
  fetchTimezoneList: () => dispatch.teamManagement.fetchTimezoneList(),
  getCountryData: () => dispatch.teamManagement.getCountryData(),
});

const TeamCreationModal = connect(mapState, mapDispatch)(TeamCreation);

export default TeamCreationModal;
