import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import BlankModal from 'library/modal/BlankModal';
import { toast } from 'libraryV2/ui/use-toast';
import {
  channelCreateAPiResponseType,
  channelListProps,
} from 'pages/integration/interface';
import { SallaChannelDefaultData } from 'pages/integration/utils/content';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import SallaIcon from '../../../assets/icons/marketplace/salla.svg';
import SallaIntegrationStep1 from './SallaIntegrationStep1';
import SallaIntegrationStep2 from './SallaIntegrationStep2';

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  selectedProject: SelectedTeamInterface;
  onComplete?: () => void;
}

export const SallaIntegrationModal: FC<Props> = ({
  open,
  handleClose,
  selectedProject,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [platformData, setPlatfromData] = useState<
    channelListProps | undefined
  >();
  const {
    integration: { createChannelIntegrations },
  } = useDispatch();

  const handleSallaLiveChat = async () => {
    let payloadData = { ...SallaChannelDefaultData };
    setLoading(true);

    let res: channelCreateAPiResponseType = await createChannelIntegrations({
      channelType: 'livechat_messenger',
      teamId: selectedProject.id,
      data: payloadData,
      hideToaster: true,
    });
    if (res.status === 200) {
      setCurrentStep(2);
      setLoading(false);
      toast({
        title: `${t('Channel')} ${res.data.dataSource?.title} ${t(
          'successfully Connected!'
        )}`,
      });

      setPlatfromData(res.data.dataSource);
    } else {
      toast({
        title: t('Something went wrong. Please try again!'),
        variant: 'destructive',
      });
      setLoading(false);
    }
  };
  const handleOnSubmit = () => {
    if (currentStep === 1) {
      handleSallaLiveChat();
    }
    if (currentStep === 2) {
      handleClose();
      if (onComplete) {
        onComplete();
      }
      setTimeout(() => {
        setCurrentStep(1);
      }, 300);
    }
  };
  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return <SallaIntegrationStep1 />;
      case 2: {
        return (
          <SallaIntegrationStep2
            platformData={platformData!}
            selectedProject={selectedProject}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <BlankModal
      open={open}
      hideCancelButton
      loading={loading}
      submitButtonText={t(currentStep === 1 ? t('Continue') : t('Complete'))}
      suggestionText={t('Need Help?')}
      documentationLink=''
      isSubmitButtonLoading={loading}
      linkText={t('Read Documentation')}
      onSubmitButtonClick={handleOnSubmit}
      headerIcon={<img src={SallaIcon} alt='Salla-icon' className='w-8 h-8' />}
      title={t('Integrate Salla')}
      subTitle={t('Follow the steps to configure Salla integration.')}
      handleClose={() => {
        setTimeout(() => {
          setCurrentStep(1);
        }, 500);
        handleClose();
      }}
      forIntegration
    >
      {renderSteps()}
    </BlankModal>
  );
};
