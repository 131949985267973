import React from 'react';
import gmailIcon from '../../../../../assets/icons/channelIconSet/gmail-icon.svg';
import outlookIcon from '../../../../../assets/icons/channelIconSet/outlook-icon.svg';
import customEmailServer from '../../../../../assets/icons/channelIconSet/custom-email-server-icon.svg';
import Button from 'library/button';
import useGoogleAuthentication from 'library/helper/hooks/useGoogleAuthentication';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  setEmailUserAuthCode: (data: string) => void;
}

const Step1: React.FC<Props> = ({ setEmailUserAuthCode }) => {
  const { t } = useTranslation();
  const googleLogin = useGoogleAuthentication(setEmailUserAuthCode);

  const renderEmailComponent = (
    image: string,
    emailType: string,
    buttonText: string
  ) => {
    const releasableFeature = ['Gmail'];

    return (
      <div>
        <Button
          className={`flex pl-3 w-full border border-gray-300 rounded ${
            releasableFeature.includes(emailType) ? '' : 'bg-gray-100'
          }`}
          onClick={() => googleLogin()}
          isDisabled={!releasableFeature.includes(emailType)}
        >
          <img src={image} width={17} height={17} alt='social_channel' />
          <p
            className={`p-0 pl-2 px-2 pr-1 text-sm border-none w-full flex space-between ${
              releasableFeature.includes(emailType)
                ? 'text-gray-700'
                : 'text-gray-500'
            }`}
          >
            {buttonText}

            {!releasableFeature.includes(emailType) && (
              <span className='bg-green-100 text-green-800 py-[2px] px-[4px] text-xs leading-4'>
                {t('Coming soon!')}
              </span>
            )}
          </p>
        </Button>
      </div>
    );
  };

  const rederInformationCard = () => {
    return (
      <div className='flex justify-center px-4 py-4 mt-6 text-sm text-blue-800 border rounded-md bg-blue-50 border-grey-200 '>
        <div className='justify-center'>
          <InformationCircleIcon
            className='w-5 h-5 text-blue-400'
            aria-hidden='true'
          />
        </div>
        <p className='ml-3'>
          {t(`MyAlice's use and transfer of information received from Google APIs to
          any other app will adhere to`)}{' '}
          <a
            href={`https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes`}
            target='_blank'
            className='text-blue-500 hover:text-blue-900'
            rel='noreferrer'
          >
            <b>{t('Google API Services User Data Policy')}</b>
          </a>
          , {t('including the Limited Use requirements.')}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className='flex flex-col col-span-6 gap-2 sm:col-span-6'>
        {renderEmailComponent(gmailIcon, 'Gmail', 'Sign in with Gmail')}
        {renderEmailComponent(outlookIcon, 'Outlook', 'Sign in with Outlook')}
        {renderEmailComponent(
          customEmailServer,
          'Custom Email Server',
          'Sign in with Custom Email'
        )}

        {rederInformationCard()}
      </div>
    </>
  );
};
export default Step1;
