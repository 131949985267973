import { PlatformInterface, WhatsappMessageTemplateProps } from 'index';
import React from 'react';
import { CompaignPostData } from '../interface';
import WhatsappDropdownInput from './WhatsappDropdownInput';
import WhatsappTemplateDropdown from './WhatsappTemplateDropdown';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  campaignData: CompaignPostData;
  handleChange: (payload: CompaignPostData) => void;
  platformList: PlatformInterface[];
  templateList: WhatsappMessageTemplateProps[] | null;
}

const CampaignDetails: React.FC<Props> = ({
  campaignData,
  handleChange,
  platformList,
  templateList,
}) => {
  const { t } = useTranslation();
  const connectedWhatsappBspChannels = platformList
    .filter((item) => item.platform_type === 'whatsapp_bsp')
    .filter((whatsapp) => whatsapp.is_connected);

  React.useEffect(() => {
    if (
      connectedWhatsappBspChannels.length === 1 &&
      connectedWhatsappBspChannels[0]?.secondary_id
    ) {
      handleChange({
        ...campaignData,
        channel_details: {
          id: connectedWhatsappBspChannels[0].id,
          title: connectedWhatsappBspChannels[0].title,
          phone: connectedWhatsappBspChannels[0].secondary_id,
        },
      });
    }
    //eslint-disable-next-line
  }, [connectedWhatsappBspChannels.length]);

  const modifyCampaignChannelData = (value: any) => {
    if (value.id !== campaignData.channel_details.id) {
      handleChange({
        ...campaignData,
        template_id: '',
        channel_details: value,
      });
    } else {
      handleChange({ ...campaignData, channel_details: value });
    }
  };

  return (
    <div className=''>
      <div className='w-full mb-5'>
        <label className='block text-sm rtl:text-right text-gray-600 font-medium'>
          {t('Broadcast Name')}
        </label>
        <div className='relative mt-1 rounded-md shadow-sm'>
          <input
            type='text'
            className='block w-full rounded-md border-gray-300 ltr:pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm'
            placeholder={t('e.g. My First Broadcast')}
            name='title'
            onChange={(e) =>
              handleChange({ ...campaignData, title: e.target.value })
            }
            maxLength={24}
            value={campaignData.title}
          />
          <div className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3'>
            <p className='text-xs text-gray-400 py-1 px-1 bg-gray-50 rounded'>
              {campaignData.title.length}/24
            </p>
          </div>
        </div>
      </div>

      {connectedWhatsappBspChannels.length === 1 ? (
        <div className='mb-5 rtl:text-right'>
          <p>{t('Your WhatsApp Channel')}</p>
          <p
            className='w-full mt-1 cursor-default rounded-md
            border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm'
          >
            <span className='block truncate'>
              {campaignData.channel_details.title} (
              {campaignData.channel_details.phone})
            </span>
          </p>
        </div>
      ) : (
        <WhatsappDropdownInput
          title={'Your WhatsApp Channel'}
          handleChange={(value) => {
            modifyCampaignChannelData(value);
          }}
          data={connectedWhatsappBspChannels}
          type='whatsapp_bsp'
          channelDetails={campaignData.channel_details}
        />
      )}

      {!!campaignData.channel_details.id && (
        <WhatsappTemplateDropdown
          title={'Message Template'}
          handleChange={(value) =>
            handleChange({ ...campaignData, template_id: value })
          }
          data={templateList}
          selectedTemplate={campaignData.template_id}
        />
      )}
    </div>
  );
};

export default CampaignDetails;
