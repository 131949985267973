import React from 'react';
import { Pane, SelectMenu } from 'evergreen-ui';
import PropTypes from 'prop-types';

const CreateButtonFragment = ({
  type,
  hasAPIButton,
  hasActionButton,
  buttonTitle,
  APIButtonTitle,
  buttonCount,
  handleCreate,
  apiButtonData,
  apiList,
  handleAPIButtonElementCreate,
  blockId,
}) => {
  let option = [];
  if (hasAPIButton) {
    option = !!apiList && apiList.filter((api) => api.return_type === type);
    option = option.map((api) => ({
      label: api.title,
      value: api.id + '',
    }));
  }
  return (
    <div className='button-group-create-container'>
      <Pane
        className='border-gray-200 button-group__child'
        onClick={handleCreate}
      >
        <div className='button-group__child-title'>{buttonTitle}</div>
        <div className='button-group__child-subtitle'>
          Create element from here
        </div>
      </Pane>
      {hasActionButton && (
        <Pane
          className='border-gray-200 button-group__child'
          onClick={() => handleCreate('ecommerce')}
        >
          <div className='button-group__child-title'>Create Action Button</div>
          <div className='button-group__child-subtitle'>
            Create element from here
          </div>
        </Pane>
      )}
      {!!hasAPIButton && buttonCount === 0 && (
        <SelectMenu
          title='Select API'
          options={option}
          selected={
            !!apiButtonData && !!apiButtonData.id ? apiButtonData.id + '' : ''
          }
          onSelect={(item) =>
            handleAPIButtonElementCreate(blockId, item, false)
          }
          filterPlaceholder={'Search for or choose an API'}
          filterIcon={'code'}
        >
          <Pane className='button-group__child'>
            <div className='button-group__child-title'>{APIButtonTitle}</div>
            <div className='button-group__child-subtitle'>
              Create a new API element here
            </div>
          </Pane>
        </SelectMenu>
      )}
    </div>
  );
};

CreateButtonFragment.propTypes = {
  hasAPIButton: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string,
  APIButtonTitle: PropTypes.string,
  buttonCount: PropTypes.number,
  handleCreate: PropTypes.func.isRequired,
  apiButtonData: PropTypes.object,
  apiList: PropTypes.arrayOf(PropTypes.object),
  handleAPIButtonElementCreate: PropTypes.func,
  blockId: PropTypes.number,
  type: PropTypes.string,
};

CreateButtonFragment.defaultProps = {
  buttonTitle: 'Create A Element',
  APIButtonTitle: 'Create a Api Element',
};

export default CreateButtonFragment;
