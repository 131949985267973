import useDatalab from 'pages/datalab/hooks/useDatalab';
import notFoundImg from '../../../../assets/icons/emptySearchIcon.svg';
import { Plus, Button, useState, type FC } from '../../export';
import DatalabSideSheet from './DatalabSideSheet';
import useTranslation from 'components/customHooks/useTranslation';

interface NoDataFoundProps {
  hasButton?: boolean;
  headingText?: string;
  descriptionText?: string;
  buttonText?: string;
}

export const NoDataFound: FC<NoDataFoundProps> = ({
  hasButton = true,
  headingText = 'No Entries Available',
  descriptionText = 'There are no entries available for the selected time range. Please adjust the time filter or add new data to get started.',
  buttonText = 'Create New Input',
}) => {
  const { t } = useTranslation();
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const { selectedDatalabInfo } = useDatalab();
  const handleSheetClose = () => {
    setIsSideSheetOpen((old) => !old);
  };
  return (
    <>
      <div className='flex items-center justify-center w-full'>
        <div className='flex flex-col items-center max-w-md'>
          <img
            src={notFoundImg}
            alt='emptySearchIcon'
            className='w-48 h-24 mb-4'
          />
          <h3 className='mb-2 text-center text-textPrimary'>
            {t(headingText)}
          </h3>
          <span className='subtitle-medium'>{t(descriptionText)}</span>
          {hasButton && (
            <>
              <Button onClick={handleSheetClose} className='text-white'>
                <Plus className='w-4 h-4 text-white ltr:mr-2 rtl:ml-2' />
                {t(buttonText)}
              </Button>
              <DatalabSideSheet
                isOpen={isSideSheetOpen}
                datalabEntries={{}}
                datalabShape={selectedDatalabInfo}
                header='Enter new Data'
                mode='CREATE'
                onClose={handleSheetClose}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
