import React from 'react';
import DeliveryPresentDetails from '../components/DeliveryPresentDetails';
import DeliveryShippingtDetails from '../components/DeliveryShippingDetails';
import DeliveryPayment from '../components/DeliveryPayment';
import DiscountsCoupons from '../components/DiscountCoupons';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  CustomerDataProps,
  PaymentMethodProps,
  ShippingMethodProps
} from '../interfaces';

interface Prop {
  customerData: CustomerDataProps;
  updateAddress: (key: string, value: string, mainKey: string) => void;
  updateStateData: (key: string, value: any) => void;
  paymentMethod: PaymentMethodProps;
  shippingMethod: ShippingMethodProps;
  shippingCost: number;
  isSameShippingAddress: boolean;
  paymentMethodList: PaymentMethodProps[];
  shippingMethodList: ShippingMethodProps[];
  currencySymbol: string;
  countryDetails: {
    country: string;
    country_iso_code: string;
    phone_code: string;
  };
  woocommerceDiscountCoupons: [{ id: number; code: string }];
  selectedCouponCode: string;
}

const DeliveryInformation: React.FC<Prop> = ({
  customerData,
  updateAddress,
  updateStateData,
  paymentMethod,
  shippingMethod,
  shippingCost,
  isSameShippingAddress,
  paymentMethodList,
  shippingMethodList,
  currencySymbol,
  countryDetails,
  woocommerceDiscountCoupons,
  selectedCouponCode,
}) => {
  const setShippingFromBilling = (checked: boolean) => {
    if (checked && customerData && customerData?.billing_address !== customerData.shipping_address) {
      if (customerData.billing_address && !customerData.billing_address.country) {
        customerData.billing_address.country = countryDetails.country || 'singapore';
      }
      let data: CustomerDataProps = cloneDeep(customerData);
      data.shipping_address = data?.billing_address;
      updateStateData('customerData', data);
    }
  };

  setShippingFromBilling(isSameShippingAddress);

  return (
    <div>
      {!!customerData && customerData?.billing_address && (
        <DeliveryPresentDetails
          data={customerData?.billing_address}
          handleChange={(key: string, value: string) => {
            if (isSameShippingAddress) {
              updateAddress(key, value, 'shipping_address');
            }
            updateAddress(key, value, 'billing_address');
          }}
          countryDetails={countryDetails}
        />
      )}
      {!!customerData && customerData?.shipping_address && (
        <DeliveryShippingtDetails
          data={
            isSameShippingAddress
              ? customerData?.billing_address
              : customerData?.shipping_address
          }
          isSameShippingAddress={isSameShippingAddress}
          updateIsSameBillingAddress={(checked: boolean) => {
            updateStateData('isSameShippingAddress', checked);
            setShippingFromBilling(checked);
          }}
          handleChange={(key: string, value: string) => {
            updateAddress(key, value, 'shipping_address');
          }}
        />
      )}
      <DeliveryPayment
        paymentMethodList={paymentMethodList}
        shippingMethodList={shippingMethodList}
        paymentMethod={paymentMethod}
        shippingMethod={shippingMethod}
        shippingCost={shippingCost}
        handleChange={(key: string, value: any) => {
          updateStateData(key, value);
        }}
        currencySymbol={currencySymbol}
      />
      <DiscountsCoupons
        couponsData={woocommerceDiscountCoupons}
        selectedCouponData={selectedCouponCode}
        handleChange={(value: string) =>
          updateStateData('selectedCouponCode', value)
        }
      />
    </div>
  );
};

const mapState = (state: any) => ({
  customerData: state.crmEcommerce.customerData,
  paymentMethod: state.crmEcommerce.paymentMethod,
  shippingMethod: state.crmEcommerce.shippingMethod,
  shippingCost: state.crmEcommerce.shippingCost,
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  paymentMethodList: state.crmEcommerce.paymentMethodList,
  shippingMethodList: state.crmEcommerce.shippingMethodList,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  countryDetails: state.settings.countryDetails,
  woocommerceDiscountCoupons: state.crmEcommerce.woocommerceDiscountCoupons,
  selectedCouponCode: state.crmEcommerce.selectedCouponCode,
});

const mapDispatch = (dispatch: any) => ({
  updateAddress: (key: string, value: string, mainKey: string) =>
    dispatch.crmEcommerce.updateAddress({ key, value, mainKey }),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const DeliveryInformationContainer = connect(
  mapState,
  mapDispatch
)(DeliveryInformation);
export default DeliveryInformationContainer;
