import React from 'react';
import Footer from '../../common/components/Footer';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'Introduction/node/insta-feed-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          {t(
            `Integrating your Instagram Feed with {{${partnerName}}} will allow you to:`
          )}
        </p>
        <ul className='mt-3 px-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            {t(
              `Respond to Instagram page comments from {{${partnerName}}} inbox.`
            )}
          </li>
          <li className='my-2'>
            {t('Manage comments from multiple Instagram accounts.')}
          </li>
          <li className='my-2'>{t('Hide/remove sensitive comments.')}</li>
          <li className='my-2'>
            {t('Add NLP apps to automate repetitive comments.')}
          </li>
          <li className='my-2'>
            {t(
              `Manage messages, comments and e-commerce orders from other platforms in the same inbox.`
            )}
          </li>
        </ul>
      </div>
      <Footer channelName='Instagram Feed' />
    </div>
  );
};
export default Introduction;
