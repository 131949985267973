import { XMarkIcon } from '@heroicons/react/20/solid';
import WhatsappFileUploader from '../../../../../../components/utilityComponent/WhatsappFileUploader';
import React from 'react';
import { connect } from 'react-redux';
import {
  transformTextStyle,
  highlightAttributeFromText,
} from 'pages/inbox/utils/functions';
import { WhatsappTemplate } from 'pages/inbox/inboxInterface';
import Spinner from 'pages/broadcast/component/Spinner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  dynamicButtonVariableName?: string;
  testMessageLoading: boolean;
  setIsOpenWhatsappMessage: (payload: boolean) => void;
  setStep: (payload: number) => void;
  selectedTemplate: WhatsappTemplate;
  variables: string[];
  setAttributeData: (data: any) => void; // there is no actual list of attribute, so its type is any
  attributeData: any; // there is no actual list of attribute, so its type is any
  submitTemplateMessage: () => void;
  setInputValue: (payload: string) => void;
}

const WhatsappTemplateDetailsModal: React.FC<Props> = ({
  dynamicButtonVariableName,
  testMessageLoading,
  setIsOpenWhatsappMessage,
  setStep,
  selectedTemplate,
  variables = [],
  setAttributeData,
  attributeData,
  submitTemplateMessage,
  setInputValue,
}) => {
  const { t } = useTranslation();
  const handleImageDrop = async (file: string) => {
    setAttributeData({ ...attributeData, image: file });
  };

  const handleDocumentDrop = async (file: string) => {
    setAttributeData({ ...attributeData, document: file });
  };

  const handleVideoDrop = async (file: string) => {
    setAttributeData({ ...attributeData, video: file });
  };

  const handleClear = () => {
    setAttributeData({
      ...attributeData,
      image: null,
      video: null,
      document: null,
    });
  };

  const renderTemplateBody = (text: string) => {
    text = transformTextStyle(text);
    const bodyText = highlightAttributeFromText(text);

    return (
      <p
        className={`text-gray-500 font-normal
                    text-[14px] leading-7`}
        dangerouslySetInnerHTML={{ __html: bodyText.join(' ') }}
      />
    );
  };

  const renderTemplateHeader = (text: string, type: string | undefined) => {
    switch (type) {
      case 'text':
        const headerText = highlightAttributeFromText(text);
        const modifiedHeaderText = headerText.map((v) => {
          if (!v.startsWith('<span')) {
            return v;
          }
          const newText = v.replace('id="', 'id="header_1');
          return newText;
        });
        return (
          <p
            className={`text-gray-800 flex font-semibold text-[14px] items-center leading-7 mb-2`}
            dangerouslySetInnerHTML={{ __html: modifiedHeaderText.join(' ') }}
          ></p>
        );
      case 'image':
        return (
          <WhatsappFileUploader
            file={attributeData.image}
            handleFileChange={handleImageDrop}
            handleClear={handleClear}
            templateHeaderType={type}
            fileMimeTypes={['image/jpeg', 'image/png']}
          />
        );
      case 'video':
        return (
          <WhatsappFileUploader
            file={attributeData.video}
            handleFileChange={handleVideoDrop}
            handleClear={handleClear}
            templateHeaderType={type}
            fileMimeTypes={['video/mp4']}
          />
        );
      case 'document':
        return (
          <WhatsappFileUploader
            file={attributeData.document}
            handleFileChange={handleDocumentDrop}
            handleClear={handleClear}
            templateHeaderType={type}
            fileMimeTypes={['application/pdf']}
          />
        );
      default:
        return null;
    }
  };

  const handleDynamicButtonAttribute = (e: any) => {
    if (!!dynamicButtonVariableName) {
      setAttributeData({
        ...attributeData,
        [dynamicButtonVariableName]: e.target.value,
        [e.target.name]: e.target.value,
        // button_attribute: e.target.value,
      });
      handleVariableOnchange(e, dynamicButtonVariableName, false);
    } else {
      setAttributeData({
        ...attributeData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const renderUrlButton = (
    type: string,
    buttonProperty: any,
    index: number
  ) => {
    let isUrlType = type === 'url';
    let hasAtrribute = isUrlType && buttonProperty.value.includes('{{');
    const domainName = isUrlType && buttonProperty.value.split('{{')[0];

    switch (isUrlType && hasAtrribute) {
      case true:
        return (
          <div key={index} className='flex gap-4 mb-3'>
            <div
              className='w-[40%] rounded-md border border-gray-200
                      bg-gray-200 text-gray-700 text-[14px] p-2 h-10'
            >
              {buttonProperty.title}
            </div>
            <div className='w-[60%] flex items-center'>
              <div
                className='w-[40%] flex align-center bg-gray-200 p-2 ltr:rounded-l-md rtl:rounded-r-md border text-sm text-gray-500 leading-5 box-border
                            border-gray-200 h-10'
              >
                <span className='overflow-x-auto overflow-y-hidden custom-text-overflow custom-horizontal-text-overflow whitespace-nowrap'>
                  {domainName}
                </span>
              </div>
              <input
                name={'button_' + buttonProperty.id}
                type='text'
                placeholder='Enter URL path'
                className='w-[60%] ltr:rounded-r-md rtl:rounded-l-md border border-gray-200 text-gray-700 text-[14px] px-2 py-1.5 h-10
                        focus:border-green-500 focus:outline-none focus:ring-green-500 autofill:bg-white'
                onChange={(e) => handleDynamicButtonAttribute(e)}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleVariableOnchange = (
    event: any,
    variable: string,
    setAttribute: boolean = true
  ) => {
    let elements = document.querySelectorAll(`[id^='${variable}']`);
    for (let i = 0; i < elements.length; i++) {
      if (event.target.value === '') {
        // @ts-ignore
        elements[i].innerText = variable;
        if (variable.startsWith('header')) {
          (elements[i] as HTMLSpanElement).innerText = '1';
        }
        // @ts-ignore
        elements[i].style.background = '#FEE2E2';
        // @ts-ignore
        elements[i].style.color = '#B91C1C';
        setAttribute &&
          setAttributeData({
            ...attributeData,
            [event.target.name]: event.target.value,
          });
        continue;
      }
      // @ts-ignore
      elements[i].innerText = event.target.value;
      // @ts-ignore
      elements[i].style.background = '#DCFCE7';
      // @ts-ignore
      elements[i].style.color = '#065F46';
      setAttribute &&
        setAttributeData({
          ...attributeData,
          [event.target.name]: event.target.value,
        });
    }
  };

  const disableSendButton = () => {
    //following check was required for old templates variables.since we have to support both new & old template variable this one don't work with new template
    // if (
    //   hasDynamicUrlButtonType(selectedTemplate) &&
    //   !attributeData.button_attribute
    // ) {
    //   return true;
    // }
    let disableButton = false;
    !!variables &&
      !!variables.length &&
      variables.forEach((variable: string) => {
        if (!attributeData[variable]) {
          disableButton = true;
          return;
        }
      });
    return disableButton;
  };

  const renderTemplateVariables = (item: string, index: number) => {
    return (
      <div className='flex gap-4 mb-3' key={index}>
        <div
          className='w-[40%] rounded-md border border-gray-200
                              bg-gray-200 text-gray-700 text-[14px] p-2 h-10'
        >
          {item}
        </div>
        <input
          type='text'
          className='w-[60%] rounded-md border border-gray-200 text-gray-900 text-[14px] p-2 focus:border-green-500 focus:outline-none focus:ring-green-500 h-10 autofill:bg-white'
          name={item}
          onChange={(e) => {
            handleVariableOnchange(e, item);
          }}
        />
      </div>
    );
  };

  return (
    <div className='w-[550px] p-4'>
      <div className='flex w-full mb-2'>
        <p className='w-[60%] flex text-gray-800 font-semibold text-lg'>
          {t('Set Variable Value')}
        </p>
        <div className='w-[40%] flex justify-end'>
          <XMarkIcon
            className='w-5 h-5 text-gray-500 cursor-pointer'
            onClick={() => {
              setIsOpenWhatsappMessage(false);
              setStep(1);
              setInputValue('');
            }}
          />
        </div>
      </div>
      <div className='overflow-y-auto rounded-md mb-4 max-h-[430px]'>
        <div className=''>
          {renderTemplateHeader(
            selectedTemplate?.header?.value || '',
            selectedTemplate?.header?.type
          )}
          <div className='p-2 mt-2 border border-gray-200 rounded-md'>
            {renderTemplateBody(selectedTemplate?.body || '')}
            <div className='flex flex-col gap-2 mt-2'>
              {selectedTemplate &&
                selectedTemplate?.buttons &&
                selectedTemplate?.buttons?.length > 0 &&
                selectedTemplate?.buttons?.map((item: any, index: number) => {
                  return (
                    <button
                      key={index}
                      className='w-full h-8 py-1 text-xs font-medium text-gray-700 border border-gray-200 rounded-md'
                    >
                      {item.title}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>

        <div className=''>
          {!!variables && variables.length > 0 ? (
            <div className='flex gap-4 mt-4 mb-1'>
              <p className='w-[40%] flex text-gray-500 text-xs font-normal'>
                {t('Template Variables')}
              </p>
              <p className='w-[60%] flex text-gray-500 text-xs font-normal'>
                {t('Values')}
              </p>
            </div>
          ) : null}
          <div className='mb-2'>
            {!!variables &&
              variables
                .filter((item) => !item.startsWith('button'))
                .map((item, index: number) => {
                  return renderTemplateVariables(item, index);
                })}
            {!!selectedTemplate &&
              selectedTemplate?.buttons &&
              selectedTemplate?.buttons?.length > 0 &&
              selectedTemplate?.buttons?.map((item: any, index: number) => {
                return renderUrlButton(item.type, item, index);
              })}
          </div>
        </div>
      </div>
      <button
        className={`p-3 rounded-md w-full ${
          disableSendButton()
            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
            : 'bg-[#04B25F] text-white'
        }`}
        onClick={() => submitTemplateMessage()}
        disabled={disableSendButton() || testMessageLoading}
      >
        {testMessageLoading ? (
          <div className='flex justify-center'>
            <Spinner color='border-r-transparent border-t-white border-b-white border-l-white' />
            <span className='pl-3'>{t('Sending Message')}</span>
          </div>
        ) : (
          t('Send Message')
        )}
      </button>
    </div>
  );
};

const mapState = (state: any) => ({
  testMessageLoading: state.loading.effects.broadcast.sendTestMessage,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(WhatsappTemplateDetailsModal);
