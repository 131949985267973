import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import cloneDeep from 'lodash/cloneDeep';
import { toaster } from 'evergreen-ui';

const initialState = {
  apis: [],
  selectedApi: null,
};

export const api = {
  state: {
    ...initialState,
  },
  reducers: {
    updateAPI(state, payload) {
      /*
      payload = [{
        id: int,
        title: string,
      }...]
      */
      payload.length > 0 &&
        payload.map((api) => {
          api.saveStatus = true;
          // changing headers and params from object to array;
          if (api.headers === {}) {
            api.headers = [];
          } else {
            api.headers = Object.keys(api.headers).map((keys) => ({
              key: keys,
              value: api.headers[keys],
            }));
          }
          if (api.query_params === {}) {
            api.query_params = [];
          } else {
            api.query_params = Object.keys(api.query_params).map((keys) => ({
              key: keys,
              value: api.query_params[keys],
            }));
          }
          api.json_body = JSON.stringify(api.json_body);
          return api;
        });
      return { ...state, apis: payload };
    },

    updateSelectedAPIkey(state, payload) {
      /*
       payload = {
        changeKey: string,
        value: any
      }
      */
      let selectedApiLocal = cloneDeep(state.selectedApi);
      selectedApiLocal[payload.changeKey] = payload.value;
      selectedApiLocal.saveStatus = false;
      return { ...state, selectedApi: selectedApiLocal };
    },

    updateSelectedAPIKeyValuePair(state, payload) {
      /*
     payload = {
     "type":"headers" or "params", //string
     "subtype":"value" or "keys", // string
     "value":"dolor", // keys that will change
     "index":0 //index in array
     }
    */
      let selectedAPILocal = cloneDeep(state.selectedApi);
      selectedAPILocal[payload.type][payload.index][payload.subtype] =
        payload.value;
      selectedAPILocal.saveStatus = false;
      return { ...state, selectedApi: selectedAPILocal };
    },

    deleteSelectedAPIKeyValuePair(state, payload) {
      /*
    payload = {
    "type":"headers" or "params", //string
    "index":0 //index in array
  }
 */
      let selectedAPILocal = cloneDeep(state.selectedApi);
      selectedAPILocal[payload.type].splice(payload.index, 1);
      selectedAPILocal.saveStatus = false;
      return { ...state, selectedApi: selectedAPILocal };
    },

    createSelectedAPIKeyValuePair(state, payload) {
      /*
     payload = {
      type: 'headers" //string
    }
    */
      let selectedAPILocal = cloneDeep(state.selectedApi);
      selectedAPILocal[payload.type].push({ key: '', value: '' });
      selectedAPILocal.saveStatus = false;
      return { ...state, selectedApi: selectedAPILocal };
    },

    createNewAPI(state, payload) {
      /*
      payload = {
        id: int,
        title: string,
      }
      */

      //initial encoder
      payload.headers = [];
      payload.query_params = [];
      payload.json_body = JSON.stringify(payload.json_body);

      if (state.apis.length === 0) {
        return {
          ...state,
          apis: [...state.apis, payload],
          selectedApi: payload,
        };
      }
      return { ...state, apis: [...state.apis, payload] };
    },

    deleteApiFromState(state, apiId) {
      /*
      apiId: int,
      */
      const api = state.apis.filter((s) => s.id !== apiId);

      if (apiId === state.selectedApi.id && api.length > 0) {
        return { ...state, apis: api, selectedApi: api[0] };
      }
      if (api.length === 0) {
        return { ...state, apis: api, selectedApi: null };
      }
      return { ...state, apis: api };
    },

    updateSingleApi(state, payload) {
      /*
      payload = {
        apiId: int,
        data: {
          title: string,
          http_method: string,
          url: string,
          json_body: object,
          query_params: object,
          headers: object,
          return_type: string,
        }
      }
      */

      const api = state.apis.map((s) => {
        if (s.id === payload.apiId) {
          return { ...s, ...payload.data };
        }
        return { ...s };
      });
      return { ...state, apis: api };
    },

    setSelectedAPI(state, apiId) {
      /*
        payload: int
        */
      let api = state.apis.filter((a) => a.id === apiId)[0];

      return { ...state, selectedApi: api };
    },

    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchAPI(projectId, rootState) {
      try {
        const res = await axios.get(config.automation.api(projectId));
        if (res.data.success) {
          const data = res.data.dataSource;
          dispatch.api.updateAPI(data);
          if (data.length > 0) {
            if (rootState.api.selectedApi === null) {
              dispatch.api.setSelectedAPI(data[0].id);
              // if none is selected
            } else {
              dispatch.api.setSelectedAPI(rootState.api.selectedApi.id);
              //for updating the data
            }
          }
        } else {
          dispatch.api.updateAPI([]);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async createAPI(payload) {
      /*
        payload = {
            projectId: int,
            title: string
        }
      */
      try {
        const data = {
          title: payload.title,
          http_method: 'GET',
          url: '',
          json_body: {},
          query_params: {},
          headers: {},
        };
        const res = await axios.post(
          config.automation.api(payload.projectId),
          data
        );
        if (res.data.success) {
          dispatch.api.createNewAPI(res.data.dataSource);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    async editAPI(payload, rootState) {
      /*
      payload = {
        projectId: int,
        apiId: int,
      }
      */
      const data = rootState.api.apis.filter((a) => a.id === payload.apiId)[0];
      //headers convert to object
      if (data.headers.length > 0) {
        data.headers = data.headers.reduce(
          (oldData, currentPair) => ({
            ...oldData,
            [currentPair.key]: currentPair.value,
          }),
          {}
        );
      } else {
        data.headers = {};
      }
      //query params convert to object
      if (data.query_params.length > 0) {
        data.query_params = data.query_params.reduce(
          (oldData, currentPair) => ({
            ...oldData,
            [currentPair.key]: currentPair.value,
          }),
          {}
        );
      } else {
        data.query_params = {};
      }
      //validating body
      data.json_body = JSON.parse(data.json_body);
      try {
        const res = await axios.put(
          config.automation.apiAction(payload.projectId, payload.apiId),
          data
        );
        if (res.data.success) {
          const data = res.data.dataSource;
          dispatch.api.updateSingleApi({
            apiId: data.id,
            data: data,
          });
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    async deleteAPI(payload) {
      /*
      payload = {
        projectId: int,
        apiId: int
      }
      */
      try {
        const res = await axios.delete(
          config.automation.apiAction(payload.projectId, payload.apiId)
        );
        if (res.data.success) {
          dispatch.api.deleteApiFromState(payload.apiId);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async saveAPI(payload, rootState) {
      try {
        let data = cloneDeep(rootState.api.selectedApi);
        //headers convert to object
        if (data.headers.length > 0) {
          data.headers = data.headers.reduce(
            (oldData, currentPair) => ({
              ...oldData,
              [currentPair.key]: currentPair.value,
            }),
            {}
          );
        } else {
          data.headers = {};
        }
        //query params convert to object
        if (data.query_params.length > 0) {
          data.query_params = data.query_params.reduce(
            (oldData, currentPair) => ({
              ...oldData,
              [currentPair.key]: currentPair.value,
            }),
            {}
          );
        } else {
          data.query_params = {};
        }
        //validating body
        data.json_body = JSON.parse(data.json_body);

        const res = await axios.put(
          config.automation.apiAction(
            rootState.dashboard.selectedProject.id,
            rootState.api.selectedApi.id
          ),
          data
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Success', {
            description: `Your API Information has been Saved Successfully`,
            duration: 1,
          });
          dispatch.api.fetchAPI(rootState.dashboard.selectedProject.id);
          return true;
        } else {
          toaster.danger('Failed', {
            description: `Failed to Save API Information`,
            duration: 1,
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: `Failed to Save API Information`,
          duration: 1,
        });
        return false;
      }
    },
  }),
};
