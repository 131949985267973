import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';

interface IProps {
  onClick: () => void;
  isNewField: boolean;
  shouldDisableSubmitButton: () => boolean;
}

const SaveCofirmationDialog: React.FC<IProps> = ({
  onClick,
  shouldDisableSubmitButton,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      disabled={shouldDisableSubmitButton()}
      className='bg-primary-hover ltr:ml-3 rtl:mr-3 text-white hover:bg-primary-hover'
      onClick={onClick}
    >
      {t('Save Changes')}
    </Button>
  );
};

export default SaveCofirmationDialog;
