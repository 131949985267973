import React from 'react';

interface Props {}

const TicketCardLoader: React.FC<Props> = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7].map((index: number) => (
        <div
          key={index}
          className='w-full p-4 mx-auto border-b border-l-2 border-gray-100'
        >
          <div className='flex items-center space-x-4 animate-pulse'>
            <div className='w-10 h-10 bg-gray-200 rounded-full' />
            <div className='flex-1 py-1 space-y-6'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='h-2 col-span-2 bg-gray-200 rounded' />
                <div className='h-2 bg-gray-200 rounded' />
              </div>
              <div className='space-y-3'>
                <div className='h-2 bg-gray-200 rounded' />
                <div className='h-2 bg-gray-200 rounded' />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default TicketCardLoader;
