import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  channelListProps,
  LiveChatDataProps,
  nlpInfoProps,
} from 'pages/integration/interface';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { classNames } from 'utilities/utils';
import Tab from '../common/Tab';
import Preview from '../preview';
import { toaster } from 'evergreen-ui';
import AdvanceOptionPanel from './advance';
import Appearance from './appearance';
import ChannelComponent from './channel';
import ContentPanel from './contentPanel';
import Installation from './installation';
import { DataValidator, ValidatorProps } from './validator';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  channelData: channelListProps;
  integratedNlpList: nlpInfoProps;
  liveChatData: LiveChatDataProps;
  previousLiveChatData: LiveChatDataProps;
  selectedProject: SelectedTeamInterface;

  handleXMarkIconClick: (isUpdateAble: boolean) => void;
  updateBrandData: (key: string, value: any) => void;
  getIntegratedNlpList: (teamId: string | number) => void;
  updateLiveChatProperty: (key: string, value: any) => void;
  handlePreviousDataChange: (liveChatData: LiveChatDataProps) => void;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => boolean;
}

const MainPanel: React.FC<Props> = ({
  handleXMarkIconClick,
  updateBrandData,
  updateLiveChatProperty,
  liveChatData,
  previousLiveChatData,
  selectedProject,
  channelData,
  handleChannelEdit,
  integratedNlpList,
  getIntegratedNlpList,
  handlePreviousDataChange,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const shouldHideDocLink = usePartner().isPartnerRestricted(
    'MainPanel/node/live-chat-doc'
  );
  const [prevewType, setPreviewType] = useState<string>('default');

  useEffect(() => {
    getIntegratedNlpList(selectedProject?.id);
    //eslint-disable-next-line
  }, []);

  const handleStepChange = (selectedStep: number) => {
    if (selectedStep === 2 || selectedStep === 4 || selectedStep === 5) {
      setPreviewType('default');
    }
    setStep(selectedStep);
  };

  const handlePreviewTypeChange = (type: string) => {
    setPreviewType(type);
  };

  const handleOnSave = async () => {
    const validation: ValidatorProps = DataValidator(liveChatData);
    if (validation.hasError) {
      toaster.warning(validation.msg);
    } else {
      let res = await handleChannelEdit(
        selectedProject.id,
        channelData.id,
        { ...liveChatData },
        `${liveChatData.title} successfully updated`
      );
      if (res) {
        handlePreviousDataChange(liveChatData);
      } else {
      }
    }
  };

  const tabItems = [
    {
      id: 1,
      name: 'Appearance',
      content: (
        <div className='pr-4' key={'t-1'}>
          <Appearance
            liveChatData={liveChatData}
            channelId={Number(channelData.id)}
            updateBrandData={updateBrandData}
            updateLiveChatProperty={updateLiveChatProperty}
            onPreviewChange={(type: string) => {
              handlePreviewTypeChange(type);
            }}
          />
        </div>
      ),
    },
    {
      id: 2,
      name: 'Channels',
      content: (
        <div className='pr-1' key={'t-2'}>
          <ChannelComponent
            liveChatData={liveChatData}
            updateLiveChatProperty={updateLiveChatProperty}
          />
        </div>
      ),
    },
    {
      id: 3,
      name: 'Content',
      content: (
        <div className='pr-4' key={'t-3'}>
          <ContentPanel
            liveChatData={liveChatData}
            updateLiveChatProperty={updateLiveChatProperty}
            onPreviewChange={(type: string) => {
              handlePreviewTypeChange(type);
            }}
          />
        </div>
      ),
    },
    {
      id: 4,
      name: 'Advanced',
      content: (
        <div className='pr-[6px]' key={'t-4'}>
          <AdvanceOptionPanel
            liveChatData={liveChatData}
            integratedNlpList={integratedNlpList}
            updateLiveChatProperty={updateLiveChatProperty}
            updateBrandData={updateBrandData}
          />
        </div>
      ),
    },
    {
      id: 5,
      name: 'Installation',
      content: (
        <div className='pr-4' key={'t-5'}>
          <Installation
            platfromData={channelData}
            theme={'dark'}
            showLineNum={false}
            hasCustomStyle={true}
          />
        </div>
      ),
    },
  ];

  const tabContentView = () => {
    return tabItems.find((obj) => obj.id === step)?.content;
  };

  const ref = useRef<HTMLDivElement | null>(null);

  // React.useLayoutEffect(() => {
  //   console.log(ref.current);
  //   console.log(ref.current?.clientHeight);
  //   if (ref.current?.offsetHeight < ref.current.scrollHeight) {
  //     setOverFlown(true);
  //   }
  // }, [ref]);

  const isDataUpdateable = () => {
    return (
      JSON.stringify(liveChatData) !== JSON.stringify(previousLiveChatData)
    );
  };

  return (
    <>
      <div className='w-[1280px]  mx-auto'>
        <div className='bg-white shadow-md overflow-hidden sm:rounded-lg relative'>
          {/* Header */}
          <div className='px-6 py-5 grid grid-flow-row-dense border grid-cols-3 grid-rows-1 border-b-gray-200'>
            <div className='col-span-2'>
              <h3 className='text-lg flex leading-6 font-medium text-gray-700'>
                {t('Customize Live Chat')}
              </h3>
              <p className='mt-1 flex text-sm text-gray-500'>
                {liveChatData.title}
              </p>
            </div>

            <div className='ltr:ml-auto rtl:mr-auto'>
              <button
                className={classNames(
                  isDataUpdateable()
                    ? 'bg-green-400 hover:bg-green-500 text-white'
                    : 'bg-gray-200 text-gray-500',
                  'px-4 py-2 rounded-lg text-sm inline-block  focus:outline-none focus:border-transparent focus:ring-0'
                )}
                disabled={!isDataUpdateable()}
                onClick={() => {
                  handleOnSave();
                }}
              >
                {t('Save Changes')}
              </button>
              <XMarkIcon
                width={20}
                height={20}
                color={'#6B7280'}
                className='inline-block ltr:ml-3 rtl:mr-3 cursor-pointer'
                onClick={() => handleXMarkIconClick(isDataUpdateable())}
              />
            </div>
          </div>

          {/* Body */}
          <div className='pt-2 ltr:pl-6 ltr:pr-0 rtl:pr-6 rtl:pl-0 grid grid-flow-row-dense grid-cols-5 grid-rows-1'>
            <div className='col-span-3 ltr:pl-12 rtl:pr-10 py-2 '>
              <div>
                <Tab
                  currentStep={step}
                  steps={tabItems}
                  handleClickEvent={handleStepChange}
                />
              </div>
            </div>
            <div className='flex justify-start items-center col-span-2'>
              <p className='inline-block px-0 ltr:text-left rtl:text-right font-extrabold text-base leading-6 w-[380px] ml-auto mr-14'>
                {t('Preview')}
              </p>
            </div>
          </div>
          <div className='px-6 pt-2 grid grid-flow-row-dense grid-cols-5 grid-rows-1 '>
            <div
              className='col-span-3 ltr:pl-6 rtl:pr-6 pr-0 pb-6 h-[70vh] max-h-[88vh] flex-initial overflow-y-auto bg-white'
              id='render_div'
            >
              <div className='pl-6 h-auto'>
                <div ref={ref} id='tab-content'>
                  {tabContentView()}
                </div>
                {/* Footer */}
                <div
                  id='live-chat-doc'
                  className={shouldHideDocLink ? 'hidden' : 'mt-6 flex w-full'}
                >
                  <p className='inline-block'>
                    {t('Confused about what to do?')}
                  </p>
                  &nbsp;&nbsp;
                  <a
                    className='text-[#0078CF]'
                    href='https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat'
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {t('Read Documentation')}
                  </a>
                </div>
              </div>
            </div>
            <div className='px-4 mx-4 col-span-2 h-[65vh] relative'>
              <div className='bg-gray-100 rounded-xl h-full overflow-y-auto w-[380px] ltr:ml-auto rtl:mr-auto ltr:mr-4 rtl:ml-2'>
                <div className='h-full'>
                  <Preview previewType={prevewType} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  liveChatData: state.integration.liveChatData,
  selectedProject: state.dashboard.selectedProject,
  integratedNlpList: state.integration.integratedNlpList,
});

const mapDispatch = (dispatch: any) => ({
  updateLiveChatProperty: (key: string, value: any) =>
    dispatch.integration.updateNestedLiveChatData({ key, value }),
  getIntegratedNlpList: (teamId: string | number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  updateBrandData: (key: string, value: any) =>
    dispatch.integration.updateLiveChatBrandData({ key, value }),
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
    }),
});

export default connect(mapState, mapDispatch)(MainPanel);
