import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '../../../../utilities/utils';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  value: AgentTypeProps;
  onChange: (agent: any) => void;
  options: AgentTypeProps[];
  hasAllAgents?: boolean;
}

interface AgentTypeProps {
  id: number | null;
  email: string;
  avatar: string | null;
  full_name?: string;
  [id: string]: any;
}

const AgentListDropdown: React.FC<Prop> = ({
  value,
  onChange,
  options,
  hasAllAgents = true,
}) => {
  const { t } = useTranslation();
  const allAgentData: AgentTypeProps = {
    id: null,
    email: '',
    avatar: null,
    full_name: 'All Agents',
  };
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className='relative w-full sm:w-auto'>
            <div className='inline-flex w-full rounded-md sm:w-auto '>
              <div className='relative z-0 inline-flex w-full divide-gray-300 rounded-md sm:w-auto'>
                <div className='relative inline-flex items-center py-2 ltr:pl-3 rtl:pl-4 ltr:pr-4 rtl:pr-3 border border-gray-300 ltr:rounded-l-md rtl:rounded-r-md'>
                  <p className='ltr:ml-2.5 rtl:mr-2.5 text-sm font-medium text-gray-700'>
                    {t(value.full_name || 'All Agents')}
                  </p>
                </div>
                <Listbox.Button className='relative inline-flex items-center p-2 text-sm font-medium border border-gray-300 ltr:rounded-l-none rtl:rounded-r-none ltr:rounded-r-md rtl:rounded-l-md'>
                  <ChevronDownIcon
                    className='w-5 h-5 text-gray-700'
                    aria-hidden='true'
                  />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {!!hasAllAgents && (
                  <Listbox.Option
                    key={0}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-1.5 pl-2.5 pr-2.5'
                      )
                    }
                    value={allAgentData}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-2 block truncate'
                            )}
                          >
                            {t(allAgentData.full_name || 'All Agents')}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='w-4 h-4' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )}
                {options.map((agent: any, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary' : 'text-gray-900',
                        'cursor-default select-none relative py-1.5 pl-2.5 pr-2.5'
                      )
                    }
                    value={agent}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className='flex items-center'>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ltr:ml-2 rtl:mr-2 block truncate'
                            )}
                          >
                            {agent.full_name}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='w-4 h-4' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default AgentListDropdown;
