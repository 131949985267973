import React from 'react';
import { CardContent } from 'libraryV2/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'libraryV2/ui/chart';
import { Pie, PieChart } from 'recharts';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import EmptyDoughNut from '../../../assets/images/EmptyDoughnut.svg';
import { getSum } from 'utilities/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';

// Define the interface for props
interface ChartData {
  name: string;
  value: any;
}
type Props = {
  data: ChartData[];
  color: string;
  title: string;
  toolTipInfo: string;
  loader: any;
  tooltipWidth?: string;
};

// Define colors for the pie chart
const PIE_COLORS = ['#22C55E', '#FB923C', '#3B82F6', '#A78BFA', '#FACC15'];

// Function to transform data into the required format
const formatChartData = (data: ChartData[]) => {
  return data.map((item: ChartData, index: number) => ({
    browser: item.name.toLowerCase().replace(' ', '-'),
    visitors: item.value,
    fill: PIE_COLORS[index % PIE_COLORS.length],
  }));
};

// Function to generate chart configuration based on the data
const createChartConfig = (data: ChartData[]) => {
  const config: any = {};
  data.forEach((item: ChartData, index: number) => {
    config[item.name.toLowerCase().replace(/ /g, '_')] = {
      label: item.name,
      color: PIE_COLORS[index % PIE_COLORS.length],
    };
  });
  return config;
};

const CustomPieChart: React.FC<Props> = ({
  data,
  color,
  title,
  toolTipInfo,
  loader,
  tooltipWidth = 'w-[400px]',
}) => {
  const { t } = useTranslation();
  const totalValue = React.useMemo(() => getSum(data, 'value'), [data]);

  const formattedData = React.useMemo(() => formatChartData(data), [data]);
  const chartConfig = React.useMemo(() => createChartConfig(data), [data]);

  return (
    <div className='h-full overflow-hidden bg-white shadow sm:rounded-lg border border-[#E4E4E7]'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='flex text-base font-semibold leading-6 text-gray-900'>
          {t(title)}
          {toolTipInfo && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InformationCircleIcon className='w-4 h-4 mt-1 ml-2' />
                </TooltipTrigger>
                <TooltipContent>
                  <div className={`${tooltipWidth} text-center`}>
                    {t(toolTipInfo)}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </h3>
        <div className='mt-1'>
          {!loader && (
            <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 place-items-center'>
              {totalValue > 0 ? (
                <CardContent className='flex-1 p-0 h-full w-full'>
                  <ChartContainer
                    config={chartConfig}
                    className='mx-auto aspect-square h-72 w-full'
                  >
                    <PieChart>
                      <ChartTooltip
                        cursor={false}
                        content={
                          <ChartTooltipContent
                            hideLabel
                            className='bg-white'
                            hideIndicator
                          />
                        }
                      />
                      <Pie
                        data={formattedData}
                        nameKey='browser'
                        cx={140}
                        cy={140}
                        labelLine={false}
                        innerRadius={60}
                        outerRadius={120}
                        fill={color}
                        dataKey='visitors'
                      />
                    </PieChart>
                  </ChartContainer>
                </CardContent>
              ) : (
                <div className='flex flex-col items-center mt-5'>
                  <img src={EmptyDoughNut} alt='empty table' className='w-64' />
                </div>
              )}
              <div className='mt-1 w-44'>
                {data.map((data, index) => (
                  <div
                    key={index}
                    className='flex items-center p-2 mt-1 space-between'
                  >
                    <div className='flex items-center'>
                      <div
                        className='w-5 h-5 rounded-sm'
                        style={{ background: PIE_COLORS[index] }}
                      />
                      <div className='ltr:ml-2 rtl:mr-2 text-sm font-normal font-inter'>
                        {t(data.name)}
                      </div>
                    </div>
                    <div className='text-sm font-semibold font-inter'>
                      {Number.isInteger(data.value)
                        ? data.value
                        : data.value.toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {loader && (
            <div className='flex flex-col items-center mt-5'>
              <img src={EmptyDoughNut} alt='empty table' className='w-64' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomPieChart;
