import { Icon1, Icon2, Icon3 } from '../assets/img/icons/chatHeadDefaultIcons';

interface props {
  id: number;
  classNames?: string;
  color?: string;
}

export const DefaultIcons: React.FC<props> = ({
  id,
  classNames = 'w-6 h-6',
  color = 'white',
}) => {
  if (id === 1) {
    return <Icon1 color={color} classNames={classNames} />;
  } else if (id === 2) {
    return <Icon2 color={color} classNames={classNames} />;
  } else {
    return <Icon3 color={color} classNames={classNames} />;
  }
};
