import {
  IUserFlowTableDataTypes,
  IUserActionFlowTableActionTypes,
} from 'pages/automationWorkflow/interface';
import {
  React,
  dayjs,
  useSelector,
  NewAlertModal,
  PopoverContent,
  PopoverTrigger,
  UtilityContents,
  SwitchComponent,
  EmptyTableCellsIcon,
  EllipsisHorizontalIcon,
  useAutomationDashboard,
  toast,
} from './../../../export';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { Modal } from 'library';
import { Popover } from 'libraryV2/ui/popover';

dayjs.extend(LocalizedFormat);

interface props {
  loader?: boolean;
  tableData?: IUserFlowTableDataTypes[];
  searchTicket?: string;
}

const AutomationListTableData: React.FC<props> = ({
  tableData,
  loader = false,
  searchTicket = '',
}) => {
  const {
    getConnectedEcommerce,
    handleSelectedEcommerce,
    handleViewSelectedAutomationHistories,
    updateWorkflowModalVisibility,
    editSelectedUserWorkflowRename,
    handleSelectedUserWorkflowName,
    handleDeleteWorkflowConfirmation,
    handleUpdatedSelectedUserFlowData,
    handleDeleteSelctedActionWorkflow,
    editSelectedUserWorkflowActivation,
    updateWorkflowPrimitiveDataAfterRename,
    isUpdateSelectedAutomationFlowLoading,
  } = useAutomationDashboard();

  const {
    automationName,
    userActionFlowList,
    selectedUserWorkflowMode,
    selectedUserActionFlowData,
  } = useSelector((state: any) => state.workflowAutomation);

  const handleDisableConfirmRenameSaveButton = React.useCallback(() => {
    const selectedUserActionFlowFilterData = userActionFlowList.filter(
      (item: IUserFlowTableDataTypes) =>
        item?.id === selectedUserActionFlowData?.id
    );
    if (
      selectedUserActionFlowFilterData &&
      selectedUserActionFlowFilterData[0]?.name ===
        selectedUserActionFlowData?.name
    ) {
      return true;
    }
    return false;
  }, [selectedUserActionFlowData, userActionFlowList]);

  const renderEmptyTableView = () => {
    return (
      <div className='flex flex-col items-center justify-center min-w-full p-8 bg-white shadow h-[400px]'>
        <EmptyTableCellsIcon />
        <p className='mt-8 mb-8 text-base font-normal text-gray-500 text-center leading-6'>
          No automation found. <br /> Get started by creating new automation.
        </p>
      </div>
    );
  };

  const renderActionView = (
    flowData: IUserFlowTableDataTypes,
    isDisabled: boolean
  ) => {
    const isStoreConnected = !isNaN(flowData?.trigger_match_unique_ref);
    const utilityList = !isDisabled
      ? UtilityContents.UserActionFlowTableActionData
      : UtilityContents.UserActionFlowTableActionData.filter(
          (data: IUserActionFlowTableActionTypes) =>
            data?.label !==
            UtilityContents?.UserActionFlowTableActionLabels?.EDIT
        );
    return (
      <Popover>
        <PopoverTrigger asChild>
          <EllipsisHorizontalIcon
            className='w-5 h-5 -mr-1 cursor-pointer'
            aria-hidden='true'
          />
        </PopoverTrigger>
        <PopoverContent
          className='shadow-lg bg-white z-30 w-56 divide-y divide-gray-100 rounded-md'
          side='left'
          align='start'
        >
          {utilityList.map(
            (item: IUserActionFlowTableActionTypes, index: number) => {
              return (
                <div
                  key={index}
                  className={`px-4 py-3 hover:bg-gray-50 text-sm text-gray-600 cursor-pointer my-1 ${
                    item?.label === 'Delete' && 'text-red-600'
                  }`}
                  onClick={() => {
                    if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.History
                    ) {
                      if (!isStoreConnected) {
                        toast({
                          title: 'No Store Connected',
                          description: 'No active ecommerce is connected',
                        });
                      } else {
                        handleSelectedEcommerce(flowData);
                        handleViewSelectedAutomationHistories(flowData);
                      }
                    } else if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.EDIT
                    ) {
                      handleUpdatedSelectedUserFlowData(flowData);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.DELETE
                    ) {
                      handleDeleteWorkflowConfirmation(flowData);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.RENAME
                    ) {
                      updateWorkflowPrimitiveDataAfterRename(flowData);
                    }
                  }}
                >
                  {item?.label}
                </div>
              );
            }
          )}
        </PopoverContent>
      </Popover>
    );
  };

  const renderDeleteChildrenView = () => {
    return (
      <div className='text-gray-500 text-sm'>
        You are about to delete “
        <span className='text-gray-600 font-medium'>{automationName}</span>”. Do
        you want to continue?
      </div>
    );
  };

  const renderRenameChildrenView = () => {
    return (
      <div className='text-gray-500 text-sm p-4'>
        <div className='flex justify-between items-center'>
          <label>Name</label>
          <div>
            {selectedUserActionFlowData?.name.length}/
            {UtilityContents.WorkflowAutomationNameLimit}
          </div>
        </div>

        <input
          maxLength={128}
          value={selectedUserActionFlowData?.name}
          className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
          onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
            handleSelectedUserWorkflowName(value.target.value);
          }}
          placeholder='Automation name will be placed here'
        />
        {selectedUserActionFlowData?.name.length >=
          UtilityContents.WorkflowAutomationNameLimit && (
          <p className='text-red-500 text-sm'>
            Name should be less than{' '}
            {UtilityContents.WorkflowAutomationNameLimit} characters.
          </p>
        )}
      </div>
    );
  };

  const headerTitle = [
    'Automations name',
    'Channels name',
    'trigger type',
    'Status',
    'Action',
  ];

  const renderTableView = (ticket: IUserFlowTableDataTypes, index: number) => {
    const isStoreConnected = isNaN(ticket?.trigger_match_unique_ref)
      ? false
      : !!getConnectedEcommerce(Number(ticket?.trigger_match_unique_ref));
    return (
      <tr key={index}>
        <td
          className='px-6 py-4 whitespace-nowrap cursor-pointer'
          onClick={() =>
            isStoreConnected && handleUpdatedSelectedUserFlowData(ticket)
          }
        >
          <p className='text-sm text-gray-900'>{ticket?.name}</p>
          <p className='text-gray-400 text-xs'>
            {dayjs.unix(Number(ticket?.created_at)).format('MMM D, YYYY')}
          </p>
        </td>

        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
          {ticket?.ecommerce_tag_code}
        </td>
        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
          {ticket?.trigger_name}
        </td>

        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
          <SwitchComponent
            isLoading={isUpdateSelectedAutomationFlowLoading}
            isEnabled={ticket?.is_active}
            classNames='ml-auto'
            disabled={
              !isStoreConnected || isUpdateSelectedAutomationFlowLoading
            }
            onChange={(isChecked) =>
              editSelectedUserWorkflowActivation({
                flowId: ticket?.id,
                name: ticket?.name,
                trigger_code: ticket?.trigger_code,
                is_active: isChecked,
                trigger_match_unique_ref: ticket?.trigger_match_unique_ref,
              })
            }
          />
        </td>
        <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
          {renderActionView(ticket, !isStoreConnected)}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className='flex flex-col overflow-x-auto overflow-y-hidden'>
        <div className='-my-2'>
          <div className='inline-block min-w-full py-2 align-middle min-h-[400px]'>
            {tableData?.length !== 0 && (
              <>
                <table className='min-w-full min-h-full border-t border-b divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      {headerTitle.map((head) => (
                        <th
                          scope='col'
                          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
                        >
                          {head.toLocaleUpperCase()}
                        </th>
                      ))}
                    </tr>
                  </thead>{' '}
                  <tbody className='divide-y divide-gray-200'>
                    {!!tableData &&
                      tableData
                        //@ts-ignore
                        .filter(
                          (elem: any) =>
                            !!elem.id &&
                            elem.id
                              .toString()
                              .toLowerCase()
                              .includes(searchTicket.toLowerCase())
                        )
                        .map((ticket: IUserFlowTableDataTypes, index: number) =>
                          renderTableView(ticket, index)
                        )}
                  </tbody>
                </table>
              </>
            )}
            {!loader && tableData?.length === 0 && renderEmptyTableView()}
          </div>
        </div>
      </div>
      {selectedUserWorkflowMode ===
        UtilityContents.UserActionFlowTableActionLabels.DELETE && (
        <NewAlertModal
          isShown={
            selectedUserWorkflowMode ===
            UtilityContents.UserActionFlowTableActionLabels.DELETE
          }
          onClose={() => updateWorkflowModalVisibility(null)}
          confirmButtonTitle='Delete'
          onCancelComplete={() => updateWorkflowModalVisibility(null)}
          onConfirm={handleDeleteSelctedActionWorkflow}
          intent='danger'
          title='Delete Automation?'
          description={renderDeleteChildrenView()}
          shouldRenderDescriptionAsNode={true}
        />
      )}
      {selectedUserWorkflowMode ===
        UtilityContents.UserActionFlowTableActionLabels.RENAME && (
        <Modal
          open={
            selectedUserWorkflowMode ===
            UtilityContents.UserActionFlowTableActionLabels.RENAME
          }
          handleClose={() => updateWorkflowModalVisibility(null)}
          confirmText='Save Changes'
          disableConfirm={handleDisableConfirmRenameSaveButton()}
          handleCancel={() => updateWorkflowModalVisibility(null)}
          handleConfirm={editSelectedUserWorkflowRename}
          children={renderRenameChildrenView()}
          title='Rename Automation?'
          description=''
          width='w-[400px]'
          height='auto'
        />
      )}
    </div>
  );
};

export default AutomationListTableData;
