import { CheckIcon } from '@heroicons/react/20/solid';
import { Button } from 'library';
import React from 'react';
import { classNames, getALLChannelData } from 'utilities/utils';
import { connect } from 'react-redux';
import {
  countryDataInterface,
  categoryType,
  basicPlatformData,
} from './interfaces';

interface Step3Props {
  onPreviousClick: () => void;
  teamData?: any;
  callTeamCreate: (
    playload: any
  ) => Promise<{ status: boolean; data: SelectedTeamInterface }>;
  teamName: string;
  selectedCountry: countryDataInterface | null;
  selectedBusinessUrl: string;
  selectedBusinessType: categoryType | null;
  selectedTimeZone: {
    timezone_alias: string;
    timezone_offset: string;
    timezone_offset_type: string;
  };
  showLoadingModal: () => void;
  showSuccessModal: () => void;
  showUnsuccessModal: () => void;
  saveNewTeamData: (value: SelectedTeamInterface) => void;
  fetchProject: () => void;
}

const ChannelSelect: React.FC<Step3Props> = ({
  onPreviousClick,
  teamData,
  callTeamCreate,
  teamName,
  selectedCountry,
  selectedBusinessUrl,
  selectedBusinessType,
  selectedTimeZone,
  showLoadingModal,
  showSuccessModal,
  showUnsuccessModal,
  saveNewTeamData,
  fetchProject,
}) => {
  const [selectedChannels, setSelectedChannels] = React.useState<
    basicPlatformData[] | []
  >([]);

  const checkIsSelected = (channel: basicPlatformData) =>
    selectedChannels?.filter((c: any) => c.id === channel.id).length > 0;

  const teamCreate = () => {
    const channelNames = selectedChannels
      ? selectedChannels.map((channel: basicPlatformData) => {
          return channel.platform_type;
        })
      : [];
    const data = {
      name: teamName,
      country: selectedCountry?.country,
      country_iso_code: selectedCountry?.country_iso_code,
      currency_name: selectedCountry?.currency_name,
      currency_code: selectedCountry?.currency_code,
      currency_symbol: selectedCountry?.currency_symbol,
      timezone_alias: selectedTimeZone?.timezone_alias,
      timezone_offset_type: selectedTimeZone?.timezone_offset_type,
      timezone_offset: selectedTimeZone?.timezone_offset,
      business_url:
        selectedBusinessUrl && selectedBusinessUrl.includes('https://')
          ? selectedBusinessUrl
          : selectedBusinessUrl
          ? 'https://' + selectedBusinessUrl
          : '',
      business_type: selectedBusinessType?.name,
      onboarding_channels: channelNames,
    };
    showLoadingModal();
    setTimeout(() => {
      callTeamCreate(data).then((res: { status: boolean; data: any }) => {
        if (res.status) {
          showSuccessModal();
          saveNewTeamData(res.data?.dataSource);
          fetchProject();
        } else {
          showUnsuccessModal();
        }
      });
    }, 4000);
  };

  return (
    <div className='flex flex-col justify-center w-full h-screen max-w-lg px-10 py-8 mx-auto bg-white rounded sm:block sm:h-auto'>
      <p className='mb-2 font-sans text-2xl font-bold text-center'>
        Select your preferred channels
      </p>
      <p className='font-sans text-sm text-center text-gray-500'>
        Select at least one channel you prefer
      </p>
      <div className='flex flex-wrap gap-2 mt-6'>
        {getALLChannelData.map(
          (channel, i) =>
            !['whatsapp_messenger'].includes(channel.platform_type) && (
              <div
                role='button'
                onClick={() => {
                  if (checkIsSelected(channel)) {
                    setSelectedChannels(
                      selectedChannels.filter((c: any) => c.id !== channel.id)
                    );
                  } else if (selectedChannels.length < 4) {
                    setSelectedChannels([...selectedChannels, channel]);
                  }
                }}
                className={classNames(
                  'px-4 py-2 flex items-center border border-gray-400 rounded-full font-medium',
                  checkIsSelected(channel)
                    ? 'bg-green-500 text-white border-none cursor-pointer'
                    : selectedChannels.length >= 4
                    ? 'bg-white text-gray-500 cursor-not-allowed'
                    : 'bg-white text-gray-500 cursor-pointer'
                )}
                key={i}
              >
                {checkIsSelected(channel) && (
                  <CheckIcon className='w-4 h-4 mr-2' />
                )}
                {channel.name}
              </div>
            )
        )}
      </div>
      <Button
        onClick={() => teamCreate()}
        isDisabled={selectedChannels.length === 0}
        className='flex justify-center w-full mt-8'
        intent='primary'
      >
        Create New Team
      </Button>
      <Button
        onClick={() => onPreviousClick()}
        className='flex justify-center w-full mt-4'
        intent='default'
      >
        Back
      </Button>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  callTeamCreate: (payload: any) => dispatch.teamManagement.teamCreate(payload),
  fetchProject: () => dispatch.dashboard.fetchProject(),
});

export default connect(mapState, mapDispatch)(ChannelSelect);
