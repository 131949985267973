import Pusher from 'pusher-js';
import React from 'react';
import LeftBar from './components/leftbar/index';
import MiddleBar from './components/middlebar/index';
import RightBar from './components/rightbar/index';

// import { env as Environment } from '../../utilities/config';
import { toaster } from 'evergreen-ui';
import { connect } from 'react-redux';
import { checkPermission } from '../../utilities/utils';
import Ecommerce from './components/ecommerce';
import OrderResponseModal from './components/ecommerce/wooCommerce/OrderResponseModal';
import {
  BotConversationQueryInterface,
  ConversationQueryInterface,
  CustomerInfoPayloadInterface,
  ICustomerCartInfo,
  ICustomerDatalabEntryPayload,
  IEcommerceProductData,
  ITicketQueueData,
  OrderFormInformationType,
  ResponseModalDataInterface,
  SendMessageRequestBodyInterface,
  TicketInterface,
} from './inboxInterface';

import { navigate, useLocation } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import NewAlertModal from 'library/modal/NewAlertModal';
import BulkView from './components/bulkTicketAction';
import ShopifySuccessModal from './components/ecommerce/shopify/SuccessModal';
import useBulkAction from './hooks/useBulkAction';

interface Props {
  permissionList: any;
  showDiscardAlert: boolean;
  conversationLoading: boolean;
  datalabEntryFetchLimit: number;
  datalabEntryFetchOffset: number;
  botConversationLoading: boolean;
  openWooCommerceModal: boolean;
  ticketList: TicketInterface[];
  ticketQueue: ITicketQueueData;
  selectedTicket: TicketInterface;
  customerCart: ICustomerCartInfo;
  selectedProject: SelectedTeamInterface;
  selectedSecondaryQueueType: string;
  customerInfoPayload: CustomerInfoPayloadInterface;
  orderFormInformation: OrderFormInformationType;
  setInboxInitialState: () => void;
  setEcommerceInitialState: () => void;
  fetchDatalabList: (projectId: number) => void;
  fetchAgentGroup: (projectId: number) => void;
  getCustomerInfo: (customerId: number) => void;
  fetchAllTicketTag: (projectId: number) => void;
  fetchSavedReplies: (projectId: number) => void;
  fetchProjectAttributes: (teamId: number) => void;
  updateSateData: (key: string, value: any) => void;
  updateOpenShopifyModal: (payload: boolean) => void;
  fetchAssignableAgentList: (projectId: number) => void;
  updateEcommerceStateData: (key: string, value: any) => void;
  fetchTicketConversation: (query: ConversationQueryInterface) => Promise<void>;
  fetchBotConversation: (query: BotConversationQueryInterface) => Promise<void>;
  getCustomerInformation: (payload: { customer_id: number }) => void;
  updateCart: (customerId: number, products: IEcommerceProductData[]) => void;
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => boolean;
  getCustomerOrder: (payload: { customerId: number }) => void;
  updateSelectedProjectOnInbox: (project: SelectedTeamInterface) => void;
  updateTicketListForRepliedUnrepliedQueue: (
    selectedTicket: TicketInterface
  ) => void;
  updateInboxDatalabStateData: (key: string, value: any) => void;
  clearStatesExceptDatalabList: () => void;
  fetchCustomerDatalabEntries: (
    requestBody: ICustomerDatalabEntryPayload
  ) => void;
  getIntegratedChannelList: (teamId: number) => void;
}

const Inbox: React.FC<Props> = ({
  ticketList,
  ticketQueue,
  customerCart,
  permissionList,
  selectedTicket,
  selectedProject,
  showDiscardAlert,
  customerInfoPayload,
  conversationLoading,
  openWooCommerceModal,
  orderFormInformation,
  botConversationLoading,
  selectedSecondaryQueueType,
  updateCart,
  updateSateData,
  fetchAgentGroup,
  getCustomerInfo,
  getCustomerOrder,
  fetchDatalabList,
  fetchSavedReplies,
  fetchAllTicketTag,
  fetchBotConversation,
  setInboxInitialState,
  datalabEntryFetchLimit,
  datalabEntryFetchOffset,
  updateOpenShopifyModal,
  fetchProjectAttributes,
  getCustomerInformation,
  fetchTicketConversation,
  setEcommerceInitialState,
  updateEcommerceStateData,
  updateInboxDatalabStateData,
  clearStatesExceptDatalabList,
  fetchAssignableAgentList,
  fetchCustomerDatalabEntries,
  updateSelectedProjectOnInbox,
  sendMessegengerChannelMessage,
  getIntegratedChannelList,
  updateTicketListForRepliedUnrepliedQueue,
}) => {
  const [showRightbar, setShowRightbar] = React.useState(false);
  const [wasRightBarOpen, setRightBarOpen] = React.useState<boolean>(false);
  const [pusherConnection, setPusherConnection] = React.useState<any>(null);
  const [openShopifySuccessModal, setOpenShopifySuccessModal] =
    React.useState(false);
  const [shopifyCheckoutLink, setShopifyCheckoutLink] =
    React.useState<string>('');
  const [shopifyOrderName, setShopifyOrderName] = React.useState<string>('');
  const [showAccessDeniedModal, setShowAccessDeniedModal] =
    React.useState<boolean>(false);
  const [showAccessDeniedModalMessage, setShowAccessDeniedModalMessage] =
    React.useState<any>({
      title: '',
      subTite: '',
    });

  const [openResponseModal, setOpenResponseModal] = React.useState(false);
  const initialModalData = {
    title: '',
    description: '',
    success: true,
    firstButtonName: '',
    secondButtonName: '',
    firstBtnCss: '',
    secondBtnCss: '',
    firstBtnAction: () => {},
    secondBtnAction: () => {},
  };
  const [responseModalData, setResponseModalData] =
    React.useState<ResponseModalDataInterface>(initialModalData);
  const [showResponseModalLoader, setShowResponseModalLoader] =
    React.useState(false);
  const [abortController, setAbortController] = React.useState(
    new AbortController()
  );

  const { t } = useTranslation();
  const location = useLocation();

  const sendCheckoutLink = async (checkoutLink: string) => {
    let payload = {
      action: 'direct_message',
      template: null,
      audio: null,
      image: null,
      text: checkoutLink,
    };
    const res = await sendMessegengerChannelMessage(payload);
    if (res) {
      setOpenResponseModal(false);
      setShowRightbar(false);
      updateEcommerceStateData('openWooCommerceModal', false);
      toaster.success(t('The cart link is pasted in your chat box'));
    } else {
      toaster.danger('Something went wrong! Please try again later.');
    }
  };

  const fetchData = async () => {
    let projectId = selectedProject?.id;
    await fetchAgentGroup(projectId);
    await fetchSavedReplies(projectId);
    await fetchAllTicketTag(projectId);
    await fetchProjectAttributes(projectId);
    await fetchAssignableAgentList(projectId);
    if (selectedProject?.subscription_plan?.name !== 'free') {
      await fetchDatalabList(projectId);
    }
  };

  const connectToPusher = () => {
    // if (selectedProject?.id === 262) {
    //   Pusher.logToConsole = true;
    // }
    let pusherKey = !!process.env.REACT_APP_PUSHER_KEY
      ? process.env.REACT_APP_PUSHER_KEY
      : '';
    let clusterKey = !!process.env.REACT_APP_PUSHER_CLUSTER
      ? process.env.REACT_APP_PUSHER_CLUSTER
      : '';

    const pusherConnection = new Pusher(pusherKey, {
      cluster: clusterKey,
      // encrypted: true,
    });
    setPusherConnection(pusherConnection);
  };

  const checkSupervisorPermission = () => {
    let haspermission = checkPermission(
      permissionList,
      'generic:crm_supervisor'
    );
    return haspermission;
  };

  const fetchConversationData = async (ticketId: number) => {
    if (!!selectedTicket && ticketQueue?.type === 'bot') {
      let query = {
        customerId: selectedTicket?.customer_id,
        parameter: 'limit=100',
        abortSignal: abortController.signal,
      };
      await fetchBotConversation(query);
    } else if (!!selectedTicket) {
      let query = {
        ticketId: ticketId,
        parameter: 'limit=100',
        abortSignal: abortController.signal,
      };
      await fetchTicketConversation(query);
    }
  };

  const handleOnticketClick = (ticket: TicketInterface) => {
    updateSateData('selectedTicket', ticket);
    updateOpenShopifyModal(false);
    // Controller to handle multiple get-conversation api calls
    abortController.abort();
    setAbortController(new AbortController());
  };

  React.useEffect(() => {
    fetchConversationData(selectedTicket?.id);
    let customerId = selectedTicket?.customer_id;
    if (!!customerId) {
      const payload = { customer_id: customerId };
      const orderListPayload = { customerId: customerId };
      const customerDatalabEntryPayload = {
        customerId: customerId,
        limit: datalabEntryFetchLimit,
        offset: datalabEntryFetchOffset,
      };

      getCustomerInformation(payload);
      getCustomerOrder(orderListPayload);
      fetchCustomerDatalabEntries(customerDatalabEntryPayload);
    }
    // eslint-disable-next-line
  }, [selectedTicket?.id]);

  React.useEffect(() => {
    setInboxInitialState();
    setEcommerceInitialState();
    updateSelectedProjectOnInbox(selectedProject);
    fetchData();
    getIntegratedChannelList(selectedProject?.id);
    // eslint-disable-next-line
  }, [selectedProject]);

  React.useEffect(() => {
    if (['replied', 'unreplied'].includes(selectedSecondaryQueueType)) {
      updateTicketListForRepliedUnrepliedQueue(selectedTicket);
    }
    if (openWooCommerceModal) {
      setShowRightbar(false);
    }
    updateEcommerceStateData('openWooCommerceModal', false);
    clearStatesExceptDatalabList();
    //eslint-disable-next-line
  }, [selectedTicket?.id]);

  React.useEffect(() => {
    if (!!permissionList && permissionList.length > 0) {
      if (!checkPermission(permissionList, 'read:conversation')) {
        setShowAccessDeniedModalMessage({
          title: 'Team Inbox Permission Denied.',
          subTitle:
            "You don't have the access to this team inbox. Please contact with team administration.",
        });
        setShowAccessDeniedModal(true);
      }
    }
    connectToPusher();
    let queueType = checkSupervisorPermission() ? 'all' : 'self';
    updateSateData('selectedQueueType', queueType);
    updateSateData('ticketIsResolvedStatus', 0);
    updateEcommerceStateData('openWooCommerceModal', false);
    updateEcommerceStateData('openShopifyModal', false);
    updateSateData('ticketQueue', {
      type: queueType,
      isResolved: false,
      privateView: '',
    });
    clearStatesExceptDatalabList();

    return () => {
      if (!!pusherConnection) {
        pusherConnection.disconnect();
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleCloseRightBar = () => {
    setRightBarOpen(showRightbar);
    setShowRightbar(false);
  };

  const handleRightBarAfterEcomClose = () => {
    setShowRightbar(wasRightBarOpen);
  };

  const setAccessDeniedModal = (
    show: boolean,
    title: string,
    subTitle: string
  ) => {
    setShowAccessDeniedModalMessage({ title, subTitle });
    setShowAccessDeniedModal(show);
  };

  const handleSendConfirmationLink = async () => {
    let payload = {
      action: 'direct_message',
      template: null,
      audio: null,
      image: null,
      text: shopifyCheckoutLink,
    };
    const res = await sendMessegengerChannelMessage(payload);
    if (res) {
      setOpenShopifySuccessModal(false);
    }
  };

  const handleDiscardCart = async () => {
    await updateCart(selectedTicket?.customer_id, []);
    await getCustomerInfo(selectedTicket?.customer_id);
  };

  const { isBulkActionActive } = useBulkAction();

  const renderMiddleBar = () => {
    if (!isBulkActionActive) {
      return (
        <MiddleBar
          ticketList={ticketList}
          ticketQueue={ticketQueue}
          showRightbar={showRightbar}
          selectedTicket={selectedTicket}
          pusherConnection={pusherConnection}
          conversationLoading={conversationLoading}
          botConversationLoading={botConversationLoading}
          handleCloseRightBar={handleCloseRightBar}
          handleRightbarAppearence={setShowRightbar}
          hasSupervisorAccess={checkSupervisorPermission()}
        />
      );
    } else {
      return <BulkView />;
    }
  };

  return (
    <div data-testid='inbox-main-index-id' className='h-screen bg-transparent'>
      {!showAccessDeniedModalMessage?.title.includes(
        'Team Inbox Permission Denied.'
      ) &&
        !showAccessDeniedModal && (
          <div className='flex flex-row w-full h-full overflow-hidden bg-white rounded-lg shadow-sm'>
            <div data-testid='leftbar-id' className='w-1/4'>
              <LeftBar
                ticketList={ticketList}
                ticketQueue={ticketQueue}
                selectedTicket={selectedTicket}
                pusherConnection={pusherConnection}
                setShowAccessDeniedModal={setAccessDeniedModal}
                handleOnticketClick={handleOnticketClick}
                hasSupervisorAccess={checkSupervisorPermission()}
              />
            </div>
            <div
              data-testid='middlebar-id'
              className={`flex-1 ltr:border-l rtl:border-r ${
                showRightbar ? 'ltr:border-r rtl:border-l' : ''
              } w-1/2`}
            >
              {renderMiddleBar()}
            </div>
            {!!showRightbar && (
              <div data-testid='rightbar-id' className='w-1/4'>
                <RightBar
                  showRightbar={showRightbar}
                  selectedTicket={selectedTicket}
                  responseModalData={responseModalData}
                  sendCheckoutLink={sendCheckoutLink}
                  setOpenResponseModal={setOpenResponseModal}
                  setResponseModalData={setResponseModalData}
                  setShowResponseModalLoader={setShowResponseModalLoader}
                  handleRightbarAppearence={setShowRightbar}
                  customerInfoPayload={customerInfoPayload}
                  setShopifyCheckoutLink={setShopifyCheckoutLink}
                  setShopifyOrderName={setShopifyOrderName}
                  setOpenShopifySuccessModal={setOpenShopifySuccessModal}
                  setShowRightbar={setShowRightbar}
                />
              </div>
            )}
          </div>
        )}

      <OrderResponseModal
        showResponseModalLoader={showResponseModalLoader}
        responseModalData={responseModalData}
        openResponseModal={openResponseModal}
        setShowResponseModalLoader={setShowResponseModalLoader}
      />

      <Ecommerce
        setShowRightbar={setShowRightbar}
        toggleRightBar={handleRightBarAfterEcomClose}
      />
      <ShopifySuccessModal
        width='w-[344px]'
        height='h-auto'
        isOpen={openShopifySuccessModal}
        setIsOpen={setOpenShopifySuccessModal}
        handleSendConfirmationLink={handleSendConfirmationLink}
        shopifyOrderName={shopifyOrderName}
      />

      <NewAlertModal
        isShown={showDiscardAlert}
        intent='danger'
        title={t('Discard Order?')}
        description={t(
          'You are about to discard a draft order. Do you want to continue?'
        )}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={t('Discard')}
        onToggle={() =>
          updateEcommerceStateData('showDiscardAlert', !showDiscardAlert)
        }
        onConfirm={() => {
          updateEcommerceStateData('showDiscardAlert', false);
          updateEcommerceStateData('openWooCommerceModal', false);
          updateEcommerceStateData('openShopifyModal', false);
          updateEcommerceStateData('openSallaModal', false);
          updateEcommerceStateData('openZidModal', false);
          handleDiscardCart();

          let filteredData = { ...orderFormInformation };
          delete filteredData[customerCart.customer_id];
          updateEcommerceStateData('orderFormInformation', filteredData);
        }}
        onClose={() => {
          updateEcommerceStateData('showDiscardAlert', false);
        }}
      />

      <NewAlertModal
        isShown={showAccessDeniedModal}
        intent='danger'
        title={t(showAccessDeniedModalMessage?.title)}
        description={t(showAccessDeniedModalMessage?.subTitle)}
        shouldShowCancelButton={false}
        confirmButtonTitle={t('Okay')}
        onConfirm={() => {
          setShowAccessDeniedModal(false);
          window.history.replaceState({}, document.title, location.pathname);
          const title = showAccessDeniedModalMessage?.title;
          const includesNoTicketFound = title?.includes('No Ticket Found');
          const includesPermissionDenied = title?.includes(
            'Team Inbox Permission Denied.'
          );

          if (!includesNoTicketFound || includesPermissionDenied) {
            navigate('/dashboard');
          }
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  ticketList: state.inbox.ticketList,
  ticketQueue: state.inbox.ticketQueue,
  customerCart: state.ecommerce.cart,
  selectedTicket: state.inbox.selectedTicket,
  datalabEntryFetchLimit: state.inboxDatalab.limit,
  datalabEntryFetchOffset: state.inboxDatalab.offset,
  selectedProject: state.dashboard.selectedProject,
  showDiscardAlert: state.ecommerce.showDiscardAlert,
  permissionList: state.dashboard.permission?.list || [],
  openWooCommerceModal: state.ecommerce.openWooCommerceModal,
  orderFormInformation: state.ecommerce.orderFormInformation,
  openShopifySuccessModal: state.ecommerce.openShopifySuccessModal,
  selectedSecondaryQueueType: state.inbox.selectedSecondaryQueueType,
  conversationLoading: state.loading.effects.inbox.fetchTicketConversation,
  botConversationLoading: state.loading.effects.inbox.fetchBotConversation,
});

const mapDispatch = (dispatch: any) => ({
  fetchAllTicketTag: (projectId: number) =>
    dispatch.inbox.fetchAllTicketTag(projectId),
  fetchAgentGroup: (projectId: number) =>
    dispatch.inbox.fetchAgentGroup(projectId),
  fetchAssignableAgentList: (projectId: number) =>
    dispatch.inbox.fetchAssignableAgentList(projectId),
  updateSateData: (key: string, value: any) =>
    dispatch.inbox.updateSateData({ key, value }),
  setInboxInitialState: () => dispatch.inbox.clearState(),
  setEcommerceInitialState: () => dispatch.ecommerce.clearState(),
  fetchTicketConversation: (query: ConversationQueryInterface) =>
    dispatch.inbox.fetchTicketConversation(query),
  fetchBotConversation: (query: BotConversationQueryInterface) =>
    dispatch.inbox.fetchBotConversation(query),
  updateCart: (customerId: number, products: IEcommerceProductData[]) =>
    dispatch.ecommerce.updateCart({
      customerId,
      cartProducts: products,
    }),
  getCustomerInfo: (customerId: number) =>
    dispatch.ecommerce.fetchCustomerInfo(customerId),
  getCustomerInformation: (payload: { customer_id: number }) =>
    dispatch.inbox.getCustomerInformation(payload),
  updateOpenShopifyModal: (payload: boolean) =>
    dispatch.ecommerce.updateOpenShopifyModal(payload),
  fetchProjectAttributes: (teamId: number) =>
    dispatch.inbox.fetchProjectAttributes(teamId),
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
  fetchSavedReplies: (projectId: number) =>
    dispatch.inbox.fetchSavedReplies(projectId),
  getCustomerOrder: (payload: { customerId: number }) =>
    dispatch.ecommerce.fetchCustomerOrder(payload),
  updateSelectedProjectOnInbox: (project: SelectedTeamInterface) =>
    dispatch.inbox.updateSelectedProjectOnInbox(project),
  updateTicketListForRepliedUnrepliedQueue: (selectedTicket: TicketInterface) =>
    dispatch.inbox.updateTicketListForRepliedUnrepliedQueue(selectedTicket),
  fetchDatalabList: (projectId: number) =>
    dispatch.inboxDatalab.fetchDatalabList(projectId),
  updateInboxDatalabStateData: (key: string, value: any) =>
    dispatch.inboxDatalab.updateInboxDatalabStateData({ key, value }),
  clearStatesExceptDatalabList: () =>
    dispatch.inboxDatalab.clearStatesExceptDatalabList(),
  fetchCustomerDatalabEntries: (requestBody: ICustomerDatalabEntryPayload) =>
    dispatch.inboxDatalab.fetchCustomerDatalabEntries(requestBody),
  getIntegratedChannelList: (teamId: number) =>
    dispatch.inbox.getIntegratedChannelList(teamId),
});

export default connect(mapState, mapDispatch)(Inbox);
