import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HasPermission } from '../../../../components';
import PaymentMethod from '../component/billing/PaymentMethod';
import { checkPermission } from '../../../../utilities/utils';
import PlanSelection from '../component/billing/PlanSelection';
import BillingHistory from '../component/billing/BillingHistory';
import PlanSelectionTemporary from "../component/billing/PlanSelectionTemporary";

class BillingPlanSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readAccess: 'read:settings',
      writeAccess: 'write:settings',
    };
  }

  async componentDidMount() {
    await this.props.fetchUserInfo();
    await this.props.fetchPlans();
    await this.props.fetchHistories(this.props.selectedProject.id);
    await this.props.fetchMarketPlaceData(this.props.selectedProject.id);
  }

  handleSubscriptionUpdate = async () => {
    await this.props.fetchProject();
    const updatedProject = this.props.projects.find(
      (project) => this.props.selectedProject.id === project.id
    );

    this.props.updateSelectedProject(updatedProject);
  };

  render() {
    const cardInfo = this.props.userInfo ? this.props.userInfo.card_info : null;
    const hidePaymentDetails =
        this.props.marketPlaceData?.ecommerce_type === 'shopify';
      // this.props.selectedProject?.subscription_plan?.plan_type === 'appsumo';
    // this.props.marketPlaceData?.ecommerce_type === 'shopify' &&
    // !!cardInfo &&
    // cardInfo.length === 0
    const selectedProject = this.props.selectedProject;
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );

    return (
      <HasPermission shortCode={this.state.readAccess} isSettingsView>
        <main className='pb-10 mx-auto max-w-7xl lg:py-12 lg:px-8'>
          <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
            {!!selectedProject?.subscription_plan &&
            selectedProject.subscription_plan.plan_type !== 'appsumo' &&
            selectedProject.subscription_plan.plan_type !== 'enterprise' &&
            (
              // TODO: Temporarily hide old pricing plans for free and starter, thus used PlanSelectionTemporary instead of PlanSelection
              <PlanSelectionTemporary
                plans={this.props.plans}
                currentPlan={selectedProject.subscription_plan}
                handleCoupon={this.props.handleCouponCode}
                createSubscription={this.props.createSubscription}
                createSubscriptionForShopify={
                  this.props.createSubscriptionForShopify
                }
                handleSubscriptionUpdate={this.handleSubscriptionUpdate}
                selectedProject={selectedProject}
                trialRemaining={this.props.userInfo?.trial_remaining}
                hasCard={cardInfo ? cardInfo.length > 0 : false}
                biilingInterval={this.props.biilingInterval}
                hasCoupon={!hidePaymentDetails}
                fetchDetails={async () => {
                  await this.props.setSelectedProject(
                    this.props.selectedProject
                  );
                }}
                currentMarketPlace={this.props.marketPlaceData?.ecommerce_type}
              />
            )}
            {!!selectedProject?.subscription_plan &&
            (selectedProject.subscription_plan.plan_type === 'appsumo' ||
            selectedProject.subscription_plan.plan_type === 'enterprise') &&
            (
                <PlanSelection
                    plans={this.props.plans}
                    currentPlan={selectedProject.subscription_plan}
                    handleCoupon={this.props.handleCouponCode}
                    createSubscription={this.props.createSubscription}
                    createSubscriptionForShopify={
                      this.props.createSubscriptionForShopify
                    }
                    handleSubscriptionUpdate={this.handleSubscriptionUpdate}
                    selectedProject={selectedProject}
                    trialRemaining={this.props.userInfo?.trial_remaining}
                    hasCard={cardInfo ? cardInfo.length > 0 : false}
                    biilingInterval={this.props.biilingInterval}
                    hasCoupon={!hidePaymentDetails}
                    fetchDetails={async () => {
                      await this.props.setSelectedProject(
                          this.props.selectedProject
                      );
                    }}
                    currentMarketPlace={this.props.marketPlaceData?.ecommerce_type}
                />
            )}
            {selectedProject.subscription_plan?.plan_type !== 'enterprise' &&
              !hidePaymentDetails && (
                <section
                  aria-labelledby='payment-details-heading'
                  id='paymentMethod'
                >
                  <div className='shadow sm:rounded-md sm:overflow-hidden'>
                    <div className='px-4 py-6 bg-white sm:p-6'>
                      <div>
                        <h2
                          id='payment-details-heading'
                          className='text-lg font-medium leading-6 text-gray-900'
                        >
                          Payment details
                        </h2>
                        <p className='mt-1 text-sm text-gray-500'>
                          Your card information is safe with us. For maximum
                          security, we use Stripe.
                        </p>
                      </div>

                      <div className='mt-6'>
                        {this.props.userInfo && (
                          <PaymentMethod
                            handlePaymentMethod={this.props.createPaymentMethod}
                            handleCardAction={this.props.manageCard}
                            userEmail={this.props.userInfo.email}
                            hasWriteAccess={hasWriteAccess}
                            projectId={this.props.selectedProject.id}
                            cardInfo={cardInfo}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              )}
            <BillingHistory histories={this.props.histories} />
          </div>
        </main>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  histories: state.billing.histories,
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  plans: state.billing.plans,
  userInfo: state.settings.userInfo,
  email: state.auth.email,
  permissionList: state.dashboard.permission.list || [],
  biilingInterval: state.dashboard.selectedProject?.subscription_plan?.interval,
  marketPlaceData: state.marketplace.marketPlaceData,
});

const mapDispatch = (dispatch) => ({
  fetchHistories: (projectId) => dispatch.billing.fetchHistories(projectId),
  fetchTeamDetails: (teamId) => dispatch.dashboard.fetchTeamDetails(teamId),
  requestRefund: (bill_id, reason) =>
    dispatch.billing.requestRefund({ bill_id, reason }),
  fetchPlans: () => dispatch.billing.fetchPlans(),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  updateSelectedProject: (payload) =>
    dispatch.dashboard.updateSelectedProject(payload),
  fetchUserInfo: () => dispatch.settings.fetchUserInfo(),
  createSubscription: (payload) => dispatch.billing.createSubscription(payload),
  createSubscriptionForShopify: (payload) =>
    dispatch.billing.createSubscriptionForShopify(payload),
  createPaymentMethod: (payload) =>
    dispatch.billing.createPaymentMethod(payload),
  manageCard: (payload) => dispatch.billing.manageCard(payload),
  handleCouponCode: (coupon) => dispatch.billing.handleCouponCode(coupon),
  setSelectedProject: (team) => dispatch.dashboard.setSelectedProject(team),
  fetchMarketPlaceData: (teamId) =>
    dispatch.marketplace.fetchMarketPlaceData(teamId),
});

const BillingPlanSettingsContainer = connect(
  mapState,
  mapDispatch
)(BillingPlanSettings);

export default BillingPlanSettingsContainer;
