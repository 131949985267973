import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { Button } from 'libraryV2/ui/button';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { cn } from 'libraryV2/utils';
import useWindowResize from 'components/customHooks/useWindowResize';

interface FlatOption {
  label: string;
  value: any;
}

interface GroupedOption {
  label: string;
  options: FlatOption[];
}

type Option = FlatOption | GroupedOption;

interface Props {
  value: any | null;
  onChange: (value: any | null) => void;
  placeholder?: string;
  options: Option[];
  withSearch?: boolean;
}

function SelectDropdown({
  value,
  onChange,
  options,
  placeholder,
  withSearch = true,
}: Props) {
  const { width } = useWindowResize();
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSelect = (selectedValue: any) => {
    onChange(selectedValue === value ? null : selectedValue);
    setOpen(false);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  const displayValue = value
    ? value.label
    : `Select ${placeholder?.split(' ').slice(1).join(' ')}` || 'Select...';

  const renderOptions = (option: Option) => {
    if ('options' in option) {
      return (
        <React.Fragment key={option.label}>
          <CommandItem disabled className='pl-4 text-gray-400 font-semibold'>
            {option.label}
          </CommandItem>
          {option.options.map((subOption) => (
            <CommandItem
              key={subOption.value}
              value={subOption.value || subOption.label}
              onSelect={() => handleSelect(subOption)}
              className='hover:bg-gray-100'
            >
              <Check
                className={cn(
                  'mr-2 h-4 w-4',
                  value && value.value === subOption.value
                    ? 'opacity-100'
                    : 'opacity-0'
                )}
              />
              {subOption.label}
            </CommandItem>
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <CommandItem
          key={option.value}
          value={option.value || option.label}
          onSelect={() => handleSelect(option)}
          className='hover:bg-gray-100 font-normal'
        >
          <Check
            className={cn(
              'mr-2 h-4 w-4',
              value && value.value === option.value
                ? 'opacity-100'
                : 'opacity-0'
            )}
          />
          {option.label}
        </CommandItem>
      );
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className='w-[380px]'>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={`w-full justify-between font-normal ${
            !value && 'text-gray-400'
          } ${open && 'border-primary'} `}
        >
          {displayValue && width < 768 && displayValue.length > 10
            ? displayValue.slice(0, 10) + '...'
            : displayValue || 'Search...'}
          {/* {displayValue} */}
          <ChevronsUpDown className='ltr:ml-2 rtl:mr-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-0 bg-white w-[380px]'>
        <Command>
          {withSearch && (
            <CommandInput
              placeholder={placeholder || 'Search...'}
              value={searchTerm}
              className='focus:border-none focus:ring-0 focus:outline-none'
              onValueChange={handleSearchChange}
            />
          )}
          <CommandList>
            {options.length === 0 ? (
              <CommandEmpty>No options found.</CommandEmpty>
            ) : (
              options.map(renderOptions)
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default SelectDropdown;
