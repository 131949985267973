import React, { useState } from 'react';
import {
  FieldCommonConfigComp,
  useActions,
  useTriggerActions,
  IDataLabField,
  platinum_color,
  TRIGGER_PRIMARY_COLOR,
} from 'pages/datalab/export';
import { isEqual } from 'lodash';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const Paragraph: React.FC<IProps> = (props) => {
  const { field, isDragging, isSmall, nodeRef } = props;
  const { id: fieldId } = field;
  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();

  const { updateDataLabField, deleteDataLabField, updateUnsaveTracker } =
    useTriggerActions();

  const [fieldProperties, setFieldProperties] = useState(field);
  const selectedFieldId = getSelectedFieldId();

  const handleOnUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFieldProperties({ ...fieldProperties, [name]: value });
  };

  const shouldDisableSubmitButton = () => {
    return !(
      fieldProperties?.name ||
      fieldProperties?.label_agent ||
      fieldProperties?.help_text ||
      fieldProperties?.placeholder ||
      fieldProperties?.min_length ||
      fieldProperties?.max_length
    );
  };

  const handleOnCheckboxChange = (name: string) => {
    setFieldProperties({
      ...fieldProperties,
      [name]: !fieldProperties[name],
    });
  };

  const handleOnSave = () => {
    const { labId } = getController();
    updateDataLabField(labId, fieldId, fieldProperties);
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = TRIGGER_PRIMARY_COLOR;
  }

  const handleOnDiscard = () => {
    setFieldProperties(field);
    setSelectedFieldId(null);
  };

  const checkFieldIsDirty = () => {
    const isDirty = !isEqual(fieldProperties, field);

    const updatedField = isDirty ? fieldProperties : {}; // if field is not dirty, then return empty object

    updateUnsaveTracker({ isDirty, field: updatedField });
  };

  return (
    <FieldCommonConfigComp
      selectedFieldId={selectedFieldId}
      fieldProperties={fieldProperties}
      onFieldUpdate={handleOnUpdate}
      onCheckboxChange={handleOnCheckboxChange}
      onSave={handleOnSave}
      onRemove={handleOnRemove}
      onBlockFieldClick={handleOnBlockFieldClick}
      onDiscard={handleOnDiscard}
      accordionTriggerStyle={accordionTriggerStyle}
      isDragging={isDragging}
      isSmall={isSmall}
      nodeRef={nodeRef}
      checkFieldIsDirty={checkFieldIsDirty}
      shouldDisableSubmitButton={shouldDisableSubmitButton}
    />
  );
};

export default Paragraph;
