import React, { useMemo } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const useOptions = (disabled: any) => {
  const options = useMemo(
    () => ({
      disabled: disabled,
      hidePostalCode: true,
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [disabled]
  );
  return options;
};

const CardSection = ({ disabled }: any) => {
  const options = useOptions(disabled);
  return (
    <div className='billing-card'>
      <CardElement options={options} />
    </div>
  );
};

export default CardSection;
