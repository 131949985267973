import React, { Fragment } from 'react';
import { Combobox } from 'evergreen-ui';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: { id: number; title: string };
  handleChange: (value: number) => void;
  options: any[];
  title: string;
  tooltip: string;
}

const DropdownCombobox: React.FC<props> = ({
  value,
  handleChange,
  options,
  title,
  tooltip,
}) => {
  return (
    <Fragment>
      <TitleHolderBuilder title={title} tooltipInfo={tooltip} />
      <Combobox
        openOnFocus
        initialSelectedItem={value}
        items={options}
        height={40}
        style={{ width: '100%' }}
        className='custom-input-style'
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleChange(selected)}
        placeholder='Select Sequence'
      />
    </Fragment>
  );
};

export default DropdownCombobox;
