import { convertTo12HourTime } from 'pages/datalab/utils';
import {
  ChangeEvent,
  Clock,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Period,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  convert12HourTo24Hour,
  useFormContext,
  useState,
} from '../../../export';

export function TimeField({
  data,
  formActionType,
  valuePath,
}: IFormFieldComponent) {
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();
  const isRequired = data.is_required;
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const currentFieldValue = form.getValues(currentFieldPath);
  const formatted12hoursValue = convertTo12HourTime(currentFieldValue);

  const [hour, setHour] = useState<string>(formatted12hoursValue.hours + '');

  const [minutes, setMinutes] = useState<string>(
    formatted12hoursValue.minutes + ''
  );
  const [timePeriod, setTimePeriod] = useState<string>(
    formatted12hoursValue.period
  );

  const triggerTimeChange = (
    hour: string,
    minute: number | string,
    period: string
  ) => {
    const timeIn24 = `${convert12HourTo24Hour(
      parseInt((hour as string) || '01'),
      period.toUpperCase() as Period
    )}:${minute || minutes}`;
    form.setValue(currentFieldPath, timeIn24);
  };

  const handleHourInput = (ev: ChangeEvent<HTMLInputElement>) => {
    let value: number | string = parseInt(ev.currentTarget.value);
    if (ev.currentTarget.value === '') {
      value = '';
    } else if (value > 12 || value < 0) {
      return;
    }
    setHour(value + '');
    triggerTimeChange(value + '', '', timePeriod);
    form.trigger(currentFieldPath, { shouldFocus: true });
  };

  const handleMinutesInput = (ev: ChangeEvent<HTMLInputElement>) => {
    let value: number | string = parseInt(ev.currentTarget.value);
    if (ev.currentTarget.value === '') {
      value = '';
    } else if (value > 59 || value < 0) {
      return;
    }
    setMinutes(value + '');
    triggerTimeChange(hour + '', value, timePeriod);
    form.trigger(currentFieldPath, { shouldFocus: true });
  };

  const renderSelectTimePeriod = () => {
    return (
      <Select
        disabled={shouldDisableInput}
        defaultValue={timePeriod}
        onValueChange={(value: string) => {
          setTimePeriod(value);
          triggerTimeChange(hour, '', value);
          form.trigger(currentFieldPath, { shouldFocus: true });
        }}
      >
        <SelectTrigger className='w-fit p-0 focus:ring-0 border-none h-fit'>
          <SelectValue />
        </SelectTrigger>
        <SelectContent className='p-0 bg-white'>
          <SelectGroup className='p-0'>
            <SelectItem value='am'>AM</SelectItem>
            <SelectItem value='pm'>PM</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  };

  const renderTimeField = () => (
    <FormField
      control={form.control}
      name={currentFieldPath}
      disabled={data?.is_readonly}
      // rules={getHookFormRules(data)}
      rules={{
        required: {
          value: data.is_required,
          message: 'This Field is required!',
        },
      }}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || data.name}
            {isRequired && <span className='text-destructive'>*</span>}
          </FormLabel>
          <FormControl>
            <div
              ref={field.ref}
              className='justify-between aria-[invalid="true"]:border-red-500 focus:ring-ring flex border rounded-md items-center px-4 text-sm'
            >
              <div className='flex gap-1 items-center'>
                <input
                  id='time-hour'
                  disabled={shouldDisableInput}
                  type='number'
                  className='border-none p-1 text-sm focus:ring-0 focus:outline-none focus-visible:border-none text-center max-w-5'
                  min={1}
                  value={hour}
                  onBlur={(e) => {
                    e.preventDefault();
                    setHour(hour.toString().padStart(2, '0'));
                    return;
                  }}
                  onChange={(e) => {
                    handleHourInput(e);
                  }}
                  max={12}
                />
                <span>:</span>
                <input
                  id={'time-minutes'}
                  type='number'
                  disabled={shouldDisableInput}
                  className='border-none text-sm p-0 focus:ring-0 focus:outline-none overflow-hidden text-center max-w-5'
                  min={0}
                  max={59}
                  value={minutes}
                  onBlur={(e) => {
                    e.preventDefault();
                    setMinutes(minutes.toString().padStart(2, '0'));
                    return;
                  }}
                  onChange={handleMinutesInput}
                />
                {renderSelectTimePeriod()}
              </div>

              <Clock size={16} className='text-muted-foreground' />
            </div>
          </FormControl>
          <FormDescription className='text-zinc-500 text-sm'>
            {data.help_text || ''}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  const renderTimeFieldView = () => {
    const { hours, minutes, period } = formatted12hoursValue;
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'>
          {data?.label_agent || data.name}
        </p>
        <p className='text-zinc-900 text-sm'>
          {formatted12hoursValue.period ? (
            <div>
              {hours}:{minutes} {period.toUpperCase()}
            </div>
          ) : (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };
  return shouldDisableInput ? renderTimeFieldView() : renderTimeField();
}
export default TimeField;
