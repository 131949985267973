import React from 'react';
import { connect } from 'react-redux';
import { DateRange } from 'react-date-range';
import { toaster } from 'evergreen-ui';
import moment from 'moment';
import {
  CalendarIcon,
  PlusSmallIcon,
  XMarkIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import HolidayModal from './HolidayModal';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

const HolidaySettings: React.FC<holidayProp> = ({
  selectedProject,
  fetchHolidayList,
  updateHolidayList,
  holidayData,
}) => {
  const { t } = useTranslation();
  const [isAddClicked, setIsAddClicked] = React.useState(false);
  const [isClickedOnDate, setIsClickedOnDate] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  const [isValuesFilled, setIsValuesFilled] = React.useState({
    titleFilled: false,
    dateFilled: false,
  });

  const ref: any = React.useRef();
  const [holidayAndDayoffList, setHolidayAndDayoffList] = React.useState<
    holidayItemProp[] | []
  >(holidayData || []);
  const IdGenerate = () => {
    return '_' + Math.random().toString(36).slice(2, 9);
  };

  const appendHoliday = async () => {
    let appendData = {
      id: IdGenerate(),
      start_date: moment(state[0].startDate).format('YYYY-MM-DD'),
      end_date: moment(state[0].endDate).format('YYYY-MM-DD'),
      title: title,
    };

    const dataList = [...holidayAndDayoffList, appendData];
    setHolidayAndDayoffList(dataList);
    const data = {
      vacation_data: dataList,
    };
    const payload = {
      teamId: selectedProject.id,
      data: data,
    };

    const response = await updateHolidayList(payload);
    if (response) {
      toaster.success(t(`Holiday Set Successfully!!`));
    } else {
      toaster.danger(t(`Something Went Wrong!!`));
    }
  };

  const renderDateRangedropdown = () => {
    return (
      <div className='relative'>
        <div
          id='calender'
          className='absolute rtl:text-right bg-white border rounded-md drop-shadow-md'
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            rangeColors={['#04B25F', '#038E4C']}
            months={1}
            ranges={state}
            direction='horizontal'
            className='rounded-md w-full rtl:text-right'
          />
        </div>
      </div>
    );
  };
  const renderHolidayModal = () => {
    return (
      <HolidayModal
        setShowHolidayCalender={setIsClickedOnDate}
        setDateFilledData={setIsValuesFilled}
        isValuesFilled={isValuesFilled}
      >
        <>
          <div className='flex justify-end p-2'>
            <XMarkIcon
              onClick={() => {
                setIsAddClicked(false);
                setIsValuesFilled({ dateFilled: false, titleFilled: false });
                setIsClickedOnDate(false);
              }}
              className='w-5 h-5 text-gray-500 cursor-pointer'
              aria-hidden='true'
            />
          </div>

          <div className='bg-white pb-[32px] pl-[32px] pr-[32px] pt-2 rounded-md flex flex-col justify-center align-center'>
            <label className='font-bold text-lg'>{t('Add Off Days')}</label>
            <p className='px-7 text-sm text-gray-500 font-normal'>
              {t('You can select a single date or a range')}
            </p>
          </div>
          <div className='flex flex-col justify-start p-4'>
            <label className='mb-1 rtl:text-right'>{t('Title')}</label>
            <input
              type='text'
              placeholder={t('e.g. Christmas')}
              onChange={(e) => {
                setTitle(e.target.value);
                if (title.length > 0) {
                  setIsValuesFilled({
                    ...isValuesFilled,
                    titleFilled: true,
                  });
                }
              }}
              className='mb-4 border border-gray-200 rounded-md text text-md focus:border-primary focus:ring-1 focus:ring-primary'
            />
            <label className='mb-1 rtl:text-right'>{t('Date')}</label>
            <div
              onClick={() => {
                setIsClickedOnDate(true);
              }}
              className='bg-white p-2 mb-4 border border-gray-200 rounded-md text text-base text-gray-500 focus:border-primary focus:ring-1 focus:ring-primary rtl:text-right'
            >
              {isValuesFilled.dateFilled ? (
                state[0].startDate !== state[0].endDate ? (
                  <div className='focus:border-primary focus:ring-1 focus:ring-primary'>
                    {moment(state[0].startDate).format('LL')} -{' '}
                    {moment(state[0].endDate).format('LL')}{' '}
                  </div>
                ) : (
                  <>{moment(state[0].startDate).format('LL')}</>
                )
              ) : (
                t('Select Date')
              )}
            </div>
            <div>{isClickedOnDate && renderDateRangedropdown()}</div>

            <button
              onClick={() => {
                setIsAddClicked(false);
                setIsValuesFilled({ dateFilled: false, titleFilled: false });
                appendHoliday();
              }}
              className={classNames(
                isValuesFilled.dateFilled && isValuesFilled.titleFilled
                  ? 'bg-primary text text-white'
                  : 'bg-gray-200 text text-gray-500',
                'rounded-md mb-4 py-2'
              )}
              disabled={
                !(isValuesFilled.dateFilled && isValuesFilled.titleFilled)
              }
            >
              {t('Save Changes')}
            </button>
          </div>
        </>
      </HolidayModal>
    );
  };

  const handleHolidayDeletion = async (id: string) => {
    let updatedList = holidayAndDayoffList.filter((item) => item.id !== id);
    setHolidayAndDayoffList(updatedList);
    const data = {
      vacation_data: updatedList,
    };
    const payload = {
      teamId: selectedProject.id,
      data: data,
    };

    const response = await updateHolidayList(payload);
    if (response) {
      toaster.success(t(`Holiday Deleted Successfully!!`));
    } else {
      toaster.danger(t(`Something Went Wrong!!`));
    }
  };

  const renderHolidayList = () => {
    return (
      <div className='w-full flex flex-col gap-2'>
        {holidayAndDayoffList.length > 0 &&
          holidayAndDayoffList.map((holiday) => {
            return (
              <div className='flex gap-2 w-1/2 p-4 bg-gray-50 w-full border border-gray-200 rounded-md '>
                <div className='flex w-3/4'>
                  <div className='flex justify-start flex-col gap-2'>
                    <label className='text text-sm text-gray-600 font-semibold '>
                      {t(holiday.title)}
                    </label>
                    <div className='flex flex-row w-full'>
                      <CalendarIcon
                        className='text-gray-400 mt-1 ltr:mr-2 rtl:ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150'
                        aria-hidden='true'
                      />
                      <p className='text text-sm text-left text-gray-500'>
                        {holiday.end_date !== holiday.start_date ? (
                          <>
                            {
                              moment(holiday.start_date)
                                .format('LL')
                                .split(',')[0]
                            }{' '}
                            - {moment(holiday.end_date).format('LL')}
                          </>
                        ) : (
                          <>{moment(holiday.start_date).format('LL')}</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='flex justify-end w-1/4 pt-5'>
                  <TrashIcon
                    className='w-5 h-5 text text-red-500 cursor-pointer'
                    aria-hidden='true'
                    onClick={() => {
                      handleHolidayDeletion(holiday.id);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  React.useEffect(() => {
    fetchHolidayList(selectedProject.id);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setHolidayAndDayoffList(holidayData);
    // eslint-disable-next-line
  }, [holidayData]);

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isClickedOnDate && ref.current && !ref.current.contains(e.target)) {
        if (e.target.id !== 'calender') {
          setIsClickedOnDate(false);
        }
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  return (
    <div className='flex justify-start flex-col gap-4 mt-6'>
      <div className='flex justify-start w-full'>
        <label className='text-base ml-1'>
          {t('Holiday and Day Off')}
          <p className='text-sm text-gray-500 font-normal'>
            {t(
              'Set up the dates your business is closed for public holidays any other day off. Your customers will receive the offline message when they want to chat.'
            )}
          </p>
        </label>
      </div>
      <div className='flex w-full'>{renderHolidayList()}</div>
      <div className='flex justify-start ml-1'>
        <button
          type='button'
          className='p-1 border border-grey-500 rounded-md text-sm'
          onClick={() => {
            setIsAddClicked(true);
          }}
        >
          <div className='flex flex-row justify-start'>
            {' '}
            <PlusSmallIcon
              className='w-5 h-5 text-gray-500'
              aria-hidden='true'
            />
            <div>{t('Add off days')}</div>
          </div>
        </button>
      </div>

      <div>{isAddClicked && renderHolidayModal()}</div>
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  businessHour: state.businesshour.businessHourTotalData,
  holidayData: state.holiday.holidayData,
});

const mapDispatch = (dispatch: any) => ({
  fetchBusinessHours: (teamId: number) =>
    dispatch.businesshour.fetchBusinessHours(teamId),
  fetchHolidayList: (teamID: number) =>
    dispatch.holiday.fetchHolidayList(teamID),
  updateHolidayList: (payload: holidayProp) =>
    dispatch.holiday.updateHolidayList(payload),
});
const HolidaySettingsContainer = connect(
  mapState,
  mapDispatch
)(HolidaySettings);
export default HolidaySettingsContainer;
