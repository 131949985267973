import React from 'react';
import { Spinner } from 'evergreen-ui';
import NoTicketImage from '../assets/images/meditation.svg';
import { FeedPost, TextMessage } from 'inconnect-chat-ui';
import moment from 'moment';
import {
  processFeedData,
  procedReplyData,
  processFeedPostData,
} from '../../../../utilities/utils';
import defaultAvatar from '../../../../assets/images/defaultAvatar.svg';

interface props {
  lineCount: number;
  ticketsAvailable: boolean;
  chatData: any;
  loading: boolean;
  updateFeedAction: (
    action: string,
    commentId: string,
    type: string,
    text?: string
  ) => boolean;
}

const CRMFeedPost: React.FC<props> = ({
  lineCount,
  ticketsAvailable,
  chatData,
  loading,
  updateFeedAction,
}) => {
  return (
    <div
      className={
        chatData?.platform_info?.type === 'facebook_feed'
          ? 'feed-container  customer-chat__container'
          : 'customer-chat__container'
      }
      style={{
        // previously it was `calc( 100vh - ${270 + (lineCount - 1) * 16}px )`
        height: `calc( 100vh - ${206 + (lineCount - 1) * 16}px )`,
      }}
    >
      {!ticketsAvailable && (
        <div className='flex justify-center align-center h-100p flex-column'>
          <img src={NoTicketImage} alt={'no data'} width={300} />
          <p className='grey-text'>Your Inbox is Empty </p>
          <p className='grey-text'>Your Bots are doing a great job</p>
        </div>
      )}
      {loading && (
        <div className='flex justify-center align-center h-100p'>
          <Spinner />
        </div>
      )}
      {!loading && (!chatData || chatData.length === 0) && ticketsAvailable && (
        <div className='flex justify-center align-center h-100p flex-column'>
          <img src={NoTicketImage} alt={'no data'} width={300} />
          <p className='grey-text'>NO DATA AVAILABLE FOR THIS CONVERSATION</p>
        </div>
      )}
      {!loading &&
        !!chatData &&
        ticketsAvailable &&
        (chatData?.platform_info?.type === 'facebook_feed' ||
        chatData?.platform_info?.type === 'instagram_feed' ? (
          <div>
            <FeedPost
              content={processFeedPostData(chatData?.parent_post_data).content}
              contentType={processFeedPostData(chatData?.parent_post_data).type}
              contentItem={
                processFeedPostData(chatData?.parent_post_data).contentItem
              }
              postAvatar={chatData?.platform_info?.avatar}
              postName={chatData?.platform_info?.name}
              postTime={`${moment(
                chatData.parent_post_data.timestamp
              ).fromNow()}`}
              pageLink={chatData?.parent_post_data?.permalink_url}
              showAction={true}
              showEditReplyAction={
                chatData?.platform_info?.type !== 'instagram_feed'
              }
              showCommentAction={true}
              closeOnActionClick={true}
              moreButtonHeightWidth={25}
              handleCommentHide={(comment: any) =>
                updateFeedAction('hide', `${comment.id}`, 'comment')
              }
              handleCommentDelete={(comment: any) => {
                updateFeedAction('remove', `${comment.id}`, 'comment');
              }}
              handleHide={(reply: any) => {
                updateFeedAction('hide', `${reply.conversation_id}`, 'reply');
              }}
              handleDelete={(reply: any) =>
                updateFeedAction('remove', `${reply.conversation_id}`, 'reply')
              }
              handleEdit={() => {}}
              handleReplyEdit={async (
                reply: any,
                text: string,
                resetCallback: () => void
              ) => {
                const res = await updateFeedAction(
                  'edit',
                  reply.conversation_id + '',
                  'reply',
                  text
                );
                if (res) {
                  resetCallback();
                }
              }}
              commentData={{
                avatar: !!chatData?.parent_comment_data?.customer_info?.avatar
                  ? chatData?.parent_comment_data?.customer_info?.avatar
                  : defaultAvatar,
                content: processFeedData(chatData?.parent_comment_data.data)
                  .content,
                image: processFeedData(chatData?.parent_comment_data.data)
                  .image,
                contentType: processFeedData(chatData?.parent_comment_data.data)
                  .type,
                id: chatData.parent_comment_data.conversation_id,
                link: chatData?.parent_comment_data?.permalink_url,
                name: chatData?.parent_comment_data?.customer_info?.full_name,
                source: 'customer',
                status: chatData?.parent_comment_data?.status,
                showMsgStatus: true,
                msgStatus: !chatData.parent_comment_data.status
                  ? 'pending'
                  : !!chatData.parent_comment_data.status
                  ? 'sent'
                  : 'failed',
                time: `${moment(
                  chatData?.parent_comment_data?.timestamp
                ).fromNow()}`,
                isHighlighted: chatData?.parent_comment_data?.is_highlighted,
                repliedPrivately:
                  chatData?.parent_comment_data?.type === 'message',
              }}
              replyContent={procedReplyData(
                chatData.replies.filter((e: any) => e?.type !== 'action'),
                chatData.platform_info
              )}
            />
          </div>
        ) : (
          <>
            {chatData.map(
              (elem: any, i: number) =>
                !!elem &&
                elem.success === false &&
                elem.source !== 'customer' && (
                  <TextMessage
                    key={i}
                    consumer='admin'
                    msgTime={moment(elem.timestamp).fromNow()}
                    showRepliedBy={false}
                    text={`A ${elem?.data?.type} Failed to Sent`}
                    elementStyle={{ background: '#ff6250', color: 'white' }}
                    userType={elem.source === 'customer' ? 'user' : 'admin'}
                  />
                )
            )}
          </>
        ))}
    </div>
  );
};

export default CRMFeedPost;
