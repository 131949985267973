import React from 'react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import upgradeModal from '../assets/upgradeModal.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface upgradeModalProps {
  updateShowUpgradeModal: (payload: boolean) => void;
  selectedProject: SelectedTeamInterface;
}

const UpgradeModal: React.FC<upgradeModalProps> = ({
  updateShowUpgradeModal,
  selectedProject,
}) => {
  const { t } = useTranslation();
  const featureList = [
    'Unlimited Team Size',
    '25% Yearly Discount',
    'Audio, Video Sending',
    'Smart Ticket Assignment',
  ];
  const featureList2 = ['WhatsApp', 'Unlimited Chatbot', 'CSAT'];

  return (
    <div>
      <div className='flex w-full justify-end'>
        <XMarkIcon
          className='w-6 h-6 text-gray-500 bg-gray-100 m-3 p-1 rounded-xl absolute cursor-pointer'
          onClick={() => updateShowUpgradeModal(false)}
        />
        <img src={upgradeModal} className='w-full' alt='upgradeModal' />
      </div>
      <div className='flex flex-col w-full justify-center p-6'>
        <p className='font-extrabold text-gray-900 text-lg text-left'>
          {t('UPGRADE TO PREMIUM')}
        </p>
        <p className='font-medium text-gray-500 text-xs mb-6'>
          {t('The ultimate customer support power in your hand!')}
        </p>
        <div className='flex mb-4'>
          <div className='mr-6'>
            {featureList.map((feature) => {
              return (
                <div className='flex mb-4 gap-3'>
                  <CheckCircleIcon className='h-5 text-green-400' />
                  <p className='text-gray-700 text-xs'>{feature}</p>
                </div>
              );
            })}
          </div>
          <div className='ml-6'>
            {featureList2.map((feature) => {
              return (
                <div className='flex mb-4 gap-3'>
                  <CheckCircleIcon className='h-5 text-green-400' />
                  <p className='text-gray-700 text-xs'>{feature}</p>
                </div>
              );
            })}
          </div>
        </div>
        {selectedProject.is_creator ? (
          <button
            className='bg-green-500 w-full h-12 text-white bg-primary rounded-md'
            onClick={() => {
              navigate(`/settings/billing`);
              updateShowUpgradeModal(false);
            }}
          >
            {t('Upgrade')}
          </button>
        ) : (
          <p className='font-extrabold text-gray-500 text-xs'>
            {t('Please contact team creator to upgrade subscription plan.')}
          </p>
        )}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  showUpgradeModal: state.dashboard.showUpgradeModal,
});
const mapDispatch = (dispatch: any) => ({
  updateShowUpgradeModal: (payload: boolean) =>
    dispatch.dashboard.updateShowUpgradeModal(payload),
});

export default connect(mapState, mapDispatch)(UpgradeModal);
