import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';

const initialState = {
  timezoneList: [],
};

export const teamManagement = {
  state: {
    ...initialState,
  },
  reducers: {
    updateTimezoneList(state, payload) {
      return { ...state, timezoneList: payload };
    },
  },
  effects: (dispatch) => ({
    async teamCreate(payload) {
      try {
        const res = await axios.post(config.dashboard.teamCreate(), payload);
        if (res.status === 200) {
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          status: false,
          res: null,
        };
      }
    },
    async fetchTimezoneList(payload) {
      try {
        const res = await axios.get(config.misc.timezoneList());
        if (res.status === 200 && res.data.success) {
          dispatch.teamManagement.updateTimezoneList(res.data.dataSource);
        } else {
          dispatch.teamManagement.updateTimezoneList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.teamManagement.updateTimezoneList([]);
      }
    },
    async getCountryData() {
      try {
        const res = await axios.get(config.onboarding.countryList());
        return res.data.dataSource;
      } catch (err) {
        return [];
      }
    },
  }),
};
