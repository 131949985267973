import React from 'react';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface feedbackType {
  feedback: (payload: { feedback: string; notify_user: boolean }) => boolean;
  setOpenDownGrade: (payload: boolean) => void;
}

const FeedBackModal: React.FC<feedbackType> = ({
  feedback,
  setOpenDownGrade,
}) => {
  const { t } = useTranslation();
  const [feedbackInfo, setFeedbackInfo] = React.useState({
    feedback: '',
    notify_user: false,
  });

  const initiateFeedback = async () => {
    if (feedbackInfo.feedback !== '') {
      const res = await feedback(feedbackInfo);
      if (res) {
        toaster.success(t('Feedback given!!'), {
          duration: 3,
        });
        setOpenDownGrade(false);
      } else {
        toaster.danger('Something went wrong!!', {
          duration: 3,
        });
        setOpenDownGrade(false);
      }
    }
  };

  const handleFeedbackInfo = ({ target }: any) => {
    if (target.checked) {
      setFeedbackInfo({
        ...feedbackInfo,
        [target.name]: target.checked,
      });
    } else {
      setFeedbackInfo({
        ...feedbackInfo,
        [target.name]: target.value,
      });
    }
  };

  return (
    <div>
      <div className='font-bold text-gray-900 pb-3 border-b mb-5 text-base'>
        {t('User feedback')}
      </div>
      <p className='text-sm text-gray-400'>{t('Tell us what happened')}</p>
      <textarea
        name='feedback'
        rows={10}
        className='w-full boder border-gray-200 rounded-md'
        onChange={(e) => handleFeedbackInfo(e)}
      ></textarea>
      <div className='flex mb-3'>
        <input
          className='form-check-input appearance-none h-4 w-4 border border-gray-300
          rounded-sm bg-white checked:bg-green-500 checked:border-green-500
          focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat
           bg-center bg-contain float-left mr-2 cursor-pointer'
          type='checkbox'
          id='flexCheckDefault'
          onChange={(e) => handleFeedbackInfo(e)}
          name='notify_user'
        />
        <label
          className='form-check-label inline-block text-gray-800'
          htmlFor='flexCheckDefault'
        >
          {t(
            'I would like to be notified when improvements are made according to my feedback'
          )}
        </label>
      </div>
      <button
        className='bg-green-500 p-2 rounded-md w-full text-white'
        onClick={() => initiateFeedback()}
      >
        {t('Send Feedback')}
      </button>
    </div>
  );
};

const mapState = () => ({});
const mapDispatch = (dispatch: any) => ({
  feedback: (payload: { feedback: string; notify_user: boolean }) =>
    dispatch.newBilling.feedback(payload),
});

export default connect(mapState, mapDispatch)(FeedBackModal);
