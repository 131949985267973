import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'libraryV2/ui/select';
import { ChevronLeftIcon, ChevronRight } from 'lucide-react';
import useNestedVariableLogic, {
  ResponseValueType,
} from '../../hooks/useNestedVariableDropDown';
import VariableBadge from '../../../../../../components/jinja/common/VariableBadge';
import { useEffect, useMemo, useCallback } from 'react';

export type AssignVariableTypes = {
  item: string;
  variableValue: string;
  fallbackValue: string;
  variableCategory: string;
};

interface Props {
  triggerInfo: Record<string, any>;
  variableIndex: number;
  variables: AssignVariableTypes[];
  staticTxt?: string;
  updateSelectedAssignVariableCategoryAndValue: (
    option: ResponseValueType
  ) => void;
}

const NestedVariableDropdown: React.FC<Props> = ({
  variables,
  triggerInfo,
  variableIndex,
  staticTxt = '',
  updateSelectedAssignVariableCategoryAndValue,
}) => {
  const {
    searchTerm,
    setSearchTerm,
    removeVariable,
    currentCategory,
    onVariableChange,
    openListboxOption,
    setCurrentCategory,
    setOpenListboxOption,
  } = useNestedVariableLogic({
    onValueChange: updateSelectedAssignVariableCategoryAndValue,
    variableIndex,
  });

  // Memoize the filtered options to avoid re-calculating on every render
  const filteredOptions = useMemo(() => {
    if (!currentCategory) return [];
    return triggerInfo[currentCategory].filter((option: any) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [currentCategory, searchTerm, triggerInfo]);

  // Set the current category if it's preselected
  useEffect(() => {
    setCurrentCategory(variables[variableIndex]?.variableCategory ?? '');
  }, [variables, variableIndex, setCurrentCategory]);

  const handleBack = useCallback(() => {
    setCurrentCategory('');
    setSearchTerm('');
  }, [setCurrentCategory, setSearchTerm]);

  const handleVariableChange = useCallback(
    (value: string) => {
      onVariableChange(value);
      setOpenListboxOption(false);
    },
    [onVariableChange, setOpenListboxOption]
  );

  return (
    <div className='w-full'>
      <Select
        value={variables[variableIndex]?.variableValue}
        onValueChange={handleVariableChange}
      >
        <div className='relative w-full'>
          <SelectTrigger
            className='px-1 py-2 w-full text-left border rounded-md z-20 focus:border-primary-hover focus:ring-0'
            onClick={() => setOpenListboxOption(!openListboxOption)}
          >
            <VariableBadge
              staticTxt={staticTxt}
              selectedVariable={variables[variableIndex]?.variableValue}
              removeVariable={removeVariable}
            />
          </SelectTrigger>

          {openListboxOption && (
            <SelectContent className='w-full mt-1 bg-white p-0 rounded-md'>
              {currentCategory ? (
                <>
                  <div
                    className='flex items-center mb-2 cursor-pointer'
                    onClick={handleBack}
                  >
                    <ChevronLeftIcon className='w-4 h-4' />
                    <span className='ml-2 font-medium'>Back</span>
                  </div>
                  <input
                    type='text'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder='Search...'
                    className='w-full px-2 py-1 mb-2 border hover:border-primary-hover rounded-lg'
                  />
                  <div className='w-full max-h-[250px] overflow-y-auto'>
                    {filteredOptions.map((item: any) => (
                      <SelectItem key={item.name} value={item.name}>
                        {`{{${item.name}}}`}
                      </SelectItem>
                    ))}
                  </div>
                </>
              ) : (
                Object.keys(triggerInfo).map((category) => (
                  <div
                    key={category}
                    className='cursor-pointer py-2 hover:bg-gray-100 flex justify-between items-start'
                    onClick={() => setCurrentCategory(category)}
                  >
                    {category} <ChevronRight className='w-4 h-4' />
                  </div>
                ))
              )}
            </SelectContent>
          )}
        </div>
      </Select>
    </div>
  );
};

export default NestedVariableDropdown;
