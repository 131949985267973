import useTranslation from 'components/customHooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { classNames } from 'utilities/utils';

interface Props {
  commentType?: string;
  commentStatus?: string;
}

const commentStatusTypes = ['hide', 'edited', 'edit', 'remove'];

const FeedCommentStatus: React.FC<Props> = ({
  commentType = '',
  commentStatus = '',
}) => {
  const { t } = useTranslation();
  const [isStatusChanged, setStatusChanged] = useState(false);

  useEffect(() => {
    if (
      commentStatusTypes.includes(commentStatus) &&
      ['comment', 'comments'].includes(commentType)
    ) {
      setStatusChanged(true);
    }
    //eslint-disable-next-line
  }, [commentStatus]);

  const renderStatusType = () => {
    let statusStr = '';

    switch (commentStatus) {
      case 'hide':
        statusStr = 'Hidden';
        break;
      case 'edit':
      case 'edited':
        statusStr = 'Edited';
        break;
      case 'remove':
        statusStr = 'Comment Deleted';
        break;
      default:
        return '';
    }
    return (
      <div
        className={classNames(
          'w-auto inline-block ml-2 px-1 py-0.5 text-xs font-medium my-1.5 rounded-md content-center',
          commentStatus === 'remove'
            ? 'bg-red-100 text-red-500 '
            : 'bg-gray-100 text-gray-600 '
        )}
      >
        {t(statusStr)}
      </div>
    );
  };

  return (
    <>
      {commentType === 'message' && (
        <div
          className='w-auto inline-block px-1 py-0.5 text-gray-500 text-sm font-medium
            mt-2 bg-gray-100 rounded-md content-center'
        >
          {t('Replied in private message')}
        </div>
      )}
      {isStatusChanged && renderStatusType()}
    </>
  );
};

export default FeedCommentStatus;
