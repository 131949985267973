import React from 'react';
import { IntegrationsFooterData } from '../../../utils/content';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  channelName: string;
}

const Footer: React.FC<Props> = ({ channelName }) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldDisableFooter = isPartnerRestricted(
    'Footer/node/integration-sidesheet-footer'
  );
  if (shouldDisableFooter) {
    return null;
  }
  return (
    <div
      id='integration-sidesheet-footer'
      className='fixed flex w-full max-w-full p-6 bg-white border-t border-b bottom-16'
    >
      <div className='flex-1 p-3 rounded-md bg-gray-50'>
        <label
          htmlFor='developer'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Developer documentation & Support')}
        </label>
        <div className='flex items-center'>
          {!!IntegrationsFooterData[channelName]?.channelLink &&
          IntegrationsFooterData[channelName]?.channelLink.length ? (
            <>
              <a
                href={IntegrationsFooterData[channelName]?.channelLink}
                target='_blank'
                className='text-blue-500 hover:text-blue-800'
                rel='noreferrer'
              >
                {channelName === 'Wit.ai'
                  ? t('Start with wit.ai')
                  : t(channelName)}
              </a>
              <span className='inline-block w-1 h-1 my-0.5 mx-2 bg-gray-500 rounded-full' />{' '}
            </>
          ) : (
            ''
          )}
          <a
            href={IntegrationsFooterData[channelName]?.documentationLink}
            target='_blank'
            className='text-blue-500 hover:text-blue-800'
            rel='noreferrer'
          >
            {t('MyAlice connection details')}
          </a>
          <span className='inline-block w-1 h-1 my-0.5 mx-2 bg-gray-500 rounded-full' />
          <a
            href={IntegrationsFooterData[channelName]?.supportLink}
            target='_blank'
            className='text-blue-500 hover:text-blue-800'
            rel='noreferrer'
          >
            {t('Talk to Support')}
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
