import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  labsData: [],
  currentEditingLabData: null,
  currentEditId: 0,
  dataLabData: {
    id: null,
    title: '',
    description: '',
    logo: {
      url: '',
    },
    button: {
      name: '',
      font_color: '#eeeeee',
      background_color: '#007B65',
    },
    type: 'form',
    webhook_url: '',
  },
};

export const dataLab = {
  state: {
    ...initialState,
  },
  reducers: {
    updateFetchDataLab(state, payload) {
      //payload: array of labs data
      return { ...state, labsData: payload };
    },
    updateDataLabDataInfo(state, payload) {
      //payload: data-lab info object
      return { ...state, dataLabData: payload };
    },
    updateDataLabCreateInfo(state, payload) {
      let dataLabDataLocal = cloneDeep(state.dataLabData);
      if (payload.key === 'logo') {
        dataLabDataLocal[payload.key] = { url: payload.value };
      } else if (
        payload.key === 'font_color' ||
        payload.key === 'background_color' ||
        payload.key === 'name'
      ) {
        dataLabDataLocal.button[payload.key] = payload.value;
      } else {
        dataLabDataLocal[payload.key] = payload.value;
      }
      return { ...state, dataLabData: dataLabDataLocal };
    },
    resetDataLabCreateInfo(state) {
      return { ...state, dataLabData: initialState.dataLabData };
    },
    updateDataLabDelete(state, payload) {
      // payload: number ( keyId )
      let data = state.labsData.filter((lab) => lab.id !== payload);
      return { ...state, labsData: data };
    },
    updateCurrentEditingLabData(state, payload) {
      /*
        payload : lab data
      */
      if (payload && payload.lab_fields) {
        payload.lab_fields = payload.lab_fields.map((elem) => ({
          ...elem,
          is_saved: true,
        }));
      }
      return { ...state, currentEditingLabData: payload };
    },
    updateEditKeyId(state, payload) {
      /*
        payload = fieldId : num
      */
      return { ...state, currentEditId: payload };
    },
    updateKeyChange(state, payload) {
      /*
      payload = {
        fieldId : num,
        keyName: string,
        value: string || number || boolean
      }
    */
      let currentEditingLabDataLocal = cloneDeep(state.currentEditingLabData);
      currentEditingLabDataLocal.lab_fields =
        currentEditingLabDataLocal.lab_fields.map((elem) => {
          if (elem.id === payload.fieldId) {
            return {
              ...elem,
              [payload.keyName]: payload.value,
              is_saved: false,
            };
          }
          return { ...elem };
        });
      return { ...state, currentEditingLabData: currentEditingLabDataLocal };
    },
    updateKeyUpdate(state, payload) {
      let currentEditingLabDataLocal = cloneDeep(state.currentEditingLabData);
      currentEditingLabDataLocal.lab_fields =
        currentEditingLabDataLocal.lab_fields.map((elem) => {
          if (elem.id === payload.id) {
            return { ...payload, is_saved: true };
          }
          return { ...elem };
        });
      return {
        ...state,
        currentEditingLabData: currentEditingLabDataLocal,
        currentEditId: 0,
      };
    },
    updateCreateField(state) {
      let currentEditingLabDataLocal = cloneDeep(state.currentEditingLabData);
      if (
        currentEditingLabDataLocal.lab_fields.filter((field) => field.id === -1)
          .length === 0
      ) {
        currentEditingLabDataLocal.lab_fields.push({
          id: -1,
          name: '',
          placeholder: '',
          type: 'text',
          is_required: false,
          is_hidden: false,
          minlength: null,
          maxlength: null,
          options: [],
          condition: '',
          is_saved: false,
        });
      } else {
        toaster.warning('Finish & Save the previous key first', {
          description: `Seems like you already created a key but haven't saved it yet`,
        });
      }
      return {
        ...state,
        currentEditingLabData: currentEditingLabDataLocal,
        currentEditId: -1,
      };
    },
    updateCreateFieldPost(state, payload) {
      let currentEditingLabDataLocal = cloneDeep(state.currentEditingLabData);
      currentEditingLabDataLocal.lab_fields =
        currentEditingLabDataLocal.lab_fields.map((elem) => {
          if (elem.id === -1) {
            return { ...payload, is_saved: true };
          }
          return { ...elem };
        });
      return {
        ...state,
        currentEditingLabData: currentEditingLabDataLocal,
        currentEditId: 0,
      };
    },
    updateDeleteField(state, payload) {
      let currentEditingLabDataLocal = cloneDeep(state.currentEditingLabData);
      currentEditingLabDataLocal.lab_fields =
        currentEditingLabDataLocal.lab_fields.filter(
          (elem) => elem.id !== payload
        );
      return {
        ...state,
        currentEditingLabData: currentEditingLabDataLocal,
      };
    },

    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchDataLabs(payload) {
      /*
      payload = {
        projectId : num
      }
       */
      try {
        const res = await axios.get(config.datalab.datalab(payload.projectId));
        if (res.data.success) {
          dispatch.dataLab.updateFetchDataLab(res.data.dataSource);
          dispatch.dataLab.updateCurrentEditingLabData(null);
        } else {
          dispatch.dataLab.updateFetchDataLab([]);
        }
      } catch (err) {}
    },
    async createDataLab(projectId, rootState) {
      /*
      projectId : num,
       */
      try {
        const res = await axios.post(
          config.datalab.datalab(projectId),
          rootState.dataLab.dataLabData
        );
        if (res.status === 200 && res.data.success) {
          dispatch.dataLab.resetDataLabCreateInfo();
          toaster.success('Data Lab Created', {
            description: `${res.data.dataSource.title} has been created successfully`,
          });
          navigate(
            `/projects/${projectId}/data-lab/edit/${res.data.dataSource.id}`
          );
        }
      } catch (err) {}
    },
    async handleDeleteLab(payload) {
      /*
      payload = {
        projectId : num,
        labId: num,
      }
       */
      try {
        const res = await axios.delete(
          config.datalab.datalabAction(payload.projectId, payload.labId)
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Deleted Successfully', {
            description: `Your selected Data Lab has been deleted successfully`,
          });
          dispatch.dataLab.updateDataLabDelete(payload.labId);
        } else {
          toaster.danger('Failed to Delete Data Lab', {
            description: `For some technical issue Data Lab delete has been failed`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },
    async fetchDataLabKeyInfo(payload) {
      /*
      payload = {
        projectId : num,
        labId: num,
      }
       */
      try {
        const res = await axios.get(
          config.datalab.datalabAction(payload.projectId, payload.labId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.dataLab.updateCurrentEditingLabData(res.data.dataSource);
        } else {
          dispatch.dataLab.updateCurrentEditingLabData(null);
        }
      } catch (err) {
        dispatch.dataLab.updateCurrentEditingLabData(null);
      }
    },
    async fetchDataLabInfo(payload) {
      /*
      payload = {
        projectId : num,
        labId: num,
      }
       */
      try {
        const res = await axios.get(
          config.datalab.datalabAction(payload.projectId, payload.labId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.dataLab.updateDataLabDataInfo(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger('Failed to Get Lab Info', {
          description: `Request for getting info about lab has been failed. Please try again`,
        });
      }
    },
    async updateDataLabInfo(payload, rootState) {
      /*
      payload = {
        projectId : num,
        labId: num,
      }
       */
      try {
        const res = await axios.put(
          config.datalab.datalabAction(payload.projectId, payload.labId),
          rootState.dataLab.dataLabData
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Updated Successfully', {
            description: `Your data lab info has been updated successfully!`,
          });
          navigate(`/projects/${payload.projectId}/data-lab`);
        } else {
          toaster.danger('Failed to Update Field', {
            description: `Your request for updating data lab info is failed. Please try again`,
          });
        }
      } catch (err) {
        toaster.danger('Failed to Update Field', {
          description: `Your request for updating data lab info is failed. Please try again`,
        });
      }
    },
    async updateDataLabField(payload) {
      /*
      payload = {
        labId : num,
        fieldId: num,
        data: object { field payload }
      }
       */
      try {
        const res = await axios.put(
          config.datalab.fieldAction(payload.labId, payload.fieldId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Updated Successfully', {
            description: `Your updated request has been updated successfully!`,
          });
          dispatch.dataLab.updateKeyUpdate(res.data.dataSource);
        } else {
          toaster.danger('Failed to Update Field', {
            description: `Your request for updating this field is failed. Please try again`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },
    async createDataLabField(payload) {
      /*
      payload = {
        labId : num,
        data: object { field payload }
      }
       */
      try {
        const res = await axios.post(
          config.datalab.field(payload.labId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Created Successfully', {
            description: `New Field Has Been Created Successfully`,
          });
          dispatch.dataLab.updateCreateFieldPost(res.data.dataSource);
        } else {
          toaster.danger('Failed to Create Field', {
            description: `Your request for create this field is failed. Please try again`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },
    async deleteDataLabField(payload) {
      /*
      payload = {
        labId : num,
        fieldId: num,
      }
       */
      try {
        const res = await axios.delete(
          config.datalab.fieldAction(payload.labId, payload.fieldId)
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Deleted Successfully', {
            description: `Field has been deleted successfully`,
          });
          dispatch.dataLab.updateDeleteField(payload.fieldId);
        } else {
          toaster.danger('Failed to Delete Field', {
            description: `Your request for deleting this field is failed. Please try again`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },
  }),
};
