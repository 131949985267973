//ecommerce order creation modal
import React, { useRef, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import Header from './component/Header';
import Footer from './component/Footer';

interface Prop {
  open: boolean;
  height?: string;
  width?: string;
  handleClose: () => void;
  children: any;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  disableConfirm?: boolean;
  closeOnExternalClick?: boolean;
  handleMinimize?: () => void;
  isConfirmButtonLoading?: boolean;
  hasMinimizeButton?: boolean;
  hideCancel?: boolean;
}

const Modal: React.FC<Prop> = ({
  open,
  height = '400px',
  width = 'w-full',
  handleClose,
  children,
  title = 'Title',
  description = 'Description',
  cancelText = 'Cancel',
  confirmText = 'Continue',
  handleConfirm,
  handleCancel,
  disableConfirm = false,
  closeOnExternalClick = true,
  handleMinimize,
  isConfirmButtonLoading = false,
  hasMinimizeButton = false,
  hideCancel = false,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      {/*add `overflow-y-auto` in dialog for let modal scroll outside overlay*/}
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-30'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          if (closeOnExternalClick) {
            handleClose();
          }
        }}
      >
        <div className='flex items-center justify-center min-h-screen p-0 px-4 pt-4 pb-20 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={`inline-block ${width} max-w-3xl my-8
            overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl`}
            >
              {/*header starts*/}
              <Header
                handleClose={handleClose}
                description={description}
                hasMinimizeButton={hasMinimizeButton}
                title={title}
                handleMinimize={handleMinimize}
              />
              <div className='overflow-y-auto' style={{ height: height }}>
                {children}
              </div>
              <Footer
                cancelText={cancelText}
                confirmText={confirmText}
                isConfirmButtonLoading={isConfirmButtonLoading}
                disableConfirm={disableConfirm}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                hideCancel={hideCancel}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
