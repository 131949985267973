import React, { ChangeEvent, useEffect, useRef } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import { maxAuthCodeExpirationTime } from 'pages/integration/utils/content';
import ReactTooltip from 'react-tooltip';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import useTranslation from 'components/customHooks/useTranslation';

interface AuthenticationTemplateOptionsProps {
  actionType: WhatsappTemplateActionType;
}

const AuthenticationTemplateOptions: React.FC<AuthenticationTemplateOptionsProps> = ({
  actionType,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateAuthTemplateSecurityRecommendation,
    updateExpirationTime,
    updateExpirationTimeCheck,
  } = useWhatsappTemplateData();

  const expirationTimeInputRef = useRef<HTMLInputElement>(null);

  const isAuthenticationTemplate =
    whatsappTemplateState.category === 'AUTHENTICATION';
  const isTemplateActionView = actionType === 'VIEW';
  const handleSecurityRecommendationCheckbox = (
    ev: ChangeEvent<HTMLInputElement>
  ) => {
    updateAuthTemplateSecurityRecommendation(ev.target.checked);
  };
  const handleExpirationTimeCheckbox = (ev: ChangeEvent<HTMLInputElement>) => {
    updateExpirationTimeCheck(ev.target.checked);
  };

  const handleAuthCodeExpirationTime = (ev: ChangeEvent<HTMLInputElement>) => {
    updateExpirationTime(ev.target.value);
  };

  useEffect(() => {
    expirationTimeInputRef.current?.focus();
  }, [whatsappTemplateState.hasAuthCodeExpirationTime]);

  if (!isAuthenticationTemplate) {
    return null;
  }

  return (
    <div className='bg-white w-full flex flex-col gap-3 mb-2 rounded-md p-4 shadow-sm'>
      <div className='flex w-1/2'>
        <input
          onChange={handleSecurityRecommendationCheckbox}
          type='checkbox'
          disabled={isTemplateActionView}
          checked={!!whatsappTemplateState?.hasSecurityRecommendation}
          id='security_recommendation'
          name='security_recommendation'
          className={` ${actionType !== 'CREATE' ? 'cursor-not-allowed' : ''
            } w-4 h-4 border-gray-300 rounded text-primary focus:ring-0 focus:ring-transparent`}
        />
        <div className='px-2'>
          <h3 className='text-sm font-medium text-gray-700'>
            {t('Add security recommendation')}
          </h3>
          <p className='text-xs text-gray-500'>
            {t(
              'By enabling security recommendation will show a additional security text in the message body.'
            )}
          </p>
        </div>
      </div>
      <div className='flex w-full'>
        <input
          checked={!!whatsappTemplateState?.hasAuthCodeExpirationTime}
          onChange={handleExpirationTimeCheckbox}
          type='checkbox'
          disabled={isTemplateActionView}
          id='security_recommendation'
          name='security_recommendation'
          className={` ${actionType !== 'CREATE' ? 'cursor-not-allowed' : ''
            } w-4 h-4  border-gray-300 rounded text-primary focus:ring-0 focus:ring-transparent`}
        />
        <div className='px-2 flex-1 flex justify-between'>
          <div className='w-[40%]'>
            <h3 className='text-sm font-medium text-gray-700'>
              {t('Add expiration time for the code')}
            </h3>
            <p className='text-xs text-gray-500'>
              {t(
                'After the code expires, the autofill button will be disabled.'
              )}
            </p>
          </div>

          <div className='w-[250px] flex flex-col'>
            <label className='block text-sm text-gray-600 mb-1'>
              {t('Expire in')}
            </label>
            <div
              data-for='expiration-div'
              data-tip={t('Enable “Expiration time for the code” first.')}
              className='flex items-center rounded-md relative'
            >
              <input
                ref={expirationTimeInputRef}
                id='expiration-time'
                type='number'
                min={0}
                disabled={
                  isTemplateActionView ||
                  !whatsappTemplateState?.hasAuthCodeExpirationTime
                }
                autoComplete='false'
                className={`w-full ${isTemplateActionView
                    ? 'bg-gray-200 cursor-not-allowed'
                    : 'bg-white'
                  } py-1 pl-2 rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 text-sm`}
                placeholder={t('Enter Value')}
                value={whatsappTemplateState?.authCodeExpirationTime || ''}
                onChange={handleAuthCodeExpirationTime}
                onWheel={(e) => e.currentTarget.blur()}
              />
              <span className='absolute ltr:mr-0.5 rtl:ml-0.5 h-[92%] ltr:right-0 rtl:left-0 flex justify-center items-center px-2 bg-gray-100 ltr:rounded-r-md rtl:rounded-l-md text-sm text-gray-500'>
                {t('Minutes')}
              </span>
              {/* @ts-ignore */}
              <ReactTooltip
                id='expiration-div'
                place='top'
                type='dark'
                effect='solid'
                disable={whatsappTemplateState?.hasAuthCodeExpirationTime}
                backgroundColor='#4B5563'
                multiline={true}
                className=' sm:text-sm whitespace-pre-wrap break-words'
              />
            </div>
            <p
              className={`text-sm text-red-500 mt-2 ${Number(whatsappTemplateState?.authCodeExpirationTime) <=
                  maxAuthCodeExpirationTime
                  ? 'hidden'
                  : 'initial'
                }`}
            >
              {t('Maximum time limit is 90 minutes.')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationTemplateOptions;

