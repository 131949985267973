import React from 'react';
import CRMRightInfoBar from './components/CRMRightInfoBar';
import CRMRightSection from './components/CRMRightSection';
import { connect } from 'react-redux';
import { Spinner } from 'evergreen-ui';

interface props {
  rightbarLoading: boolean;
  currentTicket: any;
  fetchMarketPlaceData: (teamId: number) => void;
  projectId: number;
}

const RightBar: React.FC<props> = ({
  rightbarLoading,
  currentTicket,
  fetchMarketPlaceData,
  projectId,
}) => {
  React.useEffect(() => {
    fetchMarketPlaceData(projectId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='customer-info'>
      {rightbarLoading ? (
        <div className='customer-info__loading'>
          <Spinner />
        </div>
      ) : (
        <>
          <CRMRightInfoBar />
          {!!currentTicket && <CRMRightSection />}
        </>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  rightbarLoading: state.loading.effects.crm.fetchCustomerMeta,
  currentTicket: state.crm.currentTicket,
});

const mapDispatch = (dispatch: any) => ({
  fetchMarketPlaceData: (teamId: number) =>
    dispatch.marketplace.fetchMarketPlaceData(teamId),
});
const RightBarContainer = connect(mapState, mapDispatch)(RightBar);
export default RightBarContainer;
