import React from 'react';
import Linkify from 'react-linkify';

import Avatar from '../components/conversation/Avatar';
import DateBar from './DateBar';

import {
  ButtonDataType,
  ConversationBlockButtonDataInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';

import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';

interface Props {
  blockData: ConversationBlockButtonDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
}

const ButtonBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
}) => {
  const { rowDirection, elementDirection } = useMessageBlocksAction({ source });

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderButtonTextView = () => {
    return (
      <p className='w-auto h-auto text-gray-900 break-words whitespace-pre-wrap sm:text-sm'>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {blockData.success ? blockData.text : blockData.error}
        </Linkify>
      </p>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <div className='pt-1' key={`${button?.id}`}>
        <button className='content-center px-2 text-xs font-medium text-gray-700 truncate bg-white border border-gray-300 rounded-md cursor-not-allowed w-60 h-7 drop-shadow-sm hover:bg-gray-100'>
          {button?.title}
        </button>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <ConversationBlockWrapper
        time={time}
        success={isSent}
        isMerged={false}
        report={report}
        source={source}
        isFirstElement={!isMergeable}
        conversationType={blockData?.type}
      >
        {!!blockData.text && renderButtonTextView()}
        {!!blockData.button &&
          blockData?.button?.map((eachButton) => getButtons(eachButton))}
      </ConversationBlockWrapper>
    );
  };

  return (
    <>
      {!isMergeable && (
        <div
          className={`flex w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9] ${rowDirection}`}
        >
          <Avatar avatar={avatar} />
          <div data-testid='button-block' className='max-w-[60%]'>
            <div className={`flex gap-2 ${rowDirection}`}>
              <div
                className={`mr-2 my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {name}
              </div>
            </div>
            {/* Button Block */}
            {renderContent()}
          </div>
        </div>
      )}

      {!!isMergeable && (
        <div
          className={`relative flex w-full h-auto py-1 px-16 group hover:bg-gray-50 ${elementDirection}`}
        >
          {/* Button Block */}
          <div className={`max-w-[60%] flex ${rowDirection}`}>
            {renderContent()}
          </div>
        </div>
      )}
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default ButtonBlock;
