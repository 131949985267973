import React from 'react';
import { reportingTab } from '../../../../utilities/utils';
import ReportingNavTabElement from './ReportingNavTabElement';
import useTranslation from 'components/customHooks/useTranslation';
import useWhatsappMetrics from '../WhatsappMetrics/hooks/useWhatsappMetrics';

interface tabProps {
  id: number;
  name: string;
}

interface Prop {
  currentTab: tabProps;
  updateStateData: (key: string, value: tabProps) => void;
}

const ReportingNavTab: React.FC<Prop> = ({ currentTab, updateStateData }) => {
  const { isWhatsappBsbIntegration } = useWhatsappMetrics();
  const { t } = useTranslation();

  const renderChannelTabOptions = () => {
    if (isWhatsappBsbIntegration === true) {
      return reportingTab.filter(
        (item: any) => item?.name !== 'WhatsApp Metrics'
      );
    } else {
      return reportingTab;
    }
  };
  return (
    <div className='w-full p-3 md:w-auto'>
      <div className='md:hidden sm:w-full'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          className='block w-full border-gray-300 rounded-md outline-none cursor-pointer focus:ring-primary focus:border-primary'
          defaultValue={currentTab.id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const findCurrentTab = reportingTab.filter(
              (tab) => tab.id.toString() === e.target.value.toString()
            );
            if (findCurrentTab.length > 0) {
              updateStateData('currentTab', findCurrentTab[0]);
            }
          }}
        >
          {renderChannelTabOptions().map((tab) => (
            <option key={tab.name} value={tab.id}>
              {t(tab.name)}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden md:block'>
        <nav className='flex space-x-4' aria-label='Tabs'>
          {renderChannelTabOptions().map((tab: tabProps, i) => (
            <ReportingNavTabElement
              tab={tab}
              key={i}
              handleUpdate={() => updateStateData('currentTab', tab)}
              currentTab={currentTab}
            />
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ReportingNavTab;
