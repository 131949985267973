import React from 'react';
import { cloneDeep } from 'lodash';
import DropDownList from '../components/DropDownList';
import IceBreaker from '../components/IceBreaker';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  actionType?: string;
  errorMessage?: string;
  sequences: sequenceProps[];
  messengerSettingData: instagramMessengerSettingDataProps;
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleChange: (
    key: string,
    value: string | string[] | object | number
  ) => void;
}

const InstagramMessengerSetting: React.FC<Props> = ({
  success = true,
  sequences,
  handleChange,
  errorMessage,
  integratedNlpList,
  actionType = 'create',
  messengerSettingData,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) =>
        item.id === messengerSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );

  const iceBreakerDataLength = messengerSettingData.ice_breaker.length;

  const addIceBreakertData = () => {
    if (iceBreakerDataLength < 4) {
      const data = [
        ...messengerSettingData.ice_breaker,
        {
          id:
            iceBreakerDataLength !== 0
              ? messengerSettingData.ice_breaker[iceBreakerDataLength - 1].id +
                1
              : iceBreakerDataLength,
          title: '',
          type: 'sequence',
          value: '',
        },
      ];
      handleChange('ice_breaker', data);
    }
  };
  const updateIceBreakerData = (
    index: number,
    key: string,
    value: string | number
  ) => {
    let clonedData = cloneDeep(messengerSettingData.ice_breaker);
    clonedData[index][key] = value;
    handleChange('ice_breaker', clonedData);
  };

  const deletePersistentData = (id: number) => {
    let updatedData = [
      ...messengerSettingData.ice_breaker.slice(0, id),
      ...messengerSettingData.ice_breaker.slice(id + 1),
    ];
    handleChange('ice_breaker', updatedData);
  };

  return (
    <>
      <div className='p-6'>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My messenger channel')}
            value={messengerSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {t(
              `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
            )}
          </p>
        </div>
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='block mb-1 text-sm font-medium text-gray-700'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (
              value.id !== messengerSettingData.connected_nlp_integration_id
            ) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
      {actionType !== 'update' && (
        <div className='p-6 border-t'>
          <label
            htmlFor='nlp_selection'
            className='block mb-1 text-sm font-medium text-gray-700'
          >
            {t('Allow Permissions')}*
          </label>
          <p className='mt-2 text-sm text-gray-500'>
            1. {t('Go to Instagram ')}
            <b>{t('Settings')}</b> &#8594; <b>{t('Privacy')}</b> &#8594;{' '}
            <b>{t('Messages')}</b>.
          </p>
          <p className='mt-2 text-sm text-gray-500'>
            {t('2. At the bottom of the page, turn on the toggle for')}{' '}
            <b>"{'Allow access to messages'}"</b>.
          </p>
        </div>
      )}
      {!success && actionType !== 'update' && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>
            {t('We Failed to establish a connection with Instagram.')}
            {t('Please try again with the required permissions.')}
            <br />• Be the <b>owner</b> or <b>admin</b> of a Facebook page.{' '}
            <br />• Select the <b>Instagram Business Page</b> connected to your{' '}
            <b>Facebook Business Page</b>. <br />• Turn on the toggle for{' '}
            <b>"{'Allow access to messages'}"</b> {t('from Instagram Settings')}
            .
          </p>
        </div>
      )}
      {actionType !== 'create' && (
        <div className='p-6 boreder-t'>
          <div className='col-span-6 sm:col-span-6'>
            <label
              htmlFor='ice_breaker'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Ice Breaker')}
            </label>
            {messengerSettingData.ice_breaker.map(
              (menu: icebreakerDataProps, i: number) => (
                <IceBreaker
                  key={i}
                  id={menu.id}
                  data={menu}
                  isLastElement={
                    messengerSettingData.ice_breaker[iceBreakerDataLength - 1]
                      .id === menu.id && iceBreakerDataLength < 4
                  }
                  sequences={sequences}
                  onDataChange={(key: string, value: string | number) => {
                    updateIceBreakerData(i, key, value);
                  }}
                  onAdd={() => addIceBreakertData()}
                  onDelete={() => deletePersistentData(i)}
                />
              )
            )}
            {iceBreakerDataLength < 4 && (
              <div
                className='mt-2 text-blue-500 cursor-pointer'
                onClick={() => addIceBreakertData()}
              >
                {t('Add more+')}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default InstagramMessengerSetting;
