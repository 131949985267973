import { Combobox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import { PlatformInterface } from 'index';
import { filterAvailableWhatsappBSPAndEmailChannels } from 'pages/inbox/utils/functions';
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { classNames, isValidEmail, isValidPhoneNumber } from 'utilities/utils';
interface Props {
  selectedCustomerPrimaryId: string;
  integratedChannelList: PlatformInterface[];
  handleSelectedchannelId: (value: any) => void;
}

const ChannelListDropdown: React.FC<Props> = ({
  integratedChannelList,
  handleSelectedchannelId,
  selectedCustomerPrimaryId,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const buttonRef = useRef(null);
  const [selectedChannel, setSelectedChannel] =
    useState<PlatformInterface | null>(null);
  const [platformList, setPlatformList] = useState<PlatformInterface[]>([]);

  useEffect(() => {
    if (!!selectedChannel) {
      handleSelectedchannelId(selectedChannel?.id);
    }
    //eslint-disable-next-line
  }, [selectedChannel]);

  const outlookFilter = (data: PlatformInterface[]) => {
    return data.filter(
      (platform: PlatformInterface) =>
        typeof platform?.primary_id === 'string' &&
        !platform?.primary_id.trim().toLowerCase().endsWith('outlook.com')
    );
  };

  const getChannelList = () => {
    const data: PlatformInterface[] =
      filterAvailableWhatsappBSPAndEmailChannels(
        integratedChannelList,
        isValidPhoneNumber(selectedCustomerPrimaryId),
        isValidEmail(selectedCustomerPrimaryId)
      );
    return isValidEmail(selectedCustomerPrimaryId) ? outlookFilter(data) : data;
  };

  useEffect(() => {
    const channelList = getChannelList();
    setPlatformList([...channelList]);
    //eslint-disable-next-line
  }, [selectedCustomerPrimaryId]);

  useEffect(() => {
    if (!!platformList && platformList.length === 1) {
      setSelectedChannel(platformList[0]);
    }
  }, [platformList]);

  const mainPanelView = () => {
    const channelList =
      query === '' || selectedChannel?.title === query
        ? platformList
        : platformList.filter(
            (channel) =>
              channel?.title.toLowerCase().includes(query.toLowerCase()) ||
              channel?.name.toLowerCase().includes(query.toLowerCase())
          );

    if (!!channelList && channelList?.length > 0) {
      return (
        <>
          {channelList.map((channel) => (
            <Combobox.Option
              key={channel.id}
              value={channel}
              className={({ active }) =>
                classNames(
                  'relative cursor-default select-none py-2 px-3',
                  active ? 'bg-gray-100 text-gray-800' : 'text-gray-800'
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <div className='flex items-center rtl:justify-end'>
                    <span
                      data-tip={channel?.title}
                      data-for={`${channel?.id + channel?.title}`}
                      className={classNames(
                        'truncate text-gray-600',
                        selected
                          ? 'font-semibold'
                          : active
                          ? 'font-medium'
                          : 'font-normal'
                      )}
                    >
                      {!!channel?.title && channel?.title.length > 25
                        ? channel?.title.slice(0, 21) + '...'
                        : channel?.title}
                    </span>
                    {!!channel?.title && channel?.title.length > 25 && (
                      <ReactTooltip
                        key={channel?.id}
                        id={`${channel?.id + channel?.title}`}
                        place='top'
                        type='dark'
                        effect='float'
                      />
                    )}
                  </div>

                  {!!channel?.primary_id && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-2 text-gray-500',
                        selected
                          ? 'font-semibold'
                          : active
                          ? 'font-medium'
                          : 'font-normal'
                      )}
                    >
                      {channel?.primary_id}
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </>
      );
    } else {
      return (
        <div className='py-2 px-3 ltr:text-left rtl:text-right'>
          {t('No Channel Found')}
        </div>
      );
    }
  };

  const renderSubText = () => {
    const text =
      !!selectedChannel && selectedChannel?.title?.length > 25
        ? selectedChannel?.title?.slice(0, 21) + '...'
        : selectedChannel?.title ?? '';
    if (!!selectedChannel && !!selectedChannel?.title && query === text) {
      return (
        <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center text-sm text-gray-500 rounded-r-md px-2 focus:outline-none'>
          {selectedChannel?.primary_id}
        </span>
      );
    } else return '';
  };

  return (
    <Combobox
      as='div'
      className='text-left'
      value={selectedChannel}
      onChange={setSelectedChannel}
      disabled={platformList?.length <= 1}
    >
      <Combobox.Label
        htmlFor='channel_name'
        className='block text-sm rtl:text-right font-medium leading-6 text-gray-900 cursor-text'
      >
        {isValidEmail(selectedCustomerPrimaryId)
          ? t('Email Channel')
          : isValidPhoneNumber(selectedCustomerPrimaryId)
          ? t('WhatsApp Channel')
          : t('Channel')}
        <sup>*</sup>
      </Combobox.Label>
      <div className='relative mt-2'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3'>
          <MagnifyingGlassIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <Combobox.Input
          id='channel_name'
          data-tip={t(selectedChannel?.title ?? '')}
          data-for={'selected_channel_title'}
          placeholder={
            isValidEmail(selectedCustomerPrimaryId)
              ? t('Select Email Channel')
              : isValidPhoneNumber(selectedCustomerPrimaryId)
              ? t('Select WhatsApp Channel')
              : t('Select email or whatsapp channel')
          }
          className={`w-full rounded-md border-0 ${
            platformList?.length <= 1
              ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
              : 'bg-white text-gray-900 cursor-text'
          } py-1.5 px-10  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(platform: PlatformInterface | null) => {
            if (!!platform) {
              return platform?.title?.length > 25
                ? platform?.title?.slice(0, 21) + '...'
                : platform?.title;
            } else return '';
          }}
          onFocus={() => {
            //@ts-ignore
            if (!!buttonRef) buttonRef?.current?.click();
          }}
        />
        {!!selectedChannel && selectedChannel?.title?.length > 25 && (
          <ReactTooltip
            id={'selected_channel_title'}
            place='top'
            type='dark'
            effect='float'
          />
        )}
        {renderSubText()}
        <Combobox.Button
          className='absolute inset-y-0 ltr:right-0 rtl:left-0 hidden items-center rounded-r-md px-2 focus:outline-none'
          ref={buttonRef}
        >
          <ChevronUpDownIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </Combobox.Button>

        <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {mainPanelView()}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
export default ChannelListDropdown;
