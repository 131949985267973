import useTranslation from 'components/customHooks/useTranslation';
import { Button, cn, Column, DropdownMenu, useDatalab } from '../../../export';
import { getColumnTitle } from './utils';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const { t } = useTranslation();
  const { lab_fields } = useDatalab();
  const columnTitle = getColumnTitle(lab_fields, title);

  if (!column.getCanSort()) {
    return <div className={cn(className)}>{columnTitle}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <Button
          variant='ghost'
          size='sm'
          className='ltr:-ml-3 rtl:-mr-3 rtl:text-right h-8 data-[state=open]:bg-accent'
        >
          <span className='text-[#71717A]'>{t(getColumnTitle(lab_fields, title))}</span>
        </Button>
      </DropdownMenu>
    </div>
  );
}
