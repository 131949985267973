import React from 'react';

import defaultAvatar from '../../../../assets/images/anonymousAvatar.svg';

interface Props {
  avatar: string;
  dimension?: string;
  isReplyComment?: boolean;
  isParentComment?: boolean;
  isRTL?: boolean;
}

const Avatar: React.FC<Props> = ({
  avatar,
  dimension = 'w-8 h-8',
  isReplyComment = false,
  isParentComment = false,
  isRTL = false,
}) => {
  return (
    <div className={`relative ${isRTL && 'order-1'}`}>
      <img
        className={`ltr:ml-5 rtl:mr-5 ltr:mr-3 rtl:ml-3 mt-2 object-cover ${dimension} content-between rounded-full`}
        src={avatar}
        alt='avatar'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultAvatar;
        }}
      />
      {!!isParentComment && (
        <span
          className='absolute border top-[42px] ltr:left-[34px] rtl:left-[28px] h-[calc(100%-30px)]'
          aria-hidden='true'
        />
      )}
      {!!isReplyComment && (
        <>
          <span
            className='absolute border top-4 ltr:left-[-10px] h-full rtl:right-[-10px]'
            aria-hidden='true'
          />
          <span
            className='absolute top-[-10px] ltr:left-[-10px] rtl:right-[-10px] ltr:border-l-2 rtl:border-r-2 border-b-2 h-9 w-[30px] ltr:rounded-bl-xl rtl:rounded-br-xl '
            aria-hidden='true'
          />
        </>
      )}
    </div>
  );
};

export default Avatar;
