import Linkify from 'react-linkify';
import React, { useState } from 'react';

import Time from './Time';
import Avatar from './Avatar';
import MediaViewer from './MediaViewer';
import { GlobeAmericasIcon } from '@heroicons/react/24/outline';
import videoIcon from '../../../../assets/images/videoIcon.svg';
import defaultMediaImage from '../../../../assets/images/defaultMediaImage.svg';
import {
  FeedPlatformInfoInterface,
  FeedPostDataInterface,
} from 'pages/inbox/inboxInterface';

interface Props {
  platformInfo: FeedPlatformInfoInterface;
  parentPostData: FeedPostDataInterface;
}

const FeedPost: React.FC<Props> = ({ platformInfo, parentPostData }) => {
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);

  const handleImageClick = (url: any, index: number = 0) => {
    setSelectedMedia(url);
    setSelectedMediaIndex(index);
  };

  const getMediaText = () => {
    return (
      <p
        data-testid='media-text-post'
        className={`mx-5 my-2.5 w-auto h-auto ${
          !!parentPostData?.success ? 'text-gray-900' : 'text-gray-500'
        } sm:text-sm break-words whitespace-pre-wrap`}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {parentPostData?.success
            ? parentPostData?.text
            : parentPostData?.error}
        </Linkify>
      </p>
    );
  };

  const getPostMedia = (platform_type: string) => {
    switch (parentPostData?.sub_type) {
      case 'image':
        return (
          <img
            className={`cursor-pointer object-cover h-80 w-full ${
              platform_type === 'facebook_feed' ? 'rounded-b-md' : 'mt-2.5'
            }`}
            data-testid='media-image-post'
            src={parentPostData?.urls[0]}
            alt='media'
            onClick={() => {
              handleImageClick(parentPostData?.urls[0]);
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultMediaImage;
            }}
          />
        );
      case 'video':
        return (
          <div
            className='relative cursor-pointer'
            onClick={() => handleImageClick(parentPostData.urls[0])}
          >
            <video
              className={`object-cover bg-gray-500 bg-opacity-75 h-80 w-full ${
                platform_type === 'facebook_feed' ? 'rounded-b-md' : 'mt-3'
              }`}
            >
              <source
                data-testid='media-video-post'
                src={parentPostData?.urls[0]}
              />
            </video>
            <img
              className='absolute top-1/2 left-1/2
              transform -translate-x-1/2 -translate-y-1/2 w-12 h-12'
              src={videoIcon}
              alt='videoIcon'
            />
          </div>
        );
      default:
        return (
          <img
            className={`cursor-pointer object-cover h-80 w-full ${
              platform_type === 'facebook_feed' ? 'rounded-b-md' : 'mt-3'
            }`}
            src={defaultMediaImage}
            alt='media'
            onClick={() => {
              handleImageClick(parentPostData?.urls[0]);
            }}
          />
        );
    }
  };

  return (
    <>
      <div className='border w-auto h-auto m-5 bg-white border-gray-300 rounded-md'>
        <div className='flex w-full h-auto py-1'>
          <Avatar
            avatar={platformInfo?.avatar || defaultMediaImage}
            dimension='w-10 h-10'
          />
          <div className='mr-2 mt-2 w-auto h-auto font-medium sm:text-sm text-gray-900'>
            <a
              className='hover:underline'
              target='_blank'
              href={parentPostData?.permalink_url}
              rel='noreferrer noopener'
            >
              {platformInfo?.name}
            </a>
            <div
              className='flex mt-0.5 w-auto h-auto font-normal items-center
              sm:text-xs text-gray-500'
              data-testid='media-time-post'
            >
              <Time
                time={parentPostData?.timestamp}
                isMerged={false}
                isFeedPost={true}
              />
              <p className='px-1 mb-1 font-bold font-size-[10px] align-text-top'>
                .
              </p>
              <GlobeAmericasIcon className='w-3 h-3' />
            </div>
          </div>
        </div>

        {/* Post */}
        {platformInfo?.platform_type === 'facebook_feed' && getMediaText()}
        {parentPostData?.type === 'attachment' &&
          getPostMedia(platformInfo?.platform_type)}
        {platformInfo?.platform_type === 'instagram_feed' && getMediaText()}
      </div>

      {!!selectedMedia && (
        <MediaViewer
          mediaType={parentPostData?.sub_type}
          urls={parentPostData?.urls}
          selectedMedia={selectedMedia}
          selectedMediaIndex={selectedMediaIndex}
          setSelectedMedia={setSelectedMedia}
          setSelectedMediaIndex={setSelectedMediaIndex}
        />
      )}
    </>
  );
};

export default FeedPost;
