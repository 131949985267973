import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import TextWithTooltip from 'library/text';
import { toaster, Icon, Spinner, IconButton } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';

const FileUploadField = ({
  file,
  handleDrop,
  handleClear,
  isDisabled = false,
}) => {
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .doc, .docx',
    onDrop: (files) => {
      setLoading(true);
      handleDrop(files[0]);
      setTimeout(() => setLoading(false), 1500);
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: 'File too large. Maximum file size is 7MB',
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 7340032,
  });

  return (
    <section className='file-upload'>
      <div
        {...getRootProps()}
        className={`${
          isDisabled ? 'bg-gray-100 cursor-not-allowed' : ''
        } file-upload__field`}
      >
        {!file && !loading && (
          <Fragment>
            <input
              {...getInputProps()}
              disabled={isDisabled}
              className={'bg-gray-100'}
            />
            <Icon icon='document' size={24} />
            <p>Drag 'n' Drop your file here</p>
            <p>
              Allowed file type .pdf, .doc, .docx. Maximum size allowed 7MB.
            </p>
          </Fragment>
        )}

        {loading && <Spinner />}

        {file && !loading && (
          <Fragment>
            <IconButton
              icon='cross'
              disabled={isDisabled}
              appearance='minimal'
              intent={'danger'}
              onClick={() => handleClear()}
            />
            <TextWithTooltip
              text={file}
              dataFor={file}
              dataTip={file}
              shouldShowTooltip={true}
            />
          </Fragment>
        )}
      </div>
    </section>
  );
};

FileUploadField.propTypes = {
  file: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
};

export default FileUploadField;
