import { TrashIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { classNames } from 'utilities/utils';
import Button from '../../../../../library/button';
import { persistentMenuDataProps, sequenceProps } from '../../../interface';
import SequenceDropDown from './SequenceDropDown';

interface props {
  id: number;
  onDelete: () => void;
  onAdd: () => void;
  data: persistentMenuDataProps;
  isLastElement: boolean;
  onDataChange: (key: string, value: any) => void;
  sequences: sequenceProps[];
  dataTypes: string[];
  textLimit?: number;
  hideDeleteBtn?: boolean;
  disable?: boolean;
}

const PersistentMenu: React.FC<props> = ({
  id,
  onDelete,
  onAdd,
  data,
  isLastElement,
  onDataChange,
  sequences,
  dataTypes,
  hideDeleteBtn,
  textLimit = 25,
  disable = false,
}) => {
  const { t } = useTranslation();
  const getSelectedSequence = () => {
    return sequences.filter((itm: any) => itm.id === data.value)[0];
  };
  return (
    <div className='flex mb-3'>
      <div className='w-1/3 h-10'>
        <input
          maxLength={textLimit}
          type='text'
          placeholder={t('Button title...')}
          value={data.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onDataChange(e.target.name, e.target.value)
          }
          name='title'
          className={classNames(
            'block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm',
            disable
              ? 'bg-gray-200 cursor-not-allowed'
              : 'bg-white cursor-default'
          )}
          disabled={disable}
        />
      </div>
      <div className='flex gap-3 justify-end w-2/3 h-10 ltr:ml-3 rtl:mr-3 rounded'>
        <div className='relative block w-full ltr:pl-3 rtl:pr-3 mt-1 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'>
          <div className='absolute inset-y-0 flex items-center ltr:left-3 rtl:right-3'>
            <select
              value={data.type}
              className={classNames(
                'absolute border focus:border-primary ltr:pr-7 align-center border-gray-300 h-10 ltr:pl-3 mt-1.5 text-gray-500 rtl:text-right focus:ring-primary bg-gray-50 sm:text-sm ltr:rounded-l rtl:rounded-r',
                disable
                  ? 'bg-gray-200 cursor-not-allowed'
                  : 'bg-white cursor-default'
              )}
              onChange={(event) => onDataChange('type', event.target.value)}
              disabled={disable}
            >
              {dataTypes.map((item: any, index: number) => (
                <option key={index} value={item} className='rtl:text-right'>
                  {item}
                </option>
              ))}
              {/*<option value='url'>URL</option>./*/}
              {/*<option value='sequence'>Sequence</option>*/}
            </select>
          </div>
          {data.type === 'url' ? (
            <input
              type='text'
              className={classNames(
                `focus:border-primary w-full mt-px h-10 block ltr:pl-32 rtl:pr-32 focus:ring-primary border-gray-300 rounded`,
                disable
                  ? 'cursor-not-allowed bg-gray-200'
                  : 'bg-white cursor-default'
              )}
              placeholder={t('Provide url here')}
              value={data.value}
              onChange={(event) => onDataChange('value', event.target.value)}
              disabled={disable}
            />
          ) : (
            <div className='mt-px'>
              <SequenceDropDown
                hasWriteAccess={!disable}
                sequences={sequences}
                className={'ltr:pl-24 rtl:pr-24'}
                selectedSequence={getSelectedSequence()}
                handleSelectedSequence={(name: string, id: number) =>
                  onDataChange('value', id)
                }
              />
            </div>
          )}
        </div>

        {!hideDeleteBtn && (
          <Button
            icon={<TrashIcon className='text-gray-500' />}
            className={classNames(
              'h-10 mt-1 ltr:ml-3 rtl:mr-3 text-gray-700 border-gray-300',
              disable
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white cursor-default'
            )}
            onClick={() => onDelete()}
            isDisabled={disable}
          />
        )}
      </div>
    </div>
  );
};

export default PersistentMenu;
