import React from 'react';
import dayjs from 'dayjs';
import TextWithTooltip from 'library/text';
import ReactTooltip from 'react-tooltip';
import openBoxIcon from '../../../assets/images/openBox.svg';
import useTranslation from 'components/customHooks/useTranslation';
import DatalabFormIcon from '../../../assets/images/datalabFormIcon.svg';
import DatalabInventoryIcon from '../../../assets/images/datalabInventoryIcon.svg';
import CreatorIcon from '../../../assets/images/editAlt.svg';

import {
  IDatalabList,
  ICustomerDatalabEntries,
  ICustomerDatalabEntryPayload,
} from 'pages/inbox/inboxInterface';
import Button from 'library/button';
import { useSelector } from 'react-redux';
import { Accordion } from 'libraryV2/ui/accordion';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

interface Props {
  limit: number;
  offset: number;
  totalEntry: number;
  customerId: number;
  datalabList: IDatalabList[];
  customerDatalabEntries: ICustomerDatalabEntries[];
  updateInboxDatalabStateData: (key: string, value: any) => void;
  fetchCustomerDatalabEntries: (
    requestBody: ICustomerDatalabEntryPayload
  ) => void;
  isFetchCustomerDatalabEntriesLoading: boolean;
}

const CustomerDatalabEntries: React.FC<Props> = ({
  limit,
  offset,
  totalEntry,
  datalabList,
  customerId,
  customerDatalabEntries,
  updateInboxDatalabStateData,
  fetchCustomerDatalabEntries,
  isFetchCustomerDatalabEntriesLoading,
}) => {
  const { t } = useTranslation();

  const planType = useSelector(
    (state: any) =>
      state?.dashboard?.selectedProject?.subscription_plan?.plan_type
  );

  const isPremiumTeam = ['premium'].includes(planType);

  const getDatalabDetails = (labId: number) => {
    let datalabDetails = datalabList.filter(
      (datalab: IDatalabList) => datalab?.id === labId
    );
    return datalabDetails[0];
  };

  const renderDatalabImageView = (datalab: IDatalabList) => {
    if (typeof datalab?.logo === 'string' && !!datalab?.logo) {
      return (
        <img
          src={datalab?.logo}
          className='w-8 h-8 rounded-md'
          alt='datalab-avatar'
        />
      );
    } else {
      return (
        <img
          src={
            datalab?.type === 'form' ? DatalabFormIcon : DatalabInventoryIcon
          }
          className='w-8 h-8 rounded'
          alt='datalab-avatar'
        />
      );
    }
  };

  const singleEntryView = (
    datalabEntry: ICustomerDatalabEntries,
    index: number
  ) => {
    return (
      <div
        key={index}
        className='flex w-full p-4 overflow-hidden border-t border-gray-200 cursor-pointer hover:bg-gray-50'
        onClick={() => {
          updateInboxDatalabStateData('selectedDatalabEntry', datalabEntry);
          updateInboxDatalabStateData('isDatalabFieldDisabled', true);
          updateInboxDatalabStateData('formAction', 'VIEW');
          setTimeout(() => {
            updateInboxDatalabStateData('isRightBarOpenedForDatalab', true);
            updateInboxDatalabStateData(
              'selectedDatalabId',
              datalabEntry?.lab_id
            );
          }, 200);
        }}
      >
        {renderDatalabImageView(getDatalabDetails(datalabEntry?.lab_id))}
        <div className='flex flex-col items-center justify-center ml-3 mr-1 w-[90%]'>
          {/* datalab title */}
          <TextWithTooltip
            text={getDatalabDetails(datalabEntry?.lab_id)?.title}
            dataFor={'datalba_entry' + index}
            dataTip={getDatalabDetails(datalabEntry?.lab_id)?.title}
            shouldShowTooltip={
              getDatalabDetails(datalabEntry?.lab_id)?.title.length > 15
            }
            className='text-sm font-medium text-gray-900'
          />
          {/* creator name, created date */}
          <div className='flex items-center justify-center w-full text-[13px] gap-1 font-medium'>
            {/* creator icon */}
            <img
              src={CreatorIcon}
              alt='creator icon'
              className='h-[14px]'
              data-for='creator-icon'
              data-tip='Created by'
            />
            {/* @ts-ignore */}
            <ReactTooltip
              id={'creator-icon'}
              type='dark'
              effect='solid'
              backgroundColor='#4B5563'
              multiline={true}
            />
            {/* creator name */}
            <div className='w-[36%]'>
              <TextWithTooltip
                text={datalabEntry?.created_by}
                dataFor={datalabEntry?.created_by}
                dataTip={datalabEntry?.created_by}
                className='text-gray-700 '
              />
            </div>
            <span className='w-1.5 h-1.5 bg-gray-300 rounded-full' />
            {/* creator date */}
            <div className='w-[46%]'>
              <TextWithTooltip
                text={dayjs(datalabEntry?.created_at * 1000).format(
                  'Do MMMM YYYY'
                )}
                dataFor={datalabEntry?.created_by}
                dataTip={dayjs(datalabEntry?.created_at * 1000).format(
                  'Do MMMM YYYY'
                )}
                className='text-gray-500 '
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyDatalabEntryView = () => {
    return (
      <>
        {!isFetchCustomerDatalabEntriesLoading && (
          <div className='p-12'>
            <span className='flex justify-center'>
              <img src={openBoxIcon} alt='not-ticket' />
            </span>
            <div className='flex justify-center mt-3 text-sm text-center text-gray-500'>
              <span>{t('Oops! No form entries found.')}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderDatalabCustomerEntryLoaderView = () => {
    return (
      <>
        {isFetchCustomerDatalabEntriesLoading &&
          [...Array(5)].map((item) => (
            <div key={item} className='w-full max-w-sm px-4'>
              <div className='flex items-center animate-pulse'>
                <div className='mr-3 bg-gray-200 rounded w-9 h-9' />
                <div className='flex-1 py-1'>
                  <div className='mb-2'>
                    <div className='h-4 bg-gray-200 rounded' />
                  </div>
                  <div className='grid grid-cols-3 gap-4'>
                    <div className='h-2 col-span-2 bg-gray-200 rounded' />
                    <div className='h-2 bg-gray-200 rounded' />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  const renderDatalabCustomerEntryView = () => {
    return (
      <>
        {customerDatalabEntries.map(
          (datalabEntry: ICustomerDatalabEntries, index: number) =>
            singleEntryView(datalabEntry, index)
        )}
      </>
    );
  };

  const renderEntryViews = () => {
    const isEntryEmpty = customerDatalabEntries.length === 0;
    switch (isEntryEmpty) {
      case false:
        return renderDatalabCustomerEntryView();
      case true:
        return renderEmptyDatalabEntryView();
      default:
        return null;
    }
  };

  const renderLoadMoreButton = () => {
    if (customerDatalabEntries.length >= totalEntry) return null;
    return (
      <div className='flex items-center justify-center w-full mt-2 mb-5'>
        <Button
          intent='primary'
          size='sm'
          // icon={<ArrowTopRightOnSquareIcon />}
          onClick={() => {
            fetchCustomerDatalabEntries({
              customerId: customerId,
              limit: limit,
              offset: limit + offset,
            });
          }}
        >
          {t('Load More')}
        </Button>
      </div>
    );
  };

  const renderDatalabHistories = () => {
    return (
      <Accordion
        type='single'
        collapsible
        className='flex w-auto h-auto border rounded flex-column'
      >
        <AccordionItem value='item-1' className='w-full '>
          <AccordionTrigger className='AccordionTrigger flex w-full items-center justify-between px-4 py-3'>
            <div className='font-semibold text-sm leading-5 text-gray-900 cursor-default'>
              {t('Form History')}
            </div>
            <ChevronDown
              className='text-gray-400 AccordionChevron'
              height={16}
              width={16}
            />
          </AccordionTrigger>
          <AccordionContent>
            {renderDatalabCustomerEntryLoaderView()}
            {renderEntryViews()}
            {renderLoadMoreButton()}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  };
  return isPremiumTeam ? null : renderDatalabHistories();
};

export default CustomerDatalabEntries;
