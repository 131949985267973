import React, { useEffect, useState } from 'react';
import BlockContainer from './BlockContainer';
import attributeIcon from '../../pages/builder/assets/images/icons/set_attribute.svg';
import AttributeFields from '../elements/setAttributeBlock/AttributeFields';
import Button from '../../../library/button';
import { PlusIcon } from '@heroicons/react/20/solid';
import { isNaN } from 'lodash';
import { toaster } from 'evergreen-ui';
import OperationWarning from '../elements/commonElements/OperationWarning';
import { BlocksData } from '../../../utilities/content';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  attributeList: string[];
  sequences: sequenceProps;
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  selectedProjectId: number;
  handleSetAttributeBlock: (payloadData: object, actionType: string) => void;
}

interface dataProps {
  attribute: string;
  value: string;
  collection: [];
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const SetAttributeBlock: React.FC<Props> = ({
  id,
  save,
  data,
  saveLoader,
  sequences,
  saveBlock,
  copyBlock,
  moveBlock,
  deleteBlock,
  attributeList,
  hasWriteAccess,
  handleSetAttributeBlock,
}) => {
  const [updatedAttributeList, setUpdatedAttributeList] =
    useState(attributeList);
  const [showWarning, setShowWarning] = useState(false);

  const addNewField = () => {
    handleSetAttributeBlock(
      { attribute: 'default', operation: 'set', value: '' },
      'create'
    );
  };

  const handleAttributeInput = (attributeName: string) => {
    let searchedAttribute = !!attributeList
      ? attributeList.filter(
          (elem) =>
            !!elem && elem.toLowerCase().includes(attributeName.toLowerCase())
        )
      : [];
    searchedAttribute.length !== 0
      ? setUpdatedAttributeList(searchedAttribute)
      : setUpdatedAttributeList([`Create new attribute "${attributeName}"`]);
  };

  const handleAttributeOnSelect = (attributeName: string, index: number) => {
    if (attributeName.startsWith('Create')) {
      attributeName = attributeName.slice(22, attributeName.length - 1);
    }
    handleSetAttributeBlock(
      { index: index, keyName: 'attribute', keyValue: attributeName },
      'update'
    );
    setUpdatedAttributeList(attributeList);
  };

  const handleRemoveAttributeTag = (attributeName: string, index: number) => {
    handleSetAttributeBlock(
      { index: index, keyName: 'attribute', keyValue: '' },
      'update'
    );
    setUpdatedAttributeList(attributeList);
  };

  const handleActionOnChange = (actionName: string, index: number) => {
    actionName === 'increase' || actionName === 'decrease'
      ? setShowWarning(true)
      : setShowWarning(false);
    handleSetAttributeBlock(
      { index: index, keyName: 'operation', keyValue: actionName },
      'update'
    );
    if (actionName === 'clear')
      handleSetAttributeBlock(
        { index: index, keyName: 'value', keyValue: '' },
        'update'
      );
  };

  const handleValueOnChange = (value: string, index: number) => {
    let selectedOperation = data.collection[index]['operation'];
    if (
      (selectedOperation === 'increase' || selectedOperation === 'decrease') &&
      isNaN(Number(value))
    ) {
      toaster.warning(
        `Only number is allowed for ${selectedOperation} type action`,
        { duration: 1 }
      );
      return;
    }
    handleSetAttributeBlock(
      { index: index, keyName: 'value', keyValue: value },
      'update'
    );
  };

  const handleRemoveFiled = (index: number) => {
    let selectedOperation = data.collection[index]['operation'];
    if (selectedOperation === 'increase' || selectedOperation === 'decrease')
      setShowWarning(false);
    handleSetAttributeBlock({ index: index }, 'delete');
  };

  useEffect(() => {
    if (data.collection.length === 0)
      handleSetAttributeBlock(
        { attribute: 'default', operation: 'set', value: '' },
        'create'
      );
    else {
      data.collection.forEach((elem: any) => {
        if (['increase', 'decrease'].includes(elem.operation))
          setShowWarning(true);
      });
    }
    // eslint-disable-next-line
  }, [data.collection]);

  return (
    <BlockContainer
      id={id}
      title='Save Attribute'
      subtitle='Use this block to save customer responses as attributes and reuse the value.'
      save={save}
      isLoading={saveLoader}
      icon={attributeIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <div className='flex'>
          <span className='w-2/3 text-xs font-medium text-gray-500'>
            Attribute
          </span>
          <span className='w-full ml-5 text-xs font-medium text-gray-500'>
            Value
          </span>
        </div>
        <div>
          {data.collection.length !== 0 &&
            data?.collection.map((elem: any, index) => (
              <div key={index}>
                <AttributeFields
                  id={index}
                  attributeName={elem.attribute}
                  attributeValue={elem.value}
                  operation={elem.operation}
                  updatedAttributeList={updatedAttributeList}
                  handleAttributeInput={handleAttributeInput}
                  handleAttributeOnSelect={handleAttributeOnSelect}
                  handleRemoveAttributeTag={handleRemoveAttributeTag}
                  handleActionOnChange={handleActionOnChange}
                  handleValueOnChange={handleValueOnChange}
                  handleRemoveFiled={handleRemoveFiled}
                />
              </div>
            ))}
          <div className='py-3'>
            <Button
              icon={<PlusIcon className='text-gray-500' />}
              onClick={() => addNewField()}
              className='justify-center w-full'
            >
              Add New Attribute
            </Button>
          </div>
          {showWarning && (
            <OperationWarning
              warningMessage={BlocksData.setAttributeBlock.operationWarning}
            />
          )}
        </div>
      </>
    </BlockContainer>
  );
};

export default SetAttributeBlock;
