import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  SallaIcon,
  ShopifyIcon,
  WooCommerceIcon,
} from 'pages/inbox/assets/iconComponent/EcommerceIcons';

import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  setClose: () => void;
  handleConnectClick: () => void;
}
const NoStoreView: React.FC<Props> = ({ setClose, handleConnectClick }) => {
  const { t } = useTranslation();
  return (
    <div className='w-[260px] px-2'>
      <div className='flex items-center w-full'>
        <span className=' font-semibold text-sm text-gray-800 '>
          {t('Your Store')}
        </span>
        <XMarkIcon
          className='ml-auto cursor-pointer'
          height={18}
          color={'#6B7280'}
          onClick={() => setClose()}
        />
      </div>
      <div className='flex mt-9 justify-center items-center w-full'>
        <WooCommerceIcon key={1} />
        <span className='px-2' />
        <ShopifyIcon key={2} />
        <span className='px-2' />
        <SallaIcon key={3} />
      </div>
      <div className='mt-5 mb-3 text-center text-gray-500 justify-center'>
        {t(
          'Connect your e-commerce store to recommend product or create orders for your customers'
        )}
      </div>
      <button
        className='py-[9px] w-full bg-green-400 rounded-md text-white text-sm font-medium mb-7'
        onClick={() => {
          handleConnectClick();
        }}
      >
        {t('Connect now')}
      </button>
    </div>
  );
};

export default NoStoreView;
