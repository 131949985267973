import React from 'react';
import ProductCard from './ProductCard';
import { connect } from 'react-redux';
import {
  calculateDiscount,
  getCartSubtotal,
  getStringFromObject,
} from 'pages/inbox/utils/functions';
import CopyText from '../common/CopyText';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CalculateDraftOrderProperty,
  CreateDraftOrderProperty,
  ICustomerCartInfo,
} from 'pages/inbox/inboxInterface.d';
import OrderNote from '../common/OrderNote';

interface Props {
  currencySymbol: string;
  createDraftOrder: CreateDraftOrderProperty;
  customerInfo: ICustomerCartInfo;
  calculateDraftOrderProperty: CalculateDraftOrderProperty;
  setCreateDraftOrder: (value: any) => void;
}

const OrderSummary: React.FC<Props> = ({
  createDraftOrder,
  currencySymbol,
  customerInfo,
  calculateDraftOrderProperty,
  setCreateDraftOrder,
}) => {
  const { t } = useTranslation();
  const renderCalculatePriceView = () => {
    return (
      <>
        {createDraftOrder && (
          <div className='pb-1 border-b mb-2'>
            <div className='flex w-full mb-2'>
              <p className='w-1/2 text-sm font-normal text-gray-500'>
                {t('Subtotal')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p className='text-sm text-gray-900 font-normal'>
                  {currencySymbol}
                  {getCartSubtotal(customerInfo?.alice_cart)}
                </p>
              </div>
            </div>
            <div className='flex w-full mb-2'>
              <p className='w-1/2 text-sm font-normal text-gray-500'>
                {t('Tax & Shipping')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p className='text-sm text-gray-900 font-normal'>
                  {currencySymbol}
                  {calculateDraftOrderProperty?.total_tax +
                    calculateDraftOrderProperty?.total_shipping_cost}
                </p>
              </div>
            </div>
            <div className='flex w-full'>
              <p className='w-1/2 text-sm font-normal text-gray-500'>
                {t('Total Discount')}
              </p>
              <div className='w-1/2 flex justify-end'>
                <p
                  className={`text-sm ${
                    calculateDiscount(
                      createDraftOrder?.discount_type,
                      parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                      parseInt(createDraftOrder?.discount_amount)
                    ) > 0
                      ? 'text-red-500'
                      : 'text-gray-900'
                  } font-normal`}
                >
                  {calculateDiscount(
                    createDraftOrder.discount_type,
                    parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                    parseInt(createDraftOrder.discount_amount)
                  ) > 0
                    ? '-'
                    : ''}
                  {currencySymbol}
                  {calculateDiscount(
                    createDraftOrder.discount_type,
                    parseInt(getCartSubtotal(customerInfo?.alice_cart)),
                    parseInt(createDraftOrder.discount_amount)
                  )}
                </p>
              </div>
            </div>
            <div className='flex w-full mt-2 mb-4'>
              <p className='w-1/2 text-sm'>{t('Total')}</p>
              <div className='w-1/2 flex justify-end text-xs'>
                <p className='text-sm text-green-600 font-semibold'>
                  {currencySymbol}
                  {calculateDraftOrderProperty?.total_cost}
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderAddressSummary = () => {
    return (
      <div className='border border-transparent border-t-gray-200 text-xs flex flex-col gap-2 py-4'>
        <p className='flex space-between'>
          <span className='w-[35%] text-gray-900'>{t('Name')}</span>
          <span className='w-[62%] text-gray-500'>
            {createDraftOrder?.shipping_address?.first_name}{' '}
            {createDraftOrder?.shipping_address?.last_name}
          </span>
        </p>
        {createDraftOrder?.shipping_address?.email && (
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Email</span>
            <span className='w-[62%] text-gray-500'>
              {createDraftOrder?.shipping_address?.email}
            </span>
          </p>
        )}
        {createDraftOrder?.shipping_address?.phone && (
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Phone</span>
            <span className='w-[62%] text-gray-500'>
              {createDraftOrder?.shipping_address?.phone}
            </span>
          </p>
        )}
        <p className='flex space-between'>
          <span className='w-[35%] text-gray-900'>{t('Shipping Address')}</span>
          <span className='w-[62%] text-gray-500'>
            {createDraftOrder?.shipping_address?.address_one}
            {createDraftOrder?.shipping_address?.address_two &&
              ', ' + createDraftOrder?.shipping_address?.address_two}
            {createDraftOrder?.shipping_address?.city &&
              ', ' + createDraftOrder?.shipping_address?.city}
            {createDraftOrder?.shipping_address?.state &&
              ', ' + createDraftOrder?.shipping_address?.state}
            {createDraftOrder?.shipping_address?.postcode &&
              ', ' + createDraftOrder?.shipping_address?.postcode}
          </span>
        </p>
        <p className='flex space-between'>
          <span className='w-[35%] text-gray-900'>
            {createDraftOrder?.sameAsShippingAddress && 'Billing Address'}
            {!createDraftOrder?.sameAsShippingAddress && ''}Billing Information
          </span>
          {createDraftOrder?.sameAsShippingAddress && (
            <span className='w-[62%] text-gray-500'>
              Same as shipping address
            </span>
          )}
          {!createDraftOrder?.sameAsShippingAddress && (
            <span className='w-[62%] text-gray-500'>
              {createDraftOrder?.billing_address?.first_name}{' '}
              {createDraftOrder?.billing_address?.last_name}
              {createDraftOrder?.billing_address?.email &&
                ', ' + createDraftOrder?.billing_address?.email}
              {createDraftOrder?.billing_address?.phone &&
                ', ' + createDraftOrder?.billing_address?.phone}
              {createDraftOrder?.billing_address?.address_one &&
                ', ' + createDraftOrder?.billing_address?.address_one}
              {createDraftOrder?.billing_address?.address_two &&
                ', ' + createDraftOrder?.billing_address?.address_two}
              {createDraftOrder?.billing_address?.city &&
                ', ' + createDraftOrder?.billing_address?.city}
              {createDraftOrder?.billing_address?.state &&
                ', ' + createDraftOrder?.billing_address?.state}
              {createDraftOrder?.billing_address?.postcode &&
                ', ' + createDraftOrder?.billing_address?.postcode}
            </span>
          )}
        </p>
        {createDraftOrder?.shipping_line && (
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>
              {t('Shipping method')}
            </span>
            <span className='w-[62%] text-gray-500'>
              {createDraftOrder?.shipping_line?.method_title}
            </span>
          </p>
        )}
        {calculateDraftOrderProperty?.total_tax > 0 && (
          <p className='flex space-between'>
            <span className='w-[35%] text-gray-900'>Tax/charges</span>
            <span className='w-[62%] text-gray-500'>
              ${calculateDraftOrderProperty?.total_tax}
            </span>
          </p>
        )}
      </div>
    );
  };

  const handleTextToCopy = () => {
    return (
      `Name: ${createDraftOrder?.shipping_address?.first_name} ${createDraftOrder?.shipping_address?.last_name}` +
      `\nEmail: ${createDraftOrder?.shipping_address?.email}` +
      `\nPhone: ${createDraftOrder?.shipping_address?.phone}` +
      `\nShipping Address: ${getStringFromObject(
        createDraftOrder?.shipping_address,
        ['address_one', 'address_two', 'city', 'state', 'postcode'],
        false
      )}` +
      `\nBilling Address: ${
        createDraftOrder?.sameAsShippingAddress
          ? 'Same as shipping address'
          : getStringFromObject(createDraftOrder?.billing_address, [], true)
      }` +
      `\nShipping Method: ${createDraftOrder?.shipping_line?.method_title}`
    );
  };

  const renderReasonForDicount = () => {
    if (!createDraftOrder?.discount_reason) {
      return null;
    }
    return (
      <div className='w-full flex justify-between text-xs'>
        <div className='w-[35%] text-gray-900 break-words'>
          {t('Reason for discount')}
        </div>
        <div className='w-[62%] text-gray-500'>
          {createDraftOrder?.discount_reason}
        </div>
      </div>
    );
  };

  const handleOrderNoteChange = (noteText: string) => {
    setCreateDraftOrder((o: any) => ({ ...o, order_note: noteText }));
  };

  const renderOrderNoteInput = () => {
    return (
      <OrderNote
        noteValue={createDraftOrder?.order_note ?? ''}
        handleNoteChange={handleOrderNoteChange}
      />
    );
  };

  return (
    <>
      {' '}
      {createDraftOrder && (
        <div className='px-3'>
          <div className='mb-3 border-b border-gray-200'>
            {customerInfo?.alice_cart?.map((product) => {
              return (
                <ProductCard
                  product={product}
                  key={product?.product_id}
                  currencySymbol={currencySymbol}
                />
              );
            })}
          </div>
          {renderCalculatePriceView()}
          <div className='border border-transparent flex space-between text-xs pt-3 pb-1'>
            <span className='text-[#6B7180]'>{t('Billing & Shipping')}</span>
            <CopyText height={20} textToCopy={handleTextToCopy()} />
          </div>
          {renderAddressSummary()}
          {renderReasonForDicount()}
          {renderOrderNoteInput()}
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  customerInfo: state.ecommerce.cart,
  calculateDraftOrderProperty: state.ecommerce.calculateDrftOrder,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(OrderSummary);
