import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import useTranslation from 'components/customHooks/useTranslation';
import TextWithTooltip from 'library/text';
import { IFormField } from 'pages/datalab/export';
import { IDataLabField } from 'pages/datalab/interface';
import { EmptyFileIcon } from 'pages/inbox/assets/iconComponent/EmptyStateIcon';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import { DatalabSearchAxiosCall } from 'pages/inbox/components/datalab/utils/functions';
import { extractDatalabLinkTag } from 'pages/inbox/utils/functions';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SearchField from './SearchField';

interface Props {
  datalabFields: IDataLabField[] | IFormField[];
  shouldDisableField: boolean;
  valuePath: string;
  fieldData: IDataLabField | IFormField;
}
const SearchSelectField: React.FC<Props> = ({
  fieldData,
  valuePath,
  datalabFields,
  shouldDisableField,
}) => {
  const { t } = useTranslation();
  const hookForm = useFormContext();
  const [query, setQuery] = useState<string>('');
  const [options, setOptions] = useState<any>([]);
  const [staticOptions, setStaticOptions] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('No Result Found');
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const listboxRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef(null);

  // Event listener for outside click
  const handleOutsideClick = (e: MouseEvent) => {
    if (listboxRef.current && !listboxRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const getReplacedLink = (link: string) => {
    const extractedObject = extractDatalabLinkTag(link);
    if (!extractedObject) {
      return link;
    }

    // Find the matching data from fields based on extracted key and value
    const matchingField = datalabFields.find((field) => {
      //@ts-ignore
      let fieldValue = field[`${extractedObject.key}`];
      if (!isNaN(extractedObject.value))
        extractedObject.value = Number(extractedObject.value);
      return fieldValue !== undefined && fieldValue === extractedObject.value;
    });

    if (!matchingField) {
      return link.replace(
        `<${extractedObject?.key}:${extractedObject.value}>`,
        'untitled'
      );
    } else {
      return link.replace(
        `<${extractedObject?.key}:${extractedObject.value}>`,
        hookForm.getValues(matchingField?.slug)
      );
    }
  };

  // Attach event listener when the component mounts
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleSearchResponse = (response: {
    success: boolean;
    data: any[];
    error: string;
  }) => {
    if (response.success) {
      setOptions([...response?.data]);
      if (!!fieldData?.api_call?.isStatic) {
        setStaticOptions([...response?.data]);
      }
    } else {
      setOptions([]);
      setError(response?.error);
    }
    setLoading(false);
  };

  const handleSearch = () => {
    if (!!fieldData?.api_call?.isStatic && staticOptions?.length > 0) {
      const filteredOptions = staticOptions.filter((data: any) =>
        data?.title.toLowerCase().includes(query.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setLoading(true);
      const apiProps = fieldData?.api_call;
      if (
        !apiProps.hasOwnProperty('request') ||
        !apiProps.hasOwnProperty('response')
      ) {
        return;
      }
      const apiUrlLink = getReplacedLink(apiProps?.request?.url);
      DatalabSearchAxiosCall(
        datalabFields,
        query,
        fieldData?.api_call,
        handleSearchResponse,
        apiUrlLink
      );
    }
  };

  useEffect(() => {
    handleSearch();
    //eslint-disable-next-line
  }, [query]);

  const fieldValue = hookForm.getValues(
    !!valuePath ? `${valuePath}.${fieldData?.slug}` : fieldData?.slug
  );

  const handleInboxDatalabFieldsValue = (value: any) => {
    hookForm.setValue(
      !!valuePath ? `${valuePath}.${fieldData?.slug}` : fieldData?.slug,
      value
    );
  };

  const handleMultiSelectOptionOnClick = (option: any) => {
    const optionValue = option?.value;

    let updatedOptions = fieldValue;
    if (fieldValue.includes(optionValue)) {
      updatedOptions = updatedOptions.filter(
        (option: string) => option !== optionValue
      );
      const filteredSelectedOptions = selectedOptions.filter(
        (op: any) => op?.value !== option?.value
      );
      setSelectedOptions([...filteredSelectedOptions]);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      updatedOptions = [...fieldValue, optionValue];
    }

    handleInboxDatalabFieldsValue(updatedOptions);
  };

  const loadingView = () => {
    return (
      <div className='w-full p-4 flex items-center justify-center'>
        <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
      </div>
    );
  };

  const renderEmptyListView = () => {
    return (
      <div className='p-8 flex flex-col justify-center items-center'>
        <EmptyFileIcon />
        <span className='block text-sm text-center font-normal text-gray-500 mt-2'>
          {error}
        </span>
      </div>
    );
  };

  const renderSingleSelectListView = () => {
    return (
      <Listbox.Options>
        {options.map((option: any, index: number) => (
          <Listbox.Option key={index} value={option?.value}>
            {({ active }) => (
              <div
                className={`${
                  active ? 'bg-gray-100' : ''
                } cursor-pointer select-none p-2 flex items-center justify-between`}
                onClick={() => {
                  if (fieldValue === option?.value) {
                    handleInboxDatalabFieldsValue('');
                    setSelectedOptions([]);
                  } else {
                    handleInboxDatalabFieldsValue(option?.value);
                    setSelectedOptions([option]);
                  }
                  setIsOpen(false);
                }}
              >
                <div className='flex items-center w-full justify-between flex-row'>
                  <div className='w-[70%] min-w-[70%] flex-1'>
                    <TextWithTooltip
                      text={option?.title}
                      dataFor={`${option?.title}_${index}`}
                      dataTip={option?.title}
                      className={'z-[9999] text-sm text-gray-600 font-normal'}
                      toolTipPosition='left'
                    />
                  </div>

                  <div className=' float-right w-full flex items-center justify-end'>
                    <TextWithTooltip
                      text={option?.subtitle}
                      dataFor={`${option?.subtitle}_${index}`}
                      dataTip={option?.subtitle}
                      className='z-[9999] text-sm text-gray-600 font-normal text-right'
                      toolTipPosition='left'
                    />
                    {fieldValue === option?.value && (
                      <CheckIcon className='w-5 h-5 text-green-500' />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  };

  const renderMultiSelectListView = () => {
    return (
      <Listbox.Options>
        {options.map((option: any, index: number) => (
          <Listbox.Option key={index} value={option?.value}>
            {({ active }) => (
              <div
                className={`${
                  active ? 'bg-gray-100' : ''
                } cursor-pointer select-none p-2 flex items-center justify-between`}
                onClick={() => handleMultiSelectOptionOnClick(option)}
              >
                <input
                  id={`${option?.value}_${index}`}
                  name={option?.value}
                  type='checkbox'
                  checked={fieldValue.includes(option?.value)}
                  className='w-4 h-4 border-gray-300 rounded text-primary mr-2 focus:ring-primary'
                  onChange={() => {}}
                />
                <div className='flex w-[95%] items-center justify-between flex-row'>
                  <div className='w-[70%] min-w-[70%] flex-1'>
                    <TextWithTooltip
                      text={option?.title}
                      dataFor={`${option?.value}_${index}`}
                      dataTip={option?.title}
                      className={'z-[9999]'}
                    />
                  </div>

                  <div className='float-right ml-auto w-full flex items-center justify-end '>
                    <TextWithTooltip
                      text={option?.subtitle}
                      dataFor={`${option?.subtitle}_${index}`}
                      dataTip={option?.subtitle}
                      className={
                        'z-[9999] text-sm text-gray-600 font-normal text-right'
                      }
                      toolTipPosition='left'
                    />
                  </div>
                </div>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  };

  const renderListBodyView = () => {
    if (loading) return loadingView();
    else if (!!options && options.length < 1) return renderEmptyListView();
    else if (fieldData?.type === 'singleselect')
      return renderSingleSelectListView();
    else return renderMultiSelectListView();
  };

  const renderMultiselectedValue = () => {
    if (!!fieldValue && fieldValue.length > 0) {
      const selectedOptionsData: any[] = selectedOptions.filter((option: any) =>
        fieldValue.includes(option?.value)
      );
      const optionsWithoutFirst =
        selectedOptionsData.length > 1 ? selectedOptionsData.slice(1) : [];

      const optionsWithoutFirstLength = optionsWithoutFirst?.length;

      return (
        <div className=' w-full flex items-center justify-start'>
          <span className='bg-gray-100 rounded px-2 py-1 mr-2 max-w-[75%]'>
            <TextWithTooltip
              text={selectedOptionsData[0]?.title}
              dataFor={`${selectedOptionsData[0]?.title}_${1}`}
              dataTip={selectedOptionsData[0]?.title}
              className={'z-[9999]'}
              toolTipPosition='left'
            />
          </span>
          {optionsWithoutFirstLength > 0 && (
            <span className='bg-gray-100 rounded px-2 py-1'>
              <TextWithTooltip
                text={`+${optionsWithoutFirstLength}`}
                dataFor={`multiselect_${2}`}
                toolTipPosition='left'
                dataTip={optionsWithoutFirst.map((opt) => opt.title).join('\n')}
                className={`z-[9999] ${
                  optionsWithoutFirstLength > 99
                    ? 'w-10'
                    : optionsWithoutFirstLength > 9
                    ? 'w-8'
                    : 'w-6'
                }`}
              />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <span
          className={`block pr-5 truncate text-left ${
            !!fieldValue ? 'text-gray-700' : 'text-gray-500 font-normal'
          }`}
        >
          {t('Selected Options')}
        </span>
      );
    }
  };

  const renderListViewButton = () => {
    return (
      <Listbox.Button
        disabled={shouldDisableField}
        onClick={() => {
          if (!shouldDisableField) setIsOpen(!isOpen);
        }}
        className={`relative inline-flex justify-between w-full p-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md shadow-sm  focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary bg-white hover:bg-gray-50 ${
          shouldDisableField ? 'cursor-not-allowed bg-gray-100' : ''
        }`}
      >
        {fieldData?.type === 'singleselect' && (
          <TextWithTooltip
            text={
              !!selectedOptions && selectedOptions.length > 0
                ? selectedOptions[0]?.title
                : t('Select an option')
            }
            dataFor={`singleSelect_1`}
            dataTip={
              !!selectedOptions && selectedOptions.length > 0
                ? selectedOptions[0]?.title
                : t('Select an option')
            }
            className={`z-[9999] block pr-5 truncate text-left ${
              !!fieldValue ? 'text-gray-700' : 'text-gray-500 font-normal'
            }`}
            toolTipPosition='left'
          />
        )}
        {fieldData?.type === 'multiselect' && renderMultiselectedValue()}
        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
          {isOpen ? (
            <XCircleIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
              onClick={() => setIsOpen(false)}
            />
          ) : (
            <ChevronDownIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          )}
        </span>
      </Listbox.Button>
    );
  };

  const renderListView = () => {
    return (
      <Listbox value={fieldValue} onChange={() => {}}>
        <div className='relative mb-2' ref={listboxRef}>
          {renderListViewButton()}

          <Transition
            show={isOpen}
            as={Fragment}
            afterEnter={() => {
              if (!!searchRef?.current) {
                searchRef?.current?.focus();
                handleSearch();
              }
            }}
          >
            {(ref) => (
              <div
                ref={ref}
                className='absolute right-0 z-10 w-full mt-2 mb-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 '
              >
                <SearchField
                  isApiSearch={true}
                  ref={searchRef}
                  onChange={(value: string) => setQuery(value)}
                />

                <div className='w-full overflow-x-hidden  overflow-y-scroll max-h-40'>
                  {renderListBodyView()}
                </div>
              </div>
            )}
          </Transition>
        </div>
      </Listbox>
    );
  };

  return (
    <>
      <div className='col-span-6 mb-6 sm:col-span-6'>
        <div className='flex justify-between'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-600'
          >
            {fieldData?.name}
            {fieldData?.is_required ? (
              <span className='text-red-500'>*</span>
            ) : (
              ''
            )}
          </label>
        </div>
        {renderListView()}
      </div>
    </>
  );
};

export default SearchSelectField;
