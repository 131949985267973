import React from 'react';
import IntegrationNavigationTab from './IntegrationNavigationTab';
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
// import MultiSelectDropDown from '../common/components/MultiSelectDropDown';

interface Props {
  navCurrentTab: string;
  selectedPlatformsList: string[];
  availablePlatformsList: string[];
  updateStateData: (val: object) => void;
}

const IntegrationNavigationBar: React.FC<Props> = ({
  navCurrentTab,
  selectedPlatformsList,
  availablePlatformsList,
  updateStateData,
}) => {
  // const [currentOption, setCurrentOption] = React.useState(['Channel']);
  return (
    <div className='sticky top-0 px-8 z-10 w-full bg-white'>
      <IntegrationNavigationTab selectedPlatformCount={selectedPlatformsList.length} currentTab={navCurrentTab} />
      {selectedPlatformsList}
      {/* filter will be added later */}
      {/* {navCurrentTab !== 'integrated' && (
        <div className='flex items-center px-6 pt-0 md:p-6 md:w-auto'>
          <MultiSelectDropDown
            value={currentOption}
            options={availablePlatformsList}
            onChange={(val: string) => {
              if (selectedPlatformsList.includes(val)) {
                selectedPlatformsList = selectedPlatformsList.filter(
                  (item) => item !== val
                );
              } else {
                selectedPlatformsList = [...selectedPlatformsList, val];
              }
              updateStateData({
                key: 'selectedPlatformsList',
                value: selectedPlatformsList,
              });
              setCurrentOption([...currentOption, val]);
            }}
            selectedPlatformsList={selectedPlatformsList}
          /> */}
      {/* search will be added soon */}
      {/* <div className='relative ml-3 rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </div>
          <input
            disabled={!availablePlatformsList.length}
            type='search'
            name='search'
            id='search'
            placeholder='Search'
            value={''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              console.log(e.target.value)
            }
            className={`focus:ring-primary py-2 focus:border-primary ${
              !availablePlatformsList.length
                ? 'bg-gray-50 cursor-not-allowed'
                : ''
            } block w-full pl-10 sm:text-sm border-gray-300 rounded-md`}
          />
        </div> */}
      {/* </div>
      )} */}
    </div>
  );
};
export default IntegrationNavigationBar;
