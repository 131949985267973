import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Dialog } from 'evergreen-ui';
import UserInformationSectionEditModal from './UserInformationSectionEditModal';

interface props {
  customerAttributes: any;
  tempAttributes: any;
  updateStateData: (key: any, value: any) => void;
  updateUserInformationAPI: (ticketId: any) => Promise<boolean>;
  currentTicket: any;
}

const UserInformationSection: React.FC<props> = ({
  customerAttributes,
  tempAttributes,
  updateStateData,
  updateUserInformationAPI,
  currentTicket,
}) => {
  const [showEdit, setShowEdit] = React.useState(false);

  const handleOnChange = (key: string, value: any, type: string) => {
    let localAttribute = { ...tempAttributes };
    if (type === 'fixed') {
      localAttribute.fixed[key] = value;
    } else if (type === 'variable') {
      localAttribute.variable[key] = value;
    } else if (type === 'delete') {
      localAttribute.variable[key] = null;
      // delete localAttribute.variable[key];
    }
    updateStateData('tempAttributes', localAttribute);
  };

  const getModifiedCustomerAttribiutesValue = (value: string) => {
    return value.length > 25 ? value.slice(0, 25) + '...' : value;
  };

  return (
    <div className='right-side-info-bar__user-information rounded-b-md'>
      <div className='flex align-center mb-20px'>
        <div className='right-side-info-bar__user-container'>
          <p className='right-side-info-bar__user-container-title'>
            MyAlice UID #{currentTicket.customer.id || 'N/A'}
          </p>
          <p className='right-side-info-bar__user-container-details'>
            Personal details
          </p>
        </div>
        <IconButton
          height={26}
          marginLeft={10}
          appearance='minimal'
          icon={'edit'}
          className='alice-icon-btn__default'
          onClick={() => setShowEdit(true)}
        />
      </div>

      {!!customerAttributes && !!customerAttributes.fixed && (
        <>
          {!!customerAttributes?.fixed?.gender && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Gender:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.gender}
              </p>
            </div>
          )}
          {!!customerAttributes.fixed.phone && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Phone:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.phone}
              </p>
            </div>
          )}
          {!!customerAttributes.fixed.email && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Email:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.email}
              </p>
            </div>
          )}
          {!!customerAttributes.fixed.timezone && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Timezone:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.timezone}
              </p>
            </div>
          )}
          {!!customerAttributes.fixed.locale && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Locale:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.locale}
              </p>
            </div>
          )}
          {!!customerAttributes.fixed.language && (
            <div className='user-info-crm'>
              <p className='user-info-crm__title'>Language:</p>
              <p className='user-info-crm__value'>
                {customerAttributes.fixed.language}
              </p>
            </div>
          )}
          {Object.keys(customerAttributes.variable).length > 0 &&
            Object.keys(customerAttributes.variable).map(
              (key: any, index: number) =>
                !!customerAttributes.variable[key] && (
                  <div className='user-info-crm' key={index}>
                    <p className='user-info-crm__title'>{key}:</p>
                    <p className='user-info-crm__value'>
                      {typeof customerAttributes.variable[key] === 'object'
                        ? getModifiedCustomerAttribiutesValue(
                            JSON.stringify(customerAttributes.variable[key])
                          )
                        : getModifiedCustomerAttribiutesValue(
                            customerAttributes.variable[key]
                          )}
                    </p>
                  </div>
                )
            )}
        </>
      )}
      <Dialog
        isShown={showEdit}
        hasHeader={false}
        hasFooter={false}
        width={'700px'}
        onCloseComplete={() => setShowEdit(false)}
        shouldCloseOnOverlayClick={false}
      >
        <UserInformationSectionEditModal
          attributes={tempAttributes}
          onClose={() => setShowEdit(false)}
          handleOnChange={handleOnChange}
          submitMethod={() =>
            updateUserInformationAPI(currentTicket.customer.id)
          }
        />
      </Dialog>
    </div>
  );
};

const mapState = (state: any) => ({
  customerAttributes: state.crm.customerAttributes,
  tempAttributes: state.crm.tempAttributes,
  currentTicket: state.crm.currentTicket,
});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: any, value: any) =>
    dispatch.crm.updateStateData({ key, value }),
  updateUserInformationAPI: (ticketId: any) =>
    dispatch.crm.updateUserInformationAPI(ticketId),
});

const UserInformationSectionContainer = connect(
  mapState,
  mapDispatch
)(UserInformationSection);
export default UserInformationSectionContainer;
