import React, { useState } from 'react';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from 'libraryV2/ui/select'; // Assuming Shadcn Select is configured
import { ChevronRight, XCircleIcon } from 'lucide-react';
import { IOrderItem, IContactItem } from 'pages/automationWorkflow/interface';
import { Badge } from 'libraryV2/ui/badge';

type Props = {
  categoryOptions: string[];
  categoryDetails: IOrderItem[] | IContactItem[];
  activeValue: {
    value: string;
    category: string;
  };
  updateSelectedTriggerInfoLeft: (data: string) => void;
  updateSelectedTriggerInfoRight: (data: string) => void;
};

const MultiListSelector: React.FC<Props> = ({
  categoryOptions,
  categoryDetails,
  activeValue,
  updateSelectedTriggerInfoLeft,
  updateSelectedTriggerInfoRight,
}) => {
  const [openListboxOption, setOpenListboxOption] = useState<boolean>(false);

  const handleCategoryClick = (category: string) => {
    updateSelectedTriggerInfoLeft(category);
  };

  return (
    <Select
      onValueChange={(value: string) => {
        updateSelectedTriggerInfoRight(value);
        setOpenListboxOption(false);
      }}
    >
      <SelectTrigger
        className='relative w-full text-sm z-20'
        onClick={() => setOpenListboxOption(!openListboxOption)}
      >
        <div className='flex items-center space-x-2'>
          {activeValue?.value ? (
            <Badge className='bg-indigo-50 text-indigo-400 hover:bg-indigo-100 z-50'>
              {`{{${activeValue?.value}}}`}
              <XCircleIcon
                className='inline-block w-4 h-4 ml-2 z-50'
                onClick={() => {
                  updateSelectedTriggerInfoLeft('');
                  updateSelectedTriggerInfoRight('');
                }}
              />
            </Badge>
          ) : (
            'Select variables'
          )}
        </div>
      </SelectTrigger>

      {openListboxOption && (
        <SelectContent className='w-full p-2 bg-white rounded-md shadow-lg divide-x-2'>
          <div className='flex flex-col'>
            <div className='grid grid-cols-2 gap-4'>
              <div className='col-span-1'>
                {categoryOptions.map((category, index) => (
                  <div
                    key={index}
                    className={`p-2 flex items-center justify-between cursor-pointer hover:bg-gray-100 rounded-md my-1 ${
                      activeValue?.category === category
                        ? 'bg-[#E7F8F0] text-[#04A056]'
                        : ''
                    }`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <span>{category}</span>
                    <ChevronRight className='w-4 h-4 text-gray-500' />
                  </div>
                ))}
              </div>

              <div className='col-span-1'>
                {categoryDetails.length > 0 ? (
                  categoryDetails.map((item, index) => (
                    <SelectItem
                      key={index}
                      value={item?.name}
                      className={`p-2 flex justify-between items-center cursor-pointer hover:bg-gray-100 rounded-md ${
                        activeValue?.value === item.name ? 'bg-gray-100' : ''
                      }`}
                    >
                      <span>{`{{${item.name}}}`}</span>
                    </SelectItem>
                  ))
                ) : (
                  <div className='text-center py-2 h-full w-full flex items-center justify-center'>
                    <Badge variant={'outline'}>No Data Found</Badge>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SelectContent>
      )}
    </Select>
  );
};

export default MultiListSelector;
