import { classNames } from 'utilities/utils';

export const CircleSpinner = ({
  className = 'animate-spin ml-1 h-5 w-5 text-primary inline ',
}) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
      ></circle>
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  );
};

interface ILoaderProps {
  variant?: 'default' | 'primary' | 'disable' | 'danger' | 'secondary';
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

export const CircleLoader: React.FC<ILoaderProps> = ({
  variant = 'default',
  size = 'sm',
}) => {
  const getVariantClassNames = () => {
    switch (variant) {
      case 'disable':
        return 'text-white';
      case 'danger':
        return 'text-red-500';
      case 'secondary':
        return 'text-gray-700';
      default:
        return 'text-primary';
    }
  };

  const getSizeClassNames = () => {
    switch (size) {
      case 'md':
        return 'h-7 w-7';
      case 'lg':
        return 'h-9 w-9';
      case 'xl':
        return 'h-12 w-12';
      case '2xl':
        return 'h-16 w-16';
      default:
        return 'h-5 w-5';
    }
  };

  return (
    <CircleSpinner
      className={classNames(
        'animate-spin ml-1 inline ',
        getSizeClassNames(),
        getVariantClassNames()
      )}
    />
  );
};
