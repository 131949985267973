import { ReactElement } from 'react';
import {
  ReactTable as Table,
  useDatalab,
  Badge,
  Cross2Icon,
} from '../../../export';
import DatalabSideSheet from '../DatalabSideSheet';
import ConfirmationModal from './../../../components/common/ConfirmationModal';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
  isSheetOpen: boolean;
  toggleSheetOpen: () => void;
  onApplyFilter: (a: any) => void;
  tableDatalabExportResponse: ExportStateProps;
  setTableDatalabExportResponse: (state: ExportStateProps) => void;
}

interface ExportStateProps {
  show: boolean;
  success: boolean;
  message: {
    title: string;
    description: string | ReactElement;
  };
}

export function DataTableViewOptions<TData>({
  isSheetOpen = false,
  toggleSheetOpen,
  onApplyFilter,
  tableDatalabExportResponse,
  setTableDatalabExportResponse,
}: DataTableViewOptionsProps<TData>) {
  const { selectedDatalabInfo, appliedFilterView, updateAppliedFilterView } =
    useDatalab();

  const handleCrossClick = (filterSlug: string) => {
    const updatedFilter = appliedFilterView?.filter_options.filter(
      (item: any) => item.slug !== filterSlug
    );

    updateAppliedFilterView({
      ...appliedFilterView,
      filter_options: updatedFilter,
    });

    onApplyFilter({
      ...appliedFilterView,
      filter_options: updatedFilter,
    });
  };

  return (
    <>
      <div className='flex flex-wrap'>
        {appliedFilterView?.filter_options?.length > 0 &&
          appliedFilterView?.filter_options.map((filter: any) => (
            <div
              key={filter.slug}
              className='flex items-center mt-1 mr-2 space-x-1'
            >
              <span className='text-xs text-gray-500'>{filter.slug}: </span>
              <span className='text-xs italic text-purple-500'>
                {filter.operator}
              </span>
              <Badge
                variant='ghost'
                className='first-of-type:ml-0 ml-2 bg-gray-100 rounded-md h-[22px] pl-2 pr-[5px]'
              >
                <span className='text-xs font-medium text-textPrimary'>
                  {Array.isArray(filter.value)
                    ? filter.value.join(', ')
                    : filter.value}
                </span>
                <Cross2Icon
                  className='w-3 h-3 ml-1 text-[#A1A1AA] font-semibold hover:cursor-pointer'
                  onClick={() => handleCrossClick(filter.slug)}
                />
              </Badge>
            </div>
          ))}
      </div>

      {isSheetOpen && (
        <DatalabSideSheet
          isOpen={isSheetOpen}
          datalabEntries={{}}
          datalabShape={selectedDatalabInfo}
          header='Enter new Data'
          mode='CREATE'
          onClose={toggleSheetOpen}
        />
      )}

      <ConfirmationModal
        shouldShowCancelButton={false}
        isShown={tableDatalabExportResponse?.show}
        title={tableDatalabExportResponse?.message?.title}
        description={tableDatalabExportResponse?.message?.description}
        intent={tableDatalabExportResponse?.success ? 'primary' : 'danger'}
        shouldRenderDescriptionAsNode={tableDatalabExportResponse?.success}
        confirmButtonTitle='Got it!'
        onConfirm={() =>
          setTableDatalabExportResponse({
            ...tableDatalabExportResponse,
            show: false,
          })
        }
      />
    </>
  );
}
