import useTranslation from 'components/customHooks/useTranslation';
import { LoaderCircle, Plus } from 'lucide-react';
import React from 'react';
import emptyDataIcon from '../../../assets/icons/emptyPageIcon.svg';
import WebhookModal, { IWebhookColumnData } from './components/webhookModal';
import { columns } from './components/webhookTable/Column';
import WebhooksTable from './components/webhookTable/WebhooksTable';
import { useWebhooks } from './hooks/useWebhooks';

const WebhookSettings = () => {
  const { webhookList, clearWebhookData, fetchWebhookBots, isWebhookLoading } =
    useWebhooks();
  const { t } = useTranslation();
  const [openCreateModal, setOpenCreateModal] = React.useState(false);

  React.useEffect(() => {
    const fetchBots = async () => await fetchWebhookBots();

    clearWebhookData();
    fetchBots();
    // eslint-disable-next-line
  }, []);

  // This is a hacky solution to override old component CSS.
  // It directly modifies the padding of the '.layout__content' element.
  // When we move the settings to the new design, we will update it accordingly.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  React.useEffect(() => {
    const layoutContent: any = document.querySelector('.layout__content');
    if (layoutContent) {
      layoutContent.style.padding = '0';
    }
  }, []);

  const renderEmptyState = () => {
    return (
      <div className='flex justify-center flex-col gap-4 items-center h-[500px]'>
        <img src={emptyDataIcon} alt='emptyDataIcon' />
        <div className='flex flex-col gap-1 items-center justify-center'>
          <p className='text-gray-900 text-lg font-bold'>
            {t('No Webhooks Found')}
          </p>
          <p className='text-textSecondary text-sm'>
            {t("You don't have any webhooks created at this moment.")}
          </p>
        </div>

        <button
          className='py-2 px-3 bg-[#04B25F] text-white capitalize
            rounded-lg flex gap-2 items-center'
          onClick={() => setOpenCreateModal(true)}
        >
          <Plus className='w-4 h-4 text-white' />
          {t('Create New Webhook')}
        </button>
      </div>
    );
  };

  const renderLoadingView = () => {
    return (
      <div className='w-full p-6 flex flex-col gap-3 h-[calc(100vh-8px)]'>
        {renderPageHeader()}
        <div className='flex-grow h-full w-full flex justify-center items-center'>
          <LoaderCircle
            strokeWidth={2}
            className='text-green-400 w-12 h-12 animate-spin'
          />
        </div>
      </div>
    );
  };

  const renderPageHeader = () => {
    return (
      <div className='flex flex-col '>
        <p className='text-gray-900 text-lg font-bold'>{t('Webhook')}</p>

        <p className='text-textSecondary text-sm mt-[6px] max-w-3xl subtitle-medium'>
          {t(
            'Webhooks let apps communicate automatically. When an event occurs in one app, like a ticket created, a webhook sends a message to another app, helping automate tasks and keep systems updated.'
          )}{' '}
          <a
            href='https://developers.myalice.ai/webhook/overview'
            className='text-blue-500 underline'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('Learn more')}
          </a>
        </p>
      </div>
    );
  };

  if (isWebhookLoading) {
    return renderLoadingView();
  }
  return (
    <div className='p-6 w-full'>
      {renderPageHeader()}
      <div>
        {webhookList && webhookList.length > 0 ? (
          <div className='flex-grow h-full w-full'>
            <WebhooksTable
              columns={columns}
              data={webhookList as IWebhookColumnData[]}
            />
          </div>
        ) : (
          renderEmptyState()
        )}
      </div>

      {openCreateModal && (
        <WebhookModal
          actionType='CREATE'
          isOpen={openCreateModal}
          setIsOpen={setOpenCreateModal}
        />
      )}
    </div>
  );
};

export default WebhookSettings;
