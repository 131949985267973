interface Props {
  color?: string;
  className?: string;
}

const ChevronUpDown: React.FC<Props> = ({
  color = '#9CA3AF',
  className = 'w-6 h-6',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke={color}
      className={className}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
      />
    </svg>
  );
};

export default ChevronUpDown;
