import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toaster } from 'evergreen-ui';
import CloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  activeSettings: 0,
  selectedProject: null,
  teamInformation: {},
  userInfo: null,
  pendingProjects: [],
  currentProjectRoles: [],
  userRolesType: [],
  adminTags: [],
  teamGroups: [],
  countryList: [],
  countryDetails: {},
  timezoneList: [],
  timezoneDetails: {},
  tags: null,
  webhooks: null,
  cannedResponse: null,
  isUsingNewInbox: true,
  chatBotSettings: {
    keyword: false,
    button: false,
    referral: false,
  },
};

export const settings = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateActiveSettings(state, payload) {
      // payload : num // id of the settings menu
      return { ...state, activeSettings: payload };
    },
    updateUseNewInbox(state, payload) {
      return { ...state, isUsingNewInbox: payload };
    },
    updateUserInfo(state, payload) {
      // payload: object // info about user
      return { ...state, userInfo: payload };
    },
    updateSingleUserInfo(state, payload) {
      // payload = {key: string, value: any }
      return {
        ...state,
        userInfo: { ...state.userInfo, [payload.key]: payload.value },
      };
    },
    updateFacebookMessengerSecondaryId(state, secondaryId) {
      /*
      secondaryId : number
       */
      let selectedProjectLocalPlatforms = CloneDeep(
        state.selectedProject.platforms
      );
      selectedProjectLocalPlatforms.map((platform) => {
        if (platform.type === 'facebook_messenger') {
          platform.secondary_receiver_id = secondaryId;
          return platform;
        }
        return platform;
      });
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          platforms: selectedProjectLocalPlatforms,
        },
      };
    },
    setSelectedProject(state, payload) {
      /*
      payload: int
      */
      return {
        ...state,
        selectedProject: payload,
        countryDetails: {
          country: payload.country,
          country_iso_code: payload.country_iso_code,
          phone_code: payload.phone_code,
          currency_name: payload.currency_name,
          currency_code: payload.currency_code,
          currency_symbol: payload.currency_symbol,
          flag_base64: payload.flag_base64,
        },
        timezoneDetails: {
          timezone_alias: payload.timezone_alias,
          timezone_offset_type: payload.timezone_offset_type,
          timezone_offset: payload.timezone_offset,
          city: payload.city,
        },
      };
    },
    updateTeamInformation(state, payload) {
      return {
        ...state,
        teamInformation: {
          name: payload.name,
          description: payload.description,
          api_key: payload.api_key,
          image: payload.image,
          country: payload.country,
          country_iso_code: payload.country_iso_code,
          phone_code: payload.phone_code,
          currency_name: payload.currency_name,
          currency_code: payload.currency_code,
          currency_symbol: payload.currency_symbol,
          timezone_alias: payload.timezone_alias,
          timezone_offset_type: payload.timezone_offset_type,
          timezone_offset: payload.timezone_offset,
          city: payload.city,
        },
      };
    },
    updateNLPInfo(state, payload) {
      /*
        payload: {key: string, value: string}
       */
      let selectedProject = {
        ...state.selectedProject,
        [payload.key]: payload.value,
      };
      return { ...state, selectedProject };
    },
    updatePendingProjects(state, payload) {
      /* payload : array of pending projects */
      return { ...state, pendingProjects: payload };
    },
    updateRolesData(state, payload) {
      return { ...state, currentProjectRoles: payload };
    },
    updateUserRoles(state, payload) {
      return { ...state, userRolesType: payload };
    },
    updateAdminGroup(state, payload) {
      return { ...state, adminGroup: payload };
    },
    updateAdminGroupDelete(state, payload) {
      return {
        ...state,
        adminGroup: state.adminGroup.filter((group) => group.id !== payload),
      };
    },
    updateAdminGroupAdd(state, payload) {
      let updatedAdmin = state.adminGroup;
      updatedAdmin = updatedAdmin.filter((e) => e.id !== payload.id);

      return { ...state, adminGroup: [...updatedAdmin, payload] };
    },
    updateTeamGroups(state, payload) {
      return { ...state, teamGroups: payload };
    },
    updateCountryList(state, payload) {
      return { ...state, countryList: payload };
    },
    updateTimezoneList(state, payload) {
      return { ...state, timezoneList: payload };
    },
    clearState() {
      return { ...initialState };
    },
    updateTags(state, payload) {
      return { ...state, tags: payload };
    },
    updateWebhooks(state, payload) {
      return { ...state, webhooks: payload };
    },
    updateCannedResponse(state, payload) {
      return { ...state, cannedResponse: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchUserInfo() {
      try {
        const res = await axios.get(config.auth.info());
        dispatch.settings.updateUserInfo(res.data.dataSource);
      } catch (err) {
        return false;
      }
    },
    async editUserInfo(payload) {
      try {
        const res = await axios.patch(config.auth.info(), payload);
        if (res.data.success) {
          dispatch.settings.updateUserInfo(res.data.dataSource);
          dispatch.auth.initLogin(res.data.dataSource);
          toaster.success('Update Info', {
            description: 'Information Updated Successfully',
            duration: 1,
          });
          return true;
        }
      } catch (err) {
        toaster.warning('Error', {
          description: err.response.data.error,
          duration: 1,
        });
        return false;
      }
    },
    async editProjectInfo(projectId, rootState) {
      try {
        const res = await axios.put(config.settings.teamInfo(projectId), {
          name: rootState.settings.teamInformation.name,
          image: rootState.settings.teamInformation.image,
          description: rootState.settings.teamInformation.description,
          country: rootState.settings.teamInformation.country,
          country_iso_code: rootState.settings.teamInformation.country_iso_code,
          phone_code: rootState.settings.teamInformation.phone_code,
          currency_name: rootState.settings.teamInformation.currency_name,
          currency_code: rootState.settings.teamInformation.currency_code,
          currency_symbol: rootState.settings.teamInformation.currency_symbol,
          timezone_alias: rootState.settings.teamInformation.timezone_alias,
          timezone_offset_type:
            rootState.settings.teamInformation.timezone_offset_type,
          timezone_offset: rootState.settings.teamInformation.timezone_offset,
          city: rootState.settings.teamInformation.city,
        });
        if (res.data.success) {
          dispatch.settings.setSelectedProject(res.data.dataSource);
          dispatch.dashboard.setSelectedProject(res.data.dataSource);
          toaster.success('Update Info', {
            description: 'Information Updated Successfully',
            duration: 1,
          });
          return true;
        }
      } catch (err) {
        toaster.warning('Error', {
          description: err.response.data.error,
          duration: 1,
        });
        return false;
      }
    },

    async sendTeamInvitation(payload) {
      /*
      payload = {
        teamId: num,
        email: string,
        roleId : num,
      }
       */

      try {
        const res = await axios.post(
          config.settings.teamInvite(payload.teamId),
          { email: payload.email, role_id: payload.roleId }
        );
        if (res.data.success) {
          toaster.success('Invitation Sent Successfully');
          return { success: true, data: res.data.dataSource };
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
        return { success: false, data: '' };
      }
    },

    async updateIsUsingNewInbox(res, rootState) {
      try {
        if (!!res) {
          let userAccess = res?.filter((item) => {
            return item.admin.email === rootState.auth.email;
          });

          dispatch.settings.updateUseNewInbox(
            userAccess[0]?.is_using_new_inbox
          );
          return userAccess[0];
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async editUseNewInbox(payload, rootState) {
      try {
        const res = await axios.patch(
          config.crm.editUseNewInbox(payload?.projectId, rootState.auth.id),
          {
            is_using_new_inbox: payload?.isUsingNewInbox,
          }
        );
        let isUsingNewInbox = res.data.dataSource?.is_using_new_inbox;

        if (res?.status === 200 && res?.data?.success) {
          dispatch.settings.updateUseNewInbox(isUsingNewInbox);
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Change Inbox Preference.',
            duration: 2,
          });
        }
        return isUsingNewInbox;
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Change is_using_new_inbox.',
          duration: 2,
        });
        return false;
      }
    },

    async acceptTeamInvitation(payload) {
      /*
      payload = {
        token: string,
      }
       */

      try {
        const res = await axios.post(config.auth.acceptInvite(), {
          token: payload.token,
        });
        if (res.data.success) {
          toaster.success('Invitation Accepted');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async deleteUserFromTeam(payload) {
      /*
      payload = {
        teamId: num,
        userId: num
      }
       */

      try {
        const res = await axios.delete(
          config.settings.userActionInTeam(payload.teamId, payload.userId)
        );

        if (res.data.success) {
          toaster.success('User Removed Successfully');
          return res.data.success;
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },

    async updateUserFromTeam(payload) {
      /*
      payload = {
        teamId: num,
        userId: num,
        roleId: num,
      }
       */

      try {
        const res = await axios.patch(
          config.settings.userActionInTeam(payload.teamId, payload.userId),
          { role_id: payload.roleId }
        );

        if (res.data.success) {
          toaster.success('User Updated Successfully');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async fetchTeamGroups(teamId) {
      /*
        payload = {
          teamId: num // Projects ID
        }
       */
      try {
        const res = await axios.get(config.crm.groups(teamId));
        if (res.data.success) {
          dispatch.settings.updateTeamGroups(res.data.dataSource);
        } else {
          dispatch.settings.updateTeamGroups([]);
        }
      } catch (err) {
        err?.response?.data?.error &&
          toaster.danger(err?.response?.data?.error);
      }
    },

    async fetchTeamGroupDetails({ teamId, groupId }) {
      /*
        payload = {
          teamId: num // Projects ID
        }
       */
      try {
        const res = await axios.get(config.crm.groupDetails(teamId, groupId));
        if (res.data.success) {
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        return null;
      }
    },

    async fetchTeamUsers(teamId) {
      /*
        payload = {
          teamId: num // Projects ID
        }
       */
      try {
        const res = await axios.get(config.settings.users(teamId));
        if (res.data.success) {
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        return null;
      }
    },

    async createTeamGroup(payload) {
      /*
        payload = {
          teamId: num // Projects ID
          data: GroupDetailsCardInterface
        }
       */
      try {
        let data = { ...payload.data };
        const res = await axios.post(config.crm.groups(payload.teamId), {
          ...data,
          members: data.members.map((u) => u.id),
        });
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async updateTeamGroup(payload) {
      /*
        payload = {
          teamId: num // Projects ID
          data: GroupDetailsCardInterface
        }
       */
      try {
        let data = { ...payload.data };
        const res = await axios.put(
          config.crm.groupDetails(payload.teamId, data.id),
          {
            ...data,
            members: data.members.map((u) => u.id),
          }
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async deleteTeamGroup(payload) {
      /*
        payload = {
          teamId: num // Projects ID
          groupId: num
        }
       */
      try {
        const res = await axios.delete(
          config.crm.groupDetails(payload.teamId, payload.groupId)
        );
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },

    async createPlatform(payload) {
      /*
      payload = {
        id : num,
        platforms: array
      }
       */
      try {
        const res = await axios.post(config.channel.initialize(payload.id), {
          platforms: payload.platforms,
        });
        if (res.data.success) {
          toaster.success('Platform Created Successfully');
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          await dispatch.settings.setSelectedProject(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          config.channel.facebookPagesWithoutType(payload.id, payload.token)
        );
        return res.data;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectPlatform(payload) {
      /*
        payload = {
          id: num // project id
          type: string // facebook || viber
          data: object // page information
        }
       */
      try {
        const res = await axios.post(
          config.channel.connectChannel(payload.id),
          { type: payload.type, data: payload.data }
        );
        if (res.data.success) {
          await dispatch.settings.setSelectedProject(res.data.dataSource);
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          if (res.data.dataSource.platforms.length > 0) {
            await dispatch.dashboard.setSelectedPlatform(
              res.data.dataSource.platforms[0]
            );
          }
          toaster.success('Platform Connected Successfully!');
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async disconnectPlatform(payload) {
      /*
       payload = {
         id: num // project id
         type: string // facebook || viber
       }
      */
      try {
        const res = await axios.post(
          config.channel.disconnectChannel(payload.id),
          { type: payload.type }
        );
        if (res.data.success) {
          await dispatch.settings.setSelectedProject(res.data.dataSource);
          await dispatch.dashboard.fetchProject();
          await dispatch.dashboard.setSelectedProject(res.data.dataSource);
          if (res.data.dataSource.platforms.length > 0) {
            await dispatch.dashboard.setSelectedPlatform(
              res.data.dataSource.platforms[0]
            );
          }
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectNLPEngine(payload, rootState) {
      /*
      payload = null
      */
      try {
        const nlp_engine = rootState.settings.selectedProject.nlp_engine;
        const nlp_info = rootState.settings.selectedProject.nlp_info;
        const nlp_confidence =
          rootState.settings.selectedProject.nlp_confidence;

        const projectId = rootState.settings.selectedProject.id;
        if (!!nlp_engine && !isEmpty(nlp_info) && !!projectId) {
          if (nlp_engine === 'wit' && !!nlp_info.token && !!nlp_info.app_id) {
            const res = await axios.patch(config.settings.teamInfo(projectId), {
              nlp_engine,
              nlp_info,
              nlp_confidence,
            });
            if (res.data.success) {
              toaster.success('Updated SuccessFully', {
                description: `Information Updated Successfully!`,
              });
              dispatch.settings.setSelectedProject(res.data.dataSource);
              dispatch.dashboard.fetchProject();
            }
          } else {
            toaster.warning('Data Missing', {
              description: 'Provide all required field',
            });
          }
        } else {
          toaster.warning('Data Missing', {
            description: 'Provide all required field',
          });
        }
      } catch (e) {
        toaster.danger('Failed to Connect (Exception)', {
          description: 'There is a Problem in Our System. Please re-try  again',
        });
      }
    },
    async connectHandoverProtocol(payload) {
      /*
      payload =
          platformId: number
          appId: string
       */
      try {
        const res = await axios.post(
          config.channel.secondaryReciever(payload.platformId),
          {
            app_id: payload.appId,
          }
        );
        if (res.data.success) {
          toaster.success('Handover Protocol Updated', {
            description: 'Congrats! Handover Protocol Has been Updated',
          });
          dispatch.settings.updateFacebookMessengerSecondaryId(payload.appId);
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
    async editPersistentMenu(payload) {
      /* payload =
          platformId: number ,
          data: {
                  button: [
            {
                'id': num,
                'title': string,
                'type': string,
                'value': number/string,
            },
            ...
            ]
         }
     */

      try {
        const res = await axios.post(
          config.channel.persistentMenu(payload.platformId),
          payload.data
        );

        if (res.data.success) {
          dispatch.settings.setSelectedProject(res.data.dataSource);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchPendingProjects() {
      try {
        const res = await axios.get(config.settings.pendingTeam());
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updatePendingProjects(res.data.dataSource);
        } else {
          toaster.danger('Failed to Delete this Admin Group');
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed to Delete this Admin Group');
      }
    },
    async editPendingProject(payload, rootState) {
      /* payload :
            project_id: number;
            accept: boolean;
       */
      try {
        const res = await axios.post(config.settings.pendingTeam(), payload);
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updatePendingProjects(
            rootState.settings.pendingProjects.filter(
              (pendingProject) =>
                pendingProject.project?.id !== payload.project_id
            )
          );
          dispatch.dashboard.fetchProject();
          toaster.success(res.data?.message || 'Project Added Successfully');
        } else {
          toaster.danger('Failed to update permission of this project');
        }
      } catch (err) {
        toaster.danger(
          err.response?.data?.error ||
            'Failed to update permission of this project'
        );
      }
    },
    async fetchProjectAccess(projectId) {
      try {
        const res = await axios.get(config.settings.agentAccess(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateRolesData(res.data.dataSource);
        } else {
          dispatch.settings.updateRolesData([]);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchPermissionRole(projectId) {
      try {
        const res = await axios.get(config.settings.agentRoles(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateUserRoles(res.data.dataSource);
        } else {
          dispatch.settings.updateUserRoles([]);
        }
      } catch (err) {
        dispatch.settings.updateUserRoles([]);
        console.log(err.response);
      }
    },
    async shareProject(payload) {
      /*
      payload = {
        id : num,
        email: string,
        roleId: number //id of role
      }
       */
      try {
        const res = await axios.post(config.settings.share(payload.id), {
          email: payload.email,
          role_id: payload.roleId,
        });

        if (res.status === 200 && res.data.success) {
          toaster.success('Project Shared Successfully');
          return true;
        } else {
          toaster.danger('Failed to Share Project');
          return false;
        }
      } catch (err) {
        if (!!err.response && !!err.response.data) {
          toaster.danger(err.response.data.error);
        } else {
          toaster.danger('Failed to Share Project');
        }
        return false;
      }
    },
    async deleteAdminRole(payload) {
      /*
      payload = {
        id : num, //project id
        roleId: number //id of role
      }
       */
      try {
        const res = await axios.delete(
          config.settings.userActionInTeam(payload.id, payload.roleId)
        );

        if (res.status === 200 && res.data.success) {
          toaster.success('Deleted Successfully');
          dispatch.settings.fetchProjectAccess(payload.id);
        } else {
          toaster.danger('Failed to Delete Admin');
        }
      } catch (err) {
        if (!!err.response && !!err.response.data) {
          toaster.danger(err.response.data.error);
        } else {
          toaster.danger('Failed to Delete Admin');
        }
      }
    },
    async fetchAdminGroup(teamId) {
      /*
      teamId: number
       */
      try {
        const res = await axios.get(config.settings.agentAccessTag(teamId));
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroup(res.data.dataSource);
        } else {
          dispatch.settings.updateAdminGroup([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.settings.updateAdminGroup([]);
      }
    },
    async deleteAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: number
      }
       */
      try {
        const res = await axios.delete(
          config.settings.agentAccessTagAction(
            payload.projectId,
            payload.groupId
          )
        );
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupDelete(payload.groupId);
          toaster.success('Successful', {
            description: 'Successfully Deleted Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Delete Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Delete Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async createAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        name: string
      }
       */
      try {
        const res = await axios.post(
          config.settings.agentAccessTag(payload.projectId),
          { name: payload.name }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupAdd(res.data.dataSource);
          toaster.success('Successful', {
            description: 'Successfully Added Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Add Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async editAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: num
        name: string
      }
       */
      try {
        const res = await axios.patch(
          config.settings.agentAccessTagAction(
            payload.projectId,
            payload.groupId
          ),
          { name: payload.name }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateAdminGroupAdd(res.data.dataSource);
          toaster.success('Successful', {
            description: 'Successfully Added Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Error', {
          description: 'Failed to Add Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async assignAdminGroup(payload) {
      /*
      payload = {
        projectId : num, //project id
        groupId: num
        tags: string[]
      }
       */
      try {
        const res = await axios.put(
          config.settings.userActionInTeam(payload.projectId, payload.adminId),
          { tag_ids: payload.tags }
        );

        if (res.status === 200 && res.data.success) {
          dispatch.settings.fetchProjectAccess(payload.projectId);
          toaster.success('Successful', {
            description: 'Successfully Assigned Admin Group',
            duration: 1,
          });
        } else {
          toaster.danger('Error', {
            description: 'Failed to Add Assign Admin Group ! Try Again',
            duration: 1,
          });
        }
      } catch (err) {
        toaster.danger('Error', {
          description: 'Failed to Assign Admin Group ! Try Again',
          duration: 1,
        });
      }
    },
    async fetchCountryList(payload) {
      try {
        const res = await axios.get(config.misc.countryList());
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateCountryList(res.data.dataSource);
        } else {
          dispatch.settings.updateCountryList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.settings.updateCountryList([]);
      }
    },
    async fetchTimezoneList(payload) {
      try {
        const res = await axios.get(config.misc.timezoneList());
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateTimezoneList(res.data.dataSource);
        } else {
          dispatch.settings.updateTimezoneList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.settings.updateTimezoneList([]);
      }
    },

    async sendMultipleTeamInvitation(payload) {
      try {
        const res = await axios.post(
          config.settings.sendMultipleInvitation(payload.teamId),
          payload.data
        );
        if (res.data.success) {
          return {
            emails: res.data.dataSource.successful_invitations,
            success: true,
          };
        }
      } catch (err) {
        return { emails: null, success: false };
      }
    },
    async fetchTags(teamId) {
      try {
        const res = await axios.get(config.settings.tags(teamId));
        if (res.status === 200) {
          dispatch.settings.updateTags(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createTags(payload) {
      try {
        const res = await axios.post(
          config.settings.tags(payload.teamId),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchTags(payload.teamId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async deleteTags(payload) {
      try {
        const res = await axios.post(
          config.settings.deleteTag(payload.projectId),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchTags(payload.projectId);
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    async editTag(payload) {
      try {
        const res = await axios.patch(
          config.settings.editTag(payload.projectId, payload.tagId),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchTags(payload.projectId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async fetchWebhook(teamId) {
      try {
        const res = await axios.get(config.settings.getOrCreateWebhook(teamId));
        if (res.status === 200) {
          dispatch.settings.updateWebhooks(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createWebhook(payload) {
      try {
        const res = await axios.post(
          config.settings.getOrCreateWebhook(payload.teamId),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchWebhook(payload.teamId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return { success: false, data: err.response.data };
      }
    },
    async deleteWebhook(payload) {
      try {
        console.log(payload);
        const res = await axios.delete(
          config.settings.deleteOrEditWebhook(
            payload.projectId,
            payload.webhookId
          )
        );
        if (res.data.success) {
          dispatch.settings.fetchWebhook(payload.projectId);
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    async editWebhook(payload) {
      try {
        const res = await axios.patch(
          config.settings.deleteOrEditWebhook(
            payload.projectId,
            payload.webhookId
          ),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchWebhook(payload.projectId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async fetchCannedResponse(teamId) {
      try {
        const res = await axios.get(config.settings.cannedResponse(teamId));
        if (res.status === 200) {
          dispatch.settings.updateCannedResponse(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createCannedResponse(payload) {
      try {
        const res = await axios.post(
          config.settings.cannedResponse(payload.projectId),
          payload.data
        );
        if (res.status === 200) {
          dispatch.settings.fetchCannedResponse(payload.projectId);

          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async deleteCannedResponse(payload) {
      try {
        const res = await axios.delete(
          config.settings.deleteCannedResponse(
            payload.projectId,
            payload.cannedResponseId
          )
        );
        if (res.data.success) {
          dispatch.settings.fetchCannedResponse(payload.projectId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async editCannedResponse(payload) {
      try {
        const res = await axios.patch(
          config.settings.editCannedResponse(
            payload.projectId,
            payload.cannedResponseId
          ),
          payload.data
        );
        if (res.data.success) {
          dispatch.settings.fetchCannedResponse(payload.projectId);
          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async editUserLanguageInfo(payload) {
      try {
        const res = await axios.patch(config.auth.info(), payload);

        if (res.data.success) {
          dispatch.settings.updateUserInfo(res.data.dataSource);
          dispatch.auth.updateStateData({
            key: 'dashboard_language',
            value: payload.dashboard_language,
          });
          toaster.success('Update Info', {
            description: 'Language updated successfully',
            duration: 1,
          });
          return true;
        }
        return false;
      } catch (err) {
        toaster.warning('Error', {
          description: err.response.data.error,
          duration: 1,
        });
        return false;
      }
    },

    async fetchChatBotSettings(projectId) {
      try {
        const res = await axios.get(
          config.settings.getChatBotSettings(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateStateData({
            key: 'chatBotSettings',
            value: { ...res.data.dataSource },
          });
        }
      } catch (err) {
        toaster.warning(
          'Something went wrong, please try agin after some time'
        );
      }
    },

    async updateChatBotSettings(payload) {
      /**
       * payload
       * projectId
       * settings {keyword,button,referral}
       */
      try {
        const res = await axios.post(
          config.settings.updateChatBotSettings(payload.projectId),
          {
            ...payload.settings,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.settings.updateStateData({
            key: 'chatBotSettings',
            value: { ...res.data.dataSource },
          });
          toaster.success('Chatbot settings updated successfully.');
        }
      } catch (err) {
        toaster.warning(
          'Something went wrong, please try agin after some time'
        );
      }
    },
    async updateTagMandatorySettings(payload) {
      /**
       * payload
       * projectId
       * isEnabled
       */
      try {
        const res = await axios.put(
          config.settings.updatetagMandatory(payload.projectId),
          {
            should_require_tag_to_close_tickets: payload.isEnabled,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updateSelectedProject({
            ...res.data.dataSource,
          });
          toaster.success('Tag settings updated successfully');
        }
      } catch (err) {
        toaster.warning(
          'Something went wrong, please try agin after some time'
        );
      }
    },

    async getTicketConfigurationData(projectId) {
      /**
       * projectId
       */
      try {
        const res = await axios.get(
          config.settings.getTicketConfigurationSettings(projectId)
        );
        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else return null;
      } catch (err) {
        toaster.warning(
          'Something went wrong, please try agin after some time'
        );
        return null;
      }
    },

    async updateTicketConfigurationData(payload) {
      /**
       * payload
       * projectId
       * data
       */
      try {
        const res = await axios.post(
          config.settings.updateTicketConfigurationSettings(payload.projectId),
          {
            ...payload.data,
          }
        );
        if (res.status === 200 && res.data.success) {
          return { ...res.data.dataSource };
        }
        return null;
      } catch (err) {
        return null;
      }
    },
  }),
};
