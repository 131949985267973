import { connect } from 'react-redux';
import useTranslation from 'components/customHooks/useTranslation';
import congratulationsIcon from '../../../assets/images/congratulationsIcon.svg';

interface Props {
  width?: string;
  height?: string;
  isOpen: boolean;
  setIsOpen: (payload: boolean) => void;
  handleSendConfirmationLink: () => void;
  shopifyOrderName: string;
  isSendingMessage: boolean;
}

const SuccessModal: React.FC<Props> = ({
  width = 'w-20',
  height = 'h-auto',
  isOpen,
  setIsOpen,
  handleSendConfirmationLink,
  shopifyOrderName,
  isSendingMessage,
}) => {
  const { t } = useTranslation();
  const renderLoadingUi = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          className={`border-r-transparent border-t-white-500
          border-b-white-500 border-l-white-500 animate-spin inline-block h-5
          w-5 border-2 rounded-full`}
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  };

  const renderModalBodyView = () => {
    return (
      <div className='flex flex-col w-full justify-center items-center p-5'>
        <img src={congratulationsIcon} alt='' />
        <p className='text-gray-900 font-normal text-base'>
          {t('Congratulations')}!
        </p>
        <p className='text-gray-400 mb-3'>
          {t('You have successfully placed an')}{' '}
          <span className='text-[#6B7280]'>order {shopifyOrderName}</span>
        </p>
        <button
          className='p-2 bg-[#04B25F] border border-[#04B25F]
                 rounded-md w-full text-white mb-2'
          onClick={() => handleSendConfirmationLink()}
        >
          {isSendingMessage ? renderLoadingUi() : t('Send Checkout Link')}
        </button>
        <button
          className='p-2 bg-white border border-gray-200 rounded-md
                w-full text-gray-600 font-normal'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {t('Back to Chat')}
        </button>
      </div>
    );
  };

  const renderModalView = () => {
    return (
      <div
        className='fixed z-10 inset-0 overflow-y-auto'
        aria-labelledby='modal-title'
        role='dialog'
        aria-modal='true'
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          />
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          ></span>
          <div
            className={`relative inline-block align-bottom bg-white text-left
          shadow-xl transform transition-all sm:my-8 sm:align-middle ${width} rounded-md ${height}`}
          >
            {renderModalBodyView()}
          </div>
        </div>
      </div>
    );
  };

  return <>{isOpen && renderModalView()}</>;
};

const mapState = (state: any) => ({
  isSendingMessage: state.loading.effects.inbox.sendMessegengerChannelMessage,
});

const mapDispatch = (state: any) => ({});

export default connect(mapState, mapDispatch)(SuccessModal);
