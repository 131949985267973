import React from 'react';
import InputOptionsDropdown from './/InputOptionsDropdown';
import { TrashIcon } from '@heroicons/react/20/solid';
import Button from '../../../../library/button';
import TextVariableInputField from './TextVariableInputFeild';

interface Props {
  id: number;
  name: string;
  attribute: string;
  type: string;
  hasOption: boolean;
  options: string[];
  platformType: string;
  hasWriteAccess: boolean;
  attributeList: string[];
  showAttributeField: number | null;
  setShowAttributeField: (val: any) => void;
  handleParameterDelete: (name: string, index: number) => void;
  handleSelectedParamsInputValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleSelectedParamsAttributeValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
}

const ParameterInputField: React.FC<Props> = ({
  id,
  name,
  attribute,
  hasWriteAccess,
  attributeList,
  platformType,
  hasOption,
  options,
  showAttributeField,
  setShowAttributeField,
  type,
  handleSelectedParamsInputValue,
  handleSelectedParamsAttributeValue,
  handleParameterDelete,
}) => {
  return (
    <div className='flex w-full' key={id}>
      <div className='w-1/2 h-10 mt-3 mr-3 border border-gray-300 border-solid rounded'>
        <h3 className='py-2 ml-3 text-sm text-gray-700'>{name}</h3>
      </div>
      <div className='flex justify-end w-1/2 h-10 mt-3 rounded'>
        {hasOption ? (
          <InputOptionsDropdown
            id={id}
            hasWriteAccess={hasWriteAccess}
            options={options}
            name={name}
            type={type}
            value={attribute}
            handleFieldValue={handleSelectedParamsInputValue}
          />
        ) : (
          <TextVariableInputField
            id={id}
            disabled={!hasWriteAccess || name === 'After'}
            value={attribute}
            type={type}
            attributeList={attributeList}
            showAttribute={id === showAttributeField}
            setShowAttribute={setShowAttributeField}
            placeHolder={
              type === 'number'
                ? 'Add number and variable'
                : 'Add text and variable'
            }
            handleOnChange={(value: string) =>
              handleSelectedParamsInputValue(
                id,
                name,
                value,
                `input_instructions`
              )
            }
            handleSelect={(value: any) =>
              handleSelectedParamsAttributeValue(
                id,
                name,
                value,
                'input_instructions'
              )
            }
            hasWriteAccess={hasWriteAccess}
          />
        )}
        {id > 1 && (
          <Button
            icon={<TrashIcon className='text-gray-500' />}
            className='h-10 ml-3 text-gray-700 border-gray-300'
            onClick={() => handleParameterDelete(name, id)}
          />
        )}
      </div>
    </div>
  );
};

ParameterInputField.defaultProps = {
  hasWriteAccess: false,
};

export default ParameterInputField;
