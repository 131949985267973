interface Props {
  width?: string;
  height?: string;
  isOpen: boolean;
  children: JSX.Element;
  positionCss?: string;
  hasStyle?: boolean;
  disableFullScreen?: boolean;
  setOpen?: (data: boolean) => void;
}

const BroadcastModal: React.FC<Props> = ({
  width,
  height,
  hasStyle,
  isOpen,
  children,
  positionCss,
  disableFullScreen,
  setOpen,
}) => {
  return (
    <>
      {isOpen && (
        <div
          style={hasStyle ? { width: 'calc(100% - 65px)' } : {}}
          className={`inset-0 overflow-y-auto z-10 fixed ${
            disableFullScreen
              ? ''
              : 'custom-broadcast__modal left-16 h-full ml-0.5'
          }`}
          aria-labelledby='modal-title'
          role='dialog'
          aria-modal='true'
        >
          <div
            className={`flex items-end justify-center pt-4 px-4 pb-20 text-center sm:p-0 ${
              disableFullScreen ? 'min-h-screen sm:block ' : 'w-full h-full'
            }`}
          >
            {disableFullScreen && (
              <div
                className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75'
                aria-hidden='true'
                onClick={() => {
                  setOpen && setOpen(false);
                }}
              />
            )}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            ></span>
            <div
              className={`relative inline-block align-bottom bg-white text-left
                          shadow-xl transform transition-all  ${
                            disableFullScreen
                              ? `sm:my-8 sm:align-middle ${width} rounded-md ${height}`
                              : 'w-full h-full box-border'
                          }`}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BroadcastModal;
