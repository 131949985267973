import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { Link } from 'library';
import React from 'react';
import { footerData } from 'utilities/content';
import IomnihubPowerdBy from '../../../assets/icons/partnerIconSet/Infocomm-Logo-on-Powered.svg';

interface Props {}

const LoginFooter: React.FC<Props> = () => {
  const { authT } = useTranslation();
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();

  const shouldRenderPoweredBy = isPartnerRestricted(
    'Footer/function/iomni-powerd-by'
  );

  const renderPoweredByLogo = () => {
    if (!shouldRenderPoweredBy) {
      return null;
    }
    switch (currentPartnerInfo?.domain) {
      case 'app.iomnihub.ai':
        return (
          <div className='w-full flex  flex-col justify-center items-center mt-4'>
            <img
              id='iomni-powerd-by'
              className='mt-8'
              src={IomnihubPowerdBy}
              width={128}
              height={128}
              alt='hello'
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className=''>
      {shouldRenderPoweredBy ? (
        renderPoweredByLogo()
      ) : (
        <footer
          className={`fixed w-full ltr:left-0 rtl:right-0 flex justify-center flex-col items-center bottom-0 mb-6`}
        >
          <p className=' text-gray-600'>
            {`${footerData.company} ${authT(footerData.text)}`}
          </p>
          <p>
            {authT('Read about our')}
            <Link openInNewTab={true} url={footerData.privacy.link}>{` ${authT(
              footerData.privacy.title
            )}`}</Link>
          </p>
        </footer>
      )}
    </div>
  );
};
export default LoginFooter;
