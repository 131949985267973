import React from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import { classNames } from 'utilities/utils';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  IWhatsappTemplateButton,
  IWhatsappTemplateButtonType,
  sequenceProps,
} from 'pages/integration/interface';
import { WhatsappTemplateButtonLimits } from 'pages/integration/utils/content';
import { countBy } from 'lodash';
import {
  WhatsappSingleSearchableSelectMenu,
  WhatsappSingleSelectMenu,
} from './WhatsappTemplateSelectionMenu';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';

const allTemplateButtonTypes: ITemplateButtons[] = [
  { label: 'Sequence', subLabel: '', value: 'sequence', isDisabled: false },
  {
    label: 'URL',
    value: 'url',
    subLabel: 'Can be added twice',
    isDisabled: false,
  },
  {
    label: 'Call Phone Number',
    value: 'phone_number',
    subLabel: 'Can be added once',
    isDisabled: false,
  },
];

type TAllowedTemplateButtonTypes =
  typeof allTemplateButtonTypes[number]['value'];

interface Props {
  actionType?: WhatsappTemplateActionType;
  buttonData: IWhatsappTemplateButton;
  sequenceList: sequenceProps[];
  buttonLimits?: any;
  shouldDisableButtonVariable?: boolean;
  allowedButtonTypes?: TAllowedTemplateButtonTypes[];
  shouldShowSelectMenuToolTip?: boolean;
  selectMenuTooltip?: string;
  deleteButtonTooltip?: string;
  buttonList?: IWhatsappTemplateButton[];
  showSelectMenuSublabel?: boolean;
  shouldDisableSelectMenu?: boolean;
  shouldDisableDeleteButton?: boolean;
  handleUpdateButton: (buton: IWhatsappTemplateButton) => void;
  handleDeleteButton: (button: IWhatsappTemplateButton) => void;
}

interface ITemplateButtons {
  label: string;
  value: IWhatsappTemplateButtonType;
  isDisabled: boolean;
  subLabel?: string;
}

const maxButtonTitleLength = 25;

const WhatsappTemplateButton: React.FC<Props> = ({
  buttonData,
  buttonList = [],
  sequenceList,
  actionType,
  handleUpdateButton,
  handleDeleteButton,
  buttonLimits = WhatsappTemplateButtonLimits,
  shouldDisableButtonVariable = false,
  shouldShowSelectMenuToolTip = false,
  selectMenuTooltip = '',
  deleteButtonTooltip = '',
  allowedButtonTypes = allTemplateButtonTypes.map((b) => b.value),
  showSelectMenuSublabel = true,
  shouldDisableDeleteButton = false,
  shouldDisableSelectMenu = false,
}) => {
  const { t } = useTranslation();
  const hasVariable = buttonData?.value?.toString().endsWith('{{1}}');
  const isTemplateActionView = actionType === 'VIEW';
  const buttonCount = countBy(buttonList, 'type');
  const buttonValue = buttonData?.value || '';
  const buttonTitle = buttonData?.title || '';
  const selectedButtonType = buttonData?.type || '';
  const buttonTypes = allTemplateButtonTypes.filter((btn) =>
    allowedButtonTypes.includes(btn.value)
  );
  shouldShowSelectMenuToolTip =
    shouldShowSelectMenuToolTip && !isTemplateActionView;
  const templateButtonTypes = buttonTypes.map((singleType) => {
    const shouldDisabled =
      buttonCount[singleType.value] >= buttonLimits[singleType.value];

    return { ...singleType, isDisabled: shouldDisabled };
  });

  const handleButtonTypeSelect = (btnType: IWhatsappTemplateButtonType) => {
    handleUpdateButton({ ...buttonData, type: btnType });
  };

  const handleButtonValue = (value: string) => {
    const oldValue = buttonData?.value ?? '';
    const modifiedButton: IWhatsappTemplateButton = {
      ...buttonData,
      value: value,
    };

    switch (selectedButtonType) {
      case 'sequence':
        modifiedButton['verbose'] =
          sequenceList.find(
            (sequence) => sequence.id.toString() === value.toString()
          )?.title || '';
        break;
      case 'url':
        if (
          !hasVariable &&
          !shouldDisableButtonVariable &&
          value.length > oldValue.toString().length &&
          value.endsWith('{{')
        ) {
          modifiedButton.value = value + '1}}';
          break;
        }
        const variablePattern = '{{1}}';
        const patternIndex = value.indexOf(variablePattern);
        if (patternIndex !== -1) {
          modifiedButton.value = value
            .toString()
            .slice(0, patternIndex + variablePattern.length);
        }
        break;
      default:
        break;
    }

    handleUpdateButton({
      ...buttonData,
      ...modifiedButton,
    });
  };

  const handleUrlVariableChange = (value: string) => {
    handleUpdateButton({
      ...buttonData,
      variableValue: value,
    });
  };

  const handleButtonTitle = (value: string) => {
    handleUpdateButton({
      ...buttonData,
      title: value,
    });
  };

  const handleTemplateButtonDelete = () => {
    handleDeleteButton(buttonData);
  };

  const handleAddVariable = () => {
    if (!hasVariable) {
      handleButtonValue(buttonValue + '{{1}}');
    }
  };

  const renderButtonTitleInput = () => {
    return (
      <div className='relative rounded-md shadow-sm'>
        <input
          disabled={isTemplateActionView}
          autoComplete='false'
          type='text'
          className={`w-full ${
            isTemplateActionView
              ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
              : 'bg-white text-gray-700'
          } rounded-md border-gray-300 ltr:pr-10 rtl:pl-10 placeholder-gray-400 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm`}
          placeholder={t('Enter button title')}
          name='buttonTitle'
          onChange={(e) => handleButtonTitle(e.target.value)}
          maxLength={maxButtonTitleLength}
          value={buttonTitle}
        />
        <div className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-2 flex items-center pr-2'>
          <p
            className={`text-xs bg-green-100 text-green-800 py-1 px-1  rounded`}
          >
            {buttonTitle?.length}/{maxButtonTitleLength}
          </p>
        </div>
      </div>
    );
  };

  const renderUrlInput = () => {
    const urlValue =
      isTemplateActionView && buttonValue.toString().startsWith('https://')
        ? buttonValue.toString().replace('https://', '')
        : buttonValue;
    return (
      <div className='flex flex-col gap-2'>
        <div className='flex items-center rounded-md relative'>
          <span className='absolute ltr:ml-[2px] rtl:mr-0.5 flex justify-center items-center p-2 bg-gray-100 h-[92%] ltr:rounded-l-md rtl:rounded-r-md text-sm text-gray-500'>
            https://
          </span>
          <input
            disabled={isTemplateActionView}
            autoComplete='false'
            type='text'
            className={`w-full ${
              isTemplateActionView
                ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                : 'bg-white text-gray-700'
            } ltr:pl-[75px] rtl:pr-[75px] rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm`}
            placeholder={t('Enter URL')}
            onChange={(e) => handleButtonValue(e.target.value)}
            value={urlValue}
          />
        </div>
        <div>
          <div
            className={`flex justify-end ${
              isTemplateActionView ? 'hidden' : 'visible'
            }`}
          >
            {hasVariable && !shouldDisableButtonVariable ? (
              <input
                type='text'
                disabled={isTemplateActionView}
                placeholder='Type URL variable value'
                value={buttonData?.variableValue || ''}
                onChange={(ev) => handleUrlVariableChange(ev.target.value)}
                name='header-variable-value'
                className={classNames(
                  'w-full sm:text-sm border-gray-300 focus:border-none focus:ring-2 focus:ring-green-500 rounded-md',
                  isTemplateActionView
                    ? 'bg-gray-100 cursor-not-allowed'
                    : 'bg-white'
                )}
              />
            ) : (
              <button
                disabled={hasVariable || isTemplateActionView}
                onClick={handleAddVariable}
                className={cn(
                  'flex items-center gap-1 disabled:cursor-not-allowed px-2 py-1 rounded-md',
                  isTemplateActionView
                    ? 'text-gray-500 bg-gray-100 hidden'
                    : 'text-indigo-400 bg-indigo-50',
                  { hidden: shouldDisableButtonVariable }
                )}
              >
                <PlusIcon
                  className={`w-5 font-medium h-5 ${
                    isTemplateActionView ? 'text-gray-500' : 'text-indigo-400'
                  }`}
                />
                <span>{t('Add Variables')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderButtonTypeSpecificInput = () => {
    switch (selectedButtonType) {
      case 'phone_number':
        return (
          <div className='grid grid-cols-2 gap-2 w-full'>
            {renderButtonTitleInput()}
            <div className='flex items-center rounded-md relative'>
              <input
                disabled={isTemplateActionView}
                autoComplete='false'
                type='number'
                onWheel={(e) => e.currentTarget.blur()}
                className={`w-full ${
                  isTemplateActionView
                    ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                    : 'bg-white text-gray-700'
                } rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm`}
                placeholder={t('Enter phone number with country code')}
                onChange={(e) => handleButtonValue(e.target.value)}
                value={buttonValue}
              />
            </div>
          </div>
        );

      case 'sequence':
        return (
          <div className='grid grid-cols-2 gap-2 w-full'>
            {renderButtonTitleInput()}
            <WhatsappSingleSearchableSelectMenu
              searchFieldPlaceholder={t('Search an option')}
              value={buttonValue?.toString() || ''}
              shouldDisableField={isTemplateActionView}
              onChange={handleButtonValue}
              optionList={sequenceList?.map((v) => ({
                label: v.title,
                value: v.id,
              }))}
            />
          </div>
        );

      case 'url':
        return (
          <div className='flex flex-col gap-2'>
            {renderButtonTitleInput()}
            {renderUrlInput()}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className='flex-1 w-full border flex flex-col gap-2 border-gray-300 rounded-md p-3 button-container'>
      <div className='WA-template-create-first-row flex justify-between gap-2'>
        <div className='flex-1'>
          <TooltipProviderCustomised
            content={shouldShowSelectMenuToolTip && selectMenuTooltip}
          >
            <WhatsappSingleSelectMenu
              optionLists={templateButtonTypes}
              placeholder={t('Select button type')}
              onChange={handleButtonTypeSelect}
              value={selectedButtonType}
              isDisabled={isTemplateActionView || shouldDisableSelectMenu}
              hasSubLabel={showSelectMenuSublabel}
            />
          </TooltipProviderCustomised>
        </div>
        <span className='flex-1 flex justify-end items-center'>
          <TooltipProviderCustomised content={deleteButtonTooltip}>
            <button
              disabled={isTemplateActionView || shouldDisableDeleteButton}
              onClick={handleTemplateButtonDelete}
              className={cn(
                'rounded-md p-3 border border-gray-300',
                isTemplateActionView || shouldDisableDeleteButton
                  ? 'bg-gray-100 cursor-not-allowed'
                  : 'bg-white'
              )}
            >
              <TrashIcon
                className={cn(
                  'w-4 h-auto',
                  isTemplateActionView || shouldDisableDeleteButton
                    ? 'bg-gray-100 text-gray-300'
                    : 'bg-white text-gray-500'
                )}
              />
            </button>
          </TooltipProviderCustomised>
        </span>
      </div>
      {renderButtonTypeSpecificInput()}
    </div>
  );
};

export default WhatsappTemplateButton;
