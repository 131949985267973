import AppSumoLogo from 'assets/icons/component/AppSumo';
import PropTypes from 'prop-types';
import logoLight from '../../../assets/images/logo-light-bg.svg';

import { PlusIcon } from '@heroicons/react/24/outline';
import { navigate } from '@reach/router';
import ZidWithAliceIcon from 'assets/icons/component/ZidWithAlice';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { Link } from '../../../library';
const AuthHeader = ({
  image = logoLight,
  title,
  subtitle,
  link,
  linkText,
  isLogo = true,
  partnerDomainName = '',
  hasAppsumoLogo = false,
}) => {
  const { currentPartnerInfo, isPartnerRestricted, isParnterDomain } =
    usePartner();

  let isZidConnection = false;

  const previousPath = localStorage.getItem('previousPath') || '';
  if (!!previousPath && previousPath.toLowerCase().includes('zid-connect')) {
    isZidConnection = true;
  }

  const { authT } = useTranslation();
  const shouldRenderMyAliceLogo =
    !isZidConnection && !isPartnerRestricted('AuthHeader/node/alice_logo');
  const shouldDisableSingUpUrl = isPartnerRestricted(
    'AuthHeader/node/sign_up_url'
  );
  const isWhiteLabelPartner = currentPartnerInfo?.type === 'white-label';
  return (
    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='flex items-center justify-center space-x-3'>
        {shouldRenderMyAliceLogo ? (
          <img
            id='alice_logo'
            className={
              (isLogo ? ' h-8 ' : ' h-auto ') + 'w-auto cursor-pointer'
            }
            src={image ? image : logoLight}
            alt='alice labs pte'
            onClick={() => navigate(`/`)}
          />
        ) : isZidConnection ? (
          <ZidWithAliceIcon />
        ) : null}
        {hasAppsumoLogo && (
          <>
            <PlusIcon className='w-5 h-5 text-gray-500' />
            <AppSumoLogo width='120' />
          </>
        )}
        {isParnterDomain ? (
          isWhiteLabelPartner ? null : (
            <PlusIcon className='w-5 h-5 text-gray-500' />
          )
        ) : null}
        {currentPartnerInfo?.logo}
      </div>

      <div className='mt-6 mb-8 '>
        <h2 className='text-3xl font-extrabold text-center text-gray-900'>
          {authT(title)}
        </h2>
        <p
          className={`text-center text-gray-600 text-md ${
            shouldDisableSingUpUrl ? 'hidden' : 'visible'
          }`}
        >
          {authT(subtitle)} <Link url={link}>{authT(linkText)}</Link>
        </p>
      </div>
    </div>
  );
};
AuthHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  isLogo: PropTypes.bool,
};
export default AuthHeader;
