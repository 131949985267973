import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  attributeList: string[];
  showAttribute: boolean;
  setShowAttribute: (payload: boolean) => void;
  apiList?: any;
  showAPI?: any;
  setShowAPI?: any;
  handleSelect: (val: string) => void;
  children: any;
}

const AttributeContainer: React.FC<Props> = ({
  attributeList,
  showAttribute,
  setShowAttribute,
  apiList,
  showAPI,
  setShowAPI,
  handleSelect,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        aria-live='assertive'
        className='relative flex items-end pointer-events-none sm:items-start'
      >
        <div className='flex flex-col items-start w-full space-y-4'>
          {(!!showAttribute || !!showAPI) && (
            <div className='absolute top-[-280px] max-w-[250px] left-0 w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden '>
              <div className='p-4'>
                <p className='mb-5 font-semibold text-gray-500'>
                  {showAttribute
                    ? t('Select an attribute')
                    : showAPI
                    ? t('Select an API')
                    : t('Title')}
                </p>
                <div className='flex items-start'>
                  <div className='flex flex-col w-full h-[200px] overflow-y-scroll'>
                    {showAttribute &&
                      attributeList.map((intent: string, index: number) => {
                        return (
                          <div
                            className='w-full pt-2 pb-2 pl-2 mb-2 text-gray-500 border-b cursor-pointer hover:bg-gray-200'
                            key={index}
                            onClick={() => handleSelect(intent)}
                          >
                            {'{{' + intent + '}}'}
                          </div>
                        );
                      })}
                    {showAPI &&
                      apiList.map((intent: string, index: number) => {
                        return (
                          <div
                            className='w-full pt-2 pb-2 pl-2 mb-2 text-gray-500 border-b cursor-pointer hover:bg-gray-200'
                            key={index}
                          >
                            {'{{' + intent + '}}'}
                          </div>
                        );
                      })}
                  </div>
                  <div className='ml-4 flex-shrink-0 flex absolute top-[10px] right-[10px]'>
                    <button
                      type='button'
                      className='inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon
                        className='w-5 h-5'
                        aria-hidden='true'
                        onClick={() =>
                          showAttribute
                            ? setShowAttribute(false)
                            : setShowAPI(false)
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </>
  );
};

export default AttributeContainer;
