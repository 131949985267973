import { Button } from 'library';
import React from 'react';
import { connect } from 'react-redux';

interface Prop {
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  disableConfirm?: boolean;
  isConfirmButtonLoading?: boolean;
  hasConfirmButton?: boolean;
  customConfirmButton?: React.ReactNode;
  customFooterContent?: React.ReactNode;
}

const Footer: React.FC<Prop> = ({
  cancelText = 'Cancel',
  confirmText = 'Continue',
  handleConfirm,
  handleCancel,
  disableConfirm,
  isConfirmButtonLoading,
  hasConfirmButton = true,
  customConfirmButton,
  customFooterContent,
}) => {
  return (
    <div className={`w-full py-4 bg-white flex space-between align-center`}>
      <div>{!!customFooterContent && customFooterContent}</div>
      <div>
        {!!cancelText && (
          <Button
            intent='default'
            className='ltr:mr-2 rtl:ml-2'
            size={'sm'}
            onClick={() => handleCancel()}
          >
            {cancelText}
          </Button>
        )}

        {!!customConfirmButton && customConfirmButton}
        {!!hasConfirmButton && (
          <Button
            intent={'primary'}
            size={'sm'}
            isLoading={isConfirmButtonLoading}
            isDisabled={disableConfirm}
            onClick={() => handleConfirm()}
          >
            {confirmText}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  orderStatus: state.crmEcommerce.orderStatus,
});

const mapDispatch = (dispatch: any) => ({});

const FooterContainer = connect(mapState, mapDispatch)(Footer);
export default FooterContainer;
