interface Props {
  classNames?: string;
}

export const GmailIcon: React.FC<Props> = ({ classNames = 'w-6 h-6' }) => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      className={classNames}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3197 10.0977C18.3197 9.45956 18.2624 8.84593 18.1561 8.25684H9.67969V11.7382H14.5233C14.3147 12.8632 13.6806 13.8164 12.7274 14.4546V16.7127H15.6361C17.3379 15.1459 18.3197 12.8387 18.3197 10.0977Z'
        fill='#4285F4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.67945 18.893C12.1094 18.893 14.1467 18.0871 15.6358 16.7126L12.7272 14.4544C11.9213 14.9944 10.8904 15.3135 9.67945 15.3135C7.33536 15.3135 5.35126 13.7303 4.64354 11.603H1.63672V13.9348C3.11763 16.8762 6.16126 18.893 9.67945 18.893Z'
        fill='#34A853'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.64378 11.6029C4.46378 11.0629 4.36151 10.4861 4.36151 9.89289C4.36151 9.29971 4.46378 8.72289 4.64378 8.18289V5.85107H1.63696C1.02741 7.06607 0.679688 8.44062 0.679688 9.89289C0.679688 11.3452 1.02741 12.7197 1.63696 13.9347L4.64378 11.6029Z'
        fill='#FBBC05'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.67945 4.47261C11.0008 4.47261 12.1872 4.9267 13.1199 5.81852L15.7013 3.23716C14.1426 1.78488 12.1054 0.893066 9.67945 0.893066C6.16126 0.893066 3.11763 2.90988 1.63672 5.85125L4.64354 8.18307C5.35126 6.05579 7.33536 4.47261 9.67945 4.47261Z'
        fill='#EA4335'
      />
    </svg>
  );
};

export const OutlookIcon: React.FC<Props> = ({ classNames = 'w-6 h-6' }) => {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      className={classNames}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_13523_2877)'>
        <path
          d='M17.7506 2.14307H7.44828C7.22514 2.14307 7.01114 2.23552 6.85336 2.40008C6.69558 2.56464 6.60693 2.78784 6.60693 3.02057V4.01807L12.4137 5.89307L18.5919 4.01807V3.02057C18.5919 2.78784 18.5033 2.56464 18.3455 2.40008C18.1877 2.23552 17.9737 2.14307 17.7506 2.14307Z'
          fill='#0364B8'
        />
        <path
          d='M19.581 11.7713C19.6687 11.4836 19.7388 11.1903 19.7907 10.8932C19.7906 10.8202 19.7721 10.7485 19.7371 10.6854C19.7021 10.6222 19.6517 10.5697 19.5911 10.5332L19.5834 10.5282L19.581 10.527L13.0863 6.66821C13.0583 6.64925 13.0293 6.63193 12.9994 6.61634C12.8752 6.55202 12.7384 6.51855 12.5997 6.51855C12.461 6.51855 12.3242 6.55202 12.2 6.61634C12.1701 6.63172 12.1411 6.64883 12.1131 6.66759L5.61843 10.5276L5.61603 10.5288L5.60884 10.5332C5.54813 10.5696 5.49767 10.6221 5.46254 10.6853C5.4274 10.7484 5.40883 10.8202 5.40869 10.8932C5.4606 11.1903 5.53066 11.4836 5.61843 11.7713L12.505 17.0245L19.581 11.7713Z'
          fill='#0A2767'
        />
        <path
          d='M14.9967 4.01807H10.8019L9.59082 5.89307L10.8019 7.76807L14.9967 11.5181H18.5922V7.76807L14.9967 4.01807Z'
          fill='#28A8EA'
        />
        <path
          d='M6.60693 4.01807H10.8017V7.76807H6.60693V4.01807Z'
          fill='#0078D4'
        />
        <path
          d='M14.9966 4.01807H18.5921V7.76807H14.9966V4.01807Z'
          fill='#50D9FF'
        />
        <path
          d='M14.9964 11.5181L10.8017 7.76807H6.60693V11.5181L10.8017 15.2681L17.2928 16.3731L14.9964 11.5181Z'
          fill='#0364B8'
        />
        <path
          d='M10.8018 7.76807H14.9965V11.5181H10.8018V7.76807Z'
          fill='#0078D4'
        />
        <path
          d='M6.60693 11.5181H10.8017V15.2681H6.60693V11.5181Z'
          fill='#064A8C'
        />
        <path
          d='M14.9966 11.5181H18.5921V15.2681H14.9966V11.5181Z'
          fill='#0078D4'
        />
        <path
          opacity='0.5'
          d='M12.7134 16.6543L5.64648 11.2793L5.94311 10.7355C5.94311 10.7355 12.3821 14.5605 12.4803 14.618C12.5205 14.6348 12.5636 14.6428 12.6069 14.6415C12.6502 14.6402 12.6928 14.6297 12.732 14.6105L19.2836 10.7168L19.5808 11.2599L12.7134 16.6543Z'
          fill='#0A2767'
        />
        <path
          d='M19.5912 11.2537L19.5828 11.2587L19.581 11.2599L13.0863 15.1187C12.9573 15.2054 12.8092 15.2562 12.656 15.2662C12.5028 15.2761 12.3497 15.2449 12.2114 15.1756L14.473 18.3387L19.4192 19.4618V19.4643C19.5343 19.3775 19.6279 19.2635 19.6925 19.1316C19.7571 18.9997 19.7908 18.8537 19.7907 18.7056V10.8931C19.7908 10.9662 19.7723 11.038 19.7373 11.1013C19.7023 11.1646 19.6519 11.2171 19.5912 11.2537Z'
          fill='#1490DF'
        />
        <path
          opacity='0.05'
          d='M19.7907 18.7057V18.2445L13.8084 14.6895L13.0863 15.1188C12.9573 15.2056 12.8092 15.2564 12.656 15.2663C12.5028 15.2763 12.3497 15.2451 12.2114 15.1757L14.473 18.3388L19.4192 19.462V19.4645C19.5343 19.3776 19.6279 19.2636 19.6925 19.1317C19.7571 18.9998 19.7908 18.8538 19.7907 18.7057Z'
          fill='black'
        />
        <path
          opacity='0.1'
          d='M19.7608 18.9449L13.2032 15.0493L13.0863 15.1181C12.9574 15.2049 12.8093 15.2558 12.6561 15.2659C12.5029 15.2759 12.3498 15.2448 12.2114 15.1756L14.473 18.3387L19.4192 19.4618V19.4643C19.5869 19.3376 19.7076 19.1543 19.7608 18.9456V18.9449Z'
          fill='black'
        />
        <path
          d='M5.61845 11.2618V11.2556H5.61245L5.59448 11.2431C5.53719 11.2064 5.48997 11.1549 5.45742 11.0936C5.42487 11.0322 5.40809 10.9632 5.40871 10.8931V18.7056C5.40855 18.8287 5.43169 18.9507 5.47681 19.0645C5.52193 19.1783 5.58813 19.2818 5.67163 19.3688C5.75513 19.4559 5.85428 19.525 5.96341 19.572C6.07254 19.6191 6.1895 19.6432 6.30758 19.6431H18.8918C18.9667 19.6423 19.0412 19.6318 19.1136 19.6118C19.1511 19.605 19.1875 19.5924 19.2214 19.5743C19.2342 19.573 19.2464 19.5688 19.2574 19.5618C19.3064 19.5409 19.3527 19.5136 19.3952 19.4806C19.4072 19.4743 19.4132 19.4743 19.4192 19.4618L5.61845 11.2618Z'
          fill='#28A8EA'
        />
        <path
          opacity='0.1'
          d='M11.4012 16.3099V6.10119C11.4006 5.88044 11.3162 5.66891 11.1665 5.51281C11.0169 5.35671 10.8141 5.26873 10.6024 5.26807H6.62517V9.92807L5.61843 10.5268L5.61543 10.5281L5.60824 10.5324C5.54755 10.569 5.49716 10.6216 5.46213 10.6849C5.4271 10.7482 5.40867 10.82 5.40869 10.8931V17.1431H10.6024C10.8141 17.1424 11.0169 17.0544 11.1665 16.8983C11.3162 16.7422 11.4006 16.5307 11.4012 16.3099Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M10.8019 16.9349V6.72619C10.8013 6.50544 10.7169 6.29391 10.5673 6.13781C10.4176 5.98171 10.2148 5.89373 10.0031 5.89307H6.62517V9.92807L5.61843 10.5268L5.61543 10.5281L5.60824 10.5324C5.54755 10.569 5.49716 10.6216 5.46213 10.6849C5.4271 10.7482 5.40867 10.82 5.40869 10.8931V17.7681H10.0031C10.2148 17.7674 10.4176 17.6794 10.5673 17.5233C10.7169 17.3672 10.8013 17.1557 10.8019 16.9349Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M10.8019 15.6849V6.72619C10.8013 6.50544 10.7169 6.29391 10.5673 6.13781C10.4176 5.98171 10.2148 5.89373 10.0031 5.89307H6.62517V9.92807L5.61843 10.5268L5.61543 10.5281L5.60824 10.5324C5.54755 10.569 5.49716 10.6216 5.46213 10.6849C5.4271 10.7482 5.40867 10.82 5.40869 10.8931V16.5181H10.0031C10.2148 16.5174 10.4176 16.4294 10.5673 16.2733C10.7169 16.1172 10.8013 15.9057 10.8019 15.6849Z'
          fill='black'
        />
        <path
          opacity='0.2'
          d='M10.2027 15.6849V6.72619C10.2021 6.50544 10.1177 6.29391 9.96803 6.13781C9.81836 5.98171 9.61555 5.89373 9.40389 5.89307H6.62517V9.92807L5.61843 10.5268L5.61543 10.5281L5.60824 10.5324C5.54755 10.569 5.49716 10.6216 5.46213 10.6849C5.4271 10.7482 5.40867 10.82 5.40869 10.8931V16.5181H9.40389C9.61555 16.5174 9.81836 16.4294 9.96803 16.2733C10.1177 16.1172 10.2021 15.9057 10.2027 15.6849Z'
          fill='black'
        />
        <path
          d='M1.41355 5.89307H9.40394C9.6158 5.89307 9.81898 5.98084 9.96878 6.13708C10.1186 6.29332 10.2027 6.50523 10.2027 6.72619V15.0599C10.2027 15.2809 10.1186 15.4928 9.96878 15.649C9.81898 15.8053 9.6158 15.8931 9.40394 15.8931H1.41355C1.20169 15.8931 0.998513 15.8053 0.848709 15.649C0.698905 15.4928 0.614746 15.2809 0.614746 15.0599L0.614746 6.72619C0.614746 6.50523 0.698905 6.29332 0.848709 6.13708C0.998513 5.98084 1.20169 5.89307 1.41355 5.89307Z'
          fill='#0078D4'
        />
        <path
          d='M2.93205 9.3104C3.1443 8.8386 3.48697 8.44377 3.91602 8.17665C4.39135 7.89285 4.93258 7.7514 5.48006 7.7679C5.987 7.75643 6.4872 7.89047 6.92605 8.1554C7.33914 8.41203 7.67183 8.78832 7.88365 9.23852C8.11441 9.73475 8.22939 10.2809 8.21923 10.8323C8.23033 11.4082 8.11191 11.9789 7.87346 12.4985C7.65704 12.9647 7.31432 13.3546 6.8877 13.6198C6.43164 13.8931 5.91244 14.0308 5.38658 14.0179C4.86851 14.0308 4.35698 13.8952 3.90763 13.626C3.49124 13.369 3.15463 12.9923 2.93804 12.541C2.7057 12.0521 2.58919 11.5122 2.59827 10.9666C2.58822 10.3954 2.70233 9.82912 2.93205 9.3104ZM3.98074 11.9716C4.09381 12.2699 4.28558 12.5286 4.53384 12.7179C4.78682 12.9026 5.08987 12.9979 5.39856 12.9898C5.7274 13.0031 6.0515 12.9046 6.32201 12.7091C6.5675 12.52 6.75439 12.2599 6.85953 11.961C6.97742 11.6281 7.03552 11.2753 7.03092 10.9204C7.03457 10.5621 6.97986 10.2058 6.86912 9.86665C6.7714 9.56153 6.59118 9.29239 6.35017 9.09165C6.0868 8.88634 5.76365 8.78194 5.43512 8.79602C5.11971 8.78771 4.80987 8.88375 4.55002 9.0704C4.29689 9.25998 4.10069 9.52075 3.98433 9.82227C3.72693 10.5135 3.72543 11.2799 3.98014 11.9723L3.98074 11.9716Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_13523_2877'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.5 0.893066)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CustomEmailIcon: React.FC<Props> = ({
  classNames = 'w-6 h-6',
}) => {
  return (
    <svg
      width='21'
      height='16'
      viewBox='0 0 21 16'
      fill='none'
      className={classNames}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.504167 2.74751L10.4999 7.74539L20.4958 2.74745C20.4204 1.43451 19.3318 0.393066 18 0.393066H3C1.66815 0.393066 0.579543 1.43454 0.504167 2.74751Z'
        fill='#EF4444'
      />
      <path
        d='M20.5 5.54045L10.4999 10.5405L0.5 5.54051V12.8931C0.5 14.2738 1.61929 15.3931 3 15.3931H18C19.3807 15.3931 20.5 14.2738 20.5 12.8931V5.54045Z'
        fill='#EF4444'
      />
    </svg>
  );
};
