import React, { useState } from 'react';
import RangeSliderLib from '../../../../../../../library/rangeSlider';
interface props {
  title?: string;
  min?: number;
  max?: number;
  step?: number;
  currentValue?: number;
  handleChange: (value: number) => void;
}
const RangeSlider: React.FC<props> = ({
  title = '',
  min = 0,
  max = 100,
  step = 1,
  currentValue = 0,
  handleChange,
}) => {
  const [value, setValue] = useState<number>(currentValue);

  const handleRangeChange = (val: number) => {
    handleChange(val);
    setValue(val);
  };

  return (
    <div className='pt-1'>
      <div className='w-full flex space-between h-9 relative items-center'>
        <span style={{ verticalAlign: '-webkit-baseline-middle' }}>
          {title}
        </span>
        <div className='flex py-1'>
          <kbd className='inline-flex items-center border border-gray-200 rounded  text-sm font-sans font-medium text-gray-400'>
            <span className='text-sm text-gray-900 p-3 '>{value}</span>
            <span className='text-sm text-gray-500 p-3'>PX</span>
          </kbd>
        </div>
      </div>
      <br />
      <div className='w-full'>
        <RangeSliderLib
          value={value}
          min={min}
          max={max}
          step={step}
          onValueChange={(val: string) => handleRangeChange(Number(val))}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
