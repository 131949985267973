import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import { Textarea, TextInput } from 'evergreen-ui';
import ButtonGroup from '../elements/ButtonGroup';
import PropTypes from 'prop-types';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  getButtonTypes,
  updateOnChangeText,
  updateOnSelectAttribute,
} from '../../../utilities/utils';
import buttonIcon from '../../pages/builder/assets/images/icons/buttons.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import TextCharInfo from '../elements/TextCharInfo';

const ButtonBlock = ({
  id,
  data,
  save,
  saveLoader,
  sequences,
  saveBlock,
  copyBlock,
  moveBlock,
  type,
  attributeList,
  labsData,
  apiList,
  updateButtonsBlock,
  updateButtonElements,
  setText,
  setHeader,
  createButtonElement,
  deleteButtonElement,
  maxButtonCount,
  deleteBlock,
  handleAPIButtonElementCreate,
  hasWriteAccess,
  platformType,
  subscriptionPlanType,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showHeaderAttribute, setShowHeaderAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [showHeaderAPI, setShowHeaderAPI] = useState(false);
  const [maxCount] = useState(620);
  const [headerMaxCount] = useState(60);

  const isWhatsApp = platformType === 'whatsapp_messenger';
  const isWhatsAppBSP = platformType === 'whatsapp_bsp';
  return (
    <BlockContainer
      id={id}
      title='Button'
      subtitle='Add buttons in your text to guide users to a new window.'
      save={save}
      isLoading={saveLoader}
      icon={buttonIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='flex flex-col'>
        {isWhatsApp && isWhatsAppBSP && (
          <React.Fragment>
            <AttributeAPIContainer
              attributeList={attributeList}
              showAttribute={showHeaderAttribute}
              setShowAttribute={setShowHeaderAttribute}
              apiList={apiList.filter((api) => api.return_type === 'text')}
              showAPI={showHeaderAPI}
              setShowAPI={setShowHeaderAPI}
              handleSelect={(value) =>
                (data.header + value).length + 2 <= headerMaxCount &&
                updateOnSelectAttribute(
                  value,
                  showHeaderAttribute,
                  showHeaderAPI,
                  setShowHeaderAttribute,
                  setShowHeaderAPI,
                  setHeader,
                  data.header,
                  apiList
                )
              }
            >
              <TextInput
                required={true}
                value={data.header}
                width='100%'
                disabled={!hasWriteAccess}
                onChange={(e) =>
                  e.target.value.length <= headerMaxCount &&
                  updateOnChangeText(
                    e.target.value,
                    setShowHeaderAttribute,
                    setShowHeaderAPI,
                    setHeader
                  )
                }
                placeholder='Add header here...'
              />
            </AttributeAPIContainer>
            <div className='my-2'>
              <TextCharInfo data={String(data.header)} count={headerMaxCount} />
            </div>
          </React.Fragment>
        )}
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (data.text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              setText,
              data.text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            rows={3}
            name='text-block'
            value={data.text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm cursor-text'
            placeholder={'Write your message here'}
          />
        </AttributeAPIContainer>
        <div>
          <TextCharInfo data={data.text} count={maxCount} />
        </div>
        {!!data.api ? (
          <FetchFromAPIButtonSection
            blockId={id}
            hasWriteAccess={hasWriteAccess}
            apiButtonData={data.api}
            handleDelete={handleAPIButtonElementCreate}
          />
        ) : (
          <ButtonGroup
            blockId={id}
            type={type}
            hasMessengerExtension={true}
            hasWebViewHeight={true}
            sequences={sequences}
            buttonTitle='Create a Button'
            APIButtonTitle='Create an API Button'
            allowedTypes={getButtonTypes(platformType, subscriptionPlanType)}
            maxButtonCount={maxButtonCount}
            formsData={labsData && labsData.filter((l) => l.type === 'form')}
            buttonList={data.buttons}
            handleDropUpdate={updateButtonElements}
            handleCreate={createButtonElement}
            handleDelete={deleteButtonElement}
            handleUpdate={updateButtonsBlock}
            hasAPIButton={['enterprise', 'business'].includes(
              subscriptionPlanType
            )}
            apiButtonData={data.api}
            apiList={apiList}
            hasWriteAccess={hasWriteAccess}
            handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          />
        )}
      </div>
    </BlockContainer>
  );
};

ButtonBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  updateButtonsBlock: PropTypes.func.isRequired,
  setText: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  createButtonElement: PropTypes.func.isRequired,
  deleteButtonElement: PropTypes.func.isRequired,
  maxButtonCount: PropTypes.number.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  labsData: PropTypes.arrayOf(PropTypes.object),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func,
  handleAPIButtonElementCreate: PropTypes.func,
  saveLoader: PropTypes.bool.isRequired,
  type: PropTypes.string,
  hasWriteAccess: PropTypes.bool.isRequired,
};
ButtonBlock.defaultProps = {
  maxButtonCount: 3,
  buttonList: [],
  type: null,
};

export default ButtonBlock;
