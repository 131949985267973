import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { DefaultGlobIcon } from 'assets/icons/countryFlag';
import useTranslation from 'components/customHooks/useTranslation';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { LanguageWithFlag } from 'utilities/utils';

const LanguageDropDown = () => {
  const { dashboardLanguage, updateLanguage, authT } = useTranslation();
  const [selected, setSelected] = useState<{
    name: string;
    value: string;
    flag: ReactElement;
  } | null>(null);

  useEffect(() => {
    if (
      !!dashboardLanguage &&
      (!selected || dashboardLanguage !== selected?.value)
    ) {
      const filteredData = LanguageWithFlag.filter(
        (data) => data?.value === dashboardLanguage
      );
      if (!!filteredData && filteredData?.length > 0) {
        setSelected(filteredData[0]);
      }
    }

    //eslint-disable-next-line
  }, [dashboardLanguage]);

  useEffect(() => {
    if (!!selected && selected?.value !== dashboardLanguage) {
      updateLanguage(selected?.value);
    }
    //eslint-disable-next-line
  }, [selected]);

  const defaultSelectionView = () => {
    return (
      <div className='flex gap-2 justify-start items-center'>
        <div>
          <DefaultGlobIcon />
        </div>
        <span className='text-base font-medium text-gray-600'>
          {authT('Select a language')}
        </span>
      </div>
    );
  };

  const selectedView = () => {
    return (
      <div className='flex gap-2 justify-start items-center'>
        <div>{selected?.flag}</div>
        <span className='text-base font-medium text-gray-600'>
          {selected?.name}
        </span>
      </div>
    );
  };

  return (
    <div className='w-[165px]'>
      <Listbox value={selected} onChange={setSelected}>
        <div className='relative mt-1'>
          <Listbox.Button className='relative w-full bg-transparent  cursor-default rounded-lg py-2 ltr:pl-3 ltr:pr-10 rtl:pl-10 rtl:pr-3 ltr:text-left rtl:text-right focus:outline-none sm:text-sm'>
            {!!selected ? selectedView() : defaultSelectionView()}
            <span className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2'>
              <ChevronDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm'>
              {LanguageWithFlag.map((language, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-3 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={language}
                >
                  {({ selected }) => (
                    <>
                      <div className='flex justify-start items-center'>
                        <span className='text-sm font-medium text-gray-600'>
                          {language.name}
                        </span>
                      </div>

                      <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2 text-amber-600'>
                        {selected && (
                          <CheckIcon className=' text-primary w-5 h-6 ' />
                        )}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default LanguageDropDown;
