import React from 'react';
import SuccessIcon from '../../../assets/icons/channelIconSet/rightSign.svg';
import { Button } from 'library';
interface SuccessProps {
  changeTeam: (teamElem: SelectedTeamInterface) => void;
  newTeamData: SelectedTeamInterface;
  closeModal: () => void;
  teamName: String;
  willStaySamePage?: boolean;
}

const SuccessModal: React.FC<SuccessProps> = ({
  willStaySamePage = false,
  changeTeam,
  newTeamData,
  closeModal,
  teamName,
}) => {
  const changeTeamAndUpdate = () => {
    changeTeam(newTeamData);
  };
  return (
    <div className='flex w-full p-8 flex-column'>
      <img className='w-12 h-12 m-auto' src={SuccessIcon} alt='' />
      <p className='mb-2 font-sans text-[18px] font-medium text-center text-[#111827]'>
        Team creation successful
      </p>
      <p className='font-sans text-sm text-center text-gray-500'>
        Your new team{' '}
        <span className='font-bold text-gray-600'>{teamName}</span> has been
        successfully created! You can add new members from Settings.
      </p>
      <Button
        className='flex justify-center w-1/2 m-auto mt-4'
        intent='primary'
        onClick={() =>
          willStaySamePage ? closeModal() : changeTeamAndUpdate()
        }
      >
        {willStaySamePage ? 'Okay' : 'Take me there'}
      </Button>
      {!willStaySamePage && (
        <Button
          className='m-auto flex justify-center w-1/2 mt-2 text-[#374151]'
          onClick={() => closeModal()}
        >
          Stay here
        </Button>
      )}
    </div>
  );
};

export default SuccessModal;
