import React from 'react';
import { classNames } from '../../../../../../utilities/utils';
import { NoSymbolIcon } from '@heroicons/react/20/solid';

interface Prop {
  tabsData: tabProps[];
  currentTabId: number;
  handleTabChange: (tab: tabProps) => void;
}
interface tabProps {
  id: number;
  name: string;
  icon: React.ReactNode;
}

const ProductInteractionTab: React.FC<Prop> = ({
  tabsData,
  currentTabId,
  handleTabChange,
}) => {
  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          className='block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500'
          value={tabsData.find((tab) => tab?.id === currentTabId)?.name}
          onChange={(tab) => {
            let currTab: tabProps = tabsData.filter(
              (elem) => elem.id.toString() === tab.target.value.toString()
            )[0];
            handleTabChange(currTab);
          }}
        >
          {tabsData.map((tab) => (
            <option value={tab.id} key={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <nav
          className='relative z-0 flex divide-x divide-gray-200 shadow'
          aria-label='Tabs'
        >
          {tabsData.map((tab: tabProps, tabIdx: number) => (
            <div
              key={tabIdx}
              onClick={() => handleTabChange(tab)}
              className={classNames(
                tab.id === currentTabId
                  ? 'bg-gray-100 text-gray-800'
                  : 'bg-white text-gray-500 hover:text-gray-700',
                'flex items-center justify-center group relative min-w-0 flex-1 overflow-hidden py-2.5 px-2.5 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
              )}
              aria-current={tab.id === currentTabId ? 'page' : undefined}
            >
              <div className='w-4 h-4 mr-1'>{tab?.icon || <NoSymbolIcon />}</div>
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ProductInteractionTab;
