import { useState } from 'react';

export type ResponseValueType = {
  value: any;
  category: string;
  variableIndex: number;
};

type Props = {
  variableIndex: number;
  onValueChange: (option: ResponseValueType) => void;
};

const useNestedVariableLogic = ({ onValueChange, variableIndex }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [openListboxOption, setOpenListboxOption] = useState<boolean>(false);

  const onVariableChange = (value: any) => {
    if (!onValueChange || isNaN(variableIndex) || variableIndex < 0) return;
    onValueChange({ value, category: currentCategory, variableIndex });
  };

  const removeVariable = () => {
    if (isNaN(variableIndex) || variableIndex < 0) return;
    onValueChange({ value: '', category: '', variableIndex });
  };

  return {
    searchTerm,
    setSearchTerm,
    removeVariable,
    currentCategory,
    onVariableChange,
    openListboxOption,
    setCurrentCategory,
    setOpenListboxOption,
  };
};

export default useNestedVariableLogic;
