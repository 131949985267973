import React from 'react';
import { VariantProps } from 'class-variance-authority';
import { buttonVariants } from 'libraryV2/ui/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'libraryV2/ui/alert-dialog';

interface DeleteAlertDialogProps {
  title: string;
  isOpened: boolean;
  description: string;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isConfirming?: boolean;
  confirmationVariant?: VariantProps<typeof buttonVariants>['variant'];
}

const WarningModal: React.FC<DeleteAlertDialogProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  isOpened,
  cancelTitle = 'No',
  confirmTitle = 'Yes! Delete',
  isConfirming = false,
  confirmationVariant = 'default',
}) => {
  return (
    <AlertDialog open={isOpened} onOpenChange={onCancel}>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel} disabled={isConfirming}>
            {cancelTitle}
          </AlertDialogCancel>
          <AlertDialogAction
            className={`ml-1`}
            onClick={() => {
              onConfirm();
              onCancel();
            }}
            variant={confirmationVariant}
            disabled={isConfirming}
          >
            {confirmTitle}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default WarningModal;
