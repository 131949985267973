import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { BroadcastCreationProperty } from '../interface';
import { WhatsappMessageTemplateProps } from 'index';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  credit: number;
  cardInfo: string | undefined;
  broadcastCreationData: BroadcastCreationProperty;
  templateList: WhatsappMessageTemplateProps[] | null;
  isProjectCreator: () => boolean;
  downloadAudienceReport: () => void;
  setOpenAddCredits: (data: boolean) => void;
}

const BroadcastSummary: React.FC<Props> = ({
  credit,
  cardInfo,
  templateList,
  broadcastCreationData,
  isProjectCreator,
  setOpenAddCredits,
  downloadAudienceReport,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideCardInfo = isPartnerRestricted(
    'BroadcastSummary/node/card-info'
  );
  const shouldHideBroadcastCreditSummary = isPartnerRestricted(
    'BroadcastSummary/function/renderPaymentInfoSummary'
  );
  const getWarningDescription = () => {
    return isProjectCreator()
      ? t(
          'You do not have enough credits to schedule the broadcast. Please add credits to continue.'
        )
      : t(
          'You do not have enough credits to schedule the broadcast. Please ask your team owner to add credits first.'
        );
  };

  const notice = (key: boolean) => {
    switch (key) {
      case true:
        return (
          <div className='flex bg-red-50 gap-4 rounded-md h-20 p-4'>
            <XCircleIcon className='text-[#F87171] w-5 h-5' />
            <div className='flex justify-between w-[95%] items-start h-full'>
              <div className='flex flex-col justify-between h-full'>
                <span className='font-medium text-[#991B1B]'>
                  {t('Insufficient credits')}
                </span>
                <span className='text-[#B91C1C] font-normal'>
                  {getWarningDescription()}
                </span>
              </div>
              {isProjectCreator() && (
                <button
                  className='capitalize font-medium text-[#991B1B]'
                  onClick={() => setOpenAddCredits(true)}
                >
                  {t('Add Credits')} &#8594;
                </button>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const getTemplateName = (templateId: string) => {
    const index =
      !!templateList &&
      templateList.findIndex((data) => data.id === templateId);

    return typeof index !== 'boolean' && index > -1 && !!templateList
      ? templateList[index]?.name
      : '';
  };

  const renderPaymentInfoSummary = () => {
    if (shouldHideBroadcastCreditSummary) {
      return null;
    }
    return (
      <>
        <p className='text-gray-900 text-base mb-3 font-medium rtl:text-right'>
          {t('Payment Information')}
        </p>
        <div className='flex flex-col rtl:text-right justify-between p-4 border border-gray-200 rounded-md mb-5 h-[188px] overflow-y-auto'>
          <p className='text-gray-600 mb-2 '>
            {t('Your available credits will be used first. Additional credits')}{' '}
            (
            <span className='text-gray-800 font-medium'>
              $
              {broadcastCreationData?.estimated_cost - credit > 0
                ? (broadcastCreationData?.estimated_cost - credit).toFixed(2)
                : 0.0}
            </span>
            )
            {shouldHideCardInfo ? (
              t('will be charged from your card.')
            ) : (
              <span id='card-info'>
                {t('will be charged from your card ending with')} {cardInfo}.
              </span>
            )}
          </p>
          <div className='flex'>
            <div className='flex w-[90%] justify-start text-gray-800 font-medium'>
              {t('Estimated Cost')}
            </div>
            <div className='flex w-[90%] justify-end'>
              <span className='text-gray-900 font-medium'>
                ${broadcastCreationData?.estimated_cost}
              </span>
            </div>
          </div>
          <div className='flex'>
            <div className='flex w-[90%] justify-start text-gray-800 font-medium'>
              {t('Available Credits')}
            </div>
            <div className='flex w-[90%] justify-end'>
              <span className='text-gray-900 font-medium'>
                ${credit.toFixed(2)}
              </span>
            </div>
          </div>

          <div className='flex'>
            <div className='flex w-[90%] justify-start text-gray-800 font-medium'>
              {t('Additional Credits Required')}
            </div>
            <div className='flex w-[90%] justify-end'>
              <span className='text-gray-900 font-medium'>
                $
                {broadcastCreationData?.estimated_cost - credit > 0
                  ? (broadcastCreationData?.estimated_cost - credit).toFixed(2)
                  : 0.0}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='w-full'>
      <div className='flex gap-3 justify-center mt-3'>
        <div className='flex-1'>
          <p className='text-gray-900 text-base mb-3 font-medium rtl:text-right'>
            {broadcastCreationData?.title} | {t('Broadcast Summary')}
          </p>
          <div className='flex flex-col rtl:text-right justify-between p-4 border border-gray-200 rounded-md mb-5 h-[188px] overflow-y-auto'>
            <p className='text-gray-500 mb-2 '>
              {t('Sent from')}
              <span className='text-gray-900 font-medium ltr:pl-3 rtl:pr-3'>
                {broadcastCreationData?.channel_details?.phone}
              </span>
            </p>
            <p className='text-gray-500 mb-2 '>
              {t('Message template')}
              <span className='text-gray-900 font-medium ltr:pl-3 rtl:pr-3'>
                {getTemplateName(broadcastCreationData?.template_id)}
              </span>
            </p>
            <p className='text-gray-500 mb-2'>
              {t('Scheduled to')}
              <span className='text-gray-900 font-medium ltr:pl-3 rtl:pr-3'>
                {broadcastCreationData?.total_audience_data} {t('Customers')}
              </span>
            </p>
            <p className='text-gray-500 mb-2'>
              {t('Invalid')}
              <span className='text-gray-900 font-medium ltr:pl-3 rtl:pr-3'>
                {broadcastCreationData?.invalid_audience_data}{' '}
                {broadcastCreationData?.invalid_audience_data <= 1
                  ? t('contact')
                  : t('contacts')}
              </span>
            </p>
            <p
              onClick={() => {
                downloadAudienceReport();
              }}
              className='text-[#0078CF] cursor-pointer'
            >
              {t('View invalid contacts')}
            </p>
          </div>
        </div>
        <div className='flex-1'>{renderPaymentInfoSummary()}</div>
      </div>
      {notice(broadcastCreationData?.estimated_cost > credit)}
    </div>
  );
};

export default BroadcastSummary;
