import aws from 'aws-sdk';

const region = 'ap-southeast-1';
const bucketName = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const s3 = new aws.S3({
  region,
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
});

export const getPreSignedPutUrl = async (fileName: string) => {
  const uploadURL = await s3.getSignedUrlPromise('putObject', {
    Bucket: bucketName,
    Key: fileName,
    Expires: 600,
    ACL: 'public-read',
  });
  return uploadURL;
};
