import React from 'react';
import { Avatar } from 'library';

interface ReopenRadioAgentsProps {
  data: AgentInterface[];
  selected: AgentInterface | null;
  onChange: (value: AgentInterface) => void;
}

const ReopenRadioAgents: React.FC<ReopenRadioAgentsProps> = ({
  data,
  selected,
  onChange,
}) => {
  return (
    <fieldset className='px-2'>
      <div className='mt-4 border-b border-gray-200 divide-y divide-gray-200'>
        {data.map((agent, index) => (
          <div key={index} className='relative flex items-center py-3'>
            <div className='flex-1 min-w-0 text-sm'>
              <label
                htmlFor={`side-${agent.id}`}
                className='flex items-center font-medium text-gray-700 select-none'
              >
                <Avatar
                  size='sm'
                  src={agent.admin.avatar || ''}
                  name={agent.admin.full_name}
                />
                &nbsp;&nbsp;{agent?.admin?.full_name}
              </label>
            </div>
            <div className='flex items-center h-5 ml-3'>
              <input
                id={`side-${agent.id}`}
                name='agent'
                type='radio'
                checked={!!selected && agent.id === selected.id}
                onChange={() => {
                  onChange(agent);
                  // we needed inline function because we needed agent data from this mapping
                }}
                className='w-4 h-4 border-gray-300 text-primary focus:ring-primary'
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default ReopenRadioAgents;
