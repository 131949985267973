import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'libraryV2/utils';

// Badge styles
const badgeVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary hover:text-secondary-foreground',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive hover:text-destructive-foreground',
        outline: 'text-foreground hover:text-foreground',
        datalab: 'text-foreground text-xs font-medium hover:text-foreground',
        ghost:
          'text-secondary-foreground bg-transparent shadow-none border-transparent hover:text-secondary-foreground hover:bg-transparent',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface BadgeItem {
  name: string;
  color: string | null;
}

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  list?: BadgeItem[]; // Enforcing the array of objects structure
  maxVisible?: number; // Option to customize the maximum number of visible items
}

function Badge({
  className,
  variant,
  list,
  maxVisible = 5,
  ...props
}: BadgeProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  if (list && list.length > 0) {
    const visibleItems = list.slice(0, maxVisible);
    const hiddenItems = list.length > maxVisible ? list.slice(maxVisible) : [];

    return (
      <div className='flex items-center space-x-2'>
        {visibleItems.map((item, index) => (
          <div
            key={index}
            className={cn(
              badgeVariants({ variant: variant }),
              `truncate max-w-[140px] px-2 py-[1.5px] border-border`
            )}
            style={{ color: item.color || '#18181B' }}
          >
            {item.name.length > 17 ? `${item.name.slice(0, 17)}...` : item.name}
          </div>
        ))}

        {hiddenItems.length > 0 && (
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <div
                className={cn(
                  badgeVariants({ variant: variant }),
                  'border-border cursor-pointer'
                )}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                +{hiddenItems.length}
              </div>
            </PopoverTrigger>
            <PopoverContent
              className='w-48 bg-white'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className='flex flex-col max-w-xs overflow-y-auto rounded-lg max-h-80 border-border'>
                {hiddenItems.map((item, index) => (
                  <div
                    key={index}
                    className={cn(
                      badgeVariants({ variant: variant }),
                      `truncate max-w-[240px] w-fit mx-2 my-[5px] leading-5 border-border`
                    )}
                    style={{ color: item.color || '#18181B' }}
                  >
                    {item.name.length > 17
                      ? `${item.name.slice(0, 17)}...`
                      : item.name}
                  </div>
                ))}
              </div>
            </PopoverContent>
          </Popover>
        )}
      </div>
    );
  }

  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
