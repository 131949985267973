import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CubeIcon } from '../../../assets/iconComponent/CustomerAttributesIcon';
import { toaster } from 'evergreen-ui';
import { fixedAttributeList } from '../../../utils/contents';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  projectAttributesList: string[];
  handleUpdateAttribute: (
    attributeName: string,
    attributeValue: string
  ) => void;
  selectedTicket: TicketInterface;
  showAttributeList: string[];
}

const NewAttributeDropDown: React.FC<Props> = ({
  projectAttributesList,
  handleUpdateAttribute,
  selectedTicket,
  showAttributeList,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [attribtueValue, setAttribtueValue] = React.useState('');
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [selectedAtrributeName, setSelectedAtrributeName] = React.useState('');

  const ref = React.useRef(null);
  const inputRef = React.useRef(null);
  const editInputClass = `border-0 text-[#374151] font-normal text-sm leading-5 w-full outline-none h-8 focus:outline-none focus:ring-0 focus:bg-transparent hover: bg-transparent focus:border-b-2 focus:border-solid focus:border-green-400`;

  const handInputFocus = () => {
    setTimeout(() => {
      //@ts-ignore
      ref.current.focus();
    }, 200);
  };

  React.useEffect(() => {
    setQuery('');
    setAttribtueValue('');
    setSelectedAtrributeName('');
    //@ts-ignore
    if (inputRef.current) inputRef.current.value = '';
  }, [selectedTicket?.id]);

  const setInitialState = () => {
    setQuery('');
    setAttribtueValue('');
    setIsInputFocused(false);
    setSelectedAtrributeName('');
  };

  const handleSelectedAttribute = (attribute: string) => {
    if (
      showAttributeList.includes(attribute) ||
      fixedAttributeList.includes(attribute)
    ) {
      toaster.danger(
        t(`The attribute {{${attribute}}} already exists for the customer.`)
      );
    } else {
      setSelectedAtrributeName(attribute);
      handInputFocus();
    }
  };

  const getAtrributeListForDisplay = () => {
    let attributeList = projectAttributesList;
    if (query.length !== 0) {
      attributeList = attributeList.filter((attribute: string) => {
        return attribute.toLowerCase().includes(query.toLowerCase());
      });
    }
    return attributeList;
  };

  const renderAddNewAttributeView = () => {
    return (
      <div
        className='px-3 py-3'
        onClick={() => {
          handleSelectedAttribute(query);
        }}
      >
        <div className='text-[#0078CF] cursor-pointer break-words'>
          {t(`Add "{{${query}}}" as a new attribute.`)}
        </div>
      </div>
    );
  };

  const getAttributesView = (atrribute: string, index: number) => {
    return (
      <div
        key={index}
        className='flex items-center w-full px-4 py-2 cursor-pointer hover:bg-gray-100'
        onClick={() => {
          handleSelectedAttribute(atrribute);
        }}
      >
        <div className='flex gap-2'>
          <CubeIcon className='w-5 h-5 mt-1' />
          <span className=''>
            {atrribute.length >= 23
              ? `${atrribute.slice(0, 23)}...`
              : atrribute}
          </span>
        </div>
      </div>
    );
  };

  const renderAttributeNameSelectView = () => {
    return (
      <Menu as='div' className='relative inline-block w-full text-left'>
        <div className='flex items-center w-full px-4'>
          <div className='flex gap-4 w-[80%] items-center'>
            <div>
              <CubeIcon
                className='w-5 h-5'
                fillColor={isInputFocused ? '#04B25F' : '#6B7280'}
              />
            </div>
            <input
              ref={inputRef}
              placeholder={t('Add new attribute')}
              onFocus={() => setIsInputFocused(true)}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              className={`${editInputClass}`}
              onBlur={() => setIsInputFocused(false)}
            />
          </div>
        </div>
        <Transition
          show={query.length !== 0}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <div
            className='absolute right-0 bottom-10 z-20 w-full
           mt-2 origin-top-right focus:outline-none'
          >
            <div
              className='flex flex-col h-auto mx-3 overflow-auto bg-white rounded-md
            shadow-lg max-h-32 ring-1 ring-black ring-opacity-5'
            >
              {getAtrributeListForDisplay().length === 0 &&
                renderAddNewAttributeView()}
              {getAtrributeListForDisplay().length !== 0 &&
                getAtrributeListForDisplay().map(
                  (atrribute: string, index: number) =>
                    getAttributesView(atrribute, index)
                )}
            </div>
          </div>
        </Transition>
      </Menu>
    );
  };

  const renderAttributeValueAssignView = () => {
    return (
      <div className={`flex px-4 gap-2 w-full items-center h-8`}>
        <div className='flex items-center w-full'>
          <div>
            <CubeIcon className='inline-block w-5 h-5' />
          </div>
          <div className='flex justify-start w-full gap-2 text-sm font-normal leading-5 text-gray-900'>
            <div className='flex justify-between w-full'>
              <>
                <div
                  className={`flex items-center text-[#374151] font-normal text-sm leading-5 ${
                    selectedAtrributeName.length >= 10 ? 'w-[30%]' : 'w-auto'
                  }`}
                >
                  <span className='block truncate ...'>
                    {selectedAtrributeName}
                  </span>
                  <span className='mx-1'>:</span>
                </div>
                <div
                  className='flex justify-center w-full h-8 text-sm font-normal leading-5 text-gray-900'
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      handleUpdateAttribute(
                        selectedAtrributeName,
                        attribtueValue
                      );
                      setInitialState();
                    }
                  }}
                >
                  <input
                    ref={ref}
                    className={`${editInputClass}`}
                    onChange={(e) => setAttribtueValue(e.target.value)}
                  />
                </div>
              </>
              {attribtueValue.length !== 0 && (
                <div
                  className='text-[#0078CF] text-xs cursor-pointer flex w-[20%] items-center'
                  onClick={() => {
                    handleUpdateAttribute(
                      selectedAtrributeName,
                      attribtueValue
                    );
                    setInitialState();
                  }}
                >
                  {t('Save')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`h-8 hover:bg-gray-200 w-full ${
        selectedAtrributeName ? 'bg-gray-200' : ''
      }`}
    >
      {selectedAtrributeName.length === 0 && renderAttributeNameSelectView()}
      {selectedAtrributeName.length !== 0 && renderAttributeValueAssignView()}
    </div>
  );
};

export default NewAttributeDropDown;
