import useTranslation from 'components/customHooks/useTranslation';
import BlankModal from 'library/modal/BlankModal';
import { toast } from 'libraryV2/ui/use-toast';
import { Search } from 'lucide-react';
import { IEcommerceChannelPreferencesProperty } from 'pages/integration/interface';
import { AvailableIntegrations } from 'pages/integration/utils/content';
import React, { useEffect, useState } from 'react';
import { getAllMarketPlaceData } from 'utilities/utils';
import telegramGroupIcon from '../../../../../../assets/icons/channelIconSet/telegram_group.svg';
import webChatIcon from '../../../../../../assets/icons/channelIconSet/webchat_messenger.svg';
import { Input } from '../../../../../../libraryV2/ui/input';
import { ScrollArea } from '../../../../../../libraryV2/ui/scroll-area';
import { Switch } from '../../../../../../libraryV2/ui/switch';
import EmptySearchIcon from '../../../../assets/images/EmptySearchIcons.svg';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';

interface ChannelItemProps {
  icon: React.ReactNode;
  name: string;
  title: string;
  url?: string;
  ecommerceIcon?: string;
  isConnected: boolean;
  isDisabled?: boolean;
  toggleSwitch: () => void;
}

const ChannelItem: React.FC<ChannelItemProps> = ({
  icon,
  name,
  title,
  url,
  ecommerceIcon,
  isConnected,
  isDisabled,
  toggleSwitch,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex items-center justify-between rtl:flex-row-reverse py-2 ${
        isDisabled ? 'opacity-50' : ''
      }`}
    >
      <div className='flex items-center rtl:flex-row-reverse'>
        {icon}
        <div className='ltr:ml-3 rtl:mr-3'>
          <div className='flex rtl:flex-row-reverse items-center gap-2'>
            <p className='font-medium'>{title}</p>
            {ecommerceIcon && (
              <img
                src={ecommerceIcon}
                alt='Ecommerce Icon'
                className='w-5 h-5'
              />
            )}
          </div>
          {url ? (
            <div>
              {t('Connected with')}{' '}
              <a
                href={url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500'
              >
                {name}
              </a>
            </div>
          ) : (
            <p className='text-sm text-gray-500 rtl:text-right'>
              {t('Connected')} {name ? `with ${name}` : ''}
            </p>
          )}
        </div>
      </div>
      <Switch
        checked={isConnected}
        onCheckedChange={toggleSwitch}
        disabled={isDisabled}
      />
    </div>
  );
};

interface NoChannelsFoundProps {
  searchTerm?: string;
  isLoading?: boolean;
}

const NoChannelsFound: React.FC<NoChannelsFoundProps> = ({
  searchTerm,
  isLoading,
}) => (
  <div className='flex flex-col items-center gap-3 justify-center h-[300px] text-gray-500'>
    {isLoading ? (
      <span className='ml-1 w-5 h-5 flex items-center'>
        <div
          className='inline-block w-5 h-5 text-primary border-2 rounded-full spinner-border animate-spin'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </span>
    ) : (
      <>
        {searchTerm && (
          <img
            src={EmptySearchIcon}
            className='w-48 h-48'
            alt='Empty Search Icon'
          />
        )}

        <div className='flex  flex-col gap-2'>
          <p className='text-lg font-semibold text-center text-textPrimary'>
            {searchTerm ? `No Result found` : 'No channels found'}
          </p>

          <p className='text-gray-500'>
            {searchTerm
              ? 'Please search with different keyword.'
              : 'Please integrate any messaging channel to enable ecommerce features.'}
          </p>
        </div>
      </>
    )}
  </div>
);

interface ChannelPreferencesModalProps {
  handleClose: () => void;
  open: boolean;
  channelPreferencesData: IEcommerceChannelPreferencesProperty[];
  teamId: number;
  isLoading: boolean;
  alice_store_id: number;
  onConnect: (payload: any) => boolean;
  onDisconnect: (payload: any) => boolean;
}

export const ChannelPreferencesModal: React.FC<
  ChannelPreferencesModalProps
> = ({
  handleClose,
  open,
  isLoading,
  alice_store_id,
  channelPreferencesData,
  teamId,
  onConnect,
  onDisconnect,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredChannels, setFilteredChannels] = useState<
    IEcommerceChannelPreferencesProperty[]
  >([]);

  useEffect(() => {
    const filtered = channelPreferencesData.filter((channel) =>
      channel.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChannels(filtered);
  }, [channelPreferencesData, searchTerm]);

  const getChannelIcon = (platformType: string) => {
    const availableChannel = AvailableIntegrations.Channel.find(
      (channel) => channel.type === platformType
    );

    if (availableChannel) {
      return (
        <img
          src={availableChannel.icon}
          alt={availableChannel.title}
          className='w-8 h-8'
        />
      );
    } else if (platformType === 'webchat') {
      return (
        <img src={webChatIcon} alt='WebChat Messenger' className='w-8 h-8' />
      );
    } else if (platformType === 'telegram_group') {
      return (
        <img src={telegramGroupIcon} alt='Telegram Group' className='w-8 h-8' />
      );
    }

    return <div className='w-8 h-8 bg-gray-200 rounded-full' />; // Placeholder for unknown channels
  };

  const getEcommerceIcon = (platformType: string) => {
    const availableChannel = getAllMarketPlaceData.find(
      (channel) => channel.platform_type === platformType
    );

    if (availableChannel) {
      return availableChannel.icon;
    }

    return '';
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const toggleChannelSwitch = async (
    channel: IEcommerceChannelPreferencesProperty
  ) => {
    if (channel.alice_store_id && channel.alice_store_id !== alice_store_id) {
      return;
    }
    const connectPayload = {
      teamId,
      alice_store_id,
      platform_id: channel.id,
    };
    const disconnectPayload = {
      teamId,
      alice_store_id: channel.alice_store_id,
      platform_id: channel.id,
    };

    let success;
    let type;
    if (channel.has_ecommerce_connected) {
      success = await onDisconnect(disconnectPayload);
      type = 'disconnect';
    } else {
      success = await onConnect(connectPayload);
      type = 'connect';
    }

    if (success) {
      toast({
        title: type === 'connect' ? 'Connected' : 'Disconnected',
        description: `Successfully ${type}ed ${channel.title}`,
        toastType: 'success',
      });
      setFilteredChannels((prevChannels) =>
        prevChannels.map((c) =>
          c.id === channel.id
            ? {
                ...c,
                has_ecommerce_connected: !c.has_ecommerce_connected,
                alice_store_id: c.has_ecommerce_connected ? 0 : alice_store_id,
              }
            : c
        )
      );
    } else {
      console.error('Failed to toggle channel connection');
    }
  };

  const loadingView = () => {
    return <CircleLoader size='md' />;
  };

  return (
    <BlankModal
      forIntegration
      hideSubmitButton
      hideCancelButton
      title={t('Channel Preferences')}
      subTitle={t('Select preferred channels for the e-commerce platform.')}
      open={open}
      handleClose={handleClose}
    >
      <div className='space-y-4'>
        {channelPreferencesData.length > 0 && (
          <div className='relative'>
            <Search className='absolute left-2 top-2.5 h-4 w-4 text-gray-500' />
            <Input
              placeholder='Search channels'
              className='pl-8'
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        )}

        <ScrollArea className='h-[300px] pr-4'>
          {isLoading ? (
            <div className='w-full h-[300px] flex items-center justify-center'>
              {loadingView()}
            </div>
          ) : channelPreferencesData.length === 0 ? (
            <NoChannelsFound isLoading={isLoading} />
          ) : filteredChannels.length === 0 ? (
            <NoChannelsFound searchTerm={searchTerm} isLoading={isLoading} />
          ) : (
            filteredChannels.map((channel) => {
              return (
                <ChannelItem
                  key={channel.id}
                  icon={getChannelIcon(channel.platform_type)}
                  name={channel.name ?? ''}
                  ecommerceIcon={getEcommerceIcon(channel.ecommerce_type)}
                  title={channel.title}
                  isDisabled={
                    channel.alice_store_id !== null &&
                    channel.alice_store_id !== alice_store_id
                  }
                  isConnected={channel.has_ecommerce_connected}
                  toggleSwitch={() => toggleChannelSwitch(channel)}
                />
              );
            })
          )}
        </ScrollArea>
      </div>
    </BlankModal>
  );
};
