import React, { useEffect, useState } from 'react';
import BlockContainer from './BlockContainer';
import ViewCartIcon from '../../../old/pages/builder/assets/images/icons/view_cart_block_icon.svg';
import { BlocksData } from '../../../utilities/content';
import { Switch, toaster } from 'evergreen-ui';
import TextVariableInputField from '../elements/eCommerceBlock/TextVariableInputFeild';
import ButtonGroup from '../elements/ButtonGroup';
import { getButtonTypes } from '../../../utilities/utils';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  type: string;
  attributeList: string[];
  platformType: string;
  subscriptionPlanType: string;
  labsData: [];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  updateViewCartData: (
    changeKey: string,
    changeValue: string | boolean
  ) => void;
  handleViewCartBlockGalleryButton: (data: {}, actionType: string) => void;
}

interface dataProps {
  title: string;
  subtitle: string;
  has_image: boolean;
  url: string;
  buttons: [];
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const ViewCart: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  attributeList,
  platformType,
  subscriptionPlanType,
  labsData,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  updateViewCartData,
  handleViewCartBlockGalleryButton,
}) => {
  const [showImage, setShowImage] = useState(data?.has_image);
  const [showAttributeField, setShowAttributeField] = useState('');
  const inputStack = ['title', 'subtitle', 'url'];

  const handleInputValueOnChange = (changeKey: string, changeValue: string) => {
    let currentValue = changeValue;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{') setShowAttributeField(changeKey);
    else setShowAttributeField('');
    updateViewCartData(changeKey, changeValue);
  };

  const handleAttributeValue = (changeKey: string, changeValue: string) => {
    setShowAttributeField('');
    let newValue = data[changeKey] + `${changeValue}}}`;
    updateViewCartData(changeKey, newValue);
  };

  const checkActionButton = () => {
    let hasActionButton = true;
    data?.buttons.forEach((elem: any) => {
      if (elem?.type === 'remove_from_cart') hasActionButton = false;
    });
    return hasActionButton;
  };

  const validateSave = () => {
    if (data?.title.length === 0) {
      toaster.danger('Please add title and variable', { duration: 2 });
      return;
    }
    if (data?.subtitle.length === 0) {
      toaster.danger('Please add subtitle and variable', { duration: 2 });
      return;
    }
    saveBlock(id);
  };

  useEffect(() => {
    if (data?.title.length === 0 || data?.subtitle.length === 0)
      updateViewCartData('title', '');
    // eslint-disable-next-line
  }, []);
  return (
    <BlockContainer
      id={id}
      title={BlocksData.viewCartBlock.title}
      subtitle={BlocksData.viewCartBlock.subTitle}
      save={save}
      isLoading={saveLoader}
      icon={ViewCartIcon}
      sequences={sequences}
      handleSaveClick={() => validateSave()}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <div className='flex justify-between'>
          <h6 className='font-medium text-gray-700'>Display Image?</h6>
          <Switch
            className='switch-rd'
            onChange={() => {
              updateViewCartData('has_image', !showImage);
              setShowImage(!showImage);
            }}
            height={20}
            checked={showImage}
            hasCheckIcon={false}
          />
        </div>
        {inputStack.map((item, index) => (
          <div className='mt-3'>
            <TextVariableInputField
              hasWriteAccess={hasWriteAccess}
              id={index}
              value={data[item]}
              type={'string'}
              placeHolder={`Add ${item} and variable`}
              attributeList={BlocksData.viewCartBlock.output_parameters}
              isDanger={!data[item]?.length && item !== 'url'}
              showAttribute={item === showAttributeField}
              setShowAttribute={setShowAttributeField}
              handleSelect={(val) => {
                handleAttributeValue(item, val);
              }}
              handleOnChange={(val) => {
                handleInputValueOnChange(item, val);
              }}
            />
          </div>
        ))}
        <br />
        <div className='mb-4 border-t border-gray-200' />
        <ButtonGroup
          blockId={id}
          type={type}
          hasWebViewHeight={true}
          hasMessengerExtension={true}
          hasActionButton={checkActionButton()}
          sequences={sequences}
          hasWriteAccess={hasWriteAccess}
          formsData={labsData && labsData.filter((l: any) => l.type === 'form')}
          allowedTypes={getButtonTypes(platformType, subscriptionPlanType)}
          buttonTitle={'Create a Button'}
          maxButtonCount={3}
          buttonList={data?.buttons}
          handleCreate={(id: number, buttonCategory: string = 'regular') => {
            handleViewCartBlockGalleryButton(
              { buttonCategory: buttonCategory },
              'create'
            );
          }}
          handleUpdate={(id, data) =>
            handleViewCartBlockGalleryButton({ buttonData: data }, 'update')
          }
          handleDelete={(blockId, buttonIndex) => {
            handleViewCartBlockGalleryButton(
              { buttonIndex: buttonIndex },
              'delete'
            );
          }}
          handleDropUpdate={(blockId: number, data: any) =>
            handleViewCartBlockGalleryButton(
              { buttonData: data },
              'changeButtonPosition'
            )
          }
          dragDirection='vertical'
        />
      </>
    </BlockContainer>
  );
};

export default ViewCart;
