import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, TextInput, Pane, Tablist, Tab, IconButton } from 'evergreen-ui';
import PopupBlockContent from './PopupBlockContent';
import PopupUrlContent from './PopupUrlContent';
import PopupPhoneContent from './PopupPhoneContent';
import PopupBasicContent from './PopupBasicContent';
import PopupFormContent from './PopupFormContent';
import '../../assets/styles/elements/button-popup.scss';
import TextCharInfo from './TextCharInfo';
import { connect } from 'react-redux';

const getContentComponent = (
  blockType,
  inputValue,
  buttonType,
  items,
  forms,
  value,
  formSequence,
  handleChange,
  messengerExtensions,
  handleMessengerExtensionChange,
  hasMessengerExtension,
  hasWebViewHeight,
  webViewHeight,
  handleWebViewHeightChange,
  handleInputChange,
  selectedPlatform
) => {
  switch (buttonType.toLowerCase()) {
    case 'sequence':
      return (
        <PopupBlockContent
          items={items}
          inputVal={value}
          attributeValue={inputValue}
          blockType={blockType}
          handleChange={(value) =>
            handleChange('sequence', value, formSequence)
          }
          handleInputValueChange={handleInputChange}
        />
      );
    case 'form':
      return (
        <PopupFormContent
          items={forms}
          inputVal={value}
          handleChange={(value) => handleChange('form', value, formSequence)}
          sequences={items}
          sequenceVal={formSequence}
          handleSequenceChange={(val) => handleChange('form', value, val)}
        />
      );
    case 'url':
      return (
        <PopupUrlContent
          inputVal={value.toString()}
          handleChange={(value) => handleChange('url', value, formSequence)}
          handleWebViewHeightChange={handleWebViewHeightChange}
          messengerExtensions={messengerExtensions}
          handleMessengerExtensionChange={handleMessengerExtensionChange}
          hasMessengerExtension={hasMessengerExtension}
          hasWebViewHeight={hasWebViewHeight}
          webViewHeight={webViewHeight}
        />
      );
    case 'phone':
      return (
        <PopupPhoneContent
          inputVal={value}
          handleChange={(value) => handleChange('phone', value, formSequence)}
        />
      );
    case 'basic':
      return (
        <PopupBasicContent
          blockType={blockType}
          attributeValue={inputValue}
          handleInputValueChange={handleInputChange}
        />
      );
    default:
      return (
        <PopupBlockContent
          items={items}
          inputVal={value}
          handleChange={(value) =>
            handleChange('sequence', value, formSequence)
          }
        />
      );
  }
};

const ButtonPopup = ({
  id,
  index,
  blockType,
  inputValue,
  title,
  description,
  sequences,
  formsData,
  hasMessengerExtension,
  messengerExtensions,
  hasWebViewHeight,
  webViewHeight,
  allowedTypes,
  selectedType,
  closePopover,
  value,
  formSequence,
  handleChange,
  selectedPlatform,
}) => {
  const [selectedTab, setSelectedTab] = useState(selectedType);
  const maxCount = ['webchat', 'app_messenger'].includes(
    selectedPlatform?.platform_type
  )
    ? 120
    : selectedPlatform?.platform_type === 'livechat_messenger'
    ? 32
    : 20;

  const isWhatsAppBSP = selectedPlatform?.platform_type === 'whatsapp_bsp';

  const handleTitleChange = (val) => {
    val.length <= maxCount &&
      handleChange(
        {
          id,
          buttonIndex: index,
          value,
          type: selectedTab,
          title: val,
          description,
          webview_height_ratio: webViewHeight,
          messenger_extensions: messengerExtensions,
          form_sequence: formSequence,
          input_value: inputValue,
        },
        'buttons'
      );
  };

  const handleDescriptionChange = (val) => {
    val.length <= 74 &&
      handleChange(
        {
          id,
          buttonIndex: index,
          value,
          type: selectedTab,
          title,
          description: val,
          webview_height_ratio: webViewHeight,
          messenger_extensions: messengerExtensions,
          form_sequence: formSequence,
          input_value: inputValue,
        },
        'buttons'
      );
  };

  const handleOtherChange = (type, changedItem, formSeq) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: changedItem,
        type: type,
        title,
        description,
        webview_height_ratio: webViewHeight,
        messenger_extensions: messengerExtensions,
        form_sequence: formSeq,
        input_value: inputValue,
      },
      'buttons'
    );
  };

  const handleMessengerExtensionChange = (val) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: value,
        type: selectedTab,
        title,
        description,
        webview_height_ratio: webViewHeight,
        messenger_extensions: val,
        form_sequence: formSequence,
        input_value: inputValue,
      },
      'buttons'
    );
  };

  const handleWebViewHeightChange = (val) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: value,
        type: selectedTab,
        title,
        description,
        webview_height_ratio: val,
        messenger_extensions: messengerExtensions,
        form_sequence: formSequence,
        input_value: inputValue,
      },
      'buttons'
    );
  };

  const handleInputChange = (val) => {
    handleChange(
      {
        id,
        buttonIndex: index,
        value: value,
        type: selectedTab,
        title: title,
        description,
        webview_height_ratio: webViewHeight,
        messenger_extensions: messengerExtensions,
        form_sequence: formSequence,
        input_value: val,
      },
      'buttons'
    );
  };

  return (
    <Card className='button-popup'>
      <div className='flex flex-col'>
        <h4>If the user clicks</h4>
        <div className='button-popup__absolute_-right'>
          <IconButton
            onClick={() => closePopover()}
            marginBottom={16}
            appearance='minimal'
            icon='cross'
          />
        </div>
        <TextInput
          className={
            'rounded w-full focus:outline-none focus:ring-primary focus:border-primary'
          }
          value={title}
          onChange={(e) => {
            handleTitleChange(e.target.value);
          }}
          placeholder='Title'
        />
        <div className='mt-2'>
          <TextCharInfo data={title} count={maxCount} />
        </div>
        {isWhatsAppBSP && blockType === 'quick_reply' ? (
          <div className='mt-2'>
            <TextInput
              className={
                'rounded w-full focus:outline-none focus:ring-primary focus:border-primary'
              }
              value={description}
              onChange={(e) => {
                handleDescriptionChange(e.target.value);
              }}
              placeholder='Description'
            />
            <div className='mt-2'>
              <TextCharInfo data={description} count={72} />
            </div>
          </div>
        ) : null}
        <h4>Redirect them to</h4>
        <Pane className='button-popup__tab'>
          <Tablist>
            {allowedTypes.map((tab) => {
              const val = tab.toLowerCase();
              return (
                <Tab
                  key={val}
                  id={val}
                  onSelect={() => {
                    setSelectedTab(val);
                    if (val.toLowerCase() === 'basic') {
                      handleOtherChange('basic', '', formSequence);
                    }
                  }}
                  isSelected={val === selectedTab}
                  aria-controls={`panel-${tab}`}
                  className={`button-popup__tab-item ${
                    val === selectedTab ? 'tab-selected' : ''
                  }`}
                >
                  {tab}
                </Tab>
              );
            })}
          </Tablist>
          <Pane>
            {allowedTypes.map((tab) => {
              const val = tab.toLowerCase();
              return (
                <Pane
                  key={val}
                  id={`panel-${val}`}
                  role='tabpanel'
                  aria-labelledby={val}
                  aria-hidden={val !== selectedTab}
                  display={val === selectedTab ? 'sequence' : 'none'}
                  className='button-popup__tab-content'
                >
                  {getContentComponent(
                    blockType,
                    inputValue,
                    selectedTab,
                    sequences,
                    formsData,
                    value,
                    formSequence,
                    handleOtherChange,
                    messengerExtensions,
                    handleMessengerExtensionChange,
                    hasMessengerExtension,
                    hasWebViewHeight,
                    webViewHeight,
                    handleWebViewHeightChange,
                    handleInputChange
                  )}
                </Pane>
              );
            })}
          </Pane>
        </Pane>
      </div>
    </Card>
  );
};

ButtonPopup.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  blockType: PropTypes.string,
  inputValue: PropTypes.string,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.string,
  hasMessengerExtension: PropTypes.bool.isRequired,
  messengerExtensions: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formSequence: PropTypes.number,
  handleChange: PropTypes.func,
  closePopover: PropTypes.func,
  index: PropTypes.number,
  sequences: PropTypes.array,
  formsData: PropTypes.array,
  selectedType: PropTypes.string,
  hasWebViewHeight: PropTypes.bool,
  webViewHeight: PropTypes.string,
  selectedPlatform: PropTypes.object,
};

ButtonPopup.defaultProps = {
  allowedTypes: ['Block', 'URL', 'Phone'],
  hasWebViewHeight: false,
};

const mapState = (state) => ({
  selectedPlatform: state.dashboard.selectedPlatform,
});

export default connect(mapState)(ButtonPopup);
