import {
  useSelector,
  AutomationListTable,
  useAutomationDashboard,
  AutomationDashboardHeader,
} from '../../export';
import useThrottledEffect from './hooks/useThrottleEffect';

const AutomationDashboard = () => {
  const { fetchUserActionFlowData, fetchIntegratedEcommerceData } =
    useAutomationDashboard();
  const { workflowDashboardTableOffset } = useSelector(
    (state: any) => state.workflowAutomation
  );

  useThrottledEffect(
    () => {
      fetchIntegratedEcommerceData();
      fetchUserActionFlowData(workflowDashboardTableOffset);
    },
    [],
    1500
  );

  return (
    <div className='relative w-full h-full'>
      <AutomationDashboardHeader />
      <div className='px-5'>
        <AutomationListTable />
      </div>
    </div>
  );
};

export default AutomationDashboard;
