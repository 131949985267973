import {
  Button,
  React,
  ArrowLeftIcon,
  useAutomationWorkflow,
} from '../../../export';

interface Props {}

const AutomationHistoryHeader: React.FC<Props> = () => {
  const { clearStateData, automationName, updatePrimitiveStateData } =
    useAutomationWorkflow();

  const renderAutomationHistoryHeaderView = () => {
    return (
      <>
        <div className='flex items-center justify-center'>
          <>
            <Button
              className='py-2 pl-2 pr-2 bg-green-100'
              icon={<ArrowLeftIcon className='h-5 text-green-500' />}
              onClick={() => {
                updatePrimitiveStateData({
                  key: 'automationFlowDisplayViewType',
                  value: 'dashboard',
                });
                clearStateData();
              }}
            />
            <div className='ml-3 text-gray-700 text-xl font-semibold'>
              {automationName}
            </div>
          </>
        </div>
      </>
    );
  };

  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      {renderAutomationHistoryHeaderView()}
    </div>
  );
};

export default AutomationHistoryHeader;
