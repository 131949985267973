import { SelectedTeamInterface } from 'index';
import {
  IEcommerceChannelPreferencesProperty,
  IEcommerceChannelProperty,
} from 'pages/integration/interface';
import React from 'react';
import { connect } from 'react-redux';
import { MultiEcommerce } from './components/multiEcommerce/MultiEcommerce';

interface Props {
  integratedEcommerceData: IEcommerceChannelProperty;
  selectedProject: SelectedTeamInterface;
  fetchEcommerceChannelPreferences: (payload: any) => boolean;
  ecommerceChannelPreferencesList: IEcommerceChannelPreferencesProperty[];
  connectEcommercePlatform: (payload: any) => boolean;
  disconnectEcommercePlatform: (payload: any) => boolean;
}

const EcommerceIndex: React.FC<Props> = ({
  integratedEcommerceData,
  selectedProject,
  ecommerceChannelPreferencesList,
  fetchEcommerceChannelPreferences,
  connectEcommercePlatform,
  disconnectEcommercePlatform,
}) => {
  return (
    <MultiEcommerce
      integratedEcommerceData={integratedEcommerceData}
      selectedProject={selectedProject}
      ecommerceChannelPreferencesList={ecommerceChannelPreferencesList}
      fetchEcommerceChannelPreferences={fetchEcommerceChannelPreferences}
      connectEcommercePlatform={connectEcommercePlatform}
      disconnectEcommercePlatform={disconnectEcommercePlatform}
    />
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(EcommerceIndex);
