import React, { Fragment, useRef } from 'react';
import Button from '../../../../library/button';
import Footer from '../../../../library/modal/component/Footer';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { channelInfo } from '../../../../utilities/utils';

interface Prop {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  children: React.ReactNode;
  platfromType: string;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  disableConfirm?: boolean;
  closeOnExternalClick?: boolean;
  handleMinimize?: () => void;
  isConfirmButtonLoading?: boolean;
  hasMinimizeButton?: boolean;
  hideCancel?: boolean;
  customFooterContent?: React.ReactNode;
}

const Modal: React.FC<Prop> = ({
  open,
  teamName = '',
  handleClose,
  children,
  platfromType,
  title = 'Title',
  description = 'Description',
  cancelText = 'Cancel',
  confirmText = 'Continue',
  handleConfirm,
  handleCancel,
  disableConfirm = false,
  closeOnExternalClick = true,
  handleMinimize,
  isConfirmButtonLoading = false,
  hasMinimizeButton = false,
  hideCancel = false,
  customFooterContent,
}) => {
  const { isRtlLanguage } = useTranslation();
  const cancelButtonRef = useRef(null);

  const headerSection = () => {
    return (
      <div className='px-6 py-3 bg-white border-b border-gray-200'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <img
              src={channelInfo(platfromType).image}
              alt={platfromType}
              loading='lazy'
              className={'h-5 w-5 ltr:mr-3 rtl:ml-3'}
            />
            {isRtlLanguage ? (
              <p className='font-sans text-lg font-medium leading-7'>{`${title} ${teamName}`}</p>
            ) : (
              <p className='font-sans text-lg font-medium leading-7'>{`${teamName}'s ${title}`}</p>
            )}
          </div>
          <div>
            <Button
              isCapsuleButton
              onClick={() => handleClose()}
              icon={<XMarkIcon />}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      {/*add `overflow-y-auto` in dialog for let modal scroll outside overlay*/}
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-20 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          if (closeOnExternalClick) {
            handleClose();
          }
        }}
      >
        <div className='flex items-center justify-center min-h-screen p-0 px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='items-center justify-center inline-block w-full max-w-3xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl'>
              {/*header starts*/}
              {headerSection()}
              <div className='h-auto overflow-y-auto'>{children}</div>
              <Footer
                cancelText={cancelText}
                confirmText={confirmText}
                hasTopBorder={false}
                isConfirmButtonLoading={isConfirmButtonLoading}
                disableConfirm={disableConfirm}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                hideCancel={hideCancel}
                customFooterContent={customFooterContent}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
