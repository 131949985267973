// import { useEffect, useState } from 'react';
import onSidedViewIcon from './assets/oneSidedView.svg';
import zigZagView from './assets/zigZagView.svg';

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from '../../../libraryV2/ui/card';
import useInboxPreferencesHook from './useInboxPreferencesHook';
import useTranslation from 'components/customHooks/useTranslation';

const InboxPreferences = () => {
  const { t } = useTranslation();
  const { authData, handleInboxPreferences } = useInboxPreferencesHook();

  const renderInboxPreferenceView = () => {
    let isLeftView = authData?.inbox_conversation_preference === 'left';
    return (
      <div className='mt-7'>
        <Card className='border border-gray-300 shadow-none rounded-[8px]'>
          <CardHeader>
            <CardTitle className='text-base font-medium text-gray-800'>
              {t('Conversation Preferences')}
            </CardTitle>
            <CardDescription className='text-sm font-normal text-gray-600'>
              {t('Choose how you want to view your messages in the inbox.')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className='flex gap-4'>
              <Card
                className={`relative w-[273px] h-[232px] border border-gray-300 shadow-none p-4 rounded-[8px] ${
                  isLeftView ? 'border-primary' : ''
                }`}
                onClick={() => handleInboxPreferences('left')}
              >
                <img src={onSidedViewIcon} alt='onSidedViewIcon' />
                <input
                  type='radio'
                  name='bHour'
                  className='absolute border-gray-300 focus:ring-primary text-primary top-7 right-7'
                  checked={authData?.inbox_conversation_preference === 'left'}
                />
                <CardTitle className='mt-3 text-sm font-medium text-gray-800'>
                  {t('One Sided View')}{' '}
                </CardTitle>
                <CardDescription className='text-xs font-normal text-gray-600'>
                  {t('All messages are displayed in a single list.')}{' '}
                </CardDescription>
              </Card>
              <Card
                className={`relative w-[273px] h-[232px] border border-gray-300 shadow-none p-4 rounded-[8px] ${
                  !isLeftView ? 'border-primary' : ''
                }`}
                onClick={() => handleInboxPreferences('left_right')}
              >
                <img src={zigZagView} alt='zigZagView' />
                <input
                  type='radio'
                  name='inbox view'
                  className='absolute border-gray-300 focus:ring-primary text-primary top-7 right-7'
                  checked={
                    authData?.inbox_conversation_preference === 'left_right'
                  }
                />
                <CardTitle className='mt-3 text-sm font-medium text-gray-800'>
                  {t('Zig-Zag View')}
                </CardTitle>
                <CardDescription className='text-xs font-normal text-gray-600'>
                  {t('Messages are displayed in a zig-zag pattern.')}
                </CardDescription>
              </Card>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };
  return (
    <div className='px-6 py-4 my-4'>
      <h3 className='text-2xl font-semibold text-gray-900'>
        {t('Inbox Preferences')}
      </h3>
      <p>
        {t('Personalize your inbox for optimal organization and efficiency.')}
      </p>
      {renderInboxPreferenceView()}
    </div>
  );
};

export default InboxPreferences;
