import React, { useState } from 'react';

import {
  navMenu,
  classNames,
  navBars3Icons,
  checkPermission,
  navMenuSettings,
  navMenuBroadcast,
  navMenuEnterprise,
} from '../../../utilities/utils';

import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import LogoArea from './LogoArea';
import WebchatSupport from 'components/globalComponents/WebchatSupport';
import MenuModal from '../../elements/MenuModal';
import TeamList from '../../elements/teamList';
import { Menu } from 'library/menu/Menu';
import UserMenu from '../../elements/UserMenu';
import DefaultAvatar from '../../../assets/images/defaultAvatar.svg';
import { SelectedTeamInterface } from 'index';
import { Modal } from 'library';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

import { Separator } from '../../../libraryV2/ui/separator';
import { TooltipProviderCustomised } from '../../../libraryV2/ui/tooltip';

import { Settings, Home } from 'lucide-react';
interface Prop {
  isTrialRemain: boolean;
  isAppsumoUser: boolean;
  navActiveId: number;
  permissionList: any[];
  selectedProject: SelectedTeamInterface;
  email: string;
  updateSelectedNav: (navID: number) => void;
  userAvatar?: string;
  userEmail?: string;
  userName?: string;
  userStatus?: string;
  selectedTeam: SelectedTeamInterface;
  navBarMenu: NavMenuProp[];
  isSuperProject: boolean;
  updateShowUpgradeModal: (payload: boolean) => void;
  isUsingNewInbox: boolean;
  updateNavBarMenu: (navMenuList: NavMenuProp[]) => void;
  updateStatusChangeReason: (reason: string, status: string) => void;
}

interface NavMenuProp {
  id: number;
  name: string;
  icon: any;
  link: string;
  global: boolean;
  isPro: boolean;
  // amplitudeEventName: string;
  permission: {
    readAccess: string;
    writeAccess: string;
  };
}

/**
 * only for status change on a specific project
 * stage = 262 & production 943
 */

const AwayReasons = [
  'Meeting',
  'Tea Break',
  'Lunch Break',
  'Training Break',
  'Feedback Break',
];

const Navbar: React.FC<Prop> = ({
  isAppsumoUser,
  navActiveId,
  permissionList,
  selectedProject,
  email,
  updateSelectedNav,
  userAvatar,
  userEmail,
  userName,
  userStatus,
  selectedTeam,
  navBarMenu,
  isUsingNewInbox,
  updateNavBarMenu,
  isSuperProject,
  updateShowUpgradeModal,
  isTrialRemain,
  updateStatusChangeReason,
}) => {
  const [showTeam, setShowTeam] = React.useState(false);
  const [showSupport, setShowSupport] = React.useState(false);
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [showDrawerComponent, setShowDrawerComponent] = React.useState(false);
  const [primaryNavMenuItems, setPrimaryNavMenuItems] = React.useState<
    NavMenuProp[]
  >([]);
  const [secondaryNavMenuItems, setSecondaryNavMenuItems] = React.useState<
    NavMenuProp[]
  >([]);
  const [fourthNavElementID, setFourthNavElementID] = React.useState(4);
  const [isDisabled, setDisabled] = React.useState(false);
  const { isPartnerRestricted } = usePartner();

  const topPosition =
    'top-[18px] ltr:left-[70px] rtl:right-[100px] xm:left-[12%]';
  const bottomPosition =
    'bottom-[18px] ltr:left-[70px] rtl:right-[90px] xm:left-[12%]';
  const topMiddlePosition =
    'top-[300px] ltr:left-[90px] rtl:right-[90px] xm:left-[12%]';
  const ref: any = React.useRef();

  let isPro = selectedProject?.is_using_new_billing
    ? selectedProject?.subscription_plan?.name !== 'free'
    : selectedProject?.subscription_plan?.plan_type !== 'free';

  const toggleMenuModal = () => {
    setShowTeam(!showTeam);
    setShowUserMenu(false);
    setShowDrawerComponent(false);
  };
  const toggleUserMenuModal = () => {
    setShowUserMenu(!showUserMenu);
    setShowTeam(false);
    setShowDrawerComponent(false);
  };
  const toggleDrawerMenuModal = () => {
    setShowDrawerComponent(!showDrawerComponent);
    setShowUserMenu(false);
    setShowTeam(false);
  };

  // removing Datalab icon from navbar if user is appsumo user
  // const checkDatalabPermission = (name: string) => {
  //   return isAppsumoUser && name === 'Datalab';
  // };

  // const checkIsSuperPeoject = (navId: number) => {
  //   if (navId !== 9) return true; // checking if prject has whatsapp broadcast access
  //   return isSuperProject;
  // };

  /**
   * This function is temporary and intended for use until workflow-automation development is complete.
   * NOTICE: It should be removed from the codebase once the feature is pushed to production.
   *
   * @param {string} link - The link to be checked for rendering workflow automation feature.
   * @returns {boolean} - Returns true if the workflow automation feature should be rendered, false otherwise.
   */
  // const checkShouldRenderWorkflowAutomation = (link: string) => {
  //   if (link !== 'workflow-automation') return true;

  //   return (
  //     ['dev', 'staging'].includes(Environment) ||
  //     [
  //       9, 1379, 1451, 2694, 343, 5179, 2331, 4880, 13044, 13035, 12978,
  //     ].includes(selectedProject?.id)
  //   );
  // };

  const getNavLists = (navMenuList: NavMenuProp[]) => {
    let height = window.innerHeight;
    let navBarMenuList: NavMenuProp[] = [];
    let navBarPopUpList: NavMenuProp[] = [];
    let navBarMenuItems = assignIconInNavBarMenu(navMenuList);

    if (navBarMenuItems.length === 0)
      return { navBarMenuList, navBarPopUpList };

    navBarMenuList =
      height <= 750 ? navBarMenuItems.slice(0, 4) : navBarMenuItems;
    navBarPopUpList =
      height <= 750 ? navBarMenuItems.slice(4, navBarMenuItems.length) : [];

    return { navBarMenuList, navBarPopUpList };
  };

  const assignIconInNavBarMenu = (rawNavList: NavMenuProp[]) => {
    const updatedNavList: any = [];
    !!rawNavList &&
      rawNavList.forEach((item) => {
        //@ts-ignore
        item.icon = navBars3Icons[item.id];
        updatedNavList.push(item);
      });

    return updatedNavList;
  };

  const handlePopUpMenuOnClick = (element: NavMenuProp) => {
    if (element.id >= 4 && secondaryNavMenuItems.length > 0) {
      let temp: number = 0;
      for (let i: number = 4; i < navBarMenu.length; i++) {
        if (element.id === navBarMenu[i].id) {
          temp = i;
        }
      }
      if (temp >= 4) {
        [navBarMenu[3], navBarMenu[temp]] = [navBarMenu[temp], navBarMenu[3]];
      }

      setFourthNavElementID(element.id);
      updateNavBarMenu(navBarMenu);
      toggleDrawerMenuModal();
    }
    return navBarMenu;
  };

  const renderHomeComponent = () => {
    return (
      <TooltipProviderCustomised side='right' content={'Home'}>
        <Link
          to='/dashboard'
          className='m-auto'
          aria-current={navActiveId === 0 ? 'page' : undefined}
        >
          <div
            role='button'
            className={classNames(
              'group w-9 h-9 mt-auto p-[8px] rounded-lg flex flex-col items-center justify-center text-xss font-medium transform transition duration-500 cursor-pointer',
              navActiveId === 0
                ? 'bg-primary text-gray-100'
                : 'bg-white text-gray-900 hover:bg-gray-200 hover:text-gray-800'
            )}
            aria-current={navActiveId === 0 ? 'page' : undefined}
          >
            <Home aria-hidden='true' size={18} />
          </div>
        </Link>
      </TooltipProviderCustomised>
    );
  };

  const renderSettingsComponent = () => {
    return (
      <TooltipProviderCustomised side='right' content='Settings'>
        <Link
          to={navMenuSettings[0].linkAccess}
          aria-current={navActiveId === 0 ? 'page' : undefined}
        >
          <div
            role='button'
            className={classNames(
              'group w-9 h-9 p-[8px] rounded-lg flex flex-col items-center justify-center text-xss font-medium transform transition duration-500 cursor-pointer',
              navActiveId === -1
                ? 'bg-primary text-gray-100'
                : 'bg-white text-black hover:bg-gray-200 hover:text-gray-800'
            )}
            aria-current={navActiveId === 0 ? 'page' : undefined}
          >
            <Settings size={18} />
          </div>
        </Link>
      </TooltipProviderCustomised>
    );
  };

  const renderNavComponents = (elements: NavMenuProp[]) => {
    if (elements.length === 0) return '';

    const getLink = (item: any) => {
      if (item.global) {
        return `/${item.link}`;
      } else {
        return `/projects/${selectedProject?.id}/${item.link}`;
      }
    };

    const handleOnClick = (item: any) => {
      if (item.global) {
        navigate(`/${item.link}`);
        updateSelectedNav(item.id);
      } else {
        if (item.isPro && !isPro) {
          updateShowUpgradeModal(true);
        } else {
          navigate(`/projects/${selectedProject?.id}/${item.link}`);
          updateSelectedNav(item.id);
        }
      }
    };

    return elements.map(
      (item: any, index: number) =>
        // comment out this statement and the checkDatalabPermission function if the datalab restrition for appsumo user impose again
        // !checkDatalabPermission(item.name) &&
        // checkIsSuperPeoject(item.id) && // checking if selected project has access on certain nav elements
        // checkShouldRenderWorkflowAutomation(item?.link) &&
        checkPermission(permissionList, item.permission.readAccess) && (
          <>
            {index === 1 || index === 5 ? (
              <Separator className='bg-[#DBDBDB] relative w-[150%]' />
            ) : null}
            <TooltipProviderCustomised
              content={t(item.name)}
              side={'right'}
              asChild
            >
              <button
                type='button'
                onClick={() => navigate(getLink(item))}
                disabled={item.id === navActiveId}
                aria-current={navActiveId === item.id ? 'page' : undefined}
              >
                <div
                  role={'button'}
                  className={classNames(
                    'group w-9 h-9 mt-auto p-[9px] rounded-lg flex flex-col items-center justify-center text-xss font-medium transform transition duration-500 cursor-pointer',
                    navActiveId === item.id
                      ? 'bg-primary text-gray-100'
                      : 'bg-white text-black hover:bg-gray-200 hover:text-gray-800'
                  )}
                  aria-current={item.id === navActiveId ? 'page' : undefined}
                  onClick={() => {
                    handleOnClick(item);
                  }}
                >
                  {item.isPro && !isPro && (
                    <span className='inline-flex absolute top-[-3px] right-[-8px] px-1 py-0.5 items-center text-xs font-medium text-blue-800 bg-blue-100 rounded'>
                      Pro
                    </span>
                  )}
                  <div
                    key={index}
                    className='flex items-center justify-center'
                    onClick={() => {
                      handlePopUpMenuOnClick(item);
                      setShowDrawerComponent(false);
                    }}
                  >
                    {!!item.icon && (
                      <item.icon
                        size={18}
                        className={classNames(
                          item.id === navActiveId
                            ? 'text-white'
                            : item.isPro && !isPro
                            ? 'text-gray-900 group-hover:text-gray-700'
                            : 'text-gray-900 group-hover:text-gray-800'
                        )}
                        aria-hidden='true'
                      />
                    )}
                  </div>
                </div>
              </button>
            </TooltipProviderCustomised>
          </>
        )
    );
  };

  const handleOnCloseCallback = () => {
    setShowSupport(!showSupport);
    let unMountNode = document.getElementById('icWebChat');
    let unMountScript = document.getElementById('icWebChatScript');
    unMountScript?.remove();
    unMountNode?.remove();
  };

  const renderExtraNavbarComponent = () => {
    return secondaryNavMenuItems.length > 0
      ? renderNavComponents(secondaryNavMenuItems)
      : '';
  };

  React.useEffect(() => {
    let navMenuItems = navMenu;
    if (
      selectedProject &&
      selectedProject.subscription_plan?.plan_type !== 'free'
    ) {
      navMenuItems = [...navMenuItems, ...navMenuBroadcast];
    }
    if (
      selectedProject &&
      ['enterprise', 'business', 'appsumo'].includes(
        selectedProject.subscription_plan?.plan_type
      )
    ) {
      navMenuItems = [...navMenuItems, ...navMenuEnterprise];
    }
    updateNavBarMenu(navMenuItems);
    // eslint-disable-next-line
  }, [selectedProject?.id]);

  React.useEffect(() => {
    const filteredNabBar =
      navBarMenu?.filter(
        (v) => !isPartnerRestricted(`Navbar/node/${v.name}`)
      ) ?? navBarMenu;

    let navLists = getNavLists(filteredNabBar);

    setPrimaryNavMenuItems(navLists?.navBarMenuList);
    setSecondaryNavMenuItems(navLists?.navBarPopUpList);
    // eslint-disable-next-line
  }, [navBarMenu, fourthNavElementID]);

  React.useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        (showTeam || showUserMenu || showDrawerComponent) &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        if (
          e.target.id !== 'teamImage' &&
          e.target.id !== 'userPicture' &&
          e.target.id !== 'aliceLogo' &&
          e.target.id !== 'threeDot'
        ) {
          setShowTeam(false);
          setShowUserMenu(false);
          setShowDrawerComponent(false);
        }
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showTeam, showUserMenu, showDrawerComponent]);

  React.useEffect(() => {
    if (selectedProject) {
      let trialEnded =
        !selectedProject.creator?.is_trial_user && !selectedProject.is_pro;
      let accessDisabled = trialEnded || selectedProject.is_access_disabled;
      setDisabled(accessDisabled);
    }
    //eslint-disable-next-line
  }, [selectedProject?.id]);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [awayReason, setAwayReason] = useState<string>('');

  const { t } = useTranslation();

  const renderReasonModal = () => {
    return (
      <Modal
        title={t('Select Away Reason')}
        height='auto'
        confirmText={t('Confirm')}
        description=' '
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        cancelText={t('Cancel')}
        disableConfirm={awayReason === ''}
        handleConfirm={() => {
          updateStatusChangeReason(
            awayReason,
            userStatus === 'online' ? 'away' : 'online'
          );
          setModalOpen(false);
        }}
        handleCancel={() => setModalOpen(false)}
      >
        <div className='p-4'>
          {AwayReasons.map((value, index) => {
            return (
              <div className='flex items-center py-1' key={index}>
                <input
                  type='radio'
                  id={value + index}
                  className='text-green-600 without-ring'
                  name='reason'
                  value={value}
                  onChange={(e) => setAwayReason(e.target.value)}
                />
                <label
                  htmlFor={value + index}
                  className='block ml-3 text-sm font-medium text-gray-700'
                >
                  {t(value)}
                </label>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  };

  return (
    <>
      {/* Narrow sidebar*/}
      <nav
        aria-label='Sidebar'
        className='hidden shadow md:block md:flex-shrink-0 md:bg-white w-[52px]'
      >
        <div
          className={`sticky top-0 border-b bg-white  border-[#DBDBDB] flex flex-col justify-center items-center z-10 h-[53px]`}
        >
          <LogoArea
            origin='navbar'
            teamName={selectedProject?.name}
            teamAvatar={selectedProject?.image}
            toggleShowTeams={toggleMenuModal}
            showTeams={showTeam}
          />
          {/* <Separator className='bg-[#DBDBDB] m-auto' /> */}
        </div>
        <div className='relative flex flex-col w-[52px] items-center p-2 overflow-auto scrollbar-hide h-[78%]'>
          {!isDisabled && (
            <>
              <div className='flex flex-col items-center justify-center w-full gap-2'>
                {renderHomeComponent()}
                {renderNavComponents([
                  ...primaryNavMenuItems,
                  ...secondaryNavMenuItems,
                ])}
                {renderSettingsComponent()}
              </div>
            </>
          )}
          {isDisabled && selectedProject?.is_creator && (
            <div className='flex flex-col h-full'>
              {renderSettingsComponent()}
            </div>
          )}
        </div>
        <>
          <div
            className={`absolute bottom-0 border-t bg-white  border-[#DBDBDB] flex flex-col justify-center items-center w-[52px] h-14`}
          >
            <Menu
              as='div'
              className='inline-block text-left'
              onClick={() => toggleUserMenuModal()}
            >
              <>
                <span className='sr-only'>Open user menu</span>
                <div className='relative inline-block clcursor-pointer has-tooltip'>
                  <img
                    id='userPicture'
                    className={`w-8 h-8 inline-block object-cover rounded-full hover:visible`}
                    src={!!userAvatar ? userAvatar : DefaultAvatar}
                    alt='Profile'
                  />
                  {!!true && (
                    <span
                      className={`absolute -bottom-1 right-0 inline-block w-3 h-3 ${
                        userStatus === 'online'
                          ? 'bg-green-600'
                          : 'bg-yellow-400'
                      } border-2 border-white rounded-full`}
                    />
                  )}
                </div>
                {/* </Menu.Button> */}
              </>
            </Menu>
          </div>
        </>
        {showSupport && (
          <WebchatSupport
            opened={showSupport}
            onCloseCallback={handleOnCloseCallback}
          />
        )}
      </nav>
      {renderReasonModal()}
      <div ref={ref}>
        <MenuModal
          modalState={showTeam}
          classForBanner={'manipulateMenuModal'}
          innerDesign={
            <TeamList
              toggleMenuModal={toggleMenuModal}
              selectedTeam={selectedTeam}
            />
          }
          position={topPosition}
        />
        <MenuModal
          modalState={showUserMenu}
          innerDesign={
            <UserMenu
              handleReasonModalOpen={(val: boolean) => {
                setModalOpen(val);
                val && setShowUserMenu(false);
              }}
            />
          }
          position={bottomPosition}
        />
        <MenuModal
          modalState={showDrawerComponent}
          innerDesign={renderExtraNavbarComponent()}
          position={topMiddlePosition}
          width={60}
        />
      </div>
    </>
  );
};

const mapState = <T extends { auth: any; dashboard: any; settings: any }>(
  state: T
) => ({
  isTrialRemain: state.auth.trial_remaining > 0,
  isAppsumoUser: state.auth.is_appsumo_user,
  navActiveId: state.dashboard.navActiveId,
  selectedProject: state.dashboard.selectedProject,
  permissionList: state.dashboard.permission.list || [],
  email: state.auth.email,
  userName: state.auth.full_name,
  userEmail: state.auth.email,
  userAvatar: state.auth.avatar,
  userStatus: state.auth.status,
  selectedTeam: state.dashboard.selectedProject,
  navBarMenu: state.dashboard.navBarMenu,
  isSuperProject: state.dashboard.isSuperProject,
  isUsingNewInbox: state.settings.isUsingNewInbox,
});

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch.auth.logout(),
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
  updateNavBarMenu: (navBarList: NavMenuProp[]) =>
    dispatch.dashboard.updateNavBarMenu(navBarList),
  updateStatusChangeReason: (reason: string, status: string) =>
    dispatch.auth.updateStatusChangeReason({ reason, status }),
  updateShowUpgradeModal: (payload: boolean) =>
    dispatch.dashboard.updateShowUpgradeModal(payload),
});

export default connect(mapState, mapDispatch)(Navbar);
