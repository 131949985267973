import React, { createElement, ElementType } from 'react';
import { classNames, getInitials } from 'utilities/utils';

export type SizeProps = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type StatusProps = 'online' | 'offline' | 'away' | 'busy';

interface AvatarProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * dom selector for avatar container, default `span`
   */
  as?: ElementType;
  /**
   * src link for avatar
   */
  src?: string;
  /**
   * fallback for src, default `Unknown User`
   */
  name?: string;
  /**
   * className to customize avatar contaienr
   */
  className?: string;
  /**
   * if Avatar component is Square or Rounded. default `true`
   */
  isRounded?: boolean;
  /**
   * default `md`
   * @type 'xxs | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
   */
  size?: SizeProps;
  /**
   * default `undefined`
   * @type 'online' | 'offline' | 'away' | 'busy';
   */
  status?: StatusProps;
  /**
   * aria-label for screen readers
   */
  'aria-label'?: string;
  /**
   * children will not be available for this component
   */
  children?: never;
}

const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(function Avatar(
  {
    as = 'span',
    src = '',
    name = 'Unknown User',
    className = '',
    isRounded = true,
    size = 'md',
    status = undefined,
    ...other
  },
  ref
) {
  const getSizeClasses = (size: SizeProps) => {
    switch (size) {
      case 'xxs':
        return 'h-4 w-4';
      case 'xs':
        return ' h-6 w-6';
      case 'sm':
        return ' h-8 w-8';
      case 'lg':
        return 'h-12 w-12';
      case 'xl':
        return 'h-14 w-14';
      case 'md':
      default:
        return 'h-10 w-10 ';
    }
  };

  const getStatusSizeClasses = (size: SizeProps) => {
    switch (size) {
      case 'xxs':
        return 'h-1 w-1';
      case 'xs':
        return 'h-1.5 w-1.5';
      case 'sm':
        return 'h-2 w-2';
      case 'lg':
        return 'h-3 w-3';
      case 'xl':
        return 'h-3.5 w-3.5';
      case 'md':
      default:
        return 'h-2.5 w-2.5 ';
    }
  };

  const hasValidSource = src && src.match(/^https?:\/\/.+\/.+$/);

  return createElement(
    as,
    {
      className: classNames(
        'relative',
        hasValidSource && 'inline-block',
        !hasValidSource &&
          'inline-flex items-center justify-center bg-gray-500',
        !hasValidSource && isRounded ? ' rounded-full' : 'rounded-md',
        getSizeClasses(size),
        className
      ),
      ref,
      ...other,
    },
    <>
      {hasValidSource && (
        <img
          className={classNames(
            'cursor-pointer',
            getSizeClasses(size),
            isRounded ? 'rounded-full' : 'rounded-md'
          )}
          src={src}
          alt={name || ''}
        />
      )}
      {!hasValidSource && (
        <span
          className={classNames(
            'font-medium leading-none text-white',
            size === 'xxs' ? 'text-xss' : ' text-sm'
          )}
        >
          {getInitials(name).toUpperCase()}
        </span>
      )}

      {status && (
        <span
          className={classNames(
            'absolute bottom-0 right-0 block w-4 h-4  rounded-full ring-2 ring-white',
            getStatusSizeClasses(size),
            status === 'online' && 'bg-green-400',
            status === 'offline' && 'bg-gray-400',
            status === 'away' && 'bg-yellow-500',
            status === 'busy' && 'bg-red-400'
          )}
        />
      )}
    </>
  );
});

export default Avatar;
