import React, { Component, Fragment } from 'react';
import DataLabForm from './components/DataLabForm';
import { toaster } from 'evergreen-ui';
import { connect } from 'react-redux';
import { HasPermission } from '../../../components';
import { checkPermission } from '../../../utilities/utils';

class DataLabCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      writeAccess: 'write:data_lab',
    };
  }
  errorValidation = () => {
    const { title, type } = this.props.dataLabData;
    const { name, background_color, font_color } =
      this.props.dataLabData.button;
    if (!!title && type === 'inventory') {
      return true;
    }
    if (!!title && type === 'form' && background_color && name && font_color) {
      return true;
    }
    toaster.warning('Missing Information', {
      description: 'There are missing information on this form',
    });
    return false;
  };
  handleCreate = () => {
    if (this.errorValidation()) {
      if (!!this.props.labId && !!this.props.projectId) {
        this.props.updateDataLabInfo(this.props.projectId, this.props.labId);
      } else {
        this.props.createDataLab(this.props.projectId);
      }
    }
  };

  componentDidMount() {
    const hasWritePermission = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    if (hasWritePermission) {
      if (!!this.props.labId && !!this.props.projectId) {
        //if lab id exist this is edit
        const { fetchDataLabInfo, projectId, labId } = this.props;
        fetchDataLabInfo(projectId, labId);
      }
    }
  }
  componentWillUnmount() {
    this.props.resetDataLabCreateInfo();
  }
  render() {
    const { updateDataLabCreateInfo } = this.props;
    return (
      <HasPermission shortCode={this.state.writeAccess}>
        <Fragment>
          <div className='data-lab__form'>
            <DataLabForm
              title={this.props.dataLabData.title}
              isEditing={!!this.props.labId}
              handleTitle={(val) => updateDataLabCreateInfo('title', val)}
              description={this.props.dataLabData.description}
              handleDescription={(val) =>
                updateDataLabCreateInfo('description', val)
              }
              logo={this.props.dataLabData.logo.url}
              handleLogo={(url) => updateDataLabCreateInfo('logo', url)}
              type={this.props.dataLabData.type === 'form'}
              handleType={(type) =>
                updateDataLabCreateInfo('type', type ? 'form' : 'inventory')
              }
              fontColor={this.props.dataLabData.button.font_color}
              handleFontColor={(hex) =>
                updateDataLabCreateInfo('font_color', hex)
              }
              buttonColor={this.props.dataLabData.button.background_color}
              handleButtonColor={(hex) =>
                updateDataLabCreateInfo('background_color', hex)
              }
              buttonText={this.props.dataLabData.button.name}
              handleButtonText={(val) => updateDataLabCreateInfo('name', val)}
              webHookUrl={this.props.dataLabData.webhook_url}
              handleWebHookUrl={(val) =>
                updateDataLabCreateInfo('webhook_url', val)
              }
              buttonLoading={this.props.createDataLabLoader}
              handleCreate={() => this.handleCreate()}
            />
          </div>
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  createDataLabLoader: state.loading.effects.dataLab.createDataLab,
  dataLabData: state.dataLab.dataLabData,
  permissionList: state.dashboard.permission?.list || [],
});
const mapDispatch = (dispatch) => ({
  createDataLab: (projectId) => dispatch.dataLab.createDataLab(projectId),
  updateDataLabCreateInfo: (key, value) =>
    dispatch.dataLab.updateDataLabCreateInfo({ key, value }),
  fetchDataLabInfo: (projectId, labId) =>
    dispatch.dataLab.fetchDataLabInfo({ projectId, labId }),
  updateDataLabInfo: (projectId, labId) =>
    dispatch.dataLab.updateDataLabInfo({ projectId, labId }),
  resetDataLabCreateInfo: () => dispatch.dataLab.resetDataLabCreateInfo(),
});

const DataLabCreateContainer = connect(mapState, mapDispatch)(DataLabCreate);

export default DataLabCreateContainer;
