import React from 'react';
import facebookLogo from '../../../assets/icons/facebookLogo.svg';

import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  pageList: facebookPageListProps[] | null;
  selectedPage: facebookPageListProps;
  setSelectedPage: (value: any) => void;
  platformType?: string;
  success?: boolean;
}

const Step2: React.FC<Props> = ({
  pageList,
  success,
  selectedPage,
  platformType,
  setSelectedPage,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  return (
    <>
      <div className='pb-32 overflow-y-auto'>
        <div className='border rounded'>
          {!!pageList &&
            pageList.map((page: facebookPageListProps, index: number) => (
              <div
                key={index}
                className={`flex justify-between py-3 border-t ${
                  selectedPage.name === page.name
                    ? 'bg-green-200'
                    : page.is_connected
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                <span className='flex'>
                  <input
                    name='notification-method'
                    type='radio'
                    onChange={() => setSelectedPage(page)}
                    disabled={page.is_connected}
                    checked={
                      selectedPage?.name.length &&
                      selectedPage.name === page.name
                        ? true
                        : false
                    }
                    className='w-4 h-4 mx-4 mt-2 border-gray-300 text-primary focus:ring-primary'
                  />
                  <img
                    className='inline-block w-8 h-8 rounded-full'
                    src={page.avatar.length ? page.avatar : facebookLogo}
                    alt=''
                    onClick={
                      !page.is_connected
                        ? () => setSelectedPage(page)
                        : undefined
                    }
                  />
                  <label
                    htmlFor={page.name}
                    className='block mt-1.5 ltr:ml-3 rtl:mr-3 rtl:text-right text-sm font-medium text-gray-700'
                    onClick={
                      !page.is_connected
                        ? () => setSelectedPage(page)
                        : undefined
                    }
                  >
                    {page.name}
                  </label>
                </span>
                {page.is_connected && (
                  <span className='inline-flex items-center px-3 ltr:mr-3 rtl:ml-3 rtl:text-right text-center text-green-800 bg-green-100 rounded cursor-pointer'>
                    {t('Connected')}
                  </span>
                )}
              </div>
            ))}
        </div>
        {platformType === 'instagram_messenger' && (
          <div className='p-6 border-t'>
            <label
              htmlFor='nlp_selection'
              className='block mb-1 text-sm rtl:text-right font-medium text-gray-700'
            >
              {t('Allow Permissions')}*
            </label>
            <p className='mt-2 rtl:text-right text-sm text-gray-500'>
              {isRtlLanguage ? '١' : '1'}. {t('Go to Instagram')}{' '}
              <b>{t('Settings')}</b> &#8594; <b>{t('Privacy')}</b> &#8594;{' '}
              <b>{t('Messages')}</b>.
            </p>
            <p className='mt-2 rtl:text-right text-sm text-gray-500'>
              {isRtlLanguage ? '٢' : '2'}.{' '}
              {t('At the bottom of the page, turn on the toggle for')}{' '}
              <b>{t('"Allow access to messages".')}</b>
            </p>
          </div>
        )}
        {!success && platformType === 'instagram_messenger' && (
          <div className='flex justify-center p-6 border-t'>
            <div className='justify-center'>
              <XCircleIcon
                className='w-5 h-5 text-red-500'
                aria-hidden='true'
              />
            </div>
            <p className='ltr:ml-2 rtl:mr-2 rtl:text-right text-sm text-red-600'>
              {t(
                'We Failed to establish a connection with Instagram. Please try again with the required permissions.'
              )}{' '}
              <br />• {t('Be the')} <b>{t('owner')}</b> {t('or')}{' '}
              <b>{t('admin')}</b> {t('of a Facebook page.')} <br />•{' '}
              {t('Select the')} <b>{t('Instagram Business Page')}</b>{' '}
              {t('connected to a')} <b>{t('Facebook Business Page')}</b>. <br />
              • {t('Turn on the toggle for')}{' '}
              <b>{t('"Allow access to messages"')}</b>{' '}
              {t('from Instagram Settings.')}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default Step2;
