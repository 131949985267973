import React from 'react';
import Carousel from 'nuka-carousel';
import { XMarkIcon } from '@heroicons/react/20/solid';
import nextIcon from '../../../../assets/images/nextIcon.svg';
import previousIcon from '../../../../assets/images/previousIcon.svg';
import { FileText } from 'lucide-react';
import { PdfFileIcon } from 'pages/settings/savedReplies/utils';

interface IAttachemnt {
  id: string;
  name: string;
  type: 'image' | 'video' | 'file' | string;
  url: string;
}
interface Props {
  attachmentLists: IAttachemnt[];
  handleRemoveAttachment: (index: number) => void;
}

const AttachmentPreview: React.FC<Props> = ({
  attachmentLists,
  handleRemoveAttachment,
}) => {
  const renderSingleAttachmentView = (attachment: IAttachemnt) => {
    switch (attachment.type) {
      case 'image': {
        return (
          <div
            key={attachment.id}
            className='h-5 w-5 flex-shrink-0 cursor-pointer rounded-md border border-zinc-200'
          >
            <img
              src={attachment.url}
              className='h-full w-full rounded-md object-cover'
              alt={attachment.name}
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div
            key={attachment.id}
            className='h-5 flex-shrink-0 w-5 grid rounded-lg border border-zinc-200'
          >
            <video className='object-fill rounded-md h-full w-full'>
              <source src={attachment.url} />
            </video>
          </div>
        );
      }
      default: {
        return (
          <div
            key={attachment.id}
            className='h-5 group flex-shrink-0 relative w-5 '
          >
            {attachment?.name?.endsWith('.pdf') ? (
              <PdfFileIcon size={20} />
            ) : (
              <FileText className='w-5 h-5 text-zinc-900' />
            )}
          </div>
        );
      }
    }
  };

  const renderNukaCarouselCenterLeftControlsView = (
    previousSlide: any,
    currentSlide: number,
    slideCount: number
  ) => {
    return (
      <button
        className={'nuka-carousel-slide-button'}
        onClick={previousSlide}
        disabled={currentSlide === 0}
        style={{
          cursor: currentSlide === 0 ? 'not-allowed' : 'pointer',
          background: currentSlide === 0 ? '#E5E7EB' : 'white',
        }}
      >
        <img src={previousIcon} alt={'<'} />
      </button>
    );
  };

  const renderNukaCarouselCenterRightControlsView = (
    nextSlide: any,
    currentSlide: number,
    slideCount: number
  ) => {
    let isDisabled = currentSlide + 1 === slideCount - 1 || slideCount <= 1;
    return (
      <button
        className={'nuka-carousel-slide-button'}
        onClick={nextSlide}
        disabled={isDisabled}
        style={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          background: isDisabled ? '#E5E7EB' : 'white',
        }}
      >
        <img src={nextIcon} alt={'>'} />
      </button>
    );
  };

  const handleAttachmentClick = (url: string) => {
    window.open(url, '_blank');
  };

  const renderNukaCarouselPreview = () => {
    return (
      // there is dependency issue with create react app. it does not have build mechanism to compile .mjs file that nuka's latest package contain.
      // that's why we are using older version of nuka carousel package rather than the latest one.
      //@ts-ignore
      <Carousel
        slidesToShow={3}
        slidesToScroll={2}
        style={{
          paddingLeft: '40px',
          paddingTop: '6px',
          paddingRight: '30px',
        }}
        renderCenterLeftControls={({
          previousSlide,
          currentSlide,
          slideCount,
        }) =>
          renderNukaCarouselCenterLeftControlsView(
            previousSlide,
            currentSlide,
            slideCount
          )
        }
        renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) =>
          renderNukaCarouselCenterRightControlsView(
            nextSlide,
            currentSlide,
            slideCount
          )
        }
        defaultControlsConfig={{
          pagingDotsStyle: { display: 'none' },
        }}
      >
        {attachmentLists.map((attachment, index: number) => (
          <div
            key={index}
            className={
              'p-1 flex justify-between items-center gap-2 border bg-gray-50 border-gray-200 mr-2 rounded-md h-fit mb-2'
            }
          >
            {renderSingleAttachmentView(attachment)}
            <p
              onClick={() => handleAttachmentClick(attachment?.url)}
              className='flex-grow truncate text-textPrimary text-xs cursor-pointer'
            >
              {attachment?.name ?? ''}
            </p>
            <div>
              <XMarkIcon
                data-testid='remove-button'
                className='w-5 h-5 text-gray-500 cursor-pointer hover:text-red-500'
                onClick={() => handleRemoveAttachment(index)}
              />
            </div>
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <>
      {attachmentLists.length > 0 && (
        <div className={`border-none px-4 mb-2.5`}>
          <div className='flex flex-wrap w-auto h-10'>
            {renderNukaCarouselPreview()}
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentPreview;
