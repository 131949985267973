import { Position, getConnectedEdges, useStore, Handle } from 'reactflow';
import {
  React,
  useMemo,
  useCallback,
  // CustomHandle,
  NodeDecorator,
  UtilityContents,
  UtilityFunctions,
  useReactFlowGraphServices,
  useAutomationWorkflow,
} from '../../../../export';

interface Props {
  id: string;
  type: string;
  data: any;
  position: { x: number; y: number };
}

const selector = (s: any) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const TriggerNode: React.FC<Props> = ({ id, type, data, position }) => {
  const { updatePrimitiveStateData, isViewLog } = useAutomationWorkflow();

  const { nodeInternals, edges } = useStore(selector);

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(id);
    const connectedEdges = getConnectedEdges([node], edges);

    return connectedEdges.length < 1;
    //@ts-ignore
  }, [nodeInternals, edges, id]);

  const {
    selectedTrigger: triggerState,
    automationNodes,
    addConditionOrActionButton,
    shouldRenderConditionAndActionButton,
  } = useReactFlowGraphServices();
  const selectedTrigger = isViewLog ? data : triggerState;
  const handleOnClick = useCallback(
    (sourcehandleId) => {
      updatePrimitiveStateData({ key: 'selectedNodeId', value: id });
      if (
        shouldRenderConditionAndActionButton(id, automationNodes) &&
        isHandleConnectable
      ) {
        addConditionOrActionButton(id, automationNodes);
      }
    },
    // eslint-disable-next-line
    [automationNodes]
  );

  return (
    <NodeDecorator nodeType={'trigger-node'} nodeId={id}>
      <div className={`w-auto h-auto p-4`}>
        <div
          className='flex w-full items-center'
          onClick={() => handleOnClick(`success_sourceHandle_${id}`)}
        >
          {selectedTrigger?.trigger_icon && (
            <div className='flex items-center justify-center w-16 rounded-md h-14'>
              <img
                src={selectedTrigger?.trigger_icon}
                alt={selectedTrigger?.trigger_name}
              />
            </div>
          )}
          {!selectedTrigger?.trigger_icon && (
            <div className='pt-[6px] pb-[4px] pl-[3px] rounded-md w-10 h-10 bg-indigo-50'>
              {UtilityFunctions.getTriggerIcon(selectedTrigger?.trigger_code)}
            </div>
          )}
          <div className='flex flex-col ml-3 '>
            <span className='text-sm font-bold text-gray-700 capitalize'>
              {selectedTrigger?.trigger_name}
            </span>

            {/* <span className='text-xs text-gray-600'>{`(Change Trigger)`}</span> */}
          </div>
        </div>
        <Handle
          type='source'
          isConnectable={true}
          position={Position.Bottom}
          id={`success_sourceHandle_${id}`}
          className={UtilityContents.NodeBottomMiddleHandleClass}
        />
      </div>
    </NodeDecorator>
  );
};

export default TriggerNode;
