import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  emailChannelTitle: string;
  userEmailId: string | null;
  setEmailChannelTitle: (data: string) => void;
}

const Step2: React.FC<Props> = ({
  emailChannelTitle,
  userEmailId,
  setEmailChannelTitle,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex flex-col'>
        <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
          {t('Title')}
          <span className='text-red-500'>*</span>
        </label>
        <input
          type='text'
          className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 text-sm leading-5'
          placeholder={'My Email Channel'}
          name={'email_title'}
          value={emailChannelTitle}
          onChange={(e) => {
            setEmailChannelTitle(e.target.value);
          }}
        />
        <p className='text-gray-500 text-xs leading-5'>
          {t(
            'The title is private. We use this info to identify this channel on MyAlice.'
          )}
        </p>
      </div>
      <div className='flex flex-col'>
        <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
          {t('Email Address')}
          <span className='text-red-500'>*</span>
        </label>
        <span
          className='rounded-md border text-gray-600 bg-gray-200 border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
        >
          {userEmailId}
        </span>
        <span />
      </div>
    </>
  );
};
export default Step2;
