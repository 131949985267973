import { useRef, memo } from 'react';
import { useDrag } from 'react-dnd';
import { IDataLabField } from 'pages/datalab/interface';
import { DragTypes, FormFields } from 'pages/datalab/export';

interface IProps {
  field: IDataLabField;
  index: number;
  type?: string;
}

const BlockEdit: React.FC<IProps> = ({
  field,
  index,
  type = DragTypes.BLOCK_FIELD,
}) => {
  const ref = useRef(null);

  // Drag setup
  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => ({ field, index, type }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (!field || !field.type) {
    return null;
  }

  // Fetch the appropriate form field component
  const defaultFormField = FormFields[field.type] ?? FormFields['text'];
  const { Component } = defaultFormField;

  if (!Component && !defaultFormField) {
    return null;
  }

  drag(ref);

  return (
    <div>
      <Component
        field={field}
        nodeRef={ref}
        isDragging={isDragging}
        isSmall={type === DragTypes.GROUP_FIELD}
      />
    </div>
  );
};

export default memo(BlockEdit);
