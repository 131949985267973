import React from 'react';
import { SideSheet as OrderModal } from '../../../../../../library';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import Modal from '../../../../../components/elements/redesign/Modal';
import { PaymentMethodProps, ShippingMethodProps } from './interfaces'

interface SelectedProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

interface CustomerDataProps {
  customer_id: number;
  platform_id: number;
  project_id: number;
  ecommerce_type: string;
  ecommerce_account_id: number;
  ecommerce_account_email: string;
  ecommerce_account_phone: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
  alice_cart: SelectedProductDataProps[];
  billing_address: EcomAddressProps | null;
  shipping_address: EcomAddressProps | null;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  company: string;
  email: string;
  phone: string;
}

interface Prop {
  isSameShippingAddress: boolean;
  paymentMethod: PaymentMethodProps;
  shippingMethod: ShippingMethodProps;
  createOrderOpen: boolean;
  setCreateOrderOpen: (value: boolean) => void;
  customerId: number;
  clearState: () => void;
  customerData: CustomerDataProps;
  createOrder: (teamId: number, customerId: number, ticketId: number) => Promise<any>;
  ticketId: number;
  createOrderLoading: boolean;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
  updateMinimizeModal: () => void;
  teamId: number;
}

const EcomOrderCreate: React.FC<Prop> = ({
  isSameShippingAddress,
  paymentMethod,
  shippingMethod,
  createOrderOpen,
  setCreateOrderOpen,
  customerId,
  clearState,
  customerData,
  createOrder,
  ticketId,
  createOrderLoading,
  updateCart,
  updateMinimizeModal,
  teamId,
}) => {
  const [step, setStep] = React.useState(1);
  const [showCloseWarningModal, setShowCloseWarningModal] =
    React.useState(false);
  const containerRef = React.createRef();
  const [createdOrderState, setCreatedOrderState] = React.useState('Loading');
  // didnt wanted to use callback so used three state instead. "Loading", "Try Again", "Exit" it has to be same !

  const handleConfirm = async () => {
    if (step === 1) {
      // call api to store
      setStep(2);
    } else if (step === 2) {
      // update delivery information
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      if (createdOrderState === 'Exit') {
        setCreateOrderOpen(false);
        setTimeout(() => {
          setStep(1);
        }, 1000);
      } else if (createdOrderState === 'Try Again') {
        setStep(1);
        setCreatedOrderState('Loading');
      }
    }
  };

  const handleCancel = () => {
    if (step === 2) {
      // update delivery information
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    } else if (step === 4) {
      setStep(3);
    }
  };

  const getOrderHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: 'Create New Order',
          description:
            'Click on the search bar below to browse your inventory. A selected product from the inventory will be added to the cart. You can further customize the order from there.',
        };
      case 2:
        return {
          title: 'Customer Information',
          description:
            'Please complete the required fields for order placement',
        };
      case 3:
        return {
          title: 'Order Summary',
          description:
            'Please check the information before confirming the order.',
        };
    }
  };

  React.useEffect(() => {
    //we need this to scroll to to from each step
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, [step]);

  const checkStep1NextButton = () => {
    // first we check if we have any product in alice_cart
    const cart: SelectedProductDataProps[] = customerData?.alice_cart || [];
    let flag: boolean = false;
    if (cart.length > 0) {
      cart.forEach((product) => {
        if (product.has_variations && !product.variant_id) {
          flag = true;
        }
      });
      return flag;
    }
    return true;
  };

  const checkStep2NextButton = () => {
    const billingMandatoryKey = [
      'first_name',
      'address_one',
    ];
    const shippingMandatoryKey = ['first_name', 'address_one'];

    let flag = false;

    billingMandatoryKey.forEach((elem) => {
      if (
        !(
          customerData &&
          customerData?.billing_address &&
          customerData?.billing_address[elem]
        )
      ) {
        flag = true;
      }
    });
    if (!flag) {
      if (!isSameShippingAddress) {
        shippingMandatoryKey.forEach((elem) => {
          if (
            !(
              customerData &&
              customerData?.shipping_address &&
              customerData?.shipping_address[elem]
            )
          ) {
            flag = true;
          }
        });
      }
    }
    if (!flag && !paymentMethod) {
      flag = true;
    }
    if (!flag && !shippingMethod) {
      flag = true;
    }
    return flag;
  };

  const shouldDisableNextButton = () => {
    if (step === 1) {
      return checkStep1NextButton();
    } else if (step === 2) {
      return checkStep2NextButton();
    } else if (step === 3) {
      return false;
    } else if (step === 4) {
      return false;
    }
    return true;
  };

  const handleCloseModal = () => {
    if (customerData?.alice_cart.length > 0) {
      setCreateOrderOpen(false);
      updateMinimizeModal();
      setShowCloseWarningModal(true);
    } else {
      setCreateOrderOpen(false);
    }
  };
  const handleMinimizeModal = () => {
    setCreateOrderOpen(false);
    updateMinimizeModal();
  };

  return (
    <div>
      <OrderModal
        open={createOrderOpen}
        ref={containerRef}
        hasMinimizeButton={true}
        handleClose={handleCloseModal}
        handleMinimize={handleMinimizeModal}
        closeOnExternalClick={false}
        disableConfirm={shouldDisableNextButton() ? true : createOrderLoading}
        title={getOrderHeaderInfo(step).title}
        description={getOrderHeaderInfo(step).description}
        confirmText={
          step === 3
            ? 'Complete Order'
            : step === 4
            ? createdOrderState
            : 'Next'
        }
        cancelText={'Back'}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        hideCancel={
          step === 1 ||
          (step === 4 &&
            (createdOrderState === 'Loading' || createdOrderState === 'Exit'))
        }
      >
        <Wrapper
          step={step}
          customerId={customerId}
          confirmOrderAPI={async () => {
            let res = await createOrder(teamId, customerId, ticketId);
            if (res.success) {
              updateCart(customerId, []);
              clearState();
              setCreatedOrderState('Exit');
            } else {
              setCreatedOrderState('Try Again');
            }
            return res;
          }}
        />
      </OrderModal>
      {/*need to create component for alert modal! i dont have time rn to create with this feature please create later! #thingstoImprove - sazib */}
      <Modal
        isShown={showCloseWarningModal}
        setShown={setShowCloseWarningModal}
        intent={'warning'}
        title={'Discard Order Creation'}
        description={
          'You have requested to discard the order creation process. The selected items in the cart will be discarded, and you will have to start over again if you want to create the order for the customer. Are you sure you want to proceed?'
        }
        deleteTitle='Cancel Order'
        cancelTitle='Cancel'
        hasConfirmText={false}
        onCancelCallback={() => {
          setCreateOrderOpen(true);
        }}
        onDeleteCallback={() => {
          clearState();
          setStep(1);
          updateCart(customerId, []);
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  paymentMethod: state.crmEcommerce.paymentMethod,
  shippingMethod: state.crmEcommerce.shippingMethod,
  customerData: state.crmEcommerce.customerData,
  createOrderLoading: state.loading.effects.crmEcommerce.createOrder,
  teamId: state.dashboard.selectedProject.id,
});

const mapDispatch = (dispatch: any) => ({
  clearState: () => dispatch.crmEcommerce.clearState(),
  createOrder: (teamId: number, customerId: number, ticketId: number) =>
    dispatch.crmEcommerce.createOrder({ teamId, customerId, ticketId }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
  updateMinimizeModal: () => dispatch.crmEcommerce.updateMinimizeModal(),
});

const EcomOrderCreateContainer = connect(
  mapState,
  mapDispatch
)(EcomOrderCreate);
export default EcomOrderCreateContainer;
