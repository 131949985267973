import React from 'react';
import { Alert, Link } from '../../../../../library/index';

interface Prop {
  platformSettingsLink: string;
  facebookTestUrl: string;
  userId: string;
  selectedPlatformId: string | number;
}

const PlatformConnectAlert: React.FC<Prop> = ({
  platformSettingsLink,
  facebookTestUrl,
  userId,
  selectedPlatformId,
}) => {
  return (
    <Alert
      intent='warning'
      title='Platform is not connected yet'
      className='mb-4'
    >
      You do not have any platform connected. Please click{' '}
      <Link showUnderline={true} url={platformSettingsLink}>
        this link
      </Link>{' '}
      to connect the platform. And click{' '}
      <Link
        showUnderline={true}
        url={`${facebookTestUrl}?ref=${userId}_${selectedPlatformId}`}
        openInNewTab={true}
      >
        this link
      </Link>{' '}
      to test the bot.
    </Alert>
  );
};

export default PlatformConnectAlert;
