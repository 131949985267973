import { startCase } from 'lodash';
import { CheckIcon } from 'lucide-react';
import {
  ZidIcon,
  SallaImg,
  ShopifyImg,
  WoocommerceImg,
  UtilityContents,
  ChevronRightIcon,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import {
  TriggerTagTypes,
  EcommerceStore,
} from 'pages/automationWorkflow/interface';
import { useEffect, useState } from 'react';

interface Props {
  selectedEcommerceType: string;
  setSelectedEcommerceType: (ecommerceType: string) => void;
}

const TriggersTabView: React.FC<Props> = ({
  selectedEcommerceType,
  setSelectedEcommerceType,
}) => {
  const {
    selectedEcommerce,
    updateSelectedEcommerce,
    integratedEcommerceList,
    getDistinctIntegratedEcommerce,
  } = useAutomationWorkflow();

  const [ecomList, setEcomList] = useState<EcommerceStore[]>([]);

  useEffect(() => {
    const filtered: EcommerceStore[] = integratedEcommerceList.filter(
      (ecom: EcommerceStore) => ecom?.ecommerce_type === selectedEcommerceType
    );
    if (filtered.length === 1) {
      updateSelectedEcommerce(filtered[0]);
    }
    setEcomList(filtered);
    //eslint-disable-next-line
  }, [selectedEcommerceType]);

  const renderTriggerImg = (triggerCode: string, size = 'default') => {
    switch (triggerCode) {
      case 'shopify':
        return (
          <img
            src={ShopifyImg}
            className={size === 'sm' ? 'w-5 h-5' : ''}
            alt='shopify-icon'
          />
        );
      case 'woocommerce':
        return (
          <img
            src={WoocommerceImg}
            className={size === 'sm' ? 'w-5 h-5' : ''}
            alt='woocommerce-icon'
          />
        );
      case 'salla':
        return (
          <img
            src={SallaImg}
            className={size === 'sm' ? 'w-5 h-5' : ''}
            alt='salla-icon'
          />
        );
      case 'zid':
        return (
          <img
            src={ZidIcon}
            className={size === 'sm' ? 'w-5 h-5' : ''}
            alt='zid-icon'
          />
        );
      default:
        return null;
    }
  };

  const getFilteredTriggertabs = () => {
    const listOfEcommerceType = getDistinctIntegratedEcommerce();
    const filteredData = UtilityContents.TriggerTabData.filter(
      (tab: TriggerTagTypes) => listOfEcommerceType.includes(tab?.code)
    );
    if (!!filteredData && filteredData?.length === 1)
      setSelectedEcommerceType(filteredData[0]?.code);
    return filteredData;
  };

  const renderEcommerceTypeList = () => {
    return (
      <>
        {getFilteredTriggertabs().map(
          (trigger: TriggerTagTypes, index: number) => {
            const combinedClasses = `flex justify-between items-center p-4 rounded-md cursor-pointer hover:bg-gray-100`;
            return (
              <div className='border-b p-2 ' key={index}>
                <div
                  className={combinedClasses}
                  onClick={() => {
                    setSelectedEcommerceType(trigger?.code);
                  }}
                >
                  <div className='flex items-center'>
                    {renderTriggerImg(trigger.code)}
                    <h3 className='ml-3 text-gray-700 font-medium text-base'>
                      {trigger?.title}
                    </h3>
                  </div>
                  <div>
                    <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                  </div>
                </div>
              </div>
            );
          }
        )}
      </>
    );
  };

  const renderEcommerceList = () => {
    return (
      <>
        {ecomList.map((trigger: EcommerceStore, index: number) => {
          const combinedClasses = `flex justify-between items-center truncate p-2 rounded-md cursor-pointer hover:bg-gray-100 `;
          return (
            <div className='border-b p-2 ' key={index}>
              <div
                className={combinedClasses}
                onClick={() => {
                  updateSelectedEcommerce(trigger);
                }}
              >
                <div className='flex items-center'>
                  {!!selectedEcommerce &&
                    selectedEcommerce?.alice_store_id ===
                      trigger?.alice_store_id && (
                      <CheckIcon className='w-4 h-4 inline-block mr-2' />
                    )}{' '}
                  {renderTriggerImg(trigger?.ecommerce_type, 'sm')}
                  <h3 className='ml-3 text-gray-700 font-medium text-base'>
                    {!!trigger?.store_name
                      ? startCase(trigger?.store_name)
                      : startCase(trigger?.ecommerce_type)}
                  </h3>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {!!selectedEcommerceType
        ? renderEcommerceList()
        : renderEcommerceTypeList()}
    </>
  );
};

export default TriggersTabView;
