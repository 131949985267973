import SwitchWrapper from 'old/pages/settings/component/notification/SwitchWrapper';
import React, { ReactElement } from 'react';

interface SingleItemParentProps {
  status: boolean;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  children?: ReactElement;
}

const SingleItemParent: React.FC<SingleItemParentProps> = ({
  title,
  description,
  status,
  disabled = false,
  onChange,
  children,
}) => {
  return (
    <div className='w-full p-4 rounded-lg border border-gray-300'>
      <div className='flex items-center w-full'>
        <div className='flex items-center flex-1 min-w-0'>
          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
            <div>
              <p className='text-base font-medium text-gray-700 truncate'>
                {title}
              </p>
              <p className='flex items-center mt-1 text-sm text-gray-500'>
                {description}
              </p>
            </div>
          </div>
        </div>
        <div>
          <SwitchWrapper
            disabled={disabled}
            checked={status}
            onChange={onChange}
            hasBellIcon={false}
          />
        </div>
      </div>
      {!!status && (
        <div className='px-4 mt-4 rounded-lg shadow border border-gray-100'>
          {children}
        </div>
      )}
    </div>
  );
};

export default SingleItemParent;
