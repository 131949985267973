import { SelectedTeamInterface } from 'index';
import {
  IEcommerceChannelPreferencesProperty,
  IEcommerceChannelProperty,
} from 'pages/integration/interface';
import { integrationDataCheckerType } from 'pages/integration/screens/Integrated';
import { FC, useCallback, useState } from 'react';
import EcommerceCard from '../EcommerceCard';
import { ChannelPreferencesModal } from './ChannelPreferencesModal';
import { EditEcommerceModal } from './EditEcommerceModal';

interface Props {
  integratedEcommerceData: IEcommerceChannelProperty[];
  selectedProject: SelectedTeamInterface;
  fetchEcommerceChannelPreferences: (payload: any) => boolean;
  ecommerceChannelPreferencesList: IEcommerceChannelPreferencesProperty[];
  connectEcommercePlatform: (payload: any) => boolean;
  disconnectEcommercePlatform: (payload: any) => boolean;
}

export const MultiEcommerce: FC<Props> = ({
  integratedEcommerceData,
  selectedProject,
  fetchEcommerceChannelPreferences,
  ecommerceChannelPreferencesList,
  connectEcommercePlatform,
  disconnectEcommercePlatform,
}) => {
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [
    hasEcommerceChannelPreferencesData,
    setHasEcommerceChannelPreferencesData,
  ] = useState<integrationDataCheckerType>(null);

  const fetchChannelPreferences = useCallback(
    async (alice_store_id: number) => {
      setIsLoading(true);
      try {
        const preferences = await fetchEcommerceChannelPreferences({
          teamId: selectedProject.id,
          alice_store_id,
        });
        setHasEcommerceChannelPreferencesData(!!preferences);
      } catch (error) {
        console.error('Error fetching channel preferences:', error);
        setHasEcommerceChannelPreferencesData(false);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedProject.id, fetchEcommerceChannelPreferences]
  );

  const handleActionClick = useCallback(
    (action: string, alice_store_id: number) => {
      setSelectedAction(action);
      setSelectedStoreId(alice_store_id);

      if (action === 'preferences') {
        fetchChannelPreferences(alice_store_id);
      }
    },
    [fetchChannelPreferences]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedAction(null);
    setSelectedStoreId(null);
  }, []);

  return (
    <>
      {integratedEcommerceData.map((ecommerce) => (
        <EcommerceCard
          key={ecommerce.id}
          ecommerceData={ecommerce}
          onActionClick={(action) =>
            handleActionClick(action, ecommerce.alice_store_id)
          }
        />
      ))}

      <EditEcommerceModal
        key='edit'
        open={!!(selectedStoreId && selectedAction === 'edit')}
        handleClose={handleCloseModal}
        ecommerceData={
          integratedEcommerceData.find(
            (e) => e.alice_store_id === selectedStoreId
          ) || ({} as IEcommerceChannelProperty)
        }
      />

      {/* Comment it for now */}
      {/* <CredentialsInfoModal
        key='credentials'
        open={!!(selectedStoreId && selectedAction === 'credentials')}
        handleClose={handleCloseModal}
        selectedProject={selectedProject}
      /> */}

      <ChannelPreferencesModal
        key='preferences'
        teamId={selectedProject.id}
        open={!!(selectedStoreId && selectedAction === 'preferences')}
        handleClose={handleCloseModal}
        channelPreferencesData={
          hasEcommerceChannelPreferencesData || !isLoading
            ? ecommerceChannelPreferencesList
            : []
        }
        isLoading={isLoading}
        alice_store_id={selectedStoreId!}
        onConnect={connectEcommercePlatform}
        onDisconnect={disconnectEcommercePlatform}
      />
    </>
  );
};
