import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';
import React from 'react';
import { classNames } from 'utilities/utils';

interface AlertModalProps {
  isShown: boolean;
  title?: string;
  description?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  intent?: 'primary' | 'danger' | 'warning';
  onConfirm: () => void;
  onCancelComplete?: () => void;
  onToggle: () => void;
  onClose?: () => void;
  onAddMember?: () => void;
  deleteUserFromTeam?: (selectedProjectId: number, userId: number) => boolean;
  fetchProjectAccess?: (selectedProjectId: number) => void;
  clearState?: () => void;
  selectedProjectId?: number;
  userId?: number | null;
  deletedUserName: string;
  setDeletedUserName: (payload: string) => void;
  initialFucntionality: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  isShown = false,
  description = ' Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.',
  title = 'Delete',
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  intent = 'primary',
  onToggle,
  onClose,
  onConfirm,
  onCancelComplete,
  onAddMember,
  deleteUserFromTeam,
  fetchProjectAccess,
  clearState,
  selectedProjectId,
  userId,
  deletedUserName,
  setDeletedUserName,
  initialFucntionality,
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = React.useRef(null);
  const [step, setStep] = React.useState(1);

  const initiateDelete = async () => {
    if (
      userId &&
      deleteUserFromTeam &&
      fetchProjectAccess &&
      selectedProjectId &&
      clearState
    ) {
      const res = await deleteUserFromTeam(selectedProjectId, userId);
      if (res) {
        setStep(2);
        setDeletedUserName('');
        initialFucntionality();
        await fetchProjectAccess(selectedProjectId);
      } else {
        toaster.danger(t('Somthing went wrong during deleting member!'), {
          duration: 3,
        });
      }

      // clearState();
    }
  };

  const getElement = () => {
    switch (step) {
      case 1:
        return (
          <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='p-5 inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='flex flex-col justify-center'>
                    <div className='w-full flex justify-center'>
                      <ExclamationTriangleIcon
                        className='w-8 h-8 mx-auto bg-red-100 p-2 rounded-[50%] text-red-500'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='w-full flex flex-col justify-center'>
                      <p className='text-base text-gray-500 font-semibold text-center'>
                        {t('Remove member')}
                      </p>
                      <p className='text-sm text-gray-500 text-center'>
                        {t(
                          `you have requested to remove a user from your team. do you wish to continue?`
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='px-4 py-3 flex flex-col justify-center items-center'>
                  <button
                    type='button'
                    className={classNames(
                      'w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm mb-2',
                      intent === 'primary' &&
                        'bg-primary focus:ring-primary-hover hover:bg-primary-hover ',
                      intent === 'danger' &&
                        'bg-red-600 focus:ring-red-500 hover:bg-red-700 ',
                      intent === 'warning' &&
                        'bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700 '
                    )}
                    onClick={() => initiateDelete()}
                  >
                    {t(confirmButtonTitle)}
                  </button>
                  <button
                    type='button'
                    className='w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:text-sm'
                    onClick={() => {
                      onToggle();
                      if (onCancelComplete && clearState) {
                        onCancelComplete();
                        clearState();
                      }
                    }}
                    ref={cancelButtonRef}
                  >
                    {t(cancelButtonTitle)}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        );
      case 2:
        return (
          <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='p-5 inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <div className='flex flex-col justify-center'>
                    <div className='w-full flex justify-center'>
                      <CheckIcon
                        className='w-8 h-8 mx-auto bg-green-100 p-2 rounded-[50%] text-green-500 mb-3'
                        aria-hidden='true'
                      />
                    </div>
                    <div className=' flex flex-col justify-center w-full'>
                      <p className='text-base text-gray-500 font-semibold text-center'>
                        {t('Removed member successfully')}
                      </p>
                      <p className='text-sm text-gray-500 text-center'>
                        {t(
                          `Your request to remove member has been successful. You can either add someone new or update your subscription plan accordingly.`
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='px-4 py-3 flex flex-col justify-center items-center'>
                  <button
                    type='button'
                    className={classNames(
                      'w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm mb-2 bg-primary focus:ring-primary-hover hover:bg-primary-hover '
                    )}
                    onClick={() => {
                      onAddMember && onAddMember();
                      onToggle();
                      setStep(1);
                      if (onCancelComplete && clearState) {
                        onCancelComplete();
                        clearState();
                      }
                    }}
                  >
                    {t('Add New Member')}
                  </button>
                  <button
                    type='button'
                    className='w-full px-4 py-2 mb-1 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:text-sm'
                    onClick={() => {
                      navigate('/settings/billing');
                    }}
                  >
                    {t('Update Subscription Plan')}
                  </button>
                  <button
                    type='button'
                    className='w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:text-sm'
                    onClick={() => {
                      onToggle();
                      setStep(1);
                      if (onCancelComplete && clearState) {
                        onCancelComplete();
                        clearState();
                      }
                    }}
                  >
                    {t('Done')}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Transition.Root show={isShown} as={React.Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-20 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={() => {
          onToggle();
          onClose && onClose();
          setStep(1);
          if (onCancelComplete && clearState) {
            onCancelComplete();
            clearState();
          }
        }}
      >
        {getElement()}
      </Dialog>
    </Transition.Root>
  );
};

export default AlertModal;
