import React, { useState } from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';

import liveChatStep1Image from '../../assets/images/live-chat-step-1.svg';
import liveChatStep2Image from '../../assets/images/live-chat-step-2.svg';
import liveChatStep3Image from '../../assets/images/live-chat-step-3.svg';
import liveChatStep4Image from '../../assets/images/live-chat-step-4.svg';
import liveChatStep5Image from '../../assets/images/live-chat-trophy.gif';
import LiveChatModalSteps from './LiveChatModalSteps';

const modalStepProperties = [
  {
    svgImage: liveChatStep1Image,
    title: 'MyAlice, now with better Livechat',
    description:
      'Introducing our New Live Chat, a better way to communicate with consumers through a fully revamped livechat experience. Switch from Webchat now to have early access to all new features.',
  },
  {
    svgImage: liveChatStep2Image,
    title: 'Headover to Integration',
    description:
      'Your integrated webchats will have a “Migrate” button beside their title. Click on them to start the migration process.',
  },
  {
    svgImage: liveChatStep3Image,
    title: 'Customize Livechat',
    description:
      "Once your Webchat has been migrated, you'll be taken to the customization modal. You can customize your livechat experience the way you want!",
  },
  {
    svgImage: liveChatStep4Image,
    title: 'Implement Your New Livechat!',
    description:
      "Headover to installation tab in customization modal and copy and paste the code snippet just before the end of the </footer> section of your website's backend. You can also email the script to your developer for implementation.",
  },
  {
    svgImage: liveChatStep5Image,
    title: 'That is it!',
    description: (
      <span>
        Make sure that the widget is installed and showing on your site,
        otherwise if you may run into issues with your widget behavior, please
        <a className='text-blue-500' href='https://www.myalice.ai/support'>
          &nbsp;contact support&nbsp;
        </a>
        team to assist you.
      </span>
    ),
  },
];

const renderModalStep = (
  step: number,
  setStep: (number: number) => void,
  updateSelectedNav: (navID: number) => void,
  setShowLiveChatModal: (condition: boolean) => void
) => {
  switch (step) {
    case 1:
      return (
        <LiveChatModalSteps
          title={modalStepProperties[step - 1].title}
          description={modalStepProperties[step - 1].description}
          image={modalStepProperties[step - 1].svgImage}
        >
          <div className='flex self-end'>
            <button
              className='capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Show Me How
            </button>
          </div>
        </LiveChatModalSteps>
      );
    case 2:
      return (
        <LiveChatModalSteps
          title={modalStepProperties[step - 1].title}
          description={modalStepProperties[step - 1].description}
          image={modalStepProperties[step - 1].svgImage}
        >
          <div className='flex self-end gap-3'>
            <button
              className='capitalize py-1 px-3 border border-gray-200 rounded-md h-8'
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </button>

            <button
              className='capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Next
            </button>
          </div>
        </LiveChatModalSteps>
      );
    case 3:
      return (
        <LiveChatModalSteps
          title={modalStepProperties[step - 1].title}
          description={modalStepProperties[step - 1].description}
          image={modalStepProperties[step - 1].svgImage}
        >
          <div className='flex self-end gap-3'>
            <button
              className='capitalize py-1 px-3 border border-gray-200 rounded-md h-8'
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </button>

            <button
              className='capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Next
            </button>
          </div>
        </LiveChatModalSteps>
      );
    case 4:
      return (
        <LiveChatModalSteps
          title={modalStepProperties[step - 1].title}
          description={modalStepProperties[step - 1].description}
          image={modalStepProperties[step - 1].svgImage}
        >
          <div className='flex self-end gap-3'>
            <button
              className='capitalize py-1 px-3 border border-gray-200 rounded-md h-8'
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </button>

            <button
              className='capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Next
            </button>
          </div>
        </LiveChatModalSteps>
      );
    default:
      return (
        <LiveChatModalSteps
          title={modalStepProperties[step - 1].title}
          description={modalStepProperties[step - 1].description}
          image={modalStepProperties[step - 1].svgImage}
        >
          <div className='flex self-end gap-3'>
            <button
              className='capitalize py-1 px-3 border border-gray-200 rounded-md h-8'
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </button>

            <div
              className='flex self-end capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
            >
              <Link
                onClick={() => {
                  updateSelectedNav(2);
                  if (window.location.pathname === '/integrations/integrated') {
                    setShowLiveChatModal(false);
                  }
                }}
                to='/integrations/integrated'
              >
                Headover to Integration
              </Link>
            </div>
          </div>

          {/* <div className='flex self-end'>
            <button
              className='capitalize py-1 px-3 border border-green-500 bg-green-500
                text-white rounded-md h-8'
              onClick={() => {
                window.location.href = '/integrations/integrated';
              }}
            >
              That is it!
            </button>
          </div> */}
        </LiveChatModalSteps>
      );
  }
};

interface props {
  setShowLiveChatModal: (condition: boolean) => void;
  updateSelectedNav: (navID: number) => void;
}

const LiveChatModal: React.FC<props> = ({
  setShowLiveChatModal,
  updateSelectedNav,
}) => {
  const [step, setStep] = useState(1);

  return (
    <div
      className='fixed z-[999] inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        ></span>
        <div
          className='relative inline-block align-bottom bg-white rounded-lg text-left
                    shadow-xl transform transition-all sm:align-middle'
        >
          <div className='bg-white w-[550px] relative rounded-xl'>
            <div
              onClick={() => {
                setShowLiveChatModal(false);
              }}
              className='absolute flex justify-center items-center cursor-pointer right-[12px] top-[12px] bg-[#F3F4F6] rounded-[100%] h-[24px] w-[24px]'
            >
              <span className='text-[#6B7280] text-xs'>&#x2715;</span>
            </div>

            {renderModalStep(
              step,
              setStep,
              updateSelectedNav,
              setShowLiveChatModal
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = <T extends { dashboard: any }>(state: T) => ({
  navActiveId: state.dashboard.navActiveId,
});

const mapDispatch = (dispatch: any) => ({
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
});

export default connect(mapState, mapDispatch)(LiveChatModal);
