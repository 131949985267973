import React from 'react';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  status: string;
}

const PaymentStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const renderPaymentStatus = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'unpaid':
        return (
          <p
            data-testid='payment-status'
            className='px-2 py-1 text-red-800 bg-red-200 font-medium capitalize rounded-md'
          >
            {t('unpaid')}
          </p>
        );
      case 'paid':
        return (
          <p
            data-testid='payment-status'
            className='px-2 py-1 text-[#065F46] bg-[#D1FAE5] font-medium capitalize rounded-md'
          >
            {t('paid')}
          </p>
        );
      case 'pending':
        return (
          <p
            data-testid='payment-status'
            className='px-2 py-1 text-yellow-800 bg-[#FEF3C7] font-medium capitalize rounded-md'
          >
            {t('pending')}
          </p>
        );
      case 'refunded':
        return (
          <p
            data-testid='payment-status'
            className='px-2 py-1 text-gray-800 bg-gray-200 font-medium capitalize rounded-md'
          >
            {t('refunded')}
          </p>
        );
      case 'voided':
        return (
          <p
            data-testid='payment-status'
            className='px-2 py-1 text-red-800 bg-red-200 font-medium capitalize rounded-md'
          >
            {t('voided')}
          </p>
        );
      default:
        return null;
    }
  };
  return <div>{renderPaymentStatus(status)}</div>;
};

export default PaymentStatus;
