import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from 'evergreen-ui';

const PopupFormContent = ({
  items,
  inputVal,
  handleChange,
  sequences,
  sequenceVal,
  handleSequenceChange,
}) => {
  return (
    <Fragment>
      <h5>Select a form</h5>
      <Combobox
        openOnFocus
        width='100%'
        initialSelectedItem={items.filter((itm) => itm.id === inputVal)[0]}
        items={items}
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleChange(selected.id)}
        placeholder='Form'
      />
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>This form will open if users click on the button</span>
      </p>

      <br />

      <h5>Trigger Sequence on Submit</h5>
      <Combobox
        openOnFocus
        width='100%'
        initialSelectedItem={
          sequences.filter((itm) => itm.id === sequenceVal)[0]
        }
        items={sequences}
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleSequenceChange(selected.id)}
        placeholder='Select a sequence'
      />
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>
          The selected sequence will trigger if users submit the form.This field
          is optional.
        </span>
      </p>
    </Fragment>
  );
};

PopupFormContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PopupFormContent;
