import { FC } from 'react';
import {
  Command,
  Checkbox,
  usePolicy,
  CommandItem,
  CommandList,
  CommandGroup,
  DropdownField,
  LOCAL_UTILS,
  TicketPriorityLabel,
} from '../../export';

import { IPolicy } from '../../interface';

interface Props {
  priorityList: Array<string | number>;
  handlePriorityListUpdate: (updatedList: Array<number | string>) => void;
}

const PrioritySelection: FC<Props> = ({
  priorityList,
  handlePriorityListUpdate,
}) => {
  const { inputFieldClass, defaultPolicies, getPriorityLabel } = usePolicy();

  const PriorityItems = () => (
    <Command>
      <CommandList asChild>
        <CommandGroup>
          {defaultPolicies.map((policy: IPolicy, index) => (
            <CommandItem
              asChild
              key={index}
              value={`${getPriorityLabel(policy.priority)}`}
            >
              <div className={'flex items-center space-x-2'}>
                <Checkbox
                  id={`${policy.priority}`}
                  checked={priorityList.includes(policy?.priority)}
                  className={
                    priorityList.includes(policy?.priority)
                      ? 'bg-primary text-white border-transparent'
                      : ''
                  }
                  onCheckedChange={() => {
                    let localPriorityList = [...priorityList];
                    const index = localPriorityList.indexOf(policy.priority);
                    if (index !== -1) {
                      localPriorityList.splice(index, 1);
                    } else {
                      localPriorityList.push(policy.priority);
                    }
                    handlePriorityListUpdate(localPriorityList);
                  }}
                />
                <div className='flex items-center w-[80%] space-x-2'>
                  <TicketPriorityLabel priority={policy.priority} htmlFor={`${policy.priority}`} labelTag={true} />
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return (
    <DropdownField
      value={LOCAL_UTILS.getPriorityLabelList(priorityList)}
      children={<PriorityItems />}
      childrenName='priority'
      popOverContentClass='w-52'
      buttonClass={`${inputFieldClass} w-52 mr-2`}
    />
  );
};

export default PrioritySelection;
