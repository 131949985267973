import React from 'react';
import { connect } from 'react-redux';
import { billingInfoType, cardInfoType } from '../../interface';
import UpdateBillingInfo from '../UpdateBillingInfo';
import UpdateCard from '../UpdateCard';
import useTranslation from 'components/customHooks/useTranslation';

interface paymentDetailsProps {
  billingInfo: billingInfoType;
  cardInfo: cardInfoType[];
  billingCycle: string;
  selectedProject: SelectedTeamInterface;
}

const PaymentDetails: React.FC<paymentDetailsProps> = ({
  billingInfo,
  cardInfo,
  billingCycle,
  selectedProject,
}) => {
  const { t } = useTranslation();
  const [openBillingUpdateModal, setBillingUpdateModal] = React.useState(false);
  const [card, setCard] = React.useState<cardInfoType | null>(
    cardInfo.length > 0 ? cardInfo.filter((item) => item.default)[0] : null
  );
  const [openUpdateCard, setOpenUpdateCard] = React.useState(false);

  React.useEffect(() => {
    setCard(
      cardInfo.length > 0 ? cardInfo.filter((item) => item.default)[0] : null
    );
  }, [cardInfo]);

  return (
    <>
      <p className='text-lg text-gray-900 font-medium mb-4'>
        {t('Payment Details')}
      </p>
      <div className='bg-white rounded-md border border-gray-200 mb-8 p-5'>
        <div className='flex mb-3 p-1'>
          <p className='w-1/3 text-sm text-gray-600 font-normal'>
            {t('Payment Method')}
          </p>
          <div className='w-2/3 flex justify-end'>
            <div>
              {card && (
                <>
                  <p className='text-sm text-gray-600 text-right'>
                    {card.brand} Ending with {card.last4}
                  </p>
                </>
              )}

              <div className='flex flex-end'>
                {card ? (
                  <button
                    className='text-sm text-[#0078CF] font-medium'
                    onClick={() => setOpenUpdateCard(true)}
                  >
                    {t('Update')}
                  </button>
                ) : (
                  <button
                    className='text-sm text-[#0078CF] font-medium'
                    onClick={() => setOpenUpdateCard(true)}
                  >
                    {t('Add')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex border-t border-gray-100 pt-4 pb-1 px-1'>
          <p className='w-1/3 text-sm text-gray-600 font-normal'>
            {t('Billing Information')}
          </p>
          <div className='w-2/3 flex justify-end'>
            <div className=''>
              {billingInfo && (
                <>
                  <p className='text-sm text-gray-600 text-right'>
                    {billingInfo.full_name}
                  </p>
                  <p className='text-sm text-gray-600 text-right'>
                    {billingInfo.email}
                  </p>
                  <p className='text-sm text-gray-600 text-right'>
                    {billingInfo.billing_address}
                  </p>
                </>
              )}

              <div className='flex flex-end'>
                {billingInfo ? (
                  <button
                    className='text-sm text-[#0078CF] font-medium'
                    onClick={() => setBillingUpdateModal(true)}
                  >
                    {t('Update')}
                  </button>
                ) : (
                  <button
                    className='text-sm text-[#0078CF] font-medium'
                    onClick={() => setBillingUpdateModal(true)}
                  >
                    {t('Add')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openBillingUpdateModal && (
        <UpdateBillingInfo setBillingUpdateModal={setBillingUpdateModal} />
      )}
      {openUpdateCard && (
        <UpdateCard
          cardInfo={cardInfo}
          setOpenUpdateCard={setOpenUpdateCard}
          projectId={selectedProject.id}
        />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PaymentDetails);
