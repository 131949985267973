import React from 'react';
import Modal from './Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  setOpenDeleteModal: (payload: boolean) => void;
  deleteAllTag: (type: string) => void;
  type: string;
  setSingleTag: (payload: number[]) => void;
  selectedTag: number[];
}

const DeleteModal: React.FC<Props> = ({
  isOpen,
  setOpenDeleteModal,
  deleteAllTag,
  type,
  setSingleTag,
  selectedTag,
}) => {
  const { t } = useTranslation();
  const deleteType = (type: string) => {
    switch (type) {
      case 'singleDelete':
        return (
          <div className='p-[24px] flex flex-col items-center'>
            <div className='mb-[20px]'>
              <ExclamationTriangleIcon className='bg-red-100 text-red-500 w-10 h-10 p-2 rounded-full' />
            </div>
            <p className='mb-[8px] text-gray-900 font-medium capitalize text-[18px]'>
              {t('Delete Tag?')}
            </p>
            <p className='text-gray-500 font-[400] mb-[24px] text-center text-[14px]'>
              {t(
                'You are about to delete a tag for everyone. All the tagged tickets will be untagged. This action cannot be undone.'
              )}
            </p>
            <button
              className='w-full bg-[#DC2626] border border-[#DC2626] rounded-[6px] text-[14px]
        text-white font-[500] mb-[12px] p-2 cursor-pointer'
              onClick={() => deleteAllTag('singleDelete')}
            >
              {t('Delete')}
            </button>
            <button
              className='w-full bg-white border border-[#D1D5DB] text-300
        rounded-[6px] text-[14px] text-gray-700 font-[500] mb-[12px] p-2
        cursor-pointer'
              onClick={() => {
                setOpenDeleteModal(false);
                setSingleTag([]);
              }}
            >
              {t('Cancel')}
            </button>
          </div>
        );
      case 'multipleDelete':
        return (
          <div className='p-[24px] flex flex-col items-center'>
            <div className='mb-[20px]'>
              <ExclamationTriangleIcon className='bg-red-100 text-red-500 w-10 h-10 p-2 rounded-full' />
            </div>
            <p className='mb-[8px] text-gray-900 font-medium capitalize text-[18px]'>
              {t('Delete multiple Tags?')}
            </p>
            <p className='text-gray-500 font-[400] mb-[24px] text-center text-[14px]'>
              {t(
                `You are about to delete {{${selectedTag.length}}} tags for everyone. All the tagged tickets will be untagged. This action cannot be undone.`
              )}
            </p>
            <button
              className='w-full bg-[#DC2626] border border-[#DC2626] rounded-[6px] text-[14px]
        text-white font-[500] mb-[12px] p-2 cursor-pointer'
              onClick={() => deleteAllTag('multipleDelete')}
            >
              {t(`Delete ({{${selectedTag.length}}})`)}
            </button>
            <button
              className='w-full bg-white border border-[#D1D5DB] text-300
        rounded-[6px] text-[14px] text-gray-700 font-[500] mb-[12px] p-2
        cursor-pointer'
              onClick={() => setOpenDeleteModal(false)}
            >
              {t('Cancel')}
            </button>
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <Modal isOpen={isOpen} width={'w-[384px]'}>
      <>{deleteType(type)}</>
    </Modal>
  );
};

export default DeleteModal;
