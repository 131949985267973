import React from 'react';
import { Dialog } from 'evergreen-ui';
import checkCircleIcon from '../assets/checkCircle.svg';

interface Prop {
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: 'warning' | 'success' | 'info' | 'danger';
  title?: string | number;
  description: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onConfirmCallback?: () => void;
  onCancelCallback?: () => void;
  hasConfirmText?: boolean;
  confirmText?: 'CANCEL' | 'REMOVE' | 'REFUND';
}

const ReactivatePlanModal: React.FC<Prop> = ({
  isShown,
  setShown,
  intent,
  title,
  description,
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  onConfirmCallback,
  onCancelCallback,
  hasConfirmText = false,
  confirmText,
}) => {
  const handleConfirmAction = () => {
    if (hasConfirmText) {
      if (!!onConfirmCallback) {
        onConfirmCallback();
        setTimeout(() => setShown(false), 1000);
      }
    } else {
      if (!!onConfirmCallback) {
        onConfirmCallback();
        setTimeout(() => setShown(false), 1000);
      }
    }
  };
  return (
    <div className='flex p-4'>
      <Dialog
        width={344}
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        hasFooter={false}
        hasHeader={false}
        containerProps={{ className: 'rounded-lg' }}
        shouldCloseOnOverlayClick={false}
      >
        <div className='flex flex-col align-center text-center'>
          <img className='my-1' src={checkCircleIcon} alt={'modal icon'} />
          <p className='text-gray-900 text-lg leading-6 font-medium my-2'>{title}</p>
          <p className='text-gray-500 text-sm font-normal mb-5'>{description}</p>
        </div>
        <button
          className='text-base text-white font-medium bg-primary rounded-md w-full h-10 mb-3'
          onClick={() => handleConfirmAction()}
        >
          {confirmButtonTitle}
        </button>
        <button
          className='text-gray-600 text-base font-medium border border-gray-300 rounded-md w-full h-10'
          onClick={() => {
            if (!!onCancelCallback) {
              onCancelCallback();
            }
          }}
        >
          {cancelButtonTitle}
        </button>
      </Dialog>
    </div>
  );
};

export default ReactivatePlanModal;
