import React from 'react';
import BlockContainer from './BlockContainer';
import RemoveFromCartIcon from '../../../old/pages/builder/assets/images/icons/remove_from_cart_block_icon.svg';
import { BlocksData } from '../../../utilities/content';

interface Props {
  id: number;
  data: object;
  save: boolean;
  type: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const RemoveFromCart: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
}) => {
  return (
    <BlockContainer
      id={id}
      title={BlocksData.RemoveFromCartBlock.title}
      subtitle={BlocksData.RemoveFromCartBlock.subTitle}
      save={save}
      isLoading={saveLoader}
      icon={RemoveFromCartIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    />
  );
};

export default RemoveFromCart;
