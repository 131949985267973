import React from 'react';
import { classNames } from '../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  id: number | string;
  tab: tabProps;
  selectedPlatformCount: number;
  handleUpdate: () => void;
  currentTab: string;
}
interface tabProps {
  slug: string;
  name: string;
}

const IntegrationNavigationTabElements: React.FC<Prop> = ({
  id,
  tab,
  selectedPlatformCount,
  handleUpdate,
  currentTab,
}) => {
  const { t } = useTranslation();
  return (
    <div
      key={id}
      onClick={() => handleUpdate()}
      className={classNames(
        tab.slug === currentTab
          ? 'bg-none text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'py-4 relative cursor-pointer font-medium text-sm rounded-md'
      )}
      aria-current={tab.slug === currentTab ? 'page' : undefined}
    >
      <span>{t(tab.name)}</span>
      {/* <span
        className={classNames(
          tab.slug === currentTab ? 'bg-green-100 text-primary' : 'bg-gray-100 text-gray-800',
          'ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium'
        )}>
        {selectedPlatformCount}
      </span> */}
      <span
        aria-hidden='true'
        className={classNames(
          tab.slug === currentTab ? 'bg-primary' : 'bg-transparent',
          'absolute inset-x-0 bottom-0 h-0.5'
        )}
      />
    </div>
  );
};

export default IntegrationNavigationTabElements;
