import React from 'react';
import Button from '../../../../library/button';
import shopifyIcon from '../../assets/icons/shopifyIcon.svg';
import SallaIcon from './../../../integration/assets/icons/marketplace/salla.svg';
import zidIcon from '../../assets/icons/zidIcon.svg';

import woocommerceIcon from '../../assets/icons/woocommerceIcon.svg';

import { XCircleIcon } from '@heroicons/react/20/solid';
import { mixpanelTrack } from 'utilities/mixpanel';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  handleButtonOnClick: (selectedStore: string, step: string) => void;
}

const StorePicker: React.FC<Props> = ({ handleButtonOnClick }) => {
  const { t } = useTranslation();
  const handleOnButtonClickEvent = (type: string, step: string) => {
    if (type === '') {
      mixpanelTrack(`Clicked onboarding - no ecommerce store`);
    } else {
      mixpanelTrack(`Clicked onboarding - ${type.toUpperCase()}`);
    }
    handleButtonOnClick(type, step);
  };

  return (
    <div className='flex flex-col mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
      <Button
        isFullWidth={false}
        size='lg'
        onClick={() => handleOnButtonClickEvent('shopify', 'step1')}
        icon={<img src={shopifyIcon} alt='shopifyIcon' />}
        className='mb-3 text-[16px] text-gray-700 font-medium float-left'
      >
        {t('Shopify Store')}
      </Button>
      <Button
        isFullWidth={false}
        size='lg'
        onClick={() => handleOnButtonClickEvent('woocommerce', 'step1')}
        className='mb-3 text-[16px] text-gray-700 font-medium'
        icon={<img src={woocommerceIcon} alt='woocommerceIcon' />}
      >
        {t('WooCommerce Store')}
      </Button>
      <Button
        isFullWidth={false}
        size='lg'
        onClick={() => handleOnButtonClickEvent('salla', 'step1')}
        className='mb-3 text-[16px] text-gray-700 font-medium'
        icon={<img src={SallaIcon} alt='Salla-Icon' />}
      >
        {t('Salla Store')}
      </Button>
      <Button
        isFullWidth={false}
        size='lg'
        onClick={() => handleOnButtonClickEvent('zid', 'step1')}
        className='mb-3 text-[16px] text-gray-700 font-medium'
        icon={<img src={zidIcon} alt='Zid-Icon' />}
      >
        {t('Zid Store')}
      </Button>
      <Button
        isFullWidth={false}
        size='lg'
        onClick={() => handleOnButtonClickEvent('', 'step2')}
        className='mb-3 text-[16px] text-gray-700 font-medium'
        icon={<XCircleIcon className='text-red-500' />}
      >
        {t("I don't have an e-commerce store")}
      </Button>
    </div>
  );
};
export default StorePicker;
