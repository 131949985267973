import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import createPersistPlugin from '@rematch/persist';
import { ecommerce } from 'pages/inbox/models/ecommerce';
import settingsV2 from 'pages/settings/models';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import { api } from '../old/pages/api-fuse/models';
import { audience } from '../old/pages/audience/models';
import { block, builder } from '../old/pages/builder/models';
import { crm } from '../old/pages/crm/models';
import { crmEcommerce } from '../old/pages/crm/models/crmEcommerce';
import { labDetails } from '../old/pages/data-lab-details/models';
import { dataLab } from '../old/pages/data-lab/models';
import { rules } from '../old/pages/rules/models';
import { settings } from '../old/pages/settings/models';
import { billing } from '../old/pages/settings/models/billing';
import { businesshour } from '../old/pages/settings/models/businesshour';
import { channel } from '../old/pages/settings/models/channel';
import { holiday } from '../old/pages/settings/models/holiday';
import { marketplace } from '../old/pages/settings/models/marketplace';
import { auth } from '../pages/auth/models';
import { workflowAutomation } from '../pages/automationWorkflow/models/index';
import { newBilling } from '../pages/billing/models/index';
import { broadcast } from '../pages/broadcast/models/index';
import { dashboard } from '../pages/dashboardV2/models';
import { datalabFormBuilder } from '../pages/datalab/models';
import { datalabList } from '../pages/datalab/models/datalabList';
import { inboxDatalab } from '../pages/inbox/models/inboxDatalab';
import { inbox } from '../pages/inbox/models/index';
import { integration } from '../pages/integration/models/index';
import { onboardingProgress } from '../pages/onboarding/models';
import { onboarding } from '../pages/onboardingV2/models/index';
import { reporting } from '../pages/reporting/models';
import { settingsSLAConfiguration } from '../pages/settings/slaSettings/models';
import { teamManagement } from '../pages/settings/teamManagement/models/index';
import { settingsWebhook } from '../pages/settings/webhooks/models';

const encryptor = createEncryptor({
  secretKey: process.env.REACT_APP_SECRET_KEY,
  onError: function (error) {
    console.log(error);
  },
});

const loading = createLoadingPlugin({});

const persistPlugin = createPersistPlugin({
  key: 'alice',
  storage,
  version: 2,
  whitelist: [
    'auth',
    'onboardingProgress',
    'dashboard',
    'builder',
    'block',
    'api',
    'rules',
    'dataLab',
    'datalabList',
    'labDetails',
    'settings',
    'billing',
    'channel',
    'audience',
    'crm',
    'reporting',
    'marketplace',
    'crmEcommerce',
    'integration',
    'teamManagement',
    'inbox',
    'ecommerce',
    'newBilling',
    'onboarding',
    'businesshour',
    'holiday',
    'broadcast',
    'inboxDatalab',
    'workflowAutomation',
    'datalabFormBuilder',
    'settingsSLAConfiguration',
    'settingsWebhook',
    'settingsV2',
  ],
  throttle: 100,
  transforms: [encryptor],
});

const models = {
  auth,
  onboardingProgress,
  dashboard,
  builder,
  block,
  api,
  rules,
  dataLab,
  datalabList,
  labDetails,
  settings,
  billing,
  channel,
  audience,
  crm,
  crmEcommerce,
  reporting,
  marketplace,
  integration,
  teamManagement,
  inbox,
  ecommerce,
  newBilling,
  onboarding,
  businesshour,
  holiday,
  broadcast,
  inboxDatalab,
  workflowAutomation,
  datalabFormBuilder,
  settingsSLAConfiguration,
  settingsWebhook,
  settingsV2,
};

const store = init({
  models,
  plugins: [persistPlugin, loading],
});

export default store;
