import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  step: number;
  updateEcommerceStateData: (key: string, value: any) => void;
}

const FormHeader: React.FC<Props> = ({ step, updateEcommerceStateData }) => {
  const { t } = useTranslation();
  return (
    <div className='flex px-3 py-2 h-[6.5vh]'>
      <div className='flex w-2/3 gap-2 items-center'>
        <div className='text-base text-gray-700 font-semibold'>
          {t('Create Order')}
        </div>
        <div className='bg-gray-100 rounded-md px-1 text-gray-800 font-semibold'>
          {step}/3
        </div>
      </div>
      <div className='w-1/3 flex justify-end items-center'>
        <XMarkIcon
          className='text-gray-700 font-semibold w-5 h-5 cursor-pointer'
          onClick={() => {
            updateEcommerceStateData('showDiscardAlert', true);
          }}
        />
      </div>
    </div>
  );
};

export default FormHeader;
