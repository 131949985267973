import React from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import {
  CommentStatusPayloadInterface,
  ConversationParentCommentInterface,
  ConversationRepliesInterface,
} from 'pages/inbox/inboxInterface';
import defaultAvatar from '../../../../assets/images/anonymousAvatar.svg';
import botAvatar from '../../../../assets/images/bot-message-square.svg';
import ActionBar from '../../messageBlocks/ActionBar';
import MediaAttachment from '../../messageBlocks/MediaAttachment';
import NoteMessage from '../../messageBlocks/NoteMessage';
import TextMessage from '../../messageBlocks/TextMessage';
import Avatar from './Avatar';

interface Props {
  replies: ConversationRepliesInterface[];
  platformType: string;
  userId: number;
  adminAvatar: string | null;
  parent_comment: ConversationParentCommentInterface;

  updateFeedCommentStatus: (payload: CommentStatusPayloadInterface) => void;
}

const FeedComment: React.FC<Props> = ({
  userId,
  replies,
  adminAvatar,
  platformType,
  parent_comment,

  updateFeedCommentStatus,
}) => {
  const { t } = useTranslation();
  const getAvatar = (
    reply: ConversationRepliesInterface | ConversationParentCommentInterface
  ) => {
    switch (reply?.source) {
      case 'customer':
        return reply?.customer_info?.avatar || defaultAvatar;
      case 'bot':
        return botAvatar;
      case 'admin':
        return reply?.admin_info?.avatar || defaultAvatar;
      default:
        return defaultAvatar;
    }
  };

  const getConversationStatus = (
    comment: ConversationRepliesInterface | ConversationParentCommentInterface
  ) => {
    // TODO: to be removed after backend engineer run a script to update conversation_status for old conversations
    let { success, timestamp, conversation_status } = comment;
    let successStatus = !!success ? 'delivered' : 'failed';
    return timestamp > 1709442661252 ? conversation_status : successStatus;
  };

  const getDefaultMessageBlock = (
    reply: ConversationRepliesInterface | ConversationParentCommentInterface,
    isParentComment: boolean,
    isReplyComment: boolean
  ) => {
    let blockData = {
      type: 'text',
      success: false,
      error: 'Data Type not Supported',
      text: '',
      payload: '',
    };
    let conversationStatus = getConversationStatus(reply);
    return (
      <TextMessage
        blockData={blockData}
        avatar={getAvatar(reply)}
        name={getReplyName(reply)}
        time={reply?.timestamp}
        source={reply?.source}
        isSent={conversationStatus}
        isMergeable={false}
        enableDateBar={false}
        platformType={platformType}
        isReplyComment={isReplyComment}
        isParentComment={isParentComment}
      />
    );
  };

  const getReplyName = (
    reply: ConversationRepliesInterface | ConversationParentCommentInterface
  ): string => {
    switch (reply?.source) {
      case 'bot':
        return 'Chatbot';
      case 'admin':
        return reply?.admin_info?.id === userId
          ? 'You'
          : reply?.admin_info?.full_name || 'Anonymous User';
      case 'customer':
        return reply?.customer_info?.full_name || 'Anonymous User';
      default:
        return 'Anonymous User';
    }
  };

  const getParentComment = () => {
    const {
      type,
      status,
      dataV2,
      report,
      timestamp,
      is_highlighted,
      conversation_id,
    } = parent_comment;
    let conversationStatus = getConversationStatus(parent_comment);
    switch (dataV2?.type) {
      case 'text':
        return (
          <TextMessage
            blockData={dataV2}
            avatar={getAvatar(parent_comment)}
            name={getReplyName(parent_comment)}
            time={timestamp}
            source={'customer'}
            isSent={conversationStatus}
            isMergeable={false}
            enableDateBar={false}
            report={report}
            isParentComment={true}
            commentType={type}
            commentId={conversation_id}
            isHighlighted={is_highlighted}
            status={status}
            platformType={platformType}
            updateFeedCommentStatus={updateFeedCommentStatus}
          />
        );
      case 'attachment':
        switch (dataV2?.sub_type) {
          case 'image':
          case 'video':
            return (
              <MediaAttachment
                platformType={platformType}
                blockData={dataV2}
                avatar={getAvatar(parent_comment)}
                name={getReplyName(parent_comment)}
                time={timestamp}
                source={'customer'}
                isSent={conversationStatus}
                isMergeable={false}
                enableDateBar={false}
                report={report}
                isParentComment={true}
                commentType={type}
                commentId={conversation_id}
                isHighlighted={is_highlighted}
                status={status}
                updateFeedCommentStatus={updateFeedCommentStatus}
              />
            );
          default:
            return getDefaultMessageBlock(parent_comment, true, false);
        }
      default:
        return getDefaultMessageBlock(parent_comment, true, false);
    }
  };

  const getReplies = (reply: ConversationRepliesInterface) => {
    const {
      type,
      status,
      source,
      dataV2,
      report,
      timestamp,
      is_highlighted,
      conversation_id,
    } = reply;
    let conversationStatus = getConversationStatus(reply);
    switch (dataV2?.type) {
      case 'text':
        return (
          <TextMessage
            blockData={dataV2}
            avatar={getAvatar(reply)}
            name={getReplyName(reply)}
            time={timestamp}
            source={source}
            isSent={conversationStatus}
            isMergeable={false}
            enableDateBar={false}
            report={report}
            isReplyComment={true}
            commentType={type}
            commentId={conversation_id}
            isHighlighted={is_highlighted}
            status={status}
            platformType={platformType}
            updateFeedCommentStatus={updateFeedCommentStatus}
          />
        );
      case 'attachment':
        switch (dataV2?.sub_type) {
          case 'image':
          case 'video':
            return (
              <MediaAttachment
                platformType={platformType}
                blockData={dataV2}
                avatar={getAvatar(reply)}
                name={getReplyName(reply)}
                time={timestamp}
                source={source}
                isSent={conversationStatus}
                isMergeable={false}
                enableDateBar={false}
                report={report}
                isReplyComment={true}
                commentType={type}
                commentId={conversation_id}
                isHighlighted={is_highlighted}
                status={status}
                updateFeedCommentStatus={updateFeedCommentStatus}
              />
            );
          default:
            return getDefaultMessageBlock(reply, false, true);
        }
      case 'note':
        return (
          <NoteMessage
            blockData={dataV2}
            avatar={getAvatar(reply)}
            name={getReplyName(reply)}
            time={timestamp}
            source={source}
            enableDateBar={false}
            isReplyComment={true}
            platformType={platformType}
            isMergeable={false}
          />
        );
      case 'action':
        return (
          <ActionBar
            blockData={dataV2}
            time={timestamp}
            enableDateBar={false}
            isFeed={true}
          />
        );
      default:
        return getDefaultMessageBlock(reply, false, true);
    }
  };

  const parentCommentIsRemoved = (): boolean => {
    return !!parent_comment && parent_comment?.status === 'remove';
  };

  return (
    <div className='relative'>
      {getParentComment()}
      {!parentCommentIsRemoved() && !!replies && replies?.length !== 0 && (
        <div className='flex flex-col-reverse'>
          {replies.map((reply: ConversationRepliesInterface, index: number) => (
            <div key={index}> {getReplies(reply)} </div>
          ))}
        </div>
      )}
      {
        <div className='relative flex w-full h-auto ltr:pl-11 rtl:pr-11 py-1.5'>
          <Avatar
            avatar={adminAvatar || defaultAvatar}
            isReplyComment={false}
          />
          <span
            className='absolute top-1.5 ltr:left-[34px] rtl:right-[34px] ltr:border-l-2 rtl:border-r-2 border-b-2 h-7 w-[30px] ltr:rounded-bl-xl rtl:rounded-br-xl'
            aria-hidden='true'
          />
          <p className='w-auto mt-2.5 h-auto sm:text-sm text-gray-400 content-center'>
            {t('Your reply will go here.')}
          </p>
        </div>
      }
    </div>
  );
};

export default FeedComment;
