import { UserGroupIcon } from '@heroicons/react/20/solid';
import RandomlyGeneratedAvatar from 'assets/icons/component/RandomlyGeneratedAvatar';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface GroupCardProps {
  id: number | null;
  name: string;
  owner: string;
  image: string;
  memberCount: number;
  isSmart?: boolean;
  onCardClick?: (id: number) => void;
}

const GroupCard: React.FC<GroupCardProps> = ({
  id,
  name,
  owner,
  image,
  memberCount,
  isSmart = false,
  onCardClick,
}) => {
  const { t } = useTranslation();
  const handleCardClick = () => onCardClick && onCardClick(id || -1);

  return (
    <div
      role={'button'}
      onClick={handleCardClick}
      className='bg-white border divide-y rounded-lg shadow cursor-pointer border-gray-50 hover:shadow-lg'
    >
      <div className='grid grid-cols-10 gap-4 px-4 pt-4 pb-2'>
        {image ? (
          <img
            className='inline-block w-10 h-10 col-span-2 rounded-full'
            src={image}
            alt='name'
          />
        ) : (
          <RandomlyGeneratedAvatar className='col-span-2' />
        )}
        <div className='col-span-8 space-y-1 overflow-hidden'>
          <p className='font-medium text-gray-900 truncate'>{name}</p>
          <p className='font-light text-gray-500'>
            {t('Created By')}: {owner}
          </p>
        </div>
      </div>
      <div className='flex items-center justify-between px-4 py-3'>
        <div className='flex items-center ltr:space-x-2'>
          <UserGroupIcon className='w-5 h-5 text-gray-400' />
          <span className='text-gray-600 rtl:mr-2'>
            {t(`{{${memberCount}}} members`)}
          </span>
        </div>
        {isSmart && (
          <span className='inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800'>
            {t('Smart')}
          </span>
        )}
      </div>
    </div>
  );
};

export default GroupCard;
