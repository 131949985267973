import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import PlaceOrderIcon from '../../../old/pages/builder/assets/images/icons/place_order_block_icon.svg';
import { BlocksData } from '../../../utilities/content';
import { toaster } from 'evergreen-ui';
import PlaceOrderCustomerInformation from '../elements/eCommerceBlock/PlaceOrderCustomerInformation';
import InputOutputViewNavBar from '../elements/eCommerceBlock/InputOutputViewNavBar';
import TextAreaField from '../elements/eCommerceBlock/TextAreaField';
import TextCharInfo from '../elements/TextCharInfo';

interface Props {
  id: number;
  data: dataProps[];
  save: boolean;
  type: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  updatePlaceOrderData: (changeKey: string, changeValue: string) => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}
interface dataProps {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  shipping_address: string;
  shipping_method: string;
  shipping_cost: string;
  payment_method: string;
  output_message: string;
}

const PlaceOrder: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  updatePlaceOrderData,
}) => {
  const tabData = [
    { id: 0, name: 'Input Instructions' },
    { id: 1, name: 'Output View' },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState({
    id: 0,
    name: 'Input Instructions',
  });
  const [showInputAttributeField, setShowInputAttributeField] = useState('');
  const [showOutputAttributeField, setShowOutputAttributeField] =
    useState(false);

  const handleTabChange = (tab: any) => {
    setCurrentTabIndex(tab);
  };

  const handleTextInputValue = (changeKey: any, changeValue: string) => {
    let currentValue = changeValue;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{') setShowInputAttributeField(changeKey);
    else setShowInputAttributeField('');
    updatePlaceOrderData(changeKey, changeValue);
  };

  const handleSelectedFieldsAttributeValue = (
    changeKey: any,
    changeValue: string
  ) => {
    setShowInputAttributeField('');
    let newValue = data[changeKey] + `${changeValue}}}`;
    updatePlaceOrderData(changeKey, newValue);
  };

  const getEmptyFields = () => {
    let emptyFields = '';
    Object.entries(data).forEach((item: any) => {
      if (item[1].length === 0 && item[0] !== 'output_message') {
        emptyFields += ` ${
          BlocksData.placeOrderBlock.customerInformation[item[0]]
        },`;
      }
    });
    let newString = emptyFields.split('');
    newString[newString.length - 1] = '';
    return newString.join('');
  };

  const handleSave = () => {
    if (getEmptyFields().length === 0) saveBlock(id);
    else
      toaster.danger('Please fill all the mandatory fields', { duration: 2 });
  };

  return (
    <BlockContainer
      id={id}
      title={BlocksData.placeOrderBlock.title}
      subtitle={BlocksData.placeOrderBlock.subTitle}
      save={save}
      isLoading={saveLoader}
      icon={PlaceOrderIcon}
      sequences={sequences}
      handleSaveClick={() => handleSave()}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <InputOutputViewNavBar
          tabData={tabData}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
        />
        <br />
        <div className='border-t border-gray-200 mb-2.5 w-full' />
        {currentTabIndex.id === 0 ? (
          <PlaceOrderCustomerInformation
            inputInstructionsData={data}
            type={type}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            emptyFields={getEmptyFields()}
            showAttribute={showInputAttributeField}
            setShowAttribute={setShowInputAttributeField}
            handleTextInputValue={handleTextInputValue}
            handleSelectedFieldsAttributeValue={
              handleSelectedFieldsAttributeValue
            }
          />
        ) : (
          <>
            <TextAreaField
              value={data['output_message']}
              attributeList={BlocksData.placeOrderBlock.outputViewAttributes}
              showAttribute={showOutputAttributeField}
              setShowAttribute={(val: any) => setShowOutputAttributeField(val)}
              handleOnChange={(value: string) =>
                value.length <=
                  BlocksData.placeOrderBlock.outputViewTextMaxLength &&
                handleTextInputValue('output_message', value)
              }
              handleSelect={(value: string) =>
                handleSelectedFieldsAttributeValue('output_message', value)
              }
              hasWriteAccess={hasWriteAccess}
              placeHolder={BlocksData.placeOrderBlock.placeHolder}
            />
            <TextCharInfo
              data={data['output_message']}
              count={BlocksData.placeOrderBlock.outputViewTextMaxLength}
            />
          </>
        )}
      </>
    </BlockContainer>
  );
};

export default PlaceOrder;
