import React, { Component } from 'react';
import AuthHeader from './components/AuthHeader';
import bg from './../../assets/images/authOnboardBG.svg';
import Footer from '../../components/higherOrderComponents/Footer';

import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import { authData } from '../../utilities/content';
import { navigate, Redirect } from '@reach/router';
import RegisterFormSection from './components/RegisterFormSection';
import { getQueryToken, isValidEmail } from '../../utilities/utils';

class AppSumoRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: new URLSearchParams(window.location.search).get('email', ''),
      password: '',
      firstName: '',
      lastName: '',
      appSumoToken: '',
      duplicateEmail: false,
      loading: false,
    };
    const items = [
      'https://res.cloudinary.com/dgv96gtl3/image/upload/v1626730130/MyAlice-Connect.png',
      'https://res.cloudinary.com/dgv96gtl3/image/upload/v1626730132/MyAlice-Sell.png',
      'https://res.cloudinary.com/dgv96gtl3/image/upload/v1626730206/MyAlice-Grow.png',
    ];
    this.image = items[Math.floor(Math.random() * items.length)];
  }

  handleChange = (key, value) => {
    if (key === 'email') value = value.toLowerCase();
    this.setState({ [key]: value });
  };

  validateRegisterData = () => {
    const { email, password } = this.state;
    if (password.length < 6) {
      toaster.danger('Your password should be at least 6 characters long');
      return false;
    }
    if (password.length > 20) {
      toaster.danger('Your password can be at most 20 characters long');
      return false;
    }
    if (isValidEmail(email)) {
      return true;
    } else {
      toaster.danger(
        "Your email doesn't look valid. Please provide a valid email"
      );
      return false;
    }
  };

  handleRegisterLogin = async () => {
    const { auth, login, acceptInvitation, updateInvitationToken } = this.props;
    let res = await login({
      username: this.state.email,
      password: this.state.password,
    });
    if (res?.success) {
      if (auth.invitationToken.length > 0) {
        const invited = await acceptInvitation(auth.invitationToken);
        if (invited) {
          toaster.success('Invitation Accepted', {
            duration: 2,
          });
        } else {
          toaster.danger('Could Not Accept Invitation', {
            duration: 2,
          });
        }
        updateInvitationToken('');
      }

      let hasOnboarding = res.hasOnboarding;

      const shopifyToken = localStorage.getItem(
        'shopify_installation_request_id'
      );
      if (!!shopifyToken) {
        navigate('/shopify-connect');
      } else if (hasOnboarding) {
        navigate('/onboarding');
      } else {
        navigate('/dashboard');
      }
    }
  };

  handleRegisterSubmit = () => {
    if (!this.validateRegisterData()) return;
    this.setState({ loading: true });
    this.props.fetchGeolocation().then(async (data) => {
      this.props
        .appSumoRegister({
          email: this.state.email,
          username: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          request_id: this.state.appSumoToken,
          password: this.state.password,
          country: this.props.auth?.country,
          city: this.props.auth?.city,
          geolocation: this.props.auth?.geolocation,
        })
        .then(async (success) => {
          if (success) {
            await this.handleRegisterLogin();
            this.setState({
              email: '',
              password: '',
              loading: false,
            });
          } else {
            this.setState({ loading: false, duplicateEmail: true });
          }
        });
    });
  };

  async handleAppSumoValidation() {
    const token = getQueryToken('appsumo_installation_request_id');
    if (token) {
      const data = await this.props.validateAppSumoToken(token);
      if (data && data.success) {
        this.setState({
          email: data.dataSource?.activation_email || '',
          appSumoToken: data.dataSource?.request_id || '',
        });
      }
    }
  }

  componentDidMount() {
    this.props.logout();
    setTimeout(() => {
      navigate(`/register-appsumo${window.location.search}`);
      this.handleAppSumoValidation();
    }, 500);
  }

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className='flex flex-col items-center justify-start w-screen h-screen overflow-x-auto bg-cover bg-cente sm:justify-center'
      >
        <div className='flex flex-col justify-center h-screen px-10 py-8 bg-white rounded shadow sm:w-full sm:max-w-md sm:block sm:h-auto'>
          <AuthHeader
            title={authData.register.title}
            hasAppsumoLogo={true}
            subtitle={'Or'}
            link={authData.loginInfo.link}
            linkText={'login to your account here'}
          />
          <RegisterFormSection
            {...this.state}
            handleChange={this.handleChange}
            disableEmail={true}
            loading={this.state.loading}
            hasAppSumoInfo={true}
            handleSubmit={this.handleRegisterSubmit}
            content={authData}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  appSumoRegister: (payload) => dispatch.auth.appSumoRegister(payload),
  validateAppSumoToken: (token) => dispatch.auth.validateAppSumoToken(token),
  fetchGeolocation: () => dispatch.auth.fetchGeolocation(),
  login: (payload) => dispatch.auth.login(payload),
  acceptInvitation: (token) => dispatch.auth.acceptInvitation(token),
  updateInvitationToken: (token) => dispatch.auth.updateInvitationToken(token),
  logout: () => dispatch.auth.logout(),
});

const AppSumoRegisterContainer = connect(
  mapState,
  mapDispatch
)(AppSumoRegister);

export default AppSumoRegisterContainer;
