import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { CopyBlock, solarizedLight } from 'react-code-blocks';

interface Props {
  platfromData: webChatResponseDataProps;
}

const Step2: React.FC<Props> = ({ platfromData }) => {
  const { t } = useTranslation();
  const getCustomStyle = () => {
    return {
      height: '200px',
      overflowY: 'auto',
      borderRadius: '5px',
      boxShadow: '1px 2px 3px rgba(0,0,0,0.35)',
      fontSize: '0.70rem',
    };
  };
  return (
    <>
      <div className='flex justify-between'>
        <label
          htmlFor='url_whitelist'
          className='block text-sm font-medium text-gray-600'
        >
          {t('Script For Livechat')}
        </label>
        {/* <span className='text-[#0078CF]'>Email Code to Devs</span> */}
      </div>
      <p className='mt-2 rtl:text-right text-sm text-gray-500'>
        {t(
          'Copy paste this code in the footer of your website. Don’t worry, it’s totally safe and has been tested to work with any language.'
        )}
      </p>
      <div className='h-[200px] mt-2'>
        <CopyBlock
          text={`// Put this code snippet inside script tag
<script> !function(){var e=document.createElement("div");e.id="myAliceWebChat";var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://livechat.myalice.ai/index.js";var a=document.body.getElementsByTagName("script");(a=a[a.length-1]).parentNode.insertBefore(t,a),a.parentNode.insertBefore(e,a),t.addEventListener("load",function(){MyAliceWebChat.init({selector:"#myAliceWebChat",platformId:"${
            platfromData?.id || ''
          }",primaryId:"${platfromData?.primary_id || ''}",token:"${
            platfromData?.access_token || ''
          }"})})}(); </script>`}
          language='javascript'
          theme={solarizedLight}
          customStyle={getCustomStyle()}
          wrapLongLines={false}
          codeBlock={false}
        />
      </div>
    </>
  );
};
export default Step2;
