import React from 'react';

interface GroupCheckboxProps {
  checked: boolean;
  name: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GroupCheckbox: React.FC<GroupCheckboxProps> = ({
  checked,
  name,
  label = '',
  onChange,
}) => {
  return (
    <label className='space-x-2 font-medium text-gray-700'>
      <input
        name={name}
        checked={checked}
        onChange={onChange}
        type='checkbox'
        className='w-4 h-4 border-gray-300 rounded outline-none focus:ring-0 ring-offset-0 text-primary'
      />
      <span>{label}</span>
    </label>
  );
};

export default GroupCheckbox;
