import { useEffect } from 'react';
import { useTriggerActions, useActions, LoaderLine } from '../export';
import CanvasBoard from './canvas/index';

interface IProps {
  labId: string;
  projectId: string;
}

const DataLabFormBuilder: React.FC<IProps> = ({ labId, projectId }) => {
  const { fetchDataLabInfo } = useTriggerActions();
  const { getLoadingStatus } = useActions();
  const isLoading = getLoadingStatus();

  useEffect(() => {
    fetchDataLabInfo(projectId, labId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='my-alice-form-builder'>
      {isLoading ? (
        <div className='flex justify-center h-full mt-10 text-center align-center flex-column'>
          <LoaderLine />
        </div>
      ) : (
        <div className='overflow-hidden'>
          <CanvasBoard />
        </div>
      )}
    </div>
  );
};

export default DataLabFormBuilder;
