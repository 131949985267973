import { ReactElement, useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from 'libraryV2/ui/dialog';
import DataTabs from '../common/DataTabs';
import CodeEditorPanel from '../common/CodeEditorPanel';
import useRenderJinjaCode from '../hooks/useRenderJinjaCode';
import { Button } from 'libraryV2/ui/button';

interface JinjaCodeEditorModalProps {
  existingCode?: string;
  children: ReactElement;
  sampleDataFormat: Record<string, any>;
  sampleDataLoading: boolean;
  handleSaveData: (value: string) => void;
}

const JinjaCodeEditorModal: React.FC<JinjaCodeEditorModalProps> = ({
  children,
  sampleDataFormat,
  sampleDataLoading,
  handleSaveData,
  existingCode = '',
}) => {
  const {
    code,
    setCode,
    output,
    errorMsg,
    isLoading,
    renderCode,
    sampleData,
    setSampleData,
  } = useRenderJinjaCode();

  const [lastSuccessfulCode, setLastSuccessfulCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveBtnClicked, setIsSaveBtnClick] = useState(false);

  useEffect(() => {
    setSampleData(sampleDataFormat);
  }, [setSampleData, sampleDataFormat]);

  useEffect(() => {
    if (!!existingCode && existingCode !== code) {
      setCode(existingCode);
    }
    //eslint-disable-next-line
  }, [existingCode]);

  const handleRunCode = useCallback(async (): Promise<boolean> => {
    const isRenderSuccess = await renderCode();
    if (isRenderSuccess) setLastSuccessfulCode(code);
    return isRenderSuccess;
  }, [code, renderCode]);

  const onDataSave = useCallback(async () => {
    if (lastSuccessfulCode === code) {
      handleSaveData(code);
      setIsModalOpen(false);
      return;
    }
    setIsSaveBtnClick(true);
    const isRenderSuccess = await handleRunCode();
    setIsSaveBtnClick(false);

    if (isRenderSuccess) {
      handleSaveData(code);
      setIsModalOpen(false);
    }
  }, [lastSuccessfulCode, code, handleRunCode, handleSaveData]);

  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger asChild>
        <div onClick={() => setIsModalOpen(true)}>{children}</div>
      </DialogTrigger>
      <DialogContent className='bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto'>
        <div className='flex justify-between items-center'>
          <DialogTitle className='text-lg font-bold'>
            Code Editor Preview
          </DialogTitle>
        </div>
        <DialogDescription className='text-gray-500'>
          Write and preview using Jinja templates. Only Jinja syntax is
          supported.
        </DialogDescription>

        {/* Editor Layout */}
        <div className='grid grid-cols-2 gap-2'>
          {/* Tabs Section */}
          <DataTabs isLoading={sampleDataLoading} sampleData={sampleData} />

          {/* Code Editor Section */}
          <div className='max-h-full overflow-y-auto w-full'>
            <CodeEditorPanel
              code={code}
              isLoading={isLoading}
              setCode={setCode}
              output={output}
              errorMsg={errorMsg}
              handleRunCode={handleRunCode}
            />
          </div>
        </div>

        <DialogFooter>
          <div className='flex justify-center items-end gap-1'>
            <DialogClose asChild>
              <Button variant={'outline'} onClick={closeModal}>
                Cancel
              </Button>
            </DialogClose>

            <Button
              variant={'default'}
              disabled={isLoading || !code || !output || !!errorMsg}
              onClick={onDataSave}
            >
              {isLoading && isSaveBtnClicked ? 'Running Code' : 'Save'}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default JinjaCodeEditorModal;
