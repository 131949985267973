import React from 'react';
import { TextInputField, Button } from 'evergreen-ui';

interface props {
  createAdminGroup: (name: string) => void;
  createAdminGroupLoader: boolean;
}

const CreateGroupField: React.FC<props> = ({
  createAdminGroup,
  createAdminGroupLoader,
}) => {
  const [createGroup, setCreateGroup] = React.useState('');
  const [isInvalid, setIsInvalid] = React.useState(false);
  return (
    <div className='admin-access-tags__add-container'>
      <TextInputField
        value={createGroup}
        label={''}
        style={{ height: 26, fontSize: '1rem' }}
        isInvalid={isInvalid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCreateGroup(e.target.value)
        }
        validationMessage={isInvalid ? 'Groups Canot be Empty' : null}
      />
      <Button
        className='button-alice__gradient'
        iconBefore={createAdminGroupLoader ? null : 'add'}
        isLoading={createAdminGroupLoader}
        height={26}
        onClick={() => {
          if (createGroup === '') {
            setIsInvalid(true);
          } else {
            // post request
            createAdminGroup(createGroup);
            setIsInvalid(false);
            setCreateGroup('');
          }
        }}
      >
        Add Admin Group
      </Button>
    </div>
  );
};

export default CreateGroupField;
