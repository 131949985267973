import { useEffect } from 'react';

const useThrottledEffect = (
  effect: () => void,
  deps: any[],
  throttleTime: number
) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const throttledEffect = () => {
      if (!timeoutId) {
        effect();
        timeoutId = setTimeout(() => {
          timeoutId = null;
        }, throttleTime);
      }
    };

    throttledEffect();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    //eslint-disable-next-line
  }, deps);
};

export default useThrottledEffect;
