import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import InputOptionsList from './InputOptionsList';

interface Props {
  id: number;
  hasWriteAccess: boolean;
  options: string[];
  name: string;
  value: string;
  type: string;
  handleFieldValue: (
    id: number,
    fieldName: string,
    value: string,
    changeKey: string
  ) => void;
}

const InputOptionsDropdown: React.FC<Props> = ({
  id,
  hasWriteAccess,
  options,
  name,
  value,
  type,
  handleFieldValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  return (
    <Listbox
      disabled={!hasWriteAccess}
      value={selectedOption}
      onChange={(option: any) => {
        setSelectedOption(option);
        handleFieldValue(id, name, option, 'input_instructions');
      }}
    >
      {({ open }) => (
        <div className='relative w-full'>
          <Listbox.Button className='relative w-full h-10 py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default cursor-pointer focus:outline-none focus:border-gray-300 sm:text-sm'>
            <span className='block text-sm font-medium text-gray-700 truncate '>
              {selectedOption ? selectedOption : 'Select Option'}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <ChevronDownIcon
                className='w-5 h-5 text-gray-700'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              static
              className='absolute z-10 w-full py-1 mt-3 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            >
              {options?.map((val: any, index: number) => (
                <div key={index}>
                  <InputOptionsList id={index} title={val} />
                </div>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

InputOptionsDropdown.defaultProps = {
  hasWriteAccess: false,
};
export default InputOptionsDropdown;
