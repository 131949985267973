import React from 'react';
import {
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';

interface Prop {
  intent: 'success' | 'warning' | 'info' | 'danger';
  title: string;
  description?: string;
  children?: any;
  className?: string;
}

const Alert: React.FC<Prop> = ({
  intent,
  title,
  description,
  children,
  className,
}) => {
  const getIcon = (intent: string) => {
    switch (intent) {
      case 'warning':
        return (
          <ExclamationTriangleIcon
            className='w-5 h-5 text-yellow-400'
            aria-hidden='true'
          />
        );
      case 'success':
        return (
          <CheckCircleIcon
            className='w-5 h-5 text-green-400'
            aria-hidden='true'
          />
        );
      case 'danger':
        return (
          <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
        );
      case 'info':
      default:
        return (
          <InformationCircleIcon
            className='w-5 h-5 text-blue-400'
            aria-hidden='true'
          />
        );
    }
  };

  const getBgColor = (intent: string) => {
    switch (intent) {
      case 'warning':
        return 'bg-yellow-50';
      case 'success':
        return 'bg-green-50';
      case 'danger':
        return 'bg-red-50';
      case 'info':
      default:
        return 'bg-blue-50';
    }
  };

  const getTitleColor = (intent: string) => {
    switch (intent) {
      case 'warning':
        return 'text-yellow-800';
      case 'success':
        return 'text-green-800';
      case 'danger':
        return 'text-red-800';
      case 'info':
      default:
        return 'text-blue-800';
    }
  };

  const getDescColor = (intent: string) => {
    switch (intent) {
      case 'warning':
        return 'text-yellow-700';
      case 'success':
        return 'text-green-700';
      case 'danger':
        return 'text-red-700';
      case 'info':
      default:
        return 'text-blue-700';
    }
  };

  return (
    <div
      className={`rounded-md ${getBgColor(intent)} p-4 ${
        !!className ? className : ''
      }`}
    >
      <div className='flex'>
        <div className='flex-shrink-0'>{getIcon(intent)}</div>
        <div className='ml-3'>
          <h3 className={`text-sm font-medium ${getTitleColor(intent)}`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${getDescColor(intent)}`}>
            {description && <p>{description}</p>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
