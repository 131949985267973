import React from 'react';
import warningIcon from '../../../pages/builder/assets/images/icons/warning.svg';

interface Props {
  warningMessage: string;
}

const OperationWarning: React.FC<Props> = ({ warningMessage }) => {
  return (
    <div className='flex border-none rounded-md bg-yellow-50 p-3 mt-1.5'>
      <img className='w-6 h-6 rounded-full' src={warningIcon} alt='' />
      <span className='ml-3 text-sm text-yellow-700'>{warningMessage}</span>
    </div>
  );
};

export default OperationWarning;
