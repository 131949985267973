import React from 'react';
import { Link } from 'library';
import { connect } from 'react-redux';
import AliceLogo from './../../assets/images/logo-light-bg.svg';
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { BookmarkSquareIcon, BookOpenIcon } from '@heroicons/react/24/outline';

interface Props {
  auth: { access: string };
}

const NotFoundPage: React.FC<Props> = ({ auth }) => {
  const renderLogOutPageNotFoundView = () => {
    return (
      <div className='m-6'>
        <div className='flex justify-center items-center w-full h-screen text-center bg-white rounded'>
          <div className='flex flex-col'>
            <div className='flex justify-center mb-10'>
              <img
                className='h-8 w-auto cursor-pointer'
                src={AliceLogo}
                alt=''
              />
            </div>
            <div className='mb-12'>
              <h4 className='text-[#EF4444] uppercase font-semibold text-base'>
                404 Error
              </h4>
              <div className='font-extrabold text-[#111827] mt-1 text-4xl'>
                Page not found.
              </div>
              <p className='text-gray-500 text-base mt-1 font-normal'>
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className='flex justify-center mt-3'>
                <Link
                  url='/'
                  className='flex justify-center mt-4 text-[#0078CF]'
                  textSize='md'
                >
                  <div className='mr-2'>Myalice Home</div>
                  <ArrowRightIcon className='w-5 h-5' />
                </Link>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <div className='pr-4 border-r border-gray-400'>
                <Link
                  url='https://myalice.statuspage.io/'
                  openInNewTab
                  className='text-[#6B7280]'
                >
                  Status
                </Link>
              </div>
              <div className='pl-4'>
                <Link
                  url='https://www.myalice.ai/contact'
                  openInNewTab
                  className='text-[#6B7280]'
                >
                  Contact Support
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLogInPageNotFoundView = () => {
    return (
      <div className='m-6'>
        <div className='flex justify-center items-center w-full h-screen text-center bg-white rounded'>
          <div className='flex flex-col'>
            <div className='mb-10'>
              <h4 className='text-[#EF4444] uppercase font-semibold text-base'>
                404 Error
              </h4>
              <div className='text-[#111827] mt-1 text-4xl font-extrabold'>
                This page does not exist.
              </div>
              <p className='text-gray-500 text-base mt-1 font-normal'>
                The page you are looking for could not be found.
              </p>
            </div>
            <div>
              <p className='uppercase text-left pb-4 text-gray-600 border-b font-semibold'>
                You may want to take a look at
              </p>
              <Link
                url='https://docs.myalice.ai/'
                openInNewTab
                className='flex justify-between items-center py-3 my-2 hover:bg-gray-100 transition-all rounded-md'
              >
                <div className='flex items-center'>
                  <div className=' w-12 h-12 rounded-lg bg-gray-100 flex justify-center items-center mr-2'>
                    <BookOpenIcon className='w-7 h-7 text-gray-500' />
                  </div>
                  <div className='text-left'>
                    <h4 className='text-base text-gray-900 font-medium'>
                      Documentation
                    </h4>
                    <p className='text-gray-500 text-base font-normal'>
                      Learn how MyAlice works
                    </p>
                  </div>
                </div>
                <div className='pl-8 pr-2'>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                </div>
              </Link>
              <div className='border-b'></div>
              <Link
                url='https://www.youtube.com/playlist?list=PL_EdxcvIGFEagxlG7c7rMCg62UA9pPdMk'
                openInNewTab
                className='flex justify-between items-center py-3 my-2 hover:bg-gray-100 transition-all rounded-md'
              >
                <div className='flex items-center'>
                  <div className=' w-12 h-12 rounded-lg bg-gray-100 flex justify-center items-center mr-2'>
                    <BookmarkSquareIcon className='w-7 h-7 text-gray-500' />
                  </div>
                  <div className='text-left'>
                    <h4 className='text-base text-gray-900 font-medium'>
                      Tutorials
                    </h4>
                    <p className='text-gray-500 text-base font-normal'>
                      Installation guides that cover popular setups
                    </p>
                  </div>
                </div>
                <div className='pl-8 pr-2'>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                </div>
              </Link>
            </div>
            <div className='text-left mt-4'>
              <div
                onClick={() => window.history.back()}
                className='text-[#0078CF] hover:text-link-hover cursor-pointer text-sm font-medium flex items-center'
              >
                <div className='mr-2'>Or, go back</div>
                <ArrowRightIcon className='w-5 h-5' />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      {auth?.access !== ''
        ? renderLogInPageNotFoundView()
        : renderLogOutPageNotFoundView()}
    </React.Fragment>
  );
};

const mapState = (state: any) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(NotFoundPage);
