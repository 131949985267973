import { String } from 'aws-sdk/clients/apigateway';
import React from 'react';
import SequenceDropDown from './SequenceDropDown';
import AttributeAPIContainer from '../AttributeAPIContainer';
import TextVariableInputField from '../eCommerceBlock/TextVariableInputFeild';

interface Props {
  data: dataProps;
  id: number;
  inputParameters: inputParametersProps[];
  hasWriteAccess: boolean;
  attributeList: string[];
  platformType: string;
  showAttributeField: number | null;
  setShowAttributeField: (val: {
    id: number;
    title: string;
    is_default: boolean;
  }) => void;
  handleSelectedParamsInputValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  selectedSequence: { id: number; title: string };
  setSelectedSequence: (val: { id: number; title: string }) => void;
  sequences?: sequenceProps[];
  hasSequences?: boolean;
  search: string;
  setSearch: (data: String) => void;
  updateSequence: (name: string, id: number) => void;
  handleSelectedParamsAttributeValue: (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

interface inputParametersProps {
  name: string;
  value: string;
  type: boolean | string | number;
}

interface dataProps {
  input_instructions: inputInstruction[];
  output_instructions: {
    display_image: boolean;
    title: string;
    subtitle: string;
    url: string;
    buttons: [];
  };
}

interface inputInstruction {
  name: string;
  type: string;
  value: string;
  default: string | number | null;
  verbose: string;
  isPinned: boolean;
}

const BetterdocsInputInstruction: React.FC<Props> = ({
  inputParameters,
  hasWriteAccess,
  attributeList,
  showAttributeField,
  platformType,
  setShowAttributeField,
  selectedSequence,
  setSelectedSequence,
  sequences,
  handleSelectedParamsInputValue,
  hasSequences = true,
  search,
  setSearch,
  updateSequence,
  id,
  handleSelectedParamsAttributeValue,
  data,
}) => {
  return (
    <>
      <div className='flex gap-2'>
        <div className='w-[50%]'>
          <p className='text-gray-700 text-sm p-1'>Key</p>
          <input
            value='search'
            className='bg-gray-100 p-2 border border-gray-200 text-sm text-gray-700 w-full rounded-md disabled'
          />
        </div>
        <div className='w-[50%]'>
          <p className='text-gray-700 text-sm p-1'>Value</p>
          <AttributeAPIContainer
            attributeList={attributeList}
            showAttribute={true}
            setShowAttribute={setShowAttributeField}
            handleSelect={handleSelectedParamsInputValue}
          >
            <TextVariableInputField
              id={id}
              disabled={false}
              value={
                data.input_instructions.length > 0
                  ? data.input_instructions[0].value
                  : search
              }
              type={'text'}
              attributeList={attributeList}
              showAttribute={id === showAttributeField}
              setShowAttribute={setShowAttributeField}
              placeHolder={'Add text and variable'}
              handleOnChange={(value: string) => {
                handleSelectedParamsInputValue(
                  id,
                  'search',
                  value,
                  `input_instructions`
                );
                setSearch(value);
              }}
              handleSelect={(value: any) => {
                handleSelectedParamsAttributeValue(
                  id,
                  'search',
                  value,
                  'input_instructions'
                );
                setSearch(value);
              }}
              hasWriteAccess={hasWriteAccess}
            />
          </AttributeAPIContainer>
        </div>
      </div>

      <div className='border-t border-gray-200 mt-2.5 mb-5' />
      {hasSequences && (
        <SequenceDropDown
          hasWriteAccess={hasWriteAccess}
          sequences={sequences}
          selectedSequence={selectedSequence}
          setSelectedSequence={setSelectedSequence}
          updateSequence={updateSequence}
        />
      )}
    </>
  );
};

export default BetterdocsInputInstruction;
