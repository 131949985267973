import {
  ICustomerCartInfo,
  IEcommerceProductData,
  IEcommerceProductDataForUI,
} from 'pages/inbox/inboxInterface';
import { LinkIcon } from 'pages/inbox/assets/iconComponent/LinkIcon';
import { MinusIcon } from '@heroicons/react/20/solid';
import { CopyToClipboard, classNames } from 'utilities/utils';
import { useEffect, useState } from 'react';
import { toaster } from 'evergreen-ui';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';
import ReactTooltip from 'react-tooltip';
import Button from 'library/button';
import { connect } from 'react-redux';
import useTranslation from 'components/customHooks/useTranslation';
import { EcommerceProductsFunctions } from 'pages/inbox/utils/functions';
import SingleItemView from './VariationView_v2/SingleItemView';
import { SelectedTeamInterface } from 'index';

interface Props {
  currencySymbol: string;
  customerCart: ICustomerCartInfo;
  products: IEcommerceProductData[];
  selectedTeam: SelectedTeamInterface;
  setClose: () => void;
  onCheckout: () => void;
  getCartLink: () => Promise<string>;
  handleAddMoreItem: () => void;
  updateCartInfo: (customerCart: ICustomerCartInfo) => Promise<void>;
  getVariations: (producId: string | number) => Promise<any>;
  updateEcommerceStateData: (key: string, value: any) => void;
}

const VariationView: React.FC<Props> = ({
  setClose,
  products,
  onCheckout,
  getCartLink,
  customerCart,
  selectedTeam,
  getVariations,
  updateCartInfo,
  currencySymbol,
  handleAddMoreItem,
  updateEcommerceStateData,
}) => {
  const { t } = useTranslation();
  const [isCartLinkLoading, setCartLinkLoading] = useState<boolean>(false);
  const [isCheckoutLoading, setCheckoutLoading] = useState<boolean>(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const removeProduct = async (productId: number | string) => {
    const oldCart = { ...customerCart };
    oldCart.alice_cart = [
      ...oldCart.alice_cart.filter(
        (product) => product.product_id !== productId
      ),
    ];
    await updateCartInfo({ ...oldCart });
  };

  const handleLinkClick = async () => {
    setCartLinkLoading(true);
    const link = await getCartLink();
    CopyToClipboard(link.toString())
      .then(() => {
        setCartLinkLoading(false);
        toaster.success('Link copied to clipboard');
      })
      .catch((err) => console.error(err));
  };

  const isCheckoutDisable = () => {
    const isEnable =
      customerCart?.alice_cart &&
      customerCart?.alice_cart.every((item: IEcommerceProductData) => {
        if (item.has_variations) {
          return item.variant_id && item.variant_id !== '';
        }
        return true;
      });

    return !isEnable;
  };

  const getProductData = () => {
    return EcommerceProductsFunctions.ecommerceProductsForUI(
      customerCart?.alice_cart
    );
  };

  const updateProductData = async (
    product: IEcommerceProductDataForUI,
    index: number
  ) => {
    const list = getProductData();
    list[index] = { ...product };
    customerCart.alice_cart = [
      ...EcommerceProductsFunctions.ecommerceProductsForApi(list),
    ];
    await updateCartInfo({ ...customerCart });
  };

  const getProduct = (product_id: string | number) => {
    const index = customerCart?.product_properties.findIndex(
      (product) => product.product_id === product_id
    );
    return index !== -1 ? customerCart?.product_properties[index] : null;
  };

  return (
    <div className='p-2 overflow-visible w-[35vw] max-w-[70vw]'>
      <div className='flex items-center justify-start mb-5'>
        <span className='text-base font-semibold text-gray-900 ltr:mr-3 rtl:ml-3'>
          {t('View Cart')}
        </span>
        {isCartLinkLoading && (
          <span className='w-2 h-2 flex items-center'>
            <CircleLoader />
          </span>
        )}

        {!isCartLinkLoading && (
          <>
            <span
              data-for='link-copy'
              data-tip={t('Copy cart link')}
              onClick={() => handleLinkClick()}
            >
              <LinkIcon className='w-5 h-5 cursor-pointer' />
            </span>
          </>
        )}

        <ReactTooltip id='link-copy' place='top' type='dark' effect='solid' />

        <MinusIcon
          className='w-6 h-5 ltr:ml-auto rtl:mr-auto text-gray-400 cursor-pointer'
          onClick={() => setClose()}
        />
      </div>
      <div
        className={classNames(
          'relative',
          customerCart?.alice_cart.length > 3
            ? 'max-h-[45vh] overflow-y-auto'
            : 'overflow-visible'
        )}
      >
        {getProductData().map(
          (item: IEcommerceProductDataForUI, index: number) => {
            return (
              // <SingleVariationItem
              //   key={item.product_id}
              //   product={item}
              //   isThisFirstProduct={
              //     customerCart?.alice_cart.length > 3 && index === 0
              //   }
              //   fetchVariations={getVariations}
              //   onRemoveProduct={removeProduct}
              //   onUpdateQuantity={updateQuantity}
              //   onVariationSelect={updateProductVariation}
              // />
              <SingleItemView
                key={index}
                product={item}
                getProduct={getProduct}
                currencySymbol={currencySymbol}
                onRemoveProduct={removeProduct}
                getVariations={getVariations}
                ecommerce_type={selectedTeam?.ecommerce_type ?? ''}
                onUpdate={async (data: IEcommerceProductDataForUI) => {
                  await updateProductData(data, index);
                }}
              />
            );
          }
        )}
      </div>
      <div className='mt-4  items-center flex'>
        <span
          className=' text-sm font-medium text-[#0078CF] cursor-pointer'
          onClick={() => updateEcommerceStateData('showDiscardAlert', true)}
        >
          {t('Discard draft')}
        </span>
        <div className='ltr:ml-auto rtl:mr-auto flex items-center gap-2'>
          <Button
            intent='default'
            shouldHaveFocusRing={false}
            isDisabled={isCheckoutLoading}
            onClick={() => handleAddMoreItem()}
          >
            {t('Add more items')}
          </Button>

          {isCheckoutLoading && (
            <button
              type='button'
              className='bg-gray-500 text-white px-4 py-2 rounded-md text-sm font-medium'
              disabled
            >
              {t('Please wait..')}
              <CircleLoader variant='disable' />
            </button>
          )}
          {!isCheckoutLoading && (
            <Button
              isDisabled={isCheckoutDisable()}
              intent='primary'
              className='ml-2'
              size='md'
              onClick={() => {
                setCheckoutLoading(true);
                onCheckout();
              }}
            >
              {t('Checkout')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  selectedTeam: state.dashboard.selectedProject,
  showDiscardAlert: state.ecommerce.showDiscardAlert,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
});

export default connect(mapState, mapDispatch)(VariationView);
