import React, { forwardRef, Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import Footer from 'library/modal/component/Footer';
import Header from 'library/modal/component/Header';

interface Prop {
  open: boolean;
  handleClose: () => void;
  children: any;
  title?: string;
  description?: string | React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  handleCancel?: () => void;
  disableConfirm?: boolean;
  closeOnExternalClick?: boolean;
  handleMinimize?: () => void;
  isConfirmButtonLoading?: boolean;
  hasMinimizeButton?: boolean;
  hideCancel?: boolean;
  headerClassNames?: string;
  ref?: any;
  hasFooter?: boolean;
  hasDropdown?: boolean;
  hasLeftActionElement?: boolean;
  hideLeftActionElement?: boolean;
  leftActionElement?: any;
  handleChange?: (value: any) => void;
  hasConfirmButton?: boolean;
  isDescriptionFullSize?: boolean;
  customConfirmButton?: React.ReactNode;
  width?: string;
}

const WhatsappSideSheet: React.FC<Prop> = forwardRef<any, Prop>(
  (
    {
      open,
      handleClose,
      children,
      title = 'Title',
      description,
      cancelText = 'Cancel',
      confirmText = 'Continue',
      handleConfirm,
      handleCancel,
      disableConfirm = false,
      hideCancel = false,
      closeOnExternalClick = true,
      handleMinimize,
      isConfirmButtonLoading = false,
      hasMinimizeButton = false,
      hasFooter = true,
      hasDropdown = false,
      hasLeftActionElement = false,
      hideLeftActionElement = true,
      leftActionElement,
      handleChange,
      isDescriptionFullSize = false,
      hasConfirmButton = true,
      customConfirmButton,
      headerClassNames = 'border-b border-gray-200 bg-white',
      width = 'w-[50%]',
    },
    ref
  ) => {
    const handleExternalClick = () => {
      if (closeOnExternalClick) {
        handleClose();
      }
    };

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed w-screen inset-0 z-20 overflow-hidden'
          open={open}
          onClose={handleExternalClick}
        >
          <div className='absolute inset-0 overflow-hidden w-screen'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-200'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>
            <div
              className={`fixed ${width} inset-y-0 ltr:right-0 rtl:left-0 flex max-w-full pl-0`}
            >
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-300 sm:duration-500'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-300 sm:duration-500'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='w-screen max-w-full'>
                  <div
                    ref={ref}
                    className='relative flex flex-col h-full bg-gray-50 shadow-xl max-h-full'
                  >
                    <div className='flex-1'>
                      {/* Header */}
                      <div className='sticky top-0 z-20 w-full'>
                        <Header
                          handleClose={handleClose}
                          description={description}
                          title={title}
                          isDescriptionFullSize={isDescriptionFullSize}
                          className={headerClassNames}
                          hasMinimizeButton={hasMinimizeButton}
                          handleMinimize={handleMinimize}
                        />
                      </div>

                      {/* Divider container */}
                      <div className='py-6 mb-20 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200'>
                        {children}
                      </div>
                    </div>
                    {/* Action buttons */}
                    {hasFooter && (
                      <div
                        className={`fixed bottom-0 ${width} ltr:right-0 rtl:left-0`}
                      >
                        <Footer
                          cancelText={cancelText}
                          confirmText={confirmText}
                          isConfirmButtonLoading={isConfirmButtonLoading}
                          disableConfirm={disableConfirm}
                          handleConfirm={handleConfirm}
                          handleCancel={handleCancel!}
                          hideCancel={hideCancel}
                          hasDropdown={hasDropdown}
                          handleChange={handleChange}
                          hasLeftActionElement={hasLeftActionElement}
                          hideLeftActionElement={hideLeftActionElement}
                          leftActionElement={leftActionElement}
                          hasConfirmButton={hasConfirmButton}
                          customConfirmButton={customConfirmButton}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
);

export default WhatsappSideSheet;
