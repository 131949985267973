import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import cloneDeep from 'lodash/cloneDeep';
import { mixpanelTrack } from 'utilities/mixpanel';

const initialState = {
  // to do: crm ecom related all api and reducer should be called from here
  // it was not created before so some might be in settings and crm model
  // please also note that crm ecom and settings ecom is different thing
  customerData: null,
  productData: [],
  selectedProductData: [],
  shouldScrollNext: false,
  variantLists: [],
  paymentMethod: '',
  shippingMethod: '',
  shippingCost: 0,
  isMinimized: false,
  tempCustomerData: null,
  isSameShippingAddress: true,
  noProductsFound: false,
  isOrderUpdate: false,
  ecommerceOrderList: [],
  paymentMethodList: [],
  shippingMethodList: [],
  totalCost: 0,
  totalTax: 0,
  orderStatus: 'pending',
  woocommerceDiscountCoupons: [],
  selectedCouponCode: '',
  shopifyDiscountType: '',
  shopifyDiscountAmount: '',
  applyTax: false,
  orderNote: '',
};

export const crmEcommerce = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, { key, value }) {
      return { ...state, [key]: value };
    },
    updateVariantList(state, { productId, dataSource }) {
      return {
        ...state,
        variantLists: [...state.variantLists, { id: productId, dataSource }],
      };
    },
    updateShopifyProductVariantList(state, { productId, dataSource }) {
      // // let updatedAliceCart = cloneDeep(state.customerData.alice_cart);
      // let updatedAliceCart = state.customerData.alice_cart.map((data) => {
      //   if (data.product_id === productId && dataSource.length === 1) {
      //     data.variant_id = dataSource[0].variant_id;
      //     data.variant_attributes = dataSource[0].attribute_list;
      //     data.variant_attributes_string = dataSource[0].attribute_string;
      //   }
      //   return data;
      // });
      return {
        ...state,
        variantLists: [...state.variantLists, { id: productId, dataSource }],
        // customerData: {
        //   ...state.customerData,
        //   alice_cart: updatedAliceCart,
        // },
      };
    },
    updateCustomerData(state, payload) {
      const filteredPaymentMethodData = state.paymentMethodList.filter(
        (data) => data.method_id === payload.payment_line.method_id
      );
      const filteredShipmentMethodData = state.shippingMethodList.filter(
        (data) => data.method_id === payload.shipping_line.method_id
      );
      return {
        ...state,
        customerData: {
          ...state.customerData,
          alice_cart: payload.products,
          billing_address: payload?.billing_address || '',
          shipping_address: payload.shipping_address,
          shipping_line: payload.shipping_line,
          payment_line: payload.payment_line,
        },
        paymentMethod: filteredPaymentMethodData[0],
        shippingMethod: filteredShipmentMethodData[0],
        shippingCost: payload.total_shipping_cost,
      };
    },
    updateAliceCart(state, payload) {
      return {
        ...state,
        customerData: {
          ...state.customerData,
          alice_cart: payload.alice_cart,
        },
      };
    },
    updateAddress(state, { key, value, mainKey }) {
      let customerDataLocal = JSON.parse(JSON.stringify(state.customerData))[
        mainKey
      ];
      customerDataLocal[key] = value;
      return {
        ...state,
        customerData: { ...state.customerData, [mainKey]: customerDataLocal },
      };
    },
    updateOrderData(state, payload) {
      let updatedEcomList = cloneDeep(state.ecommerceOrderList);
      updatedEcomList = updatedEcomList.map((data) => {
        if (data.ecommerce_order_id === payload.order_id) {
          data.line_items = [...payload.products];
        }
        return data;
      });
      return { ...state, ecommerceOrderList: updatedEcomList };
    },
    updateProductData(state, { payload, isScrolled }) {
      let newProductData = [];
      let noProducts = false;
      if (payload.length === 0) {
        noProducts = true;
      }
      if (isScrolled) {
        newProductData = [...state.productData, ...payload];
      } else {
        newProductData = [...payload];
      }
      return {
        ...state,
        productData: newProductData,
        shouldScrollNext: payload.length > 0,
        noProductsFound: noProducts,
      };
    },
    updateProductDataList(state, payload) {
      let updatedProductData = cloneDeep(state.productData);
      updatedProductData.forEach((item, index) => {
        if (item.product_id === payload.product_id) {
          updatedProductData[index] = payload;
        }
      });
      let updatedSelectedProductData = cloneDeep(state.selectedProductData);
      if (payload.is_selected) {
        updatedSelectedProductData = [...updatedSelectedProductData, payload];
      } else {
        updatedSelectedProductData = updatedSelectedProductData.filter(
          (product) => product.product_id !== payload.product_id
        );
      }
      return {
        ...state,
        productData: updatedProductData,
        selectedProductData: updatedSelectedProductData,
      };
    },
    updateFetchTicketMinimize(state) {
      return {
        ...state,
        customerData: state.tempCustomerData,
        isMinimized: false,
        tempCustomerData: null,
      };
    },
    updateMinimizeModal(state) {
      return {
        ...initialState,
        tempCustomerData: state.customerData,
        isMinimized: true,
      };
    },
    updateEcommerceOrderList(state, payload) {
      return { ...state, ecommerceOrderList: payload };
    },
    updatePaymentMethodList(state, payload) {
      const filteredData = payload.filter((e) => e.is_enabled);
      return {
        ...state,
        paymentMethodList: payload,
        paymentMethod: filteredData[0],
      };
    },
    updateShippingMethodList(state, payload) {
      return {
        ...state,
        shippingMethodList: payload,
        shippingMethod: payload[0],
      };
    },
    updateShopifyShippingData(state, payload) {
      return {
        ...state,
        shippingMethodList: payload.available_shipping_lines,
        shippingMethod: payload.available_shipping_lines[0],
        shippingCost: payload.total_shipping_cost,
        totalCost: payload.total_cost,
        totalTax: payload.total_tax,
      };
    },
    updateFinalShopifyShippingData(state, payload) {
      return {
        ...state,
        shippingMethodList: payload.available_shipping_lines,
        shippingMethod: payload.shipping_line,
        shippingCost: payload.total_shipping_cost,
        totalCost: payload.total_cost,
        totalTax: payload.total_tax,
      };
    },
    updateWoocommerceDiscountCoupons(state, payload) {
      return { ...state, woocommerceDiscountCoupons: payload };
    },
    updateShopifyUpdateOrderCalculation(state, payload) {
      return {
        ...state,
        orderNote: payload?.note,
        customerData: {
          ...state.customerData,
          calculationActionType: payload?.action_type || '',
          subtotalCost: payload?.subtotal_cost || 0,
          totalCost: payload?.total_cost || 0,
          totalTax: payload?.total_tax || 0,
          ecommerceOrderCalculationId:
            payload?.ecommerce_order_calculation_id || '',
          areProductsUpdated: payload?.products_updated,
          calculatedProducts: cloneDeep(payload?.products || []),
          visibleCalculatedProducts: cloneDeep(
            payload?.products.filter((product) => product.quantity > 0) || []
          ),
        },
      };
    },
    updateVisibleShopifyUpdateOrderCalculation(
      state,
      visibleCalculatedProducts
    ) {
      return {
        ...state,
        customerData: {
          ...state.customerData,
          visibleCalculatedProducts: visibleCalculatedProducts || [],
        },
      };
    },
    updateVariantAttributeMap(state, variantAttributeMap) {
      return {
        ...state,
        customerData: {
          ...state.customerData,
          variantAttributeMap: variantAttributeMap,
        },
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchCart(customerId, rootState) {
      // this loads on initial create order call :)
      try {
        const res = await axios.get(config.crmEcommerce.cart(customerId));
        if (res.status === 200 && res.data.success) {
          if (
            rootState.crmEcommerce.isMinimized &&
            rootState.crmEcommerce.tempCustomerData
          ) {
            //minimize
            dispatch.crmEcommerce.updateFetchTicketMinimize();
          } else {
            dispatch.crmEcommerce.updateStateData({
              key: 'customerData',
              value: res.data.dataSource,
            });
            return true;
          }
        } else {
          dispatch.crmEcommerce.updateStateData({
            key: 'customerData',
            value: null,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchProductList({ teamId, search, offset, limit, isScrolled }) {
      // this loads on initial create order call :)
      try {
        const res = await axios.get(config.crmEcommerce.products(teamId), {
          params: {
            search: search,
            offset: offset,
            limit: limit,
          },
        });
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateProductData({
            payload: res.data.dataSource,
            isScrolled: isScrolled,
          });
          return true;
        } else {
          dispatch.crmEcommerce.updateProductData({
            payload: [],
            isScrolled: isScrolled,
          });
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async updateCart({ customerId, cartProducts }, rootState) {
      // this loads on initial create order call :)
      try {
        const res = await axios.post(
          config.crmEcommerce.updateCart(customerId),
          {
            cart_products: cartProducts,
          }
        );
        if (res.status === 200 && res.data.success) {
          if (rootState.crmEcommerce.isOrderUpdate) {
            dispatch.crmEcommerce.updateAliceCart(res.data.dataSource);
          } else {
            dispatch.crmEcommerce.updateStateData({
              key: 'customerData',
              value: res.data.dataSource,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchVariant(payload, rootState) {
      // as our listbox component doesnt have proper mounting api, we
      // will call them on render. to optimize api call we will check if we
      // have it in store if so wont call if we dont have we will call
      // we will clear this data on close  not in minimize
      try {
        //validating payload
        if (!!payload) {
          // look for if its data already fetched and stored in redux store or not
          const isAlreadyFetched =
            rootState.crmEcommerce.variantLists.filter(
              (product) => product.id === payload.productId
            ).length > 0;
          if (!isAlreadyFetched) {
            // if not we call api
            const res = await axios.get(
              config.crmEcommerce.variant(payload.teamId, payload.productId)
            );
            if (res.status === 200 && res.data.success) {
              dispatch.crmEcommerce.updateVariantList({
                productId: payload?.productId,
                dataSource: res.data.dataSource,
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createOrder({ teamId, customerId, ticketId }, rootState) {
      // this loads on initial create order call :)
      rootState.crmEcommerce.shippingMethod.shipping_cost =
        !!rootState.crmEcommerce.shippingCost &&
        !(
          rootState.crmEcommerce.shippingMethod.method_id === 'free_shipping' ||
          rootState.crmEcommerce.shippingMethod.method_id === 'local_pickup'
        )
          ? rootState.crmEcommerce.shippingCost
          : 0;
      try {
        const payload = {
          customer_id: customerId,
          ticket_id: ticketId,
          billing_address:
            rootState.crmEcommerce.customerData?.billing_address || '',
          shipping_address:
            rootState.crmEcommerce.customerData?.shipping_address || '',
          payment_line: rootState.crmEcommerce?.paymentMethod,
          shipping_line: rootState.crmEcommerce?.shippingMethod,
          coupon_code: rootState.crmEcommerce?.selectedCouponCode,
        };
        const res = await axios.post(
          config.crmEcommerce.createOrder(teamId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          mixpanelTrack('Created Order', {
            customerId: customerId,
          });
          return res.data;
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },

    async updateOrder({ projectId, orderId, customerId }, rootState) {
      try {
        rootState.crmEcommerce.shippingMethod.shipping_cost =
          !!rootState.crmEcommerce.shippingCost &&
          !(
            rootState.crmEcommerce.shippingMethod.method_id ===
              'free_shipping' ||
            rootState.crmEcommerce.shippingMethod.method_id === 'local_pickup'
          )
            ? rootState.crmEcommerce.shippingCost
            : 0;
        rootState.crmEcommerce.selectedCouponCode =
          rootState.crmEcommerce.selectedCouponCode === null
            ? ''
            : rootState.crmEcommerce.selectedCouponCode;
        const payload = {
          billing_address: rootState.crmEcommerce.customerData?.billing_address,
          shipping_address:
            rootState.crmEcommerce.customerData?.shipping_address,
          payment_line: rootState.crmEcommerce.paymentMethod,
          shipping_line: rootState.crmEcommerce.shippingMethod,
          customer_id: customerId,
          coupon_code: rootState.crmEcommerce.selectedCouponCode,
        };
        const res = await axios.post(
          config.crmEcommerce.updateOrder(projectId, orderId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateStateData({
            key: 'isOrderUpdate',
            value: false,
          });
          dispatch.crm.updateOrderData(res.data.dataSource);
          return res.data;
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return { success: false, dataSource: null };
      }
    },
    async fetchOrder({ projectId, orderId }) {
      try {
        const res = await axios.get(
          config.crmEcommerce.getOrder(projectId, orderId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateStateData({
            key: 'isSameShippingAddress',
            value: false,
          });
          dispatch.crmEcommerce.updateStateData({
            key: 'isOrderUpdate',
            value: res.data.success,
          });
          dispatch.crmEcommerce.updateCustomerData(res.data.dataSource);
          return res.data;
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return { success: false, dataSource: null };
      }
    },
    async searchOrder({ projectId, search }) {
      try {
        const paramsLocal =
          !!search && !isNaN(search)
            ? { order_id: search }
            : { search: search };
        const res = await axios.get(
          config.crmEcommerce.searchOrder(projectId),
          {
            params: paramsLocal,
          }
        );
        if (res.data.success) {
          dispatch.crmEcommerce.updateEcommerceOrderList(res.data.dataSource);
        } else {
          dispatch.crmEcommerce.updateEcommerceOrderList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crmEcommerce.updateEcommerceOrderList([]);
      }
    },
    async fetchProducts({ productURL, products }) {
      try {
        const res = await axios.post(
          config.crmEcommerce.searchedOrderProducts(productURL),
          {
            line_items: products,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateOrderData(res.data.dataSource);
          return true;
        } else {
          dispatch.crmEcommerce.updateOrderData(null);
          return true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchPaymentMethods({ projectId }) {
      try {
        const res = await axios.get(
          config.crmEcommerce.paymentMethod(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updatePaymentMethodList(res.data.dataSource);
          return res.data.dataSource;
        } else {
          dispatch.crmEcommerce.updatePaymentMethodList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crmEcommerce.updatePaymentMethodList([]);
      }
      return [];
    },
    async fetchShippingMethods({ projectId }) {
      try {
        const res = await axios.get(
          config.crmEcommerce.shippingMethod(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateShippingMethodList(res.data.dataSource);
          return res.data.dataSource;
        } else {
          dispatch.crmEcommerce.updateShippingMethodList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crmEcommerce.updateShippingMethodList([]);
      }
      return [];
    },
    async calculateShopifyDraftOrder({ teamId, customerId }, rootState) {
      try {
        const payload = {
          customer_id: customerId,
          billing_address: rootState.crmEcommerce.customerData?.billing_address,
          shipping_address:
            rootState.crmEcommerce.customerData?.shipping_address,
          shipping_line: null,
          discount_type: rootState.crmEcommerce.shopifyDiscountType,
          discount_amount: rootState.crmEcommerce.shopifyDiscountAmount,
          apply_tax: rootState.crmEcommerce.applyTax,
        };
        const res = await axios.post(
          `${config.crmEcommerce.calculateDraftOrder(teamId)}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateShopifyShippingData(res.data.dataSource);
          return res.data.dataSource;
        }
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    async calculateFinalShopifyDraftOrder({ teamId, customerId }, rootState) {
      const shippingLine = !!rootState.crmEcommerce.shippingMethod
        ? {
            method_id: rootState.crmEcommerce.shippingMethod.method_id,
            method_title: rootState.crmEcommerce.shippingMethod.method_title,
            shipping_cost: rootState.crmEcommerce.shippingCost,
          }
        : null;
      try {
        const payload = {
          customer_id: customerId,
          billing_address: rootState.crmEcommerce.customerData?.billing_address,
          shipping_address:
            rootState.crmEcommerce.customerData?.shipping_address,
          shipping_line: shippingLine,
          discount_type: rootState.crmEcommerce.shopifyDiscountType,
          discount_amount: rootState.crmEcommerce.shopifyDiscountAmount,
          apply_tax: rootState.crmEcommerce.applyTax,
        };
        const res = await axios.post(
          `${config.crmEcommerce.calculateDraftOrder(teamId)}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateFinalShopifyShippingData(
            res.data.dataSource
          );
          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data.error,
        };
      }
      return null;
    },
    async createShopifyDraftOrder({ teamId, customerId, ticketId }, rootState) {
      try {
        const payload = {
          customer_id: customerId,
          billing_address: rootState.crmEcommerce.customerData?.billing_address,
          shipping_address:
            rootState.crmEcommerce.customerData?.shipping_address,
          shipping_line: rootState.crmEcommerce.shippingMethod,
          ticket_id: ticketId,
          create_as: rootState.crmEcommerce.orderStatus,
          discount_type: rootState.crmEcommerce.shopifyDiscountType,
          discount_amount: rootState.crmEcommerce.shopifyDiscountAmount,
          apply_tax: rootState.crmEcommerce.applyTax,
          note: rootState.crmEcommerce.orderNote,
        };
        const res = await axios.post(
          config.crmEcommerce.createOrder(teamId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          return res.data;
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },

    async fetchShopifyProductVariant(payload) {
      try {
        const res = await axios.get(
          `${config.crmEcommerce.variant(payload.teamId, payload.productId)}`
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateShopifyProductVariantList({
            productId: payload?.productId,
            dataSource: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchWoocommerceCoupons({ projectId }) {
      try {
        const res = await axios.get(
          config.crmEcommerce.getWocommerceCoupons(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateWoocommerceDiscountCoupons(
            res.data.dataSource
          );
        } else {
          dispatch.crmEcommerce.updateWoocommerceDiscountCoupons([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crmEcommerce.updateWoocommerceDiscountCoupons([]);
      }
    },
    async calculateUpdateOrderBegin({ teamId, orderId }) {
      const payload = {
        action_type: 'begin',
      };
      try {
        const res = await axios.post(
          `${config.crmEcommerce.calculateUpdateOrder(teamId, orderId)}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateShopifyUpdateOrderCalculation(
            res.data.dataSource
          );
          return res.data;
        } else {
          return { success: false, error: res.data?.error, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },
    async calculateUpdateOrderEnd({ customerId, orderId }, rootState) {
      const getModifiedCalculatedProducts = () => {
        let visibleCalculatedProducts = cloneDeep(
          rootState.crmEcommerce.customerData.visibleCalculatedProducts
        );
        let calculatedProducts = cloneDeep(
          rootState.crmEcommerce?.customerData?.calculatedProducts
        );
        let modifiedCalculatedProducts = calculatedProducts?.filter(
          (product) => {
            let visible = false;
            for (let [
              index,
              modifiedProduct,
            ] of visibleCalculatedProducts.entries()) {
              if (
                product.product_calculation_id ===
                modifiedProduct.product_calculation_id
              ) {
                if (product.quantity !== modifiedProduct.quantity) {
                  product.quantity = modifiedProduct.quantity;
                  product.product_action_type = 'update_product';
                }
                visibleCalculatedProducts.splice(index, 1);
                visible = true;
                break;
              }
            }
            //if not visible, it's removed
            if (!visible && product.quantity !== 0) {
              product.quantity = 0;
              product.product_action_type = 'update_product';
            }
            return product;
          }
        );

        //rest of the visible items are newly added products
        if (visibleCalculatedProducts?.length > 0) {
          visibleCalculatedProducts.forEach((product) => {
            product.product_action_type = 'add_product';
            modifiedCalculatedProducts?.push(product);
          });
        }

        return modifiedCalculatedProducts;
      };

      let modifiedCalculatedProducts = getModifiedCalculatedProducts();
      const payload = {
        action_type: rootState.crmEcommerce.customerData.calculationActionType,
        ecommerce_order_calculation_id:
          rootState.crmEcommerce.customerData.ecommerceOrderCalculationId,
        products_updated:
          rootState.crmEcommerce.customerData.areProductsUpdated,
        products: modifiedCalculatedProducts,
        total_cost: rootState.crmEcommerce.customerData.totalCost,
        subtotal_cost: rootState.crmEcommerce.customerData.subtotalCost,
        total_tax: rootState.crmEcommerce.customerData.totalTax,
        note: rootState.crmEcommerce.orderNote,
      };
      try {
        const res = await axios.post(
          `${config.crmEcommerce.calculateUpdateOrder(customerId, orderId)}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateShopifyUpdateOrderCalculation(
            res.data.dataSource
          );
          return res.data;
        } else {
          return { success: false, error: res?.data?.error, dataSource: null };
        }
      } catch (err) {
        console.log(err.response);
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },
    async updateShopifyOrder({ teamId, customerId, orderId }, rootState) {
      const payload = {
        ecommerce_order_calculation_id:
          rootState.crmEcommerce.customerData.ecommerceOrderCalculationId,
        products_updated:
          rootState.crmEcommerce?.customerData?.areProductsUpdated,
        products: rootState.crmEcommerce?.customerData?.calculatedProducts,
        shipping_address:
          rootState.crmEcommerce?.customerData?.shipping_address,
        email: rootState.crmEcommerce.customerData?.shipping_address?.email,
        customer_id: customerId,
        note: rootState.crmEcommerce.orderNote,
      };
      try {
        const res = await axios.post(
          `${config.crmEcommerce.shopifyUpdateOrder(teamId, orderId)}`,
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crmEcommerce.updateOrderData(res.data.dataSource);
          return res.data;
        } else {
          return { success: false, error: res.data.error, dataSource: null };
        }
      } catch (err) {
        console.log(err);
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },
    async recommendProducts(customerId, rootState) {
      try {
        const res = await axios.post(
          config.crmEcommerce.recommendProducts(customerId),
          {
            products: rootState.crmEcommerce.selectedProductData,
          }
        );
        return res.status === 200 && res.data.success;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
  }),
};
