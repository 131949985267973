import React from 'react';
import EmptyView from '../common/EmptyView';
import useTranslation from 'components/customHooks/useTranslation';
import { IAgentStats } from '../interface';
import { TableHearderForAgentTicketData } from '../utilities/contents';
import TextWithTooltip from 'library/text';

interface Props {
  loading: boolean;
  agentData: IAgentStats | null;
}

const TicketTableView: React.FC<Props> = ({ loading, agentData }) => {
  const { t } = useTranslation();
  const renderLoadingView = () => {
    return (
      <tbody className='bg-white divide-y divide-gray-200 animate-pulse'>
        {[...Array(4)].map((_, index) => (
          <tr key={index}>
            <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6'>
              <div className='w-2/3 h-4 rounded bg-slate-200' />
            </td>
            <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
              <div className='w-5 h-4 rounded bg-slate-200' />
            </td>
            <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
              <div className='w-5 h-4 rounded bg-slate-200' />
            </td>
            <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>
              <div className='w-5 h-4 rounded bg-slate-200' />
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const emptyView = () => {
    return <EmptyView msg={t('No ticket available')} />;
  };

  const getStatusString = (status: string) => {
    switch (status?.toLowerCase()) {
      case 'online':
        return 'Active';
      case 'away':
      case 'offline':
        return 'Away';
      default:
        return status;
    }
  };

  const mainView = () => {
    if (!agentData || agentData?.agents?.length < 1) {
      return emptyView();
    } else
      return (
        <div className='max-h-[375px] overflow-y-auto'>
          <table className='min-w-full mb-2 divide-y divide-gray-300 '>
            <thead className='bg-gray-50'>
              <tr>
                {TableHearderForAgentTicketData.map(
                  (value: string, index: number) => (
                    <th
                      scope='col'
                      key={index}
                      className='px-6 py-3 text-xs font-medium text-gray-500 ltr:text-left rtl:text-right'
                    >
                      {t(value).toUpperCase()}
                    </th>
                  )
                )}
              </tr>
            </thead>
            {loading && renderLoadingView()}
            {!loading && (
              <tbody className='bg-white divide-y divide-gray-200 '>
                {agentData?.agents.map((agent, index) => (
                  <tr key={index}>
                    <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right sm:pl-6'>
                      <TextWithTooltip
                        text={agent?.agent_name}
                        dataFor={agent?.agent_name + '-' + index}
                        dataTip={agent?.agent_name}
                        className='text-gray-900'
                      />
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-600 whitespace-nowrap rtl:text-right'>
                      <div
                        className={`inline-block h-1 w-1 p-1 rounded-full ${
                          agent?.agent_status === 'online'
                            ? 'bg-primary'
                            : 'bg-gray-400'
                        } ltr:mr-2 rtl:ml-2`}
                      />
                      {t(getStatusString(agent?.agent_status || ''))}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-600 whitespace-nowrap rtl:text-right'>
                      {agent?.assigned}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-600 whitespace-nowrap rtl:text-right'>
                      {agent?.unreplied}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      );
  };

  return (
    <div className='bg-white divide-y rounded-lg shadow'>
      <div className='px-6 py-4'>
        <span className='text-base font-medium text-[#111827]'>
          {t('Agents with Tickets')}{' '}
          {!!agentData?.online_count ? `(${agentData?.online_count})` : ''}
        </span>
        <span className='block mt-1 text-xs font-normal text-gray-600'>
          {t('Report of agents current activity and status.')}
        </span>
      </div>
      <div className='overflow-hidden rounded-b-lg sm:rounded-b-lg'>
        {mainView()}
      </div>
    </div>
  );
};

export default TicketTableView;
