import React from 'react';
import HeatMap from 'react-heatmap-grid';
import { Spinner } from 'evergreen-ui';
import { analytics } from '../../../../utilities/content';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';

const CustomerHeatmapGrid = ({ data, loader, toolTipInfo, isRTL }) => {
  const xLabels = new Array(24).fill('h').map((_, i) => `${i + 1}:00`);
  const xLabelsVisibility = new Array(24)
    .fill(0)
    .map((_, i) => (i % 2 === 0 ? true : false));
  const yLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const yArabicLabel = [
    'الأحد',
    'الإثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ];

  return (
    <div className='relative px-4 py-5 m-3 mb-6 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
      <div
        className='flex text-base font-normal text-gray-900'
        aria-label='Title'
      >
        <TranslationWrapper
          text={analytics.customerMetrics.customerHeatmapGrid.title}
        />

        {!!toolTipInfo ? (
          <span className='mt-1 ltr:ml-2 rtl:mr-2' title={toolTipInfo}>
            <InformationCircleIcon className='w-4 h-4' />
          </span>
        ) : (
          ''
        )}
      </div>
      <div className='text-sm font-medium text-gray-500' aria-label='Sub Title'>
        <TranslationWrapper
          text={analytics.customerMetrics.customerHeatmapGrid.subTitle}
        />
      </div>
      <br />
      <div className='-ml-3'>
        {loader ? (
          <div className='flex justify-center align-center h-200px'>
            <Spinner />
          </div>
        ) : (
          !!data && (
            <HeatMap
              xLabels={xLabels}
              xLabelsVisibility={xLabelsVisibility}
              yLabels={isRTL ? yArabicLabel : yLabels}
              data={data}
              cellStyle={(background, value, min, max, data, x, y) => ({
                background: `rgba(4, 178, 95, ${
                  1 - (max - value) / (max - min)
                })`,
                fontSize: '11px',
              })}
              cellRender={(value) => value && `${value}`}
              show
              yLabelWidth={50}
              xLabelsLocation={'bottom'}
              xLabelWidth={50}
              title={(value) => `Value: ${value}`}
            />
          )
        )}
      </div>
    </div>
  );
};
export default CustomerHeatmapGrid;
