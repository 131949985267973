import React, { useEffect } from 'react';
import Button from '../../../../../library/button';
import TicketFilterDropDown from './TicketFilterDropDown';
import DateTimeSelect from '../../../../../utilities/DateTimeSelect';

import { Fragment } from 'react';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  filterElement,
  filterElementsForBotQueue,
} from '../../../utils/contents';
import { Popover, Transition } from '@headlessui/react';
import NewAlertModal from 'library/modal/NewAlertModal';
import { ticketFilterDefaultValue } from '../../../utils/contents';
import { FilterBarsIcon } from 'pages/inbox/assets/iconComponent/FilterIcon';
import SaveFilter from './SaveFilter';
import { classNames, messengerChannels } from '../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import useBulkAction from 'pages/inbox/hooks/useBulkAction';
import {
  ISavedFilterData,
  PreviousSavedFilterTrack,
  TicketFilterInterface,
  TicketTagInterface,
} from 'pages/inbox/inboxInterface';
import { PlatformInterface } from 'index';

interface Props {
  projectId: number;
  hasFilterApplied: boolean;
  selectedQueueType: string;
  agentList: AgentInterface[];
  agentGroup: GroupInterface[];
  hasSupervisorAccess: boolean;
  channelList: PlatformInterface[];
  savedFilterList: ISavedFilterData[];
  allTicketTags: TicketTagInterface[];
  ticketFilterData: TicketFilterInterface;
  previousFilterData: PreviousSavedFilterTrack;
  updateSateData: (key: string, value: UpdateStateDataType) => void;
  handleApplyOrClearFilter: (filterData: TicketFilterInterface) => void;
  updateSavedFilterData: (
    projectid: number,
    filterList: ISavedFilterData[]
  ) => void;
}

const TicketQueue: React.FC<Props> = ({
  agentList,
  projectId,
  agentGroup,
  channelList,
  allTicketTags,
  updateSateData,
  savedFilterList,
  hasFilterApplied,
  ticketFilterData,
  selectedQueueType,
  previousFilterData,
  hasSupervisorAccess,
  updateSavedFilterData,
  handleApplyOrClearFilter,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openDeleteModal, setDeleteModal] = React.useState(false);
  const [isSaveViewOpen, setSaveViewOpen] = React.useState(false);
  const [focusedField, setFocusedField] = React.useState('');
  const [filterData, setFilterData] = React.useState(ticketFilterData);
  const [isDateOptionOpened, setIsDateOptionOpened] = React.useState(false);

  const { isBulkActionActive } = useBulkAction();

  useEffect(() => {
    setFilterData(ticketFilterData);
  }, [ticketFilterData]);

  const getFilterElement = () => {
    if (selectedQueueType.toLowerCase() === 'bot') {
      return filterElementsForBotQueue;
    } else if (hasSupervisorAccess) return filterElement;
    else
      return filterElement?.filter(
        (element) => element.type !== 'groups' && element.type !== 'agents'
      );
  };

  const getChannelFilters = () => {
    if (selectedQueueType.toLowerCase() === 'bot') {
      return channelList.filter((platform) =>
        messengerChannels.includes(platform.platform_type)
      );
    } else return channelList ?? [];
  };

  const handleFilterData = (updatedFilterData: TicketFilterInterface) => {
    setFilterData(updatedFilterData);
  };

  const handleFilterStoreModification = async (
    filterList: ISavedFilterData[],
    filterName: string,
    type: string
  ) => {
    await updateSavedFilterData(projectId, filterList);
    updateSateData('ticketQueue', {
      type: filterName === '' ? 'all' : 'private',
      isResolved: false,
      privateView: filterName,
    });

    if (type === 'update') {
      updateSateData('previouslySavedFilterData', {
        oldData: ticketFilterData,
        updateAble: true,
        indexOfSavedData: -1,
      });
    }

    if (filterName !== '') handleApplyOrClearFilter(filterData);
    setSaveViewOpen(false);
  };

  const isPreviousSaveDataChange = () => {
    return (
      JSON.stringify(previousFilterData?.oldData) !== JSON.stringify(filterData)
    );
  };

  let isDeleteButtonShow = () =>
    previousFilterData?.updateAble &&
    JSON.stringify(ticketFilterData) ===
      JSON.stringify(previousFilterData?.oldData);

  let isFilterApplied = !(previousFilterData?.updateAble
    ? JSON.stringify(ticketFilterData) === JSON.stringify(filterData)
    : JSON.stringify(ticketFilterDefaultValue) === JSON.stringify(filterData));

  const validateFilterDateTimeOnSave = (filterData: TicketFilterInterface) => {
    const { startDate, endDate } = filterData;
    if (startDate.length !== 0 && endDate.length !== 0) {
      let isValid = new Date(startDate) <= new Date(endDate);
      if (isValid) return true;
      else {
        toaster.danger(
          'End date time must be greater than the start date time'
        );
        return false;
      }
    }
    return true;
  };

  const clearFilterData = () => {
    setIsOpen(false);
    updateSateData('ticketFilterData', ticketFilterDefaultValue);
    setFilterData(ticketFilterDefaultValue);
    handleApplyOrClearFilter(ticketFilterDefaultValue);
    updateSateData('previouslySavedFilterData', {
      oldData: ticketFilterDefaultValue,
      updateAble: false,
      indexOfSavedData: -1,
    });
  };

  const deletePrivateView = () => {
    const updatedList = [
      ...savedFilterList?.filter(
        (data, index) => index !== previousFilterData?.indexOfSavedData
      ),
    ];

    handleFilterStoreModification(updatedList, '', 'delete');
    clearFilterData();
  };

  const handleFilterButtonClick = () => {
    if (previousFilterData?.updateAble) {
      setFilterData(previousFilterData?.oldData);
    }
    setIsOpen(!isOpen);
    setSaveViewOpen(false);

    if (isBulkActionActive) {
      toaster.warning('Applying filters may clear your current selection.');
    }
  };

  return (
    <>
      <NewAlertModal
        isShown={openDeleteModal}
        intent='danger'
        title={t('Delete Saved View?')}
        description={t(
          'You are about to delete a saved view. This action cannot be undone.'
        )}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={t('Delete')}
        onToggle={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          setDeleteModal(false);
          deletePrivateView();
        }}
        onCancelComplete={() => {
          setDeleteModal(false);
        }}
        onClose={() => {
          setDeleteModal(false);
        }}
      />
      <Popover as='div' className='relative w-full'>
        {({ open, close }) => (
          <>
            <Popover.Button
              onClick={() => handleFilterButtonClick()}
              className='w-full focus:ring-0 focus:ring-offset-0'
            >
              <div className='flex justify-end ltr:pr-4 rtl:pl-4 py-1.5 text-gray-500 bg-gray-50 ltr:border-r-1 rtl:border-l border-b-2 border-solid border-gray-100 w-full focus:ring-0 focus:ring-offset-0'>
                <div className='inline-flex gap-2'>
                  <FilterBarsIcon
                    className='inline-block float-left w-5 h-5'
                    color={hasFilterApplied ? '#04B25F' : '#6B7280'}
                  />
                  <span
                    className={classNames(
                      'float-right font-medium',
                      hasFilterApplied ? 'text-green-500 ' : 'text-gray-500'
                    )}
                  >
                    {hasFilterApplied ? t('Filter Applied') : t('Filter')}
                  </span>
                </div>
              </div>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Popover.Panel className='absolute ltr:right-0 rtl:left-0 z-20 mt-2 origin-top-right bg-white rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='p-4'>
                  <div className='flex justify-between mb-4'>
                    <span className='text-base font-medium text-gray-700'>
                      {t('Filter By')}
                    </span>
                    <XMarkIcon
                      onClick={() => {
                        setIsOpen(false);
                        close();
                      }}
                      className='w-5 h-5 cursor-pointer'
                      aria-hidden='true'
                    />
                  </div>
                  {getFilterElement()?.map((element, index) =>
                    element.type !== 'date' ? (
                      <div className='my-2' key={index}>
                        <TicketFilterDropDown
                          option={element}
                          agentList={agentList ?? []}
                          filterData={filterData}
                          agentGroup={agentGroup}
                          tagList={allTicketTags}
                          channelList={getChannelFilters()}
                          focusedField={focusedField}
                          setFocusedField={setFocusedField}
                          handleFilterData={handleFilterData}
                          isDateOptionOpened={isDateOptionOpened}
                        />
                      </div>
                    ) : (
                      <>
                        <label className='block text-sm font-medium text-gray-600'>
                          {t('Date')}
                        </label>
                        <div
                          onClick={() =>
                            setIsDateOptionOpened(!isDateOptionOpened)
                          }
                        >
                          <DateTimeSelect
                            placeholder={t('From')}
                            showTimeSelect={true}
                            dateTimeFormat={'dd MMM yyyy'}
                            enableDefaultDate={false}
                            value={filterData?.startDate}
                            handleOnchange={(value: any) =>
                              setFilterData({ ...filterData, startDate: value })
                            }
                          />
                        </div>
                        <div
                          className='mt-1'
                          onClick={() =>
                            setIsDateOptionOpened(!isDateOptionOpened)
                          }
                        >
                          <DateTimeSelect
                            placeholder={t('To')}
                            showTimeSelect={true}
                            enableDefaultDate={false}
                            dateTimeFormat={'dd MMM yyyy'}
                            value={filterData?.endDate}
                            handleOnchange={(value: any) =>
                              setFilterData({ ...filterData, endDate: value })
                            }
                          />
                        </div>
                      </>
                    )
                  )}
                  {isFilterApplied && (
                    <div className='flex flex-col items-center justify-center mt-6'>
                      <Button
                        className={
                          ticketFilterData === filterData ? 'hidden' : 'block'
                        }
                        intent='primary'
                        isFullWidth={true}
                        onClick={() => {
                          let isValid =
                            validateFilterDateTimeOnSave(filterData);
                          if (isValid) {
                            close();
                            updateSateData('ticketFilterData', filterData);
                            handleApplyOrClearFilter(filterData);
                          }
                        }}
                      >
                        {t('Apply Filters')}
                      </Button>
                      <Button
                        isDisabled={false}
                        isFullWidth={true}
                        className={classNames(
                          'mt-2',
                          !previousFilterData?.updateAble ? 'block' : 'hidden'
                        )}
                        onClick={() => setSaveViewOpen(true)}
                      >
                        {t('Save as View')}
                      </Button>
                      <span
                        role={'button'}
                        onClick={() => {
                          close();
                          clearFilterData();
                        }}
                        className={classNames(
                          'text-red-600 mb-2 underline mt-6',
                          previousFilterData?.updateAble ? 'hidden' : 'block'
                        )}
                      >
                        {t('Clear Filters')}
                      </span>
                    </div>
                  )}

                  <Button
                    isDisabled={false}
                    isFullWidth={true}
                    className={classNames(
                      'mt-2',
                      previousFilterData?.updateAble &&
                        isPreviousSaveDataChange()
                        ? 'block'
                        : 'hidden'
                    )}
                    onClick={() => setSaveViewOpen(true)}
                  >
                    {t('Update Saved View')}
                  </Button>
                  <div className='inline-block w-full mt-5'>
                    <button
                      className={classNames(
                        `${isDeleteButtonShow() ? 'block' : 'hidden'}`,
                        !isPreviousSaveDataChange() ? 'mx-auto' : 'float-left'
                      )}
                      onClick={() => {
                        close();
                        setDeleteModal(true);
                      }}
                    >
                      <u className='text-gray-600 border-gray-600'>
                        {t('Delete')}
                      </u>
                    </button>

                    <button
                      onClick={() => {
                        updateSateData('ticketFilterData', {
                          ...previousFilterData?.oldData,
                        });
                      }}
                      className={classNames(
                        !isDeleteButtonShow() ? 'mx-auto' : 'float-right',
                        previousFilterData?.updateAble &&
                          isPreviousSaveDataChange()
                          ? 'block'
                          : 'hidden'
                      )}
                    >
                      <u className='text-gray-600 border-gray-600'>
                        {t('Revert Changes')}
                      </u>
                    </button>
                  </div>
                </div>

                {isSaveViewOpen && (
                  <SaveFilter
                    isUpdating={previousFilterData?.updateAble}
                    prevoiusData={
                      previousFilterData?.indexOfSavedData !== -1
                        ? savedFilterList[previousFilterData?.indexOfSavedData]
                        : null
                    }
                    savedFilterData={savedFilterList}
                    ticketFilterData={filterData}
                    updateSavedData={handleFilterStoreModification}
                    handleClose={() => setSaveViewOpen(false)}
                    close={close}
                  />
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

const mapState = (state: any) => ({
  agentList: state.inbox.agentList,
  agentGroup: state.inbox.agentGroup,
  allTicketTags: state.inbox.allTicketTags,
  channelList: state.dashboard.platformList,
  savedFilterList: state.inbox.savedFilterList,
  hasFilterApplied: state.inbox.hasFilterApplied,
  projectId: state.dashboard.selectedProject?.id,
  ticketFilterData: state.inbox.ticketFilterData,
  selectedQueueType: state.inbox.selectedQueueType,
  previousFilterData: state.inbox.previouslySavedFilterData,
});

const mapDispatch = (dispatch: any) => ({
  updateSateData: (key: string, value: UpdateStateDataType) =>
    dispatch.inbox.updateSateData({ key, value }),
  updateSavedFilterData: (projectId: number, filterList: ISavedFilterData[]) =>
    dispatch.inbox.modifyTicketFilterData({ projectId, filterList }),
});

export default connect(mapState, mapDispatch)(TicketQueue);
