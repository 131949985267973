import React from 'react';
import ProductSelection from './screens/ProductSelection';
import DeliveryInformation from './screens/DeliveryInformation';
import CreateOrderSummary from './screens/CreateOrderSummary';
import { connect } from 'react-redux';
import ConfirmOrder from './screens/ConfirmOrder';

interface SelectedProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

interface PaymentMethodProps {
  description: string;
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodProps {
  method_id: string;
  method_title: string;
  shipping_cost: number;
  source: string;
}

interface Prop {
  step: number;
  customerId: number;
  fetchCart: (customerId: number) => Promise<any>;
  confirmOrderAPI: () => Promise<any>;
  orderId?: string;
  fetchOrder: (teamId: number, orderId: string) => Promise<any>;
  projectId: number;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => Promise<void>;
  updateStateData: (key: string, value: any) => void;
  fetchPaymentMethods: (projectId: number) => Promise<PaymentMethodProps[]>;
  fetchShippingMethods: (projectId: number) => Promise<ShippingMethodProps[]>;
  fetchWoocommerceCoupons: (projectId: number) => Promise<void>;
}
//created wrapper to load mount and unmount api calls
const Wrapper: React.FC<Prop> = ({
  step,
  customerId,
  fetchCart,
  confirmOrderAPI,
  orderId,
  fetchOrder,
  projectId,
  updateCart,
  updateStateData,
  fetchPaymentMethods,
  fetchShippingMethods,
  fetchWoocommerceCoupons,
}) => {
  const getCurrentPaymentMethod = (updateOrderRawData: any, paymentMethodList: PaymentMethodProps[]) => {
    let idx = 0;
    paymentMethodList.forEach((method, index) => {
      if (method.method_id === updateOrderRawData?.payment_line?.method_id) {
        idx = index;
      }
    });
    return paymentMethodList[idx];
  };

  const getCurrentShippingMethod = (updateOrderRawData: any, shippingMethodList: ShippingMethodProps[]) => {
    let idx = 0;
    shippingMethodList.forEach((method, index) => {
      if (method.method_id === updateOrderRawData?.shipping_line?.method_id) {
        idx = index;
      }
    });
    return shippingMethodList[idx];
  };

  const fetchOrderData = async () => {
    let paymentMethodList = await fetchPaymentMethods(projectId);
    let shippingMethodList = await fetchShippingMethods(projectId);
    await fetchWoocommerceCoupons(projectId);
    const res = await fetchCart(customerId);
    if (res && orderId) {
      const res = !!orderId && (await fetchOrder(projectId, orderId));
      if (res?.success) {
        await updateCart(customerId, res.dataSource.products);
        updateStateData('selectedCouponCode', res.dataSource?.coupon_info?.length > 0 ? res.dataSource.coupon_info[0] : null);
        updateStateData('paymentMethod', getCurrentPaymentMethod(res.dataSource, paymentMethodList));
        updateStateData('shippingMethod', getCurrentShippingMethod(res.dataSource, shippingMethodList));
        updateStateData('shippingCost', res.dataSource?.shipping_line?.shipping_cost || null);
      }
    }
  };

  React.useEffect(() => {
    if (customerId) {
      fetchOrderData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {step === 1 && <ProductSelection customerId={customerId} />}
      {step === 2 && <DeliveryInformation />}
      {step === 3 && <CreateOrderSummary />}
      {step === 4 && <ConfirmOrder confirmOrderAPI={confirmOrderAPI} />}
    </>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
});

const mapDispatch = (dispatch: any) => ({
  fetchCart: (customerId: number) =>
    dispatch.crmEcommerce.fetchCart(customerId),
  fetchOrder: (projectId: number, orderId: string) =>
    dispatch.crmEcommerce.fetchOrder({
      projectId,
      orderId,
    }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
  fetchPaymentMethods: (projectId: number) =>
    dispatch.crmEcommerce.fetchPaymentMethods({ projectId }),
  fetchShippingMethods: (projectId: number) =>
    dispatch.crmEcommerce.fetchShippingMethods({ projectId }),
  fetchWoocommerceCoupons: (projectId: number) =>
    dispatch.crmEcommerce.fetchWoocommerceCoupons({ projectId }),
});

const WrapperContainer = connect(mapState, mapDispatch)(Wrapper);
export default WrapperContainer;
