import React from 'react';
import { Dialog } from 'evergreen-ui';
import { findAlertInfo } from '../../../utilities/utils';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { subscriptionDetailsType } from '../interface';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface Prop {
  subscriptionDetails: subscriptionDetailsType;
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: 'warning' | 'success' | 'info' | 'danger';
  title?: string | number;
  description: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onConfirmCallback?: () => void;
  onCancelCallback?: () => void;
  hasConfirmText?: boolean;
  confirmText?: 'CANCEL' | 'REMOVE' | 'REFUND';
}

const CancelPlanModal: React.FC<Prop> = ({
  subscriptionDetails,
  isShown,
  setShown,
  intent,
  title,
  description,
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  onConfirmCallback,
  onCancelCallback,
  hasConfirmText = false,
  confirmText,
}) => {
  const [confirmCheck, setConfirmCheck] = React.useState('');

  const handleConfirmAction = () => {
    if (hasConfirmText) {
      if (!!onConfirmCallback) {
        onConfirmCallback();
        setConfirmCheck('');
        setTimeout(() => setShown(false), 1000);
      }
    } else {
      if (!!onConfirmCallback) {
        onConfirmCallback();
        setTimeout(() => setShown(false), 1000);
      }
    }
  };
  return (
    <div className='flex p-4'>
      <Dialog
        width={344}
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        hasFooter={false}
        hasHeader={false}
        containerProps={{ className: 'rounded-lg' }}
        shouldCloseOnOverlayClick={false}
      >
        <div className='flex flex-col align-center text-center'>
          <img className='my-1 w-12 h-12' src={findAlertInfo(intent).icon} alt={'modal icon'} />
          <p className='text-gray-900 text-lg leading-6 font-medium my-2'>{title}</p>
          <p className='text-gray-500 text-sm font-normal mb-5'>{description}</p>
        </div>
        {hasConfirmText && (
          <>
            <input
              type='text'
              className={`${confirmCheck ? 'text-gray-900' : 'text-gray-500'} text-base font-normal
              rounded-md border border-gray-300 focus:ring-0 focus:border-primary w-full h-10 mb-3`}
              value={confirmCheck}
              placeholder={`Type ${confirmText} to proceed`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmCheck(e.target.value);
              }}
            />
          </>
        )}
        <button
          className={`text-base font-medium rounded-md w-full h-10 mb-3 ${
            confirmCheck?.toUpperCase() === confirmText 
              ? 'bg-red-500 text-white' 
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          disabled={confirmCheck?.toUpperCase() !== confirmText}
          onClick={() => handleConfirmAction()}
        >
          {confirmButtonTitle}
        </button>
        <button
          className='text-gray-600 text-base font-medium
          border border-gray-300 rounded-md w-full h-10 mb-5'
          onClick={() => {
            if (!!onCancelCallback) {
              onCancelCallback();
            }
          }}
        >
          {cancelButtonTitle}
        </button>
        {subscriptionDetails?.project_billing_info?.payment_handler === 'stripe' && (
          <div className='flex w-full h-[72px] bg-blue-50 p-4 rounded-md'>
            <InformationCircleIcon className='w-6 h-6 p-0.5 text-blue-400 bg-blue-50 rounded-3xl' />
            <p className='w-full text-blue-800 text-sm font-medium mx-3'>
              You may enjoy your current subscription until
              <>
                &nbsp;
                {dayjs(parseInt(
                  subscriptionDetails?.project_billing_info?.subscription_plan?.billing_end_date
                ) * 1000).format('DD MMMM YYYY')}
              </>.
            </p>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default CancelPlanModal;
