import { SelectedTeamInterface } from 'index';
import { IAddress } from 'pages/inbox/inboxInterface';
import React from 'react';
import { countryData } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  key: string;
  isBilling: boolean;
  billingInformation: IAddress;
  shippingInformation: IAddress;
  selectedTeam: SelectedTeamInterface;
  handleSetWooCommerceObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const OrderForm: React.FC<Props> = ({
  key,
  isBilling,
  selectedTeam,
  billingInformation,
  shippingInformation,
  handleSetWooCommerceObjectInformation,
}) => {
  const { t } = useTranslation();
  const currentState = isBilling ? billingInformation : shippingInformation;
  const renderInputValuesView = (
    inputName: string,
    labelName: string,
    placeHolder: string
  ) => {
    return (
      <div key={inputName} className='flex flex-col'>
        <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
          {t(labelName)}
          <span className='text-red-500'>*</span>
        </label>
        <input
          type={inputName === 'phone' ? 'number' : 'text'}
          className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
          placeholder={t(placeHolder)}
          name={inputName}
          value={currentState[inputName]}
          onChange={(e) => {
            handleSetWooCommerceObjectInformation({
              objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
              childObjectKeyName: e.target.name,
              value: e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const renderAddressFieldsView = () => {
    return (
      <div className='mb-3'>
        <p className='mb-3 capitalize text-gray-600 text-sm font-medium'>
          {t(isBilling ? 'Billing' : 'Shipping')} {t('Address')}
        </p>
        <div className='flex flex-col'>
          <input
            type='text'
            className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
            placeholder={t('Address')}
            name='address_one'
            value={currentState?.address_one}
            onChange={(e) => {
              handleSetWooCommerceObjectInformation({
                objectKeyName: isBilling
                  ? 'billing_address'
                  : 'shipping_address',
                childObjectKeyName: e.target.name,
                value: e.target.value,
              });
            }}
          />
          <input
            type='text'
            className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
            placeholder={t('Apt, suite, unit etc. (Optional)')}
            name='address_two'
            value={currentState?.address_two}
            onChange={(e) => {
              handleSetWooCommerceObjectInformation({
                objectKeyName: isBilling
                  ? 'billing_address'
                  : 'shipping_address',
                childObjectKeyName: e.target.name,
                value: e.target.value,
              });
            }}
          />
          <div className='flex gap-1 w-full'>
            <input
              type='text'
              className='w-1/3 rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
              placeholder={t('City')}
              name='city'
              value={currentState?.city}
              onChange={(e) => {
                handleSetWooCommerceObjectInformation({
                  objectKeyName: isBilling
                    ? 'billing_address'
                    : 'shipping_address',
                  childObjectKeyName: e.target.name,
                  value: e.target.value,
                });
              }}
            />
            <input
              type='text'
              className='w-1/3 rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
              placeholder={t('State')}
              name='state'
              value={currentState?.state}
              onChange={(e) => {
                handleSetWooCommerceObjectInformation({
                  objectKeyName: isBilling
                    ? 'billing_address'
                    : 'shipping_address',
                  childObjectKeyName: e.target.name,
                  value: e.target.value,
                });
              }}
            />
            <input
              type='text'
              className='w-1/3 rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
              placeholder={t('Zip')}
              name='postcode'
              value={currentState?.postcode}
              onChange={(e) => {
                handleSetWooCommerceObjectInformation({
                  objectKeyName: isBilling
                    ? 'billing_address'
                    : 'shipping_address',
                  childObjectKeyName: e.target.name,
                  value: e.target.value,
                });
              }}
            />
          </div>
          <select
            name='country'
            value={
              currentState?.country.toLowerCase() ||
              selectedTeam?.country.toLowerCase()
            }
            onChange={(e) => {
              handleSetWooCommerceObjectInformation({
                objectKeyName: isBilling
                  ? 'billing_address'
                  : 'shipping_address',
                childObjectKeyName: e.target.name,
                value: e.target.value,
              });
            }}
            className='block w-full ltr:px-3 rtl:px-8 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
          >
            {countryData.map((country: any, index: number) => (
              <option key={index} value={country.name.toLowerCase()}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div key={key}>
      <p className=' capitalize text-gray-900 text-sm font-medium pb-3'>
        {isBilling ? '' : t('Shipping Information')}
      </p>

      {renderInputValuesView('first_name', 'First name', 'ex: John')}

      {renderInputValuesView('last_name', 'last name', 'ex: Doe')}

      {renderInputValuesView('email', 'Email', 'ex: john@email.com')}

      {renderInputValuesView(
        'phone',
        'Phone Number',
        'Phone number with country code'
      )}

      {renderAddressFieldsView()}
    </div>
  );
};

export default OrderForm;
