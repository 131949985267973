import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import { PlatformInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  title: string;
  handleChange: (payload: any) => void;
  data: PlatformInterface[];
  type: string;
  channelDetails: {
    id: number | string;
    title: string;
    phone: number | string;
  };
}

const WhatsappDropdownInput: React.FC<Props> = ({
  title,
  handleChange,
  data,
  type,
  channelDetails,
}) => {
  const { t } = useTranslation();
  return (
    <Listbox value={data} onChange={handleChange}>
      {({ open }) => (
        <div className='mb-5 rtl:text-right'>
          <Listbox.Label className='text-sm text-gray-600 font-medium'>
            {t(title)}
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button
              className='relative w-full cursor-default rounded-md
            border border-gray-300 bg-white py-2 ltr:pl-3 rtl:pr-3 ltr:pr-10 rtl:pl-10 ltr:text-left rtl:text-right shadow-sm
            focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500 sm:text-sm'
            >
              {channelDetails.title && channelDetails.phone ? (
                <span className='block truncate'>
                  {channelDetails.title} ({channelDetails.phone})
                </span>
              ) : (
                t('Select your WhatsApp channel')
              )}

              <span className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2'>
                <ChevronDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className='absolute z-10 mt-1 max-h-40 w-full
              overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1
              ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {data.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-green-500' : 'text-gray-900',
                        'relative cursor-default select-none py-2 ltr:pl-3 rtl:pr-3 ltr:pr-9 rtl:pl-9'
                      )
                    }
                    value={{
                      id: item.id,
                      title: item.title,
                      phone: item.secondary_id,
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {item.title} ({item.name})
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default WhatsappDropdownInput;
