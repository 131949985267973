'use client';
import {
  cn,
  React,
  toast,
  Button,
  Search,
  Input,
  Command,
  Popover,
  PlusIcon,
  useFilter,
  useDatalab,
  Cross2Icon,
  FilterIcon,
  useSelector,
  ChevronDown,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  PopoverContent,
  PopoverTrigger,
  FilterSideSheet,
  Check as CheckIcon,
  ReactTable as Table,
  DataTableViewOptions,
  Plus,
} from '../../../export';
import { Upload, Download } from 'lucide-react';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  selectedRows?: any;
  renderDeleteButton?: () => React.ReactElement | null;
  setSearchKey: (searchKey: string) => void;
}

interface ExportStateProps {
  show: boolean;
  success: boolean;
  message: {
    title: string;
    description: string | React.ReactElement;
  };
}

export function DataTableToolbar<TData>({
  table,
  selectedRows,
  renderDeleteButton,
  setSearchKey,
}: DataTableToolbarProps<TData>) {
  const {
    pageChange,
    updatePageChange,
    storedFilterData = [],
    updateIsFilterState,
    fetchDatalabEntries,
    hasWriteAccess,
    fetchExportDatalabData,
    importDatalabFile,
    fetchDataLabInfo,
    dateRange,
    labId,
    lab_fields,
    updateIsSearchState,

    // filter actions
    appliedFilterView,
    updateAppliedFilterView,
    fetchDatalabFilterData,
  } = useDatalab();
  const { validateFilterFields } = useFilter();
  const [isFilterOpened, setIsFilterOpened] = React.useState<boolean>(false);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const [isSheetOpen, setIsSheetOpen] = React.useState(false);
  const [isOpenSaveFilter, setIsOpenSaveFilter] = React.useState(false);

  const hasSearchableField = lab_fields?.some(
    (field: { is_searchable: boolean }) => field.is_searchable === true
  );

  const [tableDatalabExportResponse, setTableDatalabExportResponse] =
    React.useState<ExportStateProps>({
      show: false,
      success: false,
      message: {
        title: '',
        description: '',
      },
    });

  const { email } = useSelector((state: any) => state?.auth);

  React.useEffect(() => {
    fetchDatalabFilterData();
    // eslint-disable-next-line
  }, []);

  const handleExportClick = async () => {
    const res = await fetchExportDatalabData(labId, dateRange);
    if (res) {
      setTableDatalabExportResponse({
        show: true,
        success: true,
        message: {
          title: 'Download In Process',
          description: (
            <span className='text-sm font-normal text-gray-500'>
              Your export is currently processing. When it is ready, you will
              receive an email at{' '}
              <span className='text-sm font-bold text-gray-700'>{email}</span>
            </span>
          ),
        },
      });
    } else {
      setTableDatalabExportResponse({
        show: true,
        success: false,
        message: {
          title: 'Failed',
          description: 'Failed to Export Data',
        },
      });
    }
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const success = await importDatalabFile(labId, file);
      if (success) {
        fetchDataLabInfo();
      }
    }
  };

  const handleNewDataClick = () => {
    setIsSheetOpen(true);
  };

  const toggleSheetOpen = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  const handleApplyFilter = (filter: any) => {
    if (!filter) {
      toast({
        //@ts-ignore
        title: <p className='text-red-500'>Filter not found</p>,
        description: `Please select a valid filter.`,
      });
      return;
    }

    const isValidated = validateFilterFields(filter.filter_options);
    if (!isValidated) {
      toast({
        //@ts-ignore
        title: <p className='text-red-500'>Could not apply filter</p>,
        description: `Please fill all the fields.`,
      });

      setIsOpenSaveFilter(false);
      updateAppliedFilterView(filter);
      setIsFilterOpened(true);
      return;
    }

    updateAppliedFilterView(filter);
    setIsOpenSaveFilter(false);

    (async () => {
      const response = await fetchDatalabEntries(filter.filter_options, {
        ...pageChange,
        offset: 0,
      });
      if (response) {
        updateIsFilterState(true);
      }
    })();
  };

  const toggleSaveFilterOpen = () => {
    setIsOpenSaveFilter(!isOpenSaveFilter);
  };

  const handleOnClearFilter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    updateAppliedFilterView(undefined);
    updatePageChange(pageChange.limit, 0);
    fetchDatalabEntries();
  };

  const toggleFilterSideSheetOpen = () => {
    setIsFilterOpened(!isFilterOpened);
  };

  return (
    <>
      <div className='flex justify-between'>
        <div className='flex items-end flex-1 space-x-2'>
          {/* Search option will be enabled when backend API is ready */}
          {hasSearchableField && (
            <EntrySearch
              handleEntrySearch={(search) => {
                updatePageChange(pageChange.limit, 0);
                updateIsSearchState(search.length !== 0);
                fetchDatalabEntries(
                  [],
                  { limit: pageChange.limit, offset: 0 },
                  search
                );
                setSearchKey(search);
              }}
            />
          )}
          {/* <div className='relative flex items-center'>
            <Search className='absolute w-4 h-4 text-gray-500 left-2 mt-[6px]' />
            <Input
              placeholder='Search'
              value={(table.getState().globalFilter as string) ?? ''}
              onChange={(event) => table.setGlobalFilter(event.target.value)}
              className='h-8 w-[150px] lg:w-[246px] pl-8'
            />
          </div> */}

          <div className='flex items-center'>
            <Popover
              open={isOpenSaveFilter}
              modal={true}
              onOpenChange={toggleSaveFilterOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  type='button'
                  variant='outline'
                  role='combobox'
                  aria-expanded={isOpenSaveFilter}
                  className={`w-full justify-between font-normal bg-white  h-[32px] ${
                    isOpenSaveFilter ? 'border border-primary' : ''
                  }`}
                >
                  {appliedFilterView?.title && (
                    <Cross2Icon
                      className='w-4 h-4 mr-1 text-[#18181B] font-semibold hover:cursor-pointer'
                      onClick={handleOnClearFilter}
                    />
                  )}

                  <span className='text-muted-foreground'>
                    {appliedFilterView?.title || 'Select Filtered View'}
                  </span>

                  <span
                    className='flex items-center ml-2 h-[33px] border-l pl-[10px]'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '10px',
                      paddingLeft: '10px',
                      height: '33px',
                      borderLeft: '1px solid #E5E5E5',
                    }}
                  >
                    <ChevronDown className='w-4 h-4 opacity-50 shrink-0' />
                  </span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'>
                <Command>
                  <CommandInput
                    placeholder='Search...'
                    className='border-none h-9 focus:ring-0'
                  />

                  <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
                    <CommandEmpty>No data found.</CommandEmpty>
                    <CommandGroup>
                      {storedFilterData.map((filter: any, index: number) => (
                        <CommandItem
                          key={`${filter.id}-${index}`}
                          value={filter.id}
                          onSelect={() => handleApplyFilter(filter)}
                          className='hover:bg-primary hover:text-white'
                        >
                          <CheckIcon
                            className={cn(
                              'mr-2 h-4 w-4 text-primary',
                              filter?.id === appliedFilterView?.id
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                          {filter.title}
                        </CommandItem>
                      ))}
                      <div className='bg-white'>
                        <CommandItem
                          onSelect={() => {
                            updateAppliedFilterView(undefined);
                            updatePageChange(pageChange.limit, 0);
                            fetchDatalabEntries();
                            setIsFilterOpened(!isFilterOpened);
                          }}
                          className='hover:bg-background-hover hover:text-black text-center cursor-pointer'
                        >
                          <Plus className='mr-2 h-4 w-4 ' />
                          Create Filtered View
                        </CommandItem>
                      </div>
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <Button
            className={`${
              !!appliedFilterView &&
              appliedFilterView?.filter_options.length !== 0
                ? 'text-[#04B25F]'
                : ''
            }  h-[32px]`}
            variant='outline'
            onClick={() => setIsFilterOpened(!isFilterOpened)}
          >
            <FilterIcon className='w-[14px] h-[14px] mr-2' />{' '}
            {!!appliedFilterView &&
            appliedFilterView?.filter_options.length !== 0
              ? 'Filter Applied'
              : 'Filter'}
          </Button>
          {!!appliedFilterView &&
            appliedFilterView?.filter_options.length !== 0 && (
              <Button
                className={'h-8 bg-background-container border-none'}
                variant='outline'
                onClick={handleOnClearFilter}
              >
                <Cross2Icon className='w-[14px] h-[14px] mr-2' /> Clear Filter
              </Button>
            )}

          <FilterSideSheet
            isOpen={isFilterOpened}
            onApplyFilter={handleApplyFilter}
            onClose={toggleFilterSideSheetOpen}
          />
        </div>
        <div className='flex items-end space-x-2'>
          <div>
            <input
              type='file'
              accept='.csv'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              size='sm'
              variant='outline'
              disabled={!hasWriteAccess}
              onClick={handleImportClick}
              className={
                hasWriteAccess ? 'flex gap-2' : 'cursor-not-allowed flex gap-2'
              }
            >
              <Upload className='w-3.5 h-3.5' />
              <span className='font-medium text-xs text-textPrimary leading-5'>
                Import
              </span>
            </Button>
          </div>
          <Button
            size='sm'
            variant='outline'
            className='flex gap-2'
            onClick={handleExportClick}
          >
            <Download className='w-3.5 h-3.5' />
            <span className='font-medium text-xs text-textPrimary leading-5'>
              Export
            </span>
          </Button>
          {selectedRows?.length ? (
            renderDeleteButton?.()
          ) : (
            <div>
              <Button
                size='sm'
                onClick={handleNewDataClick}
                disabled={!hasWriteAccess}
                className={
                  hasWriteAccess
                    ? 'flex gap-2'
                    : 'cursor-not-allowed flex gap-2'
                }
              >
                <PlusIcon className='w-4 h-4 text-white' stroke='2' />
                <span className='text-white'>Create New Entry</span>
              </Button>
            </div>
          )}
        </div>
      </div>

      <DataTableViewOptions
        table={table}
        isSheetOpen={isSheetOpen}
        onApplyFilter={handleApplyFilter}
        toggleSheetOpen={toggleSheetOpen}
        tableDatalabExportResponse={tableDatalabExportResponse}
        setTableDatalabExportResponse={setTableDatalabExportResponse}
      />
    </>
  );
}

const EntrySearch = ({
  handleEntrySearch,
}: {
  handleEntrySearch: (name: string) => any;
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [debounceTimeout, setDebounceTimeout] =
    React.useState<NodeJS.Timeout | null>(null);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Clear the previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout
    const newTimeout = setTimeout(async () => {
      try {
        await handleEntrySearch(value);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }, 800); // 800ms debounce delay

    setDebounceTimeout(newTimeout);
  };

  return (
    <div className='relative flex items-center'>
      <Search className='absolute w-4 h-4 text-gray-500 left-2 top-3.5' />
      <Input
        placeholder='Search'
        value={searchTerm}
        onChange={handleSearchInputChange}
        className='h-8 w-[150px] lg:w-[250px] pl-8 rounded-lg'
      />
    </div>
  );
};
