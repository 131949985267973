import React, { useState } from 'react';
import BlockContainer from './BlockContainer';

import DataLabIcon from '../../../old/pages/builder/assets/images/icons/datalab-icon.svg';
import { PlusIcon } from '@heroicons/react/20/solid';
import EmptyBoxIcon from '../../assets/images/emptyBoxIcon.svg';
import { Button, Link } from '../../../library';
import { navigate } from '@reach/router';
import DataLabBlockDropDown from '../elements/dataLabBlock/DataLabBlockDropDown';
import DataLabBlockFieldInput from '../elements/dataLabBlock/DataLabBlockFieldInput';

interface Props {
  id: number;
  data: dataProps;
  labsData: labsDataProps[];
  save: boolean;
  attributeList: string[];
  sequences: sequenceProps;
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  selectedProjectId: number;
  handleSelectedLabData: (labId: number, blockId: number) => void;
  updateFieldAttribute: (data: object) => void;
}

interface dataProps {
  mappings: mappingProps[];
  lab: { id: number; title: string; key_count: number };
  lab_id: number;
}

interface mappingProps {
  id: number;
  name: string;
  attribute: string;
}

interface labsDataProps {
  id: number;
  title: string;
  type: string;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const DataLabEntryBlock: React.FC<Props> = ({
  id,
  data,
  labsData,
  save,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  handleSelectedLabData,
  updateFieldAttribute,
  selectedProjectId,
}) => {
  const [selectedLab, setSelectedLab] = useState(data.lab);
  const [showAttributeField, setShowAttributeField] = useState(null);

  const handleAttributePopOver = (val: any) => {
    setShowAttributeField(val);
  };

  const handleFieldAttributeChange = (id: any, value: string) => {
    if (value.length < 3) setShowAttributeField(id);
    else setShowAttributeField(null);
    updateFieldAttribute({ id: id, attribute: value });
  };

  return (
    <BlockContainer
      id={id}
      title='DATALAB ENTRY BLOCK'
      subtitle='Datalab entry block helps you to store your customer inputs directly in your datalab sheets.'
      save={save}
      isLoading={saveLoader}
      icon={DataLabIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <React.Fragment>
        <div className='flex'>
          <div className='flex-1'>
            <DataLabBlockDropDown
              id={id}
              selectedLab={selectedLab}
              setSelectedLab={(lab) => setSelectedLab(lab)}
              labsData={labsData}
              handleSelectedLabData={handleSelectedLabData}
              hasWriteAccess={hasWriteAccess}
            />
          </div>
          <div className='h-10 mt-1 ml-2.5'>
            <Button
              className={
                'border border-gray-300 text-gray-700 hover:border-gray-400'
              }
              icon={<PlusIcon />}
              onClick={() =>
                navigate(`/projects/${selectedProjectId}/data-lab`)
              }
            >
              Create New Sheet
            </Button>
          </div>
        </div>
        <br />
        {!!data.mappings && data.mappings.length !== 0 ? (
          <DataLabBlockFieldInput
            data={data}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttributeField={showAttributeField}
            setShowAttributeField={handleAttributePopOver}
            handleFieldAttributeChange={handleFieldAttributeChange}
          />
        ) : (
          <>
            {!!selectedLab && selectedLab?.key_count === 0 ? (
              <div className='text-center'>
                <img
                  className='mx-auto my-2.5'
                  src={EmptyBoxIcon}
                  alt={'empty-box'}
                />
                <span className='mx-auto my-2.5 text-gray-500 font-medium'>
                  No keys are available in this datalab sheet.
                </span>
                <p className='mx-auto'>
                  <Link url={`data-lab/edit/${selectedLab?.id}`}>
                    Add Keys Here
                  </Link>
                </p>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </React.Fragment>
    </BlockContainer>
  );
};

DataLabEntryBlock.defaultProps = {
  hasWriteAccess: false,
};
export default DataLabEntryBlock;
