import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import {
  CalendarClock,
  Clock8,
  Earth,
  Globe,
  Mail,
  MapPin,
  MonitorSmartphone,
  MousePointerClick,
  Phone,
  Ticket,
  User,
  Users,
} from 'lucide-react';
import {
  CustomerInfoInterface,
  CustomerInfoPayloadInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { truncateString } from 'pages/inbox/utils/functions';
import { validateEmail } from 'pages/inbox/utils/validEmail';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  ArrowTopRightOnSquareIcon,
  InstagramIcon,
  WhatsappIcon,
} from '../../../assets/iconComponent/CustomerInformationIconSet';
import CopyText from './CopyText';
interface IContactInfo {
  id: string;
  icon: JSX.Element;
  value: string;
  toolTip: string;
  shouldRender: boolean;
  showCopyButton?: boolean;
}

interface initialInformationInterface {
  full_name: string;
  email: string;
  phone: string | number;
}

interface Props {
  customerInfo: CustomerInfoInterface;
  selectedTicket: TicketInterface;
  conversationData: any;
  updateCustomerInformation: (
    payload: CustomerInfoPayloadInterface
  ) => Promise<boolean>;
}

const editInputClass = `border-0 text-[#374151] font-normal text-sm leading-5 p-0 m-0 w-full outline-none h-[30px] focus:outline-none focus:ring-0 focus:bg-transparent hover: bg-transparent`;

const CustomerInformation: React.FC<Props> = ({
  customerInfo,
  selectedTicket,
  conversationData,
  updateCustomerInformation,
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = React.useState(false);
  const initialEditableData = {
    full_name: (!!customerInfo && customerInfo['full_name']) || '',
    email: (!!customerInfo && customerInfo['email']) || '',
    phone: (!!customerInfo && customerInfo['phone']) || '',
  };
  const [showableInformation, setShowableInformation] =
    React.useState<initialInformationInterface>(initialEditableData);
  const [editedData, setEditedData] = React.useState<string | null>(null);

  const ref = React.useRef({
    phone: null,
    email: null,
    full_name: null,
  });

  const getCopyIcon = (informationName: string) => {
    let shoudRenderCopyIcon =
      !!showableInformation &&
      informationName !== 'full_name' &&
      showableInformation[informationName].length !== 0 &&
      initialEditableData[informationName] ===
        showableInformation[informationName];

    return (
      shoudRenderCopyIcon && (
        <div className='flex justify-end flex-shrink-0 bg-transparent'>
          <CopyText
            textToCopy={(!!customerInfo && customerInfo[informationName]) || ''}
            height={13}
          />
        </div>
      )
    );
  };

  const showInstagramIcon = (): boolean => {
    return (
      !!customerInfo &&
      !!customerInfo.secondary_id &&
      ['instagram_messenger', 'instagram_feed'].includes(
        customerInfo.platform?.type
      )
    );
  };

  const getInstagramIcon = () => {
    return (
      <a
        className='flex items-center justify-between px-3 ml-1 py-1.5 cursor-pointer hover:bg-gray-200'
        target='_blank'
        href={`https://instagram.com/${customerInfo.secondary_id}`}
        rel='noreferrer noopener'
      >
        <TooltipProviderCustomised content={t('Instagram ID')}>
          <InstagramIcon />
        </TooltipProviderCustomised>
        <div className='flex w-[95%] items-center justify-between'>
          <div className='ltr:pl-4 rtl:pr-4 font-normal text-sm leading-5 text-gray-900 h-auto'>
            {customerInfo.secondary_id}
          </div>
          <div className='pr-0.5'>
            <ArrowTopRightOnSquareIcon />
          </div>
        </div>
      </a>
    );
  };

  const getSaveButton = (informationName: string) => {
    let isValidInput: boolean | null = true;

    if (informationName === 'email') {
      isValidInput = validateEmail(showableInformation['email']);
    }
    return (
      isValidInput &&
      editedData === informationName &&
      initialEditableData[informationName] !==
        showableInformation[informationName] && (
        <div
          className={`text-[#0078CF] w-[10%] text-xs cursor-pointer m-auto text-center`}
          onClick={() => {
            handleCustomerInformationUpdate(informationName);
            setEditedData(null);
          }}
        >
          save
        </div>
      )
    );
  };

  const handleCustomerInformationUpdate = async (informationName: string) => {
    let postData = { ...showableInformation };
    const response = await updateCustomerInformation({
      data: postData,
      customer_id: selectedTicket?.customer_id,
    });

    if (response) {
      toaster.success('The customer information is successfully saved');
    } else {
      toaster.danger('The customer information is unable to be saved');
    }
    // @ts-ignore
    ref.current[informationName].blur();
  };

  const getInformations = (informationName: string) => {
    switch (informationName) {
      case 'full_name':
        return {
          icon: <User height={18} width={18} className='text-gray-400' />,
          placeholder: t('Add full name'),
          label: 'Full Name',
        };
      case 'email':
        return {
          icon: <Mail height={18} width={18} className='text-gray-400' />,
          placeholder: t('Add email'),
          label: 'Email',
        };
      case 'phone':
        return {
          icon: <Phone height={18} width={18} className='text-gray-400' />,
          placeholder: t('Add phone'),
          label: 'Phone',
        };
      default:
        return { icon: null, placeholder: '' };
    }
  };

  const renderWARedirect = () => {
    if (selectedTicket?.customer_platform_type !== 'whatsapp_bsp') {
      return null;
    }
    const phone = customerInfo['phone'];
    const url = phone
      ? `https://wa.me/${phone.replaceAll('-', '').replaceAll('+', '')}`
      : '';

    return (
      <a
        className='flex items-center justify-between px-2.5 cursor-pointer hover:bg-gray-200'
        target='_blank'
        href={url}
        rel='noreferrer noopener'
      >
        <WhatsappIcon />
      </a>
    );
  };

  const getCustomerInformationUI = (informationName: string, key: number) => {
    switch (informationName) {
      case 'phone':
      case 'email':
      case 'full_name':
        return (
          <div
            key={key}
            className={`flex gap-4 ml-1 items-center justify-between px-3  focus-within:bg-gray-200 hover:bg-gray-200`}
          >
            <TooltipProviderCustomised
              content={t(getInformations(informationName).label || '')}
            >
              {getInformations(informationName).icon}
            </TooltipProviderCustomised>
            <div
              className='flex justify-center w-[90%] font-normal text-sm leading-5 text-gray-900'
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  handleCustomerInformationUpdate(informationName);
                  setEditedData(null);
                }
              }}
              onClick={() => {
                setEditedData(informationName);
              }}
            >
              <input
                id={informationName}
                // @ts-ignore
                ref={(el) => (ref.current[informationName] = el)}
                autoComplete='off'
                className={`${editInputClass} focus:border-b-2 focus:border-solid focus:border-green-400 ${
                  informationName === 'phone' &&
                  selectedTicket?.customer_platform_type === 'whatsapp_bsp'
                }? cursor-pointer: ''`}
                type={informationName !== 'phone' ? 'text' : 'number'}
                value={
                  isFocused
                    ? showableInformation[informationName]
                    : truncateString(showableInformation[informationName], 20)
                }
                placeholder={t(
                  getInformations(informationName).placeholder || ''
                )}
                disabled={
                  informationName === 'phone' &&
                  selectedTicket?.customer_platform_type === 'whatsapp_bsp'
                }
                onChange={(e) => {
                  if (informationName === 'phone' && e.target.value.length > 20)
                    return;
                  setShowableInformation((preveiousValue) => ({
                    ...preveiousValue,
                    [informationName]: e.target.value,
                  }));
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                data-tip={showableInformation[informationName]}
                data-for='input-attribute'
                data-tip-disable={
                  isFocused ||
                  showableInformation[informationName].toString().length <= 20
                }
              />
              {getSaveButton(informationName)}
              {informationName === 'phone' ? renderWARedirect() : null}
              {getCopyIcon(informationName)}
            </div>
            <ReactTooltip
              id='input-attribute'
              place='top'
              type='dark'
              effect='solid'
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderSingleContactInformation = (info: IContactInfo) => {
    return (
      <div
        key={info?.id}
        className='flex items-center hover:bg-gray-200 gap-3 ltr:pl-4 rtl:pr-4 ltr:pr-2 rtl:pl-2 py-1.5'
      >
        <span className='text-gray-400 flex items-center'>
          <TooltipProviderCustomised content={info?.toolTip} delayDuration={0}>
            {info?.icon}
          </TooltipProviderCustomised>
        </span>

        <TooltipProviderCustomised
          asChild
          content={info?.value?.length > 30 ? info?.value : ''}
        >
          <span className='text-gray-900 truncate text-sm leading-5'>
            {info?.value}
          </span>
        </TooltipProviderCustomised>

        <div className='flex ltr:ml-auto rtl:mr-auto'>
          {info.id === 'customer_phone' && renderWARedirect()}
          {info?.showCopyButton ? (
            <div className='flex flex-shrink-0 bg-transparent'>
              <CopyText textToCopy={info?.value} height={13} />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderCustomerInformation = () => {
    const defaultEmptyValue = '--';
    const listOfContactInformations: IContactInfo[] = [
      {
        id: 'ticket_id',
        icon: <Ticket height={18} width={18} />,
        toolTip: 'Current Ticket ID',
        shouldRender: true,
        value: '#' + selectedTicket?.id,
      },
      {
        id: 'customer_gender',
        icon: <Users height={18} width={18} />,
        toolTip: 'Gender',
        shouldRender: true,
        value: selectedTicket?.customer_gender || defaultEmptyValue,
      },
      {
        id: 'customer_device',
        icon: <MonitorSmartphone height={18} width={18} />,
        toolTip: 'Device',
        shouldRender: true,
        // @ts-ignore not implemented in backend
        value: selectedTicket?.customer_device || defaultEmptyValue,
      },
      {
        id: 'customer_timezone',
        icon: <Clock8 height={18} width={18} />,
        toolTip: 'Time Zone',
        shouldRender: true,
        value: selectedTicket?.customer_timezone
          ? `(GMT+${selectedTicket?.customer_timezone})`
          : defaultEmptyValue,
      },
      {
        id: 'customer_location',
        icon: <MapPin height={18} width={18} />,
        toolTip: 'Location',
        shouldRender: true,
        value:
          !!customerInfo && (!!customerInfo?.city || !!customerInfo?.country)
            ? `${customerInfo?.city ? `${customerInfo?.city},` : ''} ${
                customerInfo?.country ? customerInfo?.country : ''
              }`
            : defaultEmptyValue,
      },
      {
        id: 'customer_user_agent',
        icon: <Earth height={18} width={18} />,
        toolTip: 'User Agent',
        shouldRender: true,
        value: customerInfo?.user_agent || defaultEmptyValue,
      },
      {
        id: 'customer_source_type',
        icon: <MousePointerClick height={18} width={18} />,
        toolTip: 'Source Type',
        shouldRender: true,
        value: customerInfo?.source_type || defaultEmptyValue,
      },
      {
        id: 'customer_current_page_url',
        icon: <Globe height={18} width={18} />,
        toolTip: 'Current page url',
        shouldRender: true,
        value: customerInfo?.current_page_url || defaultEmptyValue,
      },
      {
        id: 'customer_starting_page_url',
        icon: <Globe height={18} width={18} />,
        toolTip: 'Current starting page url',
        shouldRender: true,
        value: customerInfo?.starting_page_url || defaultEmptyValue,
      },
      {
        id: 'fb_post_id',
        icon: <Globe height={18} width={18} />,
        toolTip: 'FB Post ID',
        shouldRender:
          selectedTicket?.customer_platform_type === 'facebook_feed',
        value:
          conversationData?.parent_comment_data?.conversation_id ||
          defaultEmptyValue,
      },
      {
        id: 'ig_post_id',
        icon: <Globe height={18} width={18} />,
        toolTip: 'IG Post ID',
        shouldRender:
          selectedTicket?.customer_platform_type === 'instagram_feed',
        value:
          conversationData?.parent_comment_data?.conversation_id ||
          defaultEmptyValue,
      },
      {
        id: 'customer_since',
        icon: <CalendarClock height={18} width={18} />,
        toolTip: 'Customer since',
        shouldRender: true,
        value: `Customer since ${
          selectedTicket?.customer_created_at
            ? dayjs(selectedTicket?.customer_created_at).format('DD MMM YYYY')
            : defaultEmptyValue
        }`,
      },
    ];

    return listOfContactInformations
      .filter((_v) => _v.shouldRender && _v.value !== defaultEmptyValue)
      .map(renderSingleContactInformation);
  };

  React.useEffect(() => {
    setShowableInformation(initialEditableData);
    // eslint-disable-next-line
  }, [customerInfo?.id]);

  return (
    <div className='flex w-auto h-auto border rounded flex-column'>
      <div className='flex items-center justify-between p-3'>
        <div className='font-semibold text-sm leading-5 text-gray-900 w-[95%] cursor-default'>
          {t('Customer Information')}
        </div>
      </div>
      {showInstagramIcon() && getInstagramIcon()}
      {['full_name', 'email', 'phone'].map(
        (information: string, index: number) =>
          getCustomerInformationUI(information, index)
      )}
      {renderCustomerInformation()}
    </div>
  );
};

export default CustomerInformation;
