interface Props {
  color?: string;
  className?: string;
}

const DragDropIcon: React.FC<Props> = ({
  color = '#9CA3AF',
  className = '',
}) => (
  <svg
    stroke={color}
    className={className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M19.5 11V9C19.5 8.46957 19.2893 7.96086 18.9142 7.58579C18.5391 7.21071 18.0304 7 17.5 7H9.5C8.96957 7 8.46086 7.21071 8.08579 7.58579C7.71071 7.96086 7.5 8.46957 7.5 9V17C7.5 17.5304 7.71071 18.0391 8.08579 18.4142C8.46086 18.7893 8.96957 19 9.5 19H11.5'
      stroke='#71717A'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 3V3.01M7.5 3V3.01M11.5 3V3.01M15.5 3V3.01M3.5 7V7.01M3.5 11V11.01M3.5 15V15.01M13.5 13L22.5 16L18.5 18L16.5 22L13.5 13Z'
      stroke='#71717A'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DragDropIcon;
