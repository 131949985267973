import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../../../utilities/utils';
import { BlocksData } from '../../../../utilities/content';

interface Props {
  selectedType: string;
  handleActionButtonSelection: (val: string) => void;
}

const EcommerceActionButtons: React.FC<Props> = ({
  selectedType,
  handleActionButtonSelection,
}) => {
  const getRadioElement = (
    checked: boolean,
    name: string,
    value: string,
    index: number
  ) => {
    return (
      <div className='flex items-center text-xs flex-nowrap focus:bg-none'>
        <span
          className={classNames(
            index === 0 ? 'mr-1' : 'ml-2 mr-1',
            checked || value === selectedType
              ? 'ring-4 ring-inset ring-offset-0 ring-primary border-none'
              : 'bg-white border-gray-300',
            'h-4 w-4 rounded-full border flex items-center justify-center'
          )}
          aria-hidden='true'
        >
          <span className='w-4 h-4 rounded-full bg-none' />
        </span>
        <RadioGroup.Label as='span' className={'flex-none font-xs'}>
          {name}
        </RadioGroup.Label>
      </div>
    );
  };

  return (
    <RadioGroup
      value={selectedType}
      onChange={(val: string) => handleActionButtonSelection(val)}
    >
      <div className='flex bg-white border-none'>
        {BlocksData.productDiscoveryBlock.actionButtons.map(
          (buttons: any, index: number) => (
            <RadioGroup.Option
              key={index}
              value={buttons.value}
              className={
                'border-none flex flex-row cursor-pointer focus:outline-none'
              }
            >
              {({ checked }) =>
                getRadioElement(checked, buttons.name, buttons.value, index)
              }
            </RadioGroup.Option>
          )
        )}
      </div>
    </RadioGroup>
  );
};

export default EcommerceActionButtons;
