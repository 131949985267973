import { MarkerType } from 'reactflow';
import { DelayTimingDataTypes, TriggerTagTypes } from '../interface';

const NodehandleClass = 'border bg-white border-primary h-3 w-3';

export const NodeBottomLeftHandleClass = `${NodehandleClass} left-24`;
export const NodeBottomRightHandleClass = `${NodehandleClass} left-[17rem]`;
export const NodeTopMiddleHandleClass = `${NodehandleClass}`;
export const NodeBottomMiddleHandleClass = `${NodehandleClass}`;

export const CildrenClass = 'px-4 pt-2';
export const CildrenWrapperClass = 'bg-white border-2 border-t-0 rounded-b-2xl';

export const UserActionFlowGetterApiDataLimit = 10;
export const UserActionFlowHistoryGetterApiDataLimit = 10;
export const WorkflowAutomationNameLimit = 128;

export const dummyNodes = [
  {
    id: 'node-0',
    deletable: false,
    type: 'trigger-node',
    position: {
      x: 0,
      y: 0,
    },
    data: {
      label: 'Order Created',
    },
    width: 360,
    height: 119,
  },
  {
    id: 'node-1',
    type: 'action-node',
    position: {
      x: 0,
      y: 166.46380971548766,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 99,
    selected: false,
    positionAbsolute: {
      x: 0,
      y: 166.46380971548766,
    },
    dragging: true,
  },
  {
    id: 'node-2',
    type: 'condition-node',
    position: {
      x: 1.1615952428871879,
      y: 313.18050030189306,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 152,
    selected: false,
    positionAbsolute: {
      x: 1.1615952428871879,
      y: 313.18050030189306,
    },
    dragging: true,
  },
  {
    id: 'node-3',
    type: 'action-node',
    position: {
      x: -383.56455307525255,
      y: 674.8923392406417,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 99,
    selected: false,
    positionAbsolute: {
      x: -383.56455307525255,
      y: 674.8923392406417,
    },
    dragging: true,
  },
  {
    id: 'node-4',
    type: 'action-node',
    position: {
      x: 326.2101675483918,
      y: 667.1236607064525,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 99,
    selected: false,
    positionAbsolute: {
      x: 326.2101675483918,
      y: 667.1236607064525,
    },
    dragging: true,
  },
  {
    id: 'node-5',
    type: 'action-node',
    position: {
      x: -383.56455307525255,
      y: 807.4343258649126,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 99,
    selected: false,
    positionAbsolute: {
      x: -383.56455307525255,
      y: 807.4343258649126,
    },
    dragging: true,
  },
  {
    id: 'node-6',
    type: 'condition-node',
    position: {
      x: 326.2101675483918,
      y: 897.9112092208452,
    },
    data: {
      label: 'yo',
    },
    width: 360,
    height: 152,
    selected: true,
    positionAbsolute: {
      x: 326.2101675483918,
      y: 897.9112092208452,
    },
    dragging: true,
  },
];

export const TriggerTabData: TriggerTagTypes[] = [
  {
    title: 'Shopify',
    code: 'shopify',
  },
  {
    title: 'WooCommerce',
    code: 'woocommerce',
  },
  {
    title: 'Salla',
    code: 'salla',
  },
  {
    title: 'Zid',
    code: 'zid',
  },
];

export const OrderStatusData = [
  { id: 'any', title: 'any' },
  { id: 'pending-payment', title: 'Pending Payment' },
  { id: 'processing', title: 'Processing' },
  { id: 'on-hold', title: 'On-Hold' },
  { id: 'completed', title: 'Completed' },
  { id: 'cancelled', title: 'Cancelled' },
  { id: 'refunded', title: 'Refunded' },
  { id: 'failed', title: 'Failed' },
  { id: 'draft', title: 'Draft' },
];

export const EdgeMarkendProperty = {
  type: MarkerType.ArrowClosed,
  width: 20,
  height: 20,
  color: '#04B25F',
};

export const EdgeStyles = {
  strokeWidth: 2,
  stroke: '#04B25F',
};
export const staticExecutorListData = [
  { code: 'general', name: 'General', description: null },
];

export const DelayTimingData: DelayTimingDataTypes[] = [
  { value: 'minute', label: 'Minute' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
];

export const UserActionFlowTableActionData = [
  { link: '', label: 'Edit' },
  { link: '', label: 'Rename' },
  { link: '', label: 'Duplicate' },
  { link: '', label: 'History' },
  { link: '', label: 'Delete' },
];

export const UserActionFlowTableActionLabels = {
  EDIT: 'Edit',
  RENAME: 'Rename',
  DUPLICATE: 'Duplicate',
  LOGS: 'Logs',
  History: 'History',
  DELETE: 'Delete',
};
