import {
  Button,
  AddConditions,
  ConditionHeader,
  ChevronRightIcon,
  AddConditionLgIcon,
  AddConditionSmallIcon,
  useAutomationWorkflow,
  LinearLoaderComponent,
  useConditionalActions,
} from 'pages/automationWorkflow/export';
import {
  ComparatorTagTypes,
  ITemporaryComparatorOption,
} from 'pages/automationWorkflow/interface';

const ConditionsView = () => {
  // Access functions from useConditionalActions hook for conditional actions
  const {
    handleSelectCompoarator,
    updateSelectedComparator,
    handleSelectComparatorOption,
    isSelectedTriggerDetailsApiLoading,
    updateConditionallyRenderingConditionUI,
  } = useConditionalActions();

  // Destructure required state variables from the automation workflow hook
  const {
    selectedComparator,
    comparatorTagList,
    temporaryComparatorOptions,
    renderingChangeUIOfCondition,
  } = useAutomationWorkflow();

  // Renders the view for adding a new condition
  const renderAddNewCondition = () => {
    return (
      <div
        className='flex justify-center items-center'
        style={{ height: 'calc(100vh - 216px)' }}
      >
        <div className='flex flex-col gap-6 items-center'>
          <div>
            <AddConditionLgIcon />
          </div>
          <div>
            <p className=' text-base text-gray-500 font-normal text-center'>
              No condition added. Please add new <br /> condition by clicking
              button below.
            </p>
          </div>
          <div>
            <Button
              onClick={() =>
                updateConditionallyRenderingConditionUI('add_condition_list')
              }
              className='flex items-center bg-primary hover:bg-primary-hover border-primary transition capitalize'
            >
              <AddConditionSmallIcon />
              <p className='pl-2 text-white font-medium text-sm'>
                Add New Condition
              </p>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // Renders the list of comparator options
  const renderComparatorList = () => {
    return (
      <div className='sticky top-0 z-20 w-full'>
        <ConditionHeader
          title='Select Conditon'
          previousUIAction={() =>
            updateConditionallyRenderingConditionUI('addActionElement')
          }
        />

        <div
          className='grid grid-cols-5 grid-flow-col'
          style={{ height: 'calc(100vh - 129px)' }}
        >
          <div className='col-span-2 border-r'>
            {isSelectedTriggerDetailsApiLoading ? (
              <LinearLoaderComponent />
            ) : (
              comparatorTagList.map(
                (comparator: ComparatorTagTypes, index: number) => (
                  <div className='border-b p-1 ' key={index}>
                    <div
                      className={`flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md ${
                        selectedComparator?.code === comparator?.code &&
                        'bg-green-50'
                      }`}
                      onClick={() => {
                        handleSelectCompoarator(comparator?.code);
                        updateSelectedComparator(comparator);
                      }}
                    >
                      <div className='flex items-center'>
                        <h3
                          className={`ml-3 text-gray-700 font-medium text-base capitalize ${
                            selectedComparator?.code === comparator?.code &&
                            'text-green-500'
                          }`}
                        >
                          {comparator?.name}
                        </h3>
                      </div>
                      <div>
                        <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
          <div className='col-span-3'>{renderComparatorDetails()}</div>
        </div>
      </div>
    );
  };

  // Renders the list of comparator details
  const renderComparatorDetails = () => {
    if (temporaryComparatorOptions.length === 0) {
      return (
        <div className='flex justify-center items-center h-full'>
          <div className='text-gray-500 text-base'>Select condition first.</div>
        </div>
      );
    } else {
      return temporaryComparatorOptions.map(
        (comparator: ITemporaryComparatorOption, index: number) => (
          <div className='border-b p-1.5' key={index}>
            <div
              className='flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md'
              onClick={() => handleSelectComparatorOption(comparator?.id)}
            >
              <div className='flex items-center'>
                <h3 className='ml-3 text-gray-600 font-regular text-sm leading-5'>
                  {comparator?.name}
                </h3>
              </div>
            </div>
          </div>
        )
      );
    }
  };

  // Renders different UI elements based on the given element type
  const renderSidesheetChildElement = (elementType: string) => {
    switch (elementType) {
      case 'addActionElement':
        return renderAddNewCondition();
      case 'add_condition_list':
        return renderComparatorList();
      case 'add_condition':
        return <AddConditions />;
      case 'cart_abandoned':
        return renderComparatorList();
      default:
        return null;
    }
  };

  return renderSidesheetChildElement(renderingChangeUIOfCondition);
};

export default ConditionsView;
