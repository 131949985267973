import React from 'react';
import { Switch } from 'evergreen-ui';

interface Prop {
  taxApplied: boolean;
  handleChange: (key: string, value: boolean) => void;
}

const ApplyTax: React.FC<Prop> = ({ taxApplied, handleChange }) => {
  return (
    <div className='px-6 pt-4 pb-6 mt-0 border-b'>
      <div className='flex space-between'>
        <label
          htmlFor='country'
          className='block mt-2 text-sm font-medium text-gray-700'
        >
          Apply Tax
        </label>
        <div className='mt-2'>
          <Switch
            className='switch-rd'
            onChange={() => handleChange('applyTax', !taxApplied)}
            height={20}
            checked={taxApplied}
            hasCheckIcon={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplyTax;
