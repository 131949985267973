import React, { useEffect } from 'react';

import FeedPost from './FeedPost';
import FeedComment from './FeedComment';
import {
  CommentStatusPayloadInterface,
  ConversationFeedInterface,
} from 'pages/inbox/inboxInterface';
import useCommentEdit from 'pages/inbox/hooks/useCommentEdit';

interface Props {
  userId: number;
  conversationLoading: boolean;
  adminAvatar: string | null;
  conversationData: ConversationFeedInterface;

  updateFeedCommentStatus: (payload: CommentStatusPayloadInterface) => void;
}

const SmartFeed: React.FC<Props> = ({
  userId,
  conversationLoading,
  adminAvatar,
  conversationData,

  updateFeedCommentStatus,
}) => {
  const { cancelCommentEditMode } = useCommentEdit();
  useEffect(() => {
    return cancelCommentEditMode();
    //eslint-disable-next-line
  }, []);

  const getFeedData = () => {
    return (
      <>
        <FeedPost
          platformInfo={conversationData?.platform_info}
          parentPostData={conversationData?.parent_post_data}
        />
        <FeedComment
          parent_comment={conversationData?.parent_comment_data}
          platformType={conversationData?.platform_info?.platform_type}
          replies={conversationData?.replies}
          userId={userId}
          adminAvatar={adminAvatar}
          updateFeedCommentStatus={updateFeedCommentStatus}
        />
      </>
    );
  };

  const scrollToBottom = () => {
    const hasScrollBehavior =
      'scrollBehavior' in document.documentElement.style;
    const container = document.getElementById('conversation-bar');
    if (!!container) {
      if (hasScrollBehavior) {
        container.scroll({
          top: container.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        container.scrollTop = container?.scrollHeight;
      }
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [conversationLoading]);

  return <> {!!conversationData && getFeedData()} </>;
};

export default SmartFeed;
