import React, { Component, Fragment } from 'react';
import './asssets/style/style.scss';
import { Pagination } from '../../../components';
import { connect } from 'react-redux';
import AudienceTable from './components/AudienceTable';
import FilterTable from './components/FilterTable';
import { HasPermission, ProUser } from '../../../components';
import { checkPermission } from '../../../utilities/utils';
import { PlusIcon } from '@heroicons/react/20/solid';
import { navigate } from '@reach/router';
import NewAlertModal from 'library/modal/NewAlertModal';

class Audience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 20,
      offset: 0,
      loading: true,
      condition: [],
      mockAudienceData: [],
      showProModal: false,
      readAccess: 'read:audience',
      tableReportExportResponse: {
        show: false,
        success: false,
        message: {
          title: '',
          description: '',
        },
      },
    };
  }

  async initInitialData() {
    await this.props.fetchProject();
    if (this.props.projects.length > 0) {
      const project = this.props.projects.filter(
        (p) => parseInt(this.props.projectId) === p.id
      )[0];
      await this.props.setSelectedProject(project);
      if (!!project.platforms && project.platforms?.length > 0) {
        await this.props.setSelectedPlatform(project.platforms[0]);
      }
    }
    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (this.props.selectedPlatform && hasPermission) {
      await this.props.fetchFilterInformation(
        this.props.selectedProject.id,
        this.props.selectedPlatform.id
      );
      this.fetchAudienceInformation(
        this.state.limit,
        this.state.offset,
        this.state.condition
      );
    }
  }

  fetchAudienceInformation = async (limit, offset, condition) => {
    const body = {
      project_id: this.props.selectedProject.id,
      platform_id: this.props.selectedPlatform.id,
      conditions: condition,
      limit: limit,
      offset: offset,
    };
    await this.props.fetchAudience(body);
    this.setState({
      loading: false,
      offset: offset,
      mockAudienceData: this.props.AudienceData,
    });
  };
  handlePaginationChange = async (page) => {
    await this.setState({
      loading: true,
      currentPage: page,
    });
    this.fetchAudienceInformation(
      this.state.limit,
      (page - 1) * this.state.limit,
      this.state.condition
    );
  };

  addNewCondition = () => {
    this.setState({
      condition: [
        ...this.state.condition,
        { attribute: '', operator: '', value: '' },
      ],
    });
  };

  handleConditionOnChange = (value, type, index) => {
    let localCondition = [
      ...this.state.condition.slice(0, index),
      { ...this.state.condition[index], [type]: value },
      ...this.state.condition.slice(index + 1),
    ];

    this.setState({
      condition: localCondition,
    });
  };
  submitFilter = () => {
    const filteredData = this.state.condition.filter(
      (condition) => condition.value !== ''
    );
    this.setState(
      {
        condition: filteredData,
      },
      () => {
        this.fetchAudienceInformation(
          this.state.limit,
          this.state.offset,
          filteredData
        );
      }
    );
  };
  handleNameSearch = (name) => {
    this.setState(
      {
        condition: [
          { attribute: 'full_name', operator: 'contains', value: name },
        ],
        limit: 20,
        offset: 0,
      },
      () => {
        this.fetchAudienceInformation(
          this.state.limit,
          this.state.offset,
          this.state.condition
        );
      }
    );
  };

  handlePlatformChange = (value) => {
    this.setState({ loading: true, limit: 20, offset: 0 }, async () => {
      await this.props.setSelectedPlatform(value);
      this.fetchAudienceInformation(
        this.state.limit,
        this.state.offset,
        this.state.condition
      );
      await this.props.fetchFilterInformation(
        this.props.selectedProject.id,
        this.props.selectedPlatform.id
      );
    });
  };

  handleProModalCancel = () => {
    this.setState({ showProModal: false });
  };

  exportAudienceData = async () => {
    if (this.props.planType !== 'free') {
      const body = {
        project_id: this.props.selectedProject.id,
        platform_id: this.props.selectedPlatform.id,
        conditions: this.state.condition,
        export: true,
      };
      const response = await this.props.exportAudience(body);
      if (response) {
        this.setState({
          tableReportExportResponse: {
            show: true,
            success: true,
            message: {
              title: 'Download In Process',
              description: (
                <span className='text-sm text-gray-500 font-normal'>
                  Your export is currently processing. When it is ready, you
                  will receive an email at{' '}
                  <span className='text-sm text-gray-700 font-bold'>
                    {this.props.userEmail}
                  </span>
                </span>
              ),
            },
          },
        });
      } else {
        this.setState({
          tableReportExportResponse: {
            show: true,
            success: false,
            message: {
              title: 'Failed',
              description: 'Failed to Export Data',
            },
          },
        });
      }
    } else {
      this.setState({ showProModal: true });
    }
  };

  componentDidMount() {
    this.initInitialData();
  }
  render() {
    return (
      <HasPermission shortCode={this.state.readAccess}>
        {this.props.selectedPlatform &&
        this.props.selectedPlatform.length !== 0 ? (
          <Fragment>
            <div className='layout-body__no-scroll'>
              {this.state.showProModal && (
                <ProUser handleCancel={() => this.handleProModalCancel()} />
              )}
              <FilterTable
                condition={this.state.condition}
                filterInformation={this.props.filterInformation}
                setCondition={(data) => this.setState({ condition: data })}
                selectedProject={this.props.selectedProject}
                platformList={this.props.platformList}
                setSelectedPlatform={this.props.setSelectedPlatform}
                selectedPlatform={this.props.selectedPlatform}
                handlePlatformChange={this.handlePlatformChange}
                addCondition={this.addNewCondition}
                handleConditionOnChange={this.handleConditionOnChange}
                submitFilter={this.submitFilter}
                exportAudienceData={this.exportAudienceData}
                exportLoader={this.props.exportLoading}
                isProUser={this.props.planType !== 'free'}
              />
              <div>
                <AudienceTable
                  handleNameSearch={this.handleNameSearch}
                  loading={this.state.loading}
                  mockAudienceData={this.state.mockAudienceData}
                />
                {!this.state.loading && this.props.total > 19 && (
                  <div className='mt-10px'>
                    <Pagination
                      align='left'
                      currentPage={this.state.currentPage}
                      totalPages={Math.ceil(
                        this.props.total / this.state.limit
                      )}
                      changeCurrentPage={(pageNo) =>
                        this.handlePaginationChange(pageNo)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className='flex justify-center h-full text-center align-center flex-column'>
            <svg
              className='w-12 h-12 mx-auto text-gray-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                vectorEffect='non-scaling-stroke'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
              />
            </svg>
            <h3 className='mt-2 text-sm font-medium text-gray-900'>
              No channels
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Create a channel and start serving your customers right away!
            </p>
            <div className='mt-6'>
              <button
                type='button'
                className='inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none'
                onClick={() => navigate('/settings/channel')}
              >
                <PlusIcon className='w-5 h-5 mr-2 -ml-1' aria-hidden='true' />
                New Channel
              </button>
            </div>
          </div>
        )}
        <NewAlertModal
          shouldShowCancelButton={false}
          isShown={this.state.tableReportExportResponse?.show}
          title={this.state.tableReportExportResponse?.message?.title}
          description={
            this.state.tableReportExportResponse?.message?.description
          }
          intent={
            this.state.tableReportExportResponse?.success ? 'primary' : 'danger'
          }
          shouldRenderDescriptionAsNode={
            this.state.tableReportExportResponse?.success
          }
          confirmButtonTitle='Got it!'
          onConfirm={() => {
            this.setState((prevState) => ({
              tableReportExportResponse: {
                ...prevState?.tableReportExportResponse,
                show: false,
              },
            }));
          }}
        />
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  userEmail: state.auth.email,
  planType: state.dashboard.selectedProject['subscription_plan']['plan_type'],
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  platformList: state.dashboard.platformList,
  selectedPlatform: state.dashboard.selectedPlatform,
  AudienceData: state.audience.AudienceData,
  total: state.audience.total,
  filterInformation: state.audience.filterInformation,
  exportLoading: state.loading.effects.audience.exportAudience,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  fetchAudience: (body) => dispatch.audience.fetchAudience(body),
  exportAudience: (body) => dispatch.audience.exportAudience(body),
  fetchFilterInformation: (projectId, platformId) =>
    dispatch.audience.fetchFilterInformation({ projectId, platformId }),
  fetchProject: () => dispatch.dashboard.fetchProject(),
});

const AudienceContainer = connect(mapState, mapDispatch)(Audience);

export default AudienceContainer;
