import React from 'react';
import { Button } from '../../../../../../../library';
import { CheckIcon } from '@heroicons/react/24/outline';
import emptyState from '../../../../assets/images/emptyStateProductInteraction.svg';
import {truncate} from './../utils';
import {
  SelectedProductDataProps
} from '../interfaces';

interface Prop {
  product: SelectedProductDataProps;
  currencySymbol: string;
}

const CreateOrderSelectedListItem: React.FC<Prop> = ({
  product,
  currencySymbol,
}) => {
  return (
    <div className='block mb-4 border rounded-md cursor-default hover:bg-gray-50'>
      <div className='flex items-start px-4 py-4 sm:px-6'>
        <div className='flex items-center flex-1 min-w-0'>
          <div className='flex-shrink-0'>
            <img
              className='w-12 h-12 rounded-md'
              src={
                product.product_images.length > 0
                  ? product.product_images[0]
                  : emptyState
              }
              alt=''
            />
          </div>
          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-6 md:gap-4'>
            <div className='md:col-span-5'>
              <p className='text-sm font-medium truncate text-primary'>
                {product.product_name}
              </p>
              <p className='flex items-center mt-2 text-xs text-gray-500'>
                <span className='truncate'>
                  {product.has_variations
                    ? truncate(product.variant_attributes_string, 'Variant Not Selected', 80)
                    : 'No Variation Available'}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap items-center justify-between px-4 pb-4 sm:px-6'>
        <div className='flex items-center sm:flex-wrap'>
          {product.has_variations && (
            <div className='w-full mr-2 sm:w-auto'>
              <Button>
                <CheckIcon className='w-5 h-5 mr-2' />{' '}
                {truncate(product.variant_attributes_string, 'N/A', 20)}
              </Button>
            </div>
          )}
          <div className='mt-0 sm:mt-0'>
            <Button intent='default'>{product.quantity || 0}</Button>
          </div>
        </div>
        <p className='w-full mt-2 sm:mt-0 sm:w-auto'>
          Price:
          <span className='inline-flex ml-2 items-center px-3 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800'>
            {currencySymbol}
            {(
              product.quantity * parseFloat(product.unit_price.toString())
            ).toFixed(2)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CreateOrderSelectedListItem;
