import React from 'react';
import PasswordField from '../../../components/utilityComponent/PasswordField';
import Alert from '../../../old/components/elements/redesign/Alert';

import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { Button, Link } from '../../../library';

interface Props {
  email: string;
  password: string;
  firstName: string;
  fullName: string;
  lastName: string;
  content: any;
  handleChange: (name: string, value: string) => void;
  handleSubmit: (dashboardLanguage: string) => void;
  loading: boolean;
  isDuplicateEmail: boolean;
  isInvalidEmail: boolean;
  isInvalidPassword: boolean;
  isFullNameEmpty: boolean;
  isBusinessUrlEmpty: boolean;
  errorMessage: string;
  hasAppSumoInfo: boolean;
  disableEmail: boolean;
  businessUrl: string;
  phone: string;
  selectedCountry: CountryDataInterface;
}

const NewRegisterFormSection: React.FC<Props> = ({
  email,
  password,
  firstName,
  lastName,
  fullName,
  content,
  handleChange,
  handleSubmit,
  loading,
  isDuplicateEmail = false,
  isInvalidEmail = false,
  isInvalidPassword = false,
  isFullNameEmpty = false,
  errorMessage = '',
  hasAppSumoInfo = false,
  disableEmail = false,
  businessUrl,
  phone,
  selectedCountry,
  isBusinessUrlEmpty = false,
}) => {
  const { authT, dashboardLanguage } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideAliceTos = isPartnerRestricted(
    'NewRegisterFormSection/node/registration-tos'
  );
  return (
    <div className='mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='space-y-4'>
        <div>
          <label
            htmlFor='full_name'
            className='block text-sm font-medium text-gray-700'
          >
            <span className='mb-1'>{authT('Full Name')}</span>
            <input
              id='full_name'
              name='fullName'
              type='name'
              autoComplete='name'
              value={fullName || ''}
              placeholder={authT('John Doe')}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
            {isFullNameEmpty && errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={authT(errorMessage)} />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            <span className='mb-1'>{authT('Email Address')}</span>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              disabled={disableEmail}
              value={email || ''}
              placeholder='you@example.com'
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
            {isInvalidEmail && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={authT(errorMessage)} />
              </div>
            ) : (
              ''
            )}
            {isDuplicateEmail ? (
              <div className='mt-3'>
                <Alert
                  intent='danger'
                  text={authT('A user with this email already exists.')}
                />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <div>
          <label
            htmlFor='business_url'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='flex justify-between mb-1'>
              <span>{authT('Business URL')}</span>
            </div>
            <input
              id='business_url'
              name='businessUrl'
              type='name'
              autoComplete='name'
              value={businessUrl || ''}
              placeholder={authT('Your website/social account link')}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
          </label>
          {isBusinessUrlEmpty && errorMessage && (
            <div className='mt-3'>
              <Alert intent='danger' text={authT(errorMessage)} />
            </div>
          )}
        </div>
        <div>
          <label
            htmlFor='Phone'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='flex justify-between mb-1'>
              <span>{authT('Phone')}</span>
              <span className='text-sm text-gray-500'>{authT('Optional')}</span>
            </div>
            <div className='flex mt-1 rounded-md shadow-sm'>
              <input
                type='number'
                name='phone'
                value={phone || ''}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder={authT('Type here...')}
              />
            </div>
          </label>
        </div>
        <div>
          <label
            htmlFor='form'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='mb-1'>{authT('Password')}</div>
            <PasswordField
              password={password}
              handleChange={(name: string, value: string) =>
                handleChange(name, value)
              }
              handleSubmit={handleSubmit}
            />
            {isInvalidPassword && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={authT(errorMessage)} />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        <Button
          onClick={() => handleSubmit(dashboardLanguage || 'english')}
          isFullWidth={true}
          isLoading={loading}
          intent='primary'
        >
          {authT('Sign up')}
        </Button>
        {shouldHideAliceTos ? null : (
          <div id='registration-tos' className='flex justify-center'>
            <label
              htmlFor='remember_me'
              className='block text-sm text-gray-500'
            >
              {authT(content.signupConfirmationInfo.text)}{' '}
              <Link
                url={content.signupConfirmationInfo.link}
                openInNewTab={true}
              >
                {authT(content.signupConfirmationInfo.linkTitle)}
              </Link>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
export default NewRegisterFormSection;
