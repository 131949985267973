import {
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from 'libraryV2/ui/dialog';
import React, { useEffect } from 'react';
import { classNames } from 'utilities/utils';
import broadcastIcon from '../../../../assets/icons/broadcast-modal.svg';
import LoadingLogo from '../../../../assets/icons/component/LoadingIcon';

interface ConfirmationModalProps {
  isShown: boolean;
  title?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  intent?: 'primary' | 'danger' | 'warning' | 'send-now';
  onConfirm: () => void;
  onCancelComplete?: () => void;
  onToggle?: () => void;
  onClose?: () => void;
  afterOpen?: () => void | null;
  shouldRenderDescriptionAsNode?: boolean;
  description: string | React.ReactNode;
  isConfirmationLoading?: boolean;
  isCancelButtonDisabled?: boolean;
  shouldShowConfirmButton?: boolean;
  shouldShowCancelButton?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isShown = false,
  title = 'Delete',
  afterOpen = null,
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  intent = 'primary',
  onToggle,
  onClose,
  onConfirm,
  onCancelComplete,
  description,
  shouldRenderDescriptionAsNode = false,
  isConfirmationLoading = false,
  isCancelButtonDisabled = false,
  shouldShowConfirmButton = true,
  shouldShowCancelButton = true,
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = React.useRef(null);

  useEffect(() => {
    if (isShown && !!afterOpen) {
      afterOpen();
    }
    //eslint-disable-next-line
  }, [isShown]);

  return (
    <Dialog open={isShown} onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
        <DialogContent
          className={`inline-block overflow-hidden text-center align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg ${
            intent === 'send-now' ? 'w-[400px]' : 'sm:w-full md:w-80'
          }`}
          hideCloseButton={true}
        >
          <div className='px-2 pb-4 bg-white'>
            <div className='flex flex-col items-center'>
              <div
                className={classNames(
                  'flex items-center justify-center w-12 h-12 mx-auto rounded-3xl',
                  intent === ('primary' || intent === 'send-now') &&
                    'text-green-100 border-green-400 bg-green-100',
                  intent === 'danger' &&
                    'text-red-100 border-red-400 bg-red-100',
                  intent === 'warning' &&
                    'text-yellow-100 border-yellow-400 bg-yellow-100'
                )}
              >
                {intent === 'primary' ? (
                  <CheckIcon
                    className={
                      'flex w-6 h-6 items-center justify-center text-primary'
                    }
                    aria-hidden='true'
                  />
                ) : intent === 'send-now' ? (
                  <img src={broadcastIcon} alt='broadcast-svg' />
                ) : (
                  <ExclamationTriangleIcon
                    className={classNames(
                      'flex w-6 h-6 items-center justify-center',
                      intent === 'danger' && 'text-red-600',
                      intent === 'warning' && 'text-yellow-600'
                    )}
                    aria-hidden='true'
                  />
                )}
              </div>
              <div className='mt-4 text-center w-full'>
                <div className='flex items-center justify-center text-lg font-medium leading-6 text-gray-900'>
                  {title}
                </div>
                <div
                  className={`${intent === 'send-now' ? '' : 'w-full'} mt-2`}
                >
                  {!shouldRenderDescriptionAsNode && (
                    <p className='text-sm text-gray-500'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description as string,
                        }}
                      />
                    </p>
                  )}
                  {shouldRenderDescriptionAsNode && description}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col px-4 py-3 space-y-3'>
            {shouldShowConfirmButton && (
              <button
                type='button'
                disabled={isConfirmationLoading}
                className={classNames(
                  'inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white  border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  sm:w-auto sm:text-sm',
                  (intent === 'primary' || intent === 'send-now') &&
                    'bg-primary focus:ring-primary-hover hover:bg-primary-hover ',
                  intent === 'danger' &&
                    'bg-red-600 focus:ring-red-500 hover:bg-red-700 ',
                  intent === 'warning' &&
                    'bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700 '
                )}
                onClick={() => onConfirm()}
              >
                {isConfirmationLoading && (
                  <div className='py-0.5 ltr:mr-2 rtl:ml-2'>
                    <LoadingLogo />
                  </div>
                )}
                {t(confirmButtonTitle)}
              </button>
            )}
            {shouldShowCancelButton && (
              <button
                type='button'
                className={classNames(
                  'inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:w-auto sm:text-sm',
                  isCancelButtonDisabled
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                )}
                onClick={() => {
                  if (onToggle) onToggle();
                  if (onCancelComplete) {
                    onCancelComplete();
                  }
                }}
                ref={cancelButtonRef}
                disabled={isCancelButtonDisabled}
              >
                {cancelButtonTitle}
              </button>
            )}
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default ConfirmationModal;
