import React, { useRef, Fragment } from 'react';
import Button from '../button/index';

import { classNames } from '../../utilities/utils';
import { Transition, Dialog } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  open: boolean;
  intent: 'success' | 'warning' | 'info' | 'danger';
  handleClose: () => void;
  title?: string;
  hasError?: boolean;
  cancelText?: string;
  actionText?: string;
  description?: string;
  hasConfirmation?: boolean;
  hasMinimizeButton?: boolean;
  closeOnExternalClick?: boolean;
  isConfirmButtonLoading?: boolean;
  iconLocation?: 'left' | 'center';
  confirmText?:
    | 'CANCEL'
    | 'REMOVE'
    | 'REFUND'
    | 'DELETE'
    | 'DISCONNECT'
    | 'Confirm';
  handleAction?: (inputValue?: string) => void;
  handleCancel?: () => void;
}

const ConfirmationModal: React.FC<Prop> = ({
  open,
  intent,
  handleClose,
  handleCancel,
  handleAction,
  title = 'Title',
  hasError = false,
  cancelText = 'Cancel',
  iconLocation = 'left',
  isConfirmButtonLoading,
  confirmText = 'DELETE',
  actionText = 'Continue',
  hasConfirmation = false,
  hasMinimizeButton = false,
  description = 'Description',
  closeOnExternalClick = true,
}) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const getIcon = (intent: string) => {
    switch (intent) {
      case 'success':
        return (
          <CheckIcon className='w-6 h-6 text-green-600' aria-hidden='true' />
        );
      case 'warning':
      case 'info':
      case 'danger':
      default:
        return (
          <ExclamationTriangleIcon
            className='w-6 h-6 text-red-600'
            aria-hidden='true'
          />
        );
    }
  };
  const getIconClass = (intent: string) => {
    switch (intent) {
      case 'success':
        return 'bg-green-100';
      case 'warning':
        return 'bg-yellow-50';
      case 'info':
      case 'danger':
      default:
        return 'bg-red-100';
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      {/*add `overflow-y-auto` in dialog for let modal scroll outside overlay*/}
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-10'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          if (closeOnExternalClick) {
            handleClose();
            handleCancel && handleCancel();
          }
        }}
      >
        <div className='flex items-center justify-center min-h-screen p-0 px-4 pt-4 pb-20 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              {hasMinimizeButton && (
                <div className='absolute top-0 right-0 hidden pt-4 pr-4 sm:block'>
                  <button
                    type='button'
                    className='text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      setInputValue('');
                      handleClose();
                      handleCancel && handleCancel();
                    }}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='w-6 h-6' aria-hidden='true' />
                  </button>
                </div>
              )}
              <div
                className={classNames(
                  iconLocation === 'left' ? 'sm:flex sm:items-start' : '',
                  'text-center'
                )}
              >
                <div
                  className={classNames(
                    iconLocation === 'left'
                      ? 'flex-shrink-0 sm:mx-0 sm:h-10 sm:w-10'
                      : '',
                    `mx-auto flex items-center justify-center h-12 w-12 rounded-full ${getIconClass(
                      intent
                    )}`
                  )}
                >
                  {getIcon(intent)}
                </div>

                <div
                  className={classNames(
                    iconLocation === 'left'
                      ? 'sm:mt-0 sm:ml-4 sm:rtl:mr-4 sm:text-left'
                      : 'sm:mt-5',
                    'mt-3 text-center'
                  )}
                >
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex font-medium leading-6 text-gray-900'
                  >
                    {t(title)}
                  </Dialog.Title>
                  <div className='mt-2'>
                    <p className='text-sm rtl:text-right flex text-gray-500'>
                      {t(description)}
                    </p>
                  </div>
                  <br />
                  {hasConfirmation && (
                    <div className='relative'>
                      <input
                        placeholder={t(`Type {{'${confirmText}'}} Here`)}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
                      />
                      {hasError && (
                        <div className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3 text-sm leading-5'>
                          <ExclamationCircleIcon className='w-5 h-5 text-red-600' />
                        </div>
                      )}
                    </div>
                  )}
                  {hasError && (
                    <span className='mt-6 text-red-500'>
                      {t(
                        'You have entered the wrong key. Please enter the valid key to remove your channel.'
                      )}
                    </span>
                  )}
                </div>
              </div>
              {iconLocation === 'left' && (
                <div className='mt-8 sm:mt-6 sm:flex sm:flex-row-reverse'>
                  {handleAction && (
                    <Button
                      size='md'
                      intent={
                        intent === 'warning'
                          ? 'secondary'
                          : intent === 'danger'
                          ? 'danger'
                          : 'primary'
                      }
                      onClick={() => {
                        handleAction(inputValue);
                        setInputValue('');
                      }}
                      isLoading={isConfirmButtonLoading}
                    >
                      {t(actionText)}
                    </Button>
                  )}
                  {handleCancel && (
                    <Button
                      size='md'
                      intent='default'
                      className='ltr:mr-3 rtl:ml-3 border rounded'
                      onClick={() => {
                        handleCancel();
                        setInputValue('');
                      }}
                    >
                      {t(cancelText)}
                    </Button>
                  )}
                </div>
              )}
              {iconLocation === 'center' && (
                <div
                  className={classNames(
                    handleCancel && handleAction
                      ? 'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'
                      : '',
                    'mt-5 sm:mt-6'
                  )}
                >
                  {handleCancel && (
                    <Button
                      size='md'
                      intent='minimal'
                      className='justify-center w-full border border-gray-300'
                      onClick={() => {
                        setInputValue('');
                        handleCancel();
                      }}
                    >
                      {cancelText}
                    </Button>
                  )}
                  {handleAction && (
                    <Button
                      size='md'
                      intent='primary'
                      className='justify-center w-full'
                      onClick={() => {
                        handleAction(inputValue);
                        setInputValue('');
                      }}
                    >
                      {actionText}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
