import useTranslation from 'components/customHooks/useTranslation';
import { CornerUpLeft, CornerUpRight } from 'lucide-react';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import { TicketInterface } from 'pages/inbox/inboxInterface';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  selectedTicket: TicketInterface;
  source: string;
  handleConversationDataForAdminReplyOnClick: () => void;
}

const AdminReplyToButton: React.FC<Props> = ({
  source,
  selectedTicket,
  handleConversationDataForAdminReplyOnClick,
}) => {
  const { isRtlLanguage } = useTranslation();
  const { isZigzagView, isAdminReply } = useMessageBlocksAction({
    source,
  });

  const isAdmin = ['admin', 'echo'].includes(source);

  const isAdminReplyAllowed = [
    'whatsapp_bsp',
    'telegram_messenger',
    'telegram_group',
  ].includes(selectedTicket?.customer_platform_type);
  const replyIconPosition = isZigzagView
    ? isAdminReply
      ? 'ltr:left-[20px] rtl:right-[10px]'
      : 'ltr:right-[10px] rtl:left-[20px]'
    : 'ltr:right-[10px] rtl:left-[20px]';

  const handleAdminReply = () => {
    handleConversationDataForAdminReplyOnClick();
    const replyInputBox = document.getElementById('reply-input');
    replyInputBox?.focus();
  };

  const renderAdminReplyUi = () => {
    return (
      <div
        className={`flex items-center justify-center absolute top-[-8px] ${replyIconPosition} cursor-pointer hover:bg-gray-50 z-50`}
        onClick={handleAdminReply}
      >
        {(isAdmin || source === 'bot') && !isZigzagView && !isRtlLanguage ? (
          <CornerUpLeft className='p-1.5 w-7 h-7 text-[#6B7280] border border-gray-300 rounded-md bg-white shadow' />
        ) : (isAdmin || source === 'bot') && isZigzagView && isRtlLanguage ? (
          <CornerUpLeft className='p-1.5 w-7 h-7 text-[#6B7280] border border-gray-300 rounded-md bg-white shadow' />
        ) : (isAdmin || source === 'bot') && !isZigzagView && isRtlLanguage ? (
          <CornerUpRight className='p-1.5 w-7 h-7 text-[#6B7280] border border-gray-300 rounded-md bg-white shadow' />
        ) : (
          <CornerUpRight className='p-1.5 w-7 h-7 text-[#6B7280] border border-gray-300 rounded-md bg-white shadow' />
        )}
      </div>
    );
  };
  return isAdminReplyAllowed ? renderAdminReplyUi() : null;
};

const mapState = (state: any) => ({
  selectedTicket: state.inbox.selectedTicket,
});
const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(AdminReplyToButton);
