import React from 'react';
import Modal from '../../ecommerce/common/Modal';
import SuccessIcon from '../../../../../assets/images/woocommerce_success_svg.svg';

import { Button } from '../../../../../library/index';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ResponseModalDataInterface } from 'pages/inbox/inboxInterface';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';

interface Props {
  showResponseModalLoader: boolean;
  openResponseModal: boolean;
  responseModalData: ResponseModalDataInterface;
  setShowResponseModalLoader: (data: boolean) => void;
}

const OrderResponseModal: React.FC<Props> = ({
  showResponseModalLoader,
  responseModalData,
  openResponseModal,
  setShowResponseModalLoader,
}) => {
  const renderModalButtonsView = () => {
    return (
      <div className='w-[350px] flex flex-col gap-3'>
        <Button
          isDisabled={showResponseModalLoader}
          className={`w-full justify-center`}
          onClick={() => {
            setShowResponseModalLoader(true);
            responseModalData.firstBtnAction();
          }}
          intent={`${responseModalData.success ? 'primary' : 'default'}`}
        >
          {responseModalData.firstButtonName}&nbsp;
          {showResponseModalLoader && <CircleLoader variant='secondary' />}
        </Button>

        <Button
          className={`w-full justify-center`}
          intent='default'
          onClick={() => {
            responseModalData.secondBtnAction();
          }}
        >
          {responseModalData.secondButtonName}
        </Button>
      </div>
    );
  };

  const renderModalTitleAndDesciptionView = () => {
    return (
      <div className='px-2.5 text-center'>
        <p className='mb-[8px] text-gray-900 font-medium capitalize text-[18px]'>
          {responseModalData.title}
        </p>
        <p className='text-gray-500 font-[400] mb-[18px] text-center text-[14px]'>
          {responseModalData.description}
        </p>
      </div>
    );
  };

  return (
    <div>
      <Modal open={openResponseModal} setOpen={() => {}}>
        <div className='pt-3 flex flex-col items-center w-[360px] pb-3'>
          <div className='mb-[10px]'>
            {responseModalData.success && (
              <div>
                <img src={SuccessIcon} alt='clap' />
              </div>
            )}
            {!responseModalData.success && (
              <ExclamationTriangleIcon className='bg-red-100 text-red-500 w-[48px] h-[48px] p-2 rounded-full' />
            )}
          </div>
          {renderModalTitleAndDesciptionView()}

          {renderModalButtonsView()}
        </div>
      </Modal>
    </div>
  );
};

export default OrderResponseModal;
