import React, { useState } from 'react';
import ParameterInputField from './ParameterInputField';
import Button from '../../../../library/button';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import EcommerceInputParametersDropdown from './/EcommerceInputParametersDropdown';
import SequenceDropDown from './SequenceDropDown';

interface Props {
  inputParameters: inputParametersProps[];
  hasWriteAccess: boolean;
  attributeList: string[];
  platformType: string;
  showAttributeField: number | null;
  setShowAttributeField: (val: {
    id: number;
    title: string;
    is_default: boolean;
  }) => void;
  ecommerceParameters: any[];
  handleSelectedParameters: (selectedParameter: any) => void;
  handleSelectedParamsInputValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleSelectedParamsAttributeValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleParameterDelete: (name: string, index: number) => void;
  handleSelectedSequence?: (sequenceName: string, sequenceId: number) => void;
  selectedSequence?: { id: number; title: string };
  setSelectedSequence?: (val: {
    id: number;
    title: string;
    is_default: boolean;
  }) => void;
  sequences?: sequenceProps[];
  hasSequences?: boolean;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

interface inputParametersProps {
  name: string;
  value: string;
  type: boolean | string | number;
}

const InputInstruction: React.FC<Props> = ({
  inputParameters,
  hasWriteAccess,
  attributeList,
  showAttributeField,
  platformType,
  setShowAttributeField,
  selectedSequence,
  setSelectedSequence,
  sequences,
  ecommerceParameters,
  handleSelectedParameters,
  handleSelectedParamsInputValue,
  handleSelectedParamsAttributeValue,
  handleSelectedSequence,
  handleParameterDelete,
  hasSequences = true,
}) => {
  const [addNewParameter, setAddNewParameter] = useState(false);

  const getAvailableParameters = () => {
    let availableParameter = [...ecommerceParameters];
    for (let i = availableParameter.length - 1; i >= 0; i--) {
      for (let j = 0; j < inputParameters.length; j++) {
        if (
          availableParameter[i] &&
          availableParameter[i]['verbose'] === inputParameters[j]['verbose']
        ) {
          availableParameter.splice(i, 1);
        }
      }
    }
    return availableParameter;
  };
  return (
    <>
      {inputParameters?.map((elem: any, index) => (
        <div key={index}>
          <ParameterInputField
            id={index}
            name={elem?.verbose}
            attribute={elem?.value}
            type={elem?.type}
            hasOption={elem?.has_options}
            options={elem?.options}
            platformType={platformType}
            handleParameterDelete={handleParameterDelete}
            handleSelectedParamsInputValue={handleSelectedParamsInputValue}
            handleSelectedParamsAttributeValue={
              handleSelectedParamsAttributeValue
            }
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttributeField={showAttributeField}
            setShowAttributeField={setShowAttributeField}
          />
        </div>
      ))}
      {addNewParameter && getAvailableParameters()?.length !== 0 && (
        <div className='flex justify-end'>
          <EcommerceInputParametersDropdown
            hasWriteAccess={hasWriteAccess}
            handleSelectedParameters={handleSelectedParameters}
            ecommerceParameters={getAvailableParameters()}
          />
          <Button
            icon={<TrashIcon className='text-gray-500' />}
            className='h-10 mt-3 ml-3 text-gray-700 border-gray-300'
            onClick={() => setAddNewParameter(false)}
          />
        </div>
      )}
      <div className='py-3'>
        <Button
          icon={<PlusIcon className='text-gray-500' />}
          isDisabled={addNewParameter || getAvailableParameters()?.length === 0}
          onClick={() => setAddNewParameter(true)}
          className='justify-center w-full'
        >
          Add New Parameter
        </Button>
      </div>
      <div className='border-t border-gray-200 mt-2.5 mb-5' />
      {hasSequences && (
        <SequenceDropDown
          hasWriteAccess={hasWriteAccess}
          sequences={sequences}
          selectedSequence={selectedSequence}
          setSelectedSequence={setSelectedSequence}
          handleSelectedSequence={handleSelectedSequence}
        />
      )}
    </>
  );
};

export default InputInstruction;
