import React from 'react';
import { LinearLoaderComponent } from '../../../../utilities/utils';
import { ArrowDownTrayIcon, BarsArrowUpIcon } from '@heroicons/react/20/solid';
import { Button } from '../../../../library';
import { analytics } from '../../../../utilities/content';
import {
  InformationCircleIcon,
  BarsArrowDownIcon,
} from '@heroicons/react/24/outline';
import Pagination from '../CommonComponent/Pagination';
import TagCloudTableData from './TagCloudTableData';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  title: string;
  loader: boolean;
  data: dataProps[];
  exportData: () => void;
  exportLoader: boolean;
  total: number;
  limit: number;
  offset: number;
  sort: string;
  setSort: (val: string) => void;
  handleSortedData: () => void;
  setOffset: (val: number) => void;
}

interface dataProps {
  name: string;
  count: number;
}
const TagCloud: React.FC<props> = ({
  title,
  loader,
  data,
  exportData,
  exportLoader,
  total,
  limit,
  offset,
  sort,
  setSort,
  handleSortedData,
  setOffset,
}) => {
  const { t } = useTranslation();
  const handleNextButton = () => {
    setOffset(offset + limit);
  };
  const handlePreviousButton = () => {
    setOffset(offset - limit);
  };

  return (
    <div className='flex flex-col justify-between h-full bg-white rounded-lg shadow'>
      <div>
        <div className='flex justify-between px-4 pt-5'>
          <div className='flex text-base font-normal text-gray-900'>
            {t(title)}
            <span
              className='mt-1 ltr:ml-2 rtl:mr-2'
              title={t(analytics.automationMetrics.tagCloud.toolTip)}
            >
              <InformationCircleIcon className='w-4 h-4' />
            </span>
          </div>
          <div className='flex justify-start'>
            <Button
              isDisabled={data?.length === 0}
              size='sm'
              onClick={() => {
                handleSortedData();
                sort === 'ascending'
                  ? setSort('decending')
                  : setSort('ascending');
              }}
              icon={
                sort === 'ascending' ? (
                  <BarsArrowUpIcon />
                ) : (
                  <BarsArrowDownIcon />
                )
              }
            >
              {t('Sort')}
            </Button>
            <Button
              intent='default'
              isDisabled={loader || data?.length === 0}
              size={'sm'}
              onClick={() => exportData()}
              className={'ltr:ml-4 rtl:mr-4'}
            >
              {!exportLoader && (
                <ArrowDownTrayIcon
                  className='ltr:-ml-0.5 rtl:-mr-0.5 ltr:mr-2 rtl:ml-2 h-4 w-4'
                  aria-hidden='true'
                />
              )}
              {t('Export')}
            </Button>
          </div>
        </div>
        {loader && <LinearLoaderComponent />}
        <TagCloudTableData tableData={data} />
      </div>
      {!loader && data?.length !== 0 && (
        <Pagination
          offset={offset}
          tableDataLength={data?.length}
          total={total}
          limit={limit}
          handleNextButton={handleNextButton}
          handlePreviousButton={handlePreviousButton}
        />
      )}
    </div>
  );
};

export default TagCloud;
