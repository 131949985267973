import React, { useEffect } from 'react';
import { copyClipboard } from 'utilities/utils';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import DocumentCopyIcon from './../../assets/icons/documentCopyIcon.svg';

interface Prop {
  height?: number;
  textToCopy?: any;
}

const CopyText: React.FC<Prop> = ({ height = 26, textToCopy = '' }) => {
  const [timeLeft, setTimeLeft] = React.useState<any>(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handleCopy = () => {
    copyClipboard(textToCopy);
    setTimeLeft(5);
  };

  return (
    <div onClick={handleCopy} className='cursor-pointer'>
      {timeLeft > 0 ? (
        <CheckCircleIcon
          className='text-green-400'
          style={{ height: `${height}px`, width: `${height / 1.5}px` }}
        />
      ) : (
        <img src={DocumentCopyIcon} alt='copy' width={height / 1.5} />
      )}
    </div>
  );
};

export default CopyText;
