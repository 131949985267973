import { navigate } from '@reach/router';
import { SelectedTeamInterface, UserPropertyType } from 'index';
import { connect } from 'react-redux';
import trialEnded from '../assets/images/trialEnded.svg';
import React from 'react';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  selectedProject: SelectedTeamInterface;
  auth: UserPropertyType;
  logoutAPI: () => void;
}
const TrialEndedScreen: React.FC<Props> = ({
  selectedProject,
  auth,
  logoutAPI,
}) => {
  const { currentPartnerInfo } = usePartner();
  const parnterName =
    currentPartnerInfo?.type === 'white-label'
      ? currentPartnerInfo.shortName
      : 'MyAlice';

  const supportLink =
    currentPartnerInfo?.bussinessUrl || 'https://www.myalice.ai/support';
  const renderScreenForCreator = () => {
    return (
      <>
        <p className='font-medium text-lg text-gray-900 m-4'>
          Oops! Your free trial has ended...
        </p>
        <p className='font-normal text-sm text-gray-500 text-center mb-4 max-w-[300px]'>
          Please subscribe to a paid plan to continue using {parnterName}{' '}
          without disruptions.
        </p>
        <button
          className='font-medium text-sm text-white px-3.5 py-2 mb-8 bg-primary border rounded-md cursor-pointer'
          onClick={() => navigate(`/settings/billing`)}
        >
          Upgrade your plan
        </button>
      </>
    );
  };
  const renderScreenForOtherUsers = () => {
    return (
      <>
        <p className='font-medium text-lg text-gray-900 m-4'>
          You do not have access to the team
        </p>
        <p className='font-normal text-sm text-gray-500 text-center mb-4 max-w-[350px]'>
          Please contact your team owner to access this team or switch to a
          different team or account.
        </p>
        <button
          className='font-medium text-sm text-gray-700 px-3.5 py-2 mb-8 bg-white border rounded-md cursor-pointer'
          onClick={() => logoutAPI()}
        >
          Login to a different account
        </button>
      </>
    );
  };

  return (
    <div className='flex w-full h-full justify-center items-center'>
      <div className='w-full h-full rounded border bg-white p-5 flex justify-center items-center flex-col'>
        <div className='w-[430px] h-[250px] flex flex-col items-center justify-center'>
          <img src={trialEnded} alt='' className='w-40 h-40 mb-4' />
          {selectedProject?.is_creator && renderScreenForCreator()}
          {!selectedProject?.is_creator && renderScreenForOtherUsers()}
          <div className='border-b border-gray-200 w-full mb-3'>{''}</div>
          <p className='text-gray-500 text-sm mb-1'>
            You are currently logged in as{' '}
            <span className='font-bold'>{auth.email}</span>
          </p>
          <div className='text-gray-500 text-sm'>
            If you think something is not right, please{' '}
            <a href={supportLink} target='_blank' rel='noreferrer'>
              <span className='text-blue-500'>contact support</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  auth: state.auth,
});

const mapDispatch = (dispatch: any) => ({
  logoutAPI: () => dispatch.auth.logoutAPI(),
});
export default connect(mapState, mapDispatch)(TrialEndedScreen);
