import React from 'react';
import '../../old/assets/styles/pagination.scss';

class Pagination extends React.Component {
  state = {
    firstThreeArray: [1],
    lastNumber: '',
    showEllipis: true,
  };
  componentDidMount() {
    if (this.props.totalPages <= 5) {
      let fArray = [];

      for (let i = 1; i <= this.props.totalPages; i++) {
        fArray.push(i);
      }

      this.setState({ firstThreeArray: fArray });
    } else {
      if (this.props.currentPage < 3) {
        this.setState({ firstThreeArray: [1, 2, 3] });
      } else {
        let fArray = [];
        let index = 1;
        for (let j = this.props.currentPage; j >= 0; j--) {
          fArray.push(j);
          if (index === 3) {
            break;
          }
          index++;
        }

        fArray.reverse();
        this.setState({ firstThreeArray: fArray });
      }
      this.setState({ lastNumber: this.props.totalPages });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.totalPages <= 5) {
      let fArray = [];

      for (let i = 1; i <= nextProps.totalPages; i++) {
        fArray.push(i);
      }
      this.setState({ firstThreeArray: fArray });
    } else {
      if (
        this.props.currentPage !== nextProps.currentPage ||
        this.props.totalPages !== nextProps.totalPages
      ) {
        if (nextProps.currentPage < 3) {
          this.setState({ firstThreeArray: [1, 2, 3] });
        } else {
          let fArray = [];
          fArray.push(nextProps.currentPage - 1);
          fArray.push(nextProps.currentPage);
          if (nextProps.currentPage + 1 < nextProps.totalPages) {
            fArray.push(nextProps.currentPage + 1);
          }
          if (
            nextProps.currentPage === nextProps.totalPages - 2 ||
            nextProps.currentPage === nextProps.totalPages - 1 ||
            nextProps.currentPage === nextProps.totalPages
          ) {
            this.setState({ showEllipis: false });
          } else {
            this.setState({ showEllipis: true });
          }
          this.setState({ firstThreeArray: fArray });
        }
        this.setState({ lastNumber: nextProps.totalPages });
      }
    }
  }

  prev = () => {
    if (this.props.currentPage > 1) {
      this.props.changeCurrentPage(this.props.currentPage - 1);
    }
  };

  next = () => {
    if (this.props.currentPage < this.props.totalPages) {
      this.props.changeCurrentPage(this.props.currentPage + 1);
    }
  };

  changeCurrentPage = (no) => {
    this.props.changeCurrentPage(no);
  };

  showEllipsis = () => {
    if (this.state.showEllipis) {
      return <li>...</li>;
    }
  };

  isactive = (currentPage) => {
    if (this.props.currentPage === currentPage) {
      return true;
    }
    return false;
  };

  showLastPagi = () => {
    if (this.props.currentPage !== this.props.totalPages) {
      return (
        <li className={this.isactive(this.props.totalPages) ? 'is-active' : ''}>
          <button
            onClick={() => {
              this.changeCurrentPage(this.props.totalPages);
            }}
          >
            {this.props.totalPages}
          </button>
        </li>
      );
    }
  };

  showPrev = () => {
    if (this.props.currentPage !== 1) {
      return (
        <li>
          <button className='prev-next' onClick={this.prev}>
            {'<'}
          </button>
        </li>
      );
    }
  };

  showNext = () => {
    if (this.props.currentPage < this.props.totalPages) {
      return (
        <li>
          <button className='prev-next' onClick={this.next}>
            {'>'}
          </button>
        </li>
      );
    }
  };

  render() {
    return (
      <div className='pagination'>
        <ul
          style={
            this.props.align === 'right'
              ? { justifyContent: 'flex-end' }
              : { justifyContent: 'flex-start' }
          }
        >
          {this.showPrev()}
          {this.props.totalPages <= 5 ? (
            this.state.firstThreeArray.map((no, index) => {
              return (
                <li
                  key={index}
                  className={this.isactive(no) ? 'is-active' : ''}
                >
                  <button
                    onClick={() => {
                      this.changeCurrentPage(no);
                    }}
                  >
                    {no}
                  </button>
                </li>
              );
            })
          ) : (
            <React.Fragment>
              {this.state.firstThreeArray.map((no, index) => {
                return (
                  <li
                    key={index}
                    className={this.isactive(no) ? 'is-active' : ''}
                  >
                    <button
                      onClick={() => {
                        this.changeCurrentPage(no);
                      }}
                    >
                      {no}
                    </button>
                  </li>
                );
              })}
              {this.showEllipsis()}​{this.showLastPagi()}
            </React.Fragment>
          )}
          {this.showNext()}
        </ul>
      </div>
    );
  }
}

Pagination.defaultProps = {
  currentPage: 1,
  align: 'right',
  total: 10,
};

export default Pagination;
