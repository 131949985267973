import React from 'react';
import EmptySavedRepliesPage from './components/EmptySavedRepliesPage';
import useSavedReplies from './hooks/useSavedReplies';
import { Link } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import SavedRepliesTable from './components/savedRepliesTable/SavedRepliesTable';
import { columns } from './components/savedRepliesTable/Column';
import { LoaderCircle } from 'lucide-react';
import {Link as CustomLink} from 'library';
interface SavedRepliesProps {}

const SavedReplies: React.FC<SavedRepliesProps> = () => {
  const { t } = useTranslation();
  const {
    selectedProject,
    savedReplies,
    userInfo,
    getSavedReplyList,
    isLoading,
  } = useSavedReplies();
  const isFreeUser = selectedProject?.subscription_plan?.plan_type === 'free';
  const isProjectCreator = selectedProject?.creator?.email === userInfo.email;

  const renderUpgradeToPremiumView = () => {
    return (
      <div className='w-full'>
        <div className='flex flex-col p-4 w-full align-center text-sm h-[100vh] justify-center align-center canned-response-div'>
          <div className='relative rounded-full bg-blue-100 h-[48px] w-[48px] flex justify-center align-center mb-5'>
            <LockClosedIcon className='w-[20px] h-[20px]' fill='#1E3A8A' />
          </div>
          <p className='text-gray-900'>{t('Upgrade to Premium')}</p>
          <p className='text-gray-500 '>
            {t('Add Superpower to your support arsenal!')}
          </p>
          {!isProjectCreator && (
            <p className='font-normal text-sm text-gray-500'>
              {t('Contact your team owner to upgrade subscription plan.')}
            </p>
          )}
          {isProjectCreator && (
            // @ts-ignore
            <Link
              to='/settings/billing'
              className='mt-5 inline-flex items-center text-center px-4 py-2 text-sm rounded-md border shadow-sm border-transparent bg-primary
                    hover:bg-primary-hover text-white focus:outline-none transition ease-out duration-200'
            >
              {t('Upgrade Now')}
            </Link>
          )}
        </div>
      </div>
    );
  };

  const renderPageHeader = () => {
    return (
      <div>
        <h3>Saved Replies</h3>
        <p className='mt-[6px] max-w-3xl subtitle-medium'>
          Saved Replies let you store frequently used messages with a keyword,
          making customer interactions quicker and more consistent. Access them
          in the inbox by pressing # or clicking the saved replies icon. 
          <CustomLink
            url='https://docs.myalice.ai/myalice-inbox/chat-features'
            openInNewTab={true}
            showUnderline={true}
          >
            Learn More
          </CustomLink>
        </p>
      </div>
    );
  };
  const renderEmptySavedReplyView = () => (
    <div className='w-full flex flex-col gap-10 p-6 h-[calc(100vh-8px)]'>
      {renderPageHeader()}
      <div className='flex-grow h-full w-full'>
        <EmptySavedRepliesPage />
      </div>
    </div>
  );
  const renderSavedReplyView = () => {
    if (!savedReplies || savedReplies.length === 0) {
      return renderEmptySavedReplyView();
    }
    return (
      <div className='w-full flex flex-col gap-3 p-6 h-[calc(100vh-8px)]'>
        {renderPageHeader()}
        <div className='flex-grow h-full w-full'>
          <SavedRepliesTable data={savedReplies} columns={columns} />
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    // This is a hacky solution to override old component CSS.
    // It directly modifies the padding of the '.layout__content' element.
    // When we move the settings to the new design, we will update it accordingly.
    // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
    const layoutContent: any = document.querySelector('.layout__content');
    if (layoutContent) {
      layoutContent.style.setProperty('padding', '0');
    }
    // reset padding on unmount
    return () => layoutContent?.style?.setProperty('padding', '0 20px');
  }, []);

  React.useEffect(() => {
    const fetchSavedReplies = async () => {
      await getSavedReplyList();
    };
    fetchSavedReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoadingView = () => {
    if (!savedReplies || savedReplies.length === 0) {
      return renderEmptySavedReplyView();
    }
    return (
      <div className='w-full flex flex-col gap-3 p-6 h-[calc(100vh-8px)]'>
        {renderPageHeader()}
        <div className='flex-grow h-full w-full flex justify-center items-center'>
          <LoaderCircle
            strokeWidth={2}
            className='text-green-400 w-12 h-12 animate-spin'
          />
        </div>
      </div>
    );
  };
  if (isLoading) {
    return renderLoadingView();
  }
  if (isFreeUser) {
    return renderUpgradeToPremiumView();
  } else {
    return renderSavedReplyView();
  }
};

export default SavedReplies;
