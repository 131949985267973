import { Popover, Transition } from '@headlessui/react';
import { ChevronUpIcon, CheckIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  setOrderType: (paylaod: string) => void;
  orderType: string;
}

const OrderTypeOptionPopover: React.FC<Props> = ({
  setOrderType,
  orderType,
}) => {
  const { t } = useTranslation();
  const orderOptions = [
    {
      name: 'Pending Order',
      description: 'Requires customer checkout',
      value: 'pending',
    },
    {
      name: 'Draft Order',
      description: 'Requires payment completion',
      value: 'draft',
    },
    {
      name: 'Paid Order',
      description: 'Completed order process',
      value: 'completed',
    },
  ];

  const renderOrderOptionsView = (close: Function) => {
    return (
      <>
        {orderOptions.map((item) => (
          <div
            key={item.name}
            className='border-b cursor-pointer w-full hover:bg-gray-200'
            onClick={() => {
              setOrderType(item.value);
              close();
            }}
          >
            <div className='px-2 py-2 w-full mt-2'>
              <div className='flex w-full'>
                <p className='w-1/2 text-sm font-medium text-gray-900'>
                  {t(item.name)}
                </p>
                {orderType === item.value && (
                  <div className='w-1/2 flex justify-end'>
                    <CheckIcon className='w-5 h-5 text-gray-900' />
                  </div>
                )}
              </div>
              <p className='text-sm text-gray-500'>{t(item.description)}</p>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderPopoverButtonView = (open: boolean) => {
    return (
      <Popover.Button
        className={`${
          open ? '' : 'text-opacity-90'
        } capitalize border flex gap-2 items-center
        ltr:rounded-r rtl:rounded-l bg-green-500 px-1 border-green-500 text-white w-8 py-2 h-[39px]`}
      >
        <ChevronUpIcon
          className={`${open ? '' : 'text-opacity-70'}
                  text-white w-5 h-5`}
          aria-hidden='true'
        />
      </Popover.Button>
    );
  };

  const renderPopoverPanelView = (close: Function) => {
    return (
      <Popover.Panel className='absolute z-10 mt-[-250px] ltr:right-0 rtl:left-0 w-[250px]'>
        <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
          <div className='relative flex flex-col bg-white lg:grid-cols-2'>
            {renderOrderOptionsView(close)}
          </div>
        </div>
      </Popover.Panel>
    );
  };

  return (
    <div className=''>
      <Popover className='relative'>
        {({ open, close }) => (
          <>
            {renderPopoverButtonView(open)}
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              {renderPopoverPanelView(close)}
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default OrderTypeOptionPopover;
