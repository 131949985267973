import React, { useState } from 'react';
import { connect } from 'react-redux';
import BroadcastList from './component/BroadcastList';
import CreateBroadcast from './component/CreateBroadcast';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/20/solid';
import { useDebounce } from '../../components';
import { SelectedTeamInterface } from 'index';
import { UBroadcastProperty } from 'utilities/content';
import Setup from './component/BroadCastSetup';
import {
  AddCreditsPayload,
  AddCreditsResponsePayload,
  BroadcastCreationProperty,
  BroadcastListPayload,
  BroadcastProperty,
  CSVProperty,
} from './interface';
import { UGetInfoSvgTooptipIcon } from 'utilities/content';
import AddCredits from './component/AddCredits';
import BroadcastDetails from './component/BroadcastDetails';
import Button from 'library/button';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  creditInUse: number;
  broadcastList: BroadcastProperty[];
  fetchBroadcastList: (
    payload: BroadcastListPayload
  ) => Promise<BroadcastProperty[]>;
  selectedProject: SelectedTeamInterface;
  fetchPlatformList: (projectId: any) => void;
  setSelectedProject: (payload: SelectedTeamInterface) => Promise<void>;
  userId: number;
  updateBroadcastCreationData: (
    payload: BroadcastCreationProperty[] | null
  ) => void;
  updateMappedData: (payload: CSVProperty | null) => void;
  handleAddCredits: (payload: AddCreditsPayload) => AddCreditsResponsePayload;
}

const Broadcast: React.FC<Props> = ({
  userId,
  creditInUse,
  broadcastList,
  selectedProject,
  fetchPlatformList,
  setSelectedProject,
  fetchBroadcastList,
  updateBroadcastCreationData,
  updateMappedData,
  handleAddCredits,
}) => {
  const { t } = useTranslation();
  const [openCreateBroadcast, setOpenCreateBroadcast] = useState(false);
  const [openAddCredits, setOpenAddCredits] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchDebounce = useDebounce(searchKeyword, 500);
  const [pageCount, setPageCount] = React.useState(0);
  const [limit, setLimit] = React.useState(UBroadcastProperty.perPageData);
  const [offset, setOffset] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);
  const [broadcastId, setBroadcastId] = React.useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [isDetails, setIsDetails] = React.useState(false);

  const { isPartnerRestricted } = usePartner();
  const shouldHideCreditInfo = isPartnerRestricted(
    'Broadcast/node/credit-info'
  );

  const shouldShowAddCreditButton = !isPartnerRestricted(
    'Broadcast/node/wab-add-credit'
  );

  const renderSearchBroadcastView = () => {
    return (
      <div className='relative mt-1'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3'>
          <MagnifyingGlassIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block rounded-md border-gray-300 ltr:pl-10 rtl:pr-10
                  focus:border-green-500 focus:ring-green-500 sm:text-sm w-1/2'
          placeholder={t('Search your past broadcasts')}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
    );
  };

  const broadcastCreditInUse = () => {
    return !!creditInUse ? creditInUse : 0.0;
  };

  const search = async () => {
    let payload = {
      limit: UBroadcastProperty.perPageData,
      offset: 0,
      projectId: selectedProject.id,
      title: searchDebounce,
    };
    setPageCount(0);
    setOffset(0);
    setLimit(UBroadcastProperty.perPageData);
    await fetchBroadcastList(payload);
  };

  const fetchAndUpdateStates = async () => {
    await setSelectedProject(selectedProject);
    updateBroadcastCreationData(null);
    updateMappedData(null);
    fetchPlatformList(selectedProject?.id);
  };

  React.useEffect(() => {
    fetchAndUpdateStates();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchDebounce]);

  const isProjectCreator = () => {
    return userId === selectedProject?.creator?.id;
  };

  const renderCredits = () => {
    if (shouldHideCreditInfo) {
      return null;
    }
    return (
      <div className='ltr:pr-3 rtl:pl-3 border-0 ltr:border-r-2 rtl:border-l-2 border-r-gray-300 flex flex-col justify-end'>
        <p className='leading-5 flex justify-end'>
          <span className='text-gray-700 text-sm font-bold'>
            $
            {(
              selectedProject?.creator?.broadcast_credit +
              selectedProject?.creator?.credit -
              broadcastCreditInUse()
            ).toFixed(2)}
          </span>
          &nbsp;
          <span className='text-gray-500 text-sm font-medium flex rtl:order-first rtl:ml-2'>
            {t('credits available')}
            {!isProjectCreator() &&
              UGetInfoSvgTooptipIcon(
                t('Please contact your team owner to add credits.'),
                '',
                'ltr:left-[-100px] rtl:right-[-100px]'
              )}
          </span>
        </p>
        <p className='leading-4 flex justify-end'>
          <span className='text-gray-700 text-xs font-bold'>
            ${broadcastCreditInUse().toFixed(2)}
          </span>
          &nbsp;
          <span className='text-gray-500 text-xs font-medium flex rtl:order-first rtl:ml-2'>
            {t('credits in use')}
            {UGetInfoSvgTooptipIcon(
              t(
                'The credit has been assigned for your scheduled broadcasts. In case of failure, it will be adjusted accordingly.'
              ),
              '',
              'ltr:left-[-100px] rtl:right-[-100px]'
            )}
          </span>
        </p>
      </div>
    );
  };

  const broadCastList = () => {
    return (
      <>
        <div className='flex mb-6 items-center w-auto'>
          <div className='w-1/2'>
            <p className='text-gray-900 text-lg font-semibold'>
              {t('Your Broadcasts')}
            </p>
          </div>
        </div>
        <>
          <div className='flex mb-5 items-center w-auto'>
            <div className='w-1/2'>
              <div>{renderSearchBroadcastView()}</div>
            </div>
            <div className='w-1/2 flex justify-end'>
              <div className='flex justify-end '>
                {renderCredits()}
                {userId === selectedProject?.creator?.id &&
                  shouldShowAddCreditButton && (
                    <Button
                      intent='default'
                      className='capitalize text-gray-700 ml-3'
                      onClick={() => setOpenAddCredits(true)}
                    >
                      {t('Add Credits')}
                    </Button>
                  )}
                <button
                  className='py-2 ltr:ml-3 rtl:mr-3 px-3 bg-[#04B25F] text-white capitalize rounded-md flex gap-2 items-center'
                  onClick={() => setOpenCreateBroadcast(true)}
                >
                  <PlusIcon className='w-4 h-4 text-white' />
                  {t('Create Broadcast')}
                </button>
              </div>
            </div>
          </div>
          <BroadcastList
            pageCount={pageCount}
            setPageCount={setPageCount}
            limit={limit}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            setIsEdit={setIsEdit}
            setBroadcastId={setBroadcastId}
            setOpenCreateBroadcast={setOpenCreateBroadcast}
            setOpenDetailsModal={setOpenDetailsModal}
            setIsDetails={setIsDetails}
          />
          <CreateBroadcast
            isEdit={isEdit}
            broadcastId={broadcastId}
            isOpen={openCreateBroadcast}
            setIsEdit={setIsEdit}
            setOpen={setOpenCreateBroadcast}
            isProjectCreator={isProjectCreator}
            setOpenAddCredits={setOpenAddCredits}
          />
          <BroadcastDetails
            isOpen={openDetailsModal}
            setOpen={setOpenDetailsModal}
            selectedProject={selectedProject}
            broadcastId={broadcastId}
            setIsDetails={setIsDetails}
            isDetails={isDetails}
          />
          {userId === selectedProject?.creator?.id && (
            <AddCredits
              isOpen={openAddCredits}
              setOpen={setOpenAddCredits}
              handleAddCredits={handleAddCredits}
              projectId={selectedProject.id}
              card={
                !!selectedProject && selectedProject?.creator?.card_info?.length
                  ? selectedProject?.creator?.card_info.filter(
                      (c) => c.default
                    )[0].last4
                  : ''
              }
            />
          )}
        </>
      </>
    );
  };

  const renderUI = () => {
    if (
      selectedProject?.is_whatsapp_connected &&
      selectedProject?.is_whatsapp_templates_created &&
      selectedProject?.is_card_added
    ) {
      return broadCastList();
    } else {
      return (
        <Setup
          isCardAdded={selectedProject?.is_card_added}
          isWhatsappConnected={selectedProject?.is_whatsapp_connected}
          isWhatsappTemplatesCreated={
            selectedProject?.is_whatsapp_templates_created
          }
        />
      );
    }
  };

  return (
    <div className='relative p-5 h-full' data-testid='broadcast-body'>
      {renderUI()}
    </div>
  );
};

const mapState = (state: any) => ({
  broadcastList: state.broadcast?.broadcastList,
  creditInUse: state.broadcast?.creditInUse,
  selectedProject: state.dashboard.selectedProject,
  userId: state.auth.id,
});

const mapDispatch = (dispatch: any) => ({
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchBroadcastList: (payload: BroadcastListPayload) =>
    dispatch.broadcast.fetchBroadcastList(payload),
  updateBroadcastCreationData: (payload: BroadcastCreationProperty[] | null) =>
    dispatch.broadcast.updateBroadcastCreationData(payload),
  updateMappedData: (payload: CSVProperty | null) =>
    dispatch.broadcast.updateMappedData(payload),
  handleAddCredits: (payload: AddCreditsPayload) =>
    dispatch.broadcast.handleAddCredits(payload),
  fetchPlatformList: (teamId: number) =>
    dispatch.dashboard.fetchPlatformList(teamId),
});

export default connect(mapState, mapDispatch)(Broadcast);
