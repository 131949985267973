import React from 'react';
import useTranslation from './useTranslation';

interface Props {
  text: string;
}

const TranslationWrapper: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation();
  return <span>{t(text)}</span>;
};

export default TranslationWrapper;
