import { AgentListInterface } from 'pages/inbox/inboxInterface';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ISavedReply } from '../components/SavedReplySheet';
import { REPLY_TRIGGER_CHAR } from '../utils';
import { toast } from 'libraryV2/ui/use-toast';
import { SAVED_REPLY_CUSTOM_PROJECTS } from '../contents';

export const useSavedReplies = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth);
  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );
  const isLoading = useSelector(
    (state: any) => state.loading.effects.settingsV2.fetchSavedReplies
  );
  const savedReplies: ISavedReply[] = useSelector(
    (state: any) => state?.settingsV2?.savedReplies
  );
  const savedReplySheetAction = useSelector(
    (state: any) => state?.settingsV2?.currentSavedRepliesAction
  );
  const setSavedReplySheetAction = (v: 'CREATE' | 'EDIT' | 'VIEW') =>
    dispatch.settingsV2.updateStateData({
      key: 'currentSavedRepliesAction',
      value: v,
    });

  const agentAccess: AgentListInterface[] = useSelector(
    (state: any) => state.dashboard.agentAccess
  );
  const updateSavedReplies = (replies: ISavedReply[]) => {
    dispatch.settingsV2.updateSavedReplies(replies);
  };

  const isUserRoleAdmin = () => {
    const customProjectIds = Object.values(SAVED_REPLY_CUSTOM_PROJECTS);
    // TODO: properly structure custom roles data for better readablity & maintainablity
    const customAgentRoleIds = [18, 19, 21, 22, 24, 25];
    return agentAccess?.some((agent) => {
      const isAdmin =
        agent?.admin?.email === userInfo.email && agent?.role?.name === 'Admin';
      const isCustomAdmin =
        customProjectIds.includes(selectedProject.id) &&
        customAgentRoleIds.includes(agent?.role?.id);
      return isAdmin || isCustomAdmin;
    });
  };

  const getSavedReplyList = async () => {
    const teamId = selectedProject?.id;
    dispatch.settingsV2.fetchSavedReplies(teamId);
  };

  const createNewSavedReply = async (replyData: ISavedReply) => {
    const formatedData = {
      attachments: replyData.attachments,
      title: replyData.keyword.replace(REPLY_TRIGGER_CHAR + ' ', ''),
      text: replyData.replyText,
      for_team: replyData.visibleTo === 'everyone',
      tag_ids: [],
    };
    const payload = {
      projectId: selectedProject?.id,
      data: formatedData,
    };
    return dispatch.settingsV2.createNewSavedReply(payload);
  };

  const deleteSavedReply = async (replyIds: number | number[]) => {
    if (!Array.isArray(replyIds)) {
      replyIds = [replyIds];
    }
    const payload = {
      projectId: selectedProject?.id,
      selectedSavedReplies: replyIds,
    };
    try {
      const result = await dispatch.settingsV2.deleteSavedReplies(payload);
      toast({
        duration: 3 * 1000,
        title: <p className='text-green-500'>Delete Successful</p>,
        description:
          replyIds?.length === 1
            ? 'Saved reply has been permanently deleted.'
            : `${replyIds?.length} saved replies has been deleted successfully.`,
      });
      return result;
    } catch (error) {
      toast({
        duration: 3 * 1000,
        title: <p className='text-red-500'>Unable to delete Save reply</p>,
        description: `Something went wrong while creating save reply. Please try again`,
      });
      return { success: false };
    }
  };

  const editSavedReply = async (updatedReply: ISavedReply) => {
    const targetSavedReply = savedReplies.find(
      (r) => r.id === updatedReply?.id
    );
    if (!targetSavedReply) {
      return;
    }
    const formatedData = {
      attachments: updatedReply.attachments,
      title: updatedReply.keyword.replace('# ', ''),
      text: updatedReply.replyText,
      for_team: !(updatedReply.visibleTo === 'only_me'),
      tag_ids: [],
    };
    const payload = {
      projectId: targetSavedReply?.project?.id,
      cannedResponseId: targetSavedReply?.id,
      data: formatedData,
    };
    try {
      const result = await dispatch.settingsV2.editSavedReply(payload);
      toast({
        duration: 3 * 1000,
        title: <p className='text-green-500'>Saved Changes</p>,
        description: 'All changes has been saved successfully.',
      });
      return result;
    } catch (error) {
      toast({
        duration: 3 * 1000,
        title: <p className='text-red-500'>Unable to delete Save reply</p>,
        description: `Something went wrong while creating save reply. Please try again`,
      });
      return { success: false };
    }
  };

  return {
    isLoading,
    selectedProject,
    agentAccess,
    userInfo,
    isAdminUser: isUserRoleAdmin(),
    isUserRoleAdmin,
    createNewSavedReply,
    getSavedReplyList,
    savedReplies,
    updateSavedReplies,
    deleteSavedReply,
    savedReplySheetAction,
    setSavedReplySheetAction,
    editSavedReply,
  };
};

export default useSavedReplies;
