import { Disclosure } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utilities/utils';
import { navigate } from '@reach/router';

interface Props {
  title: string;
  btnUrl: string;
  btnText: string;
  description: string;
  isCompleted: boolean;
}

const Accordion: React.FC<Props> = ({
  title,
  btnUrl,
  btnText,
  description,
  isCompleted,
}) => {
  return (
    <>
      {isCompleted && (
        <div
          className={`flex items-center py-1 px-4 mt-4 border rounded-lg border-gray-200 bg-gray-100`}
        >
          <CheckCircleIcon
            className='mr-2'
            color='#04B25F'
            width={20}
            height={20}
          />

          <span className='text-base font-normal leading-5 text-gray-700 my-3'>
            {title}
          </span>
          <span className='text-xs leading-4 font-medium text-green-800 rounded py-1 px-2 bg-green-100 ml-auto'>
            Completed
          </span>
        </div>
      )}

      {!isCompleted && (
        <Disclosure as='div' className='mt-4 shadow-sm rounded-lg'>
          {({ open }) => (
            <>
              <Disclosure.Button className='w-full'>
                <div
                  className={`flex items-center py-1 px-4 ${
                    open
                      ? 'border-t border-l border-r rounded-t-lg'
                      : 'border rounded-lg'
                  } border-gray-200 `}
                >
                  <span className='h-4 w-4 rounded-full bg-white mr-3 border border-gray-200'></span>
                  <span className='text-base font-normal leading-5 text-gray-700 my-3'>
                    {title}
                  </span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? 'rotate-180 transform' : '',
                      'float-right ml-auto'
                    )}
                    color='#6B7280'
                    height={30}
                    width={30}
                  />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className='text-gray-500'>
                <div className='border border-gray-200 rounded-b-lg p-4'>
                  <span className='block text-sm font-normal leading-5 text-gray-500 mx-2'>
                    {description}
                  </span>
                  <button
                    onClick={() => {
                      navigate(btnUrl);
                    }}
                    className='block rounded-md mx-2 mt-4 p-2 text-white bg-green-500'
                  >
                    {btnText}
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

export default Accordion;
