import React, { Fragment } from 'react';
import { BlocksData } from '../../../../utilities/content';
import TextVariableInputField from './TextVariableInputFeild';
import OperationWarning from '../commonElements/OperationWarning';

interface Props {
  inputInstructionsData: dataProps[];
  type: string;
  hasWriteAccess: boolean;
  attributeList: string[];
  emptyFields: string;
  showAttribute: number | string;
  setShowAttribute: (val: any) => void;
  handleTextInputValue: (changeKey: any, val: string) => void;
  handleSelectedFieldsAttributeValue: (changeKey: any, val: string) => void;
}

interface dataProps {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  shipping_address: string;
  shipping_method: string;
  shipping_cost: string;
  payment_method: string;
  output_message: string;
}

const PlaceOrderCustomerInformation: React.FC<Props> = ({
  inputInstructionsData,
  type,
  attributeList,
  emptyFields,
  hasWriteAccess,
  showAttribute,
  setShowAttribute,
  handleTextInputValue,
  handleSelectedFieldsAttributeValue,
}) => {
  return (
    <>
      <div className='flex'>
        <span className='flex-1 text-xs font-medium text-gray-500'>Key</span>
        <span className='flex-1 ml-3 text-xs font-medium text-gray-500'>
          Value
        </span>
      </div>
      {Object.entries(inputInstructionsData).map((item: any, index: number) => (
        <Fragment key={index}>
          {item[0] !== 'output_message' && (
            <div className='flex'>
              <div className='flex-1 h-10 mt-3 mr-3 border border-gray-300 border-solid rounded bg-gray-50'>
                <h3 className='py-2 ml-3 text-sm text-gray-700'>
                  {BlocksData.placeOrderBlock.customerInformation[item[0]]}
                </h3>
              </div>
              <div className='flex-1 h-10 mt-3 rounded'>
                <TextVariableInputField
                  id={index}
                  value={item[1]}
                  type={type}
                  attributeList={attributeList}
                  placeHolder={'Add text and variable'}
                  showAttribute={showAttribute === item[0]}
                  setShowAttribute={setShowAttribute}
                  handleOnChange={(value: string) =>
                    handleTextInputValue(item[0], value)
                  }
                  handleSelect={(value: string) =>
                    handleSelectedFieldsAttributeValue(item[0], value)
                  }
                  hasWriteAccess={hasWriteAccess}
                />
              </div>
            </div>
          )}
        </Fragment>
      ))}
      <div className='mt-3'>
        {emptyFields.length !== 0 && (
          <OperationWarning
            warningMessage={`${BlocksData.placeOrderBlock.operationWarning} ${emptyFields}. Please add a value or variable to the attribute to continue`}
          />
        )}
      </div>
    </>
  );
};
export default PlaceOrderCustomerInformation;
