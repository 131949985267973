import React from 'react';
import ReactTooltip from 'react-tooltip';

type Place = 'top' | 'right' | 'bottom' | 'left';
interface Prop {
  text: string;
  dataFor: string;
  dataTip: string;
  className?: string;
  toolTipPosition?: Place;
  shouldShowTooltip?: boolean;
}

const TextWithTooltip: React.FC<Prop> = ({
  text,
  dataFor,
  dataTip,
  className,
  toolTipPosition = 'bottom',
  shouldShowTooltip = true,
}) => {
  return (
    <div className='w-[95%]'>
      <span
        data-for={dataFor}
        data-tip={dataTip}
        className={`block truncate ... ${className}`}
      >
        {text}
      </span>
      {shouldShowTooltip && (
        <ReactTooltip
          id={dataFor}
          type='dark'
          effect='solid'
          backgroundColor='#18181B'
          multiline={true}
          place={toolTipPosition}
          className='w-auto break-words whitespace-pre-wrap sm:text-sm'
        />
      )}
    </div>
  );
};

export default TextWithTooltip;
