import {
  React,
  Popover,
  UtilityContents,
  UtilityFunctions,
  useAutomationWorkflow,
  EllipsisHorizontalIcon,
  useUpdateWorkflowServices,
  cn,
} from '../../../../export';

interface Props {
  nodeId: string;
  nodeType: 'trigger-node' | 'action-node' | 'condition-node';
  onDelete?: any;
  children: React.ReactElement;
}

const NodeDecorator: React.FC<Props> = ({
  children,
  nodeType,
  nodeId,
  onDelete,
}) => {
  const { handleSelectedWorkflowForEdit, findWorkflowNodeById } =
    useUpdateWorkflowServices();
  const { updatePrimitiveStateData, isViewLog } = useAutomationWorkflow();
  const shouldRenderNodeActions = !isViewLog;

  const renderNodeActions = () => {
    return (
      <Popover className='relative'>
        <Popover.Button>
          <EllipsisHorizontalIcon className='w-6 h-6 text-white cursor-pointer' />
        </Popover.Button>
        <Popover.Panel className='absolute right-0 z-10 text-gray-800 bg-white rounded-md drop-shadow-lg top-[23px]'>
          <button
            className='flex w-full px-4 pt-1 cursor-pointer rounded-t-md hover:bg-gray-100'
            onClick={() => handleSelectedWorkflowForEdit(nodeId, nodeType)}
          >
            Edit
          </button>
          {/* <button className='px-4 pt-2 pb-1 cursor-pointer hover:bg-gray-100'>
            Duplicate
          </button> */}
          <button
            className='flex w-full px-4 pt-1 pb-2 cursor-pointer rounded-b-md hover:bg-gray-100'
            onClick={() => {
              // this state update is responsible for delete confirmation modal. The alert modal is commented out because
              // it is not working as expected, we have to find out solutions for this
              updatePrimitiveStateData({
                key: 'selectedNodeIDForDelete',
                value: nodeId,
              });
              onDelete();
            }}
          >
            Delete
          </button>
        </Popover.Panel>
      </Popover>
    );
  };

  const getNodeHeaderBgAndTextColor = () => {
    const currentNodeInfo = findWorkflowNodeById(nodeId);
    if (!currentNodeInfo || !isViewLog || nodeType === 'trigger-node') {
      return {
        containerStyle: {},
        iconFillColor: '',
      };
    }
    const currentNodeResult =
      nodeType === 'action-node'
        ? currentNodeInfo?.data?.component_computation_info
        : currentNodeInfo?.component_computation_info;

    const hasNoResult = currentNodeResult === undefined;
    if (!hasNoResult) {
      return {
        containerStyle: {},
        iconFillColor: '',
      };
    }
    return {
      containerStyle: {
        backgroundColor: '#f4f4f5',
        color: '#71717a',
      },
      iconFillColor: '#71717A',
    };
  };

  const { containerStyle, iconFillColor } = getNodeHeaderBgAndTextColor();

  const renderNodeHeadView = () => {
    return (
      <div
        className={cn([
          `cursor-default px-4 py-3 rounded-t-2xl flex justify-between text-white ${UtilityFunctions.nodeItemsData[nodeType].background}  `,
        ])}
        style={containerStyle}
      >
        <div className='flex items-center capitalize'>
          <>
            {/* @ts-ignore */}
            {UtilityFunctions.nodeItemsData[nodeType]?.renderIcon(
              iconFillColor
            )}
          </>
          <label className='ml-3'>
            {UtilityFunctions.nodeItemsData[nodeType].label}
          </label>
        </div>
        {nodeType !== 'trigger-node' &&
          shouldRenderNodeActions &&
          renderNodeActions()}
      </div>
    );
  };

  const renderChildrenComponentView = () => {
    return (
      <div className={`${UtilityContents.CildrenWrapperClass}`}>{children}</div>
    );
  };
  getNodeHeaderBgAndTextColor();
  return (
    <div className='h-auto w-[360px]'>
      {renderNodeHeadView()}
      {renderChildrenComponentView()}
    </div>
  );
};

export default NodeDecorator;
