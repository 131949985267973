import React, { Fragment } from 'react';
import { TextInput } from 'evergreen-ui';
import AttributeAPIContainer from '../AttributeAPIContainer';

interface Props {
  id: number | string;
  value: string;
  type: string;
  placeHolder?: string;
  attributeList: string[];
  showAttribute: boolean;
  disabled?: boolean;
  setShowAttribute: (val: any) => void;
  handleSelect: (val: string) => void;
  handleOnChange: (val: string) => void;
  hasWriteAccess: boolean;
  isDanger?: boolean;
}

const TextVariableInputField: React.FC<Props> = ({
  id,
  value,
  type,
  disabled = false,
  handleOnChange,
  isDanger,
  placeHolder,
  attributeList,
  showAttribute,
  setShowAttribute,
  handleSelect,
  hasWriteAccess,
}) => {
  return (
    <Fragment key={id}>
      <AttributeAPIContainer
        attributeList={attributeList}
        showAttribute={showAttribute}
        setShowAttribute={setShowAttribute}
        handleSelect={handleSelect}
      >
        <TextInput
          name='text-input-name'
          placeholder={placeHolder}
          value={value}
          height={40}
          autoComplete={'off'}
          disabled={disabled}
          width={'100%'}
          className={`border rounded ${
            isDanger ? 'border-red-600' : 'border-gray-300'
          } text-gray-500 focus:outline-none focus:ring-1 ${
            isDanger ? 'focus:ring-red-500' : 'focus:ring-gray-400'
          }`}
          onChange={(e: any) => {
            handleOnChange(e.target.value);
          }}
        />
      </AttributeAPIContainer>
    </Fragment>
  );
};

export default TextVariableInputField;
