import { Dialog, Transition } from '@headlessui/react';
import { SelectedTeamInterface } from 'index';
import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { BroadcastProperty } from '../interface';
import Spinner from './Spinner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  deleteBroadcast: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => { success: boolean; data: any };
  selectedProject: SelectedTeamInterface;
  broadcastId: string | null;
  isOpen: boolean;
  setIsOpen: (payload: boolean) => void;
  broadcastData: BroadcastProperty | null;
  setBroadcastData: (payload: BroadcastProperty | null) => void;
}

const DeleteModal: React.FC<Props> = ({
  deleteBroadcast,
  selectedProject,
  broadcastId,
  isOpen,
  setIsOpen,
  broadcastData,
  setBroadcastData,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const callDelete = async () => {
    setIsLoading((old) => !old);
    if (!broadcastId) {
      return;
    }

    let payload = {
      projectId: selectedProject?.id,
      data: { broadcast_id: broadcastId },
    };
    try {
      const res = await deleteBroadcast(payload);
      if (res.success) {
        setBroadcastData(null);
        window.location.reload();
        closeModal();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className=' flex flex-col items-center w-full max-w-md transform overflow-hidden rounded-2xl
                bg-white p-6 text-left align-middle shadow-xl transition-all'
                >
                  <ExclamationTriangleIcon className='bg-red-100 text-red-500 w-10 h-10 p-2 rounded-full mb-5' />
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    {t('Delete Broadcast')}
                  </Dialog.Title>
                  <div className='mt-2 rtl:text-right'>
                    <p className='text-sm text-gray-500 mb-3'>
                      {t('You’re about to delete a campaign called')}{' '}
                      <span className='font-bold capitalize'>
                        {' '}
                        {broadcastData && broadcastData.title}
                      </span>
                      .{' '}
                      {t(
                        'This action cannot be undone. Do you want to continue?'
                      )}
                    </p>
                  </div>
                  <button
                    disabled={isLoading}
                    type='button'
                    className='mb-2 capitalize w-full inline-flex gap-2 justify-center items-center rounded-md border disabled:cursor-not-allowed border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white'
                    onClick={() => {
                      callDelete();
                    }}
                  >
                    <span>{isLoading ? t('Deleting') : t('Delete')}</span>
                    <span>
                      {isLoading ? (
                        <Spinner color='border-r-transparent border-t-red-400 border-b-red-400 border-l-red-400 border-3' />
                      ) : null}
                    </span>
                  </button>
                  <button
                    type='button'
                    className='capitalize w-full inline-flex justify-center rounded-md border border-gray-200 text-gray-500 bg-white-500 px-4 py-2 text-sm'
                    onClick={() => {
                      if (broadcastId) {
                        closeModal();
                      }
                    }}
                  >
                    {t('Cancel')}
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  deleteBroadcast: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => dispatch.broadcast.deleteBroadcast(payload),
});

export default connect(mapState, mapDispatch)(DeleteModal);
