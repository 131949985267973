import React from 'react';
import { Button, SideSheet } from 'evergreen-ui';
import filterIcon from '../../../assets/images/filter_icon.svg';
import Collapse from '../../../components/elements/redesign/Collapse';
import {
  getPlatformInfoTitle,
  getUpdatedPlatformIconOutlined,
} from '../../../../utilities/utils';
import Select, { components } from 'react-select';
import TagsComponent from '../../../components/elements/redesign/TagRedesign';
import DateTimeSelect from '../../../../utilities/DateTimeSelect';
import useTranslation from 'components/customHooks/useTranslation';

interface selectProps {
  value: any;
  label: string;
}

interface Prop {
  isFilterShown: boolean;
  channelList: any[];
  filterData: any;
  tags: any[];
  agentGroup: any[];
  assignableAdmins: [];
  oldFilterData: any;
  isResolved: boolean;
  onFilterVisibilityChange: (visibility: boolean) => void;
  updateFilterData: (key: string, value: any) => void;
  updateStateData: (key: string, value: any) => void;
  handleOnFilter: () => void;
  handleReset: () => void;
}

const groupStyles = {};

const Group = (props: any) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

const FilterLeftBar: React.FC<Prop> = ({
  isFilterShown,
  channelList,
  filterData,
  tags,
  agentGroup,
  assignableAdmins,
  oldFilterData,
  isResolved,
  updateFilterData,
  updateStateData,
  handleOnFilter,
  onFilterVisibilityChange,
  handleReset,
}) => {
  const { t } = useTranslation();
  const handleChannelAdd = (ch: any) => {
    if (
      filterData.channels.filter((channel: any) => channel.id === ch.id)
        .length > 0
    ) {
      // if it already exist remove
      let localChannelList = [...filterData.channels];
      localChannelList = localChannelList.filter(
        (channel: any) => channel.id !== ch.id
      );
      updateFilterData('channels', localChannelList);
    } else {
      //add
      let localChannelList = [...filterData.channels, ch];
      updateFilterData('channels', localChannelList);
    }
  };

  const selectAllPlatform = () => {
    updateFilterData('channels', []);
  };

  const selectAllTags = () => {
    updateFilterData('tags', []);
  };

  const handleTagAdd = (tag: any) => {
    updateFilterData('tags', [...filterData.tags, tag]);
  };

  const handleIsResolved = (value: number) => {
    updateFilterData('isResolved', value);
  };

  const getLocalTagData = () => {
    let localTag = [{ value: 'all', label: 'All Tags' }];
    tags.forEach((tag: any) => {
      if (
        filterData.tags.filter((localTag: any) => localTag.value === tag.id)
          .length === 0
      ) {
        localTag = [...localTag, { value: tag.id, label: tag.name }];
      }
    });

    return localTag;
  };

  const getLocalAgentAdmin = () => {
    let agentOptions: any = [];
    let groupOptions: any = [];
    if (!!assignableAdmins) {
      assignableAdmins.forEach((elem: any) => {
        if (
          filterData.agents.filter((agent: any) => agent.id === elem.id)
            .length === 0
        ) {
          agentOptions = [
            ...agentOptions,
            {
              ...elem,
              value: elem.admin.id,
              label: elem.admin.full_name,
              type: 'agent',
            },
          ];
        }
      });
    }
    if (!!agentGroup) {
      agentGroup.forEach((group: any) => {
        if (
          filterData.groups.filter(
            (groupLocal: any) => groupLocal.id === group.id
          ).length === 0
        ) {
          // ^ this removes existing options
          groupOptions = [
            ...groupOptions,
            { ...group, value: group.id, label: group.name, type: 'group' },
          ];
        }
      });
    }

    let data = [
      {
        label: t('General'),
        options: [
          {
            value: 'all',
            label: 'All Assigned Agents/Groups',
            type: 'general',
          },
        ],
      },
      {
        label: 'Agents',
        options: agentOptions,
      },
      {
        label: 'Groups',
        options: groupOptions,
      },
    ];
    return data;
  };

  const handleAgentGroupSelect = (option: any) => {
    if (option?.type === 'general') {
      // all or none
      if (option.value === 'all') {
        updateStateData('filterData', {
          ...filterData,
          agentSelection: 'all',
          groupSelection: 'all',
          groups: [],
          agents: [],
        });
      } else if (option.value === 'none') {
        updateStateData('filterData', {
          ...filterData,
          agentSelection: 'none',
          groupSelection: 'none',
          groups: [],
          agents: [],
        });
      }
    } else if (option?.type === 'agent') {
      updateStateData('filterData', {
        ...filterData,
        agentSelection: '',
        groupSelection: '',
        agents: [...filterData.agents, option],
      });
    } else if (option?.type === 'group') {
      updateStateData('filterData', {
        ...filterData,
        agentSelection: '',
        groupSelection: '',
        groups: [...filterData.groups, option],
      });
    }
  };

  const removeTag = (tagParams: selectProps) => {
    const tagLocal = filterData.tags.filter(
      (tag: selectProps) => tag.value !== tagParams.value
    );
    updateFilterData('tags', tagLocal);
  };

  const removeGroup = (option: any) => {
    const localGroup = filterData.groups.filter(
      (group: any) => group.value !== option.value
    );
    if (localGroup.length === 0 && filterData.agents.length === 0) {
      updateStateData('filterData', {
        ...filterData,
        agentSelection: 'all',
        groupSelection: 'all',
        groups: localGroup,
      });
    } else {
      updateFilterData('groups', localGroup);
    }
  };

  const removeAgent = (option: any) => {
    const localAgent = filterData.agents.filter(
      (agent: any) => agent.value !== option.value
    );
    if (localAgent.length === 0 && filterData.groups.length === 0) {
      updateStateData('filterData', {
        ...filterData,
        agentSelection: 'all',
        groupSelection: 'all',
        agents: localAgent,
      });
    } else {
      updateFilterData('agents', localAgent);
    }
  };

  return (
    <>
      <SideSheet
        isShown={isFilterShown}
        width={450}
        onCloseComplete={() => {
          onFilterVisibilityChange(false);
          if (!!oldFilterData) {
            updateStateData('filterData', oldFilterData);
            updateStateData('oldFilterData', null);
          }
        }}
        containerProps={{ className: 'overflow-y-scroll' }}
      >
        <div className='crm-filter__container'>
          <div className='crm-filter__header'>
            <Button
              className='alice-btn__default'
              disabled={true}
              height={40}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <img src={filterIcon} width={20} alt={'filter-icon'} />
            </Button>
            <div>
              <Button
                marginRight={10}
                className='alice-btn__default'
                onClick={() => {
                  updateStateData('oldFilterData', null);
                  setTimeout(() => {
                    updateStateData('filterData', {
                      channels: [],
                      startDate: '',
                      endDate: '',
                      tags: [],
                      agents: [],
                      groups: [],
                      agentSelection: 'all',
                      groupSelection: 'all',
                    });
                    setTimeout(async () => {
                      await handleReset();
                    }, 0);
                  }, 0);
                }}
              >
                Reset
              </Button>
              <Button
                className='alice-btn__secondary'
                onClick={() => {
                  handleOnFilter();
                }}
              >
                Filter
              </Button>
            </div>
          </div>
          <br />
          <Collapse header={'Ticket Status'} intent='active'>
            <>
              <Button
                className={
                  filterData.isResolved === 0
                    ? 'alice-btn__secondary-outlined'
                    : 'alice-btn__default-outlined'
                }
                onClick={() => handleIsResolved(0)}
                height={40}
                marginTop={5}
                marginBottom={5}
                marginRight={10}
              >
                Pending
              </Button>
              <Button
                className={
                  filterData.isResolved === 1
                    ? 'alice-btn__secondary-outlined'
                    : 'alice-btn__default-outlined'
                }
                onClick={() => handleIsResolved(1)}
                height={40}
                marginTop={5}
                marginBottom={5}
                marginRight={10}
              >
                Resolved
              </Button>
            </>
          </Collapse>
          <br />
          <Collapse header={'Channels'} intent='active'>
            <>
              <Button
                className={
                  filterData.channels.length === 0
                    ? 'alice-btn__secondary-outlined'
                    : 'alice-btn__default-outlined'
                }
                onClick={() => selectAllPlatform()}
                height={40}
                marginTop={5}
                marginBottom={5}
                marginRight={10}
              >
                ALL Channels
              </Button>
              {!!channelList &&
                channelList.map(
                  (channel, i) =>
                    !channel.is_archived && (
                      <Button
                        className={
                          filterData.channels.length === 0
                            ? 'alice-btn__default-outlined'
                            : filterData.channels.filter(
                                (elem: any) => elem.id === channel.id
                              ).length > 0
                            ? 'alice-btn__secondary-outlined'
                            : 'alice-btn__default-outlined'
                        }
                        height={40}
                        key={i}
                        marginTop={5}
                        marginBottom={5}
                        marginRight={10}
                        onClick={() => handleChannelAdd(channel)}
                      >
                        <img
                          src={getUpdatedPlatformIconOutlined(channel?.type)}
                          width={16}
                          alt={'channel'}
                        />
                        &nbsp; &nbsp;{getPlatformInfoTitle(channel)}
                      </Button>
                    )
                )}
            </>
          </Collapse>
          <br />
          <Collapse header={'Time'} isOpen={false} intent='active'>
            <DateTimeSelect
              title='Start Date'
              showTimeSelect={true}
              enableDefaultDate={false}
              popUpPosition={'bottom-end'}
              value={filterData.startDate}
              placeholder={'Select Start Date'}
              handleOnchange={(value: string) =>
                updateFilterData('startDate', value)
              }
              className={'bg-gray-100 border-none text-gray-900'}
            />
            <div className='mt-20px'>
              <DateTimeSelect
                title='End Date'
                showTimeSelect={true}
                enableDefaultDate={false}
                popUpPosition={'bottom-end'}
                value={filterData.endDate}
                placeholder={'Select End Date'}
                handleOnchange={(value: string) =>
                  updateFilterData('endDate', value)
                }
                className={'bg-gray-100 border-none text-gray-900'}
              />
            </div>
          </Collapse>
          <br />
          <Collapse
            header={'Assign Agent/Group'}
            isOpen={false}
            intent='active'
          >
            <>
              <div className='flex flex-wrap'>
                {filterData.agentSelection === 'all' && (
                  <TagsComponent
                    data={{ value: 'all', label: 'All Agents Selected' }}
                  />
                )}
                {filterData.agentSelection === 'none' && (
                  <TagsComponent
                    data={{ value: 'all', label: 'No Agent Selected' }}
                  />
                )}
                {filterData.groupSelection === 'all' && (
                  <TagsComponent
                    data={{ value: 'all', label: 'All Groups Selected' }}
                  />
                )}
                {filterData.groupSelection === 'none' && (
                  <TagsComponent
                    data={{ value: 'all', label: 'No Group Selected' }}
                  />
                )}
                {filterData.agents.map((agent: any, i: number) => (
                  <TagsComponent
                    key={i}
                    data={agent}
                    isClosable={true}
                    size={12}
                    handleOnClose={(agent: any) => removeAgent(agent)}
                  />
                ))}
                {filterData.groups.map((group: any, i: number) => (
                  <TagsComponent
                    key={i}
                    data={group}
                    isClosable={true}
                    size={12}
                    handleOnClose={(group: any) => removeGroup(group)}
                  />
                ))}
              </div>
              <Select
                className='rules-select mt-10px'
                isSearchable={true}
                value={null}
                classNamePrefix='rules-select'
                components={{ Group }}
                onChange={(option: any) => {
                  handleAgentGroupSelect(option);
                }}
                placeholder={t('Select Agents/Group')}
                options={getLocalAgentAdmin()}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#eebb4d4d',
                    primary: '#eebb4d',
                    primary50: '#eebb4d',
                  },
                })}
              />
            </>
          </Collapse>
          <br />
          <Collapse header={'Tags'} isOpen={false} intent='active'>
            <>
              {filterData.tags.length === 0 && (
                <TagsComponent data={{ value: [], label: 'All Tags' }} />
              )}
              <div className='flex flex-wrap'>
                {filterData.tags.map((tag: any, i: number) => (
                  <TagsComponent
                    key={i}
                    data={tag}
                    isClosable={true}
                    size={12}
                    handleOnClose={(tag: any) => removeTag(tag)}
                  />
                ))}
              </div>
              <Select
                className='rules-select mt-10px'
                isSearchable={true}
                value={null}
                classNamePrefix='rules-select'
                onChange={(option: any) => {
                  if (option.value === 'all') {
                    selectAllTags();
                  } else {
                    handleTagAdd(option);
                  }
                }}
                placeholder={t('Select Tags')}
                options={getLocalTagData()}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#eebb4d4d',
                    primary: '#eebb4d',
                    primary50: '#eebb4d',
                  },
                })}
              />
            </>
          </Collapse>
        </div>
      </SideSheet>
    </>
  );
};

export default FilterLeftBar;
