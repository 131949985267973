import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Link } from '@reach/router';
import { TicketInterface } from 'pages/inbox/inboxInterface';
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../../library/button';
import Modal from '../common/Modal';

interface Prop {
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: 'warning' | 'success' | 'info' | 'danger';
  title?: string | number;
  description: string;
  onCancelCallback?: any;
  onDeleteCallback?: (projectId: number, orderId: string, data: any) => boolean;
  selectedTicket: TicketInterface;
  deleteTitle?: string;
  cancelTitle?: string;
  hasConfirmText?: boolean;
  hasLink?: boolean;
  confirmText?: 'CANCEL' | 'REMOVE' | 'REFUND';
  projectId: number;
  orderId: string;
  isLoading: boolean;
}

const OrderEditOptionModal: React.FC<Prop> = ({
  isShown,
  setShown,
  intent,
  title,
  description,
  onCancelCallback,
  onDeleteCallback,
  selectedTicket,
  deleteTitle = 'Delete',
  cancelTitle = 'Cancel',
  hasConfirmText = false,
  hasLink = false,
  confirmText,
  projectId,
  orderId,
  isLoading,
}) => {
  const [confirmCheck, setConfirmCheck] = React.useState<string>('');
  const selectedPlatformId = useSelector(
    (state: any) => state.ecommerce.selectedPlatformId
  );

  const handleAction = async () => {
    const payload = {
      platform_id: selectedPlatformId,
    };
    if (hasConfirmText) {
      if (confirmCheck?.toUpperCase() === confirmText) {
        if (!!onDeleteCallback) {
          const res = await onDeleteCallback(projectId, orderId, payload);
          if (res) {
            setShown(false);
            setConfirmCheck('');
          }
        }
      } else {
        setConfirmCheck('');
      }
    } else {
      if (!!onDeleteCallback) {
        const res = await onDeleteCallback(projectId, orderId, payload);
        if (res) {
          setShown(false);
        }
      }
    }
  };

  const renderCancelButton = () => {
    return (
      <Button
        data-testid='cancel-button'
        className='flex justify-center'
        onClick={() => {
          if (!!onCancelCallback) {
            onCancelCallback();
            setConfirmCheck('');
          }
          setShown(false);
        }}
        isLoading={false}
      >
        {cancelTitle}
      </Button>
    );
  };

  const renderConfirmButton = () => {
    return (
      <>
        {hasLink ? (
          <Link to='/settings/marketplace'>
            <button className='px-4 py-2 border border-red-500 bg-red-500 text-white rounded-md'>
              {deleteTitle}
            </button>
          </Link>
        ) : (
          <>
            <Button
              data-testid='delete-button'
              isLoading={isLoading}
              isDisabled={confirmText !== confirmCheck}
              className={'flex justify-center'}
              onClick={() => handleAction()}
              intent='danger'
            >
              {deleteTitle}
            </Button>
          </>
        )}
      </>
    );
  };

  const renderConfirmTextInput = () => {
    return (
      <>
        {hasConfirmText && !hasLink && (
          <div className='flex flex-col items-center justify-center'>
            <input
              data-testid='confirm-input'
              className='p-2 border border-gray-200 rounded-md w-full mb-4 focus:border-green-500 focus:ring-0 outline-none'
              value={confirmCheck}
              placeholder={`Type ${confirmText} Here`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmCheck(e.target.value);
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <Modal open={isShown} setOpen={setShown}>
        <div className='w-[344px] p-3'>
          <div className='flex flex-col justify-center items-center w-full'>
            <div className='flex flex-col justify-start items-center gap-2'>
              <ExclamationTriangleIcon className='w-12 h-12 text-red-500 bg-red-100 p-3 rounded-full' />
              <p className='text-gray-800 mb-2 font-semibold text-lg'>
                {title}
              </p>
            </div>
          </div>
          <p className='text-center'>{description}</p>
          <br />
          {renderConfirmTextInput()}
          <div className='flex flex-col gap-2'>
            {renderConfirmButton()}
            {renderCancelButton()}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderEditOptionModal;
