import React from 'react';
import DropDownList from '../components/DropDownList';
import WebChatPreview from '../../../assets/component/WebChatPreview';
import ImageUploadField from '../../../../../components/utilityComponent/ImageUploadField';

import { ChromePicker } from 'react-color';
import { handleImageUpload } from '../../../../../utilities/utils';
import { CheckIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { applicationSettingDataProps } from '../../../interface';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  actionType?: string; //'create' or 'update'
  errorMessage?: string;
  applicationSettingData: applicationSettingDataProps;
  handleChange: (key: string, value: string | string[]) => void;
  integratedNlpList: { id: string; name: string; provider: string }[];
}

const ApplicationSetting: React.FC<Props> = ({
  success = true,
  errorMessage,
  handleChange,
  integratedNlpList,
  actionType = 'create',
  applicationSettingData,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) =>
        item.id === applicationSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );
  const [showColorPallete, setShowColorPallete] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState(
    applicationSettingData.primary_color
  );
  let getSelectedColor = !!selectedColor ? selectedColor : '#04B25F';
  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='chat_avatar'
            className='block mb-1 text-sm font-medium text-gray-700'
          >
            {t('Chat Avatar')}
          </label>
          <ImageUploadField
            image={applicationSettingData.avatar || ''}
            handleDrop={(image) =>
              handleImageUpload(image, (imageUrl: string) =>
                handleChange('avatar', imageUrl)
              )
            }
            handleClear={() => handleChange('avatar', '')}
          />
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My application channel')}
            value={applicationSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {t(
              `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
            )}
          </p>
        </div>
      </div>
      <div className='p-6 boreder-t'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='color'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Color')}
          </label>
          <button
            onClick={() => setShowColorPallete(!showColorPallete)}
            style={{ background: `${getSelectedColor}` }}
            className='inline-flex w-10 h-10 p-2 bg-gray-500 rounded-full shadow focus:outline-none focus:shadow-outline'
          >
            <CheckIcon className='text-white' />
          </button>
          {showColorPallete ? (
            <div className='absolute z-10'>
              <div
                className='fixed inset-0'
                onClick={() => setShowColorPallete(!showColorPallete)}
              />
              <ChromePicker
                className='relative -top-px'
                color={getSelectedColor}
                disableAlpha={true}
                onChange={(clr: any) => {
                  setSelectedColor(clr.hex);
                  handleChange('primary_color', clr.hex);
                }}
              />
            </div>
          ) : null}
        </div>
        <div className='col-span-6 mt-3 sm:col-span-6'>
          <label
            htmlFor='preview'
            className='block text-sm font-medium text-gray-500'
          >
            {t('Webchat Preview:')}
          </label>
          <WebChatPreview
            image={
              !!applicationSettingData.avatar
                ? applicationSettingData.avatar
                : ''
            }
            fillColor={getSelectedColor}
            title={applicationSettingData.title}
          />
        </div>
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='block mb-1 text-sm font-medium text-gray-700'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (
              value.id !== applicationSettingData.connected_nlp_integration_id
            ) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
    </>
  );
};
export default ApplicationSetting;
