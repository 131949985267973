import { navigate } from '@reach/router';
import { Dialog } from 'evergreen-ui';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoaderLine from '../../components/loaders/LoaderLine';
import './assets/styles/style.scss';
import DataLabCard from './components/DataLabCard';

import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { HasPermission } from '../../../components';
import { checkPermission } from '../../../utilities/utils';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';

class DataLab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchDataLab: '',
      isDeleteDialogueShown: false,
      loading: true,
      deleteLabId: 0,
      readAccess: 'read:data_lab',
      writeAccess: 'write:data_lab',
      deleteAccess: 'delete:data_lab',
    };
  }

  async fetchDataLabs() {
    await this.props.fetchDataLabs(this.props.selectedProject.id);
    this.setState({
      loading: false,
    });
  }

  async componentDidMount() {
    if (this.props.projects.length > 0) {
      const project = this.props.projects.filter(
        (p) => parseInt(this.props.projectId) === p.id
      )[0];
      await this.props.setSelectedProject(project);
    }
    const hasPermission = checkPermission(
      this.props.permissionList,
      this.state.readAccess
    );

    if (hasPermission) {
      await this.fetchDataLabs();
    }
  }

  render() {
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );

    const hasDeleteAccess = checkPermission(
      this.props.permissionList,
      this.state.deleteAccess
    );

    return (
      <HasPermission shortCode={this.state.readAccess}>
        <Fragment>
          {(this.state.loading || this.props.labDataLoading) && (
            <div className='flex justify-center h-full text-center align-center flex-column'>
              <LoaderLine />
            </div>
          )}
          {this.props.labsData.length !== 0 ? (
            <div className='px-4 mx-auto sm:px-6 lg:px-8'>
              {hasWriteAccess && (
                <Dialog
                  isShown={this.state.isDeleteDialogueShown}
                  title={<TranslationWrapper text='Delete This Data Lab' />}
                  intent='danger'
                  onCloseComplete={() => {
                    this.setState({
                      isDeleteDialogueShown: false,
                      deleteLabId: 0,
                    });
                  }}
                  onConfirm={async () => {
                    await this.props.handleDeleteLab(
                      this.props.projectId,
                      this.state.deleteLabId
                    );
                    this.setState({
                      isDeleteDialogueShown: false,
                      deleteLabId: 0,
                    });
                  }}
                  confirmLabel={<TranslationWrapper text='Confirm' />}
                >
                  <TranslationWrapper text='Are You Sure You Want to Delete This Block?' />
                </Dialog>
              )}

              {!this.state.loading && !this.props.labDataLoading && (
                <div>
                  <div className='flex-1 min-w-0 px-0'>
                    <div className='flex items-center justify-between px-0 py-6'>
                      {hasWriteAccess && (
                        <button
                          type='button'
                          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none'
                          onClick={() =>
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/create`
                            )
                          }
                        >
                          <PlusCircleIcon
                            className='w-5 h-5 ltr:mr-2 ltr:-ml-1 rtl:-mr-1 rtl:ml-2'
                            aria-hidden='true'
                          />
                          <TranslationWrapper text='Create Datalab' />
                        </button>
                      )}
                      <div>
                        <label htmlFor='search' className='sr-only'>
                          Search
                        </label>
                        <div className='relative'>
                          <div className='absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3 pointer-events-none'>
                            <MagnifyingGlassIcon
                              className='w-5 h-5 text-gray-400'
                              aria-hidden='true'
                            />
                          </div>
                          <input
                            id='search'
                            name='search'
                            className='block w-full py-2 ltr:pl-10 rtl:pr-10 ltr:pr-3 rtl:pl-3 text-sm placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm'
                            placeholder={'Search'}
                            type='search'
                            onChange={(evt) =>
                              this.setState({ searchDataLab: evt.target.value })
                            }
                            value={this.state.searchDataLab}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                    {this.props.labsData
                      .filter((elem) =>
                        elem.title
                          .toLowerCase()
                          .includes(this.state.searchDataLab.toLowerCase())
                      )
                      .map((elem, i) => (
                        <DataLabCard
                          key={i}
                          title={elem.title}
                          type={elem.type}
                          subTitle={elem.description}
                          keyCount={elem.key_count}
                          entryCount={elem.entry_count}
                          clickCount={elem.click_count}
                          createdAt={elem.created_at}
                          hasWriteAccess={hasWriteAccess}
                          hasDeleteAccess={hasDeleteAccess}
                          dataReadAccessOnly={elem.is_readonly}
                          selectedProject={this.props.selectedProject}
                          handleKeyEdit={() =>
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/edit/${elem.id}`
                            )
                          }
                          handleInfoEdit={() =>
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/edit-info/${elem.id}`
                            )
                          }
                          handleDeleteLab={() => {
                            this.setState({
                              isDeleteDialogueShown: true,
                              deleteLabId: elem.id,
                            });
                          }}
                          handleClickAction={() => {
                            navigate(
                              `/projects/${this.props.projectId}/data-lab/${elem.id}`
                            );
                          }}
                        />
                      ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className='flex justify-center h-full text-center align-center flex-column'>
              <svg
                className='w-12 h-12 mx-auto text-gray-400'
                xmlns='http://www.w3.org/2000/svg'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 48 48'
                aria-hidden='true'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6'
                />
              </svg>
              <h3 className='mt-2 text-sm font-medium text-gray-900'>
                <TranslationWrapper text='No datalab' />
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                <TranslationWrapper text="You haven't created any datalab yet. Create one to start collecting data." />
              </p>
              <div className='mt-6'>
                {hasWriteAccess && (
                  <button
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none'
                    onClick={() =>
                      navigate(
                        `/projects/${this.props.projectId}/data-lab/create`
                      )
                    }
                  >
                    <PlusCircleIcon
                      className='w-5 h-5 ltr:mr-2 ltr:-ml-1 rtl:ml-2 rtl:-mr-1'
                      aria-hidden='true'
                    />
                    <TranslationWrapper text='Create Datalab' />
                  </button>
                )}
              </div>
            </div>
          )}
        </Fragment>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  selectedProject: state.dashboard.selectedProject,
  labsData: state.dataLab.labsData,
  projects: state.dashboard.projects,
  labDataLoading: state.loading.effects.dataLab.fetchDataLabs,
  permissionList: state.dashboard.permission?.list || [],
});

const mapDispatch = (dispatch) => ({
  fetchDataLabs: (projectId) => dispatch.dataLab.fetchDataLabs({ projectId }),
  handleDeleteLab: (projectId, labId) =>
    dispatch.dataLab.handleDeleteLab({ projectId, labId }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
});

const DataLabContainer = connect(mapState, mapDispatch)(DataLab);

export default DataLabContainer;
