import { useState } from 'react';
import { usePolicy } from '../export';
import { useSelector, useDispatch } from 'react-redux';
import { ISLACreationRequestBody, ISLAData } from '../interface';

const useSLACreation = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(
    (state: any) => state.loading.effects.settingsSLAConfiguration
  );
  const dashboardData = useSelector((state: any) => state.dashboard);

  const { defaultPolicies } = usePolicy();
  const { createSLAPolicy, updateSLAPolicy } = loadingState;
  const { platformList, selectedProject } = dashboardData;

  const [formState, setFormState] = useState<{
    name: string;
    description: string;
    platforms: Array<string | number>;
  }>({
    name: '',
    description: '',
    platforms: [],
  });

  const resetFormState = () =>
    setFormState({
      name: '',
      description: '',
      platforms: [],
    });

  const updatePlatformListFromSLA = (slaCreationResponse: any) => {
    const slaConnectedPlatforms = slaCreationResponse?.platforms;

    const updatedPlatformList = platformList.map((platform: any) => {
      const matchedPlatform = slaConnectedPlatforms.includes(platform.id);
      // If a match is found, update the platform with SLA connection details
      if (matchedPlatform) {
        return {
          ...platform,
          is_sla_connected: true,
          sla_policy: {
            name: slaCreationResponse.name,
            id: slaCreationResponse.id,
          },
        };
      }

      return platform;
    });
    // Dispatch the updated platform list
    dispatch.dashboard.updatePlatformList(updatedPlatformList);
  };

  const updatePlatformListFromSLAWithDisconnection = (
    slaCreationResponse: any
  ) => {
    const slaConnectedPlatforms = slaCreationResponse?.platforms;

    const updatedPlatformList = platformList.map((platform: any) => {
      // Check if the platform's SLA policy ID matches the current SLA's ID
      const isSLAMatched = platform.sla_policy?.id === slaCreationResponse.id;

      // If there's a match and the platform is not in the connected platforms list
      if (isSLAMatched && !slaConnectedPlatforms.includes(platform.id)) {
        // Disconnect the platform from the SLA
        return {
          ...platform,
          is_sla_connected: false,
          sla_policy: null,
        };
      }

      return platform;
    });
    // Dispatch the updated platform list
    dispatch.dashboard.updatePlatformList(updatedPlatformList);
  };

  const handleSLACreationSubmitButton = async () => {
    const requestBody: ISLACreationRequestBody = {
      name: formState.name,
      description: formState.description,
      platforms: formState.platforms,
      policies: defaultPolicies,
    };

    let response: any = await dispatch.settingsSLAConfiguration.createSLAPolicy(
      {
        teamId: selectedProject?.id,
        requestBody: requestBody,
      }
    );
    if (response.status) {
      resetFormState();
      updatePlatformListFromSLA(response.data.dataSource);
    }

    return response;
  };
  const handleSLAUpdateSubmitButton = async (requestBody: any) => {
    let response: any = await dispatch.settingsSLAConfiguration.updateSLAPolicy(
      {
        teamId: selectedProject?.id,
        requestBody: requestBody,
      }
    );
    if (response.status) {
      resetFormState();
      updatePlatformListFromSLA(response.data);
      updatePlatformListFromSLAWithDisconnection(response.data);
      dispatch.settingsSLAConfiguration.updateSingleSLAOnList(response.data);
    }

    return response;
  };

  const handleGoToIntegrationButtonClick = () => {
    dispatch.dashboard.updateSelectedNav(2);
  };

  const handleOnChannelSelect = (id: number | string) => {
    setFormState((prevState: any) => {
      const isSelected = prevState.platforms.includes(id);
      return {
        ...prevState,
        platforms: isSelected
          ? prevState.platforms.filter(
              (platformId: string | number) => platformId !== id
            )
          : [...prevState.platforms, id],
      };
    });
  };

  const isEditConfirmationButtonDisabled = (
    formData: any,
    slaData: ISLAData
  ) => {
    return (
      formData.name === slaData.name &&
      formData.description === slaData.description &&
      JSON.stringify(formData.platforms) === JSON.stringify(slaData.platforms)
    );
  };

  return {
    formState,
    platformList,
    handleOnChannelSelect,
    handleSLAUpdateSubmitButton,
    isCreationLoading: createSLAPolicy,
    isUpdatingLoading: updateSLAPolicy,
    handleSLACreationSubmitButton,
    handleGoToIntegrationButtonClick,
    setName: (name: string) =>
      setFormState((prevState) => ({ ...prevState, name })),
    setDescription: (description: string) =>
      setFormState((prevState) => ({ ...prevState, description })),
    setPlatform: (platforms: any) =>
      setFormState((prevState) => ({ ...prevState, platforms })),
    isEditConfirmationButtonDisabled,
  };
};

export default useSLACreation;
