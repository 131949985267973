import React from 'react';
import Button from '../../../../library/button';
import TextWithTooltip from '../../../../library/text';
import openBoxIcon from '../../assets/images/openBox.svg';
import useTranslation from 'components/customHooks/useTranslation';
import DatalabFormIcon from '../../assets/images/datalabFormIcon.svg';
import DatalabInventoryIcon from '../../assets/images/datalabInventoryIcon.svg';

import { navigate } from '@reach/router';
import { IDatalabList } from '../../inboxInterface';
import {
  XMarkIcon,
  ChevronRightIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';

interface Props {
  inputEvent: any;
  inputValue: string;
  bottom?: string;
  datalabList: IDatalabList[];
  updateSelectedNav: (navId: number) => void;
  setInputValue: (payload: string) => void;
  setIsDatalabListOpened: (value: boolean) => void;
  handleRightbarAppearence: (value: boolean) => void;
  updateInboxDatalabStateData: (key: string, value: any) => void;
}

const DatalabListPopoverView: React.FC<Props> = ({
  inputEvent,
  inputValue,
  datalabList,
  bottom = 'bottom-[20%]',
  setInputValue,
  updateSelectedNav,
  setIsDatalabListOpened,
  handleRightbarAppearence,
  updateInboxDatalabStateData,
}) => {
  const { t } = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!!event) {
      if (event.key === 'Enter' && datalabList.length !== 0) {
        setIsDatalabListOpened(false);
      }
    }
  };

  const renderHeaderElementsView = () => {
    return (
      <div className='flex justify-between p-3 w-[343px]'>
        <p className='w-[60%] text-gray-800 font-semibold text-sm mb-2'>
          {t('Datalab List')}
        </p>
        <div className='w-[40%] flex justify-end'>
          <XMarkIcon
            className='w-5 h-5 text-gray-500 cursor-pointer'
            onClick={() => {
              setIsDatalabListOpened(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderDatalabListIcon = (datalab: IDatalabList) => {
    if (
      !!datalab?.logo &&
      datalab.logo.hasOwnProperty('url') &&
      !!datalab?.logo?.url
    ) {
      return (
        <img
          src={datalab?.logo?.url}
          className='border-2 border-gray-100 rounded-md h-12 w-12'
          alt='datalab-avatar'
        />
      );
    } else if (typeof datalab?.logo === 'string' && !!datalab?.logo) {
      return (
        <img
          src={datalab?.logo}
          className='border-2 border-gray-100 rounded-md h-12 w-12'
          alt='datalab-avatar'
        />
      );
    } else {
      return (
        <img
          src={
            datalab?.type === 'form' ? DatalabFormIcon : DatalabInventoryIcon
          }
          className='border-2 border-gray-100 rounded h-14 w-14'
          alt='datalab-avatar'
        />
      );
    }
  };

  const renderDatalabListElementView = () => {
    return (
      <div className='max-h-[260px] overflow-y-auto mb-3 w-[343px]'>
        {datalabList.map((datalab: IDatalabList, index: number) => (
          <div key={index}>
            <div
              className={`py-[12px] flex cursor-pointer hover:bg-gray-200 p-3 `}
              onClick={() => {
                updateInboxDatalabStateData('selectedDatalabEntry', null);
                updateInboxDatalabStateData('isRightBarOpenedForDatalab', true);
                updateInboxDatalabStateData('formAction', 'CREATE');
                updateInboxDatalabStateData('selectedDatalabId', datalab?.id);
                handleRightbarAppearence(true);

                setIsDatalabListOpened(false);
              }}
            >
              {renderDatalabListIcon(datalab)}
              <div className='w-[66%] ltr:ml-3 rtl:mr-3 flex flex-col justify-center'>
                <span className='text-sm font-medium text-gray-900 '>
                  <TextWithTooltip
                    text={t(datalab?.title)}
                    dataFor={datalab?.title}
                    dataTip={t(datalab?.title)}
                    shouldShowTooltip={datalab?.title.length > 32}
                  />
                </span>
                <span className='text-xs font-normal text-gray-500'>
                  <TextWithTooltip
                    text={
                      t(datalab?.description) || t(`(No Description Provided)`)
                    }
                    dataFor={datalab?.description}
                    dataTip={t(datalab?.description)}
                    shouldShowTooltip={datalab?.description.length > 32}
                  />
                </span>
              </div>
              <div className='w-[25%] flex justify-end mt-2'>
                <ChevronRightIcon className='w-5 h-5 text-gray-300' />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderEmptyDatalabListView = () => {
    return (
      <div className='mx-3 my-16 w-'>
        <span className='flex justify-center'>
          <img src={openBoxIcon} alt='not-ticket' />
        </span>
        <div className='flex justify-center mt-3 text-sm text-center text-gray-500'>
          <span>
            {t('Oops! there are no datalab found')}.
            <br />
            {t('Better create new datalab')}.
          </span>
        </div>
        <div className='flex justify-center mt-3'>
          <Button
            intent='primary'
            size='sm'
            icon={<ArrowTopRightOnSquareIcon />}
            onClick={() => {
              updateSelectedNav(6);
              navigate(`data-lab`);
            }}
          >
            {t('Create Datalab')}
          </Button>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    handleKeyDown(inputEvent);
    // eslint-disable-next-line
  }, [inputEvent]);

  return (
    <>
      {datalabList.length === 0 && inputValue.length > 1 ? null : (
        <div className={`absolute z-10 ${bottom}`}>
          <div className='relative bg-white border border-gray-200 rounded-md'>
            <div className='w-full'>
              <div>
                {renderHeaderElementsView()}
                {!!datalabList &&
                  datalabList.length !== 0 &&
                  renderDatalabListElementView()}
                {!!datalabList &&
                  datalabList.length === 0 &&
                  renderEmptyDatalabListView()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DatalabListPopoverView;
