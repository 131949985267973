import React, { useEffect, useState } from 'react';
import BlockContainer from './BlockContainer';
import InputInstruction from '../elements/eCommerceBlock/InputInstruction';
import InputOutputViewNavBar from '../elements/eCommerceBlock/InputOutputViewNavBar';
import ProductDiscoveryBlockIcon from '../../../old/pages/builder/assets/images/icons/product_discovery_block_icon.svg';
import OutputInstruction from '../elements/eCommerceBlock/OutputInstruction';
import { getPlatformsGalleryButtonMaxNumber } from '../../../utilities/utils';
import { setEcommerceInitialParameters } from '../helpers/blockUtils';
import { ecommerceParameters } from '../../../utilities/content';
import { toaster } from 'evergreen-ui';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  type: string;
  labsData: [];
  platformType: string;
  subscriptionPlanType: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  selectedProject: { id: number; ecommerce_type: string };
  addInputInstructions: (
    payload: {
      name: string;
      verbose: string;
      value: string | number | boolean | null;
      type: string;
      isPinned: string;
    },
    save: boolean
  ) => void;
  addOutputInstructions: (payload: {
    title: string;
    subtitle: string;
    url: string;
    has_image: boolean;
    buttons: [];
  }) => void;
  deleteInputInstruction: (payload: { index: number }) => void;
  clearProductDiscoveryBlockInputInstructions: () => void;
  clearProductDiscoveryBlockOutputInstructions: () => void;
  updateProductDiscoveryBlockData: (
    payload: { name: string; value: string | number | boolean | null },
    changeKey: string,
    indexValue: number
  ) => void;
  updateFieldAttribute: (data: object) => void;
  handleProductDiscoveryBlockGalleryButton: (
    data: {},
    actionType: string
  ) => void;
}

interface inputInstruction {
  // define the object (singular)
  name: string;
  type: string;
  value: string;
  default: string | number | null;
  verbose: string;
  isPinned: boolean;
}

interface dataProps {
  input_instructions: inputInstruction[];
  output_instructions: {
    has_image: boolean;
    title: string;
    subtitle: string;
    url: string;
    buttons: [];
  };
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const ProductDiscoveryBlock: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  labsData,
  platformType,
  subscriptionPlanType,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  updateFieldAttribute,
  selectedProject,
  addInputInstructions,
  addOutputInstructions,
  deleteInputInstruction,
  clearProductDiscoveryBlockInputInstructions,
  clearProductDiscoveryBlockOutputInstructions,
  updateProductDiscoveryBlockData,
  handleProductDiscoveryBlockGalleryButton,
}) => {
  const tabData = [
    { id: 0, name: 'Input Instructions' },
    { id: 1, name: 'Output View' },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState({
    id: 0,
    name: 'Input Instructions',
  });
  const [selectedSequence, setSelectedSequence] = useState(
    data['no_products_sequence']
  );
  const [showAttributeField, setShowAttributeField] = useState(null);

  const isShowingVariationsInitialState = data?.input_instructions
    .filter((instruction) => instruction.name === 'show_variations')
    .map((instruction) => instruction.value)
    .pop();

  const [isShowingVariations, setIsShowingVariations] = useState(
    !!isShowingVariationsInitialState
  );

  let ecommercePlatformType = selectedProject?.ecommerce_type;
  let outputParameters = isShowingVariations
    ? ecommerceParameters[ecommercePlatformType].output_parameters_for_variants
    : ecommerceParameters[ecommercePlatformType]
        .output_parameters_for_regular_product;

  const handleTabChange = (tab: any) => {
    setCurrentTabIndex(tab);
  };

  const handleOutputInstructions = (name: string, val: boolean | string) => {
    updateProductDiscoveryBlockData(
      { name: name, value: val },
      `output_instructions`,
      0
    );
  };

  const handleSelectedParameters = (selectedParameter: any) => {
    if (
      selectedParameter.name === 'show_variations' &&
      ecommercePlatformType !== 'woocommerce'
    ) {
      setIsShowingVariations(true);
      clearProductDiscoveryBlockInputInstructions();
      clearProductDiscoveryBlockOutputInstructions();
      setEcommerceInitialParameters(
        data,
        platformType,
        ecommercePlatformType,
        addInputInstructions,
        addOutputInstructions,
        true
      );
    }
    addInputInstructions(selectedParameter, false);
  };

  const handleParameterDelete = (verbose: string, index: number) => {
    if (verbose === 'Show Variations') {
      clearProductDiscoveryBlockOutputInstructions();
      setIsShowingVariations(false);
    }
    deleteInputInstruction({ index: index });
  };

  const handleSelectedSequence = (sequenceName: string, sequenceId: number) => {
    updateProductDiscoveryBlockData(
      { name: sequenceName, value: sequenceId },
      `no_products_sequence`,
      0
    );
  };

  const handleAttributePopOver = (val: any) => {
    setShowAttributeField(val);
  };

  const handleSelectedParamsInputValue = (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => {
    let currentValue = value;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{')
      setShowAttributeField(changeKey === 'input_instructions' ? id : name);
    else setShowAttributeField(null);
    updateProductDiscoveryBlockData(
      { name: name, value: value },
      changeKey,
      id
    );
  };

  const handleSelectedParamsAttributeValue = (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => {
    setShowAttributeField(null);
    let newValue =
      changeKey === 'input_instructions'
        ? data?.input_instructions[id]['value'] + `${value}}}`
        : data?.output_instructions[name] + `${value}}}`;
    updateProductDiscoveryBlockData(
      { name: name, value: newValue },
      changeKey,
      id
    );
  };

  const validateDataOnSave = () => {
    // @ts-ignore
    if (data?.input_instructions[0].value.length < 1) {
      // @ts-ignore
      toaster.danger(
        `${data?.input_instructions[0].verbose} can not be empty`,
        { duration: 2 }
      );
      return;
    }
    // @ts-ignore
    if (data?.input_instructions[1].value.length < 1) {
      // @ts-ignore
      toaster.danger(
        `${data?.input_instructions[1].verbose} can not be empty`,
        { duration: 2 }
      );
      return;
    }
    //@ts-ignore
    else if (
      data?.input_instructions[1].name === 'per_page' &&
      data?.input_instructions[1].value.length >
        getPlatformsGalleryButtonMaxNumber(platformType)
    ) {
      toaster.danger(
        'Per Page can not exceed the maximum amount allowed for this channel',
        { duration: 2 }
      );
      return;
    }
    saveBlock(id);
  };

  useEffect(() => {
    setEcommerceInitialParameters(
      data,
      platformType,
      ecommercePlatformType,
      addInputInstructions,
      addOutputInstructions,
      false
    );
    //eslint-disable-next-line
  }, []);
  return (
    <BlockContainer
      id={id}
      title='Product Discovery'
      subtitle='Product discovery block helps you to recommend the right set of products from your ecommerce.'
      save={save}
      isLoading={saveLoader}
      icon={ProductDiscoveryBlockIcon}
      sequences={sequences}
      handleSaveClick={() => validateDataOnSave()}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <InputOutputViewNavBar
          tabData={tabData}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
        />
        <br />
        <div className='border-t border-gray-200 mb-2.5 w-full' />
        {currentTabIndex.id === 0 ? (
          <InputInstruction
            inputParameters={data?.input_instructions}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttributeField={showAttributeField}
            sequences={sequences}
            platformType={platformType}
            selectedSequence={selectedSequence}
            setSelectedSequence={setSelectedSequence}
            setShowAttributeField={handleAttributePopOver}
            handleParameterDelete={handleParameterDelete}
            ecommerceParameters={
              ecommerceParameters[ecommercePlatformType].input_parameters
            }
            handleSelectedSequence={handleSelectedSequence}
            handleSelectedParameters={handleSelectedParameters}
            handleSelectedParamsInputValue={handleSelectedParamsInputValue}
            handleSelectedParamsAttributeValue={
              handleSelectedParamsAttributeValue
            }
          />
        ) : (
          <OutputInstruction
            blockId={id}
            type={type}
            sequences={sequences}
            platformType={platformType}
            subscriptionPlanType={subscriptionPlanType}
            labsData={labsData}
            buttons={data?.output_instructions.buttons}
            hasWriteAccess={hasWriteAccess}
            outputInstructions={data?.output_instructions}
            outputParameters={outputParameters}
            showAttributeField={showAttributeField}
            setShowAttributeField={handleAttributePopOver}
            handleShowImage={handleOutputInstructions}
            handleSelectedParamsAttributeValue={
              handleSelectedParamsAttributeValue
            }
            handleSelectedParamsInputValue={handleSelectedParamsInputValue}
            handleProductDiscoveryBlockGalleryButton={
              handleProductDiscoveryBlockGalleryButton
            }
          />
        )}
      </>
    </BlockContainer>
  );
};

ProductDiscoveryBlock.defaultProps = {
  hasWriteAccess: false,
};
export default ProductDiscoveryBlock;
