import { useDispatch, useSelector } from 'react-redux';
import {
  IDynamicIntegratedChannelProps,
  IDynamicIntegrationListProps,
  RearrangedListItem,
} from '../interface';

const useDynamicIntegration = () => {
  const dispatch = useDispatch();
  const integrationStore = useSelector((state: any) => state.integration);
  const dashboardStore = useSelector((state: any) => state.dashboard);

  const rearrangeChannelListBySection = (
    channelList: IDynamicIntegratedChannelProps[]
  ) => {
    const list = channelList;
    const sectionsMap: { [section: string]: IDynamicIntegratedChannelProps[] } =
      {};
    list.forEach((item: IDynamicIntegratedChannelProps) => {
      if (!sectionsMap[item?.integration?.section]) {
        sectionsMap[item?.integration?.section] = [];
      }

      sectionsMap[item?.integration?.section].push(item);
    });

    const rearrangedList = Object.entries(sectionsMap).map(
      ([section, items]) => ({
        section,
        items,
      })
    );
    return rearrangedList;
  };

  const fetchDynamicIntegrationList = async (): Promise<
    RearrangedListItem[]
  > => {
    try {
      const response = await dispatch.integration.fetchDynamicIntegrationList(); // Replace with your Rematch model and action
      if (!response?.success) {
        return [];
      }
      const list = response?.data;
      const sectionsMap: { [section: string]: IDynamicIntegrationListProps[] } =
        {};
      list.forEach((item: IDynamicIntegrationListProps) => {
        if (!!item?.fields) {
          if (!sectionsMap[item.section]) {
            sectionsMap[item.section] = [];
          }

          if (!!item?.fields) sectionsMap[item.section].push(item);
        }
      });

      const rearrangedList = Object.entries(sectionsMap).map(
        ([section, items]) => ({
          section,
          items,
        })
      );

      return rearrangedList;
    } catch (error) {
      console.error('Error fetching dynamic integration list:', error);
      return [];
    }
  };

  const fetchDynamicIntegratedChannelList = async () => {
    try {
      await dispatch.integration.getIntegratedOthersList(
        dashboardStore.selectedProject?.id
      );
    } catch (exception) {
      console.error(exception);
    }
  };

  const createDynamicChannel = async (payloadData: any) => {
    const payload = {
      integrationId: payloadData?.item?.id,
      channelType: payloadData?.item?.type,
      teamId: dashboardStore?.selectedProject?.id,
      data: {
        ...payloadData?.data,
      },
    };

    let res = await dispatch.integration.createOtherDynamicIntegration(payload);

    if (res.status === 200) {
      return { success: true, message: 'Success' };
    } else {
      return {
        success: false,
        message: !!res?.data?.error
          ? res?.data?.error
          : 'Something went wrong, please try again.',
      };
    }
  };

  const updateDynamicChannelData = async (payloadData: any) => {
    let payload = {
      teamId: dashboardStore.selectedProject.id,
      channelId: payloadData?.item?.id,
      alertMessage: `${payloadData?.item?.name} info successfully updated`,
      type: payloadData?.item?.type,
      data: {
        ...payloadData?.data,
      },
    };
    let res = await dispatch.integration.editOtherChannel(payload);
    if (res) {
      return true;
    } else {
      return false;
    }
  };

  const getSingleChannelData = async (channelId: string) => {
    let channelData = await dispatch.integration.handleRetriveBetterdocs({
      teamId: dashboardStore.selectedProject.id,
      betterdocsId: channelId,
    });
    return channelData;
  };

  const handleChannelRemove = async (channelId: string, alertMessage: string) =>
    await dispatch.integration.removeOtherChannel({
      teamId: dashboardStore.selectedProject.id,
      channelId,
      alertMessage,
    });

  const handleChannelDisconnect = async (
    channelId: string,
    alertMessage: string
  ) =>
    await dispatch.integration.disconnectOtherChannel({
      teamId: dashboardStore.selectedProject.id,
      channelId,
      alertMessage,
    });

  const handleChannelReconnect = async (
    channelId: string,
    alertMessage: string
  ) =>
    await dispatch.integration.reconnectOtherChannel({
      channelId,
      alertMessage,
      teamId: dashboardStore.selectedProject.id,
      payloadData: { integration_id: channelId },
    });

  return {
    otherChannelList: rearrangeChannelListBySection(
      integrationStore?.integratedOthersList
    ),
    fetchDynamicIntegratedChannelList,
    fetchDynamicIntegrationList,
    updateDynamicChannelData,
    handleChannelDisconnect,
    handleChannelReconnect,
    getSingleChannelData,
    createDynamicChannel,
    handleChannelRemove,
  };
};

export default useDynamicIntegration;
