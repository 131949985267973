import { EmailSignatureValueMap } from 'pages/integration/utils/content';
import { useSelector } from 'react-redux';

const useEmailSignature = () => {
  const agentInformation = useSelector((state: any) => state?.auth);
  const selectedTicket = useSelector(
    (state: any) => state?.inbox?.selectedTicket
  );
  const integratedChannelList = useSelector(
    (state: any) => state.inbox.integratedChannelList
  );
  const team_name = useSelector(
    (state: any) => state?.dashboard?.selectedProject?.name
  );
  const variableValues = { ...agentInformation, team_name };
  const replacePlaceholders = (sentence: string) => {
    // Create a dictionary for quick lookup
    const variableMap = EmailSignatureValueMap();
    // Replace placeholders in the sentence
    return sentence.replace(/\{\{(.*?)\}\}/g, (match: string, p1: string) => {
      //@ts-ignore
      return variableValues[variableMap[p1]] || match;
    });
  };

  const getEmailSignature = () => {
    const platformId = selectedTicket?.customer_platform_id;
    const platformFilter = integratedChannelList.filter(
      (data: any) => data?.id === `${platformId}`
    );
    if (!!platformFilter && platformFilter.length > 0) {
      const emailPlatform = platformFilter[0];
      return replacePlaceholders(
        emailPlatform?.platform_meta?.email_signature ?? ''
      );
    } else return '';
  };

  return {
    getEmailSignature,
    emailSignatureValueReplacer: replacePlaceholders,
  };
};

export default useEmailSignature;
