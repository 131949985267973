import { LinearLoaderComponent } from '../../../../../utilities/utils';

import {
  React,
  Button,
  Pagination,
  PlusSmallIcon,
  UtilityContents,
  useTriggerActions,
  useAutomationWorkflow,
  AutomationListTableData,
  useAutomationDashboard,
  useSelector,
} from '../../../export';

interface props {}

const AutomationListTable: React.FC<props> = () => {
  const { fetchAutomationViewData } = useTriggerActions();
  const {
    userActionFlowList,
    updatePrimitiveStateData,
    workflowDashboardTableOffset,
    createdTotalWorkflowAutomation,
  } = useAutomationWorkflow();
  const {
    isFetchUserActionFlowListLoading,
    updateWorkflowDashboardTableOffset,
  } = useAutomationDashboard();

  const { integratedEcommerceList } = useSelector(
    (state: any) => state.workflowAutomation
  );

  const renderTableHeaderView = () => {
    return (
      <div className='px-4 py-5 border-b'>
        <div className='flex flex-wrap justify-end'>
          {/* <div className='flex flex-wrap justify-start gap-2'>
            <div className='relative w-full rounded-md sm:w-auto'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='search'
                name='search'
                id='search'
                className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder='Search'
                value={''}
                disabled={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(e.target.value);
                }}
              />
            </div>
          </div> */}
          <div className='flex flex-wrap justify-start gap-2'>
            {/* <Button
              intent='default'
              isDisabled={true}
              size={'sm'}
              onClick={() => console.log('export')}
              className={'w-full sm:w-auto'}
            >
              {
                <ArrowDownTrayIcon
                  className='-ml-0.5 mr-2 h-4 w-4'
                  aria-hidden='true'
                />
              }
              Export
            </Button> */}

            <Button
              intent='primary'
              isDisabled={
                !integratedEcommerceList || integratedEcommerceList?.length < 1
              }
              onClick={() => {
                fetchAutomationViewData();
                updatePrimitiveStateData({
                  key: 'automationFlowDisplayViewType',
                  value: 'createWorkflow',
                });
              }}
              className='ml-3'
            >
              {
                <PlusSmallIcon
                  className='-ml-0.5 mr-1 h-7 w-7'
                  aria-hidden='true'
                />
              }
              Create Automation
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderPaginationView = () => {
    return (
      userActionFlowList?.length !== 0 && (
        <Pagination
          offset={workflowDashboardTableOffset}
          tableDataLength={userActionFlowList?.length}
          total={createdTotalWorkflowAutomation}
          limit={UtilityContents?.UserActionFlowGetterApiDataLimit}
          handleNextButton={() =>
            updateWorkflowDashboardTableOffset(
              workflowDashboardTableOffset +
                UtilityContents?.UserActionFlowGetterApiDataLimit
            )
          }
          handlePreviousButton={() =>
            updateWorkflowDashboardTableOffset(
              workflowDashboardTableOffset -
                UtilityContents?.UserActionFlowGetterApiDataLimit
            )
          }
        />
      )
    );
  };

  return (
    <div className='mt-5 overflow-hidden bg-white rounded-lg shadow '>
      {renderTableHeaderView()}
      {isFetchUserActionFlowListLoading && <LinearLoaderComponent />}
      {!isFetchUserActionFlowListLoading && (
        <>
          <AutomationListTableData tableData={userActionFlowList} />
          {renderPaginationView()}
        </>
      )}
    </div>
  );
};

export default AutomationListTable;
