import React, { useState } from 'react';
import openBoxIcon from '../../../assets/images/openBox.svg';
import useTranslation from 'components/customHooks/useTranslation';
import { format as dateFnsFormat } from 'date-fns';

import { Accordion } from 'libraryV2/ui/accordion';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { Button } from 'libraryV2/ui/button';
import { Link } from '@reach/router';

interface Props {
  limit: number;
}

interface ITicketList {
  id: number;
  date: Date;
}

const list: ITicketList[] = [
  {
    id: 67890,
    date: new Date(),
  },
  {
    id: 25682952,
    date: new Date(),
  },
];

const CustomerTicketHistory: React.FC<Props> = ({ limit }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState('');
  const [ticketList] = useState(list);
  const isFetchCustomerDatalabEntriesLoading = false;

  const renderEmptyTicketHistoryView = () => {
    return (
      <div className='p-4'>
        <span className='flex justify-center'>
          <img src={openBoxIcon} alt='not-ticket' />
        </span>
        <div className='flex justify-center mt-3 text-sm text-center text-gray-500'>
          <span>{t('Oops! No ticket found.')}</span>
        </div>
      </div>
    );
  };

  const renderTicketHistoryList = () => {
    if (ticketList.length < 1) {
      return renderEmptyTicketHistoryView();
    }

    return (
      <div>
        {ticketList.map((v) => {
          return (
            <div
              key={v.id}
              onMouseOver={() => setIsHovered(v.id.toString())}
              onMouseLeave={() => setIsHovered('')}
              className='flex px-4 py-3 justify-between items-center border-t cursor-pointer hover:bg-gray-100'
            >
              <div>
                <h4 className='text-sm text-[#0078CF]'> #{v.id} </h4>
                <p className='text-[10px] leading-5'>
                  {dateFnsFormat(v.date, 'do MMMM yyyy')}
                </p>
              </div>
              {parseInt(isHovered) === v.id ? (
                <Link
                  to={`/projects/5179/inbox?customer_id=81279070&ticket_id=${v.id}`}
                >
                  <p className='text-[#0078CF]'>{t('View')}</p>
                </Link>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderEntryLoaderView = () => {
    return (
      <>
        {isFetchCustomerDatalabEntriesLoading &&
          [...Array(5)].map((item) => (
            <div key={item} className='w-full max-w-sm px-4'>
              <div className='flex items-center animate-pulse'>
                <div className='mr-3 bg-gray-200 rounded w-9 h-9' />
                <div className='flex-1 py-1'>
                  <div className='mb-2'>
                    <div className='h-4 bg-gray-200 rounded' />
                  </div>
                  <div className='grid grid-cols-3 gap-4'>
                    <div className='h-2 col-span-2 bg-gray-200 rounded' />
                    <div className='h-2 bg-gray-200 rounded' />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  const renderLoadMoreButton = () => {
    if (ticketList.length < 5) return null;
    return (
      <div className='flex items-center justify-center w-full mt-2 mb-5'>
        <Button size='sm'>{t('Load More')}</Button>
      </div>
    );
  };

  const renderDatalabHistories = () => {
    return (
      <Accordion
        type='single'
        collapsible
        className='flex w-auto h-auto border rounded flex-column'
      >
        <AccordionItem value='item-1' className='w-full '>
          <AccordionTrigger className='AccordionTrigger flex w-full items-center justify-between px-4 py-3'>
            <div className='font-semibold text-sm leading-5 text-gray-900 cursor-default'>
              {t('Ticket History')}
            </div>
            <ChevronDown
              className='text-gray-400 AccordionChevron'
              height={16}
              width={16}
            />
          </AccordionTrigger>
          <AccordionContent>
            {renderEntryLoaderView()}
            {renderTicketHistoryList()}
            {renderLoadMoreButton()}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  };
  return renderDatalabHistories();
};

export default CustomerTicketHistory;
