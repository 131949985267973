import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { Button } from '../../../../library';

interface props {
  offset: number;
  tableDataLength: number;
  total: number;
  limit: number;
  handleNextButton: () => void;
  handlePreviousButton: () => void;
}

const Pagination: React.FC<props> = ({
  offset,
  tableDataLength,
  total,
  limit,
  handleNextButton,
  handlePreviousButton,
}) => {
  const { t } = useTranslation();
  return (
    <div className='mt-auto bg-white border-t rounded-b-lg'>
      <div className='flex items-center justify-between px-6 py-4 whitespace-nowrap'>
        <p className='text-sm font-normal text-gray-900'>
          {t('Showing')} <span className='font-medium'>{offset + 1}</span>{' '}
          {t('to')}{' '}
          <span className='font-medium'>{offset + tableDataLength}</span>{' '}
          {t('of')} <span className='font-medium'>{total}</span> {t('results')}
        </p>
        {total > limit && (
          <div className='flex justify-between flex-1 sm:justify-end'>
            {offset > 0 && (
              <Button
                intent='default'
                size={'sm'}
                onClick={() => handlePreviousButton()}
                className={offset + limit < total ? 'ltr:mr-4 rtl:ml-4' : ''}
              >
                {t('Previous')}
              </Button>
            )}
            {offset + tableDataLength < total && (
              <Button
                isDisabled={tableDataLength < limit}
                intent='default'
                onClick={() => handleNextButton()}
                size={'sm'}
              >
                {t('Next')}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pagination;
