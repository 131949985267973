import {
  toast,
  toaster,
  useDispatch,
  useSelector,
  processArrayCondition,
  processWhatsappOperation,
  processArrayDelayOperation,
  processDiscountOperation,
} from 'pages/automationWorkflow/export';
import { INode, NodeType } from '../../../interface';
import {
  processAddTagsOperation,
  processRemoveTagsOperation,
  processAddTagsOnOrderOperation,
  processRemoveTagsOnOrderOperation,
  processRemoveTagsOnCustomerOperation,
} from 'pages/automationWorkflow/utility/createWorkflowHelperFunctions';

const useCreateWorkflowServices = () => {
  const dispatch = useDispatch();
  const {
    triggerInfo,
    automationNodes,
    automationEdges,
    selectedTrigger,
    automationName,
    isNewWorkFlowActive,
    isAutomationEditable,
    selectedAutomationId,
    selectedUserActionFlowData,
    isUpdateAutomationButtonEnable,
  } = useSelector((state: any) => state.workflowAutomation);
  const { selectedProject } = useSelector((state: any) => state.dashboard);

  // Destructure required state loading from the workflowAutomation
  const { createAutomationFlow: isCreateAutomationFlowLoading } = useSelector(
    (state: any) => state.loading.effects.workflowAutomation
  );

  // Main function to get sanitized node list
  const getSanitizedNodeList = () => {
    const tempAutomationNodes: any[] = [];
    // Iterate over automation nodes
    automationNodes.forEach((element: any) => {
      const nodeCopy = { ...element }; // Copy the original node
      if (element.type === NodeType.CONDITION && Array.isArray(element.data)) {
        const tempDataArr: any[] = []; // Create an array to store processed condition objects
        element.data.forEach((dataElement: any) => {
          if (Array.isArray(dataElement)) {
            const tempAutomationNodesChild = processArrayCondition(dataElement);
            tempDataArr.push(tempAutomationNodesChild);
          }
        });
        nodeCopy.data = tempDataArr;
      } else if (element.type === NodeType.ACTION) {
        const tempDataArr: any[] = [];
        if (element?.data?.general?.delay?.valueOfDelay !== null) {
          const tempAutomationNodesChild = processArrayDelayOperation(
            element.data?.general
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.whatsapp?.selectedTemplate !== null) {
          const tempAutomationNodesChild = processWhatsappOperation(
            element?.data?.whatsapp,
            triggerInfo
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.discount?.component) {
          const tempAutomationNodesChild = processDiscountOperation(
            element?.data?.eCommerce?.discount
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.addTagsOnCustomer?.component) {
          const tempAutomationNodesChild = processAddTagsOperation(
            element?.data?.eCommerce?.addTagsOnCustomer
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.addTagsOnOrder?.component) {
          const tempAutomationNodesChild = processAddTagsOnOrderOperation(
            element?.data?.eCommerce?.addTagsOnOrder
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.removeTagsOnCustomer?.component) {
          const tempAutomationNodesChild = processRemoveTagsOnCustomerOperation(
            element?.data?.eCommerce?.removeTagsOnCustomer
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.removeTagsOnOrder?.component) {
          const tempAutomationNodesChild = processRemoveTagsOnOrderOperation(
            element?.data?.eCommerce?.removeTagsOnOrder
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        if (element?.data?.eCommerce?.removeTags?.component) {
          const tempAutomationNodesChild = processRemoveTagsOperation(
            element?.data?.eCommerce?.removeTags
          );
          tempDataArr.push(tempAutomationNodesChild);
        }
        nodeCopy.data = tempDataArr;
      }
      tempAutomationNodes.push(nodeCopy);
    });

    return tempAutomationNodes.filter(
      (nodes: INode) => nodes.type !== NodeType.CONDITION_ACTION_BUTTON
    );
  };

  const automationFlowCreationPayload = () => {
    return {
      name: automationName,
      notes: '',
      trigger_code: selectedTrigger?.trigger_code || 'shopify_order_created',
      version: 1.0,
      is_active: isNewWorkFlowActive,
      flow_graph: { nodes: getSanitizedNodeList(), edges: automationEdges },
    };
  };

  // Change Condition UI based on the given link
  const createAutomationFlow = async () => {
    const payload = automationFlowCreationPayload();
    try {
      const getCreateRes =
        await dispatch.workflowAutomation.createAutomationFlow({
          payload,
          projectId: selectedProject?.id,
        });
      if (getCreateRes === true) {
        toaster.success('Congratulations!', {
          description: 'Your Workflow is created',
          duration: 3,
        });
        dispatch.workflowAutomation.updatePrimitiveStateData({
          key: 'automationFlowDisplayViewType',
          value: 'dashboard',
        });
        dispatch.workflowAutomation.clearState();
      }
    } catch (error) {
      toast({
        title:
          'Oops! It seems like there was an error. Please try again later or contact support.',
      });
      console.error(error);
    }
  };

  const automationFlowUpdatePayload = () => {
    return {
      ...selectedUserActionFlowData,
      flow_graph: { nodes: getSanitizedNodeList(), edges: automationEdges },
    };
  };

  //Update selected automation
  const updateSelectedAutomationData = async (
    selectedEditableAutomationID: number
  ) => {
    const payload = automationFlowUpdatePayload();

    try {
      const getupdateRes =
        await dispatch.workflowAutomation.updateSelectedAutomationFlow({
          payload,
          selectedId: selectedEditableAutomationID,
          projectId: selectedProject?.id,
        });
      if (getupdateRes === true) {
        toaster.success('Congratulations!', {
          description: 'Your Workflow is updated',
          duration: 3,
        });
        dispatch.workflowAutomation.updatePrimitiveStateData({
          key: 'automationFlowDisplayViewType',
          value: 'dashboard',
        });
        dispatch.workflowAutomation.clearState();
      }
    } catch (error) {
      toast({
        title:
          'Oops! It seems like there was an error. Please try again later or contact support.',
      });
      console.error(error);
    }
  };

  // Function to check if the Save Automation button should be enabled
  const isSaveAutomationButtonEnabled = () => {
    if (isAutomationEditable && selectedAutomationId !== null) {
      return !isUpdateAutomationButtonEnable;
    } else {
      // Object to track if required node types are present
      const requiredNodeTypes = {
        'trigger-node': true,
      };

      automationNodes.forEach((node: any) => {
        if (node.type in requiredNodeTypes) {
          requiredNodeTypes[node.type] = false;
        }
      });

      // Check if any required node type is missing
      const isAnyTypeMissing = Object.values(requiredNodeTypes).some(
        (value) => value
      );

      // Return true if any required node type is missing (button disabled),
      // otherwise return false (button enabled)
      return isAnyTypeMissing;
    }
  };

  return {
    createAutomationFlow,
    updateSelectedAutomationData,
    isSaveAutomationButtonEnabled,
    isCreateAutomationFlowLoading,
  };
};

export default useCreateWorkflowServices;
