import React from 'react';

interface IntntLogoProps {
  colorClass?: string;
}

const LoadingLogo: React.FC<IntntLogoProps> = ({
  colorClass = 'text-white',
}) => {
  return (
    <svg
      className={`animate-spin -ml-1 h-4 w-4 ${colorClass}`}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 19 18'
    >
      <path
        d='M17.4647 10.3112C17.9705 10.3945 18.453 10.0516 18.4838 9.53999C18.5988 7.62684 18.1001 5.71766 17.0472 4.09692C15.8249 2.21557 13.9441 0.858554 11.7734 0.291854C9.60262 -0.274846 7.29848 -0.0103609 5.3126 1.03346C3.32672 2.07729 1.8023 3.82519 1.03815 5.93454C0.273996 8.04389 0.325218 10.3626 1.18177 12.4361C2.03833 14.5097 3.63845 16.1886 5.66849 17.1437C7.69853 18.0988 10.0121 18.2613 12.1557 17.5993C14.0024 17.029 15.6082 15.8822 16.7452 14.3393C17.0493 13.9266 16.8982 13.3543 16.4565 13.0943C16.0147 12.8343 15.4503 12.9863 15.1353 13.3906C14.2411 14.5384 13.0123 15.392 11.608 15.8257C9.90648 16.3512 8.07007 16.2222 6.45872 15.4641C4.84736 14.706 3.57726 13.3734 2.89736 11.7275C2.21747 10.0816 2.17681 8.24108 2.78336 6.56677C3.38991 4.89247 4.59993 3.50506 6.17623 2.67652C7.75253 1.84798 9.58146 1.63804 11.3045 2.08786C13.0275 2.53768 14.5205 3.61483 15.4906 5.10815C16.2913 6.34062 16.6882 7.78323 16.6399 9.23742C16.6228 9.74971 16.9589 10.2279 17.4647 10.3112Z'
        fill='#F9FAFB'
      />
    </svg>
  );
};

export default LoadingLogo;
