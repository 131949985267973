interface Props {
  color?: string;
  className?: string;
}

const RadioLayoutListIcon: React.FC<Props> = ({
  color = '#18181B',
  className = '',
}) => (
  <svg
    stroke={color}
    className={className}
    width='28'
    height='28'
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.5 20.4166C3.5 22.6718 5.32817 24.5 7.58333 24.5C9.8385 24.5 11.6667 22.6718 11.6667 20.4166C11.6667 18.1615 9.8385 16.3333 7.58333 16.3333C5.32817 16.3333 3.5 18.1615 3.5 20.4166Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 7.58333C3.5 9.8385 5.32817 11.6667 7.58333 11.6667C9.8385 11.6667 11.6667 9.8385 11.6667 7.58333C11.6667 5.32817 9.8385 3.5 7.58333 3.5C5.32817 3.5 3.5 5.32817 3.5 7.58333Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3335 4.66669H24.5002'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3335 10.5H24.5002'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3335 17.5H24.5002'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3335 23.3333H24.5002'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RadioLayoutListIcon;
