const WhatsappTemplateDefaultData = {
  name: '',
  category: '',
  templateType: '',
  language: 'en',
  body: '',
  bodyVariables: [],
  authTemplateBody: '',
  footer: '',
  headerType: '',
  headerValue: '',
  headerVariable: '',
  buttons: [],
  hasSecurityRecommendation: false,
  hasAuthCodeExpirationTime: false,
  authCodeExpirationTime: '', //in minutes
  cards: [
    {
      id: 1,
      header: {
        value: '',
      },
      body: '',
      buttons: [],
    },
  ],
  activeCarouselCardIndex: 1,
};

export default WhatsappTemplateDefaultData;
