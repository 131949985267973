import React from 'react';
import { Disclosure } from '@headlessui/react';
import { countryData, classNames } from '../../../../../../../utilities/utils';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { EcomAddressProps } from '../interfaces';

interface Prop {
  data: EcomAddressProps | undefined | null;
  handleChange: (key: string, value: string) => void;
  updateIsSameBillingAddress: (val: boolean) => void;
  isSameShippingAddress: boolean;
}

const DeliveryPresentDetails: React.FC<Prop> = ({
  data,
  handleChange,
  updateIsSameBillingAddress,
  isSameShippingAddress,
}) => {
  const ecomPlatform = 'woocommerce';
  // this is hard coded rn! please fetch it from store and do change logic below for other platforms such as shopify
  // i didnt changed it because its not needed as we only have woocommerce in

  return (
    <div className='p-6 border-b sm:mt-0'>
      <Disclosure>
        {({ open }) => (
          <div className=' md:grid md:grid-cols-1 md:gap-6'>
            <div className='md:col-span-1'>
              <div className='flex flex-wrap px-0 mb-4 sm:mb:0 space-between'>
                <Disclosure.Button>
                  <div className='flex flex-start'>
                    <p className='text-sm font-medium leading-5 text-gray-900'>
                      Shipping Information
                    </p>
                    <ChevronDownIcon
                      className={classNames(
                        open ? '-rotate-180' : 'rotate-0',
                        'ml-1 h-5 w-5 transform'
                      )}
                      aria-hidden='true'
                    />
                  </div>
                </Disclosure.Button>
                <div className='mt-4 sm:mt-0'>
                  <div className='max-w-lg space-y-4'>
                    <div className='relative flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          name='sameBilling'
                          type='checkbox'
                          checked={isSameShippingAddress}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateIsSameBillingAddress(e.target.checked)
                          }
                          className='w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='sameBilling'
                          className='font-medium text-gray-700'
                        >
                          Same as billing address
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className='bg-white'>
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='first_name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    First Name*
                  </label>
                  <input
                    type='text'
                    placeholder='Provide first name...'
                    value={data?.first_name}
                    disabled={isSameShippingAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='first_name'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <label
                    htmlFor='last_name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Last Name
                  </label>
                  <input
                    type='text'
                    placeholder='Provide last name...'
                    value={data?.last_name}
                    disabled={isSameShippingAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='last_name'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>

                {ecomPlatform !== 'woocommerce' && (
                  <>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='email_address'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Email Address
                      </label>
                      <input
                        type='text'
                        placeholder='Provide email here...'
                        value={data?.email}
                        disabled={isSameShippingAddress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        name='email'
                        autoComplete='new-password'
                        className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                      />
                    </div>
                    <div className='col-span-6 sm:col-span-3'>
                      <label
                        htmlFor='email_address'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Phone Number
                      </label>
                      <input
                        type='text'
                        placeholder='Provide phone here...'
                        disabled={isSameShippingAddress}
                        value={data?.phone || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('phone', e.target.value)
                        }
                        name='phone'
                        autoComplete='new-password'
                        className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                      />
                    </div>
                  </>
                )}
                <div className='col-span-6 sm:col-span-6'>
                  <label
                    htmlFor='email_address'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Address 1*
                  </label>
                  <input
                    type='text'
                    placeholder='Provide address line 1 here...'
                    disabled={isSameShippingAddress}
                    value={data?.address_one}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='address_one'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>
                <div className='col-span-6 sm:col-span-6'>
                  <label
                    htmlFor='address-1'
                    className='flex text-sm font-medium space-between'
                  >
                    <span className='text-gray-700'>Address 2 </span>{' '}
                    <span className='text-xs text-gray-500 uppercase'>
                      optional
                    </span>
                  </label>
                  <input
                    type='text'
                    placeholder='Provide address line 2 here...'
                    disabled={isSameShippingAddress}
                    value={data?.address_two}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='address_two'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>
                <div className='col-span-6 sm:col-span-2'>
                  <label
                    htmlFor='last_name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    City
                  </label>
                  <input
                    type='text'
                    placeholder='Provide City...'
                    disabled={isSameShippingAddress}
                    value={data?.city}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='city'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>
                <div className='col-span-6 sm:col-span-2'>
                  <label
                    htmlFor='last_name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    State/Province
                  </label>
                  <input
                    type='text'
                    placeholder='Provide State...'
                    disabled={isSameShippingAddress}
                    value={data?.state}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='state'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>
                <div className='col-span-6 sm:col-span-2'>
                  <label
                    htmlFor='last_name'
                    className='block text-sm font-medium text-gray-700'
                  >
                    ZIP/Postal
                  </label>
                  <input
                    type='text'
                    placeholder='Provide ZIP...'
                    disabled={isSameShippingAddress}
                    value={data?.postcode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    name='postcode'
                    autoComplete='new-password'
                    className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
                  />
                </div>
                <div className='col-span-6 sm:col-span-6'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Country / Region
                  </label>
                  <select
                    name='country'
                    disabled={isSameShippingAddress}
                    value={data?.country.toLowerCase() || 'singapore'}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      handleChange('country', e.target.value)
                    }
                    className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
                  >
                    {countryData.map((country: any, index: number) => (
                      <option key={index} value={country.name.toLowerCase()}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default DeliveryPresentDetails;
