import React from 'react';
import { connect } from 'react-redux';
import { ChannelCloneDetailsDataProps } from '../../../interface';
import PlatformListDropdown from './PlatformListDropdown';
import { channelListProps } from '../../../interface';
import UnsupportedBlockTag from 'old/components/elements/UnsupportedBlockTag';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  channelCloneDetailsData: ChannelCloneDetailsDataProps;
  handleChange: (key: string, value: string | string[]) => void;
  channelData: channelListProps;
  fetchChannelCloneBlocks: (
    channelId: number,
    cloneType: string
  ) => Promise<any>;
}

const ChannelCloneDetails: React.FC<Props> = ({
  channelCloneDetailsData,
  handleChange,
  channelData,
  fetchChannelCloneBlocks,
}) => {
  const [disallowedBlocks, setDisallowedBlocks] = React.useState([]);
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  return (
    <div className='p-6'>
      <div className='col-span-6 mt-5 sm:col-span-6 mt-1'>
        <label
          htmlFor='title'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Title')}*
        </label>
        <input
          type='text'
          placeholder={t('My channel title')}
          value={channelCloneDetailsData.title}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          name='title'
          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
        <p className='mt-2 text-sm text-gray-500'>
          {t(
            `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
          )}
        </p>
      </div>
      <div className='col-span-6 mt-5 sm:col-span-6 mt-1'>
        <label
          htmlFor='description'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Select Channel')}
        </label>
        <PlatformListDropdown
          channelData={channelData}
          handleChange={handleChange}
          onPlatformChange={async (channel: any) => {
            const response = await fetchChannelCloneBlocks(
              Number(channelData.id),
              channel
            );
            setDisallowedBlocks(!!response ? response : []);
          }}
        />
        <p className='clone-channel__info-box__text mt-2 text-sm text-gray-500'>
          {t(
            'Some sequence blocks will be disabled due to changed platform limitation.'
          )}
        </p>
      </div>
      <div className='clone-channel__info-box__component-container'>
        {disallowedBlocks.map((elem: string, i: number) => (
          <UnsupportedBlockTag key={i} title={`${elem} Block`} />
        ))}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  fetchChannelCloneBlocks: (channelId: number, cloneType: string) =>
    dispatch.channel.fetchChannelCloneBlocks({ channelId, cloneType }),
});

export default connect(mapState, mapDispatch)(ChannelCloneDetails);
