import React, { Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { languageData } from '../../../../utilities/utils';

interface Props {
  userLanguage: string;
  setClickedOnLanguage: (value: boolean) => void;
  setUserLanguage: (value: string) => void;
}

const LanguageOptionsDropdown: React.FC<Props> = ({
  userLanguage,
  setUserLanguage,
  setClickedOnLanguage,
}) => {
  const getLanguageName = () => {
    let lang: { id: number; value: string; name: string }[] = [];
    lang = languageData.filter((langData) => langData.value === userLanguage);
    if (lang.length > 0) return lang[0].name;
    else return userLanguage;
  };

  const renderLanguageName = (lang: any) => {
    return (
      <div className='flex w-full gap-7'>
        <span className='w-2/3 mt-1 '>{lang?.name}</span>
        {userLanguage !== null && userLanguage === lang?.value && (
          <div className='flex justify-end w-1/3 px-2'>
            <CheckIcon
              className='p-1 text-gray-900 w-7 h-7'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <Menu as='div' className='relative' id='agent-list'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className='inline-flex justify-start w-2/3 py-2 text-sm bg-white border border-gray-200 rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
              <div className='flex justify-start w-full'>
                <p className='w-1/2 ltr:pl-3 rtl:pr-3 font-normal ltr:text-left rtl:text-right text-gray-900 text'>
                  {userLanguage.length > 0 ? getLanguageName() : 'US English'}
                </p>
                <div className='flex justify-end w-1/2 px-2 py-0.5'>
                  <ChevronUpDownIcon
                    className='w-5 h-5 ml-2 mr-1 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
              </div>
            </Menu.Button>
          </div>
          {open && (
            <Transition
              show={true}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute ltr:left-0 rtl:right-0 z-20 mt-2 py-1.5 origin-top-right bg-white rounded-md shadow-lg w-2/3 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                {languageData.map(
                  (
                    language: {
                      id: number | string;
                      value: string;
                      name: string;
                    },
                    index: number | string
                  ) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            key={index}
                            className={classNames(
                              active
                                ? 'bg-white text-gray-800'
                                : 'text-gray-700',
                              'block w-full text-sm py-1.5 px-3 border-0 cursor-pointer'
                            )}
                            onClick={() => {
                              setClickedOnLanguage(true);
                              setUserLanguage(language.value);
                            }}
                          >
                            {renderLanguageName(language)}
                          </div>
                        )}
                      </Menu.Item>
                    );
                  }
                )}
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
};
export default LanguageOptionsDropdown;
