import React from 'react';
import { Button } from '../../index';
import { MinusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  title?: string;
  description?: string | React.ReactNode;
  className?: string;
  isDescriptionFullSize?: boolean;
  handleClose: () => void;
  handleMinimize?: () => void;
  hasMinimizeButton: boolean;
}

const Header: React.FC<Prop> = ({
  title,
  handleMinimize,
  handleClose,
  description,
  className = 'border-b border-gray-200 bg-gray-50',
  hasMinimizeButton,
  isDescriptionFullSize = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('p-4', className)}>
      <div className='flex items-center justify-between'>
        <p className='font-sans text-lg font-medium leading-7 capitalize text-gray-900'>
          {typeof title === 'string' ? t(title) : title}
        </p>
        <div>
          {hasMinimizeButton && (
            <Button
              isCapsuleButton
              className='mr-2'
              onClick={() => {
                if (handleMinimize) {
                  handleMinimize();
                }
              }}
              icon={<MinusIcon />}
            />
          )}
          <Button
            isCapsuleButton
            onClick={() => handleClose()}
            icon={<XMarkIcon className=' text-gray-500 ' />}
          />
        </div>
      </div>
      {!!description && (
        <p
          className={`${
            isDescriptionFullSize ? 'max-w-full' : 'max-w-xl'
          } font-sans text-sm rtl:text-right font-normal leading-5 text-gray-600`}
        >
          {typeof description === 'string' ? t(description) : description}
        </p>
      )}
    </div>
  );
};

export default Header;
