import useTranslation from 'components/customHooks/useTranslation';
import {
  FC,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Input,
  getHookFormRules,
  useFormContext,
} from '../../export';

export const TextField: FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const { t } = useTranslation();
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const { name: label, placeholder, is_required: required } = data;

  const renderTextFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'>{t(data?.label_agent || label)}</p>
        <p className='text-zinc-900 text-sm'>
          {form.getValues(currentFieldPath) || (
            <span className='text-zinc-500'> {t('(Empty)')} </span>
          )}
        </p>
      </div>
    );
  };

  const renderTextField = () => (
    <FormField
      control={form.control}
      name={currentFieldPath}
      disabled={data.is_readonly}
      rules={getHookFormRules(data)}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {t(data?.label_agent || label)}
            {required && <span className='text-red-500'>*</span>}
          </FormLabel>
          <FormControl>
            <Input
              disabled={shouldDisableInput}
              placeholder={placeholder}
              {...field}
              className='focus:border-green-500 focus-visible:ring-0'
            />
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {t(data.help_text)}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return formActionType === 'VIEW' ? renderTextFieldView() : renderTextField();
};
export default TextField;
