import WhatsappFileUploader from 'components/utilityComponent/WhatsappFileUploader';
import React from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import { classNames } from 'utilities/utils';
import { capitalize } from 'lodash';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import { PlusIcon } from '@heroicons/react/24/solid';
import { hasSingleValidVariablePattern } from '../utils/whatsappUtility';
import useTranslation from 'components/customHooks/useTranslation';

interface WhatsappTemplateHeaderProps {
  actionType: WhatsappTemplateActionType;
}

const WhatsappTemplateHeader: React.FC<WhatsappTemplateHeaderProps> = ({
  actionType,
}) => {
  const {
    whatsappTemplateState,
    updateTemplateHeaderVariable,
    updateTemplateHeaderValue,
  } = useWhatsappTemplateData();

  const hasVariable = !!hasSingleValidVariablePattern(
    whatsappTemplateState?.headerValue || ''
  );

  const { t } = useTranslation();

  const headerType = whatsappTemplateState.headerType;
  const isTemplateActionView = actionType === 'VIEW';
  const isAuthenticationTemplate =
    whatsappTemplateState.category === 'AUTHENTICATION';
  const isCarouselType = whatsappTemplateState.templateType === 'CAROUSEL';
  const shouldHideHeaderBlock =
    isAuthenticationTemplate || !headerType || isCarouselType;

  const handleHeaderText = (text: string) => {
    if (
      !hasVariable &&
      whatsappTemplateState.headerValue !== undefined &&
      whatsappTemplateState.headerValue.length < text.length &&
      text.endsWith('{{')
    ) {
      text += '1}}';
    }
    updateTemplateHeaderValue(text);
  };

  const handleHeaderVariableValue = (text: string) => {
    updateTemplateHeaderVariable(text);
  };

  const handleHeaderFileChange = (fileUrl: string) => {
    if (isTemplateActionView) {
      return;
    }
    updateTemplateHeaderValue(fileUrl);
  };

  const handleAddVariable = () => {
    handleHeaderText(whatsappTemplateState.headerValue + '{{');
  };

  const renderWhatsappTemplateTextHeader = () => {
    return (
      <div className='flex flex-col gap-2'>
        <div
          className={`flex border border-gray-300 focus-within:border-1 focus-within:border-primary
            focus-within:ring-1 focus-within:ring-primary rounded-md shadow-sm realtive w-full items-center
            px-3 gap-2 ${
              isTemplateActionView
                ? 'bg-gray-100 cursor-not-allowed'
                : 'bg-white cursor-default'
            }`}
        >
          <input
            maxLength={60}
            type='text'
            disabled={isTemplateActionView}
            placeholder={t('Type your header text')}
            value={whatsappTemplateState?.headerValue}
            onChange={(ev) => handleHeaderText(ev.target.value)}
            name='header-value'
            className={classNames(
              'w-[93%] sm:text-sm border-none focus:ring-0 focus:border-none rounded-md',
              isTemplateActionView
                ? 'bg-gray-100 cursor-not-allowed'
                : 'bg-white'
            )}
          />
          <div
            className={`rounded-md w-[40px] px-1 text-center text-xs py-1 bg-green-100 text-green-800`}
          >
            {whatsappTemplateState?.headerValue?.length}/60
          </div>
        </div>
        <div
          className={`flex justify-end ${
            isTemplateActionView ? 'hidden' : 'visible'
          }`}
        >
          {hasVariable ? (
            <input
              type='text'
              disabled={isTemplateActionView}
              placeholder={t('Type header variable value')}
              value={whatsappTemplateState?.headerVariable}
              onChange={(ev) => handleHeaderVariableValue(ev.target.value)}
              name='header-variable-value'
              className={classNames(
                'w-full sm:text-sm border-gray-300 focus:border-none focus:ring-2 focus:ring-green-500 rounded-md',
                isTemplateActionView
                  ? 'bg-gray-200 cursor-not-allowed'
                  : 'bg-white'
              )}
            />
          ) : (
            <button
              disabled={hasVariable}
              onClick={handleAddVariable}
              className='flex items-center gap-1 disabled:cursor-not-allowed bg-indigo-50 px-2 py-1 rounded-md text-indigo-400'
            >
              <PlusIcon className='w-5 h-5 stroke-2 font-bold text-indigo-400' />
              <span>{t('Add Variables')}</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderWhatsappTemplateHeader = () => {
    switch (headerType) {
      case 'text':
        return renderWhatsappTemplateTextHeader();
      case 'image':
        return (
          <WhatsappFileUploader
            file={whatsappTemplateState?.headerValue!}
            handleFileChange={handleHeaderFileChange}
            handleClear={() => handleHeaderFileChange('')}
            templateHeaderType='image'
            fileMimeTypes={['image/png', 'image/jpeg']}
          />
        );
      case 'video':
        return (
          <WhatsappFileUploader
            file={whatsappTemplateState?.headerValue!}
            handleFileChange={handleHeaderFileChange}
            handleClear={() => handleHeaderFileChange('')}
            templateHeaderType='video'
            fileMimeTypes={['video/mp4']}
          />
        );
      case 'document':
        return (
          <WhatsappFileUploader
            file={whatsappTemplateState?.headerValue!}
            handleFileChange={handleHeaderFileChange}
            handleClear={() => handleHeaderFileChange('')}
            templateHeaderType='document'
            fileMimeTypes={['application/pdf']}
          />
        );
      default:
        return null;
    }
  };

  return shouldHideHeaderBlock ? null : (
    <div className='p-4 mb-2 shadow-sm bg-white rounded-md text-gray-700'>
      <p className='pb-1 font-medium text-sm'>
        {capitalize(headerType)}
        <span className='text-[#F36363]'>*</span>
      </p>
      {renderWhatsappTemplateHeader()}
    </div>
  );
};

export default WhatsappTemplateHeader;
