import React from 'react';
import CsatOverviewTable from './overviewTable/CsatOverviewTable';

interface CsatOverviewProps {}

const CsatOverview: React.FC<CsatOverviewProps> = () => {
  return (
    <div
      data-testid='reporting-csat-overview-table'
      className='flex flex-col gap-4'
    >
      <div>
        <p className='text-textPrimary text-base font-semibold leading-6'>
          Detailed CSAT Overview
        </p>
        <p className='text-sm text-textSecondary leading-5'>
          A complete summary of relevant CSAT interactions.
        </p>
      </div>
      <CsatOverviewTable />
    </div>
  );
};

export default CsatOverview;
