import { React } from '../../../export';

interface Props {}

const AutomationDashboardHeader: React.FC<Props> = () => {
  const renderAutomationDashboardHeaderView = () => {
    return (
      <span className='justify-start text-xl font-bold text-gray-900'>
        Automations
      </span>
    );
  };

  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      {renderAutomationDashboardHeaderView()}
    </div>
  );
};

export default AutomationDashboardHeader;
