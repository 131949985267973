import IntntLogo from 'assets/icons/component/IntntLogo';
import yesStartUpsLogo from 'assets/images/partnerLogoSet/yesStartUpsLogo.jpg';
import leadHypedLogo from 'assets/images/partnerLogoSet/leadHypedLogo.svg';
import chipvnLogo from 'assets/images/partnerLogoSet/chipvnLogo.svg';
import emoMatrixLogo from 'assets/images/partnerLogoSet/emoMatrixLogo.svg';
import djogjaLogo from 'assets/images/partnerLogoSet/djogjaLogo.svg';
import rewnuLogo from 'assets/images/partnerLogoSet/rewnuLogo.svg';
import edzymLogo from 'assets/images/partnerLogoSet/edzymLogo.svg';
import cloudyDesLogo from 'assets/images/partnerLogoSet/cloudyDesLogo.png';
import chatBotHKLogo from 'assets/images/partnerLogoSet/chatBotHK.svg';
import resolvejaLogo from 'assets/images/partnerLogoSet/resolvejaLogo.png';
import logo845a from 'assets/images/partnerLogoSet/845aLogo.svg';
import populisDigitalLogo from 'assets/images/partnerLogoSet/populisDigitalLogo.svg';
import vlanderonLogo from 'assets/images/partnerLogoSet/vlanderonLogo.svg';
import onlinetechLogo from 'assets/images/partnerLogoSet/onlinetech.svg';
import Iomnihub from 'assets/images/partnerLogoSet/iomnihub.svg';
import IomnihubIcon from 'assets/icons/partnerIconSet/iomnihub.svg';
import merckLogo from 'assets/images/partnerLogoSet/merckLogo.png';
import { useEffect, useState } from 'react';

interface IPartnerInfo {
  shortName?: string;
  name: string;
  domain: string;
  title?: string;
  favicon?: string;
  emailDomains?: string[];
  bussinessUrl?: string;
  type: 'white-label' | 'reseller-partner';
  icon?: JSX.Element | React.ReactElement;
  logo: JSX.Element | React.ReactElement;

  /**
   * Follw this naming pattern:
   *fileName/node/Id,
   *fileName/function/Name,
   *fileName/state/Name
   */
  restrictions: string[];
}

export const PartnerInfo: IPartnerInfo[] = [
  {
    shortName: 'iOmnihub',
    name: 'Infocomm Group LLC (Oman)',
    title: 'iOmniHub',
    domain: 'app.iomnihub.ai',
    bussinessUrl: 'https://iomnihub.ai/',
    emailDomains: ['@i-grp.com'],
    favicon:
      'https://www.i-grp.com/wp-content/themes/inkness/images/favicon.png',
    type: 'white-label',
    logo: <img src={Iomnihub} alt='infocommLogo' className='w-32' />,
    icon: <img src={IomnihubIcon} alt='infocommIcon' className='w-28' />,
    restrictions: [
      // 'Navbar/node/API',
      // 'Navbar/node/Datalab',
      'UserMenu/node/Support',
      'Step1/node/wa-integration',
      'AuthHeader/node/alice_logo',
      'Settings/node/plan_billing',
      'Footer/function/Footer_text',
      // 'AuthHeader/node/sign_up_url',
      'Introduction/node/tg-partner',
      'Step1/node/live-chat-partner',
      'index/function/showTrialBanner',
      'Introduction/node/line-partner',
      'ConnectEmail/node/email-connect',
      'Footer/node/onboarding-contact',
      'LineSideSheet/node/line-partner',
      'ChatBotSettings/node/learn-more',
      'Introduction/node/viber-partner',
      'index/function/upgradeButtonView',
      'ViberSideSheet/node/viber-partner',
      'TelegramSideSheet/node/tg-partner',
      'Step1/node/wa-integration-support',
      'Introduction/node/fb-feed-partner',
      'MoreBlockDialog/node/block-partner',
      'LogoArea/function/renderDefaultLogo',
      'CannedResponse/node/watch-tutorial',
      'NotificationSettings/node/link-icon',
      'teamlist/function/renderDefaultLogo',
      'Footer/function/iomni-powerd-by',
      'ChannelListDropdown/node/channel-logo',
      'FacebookFeedSideSheet/node/fb-partner',
      'Introduction/node/insta-feed-partner',
      'Introduction/node/insta-chat-partner',
      'FacebookFeedSideSheet/node/fb-partner',
      'Function/function/GetModalCustomFooter',
      'Introduction/node/fb-messenger-partner',
      'Footer/node/integration-sidesheet-footer',
      // 'LoginFormSection/node/forgot_password_url',
      'InstagramChatSideSheet/node/insta-partner',
      'ApplicationSideSheet/node/mobile-app-plugin',
      'NewRegisterFormSection/node/registration-tos',
      'ForgetPasswordVerifyComponent/node/partner-branding',
      'AvailableIntegrationsCards/node/card-description',
      // 'BroadcastSummary/function/renderPaymentInfoSummary',
      'ForgetPasswordFormSection/node/reset-password-help',
      'WhatsappTemplatePreview/function/renderDefaultLogo',
    ],
  },
  {
    name: 'ota.myalice.ai',
    domain: 'ota.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={onlinetechLogo} alt='onlinetechlogo' className='w-24' />,
    restrictions: [],
  },
  {
    name: 'intnt.myalice.ai',
    domain: 'intnt.myalice.ai',
    type: 'reseller-partner',
    logo: <IntntLogo width='120' />,
    restrictions: [],
  },
  {
    name: 'leadhyped.myalice.ai',
    domain: 'leadhyped.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={leadHypedLogo} alt='leadHypedLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'yesstartups.myalice.ai',
    domain: 'yesstartups.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={yesStartUpsLogo} alt='yesStartUpsLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'chipvn.myalice.ai',
    domain: 'chipvn.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={chipvnLogo} alt='chipvnLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'djogja.myalice.ai',
    domain: 'djogja.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={djogjaLogo} alt='djogjaLogo' className='w-44' />,
    restrictions: [],
  },
  {
    name: 'cloudydes.myalice.ai',
    domain: 'cloudydes.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={cloudyDesLogo} alt='cloudyDesLogo' className='w-32' />,
    restrictions: [],
  },
  {
    name: 'chatbothk.myalice.ai',
    domain: 'chatbothk.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={chatBotHKLogo} alt='chatbotHKLogo' className='w-20' />,
    restrictions: [],
  },
  {
    name: 'resolveja.myalice.ai',
    domain: 'resolveja.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={resolvejaLogo} alt='resolvejaLogo' className='w-24' />,
    restrictions: [],
  },
  {
    name: '845a.myalice.ai',
    domain: '845a.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={logo845a} alt='logo845a' className='w-14' />,
    restrictions: [],
  },
  {
    name: 'populisdigital.myalice.ai',
    domain: 'populisdigital.myalice.ai',
    type: 'reseller-partner',
    logo: (
      <img src={populisDigitalLogo} alt='populisDigitalLogo' className='w-32' />
    ),
    restrictions: [],
  },
  {
    name: 'vlanderon.myalice.ai',
    domain: 'vlanderon.myalice.ai',
    type: 'reseller-partner',
    logo: <img src={vlanderonLogo} alt='vlanderonLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'chat.vlanderon.com',
    domain: 'chat.vlanderon.com',
    type: 'reseller-partner',
    logo: <img src={vlanderonLogo} alt='vlanderonLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'helpdesk.populisdigital.com',
    domain: 'helpdesk.populisdigital.com',
    type: 'reseller-partner',
    logo: (
      <img src={populisDigitalLogo} alt='populisDigitalLogo' className='w-32' />
    ),
    restrictions: [],
  },
  {
    name: 'portal.chatbot.hk.com',
    domain: 'portal.chatbot.hk.com',
    type: 'reseller-partner',
    logo: <img src={chatBotHKLogo} alt='chatbotHKLogo' className='w-20' />,
    restrictions: [],
  },
  {
    name: 'support.emomatrix.com',
    domain: 'support.emomatrix.com',
    type: 'reseller-partner',
    logo: <img src={emoMatrixLogo} alt='emoMatrixLogo' className='w-14' />,
    restrictions: [],
  },
  {
    name: 'cx.rewnu.com',
    domain: 'cx.rewnu.com',
    type: 'reseller-partner',
    logo: <img src={rewnuLogo} alt='rewnuLogo' className='w-28' />,
    restrictions: [],
  },
  {
    name: 'cx.edzym.com',
    domain: 'cx.edzym.com',
    type: 'reseller-partner',
    logo: <img src={edzymLogo} alt='edzymLogo' className='w-28' />,
    restrictions: [],
  },
  {
    shortName: 'Merck',
    title: 'Merck',
    //    favicon: 'https://www.merckgroup.com/etc/designs/mkgaa/assets/favicon/favicon.ico',
    icon: <img src={merckLogo} alt='merckLogo' className='w-32' />,
    name: 'merck.myalice.ai',
    domain: 'merck.myalice.ai',
    bussinessUrl: 'https://www.merckgroup.com',
    type: 'white-label',
    logo: <img src={merckLogo} alt='merckLogo' className='w-28' />,
    restrictions: [
      // 'Navbar/node/API',
      // 'Navbar/node/Datalab',
      'UserMenu/node/Support',
      'Step1/node/wa-integration',
      'AuthHeader/node/alice_logo',
      'Settings/node/plan_billing',
      'Footer/function/Footer_text',
      // 'AuthHeader/node/sign_up_url',
      'Introduction/node/tg-partner',
      'Step1/node/live-chat-partner',
      'index/function/showTrialBanner',
      'Introduction/node/line-partner',
      'ConnectEmail/node/email-connect',
      'LineSideSheet/node/line-partner',
      'Footer/function/iomni-powerd-by',
      'Introduction/node/viber-partner',
      'ChatBotSettings/node/learn-more',
      'Footer/node/onboarding-contact',
      'index/function/upgradeButtonView',
      'ViberSideSheet/node/viber-partner',
      'TelegramSideSheet/node/tg-partner',
      'Step1/node/wa-integration-support',
      'Introduction/node/fb-feed-partner',
      'CannedResponse/node/watch-tutorial',
      'MoreBlockDialog/node/block-partner',
      'LogoArea/function/renderDefaultLogo',
      'NotificationSettings/node/link-icon',
      'teamlist/function/renderDefaultLogo',
      'Introduction/node/insta-feed-partner',
      'Introduction/node/insta-chat-partner',
      'FacebookFeedSideSheet/node/fb-partner',
      'FacebookFeedSideSheet/node/fb-partner',
      'ChannelListDropdown/node/channel-logo',
      'Function/function/GetModalCustomFooter',
      'Introduction/node/fb-messenger-partner',
      'Footer/node/integration-sidesheet-footer',
      // 'LoginFormSection/node/forgot_password_url',
      'InstagramChatSideSheet/node/insta-partner',
      'ApplicationSideSheet/node/mobile-app-plugin',
      'NewRegisterFormSection/node/registration-tos',
      'AvailableIntegrationsCards/node/card-description',
      'ForgetPasswordFormSection/node/reset-password-help',
      // 'BroadcastSummary/function/renderPaymentInfoSummary',
      'WhatsappTemplatePreview/function/renderDefaultLogo',
      'CollapsableComponent/node/E-commerce',
    ],
  },
];

export const PartnerDomainList = PartnerInfo.map((p: IPartnerInfo) => p.domain);

export const CommonRestrictionForWhiteLabelPartners = [
  'Audiencetab/node/audience-learn-more',
  'BillingSummary/node/billing-policy',
  'AvailableIntegrationsCards/node/wit',
  'CollapsableComponent/node/NLP',
  'TicketSettings/node/help-docs',
  'MainPanel/node/live-chat-doc',
  'EditEmailIntegration/node/help-doc',
  'TelegramSetting/node/tg-doc-link',
  'ViberSetting/node/vb-doc-link',
  'WebchatPreview/node/webchat-channel-name',
  'replace-with-partner-brand',
  'AvailableIntegration/function/dynamic-integration',
  'LiveChatSteps/function/getModalCustomFooter',
  'Broadcast/node/wab-add-credit',
  'BroadcastSummary/node/card-info',
];

export const CommonRestrictionForResellerPartners: string[] = [];

/**
 * @usedIn  Settings.js
 * @description only for class based legacy components
 * @returns boolean
 */
export const isPartnerRestricted = (moduleName: string) => {
  const currentHostname = window.location.hostname;
  const partner = PartnerInfo.find((p) => p.domain === currentHostname);
  if (!partner) {
    return false;
  }
  let hasCommonRestriction = false;
  if (partner.type === 'white-label') {
    hasCommonRestriction =
      CommonRestrictionForWhiteLabelPartners.includes(moduleName);
  } else {
    hasCommonRestriction =
      CommonRestrictionForResellerPartners.includes(moduleName);
  }
  const isRestricted =
    partner.restrictions.includes(moduleName) || hasCommonRestriction;
  return isRestricted;
};

export default function usePartner() {
  const [currentPartnerInfo, setCurrentPartnerInfo] =
    useState<IPartnerInfo | null>(null);
  const currentDomain: string = window.location.hostname;
  const isParnterDomain: boolean = PartnerDomainList.includes(currentDomain);

  const getCurrentParnterInfo = () =>
    PartnerInfo.find((p) => p.domain === currentDomain) ?? null;

  const isPartnerRestricted = (moduleName: string): boolean => {
    if (currentPartnerInfo === null || !isParnterDomain) {
      return false;
    }
    let hasCommonRestriction = false;
    if (currentPartnerInfo.type === 'white-label') {
      hasCommonRestriction =
        CommonRestrictionForWhiteLabelPartners.includes(moduleName);
    } else {
      hasCommonRestriction =
        CommonRestrictionForResellerPartners.includes(moduleName);
    }
    const isRestricted =
      currentPartnerInfo.restrictions.includes(moduleName) ||
      hasCommonRestriction;
    return isRestricted;
  };

  const replaceWithPartnerName = (
    originalText: string = '',
    listOfWords: string[] = ['MyAlice']
  ): string => {
    const shouldReplaceMyalice = isPartnerRestricted(
      'replace-with-partner-brand'
    );
    const partnerName = shouldReplaceMyalice
      ? currentPartnerInfo?.shortName
      : 'MyAlice';
    const modifiedText = listOfWords.reduce((text, currentWord) => {
      return text.replace(currentWord, () => partnerName!);
    }, originalText);

    return modifiedText;
  };

  const getPartnerIcon = () => {
    if (!isParnterDomain || !currentPartnerInfo?.icon) {
      return null;
    }
    return currentPartnerInfo.icon;
  };

  useEffect(() => {
    const currentPartner = getCurrentParnterInfo();
    if (!currentPartner) {
      return;
    }
    setCurrentPartnerInfo(currentPartner);
    if (currentPartner?.title) {
      // @ts-ignore
      document.title = currentPartner.title;
    }
    let link = document.querySelector("link[rel~='icon']");
    if (currentPartner?.favicon && link) {
      // @ts-ignore
      link.href = currentPartner?.favicon;
    }
    // eslint-disable-next-line
  }, [currentDomain]);

  return {
    currentDomain,
    getPartnerIcon,
    isParnterDomain,
    currentPartnerInfo,
    isPartnerRestricted,
    replaceWithPartnerName,
  };
}
