import React from 'react';
import { connect } from 'react-redux';
import { getQueryToken } from '../../utilities/utils';
import IntegrationNavigationBar from './components/navigation/IntegrationNavigationBar';
import AvailableIntegration from './screens/AvailableIntegrations';
import CustomApi from './screens/CustomApi';
import InitialIntegration from './screens/InitialIntegration';
import Integrated from './screens/Integrated';

interface Props {
  slug: string;
  selectedPlatformsList: string[];
  availablePlatformsList: string[];
  selectedProject: selectedProjectProps;
  currentTab: { id: number; name: string };
  fetchUserAccountInfo: () => boolean;
  updateUserAccountInfo: (payload: object) => void;
  updateStateData: (payload: object) => void;
  clearState: () => void;
}

const Integration: React.FC<Props> = ({
  slug,
  selectedPlatformsList,
  availablePlatformsList,
  fetchUserAccountInfo,
  updateUserAccountInfo,
  selectedProject,
  updateStateData,
  clearState,
  currentTab,
}) => {
  const [hasViewedIntegrations, setHasViewedIntegrations] =
    React.useState<any>(null);

  const checkIfFromOnboarding = () => {
    let isFromOnboarding = getQueryToken('is_from_onboarding');
    return !!isFromOnboarding;
  };

  const handleHasViewedIntegrations = () => {
    updateUserAccountInfo({
      has_viewed_integrations: true,
    });
    setHasViewedIntegrations(true);
  };

  const getUserAccountInfo = async () => {
    let res = await fetchUserAccountInfo();
    setHasViewedIntegrations(res);
  };

  const getIntegrationPage = (viewedIntegration: boolean) => {
    if (checkIfFromOnboarding()) {
      viewedIntegration = true;
    }

    switch (viewedIntegration) {
      case true:
        return (
          <IntegrationNavigationBar
            navCurrentTab={slug}
            selectedPlatformsList={selectedPlatformsList}
            availablePlatformsList={availablePlatformsList}
            updateStateData={updateStateData}
          />
        );
      case false:
        return (
          <InitialIntegration
            handleHasViewedIntegrations={handleHasViewedIntegrations}
          />
        );
      default:
        return '';
    }
  };
  React.useEffect(() => {
    clearState();
    getUserAccountInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='h-full m-6 bg-white rounded'>
      {getIntegrationPage(hasViewedIntegrations)}
      {(hasViewedIntegrations || checkIfFromOnboarding()) &&
        {
          integrated: (
            <Integrated selectedPlatformsList={selectedPlatformsList} />
          ),
          'available-integrations': (
            <AvailableIntegration
              selectedPlatformsList={selectedPlatformsList}
            />
          ),
          'custom-api': <CustomApi />,
        }[slug]}
    </div>
  );
};

const mapState = (state: any) => ({
  selectedPlatformsList: state.integration.selectedPlatformsList,
  availablePlatformsList: state.integration.availablePlatformsList,
  selectedProject: state.dashboard.selectedProject,
  currentTab: state.integration.currentTab,
});

const mapDispatch = (dispatch: any) => ({
  fetchUserAccountInfo: () => dispatch.integration.fetchUserAccountInfo(),
  updateUserAccountInfo: (payload: object) =>
    dispatch.integration.updateUserAccountInfo(payload),
  updateStateData: (payload: object) =>
    dispatch.integration.updateStateData(payload),
  clearState: () => dispatch.integration.clearState(),
});

const IntegrationContainer = connect(mapState, mapDispatch)(Integration);

export default IntegrationContainer;
