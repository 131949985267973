import React from 'react';
import { connect } from 'react-redux';
import {
  InitialOrderInterface,
  ICustomerCartInfo,
  IEcommerceProductData,
} from 'pages/inbox/inboxInterface';
import editIcon from '../../../../../assets/images/edit-cart-button.svg';
import CustomListBox from './CustomListBox';
import SingleProduct from './SingleProduct';
import Button from 'library/button';
import useTranslation from 'components/customHooks/useTranslation';
interface PaymentMethodInterface {
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodInterface {
  method_id: string;
  method_title: string;
}

interface wooCommerceCouponInterface {
  id: number;
  code: string;
}

interface Props {
  currencySymbol: string;
  modalHeader: React.ReactNode;
  customerCart: ICustomerCartInfo;
  orderInformation: InitialOrderInterface;
  paymentMethodList: PaymentMethodInterface[];
  shippingMethodList: ShippingMethodInterface[];
  woocommerceDiscountCoupons: wooCommerceCouponInterface[];
  setStep: (step: number) => void;
  setShowRightbar: (value: boolean) => void;
  renderWooCommerceOrderAmountsView: (step: number) => void;
  updateEcommerceStateData: (key: string, value: any) => void;
  handleSetWooCommercePropertyInformation: (
    data: {
      key: string;
      value: any;
    }[]
  ) => void;
  handleSetWooCommerceObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const ConfigShipping: React.FC<Props> = ({
  orderInformation,
  paymentMethodList,
  shippingMethodList,
  woocommerceDiscountCoupons,
  customerCart,
  modalHeader,
  currencySymbol,
  setStep,
  setShowRightbar,

  updateEcommerceStateData,
  renderWooCommerceOrderAmountsView,
  handleSetWooCommerceObjectInformation,
  handleSetWooCommercePropertyInformation,
}) => {
  const { t } = useTranslation();
  let enabledPaymentMethods = paymentMethodList.filter(
    (item: PaymentMethodInterface) => {
      return item.is_enabled === true;
    }
  );
  let paymentMethodTitles = enabledPaymentMethods.map(
    (item: PaymentMethodInterface) => {
      return item.method_title;
    }
  );
  let shippingMethodNames = shippingMethodList.map(
    (item: ShippingMethodInterface) => {
      return item.method_title;
    }
  );

  let woocommerceCoupons = woocommerceDiscountCoupons.map(
    (item: wooCommerceCouponInterface) => {
      return item.code;
    }
  );
  woocommerceCoupons.unshift('No coupon/discount');

  let aliceCart = customerCart?.alice_cart;

  const calculateOrderValues = () => {
    let _subTotal: number = 0;
    aliceCart.forEach((product: IEcommerceProductData) => {
      _subTotal += product.unit_price * product.quantity;
    });

    let _discount = 0;
    if (orderInformation.couponOrDiscount) {
      _discount = parseInt(orderInformation.couponOrDiscount);
      _discount = _discount ? _discount : 0;
    }

    let _total = Number(
      _subTotal +
        (orderInformation.wooCommerceShippingCost
          ? orderInformation.wooCommerceShippingCost
          : 0) -
        _discount
    );

    let _shippingCost = orderInformation.wooCommerceShippingCost;
    if (orderInformation.deliveryType === 'Free shipping') {
      _shippingCost = 0;
    }

    handleSetWooCommercePropertyInformation([
      {
        key: 'wooCommerceOrderInformation',
        value: {
          ...orderInformation,
          wooCommerceSubTotal: _subTotal,
          wooCommerceDiscount: _discount,
          wooCommerceShippingCost: _shippingCost,
          wooCommerceTotal: _total,
        },
      },
    ]);
  };

  const handleAddMoreItem = () => {
    updateEcommerceStateData('openModal', true);
    setShowRightbar(false);
    updateEcommerceStateData('showProductView', true);
  };

  React.useEffect(
    () => {
      calculateOrderValues();
    },
    // eslint-disable-next-line
    [
      aliceCart,
      orderInformation.wooCommerceSubTotal,
      orderInformation.wooCommerceShippingCost,
      orderInformation.couponOrDiscount,
      orderInformation.deliveryType,
    ]
  );

  const renderDropDownListBoxView = () => {
    return (
      <div className='flex flex-col gap-3 mb-4'>
        <div>
          <CustomListBox
            key={'payment-method-dropdown'}
            orderMethodName={orderInformation.paymentMethod}
            dataList={paymentMethodTitles}
            methodName={'paymentMethod'}
            defaultTitle={t('Choose a payment method')}
            handleSetWooCommerceObjectInformation={
              handleSetWooCommerceObjectInformation
            }
          />
        </div>

        <div className='flex gap-2'>
          <div className='w-[74%]'>
            <CustomListBox
              key={'delivery-type-dropdown'}
              orderMethodName={orderInformation.deliveryType}
              dataList={shippingMethodNames}
              methodName={'deliveryType'}
              defaultTitle={'Choose delivery type'}
              defaultValue={'Free shipping'}
              handleSetWooCommerceObjectInformation={
                handleSetWooCommerceObjectInformation
              }
            />
          </div>
          <div
            className={`w-[25%] flex align-center ltr:justify-end rounded font-normal ${
              orderInformation.deliveryType === 'Free shipping'
                ? 'h-34px bg-gray-200 text-gray-500 border border-gray-300 text-xs font-normal pr-2'
                : ''
            }`}
          >
            {orderInformation.deliveryType === 'Free shipping' && (
              <span className='overflow-x-auto text-xs custom-text-overflow'>
                0
              </span>
            )}
            {orderInformation.deliveryType !== 'Free shipping' && (
              <input
                type='number'
                className='h-full w-full text-xs rounded border border-gray-300 focus:border-green-500
                              focus:ring-1 focus:ring-green-500 py-0.5 px-1.5'
                placeholder='Shipping Cost'
                name='shipping_cost'
                value={
                  orderInformation.wooCommerceShippingCost
                    ? orderInformation.wooCommerceShippingCost
                    : ''
                }
                onChange={(e) => {
                  handleSetWooCommerceObjectInformation({
                    objectKeyName: 'wooCommerceOrderInformation',
                    childObjectKeyName: 'wooCommerceShippingCost',
                    value: Number(e.target.value),
                  });
                }}
              />
            )}
          </div>
        </div>

        <div>
          <CustomListBox
            key={'coupon-or-discount-dropdown'}
            orderMethodName={orderInformation.couponOrDiscount}
            dataList={woocommerceCoupons}
            methodName={'couponOrDiscount'}
            defaultTitle={t('Apply a coupon/discount')}
            handleSetWooCommerceObjectInformation={
              handleSetWooCommerceObjectInformation
            }
          />
        </div>
      </div>
    );
  };

  const renderFooterButtonsView = () => {
    return (
      <div className='flex space-between text-sm border border-transparent border-t-gray-200 p-2 h-[7.5vh]'>
        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center`}
          onClick={() => setStep(1)}
        >
          Back
        </Button>

        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center hover:border-primary focus:outline-none transition ease-out duration-20 ${
            !orderInformation?.paymentMethod
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-primary text-white'
          }`}
          disabled={!orderInformation?.paymentMethod}
          onClick={() => {
            if (!!orderInformation.paymentMethod) {
              setStep(3);
            }
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderProductsView = () => {
    return (
      aliceCart &&
      aliceCart.map((product: IEcommerceProductData, id: number) => {
        return (
          <div className='box-border border border-transparent border-b-gray-200'>
            <SingleProduct
              key={id}
              product={product}
              currencySymble={currencySymbol}
            />
          </div>
        );
      })
    );
  };

  return (
    <div key={'config-shipping'} className='flex flex-col h-full'>
      {modalHeader}

      <div className='h-[86vh] create-wooCoomerce-order px-3 overflow-auto'>
        <div className='mb-4'>{renderProductsView()}</div>

        <button
          className='text-xs font-medium text-[#0078CF] mb-7'
          onClick={() => {
            handleAddMoreItem();
          }}
        >
          <p className='flex align-center'>
            <div className='h-[13px]'>
              <img src={editIcon} alt='edit-icon' />
            </div>
            &nbsp;
            <span className='leading-4'>Update cart</span>
          </p>
        </button>

        {renderDropDownListBoxView()}

        {renderWooCommerceOrderAmountsView(2)}
      </div>

      {renderFooterButtonsView()}
    </div>
  );
};

const mapState = (state: any) => ({
  customerCart: state.ecommerce.cart,
  paymentMethodList: state.ecommerce.paymentMethodList,
  shippingMethodList: state.ecommerce.shippingMethodList,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  woocommerceDiscountCoupons: state.ecommerce.woocommerceDiscountCoupons,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
});

export default connect(mapState, mapDispatch)(ConfigShipping);
