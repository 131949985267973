import React from 'react';
import SetUpIcon from '../../../assets/images/setupIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  title: string;
  subTitle?: string;
}

const ChannelSetupStatus: React.FC<Props> = ({ title, subTitle }) => {
  const { t } = useTranslation();
  return (
    <div className='p-6'>
      <div className='flex flex-col items-center justify-center mt-24'>
        <img src={SetUpIcon} alt='setup_icon' />
        <h1 className='mt-2 text-lg font-extrabold text-gray-900'>
          {t(title)}
        </h1>
        <h1 className='text-sm text-gray-600'>{t(subTitle ?? '')}</h1>
      </div>
    </div>
  );
};
export default ChannelSetupStatus;
