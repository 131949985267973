import React from 'react';
import PropTypes from 'prop-types';
import PopupBlockContent from './PopupBlockContent';
import { TextInput } from 'evergreen-ui';

const PopupBasicContent = ({
  blockType,
  attributeValue,
  handleInputValueChange,
}) => {
  return (
    <>
      {blockType === 'quick_reply' && (
        <div className='mt-3'>
          <h5>Input Value</h5>
          <TextInput
            className={
              'rounded w-full focus:outline-none focus:ring-primary focus:border-primary'
            }
            width={'100%'}
            value={attributeValue}
            placeholder='Provide input value'
            onChange={(e) => {
              handleInputValueChange(e.target.value);
            }}
          />
        </div>
      )}
      <p className='button-popup__hint'>
        <strong>Hint: </strong>
        <span>No new sequence will trigger if users choose this reply.</span>
      </p>
    </>
  );
};

PopupBlockContent.propTypes = {
  handleInputValueChange: PropTypes.func,
  blockType: PropTypes.string,
  attributeValue: PropTypes.string,
};

export default PopupBasicContent;
