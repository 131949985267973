import React from 'react';
import { connect } from 'react-redux';
import { useDebounce } from '../../../../../../components';
import ProductState from './components/ProductState';
import SearchProduct from './components/SearchProduct';
import ProductList from './components/ProductList';
import { ProductDataProps } from './interface';

interface Prop {
  productData: ProductDataProps[];
  selectedProductData: ProductDataProps[];
  teamId: number;
  fetchProductList: (
    teamId: number,
    search: string,
    offset: string,
    limit: number,
    isScrolled: boolean
  ) => Promise<Boolean>;
  productListLoading: boolean;
  shouldScrollNext: boolean;
  noProductsFound: boolean;
  currencySymbol: string;
  ecommerceType: string;
  updateProductDataList: (product: ProductDataProps) => void;
}

const Wrapper: React.FC<Prop> = ({
  productData,
  selectedProductData,
  teamId,
  fetchProductList,
  productListLoading,
  shouldScrollNext,
  noProductsFound,
  currencySymbol,
  ecommerceType,
  updateProductDataList,
}) => {
  const [search, setSearch] = React.useState<string>('');
  const [productListOffset, setProductListOffset] = React.useState<string>('');
  const [scrollLoading, setScrollLoading] = React.useState<boolean>(false);
  const [selectedProductIds, setSelectedProductIds] = React.useState<(string | number)[]>([]);

  const debouncedSearch = useDebounce(search, 1000);
  const limit: number = 10;

  const handleProductSelect = (product: ProductDataProps) => {
    if (!product.is_selected) {
      const updatedProduct = {...product, is_selected: true};
      updateProductDataList(updatedProduct);
      const updatedSelectedProductIds = selectedProductIds.concat(updatedProduct.product_id);
      setSelectedProductIds(updatedSelectedProductIds);
    } else {
      const updatedProduct = {...product, is_selected: false};
      updateProductDataList(updatedProduct);
      const updatedSelectedProductIds = selectedProductIds.filter(
        (product_id) => product_id !== updatedProduct.product_id
      );
      setSelectedProductIds(updatedSelectedProductIds);
    }
  };

  const handleProductSearch = async () => {
    setProductListOffset('');
    await fetchProductList(teamId, debouncedSearch, '', limit, false);
  };

  React.useEffect(() => {
    handleProductSearch();
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const getOffset = () => {
    let newOffset: string = '';
    if (ecommerceType === 'woocommerce') {
      newOffset = (Number(productListOffset) + limit).toString();
    } else {
      newOffset = productData[productData.length - 1]?.cursor;
    }
    return newOffset;
  }

  const handleLoadProductScroll = async () => {
    setScrollLoading(true);
    const newOffset: string = getOffset();
    const res: Boolean = await fetchProductList(
      teamId,
      debouncedSearch,
      newOffset,
      limit,
      true
    );
    setScrollLoading(false);
    if (res) {
      setProductListOffset(newOffset);
    }
  };

  return (
    <div>
      <SearchProduct search={search} setSearch={setSearch} />
      {!!debouncedSearch && (
        <p className='px-6 my-2 font-sans text-xs font-medium text-gray-300'>
          {`Showing Result for ${debouncedSearch}`}
        </p>
      )}
      {(!scrollLoading && productListLoading) && (
        <ProductState state={'loading'} />
      )}

      {(scrollLoading || !productListLoading) && (
        <>
          {productData.length === 0 && selectedProductData.length === 0 ? (
            <ProductState state={'no-result'} />
          ) : (
            <ProductList
              productData={productData}
              selectedProductData={selectedProductData}
              selectedProductIds={selectedProductIds}
              handleProductSelect={handleProductSelect}
              shouldScrollNext={shouldScrollNext}
              handleLoadProductScroll={handleLoadProductScroll}
              noProductsFound={noProductsFound}
              productListLoading={productListLoading}
              currencySymbol={currencySymbol}
            />
          )}
        </>
      )}
    </div>
  );
};


const mapState = (state: any) => ({
  productData: state.crmEcommerce.productData,
  selectedProductData: state.crmEcommerce.selectedProductData,
  teamId: state.dashboard.selectedProject.id,
  productListLoading: state.loading.effects.crmEcommerce.fetchProductList,
  shouldScrollNext: state.crmEcommerce.shouldScrollNext,
  noProductsFound: state.crmEcommerce.noProductsFound,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  ecommerceType: state.dashboard.selectedProject.ecommerce_type,
});

const mapDispatch = (dispatch: any) => ({
  fetchProductList: (
    teamId: number,
    search: string,
    offset: string,
    limit: number,
    isScrolled: boolean
  ) =>
    dispatch.crmEcommerce.fetchProductList({
      teamId,
      search,
      offset,
      limit,
      isScrolled,
    }),
  updateProductDataList: (product: ProductDataProps) =>
    dispatch.crmEcommerce.updateProductDataList(product),
});

const WrapperContainer = connect(mapState, mapDispatch)(Wrapper);
export default WrapperContainer;
