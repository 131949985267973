import React from 'react';
import { findAlertInfo } from '../../../../utilities/utils';
import { Label } from 'evergreen-ui';

interface Prop {
  intent: 'warning' | 'success' | 'info' | 'danger';
  text?: string | number;
}

const Alert: React.FC<Prop> = ({ intent, text }) => {
  return (
    <div className='flex align-center mt--6px'>
      <img src={findAlertInfo(intent).icon} alt={'alert-icon'} width={16} />
      <Label className={findAlertInfo(intent).className}>&nbsp;{text}</Label>
    </div>
  );
};

export default Alert;
