import useTranslation from 'components/customHooks/useTranslation';
import BlankModal from 'library/modal/BlankModal';
import { toast } from 'libraryV2/ui/use-toast';
import { IEcommerceChannelProperty } from 'pages/integration/interface';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  ecommerceData: IEcommerceChannelProperty;
}
export const EditEcommerceModal: FC<Props> = ({
  handleClose,
  open,
  ecommerceData,
}) => {
  const { t } = useTranslation();
  const [storeName, setStoreName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!ecommerceData?.store_name) {
      setStoreName(ecommerceData?.store_name);
    }
  }, [ecommerceData?.store_name]);

  const handleStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStoreName(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      teamId: ecommerceData.project_id,
      storeId: ecommerceData.alice_store_id,
      storeName,
    };

    const res = await dispatch.integration.editStorName(payload);
    if (res) {
      toast({
        title: t('update successful'),
        description: t('Store name has been updated successfully.'),
        toastType: 'success',
      });
      handleClose();
    } else {
      toast({
        title: t('Failed to update store name'),
        toastType: 'destructive',
      });
    }
    setIsLoading(false);
  };
  return (
    <div>
      <BlankModal
        forIntegration
        title={t('Edit')}
        submitButtonText={t('Save Changes')}
        cancelButtonText={t('Cancel')}
        subTitle={t('Edit e-commerce store name.')}
        open={open}
        loading={isLoading}
        handleClose={handleClose}
        onSubmitButtonClick={handleSubmit}
        customModalWidth='sm:w-[32rem]'
      >
        <div className='flex flex-col'>
          <div className='flex justify-between'>
            <label
              htmlFor='description'
              className='block text-sm font-medium text-gray-700'
            >
              {t('Store name')} <span className='text-red-500'>*</span>
            </label>
          </div>
          <input
            type='text'
            placeholder={t('Store name')}
            value={storeName}
            onChange={handleStoreNameChange}
            name='name'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 rtl:text-right text-sm text-gray-500'>
            {t(
              'This store name will be used in MyAlice to identify this channel. Give it a title that you can differentiate with later.'
            )}
          </p>
        </div>
      </BlankModal>
    </div>
  );
};
