export const convertToJinjaVariableStrings = (
  obj: any,
  parentKey: string = ''
): string[] => {
  let jinjaStrings = [];

  for (const key in obj) {
    // If it's a top-level property (no parentKey), access it directly without get()
    const newKey = parentKey ? `${parentKey}["${key}"]` : key;

    if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        jinjaStrings.push(
          ...convertToJinjaVariableStrings(item, `${newKey}[${index}]`)
        );
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      jinjaStrings.push(...convertToJinjaVariableStrings(obj[key], newKey));
    } else {
      // Use get() notation for nested properties (if parentKey exists)
      if (parentKey) {
        jinjaStrings.push(`${parentKey}["${key}"]`);
      } else {
        jinjaStrings.push(`${key}`);
      }
    }
  }

  return jinjaStrings;
};

export const removePlaceholders = (str: string) => {
  return str.replace(/\{\{|\}\}/g, '');
};

export const replaceBrackets = (str: string) => {
  if (!str.startsWith('{{') || !str.endsWith('}}')) return str;
  if (str.startsWith('{{')) {
    str = str.slice(2);
  }

  if (str.endsWith('}}')) {
    str = str.slice(0, -2);
  }

  return str;
};
