import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  success?: boolean;
  errorMessage?: string;
  telegramSettingData: telegramSettingDataProps;
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const Step1: React.FC<Props> = ({
  success = true,
  errorMessage,
  handleChange,
  telegramSettingData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!success && (
        <div className='flex justify-center'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600 ltr:ml-2 rtl:mr-2'>
            {errorMessage}
          </p>
        </div>
      )}
      <div>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700 rtl:text-right'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My telegram channel')}
            value={telegramSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500 rtl:text-right'>
            {t(
              'This title will be used in MyAlice to identify this channel. Give it a title that you can differentiate with later.'
            )}
          </p>
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='primary_token'
            className='block text-sm font-medium text-gray-700 rtl:text-right'
          >
            {t('Primary Token')}*
          </label>
          <input
            type='text'
            placeholder={t('Primary token found in your telegram account')}
            value={telegramSettingData.primary_token}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='primary_token'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm rtl:text-right focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm font-medium text-gray-500 rtl:text-right'>
            {t(
              'To connect your Telegram account with MyAlice, you should create an account through'
            )}
            {` `}
            <a
              href='https://core.telegram.org/'
              target='_blank'
              className='text-blue-500 hover:text-blue-800'
              rel='noreferrer'
            >
              {`Telegram Admin Panel, `}
            </a>
            {t(
              'if you don’t have one already. Then provide the Primary Token which you will find in the admin panel. For details,'
            )}
            <a
              href='https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-telegram'
              target='_blank'
              className='text-blue-500 hover:text-blue-800'
              rel='noreferrer'
            >
              {` ${t('Read this.')}`}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Step1;
