import React from 'react';
import { VariantProps, SelectedProductDataProps } from '../../eCommerceOrderCreate/interfaces';
import ShopifyOrderSelectedListItem from './ShopifyOrderSelectedListItem';

interface Prop {
  productData: SelectedProductDataProps[];
  currencySymbol: string;
  variantLists: VariantProps[];
}

const ShopifyOrderSelectedLists: React.FC<Prop> = ({
  productData,
  currencySymbol,
  variantLists,
}) => {
  const constructProductVariantMap = () => {
    let productVariantMap = {};
    variantLists.forEach((product) => {
      let variantAttributeMap = {};
      let variants = product.dataSource;
      variants.forEach((variant) => {
        variantAttributeMap[variant.variant_id] = variant.attribute_string;
      });
      productVariantMap[product.id] = variantAttributeMap;
    });
    return productVariantMap;
  };

  const productVariantMap = constructProductVariantMap();

  return (
    <div className='mt-4'>
      {productData.map((product, index) => (
        <ShopifyOrderSelectedListItem
          key={index}
          product={product}
          variantAttributeMap={productVariantMap[product.product_id]}
          currencySymbol={currencySymbol}
        />
      ))}
    </div>
  );
};

export default ShopifyOrderSelectedLists;
