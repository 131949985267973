import React from 'react';
import Modal from '../../common/Modal';
import Step1 from './Step1';

import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { getModalCustomFooter } from '../../../utils/functions';

interface Props {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  handleChannelCreate: (
    channelType: string,
    channelData: any
  ) => Promise<channelCreateAPiResponseType>;
}

const initialSettingData = {
  name: '',
  title: '',
  primary_token: '',
  is_published: true,
  connected_nlp_integration_id: '',
};

const TelegramModal: React.FC<Props> = ({
  open,
  teamName,
  handleClose,
  handleChannelCreate,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [telegramSettingData, setTelegramSettingData] =
    React.useState(initialSettingData);

  const clearState = () => {
    setTelegramSettingData(initialSettingData);
    setStep(1);
    setSuccess(true);
    setErrorMessage('');
    setLoading(false);
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1: {
        setLoading(true);
        let payloadData = {
          title: telegramSettingData.title,
          primary_token: telegramSettingData.primary_token,
          connected_nlp_integration_id:
            telegramSettingData.connected_nlp_integration_id,
          is_published: telegramSettingData.is_published,
        };
        let res = await handleChannelCreate('telegram_messenger', payloadData);
        if (res.status === 200) {
          setLoading(false);
          navigate(`/dashboard?channel_type=telegram_messenger`);
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? t(
                  'Something went wrong while integrating the channel. Try again later.'
                )
              : res.data.error
          );
        }
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
    }
  };

  const checkFinishButton = () => {
    const mandatoryKeys = ['title', 'primary_token'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(telegramSettingData && telegramSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 1:
        return checkFinishButton();
      default:
        return false;
    }
  };

  return (
    <Modal
      open={open}
      teamName={teamName}
      title={
        isRtlLanguage ? t('Telegram Messenger to') : t('Telegram Messenger')
      }
      platfromType='telegram_messenger'
      closeOnExternalClick={false}
      handleClose={() => {
        clearState();
        handleClose();
      }}
      handleCancel={() => handleCancel(step)}
      disableConfirm={checkConfirmButtons() || loading}
      handleConfirm={handleConfirm}
      hideCancel={true}
      confirmText={t('Create Channel')}
      customFooterContent={getModalCustomFooter(
        t('Confused about what to do?'),
        'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-telegram',
        t('Read Documentation')
      )}
    >
      <div className='p-6'>
        <>
          {loading ? (
            <div className='flex flex-col items-center justify-center mt-20'>
              <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
              <span>{t('creating channel...')}</span>
            </div>
          ) : (
            <Step1
              success={success}
              errorMessage={errorMessage}
              telegramSettingData={telegramSettingData}
              handleChange={(
                key: string,
                value: string | string[] | boolean
              ) => {
                setTelegramSettingData({
                  ...telegramSettingData,
                  [key]: value,
                });
              }}
            />
          )}
        </>
      </div>
    </Modal>
  );
};
export default TelegramModal;
