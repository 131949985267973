import useTranslation from 'components/customHooks/useTranslation';
import { FC } from 'react';

const SallaIntegrationStep1: FC = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col  rtl:text-right'>
      <h1 className='text-lg text-gray-800 font-medium leading-5'>
        {t('Connect Your Salla Store with MyAlice:')}
      </h1>
      <ul className='list-disc list-outside mx-6 text-sm font-normal leading-5'>
        <li className='my-4'>
          {t(
            "Start the Integration: Once you install MyAlice from Salla App Store, navigate to your Salla store's dashboard, go to 'Installed Applications', find MyAlice, and click 'Activate Application'."
          )}{' '}
          {t(
            'This step initiates the connection between your Salla store and MyAlice.'
          )}
        </li>
        <li className='my-4'>
          {t(
            "Enter Key Information: You will need a few pieces of information to fully enable MyAlice's capabilities on your store."
          )}{' '}
          {t("You'll find all the necessary details on the next screen.")}
        </li>
        <li className='my-4'>
          {t(
            'Enjoy Live Chat Features: With activation complete, your store will now feature a live chat option, enhancing your customer service experience.'
          )}
        </li>
      </ul>
      <p>
        {t(
          'You have full control over the live chat widget - it can be deactivated or edited at any time from your settings or directly through MyAlice integration.'
        )}
      </p>
    </div>
  );
};

export default SallaIntegrationStep1;
