import React from 'react';
import Modal from './Modal';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import agentRemove from '../assets/agent-remove.svg';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { subscriptionPlanUpdateType } from '../interface';
import useTranslation from 'components/customHooks/useTranslation';

interface BillingUpdateModalType {
  step: number;
  setShowBillingCycleUpdateModal: (payload: boolean) => void;
  subscriptionPlanUpdate: subscriptionPlanUpdateType;
  handleSubscription: () => void;
  billingCycle: boolean;
}

const BillingCycleUpdateModal: React.FC<BillingUpdateModalType> = ({
  step,
  setShowBillingCycleUpdateModal,
  subscriptionPlanUpdate,
  handleSubscription,
  billingCycle,
}) => {
  const { t } = useTranslation();
  const fromBilling = billingCycle ? 'monthly' : 'yearly';
  const toBilling = billingCycle ? 'yearly' : 'monthly';
  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Modal>
              <div className='flex items-center justify-center px-5 py-12 flex-col'>
                <img className='w-26 h-26' src={agentRemove} alt='remove' />
                <p className='text-gray-800 text-lg font-bold mb-2'>
                  {t('Updating your Billing Cycle')}
                </p>
                <p className='text-gray-500 text-sm'>
                  {t('This may take a while.')}
                </p>
              </div>
            </Modal>
          </>
        );
      case 2:
        return (
          <Modal>
            <div className='flex items-center justify-center p-5 flex-col'>
              <CheckIcon className='bg-green-100 rounded-[50%] text-green-500 w-10 h-10 p-2 mb-3' />
              <p className='text-gray-800 font-semibold text-xl mb-2'>
                {t('Billing Cycle updated successfully')}
              </p>
              <p className='text-gray-400 text-sm mb-5 text-center'>
                {t('Your request to update your Billing cycle from')}{' '}
                <span className='text-gray-800 font-bold capitalize'>
                  {t(fromBilling)}
                </span>{' '}
                {t('to')}{' '}
                <span className='text-gray-800 font-bold capitalize'>{t(toBilling)}</span>{' '}
                {t(
                  'is successful. The billing cost has been adjusted accordingly.'
                )}
              </p>
              <button
                type='button'
                className='bg-green-500 rounded-md text-white w-full p-2 mb-3'
                onClick={() => navigate('/settings/billing')}
              >
                {t('Continue')}
              </button>
            </div>
          </Modal>
        );
      case 3:
        return (
          <Modal>
            <div className='flex items-center justify-center p-5 flex-col'>
              <ExclamationTriangleIcon className='bg-red-100 rounded-[50%] text-red-500 w-10 h-10 p-2 mb-3' />
              <p className='text-gray-800 font-semibold text-xl mb-2'>
                {t('Billing Cycle update unsuccessful')}
              </p>
              <div className='text-gray-400 text-sm mb-5 text-center'>
                {t(`Your request to update your Billing Cycle was unsuccessful.
                Please`)}{' '}
                <a
                  href='https://www.myalice.ai/support'
                  target='_blank'
                  className='text-blue-500 cursor-pointer'
                  rel='noreferrer'
                >
                  {t('contact support')}
                </a>{' '}
                {t('if this issue persists.')}
              </div>
              <button
                type='button'
                className='border-2 rounded-md text-gray-500 w-full p-2 mb-3'
                onClick={() => handleSubscription()}
              >
                {t('Try Again')}
              </button>
              <button
                type='button'
                className='border-2 rounded-md text-gray-500 w-full p-2'
                onClick={() => setShowBillingCycleUpdateModal(false)}
              >
                {t('Cancel')}
              </button>
            </div>
          </Modal>
        );
      default:
        return null;
    }
  };

  return <div>{getComponent()}</div>;
};

const mapState = (state: any) => ({
  subscriptionPlanUpdate: state.newBilling.subscriptionPlanUpdate,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(BillingCycleUpdateModal);
