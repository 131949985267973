import React from 'react';
import ProductSelection from './screens/ProductSelection';
import DeliveryInformation from './screens/DeliveryInformation';
import CreateOrderSummary from './screens/CreateOrderSummary';
import { connect } from 'react-redux';
import ConfirmOrder from '../eCommerceOrderCreate/screens/ConfirmOrder';
import { SelectedProductDataProps } from '../eCommerceOrderCreate/interfaces';

interface Prop {
  step: number;
  customerId: number;
  fetchCart: (customerId: number) => Promise<any>;
  confirmOrderAPI: () => Promise<any>;
  orderId?: string;
  fetchOrder: (teamId: number, orderId: string) => Promise<any>;
  projectId: number;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
}
const Wrapper: React.FC<Prop> = ({
  step,
  customerId,
  fetchCart,
  confirmOrderAPI,
  orderId,
  fetchOrder,
  projectId,
  updateCart,
}) => {
  React.useEffect(() => {
    if (customerId) {
      fetchCart(customerId);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {step === 1 && <ProductSelection customerId={customerId} />}
      {step === 2 && <DeliveryInformation customerId={customerId} />}
      {step === 3 && <CreateOrderSummary teamId={projectId} customerId={customerId} />}
      {step === 4 && <ConfirmOrder confirmOrderAPI={confirmOrderAPI} />}
    </>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
});

const mapDispatch = (dispatch: any) => ({
  fetchCart: (customerId: number) =>
    dispatch.crmEcommerce.fetchCart(customerId),
  fetchOrder: (projectId: number, orderId: string) =>
    dispatch.crmEcommerce.fetchOrder({
      projectId,
      orderId,
    }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
});

const WrapperContainer = connect(mapState, mapDispatch)(Wrapper);
export default WrapperContainer;
