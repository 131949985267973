import {
  LiveChatDataProps,
  SurveyDataEntity,
} from 'pages/integration/interface';

export interface ValidatorProps {
  hasError: boolean;
  msg: string;
}

export const DataValidator = (livechatData: LiveChatDataProps) => {
  if (!livechatData.title)
    return { hasError: true, msg: "Brand Name can't be empty." };
  if (!livechatData.livechat_data.header_title)
    return { hasError: true, msg: "Greeting title can't be empty." };
  if (
    livechatData.livechat_data.prechat_survey_visibility_option &&
    livechatData.livechat_data.prechat_survey_visibility_option.length > 0
  ) {
    let allPrechatHasData =
      livechatData.livechat_data.prechat_survey_data_points?.every(
        (data: SurveyDataEntity) => data.attribute && data.title
      );
    if (!allPrechatHasData)
      return { hasError: true, msg: "Pre-chat attribute data can't be empty" };
  }

  return { hasError: false, msg: 'All Okay' };
};
