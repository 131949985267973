import React, { Fragment } from 'react';
import { Combobox } from 'evergreen-ui';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: { id: number; title: string };
  handleChange: (value: number) => void;
  options: any[];
}

const SequenceSelectInput: React.FC<props> = ({
  value,
  handleChange,
  options,
}) => {
  return (
    <Fragment>
      <TitleHolderBuilder
        title='Failed Validation Sequence'
        tooltipInfo='Redirect users to this sequence if the bot fails to validate their responses after maximum error count.'
      />
      <Combobox
        openOnFocus
        initialSelectedItem={value}
        items={options}
        height={40}
        style={{ width: '100%' }}
        className='custom-input-style'
        itemToString={(item) => (item ? item.title : '')}
        onChange={(selected) => selected && handleChange(selected)}
        placeholder='Select Sequence'
      />
    </Fragment>
  );
};

export default SequenceSelectInput;
