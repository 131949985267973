import React from 'react';
import PaymentForm from './PaymentForm';
import Modal from '../Modal';
import { connect } from 'react-redux';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  subscriptionPlanUpdateType,
  couponResponse,
  subscriptionDetailsType,
  billingInfoType,
} from '../../interface';
import checkoutLoading from '../../assets/checkoutLoading.png';
import useTranslation from 'components/customHooks/useTranslation';

type props = {
  createPaymentMethod: (payload: any) => boolean;
  userInfo: any;
  selectedProject: any;
  setShowPaymentForm: (value: boolean) => void;
  manageCard: (payload: any) => boolean;
  userEmail: string;
  createSubscription: (payload: any) => {
    status: boolean;
    marketPlace: string;
  };
  isBillingAnnually: boolean;
  teamMemberCount: number;
  billingInformationUpdate: (payload: any) => {
    success: boolean;
    error: string;
    data: any;
  };
  subscriptionPlanUpdate: subscriptionPlanUpdateType;
  cardInfo: any;
  handleCouponCode: (code: string) => void;
  isPromoApplied: boolean;
  couponProperty: couponResponse | null;
  subscriptionDetails: subscriptionDetailsType;
  billingInfo: billingInfoType;
  fetchAvailablePlans: () => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  fetchPaymentDetails: () => void;
  fetchHistories: (teamId: number) => void;
  fetchEstimation: (payload: number) => void;
  disableCheckout: boolean;
  setDisableCheckout: (payload: boolean) => void;
  planType: string;
  paymentModalStep: number;
  setPaymentModalStep: (value: number) => void;
};

const PaymentModal: React.FC<props> = ({
  createPaymentMethod,
  manageCard,
  userInfo,
  selectedProject,
  setShowPaymentForm,
  userEmail,
  createSubscription,
  isBillingAnnually,
  teamMemberCount,
  billingInformationUpdate,
  subscriptionPlanUpdate,
  cardInfo,
  handleCouponCode,
  isPromoApplied,
  couponProperty,
  subscriptionDetails,
  billingInfo,
  fetchAvailablePlans,
  fetchSubscriptionDetails,
  fetchPaymentDetails,
  fetchHistories,
  fetchEstimation,
  disableCheckout,
  setDisableCheckout,
  planType,
  paymentModalStep,
  setPaymentModalStep,
}) => {
  const { t } = useTranslation();

  const getForm = (step: number) => {
    switch (step) {
      case 1:
        return (
          <PaymentForm
            setShowPaymentForm={setShowPaymentForm}
            createPaymentMethod={createPaymentMethod}
            manageCard={manageCard}
            userEmail={userEmail}
            createSubscription={createSubscription}
            selectedProject={selectedProject}
            isBillingAnnually={isBillingAnnually}
            teamMemberCount={teamMemberCount}
            setStep={setPaymentModalStep}
            billingInformationUpdate={billingInformationUpdate}
            totalCost={subscriptionPlanUpdate.total_cost}
            cardInfo={cardInfo}
            isPromoApplied={isPromoApplied}
            couponProperty={couponProperty}
            billingInfo={billingInfo}
            fetchAvailablePlans={fetchAvailablePlans}
            fetchSubscriptionDetails={fetchSubscriptionDetails}
            fetchPaymentDetails={fetchPaymentDetails}
            fetchHistories={fetchHistories}
            fetchEstimation={fetchEstimation}
            disableCheckout={disableCheckout}
            setDisableCheckout={setDisableCheckout}
            planType={planType}
          />
        );
      case 2:
        return (
          <div className='rounded-md'>
            <div className='flex justify-center'>
              <img
                src={checkoutLoading}
                className='h-52 w-52 mb-3'
                alt='checkout-loading'
              />
            </div>
            <div className='flex flex-col justify-center items-center'>
              <p className='text-xl text-gray-900 font-semibold'>
                {t('Confirming your payment details')}
              </p>
              <p className='text-sm text-gray-400'>
                {t('This may Take a while')}
              </p>
            </div>
          </div>
        );
      case 3:
        return (
          <div className='flex flex-col justify-center items-center rounded-md'>
            <CheckCircleIcon className='h-8 w-8 text-green-500 bg-green-100 rounded-[50%] mb-3 p-1' />
            <p className='text-gray-900 gont-semibold text-lg'>
              {t('Payment Complete')}
            </p>
            <p className='text-sm text-gray-400 mb-3 text-center'>
              {t('You have successfully subscribed to')} {' '}
              <span className='capitalize'>
                {t(`{{${planType}}} Plan - {{${subscriptionDetails?.project_billing_info?.subscription_plan?.billing_cycle}}}.`)}
              </span>
              {' '} {t('An invoice has been sent to your billing email. Please contact support for further query.')}
            </p>
            <a href='/settings/billing' className='w-full text-center'>
              <button
                type='reset'
                className='p-2 border border-gray-300 rounded-md w-2/3 mb-3 text-white bg-green-500'
              >
                {t('Continue')}
              </button>
            </a>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Modal>
      <div className='bg-white p-[32px] rounded-md'>{getForm(paymentModalStep)}</div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  userInfo: state.settings.userInfo,
  selectedProject: state.dashboard.selectedProject,
  userEmail: state.auth.email,
  teamMemberCount: state.newBilling.teamMemberCount,
  isBillingAnnually: state.newBilling.isBillingAnnually,
  subscriptionPlanUpdate: state.newBilling.subscriptionPlanUpdate,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  cardInfo: state.newBilling.cardInfo,
});
const mapDispatch = (dispatch: any) => ({
  manageCard: (payload: any) => dispatch.newBilling.manageCard(payload),
  createPaymentMethod: (payload: any) =>
    dispatch.newBilling.createPaymentMethod(payload),
  createSubscription: (payload: any) =>
    dispatch.newBilling.createSubscription(payload),
  billingInformationUpdate: (payload: any) =>
    dispatch.newBilling.billingInformationUpdate(payload),
  handleCouponCode: (code: string) =>
    dispatch.newBilling.handleCouponCode(code),
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  fetchPaymentDetails: () => dispatch.newBilling.fetchPaymentDetails(),
  fetchHistories: (teamId: number) =>
    dispatch.newBilling.fetchHistories(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchEstimation: (payload: number) =>
    dispatch.newBilling.fetchEstimatedCost(payload),
});

export default connect(mapState, mapDispatch)(PaymentModal);
