import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import webchatIcon from '../images/webChatIcon.svg';
import aliceIcon from '../images/aliceLogo.svg';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  fillColor?: string;
  image?: string;
  title?: string;
}

const WebchatPreview: React.FC<Props> = ({ fillColor, image, title }) => {
  const {currentPartnerInfo,isPartnerRestricted} = usePartner();
  const defaultChannelName = isPartnerRestricted('WebchatPreview/node/webchat-channel-name') ? currentPartnerInfo?.shortName : '' || 'Alice Labs';

  return (
    <div className='flex'>
      <div
        className={`inline-flex items-center w-2/3 h-16 rounded-t-xl`}
        style={{ backgroundColor: fillColor }}
      >
        <div className={'flex px-3'}>
          <img
            src={!!image ? image : aliceIcon}
            className='w-10 h-10 rounded-full'
            alt={'icon_box'}
          />
          <p id='webchat-channel-name' className={'text-white ml-3 mt-2'}>
            {!!title ? title : defaultChannelName}
          </p>
        </div>
      </div>
      <div className='inline-flex mt-6'>
        <span
          style={{ background: `${fillColor}` }}
          className='inline-flex w-10 h-10 p-2 ml-6 mr-3 bg-gray-500 rounded-full shadow focus:outline-none focus:shadow-outline'
        >
          <XMarkIcon className='text-white' />
        </span>
        <span
          style={{ background: `${fillColor}` }}
          className='inline-flex w-10 h-10 p-2 bg-gray-500 rounded-full shadow focus:outline-none focus:shadow-outline'
        >
          <img
            src={webchatIcon}
            className='text-white w-7 h-7'
            alt={'icon_box'}
          />{' '}
        </span>
      </div>
    </div>
  );
};
export default WebchatPreview;
