import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import defaultAvatar from '../../../../assets/images/default-avatar.png';
import { renderAgentStatusBadgeColor } from '../../../../utils/functions';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  close: Function;
  isLoading: boolean;
  agentList: AgentListInterface[];
  selectedTicket: TicketInterface;
  selectedAgentId: string | number | null;
  setIsAgentOffline: (value: boolean) => void;
  handleTicketAssignment: (close: Function) => void;
}

const OfflineAgentAssignModal: React.FC<Props> = ({
  close,
  isLoading,
  agentList,
  selectedTicket,
  selectedAgentId,
  setIsAgentOffline,
  handleTicketAssignment,
}) => {
  const { t } = useTranslation();
  const renderOfflineAgentText = (assignedAgent: AgentListInterface) => {
    return (
      <>
        <p className='font-bold text-lg text-center text-gray-900'>
          {t(`Assign to an ${assignedAgent?.admin.status} agent?`)}
        </p>
        <p className='px-2 text-sm text-center text-gray-400'>
          {t(
            `You are about to assign the chat to {{${assignedAgent?.admin.full_name}}}, who is currently ${assignedAgent?.admin.status}. Do you want to continue?`
          )}
        </p>
      </>
    );
  };

  const handleOfflineAgentAssignment = (close: Function) => {
    let assignedAgent = agentList?.find(
      (item) => selectedAgentId === item?.admin.id
    );
    return (
      <div className='py-4'>
        <div className='flex justify-end px-3 text-gray-500'>
          <XMarkIcon
            onClick={() => {
              setIsAgentOffline(false);
              close();
            }}
            className='w-5 h-5 cursor-pointer'
            aria-hidden='true'
          />
        </div>

        <div className='flex justify-center'>
          <div className='relative'>
            <img
              src={assignedAgent?.admin?.avatar || defaultAvatar}
              alt={'icon_box'}
              className={
                'justify-start h-12 w-12 ml-3 rounded-full object-cover border border-gray-100'
              }
            />
            <span
              className={`absolute -bottom-1 right-0 inline-block w-4 h-4 ${renderAgentStatusBadgeColor(
                assignedAgent?.admin.status
              )} border-[3px] border-white rounded-full`}
            />
          </div>
        </div>

        <div className='mb-5 mt-5'>{renderOfflineAgentText(assignedAgent)}</div>
        <div className='flex flex-col gap-2 px-5 '>
          <button
            onClick={() => {
              setIsAgentOffline(false);
              close();
            }}
            className='w-full py-3 bg-green-50 text-green-600 text-base shadow-sm rounded-md'
          >
            {t('Cancel')}
          </button>
          <button
            onClick={() => {
              handleTicketAssignment(close);
            }}
            className='w-full py-3 bg-white border border-gray-300 text-gray-600 text-base shadow-sm rounded-md'
            disabled={isLoading}
          >
            {t('Assign Anyway')}
          </button>
        </div>
      </div>
    );
  };
  return (
    <div
      className='fixed z-10 inset-0 overflow-y-auto'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        <div
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        ></span>
        <div
          className='relative inline-block align-bottom bg-white rounded-lg text-left
          shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[432px] sm:max-w-lg'
        >
          {handleOfflineAgentAssignment(close)}
        </div>
      </div>
    </div>
  );
};

export default OfflineAgentAssignModal;
