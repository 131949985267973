import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { UBroadcastProperty } from 'utilities/content';

const initialState = {
  broadcastList: null,
  broadcastCount: null,
  broadcastCreationData: null,
  mappedData: null,
  creditInUse: null,
};

export const broadcast = {
  state: {
    ...initialState,
  },
  reducers: {
    updateBroadcastList(state, broadcastList) {
      return { ...state, broadcastList: broadcastList };
    },
    updateBroadcastCount(state, broadcastcount) {
      return { ...state, broadcastCount: broadcastcount };
    },
    updateBroadcastCreationData(state, broadcastCreationData) {
      return { ...state, broadcastCreationData: broadcastCreationData };
    },
    updateMappedData(state, mappedData) {
      return { ...state, mappedData: mappedData };
    },
    updateCreditInUse(state, creditInUse) {
      return { ...state, creditInUse: creditInUse };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchBroadcastList(payload) {
      /*
      requestPayload = {
        limit: number,
        offset: number,
        title?: string
      }
       */

      let params = {
        limit: payload.limit,
        offset: payload.offset,
      };

      if (!!payload.title) {
        params['title'] = payload.title;
      }

      try {
        const res = await axios.get(
          config.broadcast.broadcastList(payload.projectId),
          {
            params: params,
          }
        );
        if (res.data.success) {
          dispatch.broadcast.updateBroadcastList(res.data.dataSource);
          dispatch.broadcast.updateBroadcastCount(res.data.total);
          dispatch.broadcast.updateCreditInUse(res.data.credit_in_use);
          return res.data.dataSource;
        }
      } catch (err) {
        return false;
      }
    },
    async createBroadcast(payload) {
      // {
      //   "title":"Broadcast 11",
      //   "template_id":"12329",
      //   "channel_details": {
      //       "id": 25,
      //       "title": "whatsapp channel",
      //       "phone": "345423534"
      //   }
      // }

      try {
        const res = await axios.post(
          config.broadcast.createBroadcast(payload.projectId),
          payload.data
        );

        if (res.data.success) {
          dispatch.broadcast.updateBroadcastCreationData(res.data.dataSource);
          return { success: true, data: res.data.dataSource };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },

    async uploadCSV(payload) {
      try {
        const res = await axios.post(
          config.broadcast.updateBroadcast(payload.projectId),
          payload.data
        );

        if (res.data.success) {
          if (payload.type === 'uploadCsvForMapping') {
            dispatch.broadcast.updateMappedData(res.data.dataSource);
          } else {
            dispatch.broadcast.updateBroadcastCreationData(res.data.dataSource);
          }

          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async deleteBroadcast(payload) {
      //       {
      //     "broadcastId": string
      // }
      try {
        const res = await axios.post(
          config.broadcast.deleteBroadcast(payload.projectId),
          payload.data
        );

        if (res.data.success) {
          let data = {
            limit: UBroadcastProperty.perPageData,
            offset: 0,
            projectId: payload.projectId,
          };
          dispatch.broadcast.fetchBroadcastList(data);
          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async fetchBroadcastDetails(payload) {
      //       {
      //     "broadcastId": string
      // }
      try {
        const res = await axios.post(
          config.broadcast.broadcastDetails(payload.projectId),
          payload.data
        );

        if (res.data.success) {
          dispatch.broadcast.updateBroadcastCreationData(res.data.dataSource);
          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async handleAddCredits(payload) {
      try {
        const res = await axios.post(
          config.broadcast.addCredits(payload.project_id),
          {
            credit_amount: payload.credit_amount,
          }
        );
        if (res.data.success) {
          const data = await dispatch.dashboard.fetchTeamDetails(
            payload.project_id
          );
          dispatch.dashboard.updateSelectedProject(data);
          return res.data;
        }
      } catch (err) {
        return false;
      }
    },

    async sendTestMessage(payload) {
      //       {
      // "phone": str,
      // "template_id": str,
      // "platform_id": str
      // }
      try {
        const res = await axios.post(
          config.broadcast.sendTestMessage(payload.projectId),
          payload.data
        );

        if (res.data.success) {
          return { success: res.data.success, data: res.data.dataSource };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async downloadCampaignReport(payload) {
      //       {
      //     "broadcastId": string
      // }
      try {
        const res = axios({
          method: 'POST',
          url: config.broadcast.downloadCampaignReport(payload.projectId),
          data: payload.data,
          responseType: 'blob',
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${payload.file_name}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

        return {
          success: true,
          data: res,
        };
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async downloadAudienceCsv(payload) {
      //       {
      //     "broadcastId": string
      // }
      try {
        const res = axios({
          method: 'POST',
          url: config.broadcast.downloadAudienceCsv(payload.projectId),
          data: payload.data,
          responseType: 'blob',
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${payload.title}_audience_file.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

        return {
          success: true,
          data: res,
        };
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data?.dataSource,
        };
      }
    },
    async whatsappAttributeList(payload) {
      try {
        const res = await axios.get(
          config.broadcast.whatsappAttributelist(payload.projectId),
          { params: payload.data }
        );
        if (res.status === 200) {
          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (error) {
        return {
          success: false,
          data: null,
        };
      }
    },
  }),
};
