import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

interface Prop {
  search: string;
  setSearch: (val: string) => void;
}

const SearchProduct: React.FC<Prop> = ({ search, setSearch }) => {
  return (
    <div className='relative px-6 pt-6'>
      <div className='sticky top-0 rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          type='text'
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
          placeholder='Search for products from your store'
        />
      </div>
    </div>
  );
};

export default SearchProduct;
