import { FC, useState } from 'react';
import {
  Button,
  PlusIcon,
  useDatalab,
  toast,
  DeleteAlert,
} from '../../../export';

import { IFilterDataArr } from '../../../interface';
import FilterField from './FilterField';
import FilterViewAddModal from './FilterViewAddModal';

interface Props {
  onSideSheetClose: () => void;
  onApplyFilter: (a: any) => void;
}

const Filter: FC<Props> = ({ onSideSheetClose, onApplyFilter }) => {
  const {
    pageChange,
    storedFilterData,
    updatePageChange,
    selectedDatalabInfo,
    fetchDatalabEntries,

    // Data lab filter api actions
    appliedFilterView,
    updateAppliedFilterView,
    createDatalabFilter,
    updateDatalabFilter,
    deleteDatalabFilter,
  } = useDatalab();
  const [filterData, setFilterData] = useState<IFilterDataArr>(
    appliedFilterView ? JSON.parse(JSON.stringify(appliedFilterView)) : {}
  );
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);
  const [openFilterTitleDialog, setOpenFilterTitleDialog] =
    useState<boolean>(false);

  // Add new filter
  const handleAddNewFilter = () => {
    const newFilter = {
      title: '',
      filter_options: [
        {
          slug: '',
          operator: '',
          value: '',
        },
      ],
    };

    setFilterData(newFilter);
  };

  // Add new filter option
  const handleOnAddNewFilterOption = () => {
    // before adding new filter option, check if the last filter option's slug, operator and value are not empty
    const lastFilterOption = filterData?.filter_options?.slice(-1)[0];
    if (
      !lastFilterOption?.slug ||
      !lastFilterOption?.operator ||
      !lastFilterOption?.value
    ) {
      toast({
        //@ts-ignore
        title: <p className='text-red-500'>Could not add new filter</p>,
        description: `Please fill all the fields. Slug, Operator and Value are required.`,
      });
      return;
    }

    const newFilterOption = {
      slug: '',
      operator: '',
      value: '',
    };

    setFilterData((prevFilterData) => {
      return {
        ...prevFilterData,
        filter_options: [...prevFilterData.filter_options, newFilterOption],
      };
    });
  };

  // Update filter option data
  const handleFilterDataOnChange = (index: number, updatedData: any) => {
    const newFilterData = { ...filterData };

    // Ensure filter_options is an array
    newFilterData.filter_options = newFilterData.filter_options || [];
    newFilterData.filter_options[index] = updatedData;

    setFilterData(newFilterData);
  };

  // Remove filter option from list
  const removeFilterOptionFromList = (index: number) => {
    const updatedFilterData = { ...filterData };

    const filterOptions = updatedFilterData?.filter_options?.filter(
      (_, i) => i !== index
    );

    setFilterData({
      ...updatedFilterData,
      filter_options: [...filterOptions],
    });

    if (filterOptions.length === 0) {
      // update applied filter view to empty object
      updateAppliedFilterView(null);
      onSideSheetClose();
    }
  };

  const handleApplyFilter = () => {
    onApplyFilter(filterData);
    onSideSheetClose();
  };

  const handleCancelFilter = () => {
    setFilterData({ title: '', filter_options: [] });
    onSideSheetClose();
  };

  const toggleFilterTitleDialog = () => {
    setOpenFilterTitleDialog(!openFilterTitleDialog);
  };

  // Save new filter
  const handleOnSaveNewFilter = (title: any) => {
    const newFilterData = { ...filterData, title };
    setFilterData(newFilterData);

    createDatalabFilter(newFilterData);

    onApplyFilter(newFilterData);
    setOpenFilterTitleDialog(false);
    onSideSheetClose();
  };

  const handleOnUpdateFilter = () => {
    updateDatalabFilter(filterData.id, filterData);
    onApplyFilter(filterData);
    onSideSheetClose();
  };

  const handleOnDeleteFilter = () => {
    deleteDatalabFilter(appliedFilterView.id);

    updatePageChange(pageChange.limit, 0);
    fetchDatalabEntries();

    onSideSheetClose();
  };

  // check filter data's filter options last element is empty or not ({slug: '', operator: '', value: ''})
  const isFilterDataEmpty = () => {
    const lastFilterOption = filterData?.filter_options?.slice(-1)[0];
    return (
      !lastFilterOption?.slug ||
      !lastFilterOption?.operator ||
      !lastFilterOption?.value
    );
  };

  const filterDataIsEmpty = isFilterDataEmpty();
  const filterOptionLength = filterData?.filter_options?.length || 0;

  const shouldDisableUpdateFilter = () => {
    const appliedFilter = storedFilterData.find(
      (item: any) => appliedFilterView?.title === item.title
    );

    // If no match for applied filter view, or lengths don't match, changes are detected
    if (
      appliedFilter?.filter_options?.length !==
      filterData?.filter_options.length
    ) {
      if (filterDataIsEmpty) {
        return true;
      }
      return false; // Changes detected, enable the button
    }

    // Compare each filter option object deeply
    for (let i = 0; i < filterData?.filter_options.length; i++) {
      const appliedOption = appliedFilter?.filter_options[i];
      const currentOption = filterData?.filter_options[i];

      if (appliedOption?.slug === 'multi-select') {
        return !(
          JSON.stringify(appliedOption?.value) !==
            JSON.stringify(currentOption?.value) ||
          appliedOption?.slug !== currentOption?.slug ||
          appliedOption?.operator !== currentOption?.operator
        );
      }

      if (
        appliedOption?.slug !== currentOption?.slug ||
        appliedOption?.operator !== currentOption?.operator ||
        appliedOption?.value !== currentOption?.value
      ) {
        // Check for mismatched or empty properties
        if (
          !currentOption?.slug ||
          !currentOption?.operator ||
          !currentOption?.value
        ) {
          return true;
        }
        return false; // Changes detected, enable the button
      }
    }

    return true; // No changes, disable the button
  };

  return (
    <div className='h-full'>
      {filterData?.filter_options?.length ? (
        <>
          <div className='h-[92%] overflow-y-auto scrollbar-hide'>
            {filterData?.filter_options?.map((data, index) => (
              <FilterField
                key={index}
                data={data}
                formFieldList={selectedDatalabInfo?.lab_fields}
                removeFilterFromList={() => removeFilterOptionFromList(index)}
                handleFilterDataOnChange={(updatedData: any) =>
                  handleFilterDataOnChange(index, updatedData)
                }
              />
            ))}
            <AddNewFilterButton onClick={handleOnAddNewFilterOption} />
          </div>

          <div className='h-[50px] pt-[22px]'>
            <div className='flex justify-between items-center gap-2'>
              <div>
                {appliedFilterView?.created_at && (
                  <Button
                    className='ml-1 text-white bg-red-50 hover:bg-red-50 h-8'
                    onClick={() => setOpenDiscardDialog(true)}
                  >
                    <span className='text-red-500 text-xs'>Delete</span>
                  </Button>
                )}
              </div>
              <div className='flex justify-center gap-2'>
                {filterOptionLength >= 0 && (
                  <Button
                    variant='outline'
                    className='h-8 text-xs'
                    onClick={() => handleCancelFilter()}
                  >
                    Cancel
                  </Button>
                )}

                {appliedFilterView?.created_at ? (
                  <Button
                    variant='outline'
                    className={`${
                      shouldDisableUpdateFilter()
                        ? 'bg-background-hover text-textSecondary-disable text-xs h-8 w-24'
                        : 'text-xs h-8 w-24'
                    }`}
                    onClick={handleOnUpdateFilter}
                    disabled={shouldDisableUpdateFilter()}
                  >
                    Update Filter
                  </Button>
                ) : (
                  <FilterViewAddModal
                    filterData={filterData}
                    storedFilterData={storedFilterData}
                    openFilterTitleDialog={openFilterTitleDialog}
                    toggleFilterTitleDialog={toggleFilterTitleDialog}
                    filterDataIsEmpty={filterDataIsEmpty}
                    handleOnSaveNewFilter={handleOnSaveNewFilter}
                  />
                )}
                <Button
                  className={`${
                    filterDataIsEmpty || shouldDisableUpdateFilter()
                      ? 'bg-background-hover text-textSecondary-disable text-xs h-8'
                      : 'text-white text-xs h-8'
                  }`}
                  onClick={handleApplyFilter}
                  disabled={filterDataIsEmpty || shouldDisableUpdateFilter()}
                >
                  Apply Filter
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <AddNewFilterView onClick={handleAddNewFilter} />
      )}
      {openDiscardDialog && (
        <DeleteAlert
          open={openDiscardDialog}
          onOpenChange={setOpenDiscardDialog}
          title={'Delete Filtered View'}
          description={`Are you sure you want to delete this saved filter settings? This action cannot be undone.`}
          onCancel={() => setOpenDiscardDialog(false)}
          onConfirm={handleOnDeleteFilter}
        />
      )}
    </div>
  );
};

const AddNewFilterButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button className='flex gap-2 mt-4' variant='outline' onClick={onClick}>
    <PlusIcon className='w-4 h-4' stroke='2' />
    <span>Add New Filter</span>
  </Button>
);

const AddNewFilterView: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className='flex flex-col items-center justify-center h-full'>
    <span>No filter added.</span>
    <AddNewFilterButton onClick={onClick} />
  </div>
);

export default Filter;
