import React from 'react';
import { SideSheet as ProductRecommenderModal } from '../../../../../../library';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import { productRecommenderData } from '../../../../../../utilities/content';
import { ProductDataProps } from './interface';
import { Button } from '../../../../../../library/index';
import { toaster } from "evergreen-ui";

interface Prop {
  productRecommenderOpen: boolean;
  setProductRecommenderOpen: (value: boolean) => void;
  customerId: number;
  clearState: () => void;
  selectedProductData: ProductDataProps[];
  recommendProducts: (customerId: number) => Promise<Boolean>;
  productRecommendationLoading: boolean;
}

const ProductRecommender: React.FC<Prop> = ({
  productRecommenderOpen,
  setProductRecommenderOpen,
  customerId,
  clearState,
  selectedProductData,
  recommendProducts,
  productRecommendationLoading,
}) => {
  const containerRef = React.createRef();

  React.useEffect(() => {
    //we need this to scroll
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseModal = () => {
    clearState();
    setProductRecommenderOpen(false);
  };

  const handleConfirm = async () => {
    const res = await recommendProducts(customerId);
    if (res) {
      toaster.success('Success', {
        description: 'Successfully Recommended Product(s) for the Customer.',
      });
    } else {
      toaster.danger('Failed', {
        description: 'Failed to Send Product(s). Please Try Again.',
      });
    }
    clearState();
    setProductRecommenderOpen(false);
  };

  const selectedProductCount = () => {
    return !!selectedProductData ? selectedProductData.length : 0;
  };

  const getCustomConfirmButton = () => {
    return <Button
      intent={'primary'}
      size={'sm'}
      isDisabled={selectedProductCount() < 1}
      isLoading={productRecommendationLoading}
      onClick={() => handleConfirm()}
    >
      {productRecommendationLoading ? 'Recommending...' : 'Recommend'}
    </Button>
  }

  const getFooterDescription = () => {
    return (
      <div className='flex'>
        <p className='font-medium text-base text-primary px-8'>
          {selectedProductCount() > 1 ?  `${selectedProductCount()} Products Selected` : '1 Product Selected'}</p>
      </div>
    );
  }

  return (
    <div>
      <ProductRecommenderModal
        open={productRecommenderOpen}
        ref={containerRef}
        hasMinimizeButton={false}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        handleCancel={handleCloseModal}
        hideCancel={true}
        closeOnExternalClick={false}
        title={productRecommenderData.title}
        description={productRecommenderData.description}
        hasFooter={true}
        hasLeftActionElement={true}
        hideLeftActionElement={selectedProductCount() <= 0}
        leftActionElement={getFooterDescription()}
        customConfirmButton={getCustomConfirmButton()}
      >
        <Wrapper/>
      </ProductRecommenderModal>
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProductData: state.crmEcommerce.selectedProductData,
  productRecommendationLoading: state.loading.effects.crmEcommerce.recommendProducts,
});

const mapDispatch = (dispatch: any) => ({
  clearState: () => dispatch.crmEcommerce.clearState(),
  recommendProducts: (customerId: number) =>
    dispatch.crmEcommerce.recommendProducts(customerId),
});

const ProductRecommenderContainer = connect(
  mapState,
  mapDispatch
)(ProductRecommender);
export default ProductRecommenderContainer;
