import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toaster } from 'evergreen-ui';
import cloneDeep from 'lodash/cloneDeep';
import { initiateNotification } from '../../../../utilities/utils';

let CreateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/create_ticket.mp3'
);
let UpdateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/update_ticket.mp3'
);

const initialState = {
  queue: [],
  limit: 20,
  offset: 0,
  ticketOrder: 'desc',
  currentTicket: null,
  total: 0,
  selectedPlatform: {
    id: null,
    name: 'ALL',
    type: 'all',
    value: 'ALL PLATFORM',
  },
  customerMeta: null,
  isExpanded: true,
  allTicketTags: [],
  allUserTags: [],
  chatData: [],
  assginableAdmins: [],
  agentGroup: [],
  ticketActions: [],
  cannedResponses: [],
  customerAttributes: null,
  tempAttributes: null,
  isAlertCreateTicket: false,
  isAlertUpdateTicket: false,
  browserNotification: false,
  msgCount: 0,
  maxSoundPerDuration: 2,
  soundDuration: 10000,
  galleryImages: [],
  galleryTotal: 0,
  eComOrderList: [],
  eComCustomerSummary: null,
  boughtProduct: [],
  cartProduct: [],
  viewedProducts: [],
  messageTemplates: [],
};

export const crm = {
  state: {
    ...initialState,
  },
  reducers: {
    updateTicket(state, payload) {
      // data: [],
      //   total: res.data.total,
      //   offset: 0,
      //   myQueueCount: res.data.my_queue_count || 0,
      //   allQueueCount: res.data.all_queue_count || 0,
      if (!!payload?.ticketOrder) {
        return {
          ...state,
          queue: payload.data,
          currentTicket: payload.data.length > 0 ? payload.data[0] : null,
          offset: payload.offset,
          total: payload.total,
          ticketOrder: payload.ticketOrder,
        };
      } else {
        return {
          ...state,
          queue: payload.data,
          currentTicket: payload.data.length > 0 ? payload.data[0] : null,
          offset: payload.offset,
          total: payload.total,
        };
      }
    },
    updateSelectedPlatform(state, payload) {
      return { ...state, selectedPlatform: payload };
    },
    updateCustomerMetaEcomReference(state, payload) {
      let customerMetaLocal = state.customerMeta;
      customerMetaLocal['is_linked_with_ecommerce_account'] = !!payload;
      return { ...state, customerMeta: customerMetaLocal };
    },
    updateExpand(state, payload) {
      return { ...state, isExpanded: payload };
    },
    updateCustomerSummary(state, payload) {
      return { ...state, eComCustomerSummary: payload };
    },
    updateProductInteraction(state, payload) {
      if (!!payload) {
        return {
          ...state,
          boughtProduct: payload.bought_products,
          cartProduct: payload.cart_products,
          viewedProducts: payload.viewed_products,
        };
      }
      return {
        ...state,
        boughtProduct: [],
        cartProduct: [],
        viewedProducts: [],
      };
    },
    updateCustomerAttributes(state, payload) {
      return {
        ...state,
        customerAttributes: payload,
        tempAttributes: JSON.parse(JSON.stringify(payload)),
      };
    },
    updateAgentGroup(state, payload) {
      return { ...state, agentGroup: payload };
    },
    updateGalleryImages(state, payload) {
      return {
        ...state,
        galleryImages: [...payload.dataSource],
        galleryTotal: payload.total,
      };
    },
    updateGalleryImagesAppend(state, payload) {
      return { ...state, galleryImages: [...state.galleryImages, ...payload] };
    },
    updateGalleryImagesUpload(state, payload) {
      return {
        ...state,
        galleryImages: [...payload, ...state.galleryImages],
        galleryTotal: state.galleryTotal + 1,
      };
    },
    updateTicketTag(state, payload) {
      const { tag, action } = payload;
      if (action === 'add') {
        return {
          ...state,
          currentTicket: {
            ...state.currentTicket,
            tags: [...state.currentTicket.tags, tag],
          },
        };
      } else if (action === 'remove') {
        return {
          ...state,
          currentTicket: {
            ...state.currentTicket,
            tags: state.currentTicket.tags.filter((e) => e.id !== tag.id),
          },
        };
      }
      return { ...state };
    },
    updateQueue(state, payload) {
      let index = state.queue.findIndex((e) => e.id === payload.ticketID);
      let updatedQueueTag = [...state.queue];
      updatedQueueTag[index] = {
        ...updatedQueueTag[index],
        tags: [...state.currentTicket.tags],
      };
      return { ...state, queue: updatedQueueTag };
    },
    updateTicketQueue(state, payload) {
      let totalLocal = state.total;
      let queueLocal = [...state.queue];
      let msgCountLocal = state.msgCount;
      if (!!payload.is_new) {
        //if not undefined we will increase the ticket total count
        totalLocal = totalLocal + 1;
        queueLocal = [payload, ...queueLocal];
        document.title = `You have ${totalLocal} Pending Tickets`;
      } else if (!!payload?.is_agent_event) {
        queueLocal.map((ticketInQueue, index) => {
          if (ticketInQueue.id === payload.id) queueLocal[index] = payload;
          return ticketInQueue;
        });
      } else {
        // if updated we will look for the ticket
        queueLocal = [
          payload,
          ...queueLocal.filter((ticket) => ticket.id !== payload.id),
        ];
      }

      // sound update
      // we update the sound based on event type is_new;
      const shouldShowSound =
        document.visibilityState === 'hidden' ||
        state.currentTicket?.id !== payload.id;
      // this means either its is of focus or not selected any of the logic will show notification

      if (shouldShowSound || state.queue.length === 0) {
        //if new ticket play create a sound
        if (!!payload.is_new) {
          //check permission
          if (
            state.isAlertCreateTicket &&
            state.msgCount < state.maxSoundPerDuration
          ) {
            CreateTicketAudio.play();
            msgCountLocal = msgCountLocal + 1;
          }
        } else if (
          state.isAlertUpdateTicket &&
          state.msgCount < state.maxSoundPerDuration
        ) {
          //play update sound
          UpdateTicketAudio.play();
          msgCountLocal = msgCountLocal + 1;
        }
        if (
          state.browserNotification &&
          state.msgCount < state.maxSoundPerDuration
        ) {
          if (!!payload.is_new) {
            initiateNotification(
              'You have a new pending ticket',
              `${
                payload?.customer?.platform?.name || 'Anonymous Channel'
              } has a new ticket `
            );
          } else {
            initiateNotification(
              'You have a new message',
              `${
                payload?.customer?.full_name || 'Anonymous User'
              } has sent a new message`
            );
          }
        }
      }
      return {
        ...state,
        queue: queueLocal,
        total: totalLocal,
        msgCount: msgCountLocal,
      };
    },
    updateTicketAppend(state, payload) {
      //payload: ticket & offset
      // data: res.data.dataSource,
      //   offset: offset,

      if (payload.data.length > 0) {
        if (state.offset !== payload.offset) {
          //prevent entries if api called same offset twice
          return {
            ...state,
            queue: [...state.queue, ...payload.data],
            offset: payload.offset,
          };
        }
      }
      return { ...state };
    },
    updateTicketData(state, payload) {
      let updatedTickets = [...state.yourTickets];
      updatedTickets = updatedTickets.map((data) => {
        if (data.id === payload.id) {
          data.description = payload.description;
        }
        return data;
      });
      // need a better solution to this !! this is mutating old state but description needs to update on fix index
      return { ...state, yourTickets: updatedTickets };
    },
    updateStateData(state, payload) {
      //payload: key,value
      return { ...state, [payload.key]: payload.value };
    },
    updateEComOrderList(state, payload) {
      return { ...state, eComOrderList: payload };
    },
    updateCustomerMeta(state, payload) {
      return { ...state, customerMeta: payload };
    },
    updateBotAssign(state, payload) {
      return {
        ...state,
        customerMeta: { ...state.customerMeta, bot_enabled: payload },
      };
    },
    updateLeftbarTicketAssign(state, payload) {
      let queueLocal = [...state.queue];
      let currentTicketLocal = { ...state.currentTicket };

      queueLocal = queueLocal.filter((ticket) => {
        if (ticket.id === state.currentTicket.id) {
          if (!!payload) {
            ticket.agents = [payload?.admin_info];
            ticket.groups = [];
            ticket.is_replied = payload?.is_replied || ticket.is_replied;
          }
        }
        if (!!payload) {
          currentTicketLocal.agents = [payload?.admin_info];
          currentTicketLocal.groups = [];
        }
        return ticket;
      });
      return {
        ...state,
        queue: queueLocal,
        currentTicket: currentTicketLocal,
      };
    },
    updateAssignAdmin(state, payload) {
      return { ...state, assginableAdmins: payload };
    },
    updateNotificationInfo(state, payload) {
      if (!!payload) {
        let currentAgent = payload.data.filter(
          (agent) => agent.admin.id === payload.adminId
        );
        if (currentAgent.length > 0) {
          currentAgent = currentAgent[0];
          return {
            ...state,
            isAlertCreateTicket: currentAgent.should_notify_web_ticket_assigned,
            isAlertUpdateTicket: currentAgent.should_notify_web_new_message,
            browserNotification: currentAgent.should_notify_web,
          };
        }
      }
      return { ...state };
    },
    updateNotificationKey(state, payload) {
      if (!!payload) {
        return {
          ...state,
          isAlertCreateTicket: payload.should_notify_web_ticket_assigned,
          isAlertUpdateTicket: payload.should_notify_web_new_message,
          browserNotification: payload.should_notify_web,
        };
      }
      return { ...state };
    },
    updateLockedStatus(state, payload) {
      let LocalData = JSON.parse(JSON.stringify(state.queue));
      let currentTicketLocal = null;
      LocalData = LocalData.map((ticket) => {
        if (ticket.id === payload.id) {
          ticket[payload.key] = payload.value;
          currentTicketLocal = ticket;
        }
        return ticket;
      });
      return {
        ...state,
        queue: LocalData,
        currentTicket: currentTicketLocal,
      };
    },
    updateCannedResponses(state, payload) {
      return { ...state, cannedResponses: payload };
    },
    addChatData(state, payload) {
      //check all logics here
      // payload = {event,adminId}
      let chatDataLocal = [...state.chatData];
      const { event, adminId } = payload;
      if (event.admin_id === adminId && event.source === 'admin') {
        //for admin we need to check pusher logics too
        if (event._id === null) {
          const isAvailable = state.chatData.filter(
            (chat) => chat.pusher_key === event.pusher_key
          ).length;
          if (isAvailable === 0) {
            chatDataLocal = [...chatDataLocal, event];
          }
          //else already exist  dont update
        } else {
          const isAvailable = state.chatData.filter(
            (chat) => chat.pusher_key === event.pusher_key
          ).length;
          if (isAvailable > 0) {
            chatDataLocal = state.chatData.map((chat) => {
              if (chat.pusher_key === event.pusher_key) {
                chat = event;
              }
              return chat;
            });
          } else {
            // just update pusher event
            chatDataLocal = [...chatDataLocal, event];
          }
        }
      } else {
        //customer always true for status
        chatDataLocal = [...state.chatData, payload.event];
      }
      return { ...state, chatData: chatDataLocal };
    },
    addFeedReplyData(state, payload) {
      //check all logics here
      // payload = {event,adminId}
      let chatDataLocal = { ...state.chatData };
      const reply = payload;
      if (reply.source === 'admin') {
        //for admin we need to check pusher logics too
        if (reply._id === null) {
          const isAvailable = state.chatData?.replies.filter(
            (replyLocal) => replyLocal?.pusher_key === reply?.pusher_key
          ).length;
          if (isAvailable === 0) {
            chatDataLocal = {
              ...chatDataLocal,
              replies: [...chatDataLocal.replies, reply],
            };
          }
          // else already exist  dont update
        } else {
          const isAvailable = state.chatData?.replies.filter(
            (replyLocal) =>
              !!replyLocal.pusher_key &&
              replyLocal?.pusher_key === reply?.pusher_key
          ).length;
          if (isAvailable > 0) {
            chatDataLocal.replies = state.chatData.replies.map((replyLocal) => {
              if (replyLocal.pusher_key === reply.pusher_key) {
                replyLocal = reply;
              }
              return replyLocal;
            });
          } else {
            // just update pusher event
            chatDataLocal = {
              ...state.chatData,
              replies: [...chatDataLocal.replies, payload],
            };
          }
        }
      } else {
        //customer/bot always true for status
        chatDataLocal = {
          ...state.chatData,
          replies: [...state.replies, payload.event],
        };
      }
      return { ...state, chatData: chatDataLocal };
    },
    updateCommentStatus(state, action) {
      let updatedChatData = cloneDeep(state.chatData);
      updatedChatData.parent_comment_data['status'] = action;
      return { ...state, chatData: updatedChatData };
    },
    updateReplyStatus(state, payload) {
      let updatedChatData = cloneDeep(state.chatData);
      updatedChatData.replies = updatedChatData.replies.map((reply) => {
        if (payload.action === 'edit') {
          if (reply.conversation_id === payload.commentId) {
            //update the chunk
            if (!!payload.reply && payload.reply.length > 0) {
              reply = payload.reply[0];
            }
          }
        } else {
          //rest
          if (reply.conversation_id === payload.commentId) {
            reply.status = payload.action;
          }
        }
        return reply;
      });
      return { ...state, chatData: updatedChatData };
    },
    updateDeletedConversation(state, payload) {
      const possibleActions = ['remove', 'hide'];
      let updatedChatData = cloneDeep(state.chatData);
      if (!!payload) {
        if (possibleActions.includes(payload.action)) {
          updatedChatData.replies = updatedChatData.replies.filter(
            (reply) => reply.status !== payload.action
          );
        }
      } else {
        updatedChatData.replies = updatedChatData.replies.filter(
          (reply) => !possibleActions.includes(reply.status)
        );
      }
      return { ...state, chatData: updatedChatData };
    },
    addFeedReply(state, payload) {
      return {
        ...state,
        chatData: {
          ...state.chatData,
          replies: [...state.chatData.replies, ...payload],
        },
      };
    },
    updateOrderData(state, payload) {
      let updatedEcomList = cloneDeep(state.eComOrderList);
      updatedEcomList = updatedEcomList.map((data) => {
        if (data.id === payload.id) {
          data = payload;
        }
        return data;
      });
      // need a better solution to this !! this is mutating old state but description needs to update on fix index
      return { ...state, eComOrderList: updatedEcomList };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchTickets(payload) {
      /*
      payload = {
        projectId: number;
        limit: number;
        queue: QueueType //go to `src/typings/index.d.ts` for global scope type
        offset: number;
        isResolved: boolean;
        search: string;
        channels: any;
        agents: any;
        tags: any;
        start: string;
        end: string;
        ticketOrder: string;

      }
       */
      try {
        const {
          projectId,
          queue,
          isResolved,
          limit,
          offset,
          search,
          channels,
          agents,
          groups,
          tags,
          start,
          end,
          ticketOrder,
        } = payload;
        const res = await axios.get(config.crm.tickets(projectId), {
          params: {
            is_resolved: isResolved,
            queue: queue,
            offset: offset,
            limit: limit,
            search: search,
            channels: channels,
            agents: agents,
            groups: groups,
            tags: tags,
            start: start,
            end: end,
            order: ticketOrder,
          },
        });
        if (res.data.success) {
          // update
          dispatch.crm.updateTicket({
            data: res.data.dataSource,
            total: res.data.total,
            offset: offset,
            ticketOrder: ticketOrder,
          });
        }
      } catch (err) {
        // all
        dispatch.crm.updateTicket({
          data: [],
          total: 0,
          offset: 0,
        });
      }
    },
    async fetchTicketsString({ projectId, paramsString }) {
      paramsString = paramsString.replace('?', '');
      try {
        const res = await axios.get(
          config.crm.ticketString(projectId, paramsString)
        );
        if (res.data.success) {
          dispatch.crm.updateTicket({
            data: res.data.dataSource,
            total: res.data.total,
            offset: 0,
          });
        }
      } catch (err) {
        // all
        dispatch.crm.updateTicket({
          data: [],
          total: 0,
          offset: 0,
        });
      }
    },
    async fetchGalleryImages({ projectId, limit, offset }) {
      // projectId,limit, offset
      const res = await axios.get(config.crm.gallery(projectId), {
        params: {
          limit: limit,
          offset: offset,
        },
      });
      if (res.status === 200 && res.data.success) {
        if (offset === 0) {
          dispatch.crm.updateGalleryImages(res.data);
        } else {
          dispatch.crm.updateGalleryImagesAppend(res.data.dataSource);
        }
      } else {
        dispatch.crm.updateGalleryImages({ dataSource: [], total: 0 });
      }
    },
    async fetchTicketsOnScroll(payload) {
      /*
      payload = {
       projectId: number
       queue: queueType
       resolved: number || null,
       offset: offset,
       limit: limit,
       search: search,
       channel: channels,
       agents: agents,
       groups: groups,
       tags: tags,
       start: start,
       end: end,
       ticketOrder: order

      }
       */
      try {
        const {
          projectId,
          queue,
          limit,
          offset,
          isResolved,
          search,
          channels,
          agents,
          groups,
          tags,
          start,
          end,
          ticketOrder,
        } = payload;
        const res = await axios.get(config.crm.tickets(projectId), {
          params: {
            is_resolved: isResolved,
            queue: queue,
            offset: offset,
            limit: limit,
            search: search,
            channels: channels,
            agents: agents,
            groups: groups,
            tags: tags,
            start: start,
            end: end,
            order: ticketOrder,
          },
        });
        if (res.data.success) {
          dispatch.crm.updateTicketAppend({
            data: res.data.dataSource,
            offset: offset,
          });
        }
        return res.data.dataSource;
      } catch (err) {
        dispatch.crm.updateTicketAppend({
          data: [],
          offset: 0,
        });
        return { data: [] };
      }
    },
    // async updateTickets(payload) {
    //   /*
    //   payload = {
    //     projectId,ticketId,body
    //   }
    //    */
    //   try {
    //     const res = await axios.patch(
    //       `${config.crm}/${payload.projectId}/tickets/${payload.ticketId}`,
    //       payload.body
    //     );
    //     if (res.data.success && res.status === 200) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //     return false;
    //   }
    // },
    async resolveConversation(payload) {
      try {
        const res = await axios.post(
          config.crm.conversationResolve(payload.ticketId),
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: err?.response?.data?.error || 'Failed to Resolve Ticket',
        });
        return false;
      }
    },
    async lockConversation(payload) {
      try {
        const res = await axios.post(
          config.crm.conversationLock(payload.ticketId),
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchCustomerMeta(payload) {
      /*
      payload = customerId
       */
      try {
        const res = await axios.get(config.crm.customerMeta(payload));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerMeta(res.data.data);
        } else {
          dispatch.crm.updateCustomerMeta(null);
        }
      } catch (err) {
        console.log(err.response);
        dispatch.crm.updateCustomerMeta(null);
      }
    },
    async editCustomerMeta(payload) {
      /*
      payload = {body: object,customerId: number}
       */
      try {
        const res = await axios.post(
          config.crm.customerMeta(payload.customerId),
          payload.body
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerMeta(res.data.data);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async updateBotStatus(payload) {
      /*
      payload = {body: object,customerId: number}
       */
      try {
        const res = await axios.post(config.crm.botStatus(payload.ticketId), {
          status: payload.status,
        });
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateBotAssign(payload.status);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async editTicketDetails(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.patch(
          config.crm.ticketAction(
            rootState.dashboard.selectedProject.id,
            rootState.crm.currentTicket.id
          ),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateTicketData(res.data.dataSource);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchAllTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.get(
          config.crm.teamTagListCreate(rootState.dashboard.selectedProject.id)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: res.data.dataSource,
          });
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async createTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.post(
          config.crm.teamTagListCreate(rootState.dashboard.selectedProject.id),
          { name: payload.value }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: [...rootState.crm.allTicketTags, res.data.dataSource],
          });
          const assignTicketTagPayload = {
            body: {
              action: 'add',
              id: res.data.dataSource.id,
              name: payload.value,
            },
          };
          await this.assignTicketTag(assignTicketTagPayload);
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger('Failed', {
          description: err?.response?.data?.error,
        });
        return false;
      }
    },
    async deleteTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.delete(
          config.crm.teamTagUpdateDelete(
            rootState.dashboard.selectedProject.id,
            payload
          )
        );
        if (res.status === 200 && res.data.success) {
          const data = JSON.parse(
            JSON.stringify(rootState.crm.allTicketTags)
          ).filter((e) => e.id !== payload);
          toaster.success('Deleted Successfully', {
            description: 'Successfully Deleted Ticket Tag',
          });
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: data,
          });
          dispatch.crm.fetchCurrentTicketData();
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger('Delete Failed', {
          description: 'Failed to Deleted Ticket Tag',
        });
        console.log(err.response);
        return false;
      }
    },
    async editTicketTag(payload, rootState) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.patch(
          config.crm.teamTagUpdateDelete(
            rootState.dashboard.selectedProject.id,
            payload.id
          ),
          { name: payload.name }
        );
        if (res.status === 200 && res.data.success) {
          const data = JSON.parse(
            JSON.stringify(rootState.crm.allTicketTags)
          ).filter((e) => {
            if (e.id === payload.id) {
              e.name = payload.name;
            }
            return e;
          });
          dispatch.crm.updateStateData({
            key: 'allTicketTags',
            value: data,
          });
          toaster.success('Updated Successfully', {
            description: 'Successfully Updated Ticket Tag',
          });
          dispatch.crm.fetchCurrentTicketData();
          return true;
        }
        return false;
      } catch (err) {
        console.log(err.response);
        toaster.danger('Update Failed', {
          description: 'Failed to Up;dated Ticket Tag',
        });
        return false;
      }
    },
    async assignTicketTag(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        let shouldFetch = true;
        const TagAlreadyExist =
          rootState.crm.currentTicket.tags.filter(
            (e) => e.id === payload.body.id
          ).length > 0;
        if (TagAlreadyExist && payload.body.action === 'add') {
          shouldFetch = false;
        }
        if (shouldFetch) {
          const res = await axios.post(
            config.crm.ticketTagAction(rootState.crm.currentTicket.id),
            payload.body
          );
          if (res.status === 200 && res.data.success) {
            dispatch.crm.updateTicketTag({
              tag: { name: payload.body.name, id: payload.body.id },
              action: payload.body.action,
            });
            dispatch.crm.updateQueue({
              ticketID: rootState.crm.currentTicket.id,
              tag: rootState.crm.currentTicket.tags,
            });
            if (payload.body.action === 'add') {
              toaster.success('Successful', {
                description: `Tag ${payload.body.name} Assigned Successfully`,
                duration: 1,
              });
            } else {
              toaster.success('Deleted', {
                description: `Tag ${payload.body.name} Deleted Successfully`,
                duration: 1,
              });
            }
          }
        } else {
          toaster.warning('Already Exist', {
            description: `Tag ${payload.body.name} Already exist`,
            duration: 1,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchCurrentTicketData(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        const res = await axios.get(
          config.crm.ticketAction(
            rootState.dashboard.selectedProject.id,
            rootState.crm.currentTicket.id
          )
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'currentTicket',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAllCustomerTag(payload, rootState) {
      /*
      payload = {customerId: num, body: object
       */
      try {
        const res = await axios.get(
          config.crm.customerTags(rootState.dashboard.selectedProject.id)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'allUserTags',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    // async createUserTag(payload, rootState) {
    //   /*
    //   payload = string
    //    */
    //   try {
    //     const res = await axios.post(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags`,
    //       { name: payload }
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: [...rootState.crm.allUserTags, res.data.dataSource],
    //       });
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //   }
    // },
    // async deleteUserTag(payload, rootState) {
    //   /*
    //   payload = {body: object}
    //    */
    //   try {
    //     const res = await axios.delete(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags/${payload}`
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       const data = JSON.parse(
    //         JSON.stringify(rootState.crm.allUserTags)
    //       ).filter((e) => e.id !== payload);
    //       toaster.success('Deleted Successfully', {
    //         description: 'Successfully Deleted User Tag',
    //       });
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: data,
    //       });
    //       // ekhane fetch hobe customer meta
    //       return true;
    //     }
    //     return false;
    //   } catch (err) {
    //     toaster.danger('Delete Failed', {
    //       description: 'Failed to Deleted User Tag',
    //     });
    //     console.log(err.response);
    //     return false;
    //   }
    // },
    // async editUserTag(payload, rootState) {
    //   /*
    //   payload = {body: object}
    //    */
    //   try {
    //     const res = await axios.patch(
    //       `${config.projects}/${rootState.dashboard.selectedProject.id}/customer-tags/${payload.id}`,
    //       { name: payload.name }
    //     );
    //     if (res.status === 200 && res.data.success) {
    //       const data = JSON.parse(
    //         JSON.stringify(rootState.crm.allUserTags)
    //       ).filter((e) => {
    //         if (e.id === payload.id) {
    //           e.name = payload.name;
    //         }
    //         return e;
    //       });
    //       dispatch.crm.updateStateData({
    //         key: 'allUserTags',
    //         value: data,
    //       });
    //       toaster.success('Updated Successfully', {
    //         description: 'Successfully Updated User Tag',
    //       });
    //       // ekhane fetch hobe customer meta
    //       return true;
    //     }
    //     return false;
    //   } catch (err) {
    //     console.log(err.response);
    //     toaster.danger('Update Failed', {
    //       description: 'Failed to Updated User Tag',
    //     });
    //     return false;
    //   }
    // },
    // async assignUserTag(payload, rootState) {
    //   /*
    //   payload = {customerId: num, body: object
    //    */
    //   try {
    //     let shouldFetch = true;
    //     const TagAlreadyExist =
    //       rootState.crm.customerMeta.tags.filter(
    //         (e) => e.id === payload.body.id
    //       ).length > 0;
    //     if (TagAlreadyExist && payload.body.action === 'add') {
    //       shouldFetch = false;
    //     }
    //     if (shouldFetch) {
    //       const res = await axios.post(
    //         `${config.customers}/${payload.customerId}/tag`,
    //         payload.body
    //       );
    //
    //       if (res.status === 200 && res.data.success) {
    //         dispatch.crm.updateUserTag({
    //           tag: { name: payload.body.name, id: payload.body.id },
    //           action: payload.body.action,
    //         });
    //         if (payload.body.action === 'add') {
    //           toaster.success('Successful', {
    //             description: `Tag ${payload.body.name} Assigned Successfully`,
    //           });
    //         } else {
    //           toaster.success('Deleted', {
    //             description: `Tag ${payload.body.name} Deleted Successfully`,
    //           });
    //         }
    //       }
    //     } else {
    //       toaster.warning('Already Exist', {
    //         description: `Tag ${payload.body.name} Already exist`,
    //       });
    //     }
    //   } catch (err) {
    //     console.log(err.response);
    //   }
    // },

    async fetchUserInformation(customerId) {
      /*
      payload = customerId
       */
      try {
        const res = await axios.get(config.crm.attributes(customerId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerAttributes(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async updateUserInformationAPI(customerId, rootState) {
      /*
      payload = customerId
       */
      try {
        let tempBody = rootState.crm.tempAttributes;
        let body = {
          full_name: tempBody.fixed.full_name,
          gender: tempBody.fixed.gender,
          timezone: tempBody.fixed.timezone,
          locale: tempBody.fixed.locale,
          phone: tempBody.fixed.phone,
          email: tempBody.fixed.email,
          language: tempBody.fixed.language,
          ...tempBody.variable,
        };
        const res = await axios.post(config.crm.attributes(customerId), body);
        if (res.status === 200 && res.data.success) {
          toaster.success('Updated Successfully');
          dispatch.crm.updateCustomerAttributes(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed to Update');
          return false;
        }
      } catch (err) {
        console.log(err.response);
        return false;
      }
    },
    async fetchMessengerChat(ticketId) {
      /*
      payload = ticketId
       */
      try {
        const res = await axios.get(config.crm.messengerChat(ticketId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'chatData',
            value: res.data.dataSource,
          });
          dispatch.crm.updateStateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchFeedData(ticketId) {
      /*
      payload = ticketId
       */
      try {
        const res = await axios.get(config.crm.feedThread(ticketId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateStateData({
            key: 'chatData',
            value: res.data.dataSource,
          });
          dispatch.crm.updateDeletedConversation();
          dispatch.crm.updateStateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async sendMessage(payload, rootState) {
      /*
      payload = message,image,action, audio
       */
      try {
        const res = await axios.post(
          config.crm.messengerChat(rootState.crm.currentTicket.id),
          {
            text: payload.message,
            image: payload.image,
            audio: payload.audio,
            template: payload.template,
            action: payload.action,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.addChatData({
            event: res.data.dataSource,
            adminId: rootState.auth.id,
          });
          dispatch.crm.updateLockedStatus({
            key: 'is_locked',
            value: true,
            id: rootState.crm.currentTicket.id,
          });
          dispatch.crm.updateBotAssign(false);
          dispatch.crm.updateLeftbarTicketAssign(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          if (!!err.response?.data?.error) {
            toaster.danger('Failed', {
              description: err.response.data.error || 'Failed to Send Message',
            });
          }
        }
        return false;
      }
    },
    async sendMessageFeed(payload, rootState) {
      /*
      payload = message,image,action
       */
      try {
        const res = await axios.post(
          config.crm.feedThread(rootState.crm.currentTicket.id),
          {
            text: payload.message,
            image: payload.image,
            action: payload.action,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.addFeedReply([res.data.dataSource]);
          dispatch.crm.updateLockedStatus({
            key: 'is_locked',
            value: true,
            id: rootState.crm.currentTicket.id,
          });
          dispatch.crm.updateBotAssign(false);
          dispatch.crm.updateLeftbarTicketAssign(res.data.dataSource);
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: err?.response?.data?.error || 'Failed to Send Message',
        });
        return false;
      }
    },
    async fetchAssignableAdmins(projectId, rootState) {
      try {
        const res = await axios.get(config.settings.agentAccess(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateAssignAdmin(res.data.dataSource);
          dispatch.crm.updateNotificationInfo({
            data: res.data.dataSource,
            adminId: rootState.auth.id,
          });
        } else {
          dispatch.crm.updateAssignAdmin([]);
          dispatch.crm.updateNotificationInfo(null, -1);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async editNotificationKey(payload, rootState) {
      /*
        payload = {
          key: string,
          value:boolean,
          projectId: number

        }
       */
      try {
        const res = await axios.patch(
          config.crm.editNotification(payload.projectId, rootState.auth.id),
          {
            [payload.key]: payload.value,
          }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateNotificationKey(res.data.dataSource);
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Change Notification Settings',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Change Notification Settings',
          duration: 2,
        });
        return false;
      }
    },

    async assignAdmin(payload) {
      /*
        payload = ticketId: number, currentTicket: number
       */
      try {
        let body = { agent_id: '', group_id: '' };
        payload.agents.forEach(
          (agent) => (body['agent_id'] = agent.id.toString())
        );
        payload.groups.forEach(
          (group) => (body['group_id'] = group.id.toString())
        );
        const res = await axios.post(config.crm.adminAssign(payload.id), body);
        if (res.status === 200 && res.data.success) {
          toaster.success('Success', {
            description: 'Assignment successful',
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Assign ticket to This Agent/Group',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Assign ticket to This Agent/Group',
        });
        return false;
      }
    },
    async fetchAgentGroup(projectId) {
      try {
        const res = await axios.get(config.crm.groups(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateAgentGroup(res.data.dataSource);
        } else {
          dispatch.crm.updateAgentGroup([]);
        }
      } catch (err) {
        console.log(err.response);
        dispatch.crm.updateAgentGroup([]);
      }
    },

    async fetchCannedResponse(projectId) {
      try {
        const res = await axios.get(config.crm.cannedResponse(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCannedResponses(res.data.dataSource);
        } else {
          dispatch.crm.updateCannedResponses([]);
        }
      } catch (err) {
        dispatch.crm.updateCannedResponses([]);
      }
    },

    async createCannedResponse(payload) {
      /*
        payload = {
          title: string,
          text: string,
          forTeam:boolean,
          teamId: number;
        }
       */
      try {
        const res = await axios.post(
          config.crm.cannedResponse(payload.teamId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Created',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Create',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error || 'Canned Response Failed to Create',
          duration: 2,
        });
        return false;
      }
    },

    async editCannedResponse(payload) {
      /*
        payload = {
          id: number
          title: string,
          text: string,
          teamId: number | undefined,
          fot_team: boolean.
        }
       */
      try {
        const res = await axios.patch(
          config.crm.cannedResponseAction(payload.teamId, payload.id),
          {
            title: payload.title,
            text: payload.text,
            for_team: payload.for_team,
          }
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Updated',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Update',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: 'Canned Response Failed to Update',
          duration: 2,
        });
        return false;
      }
    },

    async deleteCannedResponse(payload) {
      /*
        payload = {
          id: number
          teamId: number | undefined
        }
       */
      try {
        const res = await axios.delete(
          config.crm.cannedResponseAction(payload.teamId, payload.id)
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'Canned Response Deleted',
            duration: 2,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Canned Response Failed to Delete',
            duration: 2,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: 'Canned Response Failed to Delete',
          duration: 2,
        });
        return false;
      }
    },
    async updateFeedAction(payload, rootState) {
      /*
      action = 'hide || 'delete' || 'edit',
      commentId : string;
      text: string,
       */

      try {
        let body = {
          action: payload.action,
          comment_id: payload.commentId,
        };
        if (payload.action === 'edit') {
          body = { ...body, text: payload.text };
        }
        const res = await axios.post(
          config.crm.feedThread(rootState.crm.currentTicket.id),
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success(payload.action.toUpperCase(), {
            description: 'Action was Successful',
          });
          if (payload.type === 'comment') {
            dispatch.crm.updateCommentStatus(payload.action);
          } else if (payload.type === 'reply') {
            if (payload.action === 'edit') {
              dispatch.crm.updateReplyStatus({
                ...payload,
                reply: res.data.dataSource,
              });
            } else {
              dispatch.crm.updateReplyStatus(payload);
              dispatch.crm.updateDeletedConversation(payload);
            }
          }
          return true;
        } else {
          toaster.danger('Failed', {
            description: `Failed to ${payload.action} this Request`,
          });
          return false;
        }
      } catch (err) {
        console.log(err.response);
        toaster.danger('Failed', {
          description: `Failed to ${payload.action} this Request`,
        });
        return false;
      }
    },
    async fetchCRMCustomerOrder({ customerId, orderId }) {
      try {
        const paramsLocal = !!orderId ? { order_id: orderId } : {};
        const res = await axios.get(config.crm.orderList(customerId), {
          params: paramsLocal,
        });
        if (res.data.success) {
          dispatch.crm.updateEComOrderList(res.data.dataSource);
        } else {
          dispatch.crm.updateEComOrderList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.crm.updateEComOrderList([]);
      }
    },
    async fetchProductInteraction({ customerId }) {
      try {
        const res = await axios.get(config.crm.productInteraction(customerId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateProductInteraction(res.data.dataSource);
        } else {
          dispatch.crm.updateProductInteraction(null);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCustomerOrderSummary({ customerId }) {
      try {
        const res = await axios.get(config.crm.orderSummary(customerId));
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateCustomerSummary(res.data.dataSource);
        } else {
          dispatch.crm.updateCustomerSummary(null);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async cancelOrder({ projectId, orderId }) {
      try {
        const res = await axios.post(
          config.crm.cancelOrder(projectId, orderId),
          {}
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateOrderData(res.data.dataSource);
          toaster.success('Order Cancellation', {
            description: 'Order Cancelled Successfully',
          });
        } else {
          dispatch.crm.updateOrderData(null);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async refundOrder({ projectId, orderId }) {
      try {
        const res = await axios.post(
          config.crm.refundOrder(projectId, orderId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.crm.updateOrderData(res.data.dataSource);
          toaster.success('Order Refund', {
            description: 'Order Refunded Successfully',
          });
        } else {
          dispatch.crm.updateOrderData(null);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAgentList(teamId) {
      /*
      payload= teamId : number
       */
      //note: this effect is for Reporting
      try {
        const res = await axios.get(config.crm.agent(teamId));
        if (res.data.success) {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'agentList',
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async reOpenTicket(payload) {
      /**
       * payload= {ticketId: number, note: string, agentId: number| null, groupId: number| null}
       */
      try {
        const { ticketId, note, agentId, groupId } = payload;
        const res = await axios.post(config.crm.reopenTicket(ticketId), {
          note,
          agent_id: agentId,
          group_id: groupId,
        });
        if (res.data.success) {
          toaster.success('Successfully Ticket Created');
          return res.data;
        } else {
          toaster.danger(res.data?.error || 'Ticket Reopen Failed');
          return { success: false };
        }
      } catch (err) {
        toaster.danger(err.response?.data?.error || 'Ticket Reopen Failed');
        return { success: false };
      }
    },
    async createWhatsAppTicket(payload) {
      /**
       * payload= {teamId: number, waChannelId: string, phone: string}
       */
      try {
        const { teamId, waChannelId, phone } = payload;
        const res = await axios.post(config.crm.createWhatsAppTicket(teamId), {
          platform_id: waChannelId,
          customer_phone: phone,
        });
        if (res.data.success) {
          toaster.success('Successfully Ticket Created');
          return res.data;
        } else {
          toaster.danger(res.data?.error || 'Ticket Creation Failed');
          return { success: false };
        }
      } catch (err) {
        toaster.danger(err.response?.data?.error || 'Ticket Creation Failed');
        return { success: false };
      }
    },
    async getWhatsAppMesssageTemplates(payload) {
      /**
       * payload= {platformId: number}
       */
      try {
        const { platformId } = payload;
        const res = await axios.get(
          config.crm.getWhatsAppMesssageTemplates(platformId)
        );
        if (res.data.success) {
          dispatch.crm.updateStateData({
            key: 'messageTemplates',
            value: res.data.dataSource.whatsapp_message_templates,
          });
        } else {
          dispatch.reporting.updateStateData({
            key: 'messageTemplates',
            value: [],
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
  }),
};
