import React from 'react';
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { Menu } from 'library/menu/Menu';
import { dropdownDefaultValue } from 'utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  selected: CountryDetailsProps | undefined;
  options: CountryDetailsProps[];
  handleChange: (value: string, name: string, isAddress: boolean) => void;
  isShippingAddress: boolean;
}

interface CountryDetailsProps {
  country: string;
  flag_base64: string;
  country_iso_code: string;
  id: number;
  iso_alpha3: string;
  iso_numeric: number;
  phone_code: string;
  currency_code: string;
  currency_name: string;
  currency_symbol: string;
}

const CountryListDropdown: React.FC<Props> = ({
  selected,
  options,
  handleChange,
  isShippingAddress,
}) => {
  const initialSelectedCountry = {
    country: selected?.country || dropdownDefaultValue.country,
    currency_name:
      selected?.currency_name || dropdownDefaultValue.currency_name,
    phone_code: selected?.phone_code || dropdownDefaultValue.phone_code,
    id: selected?.id || dropdownDefaultValue.id,
    country_iso_code:
      selected?.country_iso_code || dropdownDefaultValue.country_iso_code,
    iso_alpha3: selected?.iso_alpha3 || dropdownDefaultValue.iso_alpha3,
    iso_numeric: selected?.iso_numeric || dropdownDefaultValue.iso_numeric,
    currency_code:
      selected?.currency_code || dropdownDefaultValue.currency_code,
    currency_symbol:
      selected?.currency_symbol || dropdownDefaultValue.currency_symbol,
    flag_base64: selected?.flag_base64 || dropdownDefaultValue.flag_base64,
  };
  const [countrySearch, setCountrySearch] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState(
    initialSelectedCountry
  );
  const [toggleDropdown, setToggleDropdown] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    setSelectedCountry({
      country: selected?.country || dropdownDefaultValue.country,
      currency_name:
        selected?.currency_name || dropdownDefaultValue.currency_name,
      phone_code: selected?.phone_code || dropdownDefaultValue.phone_code,
      id: selected?.id || dropdownDefaultValue.id,
      country_iso_code:
        selected?.country_iso_code || dropdownDefaultValue.country_iso_code,
      iso_alpha3: selected?.iso_alpha3 || dropdownDefaultValue.iso_alpha3,
      iso_numeric: selected?.iso_numeric || dropdownDefaultValue.iso_numeric,
      currency_code:
        selected?.currency_code || dropdownDefaultValue.currency_code,
      currency_symbol:
        selected?.currency_symbol || dropdownDefaultValue.currency_symbol,
      flag_base64: selected?.flag_base64 || dropdownDefaultValue.flag_base64,
    });
  }, [selected]);

  const renderNoCountryFound = () => {
    return (
      options.filter((elemFilter) =>
        elemFilter?.country
          .toLowerCase()
          .includes(countrySearch.trim().toLowerCase())
      ).length === 0 && (
        <div className='flex items-center justify-center p-2 mt-2 text-xs font-medium text-gray-600 rounded-md cursor-default'>
          <ExclamationTriangleIcon className='w-4 h-4' />
          <span className='ml-2'>{t('Not Found')}</span>
        </div>
      )
    );
  };

  const renderCountrySearchModuleView = () => {
    return (
      <div className='flex py-2 bg-white -top-px'>
        <div className=' relative w-full border-gray-300 rounded-md shadow-sm'>
          <div className=' absolute inset-y-0 ltr:left-0 rtl:right-2 flex items-center w-full pl-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='search'
            value={countrySearch}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCountrySearch(e.target.value);
            }}
            className='block w-full ltr:pl-10 rtl:pr-8 border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500 sm:text-sm'
            placeholder={t('Search Your Country')}
          />
        </div>
      </div>
    );
  };

  const renderFlag = (flag_base64: string) => {
    return (
      flag_base64 && (
        <div>
          <span className='flex items-center'>
            <img
              src={`data:image/png;base64,${flag_base64}`}
              className='rounded-sm shadow'
              width={26}
              alt='flag'
            />
          </span>
        </div>
      )
    );
  };

  const renderCountryDropdownView = (options: CountryDetailsProps[]) => {
    return (
      <Menu>
        {options
          .filter((elemFilter) =>
            elemFilter?.country
              .toLowerCase()
              .includes(countrySearch.trim().toLowerCase())
          )
          ?.map((countryOption: CountryDetailsProps) => {
            return (
              <div
                data-value={countryOption}
                id={countryOption.country}
                onClick={() => {
                  setSelectedCountry(countryOption);
                  setToggleDropdown(!toggleDropdown);
                  handleChange(
                    countryOption.country,
                    'country',
                    isShippingAddress
                  );
                }}
                className='p-3 text-left cursor-pointer hover:bg-primary hover:text-white hover:font-semibold'
              >
                <span className='flex gap-3 align-center'>
                  {renderFlag(countryOption.flag_base64)}
                  <span className='block truncate'>
                    {countryOption.country +
                      ' - ' +
                      countryOption.currency_name}
                  </span>
                </span>
              </div>
            );
          })}
        {renderNoCountryFound()}
      </Menu>
    );
  };

  const renderSelectCountryButtonView = () => {
    return (
      <button
        className='relative w-full px-3 h-11 mt-1 border border-gray-300
        rounded-md shadow-sm focus:border-primary focus:ring-1 focus:ring-primary text-left'
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <span className='flex gap-3 items-center'>
          {renderFlag(selectedCountry?.flag_base64)}
          <span className='flex gap-3 truncate'>
            {selectedCountry.country}
            {' - '}
            {selectedCountry.currency_name}
          </span>
        </span>

        <span className='absolute ltr:right-[10px] rtl:left-[10px] top-[11px]'>
          <ChevronUpDownIcon className='w-5 h-5' aria-hidden='true' />
        </span>
      </button>
    );
  };

  return (
    <div className='relative'>
      {renderSelectCountryButtonView()}
      {toggleDropdown && (
        <div className='bg-white absolute w-full mt-1 z-10 border rounded-md'>
          <div className='p-1 sticky border-white rounded-md'>
            {renderCountrySearchModuleView()}
          </div>
          <div className='relative z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {renderCountryDropdownView(options)}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryListDropdown;
