import React, { useEffect, useState } from 'react';
import SubscriptionDetails from './component/SubscriptionDetails';
import AvailablePlans from './component/AvailablePlans';
import { connect } from 'react-redux';
import {
  subscriptionDetailsType,
  billingInfoType,
  cardInfoType,
} from './interface';
import PaymentDetails from './component/payment/PaymentDetails';
import BillingHistory from './component/payment/BillingHistory';
import DownGradeModal from './component/downgrade/downGradeModal';
import BillingPlanSettings from '../../old/pages/settings/pages/BillingPlanSettings';
import { plansProperty } from 'utilities/content';
import { UserPropertyType } from 'index';
import CancelPlanModal from './component/CancelPlanModal';
import ReactivatePlanModal from './component/ReactivatePlanModal';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface billingsProps {
  fetchAvailablePlans: () => void;
  selectedProject: SelectedTeamInterface;
  fetchSubscriptionDetails: (teamId: number) => void;
  cancelSubscription: (teamId: number) => { success: boolean };
  reactivateSubscription: (teamId: number) => { success: boolean };
  subscriptionDetails: subscriptionDetailsType;
  fetchPaymentDetails: () => void;
  billingHistory: any;
  fetchHistories: (teamId: number) => void;
  billingInfo: billingInfoType;
  cardInfo: cardInfoType[];
  fetchProject: () => void;
  projects: SelectedTeamInterface[];
  setSelectedProject: (payload: SelectedTeamInterface) => void;
  updateIsBillingAnnually: (payload: boolean) => void;
  auth: UserPropertyType;
}

const Billing: React.FC<billingsProps> = ({
  fetchAvailablePlans,
  selectedProject,
  fetchSubscriptionDetails,
  cancelSubscription,
  reactivateSubscription,
  subscriptionDetails,
  fetchPaymentDetails,
  billingHistory,
  fetchHistories,
  billingInfo,
  cardInfo,
  fetchProject,
  projects,
  setSelectedProject,
  updateIsBillingAnnually,
  auth,
}) => {
  const { t } = useTranslation();
  const [openDownGrade, setOpenDownGrade] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  useEffect(() => {
    fetchAvailablePlans();
    fetchSubscriptionDetails(selectedProject.id);
    fetchPaymentDetails();
    fetchHistories(selectedProject.id);
    fetchProject();
    const updatedProject = projects.filter(
      (project) => project.id === selectedProject.id
    );
    if (updatedProject.length > 0) {
      setSelectedProject(updatedProject[0]);
    }

    // eslint-disable-next-line
  }, []);

  const enterPriseMessage = () => {
    return (
      <div className='flex flex-col w-full mb-8'>
        <p className='text-lg text-gray-900 font-medium mb-4'>
          {t('Available Plans')}
        </p>
        <p className='flex w-full text-gray-500 text-sm font-normal'>
          {t('Please')}&nbsp;
          <a
            href='https://www.myalice.ai/contact'
            target='_blank'
            rel='noreferrer'
            className='text-blue-500 font-semibold'
          >
            {t('talk to sales')}
          </a>
          &nbsp;{t('update your plan details.')}
        </p>
      </div>
    );
  };

  return (
    <>
      {selectedProject.is_using_new_billing ? (
        <div className='mt-5'>
          {subscriptionDetails && (
            <>
              <SubscriptionDetails subscriptionDetails={subscriptionDetails} />
              {subscriptionDetails.project_billing_info?.subscription_plan
                ?.name === plansProperty.enterprise_plan.name ||
              subscriptionDetails.project_billing_info?.is_appsumo_project ? (
                enterPriseMessage()
              ) : (
                <AvailablePlans
                  selectedProject={selectedProject}
                  subscriptionDetails={subscriptionDetails}
                  setShowCancelModal={setShowCancelModal}
                  setShowReactivateModal={setShowReactivateModal}
                />
              )}
              <PaymentDetails
                billingInfo={billingInfo}
                cardInfo={cardInfo}
                billingCycle={
                  subscriptionDetails.project_billing_info.subscription_plan
                    .billing_cycle
                }
              />
              <BillingHistory histories={billingHistory} />
              {openDownGrade && (
                <DownGradeModal setOpenDownGrade={setOpenDownGrade} />
              )}
              <CancelPlanModal
                subscriptionDetails={subscriptionDetails}
                isShown={showCancelModal}
                setShown={setShowCancelModal}
                intent='danger'
                description='Your team will lose access to all features after the next billing cycle.
                Do you want to continue?'
                title='Cancel Plan?'
                confirmButtonTitle='Cancel Plan'
                cancelButtonTitle='Back to Safety'
                onCancelCallback={() => setShowCancelModal(false)}
                onConfirmCallback={async () => {
                  const response = await cancelSubscription(selectedProject.id);
                  if (response.success) {
                    fetchSubscriptionDetails(selectedProject.id);
                  } else {
                    toaster.danger('Something went wrong during cancellation', {
                      duration: 3,
                    });
                  }
                }}
                hasConfirmText={true}
                confirmText='CANCEL'
              />
              <ReactivatePlanModal
                isShown={showReactivateModal}
                setShown={setShowReactivateModal}
                intent='success'
                description='Your plan will not be cancelled anymore. Do you want to continue?'
                title='Reactivate Plan?'
                confirmButtonTitle='Reactivate'
                cancelButtonTitle='Cancel'
                onCancelCallback={() => setShowReactivateModal(false)}
                onConfirmCallback={async () => {
                  const response = await reactivateSubscription(
                    selectedProject.id
                  );
                  if (response.success) {
                    fetchSubscriptionDetails(selectedProject.id);
                  } else {
                    toaster.danger('Something went wrong during reactivation', {
                      duration: 3,
                    });
                  }
                }}
                hasConfirmText={false}
              />
            </>
          )}
        </div>
      ) : (
        <BillingPlanSettings />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  availablePlan: state.newBilling.availablePlans,
  selectedProject: state.dashboard.selectedProject,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  billingHistory: state.newBilling.billingHistory,
  billingInfo: state.newBilling.billingInfo,
  cardInfo: state.newBilling.cardInfo,
  projects: state.dashboard.projects,
  auth: state.auth,
});

const mapDispatch = (dispatch: any) => ({
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  cancelSubscription: (teamId: number) =>
    dispatch.newBilling.cancelSubscription(teamId),
  reactivateSubscription: (teamId: number) =>
    dispatch.newBilling.reactivateSubscription(teamId),
  fetchPaymentDetails: () => dispatch.newBilling.fetchPaymentDetails(),
  fetchHistories: (teamId: number) =>
    dispatch.newBilling.fetchHistories(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  updateIsBillingAnnually: (payload: boolean) =>
    dispatch.newBilling.updateIsBillingAnnually(payload),
});

export default connect(mapState, mapDispatch)(Billing);
