import { useSelector, useDispatch } from 'react-redux';
import { ISLAData, IReminder, IPolicy, Priority } from '../interface';
import { LOCAL_UTILS } from '../export';

const useRemindersUtils = () => {
  const dispatch = useDispatch();
  const slaStateData = useSelector(
    (state: any) => state.settingsSLAConfiguration
  );
  const { localSLAData } = slaStateData;

  const handleReminderRuleAddition = () => {
    let copiedSLAData: ISLAData = { ...localSLAData };

    let updatedReminder: IReminder[] = [
      ...copiedSLAData.reminders,
      LOCAL_UTILS.defaultReminderRule,
    ];

    let updatedLocalSLAData = {
      ...copiedSLAData,
      reminders: updatedReminder,
    };
    dispatch.settingsSLAConfiguration.updateLocalSLAData(updatedLocalSLAData);
  };

  const handleReminderRuleDeletion = (index: number) => {
    let copiedSLAData: ISLAData = { ...localSLAData };

    // Ensure index is valid
    if (index < 0 || index >= copiedSLAData.reminders.length) {
      console.error('Invalid index for reminder rule deletion.');
      return;
    }

    let updatedReminders: IReminder[] = copiedSLAData.reminders.filter(
      (_, i) => i !== index
    );

    let updatedLocalSLAData = {
      ...copiedSLAData,
      reminders: updatedReminders,
    };
    dispatch.settingsSLAConfiguration.updateLocalSLAData(updatedLocalSLAData);
  };

  type ReminderMatch =
    | {
        priority: number[];
        target: string;
        time: string;
      }
    | false;

  const checkReminderRuleCondition = (index: number): ReminderMatch => {
    let { reminders, policies } = localSLAData;

    const targetReminder = reminders[index];
    if (!targetReminder) return false;

    const { priority, target, time } = targetReminder;

    for (let i = 0; i < reminders.length; i++) {
      const reminder = reminders[i];
      // Find common priorities between the two lists
      let matchedPriorities = priority.filter((p: any) =>
        reminder.priority.includes(p)
      );

      // Filter the matched priorities based on time exceed rule
      matchedPriorities = matchedPriorities.filter(
        (matchedPriority: Priority) => {
          const policy = policies.find(
            (p: IPolicy) => p.priority === matchedPriority
          );
          if (!policy) return false;

          if (
            (target === 'first_response_time' &&
              time >= policy.first_response_time) ||
            (target === 'resolution_time' && time >= policy.resolution_time)
          ) {
            return true;
          }

          return false;
        }
      );

      // If there are common priorities that violate the time exceed rule and the target also matches
      if (matchedPriorities.length > 0 && reminder.target === target) {
        return {
          priority: matchedPriorities, // Return only the matched priorities that violate the rule
          target: reminder.target,
          time: reminder.time,
        };
      }
    }

    return false;
  };

  const checkForDuplicateReminder = (index: number): ReminderMatch => {
    let { reminders } = localSLAData;
    // Function implementation goes here
    const targetReminders = reminders[index];

    if (!targetReminders) return false;

    const { priority, target } = targetReminders;

    for (let i = 0; i < reminders.length; i++) {
      if (i !== index) {
        const reminder = reminders[i];

        // Find common priorities between the two lists
        const matchedPriorities = priority.filter((p: any) =>
          reminder.priority.includes(p)
        );

        // If there are common priorities and the target and time also match
        if (matchedPriorities.length > 0 && reminder.target === target) {
          return {
            priority: matchedPriorities, // Return only the matched priorities
            target: reminder.target,
            time: reminder.time,
          };
        }
      }
    }

    return false;
  };

  return {
    checkForDuplicateReminder,
    checkReminderRuleCondition,
    handleReminderRuleAddition,
    handleReminderRuleDeletion,
  };
};

export default useRemindersUtils;
