import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import TeamInfoSettingsForm from '../component/TeamInfoSettingsForm';
import {
  checkPermission,
  handleImageUpload,
} from '../../../../utilities/utils';
import { HasPermission } from '../../../../components';

class TeamInfoSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInfoChanged: false,
      facebookPageList: [],
      writeAccess: 'write:settings',
    };
  }

  handleSave = async () => {
    if (!!this.props.selectedProject) {
      await this.props.editProjectInfo(this.props.selectedProject.id);
      this.setState({ isInfoChanged: false });
      await this.props.fetchProject();
    } else {
      toaster.warning('Invalid', {
        description: `Project is not selected`,
        duration: 1,
      });
    }
  };

  handleCancel = () => {
    this.setState({ isInfoChanged: false });
    this.props.setSelectedProject(
      this.props.projects.filter(
        (p) => this.props.selectedProject.id === p.id
      )[0]
    );
  };

  handleImageChange = (image) => {
    this.setState({ isInfoChanged: true });
    handleImageUpload(image, (img) =>
        this.props.updateTeamInformation({
        ...this.props.teamInformation,
        'image': img
      })
    )
  };

  handleImageClear = () => {
    this.setState({ isInfoChanged: true });
    this.props.updateTeamInformation({
      ...this.props.teamInformation,
      'image': ''
    })
  };

  handleCountryChange = (data) => {
    this.setState({ isInfoChanged: true });
    this.props.updateTeamInformation({
      ...this.props.teamInformation,
      'country': data.country,
      'country_iso_code': data.country_iso_code,
      'phone_code': data.phone_code,
      'currency_name': data.currency_name,
      'currency_code': data.currency_code,
      'currency_symbol': data.currency_symbol
    })
  }

  handleTimezoneChange = (data) => {
    this.setState({ isInfoChanged: true });
    this.props.updateTeamInformation({
      ...this.props.teamInformation,
      'timezone_alias': data.timezone_alias,
      'timezone_offset_type': data.timezone_offset_type,
      'timezone_offset': data.timezone_offset,
      'city': data.city
    })
  }

  handleChange = (name, value) => {
    this.setState({ isInfoChanged: true });
    this.props.updateTeamInformation({
      ...this.props.teamInformation,
      [name]: value,
    });
  };

  async componentDidMount() {

    await this.props.fetchProject();
    await this.props.fetchCountryList();
    await this.props.fetchTimezoneList();
    // always needs to update information on project settings mount

    if (!this.props.previousProject && this.props.selectedProject === null) {
      this.props.setSelectedProject(this.props.projects[0]);
    }

    if (this.props.previousProject) {
      this.props.setSelectedProject(
        this.props.projects.filter(
          (e) => e.id === this.props.previousProject.id
        )[0]
      );
      //filter because I've already fetched new data now just update setSelected project with new data rather previous project
    }

    this.props.updateTeamInformation(this.props.previousProject)
  }

  getFlag = (countryName, countryList) => {
    if(countryList && countryName){
      return countryList.find(country => country.country === countryName).flag_base64
    }
  }

  render() {
    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );
    return (
      <HasPermission shortCode={'read:settings'} isSettingsView>
        <div className='project-settings'>
          <TeamInfoSettingsForm
            title='Team Information'
            hasWriteAccess={hasWriteAccess}
            description='Team Information like name, avatar change etc.'
            infoLoader={this.props.infoLoader}
            infoUpdateLoader={this.props.infoUpdateLoader}
            isInfoChanged={this.state.isInfoChanged}
            projectInfo={this.props.teamInformation}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            handleDescChange={(val) => this.handleChange('description', val)}
            handleNameChange={(val) => this.handleChange('name', val)}
            handleImageChange={this.handleImageChange}
            handleImageClear={this.handleImageClear}
            countryList={this.props.countryList}
            countryDetails={{
              'country': this.props.teamInformation.country,
              'country_iso_code': this.props.teamInformation.country_iso_code,
              'phone_code': this.props.teamInformation.phone_code,
              'currency_name': this.props.teamInformation.currency_name,
              'currency_code': this.props.teamInformation.currency_code,
              'currency_symbol': this.props.teamInformation.currency_symbol,
              'flag_base64': this.getFlag(this.props.teamInformation.country, this.props.countryList)
            }}
            handleCountryChange={(data) => this.handleCountryChange(data)}
            timezoneList={this.props.timezoneList}
            timezoneDetails={{
              'timezone_alias': this.props.teamInformation.timezone_alias,
              'timezone_offset_type': this.props.teamInformation.timezone_offset_type,
              'timezone_offset': this.props.teamInformation.timezone_offset,
            }}
            handleTimezoneChange={(data) => this.handleTimezoneChange(data)}
          />
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  projects: state.dashboard.projects,
  selectedProject: state.settings.selectedProject,
  teamInformation: state.settings.teamInformation,
  previousProject: state.dashboard.selectedProject,
  infoLoader: state.loading.effects.dashboard.fetchProject,
  infoUpdateLoader: state.loading.effects.settings.editProjectInfo,
  permissionList: state.dashboard.permission.list || [],
  countryList: state.settings.countryList,
  countryDetails: state.settings.countryDetails,
  timezoneList: state.settings.timezoneList,
  timezoneDetails: state.settings.timezoneDetails,
});

const mapDispatch = (dispatch) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchCountryList: () => dispatch.settings.fetchCountryList(),
  fetchTimezoneList: () => dispatch.settings.fetchTimezoneList(),
  editProjectInfo: (projectId) => dispatch.settings.editProjectInfo(projectId),
  setSelectedProject: (payload) => dispatch.settings.setSelectedProject(payload),
  updateTeamInformation: (payload) => dispatch.settings.updateTeamInformation(payload),
});

const TeamInfoSettingsContainer = connect(
  mapState,
  mapDispatch
)(TeamInfoSettings);

export default TeamInfoSettingsContainer;
