import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface props {
  title: string;
}

const UnsupportedBlockTag: React.FC<props> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className='clone-channel__info-box__component-item'>
      <svg
        width='16'
        height='16'
        viewBox='0 0 11 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.69993 3.19999L7.29993 6.79999M3.69993 6.79999L7.29993 3.19999M5.49993 9.19999C3.18033 9.19999 1.29993 7.31958 1.29993 4.99999C1.29993 2.68039 3.18033 0.799988 5.49993 0.799988C7.81952 0.799988 9.69993 2.68039 9.69993 4.99999C9.69993 7.31958 7.81952 9.19999 5.49993 9.19999Z'
          stroke='#FF0800'
        />
      </svg>
      &nbsp;{t(title)}
    </div>
  );
};

export default UnsupportedBlockTag;
