import React from 'react';
import { availablePlansProperty, subscriptionDetailsType } from '../interface';
import { navigate } from '@reach/router';
import { plansProperty } from '../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface packageType {
  planData: availablePlansProperty;
  discount: boolean;
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  setShowCancelModal: (value: boolean) => void;
  setShowReactivateModal: (value: boolean) => void;
}

const PackageCard: React.FC<packageType> = ({
  planData,
  discount,
  selectedProject,
  subscriptionDetails,
  setShowCancelModal,
  setShowReactivateModal,
}) => {
  const { t } = useTranslation();
  const currentPlan =
    subscriptionDetails?.project_billing_info?.subscription_plan?.name;
  const isPaidProject = () => {
    return selectedProject?.is_pro && selectedProject?.subscription_anchor_time;
  };

  return (
    <div className='w-1/2 border border-gray-200 p-6 rounded-lg'>
      <div className='flex h-8 mb-2'>
        <div className='w-3/4'>
          <p className='text-gray-900 text-sm font-semibold uppercase my-1.5'>
            {t(planData.name)}
          </p>
        </div>
        <div className='w-1/2'>
          <div className='flex w-full justify-end'>
            {planData.name === plansProperty.enterprise_plan.name && (
              <p className='text-gray-500 text-sm font-normal my-1.5'>
                {t('Custom')}
              </p>
            )}
            {planData.name !== plansProperty.enterprise_plan.name && (
              <>
                {!discount && (
                  <>
                    <p className='text-gray-900 text-lg leading-8 font-semibold mr-1'>
                      ${planData.monthly_price * planData.minimum_team_size}
                    </p>
                    <p className='text-gray-500 text-sm font-normal my-1.5'>
                      /{t('month')}
                    </p>
                  </>
                )}
                {discount && (
                  <>
                    <p className='text-gray-400 text-sm leading-8 font-medium mr-1 line-through'>
                      $
                      {planData.monthly_price * planData.minimum_team_size * 12}
                    </p>
                    <p className='text-gray-900 text-lg leading-8 font-semibold mr-1'>
                      ${planData.yearly_price * planData.minimum_team_size}
                    </p>
                    <p className='text-gray-500 text-sm font-normal my-1.5'>
                      /{t('year')}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <p className='text-gray-500 text-sm font-normal mb-6'>
        {t(planData.description)}
      </p>
      <div className='flex h-9'>
        <div className='flex w-2/3 gap-5'>
          {planData.name === plansProperty.enterprise_plan.name && (
            <button
              className='w-32 py-2 ltr:mr-auto rtl:ml-auto text-sm text-white font-medium
              rounded-md bg-primary hover:bg-primary-hover'
              onClick={() =>
                window.open('https://www.myalice.ai/contact', '_blank')
              }
            >
              {t('Talk to sales')}
            </button>
          )}
          {planData.name !== plansProperty.enterprise_plan.name && (
            <>
              {planData.name === currentPlan && !!isPaidProject() && (
                <>
                  {subscriptionDetails?.project_billing_info
                    ?.subscription_status === 'cancellation_scheduled' && (
                    <button
                      className='w-32 text-white text-sm font-medium capitalize
                      ltr:mr-auto rtl:ml-auto bg-primary hover:bg-primary-hover px-6 py-2 rounded-md'
                      onClick={() => setShowReactivateModal(true)}
                    >
                      {t('Reactivate')}
                    </button>
                  )}
                  {subscriptionDetails?.project_billing_info
                    ?.subscription_status !== 'cancellation_scheduled' && (
                    <button
                      className='w-32 text-[#038E4C] text-sm font-medium capitalize cursor-not-allowed
                    ltr:mr-auto rtl:ml-auto bg-[#CDF0DF] px-6 py-2 rounded-md'
                      disabled
                    >
                      {t('Active')}
                    </button>
                  )}
                </>
              )}
              {((planData.name !== currentPlan &&
                currentPlan === plansProperty.premium_plan.name) ||
                !isPaidProject()) && (
                <button
                  className={`w-32 px-6 py-2 ltr:mr-auto rtl:ml-auto rounded-md text-sm font-medium capitalize
                  ${
                    subscriptionDetails?.project_billing_info
                      ?.subscription_status === 'cancellation_scheduled'
                      ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
                      : 'text-white bg-primary hover:bg-primary-hover'
                  }`}
                  disabled={
                    subscriptionDetails?.project_billing_info
                      ?.subscription_status === 'cancellation_scheduled'
                  }
                  onClick={() =>
                    navigate(
                      `/settings/billing/upgrade?plan_type=${planData.name}`
                    )
                  }
                >
                  {t('Upgrade')}
                </button>
              )}
              {planData.name !== currentPlan &&
                currentPlan === plansProperty.business_plan.name && (
                  <button
                    className={`w-32 px-6 py-2 ltr:mr-auto rtl:ml-auto rounded-md text-sm font-medium capitalize
                  ${
                    subscriptionDetails?.project_billing_info
                      ?.subscription_status === 'cancellation_scheduled'
                      ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
                      : 'text-gray-700 bg-white border border-gray-300'
                  }`}
                    disabled={
                      subscriptionDetails?.project_billing_info
                        ?.subscription_status === 'cancellation_scheduled'
                    }
                    onClick={() =>
                      navigate(
                        `/settings/billing/upgrade?plan_type=${planData.name}`
                      )
                    }
                  >
                    {t('Downgrade')}
                  </button>
                )}
            </>
          )}
        </div>
        <div className='flex ltr:w-1/3 rtl:w-1/2 justify-end align-center pt-4'>
          {planData.name === currentPlan &&
          !!isPaidProject() &&
          subscriptionDetails?.project_billing_info?.subscription_status !==
            'cancellation_scheduled' ? (
            <button
              className='text-gray-500 text-sm font-normal capitalize underline decoration-gray-500'
              onClick={() => setShowCancelModal(true)}
            >
              {t('Cancel Plan')}
            </button>
          ) : (
            <a
              target='_blank'
              href='https://www.myalice.ai/pricing'
              className='text-gray-500 text-sm font-normal capitalize underline decoration-gray-500'
              rel='noreferrer'
            >
              {t('See Details')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
