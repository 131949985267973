export const attributeData = [
  {
    name: 'Email',
    value: '{{email}}',
  },
  {
    name: 'Full Name',
    value: '{{full_name}}',
  },
  {
    name: 'Phone Number',
    value: '{{phone}}',
  },
];


export const visibilityOption= [
  {
    name: 'Always',
    value: 'always',
  },
  {
    name: 'Only during business hours',
    value: 'inside_business_hours',
  },
  {
    name: 'Only outside of business hours',
    value: 'outside_business_hours',
  },
  {
    name: 'Never',
    value: 'never',
  },
];
