import * as dateFns from 'date-fns';
import { words } from 'lodash';

export function appendQueryParamsToUrl(
  queries: Record<string, string | number | boolean | null | undefined> = {},
  mode: 'replace' | 'append' = 'replace'
): URL {
  const newUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(newUrl.search);

  for (const [key, value] of Object.entries(queries)) {
    if (value === null || value === undefined) {
      searchParams.delete(key);
    } else if (mode === 'replace') {
      searchParams.set(key, value + '');
    } else {
      searchParams.append(key, value + '');
    }
  }

  newUrl.search = searchParams.toString();
  window.history.replaceState(null, '', newUrl);
  return newUrl;
}

export function convertMinutesToHumanReadable(minutes: number) {
  if (typeof minutes !== 'number' || isNaN(minutes)) {
    return null;
  }
  const now = new Date();
  const futureDate = dateFns.add(now, { minutes });
  // Calculate the difference in days
  const daysDifference = dateFns.differenceInDays(futureDate, now);
  // Adjust 'now' by adding the days to calculate the remaining hours
  const adjustedNow = dateFns.add(now, { days: daysDifference });
  const hoursDifference = dateFns.differenceInHours(futureDate, adjustedNow);
  // Construct the final string
  const daysLabel = daysDifference === 1 ? 'day' : 'days';
  const hoursLabel = hoursDifference === 1 ? 'hour' : 'hours';
  return ` ${daysDifference} ${daysLabel} ${hoursDifference} ${hoursLabel}`;
}

export const truncateText = (text: any, limit = 20) => {
  if (!(typeof text == 'string')) {
    return '';
  }
  return;
};

export const getUserNameInitials = (name: string) =>
  words(name).length > 1
    ? (words(name)[0][0] + words(name).slice(-1)[0][0]).toUpperCase()
    : name.slice(0, 2).toUpperCase();
