import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import emptyState from './../../../../assets/images/emptyStateProductInteraction.svg';
import {
  ProductDataProps
} from '../interfaces';

interface Prop {
  product: ProductDataProps;
  //to do define product interface
  handleProductSelect: (product: any) => void;
  currencySymbol: string;
}

const ProductItem: React.FC<Prop> = ({
  product,
  handleProductSelect,
  currencySymbol,
}) => {
  return (
    <li
      onClick={() => {
        if (product.in_stock) {
          handleProductSelect(product);
        }
      }}
    >
      <div
        className={`block ${
          product.in_stock
            ? 'bg-white cursor-pointer'
            : 'bg-gray-50 cursor-not-allowed'
        } hover:bg-gray-50`}
      >
        <div className='grid grid-cols-8 gap-4 px-4 py-4 sm:px-6'>
          <div className='flex items-center flex-1 min-w-0 col-span-7 sm:col-span-6'>
            <div className='flex-shrink-0'>
              <img
                className='w-12 h-12 rounded-md'
                src={
                  product.product_images.length
                    ? product.product_images[0]
                    : emptyState
                }
                alt=''
              />
            </div>
            <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-6 md:gap-4'>
              <div className='md:col-span-5'>
                <p className='text-sm font-medium truncate text-primary'>
                  {product.product_name}
                </p>
                <p className='flex items-center mt-2 text-sm text-gray-500'>
                  <span className='truncate'>
                    {!!product.product_description
                      ? product.product_description
                      : 'No Description Provided'}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='flex items-center col-span-1 sm:col-span-2'>
            <div className='hidden mr-0 md:block md:mr-4'>
              <div>
                <p className='text-sm font-medium text-gray-900'>
                  {currencySymbol}
                  {product.unit_price}
                </p>
                {product.in_stock ? (
                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                    In Stock:{' '}
                    {product.stock_count === 0
                      ? 'Infinite'
                      : product.stock_count}
                  </p>
                ) : (
                  <p className='flex items-center mt-2 text-sm text-red-600'>
                    Out Of Stock
                  </p>
                )}
              </div>
            </div>
            {product.in_stock && (
              <ChevronRightIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
