import React from 'react';
import { Spinner } from 'evergreen-ui';
import dayJs from 'dayjs';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Button } from '../../../../library';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import EmptyTableCellsIcon from '../../assets/images/EmptyTableIcon.svg';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import useTranslation from 'components/customHooks/useTranslation';

const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <div className='p-3 text-xs border border-gray-200 rounded bg-white-old'>
        <p className='text-gray-700'>
          {dayJs(payload[0].payload.timestamp * 1000).format(
            'YYYY-MM-DD; HH:MM:ssA'
          )}
        </p>
        <p className='w-auto mb-2 border border-gray-200' />
        <p className='flex justify-between text-xs text-gray-900'>
          <span>{t('Incoming')}</span>
          <span>{payload[0].payload['Incoming Texts']}</span>
        </p>
        <p className='flex justify-between text-xs text-gray-900'>
          <span>{t('Automated')}</span>
          <span>{payload[0].payload['Automated Responses']}</span>
        </p>
      </div>
    );
  }
  return null;
};

const checkEmptyData = (data) => {
  let hasData = false;
  for (let elem of data.entries()) {
    if (
      elem[1]['Incoming Texts'] !== '0' ||
      elem[1]['Automated Responses'] !== '0'
    ) {
      hasData = true;
      break;
    }
  }
  return hasData;
};

const findMaxValue = (data) => {
  let maxVal = data.reduce(function (prev, current) {
    return Number(prev['Incoming Texts']) > Number(current['Incoming Texts'])
      ? prev
      : current;
  });
  return Number(maxVal['Incoming Texts']);
};

const ReportingLineChart = ({
  title,
  toolTipInfo,
  data,
  loader,
  exportData,
  exportLoader,
}) => {
  const { t } = useTranslation();
  const hasData = checkEmptyData(data);
  return (
    <div className='overflow-hidden bg-white rounded-lg shadow '>
      <div className='px-4 py-5 sm:p-6'>
        <div className='reporting-container__user-card-container-elem-data'>
          <div className='reporting-title-container'>
            <div className='flex text-base font-normal text-gray-900'>
              {t(title)}
              {!!toolTipInfo ? (
                <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(toolTipInfo)}>
                  <InformationCircleIcon className='w-4 h-4' />
                </span>
              ) : (
                ''
              )}
            </div>
            <div className='flex justify-start ltr:ml-auto rtl:mr-auto'>
              <Button
                intent='default'
                isDisabled={loader || data?.length === 0}
                size={'sm'}
                onClick={() => exportData()}
                className={`ltr:ml-4 rtl:mr-4 ${loader ? '' : 'text-gray-800'}`}
              >
                {!exportLoader && (
                  <ArrowDownTrayIcon
                    className='ltr:-ml-0.5 rtl:ml-2 ltr:mr-2 rtl:-mr-0.5 h-4 w-4'
                    aria-hidden='true'
                  />
                )}
                {t('Export')}
              </Button>
            </div>
            &nbsp;
          </div>
          <br />
          {!loader && !hasData && (
            <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white'>
              <img
                src={EmptyTableCellsIcon}
                alt={'empty table'}
                className={'w-32 mt-8'}
              />
              <p className='mt-8 mb-8 font-sans text-sm font-normal'>
                <TranslationWrapper text='No data available for this timeline' />
              </p>
            </div>
          )}
          {!loader && hasData && (
            <div className='flex ltr:mr-5 rtl:ml-5 align-center space-evenly'>
              <LineChart
                width={window.innerWidth / 2 - 120}
                height={window.innerHeight / 2}
                data={data}
              >
                <CartesianGrid vertical={false} />
                <XAxis
                  dataKey='name'
                  tickLine={false}
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  domain={[0, findMaxValue(data)]}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend align={'center'} iconType={'square'} />
                <Line
                  type='linear'
                  dataKey={t('Incoming Texts')}
                  stroke='#3B82F6'
                  dot={true}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type='linear'
                  dataKey={t('Automated Responses')}
                  stroke='#FBBF24'
                  dot={true}
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </div>
          )}
          {loader && (
            <div className='flex justify-center h-300px align-center'>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
ReportingLineChart.propTypes = {
  title: PropTypes.string,
  toolTipInfo: PropTypes.string,
  data: PropTypes.any,
  loader: PropTypes.bool,
};
export default ReportingLineChart;
