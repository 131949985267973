import axios from 'axios';
import { IDataLabField } from 'pages/datalab/interface';
import { getReplacedLink } from 'pages/inbox/utils/functions';

interface ValidationResult {
  success: boolean;
  error?: string;
  data?: any;
}

interface Pattern {
  type: string;
  key: string;
  title: string;
  subtitle?: string;
  value: string;
}

export const isEmtyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const validateObject = (
  obj: Record<string, any>,
  pattern: Pattern
): ValidationResult => {
  // Check if the object is empty
  if (isEmtyObject(obj)) {
    return { success: false, error: 'No Result Found. Try different keyword' };
  }

  // Check if the specified property exists in the object
  const keys = pattern.key.split('.');
  let currentObj = obj;
  for (const key of keys) {
    if (key.includes('[')) {
      const arrayKey = key.split('[')[0];
      const index = parseInt(key.split('[')[1].replace(']', ''), 10);
      currentObj = currentObj[arrayKey];
      if (!currentObj || !Array.isArray(currentObj) || !currentObj[index]) {
        return {
          success: false,
          error: `Property "${pattern.key}" not found.`,
        };
      }
      currentObj = currentObj[index];
    } else {
      currentObj = key === '' ? currentObj : currentObj[key];
      if (!currentObj) {
        return {
          success: false,
          error: `Property "${pattern.key}" not found.`,
        };
      }
    }
  }

  // Check if the property has a unique pattern
  if (pattern.type === 'dict' && typeof currentObj !== 'object') {
    return {
      success: false,
      error: `Property "${pattern.key}" is not a dictionary.`,
    };
  } else if (pattern.type === 'list' && !Array.isArray(currentObj)) {
    return {
      success: false,
      error: `Property "${pattern.key}" is not a list.`,
    };
  }

  // Check if title, subtitle, and value exist in array elements
  if (Array.isArray(currentObj) && currentObj.length > 0) {
    const firstElement = currentObj[0];
    if (!firstElement.hasOwnProperty(pattern.title)) {
      return {
        success: false,
        error: `Array elements do not have required properties: ${pattern.title} `,
      };
    }
    if (!firstElement.hasOwnProperty(pattern.value)) {
      return {
        success: false,
        error: `Array elements do not have required properties: ${pattern.value}`,
      };
    }
  }

  // If all checks pass, return success object
  return { success: true, data: currentObj };
};

export const getListFromKey = (
  obj: Record<string, any> | any[],
  key: string
): any[] | null => {
  if (isEmtyObject(obj)) {
    return null;
  }

  if (key === '') return Array.isArray(obj) ? obj : null;

  const keys = key.split('.');
  let currentObj: any = obj;

  for (const k of keys) {
    if (k.includes('[')) {
      const arrayKey = k.split('[')[0];
      const index = parseInt(k.split('[')[1].replace(']', ''), 10);
      currentObj = currentObj[arrayKey];
      if (!currentObj || !Array.isArray(currentObj) || !currentObj[index]) {
        return null;
      }
      currentObj = currentObj[index];
    } else {
      currentObj = currentObj[k];
      if (!currentObj || !Array.isArray(currentObj)) {
        return null;
      }
    }
  }

  return currentObj;
};

//prepare payload for api call
export const preparePayloadForAxiosCall = <T>(
  url: string,
  payload: T,
  method: string,
  replaceValue: string
): any => {
  // Clone the original payload to avoid modifying the input object
  const updatedPayload: T = { ...payload };

  // Replace the value of properties with the provided value
  for (const key in updatedPayload) {
    if (
      Object.prototype.hasOwnProperty.call(updatedPayload, key) &&
      updatedPayload[key] === '$$'
    ) {
      (updatedPayload as any)[key] = replaceValue;
    }
  }

  // Move payload properties to URL parameters for GET method
  if (method.toLowerCase() === 'get') {
    const { ...rest } = updatedPayload;
    return { url, method, params: { ...rest } };
  }

  // For other methods, payload remains in the request body
  return { url, method, data: updatedPayload };
};

const mapResponseData = (
  //@ts-ignore
  response: AxiosResponse,
  pattern: Pattern
): any => {
  const { data } = response;

  return mapListData(data, pattern.key, pattern);
};

const mapListData = (data: any, key: string, pattern: Pattern): any => {
  let result = getListFromKey(data, key);

  if (!result) return [];

  return result.map((item: any) => mapDataByPattern(item, pattern));
};

const mapDataByPattern = (data: any, pattern: Pattern): any => {
  const mappedData: any = {};

  if (pattern.title) {
    mappedData.title = data[pattern.title];
  }

  if (pattern.subtitle) {
    mappedData.subtitle = data[pattern.subtitle];
  }

  mappedData.value = data[pattern.value];

  return mappedData;
};

//@ts-ignore
export const DatalabSearchAxiosCall = (
  datalabFields: IDataLabField[],
  query: string,
  apiProps: any,
  callback: any,
  apiLink: string = ''
) => {
  if (
    !apiProps.hasOwnProperty('request') ||
    !apiProps.hasOwnProperty('response')
  ) {
    return { success: false, error: 'Invalid property' };
  }

  const updatedPayloadForGet = preparePayloadForAxiosCall(
    !!apiLink
      ? apiLink
      : getReplacedLink(datalabFields, apiProps?.request?.url),
    apiProps?.request?.payload,
    apiProps?.request?.method,
    query
  );

  axios
    .request(updatedPayloadForGet)
    .then((response: any) => {
      const validateResponse = validateObject(
        response.data,
        apiProps?.response
      );
      if (!validateResponse?.success)
        callback({ ...validateResponse, data: [] });
      else {
        const data = mapResponseData(response, apiProps?.response);
        callback({
          success: !!data && data?.length > 0,
          data,
          error: 'No result found. Try different keyword',
        });
      }
    })
    .catch((error) => {
      console.error('error:', error);
      callback({
        success: false,
        data: [],
        error: 'No Data Found',
      });
    });
};
