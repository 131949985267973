import React from 'react';
import ProductItem from './ProductItem';
import { Button } from '../../../../../../../library';
import { ProductDataProps } from '../interface';

interface Prop {
  productData: ProductDataProps[];
  selectedProductData: ProductDataProps[];
  selectedProductIds: (string | number)[];
  handleProductSelect: (product: ProductDataProps) => void;
  shouldScrollNext: boolean;
  handleLoadProductScroll: () => void;
  productListLoading: boolean;
  noProductsFound: boolean;
  currencySymbol: string;
}

const ProductList: React.FC<Prop> = ({
  productData,
  selectedProductData,
  selectedProductIds,
  handleProductSelect,
  shouldScrollNext,
  handleLoadProductScroll,
  productListLoading,
  noProductsFound,
  currencySymbol,
}) => {
  return (
    <div>
      {!!selectedProductData && selectedProductData.length > 0 &&
        <div className='px-6 pb-4 mt-4'>
          <div className='overflow-hidden bg-white shadow sm:rounded-md'>
              <ul className='divide-y divide-gray-200'>
                {selectedProductData.map((product: ProductDataProps, index: number) => (
                  <ProductItem
                    product={product}
                    key={index}
                    handleProductSelect={handleProductSelect}
                    currencySymbol={currencySymbol}
                  />
                ))}
              </ul>
          </div>
        </div>
      }
      <div className={'px-6 pb-4 mt-4'}>
        <div className='overflow-hidden bg-white shadow sm:rounded-md'>
          <ul className='divide-y divide-gray-200'>
            {productData.filter((p: ProductDataProps) => selectedProductIds.indexOf(p.product_id) < 0).map(
              (product: ProductDataProps, index: number) => (
                <ProductItem
                  product={product}
                  key={index}
                  handleProductSelect={handleProductSelect}
                  currencySymbol={currencySymbol}
                />
            ))}
          </ul>
        </div>
        {shouldScrollNext && (
          <div className='flex justify-center w-full mt-2'>
            <Button
              intent='default'
              size={'sm'}
              isLoading={productListLoading}
              onClick={handleLoadProductScroll}
            >
              {productListLoading ? 'Loading...' : 'Load More'}
            </Button>
          </div>
        )}
        {noProductsFound && (
          <div className='flex justify-center w-full mt-2'>
            <Button intent='default' size={'sm'} isLoading={!productListLoading}>
              No other products found
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;
