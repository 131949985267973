import {
  dayjs,
  useSelector,
  EmptyTableCellsIcon,
  Pagination,
  UtilityContents,
  useAutomationHistories,
  useAutomationLog,
} from 'pages/automationWorkflow/export';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { IUserFlowLogTableDataTypes } from 'pages/automationWorkflow/interface';
import { Button } from 'libraryV2/ui/button';
import { startCase } from 'lodash';

dayjs.extend(LocalizedFormat);

const AutomationHistoryTableData = () => {
  const { updateHistoryTableOffset } = useAutomationHistories();
  const { updateAutomationflowViewType } = useAutomationLog();
  const { historyTableOffset, totalHistoryData, selectedAutomationLogList } =
    useSelector((state: any) => state.workflowAutomation);
  const historyList: IUserFlowLogTableDataTypes[] =
    selectedAutomationLogList?.history_list || [];
  const uniqueIdentifierName =
    selectedAutomationLogList?.Unique_identifier_name || '';
  const renderEmptyTableView = () => {
    return (
      <div className='flex flex-col items-center justify-center min-w-full p-8 bg-white shadow h-[400px]'>
        <EmptyTableCellsIcon />
        <p className='mt-8 mb-8 text-base font-normal text-gray-500 text-center leading-6'>
          You haven't created any flow yet. <br /> Create one to start
          collecting data.
        </p>
      </div>
    );
  };

  const renderPaginationView = () => {
    return (
      historyList?.length !== 0 && (
        <Pagination
          offset={historyTableOffset}
          tableDataLength={historyList?.length}
          total={totalHistoryData}
          limit={UtilityContents.UserActionFlowHistoryGetterApiDataLimit}
          handleNextButton={() =>
            updateHistoryTableOffset(
              historyTableOffset +
                UtilityContents.UserActionFlowHistoryGetterApiDataLimit
            )
          }
          handlePreviousButton={() =>
            updateHistoryTableOffset(
              historyTableOffset -
                UtilityContents.UserActionFlowHistoryGetterApiDataLimit
            )
          }
        />
      )
    );
  };

  const headerTitle = [
    'Triggered Time',
    startCase(uniqueIdentifierName),
    'status',
    'Action',
  ];

  return (
    <div>
      <div className='flex flex-col overflow-x-auto overflow-y-hidden'>
        <div className='-my-2'>
          <div className='inline-block min-w-full py-2 align-middle'>
            {historyList?.length !== 0 && (
              <>
                <table className='min-w-full min-h-full border-t border-b divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      {headerTitle.map((head: string, index: number) => (
                        <th
                          key={index}
                          scope='col'
                          className='px-5 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase'
                        >
                          {head.toLocaleUpperCase()}
                        </th>
                      ))}
                    </tr>
                  </thead>{' '}
                  <tbody className='divide-y divide-gray-200'>
                    {!!historyList &&
                      historyList.map(
                        (log: IUserFlowLogTableDataTypes, index: number) => (
                          <tr key={index}>
                            <td className='px-6 py-4 whitespace-nowrap'>
                              <p className='text-sm text-gray-900 cursor-pointer'>
                                {dayjs
                                  .unix(Number(log?.triggered_time))
                                  .format('LT')}
                              </p>
                              <p className='text-gray-400 text-xs'>
                                {dayjs
                                  .unix(Number(log?.triggered_time))
                                  .format('MMM D, YYYY')}
                              </p>
                            </td>

                            <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
                              {log?.Unique_identifier_value === ''
                                ? '--/--'
                                : log?.Unique_identifier_value}
                            </td>
                            <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                              <div
                                className={`flex items-center px-2 py-1 rounded-full text-xs font-medium capitalize w-24 ${
                                  log?.status === 'failed'
                                    ? 'bg-red-50 text-red-500'
                                    : 'bg-green-50 text-green-500'
                                }`}
                              >
                                <div
                                  className={`w-1.5 h-1.5 rounded-full mr-1.5 ${
                                    log?.status === 'failed'
                                      ? 'bg-red-500'
                                      : 'bg-green-500'
                                  }`}
                                ></div>
                                {log?.status}
                              </div>
                            </td>

                            <td className='px-6 py-4 text-sm whitespace-nowrap'>
                              <Button
                                variant={'ghost'}
                                className=' text-[#0078CF]'
                                onClick={() => {
                                  updateAutomationflowViewType(log?.history_id);
                                }}
                              >
                                View Logs
                              </Button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
                {renderPaginationView()}
              </>
            )}
            {historyList?.length === 0 && renderEmptyTableView()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationHistoryTableData;
