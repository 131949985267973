import React from 'react';
import { useDrag } from 'react-dnd';

import { GripVerticalIcon, XIcon } from 'lucide-react';
import { InputText, DragTypes, platinum_color } from 'pages/datalab/export';
import { Button } from 'libraryV2/ui/button';

interface IChoiceProps {
  index: number;
  choice: { [key: string]: any };
  onDeleteChoice: (index: number) => void;
}

const Choice: React.FC<IChoiceProps> = (props) => {
  const { index, choice, onDeleteChoice } = props;

  const [{ isChoiceDragging }, drag] = useDrag({
    type: DragTypes.MULTI_CHOICE,
    item: { choice, index, type: DragTypes.MULTI_CHOICE },
    collect: (monitor) => ({
      isChoiceDragging: monitor.isDragging(),
    }),
  });

  const dragStyles = isChoiceDragging
    ? {
        opacity: 0.5,
        background: '#fafafa',
        cursor: 'grabbing',
        border: `1px dotted ${platinum_color}`,
        borderRadius: '4px',
      }
    : { opacity: 1, background: 'transparent' };

  return (
    <div
      className={`flex justify-between items-center mt-1 ltr:ml-4 rtl:mr-4 mb-1`}
      ref={drag}
      style={dragStyles}
    >
      <GripVerticalIcon
        color={platinum_color}
        style={{
          background: 'transparent',
          cursor: isChoiceDragging ? 'grabbing' : 'pointer',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
      />

      <InputText
        name='choices'
        value={choice?.name || ''}
        style={{
          width: '100%',
          background: 'transparent',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
        disabled
      />
      <Button
        variant='outline'
        size='icon'
        className='ltr:ml-3 rtl:mr-3'
        style={{
          background: '#FFF0F0',
          color: '#FF0000',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
        onClick={() => onDeleteChoice(index)}
      >
        <XIcon className='h-4 w-4' />
      </Button>
    </div>
  );
};

export default Choice;
