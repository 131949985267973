import React from 'react';
import collapseButton from '../../../assets/images/collapseButtonRight.svg';

interface Props {
  showRightbar: boolean;
  handleRightbarAppearence: (value: boolean) => void;
}

const CollapseButton: React.FC<Props> = ({
  showRightbar,
  handleRightbarAppearence
}) => {
  return (
    <div
      onClick={() => handleRightbarAppearence(!showRightbar)}>
      <img 
        src={collapseButton} 
        alt='right-collapse' 
        className='w-[35px] h-[35px]' />
    </div>
  );
};

export default CollapseButton;