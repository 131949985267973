import React from 'react';
import Introduction from './Introduction';
import EmptyPage from '../../common/components/EmptyPage';
import FeedSetting from '../../common/pages/FacebookFeedSetting';
import SelectablPages from '../../common/components/SelectablePages';
import FacebookConnection from '../../common/components/FacebookConnection';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { CustomSwitch as Switch } from '../../common/components/CustomSwitch';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import {
  channelCreateAPiResponseType,
  facebookPageListProps,
  facebookResponseDataProps,
  selectedProjectProps
} from 'pages/integration/interface';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;
  integratedNlpList: { id: string; name: string; provider: string }[];

  //functions
  handleClose: () => void;
  setIsSideSheetOpen: (val: string) => void;
  getIntegratedNlpList: (teamId: number) => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
  fetchFacebookPagesForBusiness: (
    token: string,
    type: string
  ) => Promise<facebookPageListProps[]>;
  fetchFacebookPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<facebookPageListProps[]>;
}

const initialPageData = {
  url: '',
  name: '',
  avatar: '',
  primary_id: '',
  is_connected: false,
};

const initialSettingData = {
  url: '',
  title: '',
  primary_id: '',
  is_published: true,
  connected_nlp_integration_id: '',
};

const FacebookFeedSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  integratedNlpList,
  setIsSideSheetOpen,
  fetchFacebookPages,
  fetchFacebookPagesForBusiness,
  getIntegratedNlpList,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  //states
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selectedPage, setSelectedPage] = React.useState(initialPageData);

  const [pageList, setPageList] = React.useState<
    facebookPageListProps[] | null
  >(null);
  const [feedSettingData, setFeedSettingData] =
    React.useState(initialSettingData);
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'FacebookFeedSideSheet/node/fb-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';
  //functions

  const clearState = () => {
    setFeedSettingData(initialSettingData);
    setSelectedPage(initialPageData);
    setStep(1);
    setLoading(false);
  };

  const processFacebookResponseForBusiness = async (
    token: string
  ) => {
    setLoading(true);
    if (token !== '') {
      const data = await fetchFacebookPagesForBusiness(
        token,
        'facebook_feed'
      );
      if (!!data) {
        setStep(3);
        setPageList(data);
        setLoading(false);
        setSuccess(true);
      } else {
        clearState();
        handleClose();
        setSuccess(false);
        setErrorMessage(
          t('Something went wrong while fetching pagelist. Try again later')
        );
      }
    } else {
      setSuccess(false);
      setErrorMessage("Couldn't Connect Facebook");
    }
  };

  const processFacebookResponse = async (
    response: facebookResponseDataProps
  ) => {
    setLoading(true);
    if (response.status !== 'unknown') {
      const data = await fetchFacebookPages(
        response.id,
        response.accessToken,
        'facebook_feed'
      );
      if (!!data) {
        setStep(3);
        setPageList(data);
        setLoading(false);
        setSuccess(true);
      } else {
        clearState();
        handleClose();
        setSuccess(false);
        setErrorMessage(
          t('Something went wrong while fetching pagelist. Try again later')
        );
      }
    } else {
      setSuccess(false);
      setErrorMessage("Couldn't Connect facebook");
    }
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 3:
        setStep(4);
        break;
      case 4: {
        setLoading(true);
        let payloadData = {
          title: feedSettingData.title,
          name: !!selectedPage && selectedPage?.name,
          primary_id: !!selectedPage && selectedPage?.primary_id,
          url: selectedPage.url,
          is_published: feedSettingData.is_published,
          connected_nlp_integration_id:
            feedSettingData.connected_nlp_integration_id,
        };
        let res = await createChannelIntegrations(
          'facebook_feed',
          selectedProject.id,
          payloadData
        );
        if (res.status === 200) {
          setLoading(false);
          setIsSideSheetOpen('');
          !!callbackUrl
            ? navigate(callbackUrl)
            : navigate(`/integrations/integrated`);
        } else {
          setSuccess(false);
          setLoading(false);
          setErrorMessage(
            res.status === 500
              ? 'Something went wrong while integrating the channel. Try again later.'
              : res.data.error
          );
        }
        break;
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    const mandatoryKeys = ['title'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(feedSettingData && feedSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return true;
      case 3:
        if (!!pageList && pageList.length) {
          if (selectedPage.primary_id.length) return false;
          else return true;
        }
        return true;
      case 4:
        return checkFinishButton();
      default:
        return false;
    }
  };

  const getIsPublishedSwitch = () => {
    return (
      <div className='flex'>
        <label className='font-medium text-gray-700'>
          {t('Publish Channel')}
        </label>
        <Switch
          className={'ltr:ml-3 rtl:mr-3'}
          checked={feedSettingData?.is_published}
          onChange={() => {
            setFeedSettingData({
              ...feedSettingData,
              is_published: !feedSettingData?.is_published,
            });
          }}
        />
      </div>
    );
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      case 2:
      default:
        return {
          title: t(`Connect Facebook Feed with {{${partnerName}}}`),
        };
      case 3:
        if (!!pageList && pageList.length)
          return {
            title: 'Facebook Feed',
          };
        else
          return {
            title: 'Facebook Feed',
          };
      case 4:
        return {
          title: 'Facebook Feed Settings',
        };
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getIntegratedNlpList(selectedProject.id);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
          clearState();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={t(getHeaderInfo(step).title)}
        confirmText={step === 4 ? t('Finish') : t('Next')}
        cancelText={t('Back')}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hasLeftActionElement={true}
        leftActionElement={getIsPublishedSwitch()}
        hideLeftActionElement={step !== 4}
        hideCancel={step === 1}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <FacebookConnection
            loading={loading}
            processFacebookResponse={processFacebookResponse}
            processFacebookResponseForBusiness={processFacebookResponseForBusiness}
            type={'facebook_feed'}
          />
        )}
        {step === 3 && (
          <>
            {!!pageList && pageList.length ? (
              <SelectablPages
                pageList={pageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                platformType={'facebook_feed'}
              />
            ) : (
              <EmptyPage platformType={'facebook_feed'} />
            )}
          </>
        )}
        {step === 4 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>{t('creating channel...')}</span>
              </div>
            ) : (
              <FeedSetting
                success={success}
                errorMessage={errorMessage}
                feedSettingData={feedSettingData}
                integratedNlpList={integratedNlpList}
                handleChange={(key: string, value: string | string[]) => {
                  setFeedSettingData({
                    ...feedSettingData,
                    [key]: value,
                  });
                }}
              />
            )}
          </>
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchFacebookPagesForBusiness: (token: string, type: string) =>
    dispatch.integration.fetchFacebookPagesForBusiness({ token, type }),
  fetchFacebookPages: (id: string, token: string, type: string) =>
    dispatch.integration.fetchFacebookPages({ id, token, type }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
});

export default connect(mapState, mapDispatch)(FacebookFeedSideSheet);
