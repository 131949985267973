import { classNames } from 'utilities/utils';
import { ICustomEmailSettings } from 'pages/integration/interface';
import TextInputWithSubLabel from 'pages/integration/components/common/components/TextInputWithSubLabel';
import {
  IDataObject,
  useEmailSettings,
} from 'pages/integration/hooks/useCustomEmail';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  stepName: string;
  isEditing?: boolean;
  onlyEmailPassword?: boolean;
  hasSameCredentials?: boolean;
  settings: ICustomEmailSettings;
  handleDataChange: (data: IDataObject) => void;
  updateSmtpToImapCredentials?: (shouldChange: boolean) => void;
}

const ContentLabels = {
  smtp: {
    host: {
      title: 'SMTP Host',
      subTitle:
        'Enter the address of the SMTP server that handles your emails.',
    },
    port: {
      title: 'SMTP Port',
      subTitle: 'Enter the port your SMTP server uses for outgoing emails.',
    },
    security: {
      title: 'SMTP Security',
      subTitle: '',
    },
    username: {
      title: 'SMTP Email',
      subTitle: '',
    },
    password: {
      title: 'SMTP Password',
      subTitle: '',
    },
  },
  imap: {
    host: {
      title: 'IMAP Host',
      subTitle:
        'Enter the address of the IMAP server that handles your emails.',
    },
    port: {
      title: 'IMAP Port',
      subTitle: 'Enter the port your IMAP server uses for outgoing emails.',
    },
    security: {
      title: 'IMAP Security',
      subTitle: '',
    },
    username: {
      title: 'IMAP Email',
      subTitle: '',
    },
    password: {
      title: 'IMAP Password',
      subTitle: '',
    },
  },
};

//TODO: need to be checked when api available
const securitylist = [
  {
    id: 'none',
    title: 'None',
  },
  {
    id: 'ssl',
    title: 'SSL',
  },
  {
    id: 'tls',
    title: 'TLS',
  },
  {
    id: 'starttls',
    title: 'STARTTLS',
  },
];

const EmailSetup: React.FC<Props> = ({
  stepName,
  settings,
  handleDataChange,
  isEditing = false,
  onlyEmailPassword = false,
  hasSameCredentials = false,
  updateSmtpToImapCredentials = (isChecked) => {},
}) => {
  const { t } = useTranslation();
  const { isValidDomain } = useEmailSettings();
  const getContentLabels = () => {
    return ContentLabels[stepName.toLowerCase()] || ContentLabels['imap'];
  };

  const renderRadioGroup = (list: any[], radioFor: string) => {
    return list.map((data) => (
      <div key={data.id} className='flex items-center'>
        <input
          type='radio'
          id={data.id}
          name={radioFor}
          value={data.id}
          checked={data.id.toLowerCase() === settings.security.toLowerCase()}
          className='without-ring h-4 w-4 text-green-600 border-gray-300'
          onChange={(e) => {
            handleDataChange({ security: e.currentTarget.value.toLowerCase() });
          }}
        />
        <label
          htmlFor={data.id}
          className='ltr:ml-1.5 rtl:mr-1.5 block text-sm font-medium text-gray-500'
        >
          {t(data.title)}
        </label>
      </div>
    ));
  };

  return (
    <div>
      {!onlyEmailPassword && (
        <>
          <TextInputWithSubLabel
            type='text'
            value={settings.host}
            placeHolder={`${stepName}.server-address.com`}
            label={getContentLabels().host.title}
            sublabel={getContentLabels().host.subTitle}
            handleChange={(data) => handleDataChange({ host: data })}
            error={
              settings.host !== '' && !isValidDomain(settings.host)
                ? t('Please enter a valid host URL.')
                : ''
            }
            disable={isEditing}
          />
          <span className=' block my-5' />
          <TextInputWithSubLabel
            type='text'
            value={settings.port}
            label={getContentLabels().port.title}
            placeHolder='Example: 25, 993, 465, 587'
            sublabel={getContentLabels().port.subTitle}
            handleChange={(data) => handleDataChange({ port: data })}
            error={
              isNaN(settings.port) || settings.port > 999
                ? t('Please enter a valid port number.')
                : ''
            }
          />
          <span className=' block my-5' />

          <label className='text-sm flex font-medium leading-5 text-gray-600'>
            {t(getContentLabels().security.title)}
          </label>
          <div className='space-y-2 sm:flex sm:items-center gap-4 sm:space-y-0 mt-2'>
            {renderRadioGroup(securitylist, `{${stepName}-security}`)}
          </div>
        </>
      )}

      <div
        className={classNames(
          'relative',
          stepName === 'smtp'
            ? 'p-3 border border-gray-300 rounded-md mt-6 mb-3'
            : 'my-5'
        )}
      >
        {stepName === 'smtp' && (
          <div className='absolute ltr:right-3 rtl:left-3 top-[-18px] px-3 py-2 bg-white rounded-md flex gap-2 justify-center items-center'>
            <input
              id='checkbox-1'
              type='checkbox'
              checked={hasSameCredentials}
              onChange={(event) => {
                updateSmtpToImapCredentials(event.target.checked);
              }}
              className='w-4 h-4 border-gray-300 rounded outline-none focus:ring-0 ring-offset-0 text-primary rtl:order-1'
            />
            <span className='text-sm font-medium text-gray-600'>
              {t('Same as IMAP')}
            </span>
          </div>
        )}
        <TextInputWithSubLabel
          type='text'
          value={settings.username}
          placeHolder={`Enter your ${stepName.toUpperCase()} email`}
          label={getContentLabels().username.title}
          sublabel={getContentLabels().username.subTitle}
          handleChange={(data) => handleDataChange({ username: data })}
          disable={isEditing}
        />
        <span className=' block my-5' />
        <TextInputWithSubLabel
          type='password'
          value={settings.password}
          placeHolder={`Enter your ${stepName.toUpperCase()} password`}
          label={getContentLabels().password.title}
          sublabel={getContentLabels().password.subTitle}
          handleChange={(data) => handleDataChange({ password: data })}
        />
      </div>
    </div>
  );
};
export default EmailSetup;
