interface Props {
  className?: string;
  color?: string;
}
export const EmailIcon: React.FC<Props> = ({
  className = 'w-5 h-5',
  color = '#9CA3AF',
}) => {
  return (
    <svg
      width='14'
      height='10'
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.602498 1.70721L7.0001 4.90561L13.3977 1.70721C13.374 1.29957 13.1954 0.916402 12.8984 0.636194C12.6013 0.355985 12.2084 0.199937 11.8001 0.200012H2.2001C1.79177 0.199937 1.39886 0.355985 1.10184 0.636194C0.804824 0.916402 0.626181 1.29957 0.602498 1.70721Z'
        fill={color}
      />
      <path
        d='M13.4001 3.49441L7.0001 6.69441L0.600098 3.49441V8.20001C0.600098 8.62436 0.768668 9.03132 1.06873 9.33138C1.36878 9.63144 1.77575 9.80001 2.2001 9.80001H11.8001C12.2244 9.80001 12.6314 9.63144 12.9315 9.33138C13.2315 9.03132 13.4001 8.62436 13.4001 8.20001V3.49441Z'
        fill={color}
      />
    </svg>
  );
};
