import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import { toast } from 'libraryV2/ui/use-toast';
import config from '../../../../utilities/config';
import axios from '../../../../utilities/httpClient';

const initialState = {
  limit: 10,
  offset: 0,
  total: 0,
  webhookList: [],
  currentWebhookAction: 'CREATE',
  searchQuery: '',
};

export const settingsWebhook = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateWebhookList(state, payload) {
      return { ...state, webhookList: payload };
    },
    updateLimit(state, payload) {
      return { ...state, limit: payload };
    },
    updateOffset(state, payload) {
      return { ...state, offset: payload };
    },
    updateTotal(state, payload) {
      return { ...state, total: payload };
    },
    updateSearchQuery(state, payload) {
      return { ...state, searchQuery: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchWebhooks(payload) {
      try {
        const res = await axios.get(
          config.settings.getWebhookList(
            payload.teamId,
            payload.limit,
            payload.offset
          )
        );
        if (res.status === 200) {
          dispatch.settingsWebhook.updateWebhookList(res.data.dataSource);
          dispatch.settingsWebhook.updateTotal(
            res.data.total ? res.data.total : res.data.dataSource.length
          );
          dispatch.settingsWebhook.updateLimit(payload.limit);
          dispatch.settingsWebhook.updateOffset(payload.offset);
        }
        return {
          status: true,
          data: res.data,
        };
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>
              <TranslationWrapper text=' Unable to fetch Webhook data' />
            </p>
          ),
          description: (
            <TranslationWrapper text='Something went wrong while fetching webhook data. Please try again' />
          ),
        });
        return {
          status: false,
          res: null,
        };
      }
    },

    async searchWebhookList(payload) {
      try {
        const res = await axios.get(
          config.settings.searchWebhook(
            payload.teamId,
            payload.offset,
            payload.limit,
            payload.search
          )
        );
        if (res.status === 200) {
          dispatch.settingsWebhook.updateTotal(
            res.data.total ? res.data.total : res.data.dataSource.length
          );
          dispatch.settingsWebhook.updateLimit(payload.limit);
          dispatch.settingsWebhook.updateOffset(payload.offset);
          return {
            status: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>
              <TranslationWrapper text=' Unable to fetch Webhook data' />
            </p>
          ),
          description: (
            <TranslationWrapper text='Something went wrong while fetching webhook data. Please try again' />
          ),
        });
        return {
          status: false,
          res: null,
        };
      }
    },

    async createWebhook(payload, rootState) {
      try {
        const res = await axios.post(
          config.settings.createWebhook(payload.projectId),
          payload.data
        );
        // insert newly created webhook to state
        dispatch.settingsWebhook.updateWebhookList([
          res?.data?.dataSource,
          ...rootState?.settingsWebhook?.webhookList,
        ]);
        dispatch.settingsWebhook.updateTotal(
          rootState?.settingsWebhook?.total + 1
        );
        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },

    async editWebhook(payload, rootState) {
      try {
        const res = await axios.patch(
          config.settings.deleteOrEditWebhook(
            payload.projectId,
            payload.webhookId
          ),
          payload.data
        );
        if (res.data.success) {
          dispatch.settingsWebhook.updateWebhookList([
            ...rootState?.settingsWebhook?.webhookList?.map((r) =>
              r.id === payload.webhookId ? res.data.dataSource : r
            ),
          ]);

          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },

    async deleteWebhook(payload, rootState) {
      try {
        const res = await axios.delete(
          config.settings.deleteOrEditWebhook(
            payload.projectId,
            payload.webhookId
          )
        );
        if (res.data.success) {
          // update total count
          dispatch.settingsWebhook.updateTotal(
            rootState?.settingsWebhook?.total - 1
          );
          return {
            success: true,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },

    async bulkDeleteWebhook(payload, rootState) {
      const selectedWebhooks = payload?.selectedWebhooks || [];
      try {
        const res = await axios.delete(
          config.settings.bulkDeleteWebhook(payload.projectId),
          {
            data: {
              ids: selectedWebhooks,
            },
          }
        );
        dispatch.settingsWebhook.updateWebhookList([
          ...rootState?.settingsWebhook?.webhookList?.filter(
            (r) => !selectedWebhooks.includes(r.id)
          ),
        ]);
        // update total count
        dispatch.settingsWebhook.updateTotal(
          rootState?.settingsWebhook?.total - selectedWebhooks.length
        );

        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
  }),
};

export default settingsWebhook;
