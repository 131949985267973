import React from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ConversationBlockAttachmentDataInterface,
  ConversationInterface,
  CustomerReplyBlockInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import downloadIcon from '../../../assets/images/downloadIcon.svg';
import fileIcon from '../../../assets/images/fileIcon.svg';
import Avatar from '../components/conversation/Avatar';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';
import AdminReplyToButton from './AdminReplyToButton';
import CustomerReply from './CustomerReply';
import DateBar from './DateBar';

interface Props {
  rawBlockData: ConversationInterface;
  blockData: ConversationBlockAttachmentDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
  hasPermissionToShowAdminReply: boolean;
  handleConversationDataForAdminReply: (
    blockData: CustomerReplyBlockInterface
  ) => void;
}

const FileAttachment: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
  rawBlockData,
  hasPermissionToShowAdminReply,
  handleConversationDataForAdminReply,
}) => {
  const { t } = useTranslation();
  const { rowDirection, elementDirection, isZigzagView } =
    useMessageBlocksAction({ source });

  const [showReplyToButton, setShowReplyToButton] = React.useState(false);

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderFileAttachmentView = () => {
    return (
      <div
        className={`flex h-10 rounded-xl  ${
          source !== 'customer' &&
          (report?.result?.reply_to_message || blockData?.reply_to_data) &&
          'pr-[72px]'
        } ${
          source === 'customer' &&
          (report?.result?.reply_to_message || blockData?.reply_to_data) &&
          'pr-14'
        } ${
          (report?.result?.reply_to_message || blockData?.reply_to_data) &&
          'z-10 border bg-[#FAFAFA]'
        }`}
      >
        <div className='content-center w-8 h-auto'>
          <img src={fileIcon} alt='fileIcon' />
        </div>
        <a
          target='_blank'
          rel='noreferrer'
          href={blockData?.urls[0]}
          className='content-center w-auto h-auto px-2 ltr:pl-0 rtl:pr-0'
        >
          <p className='font-normal text-sm ltr:mr-2.5 rtl:ml-2.5'>
            {t('Download Attached File')}
          </p>
          <img src={downloadIcon} alt='downloadIcon' />
        </a>
      </div>
    );
  };

  const renderCustomerReply = () => {
    return (
      blockData?.reply_to_data && (
        <CustomerReply
          blockData={blockData?.reply_to_data}
          isZigzagView={isZigzagView}
          source={source}
        />
      )
    );
  };

  const renderContent = () => {
    return (
      <ConversationBlockWrapper
        time={time}
        success={isSent}
        isMerged={false}
        report={report}
        source={source}
        isFirstElement={!isMergeable}
        conversationType={blockData?.sub_type}
        replay={blockData?.reply_to_data ? true : false}
      >
        <>
          {!!blockData.reply_to_data && renderCustomerReply()}
          {renderFileAttachmentView()}
        </>
      </ConversationBlockWrapper>
    );
  };
  return (
    <>
      <div
        className='relative'
        onMouseEnter={() => setShowReplyToButton(true)}
        onMouseLeave={() => setShowReplyToButton(false)}
      >
        {!isMergeable && (
          <div
            className={`flex w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9] ${rowDirection}`}
          >
            <Avatar avatar={avatar} />
            <div>
              <div className={`flex gap-2 ${rowDirection}`}>
                <div
                  className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
                >
                  {t(name)}
                </div>
              </div>
              {renderContent()}
            </div>
          </div>
        )}
        {!!isMergeable && (
          <div
            className={`relative flex w-full h-auto py-1 px-16 group hover:bg-gray-50 ${elementDirection}`}
          >
            {renderContent()}
          </div>
        )}
        {showReplyToButton && hasPermissionToShowAdminReply && (
          <AdminReplyToButton
            source={source}
            handleConversationDataForAdminReplyOnClick={() =>
              handleConversationDataForAdminReply({
                type: rawBlockData?.dataV2?.type,
                success: rawBlockData?.dataV2?.success,
                error: rawBlockData?.dataV2?.error,
                sub_type: rawBlockData?.dataV2?.sub_type,
                text: '',
                urls: rawBlockData?.dataV2?.urls || [],
                payload: rawBlockData?.conversation_id,
              })
            }
          />
        )}
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default FileAttachment;
