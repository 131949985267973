import { FC, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  Label,
  Input,
  Button,
  Textarea,
  Command,
  Checkbox,
  navigate,
  CommandItem,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  DialogTitle,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DropdownField,
  GLOBAL_UTILS,
  LOCAL_UTILS,
  useSLACreation,
  LayoutGridIcon,
  TextWithTooltip,
  DialogDescription,
} from '../../export';
import { ISLAData } from '../../interface';

interface Props {
  isOpened?: boolean;
  slaData: ISLAData;
}

interface ChannelSelectionListProps {
  slaData: any;
  platformList: any[];
  selectedPlatformList: Array<string | number>;
  onChannelSelect: (id: number | string) => void;
}

interface NoChannelFoundProps {
  handleGoToIntegrationButtonClick: () => void;
}

const SLAEdit: FC<Props> = ({ slaData, isOpened = false }) => {
  const [formState, setFormState] = useState<ISLAData>(slaData);
  const {
    platformList,
    isUpdatingLoading,
    handleSLAUpdateSubmitButton,
    isEditConfirmationButtonDisabled,
    handleGoToIntegrationButtonClick,
  } = useSLACreation();

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    nameRef.current?.focus();
  }, []);

  const handleInputChange = useCallback(
    (key: keyof ISLAData) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState((prevState) => ({
          ...prevState,
          [key]: event.target.value,
        }));
      },
    []
  );

  const handleOnChannelSelect = useCallback((id: number | string) => {
    setFormState((prevState) => {
      const isSelected = prevState.platforms.includes(id);
      const updatedPlatforms = isSelected
        ? prevState.platforms.filter((platformId) => platformId !== id)
        : [...prevState.platforms, id];
      return { ...prevState, platforms: updatedPlatforms };
    });
  }, []);

  const handleSubmit = async () => {
    const updatedData = {
      ...slaData,
      name: formState.name,
      description: formState.description,
      platforms: formState.platforms,
    };
    const res = await handleSLAUpdateSubmitButton(updatedData);
    if (res.status) {
      // Handle success
    }
  };

  const isSubmitDisabled = useMemo(
    () =>
      isUpdatingLoading || isEditConfirmationButtonDisabled(formState, slaData),
    [isUpdatingLoading, formState, slaData, isEditConfirmationButtonDisabled]
  );

  return (
    <>
      <DialogHeader>
        <DialogTitle>Edit Basic Info</DialogTitle>
        <DialogDescription className='subtitle-regular'>
          Update your SLA details below.
        </DialogDescription>
      </DialogHeader>
      <div className='grid gap-4 py-4'>
        <div className='items-center'>
          <Label htmlFor='name' className='text-left label-primary'>
            Name <span className='error'>*</span>
          </Label>
          <Input
            id='name'
            ref={nameRef}
            required
            value={formState.name}
            placeholder='Enter SLA name'
            className='col-span-3'
            maxLength={64}
            onChange={handleInputChange('name')}
          />
        </div>
        <div className='items-center'>
          <Label htmlFor='description' className='text-left label-primary'>
            Descriptions
          </Label>
          <Textarea
            id='description'
            ref={descriptionRef}
            placeholder='Enter descriptions'
            className='col-span-3'
            value={formState.description}
            onChange={handleInputChange('description')}
          />
        </div>
        <div className='flex flex-col'>
          <Label htmlFor='channels' className='text-left label-primary mb-1.5'>
            Channels
          </Label>
          <DropdownField
            value={LOCAL_UTILS.getPlatformNameList(
              formState.platforms,
              platformList
            )}
            childrenName='channels'
            popOverContentClass='w-[23.5rem]'
          >
            {platformList.length === 0 ? (
              <NoChannelFound
                handleGoToIntegrationButtonClick={
                  handleGoToIntegrationButtonClick
                }
              />
            ) : (
              <ChannelSelectionList
                slaData={slaData}
                platformList={platformList}
                selectedPlatformList={formState.platforms}
                onChannelSelect={handleOnChannelSelect}
              />
            )}
          </DropdownField>
          <span className='subtitle-regular mt-1.5'>
            Select the channels where tickets will be generated for this SLA.
          </span>
        </div>
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button type='button' variant='outline' className='cursor-pointer'>
            Cancel
          </Button>
        </DialogClose>
        <Button
          type='submit'
          isLoading={isUpdatingLoading}
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          className={
            isUpdatingLoading || formState.name.length === 0
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          }
        >
          {isUpdatingLoading ? 'Updating' : 'Save Changes'}
        </Button>
      </DialogFooter>
    </>
  );
};

const NoChannelFound: FC<NoChannelFoundProps> = ({
  handleGoToIntegrationButtonClick,
}) => (
  <Command className='flex flex-col items-center justify-center p-6'>
    <label className='subtitle-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
      <span className='inline-flex justify-center w-full text-center'>
        No channels are available. Please integrate any channel to create SLA
        policy.
      </span>
    </label>
    <Button
      variant='outline'
      size='sm'
      className='mt-4'
      onClick={() => {
        navigate('/integrations/available-integrations');
        handleGoToIntegrationButtonClick();
      }}
    >
      <LayoutGridIcon className='h-[14px] w-[14px]' />
      Go to Integration
    </Button>
  </Command>
);

const ChannelSelectionList: FC<ChannelSelectionListProps> = ({
  slaData,
  platformList,
  onChannelSelect,
  selectedPlatformList,
}) => {
  // Separate selected, enabled, and disabled platforms
  const selectedPlatforms = platformList.filter((platform: any) =>
    selectedPlatformList.includes(platform.id)
  );
  const enabledPlatforms = platformList.filter(
    (platform: any) =>
      !selectedPlatformList.includes(platform.id) &&
      !(
        platform.is_sla_connected && !selectedPlatformList.includes(platform.id)
      )
  );
  const disabledPlatforms = platformList.filter(
    (platform: any) =>
      platform.is_sla_connected && !selectedPlatformList.includes(platform.id)
  );

  // Combine selected first, then enabled, and finally disabled
  const sortedPlatformList = [
    ...selectedPlatforms,
    ...enabledPlatforms,
    ...disabledPlatforms,
  ];

  return (
    <Command>
      <CommandInput placeholder='Search channels' />
      <CommandList>
        <CommandEmpty>No channel found.</CommandEmpty>
        <CommandGroup>
          {sortedPlatformList.map((platform: any, index: number) => (
            <CommandItem
              key={platform.id}
              value={platform.title}
              className='w-[23.5rem]'
            >
              <div className='flex items-center space-x-2 w-[23.5rem]'>
                <Checkbox
                  id={platform.title}
                  checked={selectedPlatformList.includes(platform.id)}
                  disabled={
                    platform.is_sla_connected &&
                    !selectedPlatformList.includes(platform.id)
                  }
                  className={`flex-shrink-0 w-[4.5%] ${
                    selectedPlatformList.includes(platform.id)
                      ? 'bg-primary text-white border-transparent'
                      : ''
                  }`}
                  onCheckedChange={() => onChannelSelect(platform.id)} // Toggling checkbox on click
                />
                <img
                  alt='platform'
                  className='flex-shrink-0 w-5 h-5'
                  src={GLOBAL_UTILS.channelInfo(platform.platform_type).image}
                />
                <div className='flex items-center w-[80%] space-x-2'>
                  <label
                    htmlFor={platform.title}
                    className={`flex-grow h-5 text-sm flex items-center font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                      platform.is_sla_connected &&
                      platform.sla_policy?.id !== slaData?.id
                        ? 'text-textPrimary-disable'
                        : 'text-textPrimary'
                    }`}
                    style={{ width: platform.is_sla_connected ? '48%' : '60%' }}
                  >
                    <TextWithTooltip
                      text={platform.title}
                      dataFor={platform.title}
                      dataTip={platform.title}
                      shouldShowTooltip={platform.title.length > 37}
                      className='truncate'
                    />
                  </label>
                  {platform.is_sla_connected &&
                    platform.sla_policy?.id !== slaData?.id && (
                      <label
                        htmlFor={'policy_name' + index}
                        className={`w-1/2 text-sm flex h-5 items-center font-normal leading-none truncate cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70`}
                      >
                        <TextWithTooltip
                          text={`Connected ${
                            platform?.sla_policy?.name
                              ? 'to ' + platform.sla_policy.name
                              : ''
                          }`}
                          dataFor={platform?.sla_policy?.name}
                          dataTip={platform?.sla_policy?.name}
                          className='truncate text-textPrimary-disable'
                        />
                      </label>
                    )}
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default SLAEdit;
