import React from 'react';
import { connect } from 'react-redux';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import ImportDropDown from './ImportDropDown';
import {
  BroadcastAttribute,
  BroadcastCreationProperty,
  CSVProperty,
  MappedColumnData,
} from '../interface';
import { SelectedTeamInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  attributes: BroadcastAttribute | null;
  setAttributes: (data: BroadcastAttribute) => void;
  broadcastmappedData: CSVProperty;
  setMappedData: (payload: MappedColumnData[]) => void;
  mappedData: MappedColumnData[] | null;
  broadcastCreationData: BroadcastCreationProperty;
  fetchAttribute: (payload: AttributePayload) => {
    success: boolean;
    data: any;
  };
  selectedProject: SelectedTeamInterface;
  isFileReupload: boolean;
  setIsFileReupload: (payload: boolean) => void;
}

interface RenderSampleData {
  column_name: string;
  mapped_attribute: string;
  example_data: string;
  is_overwritten: boolean;
}

interface AttributePayload {
  projectId: number;
  data: {
    channel_id: number;
    template_id: string;
  };
}

const MapBroadcastData: React.FC<Props> = ({
  attributes,
  setAttributes,
  broadcastmappedData,
  setMappedData,
  mappedData,
  broadcastCreationData,
  fetchAttribute,
  selectedProject,
  isFileReupload,
  setIsFileReupload,
}) => {
  const { t } = useTranslation();
  const [mapRenderData, setMapRenderData] = React.useState<
    RenderSampleData[] | null
  >(null);

  const getAttributes = async () => {
    const payload = {
      projectId: selectedProject.id,
      data: {
        channel_id: broadcastCreationData.channel_details.id,
        template_id: broadcastCreationData.template_id,
      },
    };
    const res = await fetchAttribute(payload);
    if (res.success) {
      let essentialAttribute = res.data.essential;
      let otherAttribute = res.data.others;

      // filter out common elements between essential and other attributes from other attributes
      otherAttribute =
        otherAttribute.length > 0
          ? otherAttribute.filter(
              (attribute: { name: string; value: string }) => {
                return !essentialAttribute.find(
                  (item: { name: string; value: string }) => {
                    return item.value === attribute.value;
                  }
                );
              }
            )
          : [];

      setAttributes({
        essential: essentialAttribute,
        others: otherAttribute,
      });
    }
  };

  React.useEffect(() => {
    rederRow();
    getAttributes();
    // eslint-disable-next-line
  }, []);

  const rederRow = () => {
    let data = [];
    let renderData = [];

    if (
      broadcastCreationData.mapped_column_data.length > 0 &&
      !isFileReupload
    ) {
      setMapRenderData(broadcastCreationData.mapped_column_data);
      setMappedData(broadcastCreationData.mapped_column_data);
      setIsFileReupload(false);
      return;
    }

    if (broadcastmappedData && broadcastmappedData.first_audience_info) {
      for (let i = 0; i < broadcastmappedData.first_audience_info.length; i++) {
        data.push({
          column_name: broadcastmappedData.provided_fields[i],
          mapped_attribute: 'not_mapped',
          is_overwritten: true,
        });
      }

      for (let i = 0; i < broadcastmappedData.first_audience_info.length; i++) {
        renderData.push({
          column_name: broadcastmappedData.provided_fields[i],
          mapped_attribute: broadcastmappedData.first_audience_info[i],
          example_data: broadcastmappedData.first_audience_info[i],
          is_overwritten: true,
        });
      }
      setMappedData(data);
      setMapRenderData(renderData);
    }
  };

  const renderIsOverWritten = (value: boolean, name: string) => {
    return (
      <div className='relative flex items-start'>
        <div className='flex h-5 items-center'>
          <input
            id={name}
            aria-describedby='comments-description'
            name='comments'
            type='checkbox'
            className='h-4 w-4 rounded border-gray-300 text-[#04B25F] focus:ring-green-500'
            checked={value}
            onChange={(e) => {
              let newRendervalue =
                mapRenderData &&
                mapRenderData.map((item) => {
                  if (item.column_name === name) {
                    return { ...item, is_overwritten: e.target.checked };
                  }
                  return item;
                });

              let newMappedValue =
                mappedData &&
                mappedData.map((item) => {
                  if (item.column_name === name) {
                    return { ...item, is_overwritten: e.target.checked };
                  }
                  return item;
                });
              // @ts-ignore
              setMapRenderData([...newRendervalue]);
              // @ts-ignore
              setMappedData([...newMappedValue]);
            }}
          />
        </div>
        <div className='ltr:ml-3 rtl:mr-3 text-sm'>
          <label htmlFor={name} className='text-gray-500'>
            {t('Update existing contact')}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div className='border border-gray-200 rounded-md mb-4 max-h-[250px] overflow-auto w-full'>
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className='bg-gray-50 sticky top-0 z-10'>
          <tr>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t('Map')}
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t('Column name')}
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t('Preview EXAMPLE')}
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t('Import As')}
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t('Manage existing value')}
            </th>
          </tr>
        </thead>
        {mappedData && mapRenderData && mapRenderData.length > 0 && (
          <tbody className='bg-white divide-y divide-gray-200 w-full mt-[50px]'>
            {mapRenderData &&
              mapRenderData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className='px-6 py-1.5 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right capitalize'>
                      {mappedData[index].mapped_attribute !== 'not_mapped' && (
                        <CheckCircleIcon className='w-5 h-5 text-[#04A056]' />
                      )}
                      {mappedData[index].mapped_attribute === 'not_mapped' && (
                        <ExclamationTriangleIcon className='w-5 h-5 text-red-500' />
                      )}
                    </td>
                    <td className='px-6 py-1.5 text-sm leading-5 text-gray-900 whitespace-nowrap rtl:text-right '>
                      <div className='truncate overflow-hidden w-[170px]'>
                        {item.column_name}
                      </div>
                    </td>
                    <td className='px-6 py-1.5 text-sm text-gray-400 font-normal whitespace-nowrap rtl:text-right'>
                      <div className='truncate overflow-hidden w-[170px]'>
                        {item.example_data}
                      </div>
                    </td>
                    <td className='px-6 py-1.5 text-sm text-gray-400 whitespace-nowrap rtl:text-right capitalize'>
                      <ImportDropDown
                        setMappedData={setMappedData}
                        value={mappedData && mappedData[index].mapped_attribute}
                        columnName={item.column_name}
                        mappedData={mappedData}
                        id={index}
                        attributes={attributes}
                      />
                    </td>
                    <td className='px-6 py-1.5 text-sm text-gray-500 whitespace-nowrap rtl:text-right'>
                      {renderIsOverWritten(
                        item.is_overwritten,
                        item.column_name
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
};

const mapState = (state: any) => ({
  broadcastmappedData: state.broadcast.mappedData,
  broadcastCreationData: state.broadcast.broadcastCreationData,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchAttribute: (payload: AttributePayload) =>
    dispatch.broadcast.whatsappAttributeList(payload),
});

export default connect(mapState, mapDispatch)(MapBroadcastData);
