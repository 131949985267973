import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import defaultAvatar from 'assets/images/defaultAvatar.svg';

export interface ProjectCardShopifyProps {
  id?: number;
  name: string;
  description: string;
  image: string;
}

const ProjectCardShopify: React.FC<ProjectCardShopifyProps> = (plan) => {
  return (
    <RadioGroup.Option
      value={plan}
      className={({ checked, active }) =>
        classNames(
          checked ? 'border-transparent' : 'border-gray-300',
          active ? 'ring-2 ring-primary' : '',
          'relative block bg-white border rounded-lg shadow-sm pl-4 pr-12 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
        )
      }
    >
      {({ active, checked }) => (
        <>
          <div className='flex items-center'>
            <div className='text-sm'>
              <RadioGroup.Label as='div'>
                <div className='flex items-center space-x-3'>
                  <span className='relative inline-block'>
                    <img
                      className='w-8 h-8 rounded-full'
                      src={plan?.image || defaultAvatar}
                      alt=''
                    />
                  </span>

                  <p className='font-sans text-base font-medium text-gray-900'>
                    {plan.name}
                  </p>
                </div>
              </RadioGroup.Label>
            </div>
          </div>
          <div
            className={classNames(
              active ? 'border' : 'border-2',
              checked ? 'border-primary' : 'border-transparent',
              'absolute -inset-px rounded-lg pointer-events-none'
            )}
            aria-hidden='true'
          />
        </>
      )}
    </RadioGroup.Option>
  );
};

export default ProjectCardShopify;
