import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { toast } from 'libraryV2/ui/use-toast';
import { useState } from 'react';
import useWebhooks from '../hooks/useWebhooks';
import { getModalContents, initialWebhookState } from '../utils';
import { WebhookForm } from './WebhookForm';

export type PlatformType = {
  id: string;
  platform_type: string;
  title: string;
};

export type DatalabType = {
  id: string;
  title: string;
};

export interface IWebhookData {
  id: string;
  endpoint: string;
  event: string;
  filter_resource_id: number[];
  filter_resource_type: 'platform' | 'datalab';
  platforms: PlatformType[];
  datalabs: DatalabType[];
  isActive: boolean;
  projectId: number;
  last_used_at: string;
}

export interface IWebhookColumnData extends IWebhookData {
  sources: PlatformType[] | DatalabType[];
}

interface WebhookModalProps {
  actionType: 'CREATE' | 'EDIT';
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  data?: IWebhookData;
}

const WebhookModal = ({
  actionType,
  isOpen,
  setIsOpen,
  data,
}: WebhookModalProps) => {
  const { t } = useTranslation();
  const [webhookData, setWebhookData] = useState<IWebhookData>(
    data?.endpoint ? data : (initialWebhookState as unknown as IWebhookData)
  );
  const [error, setError] = useState<string | null>(null);
  const {
    webhookModalAction,
    createNewWebhook,
    editWebhook,
    actionLoading,
    updateSearchQuery,
  } = useWebhooks();

  const ModalContents = getModalContents(webhookModalAction);

  const handleCreateWebhook = () => {
    // reset the search query to show all the options
    updateSearchQuery('');

    createNewWebhook(webhookData)
      .then((res) => {
        if (res?.success) {
          setIsOpen(false);
          setWebhookData(initialWebhookState as unknown as IWebhookData);
          toast({
            duration: 3 * 1000,
            // @ts-expect-error
            title: (
              <p className='text-green-500'>
                {t('Webhook Created Successfully')}
              </p>
            ),
            description: t('Your new webhook is now active and ready to use.'),
          });
        }
      })
      .catch(() => {
        toast({
          duration: 3 * 1000,
          // @ts-expect-error
          title: (
            <p className='text-red-500'>{t('Unable to create Webhook')}</p>
          ),
          description: t(
            'Something went wrong while creating webhook. Please try again'
          ),
        });
      });
  };

  const handleEditWebhook = () => {
    // reset the search query to show all the options
    updateSearchQuery('');

    editWebhook(webhookData).then((res) => {
      if (res?.success) {
        setIsOpen(false);
      }
    });
  };

  const onPrimaryAction = () => {
    switch (webhookModalAction) {
      case 'CREATE': {
        handleCreateWebhook();
        return;
      }
      case 'EDIT': {
        handleEditWebhook();
        return;
      }

      default:
        return;
    }
  };

  const disableHandle = (): boolean => {
    if (actionLoading) {
      return true;
    }

    const hasEmptyFields =
      webhookData.event.length < 1 ||
      webhookData.endpoint.length < 1 ||
      webhookData.filter_resource_id.length < 1 ||
      !!error;

    if (actionType === 'EDIT') {
      return (
        hasEmptyFields ||
        (data?.endpoint === webhookData.endpoint &&
          data?.event === webhookData.event &&
          data?.filter_resource_id === webhookData.filter_resource_id &&
          !error)
      );
    } else {
      return hasEmptyFields;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        setWebhookData(initialWebhookState as unknown as IWebhookData);
      }}
    >
      <DialogContent
        className='grid-cols-1 bg-white max-w-[30rem] '
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className='rtl:text-right'>
            {t(ModalContents.title)}
          </DialogTitle>
          <DialogDescription className='text-textSecondary rtl:text-right'>
            {t(ModalContents.description)}
          </DialogDescription>
        </DialogHeader>
        <WebhookForm
          webhookData={webhookData}
          setWebhookData={setWebhookData}
          error={error}
          setError={setError}
        />
        <DialogFooter className='rtl:gap-2 rtl:justify-start'>
          <DialogClose>
            <Button variant='outline' type='button' className='shadow-none'>
              {t(ModalContents.secondaryActionText)}
            </Button>
          </DialogClose>
          <Button
            className='disabled:bg-zinc-100 disabled:text-zinc-400 disabled:opacity-100 disabled:shadow-none disabled:cursor-not-allowed bg-[#04B25F]'
            onClick={onPrimaryAction}
            disabled={disableHandle()}
          >
            {actionLoading
              ? t('Please Wait...')
              : t(ModalContents.primaryActionText)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WebhookModal;
