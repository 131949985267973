import React from 'react';

import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../utilities/utils';

interface Props {
  id: number;
  value: object;
  title: string;
}

const EcommerceInputParameterList: React.FC<Props> = ({ id, value, title }) => {
  return (
    <Listbox.Option
      key={id}
      className={({ active }) =>
        classNames(
          active ? 'bg-gray-200' : 'text-gray-900',
          'cursor-default select-none relative py-3 pl-3 pr-9'
        )
      }
      value={value}
    >
      {({ selected, active }) => (
        <>
          <span
            className={classNames(
              selected ? 'font-semibold' : 'font-normal',
              'block truncate'
            )}
          >
            {title}
          </span>
          {selected ? (
            <span
              className={classNames(
                active ? 'text-gray-900' : 'text-gray-900',
                'absolute inset-y-0 right-0 flex items-center pr-4'
              )}
            >
              <CheckIcon className='w-5 h-5' aria-hidden='true' />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

export default EcommerceInputParameterList;
