import { Button } from 'libraryV2/ui/button';
import { useState, forwardRef, useEffect, useCallback, useMemo } from 'react';
import { Base85Conversion } from '../utils/base85encoder';
import { AutosizeTextarea } from 'libraryV2/ui/autosize-textarea';
import { ExternalLink } from 'lucide-react';
import JinjaCodeEditorModal from './JinjaCodeModal';
import { replaceBrackets } from '../utils/functions';

interface Props {
  title: string;
  value: any;
  sampleDataFormat: Record<string, any>;
  sampleDataLoading: boolean;
  children: React.ReactElement;
  onChange: (value: any) => void;
}

const JinjaVariableEditorFieldWithModal = forwardRef<HTMLDivElement, Props>(
  (
    { title, onChange, value, children, sampleDataFormat, sampleDataLoading },
    ref
  ) => {
    const [isCode, setIsCode] = useState(!children);

    useEffect(() => {
      if (Base85Conversion.isBase85Encoded(replaceBrackets(value))) {
        setIsCode(true);
      }
    }, [value]);

    const decodedValue = useMemo(() => {
      const cleanValue = replaceBrackets(value);
      return Base85Conversion.isBase85Encoded(cleanValue)
        ? Base85Conversion.decodeBase85(cleanValue)
        : value;
    }, [value]);

    const handleCodeValueChange = useCallback(
      (input: string) => {
        const encodedValue = Base85Conversion.encodeToBase85(input);
        onChange(encodedValue);
      },
      [onChange]
    );

    const renderCodeEditorModal = useCallback(
      () => (
        <JinjaCodeEditorModal
          existingCode={decodedValue}
          sampleDataFormat={sampleDataFormat}
          sampleDataLoading={sampleDataLoading}
          handleSaveData={handleCodeValueChange}
        >
          <ExternalLink className='absolute bottom-1 w-4 h-4 right-1 text-gray-500 cursor-pointer' />
        </JinjaCodeEditorModal>
      ),
      [decodedValue, handleCodeValueChange, sampleDataFormat, sampleDataLoading]
    );

    return (
      <div ref={ref} className='w-full bg-white rounded'>
        {children && (
          <div className='flex justify-between items-center'>
            <p className='py-2 text-xs font-semibold text-blue-400'>{title}</p>
            <div className='p-[2px] bg-gray-100 rounded-xl flex gap-1 items-center'>
              <Button
                variant={isCode ? 'ghost' : 'secondary'}
                onClick={() => setIsCode(false)}
                className={`rounded-xl text-[10px] px-2 py-1 h-5 ${
                  isCode
                    ? 'bg-transparent hover:bg-gray-50'
                    : 'bg-white hover:bg-gray-200'
                }`}
              >
                Default
              </Button>
              <Button
                variant={!isCode ? 'ghost' : 'secondary'}
                onClick={() => setIsCode(true)}
                className={`rounded-xl text-[10px] px-2 py-1 h-5 ${
                  !isCode
                    ? 'bg-transparent hover:bg-gray-50'
                    : 'bg-white hover:bg-gray-200'
                }`}
              >
                Code
              </Button>
            </div>
          </div>
        )}

        {isCode ? (
          <div className='relative'>
            {renderCodeEditorModal()}
            <AutosizeTextarea
              style={{ resize: 'none' }}
              className='h-40 border-gray-300 rounded-md hover:border-primary-hover focus:border-primary-hover'
              placeholder='Enter your code here...'
              value={decodedValue}
              onChange={(e) => handleCodeValueChange(e.target.value)}
            />
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
);

export default JinjaVariableEditorFieldWithModal;
