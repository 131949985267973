import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { checkPermission, getQueryToken } from 'utilities/utils';
import config from '../../../utilities/config';
import axios from '../../../utilities/httpClient';
import { DashboardComponentState } from '../interface';

const OnboardingChannelList = [
  'livechat',
  'shopify',
  'woocommerce',
  'facebook_messenger',
  'line_messenger',
  'telegram_messenger',
  'viber_messenger',
  'instagram_messenger',
  'facebook_feed',
  'instagram_feed',
];

const useDashboard = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);
  const dashboard = useSelector((state: any) => state.dashboard);
  const loading = useSelector(
    (state: any) =>
      state.loading.effects.dashboard.fetchProject ||
      state.loading.effects.dashboard.fetchDashboardMarketingData
  );
  // Add other necessary useSelector hooks for state variables

  const [componentDidMount, setComponentDidMount] = useState(false);

  const [state, setState] = useState<DashboardComponentState>({
    loading: false,
    showUsage: false,
    hasOnboardingModal: false,
    onboardingChannelType: '',
    hasSupervisorPermission: false,
    showSessionUsageModal: false,
    is_disabled: false,
    hasTrialEnded: false,
    showAccessDeniedModal: false,
    showAccessDeniedModalMessage: {
      title: '',
      subTitle: '',
    },
  });

  const updateStateValues = (
    newStateValues: Partial<DashboardComponentState>
  ) => {
    setState((prev) => ({ ...prev, ...newStateValues }));
  };

  const authCheck = async () => {
    try {
      const res = await axios.get(config.auth.info());
      dispatch.auth.initLogin(res?.data?.dataSource);
    } catch (err) {
      console.error('dashboard auth error:', err);
    }
  };

  const initialConfiguration = async () => {
    updateStateValues({ loading: true });
    await authCheck();
    const parameterValue = getQueryToken('channel_type');
    if (!!parameterValue && OnboardingChannelList.includes(parameterValue)) {
      updateStateValues({
        hasOnboardingModal: true,
        onboardingChannelType: parameterValue,
      });
    }

    await dispatch.dashboard.cleanProjectHistory();
    await dispatch.billing.fetchPlans();
    const projectResponse = await dispatch.dashboard.fetchProject();

    if (projectResponse?.success)
      await initialProjectSelection([...projectResponse?.data]);
    else await initialProjectSelection([]);
    updateStateValues({ loading: false });
  };

  const onProjectSelection = async (requiredProject: any): Promise<void> => {
    await dispatch.dashboard.setSelectedProject(requiredProject);
    await dispatch.inbox.updateSelectedProjectOnInbox(requiredProject);
    // TODO: This is a legacy API, remove when all dependencies are handled.
    // await this.props.fetchPlatformList(requiredProject.id);
    const response = await dispatch.dashboard.fetchAgentAccess(
      requiredProject?.id
    );
    await dispatch.settings.updateIsUsingNewInbox(response);
  };

  const initialProjectSelection = async (projects: any[]) => {
    const previousPath = localStorage.getItem('previousPath') || '';
    localStorage.removeItem('previousPath');

    const matchPattern = (path: string, pattern: RegExp) => {
      const match = path.match(pattern);
      return !!match ? Number(match[1]) : null;
    };

    const pattern1 =
      /^\/projects\/(\d+)\/inbox(?:\?(?:customer_id=(\d+)&)?(?:ticket_id=(\d+))?)?$/;
    const pattern2 = /^\/projects\/(\d+)\/inbox\?customer_id=(\d+)$/;

    const requiredProjectId =
      matchPattern(previousPath, pattern1) ||
      matchPattern(previousPath, pattern2);

    let requestedProject = null;

    let permission = null;

    if (projects.length === 0) {
      dispatch.dashboard.setSelectedProject(null);
      dispatch.dashboard.setSelectedPlatform(null);
    } else {
      if (requiredProjectId !== null) {
        const requiredProjects = projects.filter(
          (project) => project?.id === requiredProjectId
        );

        if (requiredProjects.length > 0) {
          requestedProject = requiredProjects[0];
        } else {
          handleAccessDenied();
        }
      }

      const selectedProject = !!requestedProject
        ? requestedProject
        : !!dashboard?.selectedProject
        ? dashboard?.selectedProject
        : projects[0];

      if (selectedProject?.id !== dashboard?.selectedProject?.id) {
        await onProjectSelection(selectedProject);
      } else {
        const response = await dispatch.dashboard.fetchAgentAccess(
          selectedProject?.id
        );
        await dispatch.settings.updateIsUsingNewInbox(response);
      }
      if (!!previousPath) {
        permission = await dispatch.dashboard.fetchRolePermission(
          selectedProject?.id
        );
      }
    }

    const hasTeamInboxPermissions =
      !!permission &&
      checkPermission(permission?.list, 'read:conversation') &&
      checkPermission(permission?.list, 'write:conversation');

    if (
      !!previousPath &&
      permission?.list?.length > 0 &&
      !hasTeamInboxPermissions
    ) {
      handleAccessDenied(
        'Team Inbox Permission Denied.',
        "You don't have access to this team inbox. Please contact the team administration."
      );
    }

    dispatch.dashboard.updateSelectedNav(
      hasTeamInboxPermissions && !!requestedProject && !!requiredProjectId
        ? 1
        : 0
    );

    if (requestedProject && requiredProjectId && hasTeamInboxPermissions) {
      navigate(previousPath);
    }
  };

  const handleAccessDenied = (
    title: string = 'Permission Denied.',
    subTitle: string = "You don't have access to this team. Please contact the team administration."
  ) => {
    updateStateValues({
      showAccessDeniedModalMessage: { title, subTitle },
      showAccessDeniedModal: true,
    });
  };

  const trialCheck = () => {
    if (dashboard?.selectedProject?.is_access_disabled !== state.is_disabled) {
      updateStateValues({
        is_disabled: dashboard?.selectedProject?.is_access_disabled,
      });
    }
    const trialEnded =
      !dashboard.selectedProject?.creator?.is_trial_user &&
      !dashboard.selectedProject?.is_pro;
    if (trialEnded !== state.hasTrialEnded)
      updateStateValues({ hasTrialEnded: trialEnded });
  };

  useEffect(() => {
    if (!componentDidMount) {
      initialConfiguration();
      setComponentDidMount(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    trialCheck();

    //eslint-disable-next-line
  }, [dashboard?.selectedProject?.id]);

  useEffect(() => {
    if (!!dashboard?.permission && dashboard?.permission?.list.length > 0) {
      let haspermission = checkPermission(
        dashboard?.permission?.list,
        'generic:crm_supervisor'
      );
      updateStateValues({ hasSupervisorPermission: haspermission });
    }
    //eslint-disable-next-line
  }, [dashboard?.permission]);

  // Add other necessary functions or effects here

  const handleConfirmButtonClick = () => {
    setState((prev) => ({ ...prev, showAccessDeniedModal: false }));
    const titleIncludesNoTicketFound =
      state.showAccessDeniedModalMessage?.title.includes('No Ticket Found');
    if (!titleIncludesNoTicketFound) {
      navigate('/dashboard');
    }
  };

  const updateSelectedNav = (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId);

  const checkIfHasOnboarding = () => {
    if (auth?.is_invited) return false;
    if (auth?.has_completed_onboarding === undefined) return false;
    return !auth.has_completed_onboarding;
  };

  return {
    auth,
    loading,
    dashboard,
    updateSelectedNav,
    updateStateValues,
    checkIfHasOnboarding,
    dashboardState: state,
    handleConfirmButtonClick,
    selectedProject: dashboard?.selectedProject,
  };
};

export default useDashboard;
