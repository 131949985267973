import { useDispatch, useSelector } from 'react-redux';
import {
  CustomerInfoInterface,
  ISearchCustomer,
  TicketInterface,
} from '../inboxInterface';
import { PlatformInterface, SelectedTeamInterface } from 'index';
import { toaster } from 'evergreen-ui';

interface LeftBarStoreHook {
  authData: any;
  channelList: any[];
  dashboardData: any;
  selectedQueueType: string;
  inboxSearchState: any;
  ticketIsResolvedStatus: 0 | 1;
  selectedTicket: TicketInterface;
  selectedSecondaryQueueType: string;
  selectedProject: SelectedTeamInterface;
  integratedChannelList: PlatformInterface[];
  customerInformation: CustomerInfoInterface;
  handleEcommerceStoreData: () => void;
  getTicketConfigurationData: () => Promise<any>;
  checkIfProjectExist: (
    projectId: string | number,
    customerId: string,
    ticketId: string
  ) => Promise<{ success: boolean; title: string; subTitle: string }>;
  searchCustomerByPrimaryId: (query: string) => Promise<ISearchCustomer[]>;
  createTicketByPrimaryId: (
    customerId: any,
    primary_id: any,
    platform_id: any
  ) => Promise<boolean>;
}

const useLeftBarHook = (): LeftBarStoreHook => {
  const dispatch = useDispatch();
  const inboxData = useSelector((state: any) => state.inbox);
  const dashboardData = useSelector((state: any) => state.dashboard);
  const authData = useSelector((state: any) => state.auth);
  const channelList = useSelector((state: any) => state.inbox.channelList);

  const getTicketConfigurationData = async () => {
    const result = await dispatch.settings.getTicketConfigurationData(
      dashboardData?.selectedProject?.id
    );
    return result;
  };

  const searchCustomer = async (projectId: string | number, key: string) =>
    await dispatch.inbox.searchTicketByParamsData({
      projectId: projectId,
      key,
    });

  const updateSateData = (key: string, value: any) => {
    dispatch.inbox.updateSateData({ key, value });
  };

  const setSearchResult = async (
    query: string,
    tickets: TicketInterface[],
    selectedTicket: any,
    previousList: TicketInterface[]
  ) => {
    await updateSateData('searchState', {
      searchQuery: query,
      isSearchApplied: true,
      leftbarPreviousState: [...previousList],
    });
    await updateSateData('selectedTicket', selectedTicket);
    await updateSateData('ticketList', [...tickets]);
  };

  const fetchTicketFromParams = async ({
    projectId,
    customerId = '',
    ticketId = '',
  }) => {
    const searchResult = async (searchParam: any) => {
      const list = await searchCustomer(projectId, searchParam);
      if (list && list.length > 0) {
        setSearchResult('search-by-link', [list[0]], list[0], []);
        return {
          success: true,
          title: '',
          subTitle: '',
        };
      } else {
        return {
          success: false,
          title: 'No Ticket Found',
          subTitle: "We didn't find any ticket.",
        };
      }
    };

    if (ticketId) {
      return await searchResult(ticketId);
    } else if (customerId) {
      return await searchResult(customerId);
    } else {
      return {
        success: false,
        title: 'Invalid Link',
        subTitle: '',
      };
    }
  };

  const setRequiredProject = async (
    projectId: string | number,
    customerId: string | undefined,
    ticketId: string | undefined
  ) => {
    const requireProject = dashboardData.projects.filter(
      (project: any) => project?.id === Number(projectId)
    );

    if (!!requireProject && requireProject.length > 0) {
      if (dashboardData.selectedProject?.id !== requireProject[0]?.id) {
        await dispatch.dashboard.cleanProjectHistory();
        await dispatch.dashboard.setSelectedProject(requireProject[0]);
        await dispatch.dashboard.fetchTeamDetails(requireProject[0]?.id);
      }
      return fetchTicketFromParams({
        projectId,
        customerId,
        ticketId,
      });
    } else {
      return {
        success: false,
        title: 'Permission Denied.',
        subTitle:
          "You don't have the access to this team. Please contact with team administration.",
      };
    }
  };

  const checkIfProjectExist = async (
    projectId: string | number,
    customerId = '',
    ticketId = ''
  ) => {
    if (!dashboardData.projects || dashboardData.projects.length < 1) {
      await dashboardData.fetchProject();
    }
    return setRequiredProject(projectId, customerId, ticketId);
  };

  const searchCustomerByPrimaryId = async (query: string) => {
    const res = await dispatch.inbox.searchCustomerByPrimaryId({
      projectId: dashboardData?.selectedProject?.id,
      query,
    });
    return res;
  };

  const createTicketByPrimaryId = async (
    //@ts-ignore
    customerId: any, //TODO: will be available
    primary_id: any,
    platform_id: any
  ) => {
    const payloadBody = { platform_id, primary_id };
    const res = await dispatch.inbox.createTicketByPrimaryId({
      projectId: dashboardData?.selectedProject?.id,
      payloadBody,
    });
    if (res.success) {
      const list = await searchCustomer(
        dashboardData?.selectedProject?.id,
        res?.dataSource?.id
      );

      if (!!list && list.length > 0) {
        await dispatch.inbox.updateSateData({
          key: 'selectedTicket',
          value: { ...list[0] },
        });
      }

      return true;
    } else {
      toaster.danger(res?.error || 'Ticket creation failed');
      return false;
    }
  };

  const handleEcommerceStoreData = async () => {
    const filteredChannel = channelList.find(
      (channel: any) =>
        parseInt(channel.id) === inboxData?.selectedTicket?.customer_platform_id
    );
    if (!!filteredChannel) {
      await dispatch.ecommerce.updateSelectedAliceStoreId(
        filteredChannel.alice_store_id
      );
      await dispatch.ecommerce.updateSelectedEcommerceType(
        filteredChannel.ecommerce_type
      );
      await dispatch.ecommerce.updateSelectedPlatformId(filteredChannel.id);
    }
  };

  return {
    authData,
    channelList,
    dashboardData,
    checkIfProjectExist,
    createTicketByPrimaryId,
    handleEcommerceStoreData,
    searchCustomerByPrimaryId,
    getTicketConfigurationData,
    inboxSearchState: inboxData?.searchState,
    selectedTicket: inboxData.selectedTicket,
    selectedQueueType: inboxData.selectedQueueType,
    selectedProject: dashboardData?.selectedProject,
    customerInformation: inboxData.customerinformation,
    integratedChannelList: inboxData.integratedChannelList,
    ticketIsResolvedStatus: inboxData.ticketIsResolvedStatus,
    selectedSecondaryQueueType: inboxData.selectedSecondaryQueueType,
  };
};

export default useLeftBarHook;
