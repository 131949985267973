import React from 'react';
import DataStackCard from './DataStackCard';

interface Prop {
  data: stackProps[];
  column: number;
}

interface stackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name: string;
  loader: boolean;
  toolTip?: string;
}
const dataStackClass = (column: number) => {
  switch (column) {
    case 3:
    case 4:
      return `m-3 mb-6 flex flex-col rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200  md:flex-row md:divide-y-0 md:divide-x`;
    default:
      return `m-3 mb-6 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200  md:grid-cols-${column} md:divide-y-0 md:divide-x`;
  }
};

const DataStack: React.FC<Prop> = ({ data, column }) => {
  return (
    <div>
      <dl className={dataStackClass(column)}>
        {data.map((item, index) => (
          <DataStackCard item={item} column={column} key={index} />
        ))}
      </dl>
    </div>
  );
};

export default DataStack;
