import React, { useEffect, useState } from 'react';
import Accordion from '../../common/Accordion';
import ReactTooltip from 'react-tooltip';

import InputFiledWithLimit from '../../common/InputFieldWithLimit';

import { SketchPicker } from 'react-color';
import { Switch } from '@headlessui/react';
import { Popover } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../../../../../../utilities/utils';
import { CheckIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { SocialMediaData } from 'pages/integration/interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  liveChatData: LiveChatDataProps;
  channelId: Number;
  selectedProject: selectedProjectProps;
  auth: UserPropertyType;
  isOpen: boolean;

  handleAccordionClick: () => void;
  updateBrandData: (key: string, value: any) => void;
  updateLiveChatData: (key: string, value: any) => void;
}

let defaultBrandColors = [
  '#10B981',
  '#3B82F6',
  '#8B5CF6',
  '#3C5BFF',
  '#EF4444',
];

let defaultFontColors = ['#FFFFFF', '#000000'];

const ColorDesignComponent: React.FC<Props> = ({
  auth,
  liveChatData,
  selectedProject,
  channelId,
  isOpen,

  handleAccordionClick,
  updateBrandData,
  updateLiveChatData,
}) => {
  const { t } = useTranslation();
  const [brandColors, updateBrandColors] = useState(defaultBrandColors);
  const [fontColors, updateFontColors] = useState(defaultFontColors);

  const [pickerColor, updatePickerColor] = useState('#10B981');

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (
      !defaultBrandColors.includes(
        liveChatData.livechat_data.brand_color_regular.toUpperCase()
      )
    ) {
      defaultBrandColors[4] = liveChatData.livechat_data.brand_color_regular;
      updateBrandColors([...defaultBrandColors]);
    }

    if (
      !defaultFontColors.includes(
        liveChatData.livechat_data.font_color_regular.toUpperCase()
      )
    ) {
      defaultFontColors[2] = liveChatData.livechat_data.font_color_regular;
      updateFontColors([...defaultFontColors]);
    }

    //eslint-disable-next-line
  }, [liveChatData]);

  const handleBrandColorSelect = (color: string) => {
    updateLiveChatData('brand_color_regular', color);
  };

  const handleNewBrandColor = (color: string) => {
    brandColors[4] = color;
    updateBrandColors(brandColors);
    updateLiveChatData(
      'brand_color_regular',
      brandColors[brandColors.length - 1]
    );
  };
  const handleNewFontColor = (color: string) => {
    if (fontColors.length < 3) {
      fontColors.push(color);
    } else {
      fontColors[2] = color;
    }

    updateFontColors([...fontColors]);
    updateLiveChatData('font_color_regular', fontColors[fontColors.length - 1]);
  };

  const brandColorList = () => {
    let isChecked: boolean = false;
    let ui = [];
    for (let i = 0; i < brandColors.length; i++) {
      isChecked =
        liveChatData.livechat_data.brand_color_regular.toLowerCase() ===
        brandColors[i].toLowerCase();
      ui.push(
        <label
          className='relative items-center w-6 h-6 ltr:mr-2 rtl:ml-2 rounded-full '
          key={brandColors[i] + i}
        >
          <input
            type='checkbox'
            className={classNames(
              'without-ring w-6 h-6 rounded-full cursor-pointer mt-[-18px]',
              brandColors[i].toUpperCase() === '#FFFFFF' ||
                brandColors[i].toLowerCase() === 'white'
                ? 'border !border-gray-300'
                : 'border-0'
            )}
            style={{
              background: brandColors[i],
            }}
            onChange={() => {
              handleBrandColorSelect(brandColors[i]);
            }}
          />
          {isChecked && (
            <CheckIcon
              className='absolute'
              color={`${
                brandColors[i].toUpperCase() === '#FFFFFF' ||
                brandColors[i].toLowerCase() === 'white'
                  ? '#6B7280'
                  : 'white'
              }`}
              width={15}
              height={15}
              style={{ left: '23%', top: '-35%' }}
            />
          )}
        </label>
      );
    }

    return ui;
  };
  const fontColorList = () => {
    let isChecked: boolean = false;
    let ui = [];
    for (let i = 0; i < fontColors.length; i++) {
      isChecked =
        liveChatData.livechat_data.font_color_regular.toLowerCase() ===
        fontColors[i].toLowerCase();
      ui.push(
        <label
          className='relative items-center w-6 h-6 ltr:mr-2 rtl:ml-2 rounded-full'
          key={i}
        >
          <input
            type='checkbox'
            checked={isChecked}
            className={classNames(
              'without-ring w-6 h-6 rounded-full cursor-pointer mt-[-18px]',
              fontColors[i].toUpperCase() === '#FFFFFF' ||
                fontColors[i].toLowerCase() === 'white'
                ? 'border !border-gray-300'
                : 'border-0'
            )}
            style={{
              background: fontColors[i],
            }}
            onChange={() => {
              updateLiveChatData('font_color_regular', fontColors[i]);
            }}
          />
          {isChecked && (
            <CheckIcon
              className='absolute'
              color={`${
                fontColors[i].toUpperCase() === '#FFFFFF' ||
                fontColors[i].toLowerCase() === 'white'
                  ? '#6B7280'
                  : 'white'
              }`}
              width={15}
              height={15}
              style={{ left: '23%', top: '-35%' }}
            />
          )}
        </label>
      );
    }

    return ui;
  };

  const handleBrandNameUpdate = (brandName: string) => {
    let socialChannelList = [
      ...liveChatData.livechat_data.social_chat_options_list,
    ];

    socialChannelList.forEach((channel: SocialMediaData) => {
      if (channel.id === channelId) {
        channel.name = brandName;
      }
    });

    updateBrandData('title', brandName);
    updateLiveChatData('social_chat_options_list', [...socialChannelList]);
  };

  const getSubscriptionType = () => {
    return selectedProject.is_using_new_billing
      ? selectedProject?.subscription_plan?.name
      : selectedProject?.subscription_plan?.plan_type;
  };

  const getBrandingSwitch = () => {
    if (
      getSubscriptionType().toLowerCase() !== 'free' ||
      auth.is_appsumo_user
    ) {
      return (
        <Switch
          checked={liveChatData.livechat_data['should_show_myalice_branding']}
          onChange={() => {
            updateLiveChatData(
              'should_show_myalice_branding',
              !liveChatData.livechat_data['should_show_myalice_branding']
            );
          }}
          className={classNames(
            liveChatData.livechat_data['should_show_myalice_branding']
              ? 'bg-green-500'
              : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
          )}
        >
          <span
            aria-hidden='true'
            className={classNames(
              liveChatData.livechat_data['should_show_myalice_branding']
                ? 'ltr:translate-x-5 rtl:-translate-x-5'
                : 'translate-x-0',
              'pointer-events-none inline-block h-6 w-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      );
    } else {
      return (
        <Switch
          checked={true}
          onChange={() => {}}
          className={
            ' bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 '
          }
          data-tip='tooltip'
          data-for='live_chat_msg_should_show_myalice_branding'
        >
          <span
            aria-hidden='true'
            className={
              'translate-x-5 pointer-events-none inline-block h-6 w-6 rounded-full bg-gray-400 shadow transform ring-0 transition ease-in-out duration-200'
            }
          />
        </Switch>
      );
    }
  };

  return (
    <>
      <Accordion
        title={'Color and Design'}
        subTitle={'Edit widget colors, fonts and visual elements.'}
        open={isOpen}
        handleOnToggle={(val) => {
          handleAccordionClick();
        }}
      >
        <div className='grid grid-cols-1 divide-y'>
          <div className='px-5 py-4'>
            <InputFiledWithLimit
              label='Brand Name'
              limit={32}
              value={liveChatData.title}
              handleOnChange={(value: string) => {
                handleBrandNameUpdate(value);
              }}
            />
          </div>

          <div className='px-5 py-2 '>
            <p className='pt-4 pb-2 flex text-sm font-medium text-gray-700'>
              {t('Brand Color')}
            </p>
            <div className='rtl:float-right ltr:float-left'>
              {brandColorList()}
              <div className='h-6 border-1 bg-gray-300 ltr:mr-3 ml-1 inline-block w-[1px]' />
              <div className='inline-block'>
                <Popover className='relative'>
                  <Popover.Button>
                    <span className='items-center justify-center inline-block p-1 border border-gray-300 rounded-full'>
                      <PlusIcon width={15} color={'#6B7280'} strokeWidth={3} />
                    </span>
                  </Popover.Button>
                  <Popover.Panel className='absolute rtl:left-0 z-10'>
                    <SketchPicker
                      color={pickerColor}
                      onChange={(abc) => {
                        updatePickerColor(abc.hex);
                        handleNewBrandColor(abc.hex);
                      }}
                    />
                  </Popover.Panel>
                </Popover>
              </div>
            </div>
          </div>

          <div className='px-5 py-2 flex flex-col'>
            <p className='pt-4 pb-2 flex text-sm font-medium text-gray-700'>
              {t('Font & Icon Color')}
            </p>
            <div className='rtl:ml-auto'>
              {fontColorList()}
              <div className='h-6 border-1 bg-gray-300 ltr:mr-3 ml-1 inline-block w-[1px]'></div>
              <div className='inline-block'>
                <Popover className='relative'>
                  <Popover.Button>
                    <span className='items-center justify-center inline-block p-1 border border-gray-300 rounded-full'>
                      <PlusIcon width={15} color={'#6B7280'} strokeWidth={3} />
                    </span>
                  </Popover.Button>
                  <Popover.Panel className='absolute z-10'>
                    <SketchPicker
                      color={pickerColor}
                      onChange={(abc) => {
                        updatePickerColor(abc.hex);
                        handleNewFontColor(abc.hex);
                      }}
                    />
                  </Popover.Panel>
                </Popover>
              </div>
            </div>
            <span className='flex py-2 text-sm font-normal leading-5 text-gray-500'>
              {t(
                'Only the text and icon used on the brand color will be affected'
              )}
            </span>
          </div>

          <div className='px-5 py-2'>
            <Switch.Group
              as='div'
              className='flex w-full items-center justify-between py-2'
            >
              <span className='flex flex-col flex-grow'>
                <Switch.Label
                  as='span'
                  className='text-sm flex font-medium text-gray-900'
                  passive
                >
                  {t('Display Agent Profile Picture')}
                </Switch.Label>
              </span>
              <Switch
                checked={
                  liveChatData.livechat_data['agent_avatar_display_options'] ===
                  'all'
                }
                onChange={(val) => {
                  updateLiveChatData(
                    'agent_avatar_display_options',
                    val ? 'all' : 'never'
                  );
                }}
                className={classNames(
                  liveChatData.livechat_data['agent_avatar_display_options'] ===
                    'all'
                    ? 'bg-green-500'
                    : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
                )}
              >
                <span
                  aria-hidden='true'
                  className={classNames(
                    liveChatData.livechat_data[
                      'agent_avatar_display_options'
                    ] === 'all'
                      ? 'ltr:translate-x-5 rtl:-translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-6 w-6 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          <div className='px-5 py-2'>
            <Switch.Group
              as='div'
              className='flex items-center justify-between pt-2 cursor-default'
            >
              <span className='flex flex-col flex-grow'>
                <Switch.Label
                  as='span'
                  className='text-sm flex font-medium text-gray-900'
                  passive
                >
                  {t('MyAlice Branding')}{' '}
                  <span
                    className={classNames(
                      'px-2 py-1 text-sm text-green-800 bg-green-100 rounded-md',
                      getSubscriptionType().toLowerCase() !== 'free'
                        ? 'hidden'
                        : 'inline'
                    )}
                  >
                    {t('Premium')}
                  </span>
                </Switch.Label>
              </span>
              {getBrandingSwitch()}
            </Switch.Group>
            <ReactTooltip
              disable={getSubscriptionType() !== 'free'}
              id='live_chat_msg_should_show_myalice_branding'
              aria-haspopup='true'
              effect='solid'
            >
              <span>{t('Upgrade your plan to remove branding')}</span>
            </ReactTooltip>
          </div>
        </div>
      </Accordion>
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  auth: state.auth,
});

export default connect(mapState)(ColorDesignComponent);
