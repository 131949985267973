import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IDatalabField, IInboxDatalabFieldsHandler } from '../inboxInterface';

const useInboxDatalab = () => {
  const [shouldShowSuccessOrWarningModal, setShouldShowSuccessOrWarningModal] =
    React.useState(false);
  const [entryInsertErrorMessage, setEntryInsertErrorMessage] =
    React.useState('');
  const [showEntryDeleteWarning, setShowEntryDeleteWarning] =
    React.useState(false);
  const [successResponseData, setSuccessResponseData] = React.useState<null | {
    title: string;
    sub_title: string;
    message: string;
    dataSourse: any;
  }>(null);

  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.loading);
  const inboxDatalabData = useSelector((state: any) => state.inboxDatalab);
  const dashboardData = useSelector((state: any) => state.dashboard);
  const inboxData = useSelector((state: any) => state.inbox);

  let teamId = dashboardData?.selectedProject?.id;
  let customerId = inboxData?.selectedTicket?.customer_id;
  let selectedDatalabId = inboxDatalabData?.selectedDatalabId;
  let isFetchedDatalabFieldsLoading =
    loading.effects.inboxDatalab.fetchDatalabFields;
  let isInsertedDatalabEntriesLoading =
    loading.effects.inboxDatalab.insertDatalabEntries;
  let isRemovingSingleEntryLoading =
    loading.effects.inboxDatalab.removeSingleEntry;

  const removeSingleEntry = async () => {
    let response = await dispatch.inboxDatalab.deleteSingleEntry({
      selectedDatalabId: selectedDatalabId,
      entryId: inboxDatalabData?.selectedDatalabEntry?.id,
    });
    setShowEntryDeleteWarning(false);
    clearStatesExceptDatalabListAndCustomerEntry();
    if (response?.error.length !== 0)
      setEntryInsertErrorMessage(response.error);
  };

  const getPrefilledCustomerData = () => {
    const currentTicketData = inboxData?.selectedTicket ?? {};
    const customerInfo = inboxData?.customerInformation ?? {};
    return { ticket: currentTicketData, customer: customerInfo };
  };

  const hasEmptyRequiredField = () => {
    const entries = inboxDatalabData?.selectedDatalabFields;

    const isFieldEmpty = (field: any) => {
      if (Array.isArray(field?.value)) {
        return field?.value.length === 0;
      } else {
        return field?.value === '';
      }
    };

    const checkGroup = (group: any) => {
      if (!group?.value) return false;

      for (const row of group?.value) {
        for (const innerFieldData of row) {
          if (
            innerFieldData.is_required &&
            !innerFieldData?.is_readonly &&
            !innerFieldData?.is_hidden
          ) {
            if (isFieldEmpty(innerFieldData)) {
              return true; // Found a required field with an empty value
            }
          }
        }
      }

      return false;
    };

    return (
      entries?.some((item: any) => {
        if (item?.type === 'group') {
          return checkGroup(item);
        } else if (item.is_required && !item?.is_readonly && !item?.is_hidden) {
          return isFieldEmpty(item);
        }

        return false;
      }) ?? false
    ); // Default to false if entries is undefined
  };

  const isAllFieldsEmpty = () => {
    let entries = inboxDatalabData?.selectedDatalabFields;
    return (
      entries?.every((item: any) => {
        if (Array.isArray(item?.value)) {
          return item?.value.length === 0;
        } else {
          return item?.value === '';
        }
      }) ?? true
    );
  };

  const validateDataLabFieldsForEntry = () => {
    let isMinLengthValid = true;
    let isRequiredFieldsValid = true;

    const selectedFields = inboxDatalabData?.selectedDatalabFields;

    for (let field of selectedFields) {
      if (field.type === 'group') {
        if (!field?.value) continue;
        for (let i = 0; i < field.value.length; i++) {
          for (let j = 0; j < field.value[i].length; j++) {
            let innerFieldData = field.value[i][j];
            //@ts-ignore
            if (innerFieldData.min_length > innerFieldData.value.length) {
              //@ts-ignore
              const errorMessage = `Minimum length for ${innerFieldData?.name} is ${innerFieldData?.min_length}`;
              innerFieldData['error_message'] = errorMessage;
              updateInboxDatalabStateData('selectedDatalabFields', [
                ...selectedFields,
              ]);
              isMinLengthValid = false;
            }
          }
        }
      } else {
        if (
          !Array.isArray(field?.value) &&
          field.min_length > field.value.length
        ) {
          const errorMessage = `Minimum length for ${field?.name} is ${field?.min_length}`;
          handleInboxDatalabFieldsValue({
            id: field?.id,
            value: errorMessage,
            fieldName: 'error_message',
          });
          isMinLengthValid = false;
        }
      }

      // Add new conditions for validation here
    }

    return isMinLengthValid && isRequiredFieldsValid;
  };

  const getDataEntriesForGroupField = (entries: IDatalabField[][]) => {
    let postBody = [];
    for (let i = 0; i < entries.length; i++) {
      let objBody = {};
      for (const item of entries[i]) {
        if (item.value.length !== 0)
          objBody[item?.slug] =
            item.type === 'number' && !isNaN(item.value)
              ? Number(item.value)
              : item.value;
      }
      postBody.push(objBody);
    }
    return postBody;
  };

  const getdatalabEntriesPostBody = () => {
    let postBody = {};
    let entries: IDatalabField[] = inboxDatalabData?.selectedDatalabFields;
    for (const item of entries) {
      if (!!item.value && item.value.length !== 0)
        postBody[item?.slug] =
          item?.type === 'group'
            ? getDataEntriesForGroupField(item?.value)
            : item.type === 'number' && !isNaN(item.value)
            ? Number(item.value)
            : item.value;
    }
    postBody = { ...postBody, customer_id: customerId };
    return postBody;
  };

  const getdatalabEntryUpdatePostBody = () => {
    let postBody = {};
    let entries: IDatalabField[] = inboxDatalabData?.selectedDatalabFields;
    for (const item of entries) {
      if (!!item.value && item.value.length !== 0)
        postBody[item?.slug] =
          item?.type === 'group'
            ? getDataEntriesForGroupField(item?.value)
            : item.type === 'number' && !isNaN(item.value)
            ? Number(item.value)
            : item.value;
    }
    postBody = { ...postBody };
    return postBody;
  };

  const fetchSelectedDatalabData = async () => {
    await dispatch.inboxDatalab.fetchDatalabFields({
      teamId,
      selectedDatalabId,
    });
  };

  const updateInboxDatalabStateData = (key: string, value: any) =>
    dispatch.inboxDatalab.updateInboxDatalabStateData({ key, value });

  const handleInboxDatalabFieldsValue = (
    updatedFieldValue: IInboxDatalabFieldsHandler
  ) => {
    let localSelectedDatalabEntry = inboxDatalabData?.selectedDatalabEntry;
    if (!!localSelectedDatalabEntry) {
      dispatch.inboxDatalab.updateSelectedDatalabEntry(updatedFieldValue);
    }
    dispatch.inboxDatalab.updateInboxDatalabFieldsValue(updatedFieldValue);
  };

  const clearStatesExceptDatalabList = () =>
    dispatch.inboxDatalab.clearStatesExceptDatalabList();

  const clearStatesExceptDatalabListAndCustomerEntry = () =>
    dispatch.inboxDatalab.clearStatesExceptDatalabListAndCustomerEntry();

  const insertDatalabEntries = async (requestBody: any = null) => {
    let postRequestBody = requestBody
      ? { ...requestBody, customer_id: customerId }
      : getdatalabEntriesPostBody();
    let response = await dispatch.inboxDatalab.insertDatalabEntries({
      selectedDatalabId,
      postRequestBody,
    });
    if (response.success)
      setSuccessResponseData({
        dataSourse: response.data,
        title: response?.title,
        sub_title: response?.sub_title,
        message: response?.message,
      });
    setShouldShowSuccessOrWarningModal(true);
    if (!!response?.error && response?.error.length !== 0)
      setEntryInsertErrorMessage(response.error);
  };

  const updateSingleEntry = async (payload: any = null) => {
    let response = await dispatch.inboxDatalab.updatedSingleEntry({
      selectedDatalabId: selectedDatalabId,
      entryId: inboxDatalabData?.selectedDatalabEntry?.id,
      requestBody: payload ? payload : getdatalabEntryUpdatePostBody(),
    });
    if (response.success)
      setSuccessResponseData({
        dataSourse: response.data,
        title: response?.title,
        sub_title: response?.sub_title,
        message: response?.message,
      });
    setShouldShowSuccessOrWarningModal(true);
    if (response?.error.length !== 0)
      setEntryInsertErrorMessage(response.error);
  };

  const updateSelectedNav = async (navId: number) =>
    await dispatch.dashboard.updateSelectedNav(navId);
  // NOTE: Will be used later
  // const fetchDatalabList = async () => {
  //   await dispatch.inboxDatalab.fetchDatalabList(teamId);
  // };

  // React.useEffect(() => {
  //   fetchDatalabList();
  //   // eslint-disable-next-line
  // }, []);

  return {
    isAllFieldsEmpty,
    inboxDatalabData,
    updateSelectedNav,
    updateSingleEntry,
    removeSingleEntry,
    successResponseData,
    insertDatalabEntries,
    hasEmptyRequiredField,
    showEntryDeleteWarning,
    getPrefilledCustomerData,
    entryInsertErrorMessage,
    fetchSelectedDatalabData,
    setShowEntryDeleteWarning,
    setEntryInsertErrorMessage,
    isRemovingSingleEntryLoading,
    validateDataLabFieldsForEntry,
    updateInboxDatalabStateData,
    isFetchedDatalabFieldsLoading,
    isInsertedDatalabEntriesLoading,
    clearStatesExceptDatalabList,
    handleInboxDatalabFieldsValue,
    shouldShowSuccessOrWarningModal,
    setShouldShowSuccessOrWarningModal,
    clearStatesExceptDatalabListAndCustomerEntry,
  };
};

export default useInboxDatalab;
