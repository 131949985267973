import { LiveChatDataProps } from 'pages/integration/interface';
import React from 'react';

import { connect } from 'react-redux';
import { classNames } from 'utilities/utils';
import { PreviewArrowIcon } from '../../assets/img/icons/svgIcons';

import { DefaultIcons } from '../../utils/ChatHeadIcons';
import CardPreview from './cardPreview';
import PrechatPreview from './preChatPreview';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  liveChatData: LiveChatDataProps;
  previewType: string;
}

const Preview: React.FC<Props> = ({ liveChatData, previewType }) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const currentPartnerName =
    currentPartnerInfo?.type === 'white-label'
      ? currentPartnerInfo.shortName
      : 'MyAlice';
  const iconViewWithText = () => {
    if (liveChatData.livechat_data.chat_head_size === 'small') {
      return (
        <DefaultIcons
          id={liveChatData.livechat_data.chat_head_id}
          color={liveChatData.livechat_data.font_color_regular}
        />
      );
    } else if (liveChatData.livechat_data.chat_head_size === 'large') {
      return (
        <div className='flex gap-2 items-center'>
          <DefaultIcons
            id={liveChatData.livechat_data.chat_head_id}
            color={liveChatData.livechat_data.font_color_regular}
            classNames={'w-6 h-6 inline'}
          />
          <span
            className='text-sm font-medium inline'
            style={{ color: liveChatData.livechat_data.font_color_regular }}
          >
            {liveChatData.livechat_data.chat_head_text}
          </span>
        </div>
      );
    } else {
      return (
        <div className='inline-block'>
          <span
            className='text-sm font-medium inline'
            style={{ color: liveChatData.livechat_data.font_color_regular }}
          >
            {t(liveChatData.livechat_data.chat_head_text)}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className='w-full h-full relative'>
        {/* ================== Card Preview =================*/}
        <div
          className={classNames(
            'w-[310px] relative pt-[60px] mb-10 mx-auto',
            previewType === 'default' ? 'block' : 'hidden'
          )}
        >
          <div>
            <CardPreview liveChatData={liveChatData} />
            <div className='mt-2'>
              <PreviewArrowIcon height='10px' width='50px' />
              <div className='w-full flex justify-center items-center'>
                <span className='text-xs text-gray-700 font-normal text-center  leading-4 w-[183px]'>
                  {t(
                    `This is how ${currentPartnerName} will appear on your website`
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ================== PreChat Preview =================*/}

        <div
          className={classNames(
            'h-full py-4 mx-auto',
            previewType === 'preChat' ? 'block' : 'hidden'
          )}
          style={{ width: '300px' }}
        >
          <PrechatPreview liveChatData={liveChatData} />
        </div>

        {/* ================ Chat Head View ================= */}

        <div
          className={`${
            liveChatData.livechat_data.chat_head_size === 'small'
              ? 'rounded-2xl'
              : 'rounded-3xl'
          } ${
            previewType === 'chatHead' ? 'block' : 'hidden'
          } shadow-lg absolute `}
          style={{
            right:
              liveChatData.livechat_data.chat_head_position === 'right'
                ? `${liveChatData.livechat_data.chat_head_side_spacing}px`
                : 'auto',
            left:
              liveChatData.livechat_data.chat_head_position === 'left'
                ? `${liveChatData.livechat_data.chat_head_side_spacing}px`
                : 'auto',
            bottom: `${liveChatData.livechat_data.chat_head_bottom_spacing}px`,
          }}
        >
          {liveChatData.livechat_data.is_chat_head_logo_custom ? (
            <div className='w-12 h-12 rounded-lg'>
              <img
                src={liveChatData.livechat_data.chat_head_url}
                className='w-full h-full rounded-2xl'
                alt='c-icon'
              />
            </div>
          ) : (
            <div
              style={{
                backgroundColor: liveChatData.livechat_data.chat_head_color,
              }}
              className={`inline-flex items-center  ${
                liveChatData.livechat_data.chat_head_size === 'small'
                  ? ' p-3 rounded-2xl'
                  : 'px-3 py-[18px] rounded-3xl'
              } shadow-sm`}
            >
              {iconViewWithText()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  liveChatData: state.integration.liveChatData,
});

export default connect(mapState)(Preview);
