import { React } from '../../../../export';

interface Props {
  conditionData: any;
}

const ComparisonComponent = ({ comparisonData }: any) => {
  const getSlectedValuesTuple = (comparison: any) => {
    return comparison?.col_2
      ?.map((item: { label: string }) => item.label)
      .join(', ');
  };
  return (
    <div className='w-full'>
      {comparisonData.map((childArray: any, index: number) => (
        <div key={index} className={index > 0 ? 'mt-4' : ''}>
          <div className='p-2 border border-gray-200 rounded-md'>
            {childArray.map((comparison: any, i: number) => (
              <div
                key={i}
                className={
                  i < childArray.length - 1
                    ? 'border-b border-gray-200 py-2 flex'
                    : 'py-2 flex'
                }
              >
                {/* Render your comparison data here */}
                <div>
                  <span className='text-gray-500'>
                    {`${comparison?.name} ${comparison?.col_1?.label} - `}
                    <span className='text-gray-700'>
                      {`(${getSlectedValuesTuple(comparison)})`}
                    </span>
                  </span>
                </div>
                &nbsp;
                {/* Add more fields as needed */}
              </div>
            ))}
          </div>
          {index < comparisonData.length - 1 && (
            <div className='flex items-center justify-center mt-4 space-x-4'>
              <div className='flex-1 border-t border-gray-200' />
              <div className='font-bold text-gray-500'>OR</div>
              <div className='flex-1 border-t border-gray-200' />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const ConditionNodePreview: React.FC<Props> = ({ conditionData }) => {
  // Assuming data is your provided array
  return (
    <div className='flex items-center justify-center w-full'>
      <ComparisonComponent comparisonData={conditionData} />
    </div>
  );
};

export default ConditionNodePreview;
