import { useState } from 'react';
import Picker from 'emoji-picker-react';
import { Popover } from '@headlessui/react';
import { FaceSmileIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  value?: string;
  limit?: number;
  handleOnChange: (val: string) => void;
}

const TextAreaWithEmoji: React.FC<Props> = ({
  value = '',
  handleOnChange,
  limit = 30,
}) => {
  const { t } = useTranslation();
  const [isInputFocused, setInputFocus] = useState<boolean>(false);
  const [textAreaCursorPositon, setTextAreaCursorPosition] =
    useState<number>(0);
  const handleInputValueChange = (val: string) => {
    if (val.length <= limit) handleOnChange(val);
  };

  //object was recieved by emoji picker
  const handleEmojiSelection = (emoji: string) => {
    const valueWithEmoji =
      value.substring(0, textAreaCursorPositon) +
      emoji +
      value.substring(textAreaCursorPositon, value.length);
    handleOnChange(valueWithEmoji);
  };
  return (
    <div
      className={`border rounded-md p-2 ${
        !isInputFocused
          ? 'border-gray-300 '
          : 'ring-2 ring-green-500 ring-inset'
      }`}
    >
      <textarea
        className='border-0 text-sm font-normal bg-transparent outline-0 resize-none w-full t-0 l-0 focus:border-transparent focus:ring-0'
        rows={2}
        value={value}
        placeholder={t('Enter your text here.')}
        onBlurCapture={(event) => {
          setTextAreaCursorPosition(event.target.selectionStart);
        }}
        onChange={(event) => {
          handleInputValueChange(event.target.value);
        }}
        onFocus={() => {
          setInputFocus(true);
        }}
        onBlur={() => {
          setInputFocus(false);
        }}
      />
      <div className='w-full mt-1  b-0 flex relative'>
        <div className='flex items-center'>
          <Popover>
            <Popover.Button>
              <FaceSmileIcon width={20} height={20} color={'#9CA3AF'} />
            </Popover.Button>
            <Popover.Panel className='absolute inset-0 -top-64  w-2/5 '>
              {({ close }) => (
                <Picker
                  onEmojiClick={(event: EventListener, emojiObject: any) => {
                    handleEmojiSelection(emojiObject.emoji);
                    close();
                  }}
                />
              )}
            </Popover.Panel>
          </Popover>
        </div>

        <div className='inline-block ml-auto inset-y-0 right-0  py-1.5 pr-1.5 '>
          <kbd
            className={`inline-flex items-center   ${
              value.length >= limit ? 'text-red-500' : 'text-gray-500'
            } rounded px-[2px] text-sm font-sans font-medium bg-[#F3F4F6]`}
          >
            {value.length}/{limit}
          </kbd>
        </div>
      </div>
    </div>
  );
};

export default TextAreaWithEmoji;

// ${
//                 value.length >= limit ? 'text-red-400' : 'text-gray-400'
//               }

//               {value.length}/{limit}
