'use client';

import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { DataTable } from 'libraryV2/ui/data-table';
import { useEffect, useState } from 'react';
import useWebhooks from '../../hooks/useWebhooks';
import { IWebhookData } from '../webhookModal';
import { DataTableToolbar } from './DataTableToolbar';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export default function WebhooksTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [localWebhookData, setLocalWebhookData] = useState<IWebhookData[]>([]);

  const {
    webhookList,
    limit,
    offset,
    total: totalRowCount,
    handleLimitUpdate,
    handlePageChange,
    searchLoading,
  } = useWebhooks();

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  useEffect(() => {
    setLocalWebhookData(webhookList);
  }, [webhookList]);

  const handleLimitChange = async (newLimit: number) => {
    await handleLimitUpdate(newLimit);
  };

  const handlePageUpdate = async (newOffset: number) => {
    await handlePageChange(limit, newOffset);
  };

  return (
    <div className='container pt-3 mx-auto flex flex-col gap-3'>
      <DataTableToolbar
        table={table}
        setLocalWebhookData={setLocalWebhookData}
      />

      <DataTable
        columns={columns}
        data={localWebhookData as TData[]}
        dataLoading={searchLoading}
        hasPagination={true}
        paginationProps={{
          limit: limit,
          offset: offset,
          totalRowCount: totalRowCount,
          onLimitChange: handleLimitChange,
          onPageChange: handlePageUpdate,
        }}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  );
}
