import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from 'components';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { ISearchCustomer } from 'pages/inbox/inboxInterface';
import { getWhatsappAndEmailChannelCount } from 'pages/inbox/utils/functions';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { classNames, isValidEmail, isValidPhoneNumber } from 'utilities/utils';

interface Props {
  searchLoading?: boolean;
  customers: ISearchCustomer[];
  selectedProject: SelectedTeamInterface;
  selectedCustomer: ISearchCustomer | null;
  updateCustomerList: (list: ISearchCustomer[]) => void;
  handleCustomerSelect: (customer: ISearchCustomer) => void;
  searchCustomer: (query: string) => void;
}
const CustomerListView: React.FC<Props> = ({
  customers,
  searchCustomer,
  selectedProject,
  selectedCustomer,
  searchLoading = false,
  handleCustomerSelect,
  updateCustomerList,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const buttonRef = useRef(null);
  const [query, setQuery] = useState('');
  const [selectedPerson, setSelectedPerson] = useState<ISearchCustomer | null>(
    null
  );

  const searchDebounce = useDebounce(query, 500);

  const { emailChannelCount, whatsAppChannelCount } =
    getWhatsappAndEmailChannelCount(selectedProject);

  useEffect(() => {
    if (
      query !== '' &&
      !(!!selectedCustomer && selectedCustomer?.customer_primary_id === query)
    ) {
      searchCustomer(query);
    }
    //eslint-disable-next-line
  }, [searchDebounce]);

  useEffect(() => {
    if (!!selectedPerson) {
      handleCustomerSelect(selectedPerson);
    }
    //eslint-disable-next-line
  }, [selectedPerson]);

  useEffect(() => {
    if (!!selectedCustomer) {
      setQuery(selectedCustomer?.customer_primary_id);
    }
  }, [selectedCustomer]);

  const handleNewCustomer = () => {
    const newCustomer = {
      customer_primary_id: query,
      id: -1,
      customer_name: '',
      platform_type: '',
      platform_title: '',
      customer_avatar: '',
      platform_primary_id: '',
    };

    const index = customers.findIndex(
      (customer: ISearchCustomer) => customer?.id === -1
    );

    if (index > -1) {
      customers[index] = newCustomer;
    } else {
      customers.push(newCustomer);
    }

    updateCustomerList([...customers]);
    setSelectedPerson(newCustomer);
  };

  const renderCreateOptionView = () => {
    if (query === '') return null;
    else if (isValidPhoneNumber(query) || isValidEmail(query)) {
      return (
        <div className='py-2 px-3'>
          <p
            className='font-medium text-sm cursor-pointer'
            onClick={() => {
              handleNewCustomer();
            }}
          >
            <span className='text-primary '>{t('Create customer for')} </span>"
            {query}"
          </p>
        </div>
      );
    } else {
      const alertStr =
        (!!emailChannelCount && !!whatsAppChannelCount) ||
        (emailChannelCount === 0 && whatsAppChannelCount === 0)
          ? t(
              'To create a new customer you need to enter a valid Email address or WhatsApp number with country code.'
            )
          : emailChannelCount > 0
          ? t(
              'To create a new customer you need to enter a valid Email address.'
            )
          : t(
              'To create a new customer you need to enter a valid WhatsApp number with country code'
            );
      return (
        <div className='py-2 px-3'>
          <div className='p-2 bg-blue-50 flex items-center rounded flex-1 gap-2'>
            <p className='font-medium text-sm text-blue-500'>{alertStr}</p>
          </div>
        </div>
      );
    }
  };

  const renderLoadingPanel = () => {
    return (
      <div className='w-full p-10 flex items-center justify-center'>
        <div
          className='inline-block w-5 h-5 text-primary border-2 rounded-full spinner-border animate-spin'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  };

  const mainPanelView = () => {
    if (searchLoading) {
      return renderLoadingPanel();
    } else {
      return (
        <>
          {customers.length > 0 &&
            customers.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 px-3',
                    active ? 'bg-gray-100 text-gray-800' : 'text-gray-800'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className='flex'>
                      <div>
                        <span
                          className={classNames(
                            'truncate text-gray-900 ltr:ml-auto rtl:mr-auto flex text-sm ',
                            selected
                              ? 'font-semibold'
                              : active
                              ? 'font-medium'
                              : 'font-normal'
                          )}
                        >
                          {person?.customer_primary_id}
                        </span>
                        <div
                          data-tip={person?.platform_title}
                          data-for={`${person?.id + person?.platform_title}`}
                          className={classNames(
                            'truncate text-gray-500 ltr:ml-auto rtl:mr-auto block text-xs mt-1 flex items-center gap-1 rtl:flex-row-reverse',
                            selected
                              ? 'font-semibold'
                              : active
                              ? 'font-medium'
                              : 'font-normal'
                          )}
                        >
                          <span>
                            {!!person?.platform_title &&
                            person?.platform_title?.length > 15
                              ? isRtlLanguage
                                ? '...' + person?.platform_title.slice(0, 13)
                                : person?.platform_title.slice(0, 13) + '...'
                              : person?.platform_title}
                          </span>
                          <span>{' | '}</span>
                          <span>{person?.platform_primary_id}</span>
                        </div>
                        {!!person?.platform_title &&
                          person?.platform_title?.length > 15 && (
                            <ReactTooltip
                              key={person?.id}
                              id={`${person?.id + person?.platform_title}`}
                              place='top'
                              type='dark'
                              effect='float'
                            />
                          )}
                      </div>
                      <span
                        className={classNames(
                          'truncate text-gray-500 ltr:ml-auto rtl:mr-auto',
                          selected
                            ? 'font-semibold'
                            : active
                            ? 'font-medium'
                            : 'font-normal'
                        )}
                      >
                        {t(person?.customer_name)}
                      </span>
                    </div>

                    {/** may need on future improvement */}
                    {/* {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-2',
                          active ? 'text-white' : 'text-primary'
                        )}
                      >
                        <CheckIcon className='h-3 w-3' aria-hidden='true' />
                      </span>
                    )} */}
                  </>
                )}
              </Combobox.Option>
            ))}
          {customers.length < 1 &&
            selectedCustomer?.customer_primary_id !== query &&
            renderCreateOptionView()}
        </>
      );
    }
  };

  const handleOpenOptions = () => {
    if (!!buttonRef && !!query) {
      //@ts-ignore
      buttonRef?.current?.click();
    }
  };

  const renderSubText = () => {
    const text = !!selectedPerson
      ? selectedPerson?.customer_primary_id?.length > 25
        ? selectedPerson?.customer_primary_id?.slice(0, 21) + '...'
        : selectedPerson?.customer_primary_id
      : '';
    if (!!selectedPerson && !!selectedPerson?.customer_name && query === text) {
      return (
        <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center text-sm text-gray-500 rounded-r-md px-2 focus:outline-none'>
          {t(selectedPerson?.customer_name)}
        </span>
      );
    } else return '';
  };
  const renderLabelText = () => {
    if (whatsAppChannelCount > 0 && emailChannelCount > 0) {
      return 'Email or WhatsApp number';
    } else if (whatsAppChannelCount > 0) {
      return 'WhatsApp number';
    } else {
      return emailChannelCount > 0
        ? 'Email Address'
        : 'Email Address or WhatsApp number';
    }
  };

  return (
    <Combobox
      as='div'
      className='text-left'
      value={selectedPerson}
      onChange={(person: any) => {
        setSelectedPerson(person);
      }}
    >
      <Combobox.Label
        htmlFor='customer_primary_id_input'
        className='block text-sm rtl:text-right font-medium leading-6 text-gray-900 cursor-text'
      >
        {t(`Enter ${renderLabelText()}`)}
      </Combobox.Label>
      <div className='relative mt-2'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3'>
          <MagnifyingGlassIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <Combobox.Input
          id='customer_primary_id_input'
          data-tip={selectedPerson?.customer_primary_id}
          data-for={'selected_id_label'}
          placeholder={t('Search customer by email or phone number')}
          className='w-full rounded-md border-0  bg-white py-1.5 px-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person: ISearchCustomer) => {
            if (!!person) {
              return person?.customer_primary_id?.length > 25
                ? person?.customer_primary_id.slice(0, 21) + '...'
                : person?.customer_primary_id;
            } else {
              return '';
            }
          }}
          onFocus={() => {
            handleOpenOptions();
          }}
        />
        {!!selectedPerson &&
          selectedPerson?.customer_primary_id?.length > 25 && (
            <ReactTooltip
              id={'selected_id_label'}
              place='top'
              type='dark'
              effect='float'
            />
          )}
        {renderSubText()}
        <Combobox.Button
          className='absolute inset-y-0 ltr:right-0 rtl:left-0 hidden items-center rounded-r-md px-2 focus:outline-none'
          ref={buttonRef}
        />

        {query !== '' && selectedPerson?.customer_primary_id !== query && (
          <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {mainPanelView()}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default CustomerListView;
