import React, { memo, forwardRef } from 'react';
import { classNames } from '../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  children: any;
  header: string;
  isOpen?: boolean;
  intent?: 'active' | 'default';
  titleClassName?: string;
  hasSharpEdge?: boolean;
  className?: string;
  isBorderShown?: boolean;
}

const getIntentClass: any = (intent: 'active' | 'default') => {
  switch (intent) {
    case 'active':
      return 'bg-secondary text-white';
    case 'default':
    default:
      return 'bg-white text-gray-800';
  }
};

const getClasses: any = (
  className: string,
  isShown: boolean,
  intent: string
) => {
  const conditionalStyles = isShown ? getIntentClass(intent) : 'rounded-b-md';
  return classNames(
    !!className
      ? className
      : `rounded-t-md ${conditionalStyles} p-3 justify-between shadow ${
          isShown ? `${getIntentClass(intent)}` : ''
        }`,
    'bg-white flex justify-between'
  );
};

const Collapse: React.FC<Prop> = forwardRef(
  (
    {
      children,
      header,
      isOpen = false,
      intent = 'default',
      titleClassName,
      hasSharpEdge = false,
      className = '',
      isBorderShown = true,
    },
    ref: any
  ) => {
    const [isShown, setIsShown] = React.useState(!!isOpen);
    const { t } = useTranslation();
    return (
      <div ref={ref}>
        <div
          className={getClasses(className, isShown, intent)}
          style={!!hasSharpEdge ? { borderRadius: 0 } : {}}
        >
          <p
            className={`font-medium text-md ${
              !!titleClassName ? titleClassName : ''
            }`}
          >
            {t(header)}
          </p>
          <div
            onClick={() => {
              setIsShown(!isShown);
            }}
          >
            {isShown ? (
              <div className='cursor-pointer text-sm font-medium text-gray-500'>
                {t('Hide')}&nbsp;
              </div>
            ) : (
              <div className='cursor-pointer text-sm font-medium text-gray-500'>
                {t('Show')}&nbsp;
              </div>
            )}
          </div>
        </div>
        {isShown && (
          <div className={`${isBorderShown ? 'border-t' : ''}`}>{children}</div>
        )}
      </div>
    );
  }
);

export default memo(Collapse);
