import useAgent from '../hooks/useAgent';
import { ITicketInfo } from '../interface';
import BannerView from '../common/BannerView';
import NumberCard from '../common/NumberCard';
import { CardOptionsForAgentView } from '../utilities/contents';
import AgentTicketTableView from '../components/AgentTicketTable';
import useTranslation from 'components/customHooks/useTranslation';

const AgentView = () => {
  const { t } = useTranslation();
  const { loading, agentData, redirectToTicket } = useAgent();
  const getCardValue = (index: number) => {
    switch (index) {
      case 0:
        return agentData?.assigned ?? 0;
      case 1:
        return agentData?.replied ?? 0;
      default:
        return agentData?.unreplied ?? 0;
    }
  };
  return (
    <div className='w-full'>
      <div className='my-5'>
        <BannerView
          title={t('Quick glimpse of your current tickets status.')}
          subTitle={t('Get an overview of your ticket status.')}
          bgColor='#DBEAFE'
          type='agent'
        />
      </div>
      <ul className='grid grid-cols-1 gap-0 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 my-5 shadow rounded-lg'>
        {CardOptionsForAgentView.map((option: ITicketInfo, index: number) => (
          <li className='col-span-1 flex flex-col' key={index}>
            <NumberCard
              loading={loading}
              color={option?.color}
              title={t(option?.title)}
              value={getCardValue(index)}
              tooltipText={t(option?.tooltip)}
              className={
                index === 0
                  ? 'ltr:rounded-l-lg rtl:rounded-r-lg'
                  : index === CardOptionsForAgentView?.length - 1
                  ? 'ltr:rounded-r-lg rtl:rounded-l-lg ltr:border-l rtl:border-r border-gray-200'
                  : 'ltr:border-l rtl:border-r border-gray-200'
              }
            />
          </li>
        ))}
      </ul>
      <div className='my-5'>
        <AgentTicketTableView
          loading={loading}
          redirectToTicket={redirectToTicket}
          agentTicketData={agentData?.tickets ?? null}
          unrepliedTicketCount={agentData?.unreplied ?? 0}
        />
      </div>
    </div>
  );
};

export default AgentView;
