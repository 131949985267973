import React from 'react';
import SettingsFormContainer from './SettingsFormContainer';
import CurrencyOptionsDropdown from './CurrencyOptionsDropdown.tsx';
import ImageUploadField from '../../../../components/utilityComponent/ImageUploadField';

import { TextInputField, Textarea } from 'evergreen-ui';
import TimezoneOptionsDropdown from './TimezoneOptionsDropdown';
import CopyText from '../../../components/elements/redesign/CopyText';
import useTranslation from 'components/customHooks/useTranslation';

const TeamInfoSettingsForm = ({
  title,
  description,
  infoLoader,
  infoUpdateLoader,
  isInfoChanged,
  handleSave,
  handleCancel,
  projectInfo,
  handleNameChange,
  handleDescChange,
  handleImageChange,
  handleImageClear,
  hasWriteAccess,
  countryList,
  countryDetails,
  handleCountryChange,
  timezoneList,
  timezoneDetails,
  handleTimezoneChange,
}) => {
  const { t } = useTranslation();
  return (
    <SettingsFormContainer
      id='team-settings'
      title={title}
      description={description}
      infoLoader={infoLoader}
      hasData={!!projectInfo}
      infoUpdateLoader={infoUpdateLoader}
      isInfoChanged={isInfoChanged}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <>
        <TextInputField
          className='settings-input rounded-md'
          label={t('Team Name')}
          disabled={!hasWriteAccess}
          onChange={(e) => handleNameChange(e.target.value)}
          value={!!projectInfo ? projectInfo.name : ''}
          placeholder={t('Please provide name of the team...')}
        />

        <div className='mb-6'>
          <label
            htmlFor='api-key'
            className='block text-sm font-medium text-gray-700'
          >
            {t('API Key')}
          </label>
          <div className='mt-1 relative '>
            <input
              type='text'
              name='api-key'
              id='api-key'
              disabled={true}
              value={!!projectInfo ? projectInfo.api_key : ''}
              className='text-gray-400 leading-5 font-normal focus:ring-green-500 focus:border-green-500 block w-full ltr:pr-10 sm:text-sm border-gray-300 settings-input bg-gray-200 rounded-md rtl:text-left ltr:text-right'
            />
            <div className='absolute ltr:right-1/4 rtl:left-1/4 w-9/12 top-2'>
              <div className='float-right mr-2'>
                <CopyText
                  height={24}
                  textToCopy={!!projectInfo ? projectInfo.api_key : ''}
                />
              </div>
            </div>
          </div>
        </div>

        <p className='input-label'>{t('Team Description')}</p>
        <Textarea
          className='settings-input rounded-md'
          onChange={(e) => handleDescChange(e.target.value)}
          value={!!projectInfo ? projectInfo.description : ''}
          placeholder={t('write description here...')}
        />
        <br />
        <br />
        <p className='input-label'>{t('Team Avatar')}</p>
        <ImageUploadField
          hasWriteAccess={hasWriteAccess}
          image={!!projectInfo ? projectInfo.image : ''}
          handleDrop={(image) => handleImageChange(image)}
          handleClear={() => handleImageClear()}
        />
        <br />
        <br />
        <p className='flex items-center font-sans text-sm font-medium text-gray-600'>
          {t('Country / Region & Currency')}
        </p>
        <CurrencyOptionsDropdown
          selected={countryDetails}
          options={countryList}
          handleChange={(data) => handleCountryChange(data)}
        />
        <br />
        <p className='flex items-center font-sans text-sm font-medium text-gray-600'>
          {t('Timezone')}
        </p>
        <div className='z-10'>
          <TimezoneOptionsDropdown
            selected={timezoneDetails}
            options={timezoneList}
            handleChange={(data) => handleTimezoneChange(data)}
          />
        </div>
        <br />
        {/* <SelectMenuSearch
          search={search}
          setSearch={setSearch}
          title='Search for your country'
          label='Your e-commerce related data will have this currency and country
          by default.'
          selected={{
            primaryValue: countryDetails?.country,
            secondaryValue: countryDetails.currency_name,
            avatar: `https://www.countryflags.io/${countryDetails?.country_iso_code?.toLowerCase()}/flat/64.png`,
          }}
          isSearchAvailable={countryList.length > 5}
          showChecked={true}
          onSelect={(data) => {
            handleCountryChange(data);
          }}
          options={countryList.map((country) => ({
            ...country,
            primaryValue: country.country,
            secondaryValue: country.currency_name,
            avatar: `https://www.countryflags.io/${country?.country_iso_code?.toLowerCase()}/flat/64.png`,
          }))}
        /> */}
        {/* <p className='flex items-center font-sans text-sm font-medium text-gray-600'>
          Timezone
          <span className='ml-1' title='Timezone Details'>
            <InformationCircleIcon className='h-3 w-3 mt-0.5' />
          </span>
        </p>
        <SelectMenuSearch
          search={search}
          setSearch={setSearch}
          title='Search for your timezone'
          selected={{
            primaryValue: `${timezoneDetails?.timezone_offset_type}${timezoneDetails?.timezone_offset}`,
            secondaryValue: timezoneDetails.timezone_alias,
          }}
          isSearchAvailable={timezoneList.length > 5}
          showChecked={true}
          onSelect={(data) => {
            handleTimezoneChange(data);
          }}
          options={timezoneList.map((timezone) => ({
            ...timezone,
            primaryValue: `${timezone?.timezone_offset_type}${timezone?.timezone_offset}`,
            secondaryValue: timezone.timezone_alias,
          }))}
        /> */}
      </>
    </SettingsFormContainer>
  );
};

export default TeamInfoSettingsForm;
