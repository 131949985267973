import React, { useState } from 'react';
import { connect } from 'react-redux';
import { subscriptionDetailsType } from '../interface';
import ChangeBillingCycleModal from './ChangeBillingCycleModal';
import { Link } from '@reach/router';
import { plansProperty } from '../../../utilities/content';
import { SelectedTeamInterface } from '../../../index';
import dayjs from 'dayjs';
import useTranslation from 'components/customHooks/useTranslation';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface subscriptionProps {
  subscriptionDetails: subscriptionDetailsType;
  selectedProject: SelectedTeamInterface;
}

const SubscriptionDetails: React.FC<subscriptionProps> = ({
  subscriptionDetails,
  selectedProject,
}) => {
  const { t } = useTranslation();
  const [openBillingCycleModal, setOpenBillingCycleModal] = useState(false);
  const subscriptionPlan =
    subscriptionDetails?.project_billing_info?.subscription_plan;

  const isPaidProject = () => {
    return selectedProject?.is_pro && selectedProject?.subscription_anchor_time;
  };
  const renderSubscriptionDetailsAppSumoProject = () => {
    return (
      <>
        <p className='text-gray-900 text-lg font-medium mb-4'>
          {t('Subscription Details')}
        </p>
        <div className='border border-gray-200 rounded-md mb-4'>
          <div className='px-8 py-6'>
            <p className='text-gray-400 text-sm mb-2'>{t('Active Plan')}</p>
            <p className='text-black-400 text-lg mb-2 capitalize'>
              {t(subscriptionPlan?.name)}
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderSubscriptionDetails = () => {
    return (
      <>
        <p className='text-gray-900 text-lg font-medium mb-4'>
          {t('Subscription Details')}
        </p>
        <div className='flex border border-gray-200 rounded-md mb-4'>
          <div className='border-r px-8 py-6 w-1/2'>
            <p className='text-gray-500 text-sm font-medium mb-2'>
              {t('Active Plan')}
            </p>
            <div className='flex align-center mb-1'>
              {subscriptionDetails?.trial_remaining !== 0 &&
                !isPaidProject() && (
                  <>
                    <span className='text-gray-900 text-lg leading-6 font-medium'>
                      {t('Free Trial')}{' '}
                    </span>
                    {subscriptionDetails?.trial_remaining === 1 && (
                      <span className='px-1 py-.5 bg-red-100 text-xs font-medium text-red-800 rounded ltr:ml-2 rtl:mr-2'>
                        {t(
                          `{{${subscriptionDetails?.trial_remaining}}} days left`
                        )}
                      </span>
                    )}
                    {subscriptionDetails?.trial_remaining > 1 &&
                      subscriptionDetails?.trial_remaining < 4 && (
                        <span className='px-1 py-.5 bg-yellow-100 text-xs font-medium text-yellow-800 rounded ltr:ml-2 rtl:mr-2'>
                          {t(
                            `{{${subscriptionDetails?.trial_remaining}}} days left`
                          )}
                        </span>
                      )}
                    {subscriptionDetails?.trial_remaining >= 4 && (
                      <span className='px-1 py-.5 bg-green-100 text-xs font-medium text-green-800 rounded ltr:ml-2 rtl:mr-2'>
                        {t(
                          `{{${subscriptionDetails?.trial_remaining}}} days left`
                        )}
                      </span>
                    )}
                  </>
                )}
              {subscriptionDetails?.trial_remaining === 0 &&
                !isPaidProject() && (
                  <span className='text-gray-900 text-lg leading-6 font-medium'>
                    - -
                  </span>
                )}
              {!!isPaidProject() && (
                <>
                  <span className='text-gray-900 text-lg leading-6 font-medium capitalize'>
                    {subscriptionPlan?.name} {subscriptionPlan?.billing_cycle}
                  </span>
                  <span className='px-1 py-.5 bg-green-100 text-xs font-medium text-green-800 rounded ml-2'>
                    {
                      subscriptionDetails?.project_billing_info
                        ?.max_team_members
                    }{' '}
                    members
                  </span>
                </>
              )}
            </div>
            {!!isPaidProject() &&
              [
                plansProperty.premium_plan.name,
                plansProperty.business_plan.name,
              ].includes(subscriptionPlan?.name) && (
                <Link
                  to='/settings/billing/upgrade?plan_type=update-team'
                  className='text-xs text-[#0078CF] capitalize'
                >
                  {t('Update Team Size')}
                </Link>
              )}
          </div>
          <div className='px-8 py-6 w-1/2'>
            {!!isPaidProject() && (
              <>
                <p className='text-gray-500 text-sm font-medium mb-2'>
                  {t('Plan Renewal Date')}
                </p>
                <p className='text-gray-900 text-lg leading-6 font-medium flex align-center mb-1'>
                  {!!subscriptionPlan?.billing_end_date ? (
                    <>
                      {dayjs(
                        parseInt(subscriptionPlan?.billing_end_date) * 1000
                      ).format('DD MMM YYYY')}
                    </>
                  ) : (
                    '- - - -'
                  )}
                </p>
              </>
            )}
            {!isPaidProject() && !!subscriptionDetails?.trial_start_date && (
              <>
                {selectedProject?.creator?.is_trial_user && (
                  <>
                    <p className='text-gray-500 text-sm font-medium mb-2'>
                      {t('Trial Started')}
                    </p>
                    <p className='text-gray-900 text-lg leading-6 font-medium flex align-center mb-1'>
                      {dayjs(
                        subscriptionDetails?.trial_start_date,
                        'YYYY-MM-DD',
                        true
                      ).format('DD MMM YYYY')}
                    </p>
                  </>
                )}
                {!selectedProject?.creator?.is_trial_user && (
                  <>
                    <p className='text-gray-500 text-sm font-medium mb-2'>
                      Trial Ended
                    </p>
                    <p className='text-gray-900 text-lg leading-6 font-medium flex align-center mb-1'>
                      {dayjs(
                        subscriptionDetails?.trial_start_date,
                        'YYYY-MM-DD',
                        true
                      )
                        .add(14, 'day')
                        .format('DD MMM YYYY')}
                    </p>
                  </>
                )}
              </>
            )}
            {!!isPaidProject() &&
              [
                plansProperty.premium_plan.name,
                plansProperty.business_plan.name,
              ].includes(subscriptionPlan?.name) && (
                <p
                  className='text-xs text-[#0078CF] cursor-pointer capitalize'
                  onClick={() => setOpenBillingCycleModal(true)}
                >
                  {t('Change billing cycle')}
                </p>
              )}
          </div>
        </div>
        <div className='flex border border-gray-200 rounded-md mb-8'>
          <div className='p-6 w-3/4'>
            <p className='text-sm text-gray-900 font-medium'>
              {t('Available Credits')}
            </p>
            <p className='text-sm text-gray-500 font-normal'>
              {t(
                'Your available credits will be used first to adjust your billing.'
              )}
            </p>
          </div>
          <div className='p-6 w-1/4 flex align-center justify-end'>
            <p className='text-lg text-gray-900 font-medium'>
              ${subscriptionDetails?.credit}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {subscriptionDetails && subscriptionPlan && (
        <>
          {subscriptionDetails?.project_billing_info?.is_appsumo_project &&
            renderSubscriptionDetailsAppSumoProject()}
          {!subscriptionDetails?.project_billing_info?.is_appsumo_project &&
            renderSubscriptionDetails()}
        </>
      )}
      {openBillingCycleModal && (
        <ChangeBillingCycleModal
          setOpenBillingCycleModal={setOpenBillingCycleModal}
        />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

export default connect(mapState)(SubscriptionDetails);
