import React, { useEffect, useState } from 'react';
import HasPermission from '../../../components/higherOrderComponents/HasPermission';
import { connect } from 'react-redux';
import { checkPermission } from '../../../utilities/utils';
import RolesDropdown from './component/RoleDropdown';
import AlertModal from './component/AlertModal';
import Modal from '../../billing/component/Modal';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { InformationCircleIcon as InformationCircleIconOutline } from '@heroicons/react/24/outline';
import SimpleDropDown from './component/SimpleDropDown';
import invitationImage from './assets/sendInvitationLoading.jpg';
import {
  availablePlansProperty,
  subscriptionDetailsType,
} from '../../billing/interface';
import SelectModal from './component/SelectModal';
import moment from 'moment';
import DefaultAvatar from '../../../assets/images/defaultAvatar.svg';
import { navigate } from '@reach/router';
import { plansProperty } from '../../../utilities/content';
import {
  PermissionInterface,
  ProjectsRolesDataType,
  SelectedTeamInterface,
  UserRolesType,
} from '../../../index';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface TeamUserSettingsProps {
  fetchProject: () => { data: SelectedTeamInterface[]; success: boolean };
  fetchProjectAccess: (projectId: number) => void;
  sendTeamInvitation: (teamId: number, email: string, roleId: number) => void;
  updateUserFromTeam: (teamId: number, userId: number, roleId: number) => void;
  deleteUserFromTeam: (teamId: number, userId: number) => boolean;
  setSelectedProject: (payload: SelectedTeamInterface) => void;
  fetchPermissionRole: (projectId: number) => void;
  projects: SelectedTeamInterface[];
  userEmail: string;
  selectedProject: SelectedTeamInterface;
  previousProject: SelectedTeamInterface;
  infoLoader: boolean;
  permissionList: PermissionInterface[] | [];
  projectRolesData: ProjectsRolesDataType[];
  userRolesType: UserRolesType[];
  sendMultipleInvitations: (payload: {
    teamId: number;
    data: invitationsType;
  }) => { emails: { email: string; role: string }[]; success: boolean };
  fetchAvailablePlans: () => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  subscriptionDetails: subscriptionDetailsType | null;
  availablePlan: availablePlansProperty;
  selectedProjectInfo: SelectedTeamInterface;
  singleSendTeamInvitation: (payload: {
    teamId: number;
    email: string | number | boolean;
    roleId: string | number | boolean;
  }) => { success: boolean; data: string };
}

interface fieldsType {
  id?: string;
  email: string | number | boolean;
  role_id: number | string | boolean;
  show?: number | string | boolean;
}

interface invitationsType {
  invitations: intivationsBody[];
}

interface intivationsBody {
  email: string | number | boolean;
  role_id: string | number | boolean;
}

const TeamUserSettings: React.FC<TeamUserSettingsProps> = ({
  fetchProject,
  fetchProjectAccess,
  sendTeamInvitation,
  updateUserFromTeam,
  deleteUserFromTeam,
  setSelectedProject,
  fetchPermissionRole,
  projects,
  userEmail,
  selectedProject,
  previousProject,
  infoLoader,
  permissionList,
  projectRolesData,
  userRolesType,
  sendMultipleInvitations,
  fetchAvailablePlans,
  fetchSubscriptionDetails,
  availablePlan,
  subscriptionDetails,
  selectedProjectInfo,
  singleSendTeamInvitation,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const IdGenerate = () => {
    return '_' + Math.random().toString(36).slice(2, 9);
  };

  // const [isAddShown, setIsAddShown] = useState(false);
  const [isUpdateShown, setIsUpdateShown] = useState(false);
  const [isDeleteShown, setIsDeleteShown] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  // const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  // const [dataLoading, setDataLoading] = useState(true);
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  //@ts-ignore
  const [invitations, setInvitations] = useState<fieldsType[]>([
    { id: IdGenerate(), email: '', role_id: 1, show: false },
  ]);
  // const [oldUserInvitationsresponse, setOldUserInvitationsresponse] =
  //   useState('');
  const [modalState, setModalState] = useState(1);
  const [successfulMessage, setSeccessfulMessage] = useState<
    { email: string; role: string }[] | null
  >(null);
  const [showMemberLimitMessage, setShowMemberLimitMessage] = useState(false);
  const [deletedUserName, setDeletedUserName] = useState('');

  const initialFucntionality = async () => {
    const res = await fetchProject();

    if (res.success) {
      if (!previousProject && selectedProject === null) {
        setSelectedProject(res.data[0]);
        await fetchPermissionRole(res.data[0].id);
        await fetchProjectAccess(res.data[0].id);
      }

      if (previousProject) {
        const selProject = res.data.filter(
          (e) => e.id === previousProject.id
        )[0];
        setSelectedProject(selProject);
        fetchPermissionRole(selProject.id);
        fetchProjectAccess(selProject.id);
      }
      const writeAccess = checkPermission(
        permissionList,
        'write:settings_user_management'
      );
      setWriteAccess(writeAccess);
      fetchAvailablePlans();
      fetchSubscriptionDetails(selectedProject.id);
    }
  };

  useEffect(() => {
    initialFucntionality();
    // eslint-disable-next-line
  }, []);

  const clearState = () => {
    // setIsAddShown(false);
    setIsDeleteShown(false);
    setIsUpdateShown(false);
    setSelected(null);
    setUserId(null);
    // setEmail('');
    // setLoading(false);
    // setDataLoading(false);
  };

  const addInvitionField = (index: number) => {
    const currentProject = subscriptionDetails
      ? subscriptionDetails.project_billing_info.subscription_plan.name
      : '';
    const invitationRemaining = subscriptionDetails
      ? subscriptionDetails.project_billing_info.max_team_members -
        invitations.length -
        selectedProject.team_members
      : 0;

    if (selectedProject.is_using_new_billing) {
      if (
        (currentProject === plansProperty.premium_plan.name &&
          invitationRemaining > 0) ||
        (currentProject === plansProperty.enterprise_plan.name &&
          invitationRemaining > 0)
      ) {
        setShowMemberLimitMessage(false);
        //@ts-ignore
        setInvitations((invitations) => [
          ...invitations,
          { id: index, email: '', role_id: 1, show: false },
        ]);
        return;
      }

      if (
        currentProject === plansProperty.free_plan.name &&
        invitationRemaining > 0
      ) {
        //@ts-ignore
        setInvitations((invitations) => [
          ...invitations,
          { id: index, email: '', role_id: 1, show: false },
        ]);
      } else {
        setShowMemberLimitMessage(true);
        return;
      }
    }
  };

  const deleteInvitationField = (id: string | undefined) => {
    let filter = invitations.filter((fields) => fields.id !== id);
    setInvitations(filter);
    setShowMemberLimitMessage(false);
  };

  const editField = (
    index: number,
    value: string | number | boolean,
    type: string
  ) => {
    switch (type) {
      case 'email':
        let newValue = [...invitations];
        newValue[index].email = value;
        newValue[index].show = false;
        setInvitations(newValue);
        break;
      case 'role_id':
        let newAraay = [...invitations];
        newAraay[index].role_id = value;
        newAraay[index].show = false;
        setInvitations(newAraay);
        break;
      case 'show':
        let showAraay = [...invitations];
        showAraay[index].show = value;
        setInvitations(showAraay);
        break;
      default:
        break;
    }
  };

  const initiateInvite = async (e: any) => {
    e.preventDefault();
    setModalState(2);

    if (selectedProject.is_using_new_billing) {
      let invitationsData = invitations.map((invitation) => {
        return { email: invitation.email, role_id: invitation.role_id };
      });

      let invitationsPayload = {
        invitations: invitationsData,
      };
      const payload = {
        teamId: selectedProject.id,
        data: invitationsPayload,
      };

      const isValid = invitationsData.find(
        (invitation) => invitation.email === ''
      );

      if (isValid) {
        setModalState(1);
        return;
      } else {
        const res = await sendMultipleInvitations(payload);

        if (res.success) {
          setModalState(3);
          setSeccessfulMessage(res.emails);
          setInvitations([
            { id: IdGenerate(), email: '', role_id: 1, show: false },
          ]);
          initialFucntionality();
          setShowMemberLimitMessage(false);
        } else {
          setModalState(4);
          setInvitations([
            { id: IdGenerate(), email: '', role_id: 1, show: false },
          ]);
          initialFucntionality();
          setShowMemberLimitMessage(false);
        }
      }
    } else {
      let payload = {
        teamId: selectedProject.id,
        email: invitations[0].email,
        roleId: invitations[0].role_id,
      };
      const res = await singleSendTeamInvitation(payload);
      if (res.success) {
        setModalState(3);
        setInvitations([
          { id: IdGenerate(), email: '', role_id: 1, show: false },
        ]);
        initialFucntionality();
      } else {
        setModalState(4);
        setInvitations([
          { id: IdGenerate(), email: '', role_id: 1, show: false },
        ]);
        initialFucntionality();
      }
    }
  };

  const inviteModalComponent = () => {
    switch (modalState) {
      case 1:
        return (
          <>
            <div className='mt-5 mb-5'>
              <p className='text-base font-bold text-center text-gray-900'>
                {t('Invite Members')}
              </p>
              <p className='text-sm text-center text-gray-400'>
                {t('We will send an invitation link via email on your behalf')}
              </p>
            </div>
            <div>
              <p className='mb-1 text-sm rtl:text-right ltr:text-left text-gray-500'>
                {t('Send Invite To')}
              </p>
              <form action='' onSubmit={(e) => initiateInvite(e)}>
                {invitations.map((inviteMembers, index) => {
                  return (
                    <div className='relative flex gap-2 mb-2 border rounded'>
                      <input
                        id={`${inviteMembers.id}`}
                        onChange={(e) => {
                          editField(index, e.target.value, 'email');
                        }}
                        type='email'
                        className='w-2/3 px-3 py-2 border-none rounded-md focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500'
                        placeholder='john@gmail.com'
                        name='email'
                      />
                      <SimpleDropDown
                        index={index}
                        value={inviteMembers.role_id}
                        show={inviteMembers.show && inviteMembers.show}
                        editField={editField}
                        userRolesType={userRolesType}
                      />
                      {index !== 0 && (
                        <XCircleIcon
                          className='text-red-500 absolute right-[-5px] top-[-5px] w-4 h-4 cursor-pointer'
                          onClick={() =>
                            deleteInvitationField(
                              inviteMembers.id ? inviteMembers.id : undefined
                            )
                          }
                        />
                      )}
                    </div>
                  );
                })}
                <div>
                  {showMemberLimitMessage ? (
                    <p className='text-[#B45309] text-sm mb-5 bg-[#FFFBEB] p-2'>
                      {t(
                        `You cannot invite more members as your team limit has been reached. Please`
                      )}{' '}
                      <a
                        href='/settings/billing'
                        className='font-semibold underline'
                        rel='noreferrer'
                      >
                        {t('upgrade your plan')}
                      </a>{' '}
                      {t('to add new members.')}
                    </p>
                  ) : (
                    <>
                      {selectedProject.is_using_new_billing ? (
                        <p
                          className='mb-5 text-sm rtl:text-right text-blue-500 cursor-pointer'
                          // @ts-ignore
                          onClick={() => addInvitionField(IdGenerate())}
                        >
                          {t('Invite another team member')}
                        </p>
                      ) : null}
                    </>
                  )}
                </div>
                <button
                  type='submit'
                  className='w-full p-2 text-base text-white bg-green-500 rounded-md'
                  // onClick={}
                >
                  {t('Invite Member')}
                </button>
              </form>
            </div>
          </>
        );
      case 2:
        return (
          <div className='flex flex-col items-center justify-center'>
            <img
              className='w-[220px] h-[220px] mb-5'
              src={invitationImage}
              alt='invitation loading'
            />
            <p className='text-base font-bold text-gray-900'>
              {t('Sending invitation to your member')}
            </p>
            <p className='text-sm text-gray-400'>
              {t(`${partnerName} is better with more members`)}
            </p>
          </div>
        );
      case 3:
        return (
          <div className='flex flex-col items-center justify-center py-5'>
            <CheckCircleIcon className='w-12 h-12 mb-3 text-green-200' />
            <p className='mb-1 text-lg font-bold text-gray-900'>
              {t('Invitation Sent')}
            </p>
            {selectedProject.is_using_new_billing ? (
              <p className='mb-5 text-sm text-center text-gray-400'>
                {t('We have sent an invitation to')}
                {successfulMessage &&
                  successfulMessage.map((info, index) => {
                    if (successfulMessage.length > 1) {
                      return (
                        <>
                          {index === successfulMessage.length}
                          &nbsp;{info.email}(as {info.role})
                          {index === successfulMessage.length - 1 ? '' : ','}
                        </>
                      );
                    } else {
                      return (
                        <>
                          &nbsp;{info.email} (as {info.role}){' '}
                        </>
                      );
                    }
                  })}
                {''}{' '}
                {t(
                  'to join in your team. Once they join, you can assign them groups and tickets.'
                )}
              </p>
            ) : (
              <p className='mb-5 text-sm text-center text-gray-400'>
                {t(
                  'We have sent an invitation to join in your team. Once he/she join, you can assign them groups and tickets.'
                )}
              </p>
            )}

            <button
              className='bg-green-500 p-2.5 w-full rounded-md text-white'
              onClick={() => {
                setOpenInvitationModal(false);
                setModalState(1);
              }}
            >
              {t('Continue')}
            </button>
          </div>
        );
      case 4:
        return (
          <div className='flex flex-col items-center justify-center pt-5 pb-3'>
            <ExclamationTriangleIcon className='w-12 h-12 text-red-500 mb-3 bg-red-100 rounded-[50%] p-2' />
            <p className='mb-1 text-lg font-bold text-gray-900'>
              {t('Unsuccessful request')}
            </p>
            <p className='mb-5 text-sm text-center text-gray-400'>
              {t(
                'Your request to send invitations is unsuccessful. Please try again. Please'
              )}{' '}
              <a
                target='_blank'
                href='https://myalice.ai/contact/'
                className='text-blue-500'
                rel='noreferrer'
              >
                {t('contact support')}
              </a>{' '}
              {t('if this issue persists.')}
            </p>
            <button
              className='border-2 p-2.5 w-full rounded-md text-gray-500 mb-3'
              onClick={(e) => initiateInvite(e)}
            >
              {t('Try Again')}
            </button>
            <button
              className='border-2 p-2.5 w-full rounded-md text-gray-500'
              onClick={() => {
                setOpenInvitationModal(false);
                setModalState(1);
              }}
            >
              {t('Cancel')}
            </button>
          </div>
        );
      case 5:
        return (
          <div className='flex flex-col items-center justify-center py-5'>
            <InformationCircleIconOutline className='w-12 h-12 p-3 mb-3 text-blue-400 bg-blue-50 rounded-3xl' />
            <p className='mb-2 text-lg font-medium text-gray-900'>
              {t('Maximum limit reached')}
            </p>
            {selectedProject?.creator?.is_trial_user ||
            !selectedProject?.is_pro ? (
              <>
                <p className='mb-5 text-sm text-center text-gray-500 w-80'>
                  {t(
                    'You have already reached the maximum seat limit for your plan. Please upgrade your subscription to continue.'
                  )}
                </p>
                <button
                  className='w-full text-white text-base font-medium p-2.5 mb-3 bg-primary rounded-md'
                  onClick={() => navigate('/settings/billing')}
                >
                  {t('Upgrade Plan')}
                </button>
              </>
            ) : (
              <>
                <p className='mb-5 text-sm text-center text-gray-500 w-80'>
                  {t(
                    'You have already reached the maximum seat limit for your plan. You can add more members by updating your team size.'
                  )}
                </p>
                <button
                  className='w-full text-white text-base font-medium p-2.5 mb-3 bg-primary rounded-md'
                  onClick={() =>
                    navigate('/settings/billing/upgrade?plan_type=update-team')
                  }
                >
                  {t('Update Team Size')}
                </button>
              </>
            )}

            <button
              className='w-full text-gray-600 text-base font-medium p-2.5 border rounded-md'
              onClick={() => {
                setOpenInvitationModal(false);
                setModalState(1);
              }}
            >
              {t('Cancel')}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const getTime = (timeStamp: any) => {
    let now = new Date();
    let start = moment(timeStamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    let end = moment(now);
    // @ts-ignore
    let difference = end.diff(start);
    let lastLogin = moment.utc(difference).format('H:m');
    let time = lastLogin.toString().split(':');
    return `${time[0]}h ${time[1]}m ago`;
  };

  const denyAddMember = () => {
    if (selectedProject?.is_using_new_billing && subscriptionDetails) {
      return (
        selectedProject.team_members >=
        subscriptionDetails.project_billing_info.max_team_members
      );
    }
    return false;
  };

  const isRoleChangeable = (user: any) => {
    const userRole = projectRolesData.filter(
      (e) => e?.admin?.email === userEmail
    )[0];
    if (
      [4088, 6996, 6997].includes(selectedProjectInfo?.id) &&
      [18, 21, 24].includes(userRole?.role.id)
    ) {
      if (user?.role?.name.includes('Agent')) return true;
      return false;
    }
    return user?.is_deletable;
  };

  const isDeletable = (user: any) => {
    const userRole = projectRolesData.filter(
      (e) => e?.admin?.email === userEmail
    )[0];
    if (
      [4088, 6996, 6997].includes(selectedProjectInfo?.id) &&
      [18, 21, 24].includes(userRole?.role.id)
    ) {
      return false;
    }
    return user?.is_deletable;
  };

  const RenderUserRole = (user: any) => {
    if (user?.admin?.email === selectedProjectInfo?.creator?.email) {
      return <p>{t('Owner')}</p>;
    } else {
      if (writeAccess && isRoleChangeable(user)) {
        return (
          <>
            <RolesDropdown
              role={t(user?.role?.name)}
              userRolesType={userRolesType}
              setIsUpdateShown={setIsUpdateShown}
              onSelect={() => {
                setUserId(user.admin.id);
              }}
              setSelected={setSelected}
            />
          </>
        );
      } else {
        return <p>{t(user.role.name)}</p>;
      }
    }
  };

  const filteredUser = () => {
    let parnterEmailList = ['@myalice.ai'];
    if (currentPartnerInfo?.emailDomains) {
      parnterEmailList = [
        ...parnterEmailList,
        ...currentPartnerInfo.emailDomains,
      ];
    }
    const isPartnerUser = parnterEmailList.some((email) =>
      userEmail.endsWith(email)
    );

    if (currentPartnerInfo?.type !== 'white-label' || isPartnerUser) {
      return projectRolesData;
    }
    return projectRolesData.filter((value: ProjectsRolesDataType) => {
      // don't show user with partner email list
      return !parnterEmailList.some((email) =>
        value.admin.email.endsWith(email)
      );
    });
  };

  return (
    <HasPermission shortCode={'read:settings'} isSettingsView>
      {subscriptionDetails && (
        <>
          <div className='flex justify-between mt-5 mb-5'>
            <div id='billing-history-heading' className='flex mb-3'>
              <p className='text-lg font-medium leading-6 text-gray-900'>
                {t('Team Members')}
              </p>
              {selectedProject.is_using_new_billing ? (
                <p className='px-2 py-1 ltr:ml-3 rtl:mr-3 text-gray-500 bg-green-100 rounded-md min-w-20'>
                  {selectedProject.team_members}/
                  {subscriptionDetails.project_billing_info.max_team_members}{' '}
                  {t('Seats')}
                </p>
              ) : null}
            </div>

            <div className='flex justify-end'>
              <button
                type='button'
                className='px-5 py-2 text-green-500 bg-green-100 rounded-md'
                onClick={() => {
                  if (denyAddMember()) {
                    if (selectedProject?.is_creator) {
                      setModalState(5);
                      setOpenInvitationModal(true);
                    } else {
                      toaster.danger(
                        t(
                          'Please contact your team owner to upgrade your subscription limits'
                        ),
                        {
                          duration: 3,
                        }
                      );
                    }
                  } else {
                    setOpenInvitationModal(true);
                  }
                }}
              >
                {t('Add New Member')}
              </button>
            </div>
          </div>
          {selectedProject.is_using_new_billing && (
            <>
              {subscriptionDetails.project_billing_info.max_team_members -
                projectRolesData.length >
              0 ? (
                <div className='bg-[#EFF6FF] p-3 flex items-center gap-3 rounded-md mb-5'>
                  <div>
                    <InformationCircleIcon className='w-5 h-5 text-blue-500' />
                  </div>
                  <p className='text-blue-500 text-[12px]'>
                    {t(
                      `Your subscription plan includes {{${
                        subscriptionDetails.project_billing_info
                          .max_team_members
                      }}} agent seats in the team. You can invite {{${
                        subscriptionDetails.project_billing_info
                          .max_team_members - projectRolesData.length
                      }}} more member!`
                    )}
                  </p>
                </div>
              ) : (
                <>
                  {projectRolesData.find((item) => item.is_disabled) !==
                    undefined && (
                    <div className='bg-[#FFFBEB] p-3 flex items-center gap-3 rounded-md mb-5'>
                      <div className='text-[#B45309] text-[12px]'>
                        {t(
                          'Some of your members have been disabled because of changes in your subscription plan. You can enable them by upgrading your subscription. Please'
                        )}{' '}
                        <a
                          target='_blank'
                          href='https://www.myalice.ai/contact'
                          className='text-blue-500'
                          rel='noreferrer'
                        >
                          {t('contact support')}
                        </a>{' '}
                        {t('if you think this is an error.')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className='mb-5 border border-gray-200 rounded-md'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  >
                    {t('Name')}
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  >
                    {t('Last Log in')}
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  >
                    {t('User Role')}
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  ></th>
                </tr>
              </thead>

              <tbody className='bg-white divide-y divide-gray-200'>
                {filteredUser()?.map((user, index) => {
                  return (
                    <>
                      {' '}
                      <tr key={index} className='relative'>
                        <td
                          className={`${
                            user.is_disabled ? 'bg-gray-200' : ''
                          } px-6 py-4 text-sm rtl:text-right font-medium text-gray-900 whitespace-nowrap`}
                        >
                          <div className='flex items-center w-full gap-2'>
                            {user.admin.avatar ? (
                              <img
                                className='w-10 h-10 rounded-full'
                                src={user.admin.avatar}
                                alt='Rounded avatar'
                              />
                            ) : (
                              <img
                                className='w-10 h-10 rounded-full'
                                src={DefaultAvatar}
                                alt='Rounded avatar'
                              />
                            )}
                            <div className='flex flex-col'>
                              {!user.is_confirmed ? (
                                <>
                                  <div className='flex items-center truncate'>
                                    <div className='px-2 py-1 text-sm text-gray-500 bg-gray-100 rounded-md'>
                                      {t('Pending')}
                                    </div>
                                  </div>
                                  <div className='truncate w-[200px] text-gray-400 text-sm rtl:text-right'>
                                    {user.admin.email}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className='flex items-center truncate'>
                                    <div className='truncate min-w-[150px]'>
                                      {user.admin.full_name}
                                    </div>
                                    {user.is_disabled && (
                                      <div className='px-2 py-1 ml-2 text-sm text-red-800 bg-red-100 rounded-md'>
                                        {t('Disabled')}
                                      </div>
                                    )}
                                  </div>
                                  <div className='truncate w-[200px] text-gray-400 text-sm rtl:text-right'>
                                    {user.admin.email}
                                  </div>{' '}
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td
                          className={`${
                            user.is_disabled ? 'bg-gray-200' : ''
                          } px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap`}
                        >
                          {getTime(user.admin.last_login)}
                        </td>
                        <td
                          className={`${
                            user.is_disabled ? 'bg-gray-200' : ''
                          } px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap`}
                        >
                          {RenderUserRole(user)}
                        </td>
                        {!writeAccess ||
                        !isDeletable(user) ||
                        userEmail === user.admin.email ? (
                          <td
                            className={`${
                              user.is_disabled ? 'bg-gray-200' : ''
                            } px-6 py-4 rtl:text-right text-sm whitespace-nowrap cursor-pointer text-gray-500`}
                          >
                            {t('Remove')}
                          </td>
                        ) : (
                          <td
                            className={`${
                              user.is_disabled ? 'bg-gray-200' : ''
                            } px-6 py-4 text-sm rtl:text-right whitespace-nowrap cursor-pointer text-red-500`}
                            onClick={() => {
                              setIsDeleteShown(true);
                              setUserId(user.admin.id);
                              setDeletedUserName(user.admin.full_name);
                            }}
                          >
                            {t('Remove')}
                          </td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <SelectModal
              isShown={isUpdateShown}
              intent={'warning'}
              title='Update'
              description=' Are you sure want to update role of the user?'
              confirmButtonTitle='Yes! Do it!'
              cancelButtonTitle='Cancel'
              onConfirm={async () => {
                //@ts-ignore
                if (userId && selected) {
                  await updateUserFromTeam(
                    selectedProject.id,
                    userId,
                    //@ts-ignore
                    selected
                  );

                  await fetchProjectAccess(selectedProject.id);
                  clearState();
                }
              }}
              onToggle={() => setIsUpdateShown(false)}
            />

            <AlertModal
              isShown={isDeleteShown}
              intent={'danger'}
              title={t('Delete')}
              description={t('Are you sure want to remove the user?')}
              confirmButtonTitle='Remove'
              cancelButtonTitle='Cancel'
              onConfirm={async () => {
                if (userId) {
                  await deleteUserFromTeam(selectedProject.id, userId);
                  await fetchProjectAccess(selectedProject.id);
                  clearState();
                }
              }}
              onToggle={() => setIsDeleteShown(false)}
              onAddMember={() => {
                if (denyAddMember()) {
                  if (selectedProject?.is_creator) {
                    setModalState(5);
                    setOpenInvitationModal(true);
                  } else {
                    toaster.danger(
                      'Please contact your team owner to upgrade your subscription limits',
                      {
                        duration: 3,
                      }
                    );
                  }
                } else {
                  setOpenInvitationModal(true);
                }
              }}
              deleteUserFromTeam={deleteUserFromTeam}
              fetchProjectAccess={fetchProjectAccess}
              clearState={clearState}
              selectedProjectId={selectedProject.id}
              userId={userId}
              deletedUserName={deletedUserName}
              setDeletedUserName={setDeletedUserName}
              initialFucntionality={initialFucntionality}
            />
          </div>
          {openInvitationModal && (
            <Modal>
              <div className='relative p-5'>
                <XMarkIcon
                  className='absolute w-5 h-5 text-gray-500 top-[20px] right-[20px] cursor-pointer'
                  onClick={() => {
                    setOpenInvitationModal(false);
                    setModalState(1);
                    setShowMemberLimitMessage(false);
                  }}
                />
                {inviteModalComponent()}
              </div>
            </Modal>
          )}
        </>
      )}
    </HasPermission>
  );
};

const mapState = (state: any) => ({
  projects: state.dashboard.projects,
  userEmail: state.auth.email,
  selectedProject: state.dashboard.selectedProject,
  previousProject: state.dashboard.selectedProject,
  infoLoader: state.loading.effects.dashboard.fetchProject,
  permissionList: state.dashboard.permission.list || [],
  projectRolesData: state.settings.currentProjectRoles,
  userRolesType: state.settings.userRolesType,
  availablePlan: state.newBilling.availablePlans,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  selectedProjectInfo: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchProjectAccess: (projectId: number) =>
    dispatch.settings.fetchProjectAccess(projectId),
  sendTeamInvitation: (teamId: number, email: string, roleId: number) =>
    dispatch.settings.sendTeamInvitation({ teamId, email, roleId }),
  updateUserFromTeam: (teamId: number, userId: number, roleId: number) =>
    dispatch.settings.updateUserFromTeam({ teamId, userId, roleId }),
  deleteUserFromTeam: (teamId: number, userId: number) =>
    dispatch.settings.deleteUserFromTeam({ teamId, userId }),
  setSelectedProject: (payload: SelectedTeamInterface) =>
    dispatch.settings.setSelectedProject(payload),
  fetchPermissionRole: (projectId: number) =>
    dispatch.settings.fetchPermissionRole(projectId),
  sendMultipleInvitations: (payload: {
    teamId: number;
    data: invitationsType;
  }) => dispatch.settings.sendMultipleTeamInvitation(payload),
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  singleSendTeamInvitation: (payload: {
    teamId: number;
    email: string | number | boolean;
    roleId: string | number | boolean;
  }) => dispatch.settings.sendTeamInvitation(payload),
});

export default connect(mapState, mapDispatch)(TeamUserSettings);
