import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import EmptyTableCellsIcon from '../../assets/images/EmptyTableIcon.svg';

interface props {
  tableData: tableProps[];
}

interface tableProps {
  name: string;
  count: number;
}

const TagCloudTableData: React.FC<props> = ({ tableData }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col'>
      <div className='-my-2'>
        <div className='inline-block min-w-full py-1 align-middle'>
          {tableData.length !== 0 && (
            <>
              <table className='min-w-full min-h-full mt-5 border-t border-b divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='py-3 ltr:pl-5 rtl:pr-5 text-xs font-normal tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'>
                      {t('TAGS')}
                    </th>
                    <th className='py-3 ltr:pl-5 rtl:pr-5 text-xs font-normal tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'></th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {!!tableData &&
                    tableData.map((elem: any, index: number) => (
                      <tr key={index}>
                        <td className='px-6 py-4 text-sm rtl:text-right font-medium text-gray-900 whitespace-nowrap'>
                          {elem.name}
                        </td>
                        <td className='px-6 py-4 text-sm rtl:text-right font-medium text-right text-green-900 whitespace-nowrap'>
                          <span className='p-1 text-green-800 bg-green-100 rounded'>
                            {elem.count}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
          {tableData.length === 0 && (
            <div className='flex flex-col items-center mt-5 border-t'>
              <img
                src={EmptyTableCellsIcon}
                alt={'empty table'}
                className={'w-20 mt-28'}
              />
              <p className='my-8 text-sm font-normal'>
                {t('No data available for this timeline')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagCloudTableData;
