import React from 'react';

interface Props {}

const CustomApi: React.FC<Props> = () => {
  return (
    <div className='flex items-center justify-center h-full'>
      Custom API Coming Soon...
    </div>
  );
};
export default CustomApi;
