/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import { classNames } from 'utilities/utils';
import { getAuthTranslationData } from '../utils/authTemplateTranslation';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import useTranslation from 'components/customHooks/useTranslation';

interface WhatsappTemplateFooterProps {
  actionType: WhatsappTemplateActionType;
}

const WhatsappTemplateFooter: React.FC<WhatsappTemplateFooterProps> = ({
  actionType,
}) => {
  const { t } = useTranslation();
  const { updateTemplateFooter, whatsappTemplateState } =
    useWhatsappTemplateData();

  const isTemplateActionView = actionType === 'VIEW';
  const isAuthenticationTemplate =
    whatsappTemplateState?.category === 'AUTHENTICATION';

  const handleFooter = (ev: ChangeEvent<HTMLInputElement>) => {
    updateTemplateFooter(ev.target.value);
  };

  const copyButtonText = getAuthTranslationData(
    'copy_text',
    whatsappTemplateState?.language
  );
  const isDisabled = isTemplateActionView || isAuthenticationTemplate;

  const renderAuthTemplateFooter = () => {
    return (
      <div className='bg-white rounded-md shadow-sm p-4'>
        <label
          htmlFor='footer'
          className='block text-sm font-medium text-gray-700'
        >
          {t('Button Text')}
        </label>
        <div
          className={classNames(
            'flex border border-gray-300 focus-within:border-1 focus-within:border-primary focus-within:ring-1 focus-within:ring-primary mt-1 rounded-md shadow-sm realtive w-full items-center  gap-2',
            isTemplateActionView
              ? 'bg-gray-200 cursor-not-allowed'
              : 'bg-white cursor-default'
          )}
        >
          <input
            maxLength={60}
            type='text'
            disabled={true}
            placeholder={copyButtonText}
            name='footer-value'
            className={
              'w-[100%] sm:text-sm border-none focus:ring-0 focus:border-none rounded-md bg-gray-200 cursor-not-allowed'
            }
          />
        </div>
      </div>
    );
  };

  const renderDefaultAuthFooter = () => {
    return (
      <div className='bg-white rounded-md shadow-sm p-4'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='footer'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Footer(Optional)')}
          </label>

          <div
            className={`flex border border-gray-300 text-gray-700 focus-within:border-1
             focus-within:border-primary focus-within:ring-1 focus-within:ring-primary
              mt-1 rounded-md shadow-sm realtive w-full items-center gap-2
              ${
                isDisabled
                  ? 'bg-gray-200 cursor-not-allowed'
                  : 'bg-white cursor-default'
              }
              `}
          >
            <input
              maxLength={60}
              type='text'
              disabled={isDisabled}
              placeholder={t('Type your footer text')}
              value={whatsappTemplateState.footer}
              onChange={handleFooter}
              name='footer-value'
              className={classNames(
                'w-full sm:text-sm border-none focus:ring-0 focus:border-none rounded-md',
                isDisabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'
              )}
            />
            <div
              className={`rounded-md text-center text-xs py-1 px-2 ltr:mr-3 rtl:ml-3 bg-green-100 text-green-800`}
            >
              {whatsappTemplateState.footer.length}/60
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isAuthenticationTemplate
        ? renderAuthTemplateFooter()
        : renderDefaultAuthFooter()}
    </>
  );
};

export default WhatsappTemplateFooter;
