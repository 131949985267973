import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { GroupsListType, ProjectsRolesDataType } from 'index';
import { classNames } from '../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  options: {
    label: string;
    options: OptionTypes[];
  }[];
  selectedValue:
    | ProjectsRolesDataType[]
    | GroupsListType[]
    | OptionTypes
    | null;
  handleOnChnage: (
    data: ProjectsRolesDataType | GroupsListType,
    type: string
  ) => void;
  enableBot: boolean;
  setIsUnassigned: (payload: boolean) => void;
}

interface OptionTypes {
  value: null;
  full_name: string;
  type: string;
  is_smart?: boolean;
}

const AgentsDropdown: React.FC<Props> = ({
  options,
  selectedValue,
  handleOnChnage,
  enableBot,
  setIsUnassigned,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<
    ProjectsRolesDataType | GroupsListType | null
  >(null);

  const updateSelectedValueFromProps = () => {
    if (!!selectedValue) {
      setSelected(selectedValue[0]);
    } else setSelected(null);
  };

  useEffect(() => {
    updateSelectedValueFromProps();
    //eslint-disable-next-line
  }, [selectedValue]);

  const handleValueOnChange = (
    option: ProjectsRolesDataType | GroupsListType | null
  ) => {
    setSelected(option);
    if (!!option) {
      handleOnChnage(option, option.type);
      if (option?.full_name === 'Unassigned') {
        setIsUnassigned(true);
      }
    }
  };

  return (
    <Listbox
      value={selected}
      onChange={handleValueOnChange}
      disabled={enableBot ? true : false}
    >
      {({ open }) => (
        <>
          <div className='relative'>
            <Listbox.Button
              className={`${
                enableBot ? 'bg-gray-100' : ''
              } relative w-full bg-white border border-gray-300
            rounded-md shadow-sm ltr:pl-3 ltr:pr-10 rtl:pr-3 py-2 ltr:text-left rtl:text-right cursor-default focus:outline-none
            focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            >
              <span className='flex items-center'>
                {!selected ? (
                  <span className='ml-3 block truncate'>
                    {t('Select how or whom to assign initiated tickets')}
                  </span>
                ) : (
                  <span className='ml-3 block truncate'>
                    {selected?.full_name || selected.name}{' '}
                    {selected.is_smart && '(SMART)'}
                  </span>
                )}
              </span>
              <span className='ml-3 absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md
              py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
              >
                {options.map((type, index) => {
                  if (
                    type.options[0]?.full_name === 'Unassigned' &&
                    !selected
                  ) {
                    return '';
                  } else {
                    return (
                      <div key={index}>
                        {type.options.length > 0 && (
                          <div key={type.label}>
                            <p className='text-gray-800 flex p-2 bg-gray-100'>
                              {type.label}
                            </p>
                            {type.options.map((option, index) => {
                              return (
                                <Listbox.Option
                                  key={type.label + index}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-green-500'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={option}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className='flex items-center'>
                                        <span
                                          className={classNames(
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'ml-3 block truncate'
                                          )}
                                        >
                                          {option?.full_name}
                                          {option.is_smart && ' (SMART)'}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default AgentsDropdown;
