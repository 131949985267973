import { useSelector, useDispatch } from 'pages/datalab/export';

const useActions = () => {
  const { controller, dataLabData, unsavedTrack } = useSelector(
    (state: any) => state.datalabFormBuilder
  );

  const dispatch = useDispatch();

  const getAddedFormFields = () => {
    const labFields = dataLabData?.lab_fields?.filter(
      (field: any) => !field.parent_id
    );

    return labFields || [];
  };

  // get controller from state
  const getController = () => controller;

  // Get loading status
  const getLoadingStatus = () => controller?.isLoading || false;

  // get dataLabData from state
  const getDataLabData = () => dataLabData;

  // update datalab data
  const updateDataLabData = (data: any) => {
    dispatch.datalabFormBuilder.updateDataLabData(data);
  };

  const getSelectedFieldId = () => {
    return controller?.selectedFieldId || null;
  };

  const setSelectedFieldId = (fieldId: string | null) => {
    dispatch.datalabFormBuilder.setSelectedField({ fieldId });
  };

  const updateController = (controller: any) => {
    return dispatch.datalabFormBuilder.updateControllerInfo({ ...controller });
  };

  // unsaved track
  const getUnsavedTrack = () => {
    return unsavedTrack || {};
  };

  const clearState = () => {
    dispatch.datalabFormBuilder.clearState();
  };

  return {
    getController,
    getLoadingStatus,
    getAddedFormFields,
    setSelectedFieldId,
    getSelectedFieldId,
    updateController,
    getDataLabData,
    updateDataLabData,
    getUnsavedTrack,
    clearState,
  };
};

export default useActions;
