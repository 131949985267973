import React from 'react';
import SettingsHeader from './SettingsHeader';
import CenterSpinner from '../../../components/elements/CenterSpinner';
import { Alert } from 'evergreen-ui';
import { Button } from '../../../../library';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

const SettingsFormContainer = ({
  id,
  title,
  description,
  hasData,
  infoLoader,
  infoUpdateLoader,
  isInfoChanged,
  handleSave,
  handleCancel,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className='settings-card' id={id}>
      <SettingsHeader title={title} subtitle={description} />
      {infoLoader ? (
        <CenterSpinner />
      ) : hasData ? (
        <div className='settings-card__container'>
          <br />
          {children}
          <br />
          {isInfoChanged && (
            <div className='flex gap-2'>
              <Button
                onClick={handleSave}
                isLoading={infoUpdateLoader}
                intent='primary'
                className='mr-2'
                icon={<CheckIcon />}
              >
                {t('Update')}
              </Button>
              <Button
                onClick={handleCancel}
                intent={'default'}
                isLoading={infoLoader}
                size={'sm'}
                icon={<XMarkIcon />}
              >
                {t('Cancel')}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <br />
          <Alert
            intent='danger'
            title={t(`We weren’t able to fetch {{${title}}}`)}
          />
        </div>
      )}
    </div>
  );
};

export default SettingsFormContainer;
