import { Switch } from '@headlessui/react';
import { classNames as classNamesConcat } from 'utilities/utils';
interface Props {
  label: string;
  isEnabled: boolean;
  classNames?: string;
  onChange: (value: boolean) => void;
}

const SwitchWithRightLabel: React.FC<Props> = ({
  label,
  onChange,
  isEnabled,
  classNames = '',
}) => {
  return (
    <Switch.Group as='div' className='flex items-center'>
      <Switch
        checked={isEnabled}
        onChange={onChange}
        className={classNamesConcat(
          isEnabled ? 'bg-primary' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out without-ring ',
          classNames
        )}
      >
        <span
          aria-hidden='true'
          className={classNamesConcat(
            isEnabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as='span' className='ml-3 text-sm'>
        <span className='font-medium text-gray-900'>{label}</span>{' '}
      </Switch.Label>
    </Switch.Group>
  );
};

export default SwitchWithRightLabel;
