import React, { Component } from 'react';
import Pusher from 'pusher-js';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import { navigate } from '@reach/router';
import moment from 'moment';

import './assets/style/style.scss';

//components
import { HasPermission } from '../../../components';
import CRMReplyInput from './components/CRMReplyInput';
import CRMChat from './components/CRMChat';
import CRMActionBar from './components/CRMActionBar';
import LoaderLine from '../../components/loaders/LoaderLine';
import LeftBar from './LeftBar';
import {
  checkPermission,
  feedChannels,
  initialFilterData,
  messengerChannels,
} from '../../../utilities/utils';
import RightBar from './RightBar';
import CRMFeedPost from './components/CRMFeedPost';
import FilterLeftbar from './components/FilterLeftbar';
import { getIsoDatetimeString } from './utils/functions';
class CRM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCustomer: 0,
      queueType: 'self',
      lineCount: 1,
      inputValue: '',
      maxLineCount: 10,
      readAccess: 'read:conversation',
      writeAccess: 'write:conversation',
      isLoading: true,
      pusherConnection: null,
      pusherChannelId: null,
      feedAction: 'public_reply',
      disable: false,
      searchKeyLeftBar: '',
      isFilterShown: false,
      isFilterApplied: false,
      filterData: initialFilterData,
      oldFilterData: null,
      galleryLimit: 100,
      galleryOffset: 0,
      selectedTemplate: '',
      isFilterResetClicked: false,
    };
    //flag for multiple enter
    this.isSendingMessage = false;
  }

  onKeyPress = async (e) => {
    if (!this.isSendingMessage) {
      const { maxLineCount, inputValue } = this.state;
      const line = inputValue.split(/\r*\n/).length;
      let target = document.getElementById('reply-input');
      this.setState({
        lineCount: line > maxLineCount ? maxLineCount : line,
      });
      if (e.key === 'Enter' && e.shiftKey) {
        this.setState((prevState) => ({
          inputValue: prevState.inputValue,
          lineCount:
            prevState.lineCount >= maxLineCount
              ? maxLineCount
              : prevState.lineCount + 1,
        }));
      } else if (e.key === 'Enter') {
        // block enter
        e.preventDefault();
        //blocking double click if this logic is true then we wont let event enter
        this.isSendingMessage = true;
        if (
          feedChannels.includes(
            this.props.currentTicket?.customer?.platform?.type
          )
        ) {
          if (inputValue.trim().length > 0) {
            await this.props.sendMessageFeed(
              inputValue,
              null,
              this.state.feedAction
            );
          } else {
            toaster.danger('Failed', {
              description: 'You cannot send an empty message',
            });
          }
          target.focus();
          this.setState({
            inputValue: '',
            lineCount: 1,
          });
        } else {
          if (this.checkPermission('direct_message')) {
            if (inputValue.trim().length > 0) {
              await this.props.sendMessage({
                message: inputValue,
                image: null,
                audio: null,
                action: 'direct_message',
              });
            } else {
              toaster.danger('Failed', {
                description: 'You cannot send an empty message',
              });
            }
            target.focus();
            this.setState({
              inputValue: '',
              lineCount: 1,
            });
          } else {
            toaster.danger('Failed', {
              description: "You don't have enough permission to send message",
            });
          }
        }
        // release flag after we are done handling event
        this.isSendingMessage = false;
      }
      this.setState({ disable: false });
    }
  };

  fetchTicketData = async (isResolved) => {
    //this function will always have offset 0 // func for non scroll event;
    this.props.updateStateData('currentTicket', null);
    this.props.updateStateData('offset', 0);
    this.props.updateStateData('chatData', []);
    await this.props.fetchAssignableAdmins(this.props.projectId);
    await this.props.fetchAgentGroup(this.props.projectId);
    const {
      fetchTickets,
      projectId,
      limit,
      fetchCustomerMeta,
      fetchUserInformation,
      fetchMessengerChat,
      fetchFeedData,
      ticketOrder,
      getWhatsAppMesssageTemplates,
    } = this.props;

    if (!!this.props.location.search) {
      await this.handleOnFilter(true);
    } else {
      await fetchTickets(
        projectId,
        this.state.queueType,
        limit,
        0,
        isResolved,
        this.state.searchKeyLeftBar, //search
        'all', //channel
        'all', //agents
        'all', // groups
        'all', // tags
        '', //start
        '', //end
        ticketOrder //ticketOrder
      );
      // fetch rest of data;
      setTimeout(async () => {
        //need this timeout to store data in Queue, added in event loop;
        if (this.props.queue.length > 0) {
          await fetchCustomerMeta(this.props.queue[0].customer?.id);
          fetchUserInformation(this.props.queue[0].customer?.id);
          const platformType = this.props.queue[0].customer.platform?.type;
          const platformId = this.props.queue[0].customer.platform?.id;
          this.setState({
            isLoading: false,
          });
          if (messengerChannels.includes(platformType)) {
            await fetchMessengerChat(this.props.queue[0]?.id);
            if (platformType === 'whatsapp_bsp') {
              await getWhatsAppMesssageTemplates(platformId);
            }
            this.channelEventEmitter(this.props.queue[0]?.customer?.id);
          } else if (feedChannels.includes(platformType)) {
            await fetchFeedData(this.props.queue[0].id);
            this.feedChannelEventEmitter(this.props.queue[0].id);
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }, 0);
    }
  };

  fetchEventTicketData = (event) => {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        if (this.props.queue.length === 1) {
          this.props.updateStateData('currentTicket', event);
          await this.props.fetchCustomerMeta(event.customer?.id);
          this.props.fetchUserInformation(event.customer?.id);
          const platformType = event.customer.platform?.type;
          const platformId = this.props.queue[0].customer.platform?.id;
          this.setState({
            isLoading: false,
          });
          if (messengerChannels.includes(platformType)) {
            await this.props.fetchMessengerChat(this.props.queue[0]?.id);
            if (platformType === 'whatsapp_bsp') {
              await this.props.getWhatsAppMesssageTemplates(platformId);
            }
            this.channelEventEmitter(this.props.queue[0]?.customer?.id);
          } else if (feedChannels.includes(platformType)) {
            await this.props.fetchFeedData(this.props.queue[0].id);
            this.feedChannelEventEmitter(this.props.queue[0].id);
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    );
  };

  fetchProjectInfo = async () => {
    const project = this.props.projects.filter(
      (p) => parseInt(this.props.projectId, 10) === p.id
    )[0];
    await this.props.setSelectedProject(project);

    //first we will check if current project's permission is in loading state?
    if (this.props.permissionLoader) {
      //if true set timeout to 1s and wait for permission to load
      setTimeout(async () => {
        //permission loaded
        const hasPermission = checkPermission(
          this.props.permissionList,
          this.state.readAccess
        );

        if (hasPermission) {
          await this.fetchTicketData(this.state.filterData.isResolved);
        }
      }, 1000);
    } else {
      //permission loaded
      const hasPermission = checkPermission(
        this.props.permissionList,
        this.state.readAccess
      );

      if (hasPermission && !window.location.search) {
        await this.fetchTicketData(this.state.filterData.isResolved);
      }
    }
  };

  connectToPusher = () => {
    // Pusher.logToConsole = true;
    const pusherConnection = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
    });
    this.setState({
      pusherConnection: pusherConnection,
    });
  };

  channelEventEmitter = (customerId) => {
    if (!!this.state.pusherChannelId) {
      this.state.pusherConnection.unsubscribe(this.state.pusherChannelId);
    }
    const channel = this.state.pusherConnection.subscribe(
      `chat-C_${customerId}`
    );
    this.setState({
      pusherChannelId: `chat-C_${customerId}`,
      inputValue: '',
    });

    channel.bind('messages', (event) => {
      this.props.addChatData({ event: event, adminId: this.props.adminId });
    });
  };
  feedChannelEventEmitter = (ticketId) => {
    if (!!this.state.pusherChannelId) {
      this.state.pusherConnection.unsubscribe(this.state.pusherChannelId);
    }
    const channel = this.state.pusherConnection.subscribe(`chat-T_${ticketId}`);
    if (
      this.props.currentTicket?.customer?.platform?.type === 'instagram_feed'
    ) {
      this.setState({
        pusherChannelId: `chat-T_${ticketId}`,
        inputValue: '',
      });
    } else {
      this.setState({
        pusherChannelId: `chat-T_${ticketId}`,
        inputValue: '@' + this.props.currentTicket?.customer?.full_name + ' ',
      });
    }
    channel.bind('messages', (event) => {
      this.props.addFeedReplyData(event);
      // I need to process this
    });
  };

  checkPermission(key) {
    try {
      if (!!this.props.ticketActions && this.props.ticketActions.length > 0) {
        return this.props.ticketActions.filter((item) => item.action === key)[0]
          .is_allowed;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  handleEnCodeData = (key) => {
    const {
      channels,
      isResolved,
      agentSelection,
      agents,
      groups,
      groupSelection,
      tags,
      startDate,
      endDate,
    } = this.state.filterData;
    switch (key) {
      case 'resolved':
        return isResolved;

      case 'queue':
        return this.state.queueType;

      case 'channels':
        //channels
        let channelParams = channels.length === 0 ? 'all' : '';
        channels.forEach((channel, i) => {
          if (i === channels.length - 1) {
            channelParams = channelParams + channel.id;
          } else {
            channelParams = channelParams + channel.id + ',';
          }
        });
        return channelParams;

      case 'agents':
        //agents
        let agentParams = '';
        if (!!agentSelection) {
          agentParams = agentSelection;
        }
        agents.forEach((agent, i) => {
          if (i === agents.length - 1) {
            agentParams = agentParams + agent.value;
          } else {
            agentParams = agentParams + agent.value + ',';
          }
        });

        return agentParams;

      case 'groups':
        //groups
        let groupsParams = '';
        if (!!groupSelection) {
          groupsParams = groupSelection;
        }
        groups.forEach((group, i) => {
          if (i === groups.length - 1) {
            //last index; dont add comma
            groupsParams = groupsParams + group.value;
          } else {
            groupsParams = groupsParams + group.value + ',';
          }
        });
        return groupsParams;

      case 'tags':
        //tags
        let tagParams = tags.length === 0 ? 'all' : '';
        tags.forEach((tag, i) => {
          if (i === tags.length - 1) {
            //last index; dont add comma
            tagParams = tagParams + tag.value;
          } else {
            tagParams = tagParams + tag.value + ',';
          }
        });

        return tagParams;

      case 'startDate':
        return getIsoDatetimeString(
          startDate,
          this.props.selectedProject.timezone_offset
        );
      case 'endDate':
        return getIsoDatetimeString(
          endDate,
          this.props.selectedProject.timezone_offset
        );
      default:
        return;
    }
  };

  handleOnFilter = async (lookupDecode) => {
    const {
      fetchCustomerMeta,
      fetchUserInformation,
      fetchMessengerChat,
      fetchFeedData,
      getWhatsAppMesssageTemplates,
    } = this.props;
    if (!!this.props.location.search && lookupDecode) {
      this.decodeFilter(this.props.location.search);
    } else {
      const { startDate, endDate } = this.state.filterData;
      const search = this.state.searchKeyLeftBar;
      const url = new URL(this.props.location.href);
      let params = new URLSearchParams(url.searchParams);

      params.set('is_resolved', this.handleEnCodeData('resolved'));
      params.set('queue', this.handleEnCodeData('queue'));
      params.set('offset', '0');
      params.set('limit', this.props.limit); // this is actually limit we set offset 0 to next offset+20 is the limit
      params.set('search', search);
      params.set('channels', this.handleEnCodeData('channels'));
      params.set('agents', this.handleEnCodeData('agents'));
      if (!!startDate) {
        params.set('start', this.handleEnCodeData('startDate'));
      } else {
        params.delete('start');
      }
      if (!!endDate) {
        params.set('end', this.handleEnCodeData('endDate'));
      } else {
        params.delete('end');
      }
      params.set('groups', this.handleEnCodeData('groups'));
      params.set('tags', this.handleEnCodeData('tags'));
      params.set('order', this.props.ticketOrder);
      await this.props.fetchTicketsString(
        this.props.projectId,
        params.toString()
      );

      //fetch middle and right bar

      setTimeout(async () => {
        //need this timeout to store data in Queue, added in event loop;
        if (this.props.queue.length > 0) {
          await fetchCustomerMeta(this.props.queue[0].customer?.id);
          await fetchUserInformation(this.props.queue[0].customer?.id);
          const platformType = this.props.queue[0].customer.platform?.type;
          const platformId = this.props.queue[0].customer.platform?.id;
          this.setState({
            isLoading: false,
          });
          if (messengerChannels.includes(platformType)) {
            await fetchMessengerChat(this.props.queue[0]?.id);
            if (platformType === 'whatsapp_bsp') {
              await getWhatsAppMesssageTemplates(platformId);
            }
            this.channelEventEmitter(this.props.queue[0]?.customer?.id);
          } else if (feedChannels.includes(platformType)) {
            await fetchFeedData(this.props.queue[0].id);
            this.feedChannelEventEmitter(this.props.queue[0].id);
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }, 0);
      navigate(`${this.props.location.pathname}?${params.toString()}`);
    }
  };

  updateDecodePairData = (key, value) => {
    switch (key) {
      case 'resolved':
        this.setState({
          filterData: {
            ...this.state.filterData,
            isResolved: parseInt(value, 10),
          },
        });
        return;

      case 'queue':
        this.setState({
          queueType: value,
        });
        return;

      case 'offset':
        this.props.updateStateData('offset', 0);
        return;
      case 'limit':
        this.props.updateStateData('limit', 20);
        return;
      case 'order':
        this.props.updateStateData('ticketOrder', value);
        return;
      case 'search':
        this.setState({
          searchKeyLeftBar: value || '',
        });
        return;
      case 'channels':
        if (value === 'all') {
          this.setState({
            filterData: { ...this.state.filterData, channels: [] },
          });
        } else {
          let channels = [];
          value.split(',').forEach((channelFilter) => {
            !!this.props.platformList &&
              this.props.platformList.forEach((channel) => {
                if (channel.id === parseInt(channelFilter, 10)) {
                  channels = [...channels, channel];
                }
              });
          });
          this.setState({
            filterData: { ...this.state.filterData, channels: channels },
          });
        }
        return;
      case 'agents':
        let agentsData = [];
        if (value === 'all') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: [],
              agentSelection: 'all',
            },
          });
        } else if (value === 'none') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: [],
              agentSelection: 'none',
            },
          });
        } else {
          value.split(',').forEach((agentFilter) => {
            !!this.props.assignableAdmins &&
              this.props.assignableAdmins.forEach((agentsLocal) => {
                if (agentsLocal.admin.id === parseInt(agentFilter, 10)) {
                  agentsData = [
                    ...agentsData,
                    {
                      ...agentsLocal,
                      value: agentsLocal.admin.id,
                      label: agentsLocal.admin.full_name,
                    },
                  ];
                }
              });
          });
          this.setState({
            filterData: {
              ...this.state.filterData,
              agents: agentsData,
              agentSelection: '',
            },
          });
        }
        return;
      case 'groups':
        let groupsData = [];
        if (value === 'all') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: [],
              groupSelection: 'all',
            },
          });
        } else if (value === 'none') {
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: [],
              groupSelection: 'none',
            },
          });
        } else {
          value.split(',').forEach((groupFilter) => {
            !!this.props.agentGroup &&
              this.props.agentGroup.forEach((groupLocal) => {
                if (groupLocal.id === parseInt(groupFilter, 10)) {
                  groupsData = [
                    ...groupsData,
                    {
                      ...groupLocal,
                      value: groupLocal.id,
                      label: groupLocal.name,
                    },
                  ];
                }
              });
          });
          this.setState({
            filterData: {
              ...this.state.filterData,
              groups: groupsData,
              groupSelection: '',
            },
          });
        }
        return;
      case 'tags':
        let tagsData = [];
        if (value === 'all') {
          this.setState({
            filterData: { ...this.state.filterData, tags: [] },
          });
        } else {
          value.split(',').forEach((tagFilter) => {
            !!this.props.tags &&
              this.props.tags.forEach((tagLocal) => {
                if (tagLocal.id === parseInt(tagFilter, 10)) {
                  tagsData = [
                    ...tagsData,
                    { ...tagLocal, value: tagLocal.id, label: tagLocal.name },
                  ];
                }
              });
          });
          this.setState({
            filterData: { ...this.state.filterData, tags: tagsData },
          });
        }
        return;
      case 'start':
        this.setState({
          filterData: { ...this.state.filterData, startDate: moment(value) },
        });
        return;
      case 'end':
        this.setState({
          filterData: { ...this.state.filterData, endDate: moment(value) },
        });
        return;
      default:
        return;
    }
  };

  decodeFilter = (query) => {
    const params = new URLSearchParams(query);
    for (let pair of params.entries()) {
      this.updateDecodePairData(pair[0], pair[1]);
    }
    this.handleOnFilter(false);
  };

  fetchTicketDataOnResolve = async (ticket) => {
    this.props.fetchUserInformation(ticket.customer?.id);
    await this.props.fetchCustomerMeta(ticket.customer?.id);
    this.props.updateStateData('currentTicket', ticket);
    const platformType = ticket.customer.platform?.type;
    const platformId = ticket.customer.platform?.id;
    if (messengerChannels.includes(platformType)) {
      await this.props.fetchMessengerChat(ticket.id);
      if (platformType === 'whatsapp_bsp') {
        await this.props.getWhatsAppMesssageTemplates(platformId);
      }
      this.channelEventEmitter(ticket.customer.id);
    } else if (feedChannels.includes(platformType)) {
      await this.props.fetchFeedData(ticket.id);
      this.feedChannelEventEmitter(ticket.id);
    }
  };

  handleFocusOnResolve = (arr, idx) => {
    let tempIndex = null;
    let tempArray = [...arr];

    // filter new array
    tempArray = tempArray.filter((temp) => temp.id !== idx);

    // find next object
    if (arr[arr.length - 1].id !== idx) {
      //not last index
      let index = arr.findIndex((i) => i.id === idx);
      tempIndex = arr[index + 1];
    } else {
      // last index
      if (tempArray.length !== 0) {
        tempIndex = tempArray[0];
      }
    }
    return { tempArray, tempIndex };
  };

  handleQueueUpdateOnResolve = (ticketId) => {
    const isTicketInQueue =
      this.props.queue.findIndex((i) => i.id === ticketId) >= 0;
    if (isTicketInQueue) {
      const { tempArray, tempIndex } = this.handleFocusOnResolve(
        this.props.queue,
        ticketId
      );
      this.props.updateStateData('queue', tempArray);
      this.props.updateStateData('total', this.props.total - 1);
      if (ticketId === this.props.currentTicket.id) {
        if (this.props.queue.length > 0) {
          this.fetchTicketDataOnResolve(tempIndex);
        } else {
          this.props.updateStateData('customerAttributes', null);
          this.props.updateStateData('tempAttributes', null);
          this.props.updateStateData('customerMeta', null);
          this.props.updateStateData('chatData', []);
          this.props.updateStateData('ticketActions', []);
        }
      }
    }
  };

  handleReOpenTicket = async (
    ticketId,
    note,
    agentId,
    groupId,
    isReopenAnswer
  ) => {
    let data = await this.props.reOpenTicket(ticketId, note, agentId, groupId);

    if (data.success && isReopenAnswer) {
      this.setState({
        filterData: initialFilterData,
        searchKeyLeftBar: data.dataSource.id,
      });
      // we dont need to call api because leftbar has debounce on search value,
      // they will call api when search value changes
    }
    return data.success;
  };

  componentDidMount() {
    this.connectToPusher();
    if (checkPermission(this.props.permissionList, 'generic:crm_supervisor')) {
      this.setState({ queueType: 'all' });
    }
    this.fetchProjectInfo();
    this.props.fetchCannedResponse(this.props.projectId);
    this.props.fetchAllTicketTag();
  }

  validateFilterDateTimeOnSave = () => {
    const { startDate, endDate } = this.state.filterData;
    if (startDate.length !== 0 && endDate.length !== 0) {
      let isValid = new Date(startDate) <= new Date(endDate);
      if (isValid) return true;
      else {
        toaster.danger(
          'End date time must be greater than the start date time'
        );
        return false;
      }
    }
    return true;
  };

  componentWillUnmount() {
    // clear connection
    if (!!this.state.pusherConnection) {
      this.state.pusherConnection.disconnect();
    }
  }

  render() {
    const { projectId } = this.props;
    return (
      <HasPermission shortCode={this.state.readAccess}>
        <div className='crm'>
          {this.state.isLoading && <LoaderLine />}
          <div className='crm-container'>
            <LeftBar
              projectId={projectId}
              location={this.props.location}
              fetchCustomerMeta={this.props.fetchCustomerMeta}
              channelEventEmitter={this.channelEventEmitter}
              feedChannelEventEmitter={this.feedChannelEventEmitter}
              pusherConnection={this.state.pusherConnection}
              filterData={() => this.state.filterData}
              isFilterApplied={this.state.isFilterApplied}
              isFilterResetClicked={this.state.isFilterResetClicked}
              fetchTicket={async (order) => {
                const { fetchTickets, projectId, limit } = this.props;
                await fetchTickets(
                  projectId,
                  this.state.queueType,
                  limit,
                  0,
                  this.handleEnCodeData('resolved'),
                  this.state.searchKeyLeftBar,
                  this.handleEnCodeData('channels'),
                  this.handleEnCodeData('agents'),
                  this.handleEnCodeData('groups'),
                  this.handleEnCodeData('tags'),
                  this.handleEnCodeData('startDate'),
                  this.handleEnCodeData('endDate'),
                  order
                );
              }}
              onFilterClick={() => {
                this.setState({ isFilterShown: !this.state.isFilterShown });
              }}
              fetchEventTicketData={this.fetchEventTicketData}
              handleEnCodeData={this.handleEnCodeData}
              hasWriteAccess={() =>
                checkPermission(
                  this.props.permissionList,
                  this.state.readAccess
                )
              }
              isInboxSupervisor={checkPermission(
                this.props.permissionList,
                'generic:crm_supervisor'
              )}
              getTicketOrder={() => this.props.ticketOrder}
              isResolved={() => this.state.filterData.isResolved}
              queueType={() => this.state.queueType}
              updateQueueType={(data: QueueType) => {
                this.setState(
                  {
                    queueType: data,
                  },
                  () => {
                    this.handleOnFilter(false);
                  }
                );
              }}
              handleResolveEvent={(ticketData) => {
                this.handleQueueUpdateOnResolve(ticketData.id);
              }}
              searchKeyword={this.state.searchKeyLeftBar}
              updateSearch={(value) => {
                this.setState(
                  {
                    searchKeyLeftBar: value,
                  },
                  () => {
                    // if value is ""
                    if (!value) {
                      this.handleOnFilter(false);
                    }
                  }
                );
              }}
              onSearch={() => {
                this.handleOnFilter(false);
              }}
            />
            <div className='customer-chat'>
              <CRMActionBar
                ticketsAvailable={!!this.props.currentTicket}
                selectedQueue={this.props.selectedQueue}
                projectId={projectId}
                isResolved={this.props.currentTicket?.is_resolved}
                currentTicket={this.props.currentTicket}
                assignableAdmins={this.props.assignableAdmins}
                assignAdmin={this.props.assignAdmin}
                fetchAssignableAdmins={this.props.fetchAssignableAdmins}
                agentGroup={this.props.agentGroup}
                reOpenTicket={this.handleReOpenTicket}
                fetchAllTicket={() =>
                  this.fetchTicketData(this.state.filterData.isResolved)
                }
                resolveLoading={this.props.resolveLoading}
                authId={this.props.authId}
                updateStateData={this.props.updateStateData}
                handleAction={async (body, type) => {
                  if (type === 'lock') {
                    //call lock api
                    const res = await this.props.lockConversation(
                      this.props.currentTicket.id,
                      body.is_locked
                    );
                    if (res) {
                      this.props.updateLockedStatus(
                        'is_locked',
                        !this.props.currentTicket.is_locked,
                        this.props.currentTicket.id
                      );
                      toaster.success('Success', {
                        description: `Successfully ${
                          !this.props.currentTicket.is_locked
                            ? 'Locked'
                            : 'Unlocked'
                        } this Ticket`,
                      });
                    } else {
                      toaster.danger('Failed', {
                        description: `Failed to Lock this Ticket`,
                      });
                    }
                  } else if (type === 'resolve') {
                    //resolve
                    const resolveTicketId = this.props.currentTicket.id;
                    const res = await this.props.resolveConversation(
                      resolveTicketId,
                      body.is_resolved
                    );
                    if (res) {
                      toaster.success('Success', {
                        description: `Successfully Resolved this Ticket`,
                      });
                      this.handleQueueUpdateOnResolve(resolveTicketId);
                    }
                  }
                }}
                isBotEnabled={this.props.customerMeta?.bot_enabled}
                handleBotAction={(body) => {
                  const res = this.props.updateBotStatus(
                    body.bot_enabled,
                    this.props.currentTicket.id
                  );
                  if (!res) {
                    toaster.danger('Failed', {
                      description: 'Failed to Change Bot Status',
                    });
                  }
                }}
              />
              {feedChannels.includes(
                this.props.chatData?.platform_info?.type
              ) ? (
                <CRMFeedPost
                  lineCount={this.state.lineCount}
                  ticketsAvailable={!!this.props.currentTicket}
                  chatData={this.props.chatData}
                  loading={this.props.loadingMessage}
                  updateFeedAction={this.props.updateFeedAction}
                />
              ) : (
                <CRMChat
                  lineCount={this.state.lineCount}
                  ticketsAvailable={!!this.props.currentTicket}
                  currentTicket={this.props.currentTicket}
                  chatData={this.props.chatData}
                  loading={this.props.loadingMessage}
                  isLoading={this.state.isLoading}
                />
              )}
              <CRMReplyInput
                inputValue={this.state.inputValue}
                currentTicket={this.props.currentTicket}
                lineCount={this.state.lineCount}
                feedAction={this.state.feedAction}
                updateFeedAction={(value) =>
                  this.setState({ feedAction: value })
                }
                showNote={this.checkPermission('write_note')}
                hasPrivateReply={this.checkPermission('public_reply')}
                disable={
                  this.state.disable ||
                  !this.props.currentTicket ||
                  this.state.filterData.isResolved
                }
                selectedProject={this.props.selectedProject}
                sendNote={(message, image) => {
                  if (
                    feedChannels.includes(
                      this.props.currentTicket?.customer?.platform?.type
                    )
                  ) {
                    if (this.checkPermission('write_note')) {
                      this.props.sendMessageFeed(message, image, 'write_note');
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  } else {
                    if (this.checkPermission('write_note')) {
                      this.props.sendMessage({
                        message: message,
                        image: null,
                        audio: null,
                        action: 'write_note',
                      });
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  }
                }}
                messageSendingLoader={this.props.messageSendingLoader}
                sendMessage={async (messageRequestBody) => {
                  if (
                    feedChannels.includes(
                      this.props.currentTicket?.customer?.platform?.type
                    )
                  ) {
                    await this.props.sendMessageFeed(
                      messageRequestBody.message,
                      messageRequestBody.image,
                      this.state.feedAction
                    );
                  } else {
                    if (
                      messageRequestBody.template &&
                      this.checkPermission('send_template')
                    ) {
                      await this.props.sendMessage({
                        template: messageRequestBody.template,
                        action: 'send_template',
                      });
                      this.setState({
                        inputValue: '',
                        lineCount: 1,
                      });
                    } else if (this.checkPermission('direct_message')) {
                      await this.props.sendMessage({
                        message: messageRequestBody.message,
                        image: messageRequestBody.image,
                        audio: messageRequestBody.audio,
                        // template: this.state.selectedTemplate,
                        action: 'direct_message',
                      });
                      this.setState({
                        inputValue: '',
                        lineCount: 1,
                      });
                    } else {
                      toaster.danger('Failed', {
                        description:
                          "You don't have enough permission to send message",
                      });
                    }
                  }
                }}
                handleChange={(value) => {
                  let lineCountLocal =
                    value.split('\n')?.length || this.state.lineCount;
                  if (lineCountLocal > this.state.maxLineCount) {
                    lineCountLocal = this.state.maxLineCount;
                  }

                  this.setState({
                    inputValue: value,
                    lineCount: lineCountLocal,
                  });
                }}
                resetInput={() => {
                  this.setState({
                    inputValue: '',
                    lineCount: 1,
                    disable: false,
                    selectedTemplate: '',
                  });
                }}
                onKeyPress={this.onKeyPress}
                ref={this.inputRef}
                cannedResponses={this.props.cannedResponses}
                hasWriteAccess={() =>
                  checkPermission(
                    this.props.permissionList,
                    this.state.readAccess
                  )
                }
                selectedTemplate={this.state.selectedTemplate}
                whatsappTemplate={this.props.messageTemplates?.filter(
                  (template) => template.status === 'approved'
                )}
                handleWhatsappTemplate={(templateId) => {
                  let selectedTemplate = this.props.messageTemplates?.filter(
                    (template) => template.id === templateId
                  );
                  if (selectedTemplate) {
                    this.setState({
                      inputValue:
                        'A template named ' +
                        selectedTemplate[0].name +
                        ' will be sent.',
                    });
                    this.setState({ selectedTemplate: selectedTemplate[0].id });
                    this.setState({ disable: true });
                  }
                }}
                // handleCancel={() => this.setState({inputValue: ''})}
              />
            </div>
            <RightBar projectId={projectId} />
          </div>
          <FilterLeftbar
            isFilterShown={this.state.isFilterShown}
            onFilterVisibilityChange={(visibility) => {
              this.setState({ isFilterShown: visibility });
            }}
            location={this.props.location}
            agentGroup={this.props.agentGroup}
            assignableAdmins={this.props.assignableAdmins}
            channelList={this.props.platformList}
            filterData={this.state.filterData}
            oldFilterData={this.state.oldFilterData}
            isResolved={this.state.filterData.isResolved}
            handleReset={async () => {
              const { fetchTickets, projectId, limit, location } = this.props;
              this.setState({ isFilterResetClicked: true });
              await fetchTickets(
                projectId,
                this.state.queueType,
                limit,
                0,
                0,
                this.state.searchKeyLeftBar,
                this.handleEnCodeData('channels'),
                this.handleEnCodeData('agents'),
                this.handleEnCodeData('groups'),
                this.handleEnCodeData('tags'),
                this.handleEnCodeData('startDate'),
                this.handleEnCodeData('endDate'),
                this.props.ticketOrder
              );
              this.setState({
                isFilterShown: false,
                isFilterApplied: false,
                filterData: { ...this.state.filterData, isResolved: 0 },
              });
              navigate(location.origin + location.pathname);
            }}
            tags={this.props.tags}
            updateFilterData={(key, value) => {
              this.setState({
                filterData: { ...this.state.filterData, [key]: value },
              });
            }}
            updateStateData={(key, value) => {
              this.setState({
                [key]: value,
              });
            }}
            handleOnFilter={() => {
              let isValid = this.validateFilterDateTimeOnSave();
              if (isValid) {
                this.handleOnFilter(false);
                this.setState({
                  oldFilterData: null,
                  isFilterApplied: true,
                  isFilterShown: false,
                });
              } else {
                this.setState({ isFilterShown: true });
              }
            }}
          />
        </div>
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  selectedQueue: state.crm.selectedQueue,
  adminId: state.auth.id,
  selectedPlatform: state.crm.selectedPlatform,
  limit: state.crm.limit,
  offset: state.crm.offset,
  ticketOrder: state.crm.ticketOrder,
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  permissionList: state.dashboard.permission?.list || [],
  platformList: state.dashboard.platformList,
  queue: state.crm.queue,
  cannedResponses: state.crm.cannedResponses,
  currentTicket: state.crm.currentTicket,
  chatData: state.crm.chatData,
  customerMeta: state.crm.customerMeta,
  assignableAdmins: state.crm.assginableAdmins,
  ticketActions: state.crm.ticketActions,
  permissionLoader: state.loading.effects.dashboard.fetchRolePermission,
  authId: state.auth.id,
  agentGroup: state.crm.agentGroup,
  messageSendingLoader:
    state.loading.effects.crm.sendMessage ||
    state.loading.effects.crm.sendMessageFeed,
  loadingMessage:
    state.loading.effects.crm.fetchMessengerChat ||
    state.loading.effects.crm.fetchFeedData ||
    state.loading.effects.crm.fetchTickets ||
    state.loading.effects.crm.fetchUserInformation ||
    state.loading.effects.crm.fetchCustomerMeta ||
    state.loading.effects.crm.fetchAgentGroup,
  tags: state.crm.allTicketTags,
  resolveLoading: state.loading.effects.crm.resolveConversation,
  galleryImages: state.crm.galleryImages,
  galleryTotal: state.crm.galleryTotal,
  total: state.crm.total,
  messageTemplates: state.crm.messageTemplates,
});

const mapDispatch = (dispatch) => ({
  fetchTickets: (
    projectId,
    queue,
    limit,
    offset,
    isResolved,
    search,
    channels,
    agents,
    groups,
    tags,
    start,
    end,
    ticketOrder
  ) =>
    dispatch.crm.fetchTickets({
      projectId,
      queue,
      limit,
      offset,
      isResolved,
      search,
      channels,
      agents,
      groups,
      tags,
      start,
      end,
      ticketOrder,
    }),
  updateStateData: (key, value) => dispatch.crm.updateStateData({ key, value }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchCustomerMeta: (customerId) => dispatch.crm.fetchCustomerMeta(customerId),
  updateExpand: (value) => dispatch.crm.updateExpand(value),
  fetchMessengerChat: (ticketId) => dispatch.crm.fetchMessengerChat(ticketId),
  fetchFeedData: (ticketId) => dispatch.crm.fetchFeedData(ticketId),
  sendMessage: (messageRequestBody) =>
    dispatch.crm.sendMessage(messageRequestBody),
  sendMessageFeed: (message, image, action) =>
    dispatch.crm.sendMessageFeed({ message, image, action }),
  // updateTickets: (projectId, ticketId, body) =>
  //   dispatch.crm.updateTickets({ projectId, ticketId, body }),
  resolveConversation: (ticketId, status) =>
    dispatch.crm.resolveConversation({ ticketId, status }),
  lockConversation: (ticketId, status) =>
    dispatch.crm.lockConversation({ ticketId, status }),
  updateLockedStatus: (key, value, id) =>
    dispatch.crm.updateLockedStatus({ key, value, id }),
  editCustomerMeta: (body, customerId) =>
    dispatch.crm.editCustomerMeta({ body, customerId }),
  addChatData: (payload) => dispatch.crm.addChatData(payload),
  fetchAssignableAdmins: (projectId) =>
    dispatch.crm.fetchAssignableAdmins(projectId),
  assignAdmin: (ticket) => dispatch.crm.assignAdmin(ticket),
  fetchAgentGroup: (projectId) => dispatch.crm.fetchAgentGroup(projectId),
  fetchCannedResponse: (projectId) =>
    dispatch.crm.fetchCannedResponse(projectId),
  updateBotStatus: (status, ticketId) =>
    dispatch.crm.updateBotStatus({ status, ticketId }),
  fetchUserInformation: (customerId) =>
    dispatch.crm.fetchUserInformation(customerId),
  updateFeedAction: (action, commentId, type, text) =>
    dispatch.crm.updateFeedAction({ action, commentId, type, text }),
  fetchTicketsString: (projectId, paramsString) =>
    dispatch.crm.fetchTicketsString({ projectId, paramsString }),
  fetchAllTicketTag: () => dispatch.crm.fetchAllTicketTag(),
  updateGalleryImagesAppend: (data) =>
    dispatch.crm.updateGalleryImagesAppend(data),
  updateGalleryImagesUpload: (data) =>
    dispatch.crm.updateGalleryImagesUpload(data),
  addFeedReplyData: (data) => dispatch.crm.addFeedReplyData(data),
  reOpenTicket: (ticketId, note, agentId, groupId) =>
    dispatch.crm.reOpenTicket({ ticketId, note, agentId, groupId }),
  getWhatsAppMesssageTemplates: (platformId) =>
    dispatch.crm.getWhatsAppMesssageTemplates({ platformId }),
});

const CRMContainer = connect(mapState, mapDispatch)(CRM);
export default CRMContainer;
