import React from 'react';
import loadingStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateLoading.svg';
import errorStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateError.svg';
import confirmedStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateSuccess.svg';
import { Link } from '../../../../../../../library';
import CopyText from '../../../../../../components/elements/redesign/CopyText';

interface Prop {
  confirmOrderAPI: () => Promise<any>;
}

const ConfirmOrder: React.FC<Prop> = ({ confirmOrderAPI }) => {
  const [apiState, setAPIState] = React.useState<
    'loading' | 'confirmed' | 'error'
  >('loading');
  const [orderData, setOrderData] = React.useState<any>(null);
  const [errorState, setErrorState] = React.useState<string | null>(null);

  const handleAPICall = async () => {
    const res = await confirmOrderAPI();
    if (res.success) {
      setAPIState('confirmed');
      setOrderData(res.dataSource);
      setErrorState(null);
    } else {
      setAPIState('error');
      setErrorState(res?.error);
    }
  };

  React.useEffect(() => {
    handleAPICall();

    // eslint-disable-next-line
  }, []);

  const getOrderId = () => {
    if (!orderData) {
      return '';
    }
    if (orderData?.ecommerce_type === 'shopify') {
      return orderData?.shopify_order_name || orderData.ecommerce_order_id;
    } else {
      return orderData.ecommerce_order_id;
    }
  };

  const getAPIResponseData = (type: 'loading' | 'confirmed' | 'error') => {
    switch (type) {
      case 'confirmed':
        return {
          image: confirmedStateIcon,
          text: `ORDER-${getOrderId()} has been placed successfully!`,
        };
      case 'error':
        return {
          image: errorStateIcon,
          text:
            errorState || 'We have encountered a problem. Please try again.',
        };
      case 'loading':
      default:
        return {
          image: loadingStateIcon,
          text: 'Finishing up the work. Sit Tight!',
        };
    }
  };
  return (
    <div className='flex items-center justify-center h-96 flex-column'>
      <img
        src={getAPIResponseData(apiState).image}
        alt={apiState}
        width={200}
      />
      <p className='p-8' dangerouslySetInnerHTML={{__html: getAPIResponseData(apiState).text}}/>
      {apiState === 'confirmed' && orderData?.payment_link && (
        <span>
          <Link url={orderData?.payment_link} openInNewTab>
            Open In New Window
          </Link>
          &nbsp;
          <CopyText textToCopy={orderData?.payment_link} />
        </span>
      )}
    </div>
  );
};

export default ConfirmOrder;
