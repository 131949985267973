import React from 'react';
import { Listbox } from '@headlessui/react';
import {
  IDynamicFormSelectOptionProps,
  IDynamicIntegrationFieldProps,
} from 'pages/integration/interface';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { classNames } from 'utilities/utils';
import TextInputWithSubLabel from 'pages/integration/components/common/components/TextInputWithSubLabel';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  fields: IDynamicIntegrationFieldProps[];
  formData: any;
  disable?: boolean;
  onFieldChange: (name: string, value: any) => void;
}

const selectionType = ['single_select', 'multi_select'];

const DynamicForm: React.FC<Props> = ({
  fields,
  formData,
  onFieldChange,
  disable = false,
}) => {
  const { t } = useTranslation();
  const handleSelectData = (type: string, name: string, option: string) => {
    if (type === 'single_select') {
      return onFieldChange(name, option);
    } else {
      if (!formData[name] || formData[name].length < 1) {
        return onFieldChange(name, [option]);
      } else {
        let data = formData[name];
        const index = data?.findIndex((op: string) => op === option);
        index !== -1 ? data.splice(index, 1) : data.push(option);
        return onFieldChange(name, data);
      }
    }
  };

  //single select component view
  const renderSingleSelectView = (
    field: IDynamicIntegrationFieldProps,
    selectedNames: string[]
  ) => {
    return (
      <Listbox
        value={formData[field.name]}
        onChange={(value) =>
          handleSelectData('single_select', field?.name, value)
        }
        disabled={disable}
      >
        <div className='relative flex flex-col mb-5'>
          <label className='text-sm flex font-medium leading-5 mb-2 text-gray-600'>
            {t(field?.label)}{' '}
            {field?.is_required && (
              <span className='text-xs text-red-600'>*</span>
            )}
          </label>
          {field?.description && (
            <span className='text-sm font-normal text-gray-500 leading-4  mb-2'>
              {t(field?.description)}
            </span>
          )}
          <Listbox.Button className='border rounded p-2 w-full my-1 ltr:text-left rtl:text-right'>
            {formData[field.name] ||
              field?.placeholder ||
              t('Select an option')}
          </Listbox.Button>
          <Listbox.Options className='absolute right-0 z-10 w-full top-full mt-1 mb-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 '>
            {field.options?.map((option, optionIndex) => (
              <Listbox.Option key={optionIndex} value={option?.name}>
                {({ active, selected }) => (
                  <div
                    className={`${active ? 'bg-gray-100' : ''} ${
                      formData[field?.name] === option?.name
                        ? 'text-gray-700'
                        : 'text-gray-500'
                    } cursor-pointer select-none p-2 flex items-center`}
                  >
                    {t(option?.label)}
                    {selected && (
                      <CheckIcon className='w-4 h-4 text-primary ltr:ml-auto rtl:mr-auto' />
                    )}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    );
  };

  //multi select component view
  const renderMultiSelectView = (
    field: IDynamicIntegrationFieldProps,
    selectedNames: string[]
  ) => {
    return (
      <Listbox
        value={formData[field.name]}
        onChange={(value) => {
          handleSelectData('multi_select', field?.name, value);
        }}
        disabled={disable}
      >
        {({ open }) => (
          <div className='relative flex flex-col mb-5'>
            <label className='text-sm  flex font-medium leading-5 mb-2 text-gray-600'>
              {t(field?.label)}{' '}
              {field?.is_required && (
                <span className='text-xs text-red-600'>*</span>
              )}
            </label>
            {field?.description && (
              <span className='text-sm font-normal text-gray-500 leading-4  mb-2'>
                {t(field?.description)}
              </span>
            )}
            {!!formData[field?.name] && formData[field?.name].length > 0 && (
              <div className='flex my-2 overflow-x-auto w-full h-auto'>
                {formData[field?.name].map((data: string, index: number) => (
                  <span
                    className='flex items-center justify-center bg-gray-100 rounded-xl px-1 mr-1'
                    key={index}
                  >
                    <span className='px-2 py-1 text-sm text-gray-700 '>
                      {t(data)}
                    </span>
                    <XMarkIcon
                      className='w-4 h-4 text-gray-500 cursor-pointer'
                      onClick={() => {
                        formData[field?.name].splice(index, 1);
                        onFieldChange(field?.name, formData[field?.name]);
                      }}
                    />
                  </span>
                ))}
              </div>
            )}
            <Listbox.Button className='border rounded p-2 w-full my-1 ltr:text-left rtl:text-right'>
              {t('Select options')}
            </Listbox.Button>

            <Listbox.Options className='absolute right-0 z-10 w-full top-full mt-1 mb-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 '>
              {field.options?.map(
                (
                  option: IDynamicFormSelectOptionProps,
                  optionIndex: number
                ) => (
                  <Listbox.Option key={optionIndex} value={option?.name}>
                    {({ active, selected }) => (
                      <div
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } cursor-pointer flex select-none p-2`}
                      >
                        <label>
                          <input
                            type='checkbox'
                            checked={selectedNames.includes(option?.name)}
                            readOnly
                            className={classNames(
                              'ltr:mr-2 rtl:ml-2 text-primary rounded',
                              selectedNames.includes(option?.name)
                                ? 'border-primary'
                                : 'border-gray-300'
                            )}
                          />
                          {t(option?.label)}
                        </label>
                      </div>
                    )}
                  </Listbox.Option>
                )
              )}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
    );
  };

  const renderSelectView = (field: IDynamicIntegrationFieldProps) => {
    const selectedNames =
      field?.type === 'multi_select' && !!formData[field?.name]
        ? formData[field.name].map((data: string) => data)
        : [];
    return (
      <div className='relative  overflow-visible'>
        {field.type === 'single_select'
          ? renderSingleSelectView(field, selectedNames)
          : renderMultiSelectView(field, selectedNames)}
      </div>
    );
  };

  const renderInputView = (field: IDynamicIntegrationFieldProps) => {
    return (
      <div className='mb-5'>
        <TextInputWithSubLabel
          type={field?.type}
          value={formData[field.name]}
          label={field?.label}
          placeHolder={field?.placeholder}
          sublabel={field?.description}
          handleChange={(value) => onFieldChange(field.name, value)}
          error={''}
          disable={disable}
          hidden={field?.is_hidden}
          hintText={field?.help_text}
          isRequired={field?.is_required}
        />
      </div>
    );
  };

  const renderReadOnlyField = (label: string, sublabel: string, value: any) => {
    return (
      <div className='flex flex-col'>
        <label className='text-sm font-medium leading-5 mb-2 text-gray-600'>
          {t(label)}{' '}
        </label>
        {sublabel && (
          <span className='text-sm font-normal text-gray-500 leading-4  mb-2'>
            {t(sublabel)}
          </span>
        )}
        <div className='flex items-center border rounded-md pr-3 py-1 bg-gray-300 cursor-not-allowed'>
          <input
            type='text'
            disabled={true}
            value={value}
            name='disable-field'
            className={
              "block w-full mt-1 border-gray-300 rounded-md shadow-sm 'cursor-not-allowed bg-gray-100' sm:text-sm border-0  pl-3 py-0 without-ring  "
            }
            readOnly
          />
        </div>
      </div>
    );
  };

  return (
    <form>
      {fields.map((field, index) => (
        <div className='w-full' key={index}>
          {field?.is_readonly &&
            renderReadOnlyField(
              field?.label,
              field?.description,
              formData[field.name]
            )}
          {!field?.is_readonly && selectionType.includes(field?.type)
            ? renderSelectView(field)
            : renderInputView(field)}
        </div>
      ))}
    </form>
  );
};

export default DynamicForm;
