import { Button } from 'libraryV2/ui/button';
import { ChevronLeft } from 'lucide-react';
import useThrottledEffect from 'pages/automationWorkflow/components/automationDashboard/hooks/useThrottleEffect';
import {
  SearchBar,
  TriggerTags,
  TriggerDetails,
  UtilityContents,
  ConditionHeader,
  useTriggerActions,
  ConditionDropDown,
  useAutomationWorkflow,
  LinearLoaderComponent,
} from 'pages/automationWorkflow/export';
import { useState } from 'react';

const TriggersView = () => {
  const [selectedEcommerceType, setSelectedEcommerceType] = useState('');

  const {
    conditionallyRenderingTriggerUI,
    orderStatusChange,
    selectedEcommerce,
  } = useAutomationWorkflow();
  const {
    changeOrderStatus,
    updateTriggerInfo,
    isLoadingAutomatioViewDataApi,
    updateConditionallyRenderingTriggerUI,
  } = useTriggerActions();

  useThrottledEffect(
    () => {
      if (!!selectedEcommerce) {
        updateTriggerInfo();
      }
    },
    [selectedEcommerce],
    1500
  );

  const renderTriggerAndChannelView = () => {
    return (
      <>
        <div className='p-3 sticky top-0 z-20 w-full'>
          <SearchBar
            getSearchResult={(data) => console.log(data)}
            searchPlaceholder='Search...'
          />
        </div>
        {!!selectedEcommerceType && (
          <div className='p-2'>
            <Button
              variant={'ghost'}
              className='flex justify-between space-x-1 items-start'
              onClick={() => {
                setSelectedEcommerceType('');
              }}
            >
              <ChevronLeft /> Back
            </Button>
          </div>
        )}
        <div
          className='grid grid-cols-5 grid-flow-col'
          style={{ height: 'calc(100vh - 216px)' }}
        >
          <div className='col-span-2 border-r'>
            <TriggerTags
              selectedEcommerceType={selectedEcommerceType}
              setSelectedEcommerceType={setSelectedEcommerceType}
            />
          </div>
          <div className='col-span-3 overflow-auto'>
            {isLoadingAutomatioViewDataApi ? (
              <LinearLoaderComponent />
            ) : (
              <TriggerDetails />
            )}
          </div>
        </div>
      </>
    );
  };

  const renderingOrderStatusChangeView = () => {
    return (
      <>
        <div>
          <ConditionHeader
            title='Order Status Change'
            previousUIAction={() =>
              updateConditionallyRenderingTriggerUI('triggerElement')
            }
          />
        </div>
        <div className='bg-gray-50 h-screen'>
          <div className='grid grid-cols-2 gap-4 p-4'>
            <div className='p-4 bg-white rounded-md'>
              <h2 className='text-gray-600 text-sm font-medium mb-2'>
                Status From
              </h2>
              <ConditionDropDown
                activeValue={orderStatusChange?.statusForm}
                options={UtilityContents.OrderStatusData}
                onChange={({ id }) =>
                  changeOrderStatus('form_status', id as string)
                }
              />
            </div>
            <div className='p-4 bg-white rounded-md'>
              <h2 className='text-gray-600 text-sm font-medium mb-2'>
                To Status
              </h2>
              <ConditionDropDown
                activeValue={orderStatusChange?.toStatus}
                options={UtilityContents.OrderStatusData}
                onChange={(data) => changeOrderStatus('toStatus', data?.id)}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSidesheetChildElement = (elementType: string) => {
    switch (elementType) {
      case 'triggerElement':
        return renderTriggerAndChannelView();
      case 'order_created':
        return renderingOrderStatusChangeView();
      case 'order_status_change':
        return renderingOrderStatusChangeView();
      case 'cart_abandoned':
        return renderingOrderStatusChangeView();
      default:
        return null;
    }
  };

  return renderSidesheetChildElement(conditionallyRenderingTriggerUI);
};

export default TriggersView;
