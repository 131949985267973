'use client';

import { CartesianGrid, Line, LineChart, XAxis } from 'recharts';

import { Card, CardContent } from '../../../../../libraryV2/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '../../../../../libraryV2/ui/chart';
import dayJs from 'dayjs';

const chartConfig = {
  MARKETING: {
    label: 'MARKETING',
    color: '#22C55E',
  },
  SERVICE: {
    label: 'SERVICE',
    color: '#FB923C',
  },
  UTILITY: {
    label: 'UTILITY',
    color: '#3B82F6',
  },
  FREE_TIER: {
    label: 'FREE_TIER',
    color: '#A78BFA',
  },
  FREE_ENTRY_POINT: {
    label: 'FREE_ENTRY_POINT',
    color: '#FACC15',
  },
} satisfies ChartConfig;

export default function CustomReportingLineChart({ data, loader }: any) {
  const chartData = data.map((item: any) => ({
    month: dayJs(item?.timestamp * 1000).format('MMM D'), // If 'name' is missing, use 'Unknown'
    MARKETING: item.MARKETING,
    SERVICE: item.SERVICE,
    UTILITY: item.UTILITY,
    FREE_ENTRY_POINT: item.FREE_ENTRY_POINT,
    FREE_TIER: item.FREE_TIER,
  }));

  return (
    <Card>
      <CardContent>
        <ChartContainer
          config={chartConfig}
          className='h-80 max-h-80 w-full'
        >
          <LineChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 24,
              right: 24,
              top: 24,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey='month'
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value}
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            <ChartLegend content={<ChartLegendContent />} />
            <Line
              dataKey='MARKETING'
              type='linear'
              stroke='var(--color-MARKETING)'
              strokeWidth={1}
            />
            <Line
              dataKey='SERVICE'
              type='linear'
              stroke='var(--color-SERVICE)'
              strokeWidth={1}
            />
            <Line
              dataKey='UTILITY'
              type='linear'
              stroke='var(--color-UTILITY)'
              strokeWidth={1}
            />
            <Line
              dataKey='FREE_TIER'
              type='linear'
              stroke='var(--color-FREE_TIER)'
              strokeWidth={1}
            />
            <Line
              dataKey='FREE_ENTRY_POINT'
              type='linear'
              stroke='var(--color-FREE_ENTRY_POINT)'
              strokeWidth={1}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
