import { SelectedTeamInterface } from 'index';
import React from 'react';
import { connect } from 'react-redux';
import CountryListDropdown from './CountryListDropdown';
import { getCurrentOrderFormInformationValue } from '../../../utils/functions';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  createDraftOrder: CreateDraftOrderProperty | null;
  setCreateDraftOrder: (
    CreateDraftOrderProperty: CreateDraftOrderProperty
  ) => void;
  countryList: CountryDetailsProps[];
  selectedProject: SelectedTeamInterface;
  isValidBillingEmail: string;
  isValidShippingEmail: string;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: any;
  }) => void;
  orderFormInformation: {
    [key: string]: OrderFormInformationType;
  };
  customerInfo: ICustomerCartInfo;
}

const CustomerInfo: React.FC<Props> = ({
  createDraftOrder,
  setCreateDraftOrder,
  countryList,
  selectedProject,
  isValidBillingEmail,
  isValidShippingEmail,
  updateOrderInformationState,
  orderFormInformation,
  customerInfo,
}) => {
  const { t } = useTranslation();
  const updateOrderInformationValue = (
    key: string,
    value: string | BillingAddressProperty
  ) => {
    switch (createDraftOrder?.sameAsShippingAddress) {
      case true:
        const updatedValue = {
          ...getCurrentOrderFormInformationValue(
            orderFormInformation,
            customerInfo.customer_id
          ),
          shipping_address: {
            ...createDraftOrder?.shipping_address,
            [key]: value,
          },
          billing_address: {
            ...createDraftOrder?.shipping_address,
            [key]: value,
          },
        };

        updateOrderInformationState({
          key: customerInfo.customer_id,
          data: updatedValue,
        });
        return;
      case false:
        const newValue = {
          ...getCurrentOrderFormInformationValue(
            orderFormInformation,
            customerInfo.customer_id
          ),
          shipping_address: {
            ...createDraftOrder?.shipping_address,
            [key]: value,
          },
        };

        updateOrderInformationState({
          key: customerInfo.customer_id,
          data: newValue,
        });
        return;
      default:
        return null;
    }
  };

  const changeInformation = (
    isShippingAddress: boolean,
    value: string | BillingAddressProperty,
    key: string
  ) => {
    if (isShippingAddress) {
      updateOrderInformationValue(key, value);
    } else {
      const currentValue = getCurrentOrderFormInformationValue(
        orderFormInformation,
        customerInfo?.customer_id
      );

      const updatedValue = {
        ...currentValue,
        billing_address: {
          ...createDraftOrder?.billing_address,
          [key]: value,
        },
      };

      updateOrderInformationState({
        key: customerInfo.customer_id,
        data: updatedValue,
      });
    }
  };

  const handleShippingAddressChange = (
    value: string | BillingAddressProperty,
    key: string,
    isShippingAddress: boolean
  ) => {
    changeInformation(isShippingAddress, value, key);
  };

  const renderAddressView = (adressType: string) => {
    return (
      <>
        {' '}
        <div className='px-3 pt-3'>
          <p className='mb-1 capitalize text-gray-900 text-sm font-medium'>
            {adressType === 'shipping' ? t('Shipping Information') : ''}
          </p>
          <div className='flex flex-col'>
            <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
              {t('First name')}
              <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
              placeholder='ex: John'
              name='first_name'
              value={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.first_name
                  : createDraftOrder?.billing_address?.first_name
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
          </div>
          <div className='flex flex-col'>
            <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
              {t('Last name')}
              <span className='text-red-500'>*</span>
            </label>
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
              placeholder='ex: John'
              name='last_name'
              value={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.last_name
                  : createDraftOrder?.billing_address?.last_name
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
          </div>
          <div className='flex flex-col'>
            <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
              {t('Email')}
            </label>
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
              placeholder='ex: johndoe@email.com'
              name='email'
              defaultValue={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.email
                  : createDraftOrder?.billing_address?.email
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
            {isValidBillingEmail.length > 0 && adressType === 'billing' && (
              <p className='text-red-500 mb-2'>{isValidBillingEmail}</p>
            )}

            {isValidShippingEmail.length > 0 && adressType === 'shipping' && (
              <p className='text-red-500 mb-2'>{isValidShippingEmail}</p>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
              {t('Phone')}
            </label>
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
              placeholder={t('Phone number with country code')}
              name='phone'
              defaultValue={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.phone
                  : createDraftOrder?.billing_address?.phone
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
          </div>
        </div>
        <div className='px-3 mb-3'>
          <p className='mb-3 capitalize text-[#4B5563] text-sm font-medium'>
            {adressType === 'shipping'
              ? t('Shipping address')
              : t('Billing address')}
          </p>
          <div className='flex flex-col'>
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
              placeholder={t('Address')}
              name='address_one'
              value={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.address_one
                  : createDraftOrder?.billing_address?.address_one
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
            <input
              type='text'
              className='rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
              placeholder={t('Apt, suite, unit etc. (Optional)')}
              name='address_two'
              value={
                adressType === 'shipping'
                  ? createDraftOrder?.shipping_address?.address_two
                  : createDraftOrder?.billing_address?.address_two
              }
              onChange={(e) => {
                handleShippingAddressChange(
                  e.target.value,
                  e.target.name,
                  adressType === 'shipping' ? true : false
                );
              }}
            />
            <div className='flex gap-1 w-full'>
              <input
                type='text'
                className='w-1/3 rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
                placeholder={t('City')}
                name='city'
                value={
                  adressType === 'shipping'
                    ? createDraftOrder?.shipping_address?.city
                    : createDraftOrder?.billing_address?.city
                }
                onChange={(e) => {
                  handleShippingAddressChange(
                    e.target.value,
                    e.target.name,
                    adressType === 'shipping' ? true : false
                  );
                }}
              />
              <input
                type='text'
                className='w-1/3 rounded-md border border-[#D1D5DB] focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
                placeholder={t('State')}
                name='state'
                value={
                  adressType === 'shipping'
                    ? createDraftOrder?.shipping_address?.state
                    : createDraftOrder?.billing_address?.state
                }
                onChange={(e) => {
                  handleShippingAddressChange(
                    e.target.value,
                    e.target.name,
                    adressType === 'shipping' ? true : false
                  );
                }}
              />
              <input
                type='text'
                className='w-1/3 rounded-md border border-gray-200 focus:border-green-500
            focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-1'
                placeholder={t('Zip')}
                name='postcode'
                value={
                  adressType === 'shipping'
                    ? createDraftOrder?.shipping_address?.postcode
                    : createDraftOrder?.billing_address?.postcode
                }
                onChange={(e) => {
                  handleShippingAddressChange(
                    e.target.value,
                    e.target.name,
                    adressType === 'shipping' ? true : false
                  );
                }}
              />
            </div>
            <CountryListDropdown
              selected={
                countryList &&
                countryList.find(
                  (item) => item.country === selectedProject.country
                )
              }
              options={countryList}
              handleChange={handleShippingAddressChange}
              isShippingAddress={adressType === 'shipping' ? true : false}
            />
          </div>
        </div>
      </>
    );
  };

  const renderCheckIfSameAddressView = () => {
    return (
      <div className='flex gap-2 items-center'>
        <input
          id='check'
          type='checkbox'
          className='h-4 w-4 rounded border-gray-300 text-green-600
                      focus:ring-green-500 sm:left-6 cursor-pointer'
          name='billing_address'
          checked={createDraftOrder?.sameAsShippingAddress}
          onChange={(e) => {
            // setIsBillingSame(!isBillingSame);
            if (e.target.checked && createDraftOrder) {
              setCreateDraftOrder({
                ...createDraftOrder,
                billing_address: createDraftOrder?.shipping_address,
                sameAsShippingAddress: true,
              });

              const currentValue = getCurrentOrderFormInformationValue(
                orderFormInformation,
                customerInfo?.customer_id
              );

              const updatedValue = {
                ...currentValue,
                billing_address: createDraftOrder?.shipping_address,
                sameAsShippingAddress: true,
              };

              updateOrderInformationState({
                key: customerInfo.customer_id,
                data: updatedValue,
              });
            } else {
              const currentValue = getCurrentOrderFormInformationValue(
                orderFormInformation,
                customerInfo?.customer_id
              );

              const updatedValue = {
                ...currentValue,
                billing_address: {
                  address_one: '',
                  address_two: '',
                  city: '',
                  company: '',
                  country: '',
                  email: '',
                  first_name: '',
                  last_name: '',
                  phone: '',
                  postcode: '',
                  state: '',
                },
                sameAsShippingAddress: false,
              };

              updateOrderInformationState({
                key: customerInfo.customer_id,
                data: updatedValue,
              });
            }
          }}
        />
        <label htmlFor='check' className='cursor-pointer text-[#6B7280]'>
          {t('Same as shipping information')}
        </label>
      </div>
    );
  };

  return (
    <div className=''>
      {renderAddressView('shipping')}
      <div className='px-3 mb-3 mt-6'>
        <p className='mb-1 capitalize text-gray-700 text-sm font-medium'>
          {t('Billing Information')}
        </p>
        {renderCheckIfSameAddressView()}
      </div>
      {!createDraftOrder?.sameAsShippingAddress && renderAddressView('billing')}
    </div>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  orderFormInformation: state.ecommerce.orderFormInformation,
  customerInfo: state.ecommerce.cart,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(CustomerInfo);
