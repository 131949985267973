/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface DropDownType {
  role?: string;
  userRolesType: UserRolesType[];
  setIsUpdateShown: (payload: boolean) => void;
  onSelect: () => void;
  setSelected: (payload: number | null) => void;
}

//@ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const RolesDropdown: React.FC<DropDownType> = ({
  role,
  userRolesType,
  setIsUpdateShown,
  onSelect,
  setSelected,
}) => {
  const { t } = useTranslation();
  const roleDetails = (name: string) => {
    switch (name) {
      case 'Admin':
        return <>{t('Admin have access to everything.')}</>;
      case 'Supervisor':
        return (
          <>
            {t('Supervisors cannot access Team')} <br />
            {t('Settings and integrations.')}
          </>
        );
      case 'Developer':
        return (
          <>
            {t('Developers cannot access Inbox,')} <br />
            {t('customers and integrations.')}
          </>
        );
      case 'Marketeer':
        return (
          <>
            {t('Marketers cannot access Inbox, API,')} <br />
            {t('Datalab, Customers, and Integrations.')}
          </>
        );
      case 'Agent':
        return <>{t('Agents have access to the Inbox only')}</>;
      default:
        return '';
    }
  };
  return (
    <Menu as='div' className='relative ltr:text-left rtl:text-right'>
      <div className='flex'>
        <Menu.Button className='text-gray-500'>{t(role ?? '')}</Menu.Button>
        <ChevronDownIcon
          className='ltr:-mr-1 rtl:-ml-1 ltr:ml-2 rtl:mr-2 h-5 w-5'
          aria-hidden='true'
        />
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='z-10 ltr:origin-top-right rtl:origin-top-left absolute ltr:right-0 rtl:left-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            {userRolesType.map((roles) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div className='w-full flex flex-col relative'>
                      <div
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm flex flex-col border-b cursor-pointer'
                        )}
                        onClick={() => {
                          onSelect();
                          //@tas-ignore
                          setSelected(roles.id);
                          setIsUpdateShown(true);
                        }}
                      >
                        {t(roles.name)}
                        <p className='text-sm text-gray-400 pb-2 w-auto'>
                          {t(roleDetails(roles.name))}
                        </p>
                      </div>
                      {role === roles.name && (
                        <CheckIcon className='w-5 h-5 absolute top-[10px] ltr:right-[20px] rtl:left-[20px] text-green-500' />
                      )}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default RolesDropdown;
