import usePartner from 'components/customHooks/usePartner';
import Link from '../../../../../library/link';

export const GetModalCustomFooter = (
  description: string,
  link: string,
  linkTitle: string
) => {
  const { isPartnerRestricted } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'Function/function/GetModalCustomFooter'
  );
  if (shouldReplaceMyalice) {
    return null;
  }

  return (
    <div className='flex gap-2 justify-end mt-2'>
      <label htmlFor='remember_me' className='block text-sm text-gray-900'>
        {description}{' '}
        <Link url={link} openInNewTab={true}>
          {linkTitle}
        </Link>
      </label>
    </div>
  );
};
