import React from 'react';
import ShowAttribute from './ShowAttribute';
import NewAttributeDropDown from './NewAttributeDropDown';
import { excludedAttributeList } from '../../../utils/contents';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CustomerAttributePayloadInterface,
  CustomerInfoInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';

interface Props {
  customerInfo: CustomerInfoInterface;
  updateCustomerAttribute: (
    payload: CustomerAttributePayloadInterface
  ) => Promise<boolean>;
  selectedTicket: TicketInterface;
  projectAttributes: string[];
  conversationData: any; // tobe refactored
}

const CustomerAttribute: React.FC<Props> = ({
  customerInfo,
  selectedTicket,
  conversationData,
  projectAttributes,
  updateCustomerAttribute,
}) => {
  const { t } = useTranslation();
  const [isShowAttribute, setIsShowAttribute] = React.useState(false);

  const handleShowAttribute = (showAttributeList: string[]) => {
    setIsShowAttribute((prevCheck) => !prevCheck);
    let showLessAttributeList = showAttributeList.slice(5);
    return showLessAttributeList;
  };

  const handleHiddenAttribute = (attributeList: object) => {
    let showAttributeList: string[] = [];

    if (!!attributeList) {
      showAttributeList = Object.keys(attributeList).filter(
        (attribute) =>
          !excludedAttributeList.includes(attribute) &&
          attributeList[attribute] !== ''
      );
    }
    return showAttributeList;
  };

  const handleUpdateAttribute = async (
    attributeName: string,
    attributeValue: string
  ) => {
    let response = await updateCustomerAttribute({
      data: { [attributeName]: attributeValue },
      customer_id: selectedTicket.customer_id,
    });
    if (response) {
      toaster.success(t('The customer attribute is successfully saved'));
    } else {
      toaster.danger(t('The customer attribute is unable to be saved'));
    }
  };

  return (
    <>
      <div className='flex w-auto h-auto gap-2 border rounded flex-column'>
        <div className='flex py-3 px-4 justify-between'>
          <div className='font-semibold text-sm leading-5 text-gray-900'>
            {t('Customer Attributes')}
          </div>
          {isShowAttribute && (
            <div
              className=' text-[#0078CF] text-sm font-medium leading-5 cursor-pointer'
              onClick={() =>
                handleShowAttribute(handleHiddenAttribute(customerInfo))
              }
            >
              {t('Show Less')}
            </div>
          )}
        </div>
        <div className='flex flex-column'>
          <ShowAttribute
            customerInfo={customerInfo}
            selectedTicket={selectedTicket}
            conversationData={conversationData}
            updateCustomerAttribute={updateCustomerAttribute}
            attribute={
              isShowAttribute
                ? handleHiddenAttribute(customerInfo)
                : handleHiddenAttribute(customerInfo).slice(0, 4)
            }
          />
        </div>
        <NewAttributeDropDown
          projectAttributesList={projectAttributes}
          handleUpdateAttribute={handleUpdateAttribute}
          selectedTicket={selectedTicket}
          showAttributeList={handleHiddenAttribute(customerInfo)}
        />
        {!isShowAttribute &&
          handleHiddenAttribute(customerInfo).length >= 5 && (
            <div className='flex w-full px-2 mb-3'>
              <div className='flex justify-start items-center h-6 w-[50%] rounded '>
                <div className='w-auto p-1 ltr:ml-2 rtl:mr-2 text-sm font-medium leading-5 text-gray-800 bg-gray-100 border rounded'>
                  {t(
                    '{{' +
                      (handleHiddenAttribute(customerInfo).length - 4) +
                      '}} more attributes'
                  )}
                </div>
              </div>
              <div className='flex justify-end w-[50%]'>
                <div
                  className='text-[#0078CF] text-sm font-medium leading-5 cursor-pointer'
                  onClick={() => setIsShowAttribute(true)}
                >
                  {t('View All')}
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default CustomerAttribute;
