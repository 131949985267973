import { TicketInterface } from 'pages/inbox/inboxInterface';
import {
  AssignAgentIcon,
  AvatarIcon,
} from '../../../../assets/iconComponent/SearchComponentIcons';
import { getLocalizedTimeDateFormat } from 'pages/inbox/utils/functions';
import { CircledChannelIcons, classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  userId: number;
  ticketData: TicketInterface;
}

const SearchResultCard: React.FC<Props> = ({ ticketData, userId }) => {
  const { t, dashboardLanguage } = useTranslation();
  const timeLocale = dashboardLanguage === 'arabic' ? 'ar' : 'en';
  const getAgentName = () => {
    if (ticketData?.assigned_agent) {
      return ticketData?.assigned_agent === userId
        ? t('You')
        : ticketData?.assigned_agent_full_name.length > 20
        ? ticketData?.assigned_agent_full_name.slice(0, 20) + '...'
        : ticketData?.assigned_agent_full_name;
    } else if (ticketData?.assigned_group) {
      return t('All Assigned');
    } else {
      return t('Unassigned');
    }
  };
  const ticketStatus = () => {
    if (ticketData?.assigned_agent === userId) {
      if (ticketData?.is_resolved) {
        return ticketData?.resolved_by === userId
          ? t('My Closed')
          : t(`All Closed`);
      } else {
        return t('My Assigned');
      }
    } else {
      return ticketData?.is_resolved ? t(`All Closed`) : t('All Assigned');
    }
  };
  return (
    <>
      <div className=' p-4  border border-gray-200 rounded-lg bg-white hover:bg-gray-50 drop-shadow-sm hover:shadow '>
        <div className='flex align-top'>
          <span className='float-left'>
            {CircledChannelIcons({
              width: '24',
              height: '24',
              type: ticketData?.customer_platform_type,
              hasHoverEffect: false,
            })}
          </span>
          <div className='ltr:ml-4 rtl:mr-4'>
            <div>
              <span className='text-sm font-medium text-gray-900'>
                {ticketData?.customer_full_name}
              </span>
            </div>
            <div className='flex gap-2 justify-start mt-2 align-top'>
              <span className='px-1 py-[2px] bg-gray-100 text-xs font-medium text-gray-800 rounded flex items-center'>
                {ticketData?.customer_platform_title.length > 20
                  ? ticketData?.customer_platform_title.slice(0, 20) + '...'
                  : ticketData?.customer_platform_title}{' '}
              </span>
              <span className='px-1 py-[2px] bg-gray-100 text-xs font-medium text-gray-800 rounded flex items-center '>
                <AvatarIcon />
                &nbsp;&nbsp;{getAgentName()}{' '}
              </span>

              <span
                className={classNames(
                  `px-1 py-[2px] bg-gray-100 text-xs font-medium text-gray-800 rounded flex items-center `,
                  ticketData?.assigned_agent || ticketData?.is_resolved
                    ? 'inline'
                    : 'hidden'
                )}
              >
                <AssignAgentIcon />
                &nbsp;&nbsp;{ticketStatus()}
              </span>
            </div>
          </div>
          <span className='ltr:ml-auto rtl:mr-auto rtl:text-right text-xs leading-4 font-normal text-gray-800'>
            {getLocalizedTimeDateFormat(
              ticketData?.card_timestamp,
              timeLocale,
              'DD MMM, hh:mm A'
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default SearchResultCard;
