import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';

const initialState = {
  onboardingCompletationStep: null,
  basicInfo: null,
  businessInfo: null,
  hasEcommerceConnected: false,
  channelsOnboarded: [],
};

export const onboardingProgress = {
  state: {
    ...initialState,
  },
  reducers: {
    updateInitialState(state, payload) {
      return { ...state, ...payload };
    },
    updateOnboardingCompletationStep(state, payload) {
      return { ...state, onboardingCompletationStep: payload };
    },
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateOnboardGetData(state, payload) {
      return {
        ...state,
        onboardingCompletationStep: payload.onboarding_completion_state,
        hasEcommerceConnected: payload.has_ecommerce_connected || false,
        channelsOnboarded: payload.channels_onboarded || [],
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getCountryData() {
      try {
        const res = await axios.get(config.onboarding.countryList());
        return res.data.dataSource;
      } catch (err) {
        return [];
      }
    },
    async updateBasicInfo(payload) {
      //  payload =
      // {
      //   first_name: string
      //   last_name: string
      //   country: string
      //   country_iso_code: string
      //   phone_code: string
      //   currency_name: string
      //   currency_code: string
      //   currency_symbol: string (Optional)
      //   phone_number: string
      // }
      try {
        const res = await axios.post(config.onboarding.updateInfo(), payload);
        if (res.data.success) {
          dispatch.onboardingProgress.updateStateData({
            key: 'basicInfo',
            value: payload,
          });
          dispatch.onboardingProgress.updateOnboardingCompletationStep(
            res.data.dataSource.onboarding_completion_state
          );
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    async updateBusinessInfo(payload) {
      //  payload =
      // {
      //   business_name: string
      //   business_url: string
      //   business_type: string
      // }
      try {
        const res = await axios.post(config.onboarding.businessInfo(), payload);
        if (res.data.success) {
          dispatch.onboardingProgress.updateStateData({
            key: 'businessInfo',
            value: payload,
          });
          dispatch.onboardingProgress.updateOnboardingCompletationStep(
            res.data.dataSource.onboarding_completion_state
          );
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    async handleCreateChannel(payload) {
      //  payload = [platform_type,....] length 4
      try {
        const res = await axios.post(
          config.onboarding.createChannel(),
          payload
        );
        if (res.data.success) {
          dispatch.onboardingProgress.updateOnboardingCompletationStep(
            res.data.dataSource.onboarding_completion_state
          );
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    async updateOnboardingStates(payload) {
      //  payload = {key: boolean}
      try {
        const res = await axios.post(
          config.onboarding.onboardingState(),
          payload
        );
        if (res.data.success) {
          dispatch.onboardingProgress.updateOnboardingCompletationStep(
            res.data.dataSource.onboarding_completion_state
          );
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    async getOnboardingStates() {
      try {
        const res = await axios.get(config.onboarding.getOnboardingStateURL());
        if (res.data.success) {
          dispatch.onboardingProgress.updateOnboardGetData(res.data.dataSource);
        }
        return;
      } catch (err) {
        console.log(err.response);
        return;
      }
    },
    async handleInviteMember(payload) {
      //  payload = [email,....] length 3
      try {
        const res = await axios.post(config.onboarding.inviteMember(), {
          emails: payload,
        });
        if (res.data.success) {
          dispatch.onboardingProgress.updateOnboardingCompletationStep(
            res.data.dataSource.onboarding_completion_state
          );
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
  }),
};
