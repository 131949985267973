import React from 'react';
import { connect } from 'react-redux';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { downloadMediaAttachment } from 'pages/inbox/utils/functions';
import downloadIcon from '../../../../assets/images/downloadIcon.svg';
import defaultMediaImage from '../../../../assets/images/defaultMediaImage.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  mediaType: string;
  urls: string[];
  selectedMedia: string;
  selectedMediaIndex: number;
  setSelectedMedia: (payload: string | null) => void;
  setSelectedMediaIndex: (payload: number) => void;
  fetchAttachmentData: (url: string) => string;
}

const MediaViewer: React.FC<Props> = ({
  mediaType,
  urls,
  selectedMedia,
  selectedMediaIndex,
  setSelectedMedia,
  setSelectedMediaIndex,
  fetchAttachmentData,
}) => {
  const closeOnClick = () => {
    setSelectedMedia(null);
  };

  const { t } = useTranslation();

  const scrollLeft = () => {
    if (selectedMediaIndex !== 0) {
      setSelectedMedia(urls[selectedMediaIndex - 1]);
      setSelectedMediaIndex(selectedMediaIndex - 1);
    } else {
      setSelectedMedia(urls[urls.length - 1]);
      setSelectedMediaIndex(urls.length - 1);
    }
  };

  const scrollRight = () => {
    if (selectedMediaIndex !== urls.length - 1) {
      setSelectedMedia(urls[selectedMediaIndex + 1]);
      setSelectedMediaIndex(selectedMediaIndex + 1);
    } else {
      setSelectedMedia(urls[0]);
      setSelectedMediaIndex(0);
    }
  };

  const downloadMedia = async () => {
    if (mediaType === 'video') {
      downloadMediaAttachment(selectedMedia, mediaType);
    } else {
      let response = await fetchAttachmentData(selectedMedia);
      downloadMediaAttachment(selectedMedia, mediaType, response);
    }
  };

  const renderMediaTopBarView = () => {
    return (
      <div className='flex border border-b-gray-100 w-full h-[5%] justify-between rounded-t-md items-center'>
        <button
          className='ml-4 h-6 w-auto bg-gray-100 content-center rounded-md'
          onClick={downloadMedia}
        >
          <img
            className='w-[11.2px] h-[12.8px] mx-1.5'
            src={downloadIcon}
            alt='downloadIcon'
          />
          <p className='font-medium text-sm text-gray-800 mr-1'>
            {t('Download')}
          </p>
        </button>
        <p className='pr-20 font-medium text-gray-900 text-sm content-center'>
          {t('Attached Media')}
        </p>
        <button className='content-center'>
          <XMarkIcon
            className='w-5 h-5 mr-3.5 content-center text-gray-500'
            onClick={closeOnClick}
          />
        </button>
      </div>
    );
  };

  const renderMediaImageView = () => {
    return (
      <div
        className={`flex text-lg text-gray-700 h-[95%] w-auto items-center
        ${urls.length !== 1 ? 'justify-between' : 'justify-center'}`}
      >
        {/* <div className='w-full h-full'> */}
        {urls.length !== 1 && (
          <button
            className='border border-gray-300 rounded-full w-9 h-9 ml-6 self-center content-center'
            onClick={scrollLeft}
          >
            <ArrowLeftIcon className='w-4 h-4 text-gray-500' />
          </button>
        )}
        <img
          className='object-contain h-[95%] w-[98%] content-center'
          alt='media'
          data-testid='media-viewer-image'
          src={selectedMedia}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultMediaImage;
          }}
        />
        {urls.length !== 1 && (
          <button
            className='border border-gray-300 rounded-full w-9 h-9 mr-6 self-center content-center'
            onClick={scrollRight}
          >
            <ArrowRightIcon className='w-4 h-4 text-gray-500' />
          </button>
        )}
        {/* </div> */}
      </div>
    );
  };

  const renderMediaVideoView = () => {
    return (
      <div
        className={`flex text-lg text-gray-700 h-[95%] w-auto items-center
        ${urls.length !== 1 ? 'justify-between' : 'justify-center'}`}
      >
        {urls.length !== 1 && (
          <button
            className='border border-gray-300 rounded-full w-9 h-9 ml-6 self-center content-center'
            onClick={scrollLeft}
          >
            <ArrowLeftIcon className='w-4 h-4 text-gray-500' />
          </button>
        )}
        <video
          className='object-contain h-auto max-h-[95%] w-[98%] content-center'
          id={`video${selectedMediaIndex}`}
          controls
        >
          <source data-testid='media-viewer-video' src={selectedMedia} />
        </video>
        {urls.length !== 1 && (
          <button
            className='border border-gray-300 rounded-full w-9 h-9 mr-6 self-center content-center'
            onClick={scrollRight}
          >
            <ArrowRightIcon className='w-4 h-4 text-gray-500' />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className='fixed inset-0 z-50 bg-gray-500 bg-opacity-75'>
      <div
        className='flex h-screen justify-center items-center'
        onClick={closeOnClick}
      >
        <div
          className='flex-col bg-white w-[70%] h-[80%] rounded-md shadow-md'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderMediaTopBarView()}
          {/* Media Viewer */}
          {mediaType === 'image' && renderMediaImageView()}
          {mediaType === 'video' && renderMediaVideoView()}
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch: any) => ({
  fetchAttachmentData: (url: string) => dispatch.inbox.fetchAttachmentData(url),
});

export default connect(null, mapDispatch)(MediaViewer);
