import dayJs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { orderData } from '../../../../../../utilities/content';
import Collapse from '../../../../../components/elements/redesign/Collapse';
import Modal from '../../../../../components/elements/redesign/Modal';
import TagRedesign from '../../../../../components/elements/redesign/TagRedesign';
import EcomOrderUpdate from '../eCommerceOrderUpdate';
import ShopifyOrderUpdate from '../shopifyOrderUpdate';
import OrderCardProduct from './OrderCardProduct';
import OrderCardSummary from './OrderCardSummary';

interface Prop {
  eComOrderId: string | number;
  eComType?: string;
  email: string;
  orderLink: string;
  orderDate: string;
  totalCost: string;
  paymentMethod: string;
  billingAddress: EcomAddressProps;
  shippingAddress: EcomAddressProps;
  totalTax: string;
  shippingCost: string;
  discount: string;
  products: ProductDataProps[];
  shippingMethod: string;
  status: string;
  payment_status: string;
  refund: string;
  coupon: string[];
  note?: string;
  cancelOrder: (teamId: number, orderId: string) => void;
  refundOrder: (teamId: number, orderId: string) => void;
  projectId: number;
  orderId: string;
  currentTicket: CurrentTicket;
  productURL?: string;
  ecommerceOrderList: EcomOrderProps[];
  currencySymbol: string;
}

interface EcomOrderProps {
  ecommerce_order_id: number;
  permalink: string;
  date_paid: string;
  date_completed: string;
  coupon_info: string;
  status: string;
  payment_status: string;
  total_refund: number;
  created_at: string;
  total_cost: number;
  payment_method: string;
  billing_address: EcomAddressProps | null;
  shipping_address: EcomAddressProps | null;
  total_tax: number;
  total_shipping_cost: number;
  total_discount: number;
  shipping_method: string;
  line_items: ProductDataProps[];
  id: string;
  products_url: string;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
}

interface CurrentTicket {
  id: number;
  customer: {
    id: number;
  };
}

interface ProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

const OrderCardRightbar: React.FC<Prop> = ({
  eComOrderId,
  eComType,
  email,
  orderLink,
  orderDate,
  totalCost,
  paymentMethod,
  billingAddress,
  shippingAddress,
  totalTax,
  shippingCost,
  discount,
  products,
  shippingMethod,
  status,
  payment_status,
  refund,
  coupon,
  note,
  cancelOrder,
  refundOrder,
  projectId,
  orderId,
  currentTicket,
  productURL,
  ecommerceOrderList,
  currencySymbol,
}) => {
  const [showCancelOrderModal, setShowCancelOrderModal] = React.useState(false);
  const [showRefundOrderModal, setShowRefundOrderModal] = React.useState(false);
  const [updateOrderOpen, setUpdateOrderOpen] = React.useState(false);

  const isOrderStatusVoid = () => {
    switch (status.toLowerCase()) {
      case 'refunded':
      case 'cancelled':
        return true;
      default:
        return false;
    }
  };

  const isPaymentStatusVoid = () => {
    switch (payment_status.toLowerCase()) {
      case 'voided':
      case 'refunded':
        return true;
      default:
        return false;
    }
  };

  const renderStatusTags = () => {
    if (eComType === 'shopify') {
      return (
        <div className='flex align-center'>
          {!!payment_status && (
            <TagRedesign
              data={{
                value: payment_status,
                label: payment_status,
              }}
              size={12}
              intent='active'
            />
          )}
          {!!status && (
            <TagRedesign
              data={{
                value: status,
                label: status,
              }}
              size={12}
              intent='default'
            />
          )}
        </div>
      );
    } else {
      return (
        <div className='flex align-center'>
          <TagRedesign
            data={{
              value: status,
              label: status,
            }}
            size={12}
            intent='active'
          />
        </div>
      );
    }
  };

  return (
    <div className='order-right-bar__order-card'>
      <div className='order-right-bar__order-card__header'>
        <div className='flex flex-column'>
          <a
            href={orderLink || window.location.href}
            target='_blank'
            rel='noreferrer noopener'
            className='order-right-bar__order-card__header-title link-color'
          >
            ORDER {eComOrderId || 'N/A'}
          </a>
          <p className='order-right-bar__order-card__header-subtitle'>
            {dayJs(parseInt(orderDate) * 1000).format('Do MMM YYYY')}
          </p>
        </div>
        <div>
          <div className='order-right-bar__order-card__bill-amount'>
            {currencySymbol}
            {totalCost}
          </div>
          {renderStatusTags()}
        </div>
      </div>
      <div className='flex justify-between pd-10px pdt-0px align-center'>
        <p className='order-right-bar__order-card__content-title'>Email:</p>
        <p className='order-right-bar__order-card__content-info'>{email}</p>
      </div>
      <div className='flex justify-between pd-10px pdt-0px align-center'>
        <p className='order-right-bar__order-card__content-title'>
          Purchased Items:
        </p>
        <p className='order-right-bar__order-card__content-info'>
          {products.length}
        </p>
      </div>
      <div className='flex justify-between pd-10px pdt-0px align-center'>
        <p className='order-right-bar__order-card__content-title'>
          Payment Method:
        </p>
        <p className='order-right-bar__order-card__content-info'>
          {paymentMethod}
        </p>
      </div>
      <>
        {!!ecommerceOrderList && ecommerceOrderList.length > 0 ? (
          <div></div>
        ) : (
          <div className='flex flex-row justify-between pd-10px border-color-top'>
            {!isOrderStatusVoid() && !isPaymentStatusVoid() ? (
              <div
                onClick={() => setUpdateOrderOpen(true)}
                role='button'
                className='order-right-bar__order-card__header-subtitle link-color'
              >
                Update
              </div>
            ) : (
              <div className='order-right-bar__order-card__header-subtitle'>
                Update
              </div>
            )}
            <p className='mt-0 order-right-bar__order-card__header-subtitle'>
              .
            </p>
            {!isOrderStatusVoid() && !isPaymentStatusVoid() ? (
              <div
                onClick={() => setShowRefundOrderModal(true)}
                role='button'
                className='order-right-bar__order-card__header-subtitle link-color'
              >
                Refund
              </div>
            ) : (
              <div className='order-right-bar__order-card__header-subtitle'>
                Refund
              </div>
            )}
            <p className='mt-0 order-right-bar__order-card__header-subtitle'>
              .
            </p>
            {!isOrderStatusVoid() && !isPaymentStatusVoid() ? (
              <div
                onClick={() => setShowCancelOrderModal(true)}
                role='button'
                className='order-right-bar__order-card__header-subtitle link-color'
              >
                Cancel
              </div>
            ) : (
              <div className='order-right-bar__order-card__header-subtitle'>
                Cancel
              </div>
            )}
          </div>
        )}
      </>
      <Collapse
        header='Summary'
        isOpen={false}
        hasCollapseBorder={true}
        titleFontSize={12}
        hasSharpEdge={true}
      >
        <OrderCardSummary
          eComType={eComType}
          billingAddress={billingAddress}
          shippingAddress={shippingAddress}
          totalTax={totalTax}
          totalCost={totalCost}
          shippingCost={shippingCost}
          discount={discount}
          shippingMethod={shippingMethod}
          refund={refund}
          coupon={coupon}
          note={note}
          currencySymbol={currencySymbol}
        />
      </Collapse>
      <Collapse
        header='Products'
        isOpen={false}
        hasCollapseBorder={true}
        titleFontSize={12}
        hasSharpEdge={true}
      >
        <OrderCardProduct
          products={products}
          productURL={productURL}
          currencySymbol={currencySymbol}
        />
      </Collapse>
      <Modal
        isShown={showCancelOrderModal}
        setShown={setShowCancelOrderModal}
        deleteTitle='Cancel Order'
        onCancelCallback={() => setShowCancelOrderModal(false)}
        onDeleteCallback={async () => {
          await cancelOrder(projectId, orderId);
        }}
        hasConfirmText={true}
        confirmText='CANCEL'
        title={orderData.orderCancel.title}
        intent='danger'
        description={orderData.orderCancel.description}
      />
      <Modal
        isShown={showRefundOrderModal}
        setShown={setShowRefundOrderModal}
        deleteTitle='Issue Refund'
        onCancelCallback={() => setShowRefundOrderModal(false)}
        onDeleteCallback={async () => {
          await refundOrder(projectId, orderId);
        }}
        hasConfirmText={true}
        confirmText='REFUND'
        title={orderData.orderRefund.title}
        intent='danger'
        description={orderData.orderRefund.description}
      />
      {eComType === 'shopify' ? (
        <ShopifyOrderUpdate
          updateOrderOpen={updateOrderOpen}
          customerId={currentTicket.customer.id}
          ticketId={currentTicket.id}
          setUpdateOrderOpen={setUpdateOrderOpen}
          eComOrderId={eComOrderId}
          orderId={orderId}
        />
      ) : (
        <EcomOrderUpdate
          updateOrderOpen={updateOrderOpen}
          customerId={currentTicket.customer.id}
          setUpdateOrderOpen={setUpdateOrderOpen}
          eComOrderId={eComOrderId}
          orderId={orderId}
        />
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
  currentTicket: state.crm.currentTicket,
  ecommerceOrderList: state.crmEcommerce.ecommerceOrderList,
});

const mapDispatch = (dispatch: any) => ({
  cancelOrder: (projectId: number, orderId: string) =>
    dispatch.crm.cancelOrder({
      projectId,
      orderId,
    }),
  refundOrder: (projectId: number, orderId: string) =>
    dispatch.crm.refundOrder({
      projectId,
      orderId,
    }),
});

const OrderCardRightbarContainer = connect(
  mapState,
  mapDispatch
)(OrderCardRightbar);

export default OrderCardRightbarContainer;
