import React from 'react';
import RandomlyGeneratedAvatar from 'assets/icons/component/RandomlyGeneratedAvatar';
import { Button } from 'library';
import useTranslation from 'components/customHooks/useTranslation';

interface MemeberListProps {
  members: GroupMembers[];
  removedMembers: number[];
  onMemberRemove: (id: number, action: 'add' | 'remove') => void;
}

const MemeberList: React.FC<MemeberListProps> = ({
  members,
  removedMembers,
  onMemberRemove,
}) => {
  const { t } = useTranslation();
  const handleMemberAction = (id: number, action: 'add' | 'remove') => {
    onMemberRemove(id, action);
  };
  return (
    <div className='flex flex-col'>
      <div className='-my-2 overflow-x-auto scroll-none sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <div className='overflow-hidden border-b border-gray-200 shadow sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='w-full px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  >
                    {t('Name')}
                  </th>
                  <th
                    scope='col'
                    className='w-full px-6 py-3 text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
                  />
                </tr>
              </thead>
              <tbody>
                {members.map((member, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className='flex items-center px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
                      <div className='flex-shrink-0 w-10 h-10'>
                        {member.avatar ? (
                          <img
                            className='w-10 h-10 rounded-full'
                            src={member.avatar}
                            alt=''
                          />
                        ) : (
                          <RandomlyGeneratedAvatar className='w-10 h-10' />
                        )}
                      </div>
                      <div className='truncate rtl:mr-3 ltr:ml-3'>
                        {member.full_name || 'Unknown User'}
                      </div>
                    </td>

                    <td className='px-6 py-4 text-sm font-medium ltr:text-right rtl:text-left whitespace-nowrap'>
                      <Button
                        intent='minimal'
                        type='button'
                        size='sm'
                        className='!text-link !hover:text-link-hover'
                        onClick={() => {
                          if (removedMembers.indexOf(member.id) !== -1) {
                            handleMemberAction(member.id, 'remove');
                          } else {
                            handleMemberAction(member.id, 'add');
                          }
                        }}
                      >
                        {t(
                          removedMembers.indexOf(member.id) !== -1
                            ? 'Undo'
                            : 'Remove'
                        )}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemeberList;
