//imports files here
import { ecommerceParameters } from 'utilities/content';

import {
  getUpdatedButtonElement,
  getPlatformsGalleryButtonMaxNumber,
} from '../../../utilities/utils';

export const handleSetAttributeBlockActions = (blocksLocal, payload) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.actionType) {
        case 'create':
          block.data.collection = [
            ...block.data.collection,
            payload.payloadData,
          ];
          break;
        case 'update':
          block.data.collection[payload.payloadData.index][
            payload.payloadData.keyName
          ] = payload.payloadData.keyValue;
          break;
        case 'delete':
          block.data.collection = [
            ...block.data.collection.slice(0, payload.payloadData.index),
            ...block.data.collection.slice(payload.payloadData.index + 1),
          ];
          break;
        default:
          break;
      }
      block.save = false;
    }
    return block;
  });
};

export const handleViewCartBlockGalleryButtonActions = (
  blocksLocal,
  payload
) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.actionType) {
        case 'create':
          let buttonCategory = payload.payloadData.buttonCategory;
          block.data.buttons = [
            ...block.data.buttons,
            {
              title:
                buttonCategory === 'ecommerce'
                  ? 'Remove From Cart'
                  : 'Button Title',
              type:
                buttonCategory === 'ecommerce'
                  ? 'remove_from_cart'
                  : 'sequence',
              category: buttonCategory,
              value: '',
              webview_height_ratio: 'tall',
              messenger_extensions: false,
              form_sequence: 0,
            },
          ];
          break;
        case 'update':
          block.data.buttons = getUpdatedButtonElement(
            block.data.buttons,
            payload.payloadData.buttonData
          );
          break;
        case 'delete':
          block.data.buttons = [
            ...block.data.buttons.slice(0, payload.payloadData.buttonIndex),
            ...block.data.buttons.slice(payload.payloadData.buttonIndex + 1),
          ];
          break;
        case 'changeButtonPosition':
          block.data.buttons = payload.payloadData.buttonData;
          break;
        default:
          break;
      }
      block.save = false;
    }
    return block;
  });
};

export const handleCouponBlockActions = (blocksLocal, payload) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.actionType) {
        case 'add':
          if (payload.instructionType === 'input_instructions') {
            block.data[payload.instructionType] = [
              ...block.data[payload.instructionType],
              payload.payloadData,
            ];
          }
          if (payload.instructionType === 'output_instructions') {
            block.data[payload.instructionType] = payload.payloadData;
          }
          break;
        case 'update':
          if (payload.instructionType === 'input_instructions') {
            block.data.input_instructions[payload.payloadData.index].value =
              payload.payloadData.value;
          }
          if (payload.instructionType === 'output_instructions') {
            block.data.output_instructions.text_message =
              payload.payloadData.value;
          }
          break;
        case 'delete':
          block.data.input_instructions = [
            ...block.data.input_instructions.slice(
              0,
              payload.payloadData.index
            ),
            ...block.data.input_instructions.slice(
              payload.payloadData.index + 1
            ),
          ];
          break;
        default:
          break;
      }
      block.save = payload.shouldSave;
    }
    return block;
  });
};

export const setEcommerceInitialParameters = (
  blockData,
  platformType,
  ecommercePlatformType,
  addInputInstructions,
  addOutputInstructions,
  hasVariant = false
) => {
  switch (ecommercePlatformType) {
    case 'woocommerce':
    case 'shopify':
      if (blockData?.input_instructions.length === 0 || hasVariant) {
        ecommerceParameters[ecommercePlatformType].input_parameters.forEach(
          (elem, index) => {
            if (elem.isPinned) {
              elem.name === 'per_page'
                ? (elem['value'] =
                    getPlatformsGalleryButtonMaxNumber(platformType))
                : (elem['value'] = elem.default);
              addInputInstructions(elem, true);
            }
          }
        );
      }
      if (
        Object.keys(blockData?.output_instructions).length === 0 ||
        hasVariant
      ) {
        addOutputInstructions({
          has_image: false,
          title: '',
          subtitle: '',
          url: '',
          buttons: [],
        });
      }
      break;
    default:
      break;
  }
};

export const handleBetterdocsBlockDataUpdate = (blocksLocal, payload) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.changeKey) {
        case 'input_instructions':
          if (block.data.input_instructions[0] === undefined) {
            block.data.input_instructions.push({});
            block.data.input_instructions[0].value = '';
            block.data.input_instructions[0].default = '';
            block.data.input_instructions[0].isPinned = true;
            block.data.input_instructions[0].name = 'search';
            block.data.input_instructions[0].type = 'string';
            block.data.input_instructions[0].verbose = 'search';
          }
          block.data.input_instructions[0].value = payload.payloadData.value;
          break;
        case 'output_instructions':
          if (block.data.output_instructions.display_image === undefined) {
            block.data.output_instructions.display_image = true;
          } else if (block.data.output_instructions.title === undefined) {
            block.data.output_instructions.title = '';
          } else if (block.data.output_instructions.subtitle === undefined) {
            block.data.output_instructions.subtitle = '';
          } else if (block.data.output_instructions.buttons === undefined) {
            block.data.output_instructions.buttons = [];
          }
          block.data.output_instructions[payload.payloadData.name] =
            payload.payloadData.value;
          break;
        case 'no_docs_sequence':
          block.data['no_docs_sequence_id'] = payload.payloadData.value;
          block.data['no_docs_sequence'] = {
            id: payload.payloadData.value,
            title: payload.payloadData.name,
          };
          break;
        default:
          break;
      }
      block.save = false;
    }
    return block;
  });
};

export const handleBetterdocsBlockGalleryButtonActions = (
  blocksLocal,
  payload
) => {
  return blocksLocal.map((block) => {
    if (block.id === payload.blockId) {
      switch (payload.actionType) {
        case 'create':
          let buttonCategory = payload.payloadData.buttonCategory;

          if (block.data.output_instructions.buttons === undefined) {
            block.data.output_instructions.buttons = [];
          }

          block.data.output_instructions.buttons = [
            ...block.data.output_instructions.buttons,
            {
              title:
                buttonCategory === 'ecommerce'
                  ? 'Document Link'
                  : 'Button Title',
              type:
                buttonCategory === 'ecommerce' ? 'betterdocs_url' : 'sequence',
              category: buttonCategory,
              value: '',
              webview_height_ratio: 'tall',
              messenger_extensions: false,
              form_sequence: 0,
            },
          ];
          break;
        case 'update':
          block.data.output_instructions.buttons = getUpdatedButtonElement(
            block.data.output_instructions.buttons,
            payload.payloadData.buttonData
          );
          break;
        case 'delete':
          block.data.output_instructions.buttons = [
            ...block.data.output_instructions.buttons.slice(
              0,
              payload.payloadData.buttonIndex
            ),
            ...block.data.output_instructions.buttons.slice(
              payload.payloadData.buttonIndex + 1
            ),
          ];
          break;
        case 'changeButtonPosition':
          block.data.output_instructions.buttons =
            payload.payloadData.buttonData;
          break;
        default:
          console.log('');
      }
      block.save = false;
    }
    return block;
  });
};
