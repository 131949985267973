import React from 'react';
import { Spinner } from 'evergreen-ui';
import NoTicketImage from '../assets/images/crmMiddleBarNoData.svg';
import defaultAvatar from '../../../../assets/images/defaultAvatar.svg';
import botIcon from '../../../assets/images/robot.svg';
import Linkify from 'react-linkify';
import AvatarComponent from '../../../../old/components/elements/redesign/AvatarComponent';
import { PaperClipIcon } from '@heroicons/react/24/outline';

import {
  TextMessage,
  NoteMessage,
  QuickReplyMessage,
  ButtonMessage,
  ImageMessage,
  GalleryMessage,
} from 'inconnect-chat-ui';

import { feedChannels } from '../../../../utilities/utils';
import moment from 'moment';

interface props {
  lineCount: number;
  ticketsAvailable: boolean;
  chatData: any;
  loading: boolean;
  isLoading: boolean;
  currentTicket: any;
}

const CRMChat: React.FC<props> = ({
  lineCount,
  ticketsAvailable,
  chatData,
  loading,
  isLoading,
  currentTicket,
}) => {
  // let content: any = null;
  // let supportsScrollBehavior: any = false;

  // const setContentRef = (element: any) => {
  //   content = element;
  // };

  // const onNodeInserted = (event: any) => {
  //   const {currentTarget: target} = event;
  //
  //   if (supportsScrollBehavior) {
  //     target.scroll({
  //       top: target.scrollHeight,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     target.scrollTop = target?.scrollHeight;
  //   }
  // };

  // const onResize = () => {
  //   if (content) {
  //     content.scrollTop = content?.scrollHeight;
  //   }
  // };

  // React.useEffect(() => {
  //   // eslint-disable-next-line
  //   supportsScrollBehavior = 'scrollBehavior' in document.documentElement.style;
  //   // eslint-disable-next-line
  //   if (content) {
  //     content.addEventListener('DOMNodeInserted', onNodeInserted);
  //     window.addEventListener('resize', onResize);
  //   }
  //   // eslint-disable-next-line
  // }, []);
  //
  // React.useEffect(() => {
  //   return () => {
  //     if (content) {
  //       content.removeEventListener('DOMNodeInserted', onNodeInserted);
  //       window.removeEventListener('resize', onResize);
  //     }
  //   };
  // });

  const scrollToBottom = () => {
    const supportsScrollBehavior =
      'scrollBehavior' in document.documentElement.style;
    const container = document.getElementById('chat-container');
    if (container) {
      if (supportsScrollBehavior) {
        container.scroll({
          top: container.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        container.scrollTop = container?.scrollHeight;
      }
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [loading]);

  React.useEffect(() => {
    const lastMessage = chatData[chatData.length - 1];
    if (lastMessage?.source === 'admin') {
      scrollToBottom();
    }
  }, [chatData]);

  const getChatAvatar = (data: any, currentTicket: any) => {
    if (data.source === 'customer') {
      return currentTicket?.customer?.avatar || defaultAvatar
    } else if (data.source === 'admin') {
      return data?.admin_info?.avatar || defaultAvatar
    } else if (data.source === 'bot') {
      return botIcon
    } else {
      return defaultAvatar
    }
  }

  const SmartChatData = (data: any, i: number) => {
    switch (data?.data?.type) {
      case 'text':
        let showInfo = true;
        if (i < chatData.length - 1) {
          if (chatData[i].source === chatData[i + 1].source) {
            showInfo = false;
          }
        }
        return (
          <Linkify
            key={i}
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target='blank'
                className='text-red-600'
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            <TextMessage
              avatar={getChatAvatar(data, currentTicket)}
              consumer='admin'
              msgTime={moment(data.timestamp).fromNow()}
              showRepliedBy={true}
              repliedBy={
                data.source !== 'customer'
                  ? data.source === 'bot'
                    ? 'Bot'
                    : data.source === 'admin' && data?.admin_info?.full_name
                  : currentTicket.customer.full_name
              }
              showInfo={showInfo}
              showMsgStatus={true}
              msgStatus={
                data.source === 'customer'
                  ? 'sent'
                  : data.success === null
                  ? 'pending'
                  : !!data.success
                  ? 'sent'
                  : 'failed'
              }
              text={
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      target='blank'
                      className='text-link'
                      href={decoratedHref}
                      key={key}
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  {data.source === 'customer'
                    ? !!data.data.text
                      ? data.data.text
                      : 'Text Unavailable'
                    : !!data.data?.data?.text
                      ? data.data.data.text
                      : !!data.data?.text
                      ? data.data.data.text:'Text Unavailable'}
                </Linkify>
              }
              userType={data.source === 'customer' ? 'user' : 'admin'}
            />
          </Linkify>
        );
      case 'note':
        return (
          <NoteMessage
            msgTime={moment(data.timestamp).fromNow()}
            key={i}
            note={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data.data.data.text}
              </Linkify>
            }
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            takenBy={data.source === 'admin' ? data?.admin_info?.full_name : ''}
          />
        );
      case 'action':
        switch (data.data.data.sub_type) {
          case 'note':
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target='blank'
                        className='text-link'
                        href={decoratedHref}
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {data.data.data.text}
                  </Linkify>
                }
                showMsgStatus={true}
                intent='lime'
                msgStatus={
                  data.source === 'customer'
                    ? 'sent'
                    : data.success === null
                    ? 'pending'
                    : !!data.success
                    ? 'sent'
                    : 'failed'
                }
                takenBy={
                  data.source === 'admin'
                    ? data?.admin_info?.full_name
                    : data.source === 'bot'
                    ? 'Bot'
                    : ''
                }
              />
            );
          case 'create-ticket':
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target='blank'
                        className='text-link'
                        href={decoratedHref}
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {data.data.data.text}
                  </Linkify>
                }
                showMsgStatus={true}
                intent='lime'
                msgStatus={
                  data.source === 'customer'
                    ? 'sent'
                    : data.success === null
                    ? 'pending'
                    : !!data.success
                    ? 'sent'
                    : 'failed'
                }
                takenBy={
                  data.source === 'admin'
                    ? data?.admin_info?.full_name
                    : data.source === 'bot'
                    ? 'Bot'
                    : ''
                }
              />
            );
          default:
            return (
              <NoteMessage
                msgTime={moment(data.timestamp).fromNow()}
                key={i}
                note={
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target='blank'
                        className='text-link'
                        href={decoratedHref}
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {data.data.data.text}
                  </Linkify>
                }
                showMsgStatus={true}
                intent='lime'
                msgStatus={
                  data.source === 'customer'
                    ? 'sent'
                    : data.success === null
                    ? 'pending'
                    : !!data.success
                    ? 'sent'
                    : 'failed'
                }
                takenBy={
                  data.source === 'admin'
                    ? data?.admin_info?.full_name
                    : data.source === 'bot'
                    ? 'Bot'
                    : ''
                }
              />
            );
        }
      case 'quick_reply':
        return (
          <QuickReplyMessage
            key={i}
            buttonData={data.data.data.buttons.map((btn: any) => ({
              methodType: btn?.type === 'sequence' ? 'function' : 'url',
              title: btn.title,
              isDisabled: true,
            }))}
            avatar={getChatAvatar(data, currentTicket)}
            consumer='admin'
            elementStyle={{
              fontSize: '0.88rem',
            }}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            text={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data.data.data.text}
              </Linkify>
            }
          />
        );
      case 'button':
      case 'csat':
        return (
          <ButtonMessage
            key={i}
            buttonData={data.data.data.buttons.map((btn: any) => ({
              methodType: btn?.type === 'sequence' ? 'function' : 'url',
              title: btn.title,
              isDisabled: true,
            }))}
            avatar={getChatAvatar(data, currentTicket)}
            consumer='admin'
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            elementStyle={{
              fontSize: '0.88rem',
            }}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            text={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data.data.data.text}
              </Linkify>
            }
          />
        );
      case 'attachment':
        const conditionalType =
          data.source === 'customer'
            ? data?.data?.attachment?.type
            : data.data.data.sub_type;
        switch (conditionalType) {
          case 'image':
            return (
              <ImageMessage
                key={i}
                avatar={getChatAvatar(data, currentTicket)}
                consumer='admin'
                showMsgStatus={true}
                msgStatus={
                  data.source === 'customer'
                    ? 'sent'
                    : data.success === null
                    ? 'pending'
                    : !!data.success
                    ? 'sent'
                    : 'failed'
                }
                images={
                  data.source === 'customer'
                    ? data.data.attachment.urls
                    : data.data.data.urls
                }
                msgTime={moment(data.timestamp).fromNow()}
                showPreview
                showRepliedBy={true}
                repliedBy={
                  data.source !== 'customer'
                    ? data.source === 'bot'
                      ? 'Bot'
                      : currentTicket.agents.length > 0 &&
                        currentTicket.agents[0].full_name
                    : currentTicket.customer.full_name
                }
                text={
                  !!data.data?.text ? (
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          target='blank'
                          className='text-link'
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {data.data.text}
                    </Linkify>
                  ) : (
                    ''
                  )
                }
                elementStyle={{ fontSize: '0.88rem' }}
                userType={data.source === 'customer' ? 'user' : data.source}
              />
            );
          case 'audio':
            let audioClipURL =
              data.source === 'admin'
                ? data?.data?.data?.urls[0]
                : data?.data?.attachment?.urls[0];
            return (
              <div key={i} className={`mb-2`}>
                {!!audioClipURL ? (
                  <div
                    className={`flex h-10 mb-2 ${
                      data.source === 'admin' ? 'flex-row-reverse' : ''
                    }`}
                  >
                    <AvatarComponent
                      src={
                        data.source === 'customer'
                          ? currentTicket.customer.avatar || defaultAvatar
                          : data.source === 'bot'
                          ? botIcon
                          : data?.admin_info?.avatar || defaultAvatar
                      }
                      size='w-6 h-6'
                    />
                    <audio src={audioClipURL} controls className='mx-2'>
                      <p>
                        Your browser does not support HTML5 audio, but you can
                        still
                        <a href={audioClipURL}>download the music</a>.
                      </p>
                    </audio>
                  </div>
                ) : (
                  <TextMessage
                    key={i}
                    avatar={getChatAvatar(data, currentTicket)}
                    consumer='admin'
                    msgTime={moment(data.timestamp).fromNow()}
                    showRepliedBy={true}
                    repliedBy={
                      data.source !== 'customer'
                        ? data.source === 'bot'
                          ? 'Bot'
                          : data.source === 'admin' &&
                            data?.admin_info?.full_name
                        : currentTicket.customer.full_name
                    }
                    showInfo={false}
                    showMsgStatus={true}
                    msgStatus={
                      data.source === 'customer'
                        ? 'sent'
                        : data.success === null
                        ? 'pending'
                        : !!data.success
                        ? 'sent'
                        : 'failed'
                    }
                    text={`${conditionalType.toUpperCase()} Url Is Not Valid`}
                    userType={data.source === 'customer' ? 'user' : 'admin'}
                  />
                )}
              </div>
            );
          case 'video':
            let videoClipURL =
              data.source === 'admin'
                ? data?.data?.data?.urls[0]
                : data?.data?.attachment?.urls[0];
            return (
              <div key={i} className={`mb-2`}>
                {!!videoClipURL ? (
                  <div
                    className={`flex mb-2 ${
                      data.source === 'admin' ? 'flex-row-reverse' : ''
                    }`}
                  >
                    {' '}
                    <AvatarComponent
                      src={
                        data.source === 'customer'
                          ? currentTicket.customer.avatar || defaultAvatar
                          : data.source === 'bot'
                          ? botIcon
                          : data?.admin_info?.avatar || defaultAvatar
                      }
                      size='w-6 h-6'
                    />
                    <video
                      height='100'
                      width='160'
                      controls
                      className='mx-2 rounded-md'
                    >
                      <source src={videoClipURL} />
                      <p>
                        Your browser does not support the video tag.
                        <a href={videoClipURL}>Download video</a>
                      </p>
                    </video>
                  </div>
                ) : (
                  <TextMessage
                    key={i}
                    avatar={getChatAvatar(data, currentTicket)}
                    consumer='admin'
                    msgTime={moment(data.timestamp).fromNow()}
                    showRepliedBy={true}
                    repliedBy={
                      data.source !== 'customer'
                        ? data.source === 'bot'
                          ? 'Bot'
                          : data.source === 'admin' &&
                            data?.admin_info?.full_name
                        : currentTicket.customer.full_name
                    }
                    showInfo={false}
                    showMsgStatus={true}
                    msgStatus={
                      data.source === 'customer'
                        ? 'sent'
                        : data.success === null
                        ? 'pending'
                        : !!data.success
                        ? 'sent'
                        : 'failed'
                    }
                    text={`${conditionalType.toUpperCase()} Url Is Not Valid`}
                    userType={data.source === 'customer' ? 'user' : 'admin'}
                  />
                )}
              </div>
            );
          case 'file':
            return (
              <div key={i} className='flex mb-1'>
                {data?.data?.attachment?.urls[0] ? (
                  <>
                    <AvatarComponent
                      src={
                        data.source === 'customer'
                          ? currentTicket.customer.avatar || defaultAvatar
                          : data.source === 'bot'
                          ? botIcon
                          : data?.admin_info?.avatar || defaultAvatar
                      }
                      size='w-6 h-6'
                    />
                    <div className='flex px-3 py-2 mt-1 ml-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200'>
                      <PaperClipIcon className='h-4 w-4 mt-0.5 mr-1' />
                      <a
                        href={data?.data?.attachment?.urls[0]}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Download attached file
                      </a>
                    </div>
                  </>
                ) : (
                  <TextMessage
                    key={i}
                    avatar={getChatAvatar(data, currentTicket)}
                    consumer='admin'
                    msgTime={moment(data.timestamp).fromNow()}
                    showRepliedBy={true}
                    repliedBy={
                      data.source !== 'customer'
                        ? data.source === 'bot'
                          ? 'Bot'
                          : data.source === 'admin' &&
                            data?.admin_info?.full_name
                        : currentTicket.customer.full_name
                    }
                    showInfo={false}
                    showMsgStatus={true}
                    msgStatus={
                      data.source === 'customer'
                        ? 'sent'
                        : data.success === null
                        ? 'pending'
                        : !!data.success
                        ? 'sent'
                        : 'failed'
                    }
                    text={`${conditionalType.toUpperCase()} Url Is Not Valid`}
                    userType={data.source === 'customer' ? 'user' : 'admin'}
                  />
                )}
              </div>
            );
          default:
            return (
              <TextMessage
                key={i}
                avatar={getChatAvatar(data, currentTicket)}
                consumer='admin'
                msgTime={moment(data.timestamp).fromNow()}
                showRepliedBy={true}
                repliedBy={
                  data.source !== 'customer'
                    ? data.source === 'bot'
                      ? 'Bot'
                      : data.source === 'admin' && data?.admin_info?.full_name
                    : currentTicket.customer.full_name
                }
                showInfo={false}
                showMsgStatus={true}
                msgStatus={
                  data.source === 'customer'
                    ? 'sent'
                    : data.success === null
                    ? 'pending'
                    : !!data.success
                    ? 'sent'
                    : 'failed'
                }
                text={`${conditionalType.toUpperCase()} Not Implemented`}
                userType={data.source === 'customer' ? 'user' : 'admin'}
              />
            );
        }
      case 'gallery':
        return (
          <GalleryMessage
            key={i}
            showMsgStatus={true}
            avatar={getChatAvatar(data, currentTicket)}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            elementStyle={
              data.source === 'user'
                ? { fontSize: '0.88rem', background: '#e5e9ee' }
                : { fontSize: '0.88rem', background: '#184D47', color: '#fff' }
            }
            carouselHeight='425px'
            carouselWidth='525px'
            cellSpacing={25}
            galleryItemClassName='gallery-item-style-fix'
            consumer={data.source === 'user' ? data.source : 'admin'}
            galleryData={data.data.data.elements.map((galleryItem: any) => {
              return {
                id: galleryItem.id,
                image: galleryItem.image,
                subtitle: galleryItem.subtitle,
                title: galleryItem.title,
                buttons: galleryItem.buttons.map((btn: any) => ({
                  methodType: btn?.type === 'sequence' ? 'function' : 'url',
                  title: btn.title,
                  isDisabled: true,
                })),
              };
            })}
            hasTitle={false}
            msgTime={moment(data.timestamp).fromNow()}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            slideToShow={2}
          />
        );
      case 'input':
        return (
          <TextMessage
            key={i}
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            avatar={getChatAvatar(data, currentTicket)}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            showInfo={false}
            text={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data?.data?.data?.text || `${data?.data?.type} Was Sent`}
              </Linkify>
            }
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
      case 'template':
        return (
          <TextMessage
            key={i}
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            avatar={getChatAvatar(data, currentTicket)}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            showInfo={false}
            text={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data?.data?.data?.text}
              </Linkify>
            }
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
      default:
        return (
          <TextMessage
            key={i}
            showMsgStatus={true}
            msgStatus={
              data.source === 'customer'
                ? 'sent'
                : data.success === null
                ? 'pending'
                : !!data.success
                ? 'sent'
                : 'failed'
            }
            consumer='admin'
            msgTime={moment(data.timestamp).fromNow()}
            avatar={getChatAvatar(data, currentTicket)}
            showRepliedBy={true}
            repliedBy={
              data.source !== 'customer'
                ? data.source === 'bot'
                  ? 'Bot'
                  : currentTicket.agents.length > 0 &&
                    currentTicket.agents[0].full_name
                : currentTicket.customer.full_name
            }
            showInfo={false}
            text={
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target='blank'
                    className='text-link'
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {data?.data?.type} Was Sent
              </Linkify>
            }
            userType={data.source === 'customer' ? 'user' : 'admin'}
          />
        );
    }
  };
  return (
    <div
      className={
        feedChannels.includes(chatData?.platform_info?.type)
          ? 'feed-container  customer-chat__container'
          : 'customer-chat__container'
      }
      // ref={setContentRef}
      id={'chat-container'}
      style={{
        // previously it was `calc( 100vh - ${206 + (lineCount - 1) * 16}px ) )`
        height: `calc( 100% - ${206 + (lineCount - 1) * 16}px )`,
      }}
    >
      {!ticketsAvailable && !loading && !isLoading && (
        <div className='flex justify-center align-center h-100p flex-column'>
          <img src={NoTicketImage} alt={'no data'} width={300} />
          <p className='grey-text'>Your Inbox is Empty </p>
          <p className='grey-text'>Your Bots are doing a great job</p>
        </div>
      )}

      {(loading || isLoading) && (
        <div className='flex justify-center align-center h-100p'>
          <Spinner />
        </div>
      )}
      {!loading &&
        (!chatData || chatData.length === 0) &&
        ticketsAvailable &&
        !isLoading && (
          <div className='flex justify-center align-center h-100p flex-column'>
            <img src={NoTicketImage} alt={'no data'} width={300} />

            <p className='grey-text'>
              Kinda Empty Here. Did you connect your channels?
            </p>
          </div>
        )}
      {!loading &&
        !!chatData &&
        ticketsAvailable &&
        chatData?.platform_info?.type !== 'facebook_feed' &&
        chatData
          .filter((elem: any) => !!elem.data)
          .map((elem: any, i: number) => SmartChatData(elem, i))}
    </div>
  );
};

export default CRMChat;
