import React, { useRef } from 'react';
import { Loader2 } from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DataTablePagination,
  DataTableToolbar,
  Button,
  useState,
  Trash,
  NoDataFound,
  DataTableRowActions,
  emptySearchIcon,
  Separator,
} from '../../../export';
import { toast } from 'libraryV2/ui/use-toast';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import DeleteAlert from '../DeleteAlert';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import { toInteger } from 'lodash';
import useTranslation from 'components/customHooks/useTranslation';
import { getLocalizedTimeDateFormat } from 'pages/datalab/utils';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const {
    labId,
    pageChange,
    totalRowCount,
    hasDeleteAccess,
    isFilterApplied,
    isSearchApplied,
    updatePageChange,
    deleteBulkDatalabList,
    updateDatalabPageChange,
    hasReadAccess,
    hasWriteAccess,
    isLoading,
  } = useDatalab();

  const { t } = useTranslation();
  const { limit, offset } = pageChange;

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const [hoveredRow, setHoveredRow] = useState(null);
  const [ellipsisStyles, setEllipsisStyles] = useState({
    top: 0,
    height: 0,
  });
  const tableRef = useRef(null);

  const handleMouseEnter = (event: any, row: any) => {
    setHoveredRow(row);

    // Get the position of the hovered row relative to the table
    const rowRect = event.currentTarget.getBoundingClientRect();
    //@ts-ignore
    const tableRect = tableRef?.current?.getBoundingClientRect();
    const topPosition = rowRect.top - tableRect.top;
    setEllipsisStyles({
      top: topPosition,
      height: rowRect.height - 5,
    });
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const excludedColumnHeader = [
    'id',
    'lab_id',
    'creator_id',
    'mongo_timestamp',
  ];

  const filteredColumns = columns.filter(
    //@ts-ignore
    (column) => !excludedColumnHeader.includes(column.accessorKey)
  );

  const table = useReactTable({
    data,
    columns: filteredColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    getRowId: (originalRow) => originalRow?.id,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
    pageCount: Math.ceil(totalRowCount / limit),
  });

  const selectedRows = React.useMemo(() => {
    //@ts-ignore
    return table.getRowModel().rows.filter((row) => rowSelection[row.id]);
  }, [rowSelection, table]);

  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  const handleBulkDeleteDataLabEntries = async () => {
    try {
      const listOfRowIds = selectedRows.map((row: any) => row.original.id);
      await deleteBulkDatalabList(labId, listOfRowIds);
      resetRowSelection();
      toast({
        // @ts-expect-error Just bad typing
        title: <p className='text-green-400'>{t('Delete Successful!')}</p>,
        description: `${selectedRowCount} ${t(
          'entries have been deleted successfully.'
        )}`,
      });
    } catch (error) {
      toast({
        // @ts-expect-error Just bad typing
        title: <p className='text-red-600'>{t('Error!')}</p>,
        description: t('There was an error deleting the entries.'),
      });
    }
  };

  const selectedRowCount = selectedRows.length;

  const resetRowSelection = () => {
    setRowSelection({});
  };

  const handleLimitChange = async (newLimit: number) => {
    updatePageChange(newLimit, offset);
    updateDatalabPageChange(newLimit, offset, searchKey);
  };

  const handlePageChange = (newOffset: number) => {
    updatePageChange(limit, newOffset);
    updateDatalabPageChange(limit, newOffset, searchKey);
  };

  const renderDeleteButton = () => {
    if (selectedRows?.length <= 0) {
      return null;
    }
    return (
      <>
        <Separator
          orientation='vertical'
          className='border bg-zinc-200 mb-1 h-[20px]'
        />
        <div className='flex gap-2'>
          <Button
            className={`flex gap-1.5 text-white bg-destructive-hover hover:bg-destructive-hover hover:text-white px-2.5 py-1.5 w-[80px] h-8 ${
              !hasDeleteAccess
                ? 'cursor-not-allowed bg-gray-200 text-gray-500'
                : ''
            }`}
            variant='outline'
            disabled={!hasDeleteAccess}
            onClick={openAlert}
          >
            <span>
              <Trash className='w-4 h-4' />
            </span>
            <span className='font-medium text-xs'>{t('Delete')}</span>
          </Button>

          <Button
            size='sm'
            variant='outline'
            className='flex gap-2'
            onClick={() => setRowSelection({})}
          >
            <span>Cancel</span>
          </Button>
          <DeleteAlert
            open={isAlertOpen}
            onOpenChange={setIsAlertOpen}
            title={`${t('Delete')} ${selectedRowCount} ${t('Entries')}`}
            description={
              <span className='text-textSecondary'>
                {t(
                  'This will permanently delete entries that you have selected. Are you sure you want to delete?'
                )}
              </span>
            }
            onCancel={closeAlert}
            onConfirm={handleBulkDeleteDataLabEntries}
          />
        </div>
      </>
    );
  };

  return (
    <div className='m-4 space-y-2'>
      <DataTableToolbar
        table={table}
        renderDeleteButton={renderDeleteButton}
        selectedRows={selectedRows}
        setSearchKey={setSearchKey}
      />
      <div className='relative'>
        <div className='border rounded-md' ref={tableRef}>
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className='text-sm'
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  ))}
                  <TableHead></TableHead>
                </TableRow>
              ))}
            </TableHeader>
            {isLoading === true ? (
              <div className='flex items-center justify-center w-[86vw] h-[150px]'>
                <Loader2 size={32} className='text-green-500 animate-spin' />
              </div>
            ) : (
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row, index) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className={`cursor-pointer bg-white h-12 ${
                        //@ts-ignore
                        hoveredRow?.id === row.id ? 'bg-gray-50' : ''
                      }`}
                      onMouseEnter={(event) => handleMouseEnter(event, row)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <>
                          <TableCell
                            key={cell.id}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {cell.column.id === 'created_at' ||
                            cell.column.id === 'last_updated_at'
                              ? !!cell.getValue()
                                ? getLocalizedTimeDateFormat(
                                    toInteger(cell.getValue()) * 1000,
                                    'en',
                                    new Date(
                                      toInteger(cell.getValue()) * 1000
                                    ).getTime() > 0
                                      ? 'MMM DD YYYY, hh:mm A'
                                      : 'MMM DD, YYYY'
                                  )
                                : '-'
                              : flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                          </TableCell>
                        </>
                      ))}
                      <TableCell></TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className='text-center h-[500px]'
                    >
                      {isFilterApplied || isSearchApplied ? (
                        <div className='flex flex-col items-center justify-center'>
                          <img
                            src={emptySearchIcon}
                            alt='emptySearchIcon'
                            className='w-48 h-24 mb-4'
                          />
                          <h3 className='mb-2 text-center text-textPrimary'>
                            No Results Found
                          </h3>
                          <span className='subtitle-medium'>
                            {isFilterApplied
                              ? 'Please try with different filter.'
                              : 'Please search with different keyword.'}
                          </span>
                        </div>
                      ) : (
                        <NoDataFound hasButton={false} />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <DataTablePagination
            table={table}
            limit={limit}
            offset={offset}
            totalRowCount={totalRowCount}
            onLimitChange={handleLimitChange}
            onPageChange={handlePageChange}
          />
        </div>

        {/* Fixed ellipsis on the right side, dynamically positioned based on hovered row */}
        {hoveredRow !== null && (
          <div
            className='absolute right-1 mt-0.5 w-[60px] flex justify-center items-center'
            style={{
              top: ellipsisStyles.top,
              backgroundImage:
                'linear-gradient(270deg, rgb(250, 250, 250) 70%, rgba(244, 244, 245, 0) 100%)',
              width: '60px',
              height: `${ellipsisStyles.height}px`,
            }}
            onMouseEnter={() => setHoveredRow(hoveredRow)} // Prevent hiding when hovering over the ellipsis
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DataTableRowActions
              row={hoveredRow}
              labId={labId}
              hasReadAccess={hasReadAccess}
              hasWriteAccess={hasWriteAccess}
              hasDeleteAccess={hasDeleteAccess}
              deleteBulkDatalabList={deleteBulkDatalabList}
              OnCloseDropdown={() => setHoveredRow(null)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
