import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import cloneDeep from 'lodash/cloneDeep';
import { toaster } from 'evergreen-ui';
import {
  getUpdatedBlocks,
  handleProductDiscoveryBlockDataUpdate,
  handleProductDiscoveryBlockGalleryButtonActions,
  postBlockUpdate,
} from '../../../../utilities/utils';
import {
  handleBetterdocsBlockDataUpdate,
  handleBetterdocsBlockGalleryButtonActions,
} from '../../../components/helpers/blockUtils';
import {
  handleCouponBlockActions,
  handleSetAttributeBlockActions,
  handleViewCartBlockGalleryButtonActions,
} from '../../../components/helpers/blockUtils';
import moment from 'moment';

const initialState = {
  blocks: [],
  blockRules: [],
  blockRulesEditId: -1,
  agentList: [],
  agentTagList: [],
  allTicketTags: [],
  paymentMethodList: [],
  shippingMethodList: [],
  viewCartParameters: [],
  productDiscoveryBlockParameters: [],
  couponDiscoveryBlockParameters: [],
};

const block = {
  state: {
    ...initialState,
  },
  reducers: {
    updateBlocks(state, payload) {
      /*
      payload = [{
        "id":num,
        "serial":num,
        "type":string,
        "data":{
          "text":string
          }
        }...]
      */
      const updatedBlocks = postBlockUpdate(payload);
      return { ...state, blocks: updatedBlocks };
    },

    updateSingleBlock(state, payload) {
      /*
       payload = {
        "id":num,
        "serial":num,
        "type":string,
        "data":{
          "text":string
          }
        }
      */
      payload['save'] = true;
      return { ...state, blocks: [...state.blocks, payload] };
    },

    updateTextBlock(state, payload) {
      /*
      payload = {
        "data": string or number // data that will change
        "id": num //id of block
        "changeKey": string // key that will change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          if (payload.changeKey === 'text') {
            block.data['text'] = payload.data;
            block.save = false;
          }
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    updateEmailSendBlock(state, payload) {
      /*
      payload = {
        "data": string or number // data that will change
        "id": num //id of block
        "changeKey": string // key that will change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data[payload.changeKey] = payload.data;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    updateSaveStatus(state, payload) {
      /*
       payload = {
        "id": num //id of block
        "status": bool // status that will change for the block
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.save = payload.status;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    updateRedirectBlock(state, payload) {
      /*
        payload = {
          "id": num //id of block
          "data": {sequence: num } // sequenceId to redirect
        }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data = payload.data;
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: blocksLocal };
    },
    updateInputBlock(state, payload) {
      /*
        payload = {
          "id": num //id of block
          "data": {sequence: num } // sequenceId to redirect
          "changeKey": string  // key that will change
        }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data[payload.changeKey] = payload.data;
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: blocksLocal };
    },

    updateImageBlock(state, payload) {
      /*
        payload = {
          "id": num //id of block
          "data": string // image url
        }
      */
      const blocksLocal = cloneDeep(state.blocks);
      const updateBlocks = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.urls = [payload.data];
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: updateBlocks };
    },

    updateSubscribeBlockTitle(state, payload) {
      /*
      payload = {
        "id": num //id of block
         "data": string or number // data that will change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data['title'] = payload.data;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    createSubscribeElement(state, payload) {
      /*
      payload = {
        "id": num //id of block
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.sequences = [
            ...block.data.sequences,
            { sequence: null, value: null, type: null },
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    createButtonElement(state, blockId) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === blockId) {
          block.data.buttons = [
            ...block.data.buttons,
            {
              title: 'Button Title',
              type: 'sequence',
              value: '',
              webview_height_ratio: 'tall',
              messenger_extensions: false,
              form_sequence: 0,
              input_value: '',
            },
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    createAPIButtonElement(state, payload) {
      /*
      payload = {
        blockId: num //block id of current sequence,
        api: object // api that is selected,
        isDeleted: bool // checks if api is delete from the block
      */

      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          if (!!payload.isDeleted) {
            block.data['api'] = null;
            block.data['api_id'] = null;
          } else {
            block.data['api'] = {
              title: payload.api.label,
              id: payload.api.value,
            };
            block.data['api_id'] = parseInt(payload.api.value);
          }
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    updateButtonElements(state, payload) {
      /**
       * payload = {
       *   blockId: number,
       *   data: array (button data)s
       * }
       */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.buttons = payload.data;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    updateSubscribeSequenceData(state, payload) {
      /*
      payload = {
        "id": num //id of block
        "subscriptionIndex": num //index of subscription
        "data": string or number // data of subscription object
        "changeKey": string //key that will change
      }
      */

      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.sequences[payload.subscriptionIndex][payload.changeKey] =
            payload.data;
          block.save = false;

          if (payload.changeKey === 'type') {
            if (payload.data === 'time') {
              block.data.sequences[payload.subscriptionIndex]['value'] = moment
                .utc()
                .format('HH:mm');
            } else {
              block.data.sequences[payload.subscriptionIndex]['value'] = '';
            }
          }
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    deleteSubscriberSequenceData(state, { sequenceId, subscribeId }) {
      /*
        {sequenceId: number, subscribeId: index}
       */

      let updatedBlocks = cloneDeep(state.blocks).map((block) => {
        if (block.id === sequenceId) {
          block.data.sequences = block.data.sequences.filter(
            (subscribe, i) => i !== subscribeId
          );
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: updatedBlocks };
    },

    updateUnsubscribeBlock(state, payload) {
      /*
      payload = {
        "id": number//id of block
        "data": number // subscription ID
      }
      */

      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.subscribe_block = payload.data;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    updateURLBlocks(state, payload) {
      /*
        payload = {
          "id": num //id of block
          "data": string   // string url for url block
          "changeKey": string  // key that will change
        }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.urls = [payload.data];
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: blocksLocal };
    },
    createGalleryButtonElement(state, payload) {
      /**
       * payload = {
       *   blockId: number,
       *   galleryIndex: number
       * }
       *
       */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          const newElements = block.data.elements.map((elem, i) => {
            if (i === payload.galleryIndex) {
              return {
                ...elem,
                buttons: [
                  ...elem.buttons,
                  {
                    title: 'Button Title',
                    type: 'sequence',
                    value: '',
                    webview_height_ratio: 'tall',
                    messenger_extensions: false,
                    form_sequence: 0,
                  },
                ],
              };
            }
            return { ...elem };
          });
          return {
            ...block,
            data: { ...block.data, elements: newElements },
            save: false,
          };
        }
        return { ...block };
      });
      return { ...state, blocks: updatedBlock };
    },
    updateGalleryBlockData(state, payload) {
      /**
       * payload = {
       *   blockId: number,
       *   key: key that will change
       *   value: value
       * }
       */
      let blocksLocal = [...state.blocks];
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data[payload.key] = payload.value;
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: updatedBlock };
    },
    updateGalleryButtonElements(state, payload) {
      /**
       * payload = {
       *   blockId: number,
       *   galleryIndex: number,
       *   data: buttons data
       * }
       *
       */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          const newElements = block.data.elements.map((elem, i) => {
            if (i === payload.galleryIndex) {
              return {
                ...elem,
                buttons: payload.data,
              };
            }
            return { ...elem };
          });
          return { ...block, data: { elements: newElements }, save: false };
        }
        return { ...block };
      });
      return { ...state, blocks: updatedBlock };
    },

    createGalleryElement(state, blockId) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === blockId) {
          block.data.elements = [
            ...block.data.elements,
            {
              image: '',
              title: '',
              subtitle: '',
              url: '',
              buttons: [],
              webview_height_ratio: 'tall',
            },
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    updateGalleryElements(state, payload) {
      /**
       * payload = {
       *   blockId: number,
       *   daata: gallery data
       * }
       */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.elements = payload.data;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    updateButtonsBlock(state, payload) {
      /*
      payload = {
        "data": string for 'text',
        for buttons -> {
          "id": number,
          "buttonIndex": number,
          "title": string,
          "type": string,
          "value": string,
          "form_sequence": int
        },
        "id": num //id of block
        "changeKey": string // key that will change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = getUpdatedBlocks(blocksLocal, payload, 'buttons');
      return { ...state, blocks: updatedBlock };
    },
    updateLiveChatButtonBlock(state, payload) {
      /*
      payload = {
        id: num //blockid
        data: object  //button data
        changeKey: string // not needed in this as this is custom function, for global it was the key we would change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((b) => {
        if (b.id === payload.id) {
          return {
            ...b,
            data: { ...b.data, button: payload.data },
            save: false,
          };
        }
        return { ...b };
      });
      // const updatedBlock = getUpdatedBlocks(blocksLocal, payload, 'buttons');
      return { ...state, blocks: blocksLocal };
    },

    updateGalleryBlock(state, payload) {
      /*
      payload = {
        "data": string for 'text',
        for buttons -> {
          "id": number,
          "buttonIndex": number,
          "galleryIndex": number,
          "title": string,
          "type": string,
          "value": string,
        },
        "id": num //id of block
        "changeKey": string // key that will change
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = getUpdatedBlocks(blocksLocal, payload, 'gallery');
      return { ...state, blocks: updatedBlock };
    },

    deleteButtonElement(state, payload) {
      /*
      payload = {
        blockId: number,
        buttonIndex: number,
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.buttons = [
            ...block.data.buttons.slice(0, payload.buttonIndex),
            ...block.data.buttons.slice(payload.buttonIndex + 1),
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    deleteGalleryItem(state, payload) {
      /*
      payload = {
        blockId: number,
        galleryIndex: number,
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.elements = [
            ...block.data.elements.slice(0, payload.galleryIndex),
            ...block.data.elements.slice(payload.galleryIndex + 1),
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    deleteGalleryButtonElement(state, payload) {
      /*
      payload = {
        blockId: number,
        buttonIndex: number,
        galleryIndex: number
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          const newElements = block.data.elements.map((elem, i) => {
            if (payload.galleryIndex === i) {
              return {
                ...elem,
                buttons: [
                  ...elem.buttons.slice(0, payload.buttonIndex),
                  ...elem.buttons.slice(payload.buttonIndex + 1),
                ],
              };
            }
            return { ...elem };
          });
          block.save = false;
          block.data.elements = newElements;
        }
        return block;
      });
      return { ...state, blocks: updatedBlock };
    },

    removeBlock(state, payload) {
      /*
    payload = {
      blockId: number,
    }
    */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = blocksLocal.filter(
        (block) => block.id !== payload.blockId
      );
      return { ...state, blocks: updatedBlock };
    },

    updateBlockRulesList(state, payload) {
      /*
      payload = [{
        id: number,
        trigger: string,
        action: string,
        payload: string,
        sequence: { id: number, title: string },
        qr_code: string,
      },
      ...
      ]
      */
      return {
        ...state,
        blockRules: payload.map((rules) => ({ ...rules, save: true })),
      };
    },

    updateBlockRulesChange(state, payload) {
      /*
        payload = {
          rulesId: number,
          key: string,
          value: string,
        }
      */
      const updateRules = state.blockRules.map((rules) => {
        if (rules.id === payload.rulesId) {
          rules[payload.key] = payload.value;
          if (payload.key === 'trigger') {
            rules.payload = '';
          }
          rules.save = false;
        }
        return rules;
      });
      return {
        ...state,
        blockRules: updateRules,
        blockRulesEditId: payload.rulesId,
      };
    },

    createBlockRule(state, selectedSequence) {
      if (state.blockRules.filter((rule) => rule.id === -1).length === 0) {
        const data = {
          id: -1,
          trigger: 'keyword',
          payload: '',
          isSaved: false,
          action: 'message',
          sequence: selectedSequence,
        };
        return { ...state, blockRules: [data, ...state.blockRules] };
      } else {
        toaster.warning('Save Before Creating One', {
          description:
            'Please Save current rules you created before creating another one',
        });
      }
      return { ...state };
    },

    updateDataLabBlock(state, payload, blockId) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === blockId) {
          let mappings = [];
          payload.lab_fields.forEach((val) => {
            const data = {
              id: val.id,
              slug: val.slug,
              name: val.name,
              attribute: 'default',
            };
            mappings = [...mappings, data];
          });
          block.data['mappings'] = mappings;
          block.data['lab'] = { id: payload.id, title: payload.title };
          block.data['lab_id'] = payload.id;
          block.save = mappings.length === 0;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    updateDataLabBlockFieldAttribute(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.id) {
          block.data.mappings.forEach((val) => {
            if (val.id === payload.data.id) {
              val[payload.changeKey] = payload.data.attribute;
            }
          });
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    addProductDiscoveryBlockInputInstruction(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.input_instructions = [
            ...block.data.input_instructions,
            payload.payloadData,
          ];
          block.save = payload.save;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    addProductDiscoveryBlockOutputInstructions(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.output_instructions = payload.payloadData;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    deleteProductDiscoveryBlockInputInstruction(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.input_instructions = [
            ...block.data.input_instructions.slice(
              0,
              payload.payloadData.index
            ),
            ...block.data.input_instructions.slice(
              payload.payloadData.index + 1
            ),
          ];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    clearProductDiscoveryBlockInputInstructions(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.input_instructions = [];
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    clearProductDiscoveryBlockOutputInstructions(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.output_instructions = {};
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },

    updateProductDiscoveryBlockData(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleProductDiscoveryBlockDataUpdate(
        blocksLocal,
        payload
      );
      return { ...state, blocks: updatedBlock };
    },

    updateBetterdocsBlockData(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleBetterdocsBlockDataUpdate(
        blocksLocal,
        payload
      );
      return { ...state, blocks: updatedBlock };
    },

    handleProductDiscoveryBlockGalleryButton(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleProductDiscoveryBlockGalleryButtonActions(
        blocksLocal,
        payload
      );
      return { ...state, blocks: updatedBlock };
    },

    handleBetterdocsBlockGalleryButton(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleBetterdocsBlockGalleryButtonActions(
        blocksLocal,
        payload
      );
      return { ...state, blocks: updatedBlock };
    },

    handleSetAttributeBlock(state, payload) {
      /*
       payload = {
        actionType: "create"/"update"/"delete"
        blockId: int,
        payloadData:
          {attribute:'', operation: '', value: ''} /for create
          {index: int, keyName: 'name of the key that will be changed ex: attribute/operation/value', keyValue: 'value of the key that will be changed ex: attribute/operation/value'} /for update
          {index: 'index number that will be deleted'} /for delete
       }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleSetAttributeBlockActions(blocksLocal, payload);
      return { ...state, blocks: updatedBlock };
    },

    handleAddToCartBlock(state, payload) {
      /*
      payload = {
        payloadData:{
          product_id:'{{x_product_id}}',
          variant_id:'{{x_variant_id}}',
          quantity: string
          }
      }
      */
      let blocksLocal = cloneDeep(state.blocks);
      blocksLocal = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data.collection[payload.payloadData.changeKey] =
            payload.payloadData.changeValue;
          block.save = false;
        }
        return block;
      });
      return { ...state, blocks: blocksLocal };
    },
    updatePlaceOrderData(state, payload) {
      /*
       payload = {
          blockId: number,
          changeKey: key that will change
          changeValue: value
        }
       */
      let blocksLocal = [...state.blocks];
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data[payload.changeKey] = payload.changeValue;
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: updatedBlock };
    },
    updateViewCartData(state, payload) {
      /*
       payload = {
          blockId: number,
          changeKey: key that will change
          changeValue: value
        }
      */
      let blocksLocal = [...state.blocks];
      const updatedBlock = blocksLocal.map((block) => {
        if (block.id === payload.blockId) {
          block.data[payload.changeKey] = payload.changeValue;
          block.save = false;
        }
        return block;
      });

      return { ...state, blocks: updatedBlock };
    },
    handleViewCartBlockGalleryButton(state, payload) {
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleViewCartBlockGalleryButtonActions(
        blocksLocal,
        payload
      );
      return { ...state, blocks: updatedBlock };
    },
    handleCouponBlock(state, payload) {
      /*
       payload = {
        actionType: "add"/"update"/"delete"
        blockId: int,
        payloadData:
          {attribute:'', operation: '', value: ''} /for create
          {index: int, keyName: 'name of the key that will be changed ex: attribute/operation/value', keyValue: 'value of the key that will be changed ex: attribute/operation/value'} /for update
          {index: 'index number that will be deleted'} /for delete
       }
      */
      let blocksLocal = cloneDeep(state.blocks);
      const updatedBlock = handleCouponBlockActions(blocksLocal, payload);
      return { ...state, blocks: updatedBlock };
    },

    updateAgents(state, payload) {
      return { ...state, agentList: payload };
    },

    updateAgentTags(state, payload) {
      return { ...state, agentTagList: payload };
    },

    updateAllTagsData(state, updatedData) {
      return { ...state, allTicketTags: updatedData };
    },

    updateProductDiscoveryBlockParameters(state, payload) {
      return { ...state, productDiscoveryBlockParameters: payload };
    },
    updatePaymentMethodList(state, payload) {
      return { ...state, paymentMethodList: payload };
    },
    updateShippingMethodList(state, payload) {
      return { ...state, shippingMethodList: payload };
    },
    updateViewCartParameters(state, payload) {
      return { ...state, viewCartParameters: payload };
    },
    updateCouponDiscoveryBlockParameters(state, payload) {
      return { ...state, couponDiscoveryBlockParameters: payload };
    },
    clearState() {
      return { ...initialState };
    },
    updateTicketTag(state, payload) {
      let { tag } = payload;
      let localTicket = { ...state.selectedTicket };
      let localTicketList = [...state.ticketList];
      let localTicketIndex = localTicketList.findIndex(
        (ticket) => ticket.id === localTicket.id
      );
      localTicketList[localTicketIndex].tags = tag;
      return {
        ...state,
        selectedTicket: {
          ...state.selectedTicket,
          tags: tag,
        },
        ticketList: localTicketList,
      };
    },
  },

  effects: (dispatch) => ({
    async fetchBlock(sequenceId) {
      try {
        const res = await axios.get(config.blocks.blocks(sequenceId));
        if (res.data.success) {
          dispatch.block.updateBlocks(res.data.dataSource);
        } else {
          dispatch.block.updateBlocks([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createBlock({ sequenceId, type, data }, state) {
      if (type === 'unsubscribe') {
        try {
          const res = await axios.get(
            config.blocks.subscription(state.dashboard.selectedPlatform.id)
          );
          if (
            res.status === 200 &&
            res.data.success &&
            res.data.dataSource.length > 0
          ) {
            const subscriptions = res.data.dataSource;
            dispatch.builder.updateSubscriptions(
              subscriptions.map((s) => ({ ...s.data, id: s.id }))
            );
            data = { ...data, subscribe_block: subscriptions[0].id };
          } else {
            toaster.danger('Failed', {
              description: `Please create a Subscribe Block first`,
              duration: 1,
            });
          }
        } catch (err) {
          console.log(err);
        }
      }

      try {
        const res = await axios.post(config.blocks.blocks(sequenceId), {
          type,
          data,
        });
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateSingleBlock(res.data.dataSource);
          dispatch.builder.fetchSubscribe(state.dashboard.selectedPlatform.id);
        }
      } catch (err) {
        if (err.response.status === 403 && !!err?.response?.data?.error) {
          toaster.danger('Failed', {
            description: err.response.data.error,
            duration: 1,
          });
        } else {
          toaster.danger('Failed', {
            description: `Failed to create the block! Please Try Again`,
            duration: 1,
          });
        }
        console.log(err);
      }
    },

    async saveBlock(id, state) {
      try {
        //do the api call with that id
        const activeSequenceId = state.builder.selectedSequence;
        const updateBlock = state.block.blocks.filter(
          (block) => block.id === id
        )[0];
        //fetching from blocks for the updated data
        const type = updateBlock.type;
        const data = updateBlock.data;

        if (!!updateBlock.data && !!updateBlock.data.api) {
          // adding api_id in the payload for updating block
          updateBlock.data.api_id = updateBlock.data.api.id;
        }

        const res = await axios.put(
          config.blocks.blocksAction(activeSequenceId, id),
          { type, data }
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateSaveStatus({ id, status: true });
          toaster.success('Successful', {
            description: `The Sequence Has Been Updated`,
            duration: 1,
          });
          dispatch.builder.fetchAttributes(state.dashboard.selectedPlatform.id);
          dispatch.builder.fetchSubscribe(state.dashboard.selectedPlatform.id);
        } else {
          toaster.danger('Failed', {
            description: `Failed to update the block! Please Try Again`,
            duration: 1,
          });
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: err?.response?.data?.error
            ? err.response.data.error
            : 'Failed to update the block! Check Your Block Again',
          duration: 1,
        });
        console.log(err);
      }
    },

    async deleteBlock({ blockId }, state) {
      // do the api call;
      try {
        const activeSequenceId = state.builder.selectedSequence;
        const res = await axios.delete(
          config.blocks.blocksAction(activeSequenceId, blockId)
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: `You Have Deleted Block From the Sequence`,
            duration: 1,
          });
          dispatch.block.removeBlock({ blockId });
          dispatch.builder.fetchSubscribe(state.dashboard.selectedPlatform.id);
        } else {
          toaster.danger('Failed', {
            description: `Failed to delete the block! Please Try Again`,
            duration: 1,
          });
        }
      } catch (err) {
        if (err.response.status === 403 && !!err?.response?.data?.error) {
          toaster.danger('Failed', {
            description: err.response.data.error,
            duration: 1,
          });
        } else {
          toaster.danger('Failed', {
            description: `Failed to delete the block! Please Try Again`,
            duration: 1,
          });
        }
        console.log(err);
      }
    },

    async copyBlock({ id, sequenceToCopy }, state) {
      const block = state.block.blocks.filter((b) => b.id === id)[0];
      const { type, data } = block;
      try {
        const res = await axios.post(config.blocks.blocks(sequenceToCopy), {
          type,
          data,
        });
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: `You Have Copied Block From the Sequence`,
            duration: 1,
          });
        } else {
          toaster.danger('Failed', {
            description: `Failed to copy the block! Please Try Again`,
            duration: 1,
          });
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: `Failed to copy the block! Please Try Again`,
          duration: 1,
        });
        console.log(err);
      }
    },

    async moveBlock({ id, sequenceToMove }, state) {
      const block = state.block.blocks.filter((b) => b.id === id)[0];
      const { type, data } = block;
      try {
        const res = await axios.post(config.blocks.blocks(sequenceToMove), {
          type,
          data,
        });
        if (res.status === 200 && res.data.success) {
          dispatch.block.deleteBlock({ blockId: id });
          toaster.success('Successful', {
            description: `You Have Moved Block From the Sequence`,
            duration: 1,
          });
        } else {
          toaster.danger('Failed', {
            description: `Failed to move the block! Please Try Again`,
            duration: 1,
          });
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: `Failed to move the block! Please Try Again`,
          duration: 1,
        });
        console.log(err);
      }
    },

    async reorderBlocks(blocks, state) {
      dispatch.block.updateBlocks(blocks);
      const sequenceId = state.builder.selectedSequence;
      const data = blocks.map((block) => ({
        id: block.id,
        serial: block.serial,
      }));
      try {
        const res = await axios.post(config.blocks.reOrderBlock(sequenceId), {
          blocks: data,
        });
        if (res.data.success) {
          dispatch.block.updateBlocks(res.data.dataSource);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAgent(projectId) {
      try {
        const res = await axios.get(config.settings.agentAccess(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateAgents(res.data.dataSource);
        } else {
          dispatch.block.updateAgents([]);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAgentTag(projectId) {
      try {
        const res = await axios.get(config.settings.agentAccessTag(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateAgentTags(res.data.dataSource);
        } else {
          dispatch.block.updateAgentTags([]);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async handleSelectedLabData(payload) {
      /*
      payload = {
        projectId : num,
        labId: num,
        blockId: num
      }
       */
      try {
        const res = await axios.get(
          config.blocks.dataLabBlock(payload.projectId, payload.labId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateDataLabBlock(
            res.data.dataSource,
            payload.blockId
          );
        }
      } catch (err) {
        toaster.danger('Failed to Get Lab Info', {
          description: `Request for getting info about lab has been failed. Please try again`,
        });
      }
    },
    async fetchProductDiscoverBlockParameter(projectId) {
      /*
        projectId : num,
      }
       */
      try {
        const res = await axios.get(
          config.blocks.productDiscoveryBlock(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateProductDiscoveryBlockParameters(
            res.data.dataSource
          );
        }
      } catch (err) {
        toaster.danger('Failed to Get E-commerce Parameters');
      }
    },
    async fetchEcommercePaymentMethods(projectId) {
      /*
        projectId : num,
      }
       */
      try {
        const res = await axios.get(
          config.crmEcommerce.paymentMehtod(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updatePaymentMethodList(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger('Failed to Get Payment Methods');
      }
    },
    async fetchEcommerceShippingMethods(projectId) {
      /*
        projectId : num,
      }
       */
      try {
        const res = await axios.get(
          config.crmEcommerce.shippingMethod(projectId)
        );

        if (res.status === 200 && res.data.success) {
          dispatch.block.updateShippingMethodList(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger('Failed to Get Shipping Methods');
      }
    },
    async fetchViewCartParameters(projectId) {
      /*
        projectId : num,
      }
       */
      try {
        const res = await axios.get(
          config.crmEcommerce.viewCartParameter(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateViewCartParameters(res.data.dataSource);
        }
      } catch (err) {
        toaster.danger('Failed to Get View Cart Parameters');
      }
    },
    async fetchCouponDiscoveryBlockParameters(projectId) {
      /*
        projectId : num,
      }
       */
      try {
        const res = await axios.get(
          config.crmEcommerce.couponDiscoveryBlockParameter(projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateCouponDiscoveryBlockParameters(
            res.data.dataSource
          );
        }
      } catch (err) {
        toaster.danger('Failed to Get Coupon Discovery Parameters');
      }
    },

    async fetchAllTicketTags(projectId) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.get(
          config.inbox.getAllTicketTags('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.block.updateAllTagsData(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        dispatch.block.updateAllTagsData([]);
      }
    },
  }),
};

export default block;
