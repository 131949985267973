import { useGoogleLogin } from '@react-oauth/google';
import { toaster } from 'evergreen-ui';

const useGoogleAuthentication = (
  setEmailUserAuthCode: (data: string) => void
) => {
  const SCOPE =
    'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send';

  const googleLogin = useGoogleLogin({
    scope: SCOPE,
    flow: 'auth-code',
    onSuccess: (codeResponse: any) => {
      if (
        codeResponse.scope.includes(
          'https://www.googleapis.com/auth/gmail.readonly'
        ) &&
        codeResponse.scope.includes(
          'https://www.googleapis.com/auth/gmail.send'
        )
      ) {
        setEmailUserAuthCode(codeResponse.code);
      } else {
        toaster.danger('Please give required permission to read emails!');
      }
    },
    onError: (error: any) => {
      toaster.danger('Please give required permission to read emails!');
    },
  });

  return googleLogin;
};

export default useGoogleAuthentication;
