import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import AlreadyVerifiedMail from './components/AlreadyVerifiedMail';
import ResendVerificationMail from './components/ResendVerificationMail';
import { Redirect } from '@reach/router';

class ResendAccountVerificationMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: '',
      isTimeout: false,
      loading: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  clearAlreadyVerifiedProps = () => {
    this.props.updateAlreadyVerified(false);
  };
  handleResendVerificationLink = () => {
    this.setState({ loading: true });
    this.props
      .resendRegisterVerifyLink({ email: this.props.auth.email })
      .then((success) => {
        if (this.props.auth.already_verified) {
        } else if (success) {
          toaster.success('Verification link successfully sent to your email.');
        } else {
          this.setState({ loading: false, isTimeout: true });
        }
      });
  };

  render() {
    const isAlreadyVerified = this.props.auth.already_verified;
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div
        className='flex flex-col justify-center min-h-screen py-12 bg-white sm:px-6 lg:px-8'
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dgv96gtl3/image/upload/v1626727864/MyAlice%20Heroes.png")`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center bottom',
          backgroundSize: 'contain, cover',
        }}
      >
        {isAlreadyVerified ? (
          <AlreadyVerifiedMail
            clearAlreadyVerifiedProps={this.clearAlreadyVerifiedProps}
          />
        ) : (
          <ResendVerificationMail
            isTimeout={this.state.isTimeout}
            email={this.props.auth.email}
            loading={this.state.loading}
            handleSubmit={this.handleResendVerificationLink}
          />
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  resendRegisterVerifyLink: (payload) =>
    dispatch.auth.resendRegisterVerifyLink(payload),
  updateAlreadyVerified: (payload) =>
    dispatch.auth.updateAlreadyVerified(payload),
});

const ReSendAccountVerificationLink = connect(
  mapState,
  mapDispatch
)(ResendAccountVerificationMail);

export default ReSendAccountVerificationLink;
