import React from 'react';
import { IEcommerceProductData } from 'pages/inbox/inboxInterface';
import defaultProductImage from '../../../../../assets/images/default-product-image.svg';

interface Props {
  key: number;
  currencySymble: string;
  product: IEcommerceProductData;
  orderSummaryPage?: boolean;
}

const SingleProduct: React.FC<Props> = ({
  key,
  product,
  currencySymble,
  orderSummaryPage,
}) => {
  const renderProductImageView = () => {
    return (
      <div className='w-[32px] h-[32px] pt-[3px]'>
        {product?.product_images.length > 0 && (
          <img
            className='h-full rounded-sm'
            src={`${product.product_images[0]}`}
            alt='product_image'
          />
        )}
        {!!product.product_images && product.product_images.length === 0 && (
          <img
            className='h-full rounded-sm'
            src={defaultProductImage}
            alt='product-img'
          />
        )}
      </div>
    );
  };

  const renderProductTitleAndInformationView = () => {
    return (
      <div className='w-[68%]'>
        <p className='text-xs font-medium text-[#0078CF]'>
          {product.product_name}
        </p>
        <p className='text-gray-500 text-[10px] leading-4'>
          Qty: {product.quantity}{' '}
          {product.variant_attributes.map(
            (data: { id: any; name: string; option: string }) => {
              return '| ' + String(data.option);
            }
          )}
        </p>
      </div>
    );
  };

  const renderProductPriceView = () => {
    return (
      <div className='w-[18%] text-gray-900 text-xs font-normal flex justify-end align-center '>
        <span className='overflow-x-auto custom-text-overflow'>
          {currencySymble}
          {product.unit_price * product.quantity}
        </span>
      </div>
    );
  };

  return (
    <div
      key={key}
      className={`flex justify-between m-1 ${
        orderSummaryPage ? 'mt-2 pb-2' : 'mt-2 pb-3'
      }`}
    >
      {renderProductImageView()}

      {renderProductTitleAndInformationView()}

      {renderProductPriceView()}
    </div>
  );
};

export default SingleProduct;
