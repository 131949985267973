'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import { cn } from 'libraryV2/utils';

interface AccordionArrowProps {
  defaultChecked: boolean | undefined; // Define the prop type as boolean
}

const AccordionArrow: React.FC<AccordionArrowProps> = ({ defaultChecked }) => {
  return (
    <>
      {defaultChecked ? (
        <ChevronUpIcon className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />
      ) : (
        <ChevronDownIcon className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />
      )}
    </>
  );
};

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
    isShowUpDownArrow?: boolean; // Custom prop for adding class to the trigger
  }
>(
  (
    { className, children, defaultChecked, isShowUpDownArrow = true, ...props },
    ref
  ) => (
    <AccordionPrimitive.Header className='flex'>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all [&[data-state=open]>svg]:rotate-180',
          className
        )}
        {...props}
      >
        {children}

        {isShowUpDownArrow && (
          <AccordionArrow defaultChecked={defaultChecked} />
        )}

        {/* {defaultChecked ? (
          <ChevronUpIcon className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />
        ) : (
          <ChevronDownIcon className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />
        )} */}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className='overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down hover:bg-white'
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
