import React from 'react';
import {
  UserIcon,
  TimezoneIcon,
  ComputerIcon,
  CubeIcon,
  TicketIcon,
} from '../../../assets/iconComponent/CustomerAttributesIcon';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import NewAlertModal from '../../../../../library/modal/NewAlertModal';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CustomerAttributePayloadInterface,
  CustomerInfoInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import ReactTooltip from 'react-tooltip';

interface Prop {
  attribute: string[];
  selectedTicket: TicketInterface;
  customerInfo: CustomerInfoInterface;
  conversationData: any; // tobe refactored
  updateCustomerAttribute: (
    payload: CustomerAttributePayloadInterface
  ) => Promise<boolean>;
}

const ShowAttribute: React.FC<Prop> = ({
  attribute,
  customerInfo,
  selectedTicket,
  conversationData,
  updateCustomerAttribute,
}) => {
  const { t } = useTranslation();
  const [enableEdit, setEnableEdit] = React.useState(false);
  const [hoveredAttribute, setHoveredAttribute] = React.useState<null | number>(
    null
  );
  const [deletableIndex, setDeletableIndex] = React.useState<number | null>(
    null
  );
  const [shouldEnableDeleteButton, setShouldEnableDeleteButton] =
    React.useState(true);
  const [saveAttribute, setSaveAttribute] = React.useState<number | null>(null);
  const [editedAttribute, setEditedAttribute] = React.useState('');

  const editInputClass = `border-0 text-gray-700 font-medium text-sm leading-5 w-full outline-none h-8 focus:outline-none focus:ring-0 focus:bg-transparent hover: bg-transparent focus:border-b-2 focus:border-solid focus:border-green-400 cursor-edit`;

  const setInitialState = () => {
    setEnableEdit(false);
    setSaveAttribute(null);
    setEditedAttribute('');
    setDeletableIndex(null);
    setHoveredAttribute(null);
    setShouldEnableDeleteButton(true);
  };

  const handleEditAttribute = (
    attributeKey: string,
    attributeValue: string
  ) => {
    let attributes = {};
    attributes = {
      ...attributes,
      [attributeKey]: attributeValue,
    };
    updateCustomerAttribute({
      data: attributes,
      customer_id: selectedTicket.customer_id,
    });
  };
  const handleDeleteAttribute = async (
    attributeKey: string,
    attributeValue: string
  ) => {
    let attributes = {};
    attributes = {
      ...attributes,
      [attributeKey]: attributeValue,
    };
    const response = await updateCustomerAttribute({
      data: attributes,
      customer_id: selectedTicket.customer_id,
    });
    if (response) {
      toaster.success(t('Attribute has been deleted'));
      setDeletableIndex(null);
    } else {
      toaster.danger(t('Something went wrong. Please try again.'));
      setDeletableIndex(null);
    }
  };

  const fixedAttributeList = [
    'Ticket ID',
    'Gender',
    'Timezone',
    'User_info',
    'FB Post ID',
    'IG Post ID',
  ];

  const getIconComponent = (attributeName: string) => {
    switch (attributeName) {
      case 'Ticket ID':
        return <TicketIcon className='inline-block w-5 h-5' />;
      case 'FB Post ID':
      case 'IG Post ID':
        return <CubeIcon className='inline-block w-5 h-5' />;
      case 'Gender':
        return <UserIcon className='inline-block w-5 h-5' />;
      case 'Timezone':
        return <TimezoneIcon className='inline-block w-5 h-5' />;
      case 'User_info':
        return <ComputerIcon className='inline-block w-5 h-5' />;
      default:
        return null;
    }
  };

  const getAttributeValue = (attributeName: string): string => {
    switch (attributeName) {
      case 'Ticket ID':
        return `#${selectedTicket?.id}`;
      case 'FB Post ID':
      case 'IG Post ID':
        return conversationData?.parent_comment_data?.parent_post_id;
      case 'Timezone':
        return `GMT+ ${customerInfo[attributeName.toLowerCase()]}`;
      default:
        return (
          customerInfo[attributeName] ||
          customerInfo[attributeName.toLowerCase()] // default attributes name may has lowercase name. ex: Gender
        );
    }
  };

  const shouldRenderDefaultAttribute = (attributeName: string): boolean => {
    return (
      ['Ticket ID', 'FB Post ID', 'IG Post ID'].includes(attributeName) ||
      (!!customerInfo[attributeName.toLowerCase()] &&
        customerInfo[attributeName.toLowerCase()].length !== 0)
    );
  };

  const getCustomerAttributeCellUI = (attributeName: string, index: number) => {
    switch (attributeName) {
      case 'Ticket ID':
      case 'FB Post ID':
      case 'IG Post ID':
      case 'Gender':
      case 'Timezone':
      case 'User_info':
        return (
          shouldRenderDefaultAttribute(attributeName) && (
            <div
              className={`flex px-4 w-full items-center h-8 `}
              onMouseEnter={() => setHoveredAttribute(index)}
              onMouseLeave={() => setHoveredAttribute(null)}
            >
              <div className='flex items-center w-full gap-4'>
                <div className=''>{getIconComponent(attributeName)}</div>
                <div className='text-sm font-normal leading-5 text-gray-900 truncate ...'>
                  {t(attributeName)} : {getAttributeValue(attributeName)}
                </div>
              </div>
            </div>
          )
        );
      default:
        return (
          !!customerInfo &&
          !!fixedAttributeList &&
          !fixedAttributeList.includes(attributeName) && (
            <div
              key={index}
              className={`flex px-4 w-[100%] items-center h-8`}
              onMouseEnter={() => setHoveredAttribute(index)}
              onMouseLeave={() => setHoveredAttribute(null)}
            >
              <div
                data-for={attributeName}
                data-tip={`${t(attributeName)} : ${
                  saveAttribute === index
                    ? editedAttribute
                    : !!customerInfo[attributeName]
                    ? customerInfo[attributeName]
                    : ''
                }`}
                className='flex gap-3 items-center w-full'
              >
                <div className='mt-1'>
                  <CubeIcon className='inline-block w-5 h-5' />
                </div>
                <div
                  className='flex justify-start w-full text-sm font-normal leading-5 text-gray-900'
                  onClick={() => {
                    setEnableEdit(true);
                  }}
                >
                  <div className='flex w-full '>
                    <div
                      className={`flex items-center text-[#374151] font-normal text-sm leading-5 cursor-default ${
                        attributeName.length >= 15 ? 'w-[30%]' : 'w-auto'
                      }`}
                    >
                      <span className='block truncate ...'>
                        {t(attributeName)}
                      </span>
                      <span className='mx-1'>:</span>
                    </div>
                    <div
                      className='flex items-center justify-between w-full'
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          handleEditAttribute(attributeName, editedAttribute);
                          setSaveAttribute(null);
                          setShouldEnableDeleteButton(true);
                          setInitialState();
                        }
                      }}
                    >
                      <input
                        className={` ${editInputClass} ${
                          hoveredAttribute === index
                            ? 'bg-gray-200'
                            : 'bg-white'
                        }`}
                        disabled={enableEdit ? false : true}
                        value={
                          saveAttribute === index
                            ? editedAttribute
                            : !!customerInfo[attributeName]
                            ? customerInfo[attributeName]
                            : ''
                        }
                        onChange={(e) => {
                          setShouldEnableDeleteButton(false);
                          setEditedAttribute(e.target.value);
                          setSaveAttribute(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ReactTooltip
                id={attributeName}
                place='bottom'
                type='dark'
                effect='solid'
                backgroundColor='#4B5563'
                multiline={true}
                className='w-auto break-words whitespace-pre-wrap sm:text-sm'
              />
            </div>
          )
        );
    }
  };

  return (
    <div>
      {!!attribute &&
        attribute.map((key: string, index: number) => {
          return (
            <div
              key={index}
              className={`flex w-full ${
                hoveredAttribute === index ? 'bg-gray-200' : 'bg-white'
              }`}
            >
              {getCustomerAttributeCellUI(key, index)}
              <div className='px-2'>
                {saveAttribute === index && (
                  <div
                    className='text-[#0078CF] text-xs cursor-pointer mt-1 flex w-[20%]'
                    onClick={() => {
                      handleEditAttribute(key, editedAttribute);
                      setSaveAttribute(null);
                      setShouldEnableDeleteButton(true);
                      setInitialState();
                    }}
                  >
                    {t('Save')}
                  </div>
                )}
                {!fixedAttributeList.includes(key) &&
                  hoveredAttribute === index &&
                  shouldEnableDeleteButton && (
                    <div>
                      <img
                        src={deleteIcon}
                        alt='delete'
                        className='flex w-[16px] h-[14px] cursor-pointer items-center justify-end mt-2'
                        onClick={() => {
                          setDeletableIndex(index);
                        }}
                        onMouseEnter={() => setHoveredAttribute(index)}
                        onMouseLeave={() => setHoveredAttribute(null)}
                      />
                    </div>
                  )}
              </div>
              <NewAlertModal
                isShown={deletableIndex === index}
                intent='danger'
                title={t('Delete Attribute?')}
                confirmButtonTitle={t('Delete')}
                cancelButtonTitle={t('Cancel')}
                onConfirm={() => handleDeleteAttribute(key, '')}
                onToggle={() => {}}
                onCancelComplete={() => setDeletableIndex(null)}
                onClose={() => setDeletableIndex(null)}
                description={`<p>
                    You’re about to delete <span class='font-bold'>${key}</span> attribute. Do you want to continue?
                  </p>`}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ShowAttribute;
