import React from 'react';
import GroupCheckbox from './GroupCheckbox';
import useTranslation from 'components/customHooks/useTranslation';

interface AgentStatusProps {
  currentGroupState: GroupDetailsCardInterface | null;
  handleAgentStatusChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AgentStatus: React.FC<AgentStatusProps> = ({
  currentGroupState,
  handleAgentStatusChange,
}) => {
  const { t } = useTranslation();
  return (
    <div className='px-6 py-4 space-y-2 border rounded-md'>
      <p className='text-sm font-medium text-gray-700'>{t('Agent Status')}</p>
      <p className='font-normal text-gray-500'>
        {t(
          `Admins will be able to select the status of agents which will determine the assignment of tickets.`
        )}
      </p>
      <p className='mt-4 text-base font-medium text-gray-700'>
        {t('Assign tickets to agents who are -')}
      </p>

      <div className='items-center space-x-6 flex-'>
        <GroupCheckbox
          checked={currentGroupState?.should_assign_to_online_agents || false}
          onChange={handleAgentStatusChange}
          label={t('Online')}
          name='should_assign_to_online_agents'
        />
        <GroupCheckbox
          checked={currentGroupState?.should_assign_to_offline_agents || false}
          onChange={handleAgentStatusChange}
          label={t('Offline')}
          name='should_assign_to_offline_agents'
        />
        <GroupCheckbox
          checked={currentGroupState?.should_assign_to_away_agents || false}
          onChange={handleAgentStatusChange}
          label={t('Away')}
          name='should_assign_to_away_agents'
        />
      </div>
      {(currentGroupState?.should_assign_to_offline_agents ||
        currentGroupState?.should_assign_to_away_agents) && (
        <div className='p-4 !mt-4 text-yellow-700 bg-yellow-100 rounded-md '>
          {t(
            `Since \u2018Away\u2019 or \u2018Offline\u2019 statuses are selected, please be aware that tickets will be assigned to agents even if they are unavailable.`
          )}
        </div>
      )}
    </div>
  );
};

export default AgentStatus;
