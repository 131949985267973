import React from 'react';
import ProductItem from './ProductItem';
import { Button } from '../../../../../../../library';
import {
  ProductDataProps
} from '../../eCommerceOrderCreate/interfaces';

interface Prop {
  productData: ProductDataProps[];
  //to do define interface for product data
  handleProductSelect: (product: any) => void;
  shouldScrollNext: boolean;
  handleLoadProductScroll: () => void;
  productListLoading: boolean;
  isProductSelected: boolean;
  noProductsFound: boolean;
  currencySymbol: string;
}

const ProductLists: React.FC<Prop> = ({
  productData,
  handleProductSelect,
  shouldScrollNext,
  handleLoadProductScroll,
  productListLoading,
  isProductSelected,
  noProductsFound,
  currencySymbol,
}) => {
  return (
    <div className={`px-6 pb-4 mt-4 ${isProductSelected ? 'border-b' : ''}`}>
      <div className='overflow-hidden bg-white shadow sm:rounded-md'>
        <ul className='divide-y divide-gray-200'>
          {productData.map((product: ProductDataProps, index: number) => (
            <ProductItem
              product={product}
              key={index}
              handleProductSelect={handleProductSelect}
              currencySymbol={currencySymbol}
            />
          ))}
        </ul>
      </div>
      {shouldScrollNext && (
        <div className='flex justify-center w-full mt-2'>
          <Button
            intent='default'
            size={'sm'}
            isLoading={productListLoading}
            onClick={handleLoadProductScroll}
          >
            {productListLoading ? 'Loading...' : 'Load More'}
          </Button>
        </div>
      )}
      {noProductsFound && (
        <div className='flex justify-center w-full mt-2'>
          <Button intent='default' size={'sm'} isLoading={!productListLoading}>
            No more products could be found
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductLists;
