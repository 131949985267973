import React from 'react';
import Select from 'react-select';
import { Button, IconButton, TextInput, Tooltip } from 'evergreen-ui';

interface Prop {
  data: any;
  updateChildRules: (childRuleId: number, key: string, value: any) => void;
  intentList: string[];
  parentRule: any;
  editId: number;
  rulesCreateOptions: () => any;
  initiateChildBlockRule: () => void;
  childRuleCreateAvailable: () => boolean;
  deleteCurrentChildRule: () => void;
  hasWriteAccess: boolean;
}

const ChildRule: React.FC<Prop> = ({
  data,
  updateChildRules,
  intentList,
  parentRule,
  editId,
  rulesCreateOptions,
  initiateChildBlockRule,
  childRuleCreateAvailable,
  deleteCurrentChildRule,
  hasWriteAccess,
}) => {
  const options = rulesCreateOptions();
  return (
    <div className='rules-card__elem-container mt-10px'>
      <div className='rules-card__elem-item flex-6'>
        <Select
          className='rules-card__elem-item-child rules-select'
          isSearchable={false}
          isDisabled={editId !== parentRule.id || !hasWriteAccess}
          value={
            !!data.trigger
              ? { value: data.trigger, label: data.trigger.toUpperCase() }
              : null
          }
          classNamePrefix='rules-select'
          onChange={(option: any) => {
            updateChildRules(data.id, 'trigger', option.value);
            updateChildRules(data.id, 'payload', '');
          }}
          placeholder={'Select Action'}
          options={options.map((child: string) => ({
            value: child,
            label: child.toUpperCase(),
          }))}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#eebb4d4d',
              primary: '#eebb4d',
              primary50: '#eebb4d',
            },
          })}
        />
      </div>
      <div className='rules-card__elem-item flex-12'>
        {data.trigger === 'intent' ? (
          <Select
            className='mr-0 rules-card__elem-item-child rules-select'
            isSearchable={false}
            isDisabled={editId !== parentRule.id || !hasWriteAccess}
            value={
              !!data.payload
                ? { value: data.payload, label: data.payload.toUpperCase() }
                : null
            }
            classNamePrefix='rules-select'
            onChange={(option: any) => {
              updateChildRules(data.id, 'payload', option.value);
            }}
            placeholder={'Select Source'}
            options={intentList.map((intent) => ({
              value: intent,
              label: intent.toUpperCase(),
            }))}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#eebb4d4d',
                primary: '#eebb4d',
                primary50: '#eebb4d',
              },
            })}
          />
        ) : (
          <TextInput
            className='rules-card__elem-child-1 rules-field w-100p'
            height={38}
            disabled={editId !== parentRule.id || !hasWriteAccess}
            value={data.payload}
            placeholder='Provide Value'
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              updateChildRules(data.id, 'payload', evt.target.value);
            }}
          />
        )}
      </div>
      {childRuleCreateAvailable() && (
        <div className='rules-card__elem-item flex-2'>
          <div className='flex'>
            <Tooltip content='Create a Child Rule'>
              <IconButton
                icon='plus'
                disabled={!hasWriteAccess}
                intent='success'
                marginLeft={10}
                height={38}
                appearance='minimal'
                onClick={() => {
                  initiateChildBlockRule();
                }}
              />
            </Tooltip>
          </div>
        </div>
      )}
      <div className='rules-card__elem-item flex-2'>
        {data.id ===
          parentRule.child_rules[parentRule.child_rules.length - 1].id &&
          editId === parentRule.id && (
            <div className='flex'>
              <Tooltip content='Remove Child Rule'>
                <IconButton
                  icon='cross'
                  intent='danger'
                  disabled={!hasWriteAccess}
                  style={{ background: '#f6f7f8', width: '100%' }}
                  marginLeft={10}
                  height={38}
                  appearance='minimal'
                  onClick={() => {
                    deleteCurrentChildRule();
                  }}
                />
              </Tooltip>
            </div>
          )}
      </div>
      {childRuleCreateAvailable() && (
        <div className='rules-card__elem-item flex-2'>
          <div className='flex'>
            <Tooltip content='Create a Child Rule'>
              <Button
                icon='plus'
                intent='success'
                height={38}
                style={{ width: '100%' }}
                appearance='minimal'
                disabled
              >
                AND
              </Button>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
export default ChildRule;
