import React from 'react';
import { Spinner } from 'evergreen-ui';
import OrderCardRightbar from './OrderCardRightbar';
import noDataFound from '../../../assets/images/no-data-found.svg';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from '../../../../../../components';

interface Prop {
  eComOrderList: any[];
  loader: boolean;
  fetchCRMCustomerOrder: (search: string) => void;
  currencySymbol: string;
}

const OrderHistoryRightbarContainer: React.FC<Prop> = ({
  eComOrderList,
  loader,
  fetchCRMCustomerOrder,
  currencySymbol,
}) => {
  const [search, setSearch] = React.useState<string>('');

  const searchDebounce = useDebounce(search, 1000);

  const getVisibleOrderId = (orderData: any) =>
    orderData?.ecommerce_type === 'shopify'
      ? orderData?.shopify_order_name || orderData?.ecommerce_order_id || ''
      : orderData?.ecommerce_order_id;

  React.useEffect(() => {
    fetchCRMCustomerOrder(search);
    // eslint-disable-next-line
  }, [searchDebounce]);

  return (
    <div className='order-right-bar__container rounded-b-md'>
      <div>
        <div className='relative rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </div>
          <input
            type='number'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
            placeholder='Search Order Id...'
          />
        </div>
      </div>

      {!loader && (
        <div className='order-right-bar__order-lists'>
          {eComOrderList.length > 0 &&
            eComOrderList.map((order: any, index: number) => (
              <OrderCardRightbar
                eComOrderId={getVisibleOrderId(order)}
                eComType={order?.ecommerce_type}
                email={order?.email || ''}
                orderLink={order?.permalink}
                coupon={order?.coupon_info}
                note={order?.note}
                status={order?.status || ''}
                payment_status={order?.payment_status || ''}
                refund={order?.total_refund.toString()}
                orderDate={order?.created_at || 'Not Available'}
                totalCost={order?.total_cost || 'N/A'}
                paymentMethod={order?.payment_method || 'Not Available'}
                billingAddress={order?.billing_address || 'Not Available'}
                shippingAddress={order?.shipping_address || 'Not Available'}
                totalTax={order?.total_tax.toString() || 'N/A'}
                shippingCost={order?.total_shipping_cost.toString() || 'N/A'}
                discount={order?.total_discount.toString() || 'N/A'}
                key={index}
                shippingMethod={order?.shipping_method || 'Not Available'}
                products={
                  order?.products.filter(
                    (product: { quantity: number }) => product.quantity > 0
                  ) || []
                }
                orderId={order.id}
                currencySymbol={currencySymbol}
              />
            ))}
          {eComOrderList.length === 0 && (
            <div className='flex justify-center align-center h-100p flex-column mb-20px'>
              <img src={noDataFound} alt='no data' width='100px' />
              <p className='description'>No Order found!</p>
            </div>
          )}
        </div>
      )}
      {loader && (
        <div className='order-right-bar__order-lists'>
          <div className='flex justify-center w-100p h-100p mh-200px align-center'>
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistoryRightbarContainer;
