import React from 'react';
import Select from 'react-select';
import {
  IconButton,
  TextInput,
  Button,
  Position,
  Menu,
  Popover,
  toaster,
  Tooltip,
} from 'evergreen-ui';
import { channelInfo, copyReferralValue } from '../../../../utilities/utils';
import Alert from '../../../components/elements/redesign/Alert';
import Modal from '../../../components/elements/redesign/Modal';
import ChildRule from './ChildRule';

interface Props {
  rule: any;
  hasWriteAccess: boolean;
  deleteBlockRules: (rulesId: any) => void;
  rulesPermissionData: any;
  updateBlockRulesChange: (rulesId: number, key: string, value: any) => void;
  updateBlockRulesEditId: (ruleId: number) => void;
  fetchBlockRules: () => void;
  updateBlockRules: () => void;
  editId: number;
  intentList: string[];
  selectedPlatform: any;
  generateQrCode: (id: any) => void;
  initiateChildBlockRule: (rulesId: any) => void;
}

const RuleCard: React.FC<Props> = ({
  rule,
  hasWriteAccess,
  deleteBlockRules,
  rulesPermissionData,
  updateBlockRulesChange,
  updateBlockRulesEditId,
  fetchBlockRules,
  updateBlockRules,
  editId,
  intentList,
  selectedPlatform,
  generateQrCode,
  initiateChildBlockRule,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] =
    React.useState<boolean>(false);
  const [currentEditChildId, setCurrentChildId] = React.useState<any>(null);

  const ruleCreateAvailable = () => {
    let flag: boolean = rule.id === editId && rule.child_rules.length === 0;
    //this is valid for only parent rule;
    if (flag && !!rule.source) {
      const responseFlag =
        !!rule.trigger &&
        rulesPermissionData.patterns[rule.trigger].length > 0 &&
        !!rule.payload;
      const currentOption = new Set(rulesPermissionData.patterns[rule.trigger]);
      const sourceAvailable = new Set(
        rulesPermissionData.trigger[rule.source].map((data: any) => data.value)
      );
      return (
        responseFlag && intersectData(currentOption, sourceAvailable).length > 0
      );
    }
    return false;
  };

  const updateChildRules = (childRuleId: number, key: string, value: any) => {
    let localChildRule = [...rule.child_rules];
    localChildRule = localChildRule.map((child) => {
      if (child.id === childRuleId) {
        child[key] = value;
      }
      return child;
    });

    updateBlockRulesChange(rule.id, 'child_rules', localChildRule);
  };

  const deleteCurrentChildRule = (childRuleId: number) => {
    let localChildRule = [...rule.child_rules];
    localChildRule = localChildRule.filter(
      (child: any) => child.id !== childRuleId
    );
    updateBlockRulesChange(rule.id, 'child_rules', localChildRule);
  };

  const rulesCreateOptions = (childRule: any, index: number) => {
    let newData: any = [];
    let flag: boolean = rule.id === editId;
    //this is valid for only parent rule;
    if (flag && !!rule.source) {
      if (index === 0) {
        const currentOption = new Set(
          rulesPermissionData.patterns[rule.trigger]
        );
        const sourceAvailable = new Set(
          rulesPermissionData.trigger[rule.source].map(
            (data: any) => data.value
          )
        );
        newData = intersectData(currentOption, sourceAvailable);
      } else {
        const prevRule = rule.child_rules[index - 1];
        if (!!prevRule.trigger) {
          // check
          const parentArray = new Set(
            rulesPermissionData.patterns[rule.trigger]
          );
          const childArray = new Set(
            rulesPermissionData.patterns[prevRule.trigger]
          );
          // intersection
          newData = intersectData(parentArray, childArray);
        }
      }
    }

    return newData;
  };
  const childRuleCreateAvailable = (currentChild: any) => {
    let newData: any = [];
    if (!!currentChild.trigger) {
      const parentArray = new Set(rulesPermissionData.patterns[rule.trigger]);
      const childArray = new Set(
        rulesPermissionData.patterns[currentChild.trigger]
      );
      // intersection
      newData = intersectData(parentArray, childArray);
    }
    return newData.length > 0;
  };

  const intersectData = (prev: any, next: any) => {
    let intersects = new Set([...prev].filter((x) => next.has(x)));
    return Array.from(intersects);
  };

  return (
    <div
      className={
        rule.id === editId
          ? 'rules-card__container primary-border'
          : ' rules-card__container'
      }
    >
      {/*selector of parent comment*/}
      <div className='flex space-between mb-10px'>
        <Alert
          intent={rule.isSaved !== false ? 'success' : 'danger'}
          text={
            rule.isSaved !== false
              ? 'Successfully saved'
              : 'Changes were not saved'
          }
        />
        {rule.id !== editId && (
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={({ close: popoverClose }) => (
              <Menu>
                <Menu.Group>
                  <Menu.Item
                    onSelect={() => {
                      popoverClose();
                      updateBlockRulesEditId(rule.id);
                    }}
                    icon='edit'
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    onSelect={() => {
                      popoverClose();
                      // deleteBlockRules(rule.id);
                      setShowDeleteDialog(true);
                    }}
                    icon='trash'
                    intent='danger'
                  >
                    Delete
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            )}
          >
            <IconButton
              appearance='minimal'
              className='menu-icon'
              icon='more'
              height={28}
            />
          </Popover>
        )}
      </div>
      <div className='rules-card__elem-container'>
        <div className='rules-card__elem-item flex-6'>
          <Select
            className='rules-card__elem-item-child rules-select'
            isSearchable={true}
            isDisabled={
              editId !== rule.id
                ? true
                : !(rulesPermissionData.sources.length > 1)
            }
            value={
              !!rule.source
                ? { value: rule.source, label: channelInfo(rule.source).title }
                : null
            }
            classNamePrefix='rules-select'
            onChange={(option: any) => {
              updateBlockRulesChange(rule.id, 'source', option.value);
              //reset rest
              updateBlockRulesChange(rule.id, 'trigger', '');
              updateBlockRulesChange(rule.id, 'payload', '');
              updateBlockRulesChange(rule.id, 'child_rules', []);
            }}
            placeholder={'Select Source'}
            options={rulesPermissionData.sources}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#eebb4d4d',
                primary: '#eebb4d',
                primary50: '#eebb4d',
              },
            })}
          />
        </div>
        <div className='rules-card__elem-item flex-6'>
          <Select
            className='rules-card__elem-item-child rules-select'
            isSearchable={true}
            isDisabled={editId !== rule.id || !hasWriteAccess}
            classNamePrefix='rules-select'
            value={
              !!rule.trigger
                ? { value: rule.trigger, label: rule.trigger.toUpperCase() }
                : null
            }
            onChange={(option: any) => {
              updateBlockRulesChange(rule.id, 'trigger', option.value);
              updateBlockRulesChange(rule.id, 'child_rules', []);
              //reset
              updateBlockRulesChange(rule.id, 'payload', '');
            }}
            placeholder={'Select Action'}
            options={rulesPermissionData.trigger[rule.source]}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#eebb4d4d',
                primary: '#eebb4d',
                primary50: '#eebb4d',
              },
            })}
          />
        </div>
        <div className='rules-card__elem-item flex-6'>
          {rule.trigger === 'intent' ? (
            <Select
              className='mr-0 rules-card__elem-item-child rules-select'
              isSearchable={true}
              isDisabled={editId !== rule.id || !hasWriteAccess}
              classNamePrefix='rules-select'
              value={
                !!rule.payload
                  ? { value: rule.payload, label: rule.payload.toUpperCase() }
                  : null
              }
              onChange={(option: any) =>
                updateBlockRulesChange(rule.id, 'payload', option.value)
              }
              placeholder={'Select Intent'}
              options={intentList.map((intent: string) => ({
                value: intent,
                label: intent.toUpperCase(),
              }))}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#eebb4d4d',
                  primary: '#eebb4d',
                  primary50: '#eebb4d',
                },
              })}
            />
          ) : (
            <TextInput
              className='rules-card__elem-child-1 rules-field w-100p'
              height={38}
              disabled={editId !== rule.id || !hasWriteAccess}
              value={rule.payload}
              placeholder='Provide Value'
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                updateBlockRulesChange(rule.id, 'payload', evt.target.value)
              }
            />
          )}
        </div>
        {ruleCreateAvailable() && (
          <div className='rules-card__elem-item flex-2'>
            <div className='flex'>
              <Tooltip content='Create a Child Rule'>
                <IconButton
                  icon='plus'
                  intent='success'
                  disabled={!hasWriteAccess}
                  marginLeft={10}
                  height={38}
                  appearance='minimal'
                  onClick={() => {
                    initiateChildBlockRule(rule.id);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )}
        {rule.child_rules.length > 0 && (
          <div className='rules-card__elem-item flex-2'>
            <div className='flex'>
              <Tooltip content='Create a Child Rule'>
                <Button
                  icon='plus'
                  intent='success'
                  height={38}
                  style={{ width: '100%' }}
                  marginLeft={ruleCreateAvailable() ? 0 : 10}
                  appearance='minimal'
                  disabled
                >
                  AND
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {/*qr and referral link*/}
      {rule.id !== editId && rule.trigger === 'referral' &&
        selectedPlatform?.platform_type !== 'whatsapp_bsp' && (
          <div className='flex mt-10px'>
            <Button
              className='link-color'
              iconBefore='cloud-download'
              height={24}
              appearance={'minimal'}
              onClick={() => {
                generateQrCode(rule.id);
              }}
            >
              Generate QR Code
            </Button>
            <Button
              className='link-color'
              iconBefore='link'
              height={24}
              appearance={'minimal'}
              onClick={() => {
                copyReferralValue(selectedPlatform, rule.payload);
              }}
            >
              Copy Referral Link
            </Button>
          </div>
        )
      }
      {/*child components*/}
      {rule.child_rules.map((child: any, index: number) => (
        <ChildRule
          key={index}
          data={child}
          parentRule={rule}
          editId={editId}
          updateChildRules={updateChildRules}
          rulesCreateOptions={() => rulesCreateOptions(child, index)}
          intentList={intentList}
          hasWriteAccess={hasWriteAccess}
          initiateChildBlockRule={() => initiateChildBlockRule(rule.id)}
          childRuleCreateAvailable={() => childRuleCreateAvailable(child)}
          deleteCurrentChildRule={() => {
            setCurrentChildId(child.id);
            setShowDeleteDialog(true);
          }}
        />
      ))}

      {/*save segments*/}
      {hasWriteAccess && (rule.isSaved === false || editId === rule.id) && (
        <div className='flex mt-20px'>
          <Button
            className='alice-btn__secondary'
            marginRight={10}
            onClick={() => {
              if (!!rule.trigger) {
                if (!!rule.payload) {
                  updateBlockRules();
                } else {
                  toaster.warning('Value Cannot be Empty');
                }
              } else {
                toaster.warning('Type Cannot be Empty');
              }
            }}
          >
            {rule.id === -1 ? 'Create' : 'Save'}
          </Button>
          <Button
            className='alice-btn__off-white'
            onClick={() => {
              if (rule.id === -1) {
                deleteBlockRules(rule.id);
              } else {
                updateBlockRulesEditId(-1);
                fetchBlockRules();
              }
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      <Modal
        isShown={showDeleteDialog}
        setShown={setShowDeleteDialog}
        intent={'danger'}
        onDeleteCallback={() => {
          if (!!currentEditChildId) {
            deleteCurrentChildRule(currentEditChildId);
            setCurrentChildId(null);
          } else {
            deleteBlockRules(rule.id);
          }
        }}
        title='Are you sure you want to delete this Rules Block?'
        description='Once a Rules Block has been deleted, it cannot be recovered. You will have to start a new Rules.'
      />
    </div>
  );
};

export default RuleCard;
