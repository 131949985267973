import axios from '../../../../utilities/httpClient';
import AXIOS from 'axios';
import config from '../../../../utilities/config';
import querystring from 'querystring';
import { openInSelfTab } from '../../../../utilities/utils';
import { toaster } from 'evergreen-ui';
import { navigate } from '@reach/router';

const initialState = {
  marketPlaceData: {
    project_id: -1,
    store_url: '',
    is_verified: false,
    has_plugin_connected: false,
    is_deleted: false,
    id: -1,
  },
  customerList: [],
  webchatChannelList: [],
};

export const marketplace = {
  state: {
    ...initialState,
  },
  reducers: {
    updateMarketPlaceData(state, payload) {
      return { ...state, marketPlaceData: payload };
    },
    updateCustomerList(state, payload) {
      return { ...state, customerList: [...payload] };
    },
    updateWebchatChannelList(state, payload) {
      return { ...state, webchatChannelList: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchMarketPlaceData(teamId) {
      try {
        const res = await axios.get(config.marketPlace.marketPlaceData(teamId));
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
          } else {
            const localData = {
              project_id: teamId,
              store_url: '',
              is_verified: false,
              has_plugin_connected: false,
              is_deleted: false,
              id: -1,
            };
            dispatch.marketplace.updateMarketPlaceData(localData);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async initializeMarketPlaceData({
      teamId,
      eComStoreName,
      storeURL,
      returnURL,
    }) {
      try {
        if (eComStoreName === 'woocommerce') {
          const res = await axios.post(
            config.marketPlace.initializeWooCommerce(teamId),
            { store_url: storeURL }
          );
          if (res.data.success) {
            dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
            const store_url = storeURL;
            const endpoint = '/wc-auth/v1/authorize';
            const params = {
              app_name: 'Alice',
              scope: 'read_write',
              user_id: teamId,
              return_url: returnURL,
              callback_url: config.marketPlace.woocommerceConnectedCallback(),
            };
            const query_string = querystring
              .stringify(params)
              .replace(/%20/g, '+');

            openInSelfTab(store_url + endpoint + '?' + query_string);
          }
        } else if (eComStoreName === 'shopify') {
          const shopify_state = 'alice-shopify-integration-' + teamId;
          const res = await axios.post(
            config.marketPlace.initializeShopify(teamId),
            { store_url: storeURL, shopify_state: shopify_state }
          );
          if (res.data.success) {
            dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
            const store_url = storeURL;
            const endpoint = 'admin/oauth/authorize';
            const params = {
              app_name: 'Alice',
              client_id: process.env.REACT_APP_SHOPIFY_CLIENT_ID,
              scope:
                'read_discounts,read_price_rules,read_products,read_customers,write_orders,write_draft_orders,write_order_edits,write_themes',
              state: shopify_state,
              redirect_uri: `${window.location.origin}/public/shopify-callback-url`,
            };
            const query_string = querystring
              .stringify(params)
              .replace(/%20/g, '+');
            navigate(store_url + endpoint + '?' + query_string);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async disconnectEcommerceIntegration(teamId) {
      try {
        const res = await axios.post(
          config.marketPlace.disconnectEcommerce(teamId),
          {}
        );
        if (res.data.success) {
          dispatch.marketplace.fetchMarketPlaceData(teamId);
          toaster.success('Success', {
            description: 'Disconnected Successfully',
          });
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Disconnect from Team',
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Failed', {
          description: 'Failed to Disconnect from Team',
        });
      }
    },
    async disconnectEcommercePluginIntegration(teamId) {
      try {
        const res = await axios.post(
          config.marketPlace.disconnectPlugin(teamId),
          {}
        );
        if (res.data.success) {
          dispatch.marketplace.fetchMarketPlaceData(teamId);
          toaster.success('Success', {
            description: 'Plugin Disconnected Successfully',
          });
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Disconnect Plugin from Marketplace',
          });
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Failed', {
          description: 'Failed to Disconnect Plugin from Marketplace',
        });
      }
    },
    async getEcommerceCustomerList(payload) {
      //payload = teamId,search
      try {
        const res = await axios.get(
          config.marketPlace.customerList(payload.teamId),
          { params: { search: payload.search } }
        );
        if (res.data.success) {
          dispatch.marketplace.updateCustomerList(res.data.dataSource);
        } else {
          dispatch.marketplace.updateCustomerList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.marketplace.updateCustomerList([]);
      }
    },
    async connectPlatformToEcommercePluginIntegration({ projectId, id }) {
      try {
        const res = await axios.post(
          config.marketPlace.webchatEcommerceConnect(projectId),
          {
            platform_id: id,
          }
        );
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
          } else {
            const localData = {
              project_id: projectId,
              store_url: '',
              is_verified: false,
              has_plugin_connected: false,
              is_deleted: false,
              id: -1,
            };
            dispatch.marketplace.updateMarketPlaceData(localData);
          }
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async fetchWebchatChannel(projectId) {
      try {
        const res = await axios.get(
          config.marketPlace.webchatChannels(projectId)
        );
        if (res.data.success) {
          dispatch.marketplace.updateWebchatChannelList(res.data.dataSource);
        }
      } catch (err) {
        dispatch.marketplace.updateWebchatChannelList([]);
        console.log(err.response);
      }
    },
    async linkCustomerWithCRM(payload) {
      // payload = {teamId, customerId,ecommerceId}
      try {
        const res = await axios.post(
          config.marketPlace.linkCustomer(payload.teamId),
          {
            alice_customer_id: payload.customerId,
            ecommerce_account_id: payload.ecommerceId,
          }
        );
        if (res.data.success) {
          dispatch.crm.updateCustomerMetaEcomReference(
            res.data.dataSource?.customer_id
          );
          dispatch.crm.fetchCRMCustomerOrder(
            payload.teamId,
            payload.customerId,
            ''
          );
          return true;
        } else {
          dispatch.crm.updateCustomerMetaEcomReference(null);
          return false;
        }
      } catch (err) {
        console.log(err);
        dispatch.crm.updateCustomerMetaEcomReference(null);
        return false;
      }
    },
    async unlinkCustomerWithCRM(payload) {
      // payload = {teamId, customerId}
      try {
        const res = await axios.post(
          config.marketPlace.unlinkCustomer(payload.teamId),
          {
            alice_customer_id: payload.customerId,
          }
        );
        if (res.data.success) {
          dispatch.crm.updateCustomerMetaEcomReference(null);
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async shopifyConnectCallback(params) {
      try {
        const res = await axios.get(
          config.marketPlace.shopifyConnectCallback(params)
        );
        if (res.data.success) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async validateShopifyToken(token) {
      try {
        const res = await AXIOS.post(
          config.marketPlace.validateShopifyTokenURL(token),
          {
            shopify_installation_request_id: token,
          }
        );
        if (res.data.success) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async fetchShopifyProjects() {
      try {
        const res = await axios.get(config.marketPlace.shopifyProjectList());
        if (res.data.success) {
          return res.data?.dataSource || [];
        } else {
          return [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    async finalizeShopifyMarketPlace(project) {
      try {
        const token = localStorage.getItem('shopify_installation_request_id');
        if (!!token) {
          const res = await axios.post(
            config.marketPlace.finalizeShopifyURL(project.id),
            {
              shopify_installation_request_id: token,
            }
          );
          if (res.data.success) {
            dispatch.dashboard.setSelectedProject(project);
            setTimeout(() => {
              navigate('/settings/marketplace');
            }, 500);
          } else {
            toaster.danger('Failed', {
              description:
                'Failed to finalize shopify marketplace. please try again.',
            });
          }
        }
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to finalize shopify marketplace. please try again.',
        });
      }
    },
    async connectWebchatShopifyPlugin(payload) {
      /**
       * payload = projectId:number, platformId:number
       */
      try {
        const res = await axios.post(
          config.marketPlace.shopifyWidgetPlugin(payload.projectId),
          { platform_id: payload.platformId }
        );

        if (res.data.success) {
          dispatch.marketplace.updateMarketPlaceData(res.data.dataSource);
          setTimeout(() => {
            return true;
          }, 0);
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Connect Webchat With Shopify',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: 'Failed to Connect Webchat With Shopify',
        });
        return false;
      }
    },
  }),
};
