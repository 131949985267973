import React from 'react';
import { SideSheet, Pane, Heading, Paragraph } from 'evergreen-ui';
import IconCard from './IconCard';
import usePartner from 'components/customHooks/usePartner';

const MoreBlockDialog = ({ visible, setVisible, data }) => {
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'MoreBlockDialog/node/block-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';

  return (
    <SideSheet
      width={500}
      isShown={visible}
      hasHeader={false}
      onCloseComplete={() => setVisible(false)}
      hasFooter={false}
    >
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor='white'>
        <Pane padding={16}>
          <Heading size={600}>
            <span id='block-partner'>{partnerName}</span> Block Lists
          </Heading>
          <Paragraph size={400}>
            List of all blocks available to use in this platform.
          </Paragraph>
        </Pane>
      </Pane>
      <Pane
        flex='1'
        overflowY='auto'
        background='tint1'
        height={'calc(100vh - 77px)'}
        paddingBottom={30}
      >
        <div className='more-dialog-container'>
          {!!data &&
            data.length > 0 &&
            data.map((blockContainers, i) => (
              <div key={i} className='more-dialog-container__section'>
                <p className={'more-dialog-container__section__title'}>
                  {blockContainers.title}
                </p>
                <div
                  className={'more-dialog-container__section__card-container'}
                >
                  {!!blockContainers.data &&
                    blockContainers.data.length > 0 &&
                    blockContainers.data.map((blockElements, j) => (
                      <IconCard {...blockElements} key={j} />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </Pane>
    </SideSheet>
  );
};

export default MoreBlockDialog;
