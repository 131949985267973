import React from 'react';
import Link from '../../../../library/link';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Footer: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideOnboardingContactNode = isPartnerRestricted(
    'Footer/node/onboarding-contact'
  );
  if (shouldHideOnboardingContactNode) {
    return null;
  }
  return (
    <footer id='onboarding-contact' className='fixed bottom-[48px]'>
      <p className='max-w-sm mt-auto text-center text-gray-600'>
        {t(`Want us to set you up? `)}
        <Link
          className='rtl:mr-2'
          openInNewTab={true}
          url={'https://myalice.ai/contact/'}
        >
          {t('Contact Support')}
        </Link>
      </p>
    </footer>
  );
};
export default Footer;
