import React from 'react';
import CsvFileUploader from './CsvFileUploader';
import Spinner from './Spinner';
import MapBroadcastData from './MapBroadcastData';
import { connect } from 'react-redux';
import { handleCSVPDFUpload } from 'utilities/utils';
import { selectedProjectProps } from 'pages/integration/interface';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import {
  BroadcastAttribute,
  BroadcastCreationProperty,
  CSVProperty,
  MappedColumnData,
  UpdateBoradcastProperty,
} from '../interface';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isFileUploadLoaded: boolean;
  uploadingFileLoading: boolean;
  file: string;
  attributes: BroadcastAttribute | null;
  setAttributes: (data: BroadcastAttribute) => void;
  setMappedData: (payload: MappedColumnData[]) => void;
  mappedData: MappedColumnData[];
  setUploadingFileLoading: (payload: boolean) => void;
  setFile: (payload: string) => void;
  selectedProject: selectedProjectProps;
  uploadCSVForMapping: (
    payload:
      | {
          projectId: number;
          data: UpdateBoradcastProperty;
        }
      | { projectId: number; data: CompaignPostData }
  ) => { success: boolean; data: CSVProperty | string };
  setIsFileUploadLoaded: (payload: boolean) => void;
  broadcastCreationData: BroadcastCreationProperty;
  isEdit: boolean;
  step: number;
}

interface CompaignPostData {
  title: string;
  template_id: number | string;
  channel_id: number | string;
}

const Audiencetab: React.FC<Props> = ({
  isFileUploadLoaded,
  uploadingFileLoading,
  file,
  attributes,
  setAttributes,
  setMappedData,
  mappedData,
  setUploadingFileLoading,
  setFile,
  selectedProject,
  uploadCSVForMapping,
  setIsFileUploadLoaded,
  broadcastCreationData,
  isEdit,
  step,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideLearnMore = isPartnerRestricted(
    'Audiencetab/node/audience-learn-more'
  );
  const [loadScreen, setLoadScreen] = React.useState(false);
  const [fileUploadError, setFileUploadError] = React.useState<
    CSVProperty | string
  >('');
  const [isFileReupload, setIsFileReupload] = React.useState(false);
  const [csvFileName, setCsvFileName] = React.useState('');

  const handleFileDrop = async (file: File) => {
    setIsFileUploadLoaded(false);
    setUploadingFileLoading(true);
    setCsvFileName(file?.name);
    const callback = (file: string) => setFile(file);
    let res = await handleCSVPDFUpload(file, callback);

    // @ts-ignore
    if (res?.success) {
      let payload = {
        projectId: selectedProject.id,
        // @ts-ignore
        data: { file_url: res.data, broadcast_id: broadcastCreationData.id },
        type: 'uploadCsvForMapping',
      };
      let response = await uploadCSVForMapping(payload);

      if (response.success) {
        setIsFileUploadLoaded(true);
        setUploadingFileLoading(false);
      } else {
        setIsFileUploadLoaded(false);
        setUploadingFileLoading(false);
        setFileUploadError(response.data);
      }
    } else {
      setUploadingFileLoading(false);
      setIsFileUploadLoaded(false);
      setFileUploadError('File upload failed. Make sure its .csv file');
    }
  };

  const handleFileClear = () => {
    setFile('');
  };

  const fileForMapping = async () => {
    let payload = {
      projectId: selectedProject.id,
      // @ts-ignore
      data: {
        file_url: broadcastCreationData.audience_csv_file_url,
        broadcast_id: broadcastCreationData.id,
      },
      type: 'uploadCsvForMapping',
    };

    let response = await uploadCSVForMapping(payload);

    if (response.success) {
      setIsFileUploadLoaded(true);
      setUploadingFileLoading(false);
      setLoadScreen(true);
    } else {
      setIsFileUploadLoaded(false);
      setLoadScreen(true);
      setUploadingFileLoading(false);
      setFileUploadError(response.data);
    }
  };

  const checkCondition = () => {
    if (
      broadcastCreationData.audience_csv_file_url !== '' &&
      broadcastCreationData.mapped_column_data.length === 0
    ) {
      fileForMapping();
    } else if (
      broadcastCreationData.audience_csv_file_url !== '' &&
      broadcastCreationData.mapped_column_data.length > 0
    ) {
      setIsFileUploadLoaded(true);
      setLoadScreen(true);
    } else if (
      broadcastCreationData.audience_csv_file_url === '' &&
      broadcastCreationData.mapped_column_data.length === 0
    ) {
      setIsFileUploadLoaded(false);
      setLoadScreen(true);
    }
  };

  React.useEffect(() => {
    checkCondition();
    // eslint-disable-next-line
  }, [step]);

  return (
    <>
      {loadScreen && (
        <>
          {!isFileUploadLoaded && (
            <div className='flex h-full w-full justify-center items-center mb-[40px]'>
              <div
                className={`h-[350px] relative ${
                  uploadingFileLoading ? '' : 'mt-[100px]'
                }`}
              >
                {!uploadingFileLoading ? (
                  <>
                    <CsvFileUploader
                      csvFileName={csvFileName}
                      file={isFileUploadLoaded ? file : ''}
                      handleDrop={handleFileDrop}
                      handleClear={handleFileClear}
                      setCsvFileName={setCsvFileName}
                      fileUploadError={fileUploadError}
                    />
                    <div className='text-center mt-4'>
                      <a
                        href={
                          'https://myalice-live-public-bucket.s3.ap-southeast-1.amazonaws.com/misc/Sample-Format.csv'
                        }
                        className='text-[#0078CF] cursor-pointer underline'
                      >
                        {t('Download Sample Format')}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <Spinner
                      color='border-r-transparent border-t-[#36C17F] border-b-[#36C17F] border-[#36C17F] border-4'
                      size='w-[72px] h-[72px]'
                    />
                    <p className='absolute top-[250px] w-max left-[50%]'>
                      <span className='relative left-[-50%] rtl:text-right'>
                        {t('Uploading')} {!!csvFileName && csvFileName}
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
          {isFileUploadLoaded && (
            <div className='flex flex-col h-full w-full justify-start items-start mb-4 pt-2'>
              <div className='mb-4'>
                <p className='font-medium text-base text-gray-900 leading-6'>
                  {t('Map Columns')}
                </p>
              </div>
              <MapBroadcastData
                attributes={attributes}
                setAttributes={setAttributes}
                setMappedData={setMappedData}
                mappedData={mappedData}
                isFileReupload={isFileReupload}
                setIsFileReupload={setIsFileReupload}
              />
              <div className='w-full flex ltr:justify-end rtl:justify-start'>
                <input
                  id='reupload'
                  type='file'
                  placeholder='Upload CSV'
                  className='hidden'
                  accept='.csv'
                  onChange={(e) => {
                    //@ts-ignore
                    handleFileDrop(e.target.files[0]);
                    setIsFileReupload(true);
                  }}
                />
                <label
                  htmlFor='reupload'
                  className='decoration undeline text-[#0078CF] cursor-pointer mb-3 underline rtl:text-right'
                >
                  {t('Upload a new CSV')}
                </label>
              </div>
              <div className='flex gap-2 py-4 px-4 bg-[#EFF6FF] rounded-md w-full'>
                <InformationCircleIcon className='w-5 h-5 text-blue-500 rounded-full' />
                <div className='text-s text-blue-400 rtl:text-right'>
                  <p className='text-s text-blue-800 mb-3'>
                    {t(
                      'After you schedule the campaign, please add enough credits to run the campaign. You will be charged from your card. Unused credits will remain in your account, which you can use in future broadcasts.'
                    )}
                  </p>
                  <a
                    href='https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-whatsapp-business/whatsapp-broadcast-overview'
                    target='_blank'
                    rel='noreferrer'
                    id='audience-learn-more'
                    className={`text-s text-[#0078CF] leading-5 font-semibold ${
                      shouldHideLearnMore ? 'hidden' : 'visible'
                    }`}
                  >
                    {t('Learn More')}
                    {isRtlLanguage && <span>&#x2190;</span>}
                    {!isRtlLanguage && <span>&#x2192;</span>}
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  platformList: state.dashboard.platformList,
  selectedProject: state.dashboard.selectedProject,
  broadcastCreationData: state.broadcast.broadcastCreationData,
  broadcastColumnMap: state.broadcast.mappedData,
});

const mapDispatch = (dispatch: any) => ({
  createBroadcast: (payload: { projectId: number; data: CompaignPostData }) =>
    dispatch.broadcast.createBroadcast(payload),
  uploadCSVForMapping: (
    payload:
      | {
          projectId: number;
          data: UpdateBoradcastProperty;
        }
      | { projectId: number; data: CompaignPostData }
  ) => dispatch.broadcast.uploadCSV(payload),
  updateBroadcastCreationData: (payload: BroadcastCreationProperty[] | null) =>
    dispatch.broadcast.updateBroadcastCreationData(payload),
  updateMappedData: (payload: CSVProperty | null) =>
    dispatch.broadcast.updateMappedData(payload),
});

export default connect(mapState, mapDispatch)(Audiencetab);
