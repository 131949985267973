import { toaster } from 'evergreen-ui';
import Button from 'library/button';
import NewAlertModal from 'library/modal/NewAlertModal';
import useBulkAction from 'pages/inbox/hooks/useBulkAction';
import { useState } from 'react';
import useTranslation from 'components/customHooks/useTranslation';
import { classNames } from 'utilities/utils';
import BulkAssignTagSelector from './components/Modal';
import AgentListView from './components/AgentSelectionPanel';
import { useTagSettings } from 'old/pages/settings/hooks/useTagSettings';
const BulkView = () => {
  const {
    bulkData,
    agentData,
    assignUser,
    applyBulkAction,
    updateBulkAction,
    handleBulkToggle,
    getTicketsWithNoTags,
    selectUnselectAllTicket,
  } = useBulkAction();
  const { t } = useTranslation();
  const { isTagMandatoryEnable } = useTagSettings();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTagModalOpen, setTagModalOpen] = useState(false);
  const [waitingForRes, setWaitingForRes] = useState(false);
  const [showSelectorDialog, setSelectorDialog] = useState(false);
  const [emtyTagWarningTxt, setEmtyTagWarningTxt] = useState('');
  const [searchableAgents, updateSearchableAgents] = useState(agentData);
  const [selectedAgent, setSelectedAgent] = useState(bulkData?.assign_user);
  const [selectedGroup, setSelectedGroup] = useState(bulkData?.assign_group);

  const getFirstUppercaseFormat = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const handleApplyBulkAction = async () => {
    setWaitingForRes(true);
    const res = await applyBulkAction();
    if (res.success) {
      toaster.success(res.message);
      handleBulkToggle();
      selectUnselectAllTicket();
    } else {
      toaster.danger(t('Something went wrong. Please try again.'));
    }
    setWaitingForRes(false);
    setModalOpen(false);
  };

  const handleConfirmAction = async () => {
    if (bulkData?.action === 'close' && isTagMandatoryEnable) {
      const ticketsWithNoTag = getTicketsWithNoTags();
      if (ticketsWithNoTag.length > 0) {
        setEmtyTagWarningTxt(
          `${ticketsWithNoTag?.length} out of ${bulkData?.tickets.length} tickets `
        );
        setTagModalOpen(true);
        setModalOpen(false);
      } else {
        handleApplyBulkAction();
      }
    } else {
      handleApplyBulkAction();
    }
  };

  const handleActionButtonClick = async (type: string) => {
    await updateBulkAction(type);
  };

  const renderTicketCountView = () => {
    return (
      <div className='flex items-center'>
        <span className='w-full text-base font-bold text-gray-700 leading-6'>
          {t(`{{${bulkData?.tickets.length}}} conversations selected`)}
        </span>
        <span
          className={classNames(
            'text-sm font-medium leading-5 text-yellow-600 ',
            bulkData?.tickets.length >= 50 ? 'inline-block' : 'hidden'
          )}
        >
          {/* ltr:ml-auto rtl:mr-auto */}
          {t('Max Limit')}
        </span>
      </div>
    );
  };

  const renderActionButtonsView = () => {
    return (
      <div
        className={classNames(
          'mt-5 items-center gap-2 justify-center',
          bulkData?.tickets.length === 0 ? 'hidden' : 'flex'
        )}
      >
        <Button
          className='hidden w-full'
          size='md'
          intent='default'
          isFullWidth={true}
          onClick={() => {
            handleActionButtonClick('tag');
          }}
        >
          {t('Tag')}
        </Button>
        <Button
          className=' w-full'
          size='md'
          intent='default'
          isFullWidth={true}
          onClick={async () => {
            await handleActionButtonClick('assign');
            setSelectorDialog(true);
          }}
        >
          {t('Assign')}
        </Button>
        <Button
          className='w-full'
          size='md'
          intent='primary'
          isFullWidth={true}
          onClick={() => {
            handleActionButtonClick('close');
            setModalOpen(true);
          }}
        >
          {t('Close Chat')}
        </Button>
      </div>
    );
  };

  const renderBottomView = () => {
    return (
      <div className='mt-4 flex justify-between items-center'>
        <button
          className='text-[#0078CF] border-0 bg-white text-sm font-medium leading-5'
          onClick={() => selectUnselectAllTicket()}
        >
          {bulkData?.tickets.length > 0 ? t('Unselect All') : t('Select All')}
        </button>
        <button
          className='text-gray-600 border-0 bg-white text-sm font-medium leading-5'
          onClick={() => handleBulkToggle()}
        >
          {t('Exit Select Mode')}
        </button>
      </div>
    );
  };
  const renderModal = () => {
    return (
      <NewAlertModal
        isShown={isModalOpen}
        title={getFirstUppercaseFormat(t(`${bulkData?.action} all?`))}
        description={t(
          `You're about to {{${bulkData?.action}}} {{${
            bulkData?.tickets.length
          }}} {{conversation${
            bulkData?.tickets.length > 1 ? 's' : ''
          }}}. Do you wish to proceed?`
        )}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={getFirstUppercaseFormat(
          t(`${bulkData?.action} All`)
        )}
        onConfirm={() => {
          handleConfirmAction();
        }}
        onCancelComplete={() => setModalOpen(false)}
        isConfirmationLoading={waitingForRes}
        isCancelButtonDisabled={waitingForRes}
      />
    );
  };

  const isApplyButtonDisabled = () => {
    return selectedAgent === 0 && selectedGroup === 0;
  };

  const handleSearch = (key: string) => {
    if (key === '') {
      updateSearchableAgents({ ...agentData });
    } else {
      const filteredAgents = agentData?.agents.filter((data: AgentInterface) =>
        data?.admin?.full_name.toLowerCase().includes(key)
      );
      const filteredGroups = agentData?.groups.filter((data: GroupInterface) =>
        data?.name.toLowerCase().includes(key)
      );

      updateSearchableAgents({
        agents: [...filteredAgents],
        groups: [...filteredGroups],
      });
    }
  };

  const isAssginTagDataEmpty = () => {
    return (
      searchableAgents?.agents.length === 0 &&
      searchableAgents?.groups.length === 0
    );
  };

  const handleAgentChangeApply = () => {
    assignUser(selectedAgent, selectedGroup);
    setSelectorDialog(false);
    updateSearchableAgents(agentData);
    setModalOpen(true);
  };

  const handleAgentChange = (agentId: number, type: string) => {
    if (type === 'agent') {
      setSelectedAgent(agentId);
      setSelectedGroup(0);
    } else {
      setSelectedGroup(agentId);
      setSelectedAgent(0);
    }
  };

  const renderAssignTagView = () => {
    return (
      <BulkAssignTagSelector
        title={t('Assign')}
        subTitle={t(`{{${bulkData?.tickets.length}}} conversations selected`)}
        buttonText='Assign'
        open={showSelectorDialog}
        isDataEmpty={isAssginTagDataEmpty()}
        searchPlaceholder={t('Search Agents or Groups')}
        isApplyDisabled={isApplyButtonDisabled()}
        handleCloseDialog={() => setSelectorDialog(false)}
        handleSearchKeyChange={(searchKey: string) => {
          handleSearch(searchKey.toLowerCase());
        }}
        handleApply={handleAgentChangeApply}
      >
        <AgentListView
          agents={searchableAgents?.agents}
          groups={searchableAgents?.groups}
          selectedAgent={selectedAgent}
          selectedGroup={selectedGroup}
          handleAgentChange={handleAgentChange}
        />
      </BulkAssignTagSelector>
    );
  };

  const renderNoTagWarningModal = () => {
    return (
      <NewAlertModal
        isShown={isTagModalOpen}
        title='Bulk Action'
        confirmButtonTitle='Close Anyway'
        intent='warning'
        description={
          <span className='text-base font-normal  text-gray-700 text-center'>
            {' '}
            <span className='text-base font-semibold  text-primary'>
              {emtyTagWarningTxt}
            </span>
            won’t be closed because they don’t have any tags added.
          </span>
        }
        shouldRenderDescriptionAsNode={true}
        onConfirm={async () => {
          handleApplyBulkAction();
          setTagModalOpen(false);
          setModalOpen(true);
        }}
        onClose={() => {
          setTagModalOpen(false);
        }}
        onCancelComplete={() => setTagModalOpen(false)}
        shouldShowConfirmButton={
          !isTagMandatoryEnable ||
          bulkData?.tickets.length !== getTicketsWithNoTags()?.length
        }
      />
    );
  };

  const mainRender = () => {
    if (isModalOpen) {
      return renderModal();
    } else {
      return (
        <div className=' min-w-[450px] px-4 py-5 border border-gray-200 rounded-md shadow-lg bg-white'>
          {renderTicketCountView()}
          <div className='flex w-full'>
            <span className='w-full mt-1 text-sm font-normal leading-5 text-gray-500'>
              {t('Select multiple tickets to perform a bulk action')}
            </span>
          </div>
          {renderActionButtonsView()}
          {renderBottomView()}
          {renderAssignTagView()}
          {renderNoTagWarningModal()}
        </div>
      );
    }
  };

  return (
    <div className='w-full h-full bg-gray-50 flex items-center justify-center'>
      {mainRender()}
    </div>
  );
};

export default BulkView;
