import { React, CircleAlert, CircleCheck } from '../../../../export';
import { IAutomationLogResult } from 'pages/automationWorkflow/interface';
import { ConditionNodeDetailsLog } from './ConditionNodeDetailsLog';

interface Props {
  comparisonData: any;
}

const LogsComparisonComponent = ({ comparisonData }: Props) => {
  const getSlectedValuesTuple = (comparison: any) => {
    return comparison?.col_2
      ?.map((item: { label: string }) => item.label)
      .join(', ');
  };

  const renderSingleComparisonBlock = (
    comparison: any,
    i: number,
    childArray: any[]
  ) => {
    if (!comparison?.component_code) {
      return null;
    }
    const conditionResult =
      comparison?.component_computation_info?.current_component_state;

    const renderConditionLogResultIcon = () => {
      switch (conditionResult) {
        case 'success': {
          return (
            <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#04A056]' />
          );
        }
        case 'failed': {
          return (
            <CircleAlert className='h-4 self-center flex-shrink-0 w-4 text-red-500' />
          );
        }
        case 'pending': {
          return (
            <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#F59E0B]' />
          );
        }
        default:
          return (
            <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#A1A1AA]' />
          );
      }
    };

    return (
      <div
        key={i}
        className={
          i < childArray.length - 2
            ? 'border-b border-gray-200 py-2 flex'
            : 'py-2 flex'
        }
      >
        {/* Render your comparison data here */}
        <div className='flex gap-2 items-center'>
          <span>{renderConditionLogResultIcon()}</span>
          <span className='text-gray-500'>
            {`${comparison?.name} ${comparison?.col_1?.label} - `}
            <span className='text-gray-700 font-medium'>
              {`(${getSlectedValuesTuple(comparison)})`}
            </span>
          </span>
        </div>
        &nbsp;
        {/* Add more fields as needed */}
      </div>
    );
  };

  return (
    <div className='w-full'>
      {comparisonData?.map((childArray: any, index: number) => (
        <div key={index} className={index > 0 ? 'mt-4' : ''}>
          {/* whole commparison block */}
          <div className='p-2 border border-gray-200 rounded-md'>
            {childArray.map(renderSingleComparisonBlock)}
          </div>
          {index < comparisonData.length - 1 && (
            <div className='flex items-center justify-center mt-4'>
              <div className='flex-1 border-t border-gray-200' />
              <div className='font-bold text-gray-500 px-2 border border-gray-200 rounded-[12px]'>
                OR
              </div>
              <div className='flex-1 border-t border-gray-200' />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const LogViewConditionNodePreview: React.FC<{
  conditionData: any;
  nodeId: string;
  nodeType: string;
  logResult: IAutomationLogResult;
}> = ({ conditionData, nodeId, nodeType, logResult }) => {
  return (
    <>
      <div className='flex flex-col gap-2 items-center justify-center w-full'>
        <ConditionNodeDetailsLog
          logResult={logResult}
          conditionData={conditionData}
        />
        <LogsComparisonComponent comparisonData={conditionData} />
      </div>
    </>
  );
};

export default LogViewConditionNodePreview;
