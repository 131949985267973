import React from 'react';

interface Prop {
  fillColor?: string;
  width?: number;
  height?: number;
}

const TagIcon: React.FC<Prop> = ({
  fillColor = '#6B7280',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M19.248 9.151a1.2 1.2 0 0 1 0 1.697l-8.4 8.4a1.2 1.2 0 0 1-1.697 0l-8.4-8.4A1.196 1.196 0 0 1 .4 10V4A3.6 3.6 0 0 1 4 .4h6c.307 0 .614.117.848.351l8.4 8.4ZM4 5.2a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default TagIcon;
