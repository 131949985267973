import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Input,
  useFormContext,
} from '../../export';

export default function EmailField({
  data,
  formActionType,
  valuePath,
}: IFormFieldComponent): JSX.Element {
  const isFormViewMode = formActionType === 'VIEW';

  const form = useFormContext();
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const { name: label, placeholder, is_required: required } = data;

  const emailFieldRules = {
    required: {
      value: !!data.is_required,
      message: `${data?.name} is required.`,
    },
    pattern: {
      value: /^\S+@\S+\.\S+$/,
      message: 'Invalid email address.',
    },
  };

  const renderTextFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'> {data?.label_agent || label} </p>
        <p className='text-zinc-900 text-sm'>
          {form.getValues(currentFieldPath) || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  const renderEmailField = () => (
    <FormField
      control={form.control}
      name={currentFieldPath}
      disabled={data.is_readonly}
      rules={emailFieldRules}
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || label}
            {required && <span className='text-red-500'>*</span>}
          </FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              {...field}
              className='focus:border-ring focus-visible:ring-0'
            />
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return isFormViewMode ? renderTextFieldView() : renderEmailField();
}
