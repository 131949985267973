import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';
import React, { useState, useEffect, useRef } from 'react';

import { Filter, Input, useDatalab } from '../../../export';
import useTranslation from 'components/customHooks/useTranslation';
import editIcon from '../../../assets/images/editIcon.svg';

interface SiteSheetProps {
  onClose: () => void;
  onApplyFilter: (a: any) => void;
  isOpen: boolean;
}

const FilterSideSheet = ({
  onClose,
  onApplyFilter,
  isOpen = false,
}: SiteSheetProps) => {
  const { appliedFilterView, updateDatalabFilter } = useDatalab();
  const [editable, setEditable] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  // Update formTitle when appliedFilterView.title changes
  useEffect(() => {
    if (appliedFilterView?.title) {
      setFormTitle(appliedFilterView.title);
    }
  }, [appliedFilterView]);

  useEffect(() => {
    if (editable && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editable]);

  const handleOnFormTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormTitle(event.target.value);
  };

  const handleOnBlur = () => {
    setEditable(false);
  };
  const hanleOnEditFormTitle = () => {
    setEditable(!editable);
  };

  const handleTitleSaveChanges = () => {
    if (formTitle !== appliedFilterView?.title && appliedFilterView?.id) {
      updateDatalabFilter(appliedFilterView?.id, {
        ...appliedFilterView,
        title: formTitle,
      });
    }

    setEditable(!editable);
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto scrollbar-hide'
      >
        <SheetHeader>
          <SheetTitle>
            <div className='w-full mt-2'>
              {editable ? (
                <div className='flex items-center w-full'>
                  <Input
                    name='form-title'
                    value={formTitle}
                    onChange={handleOnFormTitleChange}
                    className='text-base border-none focus:ring-0 focus:outline-none shadow-none w-[90%] px-0 pb-1 pt-1 mt-0'
                    ref={inputRef}
                    onBlur={handleOnBlur}
                  />

                  <span
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={handleTitleSaveChanges}
                    className='pl-4 text-sm cursor-pointer'
                  >
                    Save
                  </span>
                </div>
              ) : appliedFilterView?.title ? (
                <div className='flex text-lg items-center w-full'>
                  {t(formTitle)}
                  <div onClick={hanleOnEditFormTitle} className='rtl:mr-2 ml-2'>
                    <img
                      src={editIcon}
                      alt='edit'
                      className='w-4 h-4 cursor-pointer'
                    />
                  </div>
                </div>
              ) : (
                <span className='text-lg'>{t('Add filter')}</span>
              )}
            </div>
          </SheetTitle>
        </SheetHeader>
        <div className='h-[calc(100vh-104px)] mt-4'>
          <Filter onSideSheetClose={onClose} onApplyFilter={onApplyFilter} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSideSheet;
