import { Button } from '../../../../../libraryV2/ui/button';
import { DropdownMenu } from '../../../../../libraryV2/ui/dropdown-menu';
import { Column } from '@tanstack/react-table';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const getColumnTitle = (slug: string) => {
    switch (slug) {
      case 'name':
        return 'SLA Policies';
      case 'created_at':
        return 'Created at';
      case 'created_by':
        return 'Created by';
      case 'platforms':
        return 'Channels';
      case 'is_active':
        return 'Status';
      default:
        return '';
    }
  };

  if (!column.getCanSort()) {
    return <div>{getColumnTitle(title)}</div>;
  }

  return (
    <div className={'flex items-center space-x-2 max-w-48'}>
      <DropdownMenu>
        <Button
          variant='ghost'
          size='sm'
          className='-ml-3 h-8 data-[state=open]:bg-accent'
        >
          <span className='text-[#71717A]'>{getColumnTitle(title)}</span>
        </Button>
      </DropdownMenu>
    </div>
  );
}
