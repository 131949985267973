import { navigate, Redirect } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../library';
import padlockIcon from '../assets/images/padlock-icon.svg';
import AuthHeader from './AuthHeader';
import LoginFooter from './LoginFooter';

const PasswordVerificationSuccess = ({ auth }) => {
  const { authT } = useTranslation();
  const isAuthenticated = auth.access !== '';
  return isAuthenticated ? (
    <Redirect from='/' to='/dashboard' noThrow />
  ) : (
    <div
      className='flex flex-col justify-center min-h-screen py-12 bg-white sm:px-6 lg:px-8'
      style={{
        backgroundImage: `url("https://res.cloudinary.com/dgv96gtl3/image/upload/v1626727864/MyAlice%20Heroes.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center bottom',
        backgroundSize: 'contain, cover',
      }}
    >
      <AuthHeader
        image={padlockIcon}
        title='Password Changed'
        subtitle='Click on the login button below to login with your new password.'
        isLogo={false}
      />
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='px-4 py-4 sm:rounded-lg sm:px-10'>
          <div className='space-y-6'>
            <Button
              intent='primary'
              isFullWidth={true}
              onClick={() => navigate(`/`)}
            >
              {authT('Login Now')}
            </Button>
          </div>
        </div>
      </div>
      <LoginFooter />
    </div>
  );
};
PasswordVerificationSuccess.propTypes = {
  auth: PropTypes.any,
};

const mapState = (state) => ({
  auth: state.auth,
});

const PasswordVerificationSuccessContainer = connect(
  mapState,
  {}
)(PasswordVerificationSuccess);

export default PasswordVerificationSuccessContainer;
