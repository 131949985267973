import React from 'react';
import puzzleIcon from '../../../assets/images/puzzleIcon.svg';
import Button from '../../../../../library/button';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  handleOnclick: () => void;
}

const EmptyPlatform: React.FC<Props> = ({ handleOnclick }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center'>
      <img className='mt-40' src={puzzleIcon} alt='puzzleIcon' />
      <span className='mt-3 text-sm font-medium text-gray-900'>
        {t('Nothing Integrated Yet')}
      </span>
      <span className='text-gray-500'>
        {t('Get started by connecting a channel')}
      </span>
      <Button
        intent={'primary'}
        className='mt-6'
        onClick={() => handleOnclick()}
      >
        {t('Available Integrations')}{' '}
        {
          <ArrowRightIcon
            className='w-5 h-5 ltr:ml-2 rtl:mr-2 text-white rtl:-rotate-180'
            aria-hidden='true'
          />
        }
      </Button>
    </div>
  );
};
export default EmptyPlatform;
