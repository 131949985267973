import React from 'react';

interface props {
  bgColor: string;
  showCrossIcon: boolean;
  children?: React.ReactNode;
  prioritySegment: string;
  updateShouldShowBanner: (shouldShowTrialBanner: boolean) => void;
}

const AnnouncementBanner: React.FC<props> = ({
  bgColor,
  children,
  showCrossIcon,
  prioritySegment,
  updateShouldShowBanner,
}) => {
  function getCrossColor(prioritySegment: string) {
    if (prioritySegment.includes('warning')) {
      return 'text-[#D97706]';
    } else if (prioritySegment.includes('positive')) {
      return 'text-[#10B981]';
    } else if (prioritySegment.includes('informational')) {
      return 'text-[#3B82F6]';
    } else {
      return 'text-[#EF4444]';
    }
  }
  let crossColor = getCrossColor(prioritySegment);

  return (
    <>
      <div className={`${bgColor} ${prioritySegment} absolute h-full w-full`}>
        <div className='relative h-[40px] flex justify-center align-center w-full'>
          {children}

          {showCrossIcon && (
            <div
              className={`absolute right-6 ${crossColor} cursor-pointer hover:text-[#A31CAF] hover:font-bold`}
              onClick={() => updateShouldShowBanner(false)}
            >
              <p>&#x2715;</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AnnouncementBanner;
