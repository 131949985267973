import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import sendingStatusIcon from '../../../../assets/images/sendingStatusIcon.svg';
import sentStatusBlueIcon from '../../../../assets/images/sentStatusBlueIcon.svg';
import sentStatusIcon from '../../../../assets/images/sentStatusIcon.svg';
import sentStatusIconSingleTickIcon from '../../../../assets/images/sentStatusIconSingleTick.svg';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { cn } from 'libraryV2/utils';
import { TooltipProviderCustomised } from '../../../../../../libraryV2/ui/tooltip';
import { conversationStatusType } from '../../../../inboxInterface';

interface Props {
  success: conversationStatusType | string;
  isMerged: boolean;
  report?: any;
  source?: string;
  conversationType?: string;
}

const MessageStatus: React.FC<Props> = ({
  success,
  isMerged,
  report,
  source = 'customer',
  conversationType = 'text',
}) => {
  const { t } = useTranslation();
  const isAdminReply = ['admin', 'echo', 'bot'].includes(source);
  return (
    <>
      {!isMerged && (
        <div
          className={cn(
            'flex group w-auto h-auto content-center flex-shrink-0 relative overflow-hidden z-[9]',
            conversationType === 'attachment' &&
              'absolute bottom-2 right-1 mt-0'
          )}
        >
          {success === null && (
            <>
              <TooltipProviderCustomised content={t('Sending')}>
                <img src={sendingStatusIcon} alt='sending' />
              </TooltipProviderCustomised>
            </>
          )}
          {success === 'delivered' && (
            <>
              <TooltipProviderCustomised content={t('Sent')}>
                <img
                  data-for='sent-status'
                  data-tip={t('Sent')}
                  src={isAdminReply ? sentStatusBlueIcon : sentStatusIcon}
                  alt='sent'
                />
              </TooltipProviderCustomised>
            </>
          )}
          {success === 'received' && (
            <>
              <TooltipProviderCustomised content={t('Received')}>
                <img
                  data-for='sent-status'
                  data-tip={t('Received')}
                  src={sentStatusIconSingleTickIcon}
                  alt='received'
                />
              </TooltipProviderCustomised>
            </>
          )}
          {success === 'failed' && (
            <>
              <TooltipProviderCustomised
                content={JSON.stringify(report, null, 2) || t('Failed to send')}
              >
                <ExclamationCircleIcon
                  data-for='failed-status'
                  data-tip={
                    JSON.stringify(report, null, 2) || t('Failed to send')
                  }
                  className='w-3.5 h-3.5 text-red-600'
                />
              </TooltipProviderCustomised>
            </>
          )}
        </div>
      )}
      {!!isMerged && success === 'failed' && (
        <div className='absolute flex group w-auto h-auto top-[7px] ltr:left-[47px] rtl:right-[47px]'>
          <TooltipProviderCustomised
            content={JSON.stringify(report, null, 2) || t('Failed to send')}
          >
            <ExclamationCircleIcon
              data-for='failed-status'
              data-tip={JSON.stringify(report, null, 2) || t('Failed to send')}
              className='w-3.5 h-3.5 text-red-600'
            />
          </TooltipProviderCustomised>
        </div>
      )}
    </>
  );
};

export default MessageStatus;
