import React, { useState, useEffect } from 'react';
import { Dialog, TextInputField, toaster } from 'evergreen-ui';

interface Props {
  isShown: boolean;
  isLoading: boolean;
  updateState: (state: string, value: string | number | boolean) => void;
  status: 'create' | 'edit';
  handleSequenceAdd: (title: string) => boolean;
  editId: number;
  handleRename: (id: number, title: string) => boolean;
  editTitle: string;
}

const ModifiedSequence: React.FC<Props> = ({
  isShown,
  isLoading,
  updateState,
  status,
  handleSequenceAdd,
  editId,
  handleRename,
  editTitle,
}) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (editId > 0) {
      setTitle(editTitle);
    } else {
      setTitle('');
    }

    setTimeout(() => {
      document.getElementById('TextInputField-id')?.focus();
    }, 300);
    return () => {};
  }, [isShown, editId, editTitle]);
  return (
    <Dialog
      isShown={isShown}
      title={status === 'edit' ? `Rename Sequence` : 'Create Sequence'}
      isConfirmLoading={isLoading}
      intent='success'
      onCloseComplete={() => {
        updateState('sequenceAcitivityModal', false);
        updateState('sequenceAcitivityLoader', false);
        setTitle('');
      }}
      onConfirm={async () => {
        updateState('sequenceAcitivityLoader', true);
        if (status === 'create') {
          if (!!title.trim()) {
            const result: boolean = await handleSequenceAdd(title);
            if (result) {
              setTimeout(() => {
                updateState('sequenceAcitivityModal', false);
                updateState('sequenceAcitivityLoader', false);
                setTitle('');
              }, 100);
            } else {
              updateState('sequenceAcitivityLoader', false);
            }
          } else {
            setTimeout(() => {
              toaster.warning('Provide A Valid Title');
              setTitle('');
              updateState('sequenceAcitivityLoader', false);
            }, 100);
          }
        } else if (status === 'edit') {
          if (!!title.trim()) {
            const result: boolean = await handleRename(editId, title);
            if (result) {
              setTimeout(() => {
                updateState('sequenceAcitivityModal', false);
                updateState('sequenceAcitivityLoader', false);
                setTitle('');
              }, 100);
            } else {
              updateState('sequenceAcitivityLoader', false);
            }
          } else {
            setTimeout(() => {
              toaster.warning('Provide A Valid Title');
              setTitle('');
              updateState('sequenceAcitivityLoader', false);
            }, 100);
          }
        }
      }}
      confirmLabel={
        isLoading ? 'Loading...' : status === 'edit' ? 'Update' : 'Create'
      }
    >
      <TextInputField
        id={'id'}
        label='Sequence Name'
        placeholder='Provide a valid name'
        value={title}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          setTitle(evt.target.value)
        }
        hint={
          status === 'edit'
            ? `Old Title: ${editTitle}`
            : 'ex. Product, order-list, etc'
        }
      />
    </Dialog>
  );
};

export default ModifiedSequence;
