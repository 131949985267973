import { Switch } from '@headlessui/react';
import React from 'react';
import { classNames } from 'utilities/utils';
import { connect } from 'react-redux';
import { SelectedTeamInterface } from 'index';
import useTranslation from 'components/customHooks/useTranslation';

interface AssignSmartTicketProps {
  currentGroupState: GroupDetailsCardInterface | null;
  onChange: (val: boolean) => void;
  selectedProject: SelectedTeamInterface;
}

const AssignSmartTicket: React.FC<AssignSmartTicketProps> = ({
  currentGroupState,
  onChange,
  selectedProject,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex items-center justify-between px-6 py-4 border rounded-md'>
        <div className='space-y-2'>
          <p className='text-sm font-medium text-gray-700'>
            {t('Smart Ticket Assignment')}
          </p>
          <p className='ltr:pr-4 rtl:pl-4 font-normal text-gray-500'>
            {t(
              `Allow Tickets to be assigned to Agents in this Group in a Round Robin format. This also means the ticket will be assigned to the least engaged Agent.`
            )}
          </p>
        </div>
        {selectedProject?.subscription_plan?.plan_type === 'free' ? (
          <p className='inline-flex top-0 items-center px-2 py-1 rounded text-md font-medium bg-blue-100 text-blue-800'>
            {t('Pro')}
          </p>
        ) : (
          <Switch
            checked={currentGroupState?.is_smart || false}
            disabled={
              !!currentGroupState
                ? currentGroupState.members.length === 0
                : false
            }
            onChange={onChange}
            className={classNames(
              currentGroupState?.is_smart ? 'bg-primary' : 'bg-gray-200',
              'relative disabled:cursor-not-allowed inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-offset-0 focus:ring-0'
            )}
          >
            <span className='sr-only'>{t('Use setting')}</span>
            <span
              aria-hidden='true'
              className={classNames(
                currentGroupState?.is_smart
                  ? 'translate-x-5 rtl:-left-1/2'
                  : 'translate-x-0 rtl:right-1/2',
                'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        )}
      </div>
      {!!currentGroupState && currentGroupState.members.length === 0 && (
        <p className='font-normal text-red-500'>
          {t('There are no members to assign tickets in this group.')}
        </p>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

export default connect(mapState)(AssignSmartTicket);
