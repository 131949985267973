import React, { useState } from 'react';
import { TextInput, toaster } from 'evergreen-ui';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import videoIcon from '../../pages/builder/assets/images/icons/video.svg';

const checkValid = (value) => {
  let expression =
    /^(?:http(s):\/\/)[\w-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/gm;
  let regex = new RegExp(expression);
  return !value.match(regex);
};

const VideoBlock = ({
  id,
  save,
  saveBlock,
  saveLoader,
  url,
  updateURLBlocks,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  hasWriteAccess,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  function handleSave() {
    if (!checkValid(url)) {
      saveBlock(id);
    } else {
      toaster.danger('Failed to Save', {
        description: `Provide A Valid Url`,
        duration: 1,
      });
    }
  }
  return (
    <BlockContainer
      id={id}
      title='Video'
      subtitle='Upload your video and insert the URL here.'
      save={save}
      isLoading={saveLoader}
      icon={videoIcon}
      handleSaveClick={() => handleSave()}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <TextInput
        isInvalid={isInvalid}
        spellCheck={true}
        disabled={!hasWriteAccess}
        placeholder='Paste your video URL here'
        style={{ width: '100%' }}
        value={url}
        onChange={(e) => {
          updateURLBlocks(id, e.target.value, 'urls');
          setIsInvalid(checkValid(e.target.value));
        }}
      />
    </BlockContainer>
  );
};

VideoBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  saveBlock: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  updateURLBlocks: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
};
VideoBlock.defaultProps = {
  updateURLBlocks: (e) => console.log(e),
  hasWriteAccess: false,
};

export default VideoBlock;
