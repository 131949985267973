import axios from 'axios';
import config from '../../../utilities/config';
import AXIOS from '../../../utilities/httpClient';

import { toaster } from 'evergreen-ui';
import { navigate } from '@reach/router';
import { mixpanelTrack, mixpanelUserAuth } from 'utilities/mixpanel';

const initialState = {
  city: '',
  email: '',
  avatar: '',
  status: '',
  access: '',
  refresh: '',
  country: '',
  username: '',
  full_name: '',
  spentTime: 60,
  spentTimeForBanner: 60,
  is_pro: false,
  geolocation: {},
  forceLogout: true,
  invitationToken: '',
  hasOnboarding: false,
  is_appsumo_user: false,
  already_verified: false,
  showEmailBanner: true,
  showResendCountDownForEmail: false,
  showTokenValidationBanner: true,
  showLiveChatBanner: true,
  CTATextForEmailVerification: 'Resend code',
  dashboard_language: localStorage.getItem('language') || 'english',
  // version: process.env.REACT_APP_VERSION,
};

export const auth = {
  state: {
    ...initialState,
  },
  reducers: {
    initLogin(state, payload) {
      return { ...state, ...payload };
    },
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateInvitationToken(state, payload) {
      return { ...state, invitationToken: payload };
    },
    updateEmail(state, payload) {
      return { ...state, email: payload };
    },
    updateShowTokenValidationBanner(rootState, payload) {
      return { ...rootState, showTokenValidationBanner: payload };
    },
    updateShowLiveChatBanner(state, payload) {
      return { ...state, showLiveChatBanner: payload };
    },
    updateForceLogout(state, payload) {
      return { ...state, forceLogout: payload };
    },
    updateAlreadyVerified(state, payload) {
      return { ...state, already_verified: payload };
    },
    updateTimer(state, payload) {
      return { ...state, spentTime: payload };
    },
    updateTimerForBanner(state, payload) {
      return { ...state, spentTimeForBanner: payload };
    },
    updateShowEmailBanner(state, payload) {
      return { ...state, showEmailBanner: payload };
    },
    updateShowResendCountDownForEmail(state, payload) {
      return { ...state, showResendCountDownForEmail: payload };
    },
    updateCTATextForEmailVerification(state, payload) {
      return { ...state, CTATextForEmailVerification: payload };
    },
    updateHasOnboarding(state, payload) {
      return { ...state, hasOnboarding: payload };
    },
    updateGeolocation(state, payload) {
      return {
        ...state,
        country: payload.country,
        city: payload.city,
        geolocation: payload,
      };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async login(payload) {
      try {
        const res = await axios.post(config.auth.login(), payload);
        if (res.status === 200 && res.data.success) {
          dispatch.auth.initLogin(res.data);
          dispatch.auth.updateForceLogout(false);
          let data = await this.updateInfo(res.data);

          dispatch.auth.updateHasOnboarding(
            data.dataSource.has_completed_onboarding
          );
        }
        return res;
      } catch (err) {
        return err.response;
      }
    },

    async validateAppSumoToken(token) {
      try {
        const res = await axios.post(config.auth.appSumoValidateTokenURL(), {
          appsumo_installation_request_id: token,
        });
        return res.data;
      } catch (err) {
        return { success: false };
      }
    },
    async appSumoRegister(payload) {
      try {
        const res = await axios.post(config.auth.appSumoRegister(), payload);
        dispatch.auth.updateEmail(payload.email);
        dispatch.auth.updateForceLogout(false);
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async register(payload) {
      try {
        const res = await axios.post(config.auth.register(), payload);
        dispatch.auth.updateEmail(payload.email);
        dispatch.auth.updateForceLogout(false);
        dispatch.onboardingProgress.updateOnboardingCompletationStep(
          res.data?.dataSource?.onboarding_completion_state || {}
        );
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async registerV2(payload) {
      try {
        const res = await axios.post(config.auth.registerV2(), payload);
        dispatch.auth.updateEmail(payload.email);
        mixpanelTrack('Signed Up');
        dispatch.auth.updateForceLogout(false);
        dispatch.onboardingProgress.updateOnboardingCompletationStep(
          res.data?.dataSource?.onboarding_completion_state || {}
        );
        return res.data.success;
      } catch (err) {
        return false;
      }
    },
    async fetchGeolocation() {
      try {
        const res = await axios.get(config.misc.geoLocation());
        dispatch.auth.updateGeolocation(res.data);
        return res.data;
      } catch (err) {
        return {};
      }
    },
    async resendRegisterVerifyLink(payload) {
      try {
        const res = await axios.post(
          config.auth.resendRegisterVerifyLink(),
          payload
        );
        if (res.data.status === 201) {
          dispatch.auth.updateAlreadyVerified(true);
        }
        return true;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async registerVerify(payload) {
      try {
        const res = await axios.get(
          config.auth.registerVerify('stable', payload.code)
        );
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async resetRequest(payload) {
      try {
        const res = await axios.post(config.auth.resetRequest(), payload);
        this.updateEmail(payload.email);
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async resetVerify(payload) {
      try {
        const res = await axios.post(config.auth.resetVerify(), payload);
        return res.data.success;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
        return false;
      }
    },
    async refreshLogin(payload) {
      try {
        const res = await axios.post(config.auth.refresh(), payload);
        dispatch.auth.initLogin(res.data);
        return res.status;
      } catch (err) {
        dispatch.auth.logout();
        return err.response.status;
      }
    },
    async updateInfo() {
      try {
        const res = await AXIOS.get(config.auth.info());
        dispatch.auth.initLogin(res.data.dataSource);

        const { email, full_name, id } = res.data.dataSource;

        mixpanelUserAuth(id, {
          email,
          full_name,
        });
        mixpanelTrack('Signed In');
        return res.data;
      } catch (err) {
        return false;
      }
    },
    async updateAccountInfo(status) {
      try {
        const res = await AXIOS.patch(config.auth.info(), status);

        if (res.status === 200) {
          dispatch.auth.initLogin(res.data.dataSource);
          return { data: res.data.dataSource, success: true };
        } else return { success: false };
      } catch (exception) {
        console.error(exception);
        return { success: false };
      }
    },
    async updateStatus(status) {
      try {
        const res = await AXIOS.post(config.auth.updateStatus(), {
          status: status,
        });
        dispatch.auth.initLogin(res.data.dataSource);
        return res.data;
      } catch (err) {
        return false;
      }
    },
    async updateStatusChangeReason(payload) {
      try {
        const res = await AXIOS.post(config.auth.updateStatus(), {
          status: payload.status,
          reason: payload.reason,
        });
        if (res.status === 200 && res.data.success) {
          dispatch.auth.initLogin(res.data.dataSource);
          return true;
        }
      } catch (err) {
        err.response.status === 500
          ? toaster.warning("Can't procced at this moment, try Again.")
          : toaster.warning(err.message);
        return false;
      }
    },
    // acceptInvitation will be depricated
    async acceptInvitation(token) {
      try {
        const res = await AXIOS.post(config.auth.acceptInvite(), {
          token: token,
        });
        return res.data;
      } catch (err) {
        return err.response.status;
      }
    },

    async acceptTeamInvitation(token) {
      try {
        const res = await AXIOS.post(config.auth.acceptTeamInvite(), {
          token: token,
        });
        return res;
      } catch (err) {
        return err.response;
      }
    },
    async userAccountInfo() {
      //note: this effect called for onboardingV2, remove this when onboarding changed
      try {
        const res = await AXIOS.get(config.auth.info());
        dispatch.auth.initLogin(res.data.dataSource);
        return res.data.success;
      } catch (err) {
        return false;
      }
    },

    //verify 2fa
    async verifyOtp(payload) {
      /**
       * payload
       * token : string
       * otp : string (6 digit number)
       */
      try {
        const res = await AXIOS.post(config.auth.verifyOtp(), payload);
        if (res.status === 200 && res.data.success) {
          dispatch.auth.initLogin(res.data);
          dispatch.auth.updateForceLogout(false);
          let data = await this.updateInfo(res.data);

          dispatch.auth.updateHasOnboarding(
            data.dataSource.has_completed_onboarding
          );
        }
        return res.data;
      } catch (err) {
        return {
          success: false,
          error: 'OTP verification code expired or incorrect.',
        };
      }
    },

    //resend 2fa otp
    async resendOtp(payload) {
      /**
       * payload
       * token : string
       */
      try {
        const res = await AXIOS.post(config.auth.resendOtp(), payload);

        if (res.status === 200) {
          return res.data;
        } else {
          return { success: false };
        }
      } catch (err) {
        return { success: false };
      }
    },

    async validateInvitationToken(token) {
      try {
        const res = await AXIOS.post(
          config.auth.validateInvitaionToken(token),
          {
            token: token,
          }
        );
        return res.data.dataSource;
      } catch (err) {
        if (err.response.status === 500) {
          toaster.danger('Something went wrong. Could not validate token');
        } else {
          toaster.danger(err.response.data.error);
        }
        return false;
      }
    },
    async logoutAPI() {
      try {
        await AXIOS.post(config.auth.logout());
        mixpanelTrack('Logged Out');
      } catch (err) {
        console.log(err);
      }
      await this.logout();
      navigate(`/`);
    },
    async getCountryData() {
      try {
        const res = await axios.get(config.onboarding.countryList());
        return res.data.dataSource;
      } catch (err) {
        return [];
      }
    },
    logout() {
      dispatch.auth.clearState();
      dispatch.dashboard.clearState();
      dispatch.builder.clearState();
      dispatch.block.clearState();
      dispatch.settings.clearState();
      dispatch.billing.clearState();
      dispatch.audience.clearState();
      dispatch.api.clearState();
      dispatch.rules.clearState();
      dispatch.dataLab.clearState();
      dispatch.labDetails.clearState();
      dispatch.crm.clearState();
      dispatch.reporting.clearState();
      dispatch.channel.clearState();
      dispatch.marketplace.clearState();
      dispatch.onboardingProgress.clearState();
      dispatch.crmEcommerce.clearState();
      dispatch.integration.clearState();
      dispatch.newBilling.clearState();
      dispatch.broadcast.clearState();
      dispatch.inbox.clearState();
      dispatch.datalabList.clearState();
      dispatch.datalabFormBuilder.clearState();
      localStorage.removeItem('previousPath');
    },
  }),
};
