import React from 'react';
import { Avatar } from '.';

const Playground: React.FC<{}> = () => {
  return (
    <div className='p-4'>
      <p>Basic Types</p>
      <div className='grid grid-cols-12'>
        <Avatar />
        <Avatar name='John Doe' />
        <Avatar
          name='John Doe'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
          isRounded={false}
        />
      </div>
      <p className='mt-8'>Size Types</p>
      <div className='grid grid-cols-12'>
        <Avatar
          name='John Doe'
          size='xs'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='sm'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar name='John Doe' size='md' />
        <Avatar
          name='John Doe'
          size='lg'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='xl'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
      </div>
      <p className='mt-8'>Size Types Square</p>
      <div className='grid grid-cols-12'>
        <Avatar
          name='John Doe'
          size='xs'
          isRounded={false}
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='sm'
          isRounded={false}
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='md'
          isRounded={false}
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar name='John Doe' size='lg' isRounded={false} />
        <Avatar
          name='John Doe'
          size='xl'
          isRounded={false}
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
      </div>
      <p className='mt-8'>Status</p>
      <div className='grid grid-cols-12'>
        <Avatar
          name='John Doe'
          size='xs'
          status='online'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='sm'
          status='offline'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar
          name='John Doe'
          size='md'
          status='away'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
        <Avatar name='John Doe' size='lg' status='busy' />
        <Avatar
          name='John Doe'
          size='xl'
          status='online'
          src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80'
        />
      </div>
    </div>
  );
};

export default Playground;
