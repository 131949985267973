import React from 'react';
import { SideSheet as OrderModal } from '../../../../../../library';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import Modal from '../../../../../components/elements/redesign/Modal';
import { SelectedProductDataProps, CustomerDataProps, ShopifyShippingMethodProps } from '../eCommerceOrderCreate/interfaces';
import { orderData } from '../../../../../../utilities/content';

interface Prop {
  isSameShippingAddress: boolean;
  shippingMethod: ShopifyShippingMethodProps;
  createOrderOpen: boolean;
  setCreateOrderOpen: (value: boolean) => void;
  customerId: number;
  clearState: () => void;
  customerData: CustomerDataProps;
  createShopifyDraftOrder: (
    teamId: number,
    customerId: number,
    ticketId: number
  ) => Promise<any>;
  ticketId: number;
  createOrderLoading: boolean;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
  updateMinimizeModal: () => void;
  updateStateData: (key: string, value: any) => void;
  teamId: number;
}

const ShopifyOrderCreate: React.FC<Prop> = ({
  isSameShippingAddress,
  shippingMethod,
  createOrderOpen,
  setCreateOrderOpen,
  customerId,
  clearState,
  customerData,
  createShopifyDraftOrder,
  ticketId,
  createOrderLoading,
  updateCart,
  updateMinimizeModal,
  updateStateData,
  teamId,
}) => {
  const [step, setStep] = React.useState(1);
  const [showCloseWarningModal, setShowCloseWarningModal] =
    React.useState(false);
  const containerRef = React.createRef();
  const [createdOrderState, setCreatedOrderState] = React.useState('Loading');

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        // call api to store
        setStep(2);
        break;
      case 2:
        // update delivery information
        setStep(3);
        break;
      case 3:
        setStep(4);
        break;
      case 4:
        if (createdOrderState === 'Exit') {
          setCreateOrderOpen(false);
          setTimeout(() => {
            setStep(1);
          }, 1000);
        } else if (createdOrderState === 'Try Again') {
          setStep(1);
          setCreatedOrderState('Loading');
        }
        break;
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 2:
        // update delivery information
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
    }
  };

  const getOrderHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: 'Create New Order',
          description:
            'Click on the search bar below to browse your inventory. A selected product from the inventory will be added to the cart. You can further customize the order from there.',
        };
      case 2:
        return {
          title: 'Customer Information',
          description:
            'Please complete the required fields for order placement',
        };
      case 3:
        return {
          title: 'Order Summary',
          description:
            'Please check the information before confirming the order.',
        };
    }
  };

  React.useEffect(() => {
    //we need this to scroll to top for each step
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, [step]);

  const checkStep1NextButton = () => {
    // first we check if we have any product in alice_cart
    const cart: SelectedProductDataProps[] = customerData?.alice_cart || [];
    let flag: boolean = false;
    if (cart.length > 0) {
      cart.forEach((product) => {
        if (product.has_variations && !product.variant_id) {
          flag = true;
        }
      });
      return flag;
    }
    return true;
  };

  const checkStep2NextButton = () => {
    const billingMandatoryKey = [
      'first_name',
      'address_one',
    ];
    const shippingMandatoryKey = ['first_name', 'address_one'];

    let flag = false;

    billingMandatoryKey.forEach((elem) => {
      if (
        !(
          customerData &&
          customerData?.billing_address &&
          customerData?.billing_address[elem]
        )
      ) {
        flag = true;
      }
    });
    if (!flag) {
      if (!isSameShippingAddress) {
        shippingMandatoryKey.forEach((elem) => {
          if (
            !(
              customerData &&
              customerData?.shipping_address &&
              customerData?.shipping_address[elem]
            )
          ) {
            flag = true;
          }
        });
      }
    }
    if (!flag && !shippingMethod) {
      flag = true;
    }
    return flag;
  };

  const shouldDisableNextButton = () => {
    switch (step) {
      case 1:
        return checkStep1NextButton();
      case 2:
        return checkStep2NextButton();
      case 3:
      case 4:
        return false;
      default:
        return true;
    }
  };

  const handleCloseModal = () => {
    if (customerData?.alice_cart.length > 0) {
      setCreateOrderOpen(false);
      updateMinimizeModal();
      setShowCloseWarningModal(true);
    } else {
      setCreateOrderOpen(false);
    }
  };
  const handleMinimizeModal = () => {
    setCreateOrderOpen(false);
    updateMinimizeModal();
  };

  return (
    <div>
      <OrderModal
        open={createOrderOpen}
        ref={containerRef}
        hasMinimizeButton={true}
        handleClose={handleCloseModal}
        handleMinimize={handleMinimizeModal}
        closeOnExternalClick={false}
        disableConfirm={shouldDisableNextButton() ? true : createOrderLoading}
        title={getOrderHeaderInfo(step).title}
        description={getOrderHeaderInfo(step).description}
        confirmText={
          step === 3
            ? 'Complete Order'
            : step === 4
            ? createdOrderState
            : 'Next'
        }
        cancelText={'Back'}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        hideCancel={
          step === 1 ||
          (step === 4 &&
            (createdOrderState === 'Loading' || createdOrderState === 'Exit'))
        }
        hasDropdown={step === 3 && true}
        handleChange={(data: any) => updateStateData('orderStatus', data)}
      >
        <Wrapper
          step={step}
          customerId={customerId}
          confirmOrderAPI={async () => {
            let res = await createShopifyDraftOrder(teamId, customerId, ticketId);
            if (res.success) {
              updateCart(customerId, []);
              clearState();
              setCreatedOrderState('Exit');
            } else {
              setCreatedOrderState('Try Again');
            }
            return res;
          }}
        />
      </OrderModal>
      <Modal
        isShown={showCloseWarningModal}
        setShown={setShowCloseWarningModal}
        intent={'warning'}
        title={orderData.discardOrderCreation.title}
        description={orderData.discardOrderCreation.description}
        deleteTitle='Cancel Order'
        cancelTitle='Cancel'
        hasConfirmText={false}
        onCancelCallback={() => {
          setCreateOrderOpen(true);
        }}
        onDeleteCallback={() => {
          clearState();
          setStep(1);
          updateCart(customerId, []);
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  shippingMethod: state.crmEcommerce.shippingMethod,
  customerData: state.crmEcommerce.customerData,
  createOrderLoading: state.loading.effects.crmEcommerce.createOrder,
  teamId: state.dashboard.selectedProject.id,
});

const mapDispatch = (dispatch: any) => ({
  clearState: () => dispatch.crmEcommerce.clearState(),
  createShopifyDraftOrder: (teamId: number, customerId: number, ticketId: number) =>
    dispatch.crmEcommerce.createShopifyDraftOrder({ teamId, customerId, ticketId }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
  updateMinimizeModal: () => dispatch.crmEcommerce.updateMinimizeModal(),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const ShopifyOrderCreateContainer = connect(
  mapState,
  mapDispatch
)(ShopifyOrderCreate);
export default ShopifyOrderCreateContainer;
