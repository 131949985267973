import React from 'react';
import PropTypes from 'prop-types';
import VisaIcon from '../../../../assets/images/visa.svg';
import MastercardIcon from '../../../../assets/images/mastercard.svg';
import AmexIcon from '../../../../assets/images/amex.svg';
import DiscoverIcon from '../../../../assets/images/discover.svg';
import DinersclubIcon from '../../../../assets/images/diners-club.svg';
import JcbIcon from '../../../../assets/images/jcb.svg';
import UnionpayIcon from '../../../../assets/images/unionpay.svg';
import Button from '../../../../../library/button';

const getCardIcon = (brand) => {
  switch (brand) {
    case 'amex':
      return AmexIcon;
    case 'diners':
      return DinersclubIcon;
    case 'discover':
      return DiscoverIcon;
    case 'jcb':
      return JcbIcon;
    case 'mastercard':
      return MastercardIcon;
    case 'unionpay':
      return UnionpayIcon;
    case 'visa':
      return VisaIcon;
    default:
      return VisaIcon;
  }
};

const CreditCard = ({
  brand,
  expire,
  last4,
  isDefaultLoading,
  isDeleteLoading,
  isDefaultDisabled,
  isDeleteDisabled,
  onDefaultClick,
  onDeleteClick,
}) => {
  return (
    <div className='w-full px-6 py-5 rounded-md bg-gray-50 sm:flex sm:items-start sm:justify-between'>
      <h4 className='sr-only'>Card</h4>
      <div className='sm:flex sm:items-start'>
        <img
          src={getCardIcon(brand)}
          alt={brand}
          className='w-auto h-8 sm:flex-shrink-0 sm:h-6'
        />
        <div className='mt-3 sm:mt-0 sm:ml-4'>
          <div className='text-sm font-medium text-gray-900'>
            Ending with {last4}
          </div>
          <div className='mt-1 text-sm text-gray-600 sm:flex sm:items-center'>
            <div>Expires {expire}</div>
            {/*<span className="hidden sm:mx-2 sm:inline" aria-hidden="true">*/}
            {/*&middot;*/}
            {/*</span>*/}
            {/*<div className="mt-1 sm:mt-0">Last updated on 22 Aug 2017</div>*/}
          </div>
        </div>
      </div>
      <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
        <Button
          size='md'
          intent='minimal'
          className='mr-3 border border-gray-300'
          onClick={() => onDefaultClick()}
          isLoading={isDefaultLoading}
          isDisabled={isDefaultDisabled}
        >
          Make Default
        </Button>

        <Button
          size='md'
          intent='danger'
          onClick={() => onDeleteClick()}
          isLoading={isDeleteLoading}
          isDisabled={isDeleteDisabled}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  brand: PropTypes.string,
  expire: PropTypes.string,
  last4: PropTypes.string,
  isDefaultLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
  isDefaultDisabled: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  onDefaultClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default CreditCard;
