import React, { ReactElement } from 'react';
import { AdminBannerIcon, AgentBannerIcon } from '../assets/icons/svgIcons';

interface Props {
  title: string;
  type: string;
  subTitle: string | ReactElement;
  bgColor: string;
}

const BannerView: React.FC<Props> = ({ title, type, subTitle, bgColor }) => {
  return (
    <div
      className='rounded-md bg-white p-6  w-full overflow-hidden relative'
      style={{ backgroundColor: bgColor }}
    >
      <p className='text-xl font-semibold text-gray-800'>{title}</p>
      <p className='text-sm font-medium text-gray-600 mt-2'>{subTitle}</p>
      <div
        className={`absolute ${
          type === 'agent' ? 'ltr:right-4 rtl:left-4' : 'ltr:right-0 rtl:left-0'
        } top-1`}
      >
        {type === 'agent' ? (
          <AgentBannerIcon className='w-full' />
        ) : (
          <AdminBannerIcon className='w-full' />
        )}
      </div>
    </div>
  );
};

export default BannerView;
