import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  cn,
  dayjs,
} from '../../../../export';
import { CircleAlert } from 'lucide-react';
import { ISingleActionLogData } from 'pages/automationWorkflow/interface';
import { FC } from 'react';

interface ActionNodeDetailsLogProps {
  actionData: ISingleActionLogData;
  actionNodeType?: string;
  logData?: any;
  component?: any;
  render?: () => JSX.Element;
}

export const ActionNodeDetailsLog: FC<ActionNodeDetailsLogProps> = ({
  actionData,
  logData,
  component,
  render,
}) => {
  const hasLogResult: ISingleActionLogData['component_computation_info'] =
    actionData?.component_computation_info;
  const isConditionSuccess =
    hasLogResult && hasLogResult.current_component_state !== 'failed';

  return (
    <Sheet>
      <SheetTrigger asChild className={hasLogResult ? '' : 'hidden'}>
        <button
          className={cn(
            'relative flex gap-1 w-full justify-center items-center p-3 rounded-md',
            isConditionSuccess
              ? 'bg-zinc-100 text-zinc-900'
              : 'bg-[#FFF0F0] text-[#FF0000]'
          )}
        >
          <div
            className={cn('pl-1 flex', {
              hidden: isConditionSuccess,
            })}
          >
            <CircleAlert className='w-5 h-5' />
            <p className='ml-2'>This action haven't triggered.</p>
          </div>
          <span className='p-0 font-semibold'>View Logs</span>
        </button>
      </SheetTrigger>
      <SheetContent
        className='bg-white sm:max-w-[480px] overflow-y-auto'
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <SheetHeader className='space-y-4'>
          <SheetTitle className='text-zinc-900 flex flex-col space-y-2'>
            <span className='text-lg font-semibold'>Activity Logs</span>
            <span className='text-sm text-zinc-500 font-normal leading-5'>
              You can check your automation run history from here.
            </span>
          </SheetTitle>

          <SheetDescription className='text-sm space-y-8 text-zinc-500 leading-5'>
            {typeof render === 'function' ? render() : null}
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

interface ActionNodeDetailsLogCardProps {
  render: () => JSX.Element;
  date: string;
  ResultIcon?: JSX.Element | null;
  cardTitle: string;
  errorMessage: string | null;
  isWhatsappLog?: boolean;
}

export const ActionNodeDetailsLogCard: FC<ActionNodeDetailsLogCardProps> = ({
  render,
  date,
  cardTitle = 'Logs',
  errorMessage = null,
  ResultIcon,
  isWhatsappLog = false,
}) => {
  const conditionDate = date || new Date().toISOString();

  const renderDetailLogs = () => (
    <div className='bg-zinc-50 p-3 flex rounded-xl justify-start gap-3'>
      <div>{ResultIcon}</div>
      <div className='flex flex-col gap-3 w-full'>
        <div className='flex flex-col border border-zinc-200 p-3 gap-1.5 bg-white rounded-lg'>
          {/* time */}
          <div className='flex w-full justify-between'>
            <span className='text-sm text-zinc-500 '>{cardTitle}</span>
            <span className='text-xs text-zinc-500'>
              {dayjs(conditionDate).format('MMM D, YYYY [at] hh:mm A')}
            </span>
          </div>
          {/* Log Info */}
          {typeof render === 'function' && render()}
        </div>
        {errorMessage ? (
          <p className='text-xs text-[#D70000] leading-5'>{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
  return (
    <div className='flex flex-col gap-3'>
      <p className='text-zinc-900 font-semibold leading-5'>{cardTitle}</p>
      <div>{renderDetailLogs()}</div>
    </div>
  );
};
