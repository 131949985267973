import { cloneDeep } from 'lodash';

export const RAN_ID_PREFIX = 'ml';

/**
 * Move one field from one position to another.
 *
 * @param {Array} array    Source array.
 * @param {*}     old_index field index
 * @param {*}     new_index field new index to move.
 *
 * @return {Array}         Result.
 */

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  let temp_arr = cloneDeep(arr);
  if (old_index > new_index) {
    let temp = temp_arr[old_index];
    arr.splice(old_index, 1);
    arr.splice(new_index, 0, temp);
  } else {
    arr.splice(new_index + 1, 0, temp_arr[old_index]);
    arr.splice(old_index, 1);
  }
  return arr;
};

/**
 * Accending sort by object key
 * @param arr
 * @param key
 * @returns
 */
export const sortBy = (arr: any[], key: string) =>
  arr.sort((a, b) => a[key] - b[key]);

/**
 * Capitalize first letter of string
 * Check if the string is empty or not => if empty return empty string else capitalize the first letter of the string
 * @param str
 * @returns
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;

  // custom
  if (str === 'textarea') return 'Paragraph';
  if (str === 'datetime') return 'Date/Time';
  if (str === 'multiselect') return 'Multi-Select';
  if (str === 'singleselect') return 'Single Select';

  return str.charAt(0).toUpperCase() + str.slice(1);
};
