import React from 'react';

import NewAlertModal from '../../../../library/modal/NewAlertModal';
import DatalabFieldIndex from '../datalab/DatalabFieldIndex';
// import SlaCard from './components/SLATarget';
import EcommerceOrderHistory from '../ecommerce/orderHistory/EcommerceOrderHistory';
import CreateShopifyOrder from '../ecommerce/shopify/CreateShopifyOrder';
import CreateWooCommerceOrder from '../ecommerce/wooCommerce/CreateWooCommerceOrder';
import CustomerAttribute from './components/CustomerAttributes';
import CustomerDatalabEntries from './components/CustomerDatalabEntries';
import CustomerInformation from './components/CustomerInformation';
import ECustomerConnection from './components/ECustomerConnection';
import ProfileCard from './components/ProfileCard';

import useTranslation from 'components/customHooks/useTranslation';
import FeatureFlag from 'components/higherOrderComponents/FeatureFlag';
import { SelectedTeamInterface } from 'index';
import {
  CustomerAttributePayloadInterface,
  CustomerInfoInterface,
  CustomerInfoPayloadInterface,
  EcommerceCustomerInterface,
  ICustomerDatalabEntries,
  ICustomerDatalabEntryPayload,
  IDatalabList,
  ResponseModalDataInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { connect } from 'react-redux';
import SallaOrderCreation from '../ecommerce/salla/SallaOrderCreation';
import ZidOrderCreation from '../ecommerce/zid/ZidOrderCreation';
import CustomerTicketHistory from './components/CustomerTicketHistory';

interface Props {
  showRightbar: boolean;
  selectedProject: SelectedTeamInterface;
  openZidModal: boolean;
  openShopifyModal: boolean;
  openSallaModal: boolean;
  openWooCommerceModal: boolean;
  selectedTicket: TicketInterface;
  datalabEntryFetchLimit: number;
  datalabEntryFetchOffset: number;
  datalabTotalEntry: number;
  customerInfo: CustomerInfoInterface;
  datalabList: IDatalabList[];
  customerDatalabEntries: ICustomerDatalabEntries[];
  ecommerceCustomerDetails: EcommerceCustomerInterface;
  responseModalData: ResponseModalDataInterface;
  customerInfoPayload: CustomerInfoPayloadInterface;
  conversationData: any; // tobe refactored
  isRightBarOpenedForDatalab: boolean;
  isFetchCustomerDatalabEntriesLoading: boolean;
  sendCheckoutLink: (data: string) => void;
  setOpenResponseModal: (data: boolean) => void;
  handleRightbarAppearence: (value: boolean) => void;
  setShowResponseModalLoader: (data: boolean) => void;
  setResponseModalData: (data: ResponseModalDataInterface) => void;
  updateCustomerInformation: (
    payload: CustomerInfoPayloadInterface
  ) => Promise<boolean>;
  updateCustomerAttribute: (
    payload: CustomerAttributePayloadInterface
  ) => boolean;
  projectAttributes: string[];
  setShopifyCheckoutLink: (payload: string) => void;
  setShopifyOrderName: (payload: string) => void;
  setOpenShopifySuccessModal: (payload: boolean) => void;
  setShowRightbar: (payload: boolean) => void;
  disconnectEcommerceCustomer: (payload: {
    teamId: number;
    customerId: string | number;
  }) => boolean;
  updateCustomerInformationState: (payload: any) => void;
  updateEcommerceCustomerDetails: (
    payload: EcommerceCustomerInterface | null
  ) => void;
  fetchCustomerOrder: (
    payload:
      | {
          customerId: number;
          orderId: number;
        }
      | { customerId: number }
  ) => Promise<any>;
  updateInboxDatalabStateData: (key: string, value: any) => void;
  fetchCustomerDatalabEntries: (
    requestBody: ICustomerDatalabEntryPayload
  ) => void;
}

const RightBar: React.FC<Props> = ({
  customerInfo,
  showRightbar,
  datalabList,
  selectedTicket,
  selectedProject,
  openShopifyModal,
  conversationData,
  projectAttributes,
  responseModalData,
  openZidModal,
  openWooCommerceModal,
  datalabEntryFetchLimit,
  datalabEntryFetchOffset,
  datalabTotalEntry,
  customerDatalabEntries,
  ecommerceCustomerDetails,
  sendCheckoutLink,
  setOpenResponseModal,
  setResponseModalData,
  openSallaModal,
  handleRightbarAppearence,
  updateCustomerInformation,
  setShowResponseModalLoader,
  setShopifyCheckoutLink,
  setShopifyOrderName,
  setOpenShopifySuccessModal,
  setShowRightbar,
  fetchCustomerOrder,
  disconnectEcommerceCustomer,
  updateCustomerInformationState,
  updateEcommerceCustomerDetails,
  isRightBarOpenedForDatalab,
  updateInboxDatalabStateData,
  fetchCustomerDatalabEntries,
  isFetchCustomerDatalabEntriesLoading,
}) => {
  const { t } = useTranslation();
  const [showECustomreConnection, setShowECustomreConnection] =
    React.useState<boolean>(false);
  const [shouldShowDisconnectModal, setShouldShowDisconnectModal] =
    React.useState(false);

  const getDisconnectModal = () => {
    return (
      <NewAlertModal
        intent='danger'
        title={t('Disconnect Customer?')}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={t('Disconnect')}
        isShown={shouldShowDisconnectModal}
        onCancelComplete={() => setShouldShowDisconnectModal(false)}
        onConfirm={() => {
          handleCustomerDisconnect();
          setShouldShowDisconnectModal(false);
        }}
        description={t(
          `You're about to disconnect {{${
            "<span class='text-gray-500 text-sm font-semibold'>" +
            (ecommerceCustomerDetails?.full_name || 'Ecommerce Customer') +
            '</span>'
          }}} from {{${
            "<span class='text-gray-500 text-sm font-semibold'>" +
            (customerInfo?.full_name || 'Myalice Customer') +
            '</span>'
          }}}. Do you want to continue?`
        )}
      />
    );
  };

  const handleCustomerDisconnect = async () => {
    let response = await disconnectEcommerceCustomer({
      teamId: selectedProject?.id,
      customerId: selectedTicket?.customer_id,
    });
    if (response) {
      await updateCustomerInformationState({
        ...customerInfo,
        is_linked_with_ecommerce_account: false,
      });

      await fetchCustomerOrder({
        customerId: selectedTicket.customer_id,
      });
      updateEcommerceCustomerDetails(null);
    }
  };

  const handleCustomerConnection = async (eventName: string) => {
    if (eventName === 'connect')
      setShowECustomreConnection(!showECustomreConnection);
    else {
      setShouldShowDisconnectModal(true);
    }
  };

  React.useEffect(() => {
    setShowECustomreConnection(false);
    // eslint-disable-next-line
  }, [selectedTicket?.id]);

  return (
    <div className='relative flex h-full flex-column'>
      <ProfileCard
        customerInfo={customerInfo}
        showRightbar={showRightbar}
        selectedTicket={selectedTicket}
        handleRightbarAppearence={handleRightbarAppearence}
      />
      <div className='h-[92vh] overflow-y-auto'>
        {/* SlaCard is currently commented out as it has not been finalized by the
        product team. We will uncomment it once it is ready from their side. */}
        {/* <div className='px-3 py-1.5'>
          <SlaCard />
        </div> */}
        <div className='px-3 py-1.5'>
          <CustomerInformation
            customerInfo={customerInfo}
            conversationData={conversationData}
            selectedTicket={selectedTicket}
            updateCustomerInformation={updateCustomerInformation}
          />
        </div>
        <div className='px-3 py-1.5'>
          <CustomerAttribute
            customerInfo={customerInfo}
            selectedTicket={selectedTicket}
            conversationData={conversationData}
            projectAttributes={projectAttributes}
            updateCustomerAttribute={updateCustomerInformation}
          />
        </div>
        <FeatureFlag allowedEnvironment={['dev']}>
          <div className='px-3 py-1.5'>
            <CustomerTicketHistory limit={5} />
          </div>
        </FeatureFlag>

        <div className='px-3 py-1.5'>
          <EcommerceOrderHistory
            customerInfo={customerInfo}
            handleCustomerConnection={(eventName: string) =>
              handleCustomerConnection(eventName)
            }
          />
        </div>

        <div className='px-3 py-1.5 pb-4'>
          <CustomerDatalabEntries
            datalabList={datalabList}
            limit={datalabEntryFetchLimit}
            totalEntry={datalabTotalEntry}
            offset={datalabEntryFetchOffset}
            customerId={selectedTicket?.customer_id}
            customerDatalabEntries={customerDatalabEntries}
            updateInboxDatalabStateData={updateInboxDatalabStateData}
            fetchCustomerDatalabEntries={fetchCustomerDatalabEntries}
            isFetchCustomerDatalabEntriesLoading={
              isFetchCustomerDatalabEntriesLoading
            }
          />
        </div>
      </div>
      {showECustomreConnection && (
        <ECustomerConnection
          customerInfo={customerInfo}
          closeCustomerConnection={setShowECustomreConnection}
        />
      )}
      {getDisconnectModal()}

      {openShopifyModal && (
        <CreateShopifyOrder
          setShopifyCheckoutLink={setShopifyCheckoutLink}
          setShopifyOrderName={setShopifyOrderName}
          setOpenShopifySuccessModal={setOpenShopifySuccessModal}
          setShowRightbar={setShowRightbar}
        />
      )}
      {openWooCommerceModal && (
        <CreateWooCommerceOrder
          selectedTicket={selectedTicket}
          responseModalData={responseModalData}
          sendCheckoutLink={sendCheckoutLink}
          setOpenResponseModal={setOpenResponseModal}
          setResponseModalData={setResponseModalData}
          setShowResponseModalLoader={setShowResponseModalLoader}
          setShowRightbar={handleRightbarAppearence}
        />
      )}
      {openSallaModal && (
        <SallaOrderCreation
          responseModalData={responseModalData}
          sendCheckoutLink={sendCheckoutLink}
          setOpenResponseModal={setOpenResponseModal}
          setResponseModalData={setResponseModalData}
          setShowResponseModalLoader={setShowResponseModalLoader}
          setShowRightbar={handleRightbarAppearence}
        />
      )}
      {openZidModal && (
        <ZidOrderCreation
          responseModalData={responseModalData}
          sendCheckoutLink={sendCheckoutLink}
          setOpenResponseModal={setOpenResponseModal}
          setResponseModalData={setResponseModalData}
          setShowResponseModalLoader={setShowResponseModalLoader}
          setShowRightbar={handleRightbarAppearence}
        />
      )}
      {isRightBarOpenedForDatalab && (
        <DatalabFieldIndex
          handleRightbarAppearance={handleRightbarAppearence}
        />
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  datalabEntryFetchLimit: state.inboxDatalab.limit,
  datalabEntryFetchOffset: state.inboxDatalab.offset,
  datalabTotalEntry: state.inboxDatalab.totalEntry,
  datalabList: state.inboxDatalab.datalabList,
  selectedProject: state.dashboard.selectedProject,
  openWooCommerceModal: state.ecommerce.openWooCommerceModal,
  openSallaModal: state.ecommerce.openSallaModal,
  openZidModal: state.ecommerce.openZidModal,
  customerInfo: state.inbox.customerInformation,
  customerAttribute: state.inbox.customerAttribute,
  projectAttributes: state.inbox.projectAttributes,
  openShopifyModal: state.ecommerce.openShopifyModal,
  ecommerceCustomerDetails: state.ecommerce.ecommerceCustomerDetails,
  conversationData: state.inbox.conversationData,
  customerDatalabEntries: state.inboxDatalab.customerDatalabEntries,
  isRightBarOpenedForDatalab: state.inboxDatalab.isRightBarOpenedForDatalab,
  isFetchCustomerDatalabEntriesLoading:
    state.loading.effects.inboxDatalab.fetchCustomerDatalabEntries,
});

const mapDispatch = (dispatch: any) => ({
  updateCustomerInformation: (payload: CustomerInfoPayloadInterface) =>
    dispatch.inbox.updateCustomerInformation(payload),
  updateCustomerInformationState: (payload: any) =>
    dispatch.inbox.updateCustomerInformationState(payload),
  updateCustomerAttribute: (payload: CustomerAttributePayloadInterface) =>
    dispatch.inbox.updateCustomerAttribute(payload),
  disconnectEcommerceCustomer: (payload: {
    teamId: number;
    customerId: string | number;
  }) => dispatch.ecommerce.disconnectEcommerceCustomer(payload),
  updateEcommerceCustomerDetails: (
    payload: EcommerceCustomerInterface | null
  ) => dispatch.ecommerce.updateEcommerceCustomerDetails(payload),
  fetchCustomerOrder: (
    payload: { customerId: number; orderId: number } | { customerId: number }
  ) => dispatch.ecommerce.fetchCustomerOrder(payload),
  updateInboxDatalabStateData: (key: string, value: any) =>
    dispatch.inboxDatalab.updateInboxDatalabStateData({ key, value }),
  fetchCustomerDatalabEntries: (requestBody: ICustomerDatalabEntryPayload) =>
    dispatch.inboxDatalab.fetchCustomerDatalabEntries(requestBody),
});

export default connect(mapState, mapDispatch)(RightBar);
