import React from 'react';
import Introduction from './Introduction';
import BetterDocsConnector from '../../common/pages/BetterDocsSettingsPage';
import { connect } from 'react-redux';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { navigate } from '@reach/router';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;
  handleClose: () => void;
  createBetterDocs: (payload: {
    teamId: number;
    data: { type: string; betterdocs_url: string; name: string };
  }) => Promise<integratedOthersList>;
}

const BetterDocsSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  createBetterDocs,
}) => {
  //states
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [betterdocsUrl, setBetterdocsUrl] = React.useState('');
  const [betterdocsName, setBetterDocsName] = React.useState('');

  const betterDocsPayloadData = {
    type: 'betterdocs',
    betterdocs_url: betterdocsUrl.includes('https://')
      ? betterdocsUrl
      : 'https://' + betterdocsUrl,
    name: betterdocsName,
  };

  const clearState = () => {
    setStep(1);
    setLoading(false);
    setErrorMessage('');
    setBetterdocsUrl('');
    setBetterDocsName('');
  };

  const handleBetterDocsCreation = async () => {
    if (
      betterDocsPayloadData.betterdocs_url !== '' &&
      betterDocsPayloadData.name !== ''
    ) {
      const data: any = await createBetterDocs({
        teamId: selectedProject.id,
        data: betterDocsPayloadData,
      });

      if (data.success) {
        setStep(3);
        setLoading(false);
        !!callbackUrl
          ? navigate(callbackUrl)
          : navigate(`/integrations/integrated`);
        setErrorMessage('');
      } else {
        setStep(2);
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          setErrorMessage(
            'Something went wrong while connecting betterdocs. Try again later'
          );
        }
      }
    }
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        setLoading(true);
        setTimeout(() => {
          handleBetterDocsCreation();
        }, 500);
        break;
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        setErrorMessage('');
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    const mandatoryKeys = [betterdocsName, betterdocsUrl];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(betterdocsName && betterdocsUrl)) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return checkFinishButton();
      case 3:
        return true;
      default:
        return false;
    }
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      default:
        return {
          title: 'BetterDocs',
        };
      case 2:
        return {
          title: 'BetterDocs Settings',
        };
    }
  };

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
          clearState();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={getHeaderInfo(step).title}
        confirmText={step === 2 ? 'Finish' : 'Next'}
        cancelText={'Back'}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hasLeftActionElement={true}
        hideLeftActionElement={step !== 4}
        hideCancel={step === 1}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <BetterDocsConnector
            setBetterdocsUrl={setBetterdocsUrl}
            setBetterDocsName={setBetterDocsName}
            errorMessage={errorMessage}
            betterdocsName={betterdocsName}
            betterdocsUrl={betterdocsUrl}
          />
        )}
        {step === 3 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>connecting Betterdocs...</span>
              </div>
            ) : null}
          </>
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createBetterDocs: (payload: {
    teamId: number;
    data: { type: string; betterdocs_url: string; name: string };
  }) => dispatch.integration.createBetterDocsIntegration(payload),
});

export default connect(mapState, mapDispatch)(BetterDocsSideSheet);
