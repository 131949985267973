import React from 'react';

import Avatar from './Avatar';
import CollapseButton from './CollapseButton';
import TextWithTooltip from 'library/text';
import {
  CustomerInfoInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';

interface Props {
  showRightbar: boolean;
  customerInfo: CustomerInfoInterface;
  selectedTicket: TicketInterface;
  handleRightbarAppearence: (value: boolean) => void;
}

const ProfileCard: React.FC<Props> = ({
  showRightbar,
  customerInfo,
  selectedTicket,
  handleRightbarAppearence,
}) => {
  return (
    <div className='flex items-center rtl:mr-2 w-auto h-[8vh] border-b'>
      <Avatar avatar={customerInfo?.avatar ?? ''} /> {/* to be refactored */}
      <div className='flex-initial ml-2 w-[60%]'>
        <div className='text-sm font-semibold text-[#374151] '>
          {customerInfo?.full_name === '' ? (
            'Unknown User'
          ) : customerInfo?.full_name.length < 14 ? (
            customerInfo?.full_name
          ) : (
            <TextWithTooltip
              dataFor='right-customer-name'
              dataTip={customerInfo?.full_name}
              toolTipPosition='bottom'
              text={customerInfo?.full_name}
              className='text-sm font-semibold text-[#374151]'
            />
          )}
        </div>
        <TextWithTooltip
          dataFor='right-customer-platfoem-title'
          dataTip={selectedTicket?.customer_platform_title}
          toolTipPosition='bottom'
          text={selectedTicket?.customer_platform_title}
          className='text-xs font-normal text-[#0078CF] mr-2'
        />
      </div>
      <div id='collapse-button' className='w-[20%] flex justify-end'>
        <CollapseButton
          handleRightbarAppearence={handleRightbarAppearence}
          showRightbar={showRightbar}
        />
      </div>
    </div>
  );
};

export default ProfileCard;
