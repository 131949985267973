'use client';
import { ColumnDef, Row } from '@tanstack/react-table';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import { Badge } from 'libraryV2/ui/badge';
import { Checkbox } from 'libraryV2/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipProviderCustomised,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import moment from 'moment';
import { truncateText } from 'utilities/utils';
import { formatEventName } from '../../utils';
import { DatalabType, IWebhookData, PlatformType } from '../webhookModal';
import { DataTableColumnHeader } from './DataTableColumnHeader';
import { DataTableRowActions } from './DataTableRowActions';

interface IWebhookColumnData {
  id: string;
  event: string;
  filter_resource_type: 'platform' | 'datalab';
  sources: PlatformType[] | DatalabType[];
  platforms: PlatformType[];
  datalabs: DatalabType[];
  endpoint: string;
  last_used_at: string;
}

export const columns: ColumnDef<IWebhookColumnData>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
        className={cn(
          'translate-y-[2px]',
          (table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) &&
            'bg-primary border-primary text-white'
        )}
        IsSomePageRowsSelected={
          table.getIsSomePageRowsSelected() || table.getIsAllPageRowsSelected()
        }
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
        className={cn(
          'translate-y-[2px] data-[state=checked]:bg-green-500',
          row.getIsSelected() && 'bg-primary border-primary text-white'
        )}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: 'event',
    header: ({ column }) => (
      <DataTableColumnHeader
        className='text-zinc-500 font-medium'
        column={column}
        title='Webhook Type'
      />
    ),
    cell: ({ row }) => {
      const title: string = formatEventName(row.getValue('event')) ?? '';
      return (
        <TooltipProviderCustomised content={title.length > 30 && title}>
          <div className='w-[150px] font-medium text-zinc-900'>
            <TranslationWrapper text={truncateText(title, 30)} />
          </div>
        </TooltipProviderCustomised>
      );
    },
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, columnId, filterValue) => {
      const formattedEvent = formatEventName(row.getValue(columnId));
      return formattedEvent.toLowerCase().includes(filterValue.toLowerCase());
    },
  },
  {
    accessorKey: 'sources',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Sources'
      />
    ),
    cell: ({ row }) => {
      const resourceType = row.original.filter_resource_type;
      const items =
        resourceType === 'platform'
          ? row.original.platforms
          : row.original.datalabs;

      if (!items?.length) return null;

      const firstItem = items[0];
      const remainingCount = items.length - 1;

      return (
        <div className='flex gap-2 w-[250px] items-center'>
          <TooltipProviderCustomised
            content={firstItem.title.length > 25 && firstItem.title}
            cursorType='default'
          >
            <Badge className='bg-[#F4F4F5] hover:bg-[#F4F4F5] text-xs py-0.5'>
              {truncateText(firstItem.title, 25)}
            </Badge>
          </TooltipProviderCustomised>

          {remainingCount > 0 && (
            <TooltipProviderCustomised
              content={
                <div className='flex flex-col gap-1 max-h-40 overflow-y-auto'>
                  {items.slice(1).map((item) => (
                    <span key={item.id} className=''>
                      {item.title}
                    </span>
                  ))}
                </div>
              }
              cursorType='default'
            >
              <Badge className='bg-[#F4F4F5] hover:bg-[#F4F4F5] text-xs py-0.5'>
                +{remainingCount}
              </Badge>
            </TooltipProviderCustomised>
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: 'endpoint',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Webhook URL'
      />
    ),
    cell: ({ row }) => {
      const endpoint: string = row.getValue('endpoint');
      return (
        <div className='flex'>
          {endpoint.length > 30 ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <a
                    href={endpoint}
                    className='max-w-[400px] text-blue-500 underline text-sm truncate font-normal'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {endpoint.slice(0, 30)}...
                  </a>
                </TooltipTrigger>
                <TooltipContent className='max-w-[250px] whitespace-normal break-words w-full'>
                  {endpoint}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <span className='max-w-[400px] text-sm font-normal'>
              <a
                href={endpoint}
                target='_blank'
                rel='noopener noreferrer'
                className='max-w-[400px] text-blue-500 underline text-sm truncate font-normal'
              >
                {endpoint}
              </a>
            </span>
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'last_used_at',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Last used at'
      />
    ),
    cell: ({ row }) => {
      const timestamp: string = row.getValue('last_used_at') ?? null;
      const formattedDate = timestamp
        ? moment.unix(Number(timestamp)).format('MMM D, YYYY')
        : 'N/A';

      return (
        <div className='flex'>
          <span className='max-w-[400px] text-sm truncate font-normal'>
            {formattedDate}
          </span>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'actions',
    cell: ({ row }) => (
      <DataTableRowActions row={row as unknown as Row<IWebhookData>} />
    ),
  },
];
