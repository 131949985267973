import React, { Fragment } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import DataLabList from './DataLabList';

interface Props {
  id: number;
  selectedLab?: { id: number; title: string };
  setSelectedLab: (lab: any) => void;
  hasWriteAccess: boolean;
  labsData: labsDataProps[];
  handleSelectedLabData: (labId: number, blockId: number) => void;
}

interface labsDataProps {
  id: number;
  title: string;
  type: string;
}

const DataLabBlockDropDown: React.FC<Props> = ({
  id,
  hasWriteAccess,
  selectedLab,
  setSelectedLab,
  labsData,
  handleSelectedLabData,
}) => {
  return (
    <Listbox
      disabled={!hasWriteAccess}
      value={selectedLab}
      onChange={(option: any) => {
        setSelectedLab(option);
        handleSelectedLabData(option?.id, id);
      }}
    >
      {({ open }) => (
        <>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full h-10 py-2 pl-3 pr-10 text-left text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm cursor-default cursor-pointer focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm'>
              <span className='block truncate'>
                {selectedLab?.title
                  ? selectedLab.title
                  : 'Select Datalab Sheet'}
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {labsData.map((val: any) => (
                  <DataLabList id={val.id} value={val} title={val.title} />
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

DataLabBlockDropDown.defaultProps = {
  hasWriteAccess: false,
};
export default DataLabBlockDropDown;
