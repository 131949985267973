import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { useToast } from 'libraryV2/ui/use-toast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SallaChannelDefaultData } from '../utils/content';
import { channelCreateAPiResponseType } from '../interface';

const useZidConnection = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [updatingStoreInfo, setUpdatingStoreInfo] = useState(false);
  const dispatch = useDispatch();
  const teamList = useSelector(
    (state: any) => state?.dashboard?.projects ?? []
  );

  const liveMessengerPlatformCreation = async (
    selectedTeam: SelectedTeamInterface | undefined
  ) => {
    let payloadData = { ...SallaChannelDefaultData, title: 'Zid Livechat' };

    let res: channelCreateAPiResponseType =
      await dispatch.integration.createChannelIntegrations({
        channelType: 'livechat_messenger',
        teamId: selectedTeam?.id,
        data: payloadData,
        hideToaster: true,
      });
    if (res.status === 200) {
      toast({
        title: t(
          `Channel ${res.data.dataSource?.title} successfully Connected!`
        ),
      });
      return { success: true, data: res.data.dataSource };
    } else {
      toast({
        title: t('Something went wrong. Please try again!'),
        variant: 'destructive',
      });
      return { success: false, data: null };
    }
  };

  const setZidStoreInformation = async (
    selectedTeam: SelectedTeamInterface | undefined,
    storeId: string | null,
    store_url: string | null
  ) => {
    if (!selectedTeam) {
      toast({
        title: 'Zid Connection',
        description: 'Please select a team.',
        variant: 'destructive',
      });
      return false;
    }

    if (!storeId) return false;

    setUpdatingStoreInfo(true);
    const platformCreationResponse = await liveMessengerPlatformCreation(
      selectedTeam
    );
    if (!platformCreationResponse?.success || !platformCreationResponse?.data) {
      setUpdatingStoreInfo(false);
      return;
    }
    const { primary_id, id: platform_id } = platformCreationResponse?.data;
    try {
      const response = await dispatch.integration.fetchTeamInfo(
        selectedTeam.id
      );

      if (!response?.success) {
        toast({
          title: `🚫 ${response?.msg}`,
          variant: 'default',
        });
        setUpdatingStoreInfo(false);
        return false;
      }

      const { api_key } = response.data;
      const zidConnectRes = await dispatch.integration.zidConnection({
        api_key,
        store_url,
        primary_id,
        platform_id,
        store_id: storeId,
      });

      if (!zidConnectRes?.success) {
        toast({
          title: `🚫 ${zidConnectRes?.msg}`,
          variant: 'default',
        });
        setUpdatingStoreInfo(false);
        return false;
      }
      setUpdatingStoreInfo(false);
      return true;
    } catch (error) {
      console.error('Error connecting to Zid:', error);
      toast({
        title: 'Zid Connection Error',
        description: 'An unexpected error occurred. Please try again later.',
        variant: 'destructive',
      });
      setUpdatingStoreInfo(false);
      return false;
    }
  };

  return { teamList, updatingStoreInfo, setZidStoreInformation };
};

export default useZidConnection;
