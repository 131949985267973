import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '../../../../utilities/utils';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface Props {
  id: number;
  value: string;
  attributeList: string[];
  handleAttributeInput: (attributeName: string) => void;
  handleAttributeOnSelect: (attributeName: string, index: number) => void;
  handleRemoveAttributeTag: (attributeName: string, index: number) => void;
}

const AttributeNameField: React.FC<Props> = ({
  id,
  value,
  attributeList,
  handleAttributeInput,
  handleAttributeOnSelect,
  handleRemoveAttributeTag,
}) => {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const getInputTag = () => {
    if (value.length !== 0) {
      return (
        <span className='absolute my-2 inset-y-0 left-2 pr-3 flex inline-flex items-center py-0.5 pl-2 pr-0.5 rounded text-xs font-medium bg-green-100 text-green-800'>
          {value?.length > 30 ? value.substring(0, 30) + '...' : value}
          <button
            type='button'
            className='flex-shrink-0 ml-0.5 h-4 w-4 inline-flex items-center justify-center text-indigo-400 hover:bg-transparent hover:text-gary-700 focus:outline-none focus:bg-transparent focus:text-white'
            onClick={() => {
              handleRemoveAttributeTag(value, id);
              setInputValue('');
            }}
          >
            <svg
              className='w-2 h-2'
              stroke='#34D399'
              fill='#34D399'
              viewBox='0 0 8 8'
            >
              <path
                strokeLinecap='round'
                strokeWidth='1.5'
                d='M1 1l6 6m0-6L1 7'
              />
            </svg>
          </button>
        </span>
      );
    } else return '';
  };

  const getListBoxOption = () => {
    return (
      <Listbox.Options
        static
        className='absolute z-10 w-full mt-3 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
      >
        {attributeList?.map((val: any, index: number) => (
          <div key={index}>
            <Listbox.Option
              key={index}
              className={({ active }) =>
                classNames(
                  active ? 'bg-gray-200' : 'text-gray-900',
                  'cursor-default select-none relative py-3 pl-3 pr-9'
                )
              }
              value={val}
            >
              {({ selected, active }) => (
                <span
                  className={classNames(
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate'
                  )}
                >
                  {val}
                </span>
              )}
            </Listbox.Option>
          </div>
        ))}
      </Listbox.Options>
    );
  };

  return (
    <Listbox
      value={attributeList}
      onChange={(attribute: any) => {
        handleAttributeOnSelect(attribute, id);
        setInputValue('');
        setShow(!show);
      }}
    >
      <div className='relative w-2/3'>
        <input
          type='text'
          disabled={value.length !== 0}
          onClick={() => setShow(!show)}
          onChange={(event) => {
            handleAttributeInput(event.target.value);
            setInputValue(event.target.value);
          }}
          className='block w-full pr-12 border-gray-300 rounded-md focus:ring-primary focus:border-primary pl-7 sm:text-sm'
          placeholder={value.length === 0 ? 'Set an attribute' : ''}
          autoComplete={'off'}
          value={inputValue}
        />
        {getInputTag()}
        <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
          <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
        </div>
        <Transition
          show={show}
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          {getListBoxOption()}
        </Transition>
      </div>
    </Listbox>
  );
};

export default AttributeNameField;
