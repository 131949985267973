import { useState, useRef, useCallback } from 'react';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { CheckCheck, Clipboard } from 'lucide-react';

interface OptionRendererProps {
  options: string[];
}

const OptionRenderer: React.FC<OptionRendererProps> = ({ options }) => {
  const [tooltipId, setTooltipId] = useState<number | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const isOverflowing = useCallback(
    (index: number) => {
      const optionRef = optionRefs.current[index];
      if (!optionRef) return false;
      return optionRef.scrollWidth > optionRef.clientWidth;
    },
    [optionRefs]
  );

  const copyToClipboard = (text: string, index: number) => {
    navigator.clipboard.writeText(`{{${text}}}`);
    setTooltipId(index);

    setTimeout(() => setTooltipId(null), 2000);
  };

  return (
    <div className='space-y-4 w-full'>
      {options.map((option, index) => (
        <div
          key={index}
          className='flex items-center space-x-2 w-full mr-2'
          ref={(el) => (optionRefs.current[index] = el)} // Use functional updates
        >
          {/* Option Text */}
          <TooltipProviderCustomised
            content={isOverflowing(index) ? `{{${option}}}` : ''}
          >
            <span className='text-indigo-600 whitespace-nowrap overflow-hidden text-ellipsis max-w-[80%]'>
              &#123;&#123;{option}&#125;&#125;
            </span>
          </TooltipProviderCustomised>

          {/* Copy Button */}
          <button
            className='text-gray-500 hover:text-gray-700 focus:outline-none'
            onClick={() => copyToClipboard(option, index)}
          >
            {tooltipId === index ? (
              <CheckCheck className='w-4 h-4' />
            ) : (
              <Clipboard className='w-4 h-4' />
            )}
          </button>
        </div>
      ))}
    </div>
  );
};

export default OptionRenderer;
