import React, { useEffect, useState } from 'react';
import '../../assets/accordion.scss';
import { ArrowDownIcon } from '../../assets/img/icons/svgIcons';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  title: string;
  subTitle?: string;
  open: boolean;
  handleOnToggle: (isOpen: boolean) => void;
}
const Accordion: React.FC<Props> = ({
  title,
  subTitle = '',
  open,
  children,
  handleOnToggle,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  useEffect(() => {
    setOpen(open);
  }, [open]);
  return (
    <>
      <div className='collapse-alice'>
        <details
          className='bg-white duration-300 rounded-lg border'
          open={isOpen}
        >
          <summary
            className='flex cursor-pointer rounded-lg  shadow-sm '
            onClick={(event) => {
              handleOnToggle(!isOpen);
            }}
          >
            <div className='w-full'>
              <span className='text-sm flex font-medium font-sans text-gray-600 '>
                {t(title)}
              </span>
              <span className='text-sm flex font-normal font-sans text-gray-500 subtitle'>
                {t(subTitle)}
              </span>
            </div>
            <span className='relative flex items-center'>
              <ArrowDownIcon
                width={'20'}
                height={'20'}
                storkeColor={'#6B7280'}
              />
            </span>
          </summary>
          <div className='bg-white py-2   text-sm font-light rounded-b-md'>
            {children}
          </div>
        </details>
      </div>
    </>
  );
};

export default Accordion;
