import React from 'react';
import NlpCard from './components/NlpCard';
import EditWitSideSheet from './wit/EditWitSideSheet';
import ConfirmationModal from 'library/modal/ConfirmationModal';
import EditIntntSideSheet from './intnt/EditIntntSideSheet';
import BrainFromAliceSideSheet from './brainFromAlice/brainFromAliceSideSheet';

import { nlpInfoProps } from '../../interface';
import { connect } from 'react-redux';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  integratedNlpData: nlpInfoProps[];
  selectedProject: { id: number };
  deleteNlpIntegration: (teamId: number, integrationId: string) => boolean;
  getSingleNlpData: (
    teamId: number,
    integrationId: string
  ) => Promise<nlpInfoProps>;
}

const NlpIndex: React.FC<Props> = ({
  integratedNlpData,
  deleteNlpIntegration,
  getSingleNlpData,
  selectedProject,
}) => {
  const { t } = useTranslation();
  const [providerType, setProviderType] = React.useState('');
  const [selectedChannel, setSelectedChannel] = React.useState<any>(null);
  const [showModal, setShowModal] = React.useState<null | number>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hasConfirmatioModalError, setHasConfirmatioModalError] =
    React.useState<boolean>(false);

  const setInitialState = () => {
    setLoading(false);
    setShowModal(null);
    setSelectedChannel(null);
    setHasConfirmatioModalError(false);
    setProviderType('');
  };

  const handleChannelEditOnClick = async (integrationId: string) => {
    let channelData = await getSingleNlpData(selectedProject.id, integrationId);
    setSelectedChannel(channelData);
    setProviderType(channelData.provider);
  };

  const handleModalActionButtonClick = async (
    projectId: number,
    nlpId: string,
    inputValue: string | undefined
  ) => {
    setLoading(true);
    if (inputValue?.toUpperCase() !== 'REMOVE') {
      setHasConfirmatioModalError(true);
      setLoading(false);
      return false;
    }

    let res = true;
    res = deleteNlpIntegration(projectId, nlpId);
    if (res) setInitialState();
    return res;
  };

  return (
    <>
      {integratedNlpData.map((data: nlpInfoProps, index: number) => (
        <div key={index}>
          <NlpCard
            elementData={data}
            setShowModal={() => setShowModal(index)}
            handleChannelEdit={() => handleChannelEditOnClick(data.id)}
          />
          <ConfirmationModal
            open={index === showModal}
            intent={'danger'}
            title={t(`Remove {{${data.name}}}`)}
            confirmText={'REMOVE'}
            description={t(
              `Removing {{${data.name}}} will remove the connections with this app and any of your channels. This will impact the automation rules if there is anything linked to the app.`
            )}
            isConfirmButtonLoading={loading}
            hasConfirmation={true}
            hasError={hasConfirmatioModalError}
            actionText={'Remove'}
            handleClose={() => setInitialState()}
            handleCancel={() => setInitialState()}
            handleAction={(inputValue: string | undefined) =>
              handleModalActionButtonClick(
                selectedProject.id,
                data.id,
                inputValue
              )
            }
          />
        </div>
      ))}
      <EditWitSideSheet
        isOpen={providerType === 'wit.ai'}
        data={selectedChannel}
        handleClose={() => setProviderType('')}
      />
      <BrainFromAliceSideSheet
        isOpen={providerType === 'myalice.ai'}
        data={selectedChannel}
        handleClose={() => setProviderType('')}
      />
      <EditIntntSideSheet
        isOpen={providerType === 'intnt.ai'}
        data={selectedChannel}
        handleClose={() => setProviderType('')}
      />
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  getSingleNlpData: (teamId: number, integrationId: string) =>
    dispatch.integration.getSingleNlpData({
      teamId,
      integrationId,
    }),
  deleteNlpIntegration: (teamId: number, integrationId: string) =>
    dispatch.integration.deleteNlpIntegration({
      teamId,
      integrationId,
    }),
});

export default connect(mapState, mapDispatch)(NlpIndex);
