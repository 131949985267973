import React from 'react';
import Footer from '../../common/components/Footer';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'ApplicationSideSheet/node/mobile-app-plugin'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          {t(
            `Integrating Mobile App with {{${partnerName}}} will allow you to:`
          )}
        </p>
        <ul className='mt-3 ltr:ml-6 rtl:mr-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>{t('Add a chat widget in your mobile app.')}</li>
          <li className='my-2'>
            {t('Engage your app users with live conversations.')}
          </li>
          <li className='my-2'>
            {t('Push custom notifications on different app interfaces.')}
          </li>
          <li className='my-2'>
            {t('Collect app feedback from users automatically.')}
          </li>
          <li className='my-2'>
            {t(
              'Use chatbots to create custom user journeys and automate repetitive queries.'
            )}
          </li>
          <li className='my-2'>
            {t(
              'Manage messages, comments and e-commerce orders from other platforms in the same inbox'
            )}
          </li>
        </ul>
      </div>
      <Footer channelName='Application' />
    </div>
  );
};
export default Introduction;
