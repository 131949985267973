import React from 'react';
import AttributeNameField from './AttributeNameField';
import AttributeValueField from './AttributeValueField';
import Button from '../../../../library/button';
import { TrashIcon } from '@heroicons/react/20/solid';

interface Props {
  id: number;
  attributeName: string;
  attributeValue: string;
  operation: string;
  updatedAttributeList: string[];
  handleAttributeInput: (attributeName: string) => void;
  handleAttributeOnSelect: (attributeName: string, index: number) => void;
  handleRemoveAttributeTag: (attributeName: string, index: number) => void;
  handleActionOnChange: (actionName: string, index: number) => void;
  handleValueOnChange: (value: string, index: number) => void;
  handleRemoveFiled: (index: number) => void;
}

const AttributeFields: React.FC<Props> = ({
  id,
  attributeName,
  attributeValue,
  operation,
  updatedAttributeList,
  handleAttributeOnSelect,
  handleAttributeInput,
  handleRemoveAttributeTag,
  handleActionOnChange,
  handleValueOnChange,
  handleRemoveFiled,
}) => {
  return (
    <div className='flex py-1.5' key={id}>
      <AttributeNameField
        id={id}
        value={attributeName}
        attributeList={updatedAttributeList}
        handleAttributeInput={handleAttributeInput}
        handleAttributeOnSelect={handleAttributeOnSelect}
        handleRemoveAttributeTag={handleRemoveAttributeTag}
      />
      <AttributeValueField
        id={id}
        value={attributeValue}
        operation={operation}
        handleActionOnChange={handleActionOnChange}
        handleValueOnChange={handleValueOnChange}
      />
      {!(id === 0) && (
        <Button
          icon={<TrashIcon className='text-gray-500' />}
          className='ml-3 h-10.5 text-gray-700 border-gray-300'
          onClick={() => handleRemoveFiled(id)}
        />
      )}
    </div>
  );
};

export default AttributeFields;
