import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react';
import ChannelCardMenuItems from './ChannelCardMenuItems';
import NlpCardMenuItems from './NlpCardMenuItems';
import OthersCardMenuItems from './OthersCardMenuItems';
interface Props {
  providerType: string; //providerType = 'Channel' | 'NLP' | 'E-COMMERCE' | 'Others'
  platformType: string;
  isConnected?: boolean;
  handleDisconnect?: () => void;
  handleChannelReconnect?: () => void;
  handleRemove: () => void;
  handleEdit: () => void;
  handleScriptCopy?: () => void;
  handleClone?: () => void;
  handleAccountInfo: () => void;
  handleManageTemplate?: () => void;
  handleTicketSettings?: () => void;
  isEcommerce?: boolean;
}

const ChannelEditActions: React.FC<Props> = ({
  providerType,
  platformType,
  isConnected = false,
  handleRemove,
  handleEdit,
  handleAccountInfo,
  handleDisconnect = () =>
    console.log('this function needed when providerType is Channel'),
  handleChannelReconnect = () =>
    console.log('this function needed when providerType is Channel'),
  handleScriptCopy = () =>
    console.log('this function needed when providerType is Channel'),
  handleClone = () => console.log('this function needed when we clone channel'),
  handleManageTemplate = () =>
    console.log(
      'this function needed when we manage templates for whatsapp bsp channel'
    ),
  handleTicketSettings,
  isEcommerce,
}) => {
  return (
    <>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex items-center px-2 text-sm font-medium text-center transition duration-200 ease-out bg-white border border-transparent rounded-md outline-none -top-1 hover:border-transparent focus:outline-none'>
            <EllipsisHorizontalIcon
              className='w-5 h-5 -mr-1'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute ltr:right-0 rtl:left-0 z-40 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            {
              {
                Channel: (
                  <ChannelCardMenuItems
                    isConnected={isConnected}
                    platformType={platformType}
                    handleDisconnect={handleDisconnect}
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                    handleScriptCopy={handleScriptCopy}
                    handleChannelReconnect={handleChannelReconnect}
                    handleClone={handleClone}
                    handleAccountInfo={handleAccountInfo}
                    handleManageTemplate={handleManageTemplate}
                    handleTicketSettings={
                      handleTicketSettings ? handleTicketSettings : () => {}
                    }
                    isEcommerce={isEcommerce || false}
                  />
                ),
                NLP: (
                  <NlpCardMenuItems
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                  />
                ),
                Others: (
                  <OthersCardMenuItems
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                  />
                ),
              }[providerType]
            }
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
export default ChannelEditActions;
