import React from 'react';
import UserInformationSection from './RightSideFragments/UserInformationSection';
import { connect } from 'react-redux';
import { Collapse } from '../../../../library';
import RightBarEComActionBar from './RightSideFragments/eCommerceFragments/RightBarEComActionBar';
import OrderHistoryRightbarContainer from './RightSideFragments/eCommerceOrderFragments/OrderHistoryRightbarContainer';
import CustomerSummaryCRM from './RightSideFragments/customerSummary/CustomerSummaryCRM';
import ProductInteraction from './RightSideFragments/ProductInteraction/ProductInteraction';

interface props {
  marketPlaceData: any;
  getEcommerceCustomerList: (teamId: number, search: string) => void;
  projectId: number;
  isCustomerLinkedWithEcommerceAccount: any;
  getEcommerceCustomerListLoader: boolean;
  customerList: any[];
  currentTicket: any;
  linkCustomerWithCRM: (
    teamId: number,
    customerId: number,
    ecommerceId: number
  ) => Promise<boolean>;
  unlinkCustomerWithCRM: (
    teamId: number,
    customerId: number
  ) => Promise<boolean>;
  linkLoader: boolean;
  eComOrderList: any[];
  eComOrderListLoader: boolean;
  fetchCRMCustomerOrder: (
    teamId: number,
    customerId: number,
    orderId: string
  ) => void;
  customerMeta: any;
  fetchProductInteraction: (customerId: number) => void;
  fetchCustomerOrderSummary: (customerId: number) => void;
  eComCustomerSummary: customerSummaryProps | null;
  boughtProduct: productProps[];
  cartProduct: productProps[];
  viewedProducts: productProps[];
  createOrderMinimized: boolean;
  currencySymbol: string;
  platformType: string;
}

interface productProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  unit_price: number;
  timestamp: number;
}

interface customerSummaryProps {
  average_value: number;
  frequency_days: number;
  lifetime_value: number;
  total_orders: number;
  total_tickets: number;
  total_visits: number;
}

const CRMRightSection: React.FC<props> = ({
  marketPlaceData,
  getEcommerceCustomerList,
  projectId,
  isCustomerLinkedWithEcommerceAccount,
  getEcommerceCustomerListLoader,
  customerList,
  currentTicket,
  linkCustomerWithCRM,
  unlinkCustomerWithCRM,
  linkLoader,
  eComOrderList,
  eComOrderListLoader,
  fetchCRMCustomerOrder,
  fetchCustomerOrderSummary,
  customerMeta,
  fetchProductInteraction,
  eComCustomerSummary,
  boughtProduct,
  cartProduct,
  viewedProducts,
  createOrderMinimized,
  currencySymbol,
  platformType,
}) => {
  return (
    <div className='right-side-info-bar'>
      {!!marketPlaceData?.is_verified && (
        <RightBarEComActionBar
          fetchEcommerceCustomerList={(search: string) =>
            getEcommerceCustomerList(projectId, search)
          }
          createOrderMinimized={createOrderMinimized}
          fetchEcommerceCustomerListLoader={getEcommerceCustomerListLoader}
          marketPlaceData={marketPlaceData}
          isCustomerLinkedWithEcommerceAccount={
            isCustomerLinkedWithEcommerceAccount
          }
          customerList={customerList}
          currentTicket={currentTicket}
          linkCustomerWithCRM={async (ecommerceId: number) => {
            return await linkCustomerWithCRM(
              projectId,
              currentTicket.customer.id,
              ecommerceId
            );
          }}
          unlinkCustomerWithCRM={async () => {
            return await unlinkCustomerWithCRM(
              projectId,
              currentTicket.customer.id
            );
          }}
          linkLoader={linkLoader}
          platformType={platformType}
        />
      )}
      <Collapse header='Customer Information' isOpen={true}>
        <UserInformationSection />
      </Collapse>
      <div className='mt-2' />
      {marketPlaceData.is_verified && (
        <Collapse header='Order History' isOpen={false}>
          <OrderHistoryRightbarContainer
            eComOrderList={eComOrderList}
            loader={eComOrderListLoader}
            fetchCRMCustomerOrder={(search: string) =>
              fetchCRMCustomerOrder(
                projectId,
                currentTicket.customer.id,
                search
              )
            }
            currencySymbol={currencySymbol}
          />
        </Collapse>
      )}
      <div className='mt-2' />
      {marketPlaceData.is_verified && (
        <Collapse header='Customer Summary' isOpen={false}>
          <CustomerSummaryCRM
            fetchCustomerOrderSummary={() =>
              fetchCustomerOrderSummary(currentTicket.customer.id)
            }
            eComCustomerSummary={eComCustomerSummary}
            currencySymbol={currencySymbol}
          />
        </Collapse>
      )}
      <div className='mt-2' />
      {marketPlaceData.is_verified && (
        <Collapse header='Product Interaction' isOpen={false}>
          <ProductInteraction
            fetchProductInteraction={() =>
              fetchProductInteraction(currentTicket.customer.id)
            }
            boughtProduct={boughtProduct}
            cartProduct={cartProduct}
            viewedProducts={viewedProducts}
            currencySymbol={currencySymbol}
          />
        </Collapse>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  marketPlaceData: state.marketplace.marketPlaceData,
  customerMeta: state.crm.customerMeta,
  projectId: state.dashboard?.selectedProject?.id || null,
  isCustomerLinkedWithEcommerceAccount:
    state.crm.customerMeta?.is_linked_with_ecommerce_account || null,
  getEcommerceCustomerListLoader:
    state.loading.effects.marketplace.getEcommerceCustomerList,
  customerList: state.marketplace.customerList,
  currentTicket: state.crm.currentTicket,
  linkLoader: state.loading.effects.marketplace.linkCustomerWithCRM,
  eComOrderList: state.crm.eComOrderList,
  eComOrderListLoader: state.loading.effects.crm.fetchCRMCustomerOrder,
  eComCustomerSummary: state.crm.eComCustomerSummary,
  boughtProduct: state.crm.boughtProduct,
  cartProduct: state.crm.cartProduct,
  viewedProducts: state.crm.viewedProducts,
  createOrderMinimized: state.crmEcommerce.isMinimized,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  platformType: state.crm.customerMeta?.platform?.type || '',
});

const mapDispatch = (dispatch: any) => ({
  getEcommerceCustomerList: (teamId: number, search: string) =>
    dispatch.marketplace.getEcommerceCustomerList({
      teamId,
      search,
    }),
  linkCustomerWithCRM: (
    teamId: number,
    customerId: number,
    ecommerceId: number
  ) =>
    dispatch.marketplace.linkCustomerWithCRM({
      teamId,
      customerId,
      ecommerceId,
    }),
  unlinkCustomerWithCRM: (teamId: number, customerId: number) =>
    dispatch.marketplace.unlinkCustomerWithCRM({
      teamId,
      customerId,
    }),
  fetchCRMCustomerOrder: (
    teamId: number,
    customerId: number,
    orderId: string
  ) =>
    dispatch.crm.fetchCRMCustomerOrder({
      teamId,
      customerId,
      orderId,
    }),
  fetchProductInteraction: (customerId: number) =>
    dispatch.crm.fetchProductInteraction({
      customerId,
    }),
  fetchCustomerOrderSummary: (customerId: number) =>
    dispatch.crm.fetchCustomerOrderSummary({
      customerId,
    }),
});

const CRMRightSectionContainer = connect(
  mapState,
  mapDispatch
)(CRMRightSection);
export default CRMRightSectionContainer;
