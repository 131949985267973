import { Base85Conversion } from 'components/jinja/utils/base85encoder';
import { UtilityFunctions } from '../export';

export const processArrayCondition = (dataElement: any[]) => {
  const dataElementArr: any[] = [];
  dataElement.forEach((singleElement) => {
    let formattedDataElement;

    switch (singleElement?.representation_type) {
      case 'two_column_comparator_single_choice':
      case 'two_column_comparator_single_choice_data':
        formattedDataElement = processSingleChoiceCondition(singleElement);
        break;
      case 'two_column_comparator_multi_choice':
        formattedDataElement = processMultiChoiceCondition(singleElement);
        break;
      case 'two_column_comparator_input_field':
      case 'two_column_comparator_string_input_field':
        formattedDataElement = processInputFieldCondition(singleElement);
        break;
      case 'two_column_comparator_multi_choice_api':
        formattedDataElement = processMultiChoiceApiCondition(singleElement);
        break;
      case 'two_column_comparator_single_choice_api':
        formattedDataElement = processSingleChoiceApiCondition(singleElement);
        break;
      // Add more cases as needed
      default:
        // Handle any other cases or provide a default behavior
        break;
    }

    if (formattedDataElement) {
      dataElementArr.push(formattedDataElement);
    }
  });

  return dataElementArr;
};

export const processSingleChoiceCondition = (condition: any) => {
  const sourceColumn = condition.col_1;
  const targetColumnArray = condition.col_2[0] || [];

  const targetColumnInputSchema =
    targetColumnArray?.definition?.input_schema || [];
  const targetColumnSchema = targetColumnInputSchema.find(
    (schema: any) => schema?.column === 1
  );

  // let targetColumnMarkTrueSchema =
  //   UtilityFunctions.findNextTrueMarkedObject(targetColumnSchema);
  // console.log('targetColumnSchema', targetColumnSchema);

  // Push sanitized node to the result array
  if (targetColumnSchema) {
    return {
      external_data_storage: {
        representation_type: condition?.representation_type,
        selectedComparator: condition?.selectedComparator,
        col_1: sourceColumn,
        col_2: [
          {
            label: condition?.col_2[0]?.label,
            definition: targetColumnArray?.definition,
            component_name: condition.name,
            component_code: targetColumnArray.select_Type,
            select_Type: targetColumnArray.select_Type,
            component_type: targetColumnArray?.component_type,
            component_icon:
              targetColumnArray?.component_icon === null
                ? ''
                : targetColumnArray?.component_icon,
          },
        ],
      },
      component_name: condition.name,
      component_code: targetColumnArray.select_Type,
      component_type: targetColumnArray?.component_type,
      component_icon:
        targetColumnArray?.component_icon === null
          ? ''
          : targetColumnArray?.component_icon,
      representation_type: condition?.representation_type,
      definition: {
        ...targetColumnArray?.definition,
        [targetColumnSchema?.entity]:
          // targetColumnMarkTrueSchema?.value_type === 'choice_string' &&
          sourceColumn?.compare_type ?? '',
      },
    };
  } else {
    return condition;
  }
};

// Function to process a multi-choice condition
const processMultiChoiceCondition = (condition: any) => {
  const sourceColumn = condition.col_1;
  const createMultiChoiceString = UtilityFunctions.createMultiChoiceStringArr(
    condition.col_2
  );

  const conditionInputSchema =
    condition?.component?.definition?.input_schema || [];
  const sourceColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 1
  );
  const targetColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 2
  );

  let sourceColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(sourceColumnSchema);
  let targetColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(targetColumnSchema);
  let currentObject =
    condition?.component?.definition[targetColumnSchema?.entity];
  UtilityFunctions.iterateUntilTrueMark(
    currentObject,
    targetColumnMarkTrueSchema,
    createMultiChoiceString
  );

  if (sourceColumnSchema && targetColumnSchema) {
    return {
      external_data_storage: {
        representation_type: condition?.representation_type,
        selectedComparator: condition?.selectedComparator,
        col_1: sourceColumn,
        col_2: condition?.col_2,
      },
      component_name: condition?.component?.component_name,
      component_code: condition?.component?.component_code,
      component_icon:
        condition?.component?.component_icon === null
          ? ''
          : condition?.component?.component_icon,
      component_type: 'comparator',
      representation_type: condition?.representation_type,
      definition: {
        ...condition?.component?.definition,
        [targetColumnSchema?.entity]: currentObject,
        [sourceColumnSchema?.entity]:
          sourceColumnMarkTrueSchema?.value_type === 'choice_string' &&
          sourceColumn?.compare_type,
      },
    };
  } else {
    return condition;
  }
};

// Function to process a multi-choice condition
const processSingleChoiceApiCondition = (condition: any) => {
  const sourceColumn = condition.col_1;
  const { value = '' } = condition.col_2[0];
  const createMultiChoiceString = value;

  const conditionInputSchema =
    condition?.component?.definition?.input_schema || [];
  const sourceColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 1
  );
  const targetColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 2
  );

  let sourceColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(sourceColumnSchema);
  let targetColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(targetColumnSchema);
  let currentObject =
    condition?.component?.definition[targetColumnSchema?.entity];
  UtilityFunctions.iterateUntilTrueMark(
    currentObject,
    targetColumnMarkTrueSchema,
    createMultiChoiceString
  );

  if (sourceColumnSchema && targetColumnSchema) {
    return {
      external_data_storage: {
        representation_type: condition?.representation_type,
        selectedComparator: condition?.selectedComparator,
        col_1: sourceColumn,
        col_2: condition?.col_2,
      },
      component_name: condition?.component?.component_name,
      component_code: condition?.component?.component_code,
      component_icon:
        condition?.component?.component_icon === null
          ? ''
          : condition?.component?.component_icon,
      component_type: 'comparator',
      representation_type: condition?.representation_type,
      definition: {
        ...condition?.component?.definition,
        [targetColumnSchema?.entity]: currentObject,
        [sourceColumnSchema?.entity]:
          sourceColumnMarkTrueSchema?.value_type === 'choice_string' &&
          sourceColumn?.compare_type,
      },
    };
  } else {
    return condition;
  }
};

const processInputFieldCondition = (condition: any) => {
  const sourceColumn = condition.col_1;
  // const createMultiChoiceString = createMultiChoiceStringArr(condition.col_2);

  const conditionInputSchema =
    condition.col_2[0]?.definition[0]?.definition?.input_schema || [];
  const sourceColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 1
  );
  const targetColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 2
  );

  let sourceColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(sourceColumnSchema);
  let targetColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(targetColumnSchema);
  let currentObject =
    condition.col_2[0]?.definition[0]?.definition[targetColumnSchema?.entity];

  UtilityFunctions.iterateUntilTrueMark(
    currentObject,
    targetColumnMarkTrueSchema,
    currentObject?.value_type === 'number'
      ? Number(condition.col_2[0]?.label)
      : condition.col_2[0]?.label
  );

  if (sourceColumnSchema && targetColumnSchema) {
    return {
      external_data_storage: {
        col_1: sourceColumn,
        col_2: [
          {
            label: condition.col_2[0].label,
            definition: condition.col_2[0]?.definition[0],
          },
        ],
        selectedComparator: condition?.selectedComparator,
        representation_type: condition?.representation_type,
      },
      component_name: condition?.name,
      component_code: condition.col_2[0]?.definition[0]?.component_code,
      component_type: condition.col_2[0]?.definition[0]?.component_type,
      representation_type: condition?.representation_type,
      component_icon:
        condition.col_2[0]?.definition[0]?.component_icon === null
          ? ''
          : condition.col_2[0]?.definition[0]?.component_icon,
      definition: {
        ...condition.col_2[0]?.definition[0]?.definition,
        [targetColumnSchema?.entity]: currentObject,
        [sourceColumnSchema?.entity]:
          sourceColumnMarkTrueSchema?.value_type === 'choice_string' &&
          sourceColumn?.compare_type,
      },
    };
  } else {
    return condition;
  }
};

// Function to process a multi-choice condition
const processMultiChoiceApiCondition = (condition: any) => {
  const sourceColumn = condition.col_1;
  const createMultiChoiceString = UtilityFunctions.createMultiChoiceStringArr(
    condition.col_2
  );

  const conditionInputSchema =
    condition?.component?.definition?.input_schema || [];
  const sourceColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 1
  );
  const targetColumnSchema = conditionInputSchema.find(
    (schema: any) => schema?.column === 2
  );

  let sourceColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(sourceColumnSchema);
  let targetColumnMarkTrueSchema =
    UtilityFunctions.findNextTrueMarkedObject(targetColumnSchema);
  let currentObject =
    condition?.component?.definition[targetColumnSchema?.entity];
  UtilityFunctions.iterateUntilTrueMark(
    currentObject,
    targetColumnMarkTrueSchema,
    createMultiChoiceString
  );

  if (sourceColumnSchema && targetColumnSchema) {
    return {
      external_data_storage: {
        representation_type: condition?.representation_type,
        selectedComparator: condition?.selectedComparator,
        col_1: sourceColumn,
        col_2: condition?.col_2,
      },
      component_name: condition?.component?.component_name,
      component_code: condition?.component?.component_code,
      component_icon:
        condition?.component?.component_icon === null
          ? ''
          : condition?.component?.component_icon,
      component_type: 'comparator',
      representation_type: condition?.representation_type,
      definition: {
        ...condition?.component?.definition,
        [targetColumnSchema?.entity]: currentObject,
        [sourceColumnSchema?.entity]:
          sourceColumnMarkTrueSchema?.value_type === 'choice_string' &&
          sourceColumn?.compare_type,
      },
    };
  } else {
    return condition;
  }
};

// ... (other condition processing functions here)

// actions processing functions

export const processArrayDelayOperation = (dataElement: any) => {
  const markedSchema = dataElement?.data?.definition?.input_schema[0];

  if (markedSchema && markedSchema.mark === true && markedSchema.entity) {
    const entityValue = markedSchema.entity;
    const definition = dataElement?.data?.definition;

    if (definition && definition.input_schema) {
      const delayValue = dataElement?.delay?.valueOfDelay;
      const timeOfDelay = dataElement?.delay?.timeOfDelay;

      if (delayValue !== undefined && timeOfDelay) {
        const delayInMilliseconds = UtilityFunctions.convertToMilliseconds(
          delayValue,
          timeOfDelay
        );
        // Create a new object with replaced value
        const updatedObject = {
          ...dataElement.data,
          external_data_storage: {
            valueOfDelay: delayValue,
            timeOfDelay: timeOfDelay,
          },
          definition: {
            ...definition,
            [entityValue]: delayInMilliseconds,
          },
        };
        return updatedObject;
      }
    }
  }
};

export const processWhatsappOperation = (
  dataElement: any,
  triggerInfo: any
) => {
  let tempAssignVariableMap: any = {
    ...(dataElement?.data?.component || {}),
    component_name: dataElement?.data?.component?.component_name,
    component_code: dataElement?.data?.component?.component_code,
    component_type: dataElement?.data?.component?.component_type,
    component_icon:
      dataElement?.data?.component?.component_icon === null
        ? ''
        : dataElement?.data?.component?.component_icon,
    external_data_storage: {
      selectedTemplate: dataElement?.selectedTemplate,
      phoneVariable: dataElement?.phoneVariable,
      selectedChannelId: String(dataElement?.selectedChannelId),
      selectedWhatsappTemplateData: dataElement?.selectedWhatsappTemplateData,
    },
    definition: {
      stored_data: {
        whatsapp_template_id: dataElement?.selectedTemplate?.id,
        whatsapp_platform_id: String(dataElement?.selectedChannelId),
        whatsapp_variables_map: {},
        whatsapp_customer_phone_number:
          dataElement?.phoneVariable?.phoneVariableInfoObj,
        whatsapp_template_details: {},
      },
      request_type: 'parse_and_post',
      executor_type: dataElement?.data?.component?.definition?.executor_type,
    },
  };

  dataElement?.selectedTemplate?.variables?.forEach((singleVariable: any) => {
    const variableKey = singleVariable?.item.replace(/{{|}}/g, '');

    if (!!triggerInfo?.[singleVariable?.variableCategory]) {
      const locatedAtValue = triggerInfo?.[
        singleVariable?.variableCategory
      ].find((item: any) => item?.name === singleVariable?.variableValue);

      const locatedAtObject = locatedAtValue?.located_at || {};

      tempAssignVariableMap.definition.stored_data.whatsapp_variables_map[
        variableKey
      ] = {
        name: singleVariable?.variableValue,
        default: singleVariable?.fallbackValue,
        located_at: locatedAtObject,
      };
    } else if (
      Base85Conversion?.isBase85Encoded(singleVariable?.variableValue)
    ) {
      tempAssignVariableMap.definition.stored_data.whatsapp_variables_map[
        variableKey
      ] = {
        name: singleVariable?.variableValue,
        default: singleVariable?.fallbackValue,
        located_at: triggerInfo?.jinja?.located_at,
      };
    }
  });

  // Return the generated object
  return tempAssignVariableMap;
};

export const processDiscountOperation = (dataElement: any) => {
  const { component, currentScreen, routingModel, screens, currentRoute } =
    dataElement;
  return {
    ...component,
    external_data_storage: {
      currentScreen,
      routingModel,
      screens,
      currentRoute,
    },
  };
};

export const processAddTagsOperation = (dataElement: any) => {
  const {
    currentScreen,
    routingModel,
    screens,
    currentRoute,
    extranalData,
    component,
  } = dataElement;
  const updatedData = UtilityFunctions.updateNestedProperty(
    dataElement,
    extranalData?.dataStoreLocation,
    extranalData?.payload
  );

  return {
    ...updatedData?.component,
    external_data_storage: {
      extranalData,
      currentScreen,
      routingModel,
      screens,
      currentRoute,
      component,
    },
  };
};

export const processAddTagsOnOrderOperation = (dataElement: any) => {
  const { currentScreen, routingModel, screens, currentRoute, extranalData } =
    dataElement;
  const updatedData = UtilityFunctions.updateNestedProperty(
    dataElement,
    extranalData?.dataStoreLocation,
    extranalData?.payload
  );

  return {
    ...updatedData?.component,
    external_data_storage: {
      extranalData,
      currentScreen,
      routingModel,
      screens,
      currentRoute,
    },
  };
};

export const processRemoveTagsOperation = (dataElement: any) => {
  const { component, currentScreen, routingModel, screens, currentRoute } =
    dataElement;
  return {
    ...component,
    external_data_storage: {
      currentScreen,
      routingModel,
      screens,
      currentRoute,
    },
  };
};

export const processRemoveTagsOnCustomerOperation = (dataElement: any) => {
  const { currentScreen, routingModel, screens, currentRoute, extranalData } =
    dataElement;
  const updatedData = UtilityFunctions.updateNestedProperty(
    dataElement,
    extranalData?.dataStoreLocation,
    extranalData?.payload
  );

  return {
    ...updatedData?.component,
    external_data_storage: {
      extranalData,
      currentScreen,
      routingModel,
      screens,
      currentRoute,
    },
  };
};

export const processRemoveTagsOnOrderOperation = (dataElement: any) => {
  const { currentScreen, routingModel, screens, currentRoute, extranalData } =
    dataElement;
  const updatedData = UtilityFunctions.updateNestedProperty(
    dataElement,
    extranalData?.dataStoreLocation,
    extranalData?.payload
  );

  return {
    ...updatedData?.component,
    external_data_storage: {
      extranalData,
      currentScreen,
      routingModel,
      screens,
      currentRoute,
    },
  };
};

// ... (other action processing functions here)
