import { isPartnerRestricted } from 'components/customHooks/usePartner';
import { SelectedTeamInterface } from 'index';
import React from 'react';
import { connect } from 'react-redux';
import AvailableIntegrationsCards from '../components/availableIntegrations';
import DynamicIntegrationChannels from '../components/availableIntegrations/dynamic';
import CollapsableComponent from '../components/common/components/CollapsableComponent';
import { AvailableIntegrations } from '../utils/content';

interface Props {
  selectedPlatformsList: string[];
  availablePlatformsList: string[];
  updateStateData: (key: string, value: any) => void;
  selectedProject: SelectedTeamInterface;
}

const AvailableIntegration: React.FC<Props> = ({
  selectedPlatformsList,
  updateStateData,
  availablePlatformsList,
  selectedProject,
}) => {
  const shouldHideDynamicIntegrations = isPartnerRestricted(
    'AvailableIntegration/function/dynamic-integration'
  );

  const allowedPlatformList =
    selectedPlatformsList.length === 0
      ? availablePlatformsList
      : selectedPlatformsList.filter((sp) =>
          availablePlatformsList.includes(sp)
        );

  return (
    <div className='h-auto px-8 py-4 bg-white'>
      {allowedPlatformList.map((platform, index) => {
        return (
          <div key={index}>
            {
              <CollapsableComponent
                header={platform}
                cardNumber={3}
                key={index}
              >
                <AvailableIntegrationsCards
                  integrationData={AvailableIntegrations[platform]}
                />
              </CollapsableComponent>
            }
          </div>
        );
      })}
      <br />
      {shouldHideDynamicIntegrations ? null : <DynamicIntegrationChannels />}
    </div>
  );
};

const mapState = (state: any) => ({
  availablePlatformsList: state.integration.availablePlatformsList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: string, value: any) =>
    dispatch.integration.updateStateData({ key, value }),
  clearState: () => dispatch.integration.clearState(),
});

const AvailableIntegrationContainer = connect(
  mapState,
  mapDispatch
)(AvailableIntegration);
export default AvailableIntegrationContainer;
