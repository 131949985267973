import React, { Fragment, useState } from 'react';
import EcommerceInputParameterList from './/EcommerceInputParameterList';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface Props {
  hasWriteAccess: boolean;
  handleSelectedParameters: (selectedParameter: any) => void;
  ecommerceParameters: ecommerceParametersProps[];
}

interface ecommerceParametersProps {
  name: string;
  verbose: string;
  type: string | number | boolean;
}
const EcommerceInputParametersDropdown: React.FC<Props> = ({
  hasWriteAccess,
  handleSelectedParameters,
  ecommerceParameters,
}) => {
  const [selectedParameter, setSelectedParameter] =
    useState(ecommerceParameters);
  return (
    <Listbox
      disabled={!hasWriteAccess}
      value={selectedParameter}
      onChange={(option: any) => {
        setSelectedParameter(option);
        handleSelectedParameters(option);
      }}
    >
      {({ open }) => (
        <>
          <div className='relative w-full mt-3'>
            <Listbox.Button className='relative w-full h-10 py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:border-gray-300 sm:text-sm'>
              <span className='block text-sm font-medium text-gray-700 truncate '>
                Select Option
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon
                  className='w-5 h-5 text-gray-700'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-3 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {ecommerceParameters?.map((val: any, index: number) => (
                  <div key={index}>
                    <EcommerceInputParameterList
                      id={index}
                      value={val}
                      title={val.verbose}
                    />
                  </div>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

EcommerceInputParametersDropdown.defaultProps = {
  hasWriteAccess: false,
};
export default EcommerceInputParametersDropdown;
