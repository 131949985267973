import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { IEmailData } from 'pages/inbox/inboxInterface';

interface Props {
  senderName: string | null;
  senderEmail: string | null | number;
  receiverName: string | null;
  receiverEmail: string | null | number;
  date: number;
  subject: string;
  conversationId: string;
  emailInfo?: IEmailData | null;
}

const EmailDropdown: React.FC<Props> = ({
  senderName,
  senderEmail,
  receiverName,
  receiverEmail,
  date,
  subject,
  conversationId,
  emailInfo = null,
}) => {
  const renderInformation = (
    title: string,
    name?: string | null | number,
    email?: string | null | number,
    date?: number | null
  ) => {
    return (
      <div className='flex w-full py-1'>
        <div className='w-[15%] text-gray-500 font-400 text-sm capitalize'>
          {title}
        </div>
        <div className='flex flex-wrap w-[85%] text-gray-900 font-400 text-sm'>
          {!!date && moment(date).local().format('Do MMMM YYYY, hh:mm a')}
          {!date && name}&nbsp;
          {!date && email && (
            <span className='text-gray-500'>
              {'<'}
              {email}
              {'>'}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderCustomEmailInformation = (
    title: string,
    info?: string | null | number,
    date?: number | null
  ) => {
    return (
      <div className='flex w-full py-1'>
        <div className='w-[15%] text-gray-500 font-400 text-sm capitalize'>
          {title}
        </div>
        <div className='flex flex-wrap w-[85%] text-gray-900 font-400 text-sm overflow-x-auto'>
          {!!date && moment(date).local().format('Do MMMM YYYY, hh:mm a')}
          {!date && info}
        </div>
      </div>
    );
  };

  const getPopoverPosition = () => {
    let button = document.getElementById(conversationId);
    if (button !== null) {
      const bodyToButtonDistance = button?.getBoundingClientRect();
      // @ts-ignore
      let positionValue = bodyToButtonDistance.bottom + 4 + 'px';
      return positionValue;
    }
    return null;
  };

  return (
    <div className='relative'>
      <Menu>
        {({ open }) => (
          <>
            <div id={conversationId}>
              <Menu.Button
                className={`${
                  open ? 'bg-[#F3F4F6]' : ''
                } ml-2 mt-2 hover:bg-[#F9FAFB] p-1`}
                data-testid='open-email-dropdown'
              >
                <ChevronDownIcon
                  className='w-4 text-gray-500'
                  aria-hidden='true'
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Menu.Items
                style={{ top: `${getPopoverPosition()}` }}
                className={`fixed z-50 mt-2 w-[390px]
          divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1
          ring-black ring-opacity-5 focus:outline-none`}
              >
                {!emailInfo && (
                  <div className='flex flex-col p-3'>
                    {renderInformation('from', senderName, senderEmail)}
                    {renderInformation('to', receiverName, receiverEmail)}
                    {renderInformation('date', null, null, date)}
                    {renderInformation('sub', subject)}
                  </div>
                )}
                {!!emailInfo && (
                  <div className='flex flex-col p-3'>
                    {renderCustomEmailInformation('from', emailInfo?.sender)}
                    {renderCustomEmailInformation(
                      'to',
                      emailInfo?.recipient_emails
                    )}
                    {!!emailInfo?.cc_emails &&
                      renderCustomEmailInformation('cc', emailInfo?.cc_emails)}
                    {!!emailInfo?.bcc_emails &&
                      renderCustomEmailInformation(
                        'bcc',
                        emailInfo?.bcc_emails
                      )}
                    {renderCustomEmailInformation('date', null, date)}
                    {renderCustomEmailInformation(
                      'subject',
                      emailInfo?.subject
                    )}
                  </div>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default EmailDropdown;
