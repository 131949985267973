import {
  EmptyOrderListIcon,
  EmptySearch,
} from 'pages/inbox/assets/iconComponent/EmptyOrderList';

import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  displayText?: string;
  isOrderlist?: boolean;
}

const EmptyScreen: React.FC<Props> = ({
  displayText = 'Connect the customer to view e-commerce order history.',
  isOrderlist = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className='flex-col items-center justify-center mb-2 p-4'
      data-testid='empty-screen'
    >
      <div className='flex justify-center mb-4'>
        {isOrderlist ? <EmptySearch /> : <EmptyOrderListIcon />}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: t(displayText) }}
        className='text-gray-500 text-sm font-normal text-center px-8'
      />
    </div>
  );
};

export default EmptyScreen;
