import React from 'react';

interface Props {
  color?: string;
  size?: string;
}

const Spinner: React.FC<Props> = ({
  color = 'spnnier-border',
  size = 'h-5 w-5',
}) => {
  return (
    <div className='flex items-center justify-center h-full'>
      <div
        className={`${color} animate-spin inline-block ${size} border-2
                    rounded-full`}
      >
        <span className='hidden'>Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
