import {
  useDispatch,
  useSelector,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import {
  ComparatorTagTypes,
  ISaveSelectedConditionDataTypes,
} from 'pages/automationWorkflow/interface';

// Custom hook handling conditional actions in automation workflow
const useConditionalActions = () => {
  // Access Redux dispatch function
  const dispatch = useDispatch();

  // Destructure required state variables from the Dashboard
  const { selectedProject } = useSelector((state: any) => state.dashboard);

  // Destructure required state loading from the workflowAutomation
  const {
    fetchSelectedTriggerDetails: isSelectedTriggerDetailsApiLoading,
    fetchEcommerceProductData: isFetchEcommerceProductDataLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  // Destructure required state variables from the automation workflow hook
  const {
    ecommerceProducts,
    selectedComparator,
    selectedConditions,
    isAutomationEditable,
    selectedAutomationId,
    comparatorTagDetails,
    addMoreConditionIndex,
    selectedComparatorTags,
    selectedSaveConditionList,
  } = useAutomationWorkflow();

  // Change Condition UI based on the given link
  const updateConditionallyRenderingConditionUI = (link: string) => {
    dispatch.workflowAutomation.updateConditionallyRenderingConditionUI(link);
    dispatch.workflowAutomation.updateMoreConditionIndex(null);
  };

  // Handle adding more conditions to the workflow UI
  const handleAddMoreConditions = (
    getIndex: number | null,
    previousUiLink: string
  ) => {
    // Update more condition index and UI conditionally
    dispatch.workflowAutomation.updateMoreConditionIndex(getIndex);
    dispatch.workflowAutomation.updateConditionallyRenderingConditionUI(
      previousUiLink
    );
  };

  // Make a unique ID of comparator Details option
  const makeTemporaryComparatorOptionId = (id: string) => {
    // Convert the given string ID into a format suitable for temporary options
    return id.split(' ').join('_').toLocaleLowerCase();
  };

  //Update selected comparator
  const updateSelectedComparator = (singleComparator: ComparatorTagTypes) => {
    dispatch.workflowAutomation.updateSelectedComparator(singleComparator);
  };

  // Handle selection of a single comparator
  const handleSelectCompoarator = (comparatorTagName: string) => {
    // Extract tag details based on the selected comparator tag name
    const tagDetails = comparatorTagDetails[comparatorTagName] || [];

    // Create temporary comparator options from tag details
    const temporaryComparatorOptions = tagDetails.map(
      (option: { representation_type: string; name: string }) => ({
        id: makeTemporaryComparatorOptionId(option?.name) ?? '',
        name: option?.name ?? '',
        representationType: option?.representation_type ?? '',
      })
    );

    // Update selected comparator tags and temporary comparator options
    dispatch.workflowAutomation.updateSelectedComparatorTags(tagDetails);
    dispatch.workflowAutomation.updateTemporaryComparatorOptions(
      temporaryComparatorOptions
    );
  };

  // Create a temporary object for a condition based on selected field information
  const makeTemporaryConditionObject = (selectedFieldInfo: any) => {
    return {
      name: selectedFieldInfo[0]?.name,
      id: selectedFieldInfo[0]?.id,
      representation_type: selectedFieldInfo[0]?.representation_type,
      selectedComparator: selectedComparator?.code,
      col_1: {},
      col_2: [
        // Uncomment and add logic to populate col_2 if needed
      ],
    };
  };

  // Update the index of the condition list
  const updateConditionListIndex = (
    originalList: ISaveSelectedConditionDataTypes[],
    index: number | null,
    newData: any
  ) => {
    return index === null
      ? [...originalList, [newData]]
      : originalList.map((condition: any, idx: number) =>
          idx === index ? [...condition, newData] : condition
        );
  };

  // Handle the selection of comparator options
  const handleSelectComparatorOption = (selectedId: string) => {
    if (
      selectedComparatorTags &&
      selectedComparatorTags.length &&
      selectedSaveConditionList !== null
    ) {
      const selectedFieldInfo: ComparatorTagTypes[] =
        selectedComparatorTags.filter(
          (option: ComparatorTagTypes) =>
            makeTemporaryComparatorOptionId(option?.name) === selectedId
        );

      if (selectedFieldInfo.length) {
        if (selectedFieldInfo[0]?.name === 'Order Contains') {
          dispatch.workflowAutomation.fetchEcommerceProductData({
            teamId: selectedProject?.id,
            searchValue: '',
          });
        }

        // Prepare a temporary condition object for col_1 and col_2
        const selectedSaveTempConditionObj =
          makeTemporaryConditionObject(selectedFieldInfo);

        // Update selectedSaveConditionList based on addMoreConditionIndex
        const updatedSaveConditionList = updateConditionListIndex(
          selectedSaveConditionList,
          addMoreConditionIndex,
          selectedSaveTempConditionObj
        );

        // Dispatch action to update selectedSaveConditionList
        dispatch.workflowAutomation.updateSelectedSaveConditionList(
          updatedSaveConditionList
        );

        // Update selectedConditions
        const updatedConditions = updateConditionListIndex(
          selectedConditions,
          addMoreConditionIndex,
          selectedFieldInfo[0]
        );

        // Dispatch action to update selectedConditions
        dispatch.workflowAutomation.updateSelectedConditions(updatedConditions);

        // Dispatch other actions if needed
        dispatch.workflowAutomation.updateConditionallyRenderingConditionUI(
          'add_condition'
        );
      }
    }
  };

  /// Handle updating the selected condition list
  const handleUpdateSelectedSaveCondition = (
    conditionGroupIndex: number,
    conditionItemIndex: number,
    data: any
  ) => {
    selectedSaveConditionList[conditionGroupIndex][conditionItemIndex].col_1 =
      data;
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      selectedSaveConditionList
    );
  };

  // Update the selected condition list with values
  const updateSelectedSaveConditionValueList = (
    conditionGroupIndex: number,
    conditionItemIndex: number,
    data: any
  ) => {
    selectedSaveConditionList[conditionGroupIndex][conditionItemIndex].col_2 = [
      data,
    ];
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      selectedSaveConditionList
    );
    if (isAutomationEditable && selectedAutomationId !== null) {
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'isUpdateAutomationButtonEnable',
        value: true,
      });
    }
  };

  //Remove Condition Item from Selected Condition List
  const removeSelectedConditionItem = (
    conditionGroupIndex: number,
    conditionItemIndex: number
  ) => {
    const updatedSelectedSaveConditionList = [...selectedSaveConditionList];
    const updatedSelectedConditions = [...selectedConditions];

    updatedSelectedSaveConditionList[conditionGroupIndex] =
      updatedSelectedSaveConditionList[conditionGroupIndex].filter(
        (item: any, index: number) => index !== conditionItemIndex
      );

    updatedSelectedConditions[conditionGroupIndex] = updatedSelectedConditions[
      conditionGroupIndex
    ].filter((item: any, index: number) => index !== conditionItemIndex);

    // Remove the inner array if it's empty after filtering
    if (
      !updatedSelectedSaveConditionList[conditionGroupIndex].length &&
      !updatedSelectedConditions[conditionGroupIndex].length
    ) {
      updatedSelectedSaveConditionList.splice(conditionGroupIndex, 1);
      updatedSelectedConditions.splice(conditionGroupIndex, 1);
    }

    // Update the state with the modified array
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      updatedSelectedSaveConditionList
    );
    dispatch.workflowAutomation.updateSelectedConditions(
      updatedSelectedConditions
    );
  };

  const removeSelectedDisplayedOption = (
    value: string,
    idx: number,
    idx2: number
  ) => {
    const updatedConditionList = [...selectedSaveConditionList];

    updatedConditionList[idx][idx2].col_2 = updatedConditionList[idx][
      idx2
    ].col_2.filter((item: any) => item.value !== value);

    // Update the state with the modified array
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      updatedConditionList
    );
    if (isAutomationEditable && selectedAutomationId !== null) {
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'isUpdateAutomationButtonEnable',
        value: true,
      });
    }
  };

  // Handle updating multi-select condition values
  const handleUpdateMultiSelectedSaveConditionValue = (
    idx: number,
    idx2: number,
    data: any[],
    component: any
  ) => {
    const lastElementOfSelectedData = data[data.length - 1];
    const isAlreadySelected = selectedSaveConditionList[idx][idx2].col_2.some(
      (item: any) => item?.value === lastElementOfSelectedData?.value
    );

    const updatedConditionList = [...selectedSaveConditionList];

    if (isAlreadySelected) {
      // Remove the item if already present
      updatedConditionList[idx][idx2].col_2 = updatedConditionList[idx][
        idx2
      ].col_2.filter(
        (item: any) => item.value !== lastElementOfSelectedData.value
      );
    } else {
      // Add the item to col_2
      updatedConditionList[idx][idx2].col_2.push({
        label: lastElementOfSelectedData.label,
        value: lastElementOfSelectedData.value,
        product_id: lastElementOfSelectedData?.product_id,
        product_link: lastElementOfSelectedData?.product_link,
        product_name: lastElementOfSelectedData?.product_name,
      });
    }

    // Add or update the definition object in updatedConditionList[idx]
    updatedConditionList[idx][idx2].component = component || {};
    // Update the state with the modified array
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      updatedConditionList
    );
    if (isAutomationEditable && selectedAutomationId !== null) {
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'isUpdateAutomationButtonEnable',
        value: true,
      });
    }
  };

  // Handle updating dynamic-select condition values
  const handleUpdateDynamicFieldSaveConditionValue = (
    idx: number,
    idx2: number,
    data: any[],
    component: any
  ) => {
    const updatedConditionList = [...selectedSaveConditionList];

    updatedConditionList[idx][idx2].col_2 = [...data];

    // Add or update the definition object in updatedConditionList[idx]
    updatedConditionList[idx][idx2].component = component || {};

    // Update the state with the modified array
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      updatedConditionList
    );
  };

  const handleComparatorMultiChoiceApiData = (comparatorName: string) => {
    switch (comparatorName) {
      case 'Order Contains':
        return ecommerceProducts;
      default:
        return [];
    }
  };

  const fetchSearchEcommerceProducts = (searchValue: string) => {
    dispatch.workflowAutomation.fetchEcommerceProductData({
      teamId: selectedProject?.id,
      searchValue,
    });
  };

  // Return functions for use in components
  return {
    handleSelectCompoarator,
    handleAddMoreConditions,
    updateSelectedComparator,
    fetchSearchEcommerceProducts,
    removeSelectedConditionItem,
    handleSelectComparatorOption,
    removeSelectedDisplayedOption,
    handleUpdateSelectedSaveCondition,
    isSelectedTriggerDetailsApiLoading,
    isFetchEcommerceProductDataLoading,
    handleComparatorMultiChoiceApiData,
    updateSelectedSaveConditionValueList,
    updateConditionallyRenderingConditionUI,
    handleUpdateDynamicFieldSaveConditionValue,
    handleUpdateMultiSelectedSaveConditionValue,
  };
};

export default useConditionalActions;
