import React from 'react';
import PromoCodeInput from './PromoCodeInput';
import { connect } from 'react-redux';
import {
  subscriptionPlanUpdateType,
  subscriptionDetailsType,
  subscriptionUpdateData,
  couponResponse,
} from '../interface';
import { getQueryParameters } from '../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { plansProperty } from '../../../utilities/content';
import usePartner from 'components/customHooks/usePartner';
import { Switch } from 'libraryV2/ui/switch';

interface billingSummaryProps {
  setShowPaymentForm: (value: boolean) => void;
  subscriptionPlanUpdate: subscriptionPlanUpdateType;
  subscriptionDetails: subscriptionDetailsType;
  initiateSubscriptionPlanUpdate: (payload: {
    teamId: number;
    data: subscriptionUpdateData;
  }) => void;
  teamMemberCount: number;
  isBillingAnnually: boolean;
  updateIsBillingAnnually: (value: boolean) => void;
  handleCouponCode: (code: string) => {
    dataSource: couponResponse;
    success: boolean;
  };
  isPromoApplied: boolean;
  setIsPromoApplied: (value: boolean) => void;
  couponProperty: couponResponse | null;
  setCouponProperty: (couponProperty: couponResponse) => void;
  disableCheckout: boolean;
  setDisableCheckout: (payload: boolean) => void;
  handleSubscription: () => void;
  selectedProject: SelectedTeamInterface;
  cardInfo: cardType[];
}

interface cardType {
  brand: string;
  last4: string;
  pm_id: string;
  country: string;
  default: boolean;
  exp_year: number;
  exp_month: number;
}

const BillingSummary: React.FC<billingSummaryProps> = ({
  setShowPaymentForm,
  subscriptionPlanUpdate,
  subscriptionDetails,
  initiateSubscriptionPlanUpdate,
  teamMemberCount,
  isBillingAnnually,
  updateIsBillingAnnually,
  handleCouponCode,
  isPromoApplied,
  setIsPromoApplied,
  couponProperty,
  setCouponProperty,
  disableCheckout,
  handleSubscription,
  selectedProject,
  cardInfo,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const planType = getQueryParameters('plan_type');
  const { isPartnerRestricted } = usePartner();
  const shouldHideBillingPolicy = isPartnerRestricted(
    'BillingSummary/node/billing-policy'
  );

  const getPlanName = () => {
    if (
      [
        plansProperty.premium_plan.name,
        plansProperty.business_plan.name,
      ].includes(planType)
    ) {
      return planType;
    } else {
      return subscriptionDetails?.project_billing_info?.subscription_plan?.name;
    }
  };
  const isPercentage = () => {
    if (isPromoApplied && couponProperty) {
      if (couponProperty?.percent_off) {
        return (
          ((subscriptionPlanUpdate?.base_subscription_cost +
            subscriptionPlanUpdate?.subscription_price_per_member *
              subscriptionPlanUpdate?.addon_members) *
            couponProperty?.percent_off) /
          100
        );
      } else {
        return couponProperty?.amount_off / 100;
      }
    }
    return 0;
  };

  const promoAmount = isPromoApplied && couponProperty ? isPercentage() : 0;
  const useShopifyBilling =
    selectedProject.has_ecommerce_connected &&
    selectedProject.ecommerce_type === 'shopify' &&
    cardInfo.length === 0;
  const hidePromoInput = planType === 'update-team' || useShopifyBilling;
  const billingCalculation = (credit: number, basePrice: number) => {
    let billingAmount = credit - (basePrice - promoAmount);

    if (credit > 0) {
      switch (isPromoApplied) {
        case true:
          return billingAmount > 0 ? 0 : Math.abs(billingAmount);
        case false:
          return subscriptionPlanUpdate?.billed_now;
        default:
          return 0;
      }
    } else {
      return subscriptionPlanUpdate?.billed_now - promoAmount;
    }
  };

  const updateBillingCycle = () => {
    updateIsBillingAnnually(!isBillingAnnually);
    const data = {
      plan: getPlanName(),
      team_members: teamMemberCount,
      is_billed_annually: !isBillingAnnually,
    };
    const payload = {
      teamId: selectedProject.id,
      data: data,
    };
    initiateSubscriptionPlanUpdate(payload);
  };

  return (
    <div className='w-full border border-gray-200 bg-white p-6 rounded-lg'>
      <div className='flex w-full mb-2'>
        <div className='flex w-1/2'>
          <p className='text-lg text-gray-900 font-medium '>
            {t('Billing Summary')}
          </p>
        </div>
        {[
          plansProperty.premium_plan.name,
          plansProperty.business_plan.name,
        ].includes(planType) && (
          <div className='flex w-1/2 justify-end'>
            <p className='text-sm text-gray-700 font-medium ltr:mr-1 rtl:ml-1'>
              {t('Billed Annually')}
            </p>
            <p className='text-sm text-primary font-semibold ltr:mr-3 rtl:ml-3'>
              {t(`(25 % OFF)`)}
            </p>
            <Switch  
              size='md'
              checked={isBillingAnnually}
              className={isBillingAnnually ? 'bg-primary' : 'bg-[#C6CDD4]'}
              onCheckedChange= {() => updateBillingCycle()}
            />
          </div>
        )}
      </div>

      <div className='flex w-full py-4 border-b border-gray-200'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Subscription Cost')}
          </p>
          {!isBillingAnnually &&
            (isRtlLanguage ? (
              <p className='text-sm text-gray-500 font-normal'>
                {`$${subscriptionPlanUpdate?.base_subscription_cost} x ١${t(
                  'mo'
                )}`}
              </p>
            ) : (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.base_subscription_cost} x 1{t('mo')}
              </p>
            ))}
          {isBillingAnnually &&
            (isRtlLanguage ? (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.base_subscription_cost / 12 / 0.75} x
                ٠,٧٥ x ١٢ {t('mo')}
              </p>
            ) : (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.base_subscription_cost / 12 / 0.75} x
                0.75 x 12 {t('mo')}
              </p>
            ))}
        </div>
        <div className='flex flex-end w-1/4'>
          {isBillingAnnually && (
            <p className='text-gray-400 text-sm font-medium ltr:mr-3 rtl:ml-3 line-through'>
              $
              {subscriptionPlanUpdate?.monthly_price *
                12 *
                (subscriptionPlanUpdate?.team_members -
                  subscriptionPlanUpdate?.addon_members)}
            </p>
          )}
          <p className='text-sm text-gray-700 font-semibold'>
            ${subscriptionPlanUpdate?.base_subscription_cost}
          </p>
        </div>
      </div>

      <div className='flex w-full py-4 border-b border-gray-200'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Additional team members')}
          </p>
          {!isBillingAnnually &&
            (isRtlLanguage ? (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.subscription_price_per_member} x
                {t(`add-on seats`)} {subscriptionPlanUpdate?.addon_members} x ١
                {t('mo')}
              </p>
            ) : (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.subscription_price_per_member} x $
                {t(`${subscriptionPlanUpdate?.addon_members} add-on seats`)} x 1
                {t('mo')}
              </p>
            ))}

          {isBillingAnnually &&
            (isRtlLanguage ? (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.subscription_price_per_member / 12} x
                {t(`add-on seats`)} {subscriptionPlanUpdate?.addon_members} x ١٢
                {t('mo')}
              </p>
            ) : (
              <p className='text-sm text-gray-500 font-normal'>
                ${subscriptionPlanUpdate?.subscription_price_per_member / 12} x
                ${t(`${subscriptionPlanUpdate?.addon_members} add-on seats`)} x
                12
                {t('mo')}
              </p>
            ))}
        </div>
        <div className='flex flex-end w-1/4'>
          <p className='text-sm text-gray-700 font-semibold'>
            $
            {subscriptionPlanUpdate?.subscription_price_per_member *
              subscriptionPlanUpdate?.addon_members}
          </p>
        </div>
      </div>

      <div className='flex w-full py-4 border-b border-gray-200'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Available credits')}
          </p>
        </div>
        <div className='flex flex-end w-1/4'>
          <p className='text-sm text-red-500 font-semibold'>
            - $
            {subscriptionPlanUpdate?.available_credit &&
              subscriptionPlanUpdate?.available_credit.toFixed(2)}
          </p>
        </div>
      </div>

      <div className='flex w-full py-4 border-b border-gray-200'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Already billed')}
          </p>
        </div>
        <div className='flex flex-end w-1/4'>
          <p className='text-sm text-red-500 font-semibold'>
            - $
            {subscriptionPlanUpdate?.subscription_cost_billed &&
              subscriptionPlanUpdate?.subscription_cost_billed.toFixed(2)}
          </p>
        </div>
      </div>

      <div className='flex w-full py-4 border-b border-gray-200'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Discount')}
          </p>
        </div>
        <div className='flex flex-end w-1/4'>
          <p className='text-sm text-red-500 font-semibold'>
            - ${promoAmount && promoAmount.toFixed(2)}
          </p>
        </div>
      </div>

      <div className='flex w-full py-4'>
        <div className='flex w-3/4'>
          <p className='text-sm text-gray-700 font-medium ltr:mr-2 rtl:ml-2'>
            {t('Billed Now')}
          </p>
        </div>
        <div className='flex flex-end w-1/4'>
          <p className='text-sm text-gray-700 font-semibold'>
            $
            {billingCalculation(
              subscriptionPlanUpdate?.available_credit,
              subscriptionPlanUpdate?.base_subscription_cost
            )?.toFixed(2)}
          </p>
        </div>
      </div>

      {!hidePromoInput && (
        <div className='w-full mt-4'>
          <PromoCodeInput
            handleCouponCode={handleCouponCode}
            setCouponProperty={setCouponProperty}
            isPromoApplied={isPromoApplied}
            setIsPromoApplied={setIsPromoApplied}
          />
        </div>
      )}
      {subscriptionPlanUpdate?.billed_now > 0 && (
        <>
          {useShopifyBilling && (
            <button
              onClick={() => handleSubscription()}
              className='bg-[#64943E] hover:bg-[#95BF47] p-3 w-full text-white
              font-semibold rounded-md my-6 cursor-pointer'
            >
              {t('Checkout with Shopify')}
            </button>
          )}
          {!useShopifyBilling && (
            <button
              onClick={() => setShowPaymentForm(true)}
              className='bg-primary hover:bg-primary-hover p-3 w-full text-white
              font-semibold rounded-md my-6 cursor-pointer'
            >
              {t('Checkout')}
            </button>
          )}
        </>
      )}
      {subscriptionPlanUpdate?.billed_now <= 0 && (
        <>
          {disableCheckout && (
            <button
              className='bg-primary hover:bg-gray-300 p-3 w-full text-white
              font-semibold rounded-md my-6 cursor-not-allowed'
              disabled
            >
              {t('Proceed')}
            </button>
          )}
          {!disableCheckout && (
            <button
              onClick={() => handleSubscription()}
              className='bg-primary hover:bg-primary-hover p-3 w-full text-white
              font-semibold rounded-md my-6 cursor-pointer'
            >
              {t('Proceed')}
            </button>
          )}
        </>
      )}

      <div
        id='billing-policy'
        className={`flex w-full ${
          shouldHideBillingPolicy ? 'hidden' : 'visible'
        }`}
      >
        <p className='w-full text-gray-500 text-sm text-center font-medium'>
          {t('Please read our')}{' '}
          <a
            href='https://www.myalice.ai/pricing'
            className='text-[#0078CF] text-sm font-normal'
          >
            {t('billing policy')}
          </a>{' '}
          {t('first before making any purchase.')}
        </p>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  subscriptionPlanUpdate: state.newBilling.subscriptionPlanUpdate,
  teamMemberCount: state.newBilling.teamMemberCount,
  isBillingAnnually: state.newBilling.isBillingAnnually,
  selectedProject: state.settings.selectedProject,
  cardInfo: state.newBilling.cardInfo,
});
const mapDispatch = (dispatch: any) => ({
  updateIsBillingAnnually: (value: boolean) =>
    dispatch.newBilling.updateIsBillingAnnually(value),
  handleCouponCode: (code: string) =>
    dispatch.newBilling.handleCouponCode(code),
});

export default connect(mapState, mapDispatch)(BillingSummary);
