import React from 'react';

export const ImageIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.19998 0.399994C1.31632 0.399994 0.599976 1.11634 0.599976 1.99999V9.99999C0.599976 10.8837 1.31632 11.6 2.19998 11.6H11.8C12.6836 11.6 13.4 10.8837 13.4 9.99999V1.99999C13.4 1.11634 12.6836 0.399994 11.8 0.399994H2.19998ZM11.8 9.99999H2.19998L5.39998 3.59999L7.79998 8.39999L9.39998 5.19999L11.8 9.99999Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const VideoIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='10'
      viewBox='0 0 14 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.599976 1.8C0.599976 0.916342 1.31632 0.199997 2.19998 0.199997H6.99998C7.88363 0.199997 8.59998 0.916341 8.59998 1.8V8.2C8.59998 9.08365 7.88363 9.8 6.99998 9.8H2.19998C1.31632 9.8 0.599976 9.08365 0.599976 8.2V1.8Z'
        fill='#6B7280'
      />
      <path
        d='M10.6422 2.68446C10.3712 2.81997 10.2 3.09698 10.2 3.4V6.6C10.2 6.90302 10.3712 7.18003 10.6422 7.31554L12.2422 8.11554C12.4902 8.23953 12.7847 8.22628 13.0206 8.08052C13.2564 7.93475 13.4 7.67726 13.4 7.4V2.6C13.4 2.32274 13.2564 2.06524 13.0206 1.91948C12.7847 1.77371 12.4902 1.76046 12.2422 1.88446L10.6422 2.68446Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const AudioIcon: React.FC = () => {
  return (
    <svg
      width='14'
      height='13'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.66671 7H3.66671C4.02033 7 4.35947 7.14047 4.60952 7.39052C4.85956 7.64057 5.00004 7.97971 5.00004 8.33333V11.6667C5.00004 12.0203 4.85956 12.3594 4.60952 12.6095C4.35947 12.8595 4.02033 13 3.66671 13H1.66671C1.31309 13 0.973947 12.8595 0.723898 12.6095C0.47385 12.3594 0.333374 12.0203 0.333374 11.6667V7C0.333374 3.318 3.31804 0.333328 7.00004 0.333328C10.682 0.333328 13.6667 3.318 13.6667 7V11.6667C13.6667 12.0203 13.5262 12.3594 13.2762 12.6095C13.0261 12.8595 12.687 13 12.3334 13H10.3334C9.97975 13 9.64061 12.8595 9.39056 12.6095C9.14052 12.3594 9.00004 12.0203 9.00004 11.6667V8.33333C9.00004 7.97971 9.14052 7.64057 9.39056 7.39052C9.64061 7.14047 9.97975 7 10.3334 7H12.3334C12.3334 5.58551 11.7715 4.22895 10.7713 3.22876C9.77108 2.22856 8.41453 1.66666 7.00004 1.66666C5.58555 1.66666 4.229 2.22856 3.2288 3.22876C2.22861 4.22895 1.66671 5.58551 1.66671 7Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const DocumentIcon: React.FC = () => {
  return (
    <svg
      width='10'
      height='14'
      viewBox='0 0 10 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.199951 2.2C0.199951 1.31634 0.916295 0.599998 1.79995 0.599998H5.46858C5.89293 0.599998 6.29989 0.768569 6.59995 1.06863L9.33132 3.8C9.63138 4.10006 9.79995 4.50702 9.79995 4.93137V11.8C9.79995 12.6837 9.08361 13.4 8.19995 13.4H1.79995C0.916295 13.4 0.199951 12.6837 0.199951 11.8V2.2ZM1.79995 7C1.79995 6.55817 2.15812 6.2 2.59995 6.2H7.39995C7.84178 6.2 8.19995 6.55817 8.19995 7C8.19995 7.44183 7.84178 7.8 7.39995 7.8H2.59995C2.15812 7.8 1.79995 7.44183 1.79995 7ZM2.59995 9.4C2.15812 9.4 1.79995 9.75817 1.79995 10.2C1.79995 10.6418 2.15812 11 2.59995 11H7.39995C7.84178 11 8.19995 10.6418 8.19995 10.2C8.19995 9.75817 7.84178 9.4 7.39995 9.4H2.59995Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const ImageUploaderIcon: React.FC = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.6666 5.33341H7.99998C7.29274 5.33341 6.61446 5.61437 6.11436 6.11446C5.61426 6.61456 5.33331 7.29284 5.33331 8.00008V21.3334M5.33331 21.3334V24.0001C5.33331 24.7073 5.61426 25.3856 6.11436 25.8857C6.61446 26.3858 7.29274 26.6667 7.99998 26.6667H24C24.7072 26.6667 25.3855 26.3858 25.8856 25.8857C26.3857 25.3856 26.6666 24.7073 26.6666 24.0001V18.6667M5.33331 21.3334L11.448 15.2187C11.9481 14.7188 12.6262 14.438 13.3333 14.438C14.0404 14.438 14.7186 14.7188 15.2186 15.2187L18.6666 18.6667M26.6666 13.3334V18.6667M26.6666 18.6667L24.552 16.5521C24.0519 16.0522 23.3738 15.7713 22.6666 15.7713C21.9595 15.7713 21.2814 16.0522 20.7813 16.5521L18.6666 18.6667M18.6666 18.6667L21.3333 21.3334M24 5.33341H29.3333M26.6666 2.66675V8.00008M18.6666 10.6667H18.68'
        stroke='#9CA3AF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
