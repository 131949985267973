import { React, MagnifyingGlassIcon } from 'pages/automationWorkflow/export';
interface SearchBarTypes {
  searchPlaceholder: string;
  getSearchResult: (searchResult: string) => void;
}

const SearchBar: React.FC<SearchBarTypes> = ({
  searchPlaceholder,
  getSearchResult,
}) => {
  const [searchResult, setSearchResult] = React.useState<string>('');

  return (
    <div className='relative'>
      <input
        type='text'
        placeholder={searchPlaceholder}
        className='w-full border-gray-300 rounded-md focus:ring-primary focus:border-primary pl-8'
        value={searchResult}
        onChange={(e) => setSearchResult(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && getSearchResult(searchResult)}
      />
      <div className=' absolute left-2 top-3'>
        <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' />
      </div>
    </div>
  );
};

export default SearchBar;
