import Layout from './components/layout';
import { DragDropContext } from './components/dndProvider/withDragDropContext';

const CanvasBoard = () => (
  <DragDropContext>
    <Layout />
  </DragDropContext>
);

export default CanvasBoard;
