import React, { useState } from 'react';
import {
  Popover,
  Pane,
  Tooltip,
  Dialog,
  Textarea,
  Button,
  SideSheet,
  Position,
  IconButton,
  Table,
  // toaster,
} from 'evergreen-ui';
import Picker from 'emoji-picker-react';
import emojiIcon from './../assets/icon/emojiIcon.svg';
import imageIcon from './../assets/icon/imageIcon.svg';
import attachmentIcon from './../assets/icon/attachmentIcon.svg';
import attachmentDisabledIcon from './../assets/icon/attachmentIconDisabled.svg';
import fixedArrowUturnLeftIcon from './../assets/icon/fixedReplyIcon.svg';
import notesIcon from './../assets/icon/notesIcon.svg';
import WhatsappRectangleGroupIcon from './../assets/icon/WhatsappTemplateIcon.svg';
import sendIcon from './../assets/icon/sendIconforBottomBar.svg';
import CannedResponse from './CannedResponse';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  sendNote: (message: any, image: any) => void;
  showNote: boolean;
  hasUploadImage: boolean;
  sendMessage: (messageRequestBody: {
    message: string | null;
    image: string | null;
    audio: string | null;
    template: string | null;
  }) => void;
  handleEmojiChange: (string: any) => void;
  handleSend: () => void;
  // handleCancel: () => void;
  messageSendingLoader: boolean;
  isTicketLoaded: boolean;
  hasWriteAccess: boolean;
  selectedProject: SelectedTeamInterface;
  setUploadImage: (value: boolean) => void;
  setUppyUploadType: (uploadType: string) => void;
  hasWhatsappTemplateButton: boolean;
  whatsappTemplate: any;
  currentTicket: any;
  handleWhatsappTemplate: (templateId: number | string) => void;
}

const CRMReplyOptions: React.FC<props> = ({
  sendNote,
  showNote,
  handleEmojiChange,
  handleSend,
  currentTicket,
  messageSendingLoader,
  isTicketLoaded,
  setUploadImage,
  selectedProject,
  setUppyUploadType,
  hasWhatsappTemplateButton,
  whatsappTemplate,
  handleWhatsappTemplate,
}) => {
  const { t } = useTranslation();
  const [isNoteModalShown, setIsNoteModalShown] = React.useState(false);
  const [isCannedShown, setIsCannedShown] = useState(false);
  const [note, setNote] = React.useState('');
  const [isWhatsappTemplateShown, setIsWhatsappTemplateShown] =
    React.useState(false);

  let planType = selectedProject?.subscription_plan?.name;
  let audioRestrictedChannelList = [
    'telegram_messenger',
    'line_messenger',
    'viber_messenger',
    'instagram_messenger',
    'instagram_feed',
    'facebook_feed',
  ];

  return (
    <div className='reply-content'>
      <div>
        <div className='reply-content__type'>
          <Tooltip content='Add a Note'>
            <Button
              className='reply-icon-style-fix'
              disabled={!showNote || !isTicketLoaded}
              onClick={() => setIsNoteModalShown(true)}
            >
              <img src={notesIcon} width={16} alt={'note-icon'} />
            </Button>
          </Tooltip>
          <Dialog
            isShown={isNoteModalShown}
            title='Add Note'
            onConfirm={() => {
              sendNote(note, null);
              setNote('');
              setIsNoteModalShown(false);
            }}
            confirmLabel='Save Note'
            intent={'success'}
            preventBodyScrolling
            shouldCloseOnOverlayClick={false}
          >
            <Textarea
              placeholder={'Type here...'}
              value={note}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
              className={'note-container__modal-textarea'}
              rows={6}
            />
          </Dialog>
        </div>
        <div className='reply-content__options'>
          <Popover
            content={({ close }) => (
              <Pane>
                <Picker
                  onEmojiClick={(event: any, emojiElem: any) => {
                    let target: any = document.getElementById('reply-input');

                    if (target.hasOwnProperty('setRangeText')) {
                      //if setRangeText function is supported by current browser
                      target.setRangeText(emojiElem.emoji);
                      handleEmojiChange(target.value);
                    } else {
                      target.focus();
                      document.execCommand(
                        'insertText',
                        false /*no UI*/,
                        emojiElem.emoji
                      );
                      handleEmojiChange(target.value);
                    }
                    close();
                  }}
                  disableAutoFocus={true}
                  disableSkinTonePicker={true}
                  preload={true}
                />
              </Pane>
            )}
          >
            <Tooltip content={t('Add a Emoji')}>
              <Button
                disabled={!isTicketLoaded}
                className='reply-icon-style-fix'
              >
                <img src={emojiIcon} width={16} alt='emoji' />
              </Button>
            </Tooltip>
          </Popover>
          <Tooltip content='Send Image'>
            <Button
              className='reply-icon-style-fix'
              disabled={!isTicketLoaded}
              onClick={() => {
                setUppyUploadType('image');
                setUploadImage(true);
              }}
            >
              <img src={imageIcon} width={16} alt={'img-icon'} />
            </Button>
          </Tooltip>
          <Tooltip content='Canned Response'>
            <Button
              className='reply-icon-style-fix'
              disabled={!isTicketLoaded}
              onClick={() => setIsCannedShown(true)}
            >
              <img
                src={fixedArrowUturnLeftIcon}
                width={16}
                alt='fixed-reply-icon'
              />
            </Button>
          </Tooltip>
          {!audioRestrictedChannelList.includes(
            currentTicket?.customer?.platform?.platform_type
          ) && (
            <Tooltip
              content={
                planType === 'free'
                  ? 'Available in Paid Package Only'
                  : 'Send Attachments or Voice Messages.'
              }
            >
              <Button
                className='reply-icon-style-fix'
                disabled={!isTicketLoaded}
                onClick={() => {
                  if (planType !== 'free') {
                    setUppyUploadType('audio');
                    setUploadImage(true);
                  }
                }}
              >
                <img
                  src={
                    planType === 'free'
                      ? attachmentDisabledIcon
                      : attachmentIcon
                  }
                  width={16}
                  alt={'attachment-icon'}
                  className={'text-gray-300'}
                />
              </Button>
            </Tooltip>
          )}
          <SideSheet
            width={800}
            isShown={isCannedShown}
            onCloseComplete={() => setIsCannedShown(false)}
          >
            <CannedResponse />
          </SideSheet>
          {hasWhatsappTemplateButton && (
            <Popover
              isShown={isWhatsappTemplateShown}
              position={Position.TOP_LEFT}
              content={({ close }) => (
                <Pane height={300} style={{ overflow: 'auto' }}>
                  <div className={'attribute-container__header'}>
                    {'Message Templates'}
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 5,
                        color: 'inherit',
                      }}
                      onClick={() => setIsWhatsappTemplateShown(false)}
                      marginBottom={16}
                      appearance='minimal'
                      height={24}
                      icon='cross'
                    />
                  </div>
                  {!!whatsappTemplate &&
                    whatsappTemplate.length !== 0 &&
                    whatsappTemplate?.map((template: any, index: number) => {
                      return (
                        <div key={index}>
                          <Table.Row
                            isSelectable
                            onSelect={() => handleWhatsappTemplate(template.id)}
                          >
                            <Table.TextCell>
                              {'{{' + template.name + '}}'}
                            </Table.TextCell>
                          </Table.Row>
                        </div>
                      );
                    })}
                </Pane>
              )}
            >
              <Button
                className='reply-icon-style-fix'
                disabled={!isTicketLoaded}
              >
                <img
                  src={WhatsappRectangleGroupIcon}
                  width={16}
                  alt='whatsapp-template-icon'
                  onClick={() => setIsWhatsappTemplateShown(true)}
                />
              </Button>
            </Popover>
          )}
        </div>
      </div>
      <Button
        isLoading={messageSendingLoader}
        className='reply-content__sendButton'
        height={26}
        onClick={handleSend}
      >
        {!messageSendingLoader && (
          <span>
            <img src={sendIcon} alt='sendIcon' />
            &nbsp;
          </span>
        )}
        <span>Send</span>
      </Button>
    </div>
  );
};

export default CRMReplyOptions;
