import { GripVerticalIcon } from 'lucide-react';
import React, { useState } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { isEqual } from 'lodash';

import useTranslation from 'components/customHooks/useTranslation';
import {
  capitalizeFirstLetter,
  DeleteCofirmationDialog,
  DiscardCofirmationDialog,
  FormFields,
  IDataLabField,
  InputText,
  platinum_color,
  SaveCofirmationDialog,
  TRIGGER_PRIMARY_COLOR,
  useActions,
  useTriggerActions,
} from 'pages/datalab/export';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const Video: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { field, isDragging, isSmall, nodeRef } = props;
  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();

  const {
    createDataLabField,
    updateDataLabField,
    deleteDataLabField,
    updateUnsaveTracker,
  } = useTriggerActions();

  const [fieldProperties, setFieldProperties] = useState(field);
  const selectedFieldId = getSelectedFieldId();

  const {
    id: fieldId,
    name = '',
    label_agent = '',
    placeholder = '',
    is_required = false,
    is_hidden = false,
    type = 'video',
  } = fieldProperties;

  const isNewField =
    typeof fieldId === 'string' && fieldId.startsWith('random') && !field.slug;

  const { icon } = FormFields[type];

  const [errors, setErrors] = useState({});

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: t('This field is required'),
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleOnUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFieldProperties({ ...fieldProperties, [name]: value });
  };

  const shouldDisableSubmitButton = () => {
    return !(
      fieldProperties?.name ||
      fieldProperties?.label_agent ||
      fieldProperties?.placeholder
    );
  };

  const handleOnCheckboxChange = (name: string) => {
    setFieldProperties({
      ...fieldProperties,
      [name]: !fieldProperties[name],
    });
  };

  const handleOnCreateField = () => {
    const { labId } = getController();
    createDataLabField(labId, fieldProperties);
  };

  const handleOnSaveOrCreate = () => {
    if (isNewField) {
      handleOnCreateField();
    } else {
      if (name) {
        setErrors({});

        const { labId } = getController();
        updateDataLabField(labId, fieldId, fieldProperties);
      } else {
        setErrors({
          ...errors,
          name: t('This field is required'),
        });
      }
    }
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = TRIGGER_PRIMARY_COLOR;
  }

  const handleOnDiscard = () => {
    setFieldProperties(field);
    setSelectedFieldId(null);
  };

  const checkFieldIsDirty = () => {
    const isDirty = !isEqual(fieldProperties, field);

    const updatedField = isDirty ? fieldProperties : {}; // if field is not dirty, then return empty object

    updateUnsaveTracker({ isDirty, field: updatedField });
  };

  const fieldName = capitalizeFirstLetter(name);

  const draggingStyles = isDragging
    ? { opacity: 0, backGraound: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  return (
    <AccordionItem
      value={fieldId}
      className='border bg-white rounded-md'
      ref={nodeRef}
      onBlur={checkFieldIsDirty}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        defaultChecked={selectedFieldId === fieldId}
        onClick={handleOnBlockFieldClick}
        isShowUpDownArrow={false}
      >
        <div className='flex justify-between items-center'>
          <GripVerticalIcon color={platinum_color} />
          <span className='mx-2 border rounded p-1 text-zinc-200'>{icon}</span>
          <span className='text-sm' style={accordionTriggerStyle}>
            {fieldName}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className='px-4 py-2 border-t'>
        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='name'
            label='Label for customers'
            value={name}
            onChange={handleOnUpdate}
            required
            error={errors['name']}
            onBlur={handleOnBlur}
            characterLimit={128}
          />

          <InputText
            name='label_agent'
            label={t('Label for agents')}
            value={label_agent}
            onChange={handleOnUpdate}
            characterLimit={128}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='placeholder'
            label={t('Placeholder')}
            value={placeholder}
            onChange={handleOnUpdate}
            placeholder={t('Upload or drag & drop your video here')}
            style={{ width: '100%' }}
          />
        </div>
      </AccordionContent>
      <AccordionContent className='px-4 py-2 h-[40px]'>
        <span className='text-xs text-gray-400'>
          {t('Maximum file size is 50 MB.')}
        </span>
      </AccordionContent>
      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div className='flex items-center ltr:space-x-2 rtl:mr-2'>
              <Checkbox
                id='is_required'
                className='rd-input-style-checkbox'
                name='is_required'
                checked={is_required}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_required');
                }}
              />
              <label
                htmlFor='is_required'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as required')}
              </label>
            </div>

            <div className='flex items-center ltr:space-x-2 ltr:ml-4 rtl:mr-4'>
              <Checkbox
                id='is_hidden'
                className='rd-input-style-checkbox'
                name='is_hidden'
                checked={is_hidden}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_hidden');
                }}
              />
              <label
                htmlFor='is_hidden'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as hidden')}
              </label>
            </div>
          </div>

          <div className='flex'>
            <DeleteCofirmationDialog
              type={name}
              onDelete={handleOnRemove}
              name={fieldName}
            />

            <DiscardCofirmationDialog onDiscard={handleOnDiscard} />

            <SaveCofirmationDialog
              onClick={handleOnSaveOrCreate}
              isNewField={isNewField}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default Video;
