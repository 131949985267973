import { ReactFlowIndex } from '../reactWorkflow/ReactFlowIndex';
import AutomationLogHeader from './components/AutomatonLogHeader';
import useAutomationLog from './hooks/useAutomationLog';
import { Loader2 } from 'lucide-react';

export default function AutomationLog() {
  const { isAutomationLogLoading } = useAutomationLog();
  const renderLoadingView = () => {
    return (
      <div className='relative w-full h-full prevent-select flex flex-col '>
        {/* <AutomationLogHeader /> */}
        <div className='flex-grow w-full h-auto flex justify-center items-center'>
          <Loader2 size={48} className='animate-spin text-green-400' />
        </div>
      </div>
    );
  };
  const renderAutomationFlowLog = () => {
    return (
      <div className='relative w-full h-full prevent-select flex flex-col '>
        <div className='absolute w-full'>
          <AutomationLogHeader />
        </div>
        <ReactFlowIndex />
      </div>
    );
  };
  return isAutomationLogLoading
    ? renderLoadingView()
    : renderAutomationFlowLog();
}
