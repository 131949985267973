import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toaster } from 'evergreen-ui';
import CreditCard from './CreditCard';
import PaymentMethodAdd from './PaymentMethodAdd';
import { billingInfoType, cardInfoType, couponResponse } from '../../interface';
import { plansProperty } from '../../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

type paymentFormType = {
  setShowPaymentForm: (value: boolean) => void;
  createPaymentMethod: (payload: any) => boolean;
  manageCard: (payload: any) => boolean;
  userEmail: string;
  createSubscription: (payload: any) => {
    status: boolean;
    marketPlace: string;
  };
  selectedProject: SelectedTeamInterface;
  teamMemberCount: number;
  isBillingAnnually: boolean;
  setStep: (value: number) => void;
  billingInformationUpdate: (payload: any) => {
    success: boolean;
    error: string;
    data: any;
  };
  totalCost: number;
  cardInfo: cardInfoType[];
  isPromoApplied: boolean;
  couponProperty: couponResponse | null;
  billingInfo: billingInfoType | null;
  fetchAvailablePlans: () => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  fetchPaymentDetails: () => void;
  fetchHistories: (teamId: number) => void;
  fetchEstimation: (payload: number) => void;
  disableCheckout: boolean;
  setDisableCheckout: (payload: boolean) => void;
  planType: string;
};

const PaymentForm: React.FC<paymentFormType> = ({
  setShowPaymentForm,
  createPaymentMethod,
  manageCard,
  userEmail,
  createSubscription,
  selectedProject,
  teamMemberCount,
  isBillingAnnually,
  setStep,
  billingInformationUpdate,
  totalCost,
  cardInfo,
  isPromoApplied,
  couponProperty,
  billingInfo,
  fetchAvailablePlans,
  fetchSubscriptionDetails,
  fetchPaymentDetails,
  fetchHistories,
  fetchEstimation,
  disableCheckout,
  setDisableCheckout,
  planType,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [defaultLoading, setDefaultLoading] = React.useState({});
  const [deleteLoading, setDeleteLoading] = React.useState<
    { index: number; state: boolean } | {}
  >({});
  const [addPayment, setAddPayment] = React.useState(false);
  const [billingInfos, setBillingInfo] = React.useState({
    full_name: billingInfo && billingInfo.full_name,
    email: billingInfo && billingInfo.email,
    billing_address: billingInfo && billingInfo.billing_address,
  });
  const [paymentMethod, setPaymentMethod] = React.useState(
    cardInfo.length ? cardInfo.filter((card: any) => card.default)[0].pm_id : ''
  );
  const plan = [
    plansProperty.premium_plan.name, plansProperty.business_plan.name
  ].includes(planType) ? planType : plansProperty.premium_plan.name;

  const subscriptionPayload = {
    action: 'upgrade',
    plan: plan,
    is_billed_annually: isBillingAnnually,
    team_members: teamMemberCount,
    project_id: selectedProject.id,
    total_cost: totalCost,
    payment_method: paymentMethod,
    coupon_code: isPromoApplied ? couponProperty?.name : null,
  };

  const handleBillingInfo = ({ target }: any) => {
    setBillingInfo({
      ...billingInfos,
      [target.name]: target.value,
    });
  };

  const createBillingInfo = async () => {
    const billingResponse = await billingInformationUpdate(billingInfos);
    if (billingResponse.error) {
      toaster.danger(
        'Something went wrong during saving your billing address',
        {
          description: billingResponse.error,
          duration: 3,
        }
      );
      return billingResponse.error;
    }
    return billingResponse.success;
  };

  const createStripePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      //@ts-ignore
      card: cardElement,
      billing_details: {
        email: userEmail,
      },
    });

    if (result.error) {
      toaster.danger('Something went wrong', {
        description: result.error.message,
        duration: 3,
      });
      setStep(1);
      return;
    }
    setPaymentMethod(result.paymentMethod.id);
    return result.paymentMethod.id;
  };

  const handleSubmit = async (type: string, e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (type === 'payment') {
      if (
        billingInfos.billing_address !== null &&
        billingInfos.billing_address !== '' &&
        billingInfo?.email !== null &&
        billingInfo?.email !== '' &&
        billingInfos?.full_name !== null &&
        billingInfos?.full_name !== ''
      ) {
        const isBillingInfoSuccess = createBillingInfo();
        if (!isBillingInfoSuccess) {
          return;
        }
      } else {
        toaster.danger('Fill Billing Information', {
          duration: 3,
        });
        return;
      }
    }

    if (type === 'payment') {
      if (cardInfo.length < 1) {
        const paymentId = await createStripePaymentMethod();
        if (paymentId) {
          let data: any = {
            payment_method: paymentId,
            project_id: selectedProject.id,
          };

          const subscriptionPayload = {
            action: 'upgrade',
            plan: plan,
            is_billed_annually: isBillingAnnually,
            team_members: teamMemberCount,
            project_id: selectedProject.id,
            total_cost: totalCost,
            payment_method: paymentId,
            coupon_code: isPromoApplied ? couponProperty?.name : null,
          };

          const response = await createPaymentMethod(data);

          if (response) {
            setStep(2);
            const response = await createSubscription(subscriptionPayload);

            if (response.status) {
              fetchSubscriptionDetails(selectedProject.id);
              fetchAvailablePlans();
              fetchPaymentDetails();
              fetchHistories(selectedProject.id);
              fetchEstimation(selectedProject.id);
              setTimeout(() => {
                setStep(3);
              }, 2000);
            } else {
              setStep(1);
              toaster.danger('Something went wrong during subscription', {
                duration: 3,
              });
            }
          }
        }
      } else {
        setStep(2);
        if (
          billingInfos.billing_address !== null &&
          billingInfos.billing_address !== '' &&
          billingInfo?.email !== null &&
          billingInfo?.email !== '' &&
          billingInfos?.full_name !== null &&
          billingInfos?.full_name !== ''
        ) {
          createBillingInfo();
          const response = await createSubscription(subscriptionPayload);

          if (response.status) {
            fetchSubscriptionDetails(selectedProject.id);
            fetchAvailablePlans();
            fetchPaymentDetails();
            fetchHistories(selectedProject.id);
            fetchEstimation(selectedProject.id);
            setTimeout(() => {
              setStep(3);
            }, 2000);
          } else {
            setStep(1);
            toaster.danger('Something went wrong during subscription', {
              duration: 3,
            });
          }
        } else {
          toaster.danger('Fill Billing Information', {
            duration: 3,
          });
        }
      }
    } else {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        //@ts-ignore
        card: cardElement,
        billing_details: {
          email: userEmail,
        },
      });

      if (result.error) {
        toaster.danger('Something went wrong', {
          description: result.error.message,
          duration: 3,
        });
        setStep(1);
        return;
      }

      let data: any = {
        payment_method: result.paymentMethod.id,
      };
      data = { ...data, action: 'attach' };
      const response = await manageCard(data);
      if (response) {
        toaster.success('Successfully added card', {
          duration: 3,
        });
        setAddPayment(false);
      } else {
        toaster.danger('Something went wrong', {
          duration: 3,
        });
      }
    }

    if (cardElement) {
      //@ts-ignore
      cardElement.clear();
    }
  };

  return (
    <>
      {' '}
      {!addPayment ? (
        <div className=''>
          <div className='flex flex-col justify-center w-full align-center'>
            <p className='text-lg text-gray-900 font-bold'>{t('Payment Details')}</p>
            <p className='text-sm text-gray-400 mb-3'>
              {t('We use Stripe for maximum security')}
            </p>
            <XMarkIcon
              className='absolute right-[20px] w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
              onClick={() => setShowPaymentForm(false)}
            />
          </div>
          <form className='p-1' onSubmit={(e) => handleSubmit('payment', e)}>
            <p className='text-sm text-gray-700 mb-2'>{t('Payment Method')}</p>
            {cardInfo.length < 1 ? (
              <PaymentMethodAdd />
            ) : (
              <>
                <div className='max-h-60 overflow-y-scroll mb-3'>
                  {cardInfo.map((card: any, i: any) => {
                    return (
                      <div
                        key={i}
                        className='flex align-center'
                        id='payment-method'
                      >
                        <CreditCard
                          brand={card.brand}
                          last4={card.last4}
                          expire={`${card.exp_month}/${card.exp_year}`}
                          isDefaultLoading={
                            // @ts-ignore
                            defaultLoading.index === i
                              ? // @ts-ignore
                                defaultLoading.state
                              : false
                          }
                          isDefaultDisabled={
                            card.default ||
                            // @ts-ignore
                            (deleteLoading.index === i
                              ? // @ts-ignore
                                deleteLoading.state
                              : false)
                          }
                          onDefaultClick={async () => {
                            setDefaultLoading({ index: i, state: true });
                            const data = {
                              payment_method: card.pm_id,
                              action: 'default',
                            };
                            await manageCard(data);
                            setDefaultLoading({ index: i, state: false });
                          }}
                          isDeleteLoading={
                            // @ts-ignore
                            deleteLoading.index === i
                              ? // @ts-ignore
                                deleteLoading.state
                              : false
                          }
                          isDeleteDisabled={
                            card.default ||
                            // @ts-ignore
                            (defaultLoading.index === i
                              ? // @ts-ignore
                                defaultLoading.state
                              : false)
                          }
                          onDeleteClick={async () => {
                            setDeleteLoading({ index: i, state: true });
                            const data = {
                              payment_method: card.pm_id,
                              action: 'detach',
                            };
                            await manageCard(data);
                            setDeleteLoading({ index: i, state: false });
                            toaster.danger('Card Removed', {
                              description: 'The card was removed successfully',
                              duration: 3,
                            });
                          }}
                          isDefault={card.default}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <p className='text-sm text-gray-700 '>{t('Billing Information')}</p>
            <div className='mb-1'>
              <input
                onChange={(e) => handleBillingInfo(e)}
                className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                type='text'
                placeholder={t('name')}
                name='full_name'
                // @ts-ignore
                value={billingInfos.full_name}
              />
            </div>
            <div className='mb-1'>
              <input
                onChange={(e) => handleBillingInfo(e)}
                className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                type='email'
                placeholder='john@gmail.com'
                name='email'
                // @ts-ignore
                value={billingInfos.email}
              />
            </div>
            <div className='mb-5'>
              <input
                onChange={(e) => handleBillingInfo(e)}
                className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                type='text'
                placeholder={t('Address')}
                name='billing_address'
                // @ts-ignore
                value={billingInfos.billing_address}
              />
            </div>
            <div className='mb-1'></div>
            <button
              type='submit'
              className='bg-green-500 w-full p-2 rounded-md text-white mb-3'
            >
              {t('Confirm Purchase')}
            </button>

            {cardInfo.length < 1 ? null : (
              <p
                className='text-blue-500 text-center cursor-pointer'
                onClick={() => setAddPayment(true)}
              >
                {t('Add new payment method')}
              </p>
            )}
          </form>
        </div>
      ) : (
        <>
          {' '}
          <div className='flex flex-col justify-center w-full align-center'>
            <p className='text-lg text-gray-900 font-bold'>{t('Payment Details')}</p>
            <p className='text-sm text-gray-400 mb-3'>
              {t('We use Stripe for maximum security')}
            </p>
            <XMarkIcon
              className='absolute right-[20px] w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
              onClick={() => setAddPayment(false)}
            />
          </div>
          <PaymentMethodAdd />
          <button
            type='button'
            onClick={(e) => {
              handleSubmit('add', e);
            }}
            className='bg-green-500 w-full p-2 rounded-md text-white mb-3 cursor-pointer'
          >
            {t('Add card')}
          </button>
        </>
      )}
    </>
  );
};

export default PaymentForm;
