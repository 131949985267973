import React from 'react';
import Introduction from './Introduction';
import LineSetting from '../../common/pages/LineSettingData';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { CustomSwitch as Switch } from '../../common/components/CustomSwitch';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;
  integratedNlpList: { id: string; name: string; provider: string }[];

  //functions
  handleClose: () => void;
  setIsSideSheetOpen: (val: string) => void;
  getIntegratedNlpList: (teamId: number) => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const initialSettingData = {
  name: '',
  title: '',
  primary_token: '',
  is_published: true,
  connected_nlp_integration_id: '',
};

const LineSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  integratedNlpList,
  setIsSideSheetOpen,
  getIntegratedNlpList,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [lineSettingData, setLineSettingData] =
    React.useState(initialSettingData);

  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'LineSideSheet/node/line-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';

  //functions

  const clearState = () => {
    setLineSettingData(initialSettingData);
    setStep(1);
    setLoading(false);
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2: {
        setLoading(true);
        let payloadData = {
          title: lineSettingData.title,
          primary_token: lineSettingData.primary_token,
          is_published: lineSettingData.is_published,
          connected_nlp_integration_id:
            lineSettingData.connected_nlp_integration_id,
        };
        let res = await createChannelIntegrations(
          'line_messenger',
          selectedProject.id,
          payloadData
        );
        if (res.status === 200) {
          setLoading(false);
          setIsSideSheetOpen('');
          !!callbackUrl
            ? navigate(callbackUrl)
            : navigate(`/integrations/integrated`);
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? t(
                  'Something went wrong while integrating the channel. Try again later.'
                )
              : res.data.error
          );
        }
        break;
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    let flag = false;
    const mandatoryKeys = ['title', 'primary_token'];

    mandatoryKeys.forEach((items) => {
      if (!(lineSettingData && lineSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return checkFinishButton();
      default:
        return false;
    }
  };

  const getIsPublishedSwitch = () => {
    return (
      <div className='flex gap-2'>
        <label className='font-medium text-gray-700'>
          {t('Publish Channel')}
        </label>
        <Switch
          checked={lineSettingData?.is_published}
          onChange={() => {
            setLineSettingData({
              ...lineSettingData,
              is_published: !lineSettingData?.is_published,
            });
          }}
        />
      </div>
    );
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: t(`Connect Line Messenger with {{${partnerName}}}`),
        };
      case 2:
        return {
          title: t('Line Settings'),
        };
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getIntegratedNlpList(selectedProject.id);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
          clearState();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={getHeaderInfo(step).title}
        confirmText={step === 2 ? t('Finish') : t('Next')}
        cancelText={t('Back')}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hasLeftActionElement={true}
        leftActionElement={getIsPublishedSwitch()}
        hideLeftActionElement={step !== 2}
        hideCancel={step === 1}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>{t('creating channel...')}</span>
              </div>
            ) : (
              <LineSetting
                success={success}
                errorMessage={errorMessage}
                lineSettingData={lineSettingData}
                integratedNlpList={integratedNlpList}
                handleChange={(key: string, value: string | string[]) => {
                  setLineSettingData({
                    ...lineSettingData,
                    [key]: value,
                  });
                }}
              />
            )}
          </>
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
});

export default connect(mapState, mapDispatch)(LineSideSheet);
