import Stepper from 'pages/onboardingV2/components/common/Stepper';
import React, { useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

import { navigate } from '@reach/router';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { LiveChatDataProps } from 'pages/integration/interface';
import LivechatDefaultData from 'pages/integration/models/LiveChatDefaultData';
import Link from '../../../../../library/link';
import Footer from '../../../../../library/modal/component/Footer';

interface Props {
  teamName: string;
  projectId: number;
  handleClose: () => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const formSteps = [
  { id: 1, name: 'General Information' },
  { id: 2, name: 'Embed Livechat' },
];

const LiveChatSteps: React.FC<Props> = ({
  teamName,
  projectId,
  handleClose,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { isPartnerRestricted } = usePartner();
  const shouldHideFooter = isPartnerRestricted(
    'LiveChatSteps/function/getModalCustomFooter'
  );
  const [platfromData, setPlatfromData] = React.useState<any>({
    id: '',
    primary_id: '',
    access_token: '',
  });

  const [livechatData, setLivechatData] =
    React.useState<LiveChatDataProps>(LivechatDefaultData);

  useEffect(() => {
    let defaultData = { ...livechatData };
    defaultData.title = teamName;
    setLivechatData({ ...defaultData });
    // eslint-disable-next-line
  }, [teamName]);

  const getModalCustomFooter = (
    description: string,
    link: string,
    linkTitle: string
  ) => {
    if (shouldHideFooter) {
      return null;
    }
    return (
      <div className='flex justify-end mt-4'>
        <label
          htmlFor='remember_me'
          className='flex gap-2 text-sm text-gray-900'
        >
          {t(description)}{' '}
          <Link url={link} openInNewTab={true}>
            {t(linkTitle)}
          </Link>
        </label>
      </div>
    );
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        let payloadData = { ...livechatData };
        let res = await createChannelIntegrations(
          'livechat_messenger',
          projectId,
          payloadData
        );
        if (res.status === 200) {
          setStep(2);
          setSuccess(true);
          setPlatfromData(res.data.dataSource);
        } else {
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? t(
                  'Something went wrong while creating the channel. Try again later.'
                )
              : res.data.error
          );
        }
        break;

      case 2:
        handleClose();
        navigate(`/integrations/integrated`);
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 1:
        handleClose();
        break;
    }
  };

  return (
    <div className='p-6'>
      <>
        <Stepper steps={formSteps} currentStep={step} />
        <div className='mt-6'>
          {
            {
              1: (
                <Step1
                  success={success}
                  errorMessage={errorMessage}
                  liveChatData={livechatData}
                  handleChange={(liveChatData: LiveChatDataProps) => {
                    setLivechatData({
                      ...liveChatData,
                    });
                  }}
                />
              ),
              2: (
                <Step2
                  platfromData={platfromData}
                  theme={'dark'}
                  showLineNum={false}
                  hasCustomStyle={true}
                />
              ),
            }[step]
          }
        </div>
        <div className={` ${step === 2 ? 'mt-8' : 'mt-64'} `}>
          <Footer
            cancelText={step === 2 ? t('Close') : t('Cancel')}
            confirmText={step === 1 ? t('Confirm Integration') : t('Finish')}
            hasTopBorder={false}
            isConfirmButtonLoading={false}
            disableConfirm={false}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            hideCancel={step === 2}
            customFooterContent={getModalCustomFooter(
              t('Need Help?'),
              'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat',
              t('Read Our Guide')
            )}
          />
        </div>
      </>
    </div>
  );
};

export default LiveChatSteps;
