import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import TextVariableInput from '../../common/components/TextVariableInput';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import {
  GroupsListType,
  ProjectsRolesDataType,
  SelectedTeamInterface,
} from 'index';
import AgentsDropdown from '../../common/components/AgentsDropdown';
import BotSwitch from './TicketSettingsSwitch';
import { classNames, emailChannels, navMenu } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { channelListProps } from 'pages/integration/interface';
import usePartner from 'components/customHooks/usePartner';

interface TicketSettingsType {
  setOpenticketModal: (payload: string | null) => void;
  attributes: string[];
  groups: GroupsListType[];
  agentList: ProjectsRolesDataType[];
  handleChatbotSettings: (
    payload: chatbotSettingsPayload
  ) => chatbotSettingsResponseData;
  selectedProject: SelectedTeamInterface;
  data: channelListProps;
  getChatbotSettings: (channelId: string) => void;
  chatbotSettings: chatBotSettingsType;
  fetchBusinessHours: (teamId: number) => void;
  businessHour: any;
  fetchAttributes: (channelId: string) => void;
  updateSelectedNav: (navID: number) => void;
  removePreviousChatbotSettingsStateData: () => void;
}

interface chatBotSettingsType {
  is_bot_enabled: boolean;
  ticket_assignment: {
    agents: ProjectsRolesDataType[];
    groups: GroupsListType[];
  };
  avatar: string;
  email: string;
  full_name: string;
  id: number;
  welcome_messages: string[];
}

const TicketSettings: React.FC<TicketSettingsType> = ({
  data,
  groups,
  agentList,
  attributes,
  businessHour,
  fetchAttributes,
  chatbotSettings,
  selectedProject,
  updateSelectedNav,
  setOpenticketModal,
  getChatbotSettings,
  fetchBusinessHours,
  handleChatbotSettings,
  removePreviousChatbotSettingsStateData,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideDocumentationLink = isPartnerRestricted(
    'TicketSettings/node/help-docs'
  );
  const [showAttribute, setShowAttribute] = React.useState(false);
  const [showAttributeForOffline, setShowAttributeForOffline] =
    React.useState(false);
  const [agents, setAgents] = React.useState<ProjectsRolesDataType[] | null>(
    null
  );
  const [saveGroups, setSaveGroups] = React.useState<GroupsListType[] | null>(
    null
  );
  const [welcomeMessage, setWelcomeMessage] = React.useState(
    chatbotSettings && chatbotSettings.welcome_messages.length > 0
      ? chatbotSettings.welcome_messages[0]
      : ''
  );
  const [welcomeMessageAfterhour, setWelcomeMessageAfterhour] = React.useState(
    chatbotSettings && chatbotSettings.welcome_messages.length > 1
      ? chatbotSettings.welcome_messages[1]
      : ''
  );
  const [enableBot, setEnableBot] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showSaveButton, setShowSaveButton] = React.useState(false);
  const [isUnassined, setIsUnassigned] = React.useState(false);

  const [selectedAgentOrGroup, setSelectedAgentOrGroup] = React.useState<
    ProjectsRolesDataType[] | GroupsListType[] | null
  >(null);

  const disableSave = () => {
    const offlineWelcomeMesssage =
      chatbotSettings && chatbotSettings.welcome_messages.length > 0
        ? chatbotSettings.welcome_messages[0]
        : '';
    const onlineWelcomeMessages =
      chatbotSettings && chatbotSettings.welcome_messages.length > 1
        ? chatbotSettings.welcome_messages[1]
        : '';

    const agentsInfo =
      chatbotSettings &&
      chatbotSettings.ticket_assignment &&
      chatbotSettings.ticket_assignment.agents
        ? chatbotSettings.ticket_assignment.agents
        : null;

    const groupsInfo =
      chatbotSettings &&
      chatbotSettings.ticket_assignment &&
      chatbotSettings.ticket_assignment.groups
        ? chatbotSettings.ticket_assignment.groups
        : null;

    if (chatbotSettings) {
      if (
        enableBot !== chatbotSettings.is_bot_enabled ||
        welcomeMessage !== offlineWelcomeMesssage ||
        welcomeMessageAfterhour !== onlineWelcomeMessages ||
        agents !== agentsInfo ||
        saveGroups !== groupsInfo ||
        isUnassined
      ) {
        return setShowSaveButton(true);
      }
    }
    return setShowSaveButton(false);
  };

  const getAgentOrGroups = () => {
    let agentsListLocal: any = [];
    let groupsListLocal: any = [];

    if (!!agentList) {
      agentList.forEach((elem: ProjectsRolesDataType) => {
        agentsListLocal = [
          ...agentsListLocal,
          {
            ...elem,
            value: elem.admin.id,
            full_name: elem.admin.full_name,
            type: 'agent',
          },
        ];
      });
    }

    if (!!groups) {
      groups.forEach((group: GroupsListType) => {
        groupsListLocal = [
          ...groupsListLocal,
          {
            ...group,
            value: group.id,
            full_name: `${group.name}`,
            type: 'group',
          },
        ];
      });
    }

    let data = [
      {
        label: t('General'),
        options: [{ value: null, full_name: t('Unassigned'), type: 'general' }],
      },
      {
        label: t('Agents'),
        options: agentsListLocal,
      },
      {
        label: t('Groups'),
        options: groupsListLocal,
      },
    ];
    return data;
  };

  const saveChatbotSettings = async () => {
    let agentdata: number[] = [];
    let groupData: number[] = [];

    if (agents && agents.length > 0) {
      agents[0].admin && agentdata.push(agents[0].admin.id);
    }

    if (saveGroups && saveGroups.length > 0) {
      groupData.push(saveGroups[0].id);
    }

    let payload = {
      channelId: data.id,
      data: {
        is_bot_enabled: enableBot,
        agent_ids: agentdata,
        group_ids: groupData,
        welcome_messages: [welcomeMessage, welcomeMessageAfterhour],
      },
    };

    const response = await handleChatbotSettings(payload);
    if (response.success) {
      setOpenticketModal(null);
      toaster.success(t(`Chatbot Settings Changed Successfully!!`));
    } else {
      setOpenticketModal(null);
      toaster.danger(t(`Something Went Wrong!!`));
    }
  };

  const fetchInitialApi = async () => {
    await getChatbotSettings(data.id);
    await fetchAttributes(data.id);
  };

  const handleOnChnage = (
    data: ProjectsRolesDataType | GroupsListType,
    type: string
  ) => {
    if (type === 'agent') {
      setAgents([data] as ProjectsRolesDataType[]);
      setSaveGroups([]);
    }
    if (type === 'group') {
      setSaveGroups([data] as GroupsListType[]);
      setAgents([]);
    }
    if (type === 'general') {
      setSaveGroups([]);
      setAgents([]);
    }
  };

  const fetchBusinessHourOnLoad = async () => {
    await fetchBusinessHours(selectedProject.id);
  };

  const renderWelcomeTextBox = (businessHour: boolean) => {
    return (
      <>
        <div>
          {businessHour && (
            <div className='mb-5'>
              <div className='flex gap-2 py-1'>
                <p className='flex justify-start text-[14px] text-gray-700'>
                  {t('Welcome Message')}
                </p>
                <div className='flex justify-start px-2 bg-blue-200 border border-blue-200 rounded-full text-sm text-blue-800'>
                  {t('Online')}
                </div>
              </div>
              <TextVariableInput
                id='online'
                disabled={enableBot ? true : false}
                value={welcomeMessage}
                type={'text'}
                attributeList={attributes}
                showAttribute={showAttribute}
                setShowAttribute={setShowAttribute}
                placeHolder={t('Add your welcome message here')}
                handleOnChange={(value: string) => {
                  setWelcomeMessage(value);
                }}
                setValue={setWelcomeMessage}
                hasWriteAccess={true}
                enableAttribute={true}
                enableEmoji={true}
              />
            </div>
          )}
          <div>
            <div className='flex gap-2 py-1'>
              <p className='flex justify-start text-[14px] text-gray-700 mb-1'>
                {t('Welcome Message')}
              </p>
              {businessHour && (
                <div className='flex justify-start px-2 bg-indigo-200 border border-indigo-200 rounded-full text-sm text-indigo-800'>
                  {t('Offline')}
                </div>
              )}
            </div>
            <TextVariableInput
              id='offline'
              disabled={enableBot ? true : false}
              value={welcomeMessageAfterhour}
              type={'text'}
              attributeList={attributes}
              showAttribute={showAttributeForOffline}
              setShowAttribute={setShowAttributeForOffline}
              placeHolder={t('Add your welcome message here')}
              handleOnChange={(value: string) => {
                setWelcomeMessageAfterhour(value);
              }}
              enableAttribute={true}
              enableEmoji={true}
              setValue={setWelcomeMessageAfterhour}
              hasWriteAccess={true}
            />
          </div>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    fetchInitialApi();
    fetchBusinessHourOnLoad();
    return () => {
      removePreviousChatbotSettingsStateData();
    };
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    if (chatbotSettings && businessHour) {
      setEnableBot(
        emailChannels.includes(data?.platform_type)
          ? false
          : chatbotSettings?.is_bot_enabled
      );
      setWelcomeMessage(
        chatbotSettings.welcome_messages.length > 0
          ? chatbotSettings.welcome_messages[0]
          : ''
      );
      setWelcomeMessageAfterhour(
        chatbotSettings.welcome_messages.length > 1
          ? chatbotSettings.welcome_messages[1]
          : ''
      );
      setAgents(
        chatbotSettings.ticket_assignment.agents
          ? chatbotSettings.ticket_assignment.agents
          : null
      );
      setSaveGroups(
        chatbotSettings.ticket_assignment.groups
          ? chatbotSettings.ticket_assignment.groups
          : null
      );
      setShowModal(true);
    }
    //eslint-disable-next-line
  }, [chatbotSettings, businessHour]);

  React.useEffect(() => {
    disableSave();
    /* eslint-disable-next-line */
  }, [
    enableBot,
    welcomeMessage,
    welcomeMessageAfterhour,
    agents,
    saveGroups,
    isUnassined,
  ]);

  const updateSelectedAgent = () => {
    if (agents && agents.length > 0) {
      setSelectedAgentOrGroup(agents);
    } else if (saveGroups && saveGroups.length > 0) {
      setSelectedAgentOrGroup(saveGroups);
    } else setSelectedAgentOrGroup(null);
  };

  React.useEffect(() => {
    updateSelectedAgent();
    //eslint-disable-next-line
  }, [agents, saveGroups]);

  const renderChatBotSettings = () => {
    if (emailChannels.includes(data?.platform_type)) return null;
    return (
      <div className='p-4 border rounded-md w-full mb-8'>
        <div className='flex w-full'>
          <div className='w-[80%]'>
            <p className='text-gray-700 flex text-base mb-1'>
              {t('Do you want to turn on chatbot for this channel?')}
            </p>
            {enableBot ? (
              <p className='text-sm rtl:text-right text-gray-400'>
                {t(
                  'Your chatbot will act as you have set the automation configuration for this channel. To customize, go here'
                )}{' '}
                <span
                  className='text-blue-500 cursor-pointer'
                  onClick={() => {
                    navigate(`/projects/${selectedProject.id}/automation`);
                    updateSelectedNav(
                      navMenu.find((nav) => nav.name === 'Automation')?.id || 3
                    );
                  }}
                >
                  {data?.name} {t('Automation')}
                </span>
              </p>
            ) : (
              <p className='text-sm flex text-gray-400'>
                {t(
                  'When the chatbot is off your customers will receive a welcome message and will be assigned as per your configuration.'
                )}
              </p>
            )}
          </div>
          <div className='w-[20%] flex justify-end items-center'>
            <BotSwitch enableBot={enableBot} setEnableBot={setEnableBot} />
          </div>
        </div>
      </div>
    );
  };

  const renderTicketAssignment = () => {
    return (
      <div
        className={classNames(
          'w-full',
          emailChannels.includes(data?.platform_type) ? ' mb-40 ' : 'mb-3'
        )}
      >
        <p className='text-[14px] flex text-gray-700 font-medium mb-1'>
          {t('Ticket Assignment')}
        </p>
        <div>
          <AgentsDropdown
            options={getAgentOrGroups()}
            selectedValue={selectedAgentOrGroup}
            handleOnChnage={handleOnChnage}
            enableBot={enableBot}
            setIsUnassigned={setIsUnassigned}
          />
        </div>
      </div>
    );
  };

  const renderWelcomeMessageSettings = () => {
    if (emailChannels.includes(data?.platform_type)) return null;
    return (
      <div
        className={`${businessHour?.is_operational_status_on ? '' : 'mb-5'}`}
      >
        {renderWelcomeTextBox(businessHour?.is_operational_status_on)}
      </div>
    );
  };

  return (
    <>
      {showModal && (
        <div className='relative min-h-[300px]'>
          <div className='flex justify-start w-full align-center border-b px-5 py-4'>
            <p className='text-base text-gray-800 font-semibold capitalize'>
              {emailChannels.includes(data?.platform_type)
                ? t('Ticket Settings')
                : t('chatbot settings')}
            </p>
          </div>
          <XMarkIcon
            className='absolute ltr:right-[20px] rtl:left-5 w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
            onClick={() => setOpenticketModal(null)}
          />
          <div className='p-5'>
            {renderChatBotSettings()}
            {renderTicketAssignment()}
            {renderWelcomeMessageSettings()}
            {showSaveButton && (
              <div className={`flex flex-end mb-4 mt-10`}>
                <button
                  className='bg-primary px-4 py-2 text-white rounded-md capitalize'
                  onClick={() => saveChatbotSettings()}
                >
                  {t('save changes')}
                </button>
              </div>
            )}
            {shouldHideDocumentationLink ? null : (
              <div id='help-docs' className='flex flex-end'>
                <p>
                  {t('Confused about what to do?')}{' '}
                  <a
                    href='https://docs.myalice.ai/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-blue-500'
                  >
                    {t('Read Documentation')}
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  attributes: state.builder.attributes,
  groups: state.crm.agentGroup,
  agentList: state.block.agentList,
  selectedProject: state.dashboard.selectedProject,
  chatbotSettings: state.integration.chatbotSettings,
  businessHour: state.businesshour.businessHourTotalData,
});
const mapDispatch = (dispatch: any) => ({
  handleChatbotSettings: (payload: chatbotSettingsPayload) =>
    dispatch.integration.handleChatbotSettings(payload),
  getChatbotSettings: (channelId: string) =>
    dispatch.integration.fetchChatbotSettings(channelId),
  fetchBusinessHours: (teamId: number) =>
    dispatch.businesshour.fetchBusinessHours(teamId),
  fetchAttributes: (channelId: string) =>
    dispatch.builder.fetchAttributes(channelId),
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
  removePreviousChatbotSettingsStateData: () =>
    dispatch.integration.updateChatbotSettings(null),
});

export default connect(mapState, mapDispatch)(TicketSettings);
