import React, { Fragment } from 'react';
import { Menu } from '../../../library/';
import {
  getPlatformInfoTitle,
  getUpdatedPlatformIconOutlined,
} from '../../../utilities/utils';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';

interface props {
  platformData: any[];
  handleSelect: (channel: any) => void;
  selectedPlatform: any;
  title: string;
}

const DropdownMenu2: React.FC<props> = ({
  platformData,
  handleSelect,
  selectedPlatform,
  title,
}) => {
  return (
    <Menu
      as='div'
      className='absolute inline-block text-left'
      data-testid='menu-div'
    >
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className='flex mb-5 text-sm font-medium text-gray-900 focus:outline-none'
              data-testid='menu-button'
            >
              {
                <img
                  src={getUpdatedPlatformIconOutlined(
                    !!selectedPlatform.platform_type
                      ? selectedPlatform.platform_type
                      : 'all'
                  )}
                  alt={'lorem'}
                  width={selectedPlatform.platform_type === 'all' ? 12 : 20}
                />
              }{' '}
              &nbsp;&nbsp; {getPlatformInfoTitle(selectedPlatform)} &nbsp;
              <ChevronDownIcon
                className='w-5 h-5 ml-2 -mr-1'
                aria-hidden='true'
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              static
              className='relative right-0 z-30 z-40 mt-2 origin-top-right bg-white rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none'
            >
              <div className='pb-1 overflow-y-auto max-h-96 min-h-40'>
                {!!title && (
                  <div className='px-4 pt-2 pb-2 border-b border-gray-100'>
                    <p className='text-sm font-medium text-gray-900 truncate'>
                      {title}
                    </p>
                  </div>
                )}
                {platformData?.map(
                  (channel: any, key: number) =>
                    !channel.is_archived && (
                      <Menu.Item
                        key={key}
                        onClick={() => {
                          handleSelect(channel);
                        }}
                      >
                        <div className='flex px-4 py-2 cursor-pointer align-center'>
                          <img
                            src={getUpdatedPlatformIconOutlined(
                              !!channel.platform_type
                                ? channel.platform_type
                                : 'all'
                            )}
                            alt={channel.platform_type}
                            width={20}
                          />
                          &nbsp;&nbsp; {getPlatformInfoTitle(channel)}
                        </div>
                      </Menu.Item>
                    )
                )}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropdownMenu2;
