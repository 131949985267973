/* This example requires Tailwind CSS v2.0+ */
import { Fragment, ReactElement } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import { CheckIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface OptionProps {
  name: string;
  value: any;
}

interface Props {
  buttonComponent: ReactElement;
  options: OptionProps[];
  value?: any;
  align?: string;
  width?: string;
  classes?: string;
  isFullWidth?: boolean;
  handleDataSelection: (val: OptionProps) => void;
}

const DropDown: React.FC<Props> = ({
  buttonComponent,
  options,
  value = '',
  align = 'right-0',
  width = 'w-56',
  classes = 'flex items-center  text-gray-400 hover:text-gray-600 focus:outline-none', // for button parent div design
  isFullWidth = false, // if the menu take full width or not
  handleDataSelection,
}) => {
  const { t } = useTranslation();
  const handleOnClick = (index: number) => {
    handleDataSelection(options[index]);
  };

  return (
    <Menu
      as='div'
      className={classNames(
        'relative inline-block text-left',
        isFullWidth ? 'w-full' : ''
      )}
    >
      {({ open }) => (
        <>
          <Menu.Button className={classes}>{buttonComponent}</Menu.Button>
          <Transition
            as={Fragment}
            show={open}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={classNames(
                'absolute z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden',
                align + ' ' + width
              )}
            >
              <div className='py-1'>
                {options.map((option: OptionProps, index: number) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            `block px-4 py-2 text-sm ${
                              option.value === value
                                ? 'font-semibold'
                                : 'font-normal'
                            } ltr:text-left rtl:text-right relative w-[stretch] w-[-moz-available]`
                          )}
                          onClick={() => {
                            handleOnClick(index);
                          }}
                        >
                          {t(option.name)}
                          <CheckIcon
                            color='#111827'
                            width={20}
                            height={20}
                            className={classNames(
                              option.value === value ? 'inline' : 'hidden',
                              `absolute ltr:right-2 rtl:left-2`
                            )}
                          />
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropDown;
