import React, { useState } from 'react';
import { ISavedReply, ISavedReplyAttachment } from './SavedReplySheet';
import defaultMediaImage from 'pages/inbox/assets/images/defaultMediaImage.svg';
import {
  Download,
  DownloadIcon,
  FileText,
  PlayCircleIcon,
  X,
} from 'lucide-react';
import { downloadMediaAttachment, PdfFileIcon } from '../utils';
import { Dialog, DialogClose, DialogContent } from 'libraryV2/ui/dialog';
import { Button } from 'libraryV2/ui/button';

interface ViewSavedReplyProps {
  savedReply: ISavedReply;
}

const ViewSavedReply: React.FC<ViewSavedReplyProps> = ({ savedReply }) => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] =
    useState<ISavedReplyAttachment | null>(null);

  const renderInfoCard = (cardHeader = '', cardBody = '') => {
    return (
      <div className='border border-zinc-200 rounded-md space-y-2 p-3'>
        <p className='text-zinc-500 text-xs leading-5 font-medium'>
          {cardHeader}
        </p>
        <p className='text-zinc-900 whitespace-pre-line overflow-y-auto text-sm'>
          {cardBody}
        </p>
      </div>
    );
  };

  const renderMediaImageView = () => {
    return (
      <div
        className={`flex text-lg px-4 justify-center text-gray-700 h-[40vh] w-auto items-center`}
      >
        <img
          className='w-auto h-full object-contain'
          alt='media'
          data-testid='media-viewer-image'
          src={selectedAttachment?.url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultMediaImage;
          }}
        />
      </div>
    );
  };

  const renderMediaVideoView = () => {
    return (
      <div
        className={`flex text-lg text-gray-700 h-[40vh] px-4 w-auto items-center`}
      >
        <video
          className='object-contain h-auto max-h-[95%] w-[98%] content-center'
          id={`video${selectedAttachment?.id}`}
          controls
        >
          <source
            data-testid='media-viewer-video'
            src={selectedAttachment?.url}
          />
        </video>
      </div>
    );
  };

  const renderAttachmentDownloadModal = () => {
    return (
      <Dialog
        open={isDownloadModalOpen}
        onOpenChange={(v) => setIsDownloadModalOpen(v)}
      >
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:w-[70%] grid-cols-1 bg-white p-0 max-w-2xl h-[50vh]'
        >
          <div className='w-full flex flex-col gap-2 h-full'>
            <div
              id='download-modal-header'
              className='w-full flex-row flex justify-between items-center h-8 px-2 border-b-zinc-200 border-b py-1 space-y-0'
            >
              <Button
                variant={'ghost'}
                className='flex bg-zinc-200 text-zinc-900 gap-1 h-fit p-0 rounded px-2'
                onClick={() => {
                  downloadMediaAttachment(
                    selectedAttachment?.url!,
                    selectedAttachment?.name
                  );
                }}
              >
                <Download className='h-4 w-4' />
                <span>Download</span>
              </Button>
              <span>Attached Media</span>
              <DialogClose>
                <Button variant={'ghost'} className='p-0 h-fit'>
                  <X className='h-4 w-4' />
                </Button>
              </DialogClose>
            </div>
            <div className='flex-grow h-full w-full'>
              {selectedAttachment?.type === 'image'
                ? renderMediaImageView()
                : renderMediaVideoView()}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderSingleAttachmentView = (attachment: ISavedReplyAttachment) => {
    switch (attachment.type) {
      case 'image': {
        return (
          <div
            onClick={() => {
              setSelectedAttachment(attachment);
              setIsDownloadModalOpen(true);
            }}
            key={attachment.id}
            className='h-24 w-24 cursor-pointer rounded-md border border-zinc-200/40'
          >
            <img
              src={attachment.url}
              className='h-full w-full rounded-md object-cover'
              alt={attachment.name}
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div
            key={attachment.id}
            onClick={() => {
              setSelectedAttachment(attachment);
              setIsDownloadModalOpen(true);
            }}
            className='h-24 relative w-24 grid rounded-lg border border-zinc-200/40'
          >
            <div className='relative cursor-pointer  w-full mx-auto'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-full w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md max-h-24 w-full'>
                <source src={attachment.url} />
              </video>
            </div>
          </div>
        );
      }
      case 'file': {
        return (
          <div
            key={attachment.id}
            className='h-24 group relative w-24 grid rounded-lg border border-zinc-200/40'
          >
            <button
              onClick={() =>
                downloadMediaAttachment(attachment.url, attachment.name)
              }
              className='absolute hidden group-hover:flex bg-gray-900 bg-opacity-40 rounded-md h-full w-full justify-center items-center'
            >
              <DownloadIcon className='text-white w-8 h-8' />
            </button>
            <div
              key={attachment.id}
              className='h-24 w-24 justify-center items-center flex-col flex cursor-pointer rounded-md border border-zinc-200/40'
            >
              {attachment.name.endsWith('.pdf') ? (
                <PdfFileIcon />
              ) : (
                <FileText className='w-28 h-28 text-zinc-900' />
              )}
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      <div id={'container'} className='flex flex-col gap-3'>
        {renderInfoCard('Keyword', savedReply.keyword)}
        {renderInfoCard('Saved reply', savedReply.replyText)}
        {savedReply?.attachments?.length ? (
          <div className='border border-zinc-200 rounded-md flex flex-col gap-2 p-3'>
            <p className='text-zinc-500 font-medium text-xs'>Attachments</p>
            <div className='flex gap-2 flex-wrap'>
              {savedReply?.attachments?.map((v) =>
                renderSingleAttachmentView(v)
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div>{renderAttachmentDownloadModal()}</div>
    </div>
  );
};

export default ViewSavedReply;
