import { useSelector } from 'react-redux';

const useMessageBlocksAction = <T,>({ source }: { source: T }) => {
  const authData = useSelector((state: any) => state.auth);
  let isAdminReply = ['admin', 'agent', 'bot', 'echo'].includes(source as any);
  let isZigzagView = authData?.inbox_conversation_preference !== 'left';

  let rowDirection =
    isAdminReply && isZigzagView ? 'flex-row-reverse' : 'flex-start';
  let elementDirection =
    isAdminReply && isZigzagView
      ? 'flex-col items-end'
      : 'items-start flex-col';

  return {
    isAdminReply,
    isZigzagView,
    rowDirection,
    elementDirection,
  };
};

export default useMessageBlocksAction;
