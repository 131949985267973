import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import useDebounce from 'components/customHooks/useThrottle';
import useTranslation from 'components/customHooks/useTranslation';
import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import NoSearchResultIcon from '../assets/NoSearchResultIcon.svg';

interface Props {
  value: string;
  optionList: any[];
  shouldDisableField: boolean;
  onChange: (value: any) => void;
  searchFieldPlaceholder: string;
}

export const WhatsappSingleSearchableSelectMenu: React.FC<Props> = ({
  searchFieldPlaceholder = 'Search Language',
  value: selectedValue,
  optionList,
  shouldDisableField,
  onChange,
}) => {
  const { t } = useTranslation();
  const defaultLabel =
    optionList.find((v: any) => v.value.toString() === selectedValue)?.label ??
    t('Select an option');
  const [isOpened, setIsOpened] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [allOptions, setAllOptions] = React.useState<any[]>([]);

  const searchRef = useRef<any>(null);
  const listboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (optionList) {
      setOptions([...optionList]);
      setAllOptions([...optionList]);
    }
  }, [optionList]);

  // Event listener for outside click
  const handleOutsideClick = (e: MouseEvent) => {
    if (listboxRef.current && !listboxRef.current.contains(e.target as Node)) {
      setIsOpened(false);
    }
  };

  const handleOptionSelect = (value: any) => {
    setIsOpened(false);
    onChange(value);
  };

  // Attach event listener when the component mounts
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleSearch = (query: string) => {
    const data = allOptions.filter((v) =>
      v.label.toLowerCase().includes(query.toLowerCase())
    );
    const searchedData = query.length ? data : [...allOptions];
    setOptions([...searchedData]);
  };

  const renderEmptyListView = () => {
    return (
      <div className='p-8 flex flex-col justify-center items-center'>
        <img className='' src={NoSearchResultIcon} alt='Not Found' />
        <span className='block text-sm text-center font-normal text-gray-500 mt-2'>
          {t('No result found. Try different keyword.')}
        </span>
      </div>
    );
  };

  const renderOptionView = () => {
    return (
      <Listbox
        value={selectedValue}
        disabled={shouldDisableField}
        onChange={handleOptionSelect}
      >
        <div className='relative h-full' ref={listboxRef}>
          <Listbox.Button
            disabled={shouldDisableField}
            onClick={() => {
              if (!shouldDisableField) setIsOpened(!isOpened);
            }}
            className={`relative flex justify-between w-full px-2 py-[9px] text-sm  border
             border-gray-300 rounded-md shadow-sm
              focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary
              ${
                shouldDisableField
                  ? 'cursor-not-allowed text-gray-500 bg-gray-100'
                  : 'bg-white'
              }`}
          >
            <p>{defaultLabel}</p>
            <span className='absolute inset-y-0 ltr:right-0 rtl:left-2 flex items-center pr-2 pointer-events-none'>
              {isOpened ? (
                <XCircleIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                  onClick={() => setIsOpened(false)}
                />
              ) : (
                <ChevronDownIcon
                  className='w-5 h-5 text-gray-700'
                  aria-hidden='true'
                />
              )}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            show={isOpened}
            enter='transition ease-out duration-100 transform'
            enterFrom='opacity-0 scale-95 translate-y-1'
            enterTo='opacity-100 scale-100 translate-y-0'
            leave='transition ease-in duration-75 transform'
            leaveFrom='opacity-100 scale-100 translate-y-0'
            leaveTo='opacity-0 scale-95 translate-y-1'
            afterEnter={() => {
              if (!!searchRef?.current) {
                searchRef?.current?.focus();
              }
            }}
          >
            {(ref) => (
              <div
                ref={ref}
                className='absolute right-0 z-10 w-full mt-2 mb-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 '
              >
                {allOptions.length > 5 && (
                  <SearchField
                    // @ts-ignore
                    ref={searchRef}
                    placeholder={searchFieldPlaceholder}
                    onChange={(value) => handleSearch(value.toLowerCase())}
                  />
                )}
                <div className='w-full overflow-x-hidden  overflow-y-scroll max-h-40'>
                  {options.length !== 0 && (
                    <Listbox.Options>
                      {options.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-default hover:bg-gray-100 select-none p-2 ${
                              active
                                ? 'bg-amber-100 text-gray-700'
                                : 'text-gray-500'
                            }`
                          }
                          value={option.value}
                        >
                          {({ selected }) => (
                            <div
                              className={`flex text-gray-700 justify-between ${
                                selectedValue === option.value
                                  ? 'font-medium'
                                  : ''
                              }`}
                            >
                              {option.label}
                              {selectedValue === option.value ? (
                                <span className='flex items-center text-gray-700'>
                                  <CheckIcon
                                    className='w-5 h-5 text-green-600'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  )}
                  {allOptions.length === 0 && options.length === 0 && (
                    <div className='flex justify-center items-center p-8 text-center'>
                      No option found
                    </div>
                  )}
                  {allOptions.length !== 0 &&
                    options.length === 0 &&
                    renderEmptyListView()}
                </div>
              </div>
            )}
          </Transition>
        </div>
      </Listbox>
    );
  };

  return <div className='w-full'>{renderOptionView()}</div>;
};

interface WhatsappSingleSelectMenuProps {
  optionLists: any[];
  placeholder: string;
  onChange: (value: any) => void;
  value: string;
  isDisabled?: boolean;
  hasSubLabel?: boolean;
}

export function WhatsappSingleSelectMenu({
  optionLists,
  placeholder,
  onChange,
  value,
  isDisabled = false,
  hasSubLabel = false,
}: WhatsappSingleSelectMenuProps) {
  const { t } = useTranslation();
  const selectedOption = value
    ? optionLists.find((v) => v?.value === value)
    : null;
  return (
    <Listbox disabled={isDisabled} value={value} onChange={onChange}>
      <div className='relative'>
        <Listbox.Button
          className={`w-full flex items-center justify-between border border-gray-300
        rounded-md ${
          isDisabled
            ? 'bg-gray-100 cursor-not-allowed'
            : 'bg-white cursor-default '
        } mt-1 py-[9px] px-3 text-left text-sm focus:border-0 focus:ring-2 focus:ring-primary`}
        >
          <span
            className={`block truncate p-0 ${
              isDisabled ? 'text-gray-500' : 'text-gray-700'
            }`}
          >
            {selectedOption ? t(selectedOption?.label) : placeholder}
          </span>
          <ChevronDownIcon
            className={`h-5 w-5 font-semibold ${
              isDisabled ? 'text-gray-500' : 'text-gray-700'
            }`}
            aria-hidden='true'
          />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm'>
            {optionLists?.map((singleOption: any, optionIndex: number) => (
              <Listbox.Option
                key={optionIndex}
                className={({ active }) =>
                  `relative hover:bg-gray-100 select-none py-2
                  ${
                    !!singleOption?.isDisabled && singleOption.value !== value
                      ? 'cursor-not-allowed'
                      : 'cursor-default'
                  }
                  ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`
                }
                disabled={!!singleOption?.isDisabled}
                value={singleOption?.value}
              >
                {({ selected }) => (
                  <div className='flex w-full flex-col'>
                    <div className='flex w-full justify-between px-2'>
                      <span
                        className={`block truncate ${
                          selected
                            ? 'font-medium text-gray-700'
                            : 'font-normal text-gray-600'
                        }
                      ${!!singleOption?.isDisabled ? 'text-gray-400' : ''}
                      `}
                      >
                        {t(singleOption?.label) ?? ''}
                      </span>
                      {selected ? (
                        <CheckIcon
                          className='h-5 w-5 font-bold stroke-2 text-green-600'
                          aria-hidden='true'
                        />
                      ) : null}
                    </div>
                    {hasSubLabel ? (
                      <p
                        className={`px-2 text-xs ${
                          singleOption?.isDisabled
                            ? 'text-gray-400'
                            : 'text-gray-500'
                        }`}
                      >
                        {t(singleOption?.subLabel)}
                      </p>
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

//search field component
interface SearchFieldProps {
  isApiSearch?: boolean;
  onChange: (searchQuery: string) => void;
  placeholder?: string;
}

interface SearchFieldRef {
  focus(): void;
}

const SearchField: React.FC<SearchFieldProps> = forwardRef<
  SearchFieldRef,
  SearchFieldProps
>(({ isApiSearch = false, onChange, placeholder = 'search' }, ref) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const textFieldRef = useRef<HTMLInputElement>(null);
  const serachDebounce = useDebounce(query, isApiSearch ? 500 : 0);

  useEffect(() => {
    onChange(query);
    //eslint-disable-next-line
  }, [serachDebounce]);

  // Forward the ref to the input element
  useEffect(() => {
    if (!!ref) {
      // @ts-ignore
      (ref as ForwardedRef<SearchFieldRef>).current = {
        focus: () => {
          if (textFieldRef.current) {
            textFieldRef.current.focus();
          }
        },
      };
    }
  }, [ref]);

  return (
    <div className='px-2 py-1 border-b border-gray-100'>
      <div className='relative mt-2 rounded-md shadow-sm'>
        <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-2 flex items-center pl-3 mr-2'>
          <MagnifyingGlassIcon className='text-gray-500 sm:text-sm h-4 w-4' />
        </div>
        <input
          ref={textFieldRef}
          type='text'
          name='search'
          id='search'
          className='block w-full rounded-md border-0 py-1.5 ltr:pl-8 rtl:pr-10 ltr:pr-20 rtl:pl-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
          placeholder={t(placeholder)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
          autoComplete='off'
        />
      </div>
    </div>
  );
});

export default SearchField;
