import { useEffect, useState } from 'react';
import useWhatsappMetrics from './hooks/useWhatsappMetrics';
import { useSelector } from 'react-redux';
import { WhatsappBspIcon } from 'pages/integration/utils/content';
import CustomReportingLineChart from './components/LineChart';
import TemplateAnalytics from './components/TemplateAnalytics';
import CustomPieChart from './components/PieChart';
import {
  AllConversationDataTooltip,
  ApproximateChargesTooltip,
} from '../../utility/content';

const WhatsappMetrics = () => {
  const {
    dateRange,
    platformData,
    whatsappMetricsReport,
    templateMetricsTableOffset,
  } = useSelector((state: any) => state.reporting);

  const startDate = dateRange[0]?.startDate;
  const endDate = dateRange[0]?.endDate;

  const {
    fetchWhatsappTemplateMetrics,
    handleUpdateWhatsappAccountInfo,
    fetchWhatsappConversationMetrics,
    isFetchWhatsappConversationMetricsLoading,
  } = useWhatsappMetrics();
  const [whatsappAccountInfoData, setWhatsappAccountInfoData] =
    useState<any>(null);

  useEffect(() => {
    if (whatsappAccountInfoData !== null) {
      setWhatsappAccountInfoData(null);
    }
    if (platformData?.id !== null) {
      handleWhatsappAccountInfo(platformData?.id);
    }
    //eslint-disable-next-line
  }, [platformData?.id, startDate, endDate]);

  useEffect(() => {
    if (platformData?.id !== null) {
      fetchWhatsappConversationMetrics();
    }
    //eslint-disable-next-line
  }, [platformData?.id, startDate, endDate]);

  useEffect(() => {
    if (platformData?.id !== null) {
      fetchWhatsappTemplateMetrics({ offset: templateMetricsTableOffset });
    }

    //eslint-disable-next-line
  }, [platformData?.id, startDate, endDate, templateMetricsTableOffset]);

  const handleWhatsappAccountInfo = async (id: string) => {
    try {
      const res = await handleUpdateWhatsappAccountInfo(id);
      if (res?.success === true) {
        setWhatsappAccountInfoData(res?.dataSource);
      } else {
        setWhatsappAccountInfoData(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const capitalize = (str: string) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s|_)\S/g, (match) => match.toUpperCase())
      .replace(/_/g, ' ');
  };

  const convertToArrayOfObjects = (input: any) => {
    return Object.entries(input).map(([name, value]) => ({
      name: capitalize(name),
      value,
    }));
  };

  const allConversationData = convertToArrayOfObjects(
    whatsappMetricsReport?.total_conversation_per_category || {}
  );
  const approximateCharges = convertToArrayOfObjects(
    whatsappMetricsReport?.total_cost_per_category || {}
  );

  const renderQualityRatingView = (type: string) => {
    switch (type) {
      case 'GREEN':
        return (
          <div className='border border-[#DFDFE2] text-green-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-green-500 mr-1'></span>{' '}
            High
          </div>
        );
      case 'YELLOW':
        return (
          <div className='border border-[#DFDFE2] text-gray-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-gray-500 mr-1'></span>{' '}
            Medium
          </div>
        );
      case 'RED':
        return (
          <div className='border border-[#DFDFE2] text-red-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-red-500 mr-1'></span>{' '}
            Low
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className='p-6 bg-white shadow-sm border-l'>
      <div className='flex items-center mb-5'>
        <p className='flex items-center text-[#71717A]'>
          Showing WhatsApp templates report of{' '}
          <img src={WhatsappBspIcon} alt='' className='w-5 h-5 ml-1' />{' '}
        </p>
        <p className='text-[#18181B] font-medium ml-1 mr-2'>
          {whatsappAccountInfoData?.business_name}
          <span className='text-[#71717A] font-normal ml-1'>
            ({whatsappAccountInfoData?.display_phone_number})
          </span>
        </p>
        {whatsappAccountInfoData?.quality_rating &&
          renderQualityRatingView(whatsappAccountInfoData?.quality_rating)}
      </div>
      <div className='mb-4'>
        <h3 className='text-[#111827] text-base font-medium leading-6 mb-1'>
          Conversation overview
        </h3>
        <p className='text-[#6B7280] text-sm'>
          All data insights are approximate and may vary slightly from your
          invoices due to minor data processing differences.
        </p>
      </div>
      <div className='grid grid-cols-2 gap-5'>
        <div>
          <CustomPieChart
            color='#EF7711'
            loader={isFetchWhatsappConversationMetricsLoading}
            title={`All Conversation (${
              whatsappMetricsReport?.total_conversation ?? 0
            })`}
            toolTipInfo={AllConversationDataTooltip}
            data={allConversationData}
          />
        </div>
        <CustomPieChart
          color='#EF7711'
          loader={isFetchWhatsappConversationMetricsLoading}
          title={`Approximate Charges  (${
            whatsappAccountInfoData?.currency === 'USD'
              ? '$'
              : whatsappAccountInfoData?.currency ?? ''
          }${whatsappMetricsReport?.total_cost.toFixed(2) ?? 0})`}
          toolTipInfo={ApproximateChargesTooltip}
          data={approximateCharges}
        />
      </div>
      <div className='mt-6'>
        <div className='mb-4'>
          <h3 className='text-[#111827] text-base font-medium leading-6 mb-1'>
            Conversation Reports
          </h3>
          <p className='text-[#6B7280] text-sm'>
            Track template performance over time to understand engagement
            trends.
          </p>
        </div>
        <CustomReportingLineChart
          data={whatsappMetricsReport?.conversation_reports_per_day ?? []}
          loader={false}
        />
      </div>
      <TemplateAnalytics />
    </div>
  );
};

export default WhatsappMetrics;
