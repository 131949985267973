import React from 'react';
import WitSetting from '../../common/pages/WitSetting';
import Introduction from './Introduction';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;

  //functions
  handleClose: () => void;
  setIsSideSheetOpen: (val: string) => void;
  createNlpIntegration: (
    teamId: number,
    requestPayload: createNlpRequestProps
  ) => Promise<channelCreateAPiResponseType>;
}

const WitSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  setIsSideSheetOpen,
  createNlpIntegration,
}) => {
  const { t } = useTranslation();
  const initialState = {
    name: '',
    app_id: '',
    confidence: 0,
    access_token: '',
    provider: 'wit.ai',
    api_version: '202109',
  };

  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [witSettingData, setWitSettingData] = React.useState(initialState);

  const clearState = () => {
    setWitSettingData(initialState);
    setStep(1);
    setSuccess(true);
    setLoading(false);
  };
  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2: {
        setLoading(true);
        let res = await createNlpIntegration(
          selectedProject.id,
          witSettingData
        );
        if (res.status === 200) {
          setLoading(false);
          setIsSideSheetOpen('');
          !!callbackUrl
            ? navigate(callbackUrl)
            : navigate(`/integrations/integrated`);
        } else {
          setSuccess(false);
          setLoading(false);
          setErrorMessage(res.status === 500 ? res.statusText : res.data.error);
        }
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        setLoading(false);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;
    const mandatoryKeys = ['name', 'app_id', 'access_token'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(witSettingData && witSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return checkFinishButton();
      default:
        return false;
    }
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: t('Connect Wit.ai to MyAlice'),
        };
      case 2:
        return {
          title: t('Wit.ai Settings'),
        };
    }
  };

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          clearState();
          handleClose();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={getHeaderInfo(step).title}
        confirmText={step === 1 ? t('Next') : t('Finish')}
        cancelText={t('Back')}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hideCancel={step === 1}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>{t('creating channel...')}</span>
              </div>
            ) : (
              <WitSetting
                success={success}
                nlpInfo={witSettingData}
                errorMessage={errorMessage}
                handleChange={(key: string, value: string | number) => {
                  setWitSettingData({
                    ...witSettingData,
                    [key]: value,
                  });
                }}
              />
            )}
          </>
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createNlpIntegration: (teamId: number, payloadData: createNlpRequestProps) =>
    dispatch.integration.createNlpIntegration({ teamId, payloadData }),
});

const WitSideSheetContainer = connect(mapState, mapDispatch)(WitSideSheet);

export default WitSideSheetContainer;
