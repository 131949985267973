import React from 'react';
import useTranslation from '../../../components/customHooks/useTranslation';

export const withTranslationHookHoc = (Component) => (props) => {
  const { t } = useTranslation();

  const setValueAfterTranslation = (text) => {
    const translatedText = t(text);
    return translatedText;
  };

  return <Component getTranslatedText={setValueAfterTranslation} {...props} />;
};
