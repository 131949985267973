import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTicketConfigurationSettings = () => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state: any) => state.dashboard.selectedProject
  );

  useEffect(() => {
    const isOnlyEnterpriseOrBusinessPlan = ['enterprise', 'business'].includes(
      selectedProject?.subscription_plan?.plan_type
    );
    if (!isOnlyEnterpriseOrBusinessPlan) navigate('/dashboard');
    //eslint-disable-next-line
  }, []);

  const getTicketConfigurationData = async () => {
    const result = await dispatch.settings.getTicketConfigurationData(
      selectedProject?.id
    );
    return result;
  };

  const updateConfigurationData = async (payload: any) => {
    const result = await dispatch.settings.updateTicketConfigurationData({
      projectId: selectedProject?.id,
      data: payload,
    });
    if (!result) {
      toaster.warning('Something went wrong, please try agin after some time');
    } else {
      toaster.success('Data updated successfully');
    }
    return result;
  };

  const validatePayload = (payload: any) => {
    // Check if any of the three inner properties' status is true
    const {
      has_enabled_ticket_management,
      has_enabled_ticket_hold_management,
      has_enabled_ticket_resolution_management,
    } = payload;

    const isTicketManagementEnabled = has_enabled_ticket_management.status;
    const isTicketHoldManagementEnabled =
      has_enabled_ticket_hold_management.status;
    const isTicketResolutionManagementEnabled =
      has_enabled_ticket_resolution_management.status;

    // Validate other properties
    if (isTicketManagementEnabled) {
      if (
        has_enabled_ticket_management.agent_ticket_limit < 1 ||
        has_enabled_ticket_management.agent_ticket_limit > 50
      ) {
        return {
          validate: false,
          message: 'Agent ticket limit must be between 1 and 50',
        };
      }
      if (
        has_enabled_ticket_management.fallback_ticket_distribution_time_limit <
        1
      ) {
        return {
          validate: false,
          message:
            'Fallback ticket distribution time limit must be greater than or equal to 1',
        };
      }
    }

    if (isTicketHoldManagementEnabled) {
      if (has_enabled_ticket_hold_management.customer_response_time_limit < 1) {
        return {
          validate: false,
          message:
            'Customer response time limit must be greater than or equal to 1',
        };
      }
      if (
        has_enabled_ticket_hold_management.ticket_hold_limit < 1 ||
        has_enabled_ticket_hold_management.ticket_hold_limit > 50
      ) {
        return {
          validate: false,
          message: 'Ticket hold limit must be between 1 and 50',
        };
      }
    }

    if (isTicketResolutionManagementEnabled) {
      if (
        has_enabled_ticket_resolution_management.customer_response_time_limit <
        1
      ) {
        return {
          validate: false,
          message:
            'Customer response time limit must be greater than or equal to 1',
        };
      }
    }

    // Return validation success if all conditions pass
    return { validate: true, message: 'Validation successful' };
  };

  return {
    getTicketConfigurationData,
    updateConfigurationData,
    validatePayload,
    selectedProjectId: selectedProject?.id,
  };
};

export default useTicketConfigurationSettings;
