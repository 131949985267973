import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import broadcastIcon from 'assets/icons/broadcast-modal.svg';
import LoadingLogo from 'assets/icons/component/LoadingIcon';
import useTranslation from 'components/customHooks/useTranslation';
import React, { useEffect } from 'react';
import { classNames } from 'utilities/utils';

interface NewAlertModalProps {
  isShown: boolean;
  title?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  intent?: 'primary' | 'danger' | 'warning' | 'send-now';
  onConfirm: () => void;
  onCancelComplete?: () => void;
  onToggle?: () => void;
  onClose?: () => void;
  afterOpen?: () => void | null;
  shouldRenderDescriptionAsNode?: boolean;
  description: string | React.ReactNode;
  isConfirmationLoading?: boolean;
  isCancelButtonDisabled?: boolean;
  shouldShowConfirmButton?: boolean;
  shouldShowCancelButton?: boolean;
}

const NewAlertModal: React.FC<NewAlertModalProps> = ({
  isShown = false,
  title = 'Delete',
  afterOpen = null,
  confirmButtonTitle = 'Confirm',
  cancelButtonTitle = 'Cancel',
  intent = 'primary',
  onToggle,
  onClose,
  onConfirm,
  onCancelComplete,
  description,
  shouldRenderDescriptionAsNode = false,
  isConfirmationLoading = false,
  isCancelButtonDisabled = false,
  shouldShowConfirmButton = true,
  shouldShowCancelButton = true,
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = React.useRef(null);

  useEffect(() => {
    if (isShown && !!afterOpen) {
      afterOpen();
    }
    //eslint-disable-next-line
  }, [isShown]);

  return (
    <Transition.Root show={isShown} as={React.Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-20 overflow-y-auto'
        initialFocus={cancelButtonRef}
        onClose={() => {
          if (onToggle) onToggle();
          if (onClose) onClose();
        }}
      >
        <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={`inline-block overflow-hidden text-center align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg ${
                intent === 'send-now' ? 'w-[400px]' : 'sm:w-full md:w-80'
              }`}
            >
              <div className='px-2 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                <div className='flex flex-col items-center'>
                  <div
                    className={classNames(
                      'flex items-center justify-center w-12 h-12 mx-auto rounded-3xl',
                      intent === ('primary' || intent === 'send-now') &&
                        'text-green-100 border-green-400 bg-green-100',
                      intent === 'danger' &&
                        'text-red-100 border-red-400 bg-red-100',
                      intent === 'warning' &&
                        'text-yellow-100 border-yellow-400 bg-yellow-100'
                    )}
                  >
                    {intent === 'primary' ? (
                      <CheckIcon
                        className={
                          'flex w-6 h-6 items-center justify-center text-primary'
                        }
                        aria-hidden='true'
                      />
                    ) : intent === 'send-now' ? (
                      <img src={broadcastIcon} alt='broadcast-svg' />
                    ) : (
                      <ExclamationTriangleIcon
                        className={classNames(
                          'flex w-6 h-6 items-center justify-center',
                          intent === 'danger' && 'text-red-600',
                          intent === 'warning' && 'text-yellow-600'
                        )}
                        aria-hidden='true'
                      />
                    )}
                  </div>
                  <div className='mt-4 text-center w-full'>
                    <Dialog.Title
                      as='h3'
                      className='flex items-center justify-center text-lg font-medium leading-6 text-gray-900'
                    >
                      {t(title)}
                    </Dialog.Title>
                    <div
                      className={`${
                        intent === 'send-now' ? '' : 'w-full'
                      } mt-2`}
                    >
                      {!shouldRenderDescriptionAsNode && (
                        <p className='text-sm text-gray-500'>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(description as string),
                            }}
                          />
                        </p>
                      )}
                      {shouldRenderDescriptionAsNode && description}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col px-4 py-3 space-y-3'>
                {shouldShowConfirmButton && (
                  <button
                    type='button'
                    disabled={isConfirmationLoading}
                    className={classNames(
                      'inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white  border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2  sm:w-auto sm:text-sm',
                      (intent === 'primary' || intent === 'send-now') &&
                        'bg-primary focus:ring-primary-hover hover:bg-primary-hover ',
                      intent === 'danger' &&
                        'bg-red-600 focus:ring-red-500 hover:bg-red-700 ',
                      intent === 'warning' &&
                        'bg-yellow-600 focus:ring-yellow-500 hover:bg-yellow-700 '
                    )}
                    onClick={() => onConfirm()}
                  >
                    {isConfirmationLoading && (
                      <div className='py-0.5 ltr:mr-2 rtl:ml-2'>
                        <LoadingLogo />
                      </div>
                    )}
                    {t(confirmButtonTitle)}
                  </button>
                )}
                {shouldShowCancelButton && (
                  <button
                    type='button'
                    className={classNames(
                      'inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:w-auto sm:text-sm',
                      isCancelButtonDisabled
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    )}
                    onClick={() => {
                      if (onToggle) onToggle();
                      if (onCancelComplete) {
                        onCancelComplete();
                      }
                    }}
                    ref={cancelButtonRef}
                    disabled={isCancelButtonDisabled}
                  >
                    {t(cancelButtonTitle)}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewAlertModal;
