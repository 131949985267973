import React from 'react';
import dayJs from 'dayjs';
import refreshIcon from '../../../assets/images/refresh.svg';
import { Button, IconButton, Menu, Icon, Avatar } from 'evergreen-ui';
import TagRedesign from '../../../components/elements/redesign/TagRedesign';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import AvatarComponent from '../../../../old/components/elements/redesign/AvatarComponent';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import 'dayjs/locale/ar';
dayJs.extend(relativeTime);
dayJs.extend(updateLocale);
dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a min',
    mm: '%d mins',
    h: 'an hour',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

interface Prop {
  closeSideSheet: () => void;
  currentTicket: any;
  agentGroup: any;
  agents: any;
  handleAssign: (adminId: number, type: string) => void;
  search: string;
  setSearch: (val: string) => void;
}

const AssignSideSheet: React.FC<Prop> = ({
  closeSideSheet,
  currentTicket,
  agentGroup,
  agents,
  handleAssign,
  search,
  setSearch,
}) => {
  return (
    <div className='assign-ticket__container'>
      <div className='assign-ticket__header'>
        <div className='assign-ticket__header-content'>
          <Button
            className='alice-btn__default'
            disabled={true}
            height={40}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <img src={refreshIcon} width={20} alt={'filter-icon'} />
          </Button>
          <div className='ml-10px mr-10px'>
            <p className='assign-ticket__header-content__title'>
              Assign Agent or Group
            </p>
            <p className='assign-ticket__header-content__subtitle'>
              You can assign only one agent or a group at a time
            </p>
          </div>
        </div>
        <IconButton
          appearance='minimal'
          icon='cross'
          height={40}
          onClick={() => closeSideSheet()}
        />
      </div>
      <div className='assign-ticket__info'>
        <div className='assign-ticket__info-content'>
          <p className='assign-ticket__info-content-label'>
            Currently Assigned to:
          </p>
          {currentTicket.agents.map((agent: any, i: number) => (
            <TagRedesign
              key={i}
              data={{
                value: agent.id,
                label: agent.full_name,
                avatar: agent.avatar || undefined,
                status: agent.status,
              }}
              size={12}
              isClosable={false}
              intent={'active'}
              hasAvatar={true}
            />
          ))}
          {currentTicket.groups.map((group: any, i: number) => (
            <TagRedesign
              key={i}
              data={{
                value: group.id,
                label: group.name,
                avatar: group.image || undefined,
                status: group.status,
              }}
              size={12}
              isClosable={false}
              intent={'active'}
              hasAvatar={true}
            />
          ))}
          {currentTicket.agents.length === 0 &&
            currentTicket.groups.length === 0 && (
              <TagRedesign
                data={{
                  value: null,
                  label: 'Unassigned',
                }}
                size={12}
                isClosable={false}
                intent={'active'}
                hasAvatar={false}
              />
            )}
        </div>
        <div className='mt-15px'>
          {/* <SearchInput
            className='alice-rd-search'
            height={40}
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            placeholder='Search by agent name or group...'
            width={'100%'}
          /> */}
          <div className='relative mt-1 rounded-md shadow-sm'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <MagnifyingGlassIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              type='text'
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
              placeholder='Search by agent name or group...'
            />
          </div>
        </div>
        <div className=' assign-ticket__list'>
          <p className='assign-ticket__list-title'>All Available Agents</p>
          <div className='assign-ticket__list__container'>
            <Menu.Group>
              {!!agents &&
                agents.length > 0 &&
                agents
                  .filter((agent: any) =>
                    agent.admin.full_name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((data: any, i: number) => (
                    <Menu.Item
                      key={i}
                      style={{ minHeight: 50 }}
                      onSelect={() => handleAssign(data?.admin.id, 'agent')}
                      secondaryText={
                        <div className='flex items-center gap-2'>
                          {data?.admin?.status !== 'online' && (
                            <div style={{ fontSize: '0.625rem' }}>
                              {!!data?.admin?.last_online_time
                                ? 'last active ' +
                                  dayJs(
                                    data?.admin?.last_online_time * 1000
                                  ).fromNow()
                                : ''}
                            </div>
                          )}

                          {!!currentTicket &&
                            currentTicket?.agents?.filter(
                              (agent: any) => agent.id === data?.admin?.id
                            ).length > 0 && (
                              <Icon icon={'small-tick'} size={16} />
                            )}
                        </div>
                      }
                    >
                      <div className={'flex align-center'}>
                        <AvatarComponent
                          src={data?.admin?.avatar}
                          size='w-6 h-6'
                          showStatus={true}
                          statusColor={
                            data?.admin?.status === 'online'
                              ? 'bg-green-600'
                              : data?.admin?.status === 'away'
                              ? 'bg-yellow-600'
                              : 'bg-gray-400'
                          }
                          statusSize='w-3 h-3'
                        />
                        <div className={'flex justify-between'}>
                          <div>
                            &nbsp;&nbsp;
                            {data?.admin?.full_name.length > 16 &&
                            data?.admin?.status !== 'online'
                              ? data?.admin?.full_name.substring(0, 16) + '...'
                              : data?.admin?.full_name}
                          </div>
                        </div>
                      </div>
                    </Menu.Item>
                  ))}
              {!!agents &&
                agents.filter((agent: any) =>
                  agent.admin.full_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ).length === 0 && (
                  <Menu.Item style={{ minHeight: 50 }}>
                    <div className='flex align-center'>No Agent Available</div>
                  </Menu.Item>
                )}
            </Menu.Group>
          </div>
          <br />
          <p className='assign-ticket__list-title'>All Available Groups</p>
          <div className='assign-ticket__list__container'>
            <Menu>
              <Menu.Group>
                {!!agentGroup &&
                  agentGroup.length > 0 &&
                  agentGroup
                    .filter((group: any) =>
                      group.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((data: any, i: number) => (
                      <Menu.Item
                        key={i}
                        style={{ minHeight: 50 }}
                        onSelect={() => handleAssign(data?.id, 'agentgroup')}
                        secondaryText={
                          <div>
                            {!!currentTicket &&
                              currentTicket?.groups?.filter(
                                (group: any) => group.id === data.id
                              ).length > 0 && (
                                <Icon icon={'small-tick'} size={16} />
                              )}
                          </div>
                        }
                      >
                        <div className='flex align-center'>
                          <Avatar src={data?.image} name={data?.name} />{' '}
                          &nbsp;&nbsp;{data?.name} &nbsp;{' '}
                          {data.is_smart && (
                            <span className='inline-flex items-center px-1.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800'>
                              Smart
                            </span>
                          )}
                        </div>
                      </Menu.Item>
                    ))}

                {/*no groups*/}
                {!!agentGroup &&
                  agentGroup.filter((group: any) =>
                    group.name.toLowerCase().includes(search.toLowerCase())
                  ).length === 0 && (
                    <Menu.Item style={{ minHeight: 50 }}>
                      <div className={'flex align-center'}>
                        No Groups Available
                      </div>
                    </Menu.Item>
                  )}
              </Menu.Group>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignSideSheet;
