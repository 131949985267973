import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames } from 'utilities/utils';
interface Props {
  open: boolean;
  overflow?: string;
  setOpen: (val: boolean) => void;
}

const Modal: React.FC<Props> = ({
  open,
  overflow = 'overflow-hidden',
  setOpen,
  children,
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={classNames(
                    'w-auto h-auto max-h-[70vh] max-w-[70%] transform rounded-lg bg-white p-3  text-left align-middle shadow-xl transition-all',
                    overflow
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
