import React from 'react';
import BannerView from '../common/BannerView';
import NumberCard from '../common/NumberCard';
import ChartView from '../components/ChartView';
import TicketTableView from '../components/TicketTableView';
import useAdmin from '../hooks/useAdmin';
import useTranslation from 'components/customHooks/useTranslation';
import { CardOptionsForAdminView } from '../utilities/contents';
import { ITicketInfo } from '../interface';
import { Link } from '@reach/router';

const AdminView: React.FC = () => {
  const { t } = useTranslation();
  const {
    agentStats,
    assignCount,
    platformReport,
    selectedProject,
    componentLoading,
  } = useAdmin();
  const getCardValue = (index: number) => {
    switch (index) {
      case 0:
        return assignCount?.assigned ?? 0;
      case 1:
        return assignCount?.unassigned ?? 0;
      case 2:
        return assignCount?.unreplied ?? 0;
      default:
        return agentStats?.online_count ?? 0;
    }
  };
  return (
    <div className='w-full'>
      <div className='my-5'>
        <BannerView
          title={t('Quick glimpse of your agents current status.')}
          subTitle={
            <>
              {t('Do you want to get idea in more details? You can visit the ')}
              <Link
                to={`/projects/${selectedProject?.id}/reporting?tab=agent-metrics`}
                className='pointer font-semibold'
              >
                {t('Reports.')}
              </Link>
            </>
          }
          bgColor='#CDF0DF'
          type='admin'
        />
      </div>
      <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 my-5 shadow rounded-lg'>
        {CardOptionsForAdminView.map((option: ITicketInfo, index: number) => (
          <li className='col-span-1 flex flex-col' key={index}>
            <NumberCard
              title={t(option?.title)}
              loading={componentLoading?.countApiLoading}
              value={getCardValue(index)}
              color={option?.color}
              tooltipText={t(option?.tooltip)}
              className={
                index === 0
                  ? 'ltr:rounded-l-lg rtl:rounded-r-lg'
                  : index === CardOptionsForAdminView?.length - 1
                  ? 'ltr:rounded-r-lg rtl:rounded-l-lg ltr:border-l rtl:border-r border-gray-200'
                  : 'ltr:border-l rtl:border-r border-gray-200'
              }
            />
          </li>
        ))}
      </ul>
      <div className='my-5'>
        <ChartView
          data={platformReport}
          loading={componentLoading?.reportApiLoading}
        />
      </div>
      <div className='my-5'>
        <TicketTableView
          loading={componentLoading?.agentApiloading}
          agentData={agentStats}
        />
      </div>
    </div>
  );
};

export default AdminView;
