import { useState } from 'react';
import { useParams } from '../export';

const useFilter = () => {
  // const dispatch = useDispatch();
  const { projectId, labId } = useParams();

  const [filterData, setFilterData] = useState<any>([]);

  const handleFilterDataOnChange = (index: number, filterObject: any) => {
    if (filterData.length !== 0) {
      filterData[index] = filterObject;
    }
    setFilterData(filterData);
  };

  const handleAddNewFilter = (filterObject: any) => {
    setFilterData([...filterData, filterObject]);
  };

  const removeFilterFromList = (index: number) => {
    let updatedFilterList = [...filterData];
    updatedFilterList.splice(index, 1);
    // Update the state with the new filter list
    setFilterData(updatedFilterList);
  };

  const validateFilterFields = (arr: any) => {
    // Iterate over each object in the array
    for (const obj of arr) {
      // Iterate over each key in the object
      for (const key in obj) {
        // Check if the value of the key is an empty string
        if (obj[key] === '') {
          return false; // Return false if any key has an empty string
        }
      }
    }
    return true; // Return true if no empty strings are found
  };

  return {
    labId,
    projectId,
    filterData,
    handleAddNewFilter,
    removeFilterFromList,
    validateFilterFields,
    handleFilterDataOnChange,
  };
};

export default useFilter;
