import React from 'react';
import Modal from './Modal';
import { cardInfoType } from '../interface';
import CreditCard from '../component/payment/CreditCard';
import { connect } from 'react-redux';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PaymentMethodAdd from '../component/payment/PaymentMethodAdd';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface updateCardProps {
  cardInfo: cardInfoType[];
  manageCard: (payload: any) => boolean;
  setOpenUpdateCard: (value: boolean) => void;
  userEmail: string;
  createPaymentMethod: (payload: any) => boolean;
  projectId: number;
}

const UpdateCard: React.FC<updateCardProps> = ({
  cardInfo,
  manageCard,
  setOpenUpdateCard,
  userEmail,
  createPaymentMethod,
  projectId,
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = React.useState(1);
  const [currentCard, setCurrentCard] = React.useState(
    cardInfo.filter((card) => card.default)
  );
  const [otherCard, setOtherCard] = React.useState(
    cardInfo.filter((card) => !card.default)
  );
  const [defaultLoading, setDefaultLoading] = React.useState({});
  const [deleteLoading, setDeleteLoading] = React.useState<
    { index: number; state: boolean } | {}
  >({});

  React.useEffect(() => {
    setCurrentCard(cardInfo.filter((card) => card.default));
    setOtherCard(cardInfo.filter((card) => !card.default));
    if (cardInfo.length === 0) {
      setStep(2);
    }
  }, [cardInfo]);

  const addCard = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      //@ts-ignore
      card: cardElement,
      billing_details: {
        email: userEmail,
      },
    });

    if (result.error) {
      toaster.danger('Something went wrong', {
        description: result.error.message,
        duration: 3,
      });
      setStep(1);
      return;
    }

    let data: any = {
      payment_method: result.paymentMethod.id,
      project_id: projectId,
    };

    if (cardInfo.length < 1) {
      const res = await createPaymentMethod(data);
      if (res) {
        toaster.success('Successfully added card', {
          duration: 3,
        });

        setStep(1);
      } else {
        toaster.danger('Something went wrong', {
          duration: 3,
        });
        setStep(1);
      }
    } else {
      data = { ...data, action: 'attach' };
      const response = await manageCard(data);
      if (response) {
        toaster.success('Successfully added card', {
          duration: 3,
        });
        setStep(1);
      } else {
        toaster.danger('Something went wrong', {
          duration: 3,
        });
        setStep(1);
      }
    }
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <div className='p-5'>
            <p className='text-lg text-gray-900 text-bold mb-5 border-b border-gray-300 pb-2 '>
              {t('Update Payment Method')}
            </p>
            <XMarkIcon
              className='absolute right-[20px] w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
              onClick={() => setOpenUpdateCard(false)}
            />
            <div className='mb-3'>
              <p className='text-sm text-gray-400 mb-1'>
                {t('Currently Used')}
              </p>
              {currentCard.map((card: any, i: any) => {
                return (
                  <div
                    key={i}
                    className='flex align-center'
                    id='payment-method'
                  >
                    <CreditCard
                      brand={card.brand}
                      last4={card.last4}
                      expire={`${card.exp_month}/${card.exp_year}`}
                      isDefaultLoading={
                        // @ts-ignore
                        defaultLoading.index === i
                          ? // @ts-ignore
                            defaultLoading.state
                          : false
                      }
                      isDefaultDisabled={
                        card.default ||
                        // @ts-ignore
                        (deleteLoading.index === i
                          ? // @ts-ignore
                            deleteLoading.state
                          : false)
                      }
                      onDefaultClick={async () => {
                        setDefaultLoading({ index: i, state: true });
                        const data = {
                          payment_method: card.pm_id,
                          action: 'default',
                        };
                        await manageCard(data);
                        setDefaultLoading({ index: i, state: false });
                      }}
                      isDeleteLoading={
                        // @ts-ignore
                        deleteLoading.index === i
                          ? // @ts-ignore
                            deleteLoading.state
                          : false
                      }
                      isDeleteDisabled={
                        card.default ||
                        // @ts-ignore
                        (defaultLoading.index === i
                          ? // @ts-ignore
                            defaultLoading.state
                          : false)
                      }
                      onDeleteClick={async () => {
                        setDeleteLoading({ index: i, state: true });
                        const data = {
                          payment_method: card.pm_id,
                          action: 'detach',
                        };
                        await manageCard(data);
                        setDeleteLoading({ index: i, state: false });
                      }}
                      isDefault={card.default}
                    />
                  </div>
                );
              })}
            </div>
            <div className='mb-5'>
              <p className='text-sm text-gray-400 mb-1'>{t('Others')}</p>
              <div className='max-h-60 overflow-y-scroll mb-3'>
                {otherCard.map((card: any, i: any) => {
                  return (
                    <div
                      key={i}
                      className='flex align-center'
                      id='payment-method'
                    >
                      <CreditCard
                        brand={card.brand}
                        last4={card.last4}
                        expire={`${card.exp_month}/${card.exp_year}`}
                        isDefaultLoading={
                          // @ts-ignore
                          defaultLoading.index === i
                            ? // @ts-ignore
                              defaultLoading.state
                            : false
                        }
                        isDefaultDisabled={
                          card.default ||
                          // @ts-ignore
                          (deleteLoading.index === i
                            ? // @ts-ignore
                              deleteLoading.state
                            : false)
                        }
                        onDefaultClick={async () => {
                          setDefaultLoading({ index: i, state: true });
                          const data = {
                            payment_method: card.pm_id,
                            action: 'default',
                          };
                          await manageCard(data);
                          setDefaultLoading({ index: i, state: false });
                        }}
                        isDeleteLoading={
                          // @ts-ignore
                          deleteLoading.index === i
                            ? // @ts-ignore
                              deleteLoading.state
                            : false
                        }
                        isDeleteDisabled={
                          card.default ||
                          // @ts-ignore
                          (defaultLoading.index === i
                            ? // @ts-ignore
                              defaultLoading.state
                            : false)
                        }
                        onDeleteClick={async () => {
                          setDeleteLoading({ index: i, state: true });
                          const data = {
                            payment_method: card.pm_id,
                            action: 'detach',
                          };
                          await manageCard(data);
                          setDeleteLoading({ index: i, state: false });
                        }}
                        isDefault={card.default}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <button
              type='button'
              className='bg-green-500 w-full p-2 rounded-md text-white mb-3'
              onClick={() => setOpenUpdateCard(false)}
            >
              {t('Update Changes')}
            </button>
            <p
              className='text-blue-500 text-center cursor-pointer'
              onClick={() => setStep(2)}
            >
              {t('Add new payment method')}
            </p>
          </div>
        );
      case 2:
        return (
          <>
            <div className='p-5'>
              <p className='text-base text-gray-900 text-bold text-center'>
                {t('New Payment Method')}
              </p>
              <p className='text-sm text-gray-400 text-bold mb-5 pb-2 text-center'>
                {t('We use Stripe for maximum security')}
              </p>
              <XMarkIcon
                className='absolute right-[20px] w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
                onClick={() => {
                  if (cardInfo.length === 0) {
                    setOpenUpdateCard(false);
                  } else {
                    setStep(1);
                  }
                }}
              />
              <PaymentMethodAdd />
              <button
                type='button'
                className='bg-green-500 w-full p-2 rounded-md text-white mb-3'
                onClick={() => addCard()}
              >
                {t('Add card')}
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return <Modal>{getComponent()}</Modal>;
};
const mapState = (state: any) => ({
  userEmail: state.auth.email,
});
const mapDispatch = (dispatch: any) => ({
  manageCard: (payload: any) => dispatch.newBilling.manageCard(payload),
  createPaymentMethod: (payload: any) =>
    dispatch.newBilling.createPaymentMethod(payload),
});

export default connect(mapState, mapDispatch)(UpdateCard);
