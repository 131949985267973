import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { toaster } from 'evergreen-ui';

const initialState = {
  availablePlans: [],
  subscriptionDetails: null,
  isBillingAnnually: false,
  subscriptionPlanUpdate: {},
  teamMemberCount: 1,
  billingInfo: null,
  cardInfo: [],
  paymentMethod: '',
  billingHistory: [],
  billingEstimation: null,
  shouldShowTrialBanner: true,
};

export const newBilling = {
  state: { ...initialState },
  reducers: {
    updateAvailablePlans(state, payload) {
      return { ...state, availablePlans: payload };
    },
    updateSubscriptionDetails(state, payload) {
      return { ...state, subscriptionDetails: payload };
    },
    updateIsBillingAnnually(state, payload) {
      return { ...state, isBillingAnnually: payload };
    },
    updateSubscriptionPlan(state, payload) {
      return { ...state, subscriptionPlanUpdate: payload };
    },
    updateTeamMemberCount(state, payload) {
      return { ...state, teamMemberCount: payload };
    },
    updateBillingInfo(state, payload) {
      return { ...state, billingInfo: payload };
    },
    updateCardInfo(state, payload) {
      return { ...state, cardInfo: payload };
    },
    updatePaymentMethod(state, payload) {
      return { ...state, paymentMethod: payload };
    },
    updateHistory(state, payload) {
      return { ...state, billingHistory: payload };
    },
    updateBillingEstimation(state, payload) {
      return { ...state, billingEstimation: payload };
    },
    updateShouldShowTrialBanner(state, payload) {
      return { ...state, shouldShowTrialBanner: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchAvailablePlans() {
      try {
        const res = await axios.get(config.billing.availablePlans());
        if (res.data.success) {
          dispatch.newBilling.updateAvailablePlans(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchSubscriptionDetails(teamId) {
      try {
        const res = await axios.get(config.billing.subscriptionDetails(teamId));
        if (res.data.success) {
          dispatch.newBilling.updateSubscriptionDetails(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async initiateSubscriptionPlanUpdate(payload) {
      try {
        const res = await axios.post(
          config.billing.initiateSubscriptionPlanUpdate(payload.teamId),
          payload.data
        );
        if (res.data.success) {
          dispatch.newBilling.updateSubscriptionPlan(res.data.dataSource);
        }
        return res.data.success;
      } catch (err) {
        return err.response;
      }
    },
    async handleTrialStart() {
      try {
        const res = await axios.post(config.billing.startTrial());
        if (res.data.success) {
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async createPaymentMethod(payload) {
      try {
        const res = await axios.post(
          config.billing.creatStripePaymentMethod(),
          {
            payment_method: payload.payment_method
          }
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          dispatch.newBilling.fetchPaymentDetails();
          dispatch.dashboard.fetchTeamDetails(payload.project_id);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async manageCard(payload) {
      try {
        const res = await axios.post(
          config.billing.updateStripePaymentMethod(),
          {
            payment_method: payload.payment_method,
            action: payload.action
          }
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          dispatch.newBilling.fetchPaymentDetails();
          dispatch.dashboard.fetchTeamDetails(payload.project_id);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async createSubscription(payload) {
      try {
        const res = await axios.post(
          config.billing.stripeSubscription(),
          payload
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          // dispatch.newBilling.fetchSubscriptionDetails(payload.project_id)
          // dispatch.newBilling.fetchAvailablePlans()
          // dispatch.newBilling.fetchPaymentDetails()
          // dispatch.newBilling.fetchHistories(payload.project_id)
          // dispatch.dashboard.fetchProject()
          // dispatch.newBilling.fetchEstimatedCost(payload.project_id)
        }
        return { status: res.data.success, marketPlace: 'other' };
      } catch (err) {
        return { status: false };
      }
    },
    async cancelSubscription(teamId) {
      try {
        const res = await axios.post(config.billing.cancelSubscription(teamId));
        return { success: res.data.success };
      } catch (err) {
        return { success: false };
      }
    },
    async reactivateSubscription(teamId) {
      try {
        const res = await axios.post(config.billing.reactivateSubscription(teamId));
        return { success: res.data.success };
      } catch (err) {
        return { success: false };
      }
    },
    async billingInformationUpdate(payload) {
      try {
        const res = await axios.post(
          config.billing.billingInformationUpdate(),
          payload
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
        }
        return {
          error: false,
          success: res.data.success,
          data: res.data.dataSource,
        };
      } catch (err) {
        return { error: true, success: false, data: '' };
      }
    },
    async fetchPaymentDetails() {
      try {
        const res = await axios.get(config.billing.paymentDetails());
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          dispatch.newBilling.updateCardInfo(res.data.dataSource.card_info);
          dispatch.newBilling.updateBillingInfo(
            res.data.dataSource.billing_info
          );
          dispatch.newBilling.updatePaymentMethod(
            res.data.dataSource.stripe_payment_method_id
          );
        }
        return {
          error: false,
          success: res.data.success,
          data: res.data.dataSource,
        };
      } catch (err) {
        return { error: true, success: false, data: '' };
      }
    },
    async handleCouponCode(coupon) {
      try {
        if (coupon) {
          let res = await axios.post(config.billing.billingCoupon(), {
            coupon_code: coupon,
          });
          if (res.status === 200) {
            return res.data;
          } else {
            return null;
          }
        }
      } catch (err) {
        return false;
      }
    },
    async fetchHistories(teamId) {
      try {
        const res = await axios.get(config.billing.history(teamId));
        if (res.data.success) {
          dispatch.newBilling.updateHistory(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async postFeedbacks(teamId) {
      try {
        const res = await axios.post(config.billing.feedback(teamId));
        if (res.data.success) {
          return res.data.success;
        }
      } catch (err) {
        return false;
      }
    },
    async feedback(payload) {
      try {
        const res = await axios.post(config.billing.feedback(), {
          feedback: payload.feedback,
          notify_user: payload.notify_user,
        });
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchEstimatedCost(teamId) {
      try {
        const res = await axios.get(config.billing.estimatedCost(teamId));
        if (res.data.success) {
          dispatch.newBilling.updateBillingEstimation(res.data.dataSource);
        }
        return res.data;
      } catch (err) {
        console.log(err.response);
      }
    },
    async createSubscriptionForShopify(payload) {
      /**
       * payload = {
       *    plan_id: int,
       *    action: string
       * }
       */
      try {
        const res = await axios.post(
          config.billing.handleShopifySubscription(),
          payload
        );
        if (res.data.success && !!res.data.dataSource.confirmation_url) {
          window.location.href = res.data.dataSource.confirmation_url;
        }
        return {
          status: res.data.success,
          marketPlace: 'shopify',
          confirmation_url: res.data.dataSource.confirmation_url || '',
        };
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error || 'Failed to process the request',
        });
        return {
          status: false,
          marketPlace: 'shopify',
          confirmation_url: '',
        };
      }
    },
  }),
};
