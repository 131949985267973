import React from 'react';

interface props {
  title: string;
  subtitle: string;
  icon: string;
  content: React.ReactNode;
}

const PermissionProjectHeader: React.FC<props> = ({
  title,
  subtitle,
  icon,
  content,
}) => {
  return (
    <div className='permission-project-header'>
      <div className='permission-project-header__info'>
        <img
          src={icon}
          style={{ marginRight: 20, width: 60 }}
          alt={'permission'}
        />
        <div>
          <h2 className='permission-project-header__title'>{title}</h2>
          <p className='permission-project-header__subtitle'>{subtitle}</p>
        </div>
      </div>
      <div className='permission-project-header__searchInput'>{content}</div>
    </div>
  );
};
export default PermissionProjectHeader;
