import React from 'react';
import DataGraphCard from './DataGraphCard';

interface Prop {
  data: dataProps[];
  linegraphLoader: boolean;
  column: number;
}

interface dataProps {
  data: allDataProps;
  max: maxDataProps;
  name: string;
  toolTip?: string;
}

interface allDataProps {
  name: string;
  value: number | string;
}

interface maxDataProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name?: string;
  value?: number | string;
}

const dataGraphClass = (column: number) => {
  switch (column) {
    case 3:
      return `m-3 mb-6 flex flex-col rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200  md:flex-row md:divide-y-0 md:divide-x`;
    default:
      return `m-3 mb-6 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200  md:grid-cols-${column} md:divide-y-0 md:divide-x`;
  }
};

const DataGraph: React.FC<Prop> = ({ data, linegraphLoader, column }) => {
  return (
    <div>
      <dl className={dataGraphClass(column)}>
        {data.map((item: any) => (
          <DataGraphCard
            title={item.name}
            toolTip={item.toolTip}
            maxData={item?.max || 0}
            loader={linegraphLoader}
            color={'#F3B52F'}
            graphData={item?.data || []}
          />
        ))}
      </dl>
    </div>
  );
};
export default DataGraph;
