import {
  cn,
  useAutomationWorkflow,
  WhatsappIcon,
  React,
  ActionNodeDetailsLog,
  dayjs,
} from 'pages/automationWorkflow/export';
import {
  IAutomationLogResult,
  ISingleActionLogData,
} from 'pages/automationWorkflow/interface';

interface WhatsappActionNodeProps {
  ResultIcon: JSX.Element | null;
  data: ISingleActionLogData;
}

export const WhatsappActionNode: React.FC<WhatsappActionNodeProps> = ({
  data,
  ResultIcon,
}) => {
  const { automationFlowDisplayViewType } = useAutomationWorkflow();
  const isLogView = automationFlowDisplayViewType === 'viewLog';
  const whatsappTemplateName = data?.whatsapp.selectedTemplate?.label;
  const renderDetailsLog = () => {
    const logResult: IAutomationLogResult = data.component_computation_info;
    const status = logResult?.current_component_state;
    const phoneNumber = logResult?.webhook_data?.phone_number || '';
    const errorMessage = status === 'failed' && logResult?.result?.join(' ');
    const templateStatus = logResult?.current_component_state;
    return (
      <div className='flex flex-col gap-3'>
        <p className='text-zinc-900 font-semibold leading-5'>
          {'Whatsapp Template'}
        </p>
        <div className='bg-zinc-50 p-3 flex rounded-xl justify-start gap-3'>
          <div>{ResultIcon}</div>
          <div className='flex flex-col gap-2 w-full'>
            {/* log card */}
            <div className='flex flex-col border border-zinc-200 p-3 gap-1.5 bg-white rounded-lg'>
              {/* time */}
              <div className='flex w-full justify-between'>
                <span className='text-sm text-zinc-900 font-semibold'>
                  {whatsappTemplateName}
                </span>
                <span className='text-xs text-zinc-500'>
                  {dayjs(logResult?.ends_at).format('MMM D, YYYY [at] hh:mm A')}
                </span>
              </div>
              {/* Log Info */}
              <p className='text-sm text-zinc-500'>Sent to - {phoneNumber}</p>
            </div>

            {templateStatus === 'unknown' && (
              <p className='text-xs text-[#F59E0B]'>
                Confirmation Pending from WhatsApp.
              </p>
            )}

            {errorMessage ? (
              <p className='text-xs text-[#D70000] leading-5'>{errorMessage}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex items-center'>
        <div className='mr-3'>
          <WhatsappIcon />
        </div>
        <div className='grid grid-flow-row gap-1'>
          <div className='text-sm font-semibold leading-4 text-gray-500'>
            WhatsApp Template
          </div>
          <div
            className={cn([
              'text-xs leading-4 text-gray-400',
              isLogView ? 'hidden' : '',
            ])}
          >
            (Change)
          </div>
        </div>
      </div>

      {isLogView ? (
        <ActionNodeDetailsLog render={renderDetailsLog} actionData={data} />
      ) : null}
      <div className='p-3 flex gap-2 items-center border border-gray-200 rounded-md'>
        {isLogView ? ResultIcon : null}
        <div className='text-sm space-x-1 text-gray-500'>
          <span>{whatsappTemplateName}</span>
          <span className='font-medium text-gray-700'>has sent</span>
        </div>
      </div>
    </div>
  );
};
