import React from 'react';
import { EmptyStateMessage } from '../../../utils/contents';
import { classNames } from 'utilities/utils';

interface Props {
  isFilterApplied: boolean;
}
const EmptyTicketListView: React.FC<Props> = ({ isFilterApplied }) => {
  return (
    <div className={classNames('px-5', isFilterApplied ? 'block' : 'hidden')}>
      <span className='flex justify-center'>
        {EmptyStateMessage(isFilterApplied, false, false, false, false).img}
      </span>
      <div className='flex justify-center mt-3 text-sm font-normal text-center text-gray-500'>
        <span>{EmptyStateMessage(isFilterApplied, false, false, false, false).subTitle}</span>
      </div>
    </div>
  );
};
export default EmptyTicketListView;
