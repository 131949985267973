import useTranslation from 'components/customHooks/useTranslation';
import {
  handleFileUpload,
  handleImageUpload,
  handleVideoUpload,
} from 'utilities/utils';
import { Dialog, DialogClose, DialogContent } from 'libraryV2/ui/dialog';
import defaultMediaImage from 'pages/inbox/assets/images/defaultMediaImage.svg';
import { downloadMediaAttachment } from '../../utils';
import { Download, X } from 'lucide-react';
import {
  // Accept,
  Button,
  DownloadIcon,
  FileText as DocumentIcon,
  FileAudio,
  FileText,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Fragment,
  IFormFieldComponent,
  Info as InformationCircleIcon,
  Image as PhotoIcon,
  PlayCircleIcon,
  React,
  Video as VideoCameraIcon,
  X as XMarkIcon,
  defaultFileSizeLimits,
  getAllowedMimeTypes,
  getFileMimeTypeFormat,
  getFileTypeErrorMessages,
  getHookFormRules,
  getUploadGuideMessage,
  useDropzone,
  useEffect,
  useFormContext,
  useState,
} from '../../export';

export type TFileType = 'video' | 'image' | 'audio' | 'file';

const FileUploader: React.FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const { t } = useTranslation();
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();
  const currentFieldValuePath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;
  // const currentFieldValuePath = valuePath + data.slug;
  const [currentFile, setCurrentFile] = useState(
    form.getValues(currentFieldValuePath) ?? ''
  );

  const fileType = data.type as TFileType;
  const fileMimeTypes = getAllowedMimeTypes(fileType);
  const [loading, setLoading] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [sizeLimitExceeded, setSizetLimitExceeded] = useState<boolean>(false);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const uploadFailedErrorMessage = t('Failed to upload file.Please try again!');
  const maxAllowedVideoFileSize = defaultFileSizeLimits[fileType];
  const fileSizeLimitError = getFileTypeErrorMessages(
    fileMimeTypes,
    fileType,
    maxAllowedVideoFileSize
  );

  const handleFileClear = () => {
    // updateFormState({ ...data, value: null }, parentGroupField, subGroupId);
    if (formActionType === 'VIEW') {
      return;
    }
    form.resetField(currentFieldValuePath, { defaultValue: '' });
    setCurrentFile('');
  };

  const uploadCallback = (url: string) => {
    if (!url) {
      setUploadFailed(true);
    } else {
      setLoading(false);
      setCurrentFile(url);
      form.setValue(currentFieldValuePath, url);
      form.clearErrors(currentFieldValuePath);
      setUploadFailed(false);
    }
    setLoading(false);
  };

  const uploadWrapper = (file: File) => {
    switch (fileType) {
      case 'image':
        handleImageUpload(file, uploadCallback);
        break;
      case 'video':
        handleVideoUpload(file, uploadCallback);
        break;
      default:
        handleFileUpload(file, uploadCallback);
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: getFileMimeTypeFormat(fileType) as unknown as string[],
    accept: Object.keys(
      getFileMimeTypeFormat(fileType) as Record<string, string[]>
    ),
    disabled: shouldDisableInput,
    onDrop: (files: File[]) => {
      setLoading(true);
      uploadWrapper(files[0]);
      setSizetLimitExceeded(false);
    },
    onDropRejected: () => {
      setSizetLimitExceeded(true);
      setLoading(false);
    },
    multiple: false,
    maxSize: maxAllowedVideoFileSize,
  });

  const renderMediaVideoView = () => {
    return (
      <div
        className={`flex text-lg text-gray-700 h-[40vh] px-4 w-auto items-center`}
      >
        <video
          className='object-contain h-auto max-h-[95%] w-[98%] content-center'
          controls
        >
          <source data-testid='media-viewer-video' src={currentFile} />
        </video>
      </div>
    );
  };

  const renderMediaImageView = () => {
    return (
      <div
        className={`flex text-lg px-4 justify-center text-gray-700 h-[40vh] w-auto items-center`}
      >
        <img
          className='w-auto h-full object-contain'
          alt='media'
          data-testid='media-viewer-image'
          src={currentFile}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultMediaImage;
          }}
        />
      </div>
    );
  };

  const renderAttachmentDownloadModal = () => {
    return (
      <Dialog
        open={isDownloadModalOpen}
        onOpenChange={(v) => setIsDownloadModalOpen(v)}
      >
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:w-[70%] grid-cols-1 bg-white p-0 max-w-2xl h-[50vh]'
        >
          <div className='w-full flex flex-col gap-2 h-full'>
            <div
              id='download-modal-header'
              className='w-full flex-row flex justify-between items-center h-8 px-2 border-b-zinc-200 border-b py-1 space-y-0'
            >
              <Button
                variant={'ghost'}
                className='flex bg-zinc-200 text-zinc-900 gap-1 h-fit p-0 rounded px-2'
                onClick={() => {
                  downloadMediaAttachment(currentFile!, currentFile?.name);
                }}
              >
                <Download className='h-4 w-4' />
                <span>Download</span>
              </Button>
              <span>Attached Media</span>
              <DialogClose>
                <Button variant={'ghost'} className='p-0 h-fit'>
                  <X className='h-4 w-4' />
                </Button>
              </DialogClose>
            </div>
            <div className='flex-grow h-full w-full'>
              {fileType === 'image'
                ? renderMediaImageView()
                : renderMediaVideoView()}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderHeaderPreview = () => {
    if (!currentFile) {
      return <p className='text-zinc-500 text-sm'>{t('(Empty)')}</p>;
    }
    switch (fileType) {
      case 'image':
        return (
          <div
            className='py-2 h-[140px]'
            onClick={() => {
              setIsDownloadModalOpen(true);
            }}
          >
            <img
              src={currentFile}
              alt='Block'
              className='w-auto h-full rounded-md mx-auto cursor-pointer object-cover object-top'
            />
          </div>
        );
      case 'video':
        return (
          <div
            className={`flex w-full`}
            onClick={() => {
              setIsDownloadModalOpen(true);
            }}
          >
            <div
              key={1}
              className='relative cursor-pointer  w-[50%] max-w-[190px] mx-auto'
            >
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={currentFile} />
              </video>
            </div>
          </div>
        );
      // case 'document':
      //   return (
      //     <div className='relative flex flex-col items-center'>
      //       <DocumentIcon className='w-10 h-10 text-gray-400' />
      //       <div className='w-9/12 text-center mt-2 truncate text-gray-600'>
      //         {currentFile?.split('/').pop()}
      //       </div>
      //     </div>
      //   );
      case 'audio':
        return (
          <div className='relative flex flex-col items-center'>
            <FileAudio className='w-10 h-10 text-gray-400' />
            <div className='w-9/12 text-center mt-2 truncate text-gray-600'>
              {currentFile?.split('/').pop()}
            </div>
          </div>
        );
      default:
        return (
          <div className='flex gap-2.5'>
            <div className='w-[69%] flex border items-center pl-1 rounded-md'>
              <DocumentIcon className='w-5 h-5 text-red-400' />
              <div className='text-gray-600 text-xs leading-5 ml-1'>
                {currentFile?.split('/').pop()}
              </div>
            </div>

            <div className='w-[28%]'>
              <Button
                size='sm'
                variant='outline'
                className='flex gap-2'
                onClick={() => {
                  downloadMediaAttachment(currentFile!, currentFile?.name);
                }}
              >
                <DownloadIcon className='w-3.5 h-3.5' />
                <span className='text-xs leading-5'>Download</span>
              </Button>
            </div>
          </div>
        );
    }
  };

  const renderHeaderUploadGuide = () => {
    switch (fileType) {
      case 'image':
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <PhotoIcon className='text-text-primary w-6 h-6' />
            </div>
            <p className='w-[80%] text-sm text-center text-muted-foreground'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedVideoFileSize
              )}
            </p>
          </>
        );
      case 'video':
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <VideoCameraIcon className='text-text-primary w-6 h-6' />
            </div>
            <p className='w-[80%] text-center'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedVideoFileSize
              )}
            </p>
          </>
        );
      // case 'document':
      //   return (
      //     <>
      //       <div className='py-2 px-2 border rounded-lg'>
      //         <DocumentIcon className='text-text-primary w-6 h-6' />
      //       </div>
      //       <p className='w-[80%] text-center mt-2'>
      //         {getUploadGuideMessage(
      //           fileMimeTypes,
      //           fileType,
      //           maxAllowedVideoFileSize
      //         )}
      //       </p>
      //     </>
      //   );
      case 'audio': {
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <FileAudio className='text-text-primary w-6 h-6' />
            </div>
            <p className='w-[80%] text-center mt-2'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedVideoFileSize
              )}
            </p>
          </>
        );
      }
      case 'file': {
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <FileText className='text-text-primary w-6 h-6' />
            </div>

            <p className='w-[80%] text-center mt-2'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedVideoFileSize
              )}
            </p>
            <p className='w-[80%] text-sm text-[#A1A1AA] text-center '>
              {t('Allowed file type is pdf, doc, docx or csv.')}
            </p>
          </>
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    setSizetLimitExceeded(false);
    setUploadFailed(false);
    if (currentFile) {
      form.setValue(currentFieldValuePath, currentFile);
      form.clearErrors(currentFieldValuePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileType, currentFile, currentFieldValuePath]);

  const renderFileUploader = () => (
    <FormField
      name={currentFieldValuePath}
      control={form.control}
      disabled={data.is_readonly}
      rules={getHookFormRules(data)}
      render={({ field: hookField }) => {
        return (
          <FormItem>
            <FormLabel>{data?.label_agent || data.name}</FormLabel>
            <FormControl {...getRootProps()}>
              <div className='border cursor-default border-dashed rounded-md p-6'>
                <section>
                  <div className='flex flex-col items-center justify-center  text-[#8d99ae] max-h-[138px]'>
                    {!currentFile && !loading && (
                      <Fragment>
                        <input
                          className='border border-red-500'
                          {...hookField}
                          {...getInputProps()}
                          value={!!currentFile ? currentFile : ''}
                        />
                        {renderHeaderUploadGuide()}
                      </Fragment>
                    )}
                    {loading && (
                      <div
                        className='w-8 h-8 rounded-full animate-spin border-2
          border-solid border-green-500 border-t-transparent'
                      ></div>
                    )}
                    {!!currentFile && !loading && (
                      <div className='relative w-full'>
                        <div
                          className={`absolute -top-2 -right-2 z-20 hover:cursor-pointer ${
                            shouldDisableInput ? 'hidden' : ''
                          }`}
                        >
                          <XMarkIcon
                            className='h-5 w-5 text-red-500'
                            onClick={(e: any) => {
                              e.stopPropagation();
                              handleFileClear();
                            }}
                          />
                        </div>
                        <div>{renderHeaderPreview()}</div>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </FormControl>
            {sizeLimitExceeded || uploadFailed ? (
              <p className='flex items-center text-red-500 text-sm mt-1'>
                <span className='mr-1'>
                  <InformationCircleIcon className='h-4 w-4' />
                </span>
                {uploadFailed ? uploadFailedErrorMessage : fileSizeLimitError}
              </p>
            ) : null}
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );

  const renderFileUploaderFieldView = () => {
    return (
      <div>
        <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
          <p className='text-xs text-zinc-500'>
            {t(data?.label_agent || data.name)}
          </p>
          {renderHeaderPreview()}
        </div>
        <div>{renderAttachmentDownloadModal()}</div>
      </div>
    );
  };

  return formActionType === 'VIEW'
    ? renderFileUploaderFieldView()
    : renderFileUploader();
};

export default FileUploader;
