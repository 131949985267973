import { Switch } from '@headlessui/react';
import useTranslation from 'components/customHooks/useTranslation';
import { useEffect, useState } from 'react';
import { classNames } from 'utilities/utils';

interface Props {
  value: any;
  title: string;
  subTitle: string;
  isDisable?: boolean;
  dataType: 'number' | 'timer' | 'switch' | 'textarea';
  handleValueChange: (data: any) => void;
}

const ChildItem: React.FC<Props> = ({
  value,
  title,
  subTitle,
  dataType,
  isDisable = false,
  handleValueChange,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<number>(0);
  const [timerValue, setTimerValue] = useState<{ hour: number; min: number }>({
    hour: 0,
    min: 0,
  });
  const [switchEnable, setSwitchEnable] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>('');

  const handleValueChangeEvent = () => {
    switch (dataType) {
      case 'number':
        handleValueChange(inputValue);
        break;
      case 'timer':
        handleValueChange(timerValue.hour * 60 + timerValue.min);
        break;
      case 'switch':
        handleValueChange(switchEnable);
        break;
      default:
        handleValueChange(textAreaValue);
        break;
    }
  };

  const handleParentValueChangeEvent = (value: any) => {
    switch (dataType) {
      case 'number':
        setInputValue(value);
        break;
      case 'timer':
        setTimerValue({
          hour: Math.floor(value / 60),
          min: value % 60,
        });
        break;
      case 'switch':
        setSwitchEnable(value);
        break;
      default:
        setTextAreaValue(value);
        break;
    }
  };

  useEffect(() => {
    handleParentValueChangeEvent(value);
    //eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    handleValueChangeEvent();
    //eslint-disable-next-line
  }, [
    inputValue,
    timerValue.min,
    timerValue.hour,
    switchEnable,
    textAreaValue,
  ]);

  const renderSwitchComponent = () => {
    return (
      <div className='relative mt-2 rounded-md float-right'>
        <Switch
          disabled={isDisable}
          checked={switchEnable}
          onChange={setSwitchEnable}
          className={classNames(
            switchEnable ? 'bg-primary' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
          )}
        >
          <span className='sr-only'>Use setting</span>
          <span
            aria-hidden='true'
            className={classNames(
              switchEnable
                ? 'translate-x-5  rtl:left-0'
                : 'translate-x-0 rtl:right-1/2',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out absolute'
            )}
          />
        </Switch>
      </div>
    );
  };

  const handleNumberInputCheck = (value: any) => {
    if (value === '') {
      setInputValue(value);
    }
    if (!isNaN(Number(value))) {
      value = Number(value);
      if (value > 0 && value <= 50) setInputValue(value);
    }
  };

  const renderInputComponent = () => {
    return (
      <div className='relative mt-2 rounded-md shadow-sm w-full'>
        <input
          type='number'
          min={1}
          max={50}
          value={inputValue}
          disabled={isDisable}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(event) => handleNumberInputCheck(event.target.value)}
          className={classNames(
            'block w-full min-w-[15vw] rounded-md border-0 py-1.5 px-2  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-primary focus:border-primary sm:text-sm sm:leading-6',
            isDisable
              ? 'ring-gray-200 text-gray-400'
              : 'ring-gray-300 text-gray-700'
          )}
        />
      </div>
    );
  };

  const handleTimerValueChange = (key: string, value: any) => {
    if (value === '') {
      setTimerValue({
        ...timerValue,
        [key]: 0,
      });
    } else {
      !isNaN(Number(value)) &&
        setTimerValue({
          ...timerValue,
          [key]: Number(value),
        });
    }
  };

  const renderTimeComponent = () => {
    return (
      <div className='flex justify-center items-center w-full'>
        <div className='relative mt-2 rounded-md shadow-sm w-full'>
          <input
            type='number'
            min={0}
            disabled={isDisable}
            value={timerValue.hour}
            onChange={(event) =>
              handleTimerValueChange('hour', event.target.value)
            }
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 px-2  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-primary focus:border-primary sm:text-sm sm:leading-6',
              isDisable
                ? 'ring-gray-200 text-gray-400'
                : 'text-gray-700 ring-gray-300'
            )}
            placeholder='0.00'
            aria-describedby='price-currency'
          />
          <div className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3'>
            <span className='text-gray-400 sm:text-sm' id='price-currency'>
              {t('Hour')}
            </span>
          </div>
        </div>
        <div className='mx-2 font-medium'>:</div>
        <div className='relative mt-2 rounded-md shadow-sm w-full'>
          <input
            type='number'
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 px-2  ring-1 ring-inset  placeholder:text-gray-400 focus:ring-primary focus:border-primary sm:text-sm sm:leading-6',
              isDisable
                ? 'ring-gray-200 text-gray-400'
                : 'text-gray-700 ring-gray-300'
            )}
            placeholder='0.00'
            min={0}
            disabled={isDisable}
            value={timerValue.min}
            onChange={(event) =>
              handleTimerValueChange('min', event.target.value)
            }
            aria-describedby='price-currency'
          />
          <div className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3'>
            <span className='text-gray-400 sm:text-sm' id='price-currency'>
              {t('Minute')}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderTextAreaComponent = () => {
    return (
      <div className='mt-2'>
        <textarea
          rows={4}
          disabled={isDisable}
          className={classNames(
            'block w-full rounded-md border-0 py-1.5 px-2 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-primary focus:border-primary sm:text-sm sm:leading-6',
            isDisable
              ? 'ring-gray-200 text-gray-400'
              : 'text-gray-700 ring-gray-300'
          )}
          defaultValue={''}
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)}
        />
      </div>
    );
  };

  const renderComponent = () => {
    switch (dataType) {
      case 'number':
        return renderInputComponent();
      case 'timer':
        return renderTimeComponent();
      case 'switch':
        return renderSwitchComponent();
      default:
        return renderTextAreaComponent();
    }
  };

  return (
    <div className='grid grid-flow-row-dense grid-cols-5 w-full gap-4 justify-between items-center py-4'>
      <div className='col-span-3 w-full px-4 '>
        <div className='w-full'>
          <p
            className={classNames(
              'text-sm font-medium truncate',
              isDisable ? 'text-gray-500' : 'text-gray-700'
            )}
          >
            {t(title)}
          </p>
          <p
            className={classNames(
              'flex items-center font-normal text-sm  mt-1',
              isDisable ? 'text-gray-400' : 'text-gray-500'
            )}
          >
            {t(subTitle)}
          </p>
        </div>
      </div>

      <div className='ml-auto col-span-2 w-full '>{renderComponent()}</div>
    </div>
  );
};

export default ChildItem;
