import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import AddToCartIcon from '../../../old/pages/builder/assets/images/icons/add_to_cart_block_icon.svg';
import TextVariableInputField from '../elements/eCommerceBlock/TextVariableInputFeild';
import { toaster } from 'evergreen-ui';
import { BlocksData } from '../../../utilities/content';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  type: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  handleAddToCartBlock: (payloadData: {
    changeKey: string;
    changeValue: string;
  }) => void;
}

interface dataProps {
  collection: { product_id: string; variant_id: string; quantity: string };
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const AddToCart: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  handleAddToCartBlock,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const handleInputValue = (value: string) => {
    let currentValue = value;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{') setShowAttribute(true);
    else setShowAttribute(false);
    handleAddToCartBlock({ changeKey: 'quantity', changeValue: value });
  };

  const handleSelectedAttributeValue = (value: string) => {
    setShowAttribute(false);
    let newValue = data?.collection.quantity + `${value}}}`;
    handleAddToCartBlock({ changeKey: 'quantity', changeValue: newValue });
  };

  const handleSave = () => {
    if (data?.collection.quantity.length === 0) {
      toaster.danger('Quantity can not be empty', { duration: 2 });
    } else saveBlock(id);
  };

  return (
    <BlockContainer
      id={id}
      title={BlocksData.addToCartBlock.title}
      subtitle={BlocksData.addToCartBlock.subTitle}
      save={save}
      isLoading={saveLoader}
      icon={AddToCartIcon}
      sequences={sequences}
      handleSaveClick={() => handleSave()}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='flex justify-end'>
        <div className='flex-1 h-10 mt-3 mr-3 border border-gray-300 border-solid rounded'>
          <h3 className='py-2 ml-3 text-sm text-gray-700'>Quantity</h3>
        </div>
        <div className='flex-1 h-10 mt-3 rounded'>
          <TextVariableInputField
            id={id}
            value={data?.collection.quantity}
            type={type}
            attributeList={attributeList}
            placeHolder={
              data?.collection.quantity.length === 0
                ? 'Add text and variable'
                : ''
            }
            showAttribute={showAttribute}
            setShowAttribute={setShowAttribute}
            handleOnChange={(value: string) => handleInputValue(value)}
            handleSelect={(value: string) =>
              handleSelectedAttributeValue(value)
            }
            hasWriteAccess={hasWriteAccess}
          />
        </div>
      </div>
    </BlockContainer>
  );
};

export default AddToCart;
