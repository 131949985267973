import { useState, useEffect } from 'react';

// Hook
// T is a generic type for value parameter, our case this will be string
export default function useWindowResize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const listner = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', listner);

    return () => {
      window.removeEventListener('resize', listner);
    };
  }, []);
  return { width, height };
}
