import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { ChartLoadingDummyData } from '../utilities/contents';

const LoadingChartView = () => {
  return (
    <div className='px-2 animate-pulse'>
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          width={200}
          height={140}
          data={ChartLoadingDummyData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          className={`animate-pulse`}
        >
          <CartesianGrid strokeDasharray='3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Legend />
          <Bar
            barSize={20}
            dataKey='assigned'
            fill='#F3F4F6'
            name='Ticket Assigned'
            activeBar={<Rectangle width={10} fill='#F3F4F6' stroke='#F3F4F6' />}
          />
          <Bar
            barSize={20}
            dataKey='unassigned'
            fill='#F3F4F6'
            name='Ticket Unassigned'
            activeBar={<Rectangle fill='#F3F4F6' stroke='#F3F4F6' />}
          />
          <Bar
            barSize={20}
            dataKey='unreplied'
            fill='#F3F4F6'
            name='Ticket Unreplied'
            activeBar={<Rectangle fill='#F3F4F6' stroke='#F3F4F6' />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LoadingChartView;
