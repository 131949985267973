import useTranslation from 'components/customHooks/useTranslation';
import SwitchComponent from 'library/switch/SwitchWithIcon';
import { ArrowTopRightWithBox } from 'pages/broadcast/component/broadcastPreview/svgImg';
import { useEffect, useState } from 'react';
import { useChatBotSettings } from '../hooks/useChatBotSettings';
import { classNames } from 'utilities/utils';
import usePartner from 'components/customHooks/usePartner';

interface SettingsProps {
  id: number;
  name: string;
  value: boolean;
  subTitle: string;
}

const initialValues = [
  {
    id: 1,
    name: 'Keyword',
    value: false,
    subTitle:
      'Keywords will trigger sequences even when the chat is assigned to a human.',
  },
  {
    id: 2,
    name: 'Button',
    value: false,
    subTitle:
      'Users can click on buttons and trigger a sequence even when the chat is assigned to a human.',
  },
  {
    id: 3,
    name: 'Referral',
    value: false,
    subTitle:
      'Sequences will trigger when users click on a referral URL or QR code.',
  },
];

const ChatBotSettings = () => {
  const { isPartnerRestricted } = usePartner();
  const shouldHideLearnMore = isPartnerRestricted(
    'ChatBotSettings/node/learn-more'
  );
  const { t } = useTranslation();
  const { settings, getChatBotSettings, updateChatBotSettings } =
    useChatBotSettings();
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettings] = useState<SettingsProps[]>(initialValues);

  const fetchChatBotSettingData = async () => {
    setLoading(true);
    await getChatBotSettings();
    setLoading(false);
  };

  useEffect(() => {
    fetchChatBotSettingData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    settingsData.forEach(
      (data) =>
        (data.value = !!settings[data.name.toLowerCase()]
          ? settings[data.name.toLowerCase()]
          : false)
    );
    setSettings([...settingsData]);
    //eslint-disable-next-line
  }, [settings]);

  const handleValueChange = async (id: number, value: boolean) => {
    let settingsObj = { keyword: false, button: false, referral: false };
    settingsData?.forEach((data) => {
      if (data.id === id) {
        settingsObj[data.name.toLowerCase()] = value;
      } else {
        settingsObj[data.name.toLowerCase()] = data.value;
      }
    });
    if (!!settingsObj) {
      await updateChatBotSettings(settingsObj);
    }
  };

  const loadingView = () => {
    return (
      <div className='animate-pulse'>
        <div className='w-full flex items-center justify-start px-6 py-3 border border-gray-200 rounded-md my-2'>
          <div className='bg-gray-800 h-2 w-5 px-4  rounded' />
        </div>
        <div className='w-full flex items-center justify-start px-6 py-3 border border-gray-200 rounded-md my-2'>
          <div className=' bg-gray-800 h-2 w-5 px-4 rounded' />
        </div>
        <div className='w-full flex items-center justify-start px-6 py-3 border border-gray-200 rounded-md my-2'>
          <div className=' bg-gray-800 h-2 w-5 px-4  rounded' />
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <>
        {!loading &&
          settingsData.map((setting) => {
            return (
              <div className='w-full flex items-center justify-start px-6 py-3 border border-gray-200 rounded-md my-2'>
                <div>
                  <span className='text-sm font-normal text-gray-900 block'>
                    {t(setting?.name)}&nbsp;&nbsp;
                    <span
                      className={classNames(
                        'px-1 py-0.5 rounded bg-green-100 text-green-800 text-sm font-medium',
                        setting?.name.toLowerCase() === 'button'
                          ? 'inline'
                          : 'hidden'
                      )}
                    >
                      {t('Recommended')}
                    </span>
                  </span>
                  <span className='text-sm font-normal text-gray-500 block mt-1'>
                    {t(setting?.subTitle)}
                  </span>
                </div>
                <SwitchComponent
                  isEnabled={setting?.value}
                  classNames='ltr:ml-auto rtl:mr-auto'
                  onChange={(isChecked) => {
                    handleValueChange(setting?.id, isChecked);
                  }}
                />
              </div>
            );
          })}
        {loading && loadingView()}
      </>
    );
  };

  return (
    <div className='px-6 py-4 my-4'>
      <div className={`flex items-center`}>
        <h3 className='text-lg font-semibold  text-gray-900'>
          {t('Chatbot Settings')}
        </h3>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://docs.myalice.ai/'
          className={`text-sm font-medium text-[#0078CF] ltr:ml-auto rtl:mr-auto flex justify-center items-center ${
            shouldHideLearnMore ? 'hidden' : 'visible'
          }`}
        >
          {t('Learn More')}{' '}
          <ArrowTopRightWithBox
            width={20}
            height={20}
            color='#0078CF'
            classNames='inline ltr:ml-1 rtl:mr-1'
          />
        </a>
      </div>
      <p className='text-sm font-normal text-gray-500 mt-4 '>
        {t(
          'Turning on the toggle will allow the bot to trigger sequences based on the rules even when the chat is assigned to a human. By default, we recommend turning on the button toggle and turning off the rest of the options.'
        )}{' '}
      </p>
      <div className='mt-6'>{renderSettings()}</div>
    </div>
  );
};

export default ChatBotSettings;
