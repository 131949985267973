import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { LiveChatDataProps } from 'pages/integration/interface';
import { TriangleRight } from '../../assets/img/icons/svgIcons';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  liveChatData: LiveChatDataProps;
}

const PrechatPreview: React.FC<Props> = ({ liveChatData }) => {
  const { t } = useTranslation();
  const preChatQueryFileds = () => {
    return liveChatData.livechat_data.prechat_survey_data_points?.map(
      (data, i) => {
        if (data.title && data.attribute) {
          return (
            <div
              className='my-2 rounded-md border  flex relative overflow-hidden'
              key={i}
              style={{
                borderColor: liveChatData.livechat_data.brand_color_regular,
              }}
            >
              <span className='ltr:pl-3 rtl:pr-3 py-2 text-sm font-normal text-gray-500'>
                {data.title}
              </span>
              <div
                className='absolute ltr:right-0 rtl:left-0 top-0 h-[stretch] h-[-moz-available] p-2 flex justify-center items-center'
                style={{
                  background: liveChatData.livechat_data.brand_color_regular,
                }}
              >
                <TriangleRight
                  width='16'
                  height='16'
                  fillColor={liveChatData.livechat_data.font_color_regular}
                />
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      }
    );
  };
  return (
    <div className='rounded-xl shadow-lg  h-full m-auto bg-white w-full'>
      <div
        className='flex items-center p-3 rounded-t-xl'
        style={{
          backgroundColor: liveChatData.livechat_data.brand_color_regular,
        }}
      >
        <ArrowLeftIcon
          color={liveChatData.livechat_data.font_color_regular}
          width={20}
          height={20}
        />
        <span
          className='text-base font-semibold ltr:ml-3 rtl:mr-3'
          style={{ color: liveChatData.livechat_data.font_color_regular }}
        >
          {t(liveChatData.title)}
        </span>
      </div>
      <div className='mt-3 pl-4 pr-12'>
        <div className='flex justify-start items-center py-[10px] px-3 rounded-xl bg-gray-100'>
          <span className='text-sm font-normal leading-5 text-gray-700 whitespace-pre-line'>
            {liveChatData.livechat_data.prechat_survey_message}
          </span>
        </div>
        {preChatQueryFileds()}
      </div>
    </div>
  );
};

export default PrechatPreview;
