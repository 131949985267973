import React from 'react';
import ButtonGroup from '../ButtonGroup';
import { Switch } from 'evergreen-ui';
import { getButtonTypes } from '../../../../utilities/utils';
import TextVariableInputField from '../eCommerceBlock/TextVariableInputFeild';
import AttributeAPIContainer from '../AttributeAPIContainer';

interface Props {
  blockId: number;
  type: string;
  sequences: sequenceProps[];
  platformType: string;
  subscriptionPlanType: string;
  buttons: [];
  labsData: [];
  hasWriteAccess: boolean;
  outputInstructions: {
    display_image: boolean;
    title: string;
    subtitle: string;
    url: string;
  };
  showAttributeField: number | null | string;
  setShowAttributeField: (val: string) => void;
  handleSelectedParamsAttributeValue: (
    id: number,
    name: string,
    value: string | boolean,
    changeKey: string
  ) => void;
  handleSelectedParamsInputValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleBetterdocsBlockGalleryButton: (data: any, actionType: string) => void;
  showImage: boolean;
  title: string;
  setTitle: (data: string) => void;
  subtitle: string;
  setSubtitle: (data: string) => void;
  outputParameters: string[];
  data: dataProps;
  updateDisplayImage: (id: number,
    name: string,
    value: boolean,
    changeKey: string) => void
}

interface dataProps {
  input_instructions: inputInstruction[];
  output_instructions: {
    display_image: boolean;
    title: string;
    subtitle: string;
    url: string;
    buttons: [];
  };
}

interface inputInstruction {
  name: string;
  type: string;
  value: string;
  default: string | number | null;
  verbose: string;
  isPinned: boolean;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const BetterdocsOutputInstruction: React.FC<Props> = ({
  blockId,
  type,
  sequences,
  platformType,
  subscriptionPlanType,
  buttons,
  labsData,
  hasWriteAccess,
  outputInstructions,
  outputParameters,
  showAttributeField,
  setShowAttributeField,
  handleSelectedParamsAttributeValue,
  handleSelectedParamsInputValue,
  handleBetterdocsBlockGalleryButton,
  showImage,
  title,
  setTitle,
  subtitle,
  setSubtitle,
  data,
  updateDisplayImage,
}) => {
  return (
    <>
      <div className='flex justify-between'>
        <h6 className='font-medium text-gray-700'>Display Image?</h6>
        <Switch
          className='switch-rd'
          onChange={() => {
            updateDisplayImage(
              0,
              'display_image',
              !showImage,
              'output_instructions'
            );
          }}
          height={20}
          checked={showImage}
          hasCheckIcon={false}
        />
      </div>
      <div className='mt-3'>
        <div className='w-[100%]'>
          <div className='mb-2'>
            <AttributeAPIContainer
              attributeList={outputParameters}
              showAttribute={data.output_instructions.title === '{{'}
              setShowAttribute={setShowAttributeField}
              handleSelect={handleSelectedParamsInputValue}
            >
              <TextVariableInputField
                id={'title'}
                disabled={false}
                value={
                  data.output_instructions &&
                  data.output_instructions.title !== ''
                    ? data.output_instructions.title
                    : title
                }
                type={'text'}
                attributeList={outputParameters}
                showAttribute={'title' === showAttributeField}
                setShowAttribute={setShowAttributeField}
                placeHolder={'Add text and variable'}
                handleOnChange={(value: string) => {
                  handleSelectedParamsInputValue(
                    blockId,
                    'title',
                    value,
                    `output_instructions`
                  );
                  setTitle(value);
                }}
                handleSelect={(value: any) => {
                  handleSelectedParamsAttributeValue(
                    blockId,
                    'title',
                    value,
                    'output_instructions'
                  );
                  setTitle(value);
                }}
                hasWriteAccess={hasWriteAccess}
              />
            </AttributeAPIContainer>
          </div>
          <div className=''>
            <AttributeAPIContainer
              attributeList={outputParameters}
              showAttribute={data.output_instructions.subtitle === '{{'}
              setShowAttribute={setShowAttributeField}
              handleSelect={handleSelectedParamsInputValue}
            >
              <TextVariableInputField
                id={'subtitle'}
                disabled={false}
                value={
                  data.output_instructions &&
                  data.output_instructions.subtitle !== ''
                    ? data.output_instructions.subtitle
                    : subtitle
                }
                type={'text'}
                attributeList={outputParameters}
                showAttribute={'subtitle' === showAttributeField}
                setShowAttribute={setShowAttributeField}
                placeHolder={'Add text and variable'}
                handleOnChange={(value: string) => {
                  handleSelectedParamsInputValue(
                    blockId,
                    'subtitle',
                    value,
                    `output_instructions`
                  );
                  setSubtitle(value);
                }}
                handleSelect={(value: any) => {
                  handleSelectedParamsAttributeValue(
                    blockId,
                    'subtitle',
                    value,
                    'output_instructions'
                  );
                  setSubtitle(value);
                }}
                hasWriteAccess={hasWriteAccess}
              />
            </AttributeAPIContainer>
          </div>
        </div>
      </div>
      <br />
      <div className='mb-4 border-t border-gray-200' />
      <ButtonGroup
        blockId={blockId}
        type={type}
        hasActionButton={true}
        hasWebViewHeight={true}
        hasMessengerExtension={true}
        sequences={sequences}
        hasWriteAccess={hasWriteAccess}
        formsData={labsData && labsData.filter((l: any) => l.type === 'form')}
        allowedTypes={getButtonTypes(platformType, subscriptionPlanType)}
        buttonTitle={'Create a Button'}
        maxButtonCount={3}
        buttonList={!!buttons ? buttons : []}
        handleCreate={(id: number, buttonCategory: string = 'regular') => {
          handleBetterdocsBlockGalleryButton(
            { buttonCategory: buttonCategory },
            'create'
          );
        }}
        handleUpdate={(id, data) =>
          handleBetterdocsBlockGalleryButton({ buttonData: data }, 'update')
        }
        handleDelete={(blockId, buttonIndex) =>
          handleBetterdocsBlockGalleryButton(
            { buttonIndex: buttonIndex },
            'delete'
          )
        }
        handleDropUpdate={(blockId: number, data: any) =>
          handleBetterdocsBlockGalleryButton(
            { buttonData: data },
            'changeButtonPosition'
          )
        }
        dragDirection='vertical'
      />
    </>
  );
};

export default BetterdocsOutputInstruction;
