import { Loader2 } from 'lucide-react';
import { FC } from 'react';

export const Loading: FC = () => {
  return (
    <div className='w-full flex justify-center items-center h-[90vh]'>
      <Loader2 size={48} className='text-green-500 animate-spin mb-8' />
    </div>
  );
};
