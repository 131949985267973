import React, { useState } from 'react';
import dayJs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import {
  CircledChannelIcons,
  classNames,
} from '../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { BotIcon } from 'pages/inbox/assets/iconComponent/BotIcon';
import { TicketInterface } from 'pages/inbox/inboxInterface';

dayJs.extend(relativeTime);
dayJs.extend(updateLocale);

dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});

interface Props {
  isBulkActive: boolean;
  ticketData: TicketInterface;
  currentTicket: TicketInterface;
  isTicketSelectedForBulk: (ticketId: number) => boolean;
  handleOnticketClick: (ticket: TicketInterface) => void;
}

const BotTicketCard: React.FC<Props> = ({
  ticketData,
  isBulkActive,
  currentTicket,
  handleOnticketClick,
  isTicketSelectedForBulk,
}) => {
  const { t } = useTranslation();
  const {
    id,
    customer_last_message_text,
    is_replied,
    customer_full_name,
    customer_platform_type,
  } = ticketData;

  let lastMessageText = customer_last_message_text;
  let isSelected = ticketData?.id === currentTicket?.id;
  let customerName = customer_full_name || t('Anonymous User');

  const [isHovering, setHovering] = useState<boolean>(false);

  const getTicketClassName = () => {
    if (!isBulkActive)
      return `flex h-[75px] p-3 ${
        isSelected
          ? '  border-[#2DD4BF] border-l-2 bg-gray-100'
          : ' border-b border-l-0 bg-white '
      } ${isHovering && 'bg-gray-50'}`;
    else
      return `flex h-[75px] py-3 px-2 border-b border-l-0 ${
        isHovering ? 'bg-gray-50' : 'bg-white'
      }`;
  };

  return (
    <div className='relative cursor-pointer'>
      <div
        className={getTicketClassName()}
        onClick={() => handleOnticketClick(ticketData)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {/* channel and assign icon */}
        <div className='relative flex items-center'>
          <input
            type='checkbox'
            checked={isTicketSelectedForBulk(id)}
            className={classNames(
              'mr-2 border border-gray-300 rounded text-primary without-ring',
              isBulkActive ? 'inline-block' : 'hidden'
            )}
            readOnly={true}
          />
          {CircledChannelIcons({
            width: '32',
            height: '32',
            type: customer_platform_type,
            hasHoverEffect: false,
          })}
          <div
            className={
              'absolute ltr:right-[-4px] rtl:left-0 border bottom-1 rounded-full border-white flex items-center'
            }
          >
            <BotIcon className='h-4 w-4' />
          </div>
        </div>

        {/* customer name */}
        <div className='w-full ltr:ml-4 rtl:mr-4 leading-6'>
          <div className='flex justify-between'>
            <span
              className={` flex items-center justify-between text-sm leading-6  ${
                is_replied
                  ? 'text-gray-700 font-semibold'
                  : 'text-gray-900 font-bold'
              }`}
            >
              {customerName.length > 15
                ? customerName.slice(0, 15) + '...'
                : customerName}
            </span>

            {/** date and time */}
            <div className='flex items-center gap-2'>
              <div
                className={classNames(
                  is_replied ? 'hidden' : 'inline-block',
                  'w-[6px] h-[6px] rounded-full shadow-sm bg-[#0078CF]'
                )}
              ></div>

              <span
                className={`text-[12px] font-normal ${
                  !is_replied ? 'text-[#111827]' : 'text-gray-400'
                }  `}
              >
                {!!ticketData?.customer_last_message_time &&
                  t(
                    dayJs(ticketData?.customer_last_message_time).fromNow(true)
                  )}
              </span>
            </div>
          </div>

          {/* card text message */}
          <span
            className={` block text-sm leading-5 ${
              is_replied
                ? ' font-normal text-gray-500'
                : 'font-semibold text-gray-900'
            } `}
          >
            {!!lastMessageText && lastMessageText.length > 30
              ? lastMessageText.slice(0, 30) + '...'
              : lastMessageText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BotTicketCard;
