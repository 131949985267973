import React, { useEffect } from 'react';
import { authData } from '../../../utilities/content';
import emailIcon from '../assets/images/email-icon.svg';
import timeoutIcon from '../assets/images/timeout-icon.svg';
import PropTypes from 'prop-types';
import AuthHeader from './AuthHeader';
import { Button } from '../../../library';

const ResendVerificationMail = ({
  isTimeout,
  email,
  handleSubmit,
  loading,
}) => {
  const [timeLeft, setTimeLeft] = React.useState(60);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div>
      <AuthHeader
        image={isTimeout ? timeoutIcon : emailIcon}
        title={
          isTimeout
            ? authData.resendRegisterLink.timeoutTitle
            : 'Verify Your Email'
        }
        subtitle={
          isTimeout
            ? authData.resendRegisterLink.timeoutTitle
            : 'We have sent an email to ' +
              email +
              '. Please click the link to activate your account.'
        }
        isLogo={false}
      />
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='px-4 py-4 sm:rounded-lg sm:px-10'>
          <div className='space-y-6'>
            <div>
              <Button
                isFullWidth={true}
                intent='primary'
                isLoading={loading}
                isDisabled={timeLeft > 0 && !isTimeout}
                onClick={() => {
                  handleSubmit();
                  setTimeLeft(60);
                }}
              >
                {authData.register.resendButtonTitle}
              </Button>
            </div>

            {isTimeout ? (
              ''
            ) : timeLeft > 0 ? (
              <div className='relative flex justify-center text-sm text-center'>
                <span className='px-2 text-gray-500'>
                  Link is valid for {timeLeft > 0 ? `00:${timeLeft}` : 0} sec
                </span>
              </div>
            ) : (
              <div className='relative flex justify-center text-sm text-center'>
                <span className='px-2 text-gray-500'>
                  Please click Resend Link button to generate link again
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ResendVerificationMail.propTypes = {
  isTimeout: PropTypes.bool,
  email: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default ResendVerificationMail;
