import React from 'react';
import CreateOrderSelectedListItem from './CreateOrderSelectedListItem';
import {
  SelectedProductDataProps
} from '../interfaces';

interface Prop {
  productData: SelectedProductDataProps[];
  currencySymbol: string;
}

const CreateOrderSelectedLists: React.FC<Prop> = ({
  productData,
  currencySymbol,
}) => {
  return (
    <div className='mt-4'>
      {productData.map((product, index) => (
        <CreateOrderSelectedListItem
          key={index}
          product={product}
          currencySymbol={currencySymbol}
        />
      ))}
    </div>
  );
};

export default CreateOrderSelectedLists;
