import useDynamicIntegration from 'pages/integration/hooks/useDynamicIntegration';
import {
  IDynamicIntegratedChannelProps,
  IDynamicIntegrationFieldProps,
} from 'pages/integration/interface';
import { Fragment, useEffect, useState } from 'react';
import BlankModal from 'library/modal/BlankModal';
import DynamicForm from './DynamicFormComponent';
import CollapsableComponent from 'pages/integration/components/common/components/CollapsableComponent';
import FeatureFlag from 'components/higherOrderComponents/FeatureFlag';
import { Menu, Transition } from '@headlessui/react';

import {
  EllipsisHorizontalIcon,
  PencilIcon,
  SignalIcon,
  SignalSlashIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { classNames } from 'utilities/utils';
import ConfirmationModal from 'library/modal/ConfirmationModal';
import { getDeleteDisconnectModalData } from 'pages/integration/utils/content';
import { mixpanelTrack } from 'utilities/mixpanel';
import { cloneDeep } from 'lodash';
import useTranslation from 'components/customHooks/useTranslation';

interface IFormData {
  [key: string]: string | number | string[];
}

const DynamicIntegratedChannels = () => {
  const { t } = useTranslation();
  //custom hook
  const {
    fetchDynamicIntegratedChannelList,
    updateDynamicChannelData,
    handleChannelDisconnect,
    handleChannelReconnect,
    getSingleChannelData,
    handleChannelRemove,
    otherChannelList,
  } = useDynamicIntegration();

  // all useState hook
  const [isMounted, setMounted] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [hasConfirmatioModalError, setHasConfirmatioModalError] =
    useState<boolean>(false);
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<IDynamicIntegratedChannelProps | null>(null);
  const [formData, setFormData] = useState<IFormData>({});
  const [isSubmitAble, setIsSubmitAble] = useState(false);

  const [previousFormData, setPreviousFormData] = useState<IFormData>({});
  const [isEditSubmitEnabled, setEditSubmitEnabled] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const checkIfRequiredFieldHasValue = () => {
    const integrationFields = selectedItem?.integration?.fields;
    if (integrationFields) {
      const allRequiredFields = integrationFields
        .filter((field) => field?.is_required)
        .map((field) => field?.name);

      const isAllRequiredFieldContainsValue = allRequiredFields.every(
        (fieldName) => {
          const fieldValue = formData[fieldName];
          return !(
            !fieldValue ||
            (Array.isArray(fieldValue) && fieldValue.length === 0)
          );
        }
      );
      setIsSubmitAble(isAllRequiredFieldContainsValue);
    }
    setEditSubmitEnabled(
      JSON.stringify({ ...formData }) !==
        JSON.stringify({ ...previousFormData })
    );
  };

  const updateFormData = () => {
    if (!selectedItem) return;
    let obj = {};
    for (let key in selectedItem?.data) {
      obj = { ...obj, [key]: selectedItem?.data[key] };
    }
    const findNameField = selectedItem?.integration.fields?.find(
      (data: IDynamicIntegrationFieldProps) => data?.name === 'name'
    );
    if (!!findNameField) obj = { ...obj, name: selectedItem?.name };
    setFormData(cloneDeep(obj));
    setPreviousFormData(cloneDeep(obj));
  };

  // all useEffect hook functionality

  useEffect(() => {
    checkIfRequiredFieldHasValue();
    //eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    updateFormData();
    //eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
      fetchDynamicIntegratedChannelList();
    }

    return () => {
      setMounted(false);
    };
    //eslint-disable-next-line
  }, []);

  const setInitialState = () => {
    setConfirmationLoading(false);
    setShowConfirmationModal(false);
    setModalOpen(false);
    setSelectedItem(null);
    setHasConfirmatioModalError(false);
    setActionType('');
  };

  // action handler functions

  const handleFieldChange = (
    fieldName: string,
    value: string | number | string[]
  ) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleClose = () => {
    setFormData({});
    setInitialState();
    setModalOpen(false);
    setPreviousFormData({});
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (actionType === 'edit') {
      const res = await updateDynamicChannelData({
        item: selectedItem,
        data: {
          ...formData,
        },
      });

      if (res) {
        handleClose();
      } else {
        setModalOpen(false);
        setTimeout(() => {
          setModalOpen(true);
        }, 2000);
      }
    }
    setSubmitLoading(false);
  };

  const handleEditReconnectOnClick = async (
    actionTypeName: string,
    channelId: string
  ) => {
    setActionType(actionTypeName);

    if (actionTypeName === 'edit') {
      const channelData = await getSingleChannelData(channelId);
      if (!!channelData) {
        setSelectedItem(channelData);
        setModalOpen(true);
      }
    } else if (actionTypeName === 'reconnect') {
      let alertMessage = t('Channel successfuly reconnected');
      const res = await handleChannelReconnect(channelId, alertMessage);
      if (res) handleClose();
    }
  };

  const handleChannelDisconnectOnClick = async (
    channelId: string,
    channelName: string
  ) => {
    let alertMessage = `Channel ${channelName} successfuly disconnected`;
    let res = await handleChannelDisconnect(channelId, alertMessage);
    return res;
  };

  const handleChannelRemoveOnClick = async (
    channelId: string,
    channelName: string
  ) => {
    let alertMessage = `Channel ${channelName} successfuly removed`;
    let res = await handleChannelRemove(channelId, alertMessage);
    return res;
  };

  const handleModalActionButtonClick = async (
    id: string,
    title: string,
    inputValue: string | undefined
  ) => {
    setConfirmationLoading(true);
    if (actionType === 'delete' && inputValue?.toUpperCase() !== 'DELETE') {
      setHasConfirmatioModalError(true);
      setConfirmationLoading(false);
      return false;
    }
    let res = true;
    if (!['edit', 'reconnect'].includes(actionType)) {
      actionType === 'delete'
        ? (res = await handleChannelRemoveOnClick(id, title))
        : (res = await handleChannelDisconnectOnClick(id, title));
      if (res) {
        setInitialState();
        mixpanelTrack('Deleted Channel', {
          channelId: id,
        });
      }
    }
    return res;
  };

  // all the render function for ui
  const renderCardMenu = (item: IDynamicIntegratedChannelProps) => {
    return (
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex items-center px-2 text-sm font-medium text-center transition duration-200 ease-out bg-white border border-transparent rounded-md outline-none -top-1 hover:border-transparent focus:outline-none'>
            <EllipsisHorizontalIcon
              className='w-5 h-5 -mr-1'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute bottom-full ltr:right-0 rtl:left-0 z-40 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1 cursor-pointer'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm w-full cursor-pointer'
                    )}
                    onClick={() => handleEditReconnectOnClick('edit', item?.id)}
                  >
                    <PencilIcon
                      className='w-5 h-5 ltr:mr-3 rtl:ml-3 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    {t('Edit')}
                  </span>
                )}
              </Menu.Item>
            </div>
            <div className='py-1 cursor-pointer'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm w-full cursor-pointer'
                    )}
                    onClick={() => {
                      setSelectedItem(item);
                      setActionType('delete');
                      setShowConfirmationModal(true);
                    }}
                  >
                    <TrashIcon
                      className='w-5 h-5 ltr:mr-3 rtl:ml-3 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    {t('Delete')}
                  </span>
                )}
              </Menu.Item>
            </div>
            <div className='py-1 cursor-pointer'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm w-full cursor-pointer'
                    )}
                    onClick={() => {
                      if (item?.is_active) {
                        setSelectedItem(item);
                        setActionType('connect');
                        setShowConfirmationModal(true);
                      } else {
                        handleEditReconnectOnClick('reconnect', item?.id);
                      }
                    }}
                  >
                    {item?.is_active ? (
                      <SignalSlashIcon
                        className='w-5 h-5 ltr:mr-3 rtl:ml-3 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                    ) : (
                      <SignalIcon
                        className='w-5 h-5 ltr:mr-3 rtl:ml-3 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                    )}
                    {item?.is_active ? t('Disconnect') : t('Reconnect')}
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderChannelsCardView = (items: IDynamicIntegratedChannelProps[]) => {
    return (
      <>
        {items.map((item, index) => (
          <FeatureFlag
            allowedEnvironment={['production', 'staging', 'dev']}
            key={index}
          >
            <div>
              <div
                className='p-5 border border-gray-200 rounded w-fit'
                key={index}
              >
                <div className='flex justify-between align-top'>
                  <div className='flex gap-2.5 align-top'>
                    <img
                      src={item?.integration?.logo}
                      alt={'icon_box'}
                      className='w-[32px] h-[32px] rounded-md'
                    />
                    <div className='mt-[-17px]'>
                      <p className='flex font-medium text-base align-center mt-3 mb-1 capitalize'>
                        {item?.name?.length > 25
                          ? item?.name.substring(0, 25) + '...'
                          : item?.name}
                      </p>
                      <div className='flex gap-[3px]'>
                        <p className='mt-1 text-sm text-gray-500 leading-none'>
                          {item?.is_active ? t('Connected') : t('Disconnected')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>{renderCardMenu(item)}</div>
                </div>
              </div>
            </div>
          </FeatureFlag>
        ))}
      </>
    );
  };

  const renderIntegrationModal = () => {
    return (
      <>
        {!!selectedItem && (
          <BlankModal
            open={isModalOpen}
            title={`${actionType === 'edit' ? 'Update' : 'Reconnect'} ${
              selectedItem?.name
            }`}
            headerIcon={
              <img
                src={selectedItem?.integration?.logo}
                className='w-5 h-5 rounded'
                alt={'logo'}
              />
            }
            handleClose={() => handleClose()}
            submitButtonText={
              t(selectedItem?.integration?.options?.confirm_button) || 'Submit'
            }
            cancelButtonText={
              t(selectedItem?.integration?.options?.cancel_button) || 'Cancel'
            }
            documentationLink={
              selectedItem?.integration?.options?.documentation_link ||
              'https://docs.myalice.ai'
            }
            disableSubmitButton={!isSubmitAble || !isEditSubmitEnabled}
            suggestionText={t('Need Help? ')}
            linkText={t('Read Our Guide')}
            loading={submitLoading}
            onSubmitButtonClick={() => {
              handleSubmit();
            }}
          >
            <DynamicForm
              fields={
                !!selectedItem?.integration?.fields
                  ? selectedItem?.integration?.fields
                  : []
              }
              onFieldChange={handleFieldChange}
              formData={formData}
            />
          </BlankModal>
        )}
      </>
    );
  };

  const renderConfirmationModalView = () => {
    return (
      <ConfirmationModal
        open={showConfirmationModal}
        intent={
          actionType === 'disconnect' || actionType === 'default'
            ? 'warning'
            : 'danger'
        }
        title={getDeleteDisconnectModalData(actionType).title}
        description={getDeleteDisconnectModalData(actionType).description}
        confirmText={'DELETE'}
        isConfirmButtonLoading={confirmationLoading}
        hasConfirmation={actionType === 'delete'}
        hasError={hasConfirmatioModalError}
        actionText={getDeleteDisconnectModalData(actionType).actionTitle}
        handleClose={() => setInitialState()}
        handleCancel={() => setInitialState()}
        handleAction={(inputValue: string | undefined) => {
          if (!!selectedItem)
            handleModalActionButtonClick(
              selectedItem.id,
              selectedItem?.name,
              inputValue
            );
        }}
      />
    );
  };

  const renderChannelListView = () => {
    return (
      <>
        {otherChannelList.map(
          (
            channel: {
              section: string;
              items: IDynamicIntegratedChannelProps[];
            },
            index: number
          ) => (
            <CollapsableComponent
              header={channel?.section}
              cardNumber={3}
              key={index}
            >
              {!!channel?.items && renderChannelsCardView(channel?.items)}
            </CollapsableComponent>
          )
        )}
      </>
    );
  };

  return (
    <>
      {!!otherChannelList && (
        <div>
          {/* render channel list */}
          {renderChannelListView()}

          {/* render edit recoonect modal view */}
          {renderIntegrationModal()}

          {/* render delect disconnect confirmation modal view */}
          {renderConfirmationModalView()}
        </div>
      )}
    </>
  );
};

export default DynamicIntegratedChannels;
