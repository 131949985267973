import { mixpanelAnalytics as mixpanel } from './utils';
let prevRoute: string | null = null;

export const mixpanelAnalyticsRoute = (props: any) => {
  if (prevRoute === null) {
    prevRoute = '/';
    mixpanel.track_pageview();
  }
  if (props && prevRoute !== props.location.pathname) {
    prevRoute = props.location.pathname;
    mixpanel.track_pageview();
  }
};

export const mixpanelUserAuth = (
  userId: number | string,
  data: object = {}
) => {
  mixpanel.identify(userId.toString() || '-1');
  mixpanel.people.set(data);
};

export const mixpanelUserGroup = (
  teamId: number | string,
  data: object = {}
) => {
  mixpanel.identify(teamId.toString() || '-1');
  mixpanel.people.set(data);
};

export const mixpanelTrack = (title: string, data: object = {}) =>
  mixpanel.track(title, data);
