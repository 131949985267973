import React from 'react';
import { Card, IconButton, Pane, Tab } from 'evergreen-ui';
import TextCharInfo from './TextCharInfo';
import EcommerceActionButtons from './setAttributeBlock/EcommerceActionButtons';
import cloneDeep from 'lodash/cloneDeep';
import { BlocksData } from '../../../utilities/content';
import PopupBlockContent from './PopupBlockContent';
import PopupUrlContent from './PopupUrlContent';
import BetterdocsActionButtons from './othersBlocks/BetterdocsActionButton';

interface Props {
  id: number;
  index: number;
  inputValue: string;
  category: string;
  blockType: string;
  messengerExtensions: boolean;
  hasMessengerExtension: boolean;
  hasWebViewHeight: boolean;
  webViewHeight: string;
  title: string;
  sequences: [];
  selectedType: string;
  value: string;
  closePopover: () => void;
  handleChange: (elem: object) => void;
}

const EcommerceActionButtonPopup: React.FC<Props> = ({
  id,
  index,
  title,
  category,
  blockType,
  closePopover,
  inputValue,
  messengerExtensions,
  hasMessengerExtension,
  webViewHeight,
  hasWebViewHeight,
  sequences,
  selectedType,
  value,
  handleChange,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(
    selectedType === 'product_page' ? '' : 'Sequence'
  );
  const buttonProperty = {
    id: id,
    buttonIndex: index,
    value: value,
    type: selectedType,
    title: title,
    webview_height_ratio: webViewHeight,
    messenger_extensions: messengerExtensions,
    input_value: inputValue,
  };
  const handleActionButtonSelection = (val: string) => {
    let updatedButtonProperty = cloneDeep(buttonProperty);
    let updatedTitle = '';
    BlocksData.productDiscoveryBlock.actionButtons.forEach((button: any) => {
      if (button.value === val) updatedTitle = button.name;
    });
    updatedButtonProperty = {
      ...updatedButtonProperty,
      title: updatedTitle,
      type: val,
      value: '',
    };
    setSelectedTab(val === 'product_page' ? '' : 'Sequence');
    handleChange(updatedButtonProperty);
  };

  const handleBetterdocsActionButtonSelection = (val: string) => {
    let updatedButtonProperty = cloneDeep(buttonProperty);
    let updatedTitle = '';
    BlocksData.betterdocsBlock.actionButtons.forEach((button: any) => {
      if (button.value === val) updatedTitle = button.name;
    });
    updatedButtonProperty = {
      ...updatedButtonProperty,
      title: updatedTitle,
      type: val,
      value: '',
    };
    setSelectedTab(val === 'product_page' ? '' : 'Sequence');
    handleChange(updatedButtonProperty);
  };

  const handleUpdate = (keyName: string, keyValue: string) => {
    let updatedButtonProperty = cloneDeep(buttonProperty);
    updatedButtonProperty[keyName] = keyValue;
    handleChange(updatedButtonProperty);
  };

  const getPopOverContent = (selectedType: string) => {
    switch (selectedType) {
      case 'add_to_cart':
      case 'remove_from_cart':
      case 'show_variants':
        return (
          <PopupBlockContent
            items={sequences}
            inputVal={value}
            attributeValue={inputValue}
            handleChange={(value) => handleUpdate('value', value)}
          />
        );
      case 'product_page':
        return (
          <PopupUrlContent
            category={category}
            inputVal={value.toString()}
            handleChange={(value) => handleUpdate('value', value)}
            hasExternalURL={false}
            handleWebViewHeightChange={(val: string) =>
              handleUpdate('webview_height_ratio', val)
            }
            messengerExtensions={messengerExtensions}
            handleMessengerExtensionChange={(val: string) =>
              handleUpdate('messenger_extensions', val)
            }
            hasMessengerExtension={hasMessengerExtension}
            hasWebViewHeight={hasWebViewHeight}
            webViewHeight={webViewHeight}
          />
        );
      default:
        return '';
    }
  };
  return (
    <Card className='relative p-2.5 bg-white'>
      <h4 className='mb-1 text-sm text-gray-700'>If User Clicks</h4>
      <div className='button-popup__absolute_-right focus:border-none focus:bg-none'>
        <IconButton
          onClick={() => closePopover()}
          marginBottom={16}
          appearance='minimal'
          icon='cross'
        />
      </div>
      <input
        className={
          'rounded px-2 h-9 w-full border border-gray-300 focus:outline-none focus:ring-primary focus:border-primary'
        }
        value={title}
        onChange={(e: any) => {
          if (e.target.value.length <= 20)
            handleUpdate('title', e.target.value);
        }}
      />
      <div className='mt-2'>
        <TextCharInfo data={title} count={20} />
      </div>
      <Pane className='button-popup__tab'>
        <br />
        {blockType === 'product_discovery' && (
          <>
            <h4 className='mb-1 text-sm text-gray-700'>Select Action</h4>
            <EcommerceActionButtons
              selectedType={selectedType}
              handleActionButtonSelection={(val: string) =>
                handleActionButtonSelection(val)
              }
            />
            <br />
            {selectedTab.length !== 0 && (
              <Tab
                isSelected={true}
                aria-controls={`panel-${selectedType}`}
                className={`button-popup__tab-item tab-selected`}
              >
                {selectedTab}
              </Tab>
            )}
          </>
        )}
        {blockType === 'betterdocs' && (
          <>
            <h4 className='mb-1 text-sm text-gray-700'>Select Action</h4>
            <BetterdocsActionButtons
              selectedType={selectedType}
              handleActionButtonSelection={(val: string) =>
                handleBetterdocsActionButtonSelection(val)
              }
            />
            <br />
            <Tab
              isSelected={true}
              aria-controls={`panel-${selectedType}`}
              className={`button-popup__tab-item tab-selected`}
            >
              Link
            </Tab>
            <Pane
              role='tabpanel'
              aria-labelledby={selectedType}
              className='button-popup__tab-content'
            >
              <PopupUrlContent
                category={category}
                inputVal={value.toString()}
                handleChange={(value) => handleUpdate('value', value)}
                hasExternalURL={false}
                handleWebViewHeightChange={(val: string) =>
                  handleUpdate('webview_height_ratio', val)
                }
                messengerExtensions={messengerExtensions}
                handleMessengerExtensionChange={(val: string) =>
                  handleUpdate('messenger_extensions', val)
                }
                hasMessengerExtension={hasMessengerExtension}
                hasWebViewHeight={hasWebViewHeight}
                webViewHeight={webViewHeight}
              />
            </Pane>
          </>
        )}

        {blockType !== 'betterdocs' && (
          <Pane
            role='tabpanel'
            aria-labelledby={selectedType}
            className='button-popup__tab-content'
          >
            {getPopOverContent(selectedType)}
          </Pane>
        )}
      </Pane>
    </Card>
  );
};

export default EcommerceActionButtonPopup;
