import React from 'react';
import ErrorIcon from '../../../assets/icons/error.svg';
import { Button } from 'library';
import { myaliceSupportLink } from '../../../utilities/content';
import { connect } from 'react-redux';

interface Props {
  closeModal: () => void;
  tryAgain: () => void;
}

const UnsuccessModal: React.FC<Props> = ({ closeModal, tryAgain }) => {
  return (
    <div className='flex w-full p-8 flex-column'>
      <img className='w-12 h-12 m-auto' src={ErrorIcon} alt='' />
      <p className='mb-2 font-sans text-xl font-bold text-center'>
        Team creation unsuccessful
      </p>
      <p className='font-sans text-sm text-center text-gray-500'>
        Your request to create a new team was unsuccessful. Please try to create
        your team again. If the issue persists, please{' '}
        <span>
          <a
            target='_blank'
            href={myaliceSupportLink}
            rel='noreferrer noopener'
            style={{ color: 'blue' }}
          >
            Contact Support
          </a>
        </span>
      </p>
      <Button
        className='flex justify-center w-1/2 m-auto mt-4'
        intent='primary'
        onClick={() => tryAgain()}
      >
        Try Again
      </Button>
      <Button
        className='flex justify-center w-1/2 m-auto mt-2'
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  callTeamCreate: (payload: any) => dispatch.dashboard.teamCrate(payload),
});

export default connect(mapState, mapDispatch)(UnsuccessModal);
