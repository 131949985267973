import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import { Spinner } from 'evergreen-ui';
import React from 'react';
import DataBarChart from './DataBarChart';
interface Props {
  maxData: maxDataProps;
  graphData: graphDataProps[];
  title: string;
  color: string;
  loader: boolean;
  toolTip?: string;
}
interface graphDataProps {
  name: string;
  value: string | number;
}
interface maxDataProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name: string;
  loader: boolean;
}
const DataGraphCard: React.FC<Props> = ({
  maxData,
  graphData,
  color,
  title,
  loader,
  toolTip,
}) => {
  const { t } = useTranslation();
  return (
    <div className='flex w-full px-3 py-5 md:block lg:flex'>
      <div>
        <dt className='flex text-base font-normal text-gray-900'>
          {t(title)}
          {!!toolTip ? (
            <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(toolTip)}>
              <InformationCircleIcon className='w-4 h-4' />
            </span>
          ) : (
            ''
          )}
        </dt>
        {!loader && (
          <dd className='items-baseline justify-start mt-1 md:block'>
            <div className='items-baseline text-2xl font-semibold text-gray-900'>
              {maxData.current_value || 0}
            </div>
            <div className='flex rtl:ml-8 ltr:mr-8'>
              <span className='text-sm font-medium text-gray-500'>
                {title === 'Time Zone'
                  ? `GMT ${maxData.name}`?.toUpperCase()
                  : maxData.name?.toUpperCase()}
              </span>
              <div
                className={
                  'inline-flex ltr:ml-4 rtl:mr-4 items-baseline rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                }
              >
                {maxData.growth_direction === '+' ? (
                  <ArrowSmallUpIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
                    aria-hidden='true'
                  />
                ) : maxData.growth_direction === '-' ? (
                  <ArrowSmallDownIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
                    aria-hidden='true'
                  />
                ) : (
                  <ArrowSmallUpIcon
                    className='ltr:-ml-1 rtl:ml-0.5 rtl:-mr-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-gray-500'
                    aria-hidden='true'
                  />
                )}
                <span className='sr-only'>
                  {maxData.growth_direction === '+'
                    ? 'Increased'
                    : maxData.growth_direction === '-'
                    ? 'Decreased'
                    : ''}{' '}
                  by
                </span>
                {maxData.growth_value || 'N/A'}
              </div>
            </div>
          </dd>
        )}
        {loader && (
          <div className='flex justify-center h-300px align-center'>
            <Spinner />
          </div>
        )}
      </div>
      <DataBarChart graphData={graphData} color={color} loader={loader} />
    </div>
  );
};
export default DataGraphCard;
