import React from 'react';
import { TrashIcon } from '@heroicons/react/20/solid';
import Button from '../../../../../library/button';
import SequenceDropDown from './SequenceDropDown';
import { sequenceProps, persistentMenuDataProps } from '../../../interface';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  id: number;
  onDelete: () => void;
  onAdd: () => void;
  data: persistentMenuDataProps;
  isLastElement: boolean;
  onDataChange: (key: string, value: any) => void;
  sequences: sequenceProps[];
}

const IceBreaker: React.FC<props> = ({
  id,
  onDelete,
  onAdd,
  data,
  isLastElement,
  onDataChange,
  sequences,
}) => {
  const { t } = useTranslation();
  const getSelectedSequence = () => {
    const sequence = sequences.filter(
      (itm: sequenceProps) => itm.id === data.value
    );
    if (!!sequence) return sequence[0];
    else return undefined;
  };
  return (
    <div className='flex mb-3 gap-3'>
      <div className='w-1/2 h-10'>
        <input
          type='text'
          placeholder={t('Provide title here...')}
          value={data.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onDataChange(e.target.name, e.target.value)
          }
          name='title'
          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
      </div>
      <div className='flex gap-3 justify-end w-1/2 h-10 rounded'>
        <div className='relative block w-full mt-1 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'>
          <SequenceDropDown
            hasWriteAccess={true}
            sequences={sequences}
            className='pl-1'
            selectedSequence={getSelectedSequence()}
            handleSelectedSequence={(name: string, id: number) =>
              onDataChange('value', id)
            }
          />
        </div>
        <Button
          icon={<TrashIcon className='text-gray-500' />}
          className='h-10 mt-1 text-gray-700 border-gray-300'
          onClick={() => onDelete()}
        />
      </div>
    </div>
  );
};

export default IceBreaker;
