import config from 'utilities/config';
import { FormDataAxios } from 'utilities/utils';
import Compressor from 'compressorjs';
import {
  ISavedReply,
  ISavedReplyAttachment,
} from './components/SavedReplySheet';
import { FileText, ImageIcon, Video } from 'lucide-react';
export type TFileUploadResult = {
  name: string;
  size: number;
  url: string;
};
export const handleImageUpload = (
  file: File,
  callback: (url: TFileUploadResult | null) => void,
  uploadProgress: (progressEvent: ProgressEvent) => void
) => {
  if (!file) {
    return;
  }
  const requestConfig: any = {};
  const finalResult: TFileUploadResult = {
    name: file.name,
    size: file.size,
    url: '',
  };
  if (uploadProgress) {
    requestConfig.onUploadProgress = uploadProgress;
  }
  if (file?.type === 'image/gif') {
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.misc.image(), formData, requestConfig)
      .then((res) => {
        callback({
          ...finalResult,
          url: res.data.url,
        });
      })
      .catch((err) => {
        console.log(err);
        callback(null);
      });
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, (result as File)?.name);
        FormDataAxios()
          .post(config.misc.image(), formData, requestConfig)
          .then((res) => {
            callback({
              ...finalResult,
              url: res.data.url,
            });
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const handleVideoUpload = (
  file: File,
  callback: (url: TFileUploadResult | null) => void,
  uploadProgress: (progressEvent: ProgressEvent) => void
) => {
  if (!file) {
    return callback(null);
  }
  const requestConfig: any = {};
  const finalResult = {
    name: file?.name,
    size: file?.size,
    url: '',
  };
  if (uploadProgress) {
    requestConfig.onUploadProgress = uploadProgress;
  }
  if (file?.type.startsWith('video')) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    FormDataAxios()
      .post(config.misc.video(), formData, requestConfig)
      .then((res) => {
        callback({
          ...finalResult,
          url: res.data.url,
        });
      })
      .catch((err) => {
        console.log(err);
        callback(null);
      });
  } else {
    new Compressor(file, {
      quality: 0.7,
      maxHeight: 1000,
      success(result) {
        const formData = new FormData();
        formData.append('file', result, (result as File).name);
        FormDataAxios()
          .post(config.misc.video(), formData, requestConfig)
          .then((res) => {
            callback({
              ...finalResult,
              url: res.data.url,
            });
          })
          .catch((err) => console.log(err));
      },
      error(err) {
        console.log(err.message);
      },
    });
  }
};

export const handleFileUpload = (
  file: File,
  callback: (url: TFileUploadResult | null) => void,
  uploadProgress: (progressEvent: ProgressEvent) => void
) => {
  if (!file) {
    return;
  }
  const requestConfig: any = {};
  const finalResult = {
    name: file.name,
    size: file.size,
    url: '',
  };
  if (uploadProgress) {
    requestConfig.onUploadProgress = uploadProgress;
  }
  const formData = new FormData();
  formData.append('file', file, file.name);
  FormDataAxios()
    .post(config.misc.file(), formData, requestConfig)
    .then((res) => {
      callback({
        ...finalResult,
        url: res.data.url,
      });
    })
    .catch((err) => {
      console.log(err);
      callback(null);
    });
};

export const getAttachmentFormat = (
  attachmentObj: any = {},
  type: ISavedReplyAttachment['type'] = 'image'
): ISavedReplyAttachment | undefined => {
  const id = Math.random().toString(36).slice(2, 9) + '_' + Date.now();
  return {
    id,
    type,
    ...attachmentObj,
  };
};

export function bytesToSize(bytes: number = 0) {
  if (bytes === 0) return '0 Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const idx = Math.floor(Math.log(bytes) / Math.log(1024));
  const readableSize = (bytes / Math.pow(1024, idx)).toFixed(2);

  return `${readableSize} ${sizes[idx]}`;
}
export interface ISavedReplyApiResponse {
  id: number;
  admin: number;
  project: {
    id: number;
    name: string;
  };
  title: string;
  text: string;
  for_team: boolean;
  attachments: {
    id: string;
    url: string;
    name: string;
    size: number;
    type: 'image' | 'file' | 'video';
  }[];
  tags: string[];
}

export function mapSavedReplyApiResponseToState(
  savedReplyApiResponse: ISavedReplyApiResponse
) {
  const { id, title, text, attachments, for_team } = savedReplyApiResponse;
  const formatedKeyword = title.startsWith(REPLY_TRIGGER_CHAR + ' ')
    ? title
    : `${REPLY_TRIGGER_CHAR} ${title}`;

  const result: ISavedReply = {
    id: id,
    keyword: formatedKeyword,
    replyText: text,
    visibleTo: for_team ? 'everyone' : 'only_me',
    attachments: attachments as ISavedReplyAttachment[],
  };
  return result;
}

export const MAX_KEYWORD_LENGTH = 180;
export const MAX_SAVED_REPLY_LENGTH = 1000;
export const MAX_SAVED_REPLY_ATTACHMENT_COUNT = 10;
export const REPLY_TRIGGER_CHAR = '#';

export const ATTACHMENTS_BUTTONS = [
  {
    label: 'Image',
    icon: <ImageIcon className='w-4 h-4 text-zinc-900' />,
    value: 'image',
  },
  {
    label: 'Video',
    icon: <Video className='w-4 h-4 text-zinc-900' />,
    value: 'video',
  },
  {
    label: 'File',
    icon: <FileText className='w-4 h-4 text-zinc-900' />,
    value: 'file',
  },
];

export const ATTACHMENTS_MIME_TYPES = {
  image: ['image/png', 'image/jpeg', 'image/gif'],
  video: ['video/mp4'],
  file: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
};

export const initialSavedReplyState = {
  keyword: '',
  replyText: '',
  attachments: [],
  visibleTo: 'only_me',
};

export const getReplySheetContents = (
  actionType: 'EDIT' | 'VIEW' | 'CREATE'
) => {
  switch (actionType) {
    case 'CREATE': {
      return {
        title: 'Create New Saved Reply',
        description: 'Enter the details below to create a new saved reply.',
        primaryActionText: 'Create New Saved Reply',
        secondaryActionText: 'Cancel',
      };
    }
    case 'EDIT': {
      return {
        title: 'Edit Saved Reply',
        description: 'Edit the details to make changes to this saved reply.',
        primaryActionText: 'Save Changes',
        secondaryActionText: 'Cancel',
      };
    }
    default: {
      return {
        title: 'View Details',
        description: '',
        primaryActionText: 'Edit Saved Reply',
        secondaryActionText: 'Cancel',
      };
    }
  }
};

export const PdfFileIcon = ({ size = 32 }) => {
  return (
    <svg
      width={size + ''}
      height={size + ''}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.19922 3.20001C3.19922 1.4327 4.63191 7.62939e-06 6.39922 7.62939e-06H19.1992L28.7992 9.60001V28.8C28.7992 30.5673 27.3665 32 25.5992 32H6.39922C4.63191 32 3.19922 30.5673 3.19922 28.8V3.20001Z'
        fill='#E11D48'
      />
      <path
        opacity='0.3'
        d='M19.1992 1.52588e-05L28.7992 9.60001H22.3992C20.6319 9.60001 19.1992 8.16733 19.1992 6.40001V1.52588e-05Z'
        fill='#FFF1F2'
      />
      <path
        d='M9.39847 25.4V20.1637H11.4644C11.8615 20.1637 12.1999 20.2395 12.4794 20.3912C12.759 20.5412 12.9721 20.75 13.1186 21.0176C13.2669 21.2835 13.3411 21.5904 13.3411 21.9381C13.3411 22.2858 13.2661 22.5926 13.1161 22.8585C12.9661 23.1244 12.7488 23.3316 12.4641 23.4798C12.1811 23.6281 11.8385 23.7023 11.4363 23.7023H10.1195V22.8151H11.2573C11.4703 22.8151 11.6459 22.7784 11.784 22.7051C11.9238 22.6301 12.0277 22.527 12.0959 22.3958C12.1658 22.2628 12.2007 22.1102 12.2007 21.9381C12.2007 21.7642 12.1658 21.6125 12.0959 21.483C12.0277 21.3517 11.9238 21.2503 11.784 21.1787C11.6442 21.1054 11.4669 21.0688 11.2522 21.0688H10.5056V25.4H9.39847ZM15.9164 25.4H14.0602V20.1637H15.9318C16.4585 20.1637 16.9119 20.2685 17.292 20.4781C17.6721 20.6861 17.9645 20.9852 18.169 21.3756C18.3752 21.7659 18.4784 22.233 18.4784 22.7767C18.4784 23.3222 18.3752 23.7909 18.169 24.183C17.9645 24.575 17.6704 24.8759 17.2869 25.0855C16.9051 25.2952 16.4483 25.4 15.9164 25.4ZM15.1673 24.4514H15.8704C16.1977 24.4514 16.473 24.3935 16.6963 24.2776C16.9213 24.16 17.09 23.9784 17.2025 23.733C17.3167 23.4858 17.3738 23.1671 17.3738 22.7767C17.3738 22.3898 17.3167 22.0736 17.2025 21.8281C17.09 21.5827 16.9221 21.402 16.6988 21.2861C16.4755 21.1702 16.2002 21.1122 15.873 21.1122H15.1673V24.4514ZM19.2985 25.4V20.1637H22.7655V21.0764H20.4056V22.3242H22.5354V23.2369H20.4056V25.4H19.2985Z'
        fill='#FFF1F2'
      />
    </svg>
  );
};

export const downloadMediaAttachment = async (
  mediaUrl: string,
  fileName: string = ''
) => {
  try {
    let blob = await fetch(mediaUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => res.blob());
    let url = URL.createObjectURL(blob);
    let element = document.createElement('a');
    element.href = url;
    element.download = fileName ?? mediaUrl.split('/').pop();
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(mediaUrl);
    // else {
    //   const imageData = attachmentData.data.imageData;
    //   const byteCharacters = atob(imageData);
    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    //   const url = URL.createObjectURL(blob);
    //   const element = document.createElement('a');
    //   element.href = url;
    //   element.download = 'image.png';
    //   element.click();
    //   URL.revokeObjectURL(url);
    // }
  } catch (err) {
    console.log('Unable to download media', err);
  }
};
