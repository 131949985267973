import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '../../../../../library/button';
import CreditCard from './CreditCard';
import CardSection from './CardSection';
import { toaster } from 'evergreen-ui';

const PaymentMethod = ({
  handlePaymentMethod,
  handleCardAction,
  userEmail,
  cardInfo,
  hasWriteAccess,
  projectId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({});
  let rewardlyReferral = '';

  const handleSubmit = async (type) => {
    if (!stripe || !elements) {
      return;
    }

    setDisabled(true);

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: userEmail,
      },
    });
    if (result.error) {
      toaster.danger('Something went wrong', {
        description: result.error.message,
        duration: 3,
      });
      setDisabled(false);
      return;
    } else {
      toaster.success('Card Added', {
        description: 'Successfully added card',
        duration: 3,
      });
    }
    let data = {
      payment_method: result.paymentMethod.id,
      project_id: projectId,
    };

    if (type === 'payment') {
      await handlePaymentMethod(data);
    } else {
      data = { ...data, action: 'attach', referral: rewardlyReferral };
      await handleCardAction(data);
    }
    cardElement.clear();
    setDisabled(false);
  };

  // React.useEffect(() => {
  //   //rewardly
  //   window.rewardful('ready', () => {
  //     if (window.Rewardful.referral) {
  //       setRewardlyReferral(window.Rewardful.referral);
  //     }
  //   });
  // }, []);

  return (
    <div className='mb-0'>
      {cardInfo &&
        cardInfo.map((card, i) => {
          return (
            <div key={i} className='flex mt-6 align-center' id='payment-method'>
              <CreditCard
                brand={card.brand}
                last4={card.last4}
                expire={`${card.exp_month}/${card.exp_year}`}
                isDefaultLoading={
                  defaultLoading.index === i ? defaultLoading.state : false
                }
                isDefaultDisabled={
                  card.default ||
                  (deleteLoading.index === i ? deleteLoading.state : false)
                }
                onDefaultClick={async () => {
                  setDefaultLoading({ index: i, state: true });
                  const data = {
                    payment_method: card.pm_id,
                    action: 'default',
                  };
                  await handleCardAction(data);
                  setDefaultLoading({ index: i, state: false });
                }}
                isDeleteLoading={
                  deleteLoading.index === i ? deleteLoading.state : false
                }
                isDeleteDisabled={
                  card.default ||
                  (defaultLoading.index === i ? defaultLoading.state : false)
                }
                onDeleteClick={async () => {
                  setDeleteLoading({ index: i, state: true });
                  const data = {
                    payment_method: card.pm_id,
                    action: 'detach',
                  };
                  await handleCardAction(data);
                  setDeleteLoading({ index: i, state: false });
                  toaster.success('Card Removed', {
                    description: 'The card was removed successfully',
                    duration: 3,
                  });
                }}
              />
            </div>
          );
        })}

      <div className='flex w-full px-6 py-5 mt-6 rounded-md align-center bg-gray-50 sm:flex sm:items-start sm:justify-between'>
        <CardSection disabled={disabled || !hasWriteAccess} />
        <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
          <Button
            size='md'
            intent='default'
            className='border border-gray-300'
            onClick={() => {
              if (cardInfo && cardInfo.length > 0) {
                handleSubmit('add');
              } else {
                handleSubmit('payment');
              }
            }}
            isLoading={disabled}
            isDisabled={disabled || !hasWriteAccess}
          >
            Add Card
          </Button>
        </div>
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  handlePaymentMethod: PropTypes.func,
  handleCardAction: PropTypes.func,
  userEmail: PropTypes.string,
  planId: PropTypes.number,
  projectId: PropTypes.number,
  cardInfo: PropTypes.array,
  hasWriteAccess: PropTypes.bool,
};

export default PaymentMethod;
