import React from 'react';
interface Props {
  progress: number;
}

export const ProgressCircle: React.FC<Props> = ({ progress }) => {
  const radious = 32;
  const circumference = 2 * Math.PI * radious;
  const strokeDashoffset = circumference - (progress / 100) * circumference - 2;

  return (
    <svg className='w-24 h-24'>
      <circle
        className='text-gray-300'
        strokeWidth='5'
        stroke='currentColor'
        fill='transparent'
        r={radious}
        cx='48'
        cy='48'
      />
      <circle
        className='text-[#04B25F]'
        strokeWidth='5'
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap='round'
        stroke='currentColor'
        fill='transparent'
        r={radious}
        cx='48'
        cy='48'
      />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        dy='.3em'
        className='text-lg font-bold'
      >
        {`${Math.round(progress)}%`}
      </text>
    </svg>
  );
};

export default ProgressCircle;
