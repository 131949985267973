import { ITicketInfo } from '../interface';

export const ChartLoadingDummyData = [
  {
    name: 'Woo Commerce',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Whatsapp',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Woo Commerce',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Whatsapp',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Woo Commerce',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Whatsapp',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
  {
    name: 'Woo Commerce',
    assigned: 50,
    unassigned: 80,
    unreplied: 110,
  },
];

export const CardOptionsForAgentView: ITicketInfo[] = [
  {
    title: 'Ticket Assigned to You',
    tooltip: 'Total number of tickets currently assigned to you.',
    color: '#04B25F',
  },
  {
    title: 'Ticket Replied',
    tooltip: 'Total number of assigned tickets you have replied to.',
    color: '#0EA5E9',
  },
  {
    title: 'Ticket Unreplied',
    tooltip: "Total number of assigned tickets you haven't replied to.",
    color: '#F59E0B',
  },
];

export const CardOptionsForAdminView: ITicketInfo[] = [
  {
    title: 'Ticket Assigned',
    tooltip: 'Total number of tickets currently assigned to agents.',
    color: '#04B25F',
  },
  {
    title: 'Ticket Unassigned',
    tooltip: 'Total number of tickets not assigned to agents.',
    color: '#0EA5E9',
  },
  {
    title: 'Ticket Unreplied',
    tooltip:
      'Total number of assigned tickets that are yet to receive a reply.',
    color: '#F59E0B',
  },
  {
    title: 'Current Online Agents',
    tooltip: 'Total number of agents who are currently online.',
    color: '#818CF8',
  },
];

export const TableHearderForAgentTicketData = [
  'Agents name',
  'Status',
  'Ticket assigned',
  'Unreplied',
];

export const TableHearderForTicketData = [
  'TICKET NAME',
  'CREATED AT',
  'STATUS',
  'CHANNEL',
  'ACTION',
];
