import {
  useDispatch,
  useSelector,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import { NodeType } from 'pages/automationWorkflow/interface';

const useUpdateWorkflowServices = () => {
  const dispatch = useDispatch();
  const {
    channelList,
    executorDetails,
    automationNodes,
    comparatorTagDetails,
    selectedSaveActionsData,
    updatePrimitiveStateData,
    executorOfcommonComponents,
  } = useAutomationWorkflow();

  // Destructure required state loading from the workflowAutomation
  const {
    updateSelectedAutomationFlow: isUpdateSelectedAutomationFlowLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  // Make a unique ID of comparator Details option
  const makeTemporaryComparatorOptionId = (id: string) => {
    // Convert the given string ID into a format suitable for temporary options
    return id.split(' ').join('_').toLocaleLowerCase();
  };

  const handleSelectedWorkflowComparatorOption = (data: any) => {
    // Extract tag details based on the selected comparator tag name
    const tagDetails = comparatorTagDetails[data?.selectedComparator] || [];

    // Create temporary comparator options from tag details
    const temporaryComparatorOptions = tagDetails.filter(
      (option: { name: string }) =>
        makeTemporaryComparatorOptionId(option?.name) ===
        makeTemporaryComparatorOptionId(data?.name)
    );

    return temporaryComparatorOptions[0];
  };

  //handle Selected platform
  const handleUpdateSelectedChannel = (selectedChannelId: number) => {
    const tempSelectedChannel = channelList.find(
      (option: { id: number; label: string }) =>
        option?.id === Number(selectedChannelId)
    );
    dispatch.workflowAutomation.updateSelectedChannel(tempSelectedChannel);
  };

  //handle temporary Executor Details
  const handleTemporaryExecutorDetailsData = (name: string) => {
    const getData = executorDetails?.whatsapp.find(
      (item: any) =>
        makeTemporaryComparatorOptionId(item?.name) ===
        makeTemporaryComparatorOptionId(name)
    );
    dispatch.workflowAutomation.updateSelectedTemporaryExecutorDetailsTag(
      getData
    );
  };

  const findWorkflowNodeById = (nodeId: string) => {
    return automationNodes.find(
      (workflowNode: any) => workflowNode?.id === nodeId
    );
  };

  const getSelectedComparatorOptions = (selectedWorkflowNode: any) => {
    return selectedWorkflowNode.data.map((dataElement: any[]) => {
      return dataElement.map((childData: any) =>
        handleSelectedWorkflowComparatorOption(childData)
      );
    });
  };

  const updateSelectedWorkflowConditions = (
    selectedComparatorOptions: any,
    selectedNodeData: any
  ) => {
    dispatch.workflowAutomation.updateSelectedConditions(
      selectedComparatorOptions
    );
    dispatch.workflowAutomation.updateSelectedSaveConditionList(
      selectedNodeData
    );
    dispatch.workflowAutomation.updateConditionallyRenderingConditionUI(
      'add_condition'
    );
  };

  const handleActionWithDelaySelection = (
    nodeId: string,
    nodeType: string,
    valueOfDelay: any,
    timeOfDelay: any
  ) => {
    dispatch.workflowAutomation.updateSelectedTemporaryExecutorDetailsTag(
      executorOfcommonComponents[0]
    );
    // handleTemporaryCommonExecutorDetailsData(component_name);
    const updatedDelay = {
      ...selectedSaveActionsData.general.delay,
      valueOfDelay,
      timeOfDelay,
    };
    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,
      general: {
        ...selectedSaveActionsData.general,
        delay: updatedDelay,
      },
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'add_delay',
    });
    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const handleAddTagsActionSelection = (
    findSelectedWorkflowActionNode: any,
    nodeId: string,
    nodeType: string
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: findSelectedWorkflowActionNode?.data,
    });

    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'add_tags',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };
  const handleRemoveTagsActionSelection = (
    findSelectedWorkflowActionNode: any,
    nodeId: string,
    nodeType: string
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: findSelectedWorkflowActionNode?.data,
    });

    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'remove_tags',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const handleDiscountActionSelection = (
    findSelectedWorkflowActionNode: any,
    nodeId: string,
    nodeType: string
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: findSelectedWorkflowActionNode?.data,
    });

    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'discount_forms',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const handleWhatsappActionSelection = (
    findSelectedWorkflowActionNode: any,
    nodeId: string,
    nodeType: string
  ) => {
    const { data, phoneVariable, selectedChannelId, selectedTemplate } =
      findSelectedWorkflowActionNode?.data?.whatsapp;

    dispatch.workflowAutomation.fetchWhatsappTemplates(selectedChannelId);
    handleUpdateSelectedChannel(selectedChannelId);
    handleTemporaryExecutorDetailsData(data?.component?.component_name);

    dispatch.workflowAutomation.updateSaveSelectedActionsData({
      ...selectedSaveActionsData,

      whatsapp: {
        ...selectedSaveActionsData.whatsapp,
        phoneVariable: phoneVariable,
        selectedChannelId: selectedChannelId,
        selectedTemplate: selectedTemplate,
        data,
      },
    });
    updatePrimitiveStateData({
      key: 'selectedWhatsappData',
      value:
        findSelectedWorkflowActionNode?.data?.whatsapp
          ?.selectedWhatsappTemplateData,
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'whatsapp_template',
    });
    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const updateCommonStateForNodeSelection = (
    nodeId: string,
    nodeType: string
  ) => {
    updatePrimitiveStateData({
      key: 'selectedAutomationNodeEditableId',
      value: nodeId,
    });
    updatePrimitiveStateData({ key: 'showSlider', value: true });
    updatePrimitiveStateData({ key: 'selectedNodeType', value: nodeType });
  };

  const handleSelectedWorkflowForEdit = (nodeId: string, nodeType: string) => {
    if (nodeType === NodeType.CONDITION) {
      const findSelectedWorkflowNode = findWorkflowNodeById(nodeId);

      if (findSelectedWorkflowNode) {
        const getSelectedWorkflowComporatorOptions =
          getSelectedComparatorOptions(findSelectedWorkflowNode);

        updateSelectedWorkflowConditions(
          getSelectedWorkflowComporatorOptions,
          findSelectedWorkflowNode.data
        );

        updatePrimitiveStateData({
          key: 'selectedAutomationNodeEditableId',
          value: nodeId,
        });
        updatePrimitiveStateData({ key: 'showSlider', value: true });
        updatePrimitiveStateData({ key: 'selectedNodeType', value: nodeType });
      }
    } else if (nodeType === NodeType.ACTION) {
      const findSelectedWorkflowActionNode = findWorkflowNodeById(nodeId);

      if (findSelectedWorkflowActionNode) {
        const { valueOfDelay, timeOfDelay } =
          findSelectedWorkflowActionNode?.data?.general?.delay ?? {};

        if (valueOfDelay !== null && timeOfDelay) {
          handleActionWithDelaySelection(
            nodeId,
            nodeType,
            valueOfDelay,
            timeOfDelay
          );
        } else if (findSelectedWorkflowActionNode?.data?.eCommerce?.addTags) {
          handleAddTagsActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (
          findSelectedWorkflowActionNode?.data?.eCommerce?.removeTags
        ) {
          handleRemoveTagsActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (findSelectedWorkflowActionNode?.data?.eCommerce?.discount) {
          handleDiscountActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (
          findSelectedWorkflowActionNode?.data?.eCommerce?.addTagsOnCustomer
        ) {
          handleAddTagsOnCustomertActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (
          findSelectedWorkflowActionNode?.data?.eCommerce?.addTagsOnOrder
        ) {
          handleAddTagsOnOrdertActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (
          findSelectedWorkflowActionNode?.data?.eCommerce?.removeTagsOnCustomer
        ) {
          handleRemoveTagsOnCustomertActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else if (
          findSelectedWorkflowActionNode?.data?.eCommerce?.removeTagsOnOrder
        ) {
          handleRemoveTagsOnOrdertActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        } else {
          handleWhatsappActionSelection(
            findSelectedWorkflowActionNode,
            nodeId,
            nodeType
          );
        }
      }
    }
  };

  const handleAddTagsOnCustomertActionSelection = (
    selectedNode: any,
    nodeId: any,
    nodeType: any
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: selectedNode?.data,
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'add_tags_on_customer',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };
  const handleAddTagsOnOrdertActionSelection = (
    selectedNode: any,
    nodeId: any,
    nodeType: any
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: selectedNode?.data,
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'add_tags_on_order',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const handleRemoveTagsOnCustomertActionSelection = (
    selectedNode: any,
    nodeId: any,
    nodeType: any
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: selectedNode?.data,
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'remove_tags_from_customer',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  const handleRemoveTagsOnOrdertActionSelection = (
    selectedNode: any,
    nodeId: any,
    nodeType: any
  ) => {
    updatePrimitiveStateData({
      key: 'selectedSaveActionsData',
      value: selectedNode?.data,
    });
    updatePrimitiveStateData({
      key: 'renderingChangeUIOfActions',
      value: 'remove_tags_from_order',
    });

    updateCommonStateForNodeSelection(nodeId, nodeType);
  };

  return {
    findWorkflowNodeById,
    handleSelectedWorkflowForEdit,
    isUpdateSelectedAutomationFlowLoading,
  };
};

export default useUpdateWorkflowServices;
