import React from 'react';
import emptyBoxIcon from '../../../assets/images/emptyBox.svg';
import Footer from './Footer';
import { channelInfo } from '../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  platformType: string;
}

const EmptyPage: React.FC<Props> = ({ platformType }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex items-center justify-center'>
        <div className='mt-10'>
          <img
            src={emptyBoxIcon}
            className='m-auto text-center'
            alt={'empty_box'}
          />
          <span className='flex text-lg font-medium text-center'>
            {t("We couldn't found any page associated")} <br />
            with this account
          </span>
          <a
            className={'flex justify-center text-blue-500 hover:text-blue-800'}
            href='https://www.facebook.com/'
            target='_blank'
            rel='noreferrer'
          >
            {t('Create New Page →')}
          </a>
        </div>
      </div>
      <Footer channelName={channelInfo(platformType).title} />
    </>
  );
};

export default EmptyPage;
