import shopify from '../assets/icons/marketplace/shopify.svg';
import salla from '../assets/icons/marketplace/salla.svg';
import zid from '../assets/icons/marketplace/zidIcon.svg';
import wooCommerce from '../assets/icons/marketplace/woocommerce.svg';
import witIconSet from '../../../assets/icons/channelIconSet/wit.svg';
import {
  CopyIcon,
  WhatsappIcon,
  WhatsappGrayIcon,
  WhatsappGreenTickIcon,
  WhatsappIconWithColor,
} from './../assets/icons/socialIcons';

import facebookFeedIconSet from '../../../assets/icons/channelIconSet/facebook_feed.svg';
import appMessengerIconSet from '../../../assets/icons/channelIconSet/app_messenger.svg';
import instagramFeedIconSet from '../../../assets/icons/channelIconSet/instagram_feed.svg';
import lineMessengerIconSet from '../../../assets/icons/channelIconSet/line_messenger.svg';
import viberMessengerIconSet from '../../../assets/icons/channelIconSet/viber_messenger.svg';
import whatsappMessengerIconSet from '../../../assets/icons/channelIconSet/whatsapp_messenger.svg';
import liveChatMessengerIconSet from '../../../assets/icons/channelIconSet/livechat_messenger.svg';
import telegramMessengerIconSet from '../../../assets/icons/channelIconSet/telegram_messenger.svg';
import facebookMessengerIconSet from '../../../assets/icons/channelIconSet/facebook_messenger.svg';
import instagramMessengerIconSet from '../../../assets/icons/channelIconSet/instagram_messenger.svg';
import emailIconSet from '../../../assets/icons/channelIconSet/email.svg';
import WhatsappBspIcon from '../assets/images/whatsappBsbIcon.svg';
import { IWhatsappStore } from '../hooks/useWhatsappTemplateData';

// import intntIconSet from '../../../assets/icons/channelIconSet/intnt.svg';
// import betterdocsIconSet from '../../../assets/icons/channelIconSet/betterdocs.svg';
// import brainFromAliceIconSet from '../../../assets/icons/channelIconSet/brain_from_alice.svg';

// import webChatBubbleOvalLeftEllipsisIconSet from '../../../assets/icons/channelIconSet/webchat_messenger.svg';
// import instagramFeedIconSet from '../../../assets/icons/channelIconSet/instagram_feed.svg';
// import wooCommerceIconSet from '../../../assets/icons/channelIconSet/woocommerce.svg';
// import shopifyIconSet from '../../../assets/icons/channelIconSet/shopify.svg';
// import bigCommerceIconSet from '../../../assets/icons/channelIconSet/big_commerce.svg';
// import dialogFlowIconSet from '../../../assets/icons/channelIconSet/dialogflow.svg';

// import magentoIconSet from '../../../assets/icons/channelIconSet/magento.svg';
// import dokanIconSet from '../../../assets/icons/channelIconSet/dokan.svg';

//exporting social icons
export {
  CopyIcon,
  WhatsappGrayIcon,
  WhatsappIcon,
  WhatsappBspIcon,
  WhatsappGreenTickIcon,
  WhatsappIconWithColor,
};

export const IntegrationNavigationTabs = [
  {
    slug: 'integrated',
    name: 'Integrated',
  },
  {
    slug: 'available-integrations',
    name: 'Available Integrations',
  },
  // {
  //   slug: 'custom-api',
  //   name: 'Custom API',
  // },
];

export const IntegrationDropDownItems = [
  'Channel',
  'E-commerce',
  'NLP',
  'Others',
];

export const EcommerceData = (type: string) => {
  if (type.includes('woocommerce')) {
    return {
      type: 'woocommerce',
      icon: wooCommerce,
      title: 'WooCommerce',
      description:
        'Manage messages and orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    };
  } else if (type.includes('shopify')) {
    return {
      type: 'shopify',
      icon: shopify,
      title: 'Shopify',
      description:
        'Manage messages and {{Shopify}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    };
  } else if (type.includes('salla')) {
    return {
      type: 'salla',
      icon: salla,
      title: 'Salla',
      description:
        'Manage messages and {{Salla}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    };
  } else {
    return {
      type: 'zid',
      icon: zid,
      title: 'Zid',
      description:
        'Manage messages and {{Zid}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    };
  }
};

export const AvailableIntegrations = {
  Channel: [
    {
      type: 'facebook_feed',
      icon: facebookFeedIconSet,
      title: 'Facebook Feed',
      description:
        'Respond to customer comments and mentions faster from MyAlice inbox and automate common replies to save time.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'facebook_messenger',
      icon: facebookMessengerIconSet,
      title: 'Messenger',
      description:
        'Quickly reply to customer messages on Messenger, share product images, and automate repetitive queries.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'whatsapp_bsp',
      icon: whatsappMessengerIconSet,
      title: 'WhatsApp',
      description:
        'Reach out to customers on WhatsApp for higher CTRs and engagement. Schedule campaigns and send push notifications based on customer actions.',
      hasAdditionalPurchase: true,
    },
    {
      type: 'instagram_feed',
      icon: instagramFeedIconSet,
      title: 'Instagram Feed',
      description:
        'Engage your customers on Instagram by quickly responding to all your comments and mentions from one inbox.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'instagram_messenger',
      icon: instagramMessengerIconSet,
      title: 'Instagram Chat',
      description:
        'Quickly reply to customer messages on Instagram, share product images, and automate repetitive queries.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'custom_email',
      icon: emailIconSet,
      title: 'Email',
      description:
        'Manage your support and reply to your email subscribers by connecting multiple email inboxes to MyAlice.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'viber_messenger',
      icon: viberMessengerIconSet,
      title: 'Viber',
      description:
        'Manage and automate your Viber Business messages, share product images, and send campaign notifications.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'telegram_messenger',
      icon: telegramMessengerIconSet,
      title: 'Telegram',
      description:
        'Manage and automate your Telegram messages, share product images, and send campaign notifications.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'line_messenger',
      icon: lineMessengerIconSet,
      title: 'Line',
      description:
        'Manage and automate your Line customer messages, share product images, and send campaign notifications.',
      hasAdditionalPurchase: false,
    },
    // {
    //   type: 'webchat',
    //   icon: webChatBubbleOvalLeftEllipsisIconSet,
    //   title: 'Website Plugin',
    //   description:
    //     'Add a live chat on your website to engage your visitors, guide their purchases and turn them into shoppers.',
    //   hasAdditionalPurchase: false,
    // },
    {
      type: 'livechat_messenger',
      icon: liveChatMessengerIconSet,
      title: 'Live Chat Plugin',
      description:
        'Add a live chat on your website to engage your visitors, guide their purchases and turn them into shoppers.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'app_messenger',
      icon: appMessengerIconSet,
      title: 'Mobile App Plugin',
      description:
        'Add a chat widget in your mobile application to manage support issues and collect feedback from your customers.',
      hasAdditionalPurchase: false,
    },
  ],
  'E-commerce': [
    {
      type: 'woocommerce',
      icon: wooCommerce,
      title: 'WooCommerce',
      description:
        'Manage messages and orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'shopify',
      icon: shopify,
      title: 'Shopify',
      description:
        'Manage messages and {{Shopify}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'salla',
      icon: salla,
      title: 'Salla',
      description:
        'Manage messages and {{Salla}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    },
    {
      type: 'zid',
      icon: zid,
      title: 'Zid',
      description:
        'Manage messages and {{Zid}} orders from your inbox and support faster with user data and order history next to each ticket.',
      hasAdditionalPurchase: false,
    },
    // {
    //   type: 'big_commerce',
    //   icon: facebookFeedIconSet,
    //   title: 'BigCommerce',
    //   description:
    //     'Manage messages and orders from your inbox and support faster with user data and order history next to each ticket.',
    //   hasAdditionalPurchase: false,
    // },
    // {
    //   type: 'magneto',
    //   icon: facebookFeedIconSet,
    //   title: 'Magneto',
    //   description:
    //     'Manage messages and Magento orders from your inbox and support faster with user data and order history next to each ticket.',
    //   hasAdditionalPurchase: false,
    // },
    // {
    //   type: 'dokan',
    //   title: 'Dokan',
    //   description:
    //     'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    //   hasAdditionalPurchase: false,
    // },
  ],
  NLP: [
    {
      type: 'wit',
      icon: witIconSet,
      title: 'Wit.ai',
      description:
        'Add NLP module to your chatbots to make them more responsive and detect phrases, sentences or intents in multiple languages.',
      hasAdditionalPurchase: true,
      isEnterpriseFeature: true,
    },
  ],
  // {
  //   type: 'brain_from_alice',
  //   icon: brainFromAliceIconSet,
  //   title: 'MyAlice NLU',
  //   description:
  //     'Instantly connect MyAlice built-in NLP app to make your chatbots more responsive, detect phrases, sentences or intents.',
  //   hasAdditionalPurchase: true,
  // },
  // {
  //   type: 'intnt',
  //   icon: intntIconSet,
  //   title: 'INTNT',
  //   description:
  //     'Try the new NLP app from INTNT to make your chatbots more responsive, detect phrases, sentences or intents.',
  //   hasAdditionalPurchase: true,
  //   isEnterpriseFeature: true,
  // },
  // {
  //   type: 'dialogflow',
  //   icon: dialogFlowIconSet,
  //   title: 'DialogFlow',
  //   description:
  //     'Add conversational interface in your mobile app, web app, device, chatbot, interactive voice response system and a lot more.',
  //   hasAdditionalPurchase: false,
  // },
  //],
  // Others: [
  //   {
  //     type: 'betterdocs',
  //     icon: betterdocsIconSet,
  //     title: 'BetterDocs',
  //     description:
  //       'Create knowledge bases on BetterDocs and connect with MyAlice to let users search for answers directly from live chat.',
  //     hasAdditionalPurchase: false,
  //   },
  // ],
};

export const IntegrationsFooterData = {
  'Facebook Feed': {
    channelLink:
      'https://developers.facebook.com/docs/graph-api/reference/page/feed',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-facebook-feed',
  },
  Application: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-app',
  },
  'Live Chat': {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat',
  },
  Messenger: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-messenger',
  },
  Viber: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-viber',
  },
  'Instagram Messenger': {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-instagram-messenger',
  },
  'Instagram Feed': {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-instagram-feed',
  },
  Telegram: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-telegram',
  },
  Line: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-line',
  },
  'Wit.ai': {
    channelLink: 'https://wit.ai/docs',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/chatbot-automation/natural-language-processing-nlp/connect-wit-nlp',
  },
  'Myalice NLU': {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/chatbot-automation/natural-language-processing-nlp/connect-myalice-nlu',
  },
  'Whatsapp BSP': {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      '  https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-whatsapp',
  },
  Whatsapp: {
    channelLink: '',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      '  https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-whatsapp',
  },
  'Intnt.ai': {
    channelLink: 'https://intnt.ai/intnt-engine/',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/chatbot-automation/natural-language-processing-nlp/connect-intnt',
  },
  BetterDocs: {
    channelLink:
      'https://docs.myalice.ai/connect-social-channels/connect-betterdocs',
    supportLink: 'https://myalice.ai/contact/',
    documentationLink:
      'https://docs.myalice.ai/connect-social-channels/connect-betterdocs/betterdocs-block',
  },
};

export const WhatsappTemplateLanguageList: Record<string, string> = {
  Afrikaans: 'af',
  Albanian: 'sq',
  Arabic: 'ar',
  Azerbaijani: 'az',
  Bengali: 'bn',
  Bulgarian: 'bg',
  Catalan: 'ca',
  'Chinese (CHN)': 'zh_CN',
  'Chinese (HKG)': 'zh_HK',
  'Chinese (TAI)': 'zh_TW',
  Croatian: 'hr',
  Czech: 'cs',
  Danish: 'da',
  Dutch: 'nl',
  English: 'en',
  'English (UK)': 'en_GB',
  'English (US)': 'en_US',
  Estonian: 'et',
  Filipino: 'fil',
  Finnish: 'fi',
  French: 'fr',
  Georgian: 'ka',
  German: 'de',
  Greek: 'el',
  Gujarati: 'gu',
  Hausa: 'ha',
  Hebrew: 'he',
  Hindi: 'hi',
  Hungarian: 'hu',
  Indonesian: 'id',
  Irish: 'ga',
  Italian: 'it',
  Japanese: 'ja',
  Kannada: 'kn',
  Kazakh: 'kk',
  Kinyarwanda: 'rw_RW',
  Korean: 'ko',
  Kyrgyzstan: 'ky_KG',
  Lao: 'lo',
  Latvian: 'lv',
  Lithuanian: 'lt',
  Macedonian: 'mk',
  Malay: 'ms',
  Malayalam: 'ml',
  Marathi: 'mr',
  Norwegian: 'nb',
  Persian: 'fa',
  Polish: 'pl',
  'Portuguese (BR)': 'pt_BR',
  'Portuguese (POR)': 'pt_PT',
  Punjabi: 'pa',
  Romanian: 'ro',
  Russian: 'ru',
  Serbian: 'sr',
  Slovak: 'sk',
  Slovenian: 'sl',
  Spanish: 'es',
  'Spanish (ARG)': 'es_AR',
  'Spanish (SPA)': 'es_ES',
  'Spanish (MEX)': 'es_MX',
  Swahili: 'sw',
  Swedish: 'sv',
  Tamil: 'ta',
  Telugu: 'te',
  Thai: 'th',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Urdu: 'ur',
  Uzbek: 'uz',
  Vietnamese: 'vi',
  Zulu: 'zu',
};

export const initialUserProperties = {
  id: '',
  access_token: '',
  title: '',
  type: '',
  name: '',
  url: '',
  platform_type: '',
  primary_id: '',
  is_published: false,
  is_connected: false,
  is_token_invalid: false,
  is_archived: false,
  connected_nlp_integration_id: '',
  primary_token: '',
  secondary_receiver_id: '',
  persistent_menu: [],
  ice_breaker: [],
  avatar: '',
  primary_color: '',
  whitelisted_domains: [],
  live_chat_widget_position: '',
  live_chat_should_show_mobile_prompt: false,
  live_chat_should_show_desktop_prompt: false,
  live_chat_should_show_myalice_branding: false,
  whatsapp_message_templates: [],
  is_ecommerce_plugin_channel: false,
};

export const emailUserProfile = {
  email: '',
  family_name: '',
  given_name: '',
  hd: '',
  id: '',
  locale: '',
  name: '',
  picture: '',
  verified_email: false,
};

export const getDeleteDisconnectModalData = (actionType: string) => {
  switch (actionType) {
    case 'delete':
      return {
        actionTitle: 'Delete',
        title: 'Delete the Channel?',
        description:
          'Please type “DELETE” in the box below to confirm the action. You will lose all data related to this channel. Once deleted, you cannot undo this action.',
      };

    case 'disconnect':
      return {
        actionTitle: 'Disconnect',
        title: 'Disconnect the Channel?',
        description:
          "You won't be able to manage the channel from MyAlice anymore. You can reconnect later.",
      };
    default:
      return {
        actionTitle: 'Disconnect',
        title: 'Disconnect the Channel?',
        description:
          'Your channel is still connected to Alice. Please disconnect your channel first to remove it.',
      };
  }
};

export const categoryTypes = ['MARKETING', 'AUTHENTICATION', 'UTILITY'];

export const whatsappTemplateTypes = ['GENERIC', 'CAROUSEL'];

export const maxAuthCodeExpirationTime = 90; //in minutes

export const WHATSAPP_CAROUSEL_CARD_LIMIT = 10;

export const WhatsappTemplateButtonLimits = {
  url: 2,
  phone_number: 1,
  sequence: 10,
  maxTotalButtonLimit: 10,
};
export const CarouselTemplateButtonLimits = {
  url: 1,
  phone_number: 1,
  sequence: 2,
  maxTotalButtonLimit: 2,
};

export const SallaChannelDefaultData = {
  title: 'Salla Livechat',
  avatar: 'https://google.com',
  is_published: true,
  connected_nlp_integration_id: '',
  whitelisted_domains: [],
  livechat_data: {
    brand_logo: '',
    brand_color_regular: '#10B981',
    font_color_regular: '#ffffff',
    is_dark_mode_enabled: false,
    is_dark_mode_default: false,
    brand_color_dark_mode: '#008000',
    font_color_dark_mode: '#008000',
    agent_avatar_display_options: 'all',
    should_show_myalice_branding: true,
    is_chat_head_logo_custom: false,
    chat_head_id: 1,
    chat_head_url: '',
    chat_head_text: 'تواصل معنا',
    chat_head_size: 'large',
    chat_head_color: '#10B981',
    chat_head_position: 'right',
    chat_head_side_spacing: 32,
    chat_head_bottom_spacing: 32,
    live_chat_visibility_option: 'always',
    live_chat_button_label_text: '',
    social_chat_label_text: 'Chat on Social',
    social_chat_options_list: [],
    isOnline: true,
    header_title: '👋 مرحباً!',
    greetings_view_size: 'minimal',
    header_subtext: 'Lorem Ipsum Dolor',
    header_online_message: 'Available now',
    header_offline_message: 'Temporarily unavailable',
    welcome_prompt_visibility_option: 'always',
    welcome_prompt_online_text: 'أخبرنا إذا كان بإمكاننا مساعدتك بأي شيء.',
    welcome_prompt_offline_text:
      'نحن بعيدون الآن، ولكن إذا قمت بترك رسالة سنقوم بالرد عليك فى اقرب وقت.',
    should_enable_welcome_prompt_mobile: true,
    prechat_survey_visibility_option: 'always',
    prechat_survey_message:
      'لضمان معرفتنا بمن نتحدث، يرجى مشاركة التفاصيل التالية حتى نتمكن من متابعة استفسارك في حال تركت المحادثة.',
    prechat_survey_data_points: [
      {
        title: 'Email',
        attribute: '{{email}}',
      },
      {
        title: 'Full Name',
        attribute: '{{full_name}}',
      },
    ],
    faq_visibility_option: 'always',
    faq_title: 'AMA!',
    faq_pages: [],
    should_play_sound_for_new_messages: true,
    should_allow_incoming_attachments: true,
    should_allow_outgoing_attachments: true,
    should_allow_access_to_conversation_history: true,
    widget_loading_delay: 0,
  },
};

export const EmailSignatureVariables = [
  {
    key: 'agent_full_name',
    value: 'full_name',
  },
  {
    key: 'agent_first_name',
    value: 'first_name',
  },
  {
    key: 'agent_last_name',
    value: 'last_name',
  },
  {
    key: 'agent_email',
    value: 'email',
  },
  {
    key: 'team_name',
    value: 'team_name',
  },
];

export const EmailSignatureValueMap = () => {
  const variableMap = EmailSignatureVariables.reduce((acc, variable) => {
    //@ts-ignore
    acc[variable.key] = variable.value;
    return acc;
  }, {});
  return variableMap;
};

export const IntegrateWhatsappDescriptionLimit = 512;

export const IntegrateWhatsappAboutLimit = 139;

export const CarouselButtonDeleteTooltip = `You are only allowed to delete button from the first card. Once you delete button, it will reflect on other cards.`;

export const CarouselButtonTypeSelectTooltip = `You are only allowed to change button types from the first card. Once you change types, it will reflect on other cards.`;

export const WhatsappTemplateMangeActionPermissions: {
  templateEdit: IWhatsappStore['status'][];
  categoryEdit: IWhatsappStore['status'][];
} = {
  templateEdit: ['approved', 'rejected', 'paused'],
  categoryEdit: ['rejected', 'paused'],
};
