import { useDispatch, useSelector } from 'react-redux';

interface TagSettingsHook {
  isTagMandatoryEnable: boolean;
  updateTagMandatorySetting: (isEnabled: boolean) => Promise<void>;
}

export const useTagSettings = (): TagSettingsHook => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state: any) => state.dashboard);
  const isTagMandatoryEnable =
    dashboardData?.selectedProject?.should_require_tag_to_close_tickets ||
    false;

  const updateTagMandatorySetting = async (isEnabled: boolean) => {
    await dispatch.settings.updateTagMandatorySettings({
      projectId: dashboardData?.selectedProject?.id,
      isEnabled,
    });
  };

  return {
    updateTagMandatorySetting,
    isTagMandatoryEnable,
  };
};
