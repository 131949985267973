import React from 'react';
import loadingStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateLoading.svg';
import errorStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateError.svg';
import confirmedStateIcon from './../../../../assets/images/ecommerceAssets/OrderCreateSuccess.svg';
import { Link } from '../../../../../../../library';
import CopyText from '../../../../../../components/elements/redesign/CopyText';

interface Prop {
  updateOrderAPI: () => Promise<any>;
  eComOrderId?: string | number;
}

const UpdateOrder: React.FC<Prop> = ({ updateOrderAPI, eComOrderId }) => {
  type apiStateType = 'loading' | 'confirmed' | 'error';
  const [apiState, setAPIState] = React.useState<apiStateType>('loading');
  const [orderData, setOrderData] = React.useState<any>(null);
  const [iconState, setIconState] = React.useState(loadingStateIcon);
  const [messageState, setMessageState] = React.useState(
    'Finishing up the work. Sit Tight!'
  );

  const handleAPICall = async () => {
    const res = await updateOrderAPI();
    if (res.success) {
      setAPIState('confirmed');
      setOrderData(res.dataSource);
      setIconState(confirmedStateIcon);
      setMessageState(`ORDER${getOrderId()} has been updated successfully!`);
    } else {
      setAPIState('error');
      setIconState(errorStateIcon);
      setMessageState(
        res.error ||
          'We have encountered a problem updating the order. Please try again.'
      );
    }
  };

  React.useEffect(() => {
    handleAPICall();
    // eslint-disable-next-line
  }, []);

  const getOrderId = () => {
    if (eComOrderId) {
      return '-' + eComOrderId;
    } else {
      return '';
    }
  };

  return (
    <div className='flex items-center justify-center h-96 flex-column'>
      <img src={iconState} alt={apiState} width={200} />
      <p>{messageState}</p>
      {apiState === 'confirmed' && orderData?.payment_link && (
        <span>
          <Link url={orderData?.payment_link} openInNewTab>
            Open In New Window
          </Link>
          <CopyText textToCopy={orderData?.payment_link} />
        </span>
      )}
    </div>
  );
};

export default UpdateOrder;
