export const CustomExclamationCircleIcon = ({ className = '' }) => {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M7.47266 8H6.97266V6H6.47266M6.97266 4H6.97766M11.4727 6C11.4727 8.48528 9.45794 10.5 6.97266 10.5C4.48737 10.5 2.47266 8.48528 2.47266 6C2.47266 3.51472 4.48737 1.5 6.97266 1.5C9.45794 1.5 11.4727 3.51472 11.4727 6Z'
        stroke='#6B7280'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const AgentBannerIcon = ({ className = '' }) => {
  return (
    <svg
      width='256'
      height='101'
      viewBox='0 0 256 101'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='100.736'
        cy='99.7577'
        r='86.4573'
        transform='rotate(170 100.736 99.7577)'
        stroke='url(#paint0_linear_3057_367)'
      />
      <circle
        cx='111.889'
        cy='91.4834'
        r='80.2461'
        transform='rotate(170 111.889 91.4834)'
        stroke='url(#paint1_linear_3057_367)'
      />
      <circle
        cx='123.045'
        cy='83.2091'
        r='74.0349'
        transform='rotate(170 123.045 83.2091)'
        stroke='url(#paint2_linear_3057_367)'
      />
      <circle
        cx='134.204'
        cy='74.9355'
        r='67.8236'
        transform='rotate(170 134.204 74.9355)'
        stroke='url(#paint3_linear_3057_367)'
      />
      <circle
        cx='145.356'
        cy='66.6612'
        r='61.6124'
        transform='rotate(170 145.356 66.6612)'
        stroke='url(#paint4_linear_3057_367)'
      />
      <circle
        cx='156.512'
        cy='58.3869'
        r='55.4011'
        transform='rotate(170 156.512 58.3869)'
        stroke='url(#paint5_linear_3057_367)'
      />
      <circle
        cx='167.667'
        cy='50.1134'
        r='49.1899'
        transform='rotate(170 167.667 50.1134)'
        stroke='url(#paint6_linear_3057_367)'
      />
      <circle
        cx='178.821'
        cy='41.8391'
        r='42.9787'
        transform='rotate(170 178.821 41.8391)'
        stroke='url(#paint7_linear_3057_367)'
      />
      <circle
        cx='193.034'
        cy='33.0257'
        r='36.7674'
        transform='rotate(170 193.034 33.0257)'
        stroke='url(#paint8_linear_3057_367)'
      />
      <circle
        cx='204.191'
        cy='24.7522'
        r='30.5562'
        transform='rotate(170 204.191 24.7522)'
        stroke='url(#paint9_linear_3057_367)'
      />
      <circle
        cx='218.405'
        cy='15.9388'
        r='24.3449'
        transform='rotate(170 218.405 15.9388)'
        stroke='url(#paint10_linear_3057_367)'
      />
      <circle
        cx='229.557'
        cy='7.66444'
        r='18.1337'
        transform='rotate(170 229.557 7.66444)'
        stroke='url(#paint11_linear_3057_367)'
      />
      <circle
        cx='240.715'
        cy='-0.609111'
        r='11.9225'
        transform='rotate(170 240.715 -0.609111)'
        stroke='url(#paint12_linear_3057_367)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_3057_367'
          x1='100.736'
          y1='12.8004'
          x2='100.736'
          y2='186.715'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3057_367'
          x1='111.889'
          y1='10.7373'
          x2='111.889'
          y2='172.23'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3057_367'
          x1='123.045'
          y1='8.67426'
          x2='123.045'
          y2='157.744'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3057_367'
          x1='134.204'
          y1='6.61193'
          x2='134.204'
          y2='143.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_3057_367'
          x1='145.356'
          y1='4.54887'
          x2='145.356'
          y2='128.774'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_3057_367'
          x1='156.512'
          y1='2.48581'
          x2='156.512'
          y2='114.288'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_3057_367'
          x1='167.667'
          y1='0.423492'
          x2='167.667'
          y2='99.8033'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_3057_367'
          x1='178.821'
          y1='-1.63958'
          x2='178.821'
          y2='85.3178'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_3057_367'
          x1='193.034'
          y1='-4.24173'
          x2='193.034'
          y2='70.2931'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_3057_367'
          x1='204.191'
          y1='-6.30404'
          x2='204.191'
          y2='55.8083'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_3057_367'
          x1='218.405'
          y1='-8.90619'
          x2='218.405'
          y2='40.7837'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_3057_367'
          x1='229.557'
          y1='-10.9693'
          x2='229.557'
          y2='26.2982'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_3057_367'
          x1='240.715'
          y1='-13.0316'
          x2='240.715'
          y2='11.8134'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.18' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const AdminBannerIcon = ({ className = '' }) => {
  return (
    <svg
      width='484'
      height='101'
      viewBox='0 0 484 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.74'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M185.324 20H163.184V-103H160.724V20H150.884V-103H148.424V20H138.584V-103H136.124V20H126.284V-103H123.824V20H0.824219V22.46H123.824V32.3H0.824219V34.76H123.824V44.6H0.824219V47.06H123.824V56.9H0.824219V59.36H123.824V81.5C123.824 115.466 151.359 143 185.324 143C219.29 143 246.824 115.466 246.824 81.5C246.824 47.5345 219.29 20 185.324 20ZM136.124 32.3H126.284V22.46H136.124V32.3ZM138.584 32.3H148.424V22.46H138.584V32.3ZM150.884 32.3H160.724V22.46H150.884V32.3ZM163.184 32.3V22.46H185.324C217.931 22.46 244.364 48.8931 244.364 81.5C244.364 114.107 217.931 140.54 185.324 140.54C152.717 140.54 126.284 114.107 126.284 81.5V59.36H136.124V81.5C136.124 108.672 158.152 130.7 185.324 130.7C212.497 130.7 234.524 108.672 234.524 81.5C234.524 54.3276 212.497 32.3 185.324 32.3H163.184ZM136.124 56.9H126.284V47.06H136.124V56.9ZM138.584 59.36V81.5C138.584 107.314 159.51 128.24 185.324 128.24C211.138 128.24 232.064 107.314 232.064 81.5C232.064 55.6862 211.138 34.76 185.324 34.76H163.184V44.6H185.324C205.704 44.6 222.224 61.1207 222.224 81.5C222.224 101.879 205.704 118.4 185.324 118.4C164.945 118.4 148.424 101.879 148.424 81.5V59.36H138.584ZM150.884 44.6H160.724V34.76H150.884V44.6ZM148.424 34.76V44.6H138.584V34.76H148.424ZM148.424 47.06V56.9H138.584V47.06H148.424ZM150.884 81.5V59.36H160.724V81.5C160.724 95.0862 171.738 106.1 185.324 106.1C198.91 106.1 209.924 95.0862 209.924 81.5C209.924 67.9138 198.91 56.9 185.324 56.9H163.184V47.06H185.324C204.345 47.06 219.764 62.4793 219.764 81.5C219.764 100.521 204.345 115.94 185.324 115.94C166.304 115.94 150.884 100.521 150.884 81.5ZM163.184 81.5C163.184 93.7276 173.097 103.64 185.324 103.64C197.552 103.64 207.464 93.7276 207.464 81.5C207.464 69.2724 197.552 59.36 185.324 59.36H163.184V81.5ZM160.724 56.9V47.06H150.884V56.9H160.724ZM136.124 44.6H126.284V34.76H136.124V44.6Z'
          fill='url(#paint0_linear_3095_152)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M340.574 47.5H357.764V-48H359.674V47.5H367.314V-48H369.224V47.5H376.864V-48H378.774V47.5H386.414V-48H388.324V47.5H483.824V49.41H388.324V57.05H483.824V58.96H388.324V66.6H483.824V68.51H388.324V76.15H483.824V78.06H388.324V95.25C388.324 121.622 366.946 143 340.574 143C314.203 143 292.824 121.622 292.824 95.25C292.824 68.8784 314.203 47.5 340.574 47.5ZM378.774 57.05H386.414V49.41H378.774V57.05ZM376.864 57.05H369.224V49.41H376.864V57.05ZM367.314 57.05H359.674V49.41H367.314V57.05ZM357.764 57.05V49.41H340.574C315.257 49.41 294.734 69.9333 294.734 95.25C294.734 120.567 315.257 141.09 340.574 141.09C365.891 141.09 386.414 120.567 386.414 95.25V78.06H378.774V95.25C378.774 116.347 361.671 133.45 340.574 133.45C319.477 133.45 302.374 116.347 302.374 95.25C302.374 74.1527 319.477 57.05 340.574 57.05H357.764ZM378.774 76.15H386.414V68.51H378.774V76.15ZM376.864 78.06V95.25C376.864 115.292 360.617 131.54 340.574 131.54C320.532 131.54 304.284 115.292 304.284 95.25C304.284 75.2076 320.532 58.96 340.574 58.96H357.764V66.6H340.574C324.751 66.6 311.924 79.427 311.924 95.25C311.924 111.073 324.751 123.9 340.574 123.9C356.397 123.9 369.224 111.073 369.224 95.25V78.06H376.864ZM367.314 66.6H359.674V58.96H367.314V66.6ZM369.224 58.96V66.6H376.864V58.96H369.224ZM369.224 68.51V76.15H376.864V68.51H369.224ZM367.314 95.25V78.06H359.674V95.25C359.674 105.799 351.123 114.35 340.574 114.35C330.026 114.35 321.474 105.799 321.474 95.25C321.474 84.7014 330.026 76.15 340.574 76.15H357.764V68.51H340.574C325.806 68.51 313.834 80.4819 313.834 95.25C313.834 110.018 325.806 121.99 340.574 121.99C355.342 121.99 367.314 110.018 367.314 95.25ZM357.764 95.25C357.764 104.744 350.068 112.44 340.574 112.44C331.08 112.44 323.384 104.744 323.384 95.25C323.384 85.7562 331.08 78.06 340.574 78.06H357.764V95.25ZM359.674 76.15V68.51H367.314V76.15H359.674ZM378.774 66.6H386.414V58.96H378.774V66.6Z'
          fill='url(#paint1_linear_3095_152)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_3095_152'
          x1='0.82422'
          y1='30.5'
          x2='476.824'
          y2='30.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2FBF7' stopOpacity='0' />
          <stop offset='0.522621' stopColor='#F2FBF7' />
          <stop offset='1' stopColor='#F2FBF7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3095_152'
          x1='0.82422'
          y1='30.5'
          x2='476.824'
          y2='30.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2FBF7' stopOpacity='0' />
          <stop offset='0.522621' stopColor='#F2FBF7' />
          <stop offset='1' stopColor='#F2FBF7' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
