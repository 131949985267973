import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import EyeSlashIcon from '../../assets/images/eyeOff.svg';
import EyeOnIcon from '../../assets/images/eyeOnIcon.svg';

interface Props {
  password: string;
  handleSubmit: () => void;
  handleChange: (name: string, value: string) => void;
}

const PasswordField: React.FC<Props> = ({
  password,
  handleSubmit,
  handleChange,
}) => {
  const { authT } = useTranslation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  return (
    <div className='relative'>
      <input
        id='password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        autoComplete='current-password'
        required
        placeholder={authT('At least 6 characters')}
        value={password}
        onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        className='block w-full py-2 ltr:pl-3 ltr:pr-10 rtl:pl-10 rtl:pr-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
      />
      {/* <EyeOn /> */}
      <div className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-3 rtl:pl-3 text-sm leading-5'>
        <img
          src={showPassword ? EyeSlashIcon : EyeOnIcon}
          alt='eye_on'
          className='w-5 h-5'
          loading='lazy'
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
    </div>
  );
};
export default PasswordField;
