import React from 'react';
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';

import AliceLogo from './../../assets/icons/component/AliceLogo';
import Shopify from 'assets/icons/component/Shopify';
import { PlusIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import ProjectCardShopify, {
  ProjectCardShopifyProps,
} from './components/ProjectCardShopify';
import { Button, Link } from 'library';
import { navigate } from '@reach/router';

interface ShopifyConnectPageProps {
  fetchShopifyProjects: () => any[];
  finalizeShopifyMarketPlace: (team: any) => void;
  fetchShopifyProjectsLoader: boolean;
}

const ShopifyConnectPage: React.FC<ShopifyConnectPageProps> = ({
  fetchShopifyProjects,
  finalizeShopifyMarketPlace,
  fetchShopifyProjectsLoader,
}) => {
  const [selected, setSelected] = useState<ProjectCardShopifyProps>();
  const [projects, setProject] = useState<ProjectCardShopifyProps[]>([]);

  const renderProjectLoader = () => {
    return (
      <>
        <div className='p-2 space-y-3 animate-pulse'>
          <div className='w-full h-10 bg-gray-300 rounded-md' />
          <div className='w-full h-10 bg-gray-300 rounded-md' />
          <div className='w-full h-10 bg-gray-300 rounded-md' />
        </div>
      </>
    );
  };
  const getShopifyProjects = async () => {
    const res: ProjectCardShopifyProps[] = await fetchShopifyProjects();
    setProject(res);
  };

  const verifyToken = () => {
      const token = localStorage.getItem('shopify_installation_request_id');
      if (!token) {
        navigate('/dashboard', {replace: true});
      }
  };

  React.useEffect(() => {
    getShopifyProjects();
    verifyToken();
    //eslint-disable-next-line
  }, []);

  const renderLogos = () => {
    return (
      <div className='flex items-center justify-center space-x-2'>
        <AliceLogo width={120} /> <PlusIcon className='w-6 h-6 text-gray-500' />
        <Shopify width={110} />
      </div>
    );
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen min-w-screen'>
      <div className='w-full max-w-lg px-12 py-6 my-8 bg-white rounded-md shadow'>
        {renderLogos()}
        <p className='flex justify-center font-sans text-2xl font-bold '>
          Shopify Integration
        </p>
        <p className='flex justify-center mb-8 font-sans text-base font-normal'>
          Select a team for Shopify installation
        </p>

        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className='sr-only'>
            project selection
          </RadioGroup.Label>
          {fetchShopifyProjectsLoader && renderProjectLoader()}
          {!fetchShopifyProjectsLoader && (
            <div className='p-4 space-y-4 overflow-auto max-h-72'>
              {!!projects &&
                projects.length > 0 &&
                projects.map((plan: ProjectCardShopifyProps, i: number) => (
                  <ProjectCardShopify
                    key={i}
                    id={plan.id}
                    name={plan.name}
                    description={plan.description}
                    image={plan.image}
                  />
                ))}
            </div>
          )}
        </RadioGroup>
        <div className='w-full mt-4 space-y-3'>
          <Button
            isDisabled={!selected}
            onClick={() => {
              if (!!selected && selected.id) {
                finalizeShopifyMarketPlace(selected);
              }
            }}
            className='flex justify-center w-full'
            intent='primary'
          >
            Continue
          </Button>
          <Button
            onClick={() => {
              navigate('/dashboard');
              localStorage.removeItem('shopify_installation_request_id');
            }}
            className='flex justify-center w-full'
            intent='default'
          >
            Not Now
          </Button>
          <div className='flex justify-center'>
            Need Help? &nbsp;
            <Link url='https://myalice.ai/support/' openInNewTab={true}>
              Contact Support
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  fetchShopifyProjectsLoader:
    state.loading.effects.marketplace.fetchShopifyProjects,
});

const mapDispatch = (dispatch: any) => ({
  fetchShopifyProjects: () => dispatch.marketplace.fetchShopifyProjects(),
  finalizeShopifyMarketPlace: (teamId: number) =>
    dispatch.marketplace.finalizeShopifyMarketPlace(teamId),
});

const ShopifyConnectPageContainer = connect(
  mapState,
  mapDispatch
)(ShopifyConnectPage);

export default ShopifyConnectPageContainer;
