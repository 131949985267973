import React from 'react';
import DropDownList from '../components/DropDownList';

import { telegramSettingDataProps } from '../../../interface';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  errorMessage?: string;
  actionType?: string;
  telegramSettingData: telegramSettingDataProps;
  integratedNlpList: { id: string; name: string; provider: string }[];
  handleChange: (key: string, value: string | string[]) => void;
}

const TelegramSetting: React.FC<Props> = ({
  success = true,
  errorMessage,
  handleChange,
  integratedNlpList,
  telegramSettingData,
  actionType = 'create',
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo, isPartnerRestricted } = usePartner();
  const shouldHideDocLink = isPartnerRestricted(
    'TelegramSetting/node/tg-doc-link'
  );
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) =>
        item.id === telegramSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );
  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My telegram channel')}
            value={telegramSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {t(
              `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
            )}
          </p>
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='primary_token'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Primary Token')}*
          </label>
          <input
            type='text'
            placeholder={t('Primary token found in your telegram account')}
            value={telegramSettingData.primary_token}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='primary_token'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm font-medium text-gray-500'>
            {t(`To connect your {{Telegram}} account with {{${partnerName}}}`)}
            {' ,'}
            {t('you should create an account through')}
            <a
              href='https://core.telegram.org/'
              target='_blank'
              className='text-blue-500 hover:text-blue-800'
              rel='noreferrer'
            >
              {`Telegram Admin Panel, `}
            </a>
            {t("if you don't have one already")}.{' '}
            {t(
              'Then provide the Primary Token which you will find in the admin panel.'
            )}
            <span
              id='tg-doc-link'
              className={shouldHideDocLink ? 'hidden' : ''}
            >
              {t('For details')}
              {', '}
              <a
                href='https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-telegram'
                target='_blank'
                className='text-blue-500 hover:text-blue-800'
                rel='noreferrer'
              >
                {t(` Read this.`)}
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='block mb-1 text-sm font-medium text-gray-700'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (value.id !== telegramSettingData.connected_nlp_integration_id) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
    </>
  );
};
export default TelegramSetting;
