import React from 'react';
import { SideSheet as OrderModal } from '../../../../../../library';
import Wrapper from '../eCommerceOrderCreate/Wrapper';
import { connect } from 'react-redux';
import Modal from '../../../../../components/elements/redesign/Modal';
import { orderData } from '../../../../../../utilities/content';
import { PaymentMethodProps, ShippingMethodProps } from '../eCommerceOrderCreate/interfaces'

interface SelectedProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

interface CustomerDataProps {
  customer_id: number;
  platform_id: number;
  project_id: number;
  ecommerce_type: string;
  ecommerce_account_id: number;
  ecommerce_account_email: string;
  ecommerce_account_phone: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
  alice_cart: SelectedProductDataProps[];
  billing_address: EcomAddressProps | null;
  shipping_address: EcomAddressProps | null;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  company: string;
  email: string;
  phone: string;
}

interface Prop {
  isSameShippingAddress: boolean;
  paymentMethod: PaymentMethodProps;
  shippingMethod: ShippingMethodProps;
  updateOrderOpen: boolean;
  setUpdateOrderOpen: (value: boolean) => void;
  customerId: number;
  clearState: () => void;
  customerData: CustomerDataProps;
  updateOrder: (
    projectId: number,
    orderId: string,
    customerId: number
  ) => Promise<any>;
  updateOrderLoading: boolean;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
  updateMinimizeModal: () => void;
  eComOrderId?: string | number;
  orderId: string;
  projectId: number;
  updateStateData: (key: string, val: any) => void;
}

const EcomOrderUpdate: React.FC<Prop> = ({
  isSameShippingAddress,
  paymentMethod,
  shippingMethod,
  updateOrderOpen,
  setUpdateOrderOpen,
  customerId,
  clearState,
  customerData,
  updateOrder,
  updateOrderLoading,
  updateCart,
  updateMinimizeModal,
  eComOrderId,
  orderId,
  projectId,
  updateStateData,
}) => {
  const [step, setStep] = React.useState(1);
  const [showCloseWarningModal, setShowCloseWarningModal] =
    React.useState(false);
  const containerRef = React.createRef();
  const [updatedOrderState, setUpdatedOrderState] = React.useState('Loading');

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        // call api to store
        setStep(2);
        break;
      case 2:
        // update delivery information
        setStep(3);
        break;
      case 3:
        setStep(4);
        break;
      case 4:
        if (updatedOrderState === 'Exit') {
          setUpdateOrderOpen(false);
          setTimeout(() => {
            setStep(1);
          }, 1000);
        } else if (updatedOrderState === 'Try Again') {
          setStep(1);
          setUpdatedOrderState('Loading');
        }
        break;
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 2:
        // update delivery information
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
    }
  };

  const getUpdateOrderHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
      default:
        return {
          title: `Update #Order-${eComOrderId}`,
          description:
            'Click on the search bar below to browse your inventory. A selected product from the inventory will be added to the cart. You can further update the order from there.',
        };
      case 2:
        return {
          title: `Update #Order-${eComOrderId} - Customer Information`,
          description:
            'Please complete the required fields for order placement',
        };
      case 3:
        return {
          title: `Update Summary #Order-${eComOrderId}`,
          description:
            'Please check the information before confirming the order.',
        };
    }
  };

  React.useEffect(() => {
    //we need this to scroll to to from each step
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, [step]);

  const checkStep1NextButton = () => {
    // first we check if we have any product in alice_cart
    const cart: SelectedProductDataProps[] = customerData?.alice_cart || [];
    let flag: boolean = false;
    if (cart.length > 0) {
      cart.forEach((product) => {
        if (product.has_variations && !product.variant_id) {
          flag = true;
        }
      });
      return flag;
    }
    return true;
  };

  const checkStep2NextButton = () => {
    const billingMandatoryKey = [
      'first_name',
      'address_one',
    ];
    const shippingMandatoryKey = ['first_name', 'address_one'];

    let flag = false;

    billingMandatoryKey.forEach((elem) => {
      if (
        !(
          customerData &&
          customerData?.billing_address &&
          customerData?.billing_address[elem]
        )
      ) {
        flag = true;
      }
    });
    if (!flag) {
      if (!isSameShippingAddress) {
        shippingMandatoryKey.forEach((elem) => {
          if (
            !(
              customerData &&
              customerData?.shipping_address &&
              customerData?.shipping_address[elem]
            )
          ) {
            flag = true;
          }
        });
      }
    }
    if (!flag && !paymentMethod) {
      flag = true;
    }
    if (!flag && !shippingMethod) {
      flag = true;
    }
    return flag;
  };

  const checkNextButtonAllow = () => {
    switch (step) {
      case 1:
        return checkStep1NextButton();
      case 2:
        return checkStep2NextButton();
      case 3:
      case 4:
        return false;
      default:
        return true;
    }
  };

  const handleCloseModal = () => {
    if (customerData.alice_cart.length > 0) {
      setUpdateOrderOpen(false);
      updateMinimizeModal();
      setShowCloseWarningModal(true);
      updateStateData('isOrderUpdate', false);
    } else {
      setUpdateOrderOpen(false);
      updateStateData('isOrderUpdate', false);
    }
  };
  const handleMinimizeModal = () => {
    setUpdateOrderOpen(false);
    updateMinimizeModal();
  };

  return (
    <div>
      <OrderModal
        open={updateOrderOpen}
        ref={containerRef}
        hasMinimizeButton={true}
        handleClose={handleCloseModal}
        handleMinimize={handleMinimizeModal}
        closeOnExternalClick={false}
        disableConfirm={checkNextButtonAllow() ? true : updateOrderLoading}
        title={getUpdateOrderHeaderInfo(step).title}
        description={getUpdateOrderHeaderInfo(step).description}
        confirmText={
          step === 3 ? 'Update Order' : step === 4 ? updatedOrderState : 'Next'
        }
        cancelText={'Back'}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        hideCancel={
          step === 1 ||
          (step === 4 &&
            (updatedOrderState === 'Loading' || updatedOrderState === 'Exit'))
        }
      >
        <Wrapper
          step={step}
          customerId={customerId}
          orderId={orderId}
          confirmOrderAPI={async () => {
            let res = await updateOrder(projectId, orderId, customerId);
            if (res.success) {
              updateCart(customerId, []);
              clearState();
              setUpdatedOrderState('Exit');
            } else {
              setUpdatedOrderState('Try Again');
            }
            return res;
          }}
        />
      </OrderModal>
      <Modal
        isShown={showCloseWarningModal}
        setShown={setShowCloseWarningModal}
        intent={'warning'}
        title={orderData.discardOrderUpdation.title}
        description={orderData.discardOrderUpdation.description}
        deleteTitle='Cancel Order'
        cancelTitle='Cancel'
        hasConfirmText={false}
        onCancelCallback={() => {
          setUpdateOrderOpen(true);
        }}
        onDeleteCallback={() => {
          clearState();
          setStep(1);
          updateCart(customerId, []);
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  isSameShippingAddress: state.crmEcommerce.isSameShippingAddress,
  paymentMethod: state.crmEcommerce.paymentMethod,
  shippingMethod: state.crmEcommerce.shippingMethod,
  customerData: state.crmEcommerce.customerData,
  updateOrderLoading: state.loading.effects.crmEcommerce.updateOrder,
  projectId: state.dashboard?.selectedProject?.id || null,
});

const mapDispatch = (dispatch: any) => ({
  clearState: () => dispatch.crmEcommerce.clearState(),
  updateOrder: (projectId: number, orderId: string, customerId: number) =>
    dispatch.crmEcommerce.updateOrder({ projectId, orderId, customerId }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
  updateMinimizeModal: () => dispatch.crmEcommerce.updateMinimizeModal(),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const EcomOrderUpdateContainer = connect(
  mapState,
  mapDispatch
)(EcomOrderUpdate);
export default EcomOrderUpdateContainer;
