import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { classNames } from 'utilities/utils';
interface StepProps {
  id: number;
  name: string;
}
interface Props {
  steps: StepProps[];
  currentStep: number;
  handleClickEvent: (step: number) => void;
}
const Tab: React.FC<Props> = ({ steps, currentStep, handleClickEvent }) => {
  const { t } = useTranslation();
  return (
    <nav className='border-b rtl:ml-8 ltr:mr-8 text-sm flex gap-9 justify-start'>
      {steps.map((step: StepProps) => {
        return (
          <button
            key={step.id}
            className={classNames(
              'inline-block py-2 text-sm leading-5 font-medium',
              step.id === currentStep
                ? ' mb-[-1px] border-b-2 border-green-500 text-green-600 font-semibold'
                : ' text-gray-500 hover:text-gray-700'
            )}
            onClick={() => {
              handleClickEvent(step.id);
            }}
          >
            {t(step.name)}
          </button>
        );
      })}
    </nav>
  );
};
export default Tab;
