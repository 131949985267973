import React from 'react';
import errorScreen from './assets/images/errorScreen.svg';
import { navigate } from '@reach/router';

interface Prop {
  error?: string;
  logoutAPI?: () => void;
}

const ErrorBoundary: React.FC<Prop> = ({ error, logoutAPI }) => {
  return (
    <div className='flex flex-col justify-center w-screen h-screen align-center'>
      <img src={errorScreen} alt='error screen' width={300} />
      <p className='mt-4 text-xl font-medium text-gray-500'>
        Oops! We have encountered an error!
      </p>
      {!!error && (
        <p className='mt-4 text-sm font-normal text-gray-500'>
          {error.toString()}
        </p>
      )}
      <button
        onClick={() => {
          if (logoutAPI) {
            logoutAPI();
            navigate('/');
          }
        }}
        className='inline-flex items-center px-4 py-2 mt-4 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
      >
        Please Login Again
      </button>
    </div>
  );
};

export default ErrorBoundary;
