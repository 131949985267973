import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { Pane, Spinner, toaster } from 'evergreen-ui';

const InvitationAccept = ({
  authenticated,
  acceptInvitation,
  updateInvitationToken,
  fetchProject,
}) => {
  useEffect(() => {
    const [, token] = window.location.search.split('?token=');
    if (authenticated) {
      const initAccept = async () => {
        const res = await acceptInvitation(token);
        if (res === 500) {
          toaster.danger('Could Not Accept Invitation', {
            duration: 2,
          });
        } else if (!res.success) {
          toaster.danger(res.message, {
            duration: 2,
          });
        } else
          toaster.success(res.message, {
            duration: 2,
          });
        fetchProject();
        navigate(`/dashboard`);
      };
      initAccept();
    } else {
      updateInvitationToken(token);
      toaster.danger('Please Login First to Accept Invitaion');
      navigate(`/`);
    }
  }, [authenticated, acceptInvitation, updateInvitationToken, fetchProject]);

  return (
    <Pane
      display='flex'
      alignItems='center'
      justifyContent='center'
      height='100vh'
    >
      <Spinner />
    </Pane>
  );
};

const mapState = (state) => {
  const accessToken = state.auth.access;

  return {
    authenticated: accessToken !== '',
  };
};

const mapDispatch = (dispatch) => ({
  acceptInvitation: (token) => dispatch.auth.acceptInvitation(token),
  updateInvitationToken: (token) => dispatch.auth.updateInvitationToken(token),
  fetchProject: () => dispatch.dashboard.fetchProject(),
});

export default connect(mapState, mapDispatch)(InvitationAccept);
