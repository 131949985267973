import { useState } from 'react';
import { CopyIcon, CircleCheck } from 'lucide-react';
import { Button } from '../../libraryV2/ui/button';
import { TooltipProviderCustomised } from '../../libraryV2/ui/tooltip';

const CopyToClipboard = ({ textToCopy }: { textToCopy: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3500); // Reset after 3.5s
  };

  return (
    <TooltipProviderCustomised
      content={isCopied ? 'Copied!' : 'Copy to clipboard'}
      side='top'
    >
      <Button
        variant='ghost'
        onClick={handleCopy}
        className='flex items-center p-0 space-x-2'
      >
        {!isCopied ? (
          <CopyIcon
            className='text-textSecondary-disable hover:text-textPrimary'
            height={16}
          />
        ) : (
          <CircleCheck className='text-textPrimary' height={16} />
        )}
      </Button>
    </TooltipProviderCustomised>
  );
};

export default CopyToClipboard;
