import { useEffect, useState } from 'react';
import { IApiResponse } from '../interface';
import { navigate, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

const useAgent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state: any) => state.dashboard?.selectedProject
  );

  const user_id = useSelector((state: any) => state.auth?.id);

  const [loading, setLoading] = useState(true);
  const [agentData, setAgentData] = useState<IApiResponse | null>(null);

  const fetchAssignmentAgentCount = async () => {
    const res = await dispatch.dashboard.fetchAssignmentAgentCount({
      projectId: selectedProject?.id,
      user_id,
    });
    if (!!res) {
      setAgentData({ ...res });
    } else setAgentData(null);
  };

  const apiCallOnProjectChange = async () => {
    setLoading(true);
    await fetchAssignmentAgentCount();
    setLoading(false);
  };

  const redirectToTicket = (id: string | number) => {
    const link = `${location.origin}/projects/${selectedProject?.id}/inbox?ticket_id=${id}`;
    dispatch.dashboard.updateSelectedNav(1);
    navigate(link);
  };

  useEffect(() => {
    apiCallOnProjectChange();
    //eslint-disable-next-line
  }, [selectedProject?.id]);

  return {
    loading,
    agentData,
    redirectToTicket,
  };
};

export default useAgent;
