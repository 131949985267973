import React from 'react';
import Footer from '../../common/components/Footer';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'Introduction/node/insta-chat-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          {t(
            `Integrating Instagram Messenger with {{${partnerName}}} will allow you to:`
          )}
        </p>
        <ul className='mt-3 px-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            {t(
              `Respond to Messenger chat messages from {{${partnerName}}} inbox.`
            )}
          </li>
          <li className='my-2'>
            {t(`Manage messages from multiple Facebook pages.`)}
          </li>
          <li className='my-2'>
            {t(`Share product images or location using clickable widgets.`)}
          </li>
          <li className='my-2'>
            {t(
              `Use chatbots to create custom user journeys and automate repetitive queries.`
            )}
          </li>
          <li className='my-2'>
            {t(
              `Manage messages, comments and e-commerce orders from other platforms in the same inbox.`
            )}
          </li>
        </ul>
      </div>
      <Footer channelName='Instagram Messenger' />
    </div>
  );
};
export default Introduction;
