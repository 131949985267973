'use client';

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'libraryV2/ui/toast';
import { useToast } from 'libraryV2/ui/use-toast';
import { cn } from 'libraryV2/utils';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        toastType,
        ...props
      }) {
        return (
          <Toast key={id} {...props} className={'bg-white shadow-2xl'}>
            <div className='grid gap-1'>
              {title && (
                <ToastTitle
                  className={cn(
                    toastType === 'success' && 'text-green-500',
                    toastType === 'destructive' && 'text-red-500'
                  )}
                >
                  {title}
                </ToastTitle>
              )}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
