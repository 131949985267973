import React from 'react';
import ReactTooltip from 'react-tooltip';
import TextWithTooltip from '../../../../../../library/text';

import Button from '../../../../../../library/button';
import emptyTags from '../../../../assets/images/emptyTags.svg';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import emptyResult from '../../../../assets/images/emptyResult.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  query: string;
  showActions: boolean;
  selectedTicketTags: number[];
  hasSuperVisorAccess: boolean;
  tagList: TicketTagFilterInterface[];
  handleCloseDropDown: () => void;
  handleShowActions: (value: boolean) => void;
  handleTagSelection: (value: number) => void;
  assignTicketTag: (selectedTags: number[]) => void;
  handleCurrentTagSelection: (value: TicketTagInterface[]) => void;
}

const TagCheckBox: React.FC<Props> = ({
  query,
  tagList,
  showActions,
  selectedTicketTags,
  hasSuperVisorAccess,
  assignTicketTag,
  handleShowActions,
  handleTagSelection,
  handleCloseDropDown,
  handleCurrentTagSelection,
}) => {
  const { t } = useTranslation();
  const getCheckedOptions = (value: number) => {
    return !!selectedTicketTags && selectedTicketTags.includes(value);
  };

  const uncheckAll = () => {
    let checkboxes = document.getElementsByTagName('input');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type === 'checkbox') {
        checkboxes[i].checked = false;
      }
    }
    handleCurrentTagSelection([]);
    handleShowActions(true);
  };

  const isUnselectable = (): boolean => {
    return (
      query.length === 0 &&
      tagList.length !== 0 &&
      selectedTicketTags.length !== 0
    );
  };

  const showActionBar = (): boolean => {
    return (
      (!hasSuperVisorAccess || !!showActions) &&
      (!hasSuperVisorAccess || tagList.length !== 0)
    );
  };

  return (
    <>
      {isUnselectable() && (
        <div className='px-3 pt-2.5'>
          <div
            className='text-[#0078CF] cursor-pointer'
            onClick={() => {
              uncheckAll();
            }}
          >
            {t('Unselect all')}
          </div>
        </div>
      )}

      {/* Tags List */}
      {!!tagList && tagList.length !== 0 && (
        <div className='items-center overflow-auto max-h-60'>
          {tagList.map((item: any, index: number) => (
            <div
              key={index}
              className='flex px-3 py-2.5'
              onClick={() => {
                handleTagSelection(item.value);
                handleShowActions(true);
              }}
            >
              <div className='flex items-center h-5'>
                <input
                  data-testid={`tags_${index}`}
                  id={`${item.label}_${index}`}
                  name={item.label}
                  checked={getCheckedOptions(item.value)}
                  type='checkbox'
                  autoComplete={'nope'}
                  onChange={() => {}}
                  className='w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary'
                />
              </div>
              <div className='flex w-full ltr:ml-2 rtl:mr-2 text-sm'>
                <TextWithTooltip
                  text={item.label}
                  dataTip={item.label}
                  dataFor={`tag_name_${index}`}
                  shouldShowTooltip={item.label.length > 25}
                  className={`text-sm font-normal text-gray-900`}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Empty States */}
      {tagList.length === 0 && query.length !== 0 && (
        <div className='flex flex-col content-center justify-center w-auto h-[185px]'>
          <img src={emptyResult} alt='emptyTags' />
          <div className='mt-4 text-sm font-normal text-center text-gray-500 w-60'>
            {t('Sorry, we found nothing related to')}
          </div>
          <div className='text-sm font-medium text-center text-gray-800 break-words w-60'>
            "{query}"
          </div>
        </div>
      )}
      {tagList.length === 0 && query.length === 0 && (
        <div className='flex flex-col content-center justify-center w-auto h-[185px]'>
          <img src={emptyTags} alt='emptyTags' />
          <div className='mt-4 text-sm font-normal text-center text-gray-500 w-60'>
            {`${
              !!hasSuperVisorAccess
                ? t('Hit the search bar to add new tags.')
                : t(
                    'Oops! You have no tags. Please contact your Admin to create a new tag(s).'
                  )
            }`}
          </div>
        </div>
      )}

      {/* Actions */}
      {showActionBar() && (
        <div
          className='sticky flex w-full h-[46px] p-1.5 bg-white border-t
            justify-between rounded-b-md'
        >
          <div className='content-center justify-start px-1'>
            {!hasSuperVisorAccess && (
              <>
                <InformationCircleIcon
                  data-for='tagInfo'
                  data-tip='Contact your Admin to add/edit tags.'
                  className='w-5 h-5 text-gray-400'
                />
                <ReactTooltip
                  id='tagInfo'
                  place='bottom'
                  type='dark'
                  effect='solid'
                  backgroundColor='#4B5563'
                />
              </>
            )}
          </div>
          {tagList.length !== 0 && !!showActions && (
            <div className='justify-end flex gap-3'>
              <Button
                size='xs'
                className='text-gray-700'
                onClick={() => {
                  handleCloseDropDown();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                size='xs'
                className='text-white bg-primary'
                onClick={() => {
                  assignTicketTag(selectedTicketTags);
                  handleCloseDropDown();
                }}
              >
                {t('Save Changes')}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TagCheckBox;
