import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../old/components/elements/redesign/Alert';
import PasswordField from '../../../components/utilityComponent/PasswordField';
import { Button, Link } from '../../../library';

const RegisterFormSection = (props) => {
  const {
    email,
    password,
    firstName,
    lastName,
    duplicateEmail,
    content,
    handleChange,
    handleSubmit,
    loading,
    disableEmail = false,
    invalidEmail = false,
    invalidPassword = false,
    errorMessage = '',
    hasAppSumoInfo = false,
  } = props;
  const [isChecked, setIsChecked] = React.useState(false);

  return (
    <div className='mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='space-y-4'>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            <span className='mb-1'>Your Email Address</span>
            <input
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              disabled={disableEmail}
              value={email || ''}
              placeholder='example@company.com'
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none disabled:bg-gray-100 disabled:cursor-not-allowed focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
            {invalidEmail && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={errorMessage} />
              </div>
            ) : (
              ''
            )}
            {duplicateEmail ? (
              <div className='mt-3'>
                <Alert intent='danger' text='Email is already registered' />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>
        {hasAppSumoInfo && (
          <div className='grid grid-cols-2 gap-2'>
            <label
              htmlFor='First Name'
              className='block text-sm font-medium text-gray-700'
            >
              <div className='mb-1'>First Name</div>
              <input
                id='firstName'
                name='firstName'
                type='text'
                placeholder='Type here...'
                required
                value={firstName || ''}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
              />
            </label>
            <label
              htmlFor='lastName'
              className='block text-sm font-medium text-gray-700'
            >
              <div className='mb-1'>Last Name</div>
              <input
                id='lastName'
                name='lastName'
                type='text'
                placeholder='Type here...'
                required
                value={lastName || ''}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
              />
            </label>
          </div>
        )}
        <div>
          <label
            htmlFor='password'
            className='block text-sm font-medium text-gray-700'
          >
            <div className='mb-1'>Your Password</div>
            <PasswordField
              password={password}
              handleChange={(name: string, value: string) =>
                handleChange(name, value)
              }
              handleSubmit={handleSubmit}
            />
            {invalidPassword && !!errorMessage ? (
              <div className='mt-3'>
                <Alert intent='danger' text={errorMessage} />
              </div>
            ) : (
              ''
            )}
          </label>
        </div>

        <div className='flex items-start'>
          <input
            id='remember_me'
            name='remember_me'
            type='checkbox'
            value={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className='w-5 h-5 border-gray-300 rounded text-primary focus:ring-primary'
          />
          <label
            htmlFor='remember_me'
            className='block ml-2 text-sm text-gray-900'
          >
            {content.signupConfirmationInfo.text}{' '}
            <Link url={content.signupConfirmationInfo.link} openInNewTab={true}>
              {content.signupConfirmationInfo.linkTitle}
            </Link>
          </label>
        </div>
        <div>
          <Button
            onClick={() => handleSubmit()}
            isDisabled={!isChecked}
            isFullWidth={true}
            isLoading={loading}
            intent='primary'
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};
RegisterFormSection.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  content: PropTypes.any,
  password: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default RegisterFormSection;
