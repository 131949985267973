import React from 'react';

interface Prop {
  product_images: string[];
  product_name: string;
  quantity: string;
  total_cost: number;
  metaData: any;
  currencySymbol: string;
}

const OrderCardProductElement: React.FC<Prop> = ({
  product_images,
  product_name,
  quantity,
  total_cost,
  metaData,
  currencySymbol,
}) => {
  const getTotalCost = () => {
    return !!total_cost ? `${currencySymbol}${total_cost.toString()}` : '';
  };

  return (
    <div className='order-right-bar__order-card__product-element'>
      <div className='product-mask'>
        {product_images.length > 0 && (
          <img className='flex-4' src={product_images[0]} alt='product' />
        )}
        {product_images.length === 0 && (
          <img
            className='flex-4'
            src='https://images.unsplash.com/photo-1459802071246-377c0346da93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=704&q=80'
            alt='product'
          />
        )}
      </div>
      <div className='order-right-bar__order-card__product-element-content flex-8'>
        <p className='main-text'>{product_name || 'Not Available'}</p>
        <p>Quantity: {quantity}</p>
        {!!metaData &&
          Array.isArray(metaData) &&
          metaData.map((metaFragments: any, i: number) => (
            <p key={i}>
              {metaFragments.key}: {metaFragments.value}
            </p>
          ))}
      </div>
      <div className='order-right-bar__order-card__product-element-content-price flex-4'>
        {getTotalCost()}
      </div>
    </div>
  );
};

export default OrderCardProductElement;
