import {
  Button,
  Check as CheckIcon,
  ChevronDown,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  getHookFormRules,
  useFormContext,
  useState,
} from '../../export';

import React, { useEffect } from 'react';

export const SingleSelectField: React.FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const shouldDisableInput = formActionType === 'VIEW';
  const form = useFormContext();

  const currentFieldValuePath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const [currentFieldValue, setCurrentFieldValue] = useState(
    form.getValues(currentFieldValuePath) ?? null
  );

  useEffect(() => {
    setCurrentFieldValue(form.getValues(currentFieldValuePath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldValuePath, currentFieldValue]);

  const { name: label, placeholder, is_required: required } = data;

  const [open, setOpen] = React.useState(false);

  const handleChoice = (currentValue: string) => {
    setOpen(false);
    form.setValue(currentFieldValuePath, currentValue);
    form.clearErrors(currentFieldValuePath);
    setCurrentFieldValue(currentValue);
  };

  const isFormViewMode = formActionType === 'VIEW';

  const renderNumberFieldView = () => {
    return (
      <div className='p-3 space-y-2 border rounded-md border-zinc-200'>
        <p className='text-xs text-zinc-500'> {data?.label_agent || label} </p>
        <p className='text-sm text-zinc-900'>
          {currentFieldValue || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  const renderSingleSelectField = () => (
    <FormField
      control={form.control}
      name={currentFieldValuePath}
      disabled={data?.is_readonly}
      rules={getHookFormRules(data)}
      render={() => (
        <FormItem>
          <FormLabel>
            {data?.label_agent || label}
            {required && <span className='text-destructive'>*</span>}
          </FormLabel>
          <FormControl>
            <Popover open={open} modal={true} onOpenChange={setOpen}>
              <PopoverTrigger asChild disabled={shouldDisableInput}>
                <Button
                  type='button'
                  variant='outline'
                  role='combobox'
                  aria-expanded={open}
                  className={`w-full justify-between font-normal ${
                    open ? 'border border-ring' : ''
                  }`}
                >
                  <span className='text-muted-foreground'>
                    {currentFieldValue
                      ? data?.choices.find(
                          (choice) => choice.name === currentFieldValue
                        )?.name
                      : placeholder || 'Select an option'}
                  </span>
                  <ChevronDown className='w-4 h-4 ml-2 opacity-50 shrink-0' />
                </Button>
              </PopoverTrigger>
              <PopoverContent className='p-0 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] w-[26rem]'>
                <Command>
                  <CommandInput
                    placeholder='Search...'
                    className='border-none h-9 focus:ring-0'
                  />
                  <CommandList className='max-h-[300px] min-w-[90vw] bg-background text-zinc-900 lg:min-w-form-width'>
                    <CommandEmpty>No data found.</CommandEmpty>

                    <CommandGroup>
                      {data?.choices.map((choice) => (
                        <CommandItem
                          key={choice.name}
                          value={choice.name}
                          onSelect={handleChoice}
                        >
                          <CheckIcon
                            className={cn(
                              'mr-2 h-4 w-4 text-primary',
                              currentFieldValue === choice.name
                                ? 'opacity-100'
                                : 'opacity-0'
                            )}
                          />
                          {choice.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
  return isFormViewMode ? renderNumberFieldView() : renderSingleSelectField();
};

export default SingleSelectField;
