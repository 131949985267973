import React, { Fragment, useState, useEffect } from 'react';
import { SelectMenu, Button, Icon } from 'evergreen-ui';
import countryList from 'react-select-country-list';
import PropTypes from 'prop-types';
import TitleHolderBuilder from './TitleHolderBuilder';

const SelectCountryField = ({ phoneLocale, handleChange }) => {
  const [countryListAll, setCountyList] = useState([]);
  useEffect(() => {
    const countries = countryList().getData();
    setCountyList(countries);
  }, []);
  return (
    <Fragment>
      <TitleHolderBuilder
        title='Select Country'
        tooltipInfo='Specific County Phone No Validation.'
      />
      <div className='select-menu__default'>
        <SelectMenu
          isMultiSelect
          width={'300px'}
          title='Select Country'
          options={countryListAll}
          selected={phoneLocale}
          onSelect={(item) => {
            const phoneLocaleList = [...phoneLocale, item.value];
            handleChange(phoneLocaleList);
          }}
          onDeselect={(item) => {
            const phoneLocaleList = phoneLocale.filter((e) => e !== item.value);
            handleChange(phoneLocaleList);
          }}
        >
          <Button height={40} className='custom-input-style bg-white-old'>
            {(phoneLocale.length > 0 &&
              `${phoneLocale.length} Countries Selected`) ||
              'All Countries Selected'}{' '}
            &nbsp;
            <Icon icon='chevron-down' size={16} />
          </Button>
        </SelectMenu>
      </div>
    </Fragment>
  );
};
SelectCountryField.propTypes = {
  phoneLocale: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SelectCountryField;
