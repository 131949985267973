import { DocumentIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import { WhatsappTemplate } from 'pages/inbox/inboxInterface';
import { highlightAttributeFromText } from 'pages/inbox/utils/functions';
import React from 'react';

interface Props {
  template: WhatsappTemplate;
}

const TemplatePreview: React.FC<Props> = ({ template }) => {
  const renderTemplateBody = (text: string) => {
    const bodyText = highlightAttributeFromText(text);

    return (
      <p
        className={`text-gray-500 font-normal
         text-[14px] leading-7 mb-2`}
        dangerouslySetInnerHTML={{ __html: bodyText.join(' ') }}
      />
    );
  };

  const renderTemplateHeader = (text: string, type: string | undefined) => {
    switch (type) {
      case 'text':
        const headerText = highlightAttributeFromText(text);

        return (
          <p
            className={`text-gray-800 font-semibold
             text-[14px] leading-7 mb-2 max-h-[200px] overflow-hidden`}
            dangerouslySetInnerHTML={{ __html: headerText.join(' ') }}
          ></p>
        );
      case 'image':
        return (
          <img
            src={text}
            alt='Block'
            className='w-auto mx-auto h-[138px] object-cover object-top rounded-md mb-2'
          />
        );
      case 'video':
        return (
          <div className={`flex w-full`}>
            <div key={1} className='relative cursor-pointer w-full'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full' />
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={text} />
              </video>
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
            </div>
          </div>
        );
      case 'document':
        return (
          <div className='relative flex flex-col items-center border border-gray-300 rounded-md py-5'>
            <DocumentIcon className='w-10 h-10 text-gray-400' />
            <div className='w-[90%] text-center mt-2 truncate text-gray-600'>
              {text?.split('/').pop()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className='absolute top-0 ltr:right-[-285px] rtl:right-[285px] w-[270px] h-auto
    bg-white rounded-md border border-gray-200 p-2'
    >
      <div>
        <div className='mb-2'>
          {renderTemplateHeader(
            template?.header?.value || '',
            template?.header?.type
          )}
        </div>
        {renderTemplateBody(template?.body || '')}
        {template?.buttons &&
          template?.buttons?.length > 0 &&
          template.buttons.map((item, index) => {
            return (
              <button
                key={index}
                className='w-full py-1 mb-1 text-xs font-medium text-gray-700 border border-gray-200 rounded-md x-2 py'
              >
                {item.title}
              </button>
            );
          })}
      </div>
    </div>
  );
};

export default TemplatePreview;
