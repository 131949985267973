import React from 'react';
import { NoSymbolIcon } from '@heroicons/react/24/outline';

interface infoProps {
  title: string;
  value: string;
  icon: React.ReactNode;
}

interface Prop {
  data1: infoProps;
  data2: infoProps;
}

const CustomerTotalInformationCard: React.FC<Prop> = ({ data1, data2 }) => {
  return (
    <div className='flex w-full'>
      <div className='flex flex-1 p-4 border-r'>
        <div className='rounded-md p-2.5 h-10 w-10 bg-blue-50'>
          {data1.icon || <NoSymbolIcon />}
        </div>
        <div className='ml-2'>
          <p className='font-sans text-xs font-normal text-gray-500'>
            {data1.title}
          </p>
          <p className='font-sans text-base font-medium text-gray-900'>
            {data1.value}
          </p>
        </div>
      </div>
      <div className='flex flex-1 p-4'>
        <div className='rounded-md p-2.5 h-10 w-10 bg-purple-50'>
          {data2.icon || <NoSymbolIcon />}
        </div>
        <div className='ml-2'>
          <p className='font-sans text-xs font-normal text-gray-500'>
            {data2.title}
          </p>
          <p className='font-sans text-base font-medium text-gray-900'>
            {data2.value}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerTotalInformationCard;
