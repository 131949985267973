import Accordion from '../../common/Accordion';
import {
  chatheadDataProps,
  LiveChatDataProps,
} from 'pages/integration/interface';
import RangeSlider from '../../common/RangeSlider';
import ChatHeadIcons from './chatHeadIcon';
import { Popover } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { SketchPicker } from 'react-color';
import InputFiledWithLimit from '../../common/InputFieldWithLimit';
import { useEffect, useState } from 'react';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  isOpen: boolean;
  liveChatData: LiveChatDataProps;

  handleAccordionClick: () => void;
  updateLiveChatData: (key: string, value: any) => void;
}

let defaultChatHeadColors = [
  '#10B981',
  '#3B82F6',
  '#8B5CF6',
  '#3C5BFF',
  '#EF4444',
];

const VisibilityComponent: React.FC<props> = ({
  isOpen,
  liveChatData,

  updateLiveChatData,
  handleAccordionClick,
}) => {
  const { t } = useTranslation();
  const headSizes = [
    { id: 'small', title: 'Icon only' },
    { id: 'large', title: 'Icon and Text' },
    { id: 'text_only', title: 'Text only' },
  ];

  const widgetPositions = [
    { id: 'left', title: 'Left' },
    { id: 'right', title: 'Right' },
  ];

  const [pickerColor, updatePickerColor] = useState('#10B981');
  const [chatHeadColors, updateChatHeadColors] = useState(
    defaultChatHeadColors
  );

  useEffect(() => {
    if (
      !defaultChatHeadColors.includes(
        liveChatData.livechat_data.chat_head_color.toUpperCase()
      )
    ) {
      defaultChatHeadColors[4] = liveChatData.livechat_data.chat_head_color;
      updateChatHeadColors([...defaultChatHeadColors]);
    }
  }, [liveChatData]);

  const handleNewChatHeadColor = (color: string) => {
    chatHeadColors[4] = color;
    updateChatHeadColors(chatHeadColors);
    updateLiveChatData(
      'chat_head_color',
      chatHeadColors[chatHeadColors.length - 1]
    );
  };

  const chatHeadColorList = () => {
    let isChecked: boolean = false;
    let ui = [];
    for (let i = 0; i < chatHeadColors.length; i++) {
      isChecked =
        liveChatData.livechat_data.chat_head_color.toLowerCase() ===
        chatHeadColors[i].toLowerCase();
      ui.push(
        <label
          className='relative w-6 h-6 items-center ltr:mr-2 rtl:ml-2 rounded-full'
          key={i}
        >
          <input
            type='checkbox'
            checked={isChecked}
            className={classNames(
              'without-ring w-6 h-6 rounded-full cursor-pointer mt-[-18px]',
              chatHeadColors[i].toUpperCase() === '#FFFFFF' ||
                chatHeadColors[i].toLowerCase() === 'white'
                ? 'border !border-gray-300'
                : 'border-0'
            )}
            style={{
              background: chatHeadColors[i],
            }}
            onChange={() => {
              updateLiveChatData('chat_head_color', chatHeadColors[i]);
            }}
          />
          {isChecked && (
            <CheckIcon
              className='absolute'
              color={`${
                chatHeadColors[i].toUpperCase() === '#FFFFFF' ||
                chatHeadColors[i].toLowerCase() === 'white'
                  ? '#6B7280'
                  : 'white'
              }`}
              width={15}
              height={15}
              style={{ left: '23%', top: '-35%' }}
            />
          )}
        </label>
      );
    }

    return ui;
  };

  const handleChatHeadDataChange = (data: chatheadDataProps[]) => {
    data.forEach((obj) => {
      updateLiveChatData(obj.key, obj.value);
    });
  };

  const getRadioGroup = (list: any[], radioFor: string) => {
    return list.map((data) => (
      <div key={data.id} className='flex gap-3 items-center'>
        <input
          id={data.id}
          name={radioFor}
          value={data.id}
          type='radio'
          checked={
            data.id.toLowerCase() ===
            liveChatData.livechat_data[radioFor].toLowerCase()
          }
          className='without-ring h-4 w-4 text-green-600 border-gray-300'
          onChange={(e) => {
            updateLiveChatData(radioFor, e.currentTarget.value.toLowerCase());
          }}
        />
        <label
          htmlFor={data.id}
          className='flex text-sm font-medium text-gray-700'
        >
          {t(data.title)}
        </label>
      </div>
    ));
  };

  return (
    <>
      <Accordion
        open={isOpen}
        title={t('Position and Visibility')}
        subTitle={t('Update chat head size, style, position and behavior.')}
        handleOnToggle={(open) => {
          handleAccordionClick();
        }}
      >
        <div className='grid grid-cols-1 divide-y'>
          <div className='px-5 py-3'>
            <label className='text-sm flex font-medium text-gray-700'>
              {t('Chat Head Size')}
            </label>

            <fieldset className='mt-4'>
              <legend className='sr-only'>Chat head size</legend>
              <div className='space-y-4 sm:flex sm:items-center sm:space-y-0 gap-4'>
                {getRadioGroup(headSizes, 'chat_head_size')}
              </div>
            </fieldset>

            <div
              className='mt-4'
              style={{
                display:
                  liveChatData.livechat_data.chat_head_size !== 'small'
                    ? 'block'
                    : 'none',
              }}
            >
              <InputFiledWithLimit
                limit={24}
                value={liveChatData.livechat_data.chat_head_text}
                handleOnChange={(value: string) => {
                  updateLiveChatData('chat_head_text', value);
                }}
              />
            </div>
          </div>

          <div className='px-5 py-3'>
            <div className='my-3'>
              <ChatHeadIcons
                handleChange={handleChatHeadDataChange}
                size={liveChatData.livechat_data.chat_head_size}
                iconId={liveChatData.livechat_data.chat_head_id}
                bgColor={liveChatData.livechat_data.chat_head_color}
                iconColor={liveChatData.livechat_data.font_color_regular}
                chatHeadText={liveChatData.livechat_data.chat_head_text}
                customImageUrl={liveChatData.livechat_data.chat_head_url}
                updateCustomIcon={(url: string) => {
                  updateLiveChatData('chat_head_url', url);
                }}
              />
            </div>
          </div>

          <div className='px-5 py-3'>
            <p className='text-sm flex font-medium text-gray-700  pb-2'>
              {t('Chat Head Color')}
            </p>
            <div className='rtl:float-right'>
              {chatHeadColorList()}
              <div className='h-6 border-1 bg-gray-300 mr-3 ml-1 inline-block w-[1px]'></div>
              <div className='inline-block'>
                <Popover className='relative'>
                  <Popover.Button>
                    <span className='rounded-full border border-gray-300 inline-block items-center p-1 justify-center'>
                      <PlusIcon width={15} color={'#6B7280'} strokeWidth={3} />
                    </span>
                  </Popover.Button>
                  <Popover.Panel className='absolute z-10'>
                    <SketchPicker
                      color={pickerColor}
                      onChange={(abc) => {
                        updatePickerColor(abc.hex);
                        handleNewChatHeadColor(abc.hex);
                      }}
                    />
                  </Popover.Panel>
                </Popover>
              </div>
            </div>
          </div>

          <div className='px-5 py-3'>
            <div className='py-4'>
              <label className='text-sm flex font-medium text-gray-700'>
                {t('Widget Position')}
              </label>

              <fieldset className='mt-4 mb-4'>
                <legend className='sr-only'>Widget position</legend>
                <div className='space-y-4 sm:flex gap-2 sm:items-center sm:space-y-0 sm:ltr:space-x-10'>
                  {getRadioGroup(widgetPositions, 'chat_head_position')}
                </div>
              </fieldset>

              <RangeSlider
                title={t('Bottom')}
                min={0}
                max={100}
                step={1}
                currentValue={
                  liveChatData.livechat_data.chat_head_bottom_spacing
                }
                handleChange={(val) =>
                  updateLiveChatData('chat_head_bottom_spacing', val)
                }
              />
              <br />
              <RangeSlider
                title={t('Side')}
                min={0}
                max={100}
                step={1}
                currentValue={liveChatData.livechat_data.chat_head_side_spacing}
                handleChange={(val) => {
                  updateLiveChatData('chat_head_side_spacing', val);
                }}
              />
            </div>
          </div>
        </div>
      </Accordion>
    </>
  );
};

export default VisibilityComponent;
