import dayjs from 'dayjs';
import { toInteger } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from './DataTableColumnHeader';
import { TableRowActions } from './TableRowActions';
import { DefaultAvatarIcon, Badge, Switch, LOCAL_UTILS } from '../../export';

import { ISLAData } from '../../interface';

type SLADataType = Record<string, any>;

function CreateDynamicColumns(
  platformList: any,
  slaData: ISLAData[],
  hasReadAccess: boolean,
  hasWriteAccess: boolean,
  hasDeleteAccess: boolean,
  handleIsActiveStatusChange: ({
    slaId,
    isActive,
  }: {
    slaId: string | number;
    isActive: boolean;
  }) => void,
  handleDeleteSla: (slaId: string) => any,
  handleSLAConfigurationClick: (slaData: ISLAData) => void,
  isSLADeleteLoading: boolean
) {
  if (!Array.isArray(slaData) || slaData.length === 0) {
    return [];
  }

  const defaultColumn = {
    name: '',
    created_at: '',
    created_by: '',
    platforms: [],
    is_active: '',
  };

  const firstRow = defaultColumn;

  const truncateText = (text: string, maxLength: number) => {
    if (!!text && text.length <= maxLength) {
      return text;
    }
    return text ? `${text.slice(0, maxLength)}...` : '--';
  };

  const createCellRenderer =
    (key: string) =>
    ({ row }: any) => {
      const cellValue = row.getValue(key);
      const isEmpty =
        cellValue === null || cellValue === '' || cellValue === undefined;

      if (isEmpty) {
        return <div>--</div>;
      }

      switch (key) {
        case 'name':
          return (
            <div className='text-sm font-medium text-textPrimary'>
              {truncateText(cellValue, 17)}
            </div>
          );

        case 'created_at':
          return (
            <div className=''>
              {dayjs(toInteger(cellValue) * 1000).format('ll')}
            </div>
          );

        case 'created_by':
          return (
            <div className='flex space-x-2'>
              <img
                alt='agents'
                loading='lazy'
                className='flex-shrink-0 w-6 h-6 ml-2 rounded-full'
                src={cellValue?.avatar || DefaultAvatarIcon}
              />
              <span>{truncateText(cellValue.full_name, 17)}</span>
            </div>
          );

        case 'platforms':
          return (
            <div>
              {cellValue.length === 0 ? (
                '--'
              ) : (
                <Badge className='text-xs font-medium bg-gray-100 shadow-none text-textPrimary hover:bg-gray-100'>
                  {cellValue.length === 1
                    ? truncateText(
                        LOCAL_UTILS.getPlatformNameList(
                          cellValue,
                          platformList
                        )[0],
                        20
                      )
                    : `${cellValue.length} Channels`}
                </Badge>
              )}
            </div>
          );

        case 'is_active':
          return (
            <div>
              <Switch
                checked={row.original.is_active}
                onCheckedChange={(value: boolean) => {
                  handleIsActiveStatusChange({
                    slaId: row.original.id,
                    isActive: !row.original.is_active,
                  });
                }}
              />
            </div>
          );

        default:
          return <div>{`${cellValue || '--'}`}</div>;
      }
    };

  const dynamicColumns: ColumnDef<SLADataType>[] = Object.keys(firstRow).map(
    (key, index) => ({
      accessorKey: key,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={key} />
      ),
      cell: createCellRenderer(key),
      enableSorting: true,
      enableHiding: true,
      size: index === 0 ? undefined : 190, // Set width to 190px for all columns except the first
    })
  );

  // Add the select component column
  // dynamicColumns.unshift({
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && 'indeterminate')
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label='Select all'
  //       className='translate-y-[2px] border-gray-500'
  //     />
  //   ),
  //   cell: ({ row }: any) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label='Select row'
  //       className='translate-y-[2px] border-gray-500'
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // });

  // Add the actions column
  dynamicColumns.push({
    id: 'actions',
    cell: ({ row }) => (
      <TableRowActions
        //@ts-ignore
        row={row}
        hasReadAccess={hasReadAccess}
        hasWriteAccess={hasWriteAccess}
        hasDeleteAccess={hasDeleteAccess}
        handleDeleteSla={handleDeleteSla}
        isSLADeleteLoading={isSLADeleteLoading}
        handleSLAConfigurationClick={handleSLAConfigurationClick}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  });

  return dynamicColumns;
}

export default CreateDynamicColumns;
