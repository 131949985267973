import React, { useEffect, useState } from 'react';
import TicketCard from './components/TicketCard';
import { connect } from 'react-redux';
import ascendingIcon from './assets/icon/ascendingIcon.svg';
import descendingIcon from './assets/icon/descendingIcon.svg';
import appliedFunnelIcon from './assets/icon/appliedFilterIcon.svg';
import filterIcon from '../../assets/images/filter_icon.svg';
import {
  PlusIcon as PlusIconMini,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Modal } from 'library';

import {
  Spinner,
  Popover,
  Position,
  Menu,
  Button,
  Icon,
  CornerDialog,
} from 'evergreen-ui';
import { feedChannels, messengerChannels } from '../../../utilities/utils';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from '../../../components';

interface platformProps {
  id: string;
  name: string;
  type: string;
  value: string;
  title: string;
}

interface tabDetails {
  name: string;
  value: string;
}

interface props {
  selectedPlatform: platformProps;
  updateStateData: (key: any, val: any) => void;
  queue: any[];
  authId: number;
  selectedQueue: tabDetails;
  ticketLoading: boolean;
  currentTicket: any;
  offset: number;
  limit: number;
  ticketOrder: string;
  ticketScrollLoading: boolean;
  fetchTicketsOnScroll: any;
  projectId: number;
  total: number;
  type: string;
  selectedProject: any;
  fetchUserInformation: (customerId: number) => void;
  fetchMessengerChat: (ticketId: number) => void;
  myQueueCount: null | number;
  allQueueCount: null | number;
  fetchFeedData: (ticketId: number) => void;
  channelEventEmitter: (customerId: number) => void;
  updateTicketQueue: (data: any) => void;
  pusherConnection: any;
  platforms: platformProps[];
  fetchTicketCount: () => void;
  hasWriteAccess: boolean;
  isResolved: () => number;
  handleResolveEvent: (ticketData: any) => void;
  updateSearch: (value: string) => void;
  searchKeyword: string;
  onFilterClick: () => void;
  onSearch: () => void;
  fetchCustomerMeta: (customerId: number) => void;
  filterData: () => any;
  handleEnCodeData: (key: string) => string;
  fetchEventTicketData: (event: any) => void;
  soundDuration: number;
  feedChannelEventEmitter: (ticketId: number) => void;
  location: any;
  getTicketOrder: () => string;
  fetchTicket: (order: string) => void;
  crmEcommerceClearState: () => void;
  queueType: () => QueueType;
  updateQueueType: (value: QueueType) => void;
  isFilterApplied: boolean;
  isInboxSupervisor: boolean;
  isFilterResetClicked: boolean;
  createWhatsAppTicket: any;
  getWhatsAppMesssageTemplates: (platformId: number) => void;
}

const LeftBar: React.FC<props> = ({
  updateStateData,
  queue,
  ticketLoading,
  authId,
  currentTicket,
  offset,
  limit,
  ticketOrder,
  ticketScrollLoading,
  fetchTicketsOnScroll,
  projectId,
  total,
  selectedProject,
  fetchUserInformation,
  fetchMessengerChat,
  fetchFeedData,
  channelEventEmitter,
  pusherConnection,
  updateTicketQueue,
  hasWriteAccess,
  isResolved,
  handleResolveEvent,
  updateSearch,
  searchKeyword,
  onFilterClick,
  onSearch,
  fetchCustomerMeta,
  filterData,
  handleEnCodeData,
  fetchEventTicketData,
  soundDuration,
  feedChannelEventEmitter,
  location,
  getTicketOrder,
  fetchTicket,
  crmEcommerceClearState,
  isFilterApplied,
  queueType,
  updateQueueType,
  createWhatsAppTicket,
  isInboxSupervisor,
  isFilterResetClicked,
  getWhatsAppMesssageTemplates,
}) => {
  const [delayLoading, setDelayLoading] = useState(false);
  const [channelId, setChannelId] = useState('');
  const [filterQueueMenu, setFilterQueueMenu] = React.useState<
    { label: string; value: QueueType }[]
  >([
    { label: 'My queue', value: 'self' },
    { label: 'Unassigned', value: 'unassigned' },
  ]);
  const [isCornerDialogueShown, setIsCornerDialogueShown] =
    React.useState(false);

  const [isEmptyTicketList, setIsEmptyTicketList] =
    React.useState<boolean>(false);

  const searchDebounce = useDebounce(searchKeyword, 500);
  const [customerCreateModal, setCustomerCreateModal] =
    useState<boolean>(false);
  const [whatsAppNumber, setWhatsAppNumber] = useState('');
  const [whatsAppChannel, setWhatsAppChannel] = useState('');

  React.useEffect(() => {
    if (isInboxSupervisor) {
      setFilterQueueMenu([
        { label: 'All queue', value: 'all' },
        { label: 'My queue', value: 'self' },
        { label: 'Unassigned', value: 'unassigned' },
      ]);
    } else {
      setFilterQueueMenu([
        { label: 'My queue', value: 'self' },
        { label: 'Unassigned', value: 'unassigned' },
      ]);
    }
  }, [isInboxSupervisor]);

  React.useEffect(() => {
    if (!!location.search || !!searchDebounce) {
      onSearch();
    }
    // eslint-disable-next-line
  }, [searchDebounce]);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStateData('msgCount', 0);
    }, soundDuration);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    setIsEmptyTicketList(false);
  }, [isFilterResetClicked, isFilterApplied]);

  const checkTicketQueue = (event: any) => {
    if (
      filterTicketQueue('resolved', event) &&
      filterTicketQueue('channels', event) &&
      getTicketOrder() === 'desc' &&
      (filterTicketQueue('agents', event) ||
        filterTicketQueue('groups', event)) &&
      filterTicketQueue('queue', event)
    ) {
      // we dont need it for startDate,endDate,tags, Note: groups and agents are in xor relation and rest in &&
      updateTicketQueue(event);

      //if current ticket is not selected we will fetch new ticket data
      if (!!currentTicket && event?.id !== currentTicket?.id) {
        fetchEventTicketData(event);
      }
    }
  };

  const filterTicketQueue = (key: string, event: any) => {
    const { channels, agents, agentSelection, groups, groupSelection } =
      filterData();
    //we are passing function not value of state because useEffect binds this once and keeps that filterData binded to that function
    // therefore when we pass function instead of value we get return of new and updated filterData where if we used value we would have got old data that was binded to event method
    switch (key) {
      case 'resolved':
        return !isResolved();
      case 'channels':
        let flagChannel: boolean = false;
        if (filterData().channels.length === 0) {
          return true;
        } else {
          channels.forEach((ch: any) => {
            if (ch?.id === event?.customer?.platform?.id) {
              flagChannel = true;
            }
          });
        }
        return flagChannel;
      case 'agents':
        let flagAgent: boolean = false;
        if (agentSelection === '') {
          //check agents list
          agents.forEach((agt: any) => {
            if (
              event.agents.length > 0 &&
              agt.admin.id === event.agents[0].id
            ) {
              flagAgent = true;
            }
          });
        } else if (agentSelection === 'all') {
          flagAgent = true;
        } else if (agentSelection === 'none') {
          flagAgent = false;
        }
        return flagAgent;
      case 'groups':
        let flagGroups: boolean = false;

        if (groupSelection === '') {
          //check groups list
          groups.forEach((grp: any) => {
            if (event.groups.length > 0 && grp.id === event.groups[0].id) {
              flagGroups = true;
            }
          });
        } else if (groupSelection === 'all') {
          flagGroups = true;
        } else if (groupSelection === 'none') {
          flagGroups = false;
        }
        return flagGroups;

      case 'queue':
        let flagQueue: boolean = false;
        if (queueType() === 'all') {
          return true;
        } else if (event.agents.length === 0) {
          return queueType() === 'unassigned';
        } else {
          if (event.agents[0].id === authId) {
            return queueType() === 'self';
          }
        }

        return flagQueue;
      default:
        return false;
    }
  };

  useEffect(() => {
    updateStateData('msgCount', 0);
    const channelEventEmitterForTicket = () => {
      if (!!channelId) {
        pusherConnection.unsubscribe(channelId);
      }

      if (pusherConnection) {
        const ticketChannel = pusherConnection.subscribe(
          `ticket-T_${projectId}`
        );

        setChannelId(`ticket-T_${projectId}`);

        ticketChannel.bind('ticket-update', (event: any) => {
          checkTicketQueue(event);
        });

        ticketChannel.bind('ticket-resolve', (ticketData: any) => {
          handleResolveEvent(ticketData);
        });
      }
      // if (pusherConnection) {
      //   const ticketChannel = pusherConnection.subscribe(
      //     `${authId}-ticket-created`
      //   );

      //   setChannelId(`${authId}-ticket-created`);

      //   ticketChannel.bind(`${authId}-ticket-updated`, (event: any) => {
      //     checkTicketQueue(event);
      //   });

      //   ticketChannel.bind(`${authId}-ticket-resolved`, (ticketData: any) => {
      //     handleResolveEvent(ticketData);
      //   });
      // }
    };

    channelEventEmitterForTicket();
    // eslint-disable-next-line
  }, [pusherConnection]);

  const handleScroll = (id: string) => {
    let el = document.getElementById(id);
    if (!!el) {
      if (el.scrollTop + el.offsetHeight > el.scrollHeight - 1) {
        // check if ticketLength === total

        //resolved all
        if (
          !ticketScrollLoading &&
          !isEmptyTicketList &&
          !delayLoading &&
          hasWriteAccess
        ) {
          setDelayLoading(true);
          setTimeout(async () => {
            // projectId: number,
            // isResolved: any,
            // offset: number,
            // limit: number,
            // search: string,
            // channels: string,
            // agents: string,
            // groups: string,
            // tags: string,
            // start: string,
            // end: string,

            let tickets = await fetchTicketsOnScroll(
              projectId,
              queueType(),
              isResolved(),
              offset + limit,
              limit,
              searchKeyword, //search
              handleEnCodeData('channels'), //channel
              handleEnCodeData('agents'), //agents
              handleEnCodeData('groups'), // groups
              handleEnCodeData('tags'), // tags
              handleEnCodeData('startDate'), //start
              handleEnCodeData('endDate'), //end
              getTicketOrder() //ticketOrder
            );
            setIsEmptyTicketList(tickets.length === 0);
            setDelayLoading(false);
          }, 1000);
        }
      }
    }
  };

  const toggleSort = async () => {
    if (ticketOrder === 'desc') {
      fetchTicket('asc');
    } else {
      fetchTicket('desc');
    }
  };

  const throttle = (callback: any, delay: number = 5000) => {
    let flag = true;
    return () => {
      if (flag) {
        callback();
        flag = false;
        setTimeout(() => {
          flag = true;
        }, delay);
      }
    };
  };

  const cleanWhatsAppNumber = (phone: string) => {
    return phone.replace(/[^0-9]/g, '');
  };

  const renderCreateNewWhatsAppCustomerModal = () => {
    return (
      <Modal
        title='Create WhatsApp Customer'
        confirmText='Create Now'
        description=' '
        height='280px'
        width='w-[450px]'
        open={customerCreateModal}
        handleClose={() => {
          setWhatsAppChannel('');
          setWhatsAppNumber('');
          setCustomerCreateModal(false);
        }}
        disableConfirm={
          whatsAppChannel === '' ? true : whatsAppNumber === '' ? true : false
        }
        handleConfirm={async () => {
          await createWhatsAppTicket(
            projectId,
            whatsAppChannel,
            whatsAppNumber
          );
          setWhatsAppChannel('');
          setWhatsAppNumber('');
          setCustomerCreateModal(false);
        }}
        handleCancel={() => {
          setWhatsAppChannel('');
          setWhatsAppNumber('');
          setCustomerCreateModal(false);
        }}
      >
        <div className='p-4 rounded-md bg-blue-50'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <InformationCircleIcon
                className='w-5 h-5 text-blue-400'
                aria-hidden='true'
              />
            </div>
            <div className='flex-1 ml-3 md:flex md:justify-between'>
              <p className='text-sm text-blue-700'>
                This will create a ticket in MyAlice inbox. Once created, you
                need to send a Message Template to the customer to initiate the
                conversation.
              </p>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <label htmlFor='wa_channel' className='block text-sm text-gray-500'>
            WhatsApp Channel
          </label>
          <select
            name='wa_channel'
            className='block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm'
            onChange={(e) => setWhatsAppChannel(e.target.value)}
          >
            <option disabled selected>
              {' '}
              -- Select an option --{' '}
            </option>
            {selectedProject.platforms
              .filter((item) => item.platform_type === 'whatsapp_bsp')
              .filter((whatsapp) => whatsapp.is_connected)
              .map((x) => (
                <option value={x.id}>{x.name}</option>
              ))}
          </select>
          <label className='block mt-2 text-sm text-gray-500'>
            Customer's WhatsApp Number
          </label>
          <div className='relative mt-1 rounded-md shadow-sm'>
            <input
              type='text'
              className='block w-full pr-10 placeholder-gray-400 border-gray-300 rounded-md focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm'
              placeholder='Numeric phone number with country code'
              name='title'
              onChange={(e) =>
                setWhatsAppNumber(cleanWhatsAppNumber(e.target.value))
              }
              maxLength={24}
              value={whatsAppNumber}
            />
            <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
              <p className='px-1 py-1 text-xs text-gray-400 rounded bg-gray-50'>
                {whatsAppNumber.length}/24
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className='customer-list'>
      <div className='customer-list__platform-filter'>
        <div className='flex space-between align-center'>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }: any) => (
              <Menu>
                <Menu.Group>
                  {filterQueueMenu.map((elem, index: number) => (
                    <Menu.Item
                      key={index}
                      onSelect={() => {
                        updateQueueType(elem?.value);
                        close();
                      }}
                    >
                      {elem?.label}{' '}
                    </Menu.Item>
                  ))}
                </Menu.Group>
              </Menu>
            )}
          >
            <Button
              className='uppercase alice-btn__default pl-8px'
              height={36}
              marginRight={16}
            >
              {filterQueueMenu.filter((e) => e.value === queueType())[0]?.label}
              &nbsp;
              {total === -1 ? (
                ''
              ) : (
                <span className='flex items-center justify-center w-auto h-4 p-1 text-sm text-white rounded-full bg-primary'>
                  {total > 999 ? '999+' : total === -1 ? '' : total}
                </span>
              )}
              <Icon icon='caret-down' className='ml-4' size={16} />
            </Button>
          </Popover>
          {renderCreateNewWhatsAppCustomerModal()}
          <div className='flex space-x-2 flex-end'>
            <Button
              className='alice-btn__default'
              onClick={onFilterClick}
              height={32}
              width={36}
              style={{ paddingLeft: 8, paddingRight: 4 }}
            >
              <img
                src={!!isFilterApplied ? appliedFunnelIcon : filterIcon}
                alt='filterIcon'
              />
            </Button>
            <Button
              className='alice-btn__default'
              onClick={() => toggleSort()}
              height={32}
              width={36}
              style={{ paddingLeft: 8, paddingRight: 4 }}
            >
              <img
                src={ticketOrder === 'desc' ? descendingIcon : ascendingIcon}
                alt=''
              />
            </Button>
          </div>
        </div>
      </div>
      <div className='customer-list__main-container'>
        <div className='flex justify-between mx-4 mr-6 py-3 h-[66px]'>
          <div className='relative w-full rounded-md shadow-sm'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <MagnifyingGlassIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              type='text'
              value={searchKeyword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSearch(e.target.value);
              }}
              className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
              placeholder='Search Customer Name...'
            />
          </div>
          {selectedProject.platforms
            .filter((item) => item.platform_type === 'whatsapp_bsp')
            .filter((whatsapp) => whatsapp.is_connected).length > 0 && (
            <button
              onClick={() => setCustomerCreateModal(true)}
              type='button'
              className='inline-flex items-center px-4 py-2 ml-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50'
            >
              <PlusIconMini className='w-5 h-5' aria-hidden='true' />
            </button>
          )}
        </div>
        {ticketLoading && (
          <div className='overflow-hidden customer-list__container'>
            <div className='customer-list__container-no-data'>
              <Spinner size={26} />
            </div>
          </div>
        )}
        {!ticketLoading && (
          <div
            className='customer-list__container'
            id='ticket-bar'
            onScroll={() => throttle(handleScroll('ticket-bar'))}
          >
            {!!queue &&
              queue.length > 0 &&
              queue.map((ticket, i) => (
                <TicketCard
                  selectedProject={selectedProject}
                  key={i}
                  id={ticket?.id}
                  currentTicket={currentTicket}
                  customerData={ticket?.customer}
                  ticketPlatform={ticket?.customer.platform}
                  agents={ticket?.agents}
                  groups={ticket?.groups}
                  locked={ticket?.is_locked}
                  isReplied={ticket?.is_replied}
                  cardText={ticket?.card_text}
                  cardTimestamp={ticket?.card_timestamp}
                  priority={ticket?.priority_str}
                  handleCurrentTicket={async () => {
                    fetchUserInformation(ticket?.customer?.id);
                    await fetchCustomerMeta(ticket?.customer?.id);
                    updateStateData('currentTicket', ticket);
                    const platformType = ticket?.customer?.platform?.type;
                    const platformId = ticket?.customer?.platform?.id;
                    crmEcommerceClearState();
                    if (messengerChannels.includes(platformType)) {
                      await fetchMessengerChat(ticket?.id);
                      if (platformType === 'whatsapp_bsp') {
                        await getWhatsAppMesssageTemplates(platformId);
                      }
                      channelEventEmitter(ticket?.customer.id);
                    } else if (feedChannels.includes(platformType)) {
                      await fetchFeedData(ticket?.id);
                      feedChannelEventEmitter(ticket?.id);
                    }
                  }}
                />
              ))}
            {(!queue || queue.length === 0) && (
              <div className='customer-list__container-no-data'>
                <div className={'flex align-center'}>
                  <div className='flex justify-center h-full text-center align-center flex-column'>
                    <svg
                      className='w-12 h-12 mx-auto text-gray-400'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        vectorEffect='non-scaling-stroke'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                      />
                    </svg>
                    <h3 className='mt-2 text-sm font-medium text-gray-900'>
                      No tickets
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {(ticketScrollLoading || delayLoading) && (
              <div className='customer-list__container-loader'>
                <Spinner size={26} />
              </div>
            )}
          </div>
        )}
        {ticketLoading && (
          <div className='overflow-hidden customer-list__container'>
            <div className='customer-list__container-no-data'>
              <Spinner size={26} />
            </div>
          </div>
        )}
      </div>
      <CornerDialog
        title='Notification is Disabled'
        isShown={isCornerDialogueShown}
        onCloseComplete={() => setIsCornerDialogueShown(false)}
        hasFooter={false}
      >
        <div>
          Notification permission is disabled from your browser. To allow
          Browser Notifications, please follow the steps:
          <p>1. Go to Settings</p>
          <p>2. Go to Site Settings from the Privacy and Security section </p>
          <p>3. Find Notifications and click on Allow</p>
        </div>
      </CornerDialog>
    </div>
  );
};

const mapState = (state: any) => ({
  queue: state.crm.queue,
  authId: state.auth.id,
  selectedQueue: state.crm.selectedQueue,
  selectedPlatform: state.crm.selectedPlatform,
  ticketScrollLoading: state.loading.effects.crm.fetchTicketsOnScroll,
  currentTicket: state.crm.currentTicket,
  limit: state.crm.limit,
  total: state.crm.total,
  offset: state.crm.offset,
  ticketOrder: state.crm.ticketOrder,
  selectedProject: state.dashboard.selectedProject,
  myQueueCount: state.crm.myQueueCount,
  allQueueCount: state.crm.allQueueCount,
  platforms: state.dashboard.selectedProject.platforms,
  soundDuration: state.crm.soundDuration,
});

const mapDispatch = (dispatch: any) => ({
  updateTicketQueue: (data: any) => dispatch.crm.updateTicketQueue(data),
  updateStateData: (key: string, value: any) =>
    dispatch.crm.updateStateData({ key, value }),
  fetchTicketsOnScroll: (
    projectId: number,
    queue: QueueType,
    isResolved: any,
    offset: number,
    limit: number,
    search: string,
    channels: string,
    agents: string,
    groups: string,
    tags: string,
    start: string,
    end: string,
    ticketOrder: string
  ) =>
    dispatch.crm.fetchTicketsOnScroll({
      projectId,
      queue,
      isResolved,
      offset,
      limit,
      search,
      channels,
      agents,
      groups,
      tags,
      start,
      end,
      ticketOrder,
    }),
  fetchUserInformation: (customerId: number) =>
    dispatch.crm.fetchUserInformation(customerId),
  fetchCustomerMeta: (customerId: number) =>
    dispatch.crm.fetchCustomerMeta(customerId),
  fetchMessengerChat: (ticketId: number) =>
    dispatch.crm.fetchMessengerChat(ticketId),
  getWhatsAppMesssageTemplates: (platformId: number) =>
    dispatch.crm.getWhatsAppMesssageTemplates({ platformId }),
  fetchFeedData: (ticketId: number) => dispatch.crm.fetchFeedData(ticketId),
  crmEcommerceClearState: () => dispatch.crmEcommerce.clearState(),
  createWhatsAppTicket: (teamId: number, waChannelId: string, phone: string) =>
    dispatch.crm.createWhatsAppTicket({ teamId, waChannelId, phone }),
});

const LeftBarContainer = connect(mapState, mapDispatch)(LeftBar);
export default LeftBarContainer;
