import React from 'react';
import Modal from '../../common/Modal';
import Stepper from '../../common/Stepper';
import Step1 from './Step1';
import Step2 from './Step2';

import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { getModalCustomFooter } from '../../../utils/functions';

interface Props {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  handleChannelCreate: (
    channelType: string,
    channelData: any
  ) => Promise<channelCreateAPiResponseType>;
}

const formSteps = [
  { id: 1, name: 'Connect Whatsapp Number' },
  { id: 2, name: 'General Information' },
];

const initialSettingData = {
  title: '',
  description: '',
  primary_token: '',
};

const WhatsappModal: React.FC<Props> = ({
  open,
  teamName,
  handleClose,
  handleChannelCreate,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [whatsappSettingData, setWhatsappSettingData] =
    React.useState(initialSettingData);

  //functions

  const clearState = () => {
    setWhatsappSettingData(initialSettingData);
    setStep(1);
    setLoading(false);
    setSuccess(true);
    setErrorMessage('');
  };

  const handleWhatsappResponse = (token: string) => {
    setWhatsappSettingData({
      ...whatsappSettingData,
      primary_token: token,
    });
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1: {
        setStep(2);
        break;
      }
      case 2:
        setLoading(true);
        let payloadData = {
          title: whatsappSettingData.title,
          description: whatsappSettingData.description,
          primary_token: whatsappSettingData.primary_token,
        };
        let res = await handleChannelCreate('whatsapp_bsp', payloadData);
        if (res.status === 200) {
          setLoading(false);
          navigate(`/dashboard`);
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? t(
                  'Something went wrong while integrating the channel. Try again later.'
                )
              : res.data.error
          );
        }
        break;
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
    }
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 1:
        if (loading) return true;
        return !whatsappSettingData.primary_token;
      default:
        let flag = false;
        const mandatoryKeys = ['title'];

        mandatoryKeys.forEach((items) => {
          if (!(whatsappSettingData && whatsappSettingData[items])) {
            flag = true;
          }
        });
        return flag;
    }
  };

  return (
    <Modal
      open={open}
      teamName={teamName}
      title={
        isRtlLanguage ? t('Whatsapp Messenger to') : t('Whatsapp Messenger')
      }
      platfromType='whatsapp_messenger'
      closeOnExternalClick={false}
      handleClose={() => {
        if (step === 2) {
          navigate(`/dashboard?channel_type=whatsapp_bsp`);
        }
        clearState();
        handleClose();
      }}
      handleCancel={() => handleCancel(step)}
      disableConfirm={checkConfirmButtons() || loading}
      handleConfirm={handleConfirm}
      hideCancel={true}
      confirmText={step === 1 ? t('Next') : t('Finish')}
      customFooterContent={getModalCustomFooter(
        t('Confused about what to do?'),
        'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-whatsapp',
        t('Read Documentation')
      )}
    >
      <div className='p-6'>
        <Stepper steps={formSteps} currentStep={step} />
        <div className='mt-10'>
          {
            {
              1: (
                <Step1
                  loading={loading}
                  type={'whatsapp_bsp'}
                  processWhatsappResponse={handleWhatsappResponse}
                />
              ),
              2: (
                <Step2
                  success={success}
                  errorMessage={errorMessage}
                  whatsappSettingData={whatsappSettingData}
                  handleChange={(
                    key: string,
                    value: string | string[] | boolean
                  ) => {
                    setWhatsappSettingData({
                      ...whatsappSettingData,
                      [key]: value,
                    });
                  }}
                />
              ),
            }[step]
          }
        </div>
      </div>
    </Modal>
  );
};
export default WhatsappModal;
