import React from 'react';
import { nlpInfoProps } from '../../../interface';
import { channelInfo } from '../../../../../utilities/utils';
import EditMenu from './EditMenu';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  elementData: nlpInfoProps;
  handleChannelEdit: () => void;
  setShowModal: () => void;
}

const NlpCard: React.FC<Props> = ({
  elementData,
  handleChannelEdit,
  setShowModal,
}) => {
  const channelData = channelInfo(elementData.provider);
  const { t } = useTranslation();

  const getChannleTitle = () => {
    if (elementData.name.length === 0) return '---';
    else
      return elementData?.name?.length > 25
        ? elementData?.name.substring(0, 25) + '...'
        : elementData?.name;
  };

  return (
    <div className='p-5 border border-gray-200 rounded w-fit'>
      <div className='flex justify-between align-top'>
        <div className='flex gap-2.5 align-top'>
          <img src={channelData.image} className='w-9 h-9' alt={'icon_box'} />
          <div className='mt-[-17px]'>
            <p className='font-medium text-base align-center mt-3 mb-1 capitalize'>
              {getChannleTitle()}
            </p>
            <div className='flex gap-[3px]'>
              <span className='text-sm font-normal text-gray-500'>
                {t('Confidence Threshold:')} {elementData.confidence}
              </span>
            </div>
          </div>
        </div>

        <EditMenu
          providerType={'NLP'}
          platformType={elementData.provider}
          handleRemove={setShowModal}
          handleEdit={() => handleChannelEdit()}
        />
      </div>
    </div>
  );
};
export default NlpCard;
