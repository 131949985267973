import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../assets/styles/elements/button.scss';
import { reorderList } from '../../../utilities/utils';
import PropTypes from 'prop-types';
import ButtonFragment from './ButtonFragment';
import CreateButtonFragment from './CreateButtonFragment';

const ButtonGroup = ({
  blockId,
  sequences,
  maxButtonCount,
  buttonTitle,
  hasDelete,
  type,
  APIButtonTitle,
  buttonList,
  formsData,
  handleDropUpdate,
  handleDelete,
  handleCreate,
  handleUpdate,
  allowedTypes,
  dragDirection,
  hasAPIButton,
  hasActionButton,
  apiButtonData,
  apiList,
  hasMessengerExtension,
  hasWebViewHeight,
  handleAPIButtonElementCreate,
  hasWriteAccess,
  hasDragnDrop,
  showIndex,
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      buttonList,
      result.source.index,
      result.destination.index
    );

    handleDropUpdate(blockId, items);
  };

  const manageHandleCreateMethod = (blockId, buttonType) => {
    buttonType === 'ecommerce'
      ? handleCreate(blockId, 'ecommerce')
      : handleCreate(blockId);
  };

  return (
    <div className='button-group'>
      {hasDragnDrop && (
        <DragDropContext
          onDragEnd={(result) => hasWriteAccess && onDragEnd(result)}
        >
          <Droppable droppableId='droppable' direction={dragDirection}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className='button-group__droppable'
              >
                {!!buttonList &&
                  buttonList.length > 0 &&
                  buttonList.map((button, i) => (
                    <Draggable key={i} draggableId={`button-${i}`} index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='button-group__draggable'
                        >
                          <ButtonFragment
                            key={i}
                            id={button.id}
                            index={i}
                            sequences={sequences}
                            blockType={type}
                            formsData={formsData}
                            title={button.title}
                            description={button?.description}
                            hasDelete={hasDelete}
                            type={button.type}
                            value={button.value}
                            category={button.category}
                            inputValue={button.input_value}
                            formSequence={button.form_sequence}
                            hasMessengerExtension={hasMessengerExtension}
                            messengerExtensions={button.messenger_extensions}
                            hasWebViewHeight={hasWebViewHeight}
                            webViewHeight={button.webview_height_ratio}
                            handleDelete={(index) =>
                              !!hasDelete && handleDelete(blockId, index)
                            }
                            handleUpdate={(data, type) =>
                              handleUpdate(blockId, data, type)
                            }
                            allowedTypes={allowedTypes}
                            hasWriteAccess={hasWriteAccess}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {!hasDragnDrop && (
        <div className='button-group__droppable'>
          {!!buttonList &&
            buttonList.length > 0 &&
            buttonList.map((button, i) => (
              <div key={i} className='button-group__draggable'>
                <ButtonFragment
                  key={i}
                  id={button.id}
                  index={i}
                  showIndex={showIndex}
                  sequences={sequences}
                  formsData={formsData}
                  title={button.title}
                  hasDelete={hasDelete}
                  type={button.type}
                  value={button.value}
                  category={button.category}
                  formSequence={button.form_sequence}
                  hasMessengerExtension={hasMessengerExtension}
                  messengerExtensions={button.messenger_extensions}
                  hasWebViewHeight={hasWebViewHeight}
                  webViewHeight={button.webview_height_ratio}
                  handleDelete={(index) =>
                    !!hasDelete && handleDelete(blockId, index)
                  }
                  handleUpdate={(data, type) =>
                    handleUpdate(blockId, data, type)
                  }
                  allowedTypes={allowedTypes}
                  hasWriteAccess={hasWriteAccess}
                />
              </div>
            ))}
        </div>
      )}
      {maxButtonCount > buttonList.length && hasWriteAccess && (
        <CreateButtonFragment
          type={type}
          hasAPIButton={hasAPIButton}
          hasActionButton={hasActionButton}
          APIButtonTitle={APIButtonTitle}
          buttonTitle={buttonTitle}
          buttonCount={buttonList.length}
          handleCreate={(buttonCategory = 'regular') =>
            manageHandleCreateMethod(blockId, buttonCategory)
          }
          apiButtonData={apiButtonData}
          apiList={apiList}
          handleAPIButtonElementCreate={handleAPIButtonElementCreate}
          blockId={blockId}
        />
      )}
    </div>
  );
};
ButtonGroup.propTypes = {
  maxButtonCount: PropTypes.number.isRequired,
  buttonList: PropTypes.array.isRequired,
  formsData: PropTypes.array,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  hasAPIButton: PropTypes.bool,
  type: PropTypes.string.isRequired,
  hasMessengerExtension: PropTypes.bool,
  webViewHeight: PropTypes.bool,
  hasDelete: PropTypes.bool,
  hasWriteAccess: PropTypes.bool,
  hasDragnDrop: PropTypes.bool,
  showIndex: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  allowedTypes: ['Sequence', 'URL', 'Phone'],
  dragDirection: 'horizontal',
  hasAPIButton: false,
  hasMessengerExtension: false,
  hasWriteAccess: false,
  hasDelete: true,
  hasDragnDrop: true,
  hasActionButton: false,
  showIndex: false,
  apiButtonData: {},
  apiList: [],
  APIButtonTitle: 'Create a API Button',
  handleAPIButtonElementCreate: () =>
    console.log('need to define when hasAPIButton is true'),
};

export default ButtonGroup;
