import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Checkbox, Icon, Pane, Radio } from 'evergreen-ui';

const PopupUrlContent = ({
  inputVal,
  handleChange,
  category = 'regular',
  hasExternalURL = true,
  messengerExtensions,
  handleMessengerExtensionChange,
  hasMessengerExtension,
  hasWebViewHeight,
  webViewHeight,
  handleWebViewHeightChange,
}) => {
  const regexUrl = /^https:\/\/(www\.)?([A-z]+)\.([A-z]{2,})/gi;
  return (
    <Fragment>
      {hasExternalURL && (
        <>
          <h5>Open Url</h5>
          <TextInput
            value={inputVal}
            onChange={(e) => handleChange(e.target.value)}
            placeholder='Type url'
          />
          {!!messengerExtensions && !inputVal.match(regexUrl) && (
            <p className='button-popup__hint'>
              <Icon
                icon='warning-sign'
                color='warning'
                size={12}
                marginRight={5}
              />{' '}
              Warning! Need HTTPS links in the url
            </p>
          )}
          <p className='button-popup__hint'>
            <strong>Hint: </strong>
            <span>
              Url is Where you will redirect after clicking this button This can
              be any webpage or link.
            </span>
          </p>
        </>
      )}
      {category === 'ecommerce' && (
        <p className='button-popup__hint'>
          <strong>Hint: </strong>
          <span>
            A link to view the Product will be retrieved automatically from the
            backend and given to your Customer.
          </span>
        </p>
      )}
      {hasWebViewHeight && messengerExtensions && (
        <div className='aspect-ratio__container'>
          <p className='button-popup__hint'>Web View Aspect Ratio:</p>
          <Pane display={'flex'}>
            <Radio
              marginRight={10}
              onChange={(e) =>
                e.target.checked && handleWebViewHeightChange('tall')
              }
              label='Tall'
              checked={webViewHeight === 'tall'}
            />
            <Radio
              label='Full'
              marginRight={10}
              onChange={(e) =>
                e.target.checked && handleWebViewHeightChange('full')
              }
              checked={webViewHeight === 'full'}
            />
            <Radio
              label='Compact'
              onChange={(e) =>
                e.target.checked && handleWebViewHeightChange('compact')
              }
              checked={webViewHeight === 'compact'}
            />
          </Pane>
        </div>
      )}
      {hasMessengerExtension && (
        <Checkbox
          label='Open In Messenger Extension'
          checked={messengerExtensions}
          onChange={(e) => handleMessengerExtensionChange(e.target.checked)}
          marginBottom={10}
        />
      )}
    </Fragment>
  );
};

PopupUrlContent.propTypes = {
  inputVal: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  hasWebViewHeight: PropTypes.bool,
  webViewHeight: PropTypes.string,
};

export default PopupUrlContent;
