import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toaster } from 'evergreen-ui';

const initialState = {
  channelList: [],
};

export const channel = {
  state: {
    ...initialState,
  },
  reducers: {
    updateChannelList(state, payload) {
      return { ...state, channelList: payload };
    },
    appendChannelList(state, payload) {
      return { ...state, channelList: [...state.channelList, payload] };
    },
    replaceChannelList(state, payload) {
      const localChannelList = state.channelList.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }
        return channel;
      });
      return { ...state, channelList: localChannelList };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchChannel(projectId) {
      try {
        const res = await axios.get(config.dashboard.channelList(projectId));
        if (res.data.success) {
          dispatch.channel.updateChannelList(res.data.dataSource);
          dispatch.dashboard.updatePlatformList(res.data.dataSource);
        }
      } catch (err) {
        dispatch.channel.updateChannelList([]);
      }
    },
    async channelInit(payload) {
      // payload = {
      //   projectId: number
      //   body : {
      //     title: string,
      //     description: string,
      //     type: string,
      //   }
      // }
      try {
        const res = await axios.post(
          config.integration.createChannelIntegrations(
            payload.body.type,
            payload.projectId
          ),
          payload.body
        );
        if (res.status === 200 && res.data.success) {
          dispatch.channel.appendChannelList(res.data.dataSource);
          dispatch.dashboard.appendPlatformList(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    async fetchFacebookPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          config.channel.facebookPages(payload.id, payload.token, payload.type)
        );
        return res.data;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async fetchInstagramPages(payload) {
      /*
        payload = {
          id: num // fb userID
          token: string //temp accessToken
        }
       */
      try {
        const res = await axios.get(
          config.channel.instagramPage(payload.id, payload.token, payload.type)
        );
        return res.data;
      } catch (err) {
        toaster.danger(err.response.data.error);
      }
    },
    async connectChannel(payload) {
      /*
        payload = {
          id: num // project id
          type: string // facebook || viber
          data: object // page information
        }
       */
      try {
        const res = await axios.post(
          config.channel.connect(payload.id),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          toaster.success('Platform Connected Successfully!');
          return true;
        } else {
          toaster.danger('Failed to Connect!');
          return false;
        }
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },
    async disconnectChannel(channelId) {
      /*
        channelId: number
       */
      try {
        const res = await axios.post(config.channel.disconnect(channelId));
        if (res.status === 200 && res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          toaster.success('Platform Disconnected Successfully!');
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },
    async archiveChannel(channelId) {
      /*
        channelId: number
       */
      try {
        const res = await axios.post(config.channel.archive(channelId));

        if (res.status === 200 && res.data.success) {
          toaster.success('Platform Removed Successfully!');
          return true;
        }
        return false;
      } catch (err) {
        toaster.danger(err.response.data.error);
        return false;
      }
    },
    async connectHandoverProtocol(payload) {
      /*
      payload =
          channelId: number
          appId: string
       */
      try {
        const res = await axios.post(
          config.channel.secondaryReciever(payload.channelId),
          {
            app_id: payload.appId,
          }
        );
        if (res.data.success) {
          toaster.success('Handover Protocol Updated', {
            description: 'Congrats! Handover Protocol Has been Updated',
          });
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
    async updateChannelData(payload) {
      /*
      payload =
          channelId: number
          data: channel all edited data
       */
      try {
        const {
          primary_color,
          persistent_menu,
          nlp_engine,
          nlp_info,
          nlp_confidence,
          title,
          description,
          username,
          url,
          avatar,
          name,
          cover,
          whitelisted_domains,
          ice_breaker,
        } = payload.data;

        let persistentMenu = persistent_menu.filter(
          (elem) => !!elem.title && !!elem.type && !!elem.value
        );

        let iceBreaker = ice_breaker.filter(
          (elem) => !!elem.title && !!elem.type && !!elem.value
        );
        const res = await axios.post(config.channel.update(payload.channelId), {
          primary_color: primary_color,
          persistent_menu: persistentMenu,
          nlp_engine: nlp_engine, // may add more later
          nlp_info: nlp_info,
          nlp_confidence: nlp_confidence,
          title: title,
          description: description,
          username: username,
          name: name,
          url: url,
          avatar: avatar,
          cover: cover,
          whitelisted_domains: whitelisted_domains,
          ice_breaker: iceBreaker,
        });
        if (res.data.success) {
          dispatch.channel.replaceChannelList(res.data.dataSource);
          dispatch.dashboard.replaceChannelList(res.data.dataSource);
          toaster.success('Success', {
            description: 'Updated Successfully',
          });
          return true;
        }
        return false;
      } catch (err) {
        !!err.response && toaster.danger(err.response.data.error);
      }
    },
    async fetchChannelCloneBlocks(payload) {
      /*
      payload: channelId, cloneType
       */
      try {
        const res = await axios.post(
          config.channel.disallowedBlocks(payload.channelId),
          {
            cloned_platform_type: payload.cloneType,
          }
        );
        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else {
          return [];
        }
      } catch (err) {
        return [];
      }
    },
    async cloneChannel(payload, rootState) {
      /*
      payload: channelId, cloneType, name , description
       */
      try {
        const res = await axios.post(
          config.channel.cloneChannel(payload.channelId),
          {
            platform_type: payload.cloneType,
            platform_name: payload.name,
            description: payload.description,
          }
        );
        if (res.status === 200 && res.data.success) {
          await dispatch.dashboard.fetchProject();
          if (!!rootState.dashboard.selectedProject) {
            const project = rootState.dashboard.projects.filter(
              (project) => project.id === rootState.dashboard.selectedProject.id
            )[0];
            dispatch.channel.fetchChannel(project.id);
          } else {
            dispatch.dashboard.setSelectedProject(
              rootState.dashboard.projects[0]
            );
            dispatch.dashboard.fetchPlatformList(
              rootState.dashboard.projects[0].id
            );
            dispatch.channel.fetchChannel(rootState.dashboard.projects[0].id);
          }
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
  }),
};
