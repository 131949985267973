import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

const Protected = ({
  authenticated,
  forceLogout,
  logout,
  children,
  location,
}) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    const handleRedirect = async () => {
      // Check if user is authenticated
      if (!authenticated) {
        // Store the current path in localStorage before redirecting to login
        await localStorage.setItem(
          'previousPath',
          location.pathname + location.search
        );
        setRedirectToLogin(true);
      }
    };

    handleRedirect();

    //eslint-disable-next-line
  }, [authenticated]);

  // Logout if forceLogout is true
  if (forceLogout) {
    logout();
    return <Redirect from='' to='/' noThrow />;
  }

  // Render children if authenticated
  if (authenticated) {
    return children;
  }

  // Redirect to login if not authenticated
  if (redirectToLogin) {
    return <Redirect from='' to='/' noThrow />;
  }

  return null;
};

const mapState = (state) => {
  const accessToken = state.auth.access;

  return {
    authenticated: accessToken !== '',
    forceLogout: state.auth.forceLogout,
  };
};

const mapDispatch = (dispatch) => ({
  logout: () => dispatch.auth.logout(),
});

export default connect(mapState, mapDispatch)(Protected);
