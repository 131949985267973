interface Props {
  width?: string;
  height?: string;
  color?: string;
  isFilled?: boolean;
}

interface OnlyClassProp {
  className?: string;
  color?: string;
}

const FunnelIcon: React.FC<Props> = ({
  width = '18',
  height = '18',
  color = '#6B7280',
  isFilled = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className='rotate-[270deg]'
      viewBox='0 0 18 18'
      fill={isFilled ? color : 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 2.33333C1.5 1.8731 1.8731 1.5 2.33333 1.5H15.6667C16.1269 1.5 16.5 1.8731 16.5 2.33333V4.48816C16.5 4.70917 16.4122 4.92113 16.2559 5.07741L10.9107 10.4226C10.7545 10.5789 10.6667 10.7908 10.6667 11.0118V13.1667L7.33333 16.5V11.0118C7.33333 10.7908 7.24554 10.5789 7.08926 10.4226L1.74408 5.07741C1.5878 4.92113 1.5 4.70917 1.5 4.48816V2.33333Z'
        stroke={color}
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export const FilterBarsIcon: React.FC<OnlyClassProp> = ({
  className = '',
  color = '#9CA3AF',
}) => {
  return (
    <svg
      width='16'
      height='16'
      className={className}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.66602 4.66675H13.3327'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
      />
      <path
        d='M4 8H12'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
      />
      <path
        d='M6 11.3333H10'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
      />
    </svg>
  );
};

export const SearchFunnelIcon: React.FC<{ className: string }> = ({
  className,
}) => {
  return (
    <svg
      width='49'
      height='49'
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M16.5 32.5L22.2574 26.7426M22.2574 26.7426C23.3431 27.8284 24.8431 28.5 26.5 28.5C29.8137 28.5 32.5 25.8137 32.5 22.5C32.5 19.1863 29.8137 16.5 26.5 16.5C23.1863 16.5 20.5 19.1863 20.5 22.5C20.5 24.1569 21.1716 25.6569 22.2574 26.7426ZM42.5 24.5C42.5 34.4411 34.4411 42.5 24.5 42.5C14.5589 42.5 6.5 34.4411 6.5 24.5C6.5 14.5589 14.5589 6.5 24.5 6.5C34.4411 6.5 42.5 14.5589 42.5 24.5Z'
        stroke='#9CA3AF'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default FunnelIcon;
