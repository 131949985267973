import React from 'react';
import CustomerInformationCard from './CustomerInformationCard';
import CustomerTotalInformationCard from './CustomerTotalInformationCard';
import { ChatBubbleOvalLeftEllipsisIcon, UsersIcon } from '@heroicons/react/24/outline';

interface customerSummaryProps {
  average_value: number;
  frequency_days: number;
  lifetime_value: number;
  total_orders: number;
  total_tickets: number;
  total_visits: number;
}

interface Prop {
  fetchCustomerOrderSummary: () => void;
  eComCustomerSummary: customerSummaryProps | null;
  currencySymbol: string;
}

const CustomerSummaryCRM: React.FC<Prop> = ({
  fetchCustomerOrderSummary,
  eComCustomerSummary,
  currencySymbol,
}) => {
  React.useEffect(() => {
    fetchCustomerOrderSummary();
    // eslint-disable-next-line
  }, []);

  const getCustomerCurrencyValue = (currencyValue: number | undefined) => {
    return !currencyValue ? 'N/A' : `${currencySymbol}${currencyValue}`;
  };

  return (
    <div className='bg-white shadow rounded-b-md'>
      <CustomerInformationCard
        title='Customer Lifetime Value'
        titleInfo='Total value of orders taken by the customer'
        hasBorderTop={false}
        value={getCustomerCurrencyValue(eComCustomerSummary?.lifetime_value)}
      />
      <CustomerInformationCard
        title='Total Orders'
        titleInfo='Total number of orders placed by the customer'
        value={`${eComCustomerSummary?.total_orders}`}
        unit={'Orders'}
        hasBorderTop={false}
      />
      <CustomerInformationCard
        title='Average Order Value'
        titleInfo='Average value of order placed by the customer'
        value={getCustomerCurrencyValue(eComCustomerSummary?.average_value)}
        hasBorderTop={false}
      />
      <CustomerInformationCard
        title='Order Frequency'
        titleInfo='Interval of time between which customer places the next order '
        hasBorderTop={false}
        value={`${eComCustomerSummary?.frequency_days}`}
        unit={'Days'}
      />
      <CustomerTotalInformationCard
        data1={{
          title: 'Total Clicks',
          value: `${eComCustomerSummary?.total_visits}`,
          icon: <UsersIcon />,
        }}
        data2={{
          title: 'Total Tickets',
          value: `${eComCustomerSummary?.total_tickets}`,
          icon: <ChatBubbleOvalLeftEllipsisIcon />,
        }}
      />
    </div>
  );
};

export default CustomerSummaryCRM;
