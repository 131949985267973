export const authTranslationData = {
  code_text: {
    af: '{{verification_code}} is u verifikasiekode.',
    ar: '{{verification_code}} هو رمز التحقق الخاص بك.',
    sq: '{{verification_code}} është kodi juaj i verifikimit.',
    az: '{{verification_code}} doğrulama kodunuzdur.',
    bn: '{{verification_code}} আপনার যাচাইকরণ কোড।',
    bg: '{{verification_code}} е вашият код за проверка.',
    ca: '{{verification_code}} és el vostre codi de verificació.',
    zh_CN: '{{verification_code}}是您的验证代码。',
    zh_HK: '{{verification_code}}是您的驗證代碼。',
    zh_TW: '{{verification_code}}是您的驗證代碼。',
    hr: '{{verification_code}} je vaš kôd za provjeru.',
    cs: '{{verification_code}} je váš ověřovací kód.',
    da: '{{verification_code}} er din verifikationskode.',
    nl: '{{verification_code}} is uw verificatiecode.',
    en: '{{verification_code}} is your verification code.',
    en_GB: '{{verification_code}} is your verification code.',
    en_US: '{{verification_code}} is your verification code.',
    et: '{{verification_code}} on teie kinnituskood.',
    fil: '{{verification_code}} ay ang iyong verification code.',
    fi: '{{verification_code}} on varmennuskoodisi.',
    fr: '{{verification_code}} est votre code de vérification.',
    ka: '{{verification_code}} არის თქვენი გადამოწმების კოდი.',
    de: '{{verification_code}} ist Ihr Verifizierungscode.',
    el: '{{verification_code}} είναι ο κωδικός επαλήθευσης.',
    gu: '{{verification_code}} તમારો ચકાસણી કોડ છે.',
    ha: '{{verification_code}} lambar tabbaci.',
    he: '{{verification_code}} הוא קוד האימות שלך.',
    hi: '{{verification_code}} आपका सत्यापन कोड है।',
    hu: '{{verification_code}} az Ön ellenőrző kódja.',
    id: '{{verification_code}} adalah kode verifikasi Anda.',
    ga: 'Is é {{verification_code}} do chód fíoraithe.',
    it: '{{verification_code}} è il tuo codice di verifica.',
    ja: '{{verification_code}}は確認コードです。',
    kn: '{{verification_code}} ನಿಮ್ಮ ಪರಿಶೀಲನಾ ಸಂಕೇತವಾಗಿದೆ.',
    kk: '{{verification_code}} - бұл тексеру коды.',
    rw_RW: '{{verification_code}} ni code yawe yo kugenzura.',
    ko: '{{verification_code}}은 확인 코드입니다.',
    ky_KG: '{{verification_code}} – сиздин текшерүү кодунуз',
    lo: '{{verification_code}} ແມ່ນລະຫັດຢືນຢັນຂອງທ່ານ.',
    lv: '{{verification_code}} ir jūsu verifikācijas kods.',
    lt: '{{verification_code}} yra jūsų patikrinimo kodas.',
    mk: '{{verification_code}} е вашиот код за верификација.',
    ms: '{{verification_code}} ialah kod pengesahan anda.',
    ml: '{{verification_code}} നിങ്ങളുടെ സ്ഥിരീകരണ കോഡാണ്.',
    mr: '{{verification_code}} आपला सत्यापन कोड आहे.',
    nb: '{{verification_code}} er din bekreftelseskode.',
    fa: '{{verification_code}} کد تأیید شماست.',
    pl: '{{verification_code}} to twój kod weryfikacyjny.',
    pt_BR: '{{verification_code}} é o seu código de verificação.',
    pt_PT: '{{verification_code}} é o seu código de verificação.',
    pa: '{{verification_code}} ਤੁਹਾਡਾ ਤਸਦੀਕ ਕੋਡ ਹੈ.',
    ro: '{{verification_code}} este codul dvs. de verificare.',
    ru: '{{verification_code}} - ваш код проверки.',
    sr: '{{verification_code}} је ваш верификациони код.',
    sk: '{{verification_code}} je váš overovací kód.',
    sl: '{{verification_code}} je vaša koda za preverjanje.',
    es: '{{verification_code}} es su código de verificación.',
    es_AR: '{{verification_code}} es tu código de verificación.',
    es_ES: '{{verification_code}} es su código de verificación.',
    es_MX: '{{verification_code}} es su código de verificación.',
    sw: '{{verification_code}} ni nambari yako ya uthibitisho.',
    sv: '{{verification_code}} är din verifieringskod.',
    ta: '{{verification_code}} är din verifieringskod.',
    te: '{{verification_code}} är din verifieringskod.',
    th: '{{verification_code}} เป็นรหัสการตรวจสอบของคุณ',
    tr: '{{verification_code}} doğrulama kodunuzdur.',
    uk: '{{verification_code}} - ваш код підтвердження.',
    ur: '{{verification_code}} آپ کا توثیق کوڈ ہے۔',
    uz: '{{verification_code}} bu sizning tasdiqlash kodi.',
    vi: '{{verification_code}} là mã xác minh của bạn.',
    zu: '{{verification_code}} yikhodi yakho yokuqinisekisa.',
  },
  security_recommendation: {
    af: '{{verification_code}} is u verifikasiekode. Moenie hierdie kode vir u sekuriteit deel nie.',
    ar: '{{verification_code}} هو رمز التحقق الخاص بك. لأمنك ، لا تشارك هذا الرمز.',
    sq: '{{verification_code}} është kodi juaj i verifikimit. Për sigurinë tuaj, mos e ndani këtë kod.',
    az: '{{verification_code}} doğrulama kodunuzdur. Təhlükəsizliyiniz üçün bu kodu paylaşmayın.',
    bn: '{{verification_code}} আপনার যাচাইকরণ কোড। আপনার সুরক্ষার জন্য, এই কোডটি ভাগ করবেন না।',
    bg: '{{verification_code}} е вашият код за проверка. За вашата сигурност не споделяйте този код.',
    ca: '{{verification_code}} és el vostre codi de verificació. Per a la vostra seguretat, no compartiu aquest codi.',
    zh_CN:
      '{{verification_code}}是您的验证代码。为您的安全性，请勿共享此代码。',
    zh_HK:
      '{{verification_code}}是您的驗證代碼。為您的安全性，請勿共享此代碼。',
    zh_TW:
      '{{verification_code}}是您的驗證代碼。為您的安全性，請勿共享此代碼。',
    hr: '{{verification_code}} je vaš kôd za provjeru. Za svoju sigurnost ne dijelite ovaj kôd.',
    cs: '{{verification_code}} je váš ověřovací kód. Pro vaše zabezpečení tento kód nesdílejte.',
    da: '{{verification_code}} er din verifikationskode. For din sikkerhed skal du ikke dele denne kode.',
    nl: '{{verification_code}} is uw verificatiecode. Deel deze code voor uw beveiliging niet.',
    en: '{{verification_code}} is your verification code. For your security, do not share this code.',
    en_GB:
      '{{verification_code}} is your verification code. For your security, do not share this code.',
    en_US:
      '{{verification_code}} is your verification code. For your security, do not share this code.',
    et: '{{verification_code}} on teie kinnituskood. Teie turvalisuse huvides ärge jagage seda koodi.',
    fil: '{{verification_code}} ay ang iyong verification code. Para sa iyong seguridad, huwag ibahagi ang code na ito.',
    fi: '{{verification_code}} on varmennuskoodisi. Älä jaa tätä koodia turvallisuutta varten.',
    fr: '{{verification_code}} est votre code de vérification. Pour votre sécurité, ne partagez pas ce code.',
    ka: '{{verification_code}} არის თქვენი გადამოწმების კოდი. თქვენი უსაფრთხოებისთვის, არ გაუზიაროთ ეს კოდი.',
    de: '{{verification_code}} ist Ihr Verifizierungscode. Teilen Sie diesen Code für Ihre Sicherheit nicht.',
    el: '{{verification_code}} είναι ο κωδικός επαλήθευσης. Για την ασφάλειά σας, μην μοιράζεστε αυτόν τον κωδικό.',
    gu: '{{verification_code}} તમારો ચકાસણી કોડ છે. તમારી સુરક્ષા માટે, આ કોડ શેર કરશો નહીં.',
    ha: '{{verification_code}} lambar tabbaci. Don amincin ku, kar a raba wannan lambar.',
    he: '{{verification_code}} הוא קוד האימות שלך. לצורך האבטחה שלך, אל תשתף את הקוד הזה.',
    hi: '{{verification_code}} आपका सत्यापन कोड है। अपनी सुरक्षा के लिए, इस कोड को साझा न करें।',
    hu: '{{verification_code}} az Ön ellenőrző kódja. Biztonsága érdekében ne ossza meg ezt a kódot.',
    id: '{{verification_code}} adalah kode verifikasi Anda. Untuk keamanan Anda, jangan bagikan kode ini.',
    ga: 'Is é {{verification_code}} do chód fíoraithe. Maidir le do shlándáil, ná roinn an cód seo.',
    it: '{{verification_code}} è il tuo codice di verifica. Per la tua sicurezza, non condividere questo codice.',
    ja: '{{verification_code}}は確認コードです。あなたのセキュリティのために、このコードを共有しないでください。',
    kn: '{{verification_code}} ನಿಮ್ಮ ಪರಿಶೀಲನಾ ಸಂಕೇತವಾಗಿದೆ. ನಿಮ್ಮ ಸುರಕ್ಷತೆಗಾಗಿ, ಈ ಕೋಡ್ ಅನ್ನು ಹಂಚಿಕೊಳ್ಳಬೇಡಿ.',
    kk: '{{verification_code}} - бұл тексеру коды. Сіздің қауіпсіздігіңіз үшін осы кодты бөліспеңіз.',
    rw_RW:
      '{{verification_code}} ni code yawe yo kugenzura. Kubwumutekano wawe, ntugasangire iyi code.',
    ko: '{{verification_code}}은 확인 코드입니다. 보안을 위해이 코드를 공유하지 마십시오.',
    ky_KG:
      '{{verification_code}} - бул сиздин текшерүү кодуңуз. Сиздин үчүн үлкенек каарданыштык үчүн, бул кодду бөлүшүп бербесеңиз.',
    lo: '{{verification_code}} ແມ່ນລະຫັດຢືນຢັນຂອງທ່ານ. ເພື່ອຄວາມປອດໄພຂອງທ່ານ, ຢ່າແບ່ງປັນລະຫັດນີ້.',
    lv: '{{verification_code}} ir jūsu verifikācijas kods. Jūsu drošībai nesadalieties ar šo kodu.',
    lt: '{{verification_code}} yra jūsų patikrinimo kodas. Savo saugumui nedalykite šio kodo.',
    mk: '{{verification_code}} е вашиот код за верификација. За ваша безбедност, не го споделувајте овој код.',
    ms: '{{verification_code}} ialah kod pengesahan anda. Untuk keselamatan anda, jangan kongsi kod ini.',
    ml: '{{verification_code}} നിങ്ങളുടെ സ്ഥിരീകരണ കോഡാണ്. നിങ്ങളുടെ സുരക്ഷയ്ക്കായി, ഈ കോഡ് പങ്കിടരുത്.',
    mr: '{{verification_code}} आपला सत्यापन कोड आहे. आपल्या सुरक्षिततेसाठी, हा कोड सामायिक करू नका.',
    nb: '{{verification_code}} er din bekreftelseskode. For din sikkerhet, ikke del denne koden.',
    fa: '{{verification_code}} کد تأیید شماست. برای امنیت خود ، این کد را به اشتراک نگذارید.',
    pl: '{{verification_code}} to twój kod weryfikacyjny. Dla swojego bezpieczeństwa nie udostępniaj tego kodu.',
    pt_BR:
      '{{verification_code}} é o seu código de verificação. Para sua segurança, não compartilhe este código.',
    pt_PT:
      '{{verification_code}} é o seu código de verificação. Para sua segurança, não compartilhe este código.',
    pa: '{{verification_code}} ਤੁਹਾਡਾ ਤਸਦੀਕ ਕੋਡ ਹੈ. ਤੁਹਾਡੀ ਸੁਰੱਖਿਆ ਲਈ, ਇਸ ਕੋਡ ਨੂੰ ਸਾਂਝਾ ਨਾ ਕਰੋ.',
    ro: '{{verification_code}} este codul dvs. de verificare. Pentru securitatea dvs., nu partajați acest cod.',
    ru: '{{verification_code}} - ваш код проверки. Для вашей безопасности не делитесь этим кодом.',
    sr: '{{verification_code}} је ваш верификациони код. За вашу сигурност не делите овај код.',
    sk: '{{verification_code}} je váš overovací kód. Pokiaľ ide o svoju bezpečnosť, nezdieľajte tento kód.',
    sl: '{{verification_code}} je vaša koda za preverjanje. Zaradi vaše varnosti ne delite te kode.',
    es: '{{verification_code}} es su código de verificación. Para su seguridad, no comparta este código.',
    es_AR:
      '{{verification_code}} es tu código de verificación. Por tu seguridad, no compartas este código.',
    es_ES:
      '{{verification_code}} es su código de verificación. Para su seguridad, no comparta este código.',
    es_MX:
      '{{verification_code}} es su código de verificación. Para su seguridad, no comparta este código.',
    sw: '{{verification_code}} ni nambari yako ya uthibitisho. Kwa usalama wako, usishiriki nambari hii.',
    sv: '{{verification_code}} är din verifieringskod. För din säkerhet, dela inte den här koden.',
    ta: '{{verification_code}} är din verifieringskod. För din säkerhet, dela inte den här koden.',
    te: '{{verification_code}} är din verifieringskod. För din säkerhet, dela inte den här koden.',
    th: '{{verification_code}} เป็นรหัสการตรวจสอบของคุณ เพื่อความปลอดภัยของคุณอย่าแชร์รหัสนี้',
    tr: '{{verification_code}} doğrulama kodunuzdur. Güvenliğiniz için bu kodu paylaşmayın.',
    uk: '{{verification_code}} - ваш код підтвердження. Для вашої безпеки не діліться цим кодом.',
    ur: '{{verification_code}} آپ کا توثیق کوڈ ہے۔ اپنی سلامتی کے ل this ، اس کوڈ کو شیئر نہ کریں۔',
    uz: '{{verification_code}} bu sizning tasdiqlash kodi. Xavfsizligingiz uchun ushbu kodni ulashmang.',
    vi: '{{verification_code}} là mã xác minh của bạn. Đối với bảo mật của bạn, không chia sẻ mã này.',
    zu: '{{verification_code}} yikhodi yakho yokuqinisekisa. Ngokuphepha kwakho, ungahlanganyeli le khodi.',
  },
  expiration_text: {
    af: 'Hierdie kode verstryk in {{}} minute.',
    ar: 'ينتهي هذا الرمز في {{}}.',
    sq: 'Ky kod skadon në {{}} minutat.',
    az: 'Bu kod {{}} dəqiqələrində başa çatır.',
    bn: 'এই কোডটি {{}} মিনিটে শেষ হয়।',
    bg: 'Този код изтича в {{}} минути.',
    ca: 'Aquest codi caduca en {{}} minuts.',
    zh_CN: '此代码在{{}}分钟内到期。',
    zh_HK: '此代碼在{{}}分鐘內到期。',
    zh_TW: '此代碼在{{}}分鐘內到期。',
    hr: 'Ovaj kôd istječe u {{}} minute.',
    cs: 'Tento kód vyprší v {{}} minuty.',
    da: 'Denne kode udløber i {{}} minutter.',
    nl: 'Deze code verloopt in {{}} minuten.',
    en: 'This code expires in {{}} minutes.',
    en_GB: 'This code expires in {{}} minutes.',
    en_US: 'This code expires in {{}} minutes.',
    et: 'See kood aegub {{}} minutites.',
    fil: 'Ang code na ito ay nag -expire sa {{}} minuto.',
    fi: 'Tämä koodi vanhenee {{}} minuutissa.',
    fr: 'Ce code expire en {{}} minutes.',
    ka: 'ეს კოდი ამოიწურება {{}} წუთებში.',
    de: 'Dieser Code läuft in {{}} Minuten ab.',
    el: 'Αυτός ο κώδικας λήγει σε {{}} λεπτά.',
    gu: 'આ કોડ {{}} મિનિટમાં સમાપ્ત થાય છે.',
    ha: 'Wannan lambar ta ƙare a cikin {{}}annan lambar ta ƙare a cikin {{Lambar} et.',
    he: 'קוד זה יפוג תוך {{}} דקות.',
    hi: 'यह कोड {{}} मिनट में समाप्त होता है।',
    hu: 'Ez a kód {{}} percben jár le.',
    id: 'Kode ini berakhir di {{}} menit.',
    ga: 'Rachaidh an cód seo in éag i {{}} nóiméad.',
    it: 'Questo codice scade in {{}} minuti.',
    ja: 'このコードは{{}}分で期限切れになります。',
    kn: 'ಈ ಕೋಡ್ {{}} ನಿಮಿಷಗಳಲ್ಲಿ ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ.',
    kk: 'Бұл кодта {{}} минуттарында аяқталады.',
    rw_RW: 'Iyi kode irangira muri {{}}}.',
    ko: '이 코드는 {{}} 분으로 만료됩니다.',
    ky_KG: 'Бул код {{}} минуттан кийин четтелет.',
    lo: 'ລະຫັດນີ້ຫມົດອາຍຸໃນ {{}} ນາທີ.',
    lv: 'Šis kods beidzas {{}} minūtēs.',
    lt: 'Šis kodas pasibaigia {{}} minutėmis.',
    mk: 'Овој код истекува во {{}} минути.',
    ms: 'Kod ini tamat dalam {{}} minit.',
    ml: 'ഈ കോഡ് {{}} കോഡ് {{നമ്പറിൽ കാലഹരണപ്പെടുന്നു.',
    mr: 'हा कोड {{}} मिनिटांत कालबाह्य होतो.',
    nb: 'Denne koden utløper i {{}} minutter.',
    fa: 'این کد در دقیقه {{}}ین کد در دقیقه {{شماره} منقضی می شود.',
    pl: 'Ten kod wygasa w minutach {{}}.',
    pt_BR: 'Este código expira em {{}} minutos.',
    pt_PT: 'Este código expira em {{}} minutos.',
    pa: 'ਇਹ ਕੋਡ experied {}} ਮਿੰਟ ਵਿੱਚ ਖਤਮ ਹੋ ਜਾਂਦਾ ਹੈ{{}} ਮਿੰਟ ਵਿੱਚ ਖਤਮ ਹੋ ਜਾਂਦਾ ਹੈ.',
    ro: 'Acest cod expiră în minute {{}}.',
    ru: 'Этот код истекает в {{}} минутах.',
    sr: 'Овај код истиче у {{}} минута.',
    sk: 'Tento kód vyprší v {{}} minútach.',
    sl: 'Ta koda poteče v {{}} minutah.',
    es: 'Este código expira en {{}} minutos.',
    es_AR: 'Este código caduca en {{}} minutos.',
    es_ES: 'Este código expira en {{}} minutos.',
    es_MX: 'Este código expira en {{}} minutos.',
    sw: 'Nambari hii inaisha katika dakika {{}}.',
    sv: 'Denna kod löper ut i {{}} minuter.',
    ta: 'இந்த குறியீடு {{}} நிமிடங்களில் காலாவதியாகிறது.',
    te: 'ఈ కోడ్ {{}} నిమిషాల్లో ముగుస్తుంది.',
    th: 'รหัสนี้จะหมดอายุใน {{}} นาที',
    tr: 'Bu kod {{}} dakikalarında sona erer.',
    uk: 'Цей код закінчується в {{}} протокол.',
    ur: 'یہ کوڈ {{}} منٹ میں ختم ہوتا ہے۔',
    uz: 'Ushbu kod {{}} daqiqada tugaydi.',
    vi: 'Mã này hết hạn trong {{}} phút.',
    zu: 'Le khodi iphelelwa yisikhathi ku {{}} imizuzu.',
  },
  copy_text: {
    af: 'Kopie -kode',
    ar: 'رمز النسخ',
    sq: 'Kopjoj',
    az: 'Kopyalamaq',
    bn: 'কপি কোড',
    bg: 'Копирайте код',
    ca: 'Codi de còpia',
    zh_CN: '复制代码',
    zh_HK: '複製代碼',
    zh_TW: '複製代碼',
    hr: 'Kopiranje',
    cs: 'Kopírovat kód',
    da: 'Kopier kode',
    nl: 'Kopieercode',
    en: 'Copy Code',
    en_GB: 'Copy Code',
    en_US: 'Copy Code',
    et: 'Koopiakood',
    fil: 'Kopyahin ang code',
    fi: 'Kopio',
    fr: 'Copier de code',
    ka: 'დააკოპირეთ კოდი',
    de: 'Code kopieren',
    el: 'Κώδικας αντιγραφής',
    gu: 'નકલ -સંહિતા',
    ha: 'Lambar code',
    he: 'העתק קוד',
    hi: 'प्रतिलिपि कोड',
    hu: 'Másoló kód',
    id: 'Salin kode',
    ga: 'Cóipchód',
    it: 'Codice di copia',
    ja: 'コードをコピーします',
    kn: 'ನಕಲು ಕೋಡ್',
    kk: 'Код көшірмесі',
    rw_RW: 'Kopi',
    ko: '코드 복사',
    ky_KG: 'Кодду көчүр',
    lo: 'ສໍາເນົາ',
    lv: 'Kopēt kods',
    lt: 'Kopijuoti kodą',
    mk: 'Копија код',
    ms: 'Kod salin',
    ml: 'കോപ്പി കോഡ്',
    mr: 'कॉपी कोड',
    nb: 'Kopier kode',
    fa: 'رمز',
    pl: 'KOD KOPIOWY',
    pt_BR: 'Código de cópia',
    pt_PT: 'Código de cópia',
    pa: 'ਕਾਪੀ ਕੋਡ',
    ro: 'Copiați cod',
    ru: 'Копировать код',
    sr: 'Копирање копија',
    sk: 'Kópia',
    sl: 'Kodiraj kopiranja',
    es: 'Copiar código',
    es_AR: 'Copiar Código',
    es_ES: 'Copiar código',
    es_MX: 'Copiar código',
    sw: 'Nakala ya Nakala',
    sv: 'Kopieringskod',
    ta: 'குறியீட்டை நகலெடுக்கவும்',
    te: 'కాపీ కోడ్',
    th: 'รหัสคัดลอก',
    tr: 'Kopya Kodu',
    uk: 'Копіювати код',
    ur: 'کوڈ کاپی کریں',
    uz: 'Kodni nusxalash',
    vi: 'Sao chép mã',
    zu: 'Kopisha ikhodi',
  },
};

export type TAuthLanguages = keyof typeof authTranslationData.code_text;

export type IAuthTemplateTranslationCategory = keyof typeof authTranslationData;

type TAuthTranslationData = Record<IAuthTemplateTranslationCategory, Record<TAuthLanguages, string>>;

export const getAuthTranslationData = (

  dataCategory: IAuthTemplateTranslationCategory = 'expiration_text',
  languageCode: TAuthLanguages = 'en_US',

  replace: boolean = false,
  replacementText?: string
) => {
  const dataStr = (authTranslationData as TAuthTranslationData)[dataCategory][languageCode];
  if (!replace) {
    return dataStr;
  }
  const startIdx = dataStr.indexOf('{{');
  const endIdx = dataStr.indexOf('}}');
  let result = '';
  if (startIdx !== -1 && endIdx !== -1 && startIdx < endIdx) {
    result =
      dataStr.slice(0, startIdx) + replacementText + dataStr.slice(endIdx + 2);
  }
  return result;
};
