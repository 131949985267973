import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BlockContainer from './BlockContainer';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnSelectAttribute,
  updateOnChangeText,
} from '../../../utilities/utils';
import liveChatBubbleOvalLeftEllipsisIcon from '../../pages/builder/assets/images/icons/livechat.svg';
import ButtonFragment from '../elements/ButtonFragment';
import TextCharInfo from '../elements/TextCharInfo';
import { Textarea } from 'evergreen-ui';

const LiveChatBlock = ({
  id,
  text,
  setText,
  timeout,
  handleTimeout,
  save,
  button,
  saveBlock,
  saveLoader,
  updateButtonsBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  hasWriteAccess,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(320);

  return (
    <BlockContainer
      id={id}
      title='Live Chat'
      subtitle='Use this block to hand over the conversation directly to an agent through Facebook Handover Protocol.'
      save={save}
      isLoading={saveLoader}
      icon={liveChatBubbleOvalLeftEllipsisIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='flex flex-col'>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            (text + value).length + 2 <= maxCount &&
              updateOnSelectAttribute(
                value,
                showAttribute,
                showAPI,
                setShowAttribute,
                setShowAPI,
                setText,
                text,
                apiList
              );
          }}
        >
          <Textarea
            required={true}
            rows={3}
            value={text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm cursor-text'
            placeholder='An agent would be joining you in a moment. Click the button below to continue conversation with the chatbot.'
          />
        </AttributeAPIContainer>
        <div>
          <TextCharInfo data={text} count={maxCount} />
        </div>
        <ButtonFragment
          key={1}
          id={button.id}
          hasMessengerExtension={false}
          index={1}
          sequences={sequences}
          blockId={id}
          title={button.title}
          type={button.type}
          value={button.value}
          singleElem={true}
          handleUpdate={(data, type) => updateButtonsBlock(id, data, type)}
          allowedTypes={['Sequence']}
          hasWriteAccess={hasWriteAccess}
        />
        <br />
        <div className='flex flex-col w-full'>
          <label className='block text-sm font-medium text-gray-700'>
            Resume bot automatically after (in hours)
          </label>
          <div className='mt-1'>
            <input
              type='number'
              value={timeout}
              disabled={!hasWriteAccess}
              onChange={(e) => handleTimeout(e.target.value)}
              placeholder='6'
              className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
            />
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};

LiveChatBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  timeout: PropTypes.number.isRequired,
  handleTimeout: PropTypes.func.isRequired,
  button: PropTypes.object,
  setText: PropTypes.func.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  copyBlock: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool,
};

LiveChatBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
  hasWriteAccess: false,
};

export default LiveChatBlock;
