import React from 'react';
import GroupsCRUD from '../component/team-group/GroupsCRUD';
import GroupsList from '../component/team-group/GroupsList';

const TeamGroupSettings: React.FC<{}> = () => {
  const [currentGroup, setGroup] =
    React.useState<GroupDetailsCardInterface | null>(null);

  return (
    <div className='my-7'>
      {!currentGroup && <GroupsList setGroup={setGroup} />}
      {currentGroup && (
        <GroupsCRUD currentGroup={currentGroup} setGroup={setGroup} />
      )}
    </div>
  );
};

export default TeamGroupSettings;
