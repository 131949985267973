import React, { Component } from 'react';

class WebchatSupport extends Component {
  componentDidMount() {
    const config = {
      selector: '#icWebChat',
      platformId: process.env.REACT_APP_WEBCHAT_SUPPORT_PLATFORMID,
      primaryId: process.env.REACT_APP_WEBCHAT_SUPPORT_PRIMARY_ID,
      token: process.env.REACT_APP_WEBCHAT_SUPPORT_TOKEN,
      opened: this.props.opened,
      onCloseCallback: () => this.props.onCloseCallback(),
    };
    (function () {
      var div = document.createElement('div');
      div.id = 'icWebChat';
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'icWebChatScript';
      script.async = true;
      script.src = process.env.REACT_APP_WEBCHAT_SUPPORT_URL;
      var lel = document.body.getElementsByTagName('script');
      var el = lel[lel.length - 1];
      el.parentNode.insertBefore(script, el);
      el.parentNode.insertBefore(div, el);
      script.addEventListener('load', function () {
        window.ICWebChat.init(config);
      });
    })();
  }
  render() {
    return <></>;
  }
}

export default WebchatSupport;
