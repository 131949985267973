import { useState } from 'react';
import { connect } from 'react-redux';
import { Link as LinkLib } from '../../library';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Switch } from '@headlessui/react';
import { classNames } from '../../utilities/utils';
import { myaliceSupportLink } from '../../utilities/content';
import { mixpanelTrack } from 'utilities/mixpanel';
import { env } from 'utilities/config';
import useTranslation from 'components/customHooks/useTranslation';
import { PlatformInterface, SelectedTeamInterface } from 'index';
import usePartner from 'components/customHooks/usePartner';

type PropsTypes = {
  userId?: number;
  userName?: string;
  userEmail?: string;
  userStatus?: string;
  trialAvailed: boolean;
  trialRemaining: number;
  selectedTeam: SelectedTeamInterface;
  toggleUserStatus: (status: string) => void;
  myaliceSupportLink?: string;
  logoutAPI: () => void;
  handleReasonModalOpen?: (val: boolean) => void;
};

const UserMenu: React.FC<PropsTypes> = ({
  userId,
  userName,
  userEmail,
  userStatus,
  trialAvailed,
  trialRemaining,
  selectedTeam,
  toggleUserStatus,
  logoutAPI,
  handleReasonModalOpen = (val: boolean) => {},
}) => {
  const { isPartnerRestricted } = usePartner();
  const shouldDisableSupport = isPartnerRestricted('UserMenu/node/Support');
  const { t } = useTranslation();
  const greenColor = '#038E4C';
  const whiteColor = '#E5E7EB';
  const redColor = '#EF4444';
  const [showLoading, setShowLoading] = useState(false);

  const renderTrialComponent = () => {
    return (
      <div className='grid grid-cols-10 gap-2'>
        <div className='col-span-6 mt-2 text-xs text-gray-600'>
          {t(`You have {{${trialRemaining}}} days left of your free trial.`)}
          <div>
            <LinkLib textSize='xs' url='/settings/billing'>
              {t('See Plans')}
            </LinkLib>
          </div>
        </div>
        <div className='w-full col-span-4 p-2'>
          <CircularProgressbar
            maxValue={14}
            value={trialRemaining}
            text={`${trialRemaining}`}
            strokeWidth={10}
            styles={buildStyles({
              textColor: trialRemaining > 4 ? greenColor : redColor,
              pathColor: trialRemaining > 4 ? greenColor : redColor,
              trailColor: whiteColor,
            })}
          />
        </div>
      </div>
    );
  };

  const renderUserStatus = () => {
    return (
      <Switch.Group as='div' className='flex items-start justify-between'>
        <span className='flex flex-col flex-grow'>
          <Switch.Label
            as='span'
            className='text-sm font-medium text-gray-900'
            passive
          >
            {t('Status')}
          </Switch.Label>
          <Switch.Description as='span' className='text-sm text-gray-500'>
            {t(userStatus === 'online' ? 'Active' : 'Away' || 'Not Available')}
          </Switch.Description>
        </span>
        <Switch
          checked={userStatus === 'online'}
          onChange={() => {
            userStatus === 'online' &&
            selectedTeam.id === (env === 'production' ? 943 : 262)
              ? handleReasonModalOpen(true)
              : toggleUserStatus(userStatus === 'online' ? 'away' : 'online');
          }}
          className={classNames(
            userStatus === 'online' ? 'bg-primary' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
          )}
        >
          <span
            aria-hidden='true'
            className={classNames(
              userStatus === 'online'
                ? 'ltr:translate-x-4 rtl:-translate-x-4'
                : 'ltr:translate-x-0 rtl:translate-x-0',
              'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </Switch.Group>
    );
  };
  return (
    <div>
      {!!userEmail && (
        <div className='px-4 py-3 border-b cursor-pointer'>
          {!!userName && (
            <p className='text-sm font-medium text-gray-900 truncate'>
              {userName}
            </p>
          )}
          <p className='text-xs text-gray-500 truncate'>
            {userEmail || 'Unknown Email'}
          </p>
        </div>
      )}
      {trialAvailed &&
        selectedTeam?.subscription_plan?.plan_type === 'free' && (
          <div className='px-4 py-3 border-b cursor-pointer'>
            {renderTrialComponent()}
          </div>
        )}
      <div className='px-4 py-3 border-b cursor-pointer'>
        {renderUserStatus()}
      </div>
      <a
        className={`${shouldDisableSupport ? 'hidden' : 'visible'}`}
        target='_blank'
        href={myaliceSupportLink}
        onClick={() => mixpanelTrack('Clicked on Help & Support')}
        rel='noreferrer noopener'
        id='Support'
      >
        <div className='px-4 py-3 border-b cursor-pointer'>
          {t('Help & Support')}
        </div>
      </a>
      <div
        className='flex justify-between px-4 py-3 cursor-pointer'
        onClick={async () => {
          setShowLoading(true);
          await logoutAPI();
        }}
      >
        <span>{t('Logout')}</span>
        {showLoading && (
          <svg
            className='animate-spin -ml-1 mr-3 h-5 w-5 text-primary'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        )}
      </div>
    </div>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  userId: state.auth.id,
  userName: state.auth.full_name,
  userEmail: state.auth.email,
  userAvatar: state.auth.avatar,
  userStatus: state.auth.status,
  selectedTeam: state.dashboard.selectedProject,
  teams: state.dashboard.projects,
  platformList: state.dashboard.platformList,
  permissionList: state.dashboard.permission.list || [],
  projectProgressInfo: state.dashboard.projectProgressInfo,
  trialAvailed: state.auth.trial_availed,
  trialRemaining: state.auth.trial_remaining,
});

const mapDispatch = (dispatch: any) => ({
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  updateSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.settings.setSelectedProject(project),
  fetchPlatformList: (teamId: number) =>
    dispatch.dashboard.fetchPlatformList(teamId),
  setSelectedPlatform: (platform: PlatformInterface) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  logoutAPI: () => dispatch.auth.logoutAPI(),
  toggleUserStatus: (status: string) => dispatch.auth.updateStatus(status),
  fetchDashboardCardData: (teamId: number) =>
    dispatch.dashboard.fetchDashboardCardData(teamId),
});

export default connect(mapState, mapDispatch)(UserMenu);
