import React from 'react';
import EmptypageIcon from '../../../../../assets/icons/emptyPageIcon.svg';

interface EmptyPageProps {
  title?: string;
  subtitle?: string;
  iconClass?: string;
  titleClass?: string;
  subTitleClass?: string;
  children: React.ReactNode;
}

const EmptyPage: React.FC<EmptyPageProps> = ({
  children,
  title = '',
  subtitle = '',
  iconClass = '',
  titleClass = '',
  subTitleClass = '',
}) => {
  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <img src={EmptypageIcon} alt='EMPTY_PAGE' className={`${iconClass}`} />
      <h3 className={`mt-[18px] ${titleClass}`}>{title}</h3>
      <span
        className={`subtitle-medium mt-1 mb-4 text-center w-[400px] mx-auto ${subTitleClass}`}
      >
        {subtitle}
      </span>
      {children}
    </div>
  );
};

export default EmptyPage;
