import React from 'react';
import plusCircleIcon from './../../../../assets/icon/marketplace/plusCircleIcon.svg';
import checkCircleIcon from './../../../../assets/icon/marketplace/checkCircleIcon.svg';
import emptyStateIcon from './../../../../assets/images/emptyStateProductInteraction.svg';
import { ProductDataProps } from '../interface';

interface Prop {
  product: ProductDataProps;
  handleProductSelect: (product: ProductDataProps) => void;
  currencySymbol: string;
}

const ProductItem: React.FC<Prop> = ({
  product,
  handleProductSelect,
  currencySymbol,
}) => {
  return (
    <li
      onClick={() => {
        handleProductSelect(product);
      }}
    >
      <div className='block bg-white cursor-pointer hover:bg-gray-50'>
        <div className='grid grid-cols-8 gap-4 px-1 py-4'>
          <div className='flex items-center flex-1 min-w-0 col-span-7 sm:col-span-6'>
            <div className='p-1.5 m-1.5'>
              {!product.is_selected &&
                <img src={plusCircleIcon} className='w-8 h-8' alt=''/>
              }
              {product.is_selected &&
                <img src={checkCircleIcon} className='w-8 h-8' alt=''/>
              }
            </div>
            <div className='flex-shrink-0'>
              <img
                className='w-12 h-12 rounded-md px-0.5'
                src={
                  product.product_images.length ? product.product_images[0] : emptyStateIcon
                }
                alt=''
              />
            </div>
            <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-6 md:gap-4'>
              <div className='md:col-span-5'>
                <p className='text-sm font-medium truncate text-primary'>
                  {product.product_name}
                </p>
                {product.in_stock ? (
                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                    {`In Stock: ${product.stock_count === 0 ? 'Infinite' : product.stock_count}`}
                  </p>
                ) : (
                  <p className='flex items-center mt-2 text-sm text-red-600'>
                    Out Of Stock
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className='flex items-center col-span-1 sm:col-span-2'>
            <div className='hidden mr-0 md:block md:mr-4'>
              <div>
                <p className='text-sm font-medium text-gray-900'>
                  {currencySymbol}
                  {product.unit_price}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
