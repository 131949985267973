import React from 'react';
import { SearchInput } from 'evergreen-ui';

interface Props {
  handleFilter: (value: string) => void;
  value: string;
}

const APIFuseSearchComponent: React.FC<Props> = ({ handleFilter, value }) => {
  return (
    <div className='sequence-card__search'>
      <SearchInput
        className='alice-rd-search'
        placeholder='Search API...'
        width='100%'
        onChange={(value: string) => handleFilter(value)}
        value={value}
      />
    </div>
  );
};

export default APIFuseSearchComponent;
