import React, { useState } from 'react';
import { Alert } from '../../../library/index';
import { SideSheet, Pane, Heading, Paragraph, Button } from 'evergreen-ui';
import { connect } from 'react-redux';
import noRulesSmiley from './assets/images/noRulesSmiley.svg';
import './assets/styles/styles.scss';
import RuleCard from './component/RuleCard';

interface Prop {
  rules: RuleProps[];
  hasWriteAccess: boolean;
  selectedSequence: any;
  createBlockRule: (sequenceId: any, channelType: string) => void;
  deleteBlockRules: (sequenceId: any, rulesId: any) => void;
  selectedPlatform: any;
  fetchRulesPermissionData: (source: string) => void;
  rulesPermissionData: any;
  updateBlockRules: (sequenceId: any, rulesId: any) => void;
  updateBlockRulesChange: (rulesId: number, key: string, value: any) => void;
  updateBlockRulesEditId: (ruleId: number) => void;
  fetchBlockRules: (sequenceId: any) => void;
  editId: number;
  intentList: string[];
  generateRulesQrCode: (rulesId: any) => void;
  initiateChildBlockRule: (rulesId: any) => void;
}

interface RuleProps {
  id: number;
  trigger: string;
  action: string;
  payload: string;
  qr_code: string;
  sequence: SequenceProps;
  save: boolean;
}

interface SequenceProps {
  id: number;
  title: string;
}

const Rules: React.FC<Prop> = ({
  rules,
  hasWriteAccess,
  selectedSequence,
  createBlockRule,
  deleteBlockRules,
  selectedPlatform,
  fetchRulesPermissionData,
  rulesPermissionData,
  updateBlockRulesChange,
  updateBlockRulesEditId,
  fetchBlockRules,
  updateBlockRules,
  editId,
  intentList,
  generateRulesQrCode,
  initiateChildBlockRule,
}) => {
  const [isShown, setIsShown] = useState(false);

  React.useEffect(() => {
    if (!!selectedPlatform) {
      if (selectedPlatform.platform_type) {
        fetchRulesPermissionData(selectedPlatform.platform_type);
      } else {
        fetchRulesPermissionData(selectedPlatform.type);
      }
    }
  }, [selectedPlatform, fetchRulesPermissionData]);

  React.useEffect(() => {
    updateBlockRulesEditId(-1);
    // eslint-disable-next-line
  }, [isShown]);

  return (
    <div>
      <Alert
        intent='success'
        title='Connect rules to trigger this sequence'
        className='mb-6'
      >
        <span className='inline-flex text-xs font-medium bold'>
          {!!rules ? rules.length : 'n/a'}
        </span>
        &nbsp;{rules?.length > 1 ? 'rules' : 'rule'} connected,&nbsp;
        <span
          onClick={() => setIsShown(true)}
          className='text-sm font-medium underline cursor-pointer text-link'
        >
          click here
        </span>
        &nbsp;to add new rules or modify current ones.
      </Alert>

      <SideSheet
        isShown={isShown}
        width={'60vw'}
        onCloseComplete={() => setIsShown(false)}
        preventBodyScrolling
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
      >
        <Pane
          zIndex={1}
          flexShrink={0}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          elevation={0}
          backgroundColor='white'
        >
          <Pane padding={16}>
            <Heading size={600}>User Rules</Heading>
            <Paragraph size={400}>
              Rules that are connected to current sequence
            </Paragraph>
          </Pane>

          {hasWriteAccess && rules.length > 0 && (
            <Button
              className='alice-btn__secondary'
              iconBefore={'add'}
              disabled={
                !!rules && rules.filter((rule) => rule.id === -1).length > 0
              }
              marginRight={20}
              onClick={() => {
                createBlockRule(
                  selectedSequence,
                  selectedPlatform.platform_type
                );
              }}
            >
              New Rule
            </Button>
          )}
        </Pane>
        <Pane flex='1' overflowY='scroll' background='tint1' padding={16}>
          {!!rules &&
            rules.map((rule: any, index: number) => (
              <RuleCard
                key={index}
                updateBlockRulesEditId={updateBlockRulesEditId}
                intentList={intentList}
                selectedPlatform={selectedPlatform}
                generateQrCode={generateRulesQrCode}
                fetchBlockRules={() => {
                  fetchBlockRules(selectedSequence);
                }}
                updateBlockRulesChange={updateBlockRulesChange}
                rulesPermissionData={rulesPermissionData}
                initiateChildBlockRule={initiateChildBlockRule}
                rule={rule}
                deleteBlockRules={(rulesId: any) =>
                  deleteBlockRules(selectedSequence, rulesId)
                }
                hasWriteAccess={hasWriteAccess}
                updateBlockRules={() => {
                  updateBlockRules(selectedSequence, rule.id);
                }}
                editId={editId}
              />
            ))}

          {!rules ||
            (!!rules && rules.length === 0 && (
              <div className='content-center flex-column h-100p'>
                <Pane className='rules-no-data__container' marginBottom={20}>
                  <img src={noRulesSmiley} alt='smiley' width={60} />
                  <p className='rules-no-data__title'>No rules created yet!</p>
                  <br />
                  <Button
                    className='alice-btn__secondary'
                    iconBefore={'add'}
                    disabled={
                      !!rules &&
                      rules.filter((rule) => rule.id === -1).length > 0
                    }
                    onClick={() => {
                      createBlockRule(
                        selectedSequence,
                        selectedPlatform.platform_type
                      );
                    }}
                  >
                    New Rule
                  </Button>
                </Pane>
              </div>
            ))}
        </Pane>
      </SideSheet>
    </div>
  );
};

const mapState = (state: any) => ({
  rules: state.rules.rulesData,
  selectedSequence: state.builder.selectedSequence,
  rulesPermissionData: state.rules.rulesPermissionData,
  editId: state.rules.blockRulesEditId,
  intentList: state.dashboard.intentList,
});

const mapDispatch = (dispatch: any) => ({
  fetchRulesPermissionData: (source: string) =>
    dispatch.rules.fetchRulesPermissionData(source),
  fetchBlockRules: (sequenceId: any) =>
    dispatch.rules.fetchBlockRules(sequenceId),
  createBlockRule: (selectedSequence: any, channelType: string) =>
    dispatch.rules.createBlockRule({
      selectedSequence,
      channelType,
    }),
  deleteBlockRules: (sequenceId: any, rulesId: any) =>
    dispatch.rules.deleteBlockRules({ sequenceId, rulesId }),
  updateBlockRules: (sequenceId: any, rulesId: any) =>
    dispatch.rules.updateBlockRules({ sequenceId, rulesId }),
  updateBlockRulesEditId: (ruleId: number) =>
    dispatch.rules.updateBlockRulesEditId(ruleId),
  generateRulesQrCode: (rulesId: any) =>
    dispatch.rules.generateRulesQrCode(rulesId),
  initiateChildBlockRule: (rulesId: any) =>
    dispatch.rules.initiateChildBlockRule(rulesId),
  updateBlockRulesChange: (rulesId: number, key: string, value: any) =>
    dispatch.rules.updateBlockRulesChange({
      rulesId,
      key,
      value,
    }),
});

const RuleCardContainer = connect(mapState, mapDispatch)(Rules);

export default RuleCardContainer;
