import { IEcommerceChannelProperty } from 'pages/integration/interface';
import React from 'react';
import { getAllMarketPlaceData } from 'utilities/utils';
import { Card, CardContent, CardTitle } from '../../../../../libraryV2/ui/card';
import EcommerceCardActionDropdown from './EcommerceCardActionDropdown';
import { EcommerceData } from 'pages/integration/utils/content';

interface Props {
  ecommerceData: IEcommerceChannelProperty;
  onActionClick: (action: string) => void;
}

const EcommerceCard: React.FC<Props> = ({ ecommerceData, onActionClick }) => {
  return (
    <>
      <Card className='w-full py-3 relative rounded shadow-none drop-shadow-sm flex flex-row items-center'>
        <CardContent className='px-4 py-0 overflow-hidden'>
          <div className='flex items-center gap-3'>
            <img
              src={
                getAllMarketPlaceData.find(
                  (platform) =>
                    platform.platform_type === ecommerceData?.ecommerce_type
                )?.icon
              }
              className='w-9 h-9'
              alt={'icon_box'}
            />

            <div className='flex flex-col gap-1'>
              <CardTitle className='text-base font-medium'>
                <p>
                  {!!ecommerceData?.store_name
                    ? ecommerceData?.store_name
                    : EcommerceData(ecommerceData?.ecommerce_type).title}
                </p>
                <div className='overflow-hidden text-blue-500 text-sm font-normal'>
                  <a
                    rel='noreferrer'
                    href={ecommerceData?.store_url}
                    target='_blank'
                    className='text-blue-500 text-sm truncate w-full'
                  >
                    {ecommerceData?.store_url.length > 40
                      ? ecommerceData?.store_url.slice(0, 40) + '...'
                      : ecommerceData?.store_url}
                  </a>
                </div>
              </CardTitle>
            </div>
          </div>

          <EcommerceCardActionDropdown
            elementData={ecommerceData}
            onActionClick={onActionClick}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default EcommerceCard;
