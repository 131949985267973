interface Props {
  className?: string;
  color?: string;
}
export const PhoneIcon: React.FC<Props> = ({
  className = 'w-5 h-5',
  color = '#9CA3AF',
}) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.600098 1.40001C0.600098 0.958178 0.95827 0.600006 1.4001 0.600006H3.1224C3.51347 0.600006 3.84722 0.882737 3.91151 1.26849L4.50296 4.81719C4.5607 5.16361 4.38574 5.50718 4.07162 5.66425L2.83311 6.2835C3.72614 8.50266 5.49744 10.274 7.7166 11.167L8.33586 9.92849C8.49292 9.61436 8.83649 9.43941 9.18292 9.49714L12.7316 10.0886C13.1174 10.1529 13.4001 10.4866 13.4001 10.8777V12.6C13.4001 13.0418 13.0419 13.4 12.6001 13.4H11.0001C5.25634 13.4 0.600098 8.74377 0.600098 3.00001V1.40001Z'
        fill='#9CA3AF'
      />
    </svg>
  );
};
