import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  width?: string;
  height?: string;
  isOpen: boolean;
  setIsOpen: (payload: boolean) => void;
  handleTryAgain: () => void;
  isOrderPlaceLoader: boolean;
}

const FailureModal: React.FC<Props> = ({
  width = 'w-20',
  height = 'h-auto',
  isOpen,
  setIsOpen,
  handleTryAgain,
  isOrderPlaceLoader,
}) => {
  const { t } = useTranslation();
  const renderLoadingUi = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          className={`border-r-transparent border-t-green-500
          border-b-green-500 border-l-green-500 animate-spin inline-block h-4
          w-4 border-2 rounded-full`}
        >
          <span className='visually-hidden sr-only'>Loading...</span>
        </div>
      </div>
    );
  };

  const renderModalBodyView = () => {
    return (
      <div className='flex flex-col w-full justify-center items-center p-5'>
        <ExclamationTriangleIcon className='w-8 h-8 p-1 text-red-500 bg-red-100 m-3 rounded-full' />
        <p className='text-gray-900 font-normal text-base'>
          {t('Something went wrong')}
        </p>
        <p className='text-gray-400 mb-3 text-center'>
          We were unable to create the order for the customer. Please try again.
          If the issue persists,
          <a
            href='https://www.myalice.ai/support'
            target='_black'
            rel='noreferrer'
          >
            <span className='text-blue-500'>contact support</span>
          </a>
        </p>
        <button
          className='p-2 bg-white border border-gray-200 rounded-md
                w-full text-gray-600 font-normal mb-2'
          onClick={() => {
            if (!isOrderPlaceLoader) {
              handleTryAgain();
            }
          }}
        >
          {isOrderPlaceLoader ? renderLoadingUi() : 'Try Again'}
        </button>
        <button
          className='p-2 bg-white border border-gray-200 rounded-md
                w-full text-gray-600 font-normal'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Back
        </button>
      </div>
    );
  };
  const renderModalView = () => {
    return (
      <div
        className='fixed z-10 inset-0 overflow-y-auto'
        aria-labelledby='modal-title'
        role='dialog'
        aria-modal='true'
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
            aria-hidden='true'
          />
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          ></span>
          <div
            className={`relative inline-block align-bottom bg-white text-left
          shadow-xl transform transition-all sm:my-8 sm:align-middle ${width} rounded-md ${height}`}
          >
            {renderModalBodyView()}
          </div>
        </div>
      </div>
    );
  };
  return <>{isOpen && <>{renderModalView()}</>}</>;
};

export default FailureModal;
