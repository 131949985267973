import React from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
// import Modal from './content/Modal';
import NewAlertModal from 'library/modal/NewAlertModal';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  totalAudience: number;
  sendNow: () => void;
  isBroadcasting: boolean;
}

const SendNow: React.FC<Props> = ({
  sendNow,
  totalAudience,
  isBroadcasting,
}) => {
  const [openSendNowModal, setOpenSendNowModal] = React.useState(false);
  const { t } = useTranslation();
  return (
    <div className='w-full max-w-sm relative'>
      <>
        <div
          className='group inline-flex items-center ltr:rounded-r-md rtl:rounded-l-md bg-[#04B25F] px-3 py-2 text-sm text-white h-9'
          onClick={() => setOpenSendNowModal(true)}
        >
          <ChevronUpIcon className='w-5 h-5 text-white ' />
        </div>

        <NewAlertModal
          isShown={openSendNowModal}
          intent={'send-now'}
          title={'Send broadcast now?'}
          description={`<p>${t(
            "You're about to send a broadcast to"
          )} ${totalAudience} ${t('contacts')}.</p> <span>${t(
            'Are you sure you want to continue?'
          )}</span>`}
          onConfirm={() => {
            sendNow();
          }}
          onCancelComplete={() => setOpenSendNowModal(false)}
        />
      </>
    </div>
  );
};

export default SendNow;
