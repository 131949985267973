import React from 'react';
import emptyMagnifyingGlassIcon from '../../assets/icons/emptySearchIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  searchString?: string;
}

const NotFoundView: React.FC<Props> = ({ searchString }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='flex flex-col content-center justify-center w-auto h-[185px]'>
        <img src={emptyMagnifyingGlassIcon} alt='empty' />
        <div className='mt-4 text-sm font-normal text-center text-gray-500 w-60'>
          {t('Nothing found related to')}
        </div>
        <div className='text-sm font-medium text-center text-gray-800 break-words w-60'>
          "{searchString}"
        </div>
      </div>
    </div>
  );
};
export default NotFoundView;
