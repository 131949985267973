import Button from 'library/button';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Combobox } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import { IVariation } from 'pages/inbox/inboxInterface';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CheckIcon,
  MinusIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import ChevronUpDown from 'pages/inbox/assets/iconComponent/ChevronUpDownIcon';
import useDebounce from 'components/customHooks/useDebounce';

interface Props {
  index: number;
  quantity: number;
  totalItem?: number; // total number of multiple variant added
  variantString?: string;
  ecommerce_type: string;
  hasVariations: boolean;
  selectedVariant?: string;
  variations: IVariation[];
  addAnotherVariant?: () => void;
  removeVariant?: (index: number) => void;
  stockCount: (variantId: string) => number;
  updateQuantity: (quantity: number) => void;
  updateQuantityByAmount: (quantity: number) => void;
  onVariationSelect: (varant: IVariation, index: number) => Promise<void>;
}

const VariationView: React.FC<Props> = ({
  updateQuantityByAmount,
  selectedVariant = '',
  variantString = '',
  onVariationSelect,
  ecommerce_type,
  updateQuantity,
  totalItem = 1,
  hasVariations,
  variations,
  stockCount,
  quantity,
  index,
  removeVariant = () => {},
  addAnotherVariant = () => {},
}) => {
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [inputValue, setInputValue] = useState<number | string>('');
  const [loading, setLoading] = useState(false);
  const [variationLoading, setVariationLoading] = useState(false);

  const { t } = useTranslation();

  const inputElement = useRef<HTMLButtonElement>(null);
  const quantityInputRef = useRef(null);

  const productQunatityDebounce = useDebounce(inputValue, 1000);

  useEffect(() => {
    setInputValue(quantity);
    if (loading) setLoading(false);
    //eslint-disable-next-line
  }, [quantity]);

  useEffect(() => {
    if (inputValue !== '') updateQuantityByValue();
    //eslint-disable-next-line
  }, [productQunatityDebounce]);

  const canQuantityIncrease = () => {
    if (hasVariations && selectedVariant === '') return false;
    const stock = stockCount(hasVariations ? selectedVariant || '-1' : '-1');
    return stock > quantity;
  };
  // tooltip message for plus button
  const getIncreaseButtonState = () => {
    if (hasVariations && selectedVariant === '') {
      return 'Please select a variant first';
    } else if (!canQuantityIncrease()) {
      return 'Maximum Quantity Added';
    } else return '';
  };

  const handleQuantityChange = (e: any) => {
    if (e.target.value === '') setInputValue('');
    else {
      const value = Number(e.target.value);
      const stock = stockCount(hasVariations ? selectedVariant || '-1' : '-1');
      if (!isNaN(value) && stock >= value) {
        setInputValue(value);
      }
    }
  };

  const updateQuantityByValue = () => {
    const amount = inputValue;
    if (!isNaN(Number(amount)) && inputValue !== '') {
      setLoading(true);
      updateQuantityByAmount(Number(amount));
    } else {
      setInputValue(quantity);
    }
  };

  //variant filter / search
  const filteredVariation = () => {
    return query === ''
      ? variations
      : variations.filter((variation: IVariation) =>
          variation?.attribute_string
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );
  };

  const getSelectedVariantData = (variantId: string) => {
    const list = variations.filter((data) => data.variant_id === variantId);
    return list.length > 0 ? list[0] : null;
  };

  // variant list
  const getVariantList = () => {
    if (filteredVariation().length < 1) {
      return (
        <Combobox.Option className='flex justify-center' value={null} disabled>
          <span className='text-center text-xs text-gray-900 my-2'>
            No Variant Found
          </span>
        </Combobox.Option>
      );
    } else
      return filteredVariation().map((variation: IVariation, index: number) => (
        <Combobox.Option
          key={variation?.variant_id}
          value={variation}
          disabled={!variation?.in_stock || variation?.stock_count <= 0}
        >
          <div
            className={classNames(
              'flex items-center px-3 border-gray-200 py-1',
              variation?.in_stock && variation?.stock_count > 0
                ? 'cursor-pointer'
                : 'cursor-not-allowed',
              filteredVariation()?.length - 1 === index
                ? 'border-0'
                : 'border-b'
            )}
          >
            <div>
              <span
                className={classNames(
                  'text-xs text-gray-900 mb-2',
                  selectedVariant !== variation?.variant_id
                    ? 'font-normal'
                    : 'font-semibold'
                )}
              >
                {variation?.attribute_string || ''}
              </span>
              <span
                className={`text-xs ltr:text-left rtl:text-right block ${
                  selectedVariant !== variation?.variant_id
                    ? 'font-normal'
                    : 'font-semibold'
                }  ${
                  variation?.in_stock && variation?.stock_count > 0
                    ? 'text-gray-900'
                    : 'text-red-500'
                }`}
              >
                {variation?.in_stock && variation?.stock_count > 0
                  ? `Stock: ${variation?.stock_count}`
                  : 'Not In Stock'}
              </span>
            </div>

            <CheckIcon
              className={classNames(
                'w-5 h-5 ltr:ml-auto rtl:mr-auto',
                selectedVariant === variation?.variant_id ? 'block' : 'hidden'
              )}
            />
          </div>
        </Combobox.Option>
      ));
  };

  const handleVariantSelection = async (variant: IVariation | null) => {
    if (!!variant) {
      setVariationLoading(true);
      await onVariationSelect(variant, index);
      setVariationLoading(false);
    }
    setQuery('');
  };

  const renderVariantBox = () => {
    return (
      <div className='relative ltr:ml-6 rtl:mr-6 w-full'>
        {!hasVariations && (
          <div className=' w-full rounded-md border text-xs flex items-center border-[#D1D5DB] bg-gray-200 text-gray-500 p-2'>
            No Variant
            <ChevronUpDown className='ltr:ml-auto rtl:mr-auto h-3' />
          </div>
        )}
        {hasVariations && (
          <Combobox
            value={getSelectedVariantData(selectedVariant || '-1')}
            onChange={(variant) => handleVariantSelection(variant)}
          >
            <div
              className={classNames(
                'relative  w-full cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-400 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm',
                !!variationLoading ? 'animate-pulse bg-gray-100' : 'bg-white'
              )}
            >
              <Combobox.Button
                className={`w-full  rounded-lg pl-1 flex items-center ltr:pr-10 rtl:pr-3  cursor-pointer border  ${
                  searchFocus ? 'border-green-400' : 'border-[#D1D5DB]'
                }${
                  !!variationLoading
                    ? ' bg-gray-100 cursor-not-allowed'
                    : 'bg-white'
                } `}
              >
                {searchFocus && (
                  <MagnifyingGlassIcon
                    className='ltr:ml-2 w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                )}
                <Combobox.Input
                  as='input'
                  autoComplete='off'
                  disabled={variationLoading}
                  placeholder={t('Select Variation')}
                  displayValue={(variant: IVariation) =>
                    ecommerce_type === 'salla'
                      ? variant?.attribute_string ?? ''
                      : variantString
                  }
                  className={`w-full cursor-pointer border-0 text-sm leading-5 text-gray-900 without-ring pl-1 ${
                    !!variationLoading
                      ? 'bg-gray-100 cursor-not-allowed'
                      : 'bg-white'
                  }`}
                  onChange={(event) => setQuery(event.target.value)}
                  onBlur={() => {
                    setSearchFocus(false);
                  }}
                  onFocus={() => {
                    setSearchFocus(true);
                    setQuery('');
                    inputElement.current?.click();
                  }}
                />

                <ChevronUpDown
                  className='h-6 w-6 text-gray-400 absolute ltr:right-0 rtl:left-2 flex items-center ltr:pr-2'
                  aria-hidden='true'
                />
              </Combobox.Button>
            </div>
            <Combobox.Options
              className={`rounded-lg absolute z-10 max-h-[20vh] overflow-y-auto bg-white border border-gray-200 mb-1 w-full ${
                hasVariations ? 'top-full mt-1' : 'bottom-full mb-1'
              } `}
            >
              {getVariantList()}
            </Combobox.Options>
          </Combobox>
        )}
      </div>
    );
  };

  const renderAddButton = () => {
    if (!hasVariations) return <></>;
    if (index === 0) {
      return (
        <Button
          className={`${
            variations.length === 1 && totalItem === 1 ? 'hidden' : 'block'
          } px-1.5 rounded-lg flex items-center justify-center`}
          data-tip={
            totalItem > 1
              ? 'Delete Variant'
              : selectedVariant === ''
              ? 'Select a variant first'
              : 'Add Variant'
          }
          data-for={totalItem > 1 ? 'trash-button' : 'add-button'}
          icon={
            totalItem > 1 ? (
              <TrashIcon className='text-gray-400 w-5 h-5' />
            ) : (
              <PlusIcon className='text-gray-400 w-5 h-5' />
            )
          }
          isDisabled={selectedVariant === ''}
          onClick={() => {
            ReactTooltip.hide();
            totalItem > 1 ? removeVariant(index) : addAnotherVariant();
          }}
        />
      );
    } else
      return (
        <div className='flex items-center '>
          <span>
            <Button
              className='px-1.5 rounded-lg flex items-center justify-center'
              data-tip={'Delete Variant'}
              data-for='trash-button'
              icon={<TrashIcon className='text-gray-400 w-5 h-5' />}
              onClick={() => {
                ReactTooltip.hide();
                removeVariant(index);
              }}
            />
          </span>
          <span
            className={` ml-2 ${
              variations.length < 2 || totalItem - index > 1
                ? 'hidden'
                : 'block'
            }`}
            data-tip={
              selectedVariant === '' ? 'Select a variant first' : 'Add Variant'
            }
            data-for='add-button'
          >
            <Button
              className='px-1.5 rounded-lg flex items-center justify-center'
              icon={<PlusIcon className='text-gray-400 w-5 h-5' />}
              isDisabled={selectedVariant === ''}
              onClick={() => {
                ReactTooltip.hide();
                addAnotherVariant();
              }}
            />
          </span>
        </div>
      );
  };
  return (
    <div className='mt-4 flex items-center w-full'>
      <p className=' text-xs text-gray-900 font-normal'>{t('Quantity')}</p>
      <div className='ltr:ml-3 rtl:mr-3 border border-[#D1D5DB] rounded-lg flex'>
        <span
          className='flex'
          data-tip-disable={quantity > 1}
          data-tip={'You must add at least 1 unit'}
          data-for='minus-icon'
        >
          <button
            className={classNames(
              'border-0 p-2 ltr:rounded-l-[7px] rtl:rounded-r-[7px]',
              quantity < 2
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white cursor-pointer'
            )}
            disabled={quantity < 2}
            onClick={() => updateQuantity(-1)} //todo
          >
            <MinusIcon className='w-4 h-4 m-auto' color='#9CA3AF' />
          </button>
        </span>
        <ReactTooltip
          id='minus-icon'
          effect='solid'
          place={'top'}
          type={'dark'}
        />
        <div className='border-l border-r   border-[#D1D5DB] flex items-center justify-center text-gray-900 text-xs min-w-[20px] overflow-hidden'>
          {/* {quantity} */}
          <input
            data-tip-disable={!hasVariations || selectedVariant !== ''}
            data-tip={'Select a variant first'}
            data-for='input-box'
            className={classNames(
              'bg-transparent  p-0 m-0 text-xs text-gray-900 w-full text-center focus:ring-0 focus:ring-offset-0 border-0',
              hasVariations && selectedVariant === ''
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white cursor-default'
            )}
            ref={quantityInputRef}
            type='number'
            value={inputValue}
            disabled={hasVariations && selectedVariant === ''}
            onChange={(e) => handleQuantityChange(e)}
          />
          <ReactTooltip
            id='input-box'
            effect='solid'
            place={'top'}
            type={'dark'}
          />
        </div>
        {/* using custom button for custom shape and icon with custom color */}
        {/* also need disable attribute */}
        <span
          className='flex'
          data-tip-disable={canQuantityIncrease()}
          data-tip={getIncreaseButtonState()}
          data-for='plus-icon'
        >
          <button
            className={classNames(
              'border-0 p-2 ltr:rounded-r-[7px] rtl:rounded-l-[7px]',
              !canQuantityIncrease()
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white cursor-pointer'
            )}
            disabled={!canQuantityIncrease()}
            onClick={() => updateQuantity(1)}
          >
            <PlusIcon className='w-4 h-4 m-auto' color='#9CA3AF' />
          </button>
        </span>
        <ReactTooltip
          id='plus-icon'
          effect='solid'
          place={'top'}
          type={'dark'}
        />
      </div>
      {renderVariantBox()}
      <span className='mx-1' />
      {renderAddButton()}

      <ReactTooltip
        id='add-button'
        effect='solid'
        place={'left'}
        type={'dark'}
      />
      <ReactTooltip
        id='trash-button'
        effect='solid'
        place={'left'}
        type={'dark'}
      />
    </div>
  );
};

export default VariationView;
