import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  data: string[];
}

const TableHeader: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <thead className='bg-gray-50'>
      <tr>
        {!!data &&
          data.map((head) => (
            <th
              scope='col'
              className='px-5 py-3 text-xs font-normal tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
            >
              {t(head)}
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
