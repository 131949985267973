import React from 'react';
import {
  CouponsDataProps
} from '../interfaces';

interface Prop {
  couponsData: CouponsDataProps[];
  selectedCouponData: string;
  handleChange: (value: string) => void;
}

const DiscountsCoupons: React.FC<Prop> = ({
  couponsData,
  selectedCouponData,
  handleChange,
}) => {
  return (
    <div className='px-6 pt-4 pb-6 mt-0 border-b'>
      <label
        htmlFor='country'
        className='block text-sm font-medium text-gray-700'
      >
        Discounts/Coupons
      </label>
      <select
        name='coupons'
        value={selectedCouponData}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          handleChange(e.target.value)
        }
        className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
      >
        <option value=''>Choose coupons from here...</option>
        {couponsData.map((item: any, index: number) => (
          <option key={index} value={item.code}>
            {item.code}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DiscountsCoupons;
