import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../../../../../../utilities/utils';
import {
  MethodProps
} from '../interfaces';

interface Prop {
  methodList: MethodProps[];
  selectedMethod: object;
  handleChange: (value: object) => void;
  isEnabled: boolean;
}

const MethodOptions: React.FC<Prop> = ({
  methodList,
  selectedMethod,
  handleChange,
  isEnabled = false,
}) => {
  return (
    <>
      <RadioGroup
        value={selectedMethod}
        onChange={(data) => handleChange(data)}
      >
        <RadioGroup.Label className='sr-only'>Methods</RadioGroup.Label>
        <div className='mt-3 -space-y-px bg-white rounded-md'>
          {!!isEnabled
            ? methodList?.map(
                (dataElem, idx) =>
                  !!dataElem.is_enabled && (
                    <RadioGroup.Option
                      key={idx}
                      value={dataElem}
                      className={({ checked }) =>
                        classNames(
                          idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          idx === methodList.length - 1
                            ? 'rounded-bl-md rounded-br-md'
                            : '',
                          checked
                            ? 'bg-green-50 border-green-200 z-10'
                            : 'border-gray-200',
                          'relative border p-4 flex cursor-pointer focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked
                                ? 'bg-primary border-transparent'
                                : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-primary' : '',
                              'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden='true'
                          >
                            <span className='rounded-full bg-white w-1.5 h-1.5' />
                          </span>
                          <div className='flex flex-col ml-3'>
                            <RadioGroup.Label
                              as='span'
                              className={classNames(
                                checked ? 'text-primary' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {dataElem?.method_title}
                            </RadioGroup.Label>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  )
              )
            : methodList?.map((dataElem, idx) => (
                <RadioGroup.Option
                  key={idx}
                  value={dataElem}
                  className={({ checked }) =>
                    classNames(
                      idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      idx === methodList.length - 1
                        ? 'rounded-bl-md rounded-br-md'
                        : '',
                      checked
                        ? 'bg-green-50 border-green-200 z-10'
                        : 'border-gray-200',
                      'relative border p-4 flex cursor-pointer focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? 'bg-primary border-transparent'
                            : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-primary' : '',
                          'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden='true'
                      >
                        <span className='rounded-full bg-white w-1.5 h-1.5' />
                      </span>
                      <div className='flex flex-col ml-3'>
                        <RadioGroup.Label
                          as='span'
                          className={classNames(
                            checked ? 'text-primary' : 'text-gray-900',
                            'block text-sm font-medium'
                          )}
                        >
                          {dataElem?.method_title}
                        </RadioGroup.Label>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
        </div>
      </RadioGroup>
    </>
  );
};

export default MethodOptions;
