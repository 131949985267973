import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Listbox, Transition } from '@headlessui/react';
import { allPlatformList, classNames } from 'utilities/utils';
import { channelListProps } from '../../../interface';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface Props {
  channelData: channelListProps;
  handleChange: (key: string, value: string | string[]) => void;
  onPlatformChange: (platform: any) => void;
}

const PlatformListDropdown: React.FC<Props> = ({
  channelData,
  handleChange,
  onPlatformChange,
}) => {
  const [platform, setPlatform] = React.useState(
    allPlatformList.filter(
      (elemfilter) => elemfilter.type === channelData.platform_type
    )[0].title
  );

  const mainType = allPlatformList.filter(
    (elem) => elem.type === channelData.platform_type
  )[0].main_type;

  const effectFunction = () => {
    const platformType = allPlatformList.filter(
      (elemfilter) => elemfilter.title === platform
    )[0].type;
    onPlatformChange(platformType);
    handleChange('type', platformType);
  };
  React.useEffect(() => {
    /*eslint-disable */
    effectFunction();
  }, [platform]);
  return (
    <div className='relative'>
      <Listbox value={platform} onChange={setPlatform}>
        {({ open }) => (
          <>
            <Listbox.Button className='w-full py-2 ltr:pr-16 rtl:pr-4 ltr:text-left rtl:text-right bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none sm:text-sm'>
              <span className='block ml-3 truncate'>{platform}</span>
              <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronUpDownIcon
                  className='w-4 h-4 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='z-40 relative mt-3 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-full'>
                {allPlatformList
                  .filter((item) => item.main_type === mainType)
                  .map((item) => (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-primary' : 'text-gray-900',
                          'cursor-default select-none relative py-1.5 pl-2.5 pr-2.5'
                        )
                      }
                      key={item.type}
                      value={item.title}
                    >
                      {item.title}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};
const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchChannelCloneBlocks: (channelId: number, cloneType: string) =>
    dispatch.channel.fetchChannelCloneBlocks({ channelId, cloneType }),
});
export default connect(mapState, mapDispatch)(PlatformListDropdown);
