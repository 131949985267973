import React, { useCallback, useState } from 'react';
import ReactFlow, {
  addEdge,
  Controls,
  Background,
  // getIncomers,
  getOutgoers,
  useReactFlow,
  useEdges,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  // getConnectedEdges,
} from 'reactflow';
import 'reactflow/dist/style.css';

import {
  CustomEdge,
  ActionNode,
  TriggerNode,
  TriggerButton,
  ConditionNode,
  UtilityContents,
  // GenericAlertModal,
  useReactFlowGraphServices,
  ActionConditionButtons,
} from '../../export';

import { NodeType } from '../../interface';

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = {
  'action-node': ActionNode,
  'trigger-node': TriggerNode,
  'condition-node': ConditionNode,
  'trigger-button': TriggerButton,
  'condition_action-button': ActionConditionButtons,
};

const edgeTypes = {
  'custom-edge': CustomEdge,
};

function ReactFlowService() {
  const {
    automationNodes,
    automationEdges,
    handleOnEdgeChanges,
    handleReactflowNodes,
    automationFlowDisplayViewType,
  } = useReactFlowGraphServices();
  const isLogView = automationFlowDisplayViewType === 'viewLog';
  const shouldDisableInteractions = !isLogView;
  const [nodes, setNodes] = useState(automationNodes);
  const [edges, setEdges] = useState(automationEdges);

  const workflowEdges = useEdges();
  const { fitView, setViewport, getNodes, getEdges } = useReactFlow();

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    // eslint-disable-next-line
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  const onEdgesDelete = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    // eslint-disable-next-line
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => {
      connection = {
        ...connection,
        //adding type, style, and markerend added on edge connect
        type: 'custom-edge',
        style: UtilityContents.EdgeStyles,
        markerEnd: UtilityContents.EdgeMarkendProperty,
      };
      setEdges((eds) => addEdge(connection, eds));
      handleOnEdgeChanges([...edges, connection]);
    },
    // eslint-disable-next-line
    [setEdges]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      // setEdges() contains statement that handles edge connections after a middle node deleteion;
      // this statements is commented because the deletion behaviour is not clearly defined form product;
      // setEdges(
      //   deleted.reduce((acc, node) => {
      //     const incomers = getIncomers(node, nodes, edges);
      //     const outgoers = getOutgoers(node, nodes, edges);
      //     const connectedEdges = getConnectedEdges([node], edges);

      //     const remainingEdges = acc.filter(
      //       (edge) => !connectedEdges.includes(edge)
      //     );

      //     const createdEdges = incomers.flatMap(({ id: source }) =>
      //       outgoers.map(({ id: target }) => ({
      //         id: `${source}->${target}`,
      //         source,
      //         target,
      //         //adding style, and markerend added on edge connect
      //         style: UtilityContents.EdgeStyles,
      //         markerEnd: UtilityContents.EdgeMarkendProperty,
      //       }))
      //     );
      //     return [...remainingEdges, ...createdEdges];
      //   }, edges)
      // );

      // this function update the redux store nodes by filtering deleted nodes from current node.
      // TODO: check for any builtin functions to get updated nodes
      handleReactflowNodes(
        nodes.filter((singleNode) => singleNode.id !== deleted[0].id)
      );
    },
    // eslint-disable-next-line
    [nodes, edges]
  );

  // Function to handle node position changes
  const onNodeDragStop = (event, node) => {
    // Update the node's position in the 'elements' state
    const updatedElements = nodes.map((element) => {
      if (element.id === node.id) {
        return {
          ...element,
          position: node.position,
        };
      }
      return element;
    });

    handleReactflowNodes(updatedElements);
  };

  const isValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const isSourceAlreadyConnected = edges.find(
        (edge) => edge.sourceHandle === connection.sourceHandle
      );
      const istargetAlreadyConnected = edges.find(
        (edge) => edge.targetHandle === connection.targetHandle
      );

      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return (
        !istargetAlreadyConnected &&
        !isSourceAlreadyConnected &&
        !hasCycle(target)
      );
    },
    [getNodes, getEdges]
  );

  React.useEffect(() => {
    setNodes(automationNodes);
  }, [automationNodes]);

  const firstNodeType = React.useMemo(() => nodes[0]?.type, [nodes]);

  React.useEffect(() => {
    if (firstNodeType === NodeType.TRIGGER && nodes.length === 2) {
      fitView({ padding: 1, minZoom: 0.6 });
      setViewport({ zoom: 0.7, y: 10 });
    }
  }, [nodes.length, fitView, setViewport, firstNodeType]);

  React.useEffect(() => {
    setEdges(automationEdges);
  }, [automationEdges]);

  React.useEffect(() => {
    handleOnEdgeChanges(edges);
    // eslint-disable-next-line
  }, [workflowEdges]);

  return (
    <ReactFlow
      minZoom={0}
      nodes={nodes}
      edges={edges}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgesDelete={onEdgesDelete}
      onNodesDelete={onNodesDelete}
      onNodeDragStop={onNodeDragStop}
      zoomOnDoubleClick={false}
      nodesDraggable={shouldDisableInteractions}
      nodesFocusable={shouldDisableInteractions}
      edgesFocusable={shouldDisableInteractions}
      edgesUpdatable={shouldDisableInteractions}
      zoomOnScroll={true}
      panOnScroll={true}
      selectionOnDrag={true}
      isValidConnection={isValidConnection}
      fitView
    >
      <Controls />
      <Background variant='dots' gap={12} size={1} />
    </ReactFlow>
  );
}

export function ReactFlowIndex() {
  return (
    <ReactFlowProvider>
      <ReactFlowService />
    </ReactFlowProvider>
  );
}
