import * as React from 'react';
import notFoundImg from 'assets/icons/emptySearchIcon.svg';
import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import EmptypageIcon from 'assets/icons/emptyPageIcon.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'libraryV2/ui/table';

import { DataTablePagination } from './CsatTablePagination';
import { CsatTableToolbar } from './CsatTableToolbar';
import { getCSATTableColumns } from './TableColumns';
import { useDebounce } from 'components';
import useCsatReporting from 'pages/reporting/hooks/useCsatReporting';

export default function CsatOverviewTable() {
  const {
    handleCsatTableFilter,
    totalResponseCount,
    projectId,
    isCsatTableDataLoading,
    csatTableData,
    totalCsatResponseWithoutFilter,
  } = useCsatReporting();

  const columns = getCSATTableColumns(projectId);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const debouncedFilter = useDebounce(columnFilters, 1.5 * 1000);

  const table = useReactTable({
    data: csatTableData,
    columns,
    state: {
      columnFilters,
    },
    enableRowSelection: false,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualFiltering: true,
    rowCount: totalResponseCount,
    manualPagination: true,
  });

  const handlePageSizeChange = (newPageSize: number) => {
    handleCsatTableFilter(debouncedFilter, {
      pageSize: newPageSize,
      pageIndex: table.getState().pagination.pageIndex,
    });
  };
  const handlePageIndexChange = (newPageIndex: number) => {
    handleCsatTableFilter(debouncedFilter, {
      pageSize: table.getState().pagination.pageSize,
      pageIndex: newPageIndex,
    });
  };

  React.useEffect(() => {
    handleCsatTableFilter(debouncedFilter, table.getState().pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter]);

  const renderTableLoadingView = (numberOfRows = 10) => {
    const tableHeaders = table.getFlatHeaders().map((h) => h.id);
    return Array(numberOfRows)
      .fill(0)
      .map((_, index) => (
        <TableRow key={index}>
          {tableHeaders.map((v) => (
            <TableCell key={v} colSpan={1} style={{ padding: '6px 8px' }}>
              <div className='w-auto py-4  bg-zinc-100 animate-pulse rounded'></div>
            </TableCell>
          ))}
        </TableRow>
      ));
  };

  const renderTableBody = () => {
    const hasOnlySearchFilter =
      columnFilters.length === 1 && columnFilters[0].id === 'customerName';

    if (table.getRowModel().rows?.length) {
      return table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
          {row.getVisibleCells()?.map((cell) => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          ))}
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className='h-auto text-center'>
          <div className='flex items-center justify-center w-full py-32'>
            <div className='flex flex-col items-center max-w-xs'>
              <img src={notFoundImg} alt='empty' />
              <h6 className='pt-2 text-zinc-900 text-lg font-semibold'>
                No Results Found
              </h6>
              <p className='pt-2 pb-3 text-center text-zinc-500'>
                {hasOnlySearchFilter
                  ? 'Please search with different keyword.'
                  : 'Please search with different keyword or filter.'}
              </p>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const renderTable = () => {
    return (
      <div className='flex flex-col gap-4'>
        <CsatTableToolbar table={table} />
        <div className='rounded-md border bg-white border-[#DFDFE2]'>
          <Table>
            <TableHeader>
              {table.getHeaderGroups()?.map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers?.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isCsatTableDataLoading
                ? renderTableLoadingView()
                : renderTableBody()}
            </TableBody>
          </Table>
          <div className='py-2'>
            {!isCsatTableDataLoading && (
              <DataTablePagination
                totalRow={totalResponseCount}
                onPageSizeChange={handlePageSizeChange}
                onPageIndexChange={handlePageIndexChange}
                table={table}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderNoCsatReportFound = () => {
    return (
      <div className='flex flex-col items-center justify-center h-[550px] shadow rounded-md border border-border-soft'>
        <img src={EmptypageIcon} alt='EMPTY_PAGE' />
        <h3 className={`mt-[18px]`}>No Reports Found</h3>
        <span
          className={`subtitle-medium mt-1 mb-4 text-center w-[400px] mx-auto`}
        >
          There is no data for the selected channel or time range. Please change
          your selection and check again.
        </span>
      </div>
    );
  };

  return Object.values(columnFilters).length +
    csatTableData.length +
    totalCsatResponseWithoutFilter ===
    0
    ? renderNoCsatReportFound()
    : renderTable();
}
