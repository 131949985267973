import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { availablePlansProperty } from '../interface';
import TeamMemberCounter from './TeamMemberCounter';
import useTranslation from 'components/customHooks/useTranslation';

interface PlanAddonCardProps {
  plan: availablePlansProperty;
  setDisableCheckout?: (payload: boolean) => void;
  setTeamMemberRemoveCondition: (payload: boolean) => void;
}

const PlanAddonCard: React.FC<PlanAddonCardProps> = ({
  plan,
  setDisableCheckout,
  setTeamMemberRemoveCondition,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex flex-col w-full border border-gray-200 p-6 mb-5 rounded-lg'>
        <p className='text-gray-900 text-sm font-semibold mb-4'>
          {t('PLAN ADD-ONS')}
        </p>
        <div>
          {' '}
          <TeamMemberCounter
            plan={plan}
            setDisableCheckout={setDisableCheckout}
            setTeamMemberRemoveCondition={setTeamMemberRemoveCondition}
          />
        </div>
      </div>
      <div className='flex w-full h-[72px] bg-blue-50 p-4 rounded-md'>
        <InformationCircleIcon className='w-6 h-6 p-0.5 my-2 text-blue-400 bg-blue-50 rounded-3xl' />
        <p className='w-40 text-blue-800 text-sm font-medium mx-3'>
          {t('Your plan already has 5 free seats by default.')}
        </p>
      </div>
    </>
  );
};
export default PlanAddonCard;
