import { useEffect, useRef, useState } from 'react';

export default function useDebounce<T>(value: T, delay: number): T {
  const [state, setState] = useState<T>(value);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const nextValue = useRef(null) as any;
  const hasNextValue = useRef(0) as any;

  useEffect(() => {
    if (!timeout.current) {
      setState(value);
      const timeoutCallback = () => {
        if (hasNextValue.current) {
          hasNextValue.current = false;
          setState(nextValue.current);
          timeout.current = setTimeout(timeoutCallback, delay);
        } else {
          timeout.current = undefined;
        }
      };
      timeout.current = setTimeout(timeoutCallback, delay);
    } else {
      nextValue.current = value;
      hasNextValue.current = true;
    }
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [value, delay]);

  return state;
}
