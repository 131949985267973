import React from 'react';
import { connect } from 'react-redux';
import {
  subscriptionUpdateData,
  subscriptionDetailsType,
  availablePlansProperty,
} from '../interface';
import { getQueryParameters } from 'utilities/utils';
import { plansProperty } from '../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface TeamMemberCounterProps {
  teamMemberCount: number;
  updateTeamMemberCount: (payload: number) => void;
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  isBillingAnnually: boolean;
  plan: availablePlansProperty;
  availablePlans: availablePlansProperty[];
  setDisableCheckout?: (payload: boolean) => void;
  setTeamMemberRemoveCondition: (payload: boolean) => void;
  initiateSubscriptionPlanUpdate: (payload: {
    teamId: number;
    data: subscriptionUpdateData;
  }) => void;
}

const TeamMemberCounter: React.FC<TeamMemberCounterProps> = ({
  teamMemberCount,
  updateTeamMemberCount,
  selectedProject,
  subscriptionDetails,
  isBillingAnnually,
  availablePlans,
  setDisableCheckout,
  setTeamMemberRemoveCondition,
  initiateSubscriptionPlanUpdate,
}) => {
  const { t } = useTranslation();
  const planType = getQueryParameters('plan_type');
  const subscriptionPlan =
    subscriptionDetails?.project_billing_info?.subscription_plan;
  const currentPlan = availablePlans.filter(
    (plan) => plan.name === subscriptionPlan?.name
  )[0];
  const [disableDecreaseButton, setDisableDecreaseButton] =
    React.useState(false);

  const getPlanName = () => {
    if (
      [
        plansProperty.premium_plan.name,
        plansProperty.business_plan.name,
      ].includes(planType)
    ) {
      return planType;
    } else {
      return subscriptionPlan?.name;
    }
  };

  React.useEffect(() => {
    if (
      planType === plansProperty.business_plan.name &&
      teamMemberCount <= plansProperty.business_plan.min_team_member
    ) {
      setDisableDecreaseButton(true);
    } else if (
      planType === plansProperty.premium_plan.name &&
      teamMemberCount <= plansProperty.premium_plan.min_team_member
    ) {
      setDisableDecreaseButton(true);
    } else if (
      planType === 'update-team' &&
      teamMemberCount <= currentPlan.minimum_team_size
    ) {
      setDisableDecreaseButton(true);
    } else {
      setDisableDecreaseButton(false);
    }
    // eslint-disable-next-line
  }, [teamMemberCount]);

  let minMember =
    planType === 'update-team'
      ? currentPlan.minimum_team_size
      : planType === plansProperty.business_plan.name
      ? plansProperty.business_plan.min_team_member
      : plansProperty.premium_plan.min_team_member;

  const updateTeamMember = (operation: string) => {
    setDisableCheckout && setDisableCheckout(false);
    let teamMember = Math.max(teamMemberCount, 5);

    switch (operation) {
      case 'increment':
        teamMember++;
        break;
      case 'decrement':
        if (
          teamMember === selectedProject.team_members &&
          teamMember !== currentPlan.minimum_team_size
        ) {
          setTeamMemberRemoveCondition(true);
          return;
        }
        if (teamMember > minMember) {
          teamMember--;
        }
        break;
      default:
        teamMember = teamMemberCount;
        break;
    }
    updateTeamMemberCount(teamMember);
    const data = {
      plan: getPlanName(),
      team_members: teamMember,
      is_billed_annually: isBillingAnnually,
    };

    const payload = {
      teamId: selectedProject.id,
      data: data,
    };
    initiateSubscriptionPlanUpdate(payload);
  };

  return (
    <div className='flex flex-col'>
      <p className='text-gray-500 text-sm font-medium mb-1.5'>
        {t('Number of add-on members')}
      </p>
      <div className='flex w-full border border-gray-300 rounded-md'>
        <button
          className={`${
            disableDecreaseButton ? 'bg-gray-100' : ''
          } border-r border-gray-300 rounded-l-md w-8`}
          onClick={() => updateTeamMember('decrement')}
        >
          -
        </button>
        <input
          disabled
          value={Math.max(teamMemberCount - minMember, 0)}
          type='number'
          className='p-1 active:border-none focus:ring-gray-300 border-none
          w-32 outline-none text-center text-sm text-gray-900 font-normal'
        />
        <button
          className={'border-l border-gray-300 rounded-r-md w-8'}
          onClick={() => updateTeamMember('increment')}
        >
          +
        </button>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  teamMemberCount: state.newBilling.teamMemberCount,
  selectedProject: state.dashboard.selectedProject,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  isBillingAnnually: state.newBilling.isBillingAnnually,
  availablePlans: state.newBilling.availablePlans,
});

const mapDispatch = (dispatch: any) => ({
  updateTeamMemberCount: (payload: number) =>
    dispatch.newBilling.updateTeamMemberCount(payload),
  initiateSubscriptionPlanUpdate: (payload: any) =>
    dispatch.newBilling.initiateSubscriptionPlanUpdate(payload),
});

export default connect(mapState, mapDispatch)(TeamMemberCounter);
