import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
interface StepProps {
  id: number;
  name: string;
}
interface Props {
  steps: StepProps[];
  currentStep: number;
}
const Stepper: React.FC<Props> = ({ steps, currentStep }) => {
  const { t } = useTranslation();
  return (
    <nav aria-label='Progress'>
      <ol className='space-y-4 md:flex rtl:gap-8 md:space-y-0 md:space-x-8'>
        {steps.map((step: StepProps) => (
          <li key={step.name} className='md:flex-1 rtl:text-right'>
            {step.id < currentStep ? (
              <div className='flex flex-col py-2 pl-4 border-l-4 border-green-400 group hover:border-green-400 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'>
                <span className='text-xs font-semibold tracking-wide text-green-400 uppercas'>
                  {t('Done')}
                </span>
                <span className='text-sm font-medium'>{t(step.name)}</span>
              </div>
            ) : step.id === currentStep ? (
              <div
                className='flex flex-col py-2 pl-4 border-l-4 border-green-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'
                aria-current='step'
              >
                <span className='text-xs font-semibold tracking-wide text-green-300 uppercase'>
                  {t(`Step ${step.id}`)}
                </span>
                <span className='text-sm font-medium'>{t(step.name)}</span>
              </div>
            ) : (
              <div className='flex flex-col py-2 pl-4 border-l-4 border-gray-200 group hover:border-gray-200 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4'>
                <span className='text-xs font-semibold tracking-wide text-gray-500 uppercase group-hover:text-gray-700'>
                  {t(`Step ${step.id}`)}
                </span>
                <span className='text-sm font-medium'>{t(step.name)}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
export default Stepper;
