import React from 'react';
import DropDownList from '../components/DropDownList';

import { XCircleIcon } from '@heroicons/react/20/solid';
import { facebookFeedSettingDataProps } from '../../../interface';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';
interface Props {
  success?: boolean;
  errorMessage?: string;
  feedSettingData: facebookFeedSettingDataProps;
  handleChange: (key: string, value: string | string[]) => void;
  integratedNlpList: { id: string; name: string; provider: string }[];
}

const FacebookFeedSetting: React.FC<Props> = ({
  success = true,
  handleChange,
  errorMessage,
  feedSettingData,
  integratedNlpList,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  const getSelectedNlp = () => {
    return integratedNlpList.filter(
      (item: any) => item.id === feedSettingData.connected_nlp_integration_id
    )[0];
  };

  const [selectedNlpData, setSelectedNlpData] = React.useState<any>(
    !!getSelectedNlp() ? getSelectedNlp() : null
  );
  return (
    <>
      {!success && (
        <div className='flex justify-center p-6 gap-2'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder={t('My feed channel')}
            value={feedSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            {t(
              `This title will be used in ${partnerName} to identify this channel. Give it a title that you can differentiate with later.`
            )}
          </p>
        </div>
      </div>
      <div className='p-6 border-t'>
        <label
          htmlFor='nlp_selection'
          className='flex mb-1 text-sm font-medium text-gray-700'
        >
          {t('Select NLP App')}
        </label>
        <DropDownList
          options={integratedNlpList}
          selectedValue={!!selectedNlpData ? selectedNlpData : {}}
          titleKeyName={'name'}
          isFullWidth={true}
          buttonClassName={'w-full'}
          placeHolder={t('Select NLP app')}
          handleSelectedOption={(value: any) => {
            if (value.id !== feedSettingData.connected_nlp_integration_id) {
              setSelectedNlpData(value);
              handleChange('connected_nlp_integration_id', value.id);
            } else {
              setSelectedNlpData(null);
              handleChange('connected_nlp_integration_id', '');
            }
          }}
        />
      </div>
    </>
  );
};
export default FacebookFeedSetting;
