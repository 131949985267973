import React from 'react';
import { connect } from 'react-redux';
import { ShopifyUpdateOrderCustomerDataProps } from '../../eCommerceOrderCreate/interfaces';
import errorStateIcon from '../../../../assets/images/ecommerceAssets/OrderCreateError.svg';
import ShippingAddressUpdate from '../components/ShippingAddressUpdate';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import OrderNote from '../../shopifyOrderCreate/components/OrderNote'

interface Prop {
  customerData: ShopifyUpdateOrderCustomerDataProps;
  updateAddress: (key: string, value: string, mainKey: string) => void;
  updateStateData: (key: string, value: any) => void;
  countryDetails: {
    country: string;
    country_iso_code: string;
    phone_code: string;
  };
  orderId?: string;
  customerId: number;
  finishCalculationApi: (customerId: number, orderId: string) => Promise<any>;
  orderNote?: string;
}

const DeliveryInformation: React.FC<Prop> = ({
  customerData,
  updateAddress,
  updateStateData,
  countryDetails,
  orderId,
  customerId,
  finishCalculationApi,
  orderNote,
}) => {
  type apiStateType = 'loading' | 'success' | 'error';
  const [apiState, setAPIState] = React.useState<apiStateType>('loading');
  const [messageState, setMessageState] = React.useState(
    'Calculating updated order...'
  );

  const handleCalculateUpdateOrderEnd = async (
    customerId: number,
    orderId: string
  ) => {
    let response = await finishCalculationApi(customerId, orderId);
    if (response?.success) {
      setAPIState('success');
    } else {
      if (response?.error) {
        setMessageState(response.error);
      } else {
        setMessageState('Cannot update this order! Please try again.');
      }
      setAPIState('error');
    }
  };

  React.useEffect(() => {
    if (customerId && orderId) {
      handleCalculateUpdateOrderEnd(customerId, orderId);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {apiState === 'success' ? (
        <div>
          {!!customerData && customerData?.shipping_address && (
            <div>
              <ShippingAddressUpdate
                data={customerData?.shipping_address}
                handleChange={(key: string, value: string) => {
                  updateAddress(key, value, 'shipping_address');
                }}
              />
              <OrderNote
                orderNote={orderNote}
                handleChange={(key: string, value: string) =>
                  updateStateData(key, value)
                }
              />
            </div>
          )}
        </div>
      ) : apiState === 'error' ? (
        <>
          <div className='flex items-center justify-center h-96 flex-column'>
            <img src={errorStateIcon} alt={apiState} width={200} />
            <p>{messageState}</p>
          </div>
        </>
      ) : (
        <>
          <div className='flex items-center justify-center h-96 flex-column'>
            <ArrowPathIcon className='w-10 h-10 text-primary hover:text-primary-hover animate-reverse-spin' />
            <div>
              <p>{messageState}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  customerData: state.crmEcommerce.customerData,
  countryDetails: state.settings.countryDetails,
  orderNote: state.crmEcommerce.orderNote,
});

const mapDispatch = (dispatch: any) => ({
  updateAddress: (key: string, value: string, mainKey: string) =>
    dispatch.crmEcommerce.updateAddress({ key, value, mainKey }),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const DeliveryInformationContainer = connect(
  mapState,
  mapDispatch
)(DeliveryInformation);
export default DeliveryInformationContainer;
