import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useJinjaCodeUtility = () => {
  const dispatch = useDispatch();
  const selectedProjectId = useSelector(
    (state: any) => state?.dashboard?.selectedProject?.id
  );

  const triggerCode = useSelector(
    (state: any) => state?.workflowAutomation?.selectedTrigger?.trigger_code
  );
  const [sampleData, setSampleData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSampleData = async () => {
    setLoading(true);
    const response = await dispatch.workflowAutomation.fetchWebhookSampleData({
      projectId: selectedProjectId,
      triggerCode,
    });
    if (!!response) {
      setSampleData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSampleData();
    //eslint-disable-next-line
  }, []);

  return {
    loading,
    sampleData,
    getSampleData,
  };
};

export default useJinjaCodeUtility;
