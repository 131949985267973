type MenuProps = {
  modalState: Boolean;
  innerDesign: any;
  position: String;
  ref?: any;
  width?: number;
  classForBanner?: string;
};

const MenuModal = (props: MenuProps) => {
  let width = !!props.width ? `w[${props.width}px]` : 'w-72';

  if (!props.modalState) return null;
  return (
    <div
      id='menuModal'
      className={`${props.classForBanner} z-30 absolute ${props.position} bg-white ${width} rounded-lg border-[1px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"`}
    >
      {props.innerDesign}
    </div>
  );
};

export default MenuModal;
