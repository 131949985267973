import React from 'react';
import { Link } from '../..../../../../../library';
import emptyBox from '../../assets/emptyBox.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  histories: Array<any>;
}

const BillingHistory: React.FC<Props> = ({ histories }) => {
  const { t } = useTranslation();
  return (
    <>
      <h2
        id='billing-history-heading'
        className='text-lg text-gray-900 font-medium mb-4'
      >
        {t('Billing History')}
      </h2>
      {histories.length > 0 ? (
        <div className='overflow-hidden border border-gray-200 rounded-md mb-5'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
              <tr>
                <th
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {t('Plan')}
                </th>
                <th
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {t('Date')}
                </th>
                <th
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {t('Billed')}
                </th>
                <th
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {t('Status')}
                </th>
                <th
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  {t('Method')}
                </th>
                <th
                  scope='col'
                  className='relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                >
                  <span className='sr-only'>{t('Invoice')}</span>
                </th>
              </tr>
            </thead>
            {histories.length > 0 && (
              <>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {histories.map((payment) => (
                    <tr key={payment.id}>
                      <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap capitalize'>
                        {t(payment.subscription_plan)}
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                        {payment.billing_start_date}
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
                        ${Math.abs(payment.billing_amount)}
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
                        {t(payment.billing_status)}
                      </td>
                      <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                        {payment.billing_status === 'paid' &&
                        payment.card_id === '' ? (
                          t('Available Credits')
                        ) : (
                          <>
                            {payment.billing_status === 'credited' ? null : (
                              <>{t(`Ending with {{${payment.card_id}}}`)}</>
                            )}
                          </>
                        )}
                      </td>
                      <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                        <Link
                          url={payment.stripe_invoice_url}
                          openInNewTab={true}
                          children={t('Invoice')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      ) : (
        <div className='flex p-3 w-full mx-auto border border-gray-200 rounded-md mb-5 h-[300px]'>
          <div className='w-full flex flex-col items-center justify-center'>
            <img src={emptyBox} alt='' className='w-20 h-20 mb-1' />
            <p className='text-gray-400 text-sm'>
              {t('No payments were made')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default BillingHistory;
