import React from 'react';
import AgentListDropdown from './AgentListDropdown';
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import { Button } from '../../../../library';
import TableData from './TableData';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Pagination from '../CommonComponent/Pagination';
import { LinearLoaderComponent } from '../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  title: string;
  toolTipInfo: string;
  tableData: tableDataProps[];
  loader: boolean;
  group: groupProps[];
  defaultGroup: groupProps;
  exportData: () => void;
  exportLoader: boolean;
  total: number;
  offset: number;
  limit: number;
  setOffset: (val: number) => void;
  onAgentSelect: (group: any) => void;
  handleTicketListNext: () => void;
  handleTicketListPrevious: () => void;
}

interface customerProps {
  full_name: string;
  platform: { name: string };
}

interface tableDataProps {
  id: number;
  description: string;
  created_at: string | number;
  customer: customerProps;
  tags: [];
  assigned_agent: { full_name: string };
  assigned_group: { name: string };
  resolved_at: string | number;
  resolved_by: { full_name: string };
  first_assignment_time: number | string | null;
  resolution_time: number | string | null;
  first_response_time: number | string | null;
}

interface groupProps {
  id: number | null;
  email: string;
  avatar: string | null;
  full_name: string;
}

const TicketTable: React.FC<props> = ({
  title,
  toolTipInfo,
  tableData,
  loader,
  group,
  defaultGroup,
  onAgentSelect,
  exportData,
  exportLoader,
  offset,
  total,
  limit,
  setOffset,
  handleTicketListNext,
  handleTicketListPrevious,
}) => {
  const { t } = useTranslation();
  const [searchTicket, setSearchTicket] = React.useState('');
  return (
    <div className='m-3 overflow-hidden bg-white rounded-lg shadow '>
      <div className='px-4 py-5 border-b'>
        <div className='flex flex-wrap justify-between'>
          <div className='flex mb-4 text-base font-normal text-gray-900'>
            {t(title)}
            <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(toolTipInfo)}>
              <InformationCircleIcon className='w-4 h-4' />
            </span>
          </div>
          <div className='flex flex-wrap justify-start gap-2'>
            <AgentListDropdown
              value={defaultGroup}
              onChange={(agent: any) => onAgentSelect(agent)}
              hasAllAgents={true}
              options={group}
            />
            <div className='relative w-full rounded-md shadow-sm sm:w-auto'>
              <div className='absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='search'
                name='search'
                id='search'
                className='block w-full ltr:pl-10 rtl:pr-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder={t('Search by ticket id')}
                value={searchTicket}
                disabled={tableData.length === 0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTicket(e.target.value);
                  setOffset(0);
                }}
              />
            </div>
            <Button
              intent='default'
              isDisabled={tableData.length === 0}
              size={'sm'}
              onClick={() => exportData()}
              className={'w-full sm:w-auto'}
            >
              {!exportLoader && (
                <ArrowDownTrayIcon
                  className='ltr:-ml-0.5 rtl:-mr-0.5 ltr:mr-2 rtl:ml-2 h-4 w-4'
                  aria-hidden='true'
                />
              )}
              {t('Export')}
            </Button>
          </div>
        </div>
      </div>
      {loader && <LinearLoaderComponent />}
      <TableData
        loader={loader}
        tableData={tableData}
        searchTicket={searchTicket}
      />
      {!loader && tableData?.length !== 0 && (
        <Pagination
          offset={offset}
          tableDataLength={tableData?.length}
          total={total}
          limit={limit}
          handleNextButton={handleTicketListNext}
          handlePreviousButton={handleTicketListPrevious}
        />
      )}
    </div>
  );
};

export default TicketTable;
