import React, { useEffect } from 'react';
import ProductInteractionTab from './ProductInteractionTab';
import {
  ShoppingCartIcon,
  CurrencyDollarIcon,
  EyeIcon,
} from '@heroicons/react/20/solid';
import ProductInteractionList from './ProductInteractionList';

const tabsData = [
  { id: 0, name: 'Bought', icon: <CurrencyDollarIcon /> },
  { id: 1, name: 'In Cart', icon: <ShoppingCartIcon /> },
  { id: 2, name: 'Viewed', icon: <EyeIcon /> },
];

interface tabProps {
  id: number;
  name: string;
  icon: React.ReactNode;
}

interface Prop {
  fetchProductInteraction: () => void;
  boughtProduct: productProps[];
  cartProduct: productProps[];
  viewedProducts: productProps[];
  currencySymbol: string;
}

interface productProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  unit_price: number;
  timestamp: number;
}

const ProductInteraction: React.FC<Prop> = ({
  fetchProductInteraction,
  viewedProducts,
  cartProduct,
  boughtProduct,
  currencySymbol,
}) => {
  useEffect(() => {
    fetchProductInteraction();
    // eslint-disable-next-line
  }, []);

  const [currentTabId, setCurrentTabId] = React.useState(0);
  return (
    <div className='bg-white shadow rounded-b-md'>
      <ProductInteractionTab
        tabsData={tabsData}
        currentTabId={currentTabId}
        handleTabChange={(tab: tabProps) => {
          setCurrentTabId(tab.id);
        }}
      />
      <div className='p-4 overflow-auto max-h-80'>
        <ProductInteractionList
          data={
            currentTabId === 0
              ? boughtProduct
              : currentTabId === 1
              ? cartProduct
              : viewedProducts
          }
          currentTabId={currentTabId}
          currencySymbol={currencySymbol}
        />
      </div>
    </div>
  );
};

export default ProductInteraction;
