import { WhatsappTemplateAnalyticsLogsApiDataLimit } from 'pages/reporting/utility/content';
import { useDispatch } from 'react-redux';

const useWhatsappAnalyticsLogs = () => {
  const dispatch = useDispatch();

  const fetchWhatsappTemplateAnalyticsLogsData = ({
    offset,
    searchValue,
    statusFilter,
    sourceFilter,
  }: {
    offset: number;
    searchValue: string;
    statusFilter: string[];
    sourceFilter: string[];
  }) => {
    const payload: any = {
      limit: WhatsappTemplateAnalyticsLogsApiDataLimit,
      offset,
      search: searchValue,
      status: statusFilter.length === 0 ? 'all' : `${statusFilter}`,
    };
    if (sourceFilter.length > 0) {
      payload.sources = `${sourceFilter}`;
    }
    dispatch.reporting.fetchTemplateAnalyticsLogs(payload);
  };

  const handleSelectedAnalyticsLogsView = (data: any) => {
    dispatch.reporting.updateStateData({
      key: 'selectedAnalytics',
      value: data,
    });
  };

  const updateSelectedAnalyticsId = (template_id: string) => {
    dispatch.reporting.updateStateData({
      key: 'selectedAnalyticsId',
      value: template_id,
    });
  };

  const updateSelectedLogData = (data: any) => {
    dispatch.reporting.updateStateData({
      key: 'selectedLogsData',
      value: data,
    });
  };

  return {
    updateSelectedLogData,
    updateSelectedAnalyticsId,
    handleSelectedAnalyticsLogsView,
    fetchWhatsappTemplateAnalyticsLogsData,
  };
};

export default useWhatsappAnalyticsLogs;
