import React from 'react';
import ImageUploadField from '../../../components/utilityComponent/ImageUploadField';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import { handleImageUpload } from '../../../utilities/utils';
import imageIcon from '../../pages/builder/assets/images/icons/image.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import CreateButtonFragment from '../elements/CreateButtonFragment';
import { Button } from 'evergreen-ui';

const ImageBlock = ({
  id,
  save,
  data,
  saveBlock,
  saveLoader,
  sequences,
  handleUpdate,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  apiList,
  handleAPIButtonElementCreate,
  type,
  subscriptionPlanType,
}) => {
  const image = data?.urls?.at(0);
  const [currentTab, setCurrentTab] = React.useState(-2);

  React.useEffect(() => {
    let tabIndex = data?.urls?.at(0) !== '' ? 0 : -1;
    setCurrentTab(tabIndex);
    // eslint-disable-next-line
  }, []);

  const handleImageDrop = (image) => {
    const callback = (image) => handleUpdate(id, image);
    handleImageUpload(image, callback);
  };

  const handleImageClear = () => {
    handleUpdate(id, '');
  };

  return (
    <BlockContainer
      id={id}
      title='Image'
      subtitle='Send an image to your customers.'
      save={save}
      isLoading={saveLoader}
      icon={imageIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        {currentTab === -1 &&
          (!!data?.api ? (
            <>
              <FetchFromAPIButtonSection
                blockId={id}
                hasWriteAccess={hasWriteAccess}
                apiButtonData={data.api}
                handleDelete={handleAPIButtonElementCreate}
              />
            </>
          ) : (
            data?.urls?.at(0) === '' &&
            hasWriteAccess && (
              <div className='gallery-block'>
                <CreateButtonFragment
                  type={type}
                  hasAPIButton={['enterprise', 'business'].includes(
                    subscriptionPlanType
                  )}
                  buttonTitle={'Upload an image'}
                  APIButtonTitle={'Upload an image from API'}
                  buttonCount={0}
                  handleCreate={() => setCurrentTab(0)}
                  apiButtonData={data.api}
                  apiList={apiList}
                  handleAPIButtonElementCreate={handleAPIButtonElementCreate}
                  blockId={id}
                />
              </div>
            )
          ))}
        {currentTab === 0 && (
          <>
            <Button
              onClick={() => {
                setCurrentTab(-1);
                handleImageClear();
              }}
              appearance='minimal'
              intent={'success'}
              iconBefore='arrow-left'
            >
              {' '}
              Back
            </Button>
            <br />
            <br />
            <ImageUploadField
              image={image}
              hasWriteAccess={hasWriteAccess}
              handleDrop={handleImageDrop}
              handleClear={handleImageClear}
            />
          </>
        )}
      </>
    </BlockContainer>
  );
};

ImageBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
};

export default ImageBlock;
