import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../utilities/utils';
import ActionTableData from './ActionsTableData';
import Pagination from '../CommonComponent/Pagination';
import useTranslation from 'components/customHooks/useTranslation';

interface TabProps {
  id: number;
  name: string;
}

interface props {
  tableData: tableProps[];
  tabData: TabProps[];
  currentTab: TabProps;
  onTabChange: (val: TabProps) => void;
  offset: number;
  setOffset: (val: number) => void;
  setSearchKeyword: (val: any) => void;
  searchKeyword: string;
  total: number;
  limit: number;
}

interface tableProps {
  title: string;
  webhook_count: number;
  customer_count: number;
}
const ActionsReportTable: React.FC<props> = ({
  tableData,
  tabData,
  currentTab,
  onTabChange,
  offset,
  setOffset,
  setSearchKeyword,
  searchKeyword,
  total,
  limit,
}) => {
  const { t } = useTranslation();
  const handleNextButton = () => {
    setOffset(offset + limit);
  };
  const handlePreviousButton = () => {
    setOffset(offset - limit);
  };

  return (
    <div className='flex flex-col justify-between mb-3 bg-white rounded-lg shadow'>
      <div>
        <div className='flex justify-between px-4 py-5 border-b sm:p-6'>
          <div>
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                Select a tab
              </label>
              <select
                id='tabs'
                name='tabs'
                className='block w-full border-gray-300 rounded-md focus:border-primary'
                defaultValue={currentTab.name}
              >
                {tabData.map((tab: TabProps) => (
                  <option key={tab.id}>{t(tab.name)}</option>
                ))}
              </select>
            </div>
            <div className='hidden sm:block'>
              <nav
                className='relative z-0 flex divide-x divide-gray-200 rounded-lg shadow'
                aria-label='Tabs'
              >
                {tabData.map((tab: TabProps, tabIdx) => (
                  <button
                    key={tab.id}
                    onClick={() => {
                      if (tab.id !== currentTab.id) {
                        onTabChange(tab);
                      }
                    }}
                    className={classNames(
                      tab.id === currentTab.id
                        ? 'text-gray-900'
                        : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabData.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                    )}
                    aria-current={tab.id === currentTab.id ? 'page' : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden='true'
                      className={classNames(
                        tab.id === currentTab.id
                          ? 'bg-primary'
                          : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className='relative mt-1 rounded-md shadow-sm h-full'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <MagnifyingGlassIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              type='search'
              name='search'
              id='search'
              className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
              placeholder={t('Search')}
              value={searchKeyword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchKeyword(e.target.value)
              }
            />
          </div>
        </div>
        <ActionTableData tableData={tableData} />
      </div>
      {tableData?.length !== 0 && (
        <Pagination
          offset={offset}
          tableDataLength={tableData?.length}
          total={total}
          limit={limit}
          handleNextButton={handleNextButton}
          handlePreviousButton={handlePreviousButton}
        />
      )}
    </div>
  );
};

export default ActionsReportTable;
