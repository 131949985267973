import React, { useState } from 'react';
import {
  Table,
  Avatar,
  Button,
  Icon,
  IconButton,
  Dialog,
  SelectMenu,
} from 'evergreen-ui';
import defaultAvatar from '../../../../assets/images/defaultAvatar.svg';

interface props {
  roleUser: any;
  deleteAdminRole: (adminId: number) => void;
  adminGroup: any[];
  assignAdminGroup: (adminId: number, tags: any) => void;
}

const PermissionProjectRow: React.FC<props> = ({
  roleUser,
  deleteAdminRole,
  adminGroup,
  assignAdminGroup,
}) => {
  const [isDeleteShown, setIsDeleteShown] = useState(false);

  return (
    <Table.Row display={'flex'}>
      <Table.TextCell className='avatar-cell' flexBasis={'20%'}>
        <Avatar
          src={roleUser.admin?.avatar || defaultAvatar}
          name={roleUser.admin.full_name}
          className='mr-10px'
        />
        <p>{roleUser.admin.full_name}</p>
      </Table.TextCell>
      <Table.TextCell flexBasis={'20%'} className='custom-textCell'>
        {roleUser.admin.email}
      </Table.TextCell>
      <Table.TextCell flexBasis={'20%'}>
        <div className='select-menu__default'>
          <Button height={26} className='role-button'>
            <Icon icon='user' size={16} /> &nbsp;&nbsp;
            {roleUser.role.name || 'Select Role...'}
          </Button>
        </div>
      </Table.TextCell>
      <Table.TextCell flexBasis={'20%'}>
        <div className='select-menu__default'>
          <SelectMenu
            isMultiSelect
            title='Select multiple names'
            options={adminGroup.map((group: any) => {
              return { label: group.name, value: group.id + '' };
            })}
            selected={roleUser.tags?.map((group: any) => group.id + '') || []}
            onSelect={(item: any) => {
              const oldAssignData = roleUser.tags.map(
                (group: any) => group.id + ''
              );
              const temp: any = [...oldAssignData, item.value + ''];
              assignAdminGroup(
                roleUser.id,
                temp.map((elem: any) => parseInt(elem))
              );
            }}
            onDeselect={(item) => {
              const oldAssignData = roleUser.tags.map(
                (group: any) => group.id + ''
              );
              const temp: any = oldAssignData.filter(
                (id: any) => id !== item.value
              );
              assignAdminGroup(
                roleUser.id,
                temp.map((elem: any) => parseInt(elem))
              );
            }}
          >
            <Button
              height={26}
              className='role-button'
              disabled={!(roleUser.role.name.toLowerCase() === 'admin')}
              // onClick={() => setIsGroupShown(true)}
            >
              <Icon icon='add' size={16} />
              &nbsp;&nbsp; In {roleUser.tags?.length} Groups
            </Button>
          </SelectMenu>
        </div>
      </Table.TextCell>
      <Table.Cell flexBasis={'5%'}>
        {roleUser.is_deletable && (
          <IconButton
            onClick={() => setIsDeleteShown(true)}
            appearance='minimal'
            icon='cross'
            intent='danger'
          />
        )}
      </Table.Cell>
      <Dialog
        isShown={isDeleteShown}
        title='Are you sure?'
        intent='danger'
        onCloseComplete={() => setIsDeleteShown(false)}
        confirmLabel={`Delete ${roleUser.role.name}`}
        onConfirm={() => {
          deleteAdminRole(roleUser.id);
          setIsDeleteShown(false);
        }}
      >
        Do you really want to delete <strong>{roleUser.admin.full_name}</strong>{' '}
        from user role?
      </Dialog>
    </Table.Row>
  );
};

export default PermissionProjectRow;
