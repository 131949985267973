import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import React, { useState } from 'react';
import { classNames } from 'utilities/utils';

interface Props {
  type: string;
  label: string;
  error?: string;
  hidden?: boolean;
  sublabel: string;
  disable?: boolean;
  hintText?: string;
  isRequired?: boolean;
  placeHolder?: string;
  value: string | number;
  handleChange: (data: string | number) => void;
}

const TextInputWithSubLabel: React.FC<Props> = ({
  type,
  error,
  label,
  value,
  sublabel,
  hintText,
  placeHolder,
  handleChange,
  hidden = false,
  disable = false,
  isRequired = false,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className={classNames('flex-col ', hidden ? 'hidden' : 'flex')}>
      <label className='flex text-sm font-medium leading-5 mb-2 text-gray-600'>
        {t(label)}{' '}
        {isRequired && <span className='text-xs text-red-600'>*</span>}
      </label>
      {sublabel && (
        <span className='text-sm flex font-normal text-gray-500 leading-4  mb-2'>
          {t(sublabel)}
        </span>
      )}
      <div
        className={classNames(
          'flex items-center border rounded-md ltr:pr-3 rtl:pl-3 py-1 focus:border-primary',
          error ? 'border-red-500' : 'border-gray-300',
          disable ? 'bg-gray-300 cursor-not-allowed' : 'bg-white cursor-default'
        )}
      >
        <input
          type={showPassword ? 'text' : type}
          value={value}
          className={
            'border-0 rounded-md pl-3 py-0 w-full sm:text-sm without-ring bg-transparent '
          }
          placeholder={t(placeHolder ?? '')}
          onChange={(e) => handleChange(e.target.value)}
          autoComplete='off'
          disabled={disable}
        />
        {type === 'password' && (
          <button
            type='button'
            className='inset-y-0 right-2'
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeSlashIcon className='h-5 w-5 text-gray-500' />
            ) : (
              <EyeIcon className='h-5 w-5 text-gray-500' />
            )}
          </button>
        )}
      </div>
      {hintText && (
        <span className='flex text-sm font-normal text-gray-400 p-1 mb-2 rtl:text-right'>
          {t(hintText)}
        </span>
      )}
      {error && (
        <span className='flex text-sm font-medium text-red-500 p-1'>
          {t(error)}
        </span>
      )}
    </div>
  );
};

export default TextInputWithSubLabel;
