import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import {
  ExclamationCircleIcon,
  XMarkIcon,
  CheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import iceCream from '../../assets/iceCream.svg';
import { connect } from 'react-redux';
import {
  subscriptionDetailsType,
  subscriptionPlanUpdateType,
  cardInfoType,
  subscriptionPayloadType,
} from '../../interface';

import FeedBackModal from './FeedBackModal';
import { navigate } from '@reach/router';
import { plansProperty } from '../../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';

interface downGradeProps {
  isBillingAnnually: boolean;
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  subscriptionPlanUpdate: subscriptionPlanUpdateType;
  cardInfo: cardInfoType[];
  paymentMethod: string;
  createSubscription: (payload: subscriptionPayloadType) => {
    status: boolean;
    marketPlace: string;
  };
  setOpenDownGrade: (value: boolean) => void;
  fetchAvailablePlans: () => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  fetchPaymentDetails: () => void;
  fetchHistories: (teamId: number) => void;
  fetchProject: () => void;
  projects: SelectedTeamInterface[];
  setSelectedProject: (payload: SelectedTeamInterface) => void;
  fetchEstimation: (payload: number) => void;
  shopifyBilling: (payload: any) => {
    status: boolean;
    marketPlace: string;
    confirmation_url: string;
  };
}

const DownGradeModal: React.FC<downGradeProps> = ({
  isBillingAnnually,
  subscriptionDetails,
  selectedProject,
  subscriptionPlanUpdate,
  cardInfo,
  paymentMethod,
  createSubscription,
  setOpenDownGrade,
  fetchAvailablePlans,
  fetchSubscriptionDetails,
  fetchPaymentDetails,
  fetchHistories,
  fetchProject,
  projects,
  setSelectedProject,
  fetchEstimation,
  shopifyBilling,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const featureList = [
    'Unlimited Team Size',
    '1000 Free Sessions',
    'Whatsapp',
    'NLP Connection',
    'Product Interaction',
  ];
  const downGradeEffectList = [
    'Last 3 members will lose access to this team',
    'Upcoming tickets will be queued in Unassigned instead of SMART Assignments',
    'You’ll lose your CSAT report access',
  ];

  useEffect(() => {
    if (
      selectedProject.team_members > plansProperty.free_plan.max_team_member
    ) {
      setStep(5);
    }
    // eslint-disable-next-line
  }, []);

  const callDownGrade = async (
    subscriptionPayload: subscriptionPayloadType
  ) => {
    const response = await createSubscription(subscriptionPayload);
    if (response.status) {
      setTimeout(() => {
        setStep(3);
        fetchSubscriptionDetails(selectedProject.id);
        fetchAvailablePlans();
        fetchPaymentDetails();
        fetchHistories(selectedProject.id);
        fetchProject();
        fetchEstimation(selectedProject.id);
        const updatedProject = projects.filter(
          (project) => project.id === selectedProject.id
        );
        if (updatedProject.length > 0) {
          setSelectedProject(updatedProject[0]);
        }
      }, 3000);
    } else {
      setStep(4);
    }
  };

  const handleDownGrade = async () => {
    setStep(2);
    if (
      selectedProject.has_ecommerce_connected &&
      selectedProject.ecommerce_type === 'shopify' &&
      cardInfo.length === 0
    ) {
      const subscriptionShopifyPayload = {
        action: 'downgrade',
        plan: 'free',
        is_billed_annually: isBillingAnnually,
        team_members: 3,
        project_id: selectedProject.id,
        total_cost: 0,
      };

      const res = await shopifyBilling(subscriptionShopifyPayload);

      if (res.status) {
        setTimeout(() => {
          setStep(3);
          fetchSubscriptionDetails(selectedProject.id);
          fetchAvailablePlans();
          fetchPaymentDetails();
          fetchHistories(selectedProject.id);
          fetchProject();
          fetchEstimation(selectedProject.id);
          const updatedProject = projects.filter(
            (project) => project.id === selectedProject.id
          );
          if (updatedProject.length > 0) {
            setSelectedProject(updatedProject[0]);
          }
        }, 3000);
      } else {
        setStep(4);
      }
    } else {
      const subscriptionPayload = {
        action: 'downgrade',
        plan: 'free',
        is_billed_annually: isBillingAnnually,
        team_members:
          subscriptionDetails.project_billing_info?.max_team_members,
        project_id: selectedProject.id,
        total_cost: 0,
        payment_method: paymentMethod,
      };
      setTimeout(() => {
        callDownGrade(subscriptionPayload);
      }, 1500);
    }
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className='flex justify-end w-full mb-3'>
              <div>
                <XMarkIcon
                  className='w-5 h-5 text-gray-500 cursor-pointer'
                  onClick={() => setOpenDownGrade(false)}
                />
              </div>
            </div>
            <div className='flex flex-col justify-center'>
              <p className='mb-5 text-lg font-bold text-center text-gray-700'>
                {t('Downgrade to Free Plan')}
              </p>
              <div className='p-2'>
                <div className='flex items-center w-full mb-5'>
                  <p className='w-[60%] uppercase text-gray-400 font-semibold'>
                    {t('You will lose access to')}
                  </p>
                  <hr className='w-[40%] border' />
                </div>
                <div>
                  {featureList.map((feature) => {
                    return (
                      <div className='flex gap-2 mb-4'>
                        <ExclamationCircleIcon className='h-5 text-yellow-400' />
                        <p className='text-gray-500'>{t(feature)}</p>
                      </div>
                    );
                  })}
                </div>
                <div className='flex items-center w-full mb-5'>
                  <p className='w-[60%] uppercase text-gray-400 font-semibold'>
                    {t('Downgrade after effects')}
                  </p>
                  <hr className='w-[40%] border' />
                </div>
                <div>
                  {downGradeEffectList.map((feature) => {
                    return (
                      <div className='flex gap-2 mb-4'>
                        <div className='w-3 mr-4'>
                          <ExclamationCircleIcon className='w-5 h-5 text-red-400' />
                        </div>

                        <p className='text-gray-500'>{t(feature)}</p>
                      </div>
                    );
                  })}
                </div>
                <div className='p-2 text-blue-500 rounded-md bg-[#EFF6FF] mb-3 text-[12px]'>
                  {t(`Your cost will be adjusted based on your usage and any
                  remaining balance will be moved to available credits.`)}
                </div>
                <button
                  onClick={() => handleDownGrade()}
                  className='w-full p-2 text-gray-500 bg-white border border-gray-300 rounded-md'
                >
                  {t('I Understand')}
                </button>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className='flex flex-col items-center justify-center'>
              <img src={iceCream} alt='loading' className='w-44 h-44' />
              <p className='text-base font-bold text-gray-900'>
                {t('Your superpowers are going away!')}
              </p>
              <p className='mb-5 text-sm text-gray-400'>
                {t('This may take a while.')}
              </p>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className='p-[24px] flex flex-col justify-center items-center'>
              <CheckIcon className='h-10 w-10 text-green-400 bg-green-100 rounded-[50%] p-2 mb-3' />
              <p className='text-lg text-gray-900 gont-semibold'>
                {t('Successfully downgraded')}
              </p>
              <p className='mb-3 text-sm text-center text-gray-400'>
                {t(
                  'We are sorry to see you downgrade to our Free Plan. Help us improve your experience by letting us know what happened.'
                )}
              </p>
              <button
                type='reset'
                className='w-full p-2 mb-3 text-white bg-green-500 rounded-md'
                onClick={() => setStep(6)}
              >
                {t('Provide Feedback')}
              </button>
              <button
                type='reset'
                className='w-full p-2 text-gray-500 border border-gray-300 rounded-md'
                onClick={() => setOpenDownGrade(false)}
              >
                {t('Continue without Feedback')}
              </button>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className='flex justify-end w-full mb-3'>
              <div>
                <XMarkIcon
                  className='w-5 h-5 text-gray-500 cursor-pointer'
                  onClick={() => setOpenDownGrade(false)}
                />
              </div>
            </div>
            <div className='p-[24px] flex flex-col justify-center items-center'>
              <ExclamationCircleIcon className='h-10 w-10 text-red-400 bg-red-300 rounded-[50%] p-1 mb-3' />
              <p className='text-lg text-gray-900 gont-semibold'>
                {t('Downgrade unsuccessful')}
              </p>
              <div className='mb-3 text-sm text-center text-gray-400'>
                Your request to downgrade your plan is unsuccessful. Please try
                again. If the issue persists, please{' '}
                <a href='/settings/contact' className='text-blue-500'>
                  contact support
                </a>
              </div>
              <button
                type='reset'
                onClick={() => handleDownGrade()}
                className='w-full p-2 mb-3 text-gray-500 border border-gray-300 rounded-md'
              >
                Try Again
              </button>
              <button
                type='reset'
                className='w-full p-2 text-gray-500 border border-gray-300 rounded-md'
                onClick={() => setOpenDownGrade(false)}
              >
                Cancel
              </button>
            </div>
          </>
        );
      case 5:
        return (
          <div className='p-[24px] flex flex-col justify-center items-center'>
            <ExclamationTriangleIcon className='h-10 w-10 text-yellow-400 bg-yellow-100 rounded-[50%] p-2 mb-3' />
            <p className='text-lg text-gray-900 gont-semibold'>
              Remove members
            </p>
            <p className='mb-3 text-sm text-center text-gray-400'>
              Please remove a member from your team first to decrease the number
              of members from your current plan.
            </p>
            <button
              type='reset'
              className='w-full p-2 mb-3 text-white bg-yellow-500 rounded-md'
              onClick={() => navigate('/settings/team-user')}
            >
              Remove Members
            </button>
            <button
              type='reset'
              className='w-full p-2 text-gray-500 border border-gray-300 rounded-md'
              onClick={() => setOpenDownGrade(false)}
            >
              Cancel
            </button>
          </div>
        );
      case 6:
        return (
          <div className='relative'>
            <div className='flex justify-end w-full mb-3'>
              <div>
                <XMarkIcon
                  className='absolute top-0 right-0 w-5 h-5 text-gray-500 cursor-pointer'
                  onClick={() => setOpenDownGrade(false)}
                />
              </div>
              <FeedBackModal setOpenDownGrade={setOpenDownGrade} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Modal>
      <div className='p-5'>{getComponent()}</div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  userInfo: state.settings.userInfo,
  selectedProject: state.dashboard.selectedProject,
  isBillingAnnually: state.newBilling.isBillingAnnually,
  cardInfo: state.newBilling.cardInfo,
  paymentMethod: state.newBilling.paymentMethod,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  subscriptionPlanUpdate: state.newBilling.subscriptionPlanUpdate,
  projects: state.dashboard.projects,
});
const mapDispatch = (dispatch: any) => ({
  createSubscription: (payload: subscriptionPayloadType) =>
    dispatch.newBilling.createSubscription(payload),
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  fetchPaymentDetails: () => dispatch.newBilling.fetchPaymentDetails(),
  fetchHistories: (teamId: number) =>
    dispatch.newBilling.fetchHistories(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchEstimation: (payload: number) =>
    dispatch.newBilling.fetchEstimatedCost(payload),
  shopifyBilling: (payload: any) =>
    dispatch.newBilling.createSubscriptionForShopify(payload),
});

export default connect(mapState, mapDispatch)(DownGradeModal);
