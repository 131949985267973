import useTranslation from 'components/customHooks/useTranslation';
import SallaIntegrationStep2 from 'pages/integration/components/availableIntegrations/salla/SallaIntegrationStep2';
import {
  IEcommerceChannelProperty,
  LiveChatDataProps,
  channelListProps,
  webChatResponseDataProps,
} from 'pages/integration/interface';
import React from 'react';
import { CopyBlock, solarizedLight, nord } from 'react-code-blocks';
import { useSelector } from 'react-redux';

interface Props {
  platfromData: webChatResponseDataProps;
  theme?: string;
  showLineNum?: boolean;
  hasCustomStyle?: boolean;
}

const Installation: React.FC<Props> = ({
  platfromData,
  theme = 'default',
  showLineNum = true,
  hasCustomStyle = false,
}) => {
  const selectedProject = useSelector(
    (state: any) => state.dashboard.selectedProject
  );

  const integratedEcommerceData: IEcommerceChannelProperty = useSelector(
    (state: any) => state?.integration?.integratedEcommerceList
  );

  const { t } = useTranslation();

  const isSallaLiveChat = Boolean(
    integratedEcommerceData?.ecommerce_type === 'salla' &&
      (platfromData as unknown as LiveChatDataProps)
        ?.is_ecommerce_plugin_channel
  );
  const checkTheme = () => {
    if (
      theme.toLocaleLowerCase() === 'default' ||
      theme.toLocaleLowerCase() === 'light'
    ) {
      return solarizedLight;
    } else if (theme.toLocaleLowerCase() === 'dark') return nord;

    return solarizedLight;
  };

  const getCustomStyle = () => {
    if (hasCustomStyle) {
      return {
        height: '200px',
        overflowY: 'auto',
        borderRadius: '5px',
        boxShadow: '1px 2px 3px rgba(0,0,0,0.35)',
        fontSize: '0.70rem',
      };
    } else return {};
  };

  const renderDefaultInstallation = () => {
    return (
      <>
        <div className='px-5 py-4 bg-gray-100 border-b border-gray-200 flex items-center'>
          <span className='font-semibold text-base text-gray-600'>
            {t('Embed Livechat')}
          </span>
        </div>
        <div className='px-4 pt-6 pb-2'>
          <div className='flex justify-start gap-2 items-center'>
            <div className='flex justify-center items-center w-5 h-5 rounded-full text-xs bg-gray-100 text-gray-800'>
              1
            </div>

            <p className='leading-5 font-normal flex rtl:text-right text-sm text-gray-500'>
              {t(
                'Paste this code in your website footer. The code has been tested safe and works with any language.'
              )}
            </p>
          </div>
          <div className='h-[200px] mt-3' key={'c-002'}>
            <CopyBlock
              text={`// Put this code snippet inside script tag
<script> !function(){var e=document.createElement("div");e.id="myAliceWebChat";var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://livechat.myalice.ai/index.js";var a=document.body.getElementsByTagName("script");(a=a[a.length-1]).parentNode.insertBefore(t,a),a.parentNode.insertBefore(e,a),t.addEventListener("load",function(){MyAliceWebChat.init({selector:"#myAliceWebChat",platformId:"${
                platfromData?.id || ''
              }",primaryId:"${platfromData?.primary_id || ''}",token:"${
                platfromData?.access_token || ''
              }"})})}(); </script>`}
              language='javascript'
              theme={checkTheme()}
              showLineNumbers={showLineNum}
              customStyle={getCustomStyle()}
              wrapLongLines={false}
              codeBlock={false}
            />
          </div>
          <div className='flex justify-start items-center gap-2 my-4'>
            <div className='flex justify-center rtl:text-right items-center w-5 h-5 rounded-full text-xs bg-gray-100 text-gray-800'>
              2
            </div>

            <p className='leading-5 font-normal text-sm text-gray-500'>
              {t(
                'Reload your website. Livechat should appear in the bottom right corner.'
              )}
            </p>
          </div>
        </div>
      </>
    );
  };
  const renderSallaInstallation = () => {
    return (
      <div className='px-5 py-4'>
        <SallaIntegrationStep2
          platformData={platfromData as channelListProps}
          selectedProject={selectedProject}
        />
      </div>
    );
  };
  return (
    <>
      <div className='border border-gray-200 rounded-lg'>
        {isSallaLiveChat
          ? renderSallaInstallation()
          : renderDefaultInstallation()}
      </div>
    </>
  );
};
export default Installation;
