import React from 'react';
import Footer from '../../common/components/Footer';

import { Link } from '../../../../../library';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className='relative'>
      <div className='p-6'>
        <p className='text-base font-medium'>
          <Link
            url='https://intnt.ai/'
            showUnderline={false}
            openInNewTab={true}
          >
            INTNT.ai{' '}
          </Link>
          trains chatbots and voice bots to help companies deliver 24/7 customer
          support.
        </p>
        <br />
        <p className='text-base font-medium'>
          Integrating INTNT NLU will allow you to:
        </p>
        <ul className='mt-3 ml-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            Instantly connect NLP to your chatbots without creating a{' '}
            <Link
              url='https://wit.ai/'
              showUnderline={false}
              openInNewTab={true}
            >
              wit.ai{' '}
            </Link>
            app from scratch.
          </li>
          <li className='my-2'>
            {t(
              'Help your bots understand common human intents and keywords in English.'
            )}
          </li>
          <li className='my-2'>
            {t(
              'Create advanced user journeys and automate repetitive queries.'
            )}
          </li>
        </ul>
      </div>
      <Footer channelName='Intnt.ai' />
    </div>
  );
};
export default Introduction;
