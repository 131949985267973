import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'components/customHooks/useTranslation';
const SettingsHeader = ({ title, subtitle }) => {
  const { t } = useTranslation();
  return (
    <div className='settings__header'>
      <p className='settings__header-title'>{t(title)}</p>
      <p className='settings__header-subtitle'>{t(subtitle)}</p>
    </div>
  );
};

SettingsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SettingsHeader;
