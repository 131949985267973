import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAgentStats, PlatformStats } from '../interface';

const useAdmin = () => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state: any) => state.dashboard?.selectedProject
  );

  const [componentLoading, setComponentLoading] = useState({
    countApiLoading: false,
    reportApiLoading: false,
    agentApiloading: false,
  });
  const [agentStats, setAgentStats] = useState<IAgentStats | null>(null);
  const [platformReport, setPlatformReport] = useState<PlatformStats[]>([]);

  const [assignCount, setAssignCount] = useState({
    assigned: 0,
    unassigned: 0,
    unreplied: 0,
  });

  const fetchAssignmentCount = async () => {
    const res = await dispatch.dashboard.fetchAssignmentCount(
      selectedProject?.id
    );
    if (!!res) {
      setAssignCount({ ...res });
    } else {
      setAssignCount({
        assigned: 0,
        unassigned: 0,
        unreplied: 0,
      });
    }
    setComponentLoading({ ...componentLoading, countApiLoading: false });
  };

  const fetchAssignmentReport = async () => {
    const res = await dispatch.dashboard.fetchAssignmentReport(
      selectedProject?.id
    );
    if (!!res && Array.isArray(res) && res.length > 0) {
      res.forEach((data) => {
        data.platform_name =
          data?.platform_name?.length > 15
            ? data?.platform_name.substring(0, 13) + '...'
            : data?.platform_name;
      });
      setPlatformReport([...res]);
    } else {
      setPlatformReport([]);
    }
    setComponentLoading({ ...componentLoading, reportApiLoading: false });
  };

  const fetchAssignedAgent = async () => {
    const res = await dispatch.dashboard.fetchAssignedAgent(
      selectedProject?.id
    );
    if (!!res) {
      setAgentStats({ ...res });
    } else setAgentStats(null);
    setComponentLoading({ ...componentLoading, agentApiloading: false });
  };

  const apiCallOnProjectChange = async () => {
    setComponentLoading({
      countApiLoading: true,
      reportApiLoading: true,
      agentApiloading: true,
    });
    fetchAssignmentCount();
    fetchAssignmentReport();
    fetchAssignedAgent();
  };

  useEffect(() => {
    apiCallOnProjectChange();
    //eslint-disable-next-line
  }, [selectedProject?.id]);

  return {
    agentStats,
    assignCount,
    platformReport,
    selectedProject,
    componentLoading,
  };
};

export default useAdmin;
