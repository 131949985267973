import { FC, useEffect, useState } from 'react';
import {
  Button,
  Label,
  InputText,

  // Dialog,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../export';

interface Props {
  filterData: any;
  storedFilterData: any;
  openFilterTitleDialog: any;
  toggleFilterTitleDialog: any;
  filterDataIsEmpty: any;
  handleOnSaveNewFilter: any;
}

const FilterViewAddModal: FC<Props> = ({
  filterData,
  storedFilterData,
  openFilterTitleDialog,
  toggleFilterTitleDialog,
  filterDataIsEmpty,
  handleOnSaveNewFilter,
}) => {
  const [title, setTitle] = useState('');
  const [isTitleExist, setIsTitleExist] = useState<boolean>(false);

  useEffect(() => {
    setTitle(filterData?.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFilterTitleDialog]);

  const handleOnTitleChange = (e: any) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    setIsTitleExist(
      storedFilterData.some((filter: any) => filter.title === newTitle)
    );
  };

  const onSaveNewFilter = () => {
    handleOnSaveNewFilter(title);
    toggleFilterTitleDialog();
  };

  const shouldDisable = () => {
    return !title || isTitleExist;
  };

  return (
    <Dialog open={openFilterTitleDialog} onOpenChange={toggleFilterTitleDialog}>
      <DialogTrigger asChild>
        <Button
          variant='outline'
          className={`${
            filterDataIsEmpty
              ? 'text-textSecondary-disable h-8'
              : 'text-black hover:bg-background-hover h-8'
          }`}
          disabled={filterDataIsEmpty}
        >
          Create Filtered View
        </Button>
      </DialogTrigger>
      <DialogContent className='bg-white sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>Create Filtered View</DialogTitle>
          <DialogDescription>
            Enter a name to save your current filter settings.
          </DialogDescription>
        </DialogHeader>
        <div className='space-y-2'>
          <Label htmlFor='fitler-name'>
            Filtered View Name
            <span style={{ color: '#FF2323', marginLeft: '1px' }}>*</span>
          </Label>

          <InputText
            name='fitler-name'
            value={title}
            onChange={handleOnTitleChange}
            placeholder='Enter a name'
            focus
            required
            characterLimit={15}
            style={{ width: '100%' }}
            className={`${
              isTitleExist
                ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
                : 'focus:ring-primary focus:border-primary'
            } sm:text-sm`}
          />

          {isTitleExist && (
            <span className='mt-3 mb-2 text-red-600'>
              This name already exists. Please enter a different name.
            </span>
          )}
        </div>
        <DialogFooter>
          <Button
            type='submit'
            className='bg-[#FFFFFF] text-black border border-solid border-[#DFDFE2] hover:bg-[#E4E4E7]'
            onClick={toggleFilterTitleDialog}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            className='bg-[#04B25F] ml-3 text-white hover:bg-[#078E4F]'
            onClick={onSaveNewFilter}
            disabled={shouldDisable()}
          >
            Save and Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FilterViewAddModal;
