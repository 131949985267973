import React, { Fragment } from 'react';
import SettingsFormContainer from './SettingsFormContainer';
import { TextInputField, InlineAlert } from 'evergreen-ui';
import { isValidEmail } from '../../../../utilities/utils';
import ImageUploadField from '../../../../components/utilityComponent/ImageUploadField';
import useTranslation from 'components/customHooks/useTranslation';

const SettingsUserInformation = ({
  title,
  description,
  icon,
  infoLoader,
  infoUpdateLoader,
  userInfo,
  handleChange,
  handleImageChange,
  handleImageClear,
  isUserInfoChanged,
  handleSave,
  handleCancel,
}) => {
  const { t } = useTranslation();
  return (
    <SettingsFormContainer
      id='user-settings'
      title={title}
      description={description}
      icon={icon}
      hasData={!!userInfo}
      infoLoader={infoLoader}
      infoUpdateLoader={infoUpdateLoader}
      isInfoChanged={isUserInfoChanged}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      {userInfo && (
        <Fragment>
          <div className='settings-name__container'>
            <TextInputField
              className='settings-input w-100p-mr-10px'
              marginRight={10}
              label={t('First Name')}
              name={'first_name'}
              onChange={(e) =>
                e.target.value.length <= 160 &&
                handleChange(e.target.name, e.target.value)
              }
              value={userInfo.first_name}
              placeholder={t('Please provide first name of this user...')}
            />
            <TextInputField
              className='settings-input w-100p'
              label={t('Last Name')}
              name={'last_name'}
              onChange={(e) =>
                e.target.value.length <= 160 &&
                handleChange(e.target.name, e.target.value)
              }
              value={userInfo.last_name}
              placeholder={t('Please provide last name of this user...')}
            />
          </div>
          <TextInputField
            className='settings-input'
            label={t('Email Address')}
            type='email'
            name={'email'}
            hint={
              userInfo.email !== '' &&
              !isValidEmail(userInfo.email) && (
                <InlineAlert intent='warning' marginTop={10}>
                  {t('Email Address is Invalid')}
                </InlineAlert>
              )
            }
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={userInfo.email}
            placeholder={t('Please provide email address of this user...')}
          />
          <p className='input-label'>{t('User Avatar')}</p>
          <ImageUploadField
            image={userInfo.avatar}
            handleDrop={(image) => handleImageChange(image)}
            handleClear={() => handleImageClear()}
          />
        </Fragment>
      )}
    </SettingsFormContainer>
  );
};

export default SettingsUserInformation;
