import React from 'react';
import { connect } from 'react-redux';
import OrderForm from './OrderForm';
import { IAddress, IZidAddress } from 'pages/inbox/inboxInterface';
import {
  validateCountryCode,
  validateMobileNumberWithoutCountryCode,
} from 'pages/inbox/utils/functions';
import { validateEmail } from 'pages/inbox/utils/validEmail';
import Button from 'library/button';
import { SelectedTeamInterface } from '../../../../../index';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  modalHeader: React.ReactNode;
  billingInformation: IZidAddress;
  shippingInformation: IZidAddress;
  sameAsShippingAddress: boolean;
  selectedTeam: SelectedTeamInterface;
  initialCustomerBillingInformation: IAddress;
  setStep: (step: number) => void;
  setShowRightbar: (value: boolean) => void;
  updateStateData: (key: string, value: boolean) => void;
  handleSetZidPropertyInformation: (
    data: {
      key: string;
      value: any;
    }[]
  ) => void;
  handleSetZidObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const CustomerInformation: React.FC<Props> = ({
  modalHeader,
  selectedTeam,
  billingInformation,
  shippingInformation,
  sameAsShippingAddress,
  initialCustomerBillingInformation,
  setStep,
  updateStateData,
  setShowRightbar,
  handleSetZidObjectInformation,
  handleSetZidPropertyInformation,
}) => {
  const { t } = useTranslation();
  const validateReceiverInformation = (reciever: {
    email: string;
    full_name: string;
    mobile_country_code: string;
    mobile_number: string;
  }) => {
    const { full_name, mobile_number, mobile_country_code, email } = reciever;
    return (
      email &&
      full_name &&
      validateEmail(email) &&
      mobile_country_code &&
      validateCountryCode(mobile_country_code) &&
      mobile_number &&
      validateMobileNumberWithoutCountryCode(mobile_number)
    );
  };
  const validateObjectValues = (objectData: IZidAddress) => {
    const {
      full_name,
      country,
      city,
      address_one,
      email,
      mobile_number,
      reciever,
      mobile_country_code,
    } = objectData;

    const isCustomerInformationValidated =
      !!full_name &&
      !!country &&
      !!city &&
      !!address_one &&
      !!email &&
      validateEmail(email) &&
      !!mobile_country_code &&
      validateCountryCode(mobile_country_code) &&
      !!mobile_number &&
      validateMobileNumberWithoutCountryCode(mobile_number);

    return (
      isCustomerInformationValidated && validateReceiverInformation(reciever)
    );
  };
  const disableNextButton = () => {
    if (
      validateObjectValues(billingInformation) &&
      validateObjectValues(shippingInformation)
    ) {
      return false;
    } else {
      return true;
    }
  };

  //may needed in future for billing address
  // const copyShippingInfoToBillingInfoView = (
  //   copyShippingtoBilling: boolean
  // ) => {
  //   if (copyShippingtoBilling) {
  //     handleSetZidPropertyInformation([
  //       {
  //         key: 'sameAsShippingAddress',
  //         value: !sameAsShippingAddress,
  //       },
  //       {
  //         key: 'billing_address',
  //         value: shippingInformation,
  //       },
  //     ]);
  //   } else {
  //     handleSetZidPropertyInformation([
  //       {
  //         key: 'sameAsShippingAddress',
  //         value: !sameAsShippingAddress,
  //       },
  //       {
  //         key: 'billing_address',
  //         value: initialCustomerBillingInformation,
  //       },
  //     ]);
  //   }
  // };

  const handleOpenCart = () => {
    updateStateData('openZidModal', false);
    updateStateData('openModal', true);
    setShowRightbar(false);
  };

  const renderFooterButtonsView = () => {
    let conditionalCss = disableNextButton()
      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
      : 'bg-primary text-white';

    return (
      <div className='flex space-between gap-4 text-sm items-center border-t border-transparent border-t-gray-200 p-2 h-[7.5vh]'>
        <Button
          className={`w-[49%] h-[100%]  rounded-md justify-center text-gray-600`}
          intent='default'
          onClick={() => {
            handleOpenCart();
          }}
        >
          {t('View Cart')}
        </Button>

        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center hover:border-primary focus:outline-none
                    transition ease-out duration-20 ${conditionalCss}`}
          disabled={disableNextButton()}
          onClick={() => {
            setStep(2);
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderZidOrderFormView = (key: string, isBilling: boolean) => {
    return (
      <OrderForm
        key={key}
        billingInformation={billingInformation}
        shippingInformation={shippingInformation}
        isBilling={isBilling}
        selectedTeam={selectedTeam}
        handleSetZidObjectInformation={handleSetZidObjectInformation}
      />
    );
  };

  return (
    <div key={'customer-info'} className='flex flex-col h-full'>
      {modalHeader}

      <div className='h-[86vh] pt-3 create-wooCoomerce-order px-3 overflow-auto'>
        <div className='w-full'>
          {renderZidOrderFormView('shipping-form', false)}

          {/* <div className='mb-3'>
            <p className='mb-1 mt-6 capitalize text-gray-900 text-sm font-medium'>
              {t('Billing Information')}
            </p>
            <div className='flex gap-2 items-center mb-2'>
              <input
                id='check'
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-green-600
                        focus:ring-green-500 sm:left-6 cursor-pointer focus:outline-none foucs:outline-offset-0'
                name='billing_address'
                checked={sameAsShippingAddress}
                onChange={(e) => {
                  copyShippingInfoToBillingInfoView(e.target.checked);
                }}
              />
              <label htmlFor='check' className='cursor-pointer text-gray-500'>
                {t('Same as shipping information')}
              </label>
            </div>
          </div>

          {!sameAsShippingAddress && (
            <div className='mb-6'>
              {renderZidOrderFormView('billing-form', true)}
            </div>
          )} */}
        </div>
      </div>

      {renderFooterButtonsView()}
    </div>
  );
};

const mapState = (state: any) => ({
  updateStateData: state.ecommerce.openModal,
  selectedTeam: state.dashboard?.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
});

export default connect(mapState, mapDispatch)(CustomerInformation);
