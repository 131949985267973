import React from 'react';
import MethodOptions from './MethodOptions';
import {
  PaymentMethodProps,
  ShippingMethodProps
} from '../interfaces';

interface Prop {
  handleChange: (key: string, value: any) => void;
  paymentMethod: PaymentMethodProps;
  shippingMethod: ShippingMethodProps;
  shippingCost: number;
  paymentMethodList: PaymentMethodProps[];
  shippingMethodList: ShippingMethodProps[];
  currencySymbol: string;
}

const DeliveryPayment: React.FC<Prop> = ({
  paymentMethod,
  shippingMethod,
  handleChange,
  shippingCost,
  paymentMethodList,
  shippingMethodList,
  currencySymbol,
}) => {
  const DeliveryPaymentMethod = () => {
    return (
      <div className='px-6 pt-4 pb-6 mt-0 border-b'>
        <label className='block mb-4 text-sm font-medium text-gray-700'>
          Payment Method
        </label>
        <MethodOptions
          methodList={paymentMethodList}
          selectedMethod={paymentMethod}
          isEnabled={true}
          handleChange={(data) => {
            handleChange('paymentMethod', data);
          }}
        />
      </div>
    );
  };

  const DeliveryShipmentMethod = () => {
    return (
      <div className='px-6 pt-4 pb-6 mt-0 border-b'>
        <label className='block mb-4 text-sm font-medium text-gray-700'>
          Shipping Method
        </label>
        <MethodOptions
          methodList={shippingMethodList}
          selectedMethod={shippingMethod}
          isEnabled={false}
          handleChange={(data: any) => {
            handleChange('shippingMethod', data);
            handleChange('shippingCost', data?.amount);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <DeliveryPaymentMethod />
      <DeliveryShipmentMethod />
      <div className='p-6 mt-10 border-b sm:mt-0'>
        <div>
          <label
            htmlFor='price'
            className='block text-sm font-medium text-gray-700'
          >
            Shipping Cost
          </label>
          <div className='relative mt-1 rounded-md shadow-sm'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <span className='text-gray-500 sm:text-sm'>{currencySymbol}</span>
            </div>
            <input
              type='number'
              placeholder='0.00'
              value={
                !!shippingCost && !(shippingMethod?.method_id === 'free_shipping' || shippingMethod?.method_id === 'local_pickup')
                  ? shippingCost
                  : ''
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('shippingCost', e.target.value)
              }
              className='block w-full pr-12 border-gray-300 rounded-md outline-none focus:ring-primary focus:border-primary sm:w-auto pl-7 sm:text-sm'
              aria-describedby='price-currency'
              disabled={                
                shippingMethod?.method_id === 'free_shipping' || shippingMethod?.method_id === 'local_pickup'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPayment;
