import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import SequenceList from './SequenceList';

interface Props {
  selectedSequence?: { id: number; title: string };
  setSelectedSequence?: (val: any) => void;
  hasWriteAccess: boolean;
  sequences?: sequenceProps[];
  handleSelectedSequence?: (sequenceName: string, sequenceId: number) => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const SequenceDropDown: React.FC<Props> = ({
  hasWriteAccess,
  selectedSequence,
  setSelectedSequence,
  sequences,
  handleSelectedSequence,
}) => {
  return (
    <Listbox
      disabled={!hasWriteAccess}
      value={selectedSequence}
      onChange={(option: any) => {
        if (!!setSelectedSequence && !!handleSelectedSequence) {
          setSelectedSequence(option);
          handleSelectedSequence(option?.title, option?.id);
        }
      }}
    >
      {({ open }) => (
        <div className='relative mt-1'>
          <Listbox.Button className='relative w-full h-10 py-2 pl-3 pr-10 text-left text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm cursor-default cursor-pointer focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300 sm:text-sm'>
            <span className='block font-medium text-gray-700 truncate'>
              {selectedSequence?.title
                ? selectedSequence.title
                : 'Sequence if no product is found'}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <ChevronDownIcon
                className='w-5 h-5 text-gray-700'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              static
              className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            >
              {!!sequences &&
                sequences.map((val: any, index) => (
                  <SequenceList id={index} value={val} title={val.title} />
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

SequenceDropDown.defaultProps = {
  hasWriteAccess: false,
};
export default SequenceDropDown;
