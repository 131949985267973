import SingleItemComponent from './SingleItemComponent';
import { checkPermission } from '../../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import useLeftBarHook from 'pages/inbox/hooks/useLeftBarHook';

import { SelectedTeamInterface } from 'index';
import {
  ISavedFilterData,
  TicketFilterInterface,
  TicketsBadgeCountType,
} from '../../../../inboxInterface';
interface Props {
  isUserAdmin: boolean;
  selectedNumber: number;
  savedFilterList: ISavedFilterData[];
  selectedProject: SelectedTeamInterface;

  handleClosedTicketQueue: (
    queue: string,
    label: string,
    selectedNum: number
  ) => void;

  handlerFilterDataClick: (
    filterData: TicketFilterInterface,
    label: string,
    selectedNum: number
  ) => void;
  handleOnQueryChange: (
    queryString: string,
    label: string,
    selectedNum: number
  ) => void;
  ticketsBadgeCount: TicketsBadgeCountType;
}

const TicketQueryComponent: React.FC<Props> = ({
  isUserAdmin,
  selectedNumber,
  savedFilterList,
  selectedProject,
  handleOnQueryChange,
  handlerFilterDataClick,
  handleClosedTicketQueue,
  ticketsBadgeCount,
}) => {
  const { t } = useTranslation();
  const { dashboardData } = useLeftBarHook();

  const customRestrictionForDaiichi = () => {
    if (selectedProject?.id !== 4339) return true;
    return isUserAdmin;
  };

  const hasPermissionToShowUnassignedQueue = () => {
    let permissionList = dashboardData?.permission?.list || [];
    let haspermission = checkPermission(
      permissionList,
      'read:inbox_unassigned_queue'
    );
    return haspermission;
  };

  const hasPermissionToShowBotQueue = () => {
    let permissionList = dashboardData?.permission?.list || [];
    let haspermission = checkPermission(permissionList, 'read:inbox_bot_queue');
    return haspermission;
  };
  return (
    <div className='w-full bg-white rounded-md shadow-lg'>
      {/* Ticket catergory by status */}

      {customRestrictionForDaiichi() && (
        <span className='flex w-full px-3 py-1 text-xs font-semibold leading-5 text-gray-600 border-b border-gray-100'>
          {t('GENERAL')}
        </span>
      )}

      <div className='px-4 py-2'>
        {isUserAdmin && (
          <SingleItemComponent
            handleClickEvent={() => {
              handleOnQueryChange('all', 'All Assigned', 0);
            }}
            countColorClass='text-[#6B7180]'
            label={t('All Assigned')}
            count={
              ticketsBadgeCount?.all_assigned
                ? ticketsBadgeCount?.all_assigned
                : 0
            }
            isSelected={selectedNumber === 0}
            showCount={true}
          />
        )}
        {customRestrictionForDaiichi() &&
          hasPermissionToShowUnassignedQueue() && (
            <SingleItemComponent
              label={t('Unassigned')}
              countColorClass='text-[#6B7180]'
              handleClickEvent={() => {
                handleOnQueryChange('unassigned', 'Unassigned', 1);
              }}
              isSelected={selectedNumber === 1}
              showCount={true}
              count={
                ticketsBadgeCount?.unassigned
                  ? ticketsBadgeCount?.unassigned
                  : 0
              }
            />
          )}
        {isUserAdmin && (
          <SingleItemComponent
            label={t('All Closed')}
            showCount={false}
            handleClickEvent={() => {
              handleClosedTicketQueue('all', 'All Closed', 3);
            }}
            isSelected={selectedNumber === 3}
          />
        )}

        {hasPermissionToShowBotQueue() && (
          <SingleItemComponent
            label={t('Bot')}
            showCount={false}
            handleClickEvent={() => {
              handleClosedTicketQueue('bot', 'Bot', 8);
            }}
            isSelected={selectedNumber === 8}
          />
        )}
      </div>

      {/* Assigned ticket query  */}

      <span className='flex w-full px-3 py-1 text-xs font-semibold leading-5 text-gray-600 border-t border-b border-gray-100'>
        {t('MY TICKETS')}
      </span>
      <div className='px-4 py-2'>
        <SingleItemComponent
          label={t('My Assigned')}
          countColorClass='text-[#6B7180]'
          handleClickEvent={() => {
            handleOnQueryChange('self', 'My Assigned', 4);
          }}
          isSelected={selectedNumber === 4}
          showCount={true}
          count={
            ticketsBadgeCount?.my_assigned ? ticketsBadgeCount?.my_assigned : 0
          }
        />
        <SingleItemComponent
          label={t('My Closed')}
          count={99}
          handleClickEvent={() => {
            handleClosedTicketQueue('self', 'My Closed', 6);
          }}
          isSelected={selectedNumber === 6}
        />
      </div>

      {/* Saved filter data */}

      <div className={savedFilterList.length > 0 ? 'block' : 'hidden'}>
        <span className='flex w-full px-3 py-1 text-xs font-semibold leading-5 text-gray-600 border-t border-b border-gray-100'>
          {t('PRIVATE VIEWS')}
        </span>
        <div className='px-4 py-2'>
          {savedFilterList.map((item: ISavedFilterData, index: number) => {
            return (
              <div key={index}>
                <SingleItemComponent
                  key={index + 9}
                  label={item.name}
                  isSelected={selectedNumber === index + 9}
                  handleClickEvent={() => {
                    handlerFilterDataClick(item.data, item.name, index + 8);
                  }}
                  showCount={false}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TicketQueryComponent;
