import { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';

class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyCode: '',
      loading: false,
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResetVerifySubmit = async (verifyCode: string) => {
    const isAuthenticated = this.props.auth.access !== '';

    this.setState({ loading: true });
    let res = await this.props.registerVerify({
      code: verifyCode,
    });
    if (res) {
      toaster.success('Account Verified!');
      this.setState({
        verifyCode: '',
        loading: false,
      });
    }
    if (isAuthenticated) navigate('/dashboard');
    else navigate('/');
  };

  componentDidMount() {
    const [, query] = this.props.location.search.split('?code=');
    this.handleResetVerifySubmit(query);
  }

  render() {
    return null;
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  registerVerify: (payload) => dispatch.auth.registerVerify(payload),
});

export default connect(mapState, mapDispatch)(VerifyAccount);
