import React, { useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Stepper from 'pages/onboardingV2/components/common/Stepper';

import { navigate } from '@reach/router';

import Link from '../../../../../library/link';
import Footer from './Footer';
import {
  channelCreateAPiResponseType,
  channelListProps,
} from 'pages/integration/interface';
import { initialUserProperties } from 'pages/integration/utils/content';
import { toaster } from 'evergreen-ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  projectId: number;
  handleClose: () => void;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => Promise<boolean>;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const formSteps = [
  { id: 1, name: 'Select a platform' },
  { id: 2, name: 'Set a title' },
];

const EmailIntegrationSteps: React.FC<Props> = ({
  projectId,
  handleClose,
  handleChannelEdit,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [emailUserAuthCode, setEmailUserAuthCode] = React.useState('');
  const [emailChannelProperties, setEmailChannelProperties] =
    React.useState<channelListProps>(initialUserProperties);
  const [emailChannelTitle, setEmailChannelTitle] = React.useState<string>('');
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] =
    React.useState<boolean>(false);

  const getModalCustomFooter = (
    description: string,
    link: string,
    linkTitle: string
  ) => {
    return (
      <div className='flex justify-end '>
        <label
          htmlFor='remember_me'
          className='block ml-2 text-sm text-gray-900'
        >
          {t(description)}{' '}
          <Link url={link} openInNewTab={true}>
            {t(linkTitle)}
          </Link>
        </label>
      </div>
    );
  };

  const createEmailChannel = async () => {
    let payloadData = {
      title: emailChannelTitle,
      auth_code: emailUserAuthCode,
    };
    let res = await createChannelIntegrations('gmail', projectId, payloadData);
    if (res.status === 200) {
      setEmailChannelProperties(res.data.dataSource);
      setIsConfirmButtonLoading(false);
      setStep(2);
    } else {
      setIsConfirmButtonLoading(false);
      setEmailChannelProperties(initialUserProperties);
      setEmailUserAuthCode('');

      if (
        !!res?.data?.error &&
        res?.data?.error.includes('The account is already created.')
      ) {
        toaster.danger(res.data.error);
      } else {
        toaster.danger(
          t(
            'Something went wrong while creating a channel. Please login with google again!'
          )
        );
      }
    }
  };

  const handleChannelUpdate = async () => {
    let res = await handleChannelEdit(
      projectId,
      emailChannelProperties?.id,
      {
        title: emailChannelTitle,
        name: emailChannelProperties?.name,
      },
      t(`{{${emailChannelTitle}}} successfully updated`)
    );
    if (res) {
      setIsConfirmButtonLoading(false);
      handleClose();
      navigate(`/integrations/integrated`);
      toaster.success(
        t(`{{${emailChannelTitle}}} has been successfully connected`)
      );
    } else {
      setIsConfirmButtonLoading(false);
      toaster.danger(t('Something went wrong while creating this channel'));
    }
  };

  const handleConfirm = async () => {
    switch (step) {
      case 2:
        setIsConfirmButtonLoading(true);
        handleChannelUpdate();
        break;

      default:
        setIsConfirmButtonLoading(true);
        createEmailChannel();
        break;
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 1:
        handleClose();
        break;
      default:
        setStep(1);
        break;
    }
  };

  useEffect(() => {
    if (emailUserAuthCode !== '') handleConfirm();

    //eslint-disable-next-line
  }, [emailUserAuthCode]);

  return (
    <GoogleOAuthProvider
      clientId={
        !!process.env.REACT_APP_GOOGLE_CLIENT_ID
          ? process.env.REACT_APP_GOOGLE_CLIENT_ID
          : ''
      }
    >
      <div className='p-6 h-[93%] flex flex-col space-between'>
        <>
          <div>
            <div className='text-gray-900'>
              <Stepper steps={formSteps} currentStep={step} />
            </div>
            <div className='mt-6'>
              {
                {
                  1: <Step1 setEmailUserAuthCode={setEmailUserAuthCode} />,
                  2: (
                    <Step2
                      emailChannelTitle={emailChannelTitle}
                      userEmailId={
                        !!emailChannelProperties?.primary_id
                          ? emailChannelProperties?.primary_id
                          : ''
                      }
                      setEmailChannelTitle={setEmailChannelTitle}
                    />
                  ),
                }[step]
              }
            </div>
          </div>
          <div className={``}>
            <Footer
              cancelText={step === 1 ? t('Cancel') : ''}
              confirmText={step === 1 ? t('Next') : t('Complete')}
              isConfirmButtonLoading={isConfirmButtonLoading}
              disableConfirm={
                step === 1 ? emailUserAuthCode === '' : emailChannelTitle === ''
              }
              handleConfirm={handleConfirm}
              handleCancel={handleCancel}
              customFooterContent={getModalCustomFooter(
                t('Need Help?'),
                'https://docs.myalice.ai/connect-social-channels/connect-your-email',
                t('Read Our Guide')
              )}
            />
          </div>
        </>
      </div>
    </GoogleOAuthProvider>
  );
};

export default EmailIntegrationSteps;
