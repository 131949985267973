import React from 'react';
import BlockContainer from './BlockContainer';
import TitleHolderBuilder from '../elements/TitleHolderBuilder';
import Select, { components } from 'react-select';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import icon from '../../pages/builder/assets/images/icons/template.svg';

interface props {
  id: number;
  selected_template: any;
  templates: any;
  save: boolean;
  type: string;
  saveBlock: any;
  deleteBlock: any;
  sequences: any;
  copyBlock: any;
  moveBlock: any;
  saveLoader: boolean;
  hasWriteAccess: boolean;
  updateTemplate: (value: any) => void;
}

const Group = (props: any) => (
  <div>
    <components.Group {...props} />
  </div>
);

const WATemplateBlock: React.FC<props> = ({
  id,
  templates,
  selected_template,
  save,
  type,
  saveBlock,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
  updateTemplate,
}) => {
  const getInitTemplate = () => {
    let localData: any = null;
    if (!!selected_template) {
      localData = [
        { value: selected_template.template_id, label: selected_template.name },
      ];
    }
    return localData;
  };

  const getTemplateValues = () => {
    let templateList: any = [];

    if (!!templates) {
      templates.forEach((elem: any) => {
        templateList = [
          ...templateList,
          {
            ...elem,
            value: elem.id,
            label: elem.name,
          },
        ];
      });
    }

    let data = [
      {
        label: 'Approved Templates',
        options: templateList,
      },
    ];
    return data;
  };
  return (
    <BlockContainer
      id={id}
      title='WhatsApp Template'
      subtitle='Template Created in WhatsApp will be sent'
      save={save}
      isLoading={saveLoader}
      icon={icon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div className='flex flex-col'>
        <div className='rounded-md bg-yellow-50 p-4'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <ExclamationCircleIcon
                className='h-5 w-5 text-yellow-400'
                aria-hidden='true'
              />
            </div>
            <div className='ml-3'>
              <h3 className='text-sm font-medium text-yellow-800'>
                Attention needed
              </h3>
              <div className='mt-2 text-sm text-yellow-700'>
                <p>
                  If you have variable in your message template, make sure to
                  use "Saved Attributes" to fillup those variables. Otherwise
                  the message template will be falied to deliver.
                </p>
              </div>
            </div>
          </div>
        </div>

        <TitleHolderBuilder
          title={'Select Template'}
          tooltipInfo={'Select the template you want to send'}
        />

        <Select
          className='rules-select'
          isSearchable={true}
          value={getInitTemplate()}
          classNamePrefix='rules-select'
          components={{ Group }}
          onChange={(option: any) => {
            updateTemplate(option);
          }}
          placeholder={'Select Template'}
          options={getTemplateValues()}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#eebb4d4d',
              primary: '#eebb4d',
              primary50: '#eebb4d',
            },
          })}
        />
      </div>
    </BlockContainer>
  );
};

WATemplateBlock.defaultProps = {
  deleteBlock: (blockId: number, SequenceId: number) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default WATemplateBlock;
