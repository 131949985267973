import React from 'react';
import NotFound from '../../old/pages/404/NotFound';
import { connect } from 'react-redux';
import LoaderLine from '../../old/components/loaders/LoaderLine';

const HasPermission = ({
  children,
  shortCode,
  permissionList,
  hasDashboardView,
  isSettingsView,
  loader,
  navIsOpen,
}) => {
  if (
    permissionList.filter((permission) => permission.short_code === shortCode)
      .length > 0
  ) {
    return loader ? (
      <div className='h-100vh'>
        <LoaderLine />
      </div>
    ) : (
      children
    );
  }
  return loader ? (
    <div className='h-100vh'>
      <LoaderLine />
    </div>
  ) : (
    <NotFound
      isDashboardView={hasDashboardView}
      isSettingsView={isSettingsView}
      navIsOpen={navIsOpen}
    />
  );
};

HasPermission.defaultProps = {
  hasDashboardView: true,
  isSettingsView: false,
};

const mapState = (state) => ({
  permissionList: state.dashboard.permission?.list || [],
  loader: state.loading.effects.dashboard.fetchRolePermission,
  navIsOpen: state.dashboard.navIsOpen,
});

const HasPermissionContainer = connect(mapState)(HasPermission);

export default HasPermissionContainer;
