import config from 'utilities/config';
import axios from 'utilities/httpClient';

const initialState = {
  savedReplies: [],
  currentSavedRepliesAction: 'CREATE',
  channelList: [],
  datalabFormList: [],
};

export const settingsV2 = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      return { ...state, [payload.key]: payload.value };
    },
    updateSavedReplies(state, payload) {
      return { ...state, savedReplies: payload };
    },
    updateChannelList(state, payload) {
      return { ...state, channelList: payload };
    },
    updateDatalabFormList(state, payload) {
      return { ...state, datalabFormList: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchSavedReplies(teamId) {
      try {
        const res = await axios.get(config.settings.cannedResponse(teamId));
        if (res.status === 200) {
          dispatch.settingsV2.updateSavedReplies(res.data.dataSource);
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    async createNewSavedReply(payload, rootState) {
      try {
        const res = await axios.post(
          config.settings.cannedResponse(payload.projectId),
          payload.data
        );
        // insert newly created saved reply to state
        dispatch.settingsV2.updateSavedReplies([
          res?.data?.dataSource,
          ...rootState?.settingsV2?.savedReplies,
        ]);
        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async deleteSavedReplies(payload, rootState) {
      const selectedSavedReplies = payload?.selectedSavedReplies || [];
      try {
        const res = await axios.delete(
          config.settings.bulkDeleteCannedResponse(payload.projectId),
          {
            data: {
              ids: selectedSavedReplies,
            },
          }
        );
        // remove saved reply from state
        dispatch.settingsV2.updateSavedReplies([
          ...rootState?.settingsV2?.savedReplies?.filter(
            (r) => !selectedSavedReplies.includes(r.id)
          ),
        ]);

        return {
          success: true,
          data: res.data,
        };
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },
    async editSavedReply(payload, rootState) {
      try {
        const res = await axios.patch(
          config.settings.editCannedResponse(
            payload.projectId,
            payload.cannedResponseId
          ),
          payload.data
        );
        if (res.data.success) {
          dispatch.settingsV2.updateSavedReplies([
            ...rootState?.settingsV2?.savedReplies?.map((r) =>
              r.id === payload.cannedResponseId ? res.data.dataSource : r
            ),
          ]);

          return {
            success: true,
            data: res.data,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err.response.data,
        };
      }
    },

    async fetchChannelList(teamId) {
      try {
        const res = await axios.get(
          config.integration.getIntegratedChannelList(teamId)
        );
        if (res.status === 200) {
          dispatch.settingsV2.updateChannelList(res.data.dataSource);
          if (res?.data?.dataSource) return res.data.dataSource.length !== 0;
        }
      } catch (err) {
        console.error(err?.response?.data?.error || '');
        dispatch.inbox.updateIntegratedChannelList([]);
        return false;
      }
    },

    async fetchDatalabFormList(teamId) {
      try {
        const res = await axios.get(
          config.inboxDatalab.datalabList(teamId, 'stable')
        );
        if (res.status === 200) {
          dispatch.settingsV2.updateDatalabFormList(res.data.dataSource);

          return { success: true, data: res.data.dataSource };
        }
        return { success: false, data: [] };
      } catch (err) {
        console.error(err?.response?.data?.error || '');
        return { success: false, data: [] };
      }
    },
  }),
};

export default settingsV2;
