import {
  LiveChatDataProps,
  SurveyDataEntity,
} from 'pages/integration/interface';
import AttributeInputField from '../../common/AttributeInputField';
import DropDown from '../../common/DropDownWithButton';
import TextAreaWithEmoji from '../../common/TextAreaWithEmoji';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { visibilityOption } from '../../../utils/constent';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  liveChatData: LiveChatDataProps;
  updateLiveChatProperty: (key: string, value: any) => void;
}

const getSurveyTypeOptionName = (value: string) => {
  const selectedItem = visibilityOption.filter((data) => data.value === value);
  return selectedItem[0].name;
};

const PreChatSurvey: React.FC<Props> = ({
  liveChatData,
  updateLiveChatProperty,
}) => {
  const { t } = useTranslation();
  const addNewAttribute = () => {
    if (
      liveChatData.livechat_data.prechat_survey_data_points &&
      liveChatData.livechat_data.prechat_survey_data_points?.length < 3
    ) {
      let dataList: SurveyDataEntity[] = liveChatData.livechat_data
        .prechat_survey_data_points
        ? [...liveChatData.livechat_data.prechat_survey_data_points]
        : [];
      dataList.push({ title: '', attribute: '' });
      updateLiveChatProperty('prechat_survey_data_points', [...dataList]);
    }
  };

  const handleAttributeRemove = (index: number) => {
    let dataList: SurveyDataEntity[] = liveChatData.livechat_data
      .prechat_survey_data_points
      ? [...liveChatData.livechat_data.prechat_survey_data_points]
      : [];

    dataList.splice(index, 1);
    updateLiveChatProperty('prechat_survey_data_points', [...dataList]);
  };

  const handleDataChange = (data: SurveyDataEntity, index: number) => {
    let dataList: SurveyDataEntity[] = liveChatData.livechat_data
      .prechat_survey_data_points
      ? [...liveChatData.livechat_data.prechat_survey_data_points]
      : [];
    dataList[index] = { ...data };
    updateLiveChatProperty('prechat_survey_data_points', [...dataList]);
  };
  return (
    <>
      <div className='py-2'>
        <span className='text-sm flex font-medium leading-5  text-gray-700'>
          {t('Enable pre-chat survey')}
        </span>

        <div className='mt-4 w-full'>
          <DropDown
            classes='w-full flex items-center  text-gray-600 hover:text-gray-600 focus:outline-none'
            align='left-0'
            width='w-full'
            isFullWidth={true}
            buttonComponent={
              <div className='border w-full border-gray-300 rounded-md rtl:text-right ltr:pl-3 rtl:pr-3 ltr:pr-10 py-2 text-left focus:outline-none focus:border-gray-300 sm:text-sm'>
                {t(
                  getSurveyTypeOptionName(
                    liveChatData.livechat_data.prechat_survey_visibility_option
                  )
                )}
                <span className='absolute inset-y-0 ltr:right-0 rtl:left-2 flex items-center pr-2 pointer-events-none'>
                  <ChevronUpDownIcon
                    className='w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </div>
            }
            options={visibilityOption}
            handleDataSelection={(data: any) => {
              updateLiveChatProperty(
                'prechat_survey_visibility_option',
                data.value
              );
            }}
            value={liveChatData.livechat_data.prechat_survey_visibility_option}
          />
        </div>
      </div>

      <div className='py-2'>
        <span className='text-sm  flex font-medium leading-5  text-gray-700'>
          {t('Survey Message')}
        </span>

        <div className='mt-4'>
          <TextAreaWithEmoji
            limit={180}
            value={liveChatData.livechat_data.prechat_survey_message}
            handleOnChange={(val: string) => {
              updateLiveChatProperty('prechat_survey_message', val);
            }}
          />
        </div>
      </div>

      <div className='py-2'>
        <span className='text-sm flex font-medium leading-5  text-gray-700'>
          {t('Survey Fields')}
        </span>

        <div className='mt-4 rtl:text-right'>
          {liveChatData.livechat_data.prechat_survey_data_points?.map(
            (data: SurveyDataEntity, index: number) => {
              return (
                <div className='mb-4' key={index}>
                  <AttributeInputField
                    title={t(data.title)}
                    attribute={data.attribute}
                    handleRemove={() => {
                      handleAttributeRemove(index);
                    }}
                    handleOnChange={(val: SurveyDataEntity) => {
                      handleDataChange(val, index);
                    }}
                  />
                </div>
              );
            }
          )}

          {liveChatData.livechat_data.prechat_survey_data_points?.length <
            3 && (
            <span
              className='text-sm font-medium text-blue-400 cursor-pointer'
              onClick={() => {
                addNewAttribute();
              }}
            >
              {t('Add more entries')}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PreChatSurvey;
