import React from 'react';
import { Icon } from 'evergreen-ui';
import AvatarComponent from './AvatarComponent';

interface tagProps {
  value: any;
  label: string;
  avatar?: string;
  status?: string;
}

interface Prop {
  data: tagProps;
  intent?: 'default' | 'success' | 'danger' | 'active';
  size?: number;
  isClosable?: boolean;
  handleOnClose?: (tag: any) => void;
  hasAvatar?: boolean;
  isBold?: boolean;
}

const TagRedesign: React.FC<Prop> = ({
  data,
  intent = 'default',
  size = 14,
  isClosable = false,
  handleOnClose,
  hasAvatar = false,
  isBold = false,
}) => {
  return (
    <div
      className={`alice-tag alice-tag-${intent}`}
      style={isBold ? { fontSize: size, fontWeight: 500 } : { fontSize: size }}
    >
      {hasAvatar && (
        <>
          <AvatarComponent
            src={data?.avatar}
            size='w-5 h-5'
            showStatus={true}
            statusSize='w-2 h-2'
            statusColor={
              data?.status === 'online'
                ? 'bg-green-600'
                : data?.status === 'away'
                ? 'bg-yellow-600'
                : 'bg-gray-400'
            }
          />
          &nbsp;
        </>
      )}
      {data.label}
      {isClosable && (
        <Icon
          marginLeft={3}
          onClick={() => {
            !!handleOnClose && handleOnClose(data);
          }}
          icon='cross'
        />
      )}
    </div>
  );
};

export default TagRedesign;
