import { Skeleton } from 'libraryV2/ui/skeleton';

const LoaderLine = () => (
  <div className='flex flex-col space-y-3'>
    <div className='space-y-2'>
      {[...Array(5)].map((_, index) => (
        <Skeleton
          key={index}
          className='bg-gray-200'
          style={{ height: '52px', width: '580px' }}
        />
      ))}
    </div>
  </div>
);

export default LoaderLine;
