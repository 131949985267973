import React from 'react';
import OrderCardProductElement from './OrderCardProductElement';
import { connect } from 'react-redux';
import noDataFound from '../../../assets/images/no-data-found.svg';

interface Prop {
  products: ProductDataProps[];
  productURL?: string;
  fetchProducts: (
    productURL: string,
    products: ProductDataProps[]
  ) => Promise<any>;
  currencySymbol: string;
}
interface ProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

const OrderCardProduct: React.FC<Prop> = ({
  products,
  productURL,
  fetchProducts,
  currencySymbol,
}) => {
  const [showProducts, setShowProducts] = React.useState(false);

  const fetchEcommerceProducts = async () => {
    if (!!productURL) {
      const res = await fetchProducts(productURL, products);
      if (res) setShowProducts(true);
    }
  };

  React.useEffect(() => {
    fetchEcommerceProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='order-right-bar__order-card__product'>
      {products.length === 0 && (
        <div className='flex justify-center align-center h-100p flex-column'>
          <img src={noDataFound} alt='no data' width='80px' />
          <p className='description'>No Products found!</p>
        </div>
      )}
      {showProducts &&
        products.length > 0 &&
        products.map((product: any, index: number) => (
          <OrderCardProductElement
            key={index}
            {...product}
            metaData={product.meta_data}
            currencySymbol={currencySymbol}
          />
        ))}
      {!productURL &&
        products.length > 0 &&
        products.map((product: any, index: number) => (
          <OrderCardProductElement
            key={index}
            {...product}
            metaData={product.meta_data}
            currencySymbol={currencySymbol}
          />
        ))}
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  fetchProducts: (productURL: string, products: ProductDataProps[]) =>
    dispatch.crmEcommerce.fetchProducts({
      productURL,
      products,
    }),
});

const OrderCardProductContainer = connect(
  mapState,
  mapDispatch
)(OrderCardProduct);

export default OrderCardProductContainer;
