import React from 'react';
import PropTypes from 'prop-types';

const TitleHolderBuilder = ({ title, tooltipInfo }) => {
  return (
    <div className='flex items-center pt-2 mt-2 mb-2'>
      <div>{title}</div>&nbsp;&nbsp;
    </div>
  );
};

TitleHolderBuilder.propTypes = {
  title: PropTypes.string,
  tooltipInfo: PropTypes.string,
};
export default TitleHolderBuilder;
