import { Component } from 'react';
import bg from './../../assets/images/authOnboardBG.svg';
import AuthHeader from './components/AuthHeader';
import ExpoRegisterForm from './components/ExpoRegisterForm';

import { connect } from 'react-redux';
// import { Redirect } from '@reach/router';
import { PartnerDomainList } from 'components/customHooks/usePartner';
import { authData } from '../../utilities/content';
import { getQueryToken, isValidEmail } from '../../utilities/utils';

class ExpoRegisterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: new URLSearchParams(window.location.search).get('email', ''),
      phone: '',
      partner: '',
      password: '',
      fullName: '',
      businessUrl: '',
      errorMessage: '',
      loading: false,
      isInvited: false,
      invitationToken: '',
      isInvalidEmail: false,
      isFullNameEmpty: false,
      isDuplicateEmail: false,
      isInvalidPassword: false,
      isPhoneEmpty: false,
      selectedCountry: null,
    };
  }

  // Trigger this when someone signs up
  async componentDidMount() {
    //checking for partner domain
    const partnerDomain = window.location.hostname;
    if (PartnerDomainList.includes(partnerDomain)) {
      this.setState({ partner: partnerDomain });
    }

    //checking if user came from invitation
    const invitationToken = getQueryToken('invitation_token');
    if (invitationToken.length !== 0) {
      const { validateInvitationToken } = this.props;
      const validationResponse = await validateInvitationToken(invitationToken);

      this.setState({
        isInvited: !!validationResponse ? true : false,
        email: !!validationResponse ? validationResponse.email : '',
        invitationToken: !!validationResponse ? validationResponse.token : '',
      });
    }

    // get geolocation
    this.props.fetchGeolocation();
  }

  handleChange = (key, value) => {
    if (key === 'email') value = value.toLowerCase();
    this.setState({
      [key]: value,
      isInvalidEmail: false,
      isInvalidPassword: false,
      isDuplicateEmail: false,
      isFullNameEmpty: false,
      isPhoneEmpty: false,
    });
  };

  checkValidUrl = (str: string) => {
    let givenUrl = str;
    if (givenUrl && givenUrl.includes('https://')) {
      givenUrl = str.replace('https://', '');
    }
    if (givenUrl && givenUrl.includes('http://')) {
      givenUrl = str.replace('http://', '');
    }
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    return !!pattern.test(givenUrl);
  };

  validateRegisterData = () => {
    const { email, password, fullName, phone } = this.state;
    if (fullName.length === 0) {
      this.setState({
        errorMessage: 'Please provide your full name',
        isFullNameEmpty: true,
      });
      return false;
    }
    if (!isValidEmail(email)) {
      this.setState({
        errorMessage: 'Please provide a valid email',
        isInvalidEmail: true,
      });
      return false;
    }
    if (phone.length === 0) {
      this.setState({
        errorMessage: 'Please provide your phone number',
        isPhoneEmpty: true,
      });
      return false;
    }
    if (password.length < 6) {
      this.setState({
        errorMessage: 'Your password should be at least 6 characters long',
        isInvalidPassword: true,
      });
      return false;
    }
    if (password.length > 20) {
      this.setState({
        errorMessage: 'Your password can be at most 20 characters long',
        isInvalidPassword: true,
      });
      return false;
    }
    return true;
  };

  handleRegisterLogin = async () => {
    const { login } = this.props;
    let res = await login({
      username: this.state.email,
      password: this.state.password,
    });
    if (res?.status === 200) {
      this.handleAplitudeAndRedirection();
    }
  };

  handleRegisterSubmit = () => {
    if (!this.validateRegisterData()) return;
    this.setState({ loading: true });
    this.props
      .register({
        phone: this.state.phone,
        email: this.state.email,
        username: this.state.email,
        full_name: this.state.fullName,
        city: this.props.auth?.city,
        partner: 'expo',
        password: this.state.password,
        is_invited: this.state.isInvited,
        business_url: this.state.businessUrl,
        invitation_token: this.state.invitationToken,
        country: this.props.auth?.country,
        geolocation: this.props.auth?.geolocation,
        has_completed_onboarding: true,
      })
      .then(async (success) => {
        if (success) {
          window.location.replace('https://www.myalice.ai/expo');
        } else {
          this.setState({ loading: false, isDuplicateEmail: true });
        }
      });
  };

  getRegisterForm = () => {
    return (
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className='flex flex-col items-center justify-start w-screen h-screen overflow-x-auto bg-cover bg-cente sm:justify-center'
      >
        <div className='flex flex-col justify-center h-screen px-10 py-8 bg-white rounded shadow sm:w-full sm:max-w-md sm:block sm:h-auto'>
          <AuthHeader
            title={authData.register.title}
            subtitle={'Or'}
            link={authData.loginInfo.link}
            linkText={'login to your account here'}
            partnerDomainName={this.state.partner}
          />
          <ExpoRegisterForm
            {...this.state}
            content={authData}
            loading={this.state.loading}
            handleChange={this.handleChange}
            handleSubmit={this.handleRegisterSubmit}
            disableEmail={this.state.isInvited && this.state.email.length !== 0}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.getRegisterForm();
  }
}

const mapState = (state: any) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch: any) => ({
  login: (payload: any) => dispatch.auth.login(payload),
  register: (payload: any) => dispatch.auth.registerV2(payload),
  fetchGeolocation: () => dispatch.auth.fetchGeolocation(),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  acceptTeamInvitation: (token: string) =>
    dispatch.auth.acceptTeamInvitation(token),
  updateOnboardingCompletationStep: (payload: any) =>
    dispatch.onboardingProgress.updateOnboardingCompletationStep(payload),
  validateInvitationToken: (token: string) =>
    dispatch.auth.validateInvitationToken(token),
  getCountryData: () => dispatch.auth.getCountryData(),
});

export default connect(mapState, mapDispatch)(ExpoRegisterIndex);
