import { Textarea } from 'libraryV2/ui/textarea';
import { FC } from 'react';
const MAX_NOTE_TEXT_LENGTH = 160;
interface OrderNoteProps {
  handleNoteChange: (noteText: string) => void;
  noteValue: string;
}

export const OrderNote: FC<OrderNoteProps> = ({
  noteValue,
  handleNoteChange,
}) => {
  return (
    <div
      className={
        'relative border border-gray-300 shadow-sm rounded-md focus-within:border-transparent ring-inset focus-within:ring-1 focus-within:ring-green-500 px-2'
      }
    >
      <Textarea
        name='text-input-name'
        placeholder={'Type your message here'}
        maxLength={MAX_NOTE_TEXT_LENGTH}
        value={noteValue}
        rows={3}
        className={`resize-y text-sm text-gray-700 p-0 font-normal border-0 border-none shadow-none rounded w-full focus:outline-none focus:ring-0`}
        onChange={(e) => handleNoteChange(e.target.value)}
      />
      <div className='h-6 flex gap-1 items-center w-full justify-end my-1 mb-1.5'>
        <div className={`rounded-md p-1 text-xs bg-green-100 text-green-800`}>
          {noteValue?.length} /{MAX_NOTE_TEXT_LENGTH}
        </div>
      </div>
    </div>
  );
};

export default OrderNote;
