import React from 'react';

interface Prop {
  fillColor?: string;
  width?: number;
  height?: number;
}

const PinIcon: React.FC<Prop> = ({
  fillColor = '#9CA3AF',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.8125 10.5469L3.35417 13.0728'
        stroke='#A1A1AA'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.27637 8.50509L8.34891 12.5884L8.86224 11.6993C8.97067 11.5113 9.02449 11.2967 9.01766 11.0798C9.01083 10.8628 8.94361 10.6521 8.82357 10.4713L8.18685 9.49745C8.06681 9.31662 7.9996 9.10588 7.99277 8.88894C7.98594 8.672 8.03976 8.45745 8.14818 8.26942L9.53652 5.86476L10.0417 6.15643C10.3097 6.31114 10.6281 6.35306 10.927 6.27298C11.2259 6.19289 11.4807 5.99736 11.6354 5.7294C11.7901 5.46143 11.832 5.14299 11.7519 4.84411C11.6719 4.54523 11.4763 4.29041 11.2084 4.1357L7.16691 1.80237C6.89895 1.64766 6.5805 1.60573 6.28162 1.68582C5.98275 1.7659 5.72793 1.96143 5.57322 2.2294C5.41851 2.49736 5.37658 2.81581 5.45666 3.11469C5.53675 3.41356 5.73228 3.66838 6.00025 3.82309L6.50543 4.11476L5.11709 6.51942C5.00847 6.70734 4.84958 6.86122 4.65829 6.96378C4.46699 7.06633 4.25088 7.11349 4.03426 7.09995L2.87254 7.03545C2.65591 7.02191 2.4398 7.06906 2.24851 7.17162C2.05722 7.27417 1.89832 7.42806 1.7897 7.61597L1.27637 8.50509Z'
        stroke='#A1A1AA'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PinIconFilled: React.FC<Prop> = ({
  fillColor = '#F59E0B',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.8125 10.5469L3.35417 13.0728'
        stroke={fillColor}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.27637 8.50509L8.34891 12.5884L8.86224 11.6993C8.97067 11.5113 9.02449 11.2967 9.01766 11.0798C9.01083 10.8628 8.94361 10.6521 8.82357 10.4713L8.18685 9.49745C8.06681 9.31662 7.9996 9.10588 7.99277 8.88894C7.98594 8.672 8.03976 8.45745 8.14818 8.26942L9.53652 5.86476L10.0417 6.15643C10.3097 6.31114 10.6281 6.35306 10.927 6.27298C11.2259 6.19289 11.4807 5.99736 11.6354 5.7294C11.7901 5.46143 11.832 5.14299 11.7519 4.84411C11.6719 4.54523 11.4763 4.29041 11.2084 4.1357L7.16691 1.80237C6.89895 1.64766 6.5805 1.60573 6.28162 1.68582C5.98275 1.7659 5.72793 1.96143 5.57322 2.2294C5.41851 2.49736 5.37658 2.81581 5.45666 3.11469C5.53675 3.41356 5.73228 3.66838 6.00025 3.82309L6.50543 4.11476L5.11709 6.51942C5.00847 6.70734 4.84958 6.86122 4.65829 6.96378C4.46699 7.06633 4.25088 7.11349 4.03426 7.09995L2.87254 7.03545C2.65591 7.02191 2.4398 7.06906 2.24851 7.17162C2.05722 7.27417 1.89832 7.42806 1.7897 7.61597L1.27637 8.50509Z'
        stroke={fillColor}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PinIconOnLoad: React.FC<Prop> = ({
  fillColor = '#D97706',
  width = 20,
  height = 20,
}) => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.8125 10.5469L3.35417 13.0728'
        stroke={fillColor}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.27637 8.50509L8.34891 12.5884L8.86224 11.6993C8.97067 11.5113 9.02449 11.2967 9.01766 11.0798C9.01083 10.8628 8.94361 10.6521 8.82357 10.4713L8.18685 9.49745C8.06681 9.31662 7.9996 9.10588 7.99277 8.88894C7.98594 8.672 8.03976 8.45745 8.14818 8.26942L9.53652 5.86476L10.0417 6.15643C10.3097 6.31114 10.6281 6.35306 10.927 6.27298C11.2259 6.19289 11.4807 5.99736 11.6354 5.7294C11.7901 5.46143 11.832 5.14299 11.7519 4.84411C11.6719 4.54523 11.4763 4.29041 11.2084 4.1357L7.16691 1.80237C6.89895 1.64766 6.5805 1.60573 6.28162 1.68582C5.98275 1.7659 5.72793 1.96143 5.57322 2.2294C5.41851 2.49736 5.37658 2.81581 5.45666 3.11469C5.53675 3.41356 5.73228 3.66838 6.00025 3.82309L6.50543 4.11476L5.11709 6.51942C5.00847 6.70734 4.84958 6.86122 4.65829 6.96378C4.46699 7.06633 4.25088 7.11349 4.03426 7.09995L2.87254 7.03545C2.65591 7.02191 2.4398 7.06906 2.24851 7.17162C2.05722 7.27417 1.89832 7.42806 1.7897 7.61597L1.27637 8.50509Z'
        stroke={fillColor}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PinIcon;
