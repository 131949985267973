import React, { Fragment } from 'react';
import { TextInput } from 'evergreen-ui';
import TitleHolderBuilder from './TitleHolderBuilder';

interface props {
  value: any;
  handleChange: (event: any) => void;
  title: any;
  tooltip: string;
  type?: string;
}

const InputLabel: React.FC<props> = ({
  value,
  handleChange,
  title,
  tooltip,
  type,
}) => {
  return (
    <Fragment>
      <TitleHolderBuilder title={title} tooltipInfo={tooltip} />
      <TextInput
        type={!!type ? type : 'text'}
        value={!!value ? value : ''}
        width={'100%'}
        height={40}
        className='custom-input-style'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
        placeholder='Provide value...'
      />
    </Fragment>
  );
};

export default InputLabel;
