import React from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Menu } from 'library/menu/Menu';
import {
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  selected: TimezoneDetailsProps;
  options: TimezoneDetailsProps[];
  handleChange: (data: any) => void;
}

interface TimezoneDetailsProps {
  timezone_alias: string;
  timezone_offset_type: string;
  timezone_offset: string;
  city: string;
}

const TimezoneOptionsDropdown: React.FC<Props> = ({
  selected,
  options,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [timezoneSearch, setTimezoneSearch] = React.useState('');
  const [selectedTimezone, setSelectedTimezone] = React.useState({
    timezone_alias: selected?.timezone_alias || 'Asia/Singapore',
    timezone_offset_type: selected?.timezone_offset_type || 'UTC ',
    timezone_offset: selected?.timezone_offset || '+8:00',
    city: '',
  });

  const [toggleDropdown, setToggleDropdown] = React.useState(false);

  React.useEffect(() => {
    setSelectedTimezone({
      timezone_alias: selected?.timezone_alias || 'Asia/Singapore',
      timezone_offset_type: selected?.timezone_offset_type || 'UTC ',
      timezone_offset: selected?.timezone_offset || '+8:00',
      city: '',
    });
  }, [selected]);

  const renderNoTimeZoneFound = () => {
    return (
      options.filter((elemFilter) =>
        elemFilter?.timezone_alias
          .toLowerCase()
          .includes(timezoneSearch.trim().toLowerCase())
      ).length === 0 && (
        <div className='flex items-center justify-center p-2 mt-2 text-xs font-medium text-gray-600 rounded-md cursor-default'>
          <ExclamationTriangleIcon className='w-4 h-4' />
          <span className='ltr:ml-2 rtl:mr-2'>{t('Not Found')}</span>
        </div>
      )
    );
  };

  const renderSearchMoudle = () => {
    return (
      <div className='sticky flex py-2 bg-white -top-px'>
        <div className='relative w-full border-gray-300 rounded-md shadow-sm'>
          <div className='absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center w-full ltr:pl-3 rtl:pr-3 pointer-events-none'>
            <MagnifyingGlassIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          </div>
          <input
            type='search'
            value={timezoneSearch}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTimezoneSearch(e.target.value);
            }}
            className='block w-full ltr:pl-10 rtl:pr-10 border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500 sm:text-sm'
            placeholder={t('Search Your Timezone')}
          />
        </div>
      </div>
    );
  };
  const renderDropdown = (options: TimezoneDetailsProps[]) => {
    return (
      <Menu>
        {options
          .filter((elemFilter) =>
            elemFilter?.timezone_alias
              .toLowerCase()
              .includes(timezoneSearch.trim().toLowerCase())
          )
          ?.map((timezoneOption: TimezoneDetailsProps) => {
            return (
              <div
                data-value={timezoneOption}
                id={timezoneOption.timezone_alias}
                onClick={() => {
                  setSelectedTimezone(timezoneOption);
                  setToggleDropdown(!toggleDropdown);
                  handleChange(timezoneOption);
                }}
                className='p-3 text-left cursor-pointer hover:bg-primary hover:text-white hover:font-semibold'
              >
                {timezoneOption.timezone_offset_type +
                  ' ' +
                  timezoneOption.timezone_offset +
                  ' - ' +
                  timezoneOption.timezone_alias}
              </div>
            );
          })}
        {renderNoTimeZoneFound()}
      </Menu>
    );
  };
  return (
    <div className='relative'>
      <p className='block text-sm text-gray-300'>
        {t('Your e-commerce related data will have this timezone by default.')}
      </p>
      <button
        className='relative w-9/12 p-3 h-11 mt-1 border rounded-md shadow-sm focus:border-primary focus:ring-1 focus:ring-primary ltr:text-left rtl:text-right'
        onClick={() => setToggleDropdown(!toggleDropdown)}
      >
        <p>
          {selectedTimezone.timezone_offset_type}{' '}
          {selectedTimezone.timezone_offset} - {selectedTimezone.timezone_alias}
        </p>
        <span className='absolute ltr:right-[10px] rtl:left-[10px] top-[11px]'>
          <ChevronUpDownIcon className='w-5 h-5' aria-hidden='true' />
        </span>
      </button>
      {toggleDropdown && (
        <div className='bg-white absolute w-9/12 border rounded-md shadow-sm mt-1'>
          <div className='p-1 sticky border-white rounded-md'>
            {renderSearchMoudle()}
          </div>
          <div className='relative z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {renderDropdown(options)}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimezoneOptionsDropdown;
