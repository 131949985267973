import React from 'react';
import LabFieldInput from './LabFieldInput';

interface Props {
  data: dataProps;
  hasWriteAccess: boolean;
  attributeList: string[];
  showAttributeField: number | null;
  setShowAttributeField: (val: any) => void;
  handleFieldAttributeChange: (id: number, value: string) => void;
}

interface dataProps {
  mappings: mappingProps[];
  lab: { id: number; title: string };
  lab_id: number;
}

interface mappingProps {
  id: number;
  name: string;
  attribute: string;
}

const DataLabBlockFieldInput: React.FC<Props> = ({
  data,
  hasWriteAccess,
  handleFieldAttributeChange,
  attributeList,
  showAttributeField,
  setShowAttributeField,
}) => {
  return (
    <>
      <h3 className='mb-2.5 font-medium text-gray-700 text-sm'>Keys</h3>
      <div className='flex'>
        <h3 className='flex-1 text-xs font-medium text-gray-500'>Column</h3>
        <h3 className='flex-1 font-medium ml-3.5 text-gray-500 text-xs'>
          Value
        </h3>
      </div>
      {data.mappings.map((val: any) => (
        <LabFieldInput
          id={val.id}
          name={val.name}
          attribute={val.attribute}
          hasWriteAccess={hasWriteAccess}
          attributeList={attributeList}
          showAttributeField={showAttributeField}
          setShowAttributeField={setShowAttributeField}
          handleFieldAttributeChange={handleFieldAttributeChange}
        />
      ))}
    </>
  );
};

export default DataLabBlockFieldInput;
