import React, { useState } from 'react';
import Picker from 'emoji-picker-react';
import { Popover } from '@headlessui/react';
import { FaceSmileIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  limit: number;
  label?: string;
  value?: string;
  type?: string;
  showEmojiPicker?: boolean;
  placeHolder?: string;
  handleOnChange: (value: string) => void;
}

const InputFiledWithLimit: React.FC<Props> = ({
  label = '',
  limit,
  value = '',
  type = 'text',
  showEmojiPicker = false,
  placeHolder = 'Your Online Store',
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const [textAreaCursorPositon, setTextAreaCursorPosition] = useState<
    number | null
  >(value.length > 0 ? value.length : 0);

  //object was recieved by emoji picker
  const handleEmojiSelection = (emoji: string) => {
    const valueWithEmoji =
      value.substring(0, textAreaCursorPositon) +
      emoji +
      value.substring(textAreaCursorPositon, value.length);
    handleOnChange(valueWithEmoji);
  };
  return (
    <>
      <label
        htmlFor='alice-textFiled'
        className='text-sm flex w-full font-medium text-gray-700 pb-2'
        style={{ display: label === '' ? 'none' : 'flex' }}
      >
        {t(label)}
      </label>
      <div className='mt-1 relative flex items-center'>
        <input
          id='alice-textFiled'
          type='text'
          placeholder={t(placeHolder)}
          value={value}
          onChange={(event) => {
            if (event.target.value.length <= limit)
              handleOnChange(event.target.value);
          }}
          onBlurCapture={(event) => {
            setTextAreaCursorPosition(event.target.selectionStart);
          }}
          className='shadow-sm focus:ring-green-500 focus:border-green-500 block w-full ltr:pr-12 rtl:pr-3 sm:text-sm border-gray-300 rounded-md'
        />

        <div className='absolute inset-y-0 ltr:right-0 rtl:left-2 flex py-2 pr-3 '>
          <div
            className={classNames(
              'relative items-center pt-1 mr-1',
              showEmojiPicker ? 'flex' : 'hidden'
            )}
          >
            <Popover className='relative'>
              <Popover.Button>
                <FaceSmileIcon width={20} height={20} color={'#9CA3AF'} />
              </Popover.Button>
              <Popover.Panel className='absolute z-10 left-[-220px] bg-white w-2/5 '>
                {({ close }) => (
                  <Picker
                    onEmojiClick={(event: EventListener, emojiObject: any) => {
                      handleEmojiSelection(emojiObject.emoji);
                      close();
                    }}
                  />
                )}
              </Popover.Panel>
            </Popover>
          </div>
          <kbd
            className={`inline-flex items-center  ${
              value.length >= limit ? 'text-red-500' : 'text-gray-500'
            } rounded px-[2px] text-sm font-sans font-medium bg-[#F3F4F6]`}
          >
            {value.length}/{limit}
          </kbd>
        </div>
      </div>
    </>
  );
};

export default InputFiledWithLimit;
