import {
  React,
  ReactFlowIndex,
  GenericSideSheet,
  // GenericAlertModal,
  ReactWorkflowHeader,
} from '../../export';

interface Props {}
const AutomationWorkflow: React.FC<Props> = () => {
  return (
    <div className='relative w-full h-full prevent-select '>
      <ReactWorkflowHeader />
      <ReactFlowIndex />
      <GenericSideSheet />
      {/* <GenericAlertModal /> */}
    </div>
  );
};

export default AutomationWorkflow;
