import React from 'react';

import {
  EyeIcon,
  EyeSlashIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import NewAlertModal from 'library/modal/NewAlertModal';
import useLeftBarHook from 'pages/inbox/hooks/useLeftBarHook';
import { CommentStatusPayloadInterface } from 'pages/inbox/inboxInterface';
import { classNames } from 'utilities/utils';

interface Props {
  source?: string;
  commentId?: string;
  commentType?: string;
  commentStatus?: string;
  isReplyComment?: boolean;
  isParentComment?: boolean;
  platformType?: string;

  handleCommentEdit?: (commentId: string) => void;
  updateFeedCommentStatus?: (payload: CommentStatusPayloadInterface) => void;
}

const BlockActions: React.FC<Props> = ({
  source = '',
  commentId = '',
  commentType = '',
  commentStatus = '',
  platformType = '',
  isReplyComment = false,
  isParentComment = false,
  handleCommentEdit = () => {},
  updateFeedCommentStatus = () => {},
}) => {
  const { t } = useTranslation();
  const { ticketIsResolvedStatus, dashboardData, authData } = useLeftBarHook();
  const { agentAccess } = dashboardData;
  const { id } = authData;

  const [shouldShowDeleteModal, setShouldShowDeleteModal] =
    React.useState(false);

  const handleHideComment = async () => {
    let payload = {
      action: 'hide',
      comment_id: commentId,
    };
    await updateFeedCommentStatus(payload);
  };

  const handleUnhideComment = async () => {
    let payload = {
      action: 'unhide',
      comment_id: commentId,
    };
    await updateFeedCommentStatus(payload);
  };

  const handleRemoveComment = async () => {
    let payload = {
      action: 'remove',
      comment_id: commentId,
    };
    await updateFeedCommentStatus(payload);
  };

  const handleEditComment = () => {
    handleCommentEdit(commentId);
  };

  const IsCommentActionsAvailable = (): boolean => {
    return (
      (!!isParentComment || !!isReplyComment) &&
      ticketIsResolvedStatus === 0 &&
      ['comment', 'comments'].includes(commentType) &&
      ['add', 'edit', 'edited', 'hide', 'unhide'].includes(commentStatus)
    );
  };

  const isAdminAllowedtoReply = (): boolean => {
    let agentAccessData = agentAccess.filter(
      (agent: any) => agent?.admin?.id === id
    );
    if (!!agentAccessData && agentAccessData[0].role?.id === 1034) return false;
    else return true;
  };

  const renderDeleteModal = () => {
    return (
      <NewAlertModal
        intent='danger'
        title={t('Delete Comment?')}
        cancelButtonTitle={t('Cancel')}
        confirmButtonTitle={t('Delete')}
        isShown={shouldShowDeleteModal}
        onCancelComplete={() => setShouldShowDeleteModal(false)}
        onConfirm={() => {
          handleRemoveComment();
          setShouldShowDeleteModal(false);
        }}
        description={t(
          'Are you sure you want to delete this comment? This cannot be undone.'
        )}
      />
    );
  };

  return (
    <>
      {IsCommentActionsAvailable() &&
        isAdminAllowedtoReply() &&
        source === 'customer' && (
          <div className='absolute flex top-[-15px] ltr:right-[20px] rtl:left-[20px] invisible group-hover:visible'>
            {commentStatus === 'hide' ? (
              <button
                className='border w-9 h-8 content-center ltr:rounded-l-md rtl:rounded-r-md bg-white
                      hover:bg-gray-100 border-r-[0.5px] active:bg-gray-200'
                onClick={handleUnhideComment}
              >
                <EyeIcon className='w-4 h-3.5 text-gray-400' />
              </button>
            ) : (
              <button
                className='border w-9 h-8 content-center ltr:rounded-l-md rtl:rounded-r-md bg-white
                      hover:bg-gray-100 border-r-[0.5px] active:bg-gray-200'
                onClick={handleHideComment}
              >
                <EyeSlashIcon className='w-4 h-3.5 text-gray-400' />
              </button>
            )}
            <button
              className='border w-9 h-8 content-center ltr:rounded-r-md rtl:rounded-l-md bg-white
                  hover:bg-gray-100 border-r-[0.5px] active:bg-gray-200'
              onClick={() => {
                setShouldShowDeleteModal(true);
              }}
            >
              <TrashIcon className='w-3.5 h-4 text-gray-400 cursor-pointer' />
            </button>
          </div>
        )}
      {IsCommentActionsAvailable() &&
        isAdminAllowedtoReply() &&
        ['admin', 'bot'].includes(source) && (
          <div className='absolute z-10 flex top-[-15px] overflow-hidden ltr:right-[20px] rtl:left-[20px] invisible group-hover:visible border rounded-md border-gray-300'>
            {!!platformType && platformType === 'facebook_feed' && (
              <button
                className='w-9 h-8 content-center ltr:border-r-2 rtl:border-l-2 bg-white
                  hover:bg-gray-100 active:bg-gray-200'
                onClick={handleEditComment}
              >
                <PencilIcon className='w-3.5 h-4 text-gray-400 cursor-pointer' />
              </button>
            )}

            <button
              className={classNames(
                ' w-9 h-8 content-center bg-white hover:bg-gray-100  active:bg-gray-200',
                !!platformType && platformType === 'facebook_feed'
                  ? 'ltr:rounded-r-md rtl:rounded-l-md'
                  : 'rounded-md'
              )}
              onClick={() => {
                setShouldShowDeleteModal(true);
              }}
            >
              <TrashIcon className='w-3.5 h-4 text-gray-400 cursor-pointer' />
            </button>
          </div>
        )}
      {shouldShowDeleteModal && renderDeleteModal()}
    </>
  );
};

export default BlockActions;
