export const UserIcon = ({ className = 'p-2' }) => {
  return (
    <svg
      width='18'
      height='14'
      className={className}
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 3C8 4.65685 6.65685 6 5 6C3.34315 6 2 4.65685 2 3C2 1.34315 3.34315 0 5 0C6.65685 0 8 1.34315 8 3Z'
        fill='#6B7280'
      />
      <path
        d='M16 3C16 4.65685 14.6569 6 13 6C11.3431 6 10 4.65685 10 3C10 1.34315 11.3431 0 13 0C14.6569 0 16 1.34315 16 3Z'
        fill='#6B7280'
      />
      <path
        d='M11.9291 14C11.9758 13.6734 12 13.3395 12 13C12 11.3648 11.4393 9.86059 10.4998 8.66907C11.2352 8.24355 12.0892 8 13 8C15.7614 8 18 10.2386 18 13V14H11.9291Z'
        fill='#6B7280'
      />
      <path
        d='M5 8C7.76142 8 10 10.2386 10 13V14H0V13C0 10.2386 2.23858 8 5 8Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const TimezoneIcon = ({ className = 'p-2' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.00008 0.666687C4.41675 0.666687 0.666748 4.41669
    0.666748 9.00002C0.666748 13.5834 4.41675 17.3334 9.00008
    17.3334C13.5834 17.3334 17.3334 13.5834 17.3334 9.00002C17.3334
    4.41669 13.5834 0.666687 9.00008 0.666687ZM11.9167 10.6667C11.6667
    11.0834 11.1667 11.1667 10.7501 11L8.58341 9.75002C8.33341 9.58335 8.16675
    9.33335 8.16675 9.00002V4.83335C8.16675 4.33335 8.50008 4.00002 9.00008
    4.00002C9.50008 4.00002 9.83341 4.33335 9.83341 4.83335V8.50002L11.5834
    9.50002C12.0001 9.75002 12.0834 10.25 11.9167 10.6667Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const ComputerIcon = ({ className = 'p-2' }) => {
  return (
    <svg
      width='14'
      height='15'
      className={className}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V10C14 11.1046 13.1046 12 12 12H9.78081L9.90299 12.4887L10.7071 13.2929C10.9931 13.5789 11.0787 14.009 10.9239 14.3827C10.7691 14.7563 10.4045 15 10 15H4.00003C3.59557 15 3.23093 14.7563 3.07615 14.3827C2.92137 14.009 3.00692 13.5789 3.29292 13.2929L4.09706 12.4887L4.21925 12H2C0.89543 12 0 11.1046 0 10V2ZM5.7713 9C5.75657 8.99967 5.74189 8.99967 5.72725 9H2V2H12V9H8.27281C8.25817 8.99967 8.24348 8.99967 8.22876 9H5.7713Z'
        fill='#6B7280'
      />
    </svg>
  );
};

export const CubeIcon = ({ className = 'p-2', fillColor = '#6B7280' }) => {
  return (
    <svg className={className} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 15C8 15.3466 8.17945 15.6684 8.47427 15.8507C8.76908 16.0329 9.13723 16.0494 9.44721 15.8944L13.4472 13.8944C13.786 13.725 14 13.3788 14 13V7.23607C14 6.88949 13.8205 6.56762 13.5257 6.38542C13.2309 6.20321 12.8628 6.18665 12.5528 6.34164L8.55279 8.34164C8.214 8.51103 8 8.8573 8 9.23607V15Z'
        fill={fillColor}
      />
      <path
        d='M12.2111 4.27639C12.5499 4.107 12.7639 3.76074 12.7639 3.38197C12.7639 3.00319 12.5499 2.65693 12.2111 2.48754L7.44721 0.105573C7.16569 -0.0351909 6.83431 -0.0351909 6.55279 0.105573L1.78885 2.48754C1.45007 2.65693 1.23607 3.00319 1.23607 3.38197C1.23607 3.76074 1.45007 4.107 1.78885 4.27639L6.55279 6.65836C6.83431 6.79912 7.16569 6.79912 7.44721 6.65836L12.2111 4.27639Z'
        fill={fillColor}
      />
      <path
        d='M1.44721 6.34164C1.13723 6.18665 0.769085 6.20321 0.474269 6.38542C0.179452 6.56762 0 6.88949 0 7.23607V13C0 13.3788 0.214002 13.725 0.552786 13.8944L4.55279 15.8944C4.86277 16.0494 5.23091 16.0329 5.52573 15.8507C5.82055 15.6684 6 15.3466 6 15V9.23607C6 8.8573 5.786 8.51103 5.44721 8.34164L1.44721 6.34164Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const TicketIcon = ({ className = 'p-2', fillColor = '#6B7280' }) => {
  return (
    <svg
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 4C2.89543 4 2 4.89543 2 6V8C3.10457 8 4 8.89543 4 10C4 11.1046 3.10457 12 2 12V14C2 15.1046 2.89543 16 4 16H10V14.5H11V16H16C17.1046 16 18 15.1046 18 14V12C16.8954 12 16 11.1046 16 10C16 8.89543 16.8954 8 18 8V6C18 4.89543 17.1046 4 16 4H11V5.5H10V4H4ZM10 11.5H11V8.5H10V11.5Z'
        fill={fillColor}
      />
    </svg>
  );
};

export const DeleteIcon = ({ className = '' }) => {
  return (
    <svg
      width='14'
      height='16'
      className={className}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.25 4.25L11.5995 13.3569C11.5434 14.1418
        10.8903 14.75 10.1033 14.75H3.89668C3.10972 14.75
        2.45656 14.1418 2.40049 13.3569L1.75 4.25M5.5
        7.25V11.75M8.5 7.25V11.75M9.25 4.25V2C9.25 1.58579
        8.91421 1.25 8.5 1.25H5.5C5.08579 1.25 4.75 1.58579
        4.75 2V4.25M1 4.25H13'
        stroke='#6B7280'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
