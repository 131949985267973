import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { CornerDialog } from 'evergreen-ui';
import { Link } from 'library';
import React from 'react';
import { connect } from 'react-redux';
import { grantNotificationAccess } from 'utilities/utils';
import NotificationList from '../component/notification/NotificationList';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface NotificationSettingsProps {
  //data
  selectedTeamId: number;
  isAlertCreateTicket: boolean;
  isAlertUpdateTicket: boolean;
  browserNotification: boolean;
  //functions
  fetchAssignableAdmins: (teamId: number) => void;
  editNotificationKey: (projectId: number, key: string, value: boolean) => void;
}

const NotificationSettings: React.FC<NotificationSettingsProps> = ({
  selectedTeamId,
  isAlertCreateTicket,
  isAlertUpdateTicket,
  browserNotification,
  fetchAssignableAdmins,
  editNotificationKey,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideLinkIcon = isPartnerRestricted(
    'NotificationSettings/node/link-icon'
  );
  //mount
  React.useEffect(() => {
    grantNotificationAccess();
    callInitialNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callInitialNotificationData = () =>
    !!selectedTeamId && fetchAssignableAdmins(selectedTeamId);

  const handleNotificationChange = async (checked: boolean, key: string) => {
    editNotificationKey(selectedTeamId, key, checked);
  };

  const handleBrowserNotification = (checked: boolean) => {
    if (!!window.Notification) {
      if (window.Notification.permission === 'denied') {
        setIsCornerDialogueShown(true);
      } else {
        // ask or granted
        handleNotificationChange(checked, 'should_notify_web');
      }
    }
  };

  const browserNotificationStatus =
    !!window.Notification &&
    window.Notification.permission !== 'denied' &&
    browserNotification;

  const [isCornerDialogueShown, setIsCornerDialogueShown] =
    React.useState(false);

  return (
    <div className='px-6 py-4 my-4'>
      <h3 className='text-3xl text-gray-900'>{t('Notification')}</h3>
      <p>{t('Manage your Notifications from here')} </p>
      <div className='mt-6'>
        <div className='overflow-hidden bg-white shadow sm:rounded-md'>
          <ul className='border border-gray-100 divide-y divide-gray-200'>
            {/* incoming ticket sound */}
            <NotificationList
              title={t('Incoming Tickets Sound')}
              description={t(
                'This notification will notify when you will have a new ticket in inbox'
              )}
              status={isAlertCreateTicket}
              onChange={(checked: boolean) =>
                handleNotificationChange(
                  checked,
                  'should_notify_web_ticket_assigned'
                )
              }
            />

            {/* incoming message sound */}
            <NotificationList
              title={t('Incoming Message Sound')}
              description={t(
                'Incoming Message Sound allows you to get sound when you are away'
              )}
              status={isAlertUpdateTicket}
              onChange={(checked: boolean) =>
                handleNotificationChange(
                  checked,
                  'should_notify_web_new_message'
                )
              }
            />

            {/* Browser Notification sound */}
            {!!window.Notification && (
              <NotificationList
                title={
                  <div className='flex items-center'>
                    {t('Browser Notification')}
                    <span
                      id='link-icon'
                      className={`ltr:ml-3 rtl:mr-3 ${
                        shouldHideLinkIcon ? 'hidden' : 'visible'
                      }`}
                    >
                      <Link
                        url='https://docs.myalice.ai/connect-social-channels/inbox/inbox-notifications'
                        openInNewTab={true}
                      >
                        <ArrowTopRightOnSquareIcon className='w-4 h-4 text-primary' />
                      </Link>
                    </span>
                  </div>
                }
                description={t(
                  'Browser Notification allows you to send notification in Inbox'
                )}
                disabled={!window.Notification}
                status={browserNotificationStatus}
                onChange={handleBrowserNotification}
              />
            )}
          </ul>
        </div>
      </div>

      <CornerDialog
        title={t('Notification is Disabled')}
        isShown={isCornerDialogueShown}
        onCloseComplete={() => setIsCornerDialogueShown(false)}
        hasFooter={false}
      >
        <div>
          {t(
            'Notification permission is disabled from your browser. To allow Browser Notifications, please follow the steps:'
          )}
          <p>{t('1. Go to Settings')}</p>
          <p>
            {t('2. Go to Site Settings from the Privacy and Security section')}{' '}
          </p>
          <p>{t('3. Find Notifications and click on Allow')}</p>
        </div>
      </CornerDialog>
    </div>
  );
};

const mapState = (state: any) => ({
  isAlertCreateTicket: state.crm.isAlertCreateTicket || false,
  isAlertUpdateTicket: state.crm.isAlertUpdateTicket || false,
  browserNotification: state.crm.browserNotification || false,
  selectedTeamId: state.dashboard.selectedProject.id || null,
});

const mapDispatch = (dispatch: any) => ({
  fetchAssignableAdmins: (teamId: number) =>
    dispatch.crm.fetchAssignableAdmins(teamId),
  editNotificationKey: (projectId: number, key: string, value: boolean) =>
    dispatch.crm.editNotificationKey({
      projectId,
      key,
      value,
    }),
});

const NotificationSettingsContainer = connect(
  mapState,
  mapDispatch
)(NotificationSettings);

export default NotificationSettingsContainer;
