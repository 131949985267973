import React from 'react';
import BroadcastModal from './BroadcastModal';
import {
  XMarkIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import SendTextMessage from '../component/SendTextMessage';
import BroadcastPreview from './broadcastPreview';
import { connect } from 'react-redux';
import {
  PlatformInterface,
  SelectedTeamInterface,
  WhatsappMessageTemplateProps,
} from 'index';
import StatusBar from './StatusBar';
import CampaignDetails from './CampaignDetails';
import Spinner from './Spinner';
import { toaster } from 'evergreen-ui';
import Audiencetab from './Audiencetab';
import ScheduleBoradcast from './ScheduleBroadcast';
import {
  BroadcastAttribute,
  BroadcastCreationProperty,
  BroadcastListPayload,
  CompaignPostData,
  CSVProperty,
  MappedColumnData,
  UpdateBoradcastProperty,
} from '../interface';
import SendNow from './SendNowPopup';
import BroadcastSummary from './BroadcastSummary';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';

interface Props {
  isOpen: boolean;
  isEdit: boolean;
  creditInUse: number;
  broadcastId: string;
  platformList: PlatformInterface[];
  broadcastDetailsIsLoading: boolean;
  broadcastListIsLoading: boolean;
  createBroadcastIsLoading: boolean;
  uploadCSVIsLoading: boolean;
  whatsappAttributeListIsLoading: boolean;
  selectedProject: SelectedTeamInterface;
  broadcastColumnMap: CSVProperty | null;
  broadcastCreationData: BroadcastCreationProperty;
  isProjectCreator: () => boolean;
  setOpen: (payload: boolean) => void;
  setIsEdit: (payload: boolean) => void;
  setOpenAddCredits: (data: boolean) => void;
  updateMappedData: (payload: CSVProperty | null) => void;
  createBroadcast: (payload: {
    projectId: number;
    data: createPostData;
  }) => Promise<{
    success: boolean;
    data: BroadcastCreationProperty;
  }>;
  uploadCSVForMapping: (
    payload:
      | {
          projectId: number;
          data: UpdateBoradcastProperty;
        }
      | { projectId: number; data: CompaignPostData }
  ) => Promise<{
    success: boolean;
    data: CSVProperty | BroadcastCreationProperty;
  }>;
  updateBroadcastCreationData: (
    payload: BroadcastCreationProperty[] | null
  ) => void;
  fetchBroadcastDetails: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => void;
  fetchBroadcastList: (payload: BroadcastListPayload) => void;
  downloadAudienceCsv: (paylaod: {
    projectId: number;
    title: string;
    data: { broadcast_id: string };
  }) => Promise<void>;
}

interface createPostData {
  title: string;
  template_id: number | string;
  channel_id: number | string;
}

const CreateBroadcast: React.FC<Props> = ({
  isOpen,
  isEdit,
  broadcastId,
  creditInUse,
  platformList,
  selectedProject,
  broadcastDetailsIsLoading,
  broadcastListIsLoading,
  createBroadcastIsLoading,
  uploadCSVIsLoading,
  whatsappAttributeListIsLoading,
  broadcastColumnMap,
  broadcastCreationData,
  setOpen,
  setIsEdit,
  createBroadcast,
  isProjectCreator,
  updateMappedData,
  setOpenAddCredits,
  fetchBroadcastList,
  downloadAudienceCsv,
  uploadCSVForMapping,
  fetchBroadcastDetails,
  updateBroadcastCreationData,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [templateList, setTemplateList] = React.useState<
    WhatsappMessageTemplateProps[] | null
  >(null);
  const [campaignData, setCampaignData] = React.useState<CompaignPostData>({
    title: broadcastCreationData ? broadcastCreationData.title : '',
    template_id: broadcastCreationData ? broadcastCreationData.template_id : '',
    channel_details: broadcastCreationData
      ? broadcastCreationData.channel_details
      : {
          id: '',
          title: '',
          phone: '',
        },
  });
  const [mappedData, setMappedData] = React.useState<MappedColumnData[]>(
    broadcastCreationData &&
      broadcastCreationData?.mapped_column_data.length > 0
      ? broadcastCreationData?.mapped_column_data
      : []
  );
  const [file, setFile] = React.useState<string>('');
  const [isFileUploadLoaded, setIsFileUploadLoaded] = React.useState(
    broadcastColumnMap ? true : false
  );
  const [uploadingFileLoading, setUploadingFileLoading] = React.useState(false);
  const [isBroadcasting, setIsBroadcasting] = React.useState(false);
  const [scheduleTimeStamp, setScheduleTimeStamp] = React.useState('');
  const [isSendUnmappedColumn, setIsSendUnmappedColumn] = React.useState(false);
  const [attributes, setAttributes] = React.useState<BroadcastAttribute | null>(
    null
  );
  const [costOfScheduledBroadcast, setCostOfScheduledBroadcast] =
    React.useState(0);
  const [isNextButtonLoading, setIsNextButtonLoading] = React.useState(false);

  const broadcastCreditInUse = () => {
    return !!creditInUse ? creditInUse : 0.0;
  };

  const getCurrentScheduledBroadcastCost = () => {
    return broadcastCreationData?.status === 'scheduled' &&
      !!costOfScheduledBroadcast
      ? costOfScheduledBroadcast
      : 0;
  };

  let totalBroadcastCredit =
    selectedProject?.creator?.broadcast_credit +
    selectedProject?.creator?.credit -
    broadcastCreditInUse() +
    getCurrentScheduledBroadcastCost();

  let unMappedColumnslength =
    mappedData.length > 0 &&
    mappedData.filter((item) => item?.mapped_attribute === 'not_mapped');
  const refCreation: any = React.useRef();

  const downloadAudienceReport = async () => {
    let payload = {
      projectId: selectedProject?.id,
      title: broadcastCreationData?.title,
      data: {
        broadcast_id: broadcastCreationData?.id,
      },
    };
    await downloadAudienceCsv(payload);
  };

  React.useEffect(() => {
    if (isEdit && isOpen) {
      setCostOfScheduledBroadcast(broadcastCreationData?.estimated_cost);
    }
    // eslint-disable-next-line
  }, [isOpen, isEdit, broadcastCreationData]);

  React.useEffect(() => {
    const isBroadcastApiLoading = () => {
      return (
        broadcastListIsLoading ||
        createBroadcastIsLoading ||
        uploadCSVIsLoading ||
        whatsappAttributeListIsLoading
      );
    };

    if (isBroadcastApiLoading()) {
      setIsNextButtonLoading(true);
    } else {
      setIsNextButtonLoading(false);
    }
  }, [
    broadcastListIsLoading,
    createBroadcastIsLoading,
    uploadCSVIsLoading,
    whatsappAttributeListIsLoading,
  ]);

  const renderForms = () => {
    switch (step) {
      case 1:
        return (
          <CampaignDetails
            campaignData={campaignData}
            handleChange={setCampaignData}
            platformList={platformList}
            templateList={templateList}
          />
        );
      case 2:
        return (
          <Audiencetab
            attributes={attributes}
            setAttributes={setAttributes}
            isFileUploadLoaded={isFileUploadLoaded}
            uploadingFileLoading={uploadingFileLoading}
            file={file}
            setMappedData={setMappedData}
            mappedData={mappedData}
            setUploadingFileLoading={setUploadingFileLoading}
            setFile={setFile}
            setIsFileUploadLoaded={setIsFileUploadLoaded}
            isEdit={isEdit}
            step={step}
          />
        );
      case 3:
        return (
          <BroadcastSummary
            templateList={templateList}
            broadcastCreationData={broadcastCreationData}
            credit={totalBroadcastCredit}
            cardInfo={
              selectedProject.creator.card_info.find(
                (item) => item.default === true
              )?.last4
            }
            isProjectCreator={isProjectCreator}
            setOpenAddCredits={setOpenAddCredits}
            downloadAudienceReport={downloadAudienceReport}
          />
        );
      default:
        return null;
    }
  };

  const createCampaign = async (type: string) => {
    switch (type) {
      case 'create':
        let payload = {
          projectId: selectedProject?.id,
          data: {
            title: campaignData?.title,
            template_id: campaignData?.template_id,
            channel_id: campaignData?.channel_details?.id,
          },
        };
        const response = await createBroadcast(payload);
        setIsNextButtonLoading(false);
        if (response.success) {
          setStep(2);
        } else {
          toaster.danger('Error!!', {
            description: `${response?.data}`,
            duration: 5,
          });
        }
        break;
      case 'update':
        let updatedData = {
          broadcast_id: broadcastCreationData?.id,
          title: campaignData?.title,
          template_id: campaignData?.template_id,
          channel_id: campaignData?.channel_details.id,
        };
        let payloadData = { projectId: selectedProject?.id, data: updatedData };
        let returnData = await uploadCSVForMapping(payloadData);
        setIsNextButtonLoading(false);
        if (returnData.success) {
          setStep(2);
        } else {
          toaster.danger('Error!!', {
            description: `${returnData.data}`,
            duration: 5,
          });
        }
        break;
      case 'schedule':
        setIsBroadcasting(true);
        let payloads = {
          projectId: selectedProject.id,
          // @ts-ignore
          data: {
            broadcast_id: broadcastCreationData.id,
            schedule_time: `${new Date(scheduleTimeStamp).getTime()}`,
            send_now: false,
          },
        };
        let res = await uploadCSVForMapping(payloads);
        setIsNextButtonLoading(false);
        if (res.success) {
          setIsBroadcasting(false);
          setOpen(false);
          updateBroadcastCreationData(null);
          setCostOfScheduledBroadcast(0);
          updateMappedData(null);
          toaster.success('Broadcast scheduled successfully!', {
            duration: 5,
          });
          window.location.reload();
        } else {
          setIsBroadcasting(false);
          toaster.danger('Broadcast scheduled Failed!', {
            description: res.data,
            duration: 5,
          });
        }
        break;
      case 'schedule_now':
        setIsBroadcasting(true);
        let mappedData = {
          projectId: selectedProject?.id,
          // @ts-ignore
          data: {
            broadcast_id: broadcastCreationData?.id,
            schedule_time: null,
            send_now: true,
          },
        };

        let resData = await uploadCSVForMapping(mappedData);
        setIsNextButtonLoading(false);
        if (resData.success) {
          setIsBroadcasting(false);
          setOpen(false);
          updateBroadcastCreationData(null);
          setCostOfScheduledBroadcast(0);
          updateMappedData(null);
          toaster.success('Broadcast scheduled successfully!', {
            duration: 5,
          });
          window.location.reload();
        } else {
          toaster.danger('Broadcast scheduled Failed!', {
            description: resData.data,
            duration: 5,
          });
        }
        break;
      case 'mapData':
        const respo = await sendMappedData();
        setIsNextButtonLoading(false);
        if (respo.success) {
          setStep(3);
        }
        break;
      case 'next':
        setStep(2);
        break;
      default:
        break;
    }
  };

  const sendMappedData = async () => {
    if (isEssentialDataMapped()) {
      let payload = {
        projectId: selectedProject?.id,
        // @ts-ignore
        data: {
          broadcast_id: broadcastCreationData?.id,
          mapped_data: mappedData,
        },
        type: 'mappedDataSaving',
      };
      let res = await uploadCSVForMapping(payload);
      if (res.success) {
        return { success: true, data: res.data };
      } else {
        setIsBroadcasting(false);
        toaster.danger(`${res.data}`, {
          duration: 2,
        });
        return { success: false, data: null };
      }
    } else {
      setIsBroadcasting(false);
      toaster.warning('Attribute is not selected!', {
        duration: 2,
      });
      return { success: false };
    }
  };

  const unMappedColumns = () => {
    return (
      <>
        {mappedData.length > 0 &&
          isEssentialDataMapped() &&
          mappedData.find((item) => item?.mapped_attribute === 'not_mapped') &&
          isFileUploadLoaded && (
            <div className='text-xs flex flex-col justify-end items-end'>
              <div className='text-red-700 flex items-center text-medium gap-2 font-medium'>
                <ExclamationTriangleIcon className='text-[#F87171] h-4 w-4' />
                {t('You have')}{' '}
                {unMappedColumnslength && unMappedColumnslength.length}{' '}
                {t('unmapped column')}
              </div>
              <div className='flex items-center w-full'>
                <input
                  id='importDisable'
                  aria-describedby='comments-description'
                  name='comments'
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-[#04B25F] focus:ring-green-500'
                  checked={isSendUnmappedColumn}
                  onChange={() =>
                    setIsSendUnmappedColumn(!isSendUnmappedColumn)
                  }
                />
                <div className='ltr:ml-3 rtl:mr-3 text-sm'>
                  <label htmlFor='importDisable' className='text-gray-500'>
                    {t("Don't import data in unmapped columns")}
                  </label>
                </div>
              </div>
            </div>
          )}
        {mappedData.length > 0 && !isEssentialDataMapped() && (
          <div className='text-red-700 flex items-center gap-2 font-medium text-sm'>
            <ExclamationTriangleIcon className='text-red-700 h-4 w-4' />
            {t('Some essential variables are unmapped')}
          </div>
        )}
      </>
    );
  };

  const isEssentialDataMapped = () => {
    let mappedAttributes = mappedData?.map((item) => item?.mapped_attribute);
    let uniqueMappedAttributes = Array.from(new Set(mappedAttributes));
    let essentialDataMapped = true;

    attributes?.essential?.forEach((item) => {
      if (uniqueMappedAttributes?.indexOf(item?.value) === -1) {
        essentialDataMapped = false;
        return;
      }
    });
    return essentialDataMapped;
  };

  React.useEffect(() => {
    let hasTemplateList = platformList?.find(
      (item) => item?.secondary_id === campaignData?.channel_details?.phone
    );
    if (!!hasTemplateList) {
      setTemplateList(
        hasTemplateList?.whatsapp_message_templates?.filter(
          (template) =>
            template.status === 'approved' &&
            template.category !== 'AUTHENTICATION'
        )
      );
    }
  }, [campaignData, platformList]);

  React.useEffect(() => {
    if (isEdit) {
      let payload = {
        projectId: selectedProject?.id,
        data: {
          broadcast_id: broadcastId,
        },
      };
      fetchBroadcastDetails(payload);
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const clearData = () => {
    setIsBroadcasting(false);
    setOpen(false);
    updateBroadcastCreationData(null);
    setCostOfScheduledBroadcast(0);
    updateMappedData(null);
    setIsEdit(false);
    setStep(1);
    setCampaignData({
      title: '',
      template_id: '',
      channel_details: {
        id: '',
        title: '',
        phone: '',
      },
    });
    let payload = {
      limit: 50,
      offset: 0,
      projectId: selectedProject?.id,
    };

    fetchBroadcastList(payload);
  };

  React.useEffect(() => {
    setCampaignData({
      title: broadcastCreationData ? broadcastCreationData?.title : '',
      template_id: broadcastCreationData
        ? broadcastCreationData?.template_id
        : '',
      channel_details: broadcastCreationData
        ? broadcastCreationData?.channel_details
        : {
            id: '',
            title: '',
            phone: '',
          },
    });
    // eslint-disable-next-line
  }, [broadcastCreationData]);

  React.useEffect(() => {
    if (
      mappedData.length > 0 &&
      !mappedData.find((item) => item?.mapped_attribute === 'not_mapped')
    ) {
      setIsSendUnmappedColumn(true);
    } else {
      setIsSendUnmappedColumn(false);
    }
  }, [mappedData]);

  const allowSecondStep = () => {
    return (
      !!campaignData?.title &&
      !!campaignData?.channel_details &&
      !!campaignData?.template_id &&
      !isNextButtonLoading
    );
  };

  const disableThirdStep = () => {
    return (
      !isFileUploadLoaded ||
      !isSendUnmappedColumn ||
      !isEssentialDataMapped() ||
      isNextButtonLoading
    );
  };

  const getCssClassForFooter = (step: number) => {
    switch (step) {
      case 2:
        return disableThirdStep()
          ? 'bg-gray-200 border-gray-300 text-gray-500 cursor-not-allowed'
          : 'bg-[#04B25F] border-green-500 text-white';
      default:
        return allowSecondStep()
          ? 'bg-[#04B25F] border-green-500 text-white'
          : 'bg-gray-200 border-gray-300 text-gray-500 cursor-not-allowed';
    }
  };

  const disableNextButton = () => {
    return (
      (step === 2 && disableThirdStep()) || (step === 1 && !allowSecondStep())
    );
  };

  const renderTemplatePreview = () => {
    return (
      <div className='w-[24%] flex flex-col items-end h-full'>
        <h1 className='w-full max-w-xs text-gray-700 rtl:text-right font-extrabold text-base mb-2 leading-6'>
          Preview
        </h1>
        <div className='w-full max-w-xs flex-grow h-[85%]'>
          <BroadcastPreview
            templateId={campaignData?.template_id}
            templateList={templateList}
            title={campaignData?.channel_details?.phone?.toString()}
            hasGreenTick={!!campaignData?.channel_details?.green_tick}
          />
        </div>
        <p className='text-blue-500 w-full max-w-xs text-sm cursor-pointer relative'>
          {campaignData?.title &&
            campaignData?.template_id &&
            campaignData?.channel_details?.phone && (
              <SendTextMessage
                templateId={String(campaignData?.template_id)}
                templateList={templateList}
                campaignData={campaignData}
              />
            )}
        </p>
      </div>
    );
  };

  return (
    <BroadcastModal isOpen={isOpen} hasStyle={true}>
      <div
        id='broadcast-creation-modal'
        className={`h-full flex flex-col justify-between`}
        ref={refCreation}
      >
        {broadcastDetailsIsLoading && (
          <>
            <div className='h-full flex items-center justify-center w-full'>
              <Spinner
                color='border-r-transparent border-t-[#04B25F] border-b-[#04B25F] border-l-[#04B25F]'
                size='w-16 h-16'
              />
            </div>
          </>
        )}
        {!broadcastDetailsIsLoading && (
          <>
            <div className='mp-2 h-[7%]'>
              <div className='p-5'>
                <div className='flex items-center'>
                  <div className='w-[50%] flex justify-start'>
                    <p className=''>
                      <span
                        onClick={() => {
                          clearData();
                        }}
                        className='text-gray-500 text-sm leading-5 cursor-pointer'
                      >
                        {t('Broadcast')}
                      </span>
                      <span className='text-gray-300 text-lg px-4 leading-5'>
                        {'>'}
                      </span>
                      <span className='text-gray-600 text-sm leading-5 font-medium'>
                        {isEdit ? t('Edit') : t('Create')} {t('Broadcast')}
                      </span>
                    </p>
                  </div>
                  <div className='w-[50%] flex justify-end'>
                    <XMarkIcon
                      className='w-5 h-5 text-gray-700 cursor-pointer'
                      onClick={() => {
                        clearData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`p-5 flex justify-center h-[85%] `}>
              <div className='flex w-full gap-x-12'>
                <div className={`w-full`}>
                  <StatusBar step={step} />
                  <div
                    className={cn('flex gap-20 mt-6', {
                      'h-[75%]': step === 2,
                      'h-[85%]': step === 1,
                    })}
                  >
                    <div className={`${step === 1 ? 'w-[55%]' : 'w-[100%]'}`}>
                      {renderForms()}
                    </div>

                    {step === 1 && renderTemplatePreview()}
                  </div>
                </div>
              </div>
            </div>
            <div className='border-t h-[7%]'>
              <div className='py-2 px-5 h-full flex align-center justify-end'>
                <div className='flex align-center w-full'>
                  <div className='w-[30%] flex justify-start'>
                    {(step === 2 || step === 3) && (
                      <p
                        className='text-[#0078CF] cursor-pointer font-medium'
                        onClick={() => clearData()}
                      >
                        {t('Save as Draft')}
                      </p>
                    )}
                  </div>
                  <div className='w-[70%] flex justify-end align-center'>
                    <div className='flex justify-end ltr:mr-6 rtl:ml-6'>
                      {step === 2 &&
                        mappedData?.length > 0 &&
                        unMappedColumns()}
                    </div>
                    <div className=' flex justify-end gap-2 align-center'>
                      {step === 2 || step === 3 ? (
                        <button
                          className='capitalize py-1 px-3 border text-gray-700 border-gray-300 bg-white rounded-md h-9'
                          onClick={() => setStep(step - 1)}
                        >
                          {t('Back')}
                        </button>
                      ) : (
                        <button
                          className='capitalize py-1 px-3 border text-gray-700 border-gray-300 bg-white rounded-md h-9'
                          onClick={() => {
                            setIsBroadcasting(false);
                            setOpen(false);
                            updateBroadcastCreationData(null);
                            setCostOfScheduledBroadcast(0);
                            updateMappedData(null);
                            setIsEdit(false);
                            setStep(1);
                            setCampaignData({
                              title: '',
                              template_id: '',
                              channel_details: {
                                id: '',
                                title: '',
                                phone: '',
                              },
                            });
                          }}
                        >
                          {t('Cancel')}
                        </button>
                      )}
                      {step === 3 && (
                        <div className='flex gap-[1px]'>
                          {isFileUploadLoaded &&
                            isSendUnmappedColumn &&
                            totalBroadcastCredit >=
                              broadcastCreationData?.estimated_cost && (
                              <ScheduleBoradcast
                                createCampaign={createCampaign}
                                setScheduleTimeStamp={setScheduleTimeStamp}
                                gmtTime={selectedProject?.timezone_offset}
                                isBroadcasting={isBroadcasting}
                                selectedProject={selectedProject}
                              />
                            )}
                          {(!!isFileUploadLoaded || !!isSendUnmappedColumn) &&
                            totalBroadcastCredit <
                              broadcastCreationData?.estimated_cost && (
                              <button
                                className='border border-gray-300 rounded-l
                                flex items-center bg-gray-200 px-3 py-1 h-9 text-gray-500'
                              >
                                {t('Schedule')}
                              </button>
                            )}
                          {isFileUploadLoaded &&
                            isSendUnmappedColumn &&
                            totalBroadcastCredit >=
                              broadcastCreationData?.estimated_cost && (
                              <SendNow
                                totalAudience={
                                  broadcastCreationData?.valid_audience_data
                                }
                                sendNow={() => {
                                  if (
                                    isFileUploadLoaded ||
                                    isSendUnmappedColumn
                                  ) {
                                    createCampaign('schedule_now');
                                  }
                                }}
                                isBroadcasting={isBroadcasting}
                              />
                            )}
                          {(!!isFileUploadLoaded || !!isSendUnmappedColumn) &&
                            totalBroadcastCredit <
                              broadcastCreationData?.estimated_cost && (
                              <button
                                className={`capitalize py-1 px-3 border flex gap-2 items-center h-9
                                            rounded-r-md bg-gray-200 text-gray-500 border-gray-300`}
                              >
                                <ChevronUpIcon className='text-gray-500 w-5 h-5' />
                              </button>
                            )}
                        </div>
                      )}
                      {step !== 3 && (
                        <button
                          className={`${getCssClassForFooter(step)}
                          capitalize py-1 px-3 border rounded-md h-9
                          ${
                            disableNextButton()
                              ? 'bg-gray-200 border-gray-300 text-gray-500 cursor-not-allowed'
                              : ''
                          }
                          `}
                          disabled={disableNextButton()}
                          onClick={() => {
                            setIsNextButtonLoading(true);
                            if (step === 1) {
                              if (broadcastCreationData) {
                                createCampaign('update');
                              } else {
                                createCampaign('create');
                              }
                            } else {
                              createCampaign('mapData');
                            }
                          }}
                        >
                          {isNextButtonLoading ? (
                            <div className='flex'>
                              {t('Next')}
                              <span className='pl-2'>
                                <Spinner
                                  color='border-r-transparent border-t-gray-400 border-b-gray-400 border-l-gray-400 border-3'
                                  size='w-[16px] h-[16px] mt-0'
                                />
                              </span>
                            </div>
                          ) : (
                            t('Next')
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </BroadcastModal>
  );
};

const mapState = (state: any) => ({
  creditInUse: state.broadcast?.creditInUse,
  platformList: state.dashboard.platformList,
  selectedProject: state.dashboard.selectedProject,
  broadcastCreationData: state.broadcast.broadcastCreationData,
  broadcastColumnMap: state.broadcast.mappedData,
  broadcastListIsLoading: state.loading.effects.broadcast.fetchBroadcastList,
  broadcastDetailsIsLoading:
    state.loading.effects.broadcast.fetchBroadcastDetails,
  createBroadcastIsLoading: state.loading.effects.broadcast.createBroadcast,
  uploadCSVIsLoading: state.loading.effects.broadcast.uploadCSV,
  whatsappAttributeListIsLoading:
    state.loading.effects.broadcast.whatsappAttributeList,
});
const mapDispatch = (dispatch: any) => ({
  createBroadcast: (payload: { projectId: number; data: createPostData }) =>
    dispatch.broadcast.createBroadcast(payload),
  uploadCSVForMapping: (
    payload:
      | {
          projectId: number;
          data: UpdateBoradcastProperty;
        }
      | { projectId: number; data: CompaignPostData }
  ) => dispatch.broadcast.uploadCSV(payload),
  updateBroadcastCreationData: (payload: BroadcastCreationProperty[] | null) =>
    dispatch.broadcast.updateBroadcastCreationData(payload),
  updateMappedData: (payload: CSVProperty | null) =>
    dispatch.broadcast.updateMappedData(payload),
  fetchBroadcastDetails: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => dispatch.broadcast.fetchBroadcastDetails(payload),
  fetchBroadcastList: (payload: BroadcastListPayload) =>
    dispatch.broadcast.fetchBroadcastList(payload),
  downloadAudienceCsv: (payload: {
    projectId: number;
    title: string;
    data: { broadcast_id: string };
  }) => dispatch.broadcast.downloadAudienceCsv(payload),
});

export default connect(mapState, mapDispatch)(CreateBroadcast);
