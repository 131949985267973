import React from 'react';
import { toaster } from 'evergreen-ui';
import { couponResponse } from '../interface';
import useTranslation from 'components/customHooks/useTranslation';

type promoProps = {
  handleCouponCode: (code: string) => {
    dataSource: couponResponse;
    success: boolean;
  };
  setCouponProperty: (payload: couponResponse) => void;
  isPromoApplied: boolean;
  setIsPromoApplied:(value: boolean) => void;
};

const PromoCodeInput: React.FC<promoProps> = ({
  handleCouponCode,
  setCouponProperty,
  isPromoApplied,
  setIsPromoApplied
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const applyPromo = async () => {
    const res = await handleCouponCode(value);
    if (res.success) {
      setCouponProperty(res.dataSource);
      setIsPromoApplied(true)
      toaster.success(t('Promo code successfully applied'), {
        duration: 3,
      });
    } else {
      toaster.danger(t('Invalid promo code!'), {
        duration: 3,
      });
    }
  };
  return (
    <div className='flex w-full'>
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={t('Promo Code')}
        type='text'
        className={
          `w-4/5 text-sm ${value ? 'text-gray-900' : 'text-gray-500'} ${isPromoApplied ? 'bg-gray-100' : 'bg-white'}
          font-normal rounded-l-md border border-gray-300 focus:ring-0 focus:border-primary`
        }
        disabled={isPromoApplied}
      />
      <button
        type='submit'
        className={
          `w-1/5 text-sm font-medium rounded-r-md border-r border-b border-t
          ${isPromoApplied
            ? 'text-primary-hover bg-[#CDF0DF] border-[#CDF0DF]' 
            : value
              ? 'text-white bg-primary border-primary'
              : 'text-gray-600 bg-gray-100 border-gray-300 cursor-not-allowed'
          }`
        }
        disabled={!value}
        onClick={() => applyPromo()}
      >
        {t(isPromoApplied ? 'Promo Applied' : 'Apply Promo')}
      </button>
    </div>
  );
};

export default PromoCodeInput;
