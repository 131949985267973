import React from 'react';
import { countryData } from '../../../../../../../utilities/utils';
import { EcomAddressProps } from '../interfaces';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  data: EcomAddressProps | undefined | null;
  handleChange: (key: string, value: string) => void;
  countryDetails: {
    country: string;
    country_iso_code: string;
    phone_code: string;
  };
}

const DeliveryPresentDetails: React.FC<Prop> = ({
  data,
  handleChange,
  countryDetails,
}) => {
  const { t } = useTranslation();
  return (
    <div className='p-6 border-b sm:mt-0'>
      <div className='md:grid md:grid-cols-1 md:gap-6'>
        <div className='md:col-span-1'>
          <div className='px-0 mb-4 sm:mb:0'>
            <p className='text-sm font-medium leading-5 text-gray-900'>
              {t('Billing Information')}
            </p>
          </div>
        </div>
        <div className='bg-white'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='first_name'
                className='block text-sm font-medium text-gray-700'
              >
                {t('First Name')}*
              </label>
              <input
                type='text'
                placeholder={t('Provide first name...')}
                value={data?.first_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='first_name'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='last_name'
                className='block text-sm font-medium text-gray-700'
              >
                {t('Last Name')}
              </label>
              <input
                type='text'
                placeholder={t('Provide last name...')}
                value={data?.last_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='last_name'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>

            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='email_address'
                className='block text-sm font-medium text-gray-700'
              >
                {t('Email Address')}
              </label>
              <input
                type='text'
                placeholder='Provide email here...'
                value={data?.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='email'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-3'>
              <label
                htmlFor='phone_number'
                className='block text-sm font-medium text-gray-700'
              >
                {t('Phone Number')}
              </label>
              <input
                type='text'
                placeholder='Provide phone here...'
                value={data?.phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='phone'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <label
                htmlFor='address-1'
                className='block text-sm font-medium text-gray-700'
              >
                {t('Address')}1*
              </label>
              <input
                type='text'
                placeholder='Provide address line 1 here...'
                value={data?.address_one}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='address_one'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <label
                htmlFor='address-2'
                className='flex text-sm font-medium space-between'
              >
                <span className='text-gray-700'>{t('Address')} 2 </span>{' '}
                <span className='text-xs text-gray-500 uppercase'>
                  {t('optional')}
                </span>
              </label>
              <input
                type='text'
                placeholder='Provide address line 2 here...'
                value={data?.address_two}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='address_two'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-2'>
              <label
                htmlFor='city'
                className='block text-sm font-medium text-gray-700'
              >
                {t('City')}
              </label>
              <input
                type='text'
                placeholder='Provide City...'
                value={data?.city}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='city'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-2'>
              <label
                htmlFor='state'
                className='block text-sm font-medium text-gray-700'
              >
                {t('State/Province')}
              </label>
              <input
                type='text'
                placeholder='Provide State...'
                value={data?.state}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='state'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-2'>
              <label
                htmlFor='zip'
                className='block text-sm font-medium text-gray-700'
              >
                {t('ZIP/Postal')}
              </label>
              <input
                type='text'
                placeholder='Provide ZIP...'
                value={data?.postcode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.name, e.target.value)
                }
                name='postcode'
                autoComplete='new-password'
                className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
            <div className='col-span-6 sm:col-span-6'>
              <label
                htmlFor='country'
                className='block text-sm font-medium text-gray-700'
              >
                {t('Country / Region')}
              </label>
              <select
                name='country'
                value={
                  data?.country.toLowerCase() ||
                  countryDetails.country ||
                  'singapore'
                }
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChange('country', e.target.value)
                }
                className='block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
              >
                {countryData.map((country: any, index: number) => (
                  <option key={index} value={country.name.toLowerCase()}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPresentDetails;
