import React from 'react';
import dayJs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import TableHeader from '../CommonComponent/TableHeader';
import EmptyTableCellsIcon from '../../assets/images/EmptyTableIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';

dayJs.extend(LocalizedFormat);

interface props {
  tableData: tableProps[];
}

interface tableProps {
  title: string;
  webhook_count: number;
  customer_count: number;
}

const ActionTableData: React.FC<props> = ({ tableData }) => {
  const { t } = useTranslation();
  const headerTitle = ['Name', 'Total Triggers', 'Unique Customers'];
  return (
    <div className='flex flex-col'>
      <div className='-my-2'>
        <div className='inline-block min-w-full py-2 align-middle'>
          {tableData.length !== 0 && (
            <>
              <table className='min-w-full min-h-full border-t border-b divide-y divide-gray-200 table-fixed'>
                <TableHeader data={headerTitle} />
                <tbody className='divide-y divide-gray-200'>
                  {tableData.map((rowItem: any, index: number) => (
                    <tr key={index}>
                      <td className='px-6 py-4 text-sm rtl:text-right font-medium text-gray-900 whitespace-nowrap'>
                        {(rowItem?.title?.length > 25
                          ? rowItem?.title.substring(0, 25) + '...'
                          : rowItem?.title) || 'Not Available'}
                      </td>
                      <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                        {rowItem.webhook_count}
                      </td>
                      <td className='px-6 py-4 text-sm rtl:text-right text-gray-500 whitespace-nowrap'>
                        {rowItem.customer_count}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {tableData.length === 0 && (
            <div className='flex flex-col items-center border-t'>
              <img
                src={EmptyTableCellsIcon}
                alt={'empty table'}
                className={'w-20 mt-28'}
              />
              <p className='mt-4 text-sm font-normal'>
                {t('No data available for this timeline')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionTableData;
