import { BoltIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Redirect, navigate } from '@reach/router';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import Button from 'library/button';
import NewAlertModal from 'library/modal/NewAlertModal';
import LoaderLine from 'old/components/loaders/LoaderLine';
import { status } from 'utilities/config';
import DisabledScreen from '../dashboard/components/DisabledScreen';
import OnboardingModal from '../dashboard/components/OnboardingModal';
import OnboardingModalContent from '../dashboard/components/OnboardingModalContent';
import SessionUsageModal from '../dashboard/components/SessionUsageModal';
import TrialEndedScreen from '../dashboard/components/TrialEndedScreen';
import AdminView from './adminView';
import AgentView from './agentView';
import useDashboard from './hooks/useDashboard';

const DashboardV2 = () => {
  const {
    auth,
    loading,
    dashboard,
    dashboardState,
    selectedProject,
    updateStateValues,
    updateSelectedNav,
    checkIfHasOnboarding,
  } = useDashboard();
  const { t } = useTranslation();

  const { isPartnerRestricted } = usePartner();
  const disableTrialInfo = isPartnerRestricted(
    'index/function/upgradeButtonView'
  );
  const isAppsumoPlan: boolean =
    !!dashboard?.billingInfo?.subscription_plan?.planType &&
    dashboard?.billingInfo?.subscription_plan?.planType.toLowerCase() ===
      'appsumo';

  const upgradeButtonURL: string = isAppsumoPlan
    ? 'https://appsumo.com/products/myalice/'
    : '/settings/billing';

  const renderDisableScreen = () => {
    return <DisabledScreen />;
  };
  const renderTrialEndedScreen = () => {
    return <TrialEndedScreen />;
  };

  // Your render logic here
  const renderLoader = () => {
    return (
      <div className='z-20 w-full'>
        <LoaderLine
          style={{ height: `calc(100vh - 150px)` }}
          color={'#007B65'}
        />
      </div>
    );
  };

  const componentView = () =>
    !!selectedProject ? (
      dashboardState?.hasSupervisorPermission ? (
        <AdminView />
      ) : (
        <AgentView />
      )
    ) : (
      ''
    );

  const upgradeButtonView = () => {
    if (
      !disableTrialInfo &&
      dashboardState?.hasSupervisorPermission &&
      auth?.trial_availed &&
      dashboard?.selectedProject?.subscription_plan?.plan_type === 'free'
    ) {
      return (
        <div className='flex justify-center gap-2'>
          <div className='flex gap-1 px-4 py-2 rounded-md shadow bg-purple-50'>
            <ClockIcon className='inline-block w-5 text-indigo-500' />
            <span className='text-sm font-medium text-indigo-500'>
              {t(
                `You have {{${auth?.trial_remaining}}} days left in your free trial`
              )}
            </span>
          </div>
          <Button
            size='sm'
            intent='primary'
            iconInRight={true}
            icon={<BoltIcon className='w-5 text-white ' />}
            onClick={() => navigate(upgradeButtonURL)}
          >
            {t('Upgrade')}
          </Button>
        </div>
      );
    } else return '';
  };

  const mainComponentView = () => {
    if (loading || dashboardState?.loading) return renderLoader();
    else if (dashboardState?.hasTrialEnded) {
      return <div className='h-[100vh]'>{renderTrialEndedScreen()}</div>;
    } else if (dashboardState?.is_disabled) {
      return <div className='h-[100vh]'>{renderDisableScreen()}</div>;
    } else
      return (
        <>
          <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
            <span className='justify-start text-xl font-bold text-gray-900'>
              {t('Home')}
            </span>
            {upgradeButtonView()}
          </div>
          <div className='w-full px-5 overflow-x-auto'>{componentView()}</div>
        </>
      );
  };

  const mainView = () => {
    const shopifyToken = localStorage.getItem(
      'shopify_installation_request_id'
    );
    if (!!shopifyToken) {
      return <Redirect from='' to='/shopify-connect' noThrow />;
    } else if (checkIfHasOnboarding()) {
      return <Redirect from='' to='/onboarding' noThrow />;
    } else return mainComponentView();
  };

  return (
    <div className='w-full'>
      {mainView()}
      {status === 'staging' && dashboardState?.hasOnboardingModal && (
        <OnboardingModal open={dashboardState?.hasOnboardingModal}>
          <div className='flex items-center justify-center h-auto'>
            <OnboardingModalContent
              selectedProject={dashboard?.selectedProject}
              channelType={dashboardState?.onboardingChannelType}
              handleButtonOnClick={(navId) => {
                updateStateValues({
                  hasOnboardingModal: false,
                  onboardingChannelType: '',
                });
                updateSelectedNav(navId);
              }}
            />
          </div>
        </OnboardingModal>
      )}
      {dashboardState?.showSessionUsageModal && (
        <SessionUsageModal
          handleCloseShowUsage={() =>
            updateStateValues({ showSessionUsageModal: false })
          }
        />
      )}
      <NewAlertModal
        isShown={dashboardState?.showAccessDeniedModal}
        intent='danger'
        title={dashboardState?.showAccessDeniedModalMessage?.title}
        description={dashboardState?.showAccessDeniedModalMessage?.subTitle}
        shouldShowCancelButton={false}
        confirmButtonTitle={'Okay'}
        onConfirm={() => {
          updateStateValues({ showAccessDeniedModal: false });
          const titleIncludesNoTicketFound =
            dashboardState?.showAccessDeniedModalMessage?.title.includes(
              'No Ticket Found'
            );
          if (!titleIncludesNoTicketFound) {
            navigate('/dashboard');
          }
        }}
      />
    </div>
  );
};

export default DashboardV2;
