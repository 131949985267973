import { useSelector, useDispatch } from 'react-redux';
import { ISLAData } from '../interface';

const useSLA = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(
    (state: any) => state.loading.effects.settingsSLAConfiguration
  );
  const dashboardData = useSelector((state: any) => state.dashboard);
  const slaStateData = useSelector(
    (state: any) => state.settingsSLAConfiguration
  );
  const authStateData = useSelector((state:any) => state.auth);
  const { fetchSLAPolicyList, deleteSLAPolicy } = loadingState;
  const { slaList, limit, offset, total, agentAccessList } = slaStateData;
  const { selectedProject, platformList } = dashboardData;
  const authEmail = authStateData.email;
  const getSlaList = async (offset: number = 0, limit: number = 10) => {
    await dispatch.settingsSLAConfiguration.fetchSLAPolicyList({
      teamId: selectedProject?.id,
      limit,
      offset,
    });
  };

  const handleSLASearch = async (name = '') => {
    let response = await dispatch.settingsSLAConfiguration.searchSLAPolicyList({
      teamId: selectedProject?.id,
      limit,
      offset,
      name,
    });
    return response;
  };

  const handleSLAConfigurationClick = (slaData: ISLAData) => {
    let copiedSLAData: ISLAData = { ...slaData };

    let updatedLocalSLAData = {
      ...copiedSLAData,
      escalations: copiedSLAData.escalations,
    };

    dispatch.settingsSLAConfiguration.updateSLAData(slaData);
    dispatch.settingsSLAConfiguration.updateLocalSLAData(updatedLocalSLAData);
  };

  const handleLimitUpdate = async (limit: number) => {
    await getSlaList(offset, limit);
  };

  const handlePageChange = async (offset: number, limit: number) => {
    await getSlaList(offset, limit);
  };

  const handleIsActiveStatusChange = async ({
    slaId,
    isActive,
  }: {
    slaId: string | number;
    isActive: boolean;
  }) => {
    let copiedSlaList = [...slaList];
    let selectedSLA = copiedSlaList.filter((sla) => sla.id === slaId);
    let requestBody: ISLAData = selectedSLA[0];

    requestBody = { ...requestBody, is_active: isActive };

    const response: { status: boolean; data: any } =
      await dispatch.settingsSLAConfiguration.updateIsActiveStatus({
        teamId: selectedProject?.id,
        requestBody: requestBody,
      });

    if (response.status) {
      dispatch.settingsSLAConfiguration.updateSingleSLAOnList(response.data);
    }

    return response;
  };

  const handleDeleteSla = async (slaId: string) => {
    let response = await dispatch.settingsSLAConfiguration.deleteSLAPolicy({
      teamId: selectedProject?.id,
      slaId: slaId,
    });
    if (response.status) {
      await getSlaList(offset, limit);
    }
    return response;
  };

  const getAgentAccessList = async () => {
    await dispatch.settingsSLAConfiguration.fetchAgentAccess({
      teamId: selectedProject?.id,
    });
  };
  return {
    limit,
    offset,
    total,
    slaList,
    getSlaList,
    platformList,
    handleSLASearch,
    handleDeleteSla,
    handlePageChange,
    handleLimitUpdate,
    agentList: agentAccessList,
    authEmail,
    getAgentAccessList,
    handleIsActiveStatusChange,
    handleSLAConfigurationClick,
    isSLADeleteLoading: deleteSLAPolicy,
    isSLAFetchingListLoading: fetchSLAPolicyList,
  };
};

export default useSLA;
