import { Badge } from 'libraryV2/ui/badge';
import { Button } from 'libraryV2/ui/button';
import { Checkbox } from 'libraryV2/ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';
import { ChevronDown } from 'lucide-react';
import * as React from 'react';

interface Tag {
  id: number;
  name: string;
  created_at: string;
}

interface Props {
  ticketTags: Tag[];
  selectedTicketTags: Tag[];
  updateTicketTags: (ticketTags: Tag[]) => void;
}

function TagsDropdown({
  ticketTags,
  updateTicketTags,
  selectedTicketTags,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValues, setSelectedValues] = React.useState<Tag[]>(
    selectedTicketTags ?? []
  );
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSelect = (currentValue: Tag) => {
    let newSelectedValues;
    const isTagAlreadyAdded = selectedValues.some(
      (obj: Tag) => obj?.id === currentValue?.id
    );
    if (isTagAlreadyAdded) {
      newSelectedValues = selectedValues.filter(
        (value: Tag) => value?.id !== currentValue?.id
      );
    } else {
      newSelectedValues = [...selectedValues, currentValue];
    }
    setSelectedValues(newSelectedValues);
    // Call updateTicketTags with the new set of selected tag names
    updateTicketTags(newSelectedValues);
  };

  const handleLabelClick = (event: any, value: Tag) => {
    event.preventDefault();
    handleSelect(value);
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className='w-[380px]'>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={`w-full justify-between hover:bg-transparent ${
            open && 'border-primary'
          } ${selectedValues.length === 0 && 'text-gray-400 font-normal'}`}
        >
          <div className='flex'>
            {!!ticketTags &&
            ticketTags?.length > 0 &&
            selectedValues.length > 0 ? (
              selectedValues.length < 2 ? (
                selectedValues.map((value: Tag) => {
                  const foundTag = ticketTags.find(
                    (tag) => tag?.id === value?.id
                  );

                  return (
                    <Badge
                      variant='ghost'
                      key={value?.id}
                      className='first-of-type:ml-0 ml-2 bg-gray-100 rounded-md'
                    >
                      {foundTag?.name && foundTag.name.length > 10
                        ? foundTag.name.slice(0, 10) + '...'
                        : foundTag?.name}
                    </Badge>
                  );
                })
              ) : (
                <Badge
                  variant='ghost'
                  className='rounded-md bg-gray-100'
                  title={selectedValues
                    .map((tag) =>
                      tag.name.length > 20
                        ? `${tag.name.slice(0, 20)}...`
                        : tag.name
                    )
                    .join(', ')}
                >
                  {selectedValues.length} selected
                </Badge>
              )
            ) : (
              <span>Select tags</span>
            )}
          </div>
          <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[380px] p-0 bg-white'>
        <Command>
          <CommandInput
            placeholder='Search or add new tags'
            onValueChange={handleSearchChange}
            className='focus:border-none focus:ring-0 focus:outline-none'
          />
          <CommandList>
            <CommandEmpty className='py-2'>
              {!!searchTerm && (
                <div className='text-blue-600 ml-2 cursor-pointer'>
                  Create "{searchTerm}" as a new tag.
                </div>
              )}
            </CommandEmpty>
          </CommandList>
          <CommandList>
            <CommandGroup>
              {!!ticketTags &&
                ticketTags.map((tag) => (
                  <CommandItem
                    key={tag?.id}
                    value={tag?.name}
                    onSelect={() => handleSelect(tag)}
                  >
                    <div className='flex items-center space-x-2'>
                      <Checkbox
                        id={`${tag?.id}`}
                        className={cn(
                          selectedValues.some(
                            (stag: Tag) => stag?.id === tag?.id
                          )
                            ? 'bg-primary text-white'
                            : 'border-gray-600',
                          'rounded shadow-none'
                        )}
                        checked={selectedValues.some(
                          (stag: Tag) => stag?.id === tag?.id
                        )}
                        onChange={() => handleSelect(tag)}
                      />
                      <label
                        htmlFor={`${tag?.id}`}
                        className='text-sm flex-1 font-medium leading-none cursor-pointer'
                        onClick={(event) => handleLabelClick(event, tag)}
                      >
                        {tag?.name}
                      </label>
                    </div>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default TagsDropdown;
