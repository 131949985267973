import React from 'react';
import { connect } from 'react-redux';
import { WhatsappTemplate } from 'pages/inbox/inboxInterface';
import { toaster } from 'evergreen-ui';
import { CompaignPostData } from '../interface';
import { WhatsappMessageTemplateProps } from 'index';
import useTranslation from 'components/customHooks/useTranslation';

interface TemplateProps {
  channelId: number;
  templateId: string;
}

const defaultData = {
  id: 'defaultId',
  name: 'Default name',
  header: {
    type: 'text',
    value: 'Hello there,',
  },
  body: 'Template preview will show here',
  status: 'approved',
  category: 'default',
  language: 'english',
};

interface props {
  phone: string;
  templateId: string;
  messageCount: number;
  campaignData: CompaignPostData;
  templateList: WhatsappMessageTemplateProps[] | null;
  maxAllowedTestMessageCount: number;
  setVariables: (data: string[]) => void;
  setStep: (data: number) => void;
  setPhone: (data: string) => void;
  setAttributeData: (data: any) => void;
  setSelectedTemplate: (data: WhatsappTemplate) => void;
  setOpenSendTemplateModal: (data: boolean) => void;
  fetchWhatsappTemplateDetails: (payload: TemplateProps) => Promise<string[]>;
  setDynamicButtonVariableName: (data: string) => void;
}

const EnterPhoneNumber: React.FC<props> = ({
  phone,
  templateId,
  templateList,
  messageCount,
  campaignData,
  setStep,
  setPhone,
  setVariables,
  setAttributeData,
  setSelectedTemplate,
  maxAllowedTestMessageCount,
  setOpenSendTemplateModal,
  fetchWhatsappTemplateDetails,
  setDynamicButtonVariableName,
}) => {
  const { t } = useTranslation();
  const availableMessageTemplateCount =
    Number(maxAllowedTestMessageCount) - Number(messageCount);
  const hasExceededLimit = availableMessageTemplateCount <= 0;
  const hasDynamicUrlButtonType = (selectedTemplate: WhatsappTemplate) => {
    return (
      selectedTemplate?.buttons &&
      selectedTemplate?.buttons?.length === 1 &&
      selectedTemplate?.buttons[0].type === 'url' &&
      typeof selectedTemplate?.buttons[0]?.value === 'string' &&
      !!selectedTemplate?.buttons[0]?.value.match(/{{.*}}/)
    );
  };

  const setTemplateVariables = async (
    channelId: number,
    templateId: string,
    selectedTemplate: WhatsappTemplate
  ) => {
    const payload = {
      channelId: channelId,
      templateId: templateId,
    };
    const res = await fetchWhatsappTemplateDetails(payload);
    if (!!res) {
      if (!!selectedTemplate && hasDynamicUrlButtonType(selectedTemplate)) {
        let buttonProperty: any =
          !!selectedTemplate?.buttons && selectedTemplate?.buttons[0];
        let dynamicButton = buttonProperty.value.match(/{{.*}}/);
        const buttonVariableName =
          !!dynamicButton && dynamicButton[0].slice(2, -2);

        let variables = !!buttonVariableName
          ? res.filter((variable: string) => {
              return variable !== buttonVariableName;
            })
          : res;

        if (!!buttonVariableName) {
          setDynamicButtonVariableName(buttonVariableName);
        }
        setVariables(variables);
      } else {
        setVariables(res);
      }
    } else {
      toaster.danger('Could not find template variable!');
    }
  };

  React.useEffect(
    () => {
      const index =
        templateList &&
        templateList.findIndex((data) => data.id === templateId);

      if (
        Array.isArray(templateList) &&
        templateList &&
        templateList.length > 0 &&
        index != null &&
        index !== -1
      ) {
        setSelectedTemplate(templateList[index]);
        setTemplateVariables(
          Number(campaignData?.channel_details?.id),
          templateId,
          templateList[index]
        );
      } else {
        setSelectedTemplate(defaultData);
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className='w-[376px] relative py-5 px-3 rtl:text-right'>
      <p className='text-gray-900 text-base leading-6 font-medium pb-3'>
        {t('Send Test Message')}
      </p>
      <p className='text-gray-500 text-sm leading-5 mb-5'>
        {t('You have')} {availableMessageTemplateCount}/
        {maxAllowedTestMessageCount} {t('test messages left.')}
      </p>
      <div
        className={`absolute ltr:right-2.5 rtl:left-2.5 top-1.5 text-gray-500 cursor-pointer hover:text-gray-600 hover:font-bold`}
        onClick={() => {
          setOpenSendTemplateModal(false);
        }}
      >
        <p>&#x2715;</p>
      </div>
      <div>
        <div className='relative mt-1 rounded-md shadow-sm mb-3'>
          <input
            type='number'
            name='phone-number'
            id='phone-number'
            className={`block w-full rounded-md text-sm leading-5 border-gray-300 focus:border-green-500 focus:ring-green-500
                          sm:text-sm text-gray-500 ${
                            hasExceededLimit
                              ? 'bg-gray-300 cursor-not-allowed'
                              : ''
                          }`}
            placeholder={t('Enter WhatsApp number with country code')}
            onChange={(e) => setPhone(e.target.value)}
            disabled={hasExceededLimit}
          />
        </div>
      </div>

      <button
        onClick={() => {
          if (hasExceededLimit || phone.length <= 9) return;
          setStep(2);
        }}
        className={`${
          hasExceededLimit || phone.length <= 9
            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
            : 'bg-[#04B25F] text-white'
        } py-2 px-3 w-full rounded-md flex gap-2 justify-center font-medium`}
      >
        {t('Next')}
      </button>
    </div>
  );
};

const mspState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  fetchWhatsappTemplateDetails: (payload: TemplateProps) =>
    dispatch.inbox.fetchWhatsappTemplateDetails(payload),
});

export default connect(mspState, mapDispatch)(EnterPhoneNumber);
