import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import ButtonGroup from '../elements/ButtonGroup';
import PropTypes from 'prop-types';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  emailChannels,
  updateOnChangeText,
  updateOnSelectAttribute,
} from '../../../utilities/utils';
import csatIcon from '../../pages/builder/assets/images/icons/csat.svg';
import FetchFromAPIButtonSection from '../elements/FetchFromAPIButtonSection';
import TextCharInfo from '../elements/TextCharInfo';
import {
  InformationCircleIcon,
  BarsArrowDownIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../library';
import { BarsArrowUpIcon } from '@heroicons/react/20/solid';
import { Textarea } from 'evergreen-ui';
import { useSelector } from 'react-redux';

const CsatBlock = ({
  id,
  data,
  save,
  saveLoader,
  sequences,
  saveBlock,
  copyBlock,
  moveBlock,
  type,
  attributeList,
  labsData,
  apiList,
  updateButtonsBlock,
  updateButtonElements,
  handleText,
  handleSorting,
  createButtonElement,
  deleteButtonElement,
  maxButtonCount,
  deleteBlock,
  handleAPIButtonElementCreate,
  hasWriteAccess,
  subscriptionPlanType,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(620);

  const platformType =
    useSelector((state) => state.dashboard?.selectedPlatform?.platform_type) ??
    '';

  return (
    <BlockContainer
      id={id}
      link='https://docs.myalice.ai/automate-channels/chatbot-blocks/collect-user-information-blocks#2-csat-block'
      title='User Ratings'
      subtitle='Collect customer satisfaction report on a scale of 1-5. The ratings will determine your overall support performance.'
      save={save}
      isLoading={saveLoader}
      icon={csatIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === 'text')}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) =>
            (data.text + value).length + 2 <= maxCount &&
            updateOnSelectAttribute(
              value,
              showAttribute,
              showAPI,
              setShowAttribute,
              setShowAPI,
              handleText,
              data.text,
              apiList
            )
          }
        >
          <Textarea
            required={true}
            rows={3}
            name='text-block'
            value={data.text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                handleText
              )
            }
            className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm cursor-text'
            placeholder='Write your message here'
          />
        </AttributeAPIContainer>
        <TextCharInfo data={data.text} count={maxCount} />
        <div className='flex w-full flex-column'>
          <div className='flex items-start justify-between pt-3 mb-3'>
            <div className='flex items-center font-sans text-sm'>
              Rating Scale
              <span
                className='ml-2'
                title={
                  'You can connect individual rating button to a new or an existing sequence.'
                }
              >
                <InformationCircleIcon className='w-4 h-4' />
              </span>
            </div>
            <div className='flex items-center mr-1'>
              <Button
                size='xs'
                onClick={() => {
                  data.sorting === 'ascending'
                    ? handleSorting('descending')
                    : handleSorting('ascending');
                  updateButtonElements(id, data.buttons.reverse());
                }}
                icon={
                  data.sorting === 'ascending' ? (
                    <BarsArrowUpIcon />
                  ) : (
                    <BarsArrowDownIcon />
                  )
                }
              >
                Sort ({data.sorting.toUpperCase()})
              </Button>
            </div>
          </div>
          {!!data.api ? (
            <FetchFromAPIButtonSection
              blockId={id}
              hasWriteAccess={hasWriteAccess}
              apiButtonData={data.api}
              handleDelete={handleAPIButtonElementCreate}
            />
          ) : (
            <ButtonGroup
              blockId={id}
              hasDragnDrop={false}
              type={type}
              showIndex={true}
              hasDelete={false}
              hasMessengerExtension={true}
              hasWebViewHeight={true}
              sequences={sequences}
              buttonTitle='Create a Button'
              APIButtonTitle='Create a API Button'
              allowedTypes={
                emailChannels.includes(platformType)
                  ? ['Basic']
                  : ['Sequence', 'Basic']
              }
              maxButtonCount={maxButtonCount}
              formsData={labsData && labsData.filter((l) => l.type === 'form')}
              buttonList={data.buttons}
              handleDropUpdate={updateButtonElements}
              handleCreate={createButtonElement}
              handleDelete={deleteButtonElement}
              handleUpdate={updateButtonsBlock}
              hasAPIButton={['enterprise', 'business'].includes(
                subscriptionPlanType
              )}
              apiButtonData={data.api}
              apiList={apiList}
              hasWriteAccess={hasWriteAccess}
              handleAPIButtonElementCreate={handleAPIButtonElementCreate}
            />
          )}
        </div>
      </>
    </BlockContainer>
  );
};

CsatBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  saveBlock: PropTypes.func.isRequired,
  sequences: PropTypes.array.isRequired,
  updateButtonsBlock: PropTypes.func.isRequired,
  handleText: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
  createButtonElement: PropTypes.func.isRequired,
  deleteButtonElement: PropTypes.func.isRequired,
  maxButtonCount: PropTypes.number.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  labsData: PropTypes.arrayOf(PropTypes.object),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func,
  handleAPIButtonElementCreate: PropTypes.func,
  saveLoader: PropTypes.bool.isRequired,
  type: PropTypes.string,
  hasWriteAccess: PropTypes.bool.isRequired,
};
CsatBlock.defaultProps = {
  maxButtonCount: 5,
  buttonList: [],
  type: null,
};

export default CsatBlock;
