import React from 'react';
import { SideSheet as OrderModal } from '../../../../../../library';
import Wrapper from './Wrapper';
import { connect } from 'react-redux';
import { orderData } from '../../../../../../utilities/content';

interface Prop {
  searchOrderOpen: boolean;
  setSearchOrderOpen: (value: boolean) => void;
  updateStateData: (key: string, val: any) => void;
}

const EcommerceSearchOrder: React.FC<Prop> = ({
  searchOrderOpen,
  setSearchOrderOpen,
  updateStateData,
}) => {
  const containerRef = React.createRef();

  React.useEffect(() => {
    //we need this to scroll
    const elem: any = containerRef.current;
    if (elem) {
      elem.scrollTop = 0;
    }
    // eslint-disable-next-line
  }, []);

  const handleCloseModal = () => {
    updateStateData('ecommerceOrderList', []);
    setSearchOrderOpen(false);
  };

  return (
    <div>
      <OrderModal
        open={searchOrderOpen}
        ref={containerRef}
        hasMinimizeButton={false}
        handleClose={handleCloseModal}
        handleConfirm={handleCloseModal}
        handleCancel={handleCloseModal}
        closeOnExternalClick={false}
        title={orderData.searchOrder.title}
        description={orderData.searchOrder.description}
        hasFooter={false}
      >
        <Wrapper />
      </OrderModal>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const EcommerceSearchOrderContainer = connect(
  mapState,
  mapDispatch
)(EcommerceSearchOrder);
export default EcommerceSearchOrderContainer;
