import { Fragment, useEffect, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { classNames } from 'utilities/utils';
import SearchResultCard from './SearchResultCard';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { EmptyState } from 'pages/inbox/assets/iconComponent/SearchComponentIcons';
import useDebounce from 'components/customHooks/useDebounce';
import { TicketInterface } from 'pages/inbox/inboxInterface';
import useTranslation from 'components/customHooks/useTranslation';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';

interface Props {
  open: boolean;
  userId: number;
  ticketList: TicketInterface[];
  searchResult: TicketInterface[];
  selectedProject: SelectedTeamInterface;
  setOpen: (isOpen: boolean) => void;
  updateSateData: (key: string, value: any) => void;
  searchCustomer: (projectid: number, key: string) => void;
}

const GlobalSearch: React.FC<Props> = ({
  open,
  userId,
  setOpen,
  ticketList,
  searchResult,
  updateSateData,
  searchCustomer,
  selectedProject,
}) => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isInputFocused, setInputFocus] = useState<boolean>(false);

  const searchDebounce = useDebounce(query, 500);
  const { t } = useTranslation();

  const handleDebounce = async () => {
    if (searchDebounce.length !== 0) {
      await searchCustomer(selectedProject?.id, query);
    } else await searchCustomer(selectedProject?.id, '');
    setLoading(false);
  };
  useEffect(() => {
    handleDebounce();

    //eslint-disable-next-line
  }, [searchDebounce]);

  const handleTicketSelection = (ticket: TicketInterface, title?: string) => {
    updateSateData('searchState', {
      searchQuery: query,
      isSearchApplied: true,
      title: t(title || 'Search Result'),
      leftbarPreviousState: ticketList,
    });
    updateSateData('selectedTicket', ticket);
    updateSateData('ticketList', [ticket]);
    setOpen(false);
  };

  const loadingAndNoDataView = () => {
    if (loading)
      return (
        <div className='px-4 text-center py-14 sm:px-14'>
          <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
        </div>
      );
    else {
      return (
        <div className='px-4 text-center py-14 sm:px-14'>
          <EmptyState className='mx-auto text-gray-400' aria-hidden='true' />
          <p className='mt-4 text-sm text-gray-500'>
            {t(`Nothing found related to`) + ' ' + query}
          </p>
        </div>
      );
    }
  };

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery('')}
      appear
    >
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 transition-opacity bg-gray-600 bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='max-w-xl p-4 mx-auto transition-all transform bg-white shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5'>
              <div className='flex w-full mb-4'>
                <span className='text-base font-semibold text-gray-800 '>
                  {t('Search')}
                </span>
                <XMarkIcon
                  className='ltr:ml-auto rtl:mr-auto cursor-pointer'
                  height={20}
                  color='#6B7280'
                  onClick={() => setOpen(false)}
                />
              </div>
              <Combobox
                onChange={(ticket: TicketInterface) =>
                  handleTicketSelection(ticket)
                }
              >
                <div
                  className={classNames(
                    'w-full flex items-center border rounded-md px-3',
                    isInputFocused ? 'border-green-500' : 'border-gray-300'
                  )}
                >
                  <MagnifyingGlassIcon height={20} color='#9CA3AF' />
                  <Combobox.Input
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    className='w-full rounded-md border-0 text-sm font-normal  px-2 without-ring  text-[#111827] '
                    placeholder={t('Search by name, email or phone number')}
                    onChange={(event) => {
                      setQuery(event.target.value);
                      setLoading(true);
                    }}
                  />
                  <button
                    className={classNames(
                      'mx-1 p-1 text-[#6B7180] text-xs font-normal',
                      query ? 'block' : 'hidden'
                    )}
                    onClick={() => setQuery('')}
                  >
                    {t('Clear')}
                  </button>
                </div>

                {!!searchResult && searchResult.length > 0 && (
                  <>
                    <div className='w-full py-3'>
                      <span className='text-xs font-light'>
                        {t('Showing')}{' '}
                      </span>
                      <span className='text-xs font-medium text-gray-600'>
                        {t(`${(!!searchResult && searchResult.length) || 0} `)}
                      </span>
                      <span className='text-xs font-light'>
                        {t(
                          `result${
                            !!searchResult && searchResult.length > 1 ? 's' : ''
                          }`
                        )}
                      </span>
                    </div>
                    <Combobox.Options
                      static
                      className='pb-2 -mb-2 overflow-y-auto text-sm text-gray-800 max-h-72 scroll-py-2'
                    >
                      {searchResult.map(
                        (ticket: TicketInterface, index: number) => (
                          <Combobox.Option
                            key={index}
                            value={ticket}
                            className={({ active }) =>
                              classNames(
                                'cursor-pointer select-none rounded-md py-2',
                                active && ' text-white'
                              )
                            }
                          >
                            <SearchResultCard
                              ticketData={ticket}
                              userId={userId}
                            />
                          </Combobox.Option>
                        )
                      )}
                    </Combobox.Options>
                  </>
                )}

                {query !== '' &&
                  !!searchResult &&
                  searchResult.length === 0 &&
                  loadingAndNoDataView()}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapState = (state: any) => ({
  userId: state.auth.id,
  ticketList: state.inbox.ticketList,
  searchResult: state.inbox.searchTicketResult,
});

const mapDispatch = (dispatch: any) => ({
  searchCustomer: (projectId: number, key: string) =>
    dispatch.inbox.searchCustomer({ projectId, key }),
  updateSateData: (key: string, value: any) =>
    dispatch.inbox.updateSateData({ key, value }),
});

export default connect(mapState, mapDispatch)(GlobalSearch);
