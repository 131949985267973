import React, { useState } from 'react';
import TextVariableInputField from './/TextVariableInputFeild';
import ButtonGroup from '../ButtonGroup';
import { Switch } from 'evergreen-ui';
import { getButtonTypes } from '../../../../utilities/utils';

interface Props {
  blockId: number;
  type: string;
  sequences: sequenceProps[];
  platformType: string;
  subscriptionPlanType: string;
  buttons: [];
  labsData: [];
  hasWriteAccess: boolean;
  outputInstructions: {
    has_image: boolean;
    title: string;
    subtitle: string;
    url: string;
    buttons: [];
  };
  outputParameters: string[];
  showAttributeField: string | null;
  setShowAttributeField: (val: string) => void;
  handleShowImage: (name: string, val: boolean) => void;
  handleSelectedParamsAttributeValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleSelectedParamsInputValue: (
    id: number,
    name: string,
    value: string,
    changeKey: string
  ) => void;
  handleProductDiscoveryBlockGalleryButton: (
    data: any,
    actionType: string
  ) => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const OutputInstruction: React.FC<Props> = ({
  blockId,
  type,
  sequences,
  platformType,
  subscriptionPlanType,
  buttons,
  labsData,
  hasWriteAccess,
  outputInstructions,
  outputParameters,
  showAttributeField,
  setShowAttributeField,
  handleShowImage,
  handleSelectedParamsAttributeValue,
  handleSelectedParamsInputValue,
  handleProductDiscoveryBlockGalleryButton,
}) => {
  const [showImage, setShowImage] = useState(outputInstructions.has_image);
  const inputStack = ['title', 'subtitle', 'url'];
  return (
    <>
      <div className='flex justify-between'>
        <h6 className='font-medium text-gray-700'>Display Image?</h6>
        <Switch
          className='switch-rd'
          onChange={() => {
            handleShowImage('has_image', !showImage);
            setShowImage(!showImage);
          }}
          height={20}
          checked={showImage}
          hasCheckIcon={false}
        />
      </div>
      {inputStack.map((item, index) => (
        <div className='mt-3'>
          <TextVariableInputField
            hasWriteAccess={hasWriteAccess}
            id={index}
            value={outputInstructions[item]}
            type={'string'}
            placeHolder={`Add ${item} and variable`}
            attributeList={outputParameters}
            isDanger={!outputParameters[item]?.length && item !== 'url'}
            showAttribute={item === showAttributeField}
            setShowAttribute={setShowAttributeField}
            handleSelect={(val) => {
              handleSelectedParamsAttributeValue(
                index,
                item,
                val,
                'output_instructions'
              );
            }}
            handleOnChange={(val) => {
              handleSelectedParamsInputValue(
                index,
                item,
                val,
                'output_instructions'
              );
            }}
          />
        </div>
      ))}
      <br />
      <div className='mb-4 border-t border-gray-200' />
      <ButtonGroup
        blockId={blockId}
        type={type}
        hasActionButton={true}
        hasWebViewHeight={true}
        hasMessengerExtension={true}
        sequences={sequences}
        hasWriteAccess={hasWriteAccess}
        formsData={labsData && labsData.filter((l: any) => l.type === 'form')}
        allowedTypes={getButtonTypes(platformType, subscriptionPlanType)}
        buttonTitle={'Create a Button'}
        maxButtonCount={3}
        buttonList={!!buttons ? buttons : []}
        handleCreate={(id: number, buttonCategory: string = 'regular') => {
          handleProductDiscoveryBlockGalleryButton(
            { buttonCategory: buttonCategory },
            'create'
          );
        }}
        handleUpdate={(id, data) =>
          handleProductDiscoveryBlockGalleryButton(
            { buttonData: data },
            'update'
          )
        }
        handleDelete={(blockId, buttonIndex) =>
          handleProductDiscoveryBlockGalleryButton(
            { buttonIndex: buttonIndex },
            'delete'
          )
        }
        handleDropUpdate={(blockId: number, data: any) =>
          handleProductDiscoveryBlockGalleryButton(
            { buttonData: data },
            'changeButtonPosition'
          )
        }
        dragDirection='vertical'
      />
    </>
  );
};

export default OutputInstruction;
