import React, { useState } from 'react';
import Link from '../../library/link';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import StorePicker from './components/common/StorePicker';
import ChannelPicker from './components/common/ChannelPicker';
import { OnboardingDescriptionData } from './utils/content';
import { SallaOnboarding } from './components/onboardingEcommerce/salla';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Onboarding: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState('step1');
  const [isSallaConnected, setIsSallaConnected] = useState(false);
  const [selectedEcommerceStore, setSelectedEcommerceStore] =
    React.useState('');

  const handleButtonOnclick = (selectedStore: string, step: string) => {
    setStep(step);
    setSelectedEcommerceStore(selectedStore);
  };

  const woocommerceInstallationButton = () => {
    return (
      <div className='flex w-full px-3 py-2 mt-8 border border-gray-200 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'>
        <div className='w-[63%] text-gray-500 text-[14px]'>
          {t(
            'Go to Add Plugin option from your wordpress admin panel and search for MyAlice. Or You can download and install the plugin as well.'
          )}
        </div>
        <div className='ml-2 flex items-center justify-center w-[37%]'>
          <Link
            url={'https://wordpress.org/plugins/myaliceai/'}
            className={
              'text-[13px] text-white font-medium flex w-full p-2 justify-center border border-gray-200 rounded-md shadow-sm hover:text-white sm:text-sm bg-primary'
            }
          >
            {t('Download Plugin')}
          </Link>
        </div>
      </div>
    );
  };

  const shopifyInstallationButton = () => {
    return (
      <div className='flex w-full px-3 gap-2 py-2 mt-8 border border-gray-200 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'>
        <div className='w-4/6 text-gray-500 text-[14px]'>
          {t(
            'You will be taken to the Shopify app store from where you can connect your store with MyAlice.'
          )}
        </div>
        <div className='flex items-center justify-center w-2/6'>
          <Link
            url={'https://apps.shopify.com/myalice-live'}
            className={
              'text-[13px] text-white font-medium flex w-full p-2 justify-center border border-gray-200 rounded-md shadow-sm hover:text-white sm:text-sm bg-primary'
            }
          >
            {t('Install Now')}
          </Link>
        </div>
      </div>
    );
  };

  const sallaModalHandler = (isConnected: boolean): void => {
    setSelectedEcommerceStore('');
    setIsSallaConnected(isConnected);
  };

  const renderSelectedEcommerce = (ecomName: string = '') => {
    switch (ecomName) {
      case 'shopify':
        return shopifyInstallationButton();
      case 'woocommerce':
        return woocommerceInstallationButton();
      case 'salla':
        return (
          <SallaOnboarding
            open={selectedEcommerceStore === 'salla'}
            handleClose={sallaModalHandler}
          />
        );
      case 'zid':
        return zidInstallationButton();
      default:
        return null;
    }
  };

  const zidInstallationButton = () => {
    return (
      <div className='flex w-full px-3 gap-2 py-2 mt-8 border border-gray-200 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'>
        <div className='w-4/6 text-gray-500 text-[14px]'>
          {t(
            'You will be taken to the Zid app store from where you can connect your store with MyAlice.'
          )}
        </div>
        <div className='flex items-center justify-center w-2/6'>
          <Link
            url={'https://web.zid.sa/market/app/2899'}
            className={
              'text-[13px] text-white font-medium flex w-full p-2 justify-center border border-gray-200 rounded-md shadow-sm hover:text-white sm:text-sm bg-primary'
            }
          >
            {t('Install Now')}
          </Link>
        </div>
      </div>
    );
  };

  const renderSteps = () => {
    switch (step) {
      case 'step1': {
        return (
          <div style={{ margin: 'auto', width: '350px' }}>
            <StorePicker handleButtonOnClick={handleButtonOnclick} />
          </div>
        );
      }
      case 'step2': {
        if (isSallaConnected) {
          return null;
        }
        return <ChannelPicker handleSelectStore={setStep} />;
      }
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-screen h-screen overflow-x-auto bg-white bg-cover bg-cente sm:justify-center'>
      <div className='flex flex-col justify-center h-screen px-5 py-8 sm:w-full sm:max-w-lg sm:block sm:h-auto'>
        <Header
          title={OnboardingDescriptionData[step].title}
          subTitle={OnboardingDescriptionData[step].subTitle}
        />
        {renderSteps()}
        {renderSelectedEcommerce(selectedEcommerceStore)}
      </div>
      <Footer />
    </div>
  );
};

export default Onboarding;
