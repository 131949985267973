import React from 'react';
import { CopyBlock, solarizedLight } from 'react-code-blocks';
import config from '../../../../../utilities/config';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  platfromData: webChatResponseDataProps;
}

const PluginScript: React.FC<Props> = ({ platfromData }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='p-6'>
        <p className='text-sm text-gray-500'>
          {t(
            'Copy this code and put this code snippet inside script tag in the footer.'
          )}
        </p>
      </div>
      <div className='p-6'>
        <CopyBlock
          text={`{{${'// Put this code snippet inside script tag'}}}
(function () {
  var div = document.createElement('div');
  div.id = 'icWebChat';
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = '${config.misc.webChatSdkUrl()}/index.js';
  var lel = document.body.getElementsByTagName('script');
  var el = lel[lel.length - 1];
  el.parentNode.insertBefore(script, el);
  el.parentNode.insertBefore(div, el);
  script.addEventListener('load', function () {
    ICWebChat.init({ selector: '#icWebChat',
                     platformId: '${platfromData?.id || ''}',
                     primaryId: '${platfromData?.primary_id || ''}',
                     token: '${platfromData?.access_token || ''}' });
  });
})();`}
          language='javascript'
          theme={solarizedLight}
          codeBlock
        />
      </div>
    </>
  );
};
export default PluginScript;
