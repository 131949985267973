import JinjaVariableEditorFieldWithModal from 'components/jinja/components/JinjaVariableEditorFieldWithModal';
import NestedVariableDropdown, {
  AssignVariableTypes,
} from '../NestedVariableDropdown';
import { ResponseValueType } from '../../../hooks/useNestedVariableDropDown';

interface IProps {
  value: any;
  url?: string;
  title: string;
  sampleDataFormat: Record<string, any>;
  sampleDataLoading: boolean;
  variableIndex: number;
  triggerInfo: Record<string, any>;
  variables: AssignVariableTypes[];
  onChange: (value: any) => void;
  updateSelectedAssignVariableCategoryAndValue: (
    option: ResponseValueType
  ) => void;
}

const JinjaNestedVariableSelector: React.FC<IProps> = ({
  title,
  value,
  url = '',
  onChange,
  variables,
  triggerInfo,
  variableIndex,
  sampleDataFormat,
  sampleDataLoading,
  updateSelectedAssignVariableCategoryAndValue,
}) => {
  return (
    <JinjaVariableEditorFieldWithModal
      title={title}
      value={!!value ? `{{${value}}}` : value}
      onChange={onChange}
      sampleDataFormat={sampleDataFormat}
      sampleDataLoading={sampleDataLoading}
    >
      <NestedVariableDropdown
        staticTxt={url}
        variables={variables}
        triggerInfo={triggerInfo}
        variableIndex={variableIndex}
        updateSelectedAssignVariableCategoryAndValue={
          updateSelectedAssignVariableCategoryAndValue
        }
      />
    </JinjaVariableEditorFieldWithModal>
  );
};

export default JinjaNestedVariableSelector;
