import React from 'react';
import { connect } from 'react-redux';
import {
  ICustomerCartInfo,
  IEcommerceProductData,
  ZidInitialOrderInterface,
} from 'pages/inbox/inboxInterface';
import editIcon from '../../../../../assets/images/edit-cart-button.svg';
import CustomListBox from './CustomListBox';
import SingleProduct from './SingleProduct';
import Button from 'library/button';
import useTranslation from 'components/customHooks/useTranslation';
import { Input } from 'libraryV2/ui/input';
interface PaymentMethodInterface {
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodInterface {
  method_id: string;
  method_title: string;
}

interface ZidCouponInterface {
  id: number;
  code: string;
}

interface Props {
  currencySymbol: string;
  modalHeader: React.ReactNode;
  customerCart: ICustomerCartInfo;
  orderInformation: ZidInitialOrderInterface;
  paymentMethodList: PaymentMethodInterface[];
  shippingMethodList: ShippingMethodInterface[];
  zidDiscountCoupons: ZidCouponInterface[];
  setStep: (step: number) => void;
  setShowRightbar: (value: boolean) => void;
  renderZidOrderAmountsView: (step: number) => void;
  updateEcommerceStateData: (key: string, value: any) => void;
  handleSetZidPropertyInformation: (
    data: {
      key: string;
      value: any;
    }[]
  ) => void;
  handleSetZidObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const ConfigShipping: React.FC<Props> = ({
  orderInformation,
  paymentMethodList,
  shippingMethodList,
  zidDiscountCoupons,
  customerCart,
  modalHeader,
  currencySymbol,
  setStep,
  setShowRightbar,

  updateEcommerceStateData,
  renderZidOrderAmountsView,
  handleSetZidObjectInformation,
  handleSetZidPropertyInformation,
}) => {
  const { t } = useTranslation();
  let enabledPaymentMethods = paymentMethodList.filter(
    (item: PaymentMethodInterface) => {
      return (
        item.is_enabled === true &&
        !item?.method_title.toLowerCase().includes('bank')
      );
    }
  );
  let paymentMethodTitles = enabledPaymentMethods.map(
    (item: PaymentMethodInterface) => {
      return item.method_title;
    }
  );
  let shippingMethodNames = shippingMethodList.map(
    (item: ShippingMethodInterface) => {
      return item.method_title;
    }
  );

  let aliceCart = customerCart?.alice_cart;

  const calculateOrderValues = () => {
    let _subTotal: number = 0;
    aliceCart.forEach((product: IEcommerceProductData) => {
      _subTotal += product.unit_price * product.quantity;
    });

    let _total = Number(
      _subTotal +
        (orderInformation.zidShippingCost
          ? orderInformation.zidShippingCost
          : 0)
    );

    let _shippingCost = orderInformation.zidShippingCost;
    if (orderInformation.deliveryType === 'Free shipping') {
      _shippingCost = 0;
    }

    handleSetZidPropertyInformation([
      {
        key: 'ZidOrderInformation',
        value: {
          ...orderInformation,
          zidSubTotal: _subTotal,
          zidDiscount: 0,
          zidShippingCost: _shippingCost,
          zidTotal: _total,
        },
      },
    ]);
  };

  const handleAddMoreItem = () => {
    updateEcommerceStateData('openModal', true);
    setShowRightbar(false);
    updateEcommerceStateData('showProductView', true);
  };

  React.useEffect(
    () => {
      calculateOrderValues();
    },
    // eslint-disable-next-line
    [
      aliceCart,
      orderInformation.zidSubTotal,
      orderInformation.zidShippingCost,
      orderInformation.coupon,
      orderInformation.deliveryType,
    ]
  );

  const renderDropDownListBoxView = () => {
    return (
      <div className='flex flex-col gap-3 mb-4'>
        <div className=' gap-y-2 w-full items-center'>
          <div className='mb-3'>
            <CustomListBox
              key={'payment-method-dropdown'}
              orderMethodName={orderInformation.paymentMethod}
              dataList={paymentMethodTitles}
              methodName={'paymentMethod'}
              defaultTitle={t('Choose a payment method')}
              handleSetZidObjectInformation={handleSetZidObjectInformation}
            />
          </div>
          <CustomListBox
            key={'payment-method-dropdown-2'}
            orderMethodName={orderInformation.paymentStatus}
            dataList={['Paid', 'Pending']}
            methodName={'paymentStatus'}
            defaultTitle={t('Payment Status')}
            handleSetZidObjectInformation={handleSetZidObjectInformation}
          />
        </div>

        <div className='flex gap-2'>
          <div className='w-[74%]'>
            <CustomListBox
              key={'delivery-type-dropdown'}
              orderMethodName={orderInformation.deliveryType}
              dataList={shippingMethodNames}
              methodName={'deliveryType'}
              defaultTitle={'Choose delivery type'}
              handleSetZidObjectInformation={handleSetZidObjectInformation}
            />
          </div>
          <div
            className={`w-[25%] flex align-center justify-end rounded font-normal ${
              orderInformation.deliveryType === 'Free shipping'
                ? 'h-34px bg-gray-200 text-gray-500 border border-gray-300 text-xs font-normal pr-2'
                : ''
            }`}
          >
            {orderInformation.deliveryType === 'Free shipping' && (
              <span className='overflow-x-auto text-xs custom-text-overflow'>
                0
              </span>
            )}
            {orderInformation.deliveryType !== 'Free shipping' && (
              <input
                type='number'
                className='h-full w-full text-xs rounded border border-gray-300 focus:border-green-500
                              focus:ring-1 focus:ring-green-500 py-0.5 px-1.5'
                placeholder='Shipping Cost'
                name='shipping_cost'
                value={
                  orderInformation.zidShippingCost
                    ? orderInformation.zidShippingCost
                    : ''
                }
                onChange={(e) => {
                  handleSetZidObjectInformation({
                    objectKeyName: 'ZidOrderInformation',
                    childObjectKeyName: 'zidShippingCost',
                    value: Number(e.target.value),
                  });
                }}
              />
            )}
          </div>
        </div>

        <div>
          <Input
            type='text'
            value={orderInformation.coupon}
            placeholder='Apply a coupon'
            onChange={(e) => {
              const value = e.target.value;
              handleSetZidObjectInformation({
                objectKeyName: 'ZidOrderInformation',
                childObjectKeyName: 'coupon',
                value: value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderFooterButtonsView = () => {
    return (
      <div className='flex space-between text-sm border border-transparent border-t-gray-200 p-2 h-[7.35vh]'>
        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center`}
          onClick={() => setStep(1)}
        >
          Back
        </Button>

        <Button
          className={`w-[49%] h-[100%] rounded-md justify-center hover:border-primary focus:outline-none transition ease-out duration-20 ${
            !orderInformation?.paymentStatus || !orderInformation?.deliveryType
              ? 'bg-gray-100 text-gray-600'
              : 'bg-primary text-white'
          }`}
          disabled={
            !orderInformation?.paymentStatus || !orderInformation?.deliveryType
          }
          onClick={() => {
            if (
              !!orderInformation?.paymentStatus &&
              !!orderInformation?.deliveryType
            )
              setStep(3);
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderProductsView = () => {
    return (
      aliceCart &&
      aliceCart.map((product: IEcommerceProductData, id: number) => {
        return (
          <div className='box-border border border-transparent border-b-gray-200'>
            <SingleProduct
              key={id}
              product={product}
              currencySymble={currencySymbol}
            />
          </div>
        );
      })
    );
  };

  return (
    <div key={'config-shipping'} className='flex flex-col h-full'>
      {modalHeader}

      <div className='h-[86vh] create-wooCoomerce-order px-3 overflow-auto'>
        <div className='mb-4'>{renderProductsView()}</div>

        <button
          className='text-xs font-medium text-[#0078CF] mb-7'
          onClick={() => {
            handleAddMoreItem();
          }}
        >
          <p className='flex align-center'>
            <div className='h-[13px]'>
              <img src={editIcon} alt='edit-icon' />
            </div>
            &nbsp;
            <span className='leading-4'>Update cart</span>
          </p>
        </button>

        {renderDropDownListBoxView()}

        {renderZidOrderAmountsView(2)}
      </div>

      {renderFooterButtonsView()}
    </div>
  );
};

const mapState = (state: any) => ({
  customerCart: state.ecommerce.cart,
  paymentMethodList: state.ecommerce.paymentMethodList,
  shippingMethodList: state.ecommerce.shippingMethodList,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  zidDiscountCoupons: state.ecommerce.zidDiscountCoupons,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
});

export default connect(mapState, mapDispatch)(ConfigShipping);
