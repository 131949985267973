import useTranslation from 'components/customHooks/useTranslation';
import { EmptyStateMessage } from 'pages/inbox/utils/contents';
import React from 'react';

interface Props {
  isFilterApplied: boolean;
  isPrivateView: boolean;
  isUnassigned: boolean;
  isClosed: boolean;
  isBot: boolean;
}

interface IObj {
  img: any;
  title: string;
  subTitle: string;
}

const EmptyState: React.FC<Props> = ({
  isFilterApplied,
  isPrivateView,
  isUnassigned,
  isClosed,
  isBot,
}) => {
  const { t } = useTranslation();
  const EmptyObj: IObj = EmptyStateMessage(
    isFilterApplied,
    isUnassigned,
    isClosed,
    isPrivateView,
    isBot,
  );
  return (
    <div className='w-full h-full bg-gray-50 flex items-center justify-center'>
      <div className={`${isFilterApplied ? 'hidden' : 'block'}`}>
        <span className='flex justify-center'>{EmptyObj.img}</span>
        <p className='text-sm font-medium text-gray-700 text-center mt-6'>
          {t(EmptyObj.title)}
        </p>
        <p className='text-sm font-normal text-gray-500 text-center mt-1'>
          {t(EmptyObj.subTitle)}
        </p>
      </div>
    </div>
  );
};

export default EmptyState;
