import axiosClient from 'axios';
import { mixpanelUserGroup } from 'utilities/mixpanel';
import config from '../../../utilities/config';
import axios from '../../../utilities/httpClient';

const initialState = {
  projects: [],
  selectedProject: null,
  selectedPlatform: null,
  intentList: [],
  navIsOpen: false,
  navActiveId: 0,
  permission: { id: null, list: [] },
  platformList: [],
  activityData: [],
  projectProgressInfo: {},
  isNewUser: false,
  dashboardMarketingData: null, // this will be loaded on every login not from dashboard load
  billingInfo: null,
  salesInfo: null,
  customerInfo: null,
  ticketInfo: null,
  ratingInfo: null,
  navBarMenu: null,
  agentAccess: [],
  isSuperProject: false, // this state can be used to justify if a project has super access to all features. super project is hard coded
  showUpgradeModal: false,
};

export const dashboard = {
  state: {
    ...initialState,
  },
  reducers: {
    updateProject(state, payload) {
      /*
      payload = [{
        id: int,
        name: string,
        slug: string,
        image: string,
        platforms: [
          {
            id: int,
            name: string,
            type: string
          }
        ]
      }...]
      */
      return { ...state, projects: payload };
    },
    updateDashboardMarketingData(state, payload) {
      return { ...state, dashboardMarketingData: payload };
    },
    updateActivityData(state, payload) {
      return { ...state, activityData: payload };
    },
    updateNavToggle(state) {
      return { ...state, navIsOpen: !state.navIsOpen };
    },
    updateSelectedNav(state, navId) {
      return { ...state, navActiveId: navId };
    },
    updateIntents(state, payload) {
      /* payload = list of string for intents */
      return { ...state, intentList: payload };
    },
    updateStateData(state, { key, value }) {
      return { ...state, [key]: value };
    },
    updateSelectedProject(state, payload) {
      /*
      payload: object
      */
      return { ...state, selectedProject: payload };
    },
    updateSelectedProjectToPro(state) {
      return {
        ...state,
        selectedProject: { ...state.selectedProject, is_pro: true },
      };
    },
    updatePermission(state, payload) {
      //payload= list of permission
      return { ...state, permission: payload };
    },
    setSelectedPlatform(state, payload) {
      return { ...state, selectedPlatform: payload };
    },
    updatePlatformList(state, payload) {
      return { ...state, platformList: [...payload] };
    },
    replaceChannelList(state, payload) {
      const localChannelList = state.platformList.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }
        return channel;
      });
      return { ...state, platformList: localChannelList };
    },
    appendPlatformList(state, payload) {
      return { ...state, platformList: [...state.platformList, payload] };
    },
    updateProjectProgressInfo(state, payload) {
      return { ...state, projectProgressInfo: payload };
    },
    updateCreatedNewTeam(state, payload) {
      return { ...state, createdNewTeam: payload };
    },
    cleanHistory(state) {
      return { ...state, selectedProject: null, selectedPlatform: null };
    },
    clearState() {
      return { ...initialState };
    },
    updateNavBarMenu(state, payload) {
      return { ...state, navBarMenu: payload };
    },
    updateAgentAccess(state, payload) {
      //payload= list of permission
      return { ...state, agentAccess: payload };
    },
    updateIsSuperProject(state, payload) {
      let isSuperProject = [343, 254].includes(payload);
      return { ...state, isSuperProject: isSuperProject };
    },
    updateShowUpgradeModal(state, payload) {
      return { ...state, showUpgradeModal: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchProject() {
      try {
        const res = await axios.get(config.dashboard.projects());
        if (res.data.success) {
          await dispatch.dashboard.updateProject(res.data.dataSource);
        } else {
          await dispatch.dashboard.updateProject([]);
        }
        return { data: res.data.dataSource, success: true };
      } catch (err) {
        console.log(err);
        return { data: null, success: false };
      }
    },
    async fetchTeamDetails(teamId) {
      try {
        const res = await axios.get(config.dashboard.teamDetails(teamId));

        mixpanelUserGroup(teamId, {
          name: res.data?.dataSource?.name,
          has_ecommerce: res.data?.dataSource?.has_ecommerce_connected,
          ecommerce_type: res.data?.dataSource?.ecommerce_type,
          store_url: res.data?.dataSource?.ecommerce_store_url,
          subscription_plan: res.data?.dataSource?.subscription_plan?.name,
          currecny_code: res.data?.dataSource?.currecny_code,
          platforms: res.data?.dataSource?.platforms.length,
          is_using_new_billing: res.data?.dataSource?.is_using_new_billing,
          team_members: res.data?.dataSource?.team_members,
        });
        if (res.data.success) {
          dispatch.dashboard.updatePlatformList(res.data.dataSource.platforms);
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchIntent(payload) {
      /*
      payload = {
        platformId : num
        }
       */
      try {
        const res = await axios.get(config.dashboard.intent(payload));
        if (res.data.success) {
          dispatch.dashboard.updateIntents(res.data.dataSource);
        } else {
          dispatch.dashboard.updateIntents([]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async setSelectedProject(payload, rootState) {
      /*
      payload: object of project
      */

      try {
        if (payload) {
          if (!rootState.loading.effects.dashboard.fetchRolePermission) {
            if (rootState.dashboard.permission?.id !== payload?.id) {
              await dispatch.dashboard.fetchRolePermission(payload?.id);
            }
          }
          const data = await dispatch.dashboard.fetchTeamDetails(payload.id);
          await dispatch.settings.setSelectedProject(data);
          dispatch.dashboard.updateIsSuperProject(payload.id);

          await dispatch.dashboard.updateSelectedProject(data);
          await dispatch.dashboard.setSelectedPlatform(data?.platforms[0]);
          return data;
        }
        return true;
      } catch (error) {
        console.log(error.response);
      }
    },
    async fetchRolePermission(teamId) {
      /*
      teamId : num // project Id
       */
      try {
        const res = await axios.get(config.dashboard.permission(teamId));
        if (res.status === 200 && res.data.success) {
          await dispatch.dashboard.updatePermission({
            id: teamId,
            list: res.data.dataSource,
          });
          return {
            id: teamId,
            list: res.data.dataSource,
          };
        } else {
          await dispatch.dashboard.updatePermission({ id: teamId, list: [] });
          return { id: teamId, list: [] };
        }
      } catch (err) {
        console.log(err?.response || err);
        return { id: teamId, list: [] };
      }
    },
    async fetchDashboardMarketingData() {
      try {
        const res = await axiosClient.get(
          config.dashboard.dashboardMarketingData()
        );
        dispatch.dashboard.updateDashboardMarketingData(res.data);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchDashboardBillingInfo(teamId) {
      try {
        const res = await axios.get(config.dashboard.billingCard(teamId));
        if (res.data.success) {
          dispatch.dashboard.updateStateData({
            key: 'billingInfo',
            value: res.data.dataSource,
          });
        }
        return null;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchSalesCard(teamId) {
      try {
        const res = await axios.get(config.dashboard.salesCard(teamId));
        if (res.data.success) {
          dispatch.dashboard.updateStateData({
            key: 'salesInfo',
            value: res.data.dataSource,
          });
        }
        return null;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCustomerCard(teamId) {
      try {
        const res = await axios.get(config.dashboard.customerCard(teamId));
        if (res.data.success) {
          dispatch.dashboard.updateStateData({
            key: 'customerInfo',
            value: res.data.dataSource,
          });
        }
        return null;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTicketCard(teamId) {
      try {
        const res = await axios.get(config.dashboard.ticketCard(teamId));
        if (res.data.success) {
          dispatch.dashboard.updateStateData({
            key: 'ticketInfo',
            value: res.data.dataSource,
          });
        }
        return null;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchRatingCard(teamId) {
      try {
        const res = await axios.get(config.dashboard.ratingCard(teamId));
        if (res.data.success) {
          dispatch.dashboard.updateStateData({
            key: 'ratingInfo',
            value: res.data.dataSource,
          });
        }
        return null;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchPlatformList(teamId) {
      /*
      teamId : num // team Id
       */
      try {
        const res = await axios.get(config.dashboard.channelList(teamId));
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updatePlatformList(res.data.dataSource);
        } else {
          dispatch.dashboard.updatePlatformList([]);
        }
      } catch (err) {
        console.log(err?.response || err);
        dispatch.dashboard.updatePlatformList([]);
      }
    },
    // async fetchDashboardActivity(teamId) {
    //   /*
    //   id : num // project Id
    //    */
    //   try {
    //     const res = await axios.get(config.dashboard.activity(teamId));
    //     if (res.status === 200 && res.data.success) {
    //       dispatch.dashboard.updateActivityData(res.data.dataSource);
    //     } else {
    //       dispatch.dashboard.updateActivityData([]);
    //     }
    //   } catch (err) {
    //     dispatch.dashboard.updateActivityData([]);
    //   }
    // },

    // async fetchProjectProgress(payload) {
    //   /*
    //   teamId : num // project Id
    //   email: string // user email
    //    */
    //   try {
    //     const res = await axios.post(
    //       config.dashboard.projectProgress(payload.teamId),
    //       { email: payload.email }
    //     );
    //     if (res.data.success) {
    //       dispatch.dashboard.updateProjectProgressInfo(res.data['dataSource']);
    //     }
    //   } catch (err) {
    //     dispatch.dashboard.updateProjectProgressInfo({});
    //   }
    // },

    async fetchDashboardCardData(teamId) {
      dispatch.dashboard.fetchDashboardBillingInfo(teamId);
      dispatch.dashboard.fetchSalesCard(teamId);
      dispatch.dashboard.fetchCustomerCard(teamId);
      dispatch.dashboard.fetchTicketCard(teamId);
      dispatch.dashboard.fetchRatingCard(teamId);
    },
    async cleanProjectHistory() {
      dispatch.api.clearState();
      dispatch.builder.clearState();
      dispatch.block.clearState();
      dispatch.settings.clearState();
      dispatch.api.clearState();
      dispatch.dataLab.clearState();
      dispatch.labDetails.clearState();
      dispatch.audience.clearState();
      dispatch.reporting.clearState();
      dispatch.marketplace.clearState();
      dispatch.rules.clearState();
      dispatch.crm.clearState();
      dispatch.channel.clearState();
      dispatch.crmEcommerce.clearState();
      dispatch.workflowAutomation.clearState();
    },
    async fetchAgentAccess(projectId) {
      try {
        const res = await axios.get(config.settings.agentAccess(projectId));
        if (res.status === 200 && res.data.success) {
          dispatch.dashboard.updateAgentAccess(res.data.dataSource);
          return res.data.dataSource;
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAccess(payload) {
      try {
        const res = await axios.get(
          config.crm.editNotification(payload.teamId, payload.userId)
        );
        if (res.data.success) {
          return res.data.dataSource;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchAssignmentCount(projectId) {
      try {
        const res = await axios.get(
          config.dashboard.ticketAssignCount(projectId)
        );
        if (res.data.success) {
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async fetchAssignmentReport(projectId) {
      try {
        const res = await axios.get(
          config.dashboard.ticketAssignReport(projectId)
        );
        if (res.data.success) {
          return [...res.data.dataSource];
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async fetchAssignedAgent(projectId) {
      try {
        const res = await axios.get(config.dashboard.assignedAgents(projectId));
        if (res.data.success) {
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async fetchAssignmentAgentCount(payload) {
      try {
        const res = await axios.get(
          config.dashboard.ticketCountOfAgent(payload?.projectId),
          {
            params: { agent_id: payload?.user_id },
          }
        );
        if (res.data.success) {
          return res.data.dataSource;
        } else {
          return null;
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  }),
};
