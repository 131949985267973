import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface drop {
  index: number;
  value: string | number | boolean;
  show?: string | number | boolean;
  editField: any;
  userRolesType: any;
}

const SimpleDropDown: React.FC<drop> = ({
  index,
  value,
  show,
  editField,
  userRolesType,
}) => {
  const { t } = useTranslation();
  return (
    <div className='relative inline-block text-left'>
      <div>
        <button
          type='button'
          className='inline-flex justify-center w-full rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'
          id='menu-button'
          aria-expanded='true'
          aria-haspopup='true'
          onClick={() => editField(index, !show, 'show')}
        >
          {/* @ts-ignore */}
          {t(userRolesType.filter((role) => role.id === value)[0].name)}
          <svg
            className='ltr:-mr-1 rtl:-ml-1 ltr:ml-2 rtl:mr-2 h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </div>
      {show && (
        <div
          className='z-10 ltr:origin-top-right rtl:origin-top-left absolute ltr:right-0 rtl:left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
          tabIndex={-1}
        >
          <div className='py-1' role='none'>
            {userRolesType.map((user: any) => {
              return (
                <p
                  className='text-gray-700 block px-4 py-2 text-sm'
                  role='menuitem'
                  tabIndex={-1}
                  id='menu-item-0'
                  onClick={() => {
                    editField(index, user.id, 'role_id');
                  }}
                >
                  {t(user.name)}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleDropDown;
