import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '../../../utilities/utils';

const TextCharInfo = ({ data, count }) => {
  return (
    <span
      className={classNames(
        data?.length >= count
          ? 'bg-red-100 text-red-800'
          : 'bg-gray-100 text-gary-600',
        'inline-flex float-right items-center px-2 py-0.5 rounded text-xs font-medium'
      )}
    >
      {data?.length}/{count}
    </span>
  );
};

TextCharInfo.propTypes = {
  data: PropTypes.string,
  count: PropTypes.number,
};
TextCharInfo.defaultProps = {
  data: '',
  count: 0,
};

export default TextCharInfo;
