import React, { useEffect, useState } from 'react';
import BlockContainer from './BlockContainer';
import InputInstruction from '../elements/eCommerceBlock/InputInstruction';
import InputOutputViewNavBar from '../elements/eCommerceBlock/InputOutputViewNavBar';
import CouponDiscoveryBlockIcon from '../../pages/builder/assets/images/icons/coupon_block.svg';
import { BlocksData } from '../../../utilities/content';
import TextAreaField from '../elements/eCommerceBlock/TextAreaField';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  type: string;
  labsData: [];
  platformType: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  selectedProjectId: number;
  handleCouponBlock: (
    payloadData: {},
    actionType: string,
    instructionType: string,
    shouldSave: boolean
  ) => void;
}

interface dataProps {
  input_instructions: [];
  output_instructions: { text_message: string };
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

const CouponDiscoveryBlock: React.FC<Props> = ({
  id,
  data,
  save,
  type,
  labsData,
  platformType,
  attributeList,
  sequences,
  saveLoader,
  saveBlock,
  deleteBlock,
  copyBlock,
  moveBlock,
  hasWriteAccess,
  handleCouponBlock,
}) => {
  const tabData = [
    { id: 0, name: 'Input Instructions' },
    { id: 1, name: 'Output View' },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState({
    id: 0,
    name: 'Input Instructions',
  });
  const [showInputAttributeField, setShowInputAttributeField] = useState(null);
  const [showOutputAttributeField, setShowOutputAttributeField] =
    useState(false);

  let inputParameters =
    BlocksData.couponDiscoveryBlock.woocommerce.input_parameters;
  let outputParameters =
    BlocksData.couponDiscoveryBlock.woocommerce.output_parameters;

  const handleTabChange = (tab: any) => {
    setCurrentTabIndex(tab);
  };

  const handleSelectedField = (selectedParameter: any) => {
    handleCouponBlock(selectedParameter, 'add', 'input_instructions', false);
  };

  const deleteInputInstruction = (vaerbose: string, id: any) => {
    handleCouponBlock({ index: id }, 'delete', 'input_instructions', false);
  };

  const handleSelectedFieldInputValue = (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => {
    let currentValue = value;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{') {
      setShowOutputAttributeField(true);
      setShowInputAttributeField(
        changeKey === 'input_instructions' ? id : name
      );
    } else {
      setShowInputAttributeField(null);
      setShowOutputAttributeField(false);
    }
    handleCouponBlock({ index: id, value: value }, 'update', changeKey, false);
  };

  const handleSelectedFieldAttributeValue = (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => {
    setShowInputAttributeField(null);
    setShowOutputAttributeField(false);
    let newValue =
      changeKey === 'input_instructions'
        ? data?.input_instructions[id]['value'] + `${value}}}`
        : data?.output_instructions.text_message + `${value}}}`;
    handleCouponBlock(
      { index: id, value: newValue },
      'update',
      changeKey,
      false
    );
  };

  useEffect(() => {
    if (data?.input_instructions.length === 0) {
      inputParameters.forEach((elem: any) => {
        if (elem.isPinned) {
          handleCouponBlock(
            {
              name: elem.name,
              verbose: elem.verbose,
              type: elem.type,
              value: elem.default,
            },
            'add',
            'input_instructions',
            true
          );
        }
      });
    }
    if (data?.input_instructions.length === 0) {
      handleCouponBlock(
        { text_message: BlocksData.couponDiscoveryBlock.outputMessage },
        'add',
        'output_instructions',
        true
      );
    }
  });
  return (
    <BlockContainer
      id={id}
      title={BlocksData.couponDiscoveryBlock.title}
      subtitle={BlocksData.couponDiscoveryBlock.subTitle}
      save={save}
      isLoading={saveLoader}
      icon={CouponDiscoveryBlockIcon}
      sequences={sequences}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <InputOutputViewNavBar
          tabData={tabData}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
        />
        <br />
        <div className='border-t border-gray-200 mb-2.5 w-full' />
        {currentTabIndex.id === 0 ? (
          <InputInstruction
            inputParameters={data?.input_instructions}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttributeField={showInputAttributeField}
            sequences={sequences}
            platformType={platformType}
            hasSequences={false}
            setShowAttributeField={(val: any) =>
              setShowInputAttributeField(val)
            }
            handleParameterDelete={deleteInputInstruction}
            ecommerceParameters={inputParameters}
            handleSelectedParameters={handleSelectedField}
            handleSelectedParamsInputValue={handleSelectedFieldInputValue}
            handleSelectedParamsAttributeValue={
              handleSelectedFieldAttributeValue
            }
          />
        ) : (
          <TextAreaField
            value={data.output_instructions?.text_message}
            attributeList={outputParameters}
            showAttribute={showOutputAttributeField}
            setShowAttribute={(val: any) => setShowOutputAttributeField(val)}
            handleOnChange={(value: string) =>
              handleSelectedFieldInputValue(
                0,
                'output',
                value,
                'output_instructions'
              )
            }
            handleSelect={(value: string) =>
              handleSelectedFieldAttributeValue(
                0,
                'output',
                value,
                'output_instructions'
              )
            }
            hasWriteAccess={hasWriteAccess}
            placeHolder={BlocksData.couponDiscoveryBlock.placeHolder}
          />
        )}
      </>
    </BlockContainer>
  );
};

CouponDiscoveryBlock.defaultProps = {
  hasWriteAccess: false,
};
export default CouponDiscoveryBlock;
