// TODO: To be refactored..
import React from 'react';
import { Fragment } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Combobox, Transition } from '@headlessui/react';
import defaultAvatar from '../../../../assets/images/default-avatar.png';
import { renderAgentStatusBadgeColor } from '../../../../utils/functions';
import useTranslation from 'components/customHooks/useTranslation';
import useMiddleBarHook from '../../../../hooks/useMiddleBarHook';

import {
  TicketInterface,
  AgentListInterface,
} from '../../../../inboxInterface';

interface Props {
  close: Function;
  userId: number;
  agentGroup: GroupInterface[];
  agentList: AgentListInterface[];
  shouldRenderSelectedAgent: boolean;
  selectedTicket: TicketInterface;
  handleNoAgentMatchFound: (word: string) => void;
  handleNoGroupMatchFound: (word: string) => void;
  handleSelectedAgentId: (agentId: string | number | null) => void;
  handleSelectedGroupId: (groupId: string | number | null) => void;
  handleAgentOrGroupMatchFound: (value: boolean) => void;
}

const UserOrGroupSelectionComponent: React.FC<Props> = ({
  close,
  userId,
  agentList,
  agentGroup,
  selectedTicket,
  handleNoAgentMatchFound,
  handleNoGroupMatchFound,
  handleSelectedAgentId,
  handleSelectedGroupId,
  shouldRenderSelectedAgent,
  handleAgentOrGroupMatchFound,
}) => {
  const { t } = useTranslation();
  const { teamOhsogoCustomModificationObject } = useMiddleBarHook();

  const [searchDataAgent, setSearchDataAgent] = React.useState<
    AgentListInterface[] | null
  >(agentList && agentList.length > 0 ? agentList : null);
  const [searchDataGroup, setSearchDataGroup] = React.useState<
    GroupInterface[] | null
  >(agentGroup && agentGroup.length > 0 ? agentGroup : null);
  const [selectedAgent, setSelectedAgent] = React.useState<
    string | number | null
  >(selectedTicket?.assigned_agent || selectedTicket?.assigned_group || null);
  const [hasClickedOnAgent, setHasClickedOnAgent] = React.useState(
    shouldRenderSelectedAgent
  );

  const renderAgentNames = (name: string) => {
    if (name.length > 15) {
      return name.substring(0, 15) + '...';
    } else {
      return name;
    }
  };

  const renderGroupNames = (name: string) => {
    if (name.length > 10) {
      return name.substring(0, 9) + '...';
    } else {
      return name;
    }
  };

  const renderDropdownLabel = () => {
    if (selectedTicket.is_resolved) {
      return t('Assign & Reopen');
    } else {
      return t(' Assign');
    }
  };

  const filterAgentData = (agentName: string) => {
    let agentData =
      agentList &&
      agentList.filter((agent) =>
        agent?.admin.full_name
          .toLowerCase()
          .includes(agentName.trim().toLowerCase())
      );
    return agentData;
  };

  const filterGroupData = (groupName: string) => {
    let groupData =
      agentGroup &&
      agentGroup.filter((group) =>
        group?.name.toLowerCase().includes(groupName.trim().toLowerCase())
      );
    return groupData;
  };

  const renderSearchFieldView = () => {
    return (
      <>
        <div className='absolute inset-y-0 flex items-center pointer-events-none ltr:left-0 rtl:right-0 ltr:pl-5 rtl:pr-5'>
          <MagnifyingGlassIcon
            className='w-5 h-5 text-gray-400'
            aria-hidden='true'
          />
        </div>

        <input
          onChange={(e) => {
            if (e.target.value === '') {
              handleNoAgentMatchFound(e.target.value);
              handleNoGroupMatchFound(e.target.value);
              handleAgentOrGroupMatchFound(false);
              return (
                setSearchDataAgent(agentList), setSearchDataGroup(agentGroup)
              );
            } else if (e.target.value !== '') {
              let agentData = filterAgentData(e.target.value);
              let groupData = filterGroupData(e.target.value);
              setSearchDataAgent(agentData);
              setSearchDataGroup(groupData);
              handleAgentOrGroupMatchFound(true);

              agentData.length === 0
                ? handleNoAgentMatchFound(e.target.value)
                : handleNoAgentMatchFound('');

              groupData.length === 0
                ? handleNoGroupMatchFound(e.target.value)
                : handleNoGroupMatchFound('');
            }
          }}
          placeholder={t('Search Agents or Groups')}
          autoComplete='new-password'
          onKeyDown={(e: any) => {
            e.code === 'Space' && e.stopPropagation();
          }}
          className='w-full py-2 pl-10 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm '
        />
      </>
    );
  };

  return (
    <div>
      <Combobox as='div' value={''} onChange={() => console.log('')}>
        <div className='flex w-full'>
          <div className='w-2/3'>
            <Combobox.Label className='px-4 py-4 text-base font-semibold text-gray-800'>
              {renderDropdownLabel()}
            </Combobox.Label>
          </div>
          <div className='flex justify-end w-1/3 px-2 text-gray-500'>
            <XMarkIcon
              onClick={() => {
                handleSelectedAgentId(null);
                handleSelectedGroupId(null);
                handleAgentOrGroupMatchFound(false);
                handleNoAgentMatchFound('');
                handleNoGroupMatchFound('');
                close();
              }}
              className='w-5 h-5 cursor-pointer'
              aria-hidden='true'
            />
          </div>
        </div>
        {
          <div>
            {!teamOhsogoCustomModificationObject?.isUserRestrictedToAssignOtherAgentOrGroup && (
              <Combobox.Button className='relative w-full px-3 mt-3'>
                {renderSearchFieldView()}
              </Combobox.Button>
            )}
            <Transition
              show={true}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Combobox.Options className='relative z-10 flex flex-col w-full gap-3 mt-0.5 overflow-auto text-base bg-white border-transparent max-h-16 focus:outline-none sm:text-sm scroll-none'>
                {searchDataAgent
                  ?.filter((item) => item?.admin.id === userId)
                  ?.map((item: AgentListInterface, index: number) => (
                    <label
                      className='flex px-6 py-1 mt-1 cursor-pointer'
                      key={index}
                    >
                      <input
                        autoComplete='nope'
                        type='checkbox'
                        className='mt-1.5 text-primary focus:ring-primary rounded-full'
                        onChange={() => {
                          setHasClickedOnAgent(true);
                          setSelectedAgent(item.admin.id);
                          handleSelectedGroupId(null);
                          handleSelectedAgentId(item?.admin?.id);
                        }}
                        checked={
                          selectedAgent === item.admin.id && hasClickedOnAgent
                        }
                        name={item.admin.full_name}
                      />
                      <div className='relative ltr:ml-3 rtl:mr-3'>
                        <img
                          src={
                            item.admin.avatar
                              ? item.admin.avatar
                              : defaultAvatar
                          }
                          alt='icon_box'
                          className='object-cover border border-gray-100 rounded-full h-7 w-7'
                        />
                        <span
                          className={`absolute -bottom-1 right-0 inline-block w-3 h-3 ${renderAgentStatusBadgeColor(
                            item?.admin.status
                          )} border-2 border-white rounded-full`}
                        />
                      </div>
                      <p className='mt-1 rtl:mr-3 ltr:ml-3'>{t('You')}</p>
                    </label>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        }
        {!!searchDataAgent &&
          searchDataAgent?.length !== 0 &&
          !teamOhsogoCustomModificationObject?.isUserRestrictedToAssignOtherAgentOrGroup && (
            <div>
              <div className='mt-1'>
                <div className='w-full px-3 py-1 text-xs font-medium text-gray-400 border border-gray-100'>
                  {t('Agents')}
                </div>
              </div>
              <Transition
                show={true}
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Combobox.Options className='relative z-10 flex flex-col w-full h-32 gap-3 mt-1 overflow-auto text-base bg-white border-transparent focus:outline-none sm:text-sm scroll-none'>
                  {searchDataAgent
                    ?.filter((item) => item?.admin.id !== userId)
                    ?.map((item: AgentListInterface, index: number) => (
                      <label
                        className='flex px-6 mt-1 cursor-pointer'
                        key={index}
                      >
                        <input
                          autoComplete={'nope'}
                          type='checkbox'
                          id={item.admin.full_name}
                          className='mt-1.5 text-primary focus:ring-primary rounded-full'
                          onChange={() => {
                            setHasClickedOnAgent(true);
                            setSelectedAgent(item.admin.id);
                            handleSelectedGroupId(null);
                            handleSelectedAgentId(item.admin.id);
                          }}
                          checked={
                            selectedAgent === item.admin.id && hasClickedOnAgent
                          }
                          name={item.admin.full_name}
                        />
                        <div
                          className='relative ltr:ml-3 rtl:mr-3'
                          data-for={item.admin.full_name}
                        >
                          <img
                            src={
                              !!item.admin.avatar
                                ? item.admin.avatar
                                : defaultAvatar
                            }
                            alt={'icon_box'}
                            className={
                              'h-7 w-7 rounded-full object-cover border border-gray-100'
                            }
                          />
                          <span
                            className={`absolute -bottom-1 right-0 inline-block w-3 h-3 ${renderAgentStatusBadgeColor(
                              item?.admin.status
                            )} border-2 border-white rounded-full`}
                          />
                        </div>
                        <p className='mt-1 ltr:ml-1 rtl:mr-3'>
                          {renderAgentNames(item.admin.full_name)}
                        </p>
                      </label>
                    ))}
                </Combobox.Options>
              </Transition>
            </div>
          )}
        {!!searchDataGroup &&
          searchDataGroup?.length !== 0 &&
          !teamOhsogoCustomModificationObject?.isUserRestrictedToAssignOtherAgentOrGroup && (
            <div className='mt-1'>
              <div className='mt-2'>
                <div className='w-full px-3 py-1 text-xs font-medium text-gray-400 border border-gray-100'>
                  {t('Groups')}
                </div>
              </div>
              <Transition
                show={true}
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Combobox.Options className='relative z-10 flex flex-col w-full h-32 gap-3 mt-1 overflow-auto text-base bg-white border-transparent focus:outline-none sm:text-sm scroll-none'>
                  {searchDataGroup?.map(
                    (groupData: GroupInterface, index: number) => (
                      <label
                        className='flex px-6 mt-1 cursor-pointer'
                        key={index}
                      >
                        <input
                          type='checkbox'
                          className='mt-1.5 text-primary focus:ring-primary rounded-full'
                          onChange={() => {
                            setHasClickedOnAgent(true);
                            setSelectedAgent(groupData?.id);
                            handleSelectedAgentId(null);
                            handleSelectedGroupId(groupData?.id);
                          }}
                          name={groupData?.name}
                          checked={
                            selectedAgent === groupData?.id && hasClickedOnAgent
                          }
                        />
                        <div className='ltr:ml-3 rtl:mr-3'>
                          <img
                            src={
                              !!groupData?.creator?.avatar
                                ? groupData?.creator?.avatar
                                : defaultAvatar
                            }
                            alt={'icon_box'}
                            className={
                              'h-7 w-7 rounded-full object-cover border border-gray-100'
                            }
                          />
                        </div>
                        <p className='mt-1 ltr:ml-2 rtl:mr-3'>
                          {renderGroupNames(groupData?.name)}
                        </p>{' '}
                        {groupData?.is_smart && (
                          <div className='flex items-center'>
                            <label className='px-1 py-0.5 ml-3 text-xs text-gray-800 bg-green-100 rounded'>
                              {t('Smart')}
                            </label>
                          </div>
                        )}
                      </label>
                    )
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          )}
      </Combobox>
    </div>
  );
};

export default UserOrGroupSelectionComponent;
