import React from 'react';
import BlockContainer from './BlockContainer';
import PropTypes from 'prop-types';
import delayIcon from '../../assets/images/typing.svg';
import '../../assets/styles/blocks/global-style.scss';

const DelayBlock = ({
  id,
  sequences,
  deleteBlock,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
}) => {
  return (
    <BlockContainer
      id={id}
      title='Delay'
      subtitle='Use this block to add a delay effect in between your messages.'
      sequences={sequences}
      save={true}
      isLoading={saveLoader}
      icon={delayIcon}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
      isExpandable={false}
    ></BlockContainer>
  );
};
DelayBlock.propTypes = {
  id: PropTypes.number.isRequired,
  deleteBlock: PropTypes.func.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
};

DelayBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
  hasWriteAccess: false,
};

export default DelayBlock;
