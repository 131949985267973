import React from 'react';
import BillIcon from '../../../assets/icon/marketplace/BillIcon.svg';
import LocationIcon from '../../../assets/icon/marketplace/LocationIcon.svg';
import DeliveryIcon from '../../../assets/icon/marketplace/deliveryIcon.svg';
import OrderCardSummaryList from './OrderCardSummaryList';
import OrderCardSummaryTableItem from './OrderCardSummaryTableItem';
import CopyText from '../../../../../components/elements/redesign/CopyText';

interface Prop {
  eComType?: string;
  billingAddress: EcomAddressProps;
  shippingAddress: EcomAddressProps;
  totalTax: string;
  totalCost: string;
  shippingCost: string;
  discount: string;
  shippingMethod: string;
  refund: string;
  coupon: string[];
  note?: string;
  currencySymbol: string;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
}

const OrderCardSummary: React.FC<Prop> = ({
  eComType,
  billingAddress,
  shippingAddress,
  totalTax,
  totalCost,
  shippingCost,
  discount,
  shippingMethod,
  refund,
  coupon,
  note,
  currencySymbol,
}) => {
  const parseCoupon = (data: string[]) => {
    let result: string = '';
    data.map((coupon: string) => (result = result + coupon + ', '));
    return result;
  };

  return (
    <div className='order-right-bar__order-card__summary'>
      <OrderCardSummaryList
        icon={BillIcon}
        title='Billing Address'
        address={billingAddress}
      />
      <OrderCardSummaryList
        icon={LocationIcon}
        title='Shipping Address'
        address={shippingAddress}
      />
      <OrderCardSummaryList
        icon={DeliveryIcon}
        title='Shipping Method'
        description={shippingMethod}
      />
      <div className='alice-hr' />
      <br />
      <OrderCardSummaryTableItem
        title='Items Total'
        value={`${currencySymbol}${
          parseFloat(totalCost) +
          parseFloat(discount) -
          (parseFloat(totalTax) + parseFloat(shippingCost))
        }`}
      />
      <OrderCardSummaryTableItem
        title='Taxes'
        value={`${currencySymbol}${totalTax}`}
      />
      <OrderCardSummaryTableItem
        title='Delivery Charges'
        value={`${currencySymbol}${shippingCost}`}
      />
      <OrderCardSummaryTableItem
        title='Discount'
        value={`${currencySymbol}${discount}`}
      />
      <OrderCardSummaryTableItem
        title='Total'
        value={`${currencySymbol}${totalCost}`}
        isBold={true}
      />
      <OrderCardSummaryTableItem
        title='Refunded'
        value={`${currencySymbol}${refund}`}
      />
      <OrderCardSummaryTableItem
        title='Net Payment'
        value={`${currencySymbol}${parseFloat(totalCost) - parseFloat(refund)}`}
        isBold={true}
      />
      <br />
      <div className='alice-hr' />
      <div className='flex align-center mt-15px'>
        <p className='order-right-bar__order-card__summary__info-container-title'>
          Coupon:
        </p>{' '}
        &nbsp;
        <p className={coupon.length > 0 ? 'order-right-bar__order-card__summary__info-container-subtitle': 'order-right-bar__order-card__summary__info-container-title'}>
          {coupon.length > 0 ? parseCoupon(coupon) : 'Not Available'}
        </p>{' '}
        &nbsp;
        {coupon.length !== 0 && <CopyText textToCopy={parseCoupon(coupon)} />}
      </div>
      {eComType === 'shopify' &&
        <div className='flex align-center'>
          <p className='order-right-bar__order-card__summary__info-container-title'>
            Note:
          </p>{' '}
          &nbsp;
          <p className={note ? 'order-right-bar__order-card__summary__info-container-subtitle': 'order-right-bar__order-card__summary__info-container-title'}>
            {note || 'Not Available'}
          </p>{' '}
          &nbsp;
          {note &&note.length !== 0 && <CopyText textToCopy={note} />}
        </div>
      }
    </div>
  );
};

export default OrderCardSummary;
