import {
  React,
  Button,
  BoltIcon,
  useCallback,
  ConditionNodeIcon,
  useAutomationWorkflow,
} from '../../../../export';

import { NodeType } from '../../../../interface';

interface Props {
  id: string;
  data: any;
}

const ActionConditionButtons: React.FC<Props> = ({ id, data }) => {
  const { updatePrimitiveStateData } = useAutomationWorkflow();

  const handleOnClick = useCallback((nodeType: NodeType) => {
    updatePrimitiveStateData({ key: 'showSlider', value: true });
    updatePrimitiveStateData({
      key: 'selectedNodeType',
      value: nodeType,
    });
    // eslint-disable-next-line
  }, []);

  const renderConditionButton = () => {
    return (
      <Button
        className={`text-[#FB923C] bg-white flex items-center z-50 border border-gray-200 hover:bg-gray-50 hover:border-gray-200`}
        icon={<ConditionNodeIcon strokeColor='#FB923C' />}
        onClick={() => handleOnClick(NodeType.CONDITION)}
      >
        Condition
      </Button>
    );
  };

  const renderActionButton = () => {
    return (
      <Button
        className='ml-3 bg-white text-indigo-400 border-gray-200 hover:bg-gray-50 hover:border-gray-200'
        icon={<BoltIcon className='text-indigo-400' />}
        onClick={() => handleOnClick(NodeType.ACTION)}
      >
        Action
      </Button>
    );
  };

  return (
    <div className='z-50 w-auto h-auto p-2 bg-white rounded-lg drop-shadow-lg'>
      <div className='flex w-full'>
        {renderConditionButton()}
        {renderActionButton()}
      </div>
    </div>
  );
};

export default ActionConditionButtons;
