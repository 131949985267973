import React from 'react';
import { connect } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useDebounce } from '../../../../../../components';
import OrderCardRightbar from '../eCommerceOrderFragments/OrderCardRightbar';
import ProductState from '../eCommerceOrderCreate/components/ProductState';

interface Prop {
  projectId: number;
  ecommerceType: string;
  searchOrder: (projectId: number, search: string | number) => void;
  ecommerceOrderList: EcomOrderProps[];
  searchOrderLoading: boolean;
  updateStateData: (key: string, val: any) => void;
  currencySymbol: string;
}

interface EcomOrderProps {
  ecommerce_order_id: number;
  permalink: string;
  date_paid: string;
  date_completed: string;
  coupon_info: string;
  status: string;
  payment_status: string;
  total_refund: number;
  created_at: string;
  total_cost: number;
  payment_method: string;
  billing_address: EcomAddressProps | null;
  shipping_address: EcomAddressProps | null;
  total_tax: number;
  total_shipping_cost: number;
  total_discount: number;
  shipping_method: string;
  line_items: ProductDataProps[];
  id: string;
  products_url: string;
  shopify_order_name: string;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
}

interface ProductDataProps {
  product_id: string | number;
  product_name: string;
  product_link: string;
  product_images: string[];
  product_sku?: string;
  unit_price: number;
  in_stock: boolean;
  stock_count: number;
  has_variations: boolean;
  variant_id: string;
  variant_attributes: VariantAttributeProps[];
  variant_attributes_string: string;
  quantity: number;
  total_cost: number;
}

interface VariantAttributeProps {
  id: number;
  name: string;
  option: string;
}

const Wrapper: React.FC<Prop> = ({
  projectId,
  ecommerceType,
  searchOrder,
  ecommerceOrderList,
  searchOrderLoading,
  updateStateData,
  currencySymbol,
}) => {
  const [search, setSearch] = React.useState<string>('');

  const searchDebounce = useDebounce(search, 1000);

  const getVisibleOrderId = (orderData: EcomOrderProps) =>
    ecommerceType === 'shopify'
      ? orderData?.shopify_order_name || orderData?.ecommerce_order_id || ''
      : orderData?.ecommerce_order_id;

  React.useEffect(() => {
    updateStateData('ecommerceOrderList', []);
    searchOrder(projectId, search);
    // eslint-disable-next-line
  }, [searchDebounce]);

  return (
    <div className='order-right-bar_container'>
      <div className='relative px-6 pt-6'>
        <div className='sticky top-0 rounded-md shadow-sm'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <MagnifyingGlassIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </div>
          <input
            type='text'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
            placeholder='Type ID or Email'
          />
        </div>
      </div>

      {!!searchDebounce && (
        <p className='px-6 my-2 font-sans text-xs font-medium text-gray-300'>
          Showing Result for "{searchDebounce}"
        </p>
      )}
      {searchOrderLoading && <ProductState state={'loading'} />}

      <div className='px-6 pt-3 order-right-bar__order-lists'>
        {!searchOrderLoading && ecommerceOrderList.length === 0 ? (
          <ProductState state={'no-order'} search={search} />
        ) : (
          ecommerceOrderList.map((order: any, index: number) => (
            <OrderCardRightbar
              eComType={order?.ecommerce_type}
              eComOrderId={getVisibleOrderId(order)}
              orderLink={order?.permalink}
              email={order?.email || ''}
              coupon={order?.coupon_info}
              status={order?.status || ''}
              payment_status={order?.payment_status || ''}
              refund={order?.total_refund.toString()}
              orderDate={order?.created_at || 'Not Available'}
              totalCost={order?.total_cost || 'N/A'}
              paymentMethod={order?.payment_method || 'Not Available'}
              billingAddress={order?.billing_address || 'Not Available'}
              shippingAddress={order?.shipping_address || 'Not Available'}
              totalTax={order?.total_tax.toString() || 'N/A'}
              shippingCost={order?.total_shipping_cost.toString() || 'N/A'}
              discount={order?.total_discount.toString() || 'N/A'}
              key={index}
              shippingMethod={order?.shipping_method || 'Not Available'}
              products={order?.line_items || []}
              orderId={getVisibleOrderId(order).toString()}
              productURL={order?.products_url || ''}
              currencySymbol={currencySymbol}
            />
          ))
        )}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
  ecommerceType: state.dashboard?.selectedProject?.ecommerce_type || null,
  ecommerceOrderList: state.crmEcommerce.ecommerceOrderList,
  searchOrderLoading: state.loading.effects.crmEcommerce.searchOrder,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({
  searchOrder: (projectId: number, search: string | number) =>
    dispatch.crmEcommerce.searchOrder({
      projectId,
      search,
    }),
  updateStateData: (key: string, value: any) =>
    dispatch.crmEcommerce.updateStateData({ key, value }),
});

const WrapperContainer = connect(mapState, mapDispatch)(Wrapper);
export default WrapperContainer;
