import React from 'react';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames, navMenu } from 'utilities/utils';
import { Link } from '@reach/router';
import { toaster } from 'evergreen-ui';
import {
  PlusSmallIcon,
  TrashIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import HolidaySettingsContainer from './HolidaySettings';
import useTranslation from 'components/customHooks/useTranslation';
import { SwitchComponent } from 'library';

const BusinessHourSettings: React.FC<businesshourProps> = ({
  fetchBusinessHours,
  selectedProject,
  updateBusinessHours,
  businessHour,
  updateSelectedNav,
}) => {
  const { t } = useTranslation();
  const [operationalStatus, setOperationalStatus] = React.useState(false);
  const [dayLong, setDayLong] = React.useState(false);
  const [valueChanged, setValueChanged] = React.useState(false);
  const [isCustomHour, setIsCustomHour] = React.useState(false);
  const [dayAndTimeList, setDayAndTimeList] = React.useState({
    sunday: {
      status: false,
      time_data: [{ id: 'hggg', start_time: '00:00', end_time: '00:00' }],
    },
    monday: {
      status: false,
      time_data: [{ id: 'hjjhdfj', start_time: '00:00', end_time: '00:00' }],
    },
    tuesday: {
      status: false,
      time_data: [{ id: 'hh', start_time: '00:00', end_time: '00:00' }],
    },
    wednesday: {
      status: false,
      time_data: [{ id: 'dhgdgh', start_time: '00:00', end_time: '00:00' }],
    },
    thursday: {
      status: false,
      time_data: [{ id: 'ghgd', start_time: '00:00', end_time: '00:00' }],
    },
    friday: {
      status: false,
      time_data: [{ id: 'hbbdhdb', start_time: '00:00', end_time: '00:00' }],
    },
    saturday: {
      status: false,
      time_data: [{ id: 'oie', start_time: '00:00', end_time: '00:00' }],
    },
  });

  const IdGenerate = () => {
    return '_' + Math.random().toString(36).slice(2, 9);
  };

  const renderSavedValue = () => {
    if (businessHour) {
      setOperationalStatus(businessHour.is_operational_status_on);
      setDayLong(businessHour.is_operational_type_daylong);
      setIsCustomHour(businessHour.is_operational_type_custom);
      if (businessHour.business_hour_data) {
        setDayAndTimeList({ ...businessHour.business_hour_data });
      }
    }
  };

  const renderInNewWindow = (url: string) => {
    if (url != null) {
      window.open(url, '_blank');
    }
  };

  const renderOperationalStatusOffModule = () => {
    return (
      <div className='bg-[#EFF6FF] px-4 py-4 border border-grey-200 rounded-md text-[#1E40AF] text-sm w-3/4'>
        {t(
          'As you have turned off operational hours, you may need to modify the welcome message from'
        )}{' '}
        <Link
          to='/integrations/integrated'
          className='text-[#0078CF]'
          onClick={() => {
            updateSelectedNav(
              navMenu.find((nav) => nav.name === 'Integration')?.id || 2
            );
          }}
        >
          {t('Integrations')}
        </Link>{' '}
        {t('and')}{' '}
        <Link
          to={`/projects/${selectedProject.id}/automation`}
          className='text-[#0078CF]'
          onClick={() => {
            updateSelectedNav(
              navMenu.find((nav) => nav.name === 'Automation')?.id || 3
            );
          }}
        >
          {t('Automation')}
        </Link>{' '}
        {t('for away time on all connected channels.')}
      </div>
    );
  };

  const sortData = (dayAndTimeList: any) => {
    const day = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    let finalData = {};

    for (let i = 0; i < day.length; i++) {
      for (const [key, value] of Object.entries(dayAndTimeList)) {
        if (day[i] === key) {
          let dayName = day[i];
          let dayData = {
            [dayName]: value,
          };
          Object.assign(finalData, dayData);
        }
      }
    }
    return finalData;
  };

  const renderAmPmDropdown = (key: any, time: any, identifier: number) => {
    const renderAmOrPM = () => {
      var value = '';
      dayAndTimeList[key].time_data.map((item: any) => {
        if (item.id === time.id && identifier === 1) {
          if (parseInt(time.start_time.split(':')[0]) >= 12) {
            value = 'PM';
          } else value = 'AM';
        } else if (item.id === time.id && identifier === 2) {
          if (parseInt(time.end_time.split(':')[0]) >= 12) {
            value = 'PM';
          } else value = 'AM';
        }
        return '';
      });

      return <div className='text text-gray-400'>{t(value)}</div>;
    };
    return (
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button
            className={classNames(
              dayAndTimeList[key].status ? 'bg-white' : 'bg-gray-200',
              'inline-flex w-full justify-center rounded-md border border-transparent bg-white px-2 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:ring-0 '
            )}
          >
            {renderAmOrPM()}
            <ChevronDownIcon
              className='-mr-1 ltr:ml-2 rtl:mr-2 h-5 w-5 text-gray-500 '
              aria-hidden='true'
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 w-20 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-primary text-white' : 'text-gray-400',
                      'w-20 block px-4 py-2 text-sm'
                    )}
                    onClick={() => {
                      let dayTimeList = dayAndTimeList;

                      for (const property in dayAndTimeList) {
                        if (property === key) {
                          let newTimeData = dayAndTimeList[
                            property
                          ].time_data.map((item: any) => {
                            if (item.id === time.id && identifier === 1) {
                              if (
                                parseInt(time.start_time.split(':')[0]) >= 12
                              ) {
                                let data =
                                  parseInt(time.start_time.split(':')[0]) - 12;
                                let newData =
                                  data.toString() +
                                  ':' +
                                  time.start_time.split(':')[1];
                                return {
                                  ...item,
                                  start_time: newData,
                                };
                              }
                            } else if (
                              item.id === time.id &&
                              identifier === 2
                            ) {
                              if (parseInt(time.end_time.split(':')[0]) >= 12) {
                                let data =
                                  parseInt(time.end_time.split(':')[0]) - 12;
                                let newData =
                                  data.toString() +
                                  ':' +
                                  time.end_time.split(':')[1];
                                return {
                                  ...item,
                                  end_time: newData,
                                };
                              }
                            }
                            return item;
                          });
                          dayTimeList[key].time_data = newTimeData;
                          setDayAndTimeList({
                            ...dayTimeList,
                          });
                        }
                      }
                    }}
                  >
                    {t('AM')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? 'bg-primary text-white' : 'text-gray-400',
                      'w-20 block px-4 py-2 text-sm'
                    )}
                    onClick={() => {
                      let dayTimeList = dayAndTimeList;

                      for (const property in dayAndTimeList) {
                        if (property === key) {
                          let newTimeData = dayAndTimeList[
                            property
                          ].time_data.map((item: any) => {
                            if (item.id === time.id && identifier === 1) {
                              if (
                                parseInt(time.start_time.split(':')[0]) < 12
                              ) {
                                let data =
                                  parseInt(time.start_time.split(':')[0]) + 12;
                                let newData =
                                  data.toString() +
                                  ':' +
                                  time.start_time.split(':')[1];
                                return {
                                  ...item,
                                  start_time: newData,
                                };
                              }
                            } else if (
                              item.id === time.id &&
                              identifier === 2
                            ) {
                              if (parseInt(time.end_time.split(':')[0]) < 12) {
                                let data =
                                  parseInt(time.end_time.split(':')[0]) + 12;
                                let newData =
                                  data.toString() +
                                  ':' +
                                  time.end_time.split(':')[1];
                                return {
                                  ...item,
                                  end_time: newData,
                                };
                              }
                            }
                            return item;
                          });
                          dayTimeList[key].time_data = newTimeData;
                          setDayAndTimeList({
                            ...dayTimeList,
                          });
                        }
                      }
                    }}
                  >
                    {t('PM')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderDayComponent = () => {
    return (
      <>
        {Object.keys(sortData(dayAndTimeList))?.map((key, index) => {
          return (
            <div className='w-full py-2'>
              <div className='flex justify-start w-[95%] items-start mt-2'>
                <div className='w-[5%] mt-2'>
                  <input
                    type='checkbox'
                    onClick={() => {
                      let dayTimeList = dayAndTimeList;
                      for (const property in dayAndTimeList) {
                        if (property === key) {
                          dayAndTimeList[key].status =
                            !dayAndTimeList[key].status;
                        }
                      }
                      setDayAndTimeList({ ...dayTimeList });
                      setValueChanged(true);
                    }}
                    checked={dayAndTimeList[key].status}
                    className='flex justify-start align-middle py-1 mt-1 ltr:mr-4 rtl:ml-4 w-4 h-4 border-gray-300 rounded outline-none focus:ring-0 ring-offset-0 text-primary'
                  />
                </div>
                <div className='flex justify-start gap-2 item-center w-[35%] text text-gary-600 text-sm font-medium mt-1 py-1'>
                  {t(key.charAt(0).toUpperCase() + key.slice(1))}
                </div>
                <div className='flex flex-col gap-2 w-[60%] justify-end'>
                  {dayAndTimeList[key].time_data.map(
                    (time: any, index: number) => {
                      const renderTime = (t: any) => {
                        if (parseInt(t.split(':')[0]) === 0) {
                          return '00';
                        }
                        if (t.split(':')[0] === '') {
                          return '';
                        }
                        return parseInt(t.split(':')[0]) > 12
                          ? parseInt(t.split(':')[0]) - 12 < 10
                            ? '0' + (parseInt(t.split(':')[0]) - 12).toString()
                            : parseInt(t.split(':')[0]) - 12
                          : 12 - parseInt(t.split(':')[0]) < 10 &&
                            12 - parseInt(t.split(':')[0]) > 2 &&
                            12 - parseInt(t.split(':')[0]) !== 0
                          ? '0' + parseInt(t.split(':')[0]).toString()
                          : parseInt(t.split(':')[0]);
                      };
                      return (
                        <div className='flex justify-end gap-2 w-full '>
                          <div
                            className={classNames(
                              dayAndTimeList[key].status
                                ? 'bg-white'
                                : 'bg-gray-200',
                              'flex justify-center item-center w-full relative rounded-md border border-gray-300 focus:ring-primary focus:border-primary z-1'
                            )}
                          >
                            <div
                              className={classNames(
                                dayAndTimeList[key].status
                                  ? 'bg-transparent'
                                  : 'bg-gray-200',
                                'flex border border-transparent rounded-md'
                              )}
                            >
                              <input
                                //key={renderTime(time.start_time).toString()}
                                type='text'
                                maxLength={2}
                                min='1'
                                max='12'
                                defaultValue={renderTime(
                                  time.start_time
                                ).toString()}
                                onClick={() => {
                                  setValueChanged(true);
                                }}
                                onChange={(e) => {
                                  let hour = e.target.value;
                                  if (hour.length > 2) return;
                                  let hourData =
                                    hour.toString() +
                                    ':' +
                                    time.start_time.split(':')[1];

                                  let dayTimeList = dayAndTimeList;
                                  for (const property in dayAndTimeList) {
                                    if (property === key) {
                                      let newTimeData = dayAndTimeList[
                                        property
                                      ].time_data.map((item: any) => {
                                        if (item.id === time.id) {
                                          return {
                                            ...item,
                                            start_time: hourData,
                                          };
                                        }
                                        return item;
                                      });
                                      dayTimeList[key].time_data = newTimeData;
                                      setDayAndTimeList({
                                        ...dayTimeList,
                                      });
                                    }
                                  }
                                }}
                                disabled={!dayAndTimeList[key].status}
                                className={classNames(
                                  dayAndTimeList[key].status
                                    ? 'bg-white'
                                    : 'bg-gray-200',
                                  'p-3s mt-1 w-12 h-7 text text-gray-900 text-sm font-normal border-none focus:outline-none focus:ring-0 rounded-md'
                                )}
                              />
                              <div className='mt-2'>:</div>
                              <input
                                type='text'
                                maxLength={2}
                                value={time.start_time.split(':')[1]}
                                onClick={() => {
                                  setValueChanged(true);
                                }}
                                onChange={(e) => {
                                  let minute = e.target.value;
                                  let minuteData =
                                    time.start_time.split(':')[0] +
                                    ':' +
                                    minute.toString();
                                  let dayTimeList = dayAndTimeList;
                                  for (const property in dayAndTimeList) {
                                    if (property === key) {
                                      let newTimeData = dayAndTimeList[
                                        property
                                      ].time_data.map((item: any) => {
                                        if (item.id === time.id) {
                                          return {
                                            ...item,
                                            start_time: minuteData,
                                          };
                                        }
                                        return item;
                                      });
                                      dayTimeList[key].time_data = newTimeData;
                                      setDayAndTimeList({
                                        ...dayTimeList,
                                      });
                                    }
                                  }
                                }}
                                disabled={!dayAndTimeList[key].status}
                                className={classNames(
                                  dayAndTimeList[key].status
                                    ? 'bg-white'
                                    : 'bg-gray-200',
                                  'p-3 mt-1 w-14 h-7 text text-gray-900 text-sm font-normal border border-none focus:outline-none  focus:ring-0'
                                )}
                              />
                              {renderAmPmDropdown(key, time, 1)}
                            </div>
                          </div>
                          <div className='flex justify-start font-semibold text text-gray-500 mt-1'>
                            _
                          </div>
                          <div
                            className={classNames(
                              dayAndTimeList[key].status
                                ? 'bg-transparent'
                                : 'bg-gray-200',
                              'flex justify-end relative w-full border border-gray-300 rounded-md focus:ring-primary focus:border-primary'
                            )}
                          >
                            <div className='flex flex-row border border-none'>
                              <input
                                //key={renderTime(time.end_time).toString()}
                                type='text'
                                maxLength={2}
                                defaultValue={renderTime(
                                  time.end_time
                                ).toString()}
                                onClick={() => {
                                  setValueChanged(true);
                                }}
                                onChange={(e) => {
                                  let hour = e.target.value;
                                  if (hour.length > 2) return;
                                  let hourData =
                                    hour.toString() +
                                    ':' +
                                    time.end_time.split(':')[1];

                                  let dayTimeList = dayAndTimeList;
                                  for (const property in dayAndTimeList) {
                                    if (property === key) {
                                      let newTimeData = dayAndTimeList[
                                        property
                                      ].time_data.map((item: any) => {
                                        if (item.id === time.id) {
                                          return {
                                            ...item,
                                            end_time: hourData,
                                          };
                                        }
                                        return item;
                                      });
                                      dayTimeList[key].time_data = newTimeData;
                                      setDayAndTimeList({
                                        ...dayTimeList,
                                      });
                                    }
                                  }
                                }}
                                disabled={!dayAndTimeList[key].status}
                                className={classNames(
                                  dayAndTimeList[key].status
                                    ? 'bg-white'
                                    : 'bg-gray-200',
                                  'p-3 mt-1 w-12 h-7 text text-gray-900 text-sm font-normal border border-none focus:outline-none focus:ring-0'
                                )}
                              />
                              <div className='mt-2'>:</div>
                              <input
                                type='text'
                                maxLength={2}
                                value={time.end_time.split(':')[1]}
                                onClick={() => {
                                  setValueChanged(true);
                                }}
                                onChange={(e) => {
                                  let minute = e.target.value;
                                  let minuteData =
                                    time.end_time.split(':')[0] +
                                    ':' +
                                    minute.toString();
                                  let dayTimeList = dayAndTimeList;
                                  for (const property in dayAndTimeList) {
                                    if (property === key) {
                                      let newTimeData = dayAndTimeList[
                                        property
                                      ].time_data.map((item: any) => {
                                        if (item.id === time.id) {
                                          return {
                                            ...item,
                                            end_time: minuteData,
                                          };
                                        }
                                        return item;
                                      });
                                      dayTimeList[key].time_data = newTimeData;
                                      setDayAndTimeList({
                                        ...dayTimeList,
                                      });
                                    }
                                  }
                                }}
                                disabled={!dayAndTimeList[key].status}
                                className={classNames(
                                  dayAndTimeList[key].status
                                    ? 'bg-white'
                                    : 'bg-gray-200',
                                  'p-3 w-14 mt-1 h-7 text text-gray-900 text-sm font-normal border border-none focus:outline-none  focus:ring-0'
                                )}
                              />
                              {renderAmPmDropdown(key, time, 2)}
                            </div>
                          </div>
                          <div className='flex gap-2 justify-end relative w-[15%] text text-gray-500'>
                            {index === 0 ? (
                              <button
                                type='button'
                                onClick={() => {
                                  let dayTimeList = dayAndTimeList;
                                  for (let property in dayAndTimeList) {
                                    if (property === key) {
                                      let newTimeData = {
                                        id: IdGenerate(),
                                        start_time: '00:00',
                                        end_time: '00:00',
                                      };
                                      let timeData =
                                        dayTimeList[`${property}`].time_data;
                                      timeData = [...timeData, newTimeData];
                                      dayTimeList[`${property}`].time_data =
                                        timeData;
                                    }
                                  }
                                  setDayAndTimeList({ ...dayTimeList });
                                }}
                                className={classNames(
                                  dayAndTimeList[key].status
                                    ? 'bg-white'
                                    : 'bg-gray-200',
                                  'px-2.5 py-2.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white'
                                )}
                                disabled={!dayAndTimeList[key].status}
                              >
                                <PlusSmallIcon
                                  className={classNames(
                                    dayAndTimeList[key].status
                                      ? 'bg-white'
                                      : 'bg-gray-200',
                                    'w-5 h-5 text-gray-500'
                                  )}
                                  aria-hidden='true'
                                />
                              </button>
                            ) : (
                              <button
                                type='button'
                                onClick={() => {
                                  let updatedList = dayAndTimeList[
                                    key
                                  ].time_data.filter(
                                    (item: any) => item.id !== time.id
                                  );
                                  let dayTimeList = dayAndTimeList;
                                  for (let property in dayAndTimeList) {
                                    if (property === key) {
                                      dayTimeList[`${property}`].time_data =
                                        updatedList;
                                    }
                                  }
                                  setDayAndTimeList({ ...dayTimeList });
                                }}
                                disabled={!dayAndTimeList[key].status}
                                className='px-2.5 py-2.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-white'
                              >
                                <TrashIcon
                                  className='w-5 h-5 text-gray-500 text text-red-500'
                                  aria-hidden='true'
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const renderSaveButton = () => {
    return (
      <div className='flex justify-end w-1/2 gap-4'>
        <button
          onClick={() => {
            fetchBusinessHourOnLoad();
            setValueChanged(false);
            window.location.reload();
          }}
          type='button'
          className='inline-flex items-center text-center px-3 py-1.5 text-sm rounded-md  border font-medium outline-none shadow-sm border-gray-200 bg-white text-gray-600 focus:outline-none transition ease-out duration-200'
        >
          {t('Cancel')}
        </button>
        <button
          onClick={() => {
            setValueChanged(false);
            submitBusinessHours(operationalStatus);
          }}
          type='button'
          className='inline-flex items-center text-center px-3 py-1.5 text-sm rounded-md  border font-medium outline-none shadow-sm border-transparent bg-primary hover:bg-primary-hover text-white focus:outline-none transition ease-out duration-200'
        >
          {t('Save')}
        </button>
      </div>
    );
  };

  const renderCustomHourDetails = () => {
    return (
      <>
        <div className='flex gap-4'>
          <div className='flex w-full'>
            <div className='flex justify-start w-[29%] text-sm text-gray-500 font-medium px-2'>
              {t('Day of Week')}
            </div>
            <div className='flex justify-start w-[70%] text-sm text-gray-500 font-medium px-2'>
              {t('Working Hour')}
            </div>
          </div>
        </div>
        <div>
          <div className='mt-4'>
            <div className='text-sm text-gray-500 px-2 w-full'>
              {renderDayComponent()}
            </div>
          </div>
          <div className='mt-6 ml-4 px-4 py-4 bg-[#EFF6FF] text-[#1E40AF] rounded-md'>
            {t(
              'As you have set custom business hours, you may need to modify the welcome message from'
            )}{' '}
            <Link
              to='/integrations/integrated'
              className='text-[#0078CF]'
              onClick={() => {
                updateSelectedNav(
                  navMenu.find((nav) => nav.name === 'Integration')?.id || 2
                );
              }}
            >
              {t('Integrations')}
            </Link>{' '}
            {t('and')}{' '}
            <Link
              to={`/projects/${selectedProject.id}/automation`}
              className='text-[#0078CF]'
              onClick={() => {
                updateSelectedNav(
                  navMenu.find((nav) => nav.name === 'Automation')?.id || 3
                );
              }}
            >
              {t('Automation')}
            </Link>{' '}
            {t('for away time on all connected channels.')}
          </div>
        </div>
      </>
    );
  };

  const renderBusinessHourOption = () => {
    return (
      <div className='flex w-3/4'>
        <div className='grid grid-cols-1 gap-4 divide-y'>
          <div className='grid grid-cols-1 gap-4'>
            <div
              className={classNames(
                dayLong
                  ? 'radio  border border-primary rounded-lg'
                  : 'radio border border-[#D1D5DB] rounded-lg'
              )}
            >
              <div className='px-4 mt-4'>
                <input
                  type='radio'
                  name='bHour'
                  onClick={() => {
                    setValueChanged(true);
                    setIsCustomHour(false);
                    setDayLong(true);
                  }}
                  className='focus:ring-primary text-primary'
                  checked={dayLong}
                />
                <label className='px-2 py-2 font-medium'>
                  {' '}
                  {t('24/7 Business Hours')}
                  <p className='px-7 text-sm text-gray-500 font-normal'>
                    {t(
                      'Your agents are available 24 hours. The default welcome message will be sent when a customer initiates a conversation. You can edit your welcome message from'
                    )}{' '}
                    <span
                      className='text-blue-500 cursor-pointer'
                      onClick={() =>
                        renderInNewWindow(
                          `/projects/${selectedProject.id}/automation`
                        )
                      }
                    >
                      {t('Automation')}
                    </span>
                  </p>
                </label>
              </div>
            </div>
            <div
              className={classNames(
                isCustomHour
                  ? 'radio  border border-primary rounded-lg '
                  : 'radio  border border-grey-200 rounded-lg '
              )}
            >
              <div
                className={classNames(
                  isCustomHour ? 'grid w-full grid-cols-1 divide-y ' : ''
                )}
              >
                <div className='px-4 mt-4'>
                  <input
                    type='radio'
                    value='Custom'
                    name='bHour'
                    onClick={() => {
                      setIsCustomHour(true);
                      setDayLong(false);
                    }}
                    className='focus:ring-primary text-primary'
                    checked={isCustomHour}
                  />
                  <label className='px-2  font-medium'>
                    {' '}
                    {t('Custom Business Hours')}
                    <p className='flex items-center px-7 text-sm text-gray-500 font-normal'>
                      {t(
                        'Set your business availability as per your convenience.'
                      )}
                    </p>
                  </label>
                </div>
                <div>
                  {isCustomHour && (
                    <div className='px-4 py-4'>{renderCustomHourDetails()}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {valueChanged && (
            <div className='flex justify-end py-4'> {renderSaveButton()}</div>
          )}
        </div>
      </div>
    );
  };

  const createBusinessHourData = () => {
    let data = {
      sunday: {
        status: true,
        time_data: [{ id: '-ujhd1', start_time: '00:00', end_time: '23:59' }],
      },
      monday: {
        status: true,
        time_data: [{ id: '_defr', start_time: '00:00', end_time: '23:59' }],
      },
      tuesday: {
        status: true,
        time_data: [{ id: '_hhujhd1', start_time: '00:00', end_time: '23:59' }],
      },
      wednesday: {
        status: true,
        time_data: [{ id: '_weujhd1', start_time: '00:00', end_time: '23:59' }],
      },
      thursday: {
        status: true,
        time_data: [
          { id: '_eyyuujhd1', start_time: '00:00', end_time: '23:59' },
        ],
      },
      friday: {
        status: true,
        time_data: [{ id: '_hejj', start_time: '00:00', end_time: '23:59' }],
      },
      saturday: {
        status: true,
        time_data: [{ id: '_ghhgd', start_time: '00:00', end_time: '23:59' }],
      },
    };
    if (dayLong) {
      return data;
    }
    for (var day in data) {
      data[day] = dayAndTimeList[day];
    }
    return data;
  };

  const submitBusinessHours = async (operational: boolean) => {
    const business_Hour_Data = createBusinessHourData();

    const data = {
      is_operational_status_on: operational,
      is_operational_type_daylong: dayLong,
      is_operational_type_custom: isCustomHour,
      business_hour_data: business_Hour_Data,
    };

    const payload = {
      teamId: selectedProject.id,
      data: data,
    };

    const response = await updateBusinessHours(payload);

    if (response) {
      toaster.success(t(`Business Hour Settings Changed Successfully!!`));
    } else {
      toaster.danger(t(`Something Went Wrong!!`));
    }
  };

  const fetchBusinessHourOnLoad = async () => {
    await fetchBusinessHours(selectedProject.id);
  };

  React.useEffect(() => {
    fetchBusinessHourOnLoad();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    renderSavedValue();
    // eslint-disable-next-line
  }, [businessHour]);

  return (
    <>
      <div className='mt-6'>
        <div className='h-auto p-4 rounded w-full'>
          <div className='flex gap-4'>
            <div className='flex justify-start w-1/2 py-4'>
              <label className='text-base ml-1'>
                {t('Operational Status')}
              </label>
            </div>
            <div className='flex justify-end w-1/4 px-4 py-4'>
              <SwitchComponent
                isEnabled={operationalStatus}
                classNames='ltr:ml-auto rtl:mr-auto'
                onChange={() => {
                  setOperationalStatus(!operationalStatus);
                  submitBusinessHours(!operationalStatus);
                }}
              />
            </div>
          </div>
          <div>
            {operationalStatus && renderBusinessHourOption()}
            {!operationalStatus && renderOperationalStatusOffModule()}
          </div>
        </div>
      </div>
      <div className='p-4'>
        <div className='border border-[#D1D5DB] rounded-lg px-6 w-3/4'>
          <HolidaySettingsContainer />
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  businessHour: state.businesshour.businessHourTotalData,
  navActiveId: state.dashboard.navActiveId,
});

const mapDispatch = (dispatch: any) => ({
  fetchBusinessHours: (teamId: number) =>
    dispatch.businesshour.fetchBusinessHours(teamId),
  updateBusinessHours: (payload: businessHourPayloadProp) =>
    dispatch.businesshour.updateBusinessHours(payload),
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
});

const NotificationSettingsContainer = connect(
  mapState,
  mapDispatch
)(BusinessHourSettings);

export default NotificationSettingsContainer;
