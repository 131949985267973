import React, { useState } from 'react';
import {
  Table,
  IconButton,
  SearchInput,
  Dialog,
  Button,
  TextInput,
  Textarea,
  toaster,
  Radio,
  Pane,
} from 'evergreen-ui';
import { connect } from 'react-redux';
import TagRedesign from '../../../components/elements/redesign/TagRedesign';

interface Props {
  cannedResponses: any;
  fetchCannedResponse: (teamId: undefined | number) => void;
  createCannedResponse: (
    title: string,
    text: string,
    forTeam: boolean,
    teamId: undefined | number
  ) => boolean;
  editCannedResponse: (
    id: number,
    title: string,
    text: string,
    teamId: undefined | number,
    for_team: boolean
  ) => boolean;
  deleteCannedResponse: (teamId: number | undefined, id: number) => boolean;
  teamId: undefined | number;
}

const CannedResponse: React.FC<Props> = ({
  cannedResponses,
  fetchCannedResponse,
  createCannedResponse,
  editCannedResponse,
  deleteCannedResponse,
  teamId,
}) => {
  const [isCreateShown, setCreateShown] = useState(false);
  const [forTeam, setForTeam] = useState(false);
  const [isDeleteShown, setDeleteShown] = useState(false);
  const [editingId, setEditingId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [deleteCanned, setDeleteCanned] = useState<any>(null);

  const filteredData =
    searchText.length > 1
      ? cannedResponses.filter((cr: any) =>
          cr.title.toLowerCase().includes(searchText.toLowerCase())
        )
      : cannedResponses;

  return (
    <div className='canned-response__container'>
      <div className='canned-response__header'>
        <div className='canned-response__header-title'>
          <h3>Manage Canned Responses</h3>
          <Button
            className='alice-btn__default'
            appearance='primary'
            onClick={() => setCreateShown(true)}
          >
            New Response
          </Button>
        </div>
        <SearchInput
          placeholder='Search shortcut and hit enter...'
          className='alice-rd-search'
          height={40}
          width='100%'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value)
          }
        />
      </div>
      <Table>
        <Table.Head className='table-head-rd'>
          <Table.TextHeaderCell className='flex-3'>
            Keyword
          </Table.TextHeaderCell>
          <Table.TextHeaderCell className='flex-2'>
            Created For
          </Table.TextHeaderCell>
          <Table.TextHeaderCell className='flex-5'>
            Saved Response
          </Table.TextHeaderCell>
          <Table.TextHeaderCell className='flex-1'>Edit</Table.TextHeaderCell>
          <Table.TextHeaderCell className='flex-1'>Action</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body
          style={{ overflow: 'auto', maxHeight: `calc(100vh - 225px)` }}
        >
          {filteredData.map((cr: any, i: number) => (
            <Table.Row key={i} height='auto'>
              <Table.TextCell className='flex-3 canned-response__table-title'>
                <TagRedesign
                  data={{ value: cr.id, label: `#${cr.title}` }}
                  isBold
                  intent={'default'}
                />
              </Table.TextCell>
              <Table.TextCell className='flex-2 canned-response__table-title'>
                <TagRedesign
                  data={{
                    value: cr.for_team,
                    label: cr.for_team ? 'Team' : 'Individual',
                  }}
                  intent={cr.for_team ? 'success' : 'active'}
                />
              </Table.TextCell>
              <Table.TextCell className='flex-5 canned-response__table-text'>
                {cr.text.length > 100 ? `${cr.text.slice(0, 100)}...` : cr.text}
              </Table.TextCell>
              <Table.TextCell
                className='flex-1'
                style={{ paddingLeft: 0, paddingRight: 24 }}
              >
                <IconButton
                  icon='edit'
                  appearance='minimal'
                  onClick={() => {
                    setEditingId(cr.id);
                    setTitle(cr.title);
                    setText(cr.text);
                    setForTeam(cr.for_team);
                    setCreateShown(true);
                  }}
                />
              </Table.TextCell>
              <Table.TextCell
                className='flex-1'
                style={{ paddingLeft: 0, paddingRight: 24 }}
              >
                <IconButton
                  icon='trash'
                  appearance='minimal'
                  onClick={() => {
                    setDeleteShown(true);
                    setDeleteCanned(cr);
                  }}
                />
              </Table.TextCell>
              <Dialog
                isShown={isDeleteShown}
                onCloseComplete={() => setDeleteShown(false)}
                hasFooter={false}
                hasHeader={false}
                containerProps={{ className: 'canned-response__remove-modal' }}
              >
                <p className='canned-response__remove-modal-title'>
                  Delete Canned Response
                </p>
                <p className='canned-response__remove-modal-subtitle'>
                  Are you Sure you want to delete Canned Response?
                </p>
                <div className='flex flex-end align-center mt-15px'>
                  <Button
                    marginRight={30}
                    className='alice-btn__default'
                    onClick={() => setDeleteShown(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className='alice-btn__warning'
                    isLoading={loading}
                    onClick={async () => {
                      setLoading(true);
                      if (!!deleteCanned) {
                        await deleteCannedResponse(teamId, deleteCanned.id);
                        await fetchCannedResponse(teamId);
                        setDeleteCanned(null);
                      }
                      setLoading(false);
                      setDeleteShown(false);
                    }}
                  >
                    Yes Do It!
                  </Button>
                </div>
              </Dialog>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Dialog
        isShown={isCreateShown}
        title={
          editingId === 0 ? 'Create a Canned Response' : 'Edit Canned Response'
        }
        onCloseComplete={() => {
          setCreateShown(false);
          setTitle('');
          setText('');
          setEditingId(0);
        }}
        hasFooter={false}
      >
        <div className='canned-response__form'>
          <div className='canned-response__form-item'>
            <p>Keyword</p>
            <TextInput
              className='custom-input-style'
              height={40}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              value={title}
              placeholder='e.g. product'
            />
          </div>
          <br />

          <div className='canned-response__form-item'>
            <p>Response</p>
            <Textarea
              height={220}
              className='custom-input-style'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
              value={text}
              placeholder='Response text here'
            />
          </div>
          <Pane display={'flex'}>
            <Radio
              marginRight={10}
              className='radio-rd'
              onChange={() => {
                setForTeam(false);
              }}
              label='Individual Use'
              checked={!forTeam}
            />
            <Radio
              label='Team'
              className='radio-rd'
              onChange={() => {
                setForTeam(true);
              }}
              checked={forTeam}
            />
          </Pane>
          <br />
          <div className='canned-response__submit'>
            <Button
              appearance='minimal'
              className='alice-btn__default'
              intent='danger'
              onClick={async () => {
                setEditingId(0);
                setTitle('');
                setText('');
                setForTeam(false);
                setCreateShown(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className='alice-btn__secondary'
              appearance='primary'
              isLoading={loading}
              onClick={async () => {
                if (editingId === 0) {
                  //create
                  if (title.length > 0 && text.length > 0) {
                    setLoading(true);
                    await createCannedResponse(title, text, forTeam, teamId);
                    await fetchCannedResponse(teamId);
                    setLoading(false);
                    setCreateShown(false);
                  } else {
                    toaster.danger('Tag and Response Text are required', {
                      duration: 2,
                    });
                  }
                } else {
                  //edit
                  setLoading(true);
                  let response = await editCannedResponse(
                    editingId,
                    title,
                    text,
                    teamId,
                    forTeam
                  );
                  await fetchCannedResponse(teamId);
                  setLoading(false);
                  if (response) {
                    setEditingId(0);
                    setTitle('');
                    setText('');
                    setForTeam(false);
                    setCreateShown(false);
                  }
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapState = (state: any) => ({
  cannedResponses: state.crm.cannedResponses,
  teamId: state.dashboard.selectedProject.id,
});

const mapDispatch = (dispatch: any) => ({
  fetchCannedResponse: (teamId: undefined | number) =>
    dispatch.crm.fetchCannedResponse(teamId),
  createCannedResponse: (
    title: string,
    text: string,
    forTeam: boolean,
    teamId: undefined | number
  ) =>
    dispatch.crm.createCannedResponse({
      title,
      text,
      for_team: forTeam,
      teamId,
    }),
  editCannedResponse: (
    id: number,
    title: string,
    text: string,
    teamId: undefined | number,
    for_team: boolean
  ) => dispatch.crm.editCannedResponse({ id, title, text, teamId, for_team }),
  deleteCannedResponse: (teamId: number | undefined, id: number) =>
    dispatch.crm.deleteCannedResponse({ teamId, id }),
});

const CannedResponseContainer = connect(mapState, mapDispatch)(CannedResponse);

export default CannedResponseContainer;
