import React from 'react';
import ShopifyOrderSelectedLists from '../../shopifyOrderCreate/components/ShopifyOrderSelectedLists';
import {
  MapPinIcon,
  EnvelopeIcon,
  UserIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { connect } from 'react-redux';
import { ShopifyUpdateOrderCustomerDataProps } from '../../eCommerceOrderCreate/interfaces';
import { VariantProps } from '../../eCommerceOrderCreate/interfaces';

interface Prop {
  customerData: ShopifyUpdateOrderCustomerDataProps;
  variantLists: VariantProps[];
  currencySymbol: string;
  shippingCost: number;
  orderNote?: string;
}

const ShopifyUpdateSummary: React.FC<Prop> = ({
  customerData,
  variantLists,
  currencySymbol,
  shippingCost,
  orderNote,
}) => {
  const renderAddressWithComma = (key: any) => {
    if (key) {
      return key + ',';
    }
    return '';
  };

  return (
    <>
      <div className='p-6'>
        <p className='font-sans text-sm font-medium leading-5'>
          Product Selected
        </p>
        <ShopifyOrderSelectedLists
          productData={customerData?.visibleCalculatedProducts || []}
          variantLists={variantLists}
          currencySymbol={currencySymbol}
        />
      </div>
      <div className='p-6 mt-4'>
        <div>
          <p className='text-sm font-medium leading-6 text-gray-900'>
            Applicant Information
          </p>
        </div>
        <div className='border-gray-200'>
          {!!customerData && (
            <dl>
              {!!customerData.shipping_address?.email &&
                <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                  <dt className='text-sm font-medium text-gray-500'>Email</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    <span className='flex items-center'>
                      <EnvelopeIcon className='w-4 h-4 mr-2' />{' '}
                      {customerData.shipping_address?.email}
                    </span>
                  </dd>
                </div>
              }
              <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Shipping Information
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span className='flex items-center'>
                    <UserIcon className='w-4 h-4 mr-2' />
                    {customerData?.shipping_address?.first_name +
                      ' ' +
                      customerData?.shipping_address?.last_name}
                  </span>
                  <div className='flex items-start mt-2'>
                    <div className='flex-none'>
                      <MapPinIcon className='w-4 h-4 mr-2 mt-0.5' />
                    </div>
                    <div className='flex-grow'>
                      {renderAddressWithComma(
                        customerData?.shipping_address?.address_one
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.address_two
                      )}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.city
                      )}{' '}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.state
                      )}
                      {renderAddressWithComma(
                        customerData?.shipping_address?.postcode
                      )}{' '}
                      {customerData?.shipping_address?.country}
                    </div>
                  </div>
                </dd>
              </div>
              {orderNote &&
                <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                  <dt className='text-sm font-medium text-gray-500'>
                    Note
                  </dt>
                  <dd className='flex items-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                    <PencilSquareIcon className='w-4 h-4 mr-2' />
                    <span>{orderNote}</span>
                  </dd>
                </div>
              }
              <div className='py-4 border-b sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium text-gray-500'>
                  Subtotal Bill
                </dt>
                <dd className='flex items-center mt-1 ml-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span>
                    {currencySymbol}
                    {customerData?.subtotalCost}
                  </span>
                </dd>
                <dt className='text-sm font-medium text-gray-500'>Total Tax</dt>
                <dd className='flex items-center mt-1 ml-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span>
                    {currencySymbol}
                    {customerData?.totalTax}
                  </span>
                </dd>
                <dt className='text-sm font-medium text-gray-500'>
                  Shipping Cost
                </dt>
                <dd className='flex items-center mt-1 ml-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span>
                    {currencySymbol}
                    {shippingCost}
                  </span>
                </dd>
              </div>
              <div className='flex py-4 mt-4 sm:py-5 space-between'>
                <dt className='text-sm font-medium text-gray-700'>
                  Total Bill
                </dt>
                <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                  <span className='inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800'>
                    {currencySymbol}
                    {customerData?.totalCost}
                  </span>
                </dd>
              </div>
            </dl>
          )}
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  customerData: state.crmEcommerce.customerData,
  variantLists: state.crmEcommerce.variantLists,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  shippingCost: state.crmEcommerce.shippingCost,
  orderNote: state.crmEcommerce.orderNote,
});

const mapDispatch = (dispatch: any) => ({});

const CreateOrderSummaryContainer = connect(
  mapState,
  mapDispatch
)(ShopifyUpdateSummary);
export default CreateOrderSummaryContainer;
