import React from 'react';
import { Collapse } from '../../../../../library';
import { isPartnerRestricted } from 'components/customHooks/usePartner';

interface Props {
  header: string;
  children: any;
  cardNumber: number;
}

const CollapsableComponent: React.FC<Props> = ({
  header,
  children,
  cardNumber,
}) => {
  const shouldHide = isPartnerRestricted('CollapsableComponent/node/' + header);
  if (shouldHide) {
    return null;
  }
  return (
    <Collapse
      header={header.toUpperCase()}
      isOpen={true}
      className={'bg-transparent p-3'}
      isBorderShown={false}
    >
      <div
        className={`grid gap-5 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-${cardNumber}`}
      >
        {children}
      </div>
    </Collapse>
  );
};
export default CollapsableComponent;
