import React, { Fragment } from 'react';
import AttributeContainer from 'components/globalComponents/AttributeContainer';
import { Tooltip, Button } from 'evergreen-ui';
import emojiIcon from '../../../assets/images/smileEmoji.svg';
import Picker from 'emoji-picker-react';
import { Popover } from '@headlessui/react';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  id: number | string;
  value: string;
  type: string;
  placeHolder?: string;
  attributeList?: string[];
  showAttribute: boolean;
  disabled?: boolean;
  setShowAttribute: (val: boolean) => void;
  handleOnChange: (val: string) => void;
  hasWriteAccess: boolean;
  isDanger?: boolean;
  setValue: (val: string) => void;
  enableAttribute: boolean;
  enableEmoji: boolean;
  textLimit?: number;
  classForCannedResponse?: string;
}

const TextVariableInput: React.FC<Props> = ({
  id,
  value,
  type,
  disabled = false,
  handleOnChange,
  isDanger,
  placeHolder,
  attributeList = ['first_name'],
  showAttribute,
  setShowAttribute,
  hasWriteAccess,
  setValue,
  enableAttribute,
  enableEmoji,
  textLimit,
  classForCannedResponse,
}) => {
  textLimit = textLimit ? textLimit : 10000;
  const { t } = useTranslation();
  const changeCursorValue = (value: string) => {
    let target: any = document.getElementById(`${id}`);
    if (target.hasOwnProperty('setRangeText')) {
      //if setRangeText function is supported by current browser
      target.setRangeText('{{' + value + '}}');
      setValue(target.value);
    } else {
      target.focus();
      document.execCommand('insertText', false /*no UI*/, '{{' + value + '}}');
      setValue(target.value);
    }
    setShowAttribute(false);
  };

  const onEmojiClick = (event: any, emojiObject: any) => {
    let target: any = document.getElementById(`${id}`);

    if (target.hasOwnProperty('setRangeText')) {
      //if setRangeText function is supported by current browser
      target.setRangeText(emojiObject.emoji);
      setValue(target.value);
    } else {
      target.focus();
      document.execCommand('insertText', false /*no UI*/, emojiObject.emoji);
      setValue(target.value);
    }
  };

  return (
    <Fragment key={id}>
      <AttributeContainer
        attributeList={attributeList}
        showAttribute={showAttribute}
        setShowAttribute={setShowAttribute}
        handleSelect={(value) => changeCursorValue(value)}
      >
        <div
          className={`relative border border-gray-200 rounded px-3 py-2 focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500 ${
            disabled
              ? 'bg-gray-200 cursor-not-allowed'
              : ' cursor-default bg-white'
          }`}
        >
          <textarea
            maxLength={textLimit}
            id={`${id}`}
            name='text-input-name'
            placeholder={placeHolder}
            value={value}
            rows={3}
            disabled={disabled}
            className={`resize-none border border-none rounded w-full ${
              isDanger
                ? 'border-red-600 focus:ring-red-500'
                : 'focus:ring-0 focus:border-none'
            } text-gray-500 ${classForCannedResponse} focus:outline-none focus:ring-1 ${
              disabled
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-white cursor-default'
            }`}
            onInput={(e: any) => {
              handleOnChange(e.target.value);
            }}
          />
          <div className='h-6 flex gap-1 items-center w-full'>
            <div className='flex w-[50%] justify-start items-center'>
              {enableAttribute && (
                <>
                  {!disabled ? (
                    <p
                      onClick={() => setShowAttribute(true)}
                      className='text-gray-400 cursor-pointer'
                    >
                      {'{..}'}
                    </p>
                  ) : (
                    <p className='text-gray-400'>{'{..}'}</p>
                  )}
                </>
              )}

              {enableEmoji && (
                <>
                  {!disabled ? (
                    <Popover>
                      {({ open }) => (
                        <>
                          <Popover.Button>
                            {/* @ts-ignore */}
                            <Tooltip content={t('Add a Emoji')}>
                              {/* @ts-ignore */}
                              <Button
                                disabled={false}
                                className='reply-icon-style-fix'
                              >
                                <img src={emojiIcon} width={16} alt='emoji' />
                              </Button>
                            </Tooltip>
                          </Popover.Button>
                          <Popover.Panel className='absolute left-[30%] top-[-100%] z-20 mt-3 max-w-auto -translate-x-1/2 transform px-4 sm:px-0'>
                            <Picker
                              onEmojiClick={(event: any, emojiElem: any) =>
                                onEmojiClick(event, emojiElem)
                              }
                              disableAutoFocus={true}
                              disableSkinTonePicker={true}
                              preload={true}
                            />
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                  ) : (
                    //@ts-ignore
                    <Button disabled={false} className='reply-icon-style-fix'>
                      <img src={emojiIcon} width={16} alt='emoji' />
                    </Button>
                  )}
                </>
              )}
            </div>
            <div className='flex w-[50%] justify-end'>
              <div className='bg-green-100 rounded-md p-1 text-green-800'>
                {value.length} /{textLimit}
              </div>
            </div>
          </div>
        </div>
      </AttributeContainer>
    </Fragment>
  );
};

export default TextVariableInput;
