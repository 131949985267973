import useTranslation from 'components/customHooks/useTranslation';
import SingleItem from './singleItem';
import groupBy from 'lodash/groupBy';

import { FormFields } from 'pages/datalab/export';
import { IFormField } from 'pages/datalab/interface';

const LeftSideBar = () => {
  const { t } = useTranslation();
  const formFieldsArr = Object.values(FormFields);

  const groupedFormFields = groupBy(formFieldsArr, 'category');

  return (
    <div
      className={`row-span-3 bg-white ltr:border-r rtl:border-l border-gray-200 dark:border-gray-600   dark:bg-slate-800`}
      style={{ width: '378px' }}
    >
      <div className='fixed p-4 h-[calc(100vh-52px)] overflow-y-scroll scroll-none'>
        {Object.keys(groupedFormFields).map(
          (category: string, index: number) => {
            if (!category || category === 'undefined') return null;

            return (
              <div key={index}>
                <span className='text-xs text-textSecondary font-semibold'>
                  {t(category.toUpperCase())}
                </span>
                <div className='grid grid-cols-3 gap-2 mt-3 mb-2'>
                  {groupedFormFields[category].map(
                    (field: IFormField, i: number) => (
                      <SingleItem field={field} key={i} />
                    )
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default LeftSideBar;
