import {
  React,
  PlusIcon,
  TrashIcon,
  ConditionHeader,
  UtilityFunctions,
  ConditionDropDown,
  useConditionalActions,
  useAutomationWorkflow,
  MultipleConditionDropdown,
  DynamicSearchField,
} from 'pages/automationWorkflow/export';
import { IOrder } from 'pages/automationWorkflow/interface';
import SingleSelectDropDown from '../common/SingleChoiceDropDown';
import {
  TooltipProviderCustomised,
} from 'libraryV2/ui/tooltip';

const staticCondition = ['exists', 'not_exists'];

const AddConditions = () => {
  // Destructure required state variables from the automation workflow hook
  const { selectedConditions, selectedSaveConditionList } =
    useAutomationWorkflow();

  // Access functions from useConditionalActions hook for conditional actions
  const {
    handleAddMoreConditions,
    removeSelectedConditionItem,
    removeSelectedDisplayedOption,
    handleUpdateSelectedSaveCondition,
    updateSelectedSaveConditionValueList,
    updateConditionallyRenderingConditionUI,
    handleUpdateDynamicFieldSaveConditionValue,
    handleUpdateMultiSelectedSaveConditionValue,
  } = useConditionalActions();

  const setStaticValue = (
    conditionData: any,
    conditionIdx: number,
    itemIdx: number,
    obj: {
      label: string;
      compare_type: string;
      select_Type: string;
    }
  ) => {
    handleUpdateSelectedSaveCondition(conditionIdx, itemIdx, { ...obj });
    if (
      staticCondition.includes((obj?.compare_type ?? '').trim().toLowerCase())
    ) {
      updateSelectedSaveConditionValueList(conditionIdx, itemIdx, {
        label: '',
        definition: conditionData?.data?.col_2_label_options || {},
      });
    }
  };

  // Handles rendering of individual condition items in the UI
  const renderConditionItem = (
    conditionItem: IOrder,
    conditionIdx: number,
    itemIdx: number
  ) => (
    <div className='grid grid-cols-5 gap-4 py-2' key={itemIdx}>
      <div className=' col-span-2'>
        <TooltipProviderCustomised side='top' content={conditionItem?.data?.col_1_label_name || ''}>
          <p className='text-gray-600 font-medium text-sm mb-1'>{conditionItem?.data?.col_1_label_name || ''}</p>
        </TooltipProviderCustomised>
        <ConditionDropDown
          activeValue={UtilityFunctions.activeDropdownValue(
            selectedSaveConditionList[conditionIdx][itemIdx]?.col_1,
            { label: 'Select Item' },
            'col1'
          )}
          options={conditionItem?.data?.col_1_label_options}
          onChange={(data: {
            label: string;
            compare_type: string;
            select_type: string;
          }) =>
            setStaticValue(conditionItem, conditionIdx, itemIdx, {
              label: data?.label,
              compare_type: data?.compare_type,
              select_Type: data?.select_type,
            })
          }
        />
      </div>
      <div className='col-span-3'>
        <div className='flex justify-between'>
          <div className='w-full'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {conditionItem?.data?.col_2_label_name || ''}
            </p>
            <div className='w-full'>
              {renderConditionRepresentationTypeView(
                conditionItem?.representation_type,
                conditionItem,
                conditionIdx,
                itemIdx
              )}
            </div>
          </div>

          <div className='ml-4 mt-6'>
            <div
              className='bg-white border border-gray-300 shadow-sm rounded-md p-2.5 cursor-pointer mt-0.5'
              onClick={() => removeSelectedConditionItem(conditionIdx, itemIdx)}
            >
              <TrashIcon className='w-4 h-4 text-gray-500' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const handleSingleChoiceV2 = (
    value: string,
    component: any,
    conditionIndex: number,
    itemIndex: number
  ) => {
    if (!component) return;
    const {
      component_code,
      label,
      definition,
      component_type,
      component_icon,
    } = component;
    try {
      definition.compare_to.value = value;
      updateSelectedSaveConditionValueList(conditionIndex, itemIndex, {
        select_Type: component_code,
        label,
        definition,
        component_type,
        component_icon,
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  // Renders the appropriate UI representation based on representation_type
  const renderConditionRepresentationTypeView = (
    type: string,
    conditionData: IOrder,
    conditionIndex: number,
    itemIndex: number
  ) => {
    switch (type) {
      case 'two_column_comparator_single_choice_data':
        return (
          <SingleSelectDropDown
            options={UtilityFunctions.findAlias(conditionData)?.alias_as ?? []}
            activeValue={
              UtilityFunctions.activeSingleDropdownValueV2(
                selectedSaveConditionList[conditionIndex][itemIndex]?.col_2,
                { value: '' },
                'col2'
              ).value
            }
            onChange={(value: any) => {
              handleSingleChoiceV2(
                value,
                !!conditionData?.data?.col_2_label_options
                  ? conditionData?.data?.col_2_label_options[0]
                  : null,
                conditionIndex,
                itemIndex
              );
            }}
          />
        );
      case 'two_column_comparator_single_choice':
        // Render UI for two-column comparator type
        return (
          <ConditionDropDown
            activeValue={
              UtilityFunctions.activeDropdownValue(
                selectedSaveConditionList[conditionIndex][itemIndex]?.col_2,
                { label: 'Select Value' },
                'col2'
              )[0]
            }
            options={conditionData?.data?.col_2_label_options || []}
            onChange={({
              component_code,
              label,
              definition,
              component_type,
              component_icon,
            }) =>
              updateSelectedSaveConditionValueList(conditionIndex, itemIndex, {
                select_Type: component_code,
                label,
                definition,
                component_type,
                component_icon,
              })
            }
          />
        );
      case 'two_column_comparator_multi_choice_api':
      case 'two_column_comparator_single_choice_api':
        // Render UI for two-column-multi-choice-api comparator type
        return (
          <DynamicSearchField
            condition={conditionData}
            representType={
              type === 'two_column_comparator_multi_choice_api'
                ? 'multiselect'
                : 'singleselect'
            }
            value={selectedSaveConditionList[conditionIndex][itemIndex]?.col_2}
            handleSelectedValue={(data) => {
              handleUpdateDynamicFieldSaveConditionValue(
                conditionIndex,
                itemIndex,
                data,
                conditionData?.data?.col_2_label_options &&
                  conditionData.data.col_2_label_options[0]
                  ? conditionData.data.col_2_label_options[0]
                  : {}
              );
            }}
          />
        );

      case 'two_column_comparator_multi_choice':
        return (
          <MultipleConditionDropdown
            options={conditionData?.data?.col_2_label_options || []}
            selectedOptions={
              selectedSaveConditionList[conditionIndex][itemIndex]?.col_2
            }
            setSelectedOptions={(data) =>
              handleUpdateMultiSelectedSaveConditionValue(
                conditionIndex,
                itemIndex,
                data,
                conditionData?.component && conditionData?.component
                  ? conditionData?.component
                  : {}
              )
            }
            removeSelectedDisplayOption={(selectedItem: string) =>
              removeSelectedDisplayedOption(
                selectedItem,
                conditionIndex,
                itemIndex
              )
            }
          />
        );
      case 'two_column_comparator_input_field':
      case 'two_column_comparator_string_input_field':
        // Render UI for single-column comparator type
        return (
          <div>
            <input
              value={
                selectedSaveConditionList[conditionIndex][itemIndex]?.col_2
                  .length !== 0
                  ? selectedSaveConditionList[conditionIndex][itemIndex]
                      ?.col_2[0]?.label
                  : ''
              }
              onWheel={(event) => event.currentTarget.blur()}
              type={
                type === 'two_column_comparator_string_input_field'
                  ? 'string'
                  : 'number'
              }
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              placeholder='Value'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                updateSelectedSaveConditionValueList(
                  conditionIndex,
                  itemIndex,
                  {
                    label: value.target.value,
                    definition: conditionData?.data?.col_2_label_options || {},
                  }
                )
              }
            />
          </div>
        );

      default:
        return null;
    }
  };

  // Renders a group of conditions in the UI
  const renderConditionGroupUI = (
    conditionGroup: IOrder[],
    conditionIdx: number
  ) => (
    <div key={conditionIdx} className='mb-4'>
      <div className='px-4 py-2 bg-white rounded'>
        {conditionGroup.map((item: IOrder, itemIdx: number) => {
          return renderConditionItem(item, conditionIdx, itemIdx);
        })}
      </div>
      <div className='mt-4 flex items-center'>
        <div
          className='bg-white border border-gray-300 text-gray-700 font-medium text-xs rounded py-2 px-4 mr-4 cursor-pointer flex items-center'
          onClick={() =>
            handleAddMoreConditions(conditionIdx, 'add_condition_list')
          }
        >
          <PlusIcon className='h-5 w-5 text-gray-700 mr-1' />
          And
        </div>

        {selectedConditions.length - 1 === conditionIdx ? (
          <div
            className='bg-white border border-gray-300 text-gray-700 font-medium text-xs rounded py-2 px-4 cursor-pointer flex items-center'
            onClick={() => handleAddMoreConditions(null, 'add_condition_list')}
          >
            <PlusIcon className='h-5 w-5 text-gray-700 mr-1' />
            Or
          </div>
        ) : null}
      </div>
      {conditionIdx !== selectedConditions.length - 1 && (
        <div className='mt-9 mb-10 border-b-2 border-gray-200 relative'>
          <div className='absolute left-1/2 -translate-x-1/2 top-[-18px] bg-white rounded-full py-2 px-4'>
            Or
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <ConditionHeader
        title='Add Condition'
        previousUIAction={() =>
          updateConditionallyRenderingConditionUI('add_condition_list')
        }
      />
      <div
        className='bg-gray-50 p-4 overflow-auto'
        style={{ height: 'calc(100vh - 216px)' }}
      >
        {/* Maps through selected conditions and renders condition groups */}
        {selectedConditions?.map(
          (conditionGroup: IOrder[], conditionIdx: number) => {
            return renderConditionGroupUI(conditionGroup, conditionIdx);
          }
        )}
      </div>
    </div>
  );
};

export default AddConditions;
