import useTranslation from 'components/customHooks/useTranslation';
import CopyText from 'library/copyText';
import BlankModal from 'library/modal/BlankModal';
import { IWhatsappAccountInfo } from 'pages/integration/interface';
import {
  WhatsappBspIcon,
  WhatsappGrayIcon,
  WhatsappGreenTickIcon,
} from 'pages/integration/utils/content';
import React from 'react';

type Props = {
  setOpenAccountInfoModal: (value: boolean) => void;
  whatsappAccountInfoData: IWhatsappAccountInfo | null;
  isOpenWhatsappAccountInfoModal: boolean;
};

const WhatsappAccountInfo: React.FC<Props> = ({
  setOpenAccountInfoModal,
  whatsappAccountInfoData,
  isOpenWhatsappAccountInfoModal,
}) => {
  const { t } = useTranslation();
  const renderAccountStatusView = (type: string) => {
    switch (type) {
      case 'APPROVED':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {t('Approved')}
          </div>
        );
      case 'REJECTED':
        return (
          <div className='bg-red-100 text-red-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            {t('Rejected')}
          </div>
        );
      case 'PENDING':
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            {t('Pending')}
          </div>
        );

      default:
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {type}
          </div>
        );
    }
  };

  const renderFbBusinessVerificationView = (type: string) => {
    switch (type) {
      case 'verified':
        return (
          <div className='bg-green-100 rtl:text-right text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {t('Verified')}
          </div>
        );
      case 'not_verified':
        return (
          <div className='bg-gray-100 rtl:text-right text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {t('Not Verified')}
          </div>
        );

      default:
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {type}
          </div>
        );
    }
  };

  const renderQualityRatingView = (type: string) => {
    switch (type) {
      case 'GREEN':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {t('High')}
          </div>
        );
      case 'YELLOW':
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            {t('Medium')}
          </div>
        );
      case 'RED':
        return (
          <div className='bg-red-100 text-red-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            {t('Low')}
          </div>
        );

      default:
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {type}
          </div>
        );
    }
  };

  const renderStatusView = (type: string) => {
    switch (type) {
      case 'CONNECTED':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Connected
          </div>
        );
      case 'FLAGGED':
        return (
          <div className='bg-red-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Flagged
          </div>
        );
      case 'RESTRICTED':
        return (
          <div className='bg-red-100 text-red-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Restricted
          </div>
        );

      default:
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {type}
          </div>
        );
    }
  };

  const renderThoughputView = (type: string) => {
    switch (type) {
      case 'STANDARD':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Standard
          </div>
        );
      case 'HIGH':
        return (
          <div className='bg-green-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            High
          </div>
        );
      case 'NOT_APPLICABLE':
        return (
          <div className='bg-red-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Not Applicable
          </div>
        );

      default:
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            {type}
          </div>
        );
    }
  };

  return (
    <>
      <BlankModal
        open={isOpenWhatsappAccountInfoModal}
        hideFooter={true}
        handleClose={() => {
          setOpenAccountInfoModal(false);
        }}
        title={t('Account Info')}
        headerIcon={<img src={WhatsappBspIcon} alt='' />}
      >
        <div className='grid grid-cols-2 gap-6 pb-4'>
          <div className='border rtl:text-right border-gray-200 bg-gray-50 rounded-md p-4'>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-700 text-base font-semibold ltr:mr-1.5 rtl:ml-1.5'>
                  {whatsappAccountInfoData?.business_name}
                </h3>
                {!!whatsappAccountInfoData?.green_tick && (
                  <div>
                    <WhatsappGreenTickIcon />
                  </div>
                )}
              </div>
              <div className='flex items-center mt-1'>
                <div className='ltr:mr-1 rtl:ml-1'>
                  <WhatsappGrayIcon />
                </div>
                <div className='text-gray-500 text-xs'>
                  {t('Connected with')}{' '}
                  <span className='text-gray-800'>
                    {whatsappAccountInfoData?.display_phone_number}
                  </span>
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                {t('Business name')}
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 rtl:text-right text-sm'>
                  {whatsappAccountInfoData?.business_name}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase ltr:mr-1 rtl:ml-1'>
                  {t('WABA ID')}
                </h3>
                <div className='w-6 h-6 mt-1'>
                  <CopyText
                    height={20}
                    textToCopy={whatsappAccountInfoData?.waba_id ?? ''}
                  />
                </div>
              </div>
              <div className='mt-1'>
                <div className='text-gray-800 rtl:text-right text-sm'>
                  {whatsappAccountInfoData?.waba_id}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Status
              </h3>
              <div className='mt-1'>
                {renderStatusView(whatsappAccountInfoData?.status ?? '')}
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                {t('account status')}
              </h3>
              <div className='mt-1'>
                {renderAccountStatusView(
                  whatsappAccountInfoData?.account_status ?? ''
                )}
              </div>
            </div>
            <div className=''>
              <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                {t('Message limit')}
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 rtl:text-right text-sm'>
                  {whatsappAccountInfoData?.message_limit}
                </div>
              </div>
            </div>
          </div>
          <div className='border border-gray-200 bg-gray-50 rounded-md p-4'>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-700 rtl:text-right text-base font-semibold ltr:mr-1.5 rtl:ml-1.5'>
                  {t('Facebook Business')}
                </h3>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                {t('Facebook Business name')}
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 rtl:text-right text-sm'>
                  {whatsappAccountInfoData?.fb_business_name}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                  {t('FB business verification')}
                </h3>
              </div>
              <div className='mt-1'>
                <div>
                  {renderFbBusinessVerificationView(
                    whatsappAccountInfoData?.business_verification_status ?? ''
                  )}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 rtl:text-right text-xs font-semibold uppercase'>
                {t('Quality ratings')}
              </h3>
              <div className='mt-1'>
                {renderQualityRatingView(
                  whatsappAccountInfoData?.quality_rating ?? ''
                )}
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Throughput
              </h3>
              <div className='mt-1'>
                {renderThoughputView(whatsappAccountInfoData?.throughput ?? '')}
              </div>
            </div>
          </div>
        </div>
      </BlankModal>
    </>
  );
};

export default React.memo(WhatsappAccountInfo);
