import { TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { SurveyDataEntity } from 'pages/integration/interface';
import { AttributeIcon } from '../../assets/img/icons/svgIcons';
import { attributeData } from '../../utils/constent';
import DropDown from './DropDownWithButton';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  attribute?: string;
  title?: string;
  handleRemove: () => void;
  handleOnChange: (data: SurveyDataEntity) => void;
}

const AttributeInputField: React.FC<Props> = ({
  attribute = '',
  title = '',
  handleRemove,
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const getAttributeName = () => {
    let attributeName = '';
    attributeData.forEach((data) => {
      if (data.value === attribute) {
        attributeName = data.name;
      }
    });

    return attributeName;
  };
  const getValueData = () => {
    if (!attribute) {
      return (
        <span className='text-sm text-gray-500'>
          {t('Set Field Attribute')}
        </span>
      );
    } else {
      return (
        <span className='px-2 py-1 text-xs bg-green-100 text-green-800 rounded-md'>
          {getAttributeName()}{' '}
          <XMarkIcon
            className='inline'
            color={'rgba(5, 150, 105)'}
            height={10}
            width={10}
            onClick={() => {
              handleOnChange({ title, attribute: '' });
            }}
          />
        </span>
      );
    }
  };

  // const childRef = useRef<HTMLDivElement | null>(null);
  // const [isClickedBefore, setClickedBefore] = useState<boolean>(false);

  return (
    <>
      <div className=' border border-gray-300 rounded-md'>
        <div className='relative w-full border-b border-gray-300 items-center justify-center'>
          <input
            type={'text'}
            className='w-3/4 text-sm p-2 border-0 outline-0 bg-transparent text-gray-500 focus:outline-none focus:border-transparent focus:ring-0'
            value={t(title)}
            placeholder={t('Field Title')}
            onChange={(event) => {
              handleOnChange({ title: event.target.value, attribute });
            }}
          />
          <span className='absolute ltr:right-3 rtl:left-3 top-2 cursor-pointer'>
            <TrashIcon
              color='rgba(220, 38, 38)'
              width={20}
              height={20}
              onClick={() => {
                handleRemove();
              }}
            />
          </span>
        </div>

        <div className='p-2'>
          <DropDown
            classes='w-full  text-gray-600 hover:text-gray-600 focus:outline-none'
            align='ltr:left-0 '
            width='w-full'
            isFullWidth={true}
            buttonComponent={
              <div className='relative flex items-center w-full'>
                {getValueData()}
                <div className='absolute ltr:right-1 rtl:left-1 top-1'>
                  <AttributeIcon
                    fillColor='rgba(220, 38, 38)'
                    width={'20'}
                    height={'25'}
                  />
                </div>
              </div>
            }
            value={attribute}
            options={attributeData}
            handleDataSelection={(val) => {
              handleOnChange({ title, attribute: val.value });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AttributeInputField;
