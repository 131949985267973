import React, { Component } from 'react';
import { connect } from 'react-redux';
import userPasswordIcon from '../../../assets/images/user-password-settings.svg';
import SettingsUserInformation from '../component/SettingsUserInformation';
import SettingsUserPassword from '../component/SettingsUserPassword';
import userInfoIcon from '../../../assets/images/user-info-settings.svg';
import { isValidEmail, handleImageUpload } from '../../../../utilities/utils';
import { toaster } from 'evergreen-ui';
import SwitchWrapper from '../component/notification/SwitchWrapper';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserInfoChanged: false,
    };
  }

  handleChange = (name, value) => {
    this.setState({ isUserInfoChanged: true });
    this.props.updateSingleUserInfo(name, value);
  };

  handleSave = async () => {
    if (
      !!this.props.userInfo.email &&
      isValidEmail(this.props.userInfo.email)
    ) {
      await this.props.editUserInfo(this.props.userInfo);
      this.setState({ isUserInfoChanged: false });
    } else {
      toaster.warning('Invalid', {
        description: `Email Address is Invalid or Empty`,
        duration: 1,
      });
    }
  };
  handleCancel = () => {
    this.setState({ isUserInfoChanged: false });
    this.props.fetchUserInfo();
  };

  componentDidMount() {
    this.props.fetchUserInfo();
    this.props.updateActiveSettings(0);
  }
  handleImageChange = (image) => {
    this.setState({ isUserInfoChanged: true });
    handleImageUpload(image, (img) =>
      this.props.updateSingleUserInfo('avatar', img)
    );
  };

  handleTwoFactorAuthValueChange = async (value) => {
    if (this.props.userInfo.hasOwnProperty('is_2fa_enabled')) {
      this.props.userInfo.is_2fa_enabled = value;
      await this.props.editUserInfo(this.props.userInfo);
    }
  };

  renderTwoFactorAuthView = () => {
    return (
      <div className='px-3 py-2 border border-gray-200 my-2'>
        <div className='flex items-center px-4 py-4 sm:px-6'>
          <div className='flex items-center flex-1 min-w-0'>
            <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
              <div>
                <p className='text-base font-medium text-gray-700 truncate'>
                  <TranslationWrapper text='Enable Two Step Verification' />
                </p>
                <p className='flex items-center mt-2 text-sm text-gray-500'>
                  <TranslationWrapper text='Require an authentication code when you log in with an email and password.' />
                </p>
              </div>
            </div>
          </div>
          <div>
            <SwitchWrapper
              disabled={false}
              checked={
                !!this.props.userInfo?.is_2fa_enabled &&
                this.props.userInfo?.is_2fa_enabled
              }
              onChange={(checked) => {
                this.handleTwoFactorAuthValueChange(checked);
              }}
              hasBellIcon={false}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id='profile-settings'>
        <SettingsUserInformation
          title='User Information'
          description='User Information like name, avatar change etc.'
          icon={userInfoIcon}
          infoLoader={this.props.infoLoader}
          infoUpdateLoader={this.props.infoUpdateLoader}
          userInfo={this.props.userInfo}
          handleChange={(name, value) => this.handleChange(name, value)}
          handleImageChange={this.handleImageChange}
          handleImageClear={() => {
            this.setState({ isUserInfoChanged: true });
            this.props.updateSingleUserInfo('avatar', '');
          }}
          isUserInfoChanged={this.state.isUserInfoChanged}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
        />
        {this.renderTwoFactorAuthView()}
        <SettingsUserPassword
          title='Change Password'
          subtitle='User password change here.'
          icon={userPasswordIcon}
          handleSave={this.props.editUserInfo}
          infoUpdateLoader={this.props.infoUpdateLoader}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  activeSettings: state.settings.activeSettings,
  userInfo: state.settings.userInfo,
  infoLoader: state.loading.effects.settings.fetchUserInfo,
  infoUpdateLoader: state.loading.effects.settings.editUserInfo,
});

const mapDispatch = (dispatch) => ({
  updateActiveSettings: (payload) =>
    dispatch.settings.updateActiveSettings(payload),
  fetchUserInfo: () => dispatch.settings.fetchUserInfo(),
  updateSingleUserInfo: (key, value) =>
    dispatch.settings.updateSingleUserInfo({ key, value }),
  editUserInfo: (payload) => dispatch.settings.editUserInfo(payload),
});

const ProfileSettingsContainer = connect(
  mapState,
  mapDispatch
)(ProfileSettings);

export default ProfileSettingsContainer;
