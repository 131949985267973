import React from 'react';

import { XCircleIcon } from '@heroicons/react/20/solid';
import { LiveChatDataProps } from 'pages/integration/interface';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  success?: boolean;
  errorMessage?: string;
  liveChatData: LiveChatDataProps;
  handleChange: (data: LiveChatDataProps) => void;
}

const Step1: React.FC<Props> = ({
  liveChatData,
  handleChange,
  success,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldReplaceMyalice = isPartnerRestricted(
    'Step1/node/live-chat-partner'
  );
  const partnerName = shouldReplaceMyalice
    ? currentPartnerInfo?.shortName
    : 'MyAlice';

  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div className='col-span-6 sm:col-span-6'>
        <label
          htmlFor='title'
          className='block rtl:text-right text-sm font-medium text-gray-700'
        >
          {t('Title')}*
        </label>
        <input
          type='text'
          placeholder={t('My website channel')}
          value={liveChatData.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            liveChatData.title = e.target.value;
            handleChange({ ...liveChatData });
          }}
          name='title'
          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
        <p
          className='mt-2 text-sm rtl:text-right text-gray-500'
          id='live-chat-partner'
        >
          {t(
            'Use this name to differentiate this channel from your other channels on ' +
              `{{${partnerName}}}` +
              '. Your channel name is private to you and your team members only.'
          )}
        </p>
      </div>
    </>
  );
};
export default Step1;
