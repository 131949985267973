import { GripVerticalIcon, SquarePenIcon } from 'lucide-react';
import GroupBlockList from './groupBlockList';
import React, { useState, useRef, useEffect } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';

import { Input } from 'libraryV2/ui/input';

import useTranslation from 'components/customHooks/useTranslation';
import {
  capitalizeFirstLetter,
  DeleteCofirmationDialog,
  DragDropIcon,
  FormFields,
  IDataLabField,
  platinum_color,
  tertiary_color,
  TRIGGER_PRIMARY_COLOR,
  useActions,
  useTriggerActions,
} from 'pages/datalab/export';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const Group: React.FC<IProps> = (props) => {
  const inputRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [groupTitle, setGroupTitle] = useState(props.field?.name || '');
  const { t } = useTranslation();
  const { field, isDragging, nodeRef } = props;
  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();
  const { deleteDataLabField, updateDataLabField } = useTriggerActions();

  const { id: fieldId, name, type = 'group', children = [] } = field;

  const selectedFieldId = getSelectedFieldId();

  const { icon } = FormFields[type];

  const hanleOnEditFormTitle = () => {
    setEditable(!editable);
  };

  useEffect(() => {
    if (editable && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editable]);

  const handleTitleSaveChanges = () => {
    const { labId } = getController();
    if (field.name !== groupTitle) {
      field.name = groupTitle;
      updateDataLabField(labId, fieldId, field);
    }
    setEditable(false);
  };

  const handleOnBlur = () => {
    setEditable(false);
  };

  const handleOnFormTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGroupTitle(event.target.value);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId);
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = TRIGGER_PRIMARY_COLOR;
  }

  const fieldName = capitalizeFirstLetter(name);

  const draggingStyles = isDragging
    ? { opacity: 0, backGraound: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  return (
    <AccordionItem
      value={fieldId}
      className='border bg-white rounded-md'
      ref={nodeRef}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        defaultChecked={selectedFieldId === fieldId}
        onClick={handleOnBlockFieldClick}
        isShowUpDownArrow={false}
      >
        <div className='flex justify-between items-center w-full'>
          <GripVerticalIcon color={platinum_color} />
          <span className='mx-2 border rounded p-1 text-zinc-200'>{icon}</span>
          <span className='w-full' style={accordionTriggerStyle}>
            {editable ? (
              <span className='flex text-sm items-center w-full'>
                <Input
                  name='form-title'
                  value={groupTitle}
                  onChange={handleOnFormTitleChange}
                  className='border-none focus:ring-0 focus:outline-none shadow-none w-[90%] px-0 pb-1 pt-1 mt-0'
                  ref={inputRef}
                  onBlur={handleOnBlur}
                />

                <span
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={handleTitleSaveChanges}
                  className='pl-4'
                >
                  Save
                </span>
              </span>
            ) : (
              <span className='flex text-sm items-center w-full'>
                {t(groupTitle)}
                <SquarePenIcon
                  onClick={hanleOnEditFormTitle}
                  className='ltr:ml-2 rtl:mr-2 h-4 w-4'
                  color={tertiary_color}
                />
              </span>
            )}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent
        className='px-4 py-2 border-t'
        style={{ background: '#fafafa' }}
      >
        <div
          className='row-span-2 col-span-2 mt-1 mb-1'
          style={{ width: '548px' }}
        >
          <GroupBlockList groupId={fieldId} children={children} />
          <div
            className='flex justify-center items-center rounded border-dashed border-2 border-gray-300 mt-4 bg-white'
            style={{ width: '100%', height: '52px' }}
          >
            <DragDropIcon />
            <span className='text-sm text-zinc-500 ltr:ml-2 rtl:mr-2'>
              {t('Drag items from left bar')}
            </span>
          </div>
        </div>
      </AccordionContent>

      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <DeleteCofirmationDialog
              type={type}
              onDelete={handleOnRemove}
              name={fieldName}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default Group;
