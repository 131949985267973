import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { acceptInvitationAPiResponseType } from '../inteface';

const useOTP = () => {
  const dispatch = useDispatch();
  const [token, updateToken] = useState<string>('');
  const authData = useSelector((state: any) => state.auth);

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const updateTimer = (time: number) => dispatch.auth.updateTimer(time);

  const acceptInvitation = async (token: string) => {
    const invitationResponse: acceptInvitationAPiResponseType =
      await dispatch.auth.acceptTeamInvitation(token);
    if (invitationResponse?.status === 200) {
      await dispatch.auth.fetchProject();
      toaster.success('Invitation accepted');
    } else if (invitationResponse?.status === 500) {
      toaster.danger('Something went wrong. Could Not Accept Invitation');
    } else {
      toaster.danger(invitationResponse?.data?.error);
    }
  };

  const confirmLogin = async () => {
    if (authData.invitationToken.length !== 0) {
      const validationResponse = await dispatch.auth.validateInvitationToken(
        authData.invitationToken
      );
      if (!!validationResponse) acceptInvitation(authData.invitationToken);
      dispatch.auth.updateInvitationToken('');
    }

    const shopifyToken = localStorage.getItem(
      'shopify_installation_request_id'
    );

    if (!!shopifyToken) {
      navigate('/shopify-connect');
    } else if (authData.hasOnboarding) {
      navigate('/onboarding');
    } else {
      navigate('/dashboard');
    }
  };

  const verifyOTP = async (token: string, otp: string) => {
    if (!!otp && otp.length === 6) {
      setVerifyLoading(true);
      const res = await dispatch.auth.verifyOtp({ token, otp });

      if (res.success) {
        toaster.success('OTP Verified Successfully');
        await confirmLogin();
      } else {
        toaster.danger(res.error);
        navigate('/');
      }
    } else {
      toaster.warning('Please enter a valid otp');
    }
    setVerifyLoading(false);
  };

  const resendOTP = async (token: string, email: string) => {
    setResendLoading(true);
    const res = await dispatch.auth.resendOtp({ token });
    setResendLoading(false);
    if (res.success) {
      toaster.notify(
        'An OTP has been sent to your email address. Please check your inbox for the latest one.'
      );
      updateToken(!!res?.token ? res?.token : '');
      return true;
    } else {
      toaster.warning("Can't procced at this moment, please try again.");
      return false;
    }
  };

  return {
    spentTime: authData.spentTime,
    verifyLoading,
    resendLoading,
    updateTimer,
    updateToken,
    verifyOTP,
    resendOTP,
    token,
  };
};

export default useOTP;
