import React from 'react';
import { status as Environment } from '../../utilities/config';

interface teamProps {
  teamId: number;
  allowedTeamList: number[];
}

type AllOrNone<T> = Required<T>;
type teamPropsType = AllOrNone<teamProps>;

interface Props {
  children: React.ReactNode;
  shouldRender?: boolean;
  allowedTeam?: teamPropsType | null;
  allowedEnvironment: string[];
}

const FeatureFlag: React.FC<Props> = ({
  children,
  shouldRender = true,
  allowedTeam,
  allowedEnvironment,
}) => {
  const getChildren = () => {
    if (['staging', 'dev'].includes(Environment)) return children;
    else if (
      !!allowedTeam &&
      !allowedTeam.allowedTeamList?.includes(allowedTeam.teamId)
    ) {
      return null;
    }
    return children;
  };
  return (
    <>
      {allowedEnvironment.includes(Environment) && shouldRender
        ? getChildren()
        : null}
    </>
  );
};
export default FeatureFlag;
