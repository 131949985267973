import React, { Fragment } from 'react';
import { classNames } from 'utilities/utils';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from 'library';
import { countryDataInterface } from './interfaces';
import TimezoneOptionsDropdown from './TimezoneOptionsDropdown';
import CountryOptionsDropdown from './CountryOptionsDropdown';
import { categoryType, TimezoneDetailsProps } from './interfaces';
import ReactTooltip from 'react-tooltip';
import Alert from 'old/components/elements/redesign/Alert';

interface Step1Props {
  onNextClick: () => void;
  selectCountry: (value: countryDataInterface) => void;
  selectedCountry: countryDataInterface | null;
  countryList?: countryDataInterface[];
  selectedCategory: categoryType | null;
  setSelectedCategory: any;
  businessCategories: categoryType[];
  setBusinessUrl: (value: string) => void;
  businessUrl: string;
  teamName: string;
  setTeamName: (value: string) => void;
  handleTimezoneChange: (data: any) => void;
  timeZone: TimezoneDetailsProps;
  timeZoneList: TimezoneDetailsProps[];
  errorMassage: string;
  validUrl: string;
}

const InformationForm: React.FC<Step1Props> = ({
  onNextClick,
  selectCountry,
  selectedCountry,
  countryList,
  selectedCategory,
  setSelectedCategory,
  businessCategories,
  setBusinessUrl,
  businessUrl,
  teamName,
  setTeamName,
  handleTimezoneChange,
  timeZone,
  timeZoneList,
  errorMassage,
  validUrl,
}) => {
  const countryToolTip =
    'The selected country will be set as default for your team.<br />The currency will be adjusted when viewing prices and sales for all E-commerce features.';
  const timezoneToolTip =
    'The selected timezone will be adjusted for all ticket related actions, <br />agent metrics and any data involving time measurements.';

  return (
    <div className='flex flex-col justify-center h-screen p-8 bg-white rounded sm:block sm:h-auto'>
      <p className='mb-2 font-sans text-2xl font-bold text-center'>
        Create a new team!
      </p>
      <p className='font-sans text-sm text-center text-gray-500'>
        Your current account will be set as admin
      </p>
      <div className='py-4 space-y-4'>
        {/* First Name */}
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Team Name
          </label>
          <div className='mt-1'>
            <input
              value={teamName}
              type='text'
              onChange={(e) => setTeamName(e.target.value)}
              className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
              placeholder='Type here...'
              aria-describedby='first-name'
            />
          </div>
          {errorMassage !== '' && (
            <div className='mt-3'>
              <Alert intent='danger' text={errorMassage} />
            </div>
          )}
        </div>
        <div>
          <p className='flex items-center font-sans text-sm font-medium text-gray-700'>
            Country
            <span data-for='time-tooltip' data-tip={countryToolTip}>
              <InformationCircleIcon
                className='w-4 h-4 ml-1'
                aria-hidden='true'
              />
            </span>
          </p>
          <ReactTooltip
            id='country-tooltip'
            place='top'
            type='dark'
            effect='float'
            html={true}
          />
          <CountryOptionsDropdown
            selected={selectedCountry}
            options={countryList}
            handleChange={(data) => selectCountry(data)}
          />
        </div>
        <div>
          <p className='flex items-center font-sans text-sm font-medium text-gray-700'>
            Timezone
            <span data-for='time-tooltip' data-tip={timezoneToolTip}>
              <InformationCircleIcon
                className='w-4 h-4 ml-1'
                aria-hidden='true'
              />
            </span>
          </p>
          <ReactTooltip
            id='time-tooltip'
            place='top'
            type='dark'
            effect='float'
            html={true}
          />
          <TimezoneOptionsDropdown
            selected={timeZone}
            options={timeZoneList}
            handleChange={(data) => handleTimezoneChange(data)}
          />
        </div>
        <div>
          <div className='flex justify-between'>
            <label className='block text-sm font-medium text-gray-700'>
              Business URL
            </label>
            <span
              className='font-medium text-sm text-gray-500'
              id='email-optional'
            >
              Optional
            </span>
          </div>
          <div className='flex mt-1 rounded-md shadow-sm'>
            <span className='inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm'>
              https://
            </span>
            <input
              value={businessUrl}
              type='text'
              onChange={(e) => setBusinessUrl(e.target.value)}
              className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md focus:ring-primary focus:border-primary sm:text-sm'
              placeholder='example.com'
            />
          </div>
          {validUrl !== '' && (
            <div className='mt-3'>
              <Alert intent='danger' text={validUrl} />
            </div>
          )}
        </div>
        <div>
          <div className='mt-1'>
            <Listbox value={selectedCategory} onChange={setSelectedCategory}>
              {({ open }) => (
                <>
                  <Listbox.Label className='relative block text-sm font-medium text-gray-700'>
                    Business Type
                    <span
                      className='absolute text-sm text-gray-500 right-0'
                      id='email-optional'
                    >
                      Optional
                    </span>
                  </Listbox.Label>
                  <div className='relative mt-1'>
                    <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm'>
                      <span className='flex items-center'>
                        {selectedCategory?.emoji}
                        <span className='block ml-3 truncate text-gray-500'>
                          {selectedCategory?.name ||
                            'Select your business type'}
                        </span>
                      </span>
                      <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
                        <ChevronUpDownIcon
                          className='w-5 h-5 text-gray-400'
                          aria-hidden='true'
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave='transition ease-in duration-100'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'
                    >
                      <Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                        {businessCategories &&
                          businessCategories.map((category, i) => (
                            <Listbox.Option
                              key={i}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'text-white bg-primary'
                                    : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                              }
                              value={category}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className='flex items-center'>
                                    {category.emoji}
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'ml-3 block truncate'
                                      )}
                                    >
                                      {category.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-primary',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className='w-5 h-5'
                                        aria-hidden='true'
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
      </div>
      <Button
        onClick={() => onNextClick()}
        className='flex justify-center w-full mt-8 text-normal'
        intent='primary'
      >
        Next
      </Button>
    </div>
  );
};

export default InformationForm;
