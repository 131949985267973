import { XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { classNames } from 'utilities/utils';
import InputFiledWithLimit from './InputFieldWithLimit';
import useTranslation from 'components/customHooks/useTranslation';
import {
  ISavedFilterData,
  TicketFilterInterface,
} from 'pages/inbox/inboxInterface';

interface Props {
  close: Function;
  isUpdating: boolean;
  prevoiusData: ISavedFilterData | null;
  savedFilterData: ISavedFilterData[];
  ticketFilterData: TicketFilterInterface;
  handleClose: () => void;
  updateSavedData: (
    filterList: ISavedFilterData[],
    filterName: string,
    type: string
  ) => void;
}

const SaveFilter: React.FC<Props> = ({
  close,
  isUpdating,
  prevoiusData,
  savedFilterData,
  ticketFilterData,
  handleClose,
  updateSavedData,
}) => {
  const maxSavedFilterCount = 7;
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState<string>('');
  const [dataError, setDataError] = useState({ hasError: false, errorMsg: '' });

  useEffect(() => {
    if (isUpdating && prevoiusData) {
      setFilterName(prevoiusData.name);
    }
  }, [isUpdating, prevoiusData]);

  const getButtonAccess = () => {
    return filterName && !dataError.hasError;
  };

  const checkDuplicity = (name: string) => {
    let isDuplicate =
      savedFilterData.filter((data: ISavedFilterData) => data.name === name)
        .length > 0;
    setDataError({
      hasError: isDuplicate,
      errorMsg: t('A duplicate name already exists'),
    });
    setFilterName(name);
  };

  const handleSaveData = () => {
    if (!savedFilterData) {
      updateSavedData(
        [{ name: filterName, data: ticketFilterData }],
        filterName,
        'update'
      );
    } else if (isUpdating && prevoiusData) {
      const newList = savedFilterData.map((item) => {
        if (item.name === prevoiusData.name)
          return { name: filterName, data: ticketFilterData };
        else return item;
      });
      updateSavedData([...newList], filterName, 'update');
    } else if (!dataError.hasError) {
      if (savedFilterData.length < maxSavedFilterCount) {
        updateSavedData(
          [...savedFilterData, { name: filterName, data: ticketFilterData }],
          filterName,
          'update'
        );
      } else {
        setDataError({
          hasError: true,
          errorMsg: `Saved limit(${maxSavedFilterCount}) crossed`,
        });
      }
    }
  };
  return (
    <>
      <div className='absolute w-full h-full left-0 top-0 rounded-md'>
        <div className='absolute w-full h-full left-0 top-0 bg-gray-600 opacity-20 rounded-md'></div>
        <div className='absolute bottom-0 p-4 bg-white w-full rounded-md'>
          <div className='w-full flex justify-between mb-2'>
            <span className='text-sm font-semibold leading-5 float-left'>
              {isUpdating ? t('Update View') : t('Save as View')}
            </span>
            <span onClick={() => handleClose()}>
              <XMarkIcon color='#6B7280' width={18} />
            </span>
          </div>
          <InputFiledWithLimit
            limit={12}
            placeHolder={t('New inbox view')}
            value={filterName}
            handleOnChange={(val) => {
              checkDuplicity(val);
            }}
            hasError={dataError.hasError}
            errorMessage={dataError.errorMsg}
          />
          <button
            disabled={!getButtonAccess()}
            className={classNames(
              'w-full px-4 py-2 rounded-md  shadow-sm my-2 z-10',
              getButtonAccess()
                ? 'bg-[#04B25F] text-white'
                : 'bg-gray-300 text-gray-900'
            )}
            onClick={() => {
              handleSaveData();
              close();
            }}
          >
            {isUpdating ? t('Save') : t('Save for yourself')}
          </button>
        </div>
      </div>
    </>
  );
};

export default SaveFilter;
