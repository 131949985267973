import React from 'react';
import { toaster } from 'evergreen-ui';
import { isValidUrl } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  handleChange: (key: string, value: string | string[]) => void;
  tagList: string[];
}

const InputTag: React.FC<Props> = ({ handleChange, tagList }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');

  const handleAdd = () => {
    if (isValidUrl(value)) {
      let updatedTagList = isTagListValid() ? [...tagList, value] : [value];
      handleChange('whitelisted_domains', updatedTagList);
      setValue('');
    } else {
      toaster.warning('Add valid URL');
    }
  };

  const isTagListValid = () => {
    return tagList && tagList.length !== 0;
  };

  const getInputTag = (value: string, id: number) => {
    return (
      <div
        key={id}
        className='my-2 mr-2 inset-y-0 left-2 pr-3 inline-flex items-center py-0.5 pl-2  rounded text-xs font-medium bg-green-100 text-green-800'
      >
        {value?.length > 30 ? value.substring(0, 30) + '...' : value}
        <button
          type='button'
          className='flex-shrink-0 ml-0.5 h-4 w-4 inline-flex items-center justify-center text-indigo-400 hover:bg-transparent hover:text-gary-700 focus:outline-none focus:bg-transparent focus:text-white'
          onClick={() => {
            let updatedTagList = [
              ...tagList.slice(0, id),
              ...tagList.slice(id + 1),
            ];
            handleChange('whitelisted_domains', updatedTagList);
          }}
        >
          <svg
            className='w-2 h-2'
            stroke='#34D399'
            fill='#34D399'
            viewBox='0 0 8 8'
          >
            <path
              strokeLinecap='round'
              strokeWidth='1.5'
              d='M1 1l6 6m0-6L1 7'
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className='flex mt-1 rounded-md shadow-sm'>
        <input
          type='text'
          name='whitelisted_domains'
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter' && value.length !== 0) handleAdd();
          }}
          className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-md rounded-r-md focus:ring-primary focus:border-primary sm:text-sm'
          placeholder={t(`Provide the domain here and press "Enter"`)}
        />
      </div>

      {isTagListValid() && (
        <label
          htmlFor='url_whitelist'
          className='block mt-3 text-sm font-medium text-gray-700'
        >
          Tags
        </label>
      )}
      {isTagListValid() &&
        tagList.map((item: string, id) => {
          return getInputTag(item, id);
        })}
    </div>
  );
};
export default InputTag;
