import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'evergreen-ui';
import { getSum } from '../../../../utilities/utils';
import DataPieChart from './DataPieChart';
import EmptyDoughNut from '../../assets/images/EmptyDoughnut.svg';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';

const COLORS = ['#50AEF4', '#F43F5E', '#FBBF24', '#9CA3AF', '#A78BFA'];

const PieGraph = ({ data, color, title, toolTipInfo, loader }) => {
  const { t } = useTranslation();
  return (
    <div className='h-full overflow-hidden bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='flex text-base font-normal leading-6 text-gray-900'>
          {t(title)}
          {!!toolTipInfo ? (
            <span className='mt-1 ltr:ml-2 rtl:mr-2' title={t(toolTipInfo)}>
              <InformationCircleIcon className='w-4 h-4' />
            </span>
          ) : (
            ''
          )}
        </h3>
        <div className='mt-1'>
          {!loader && (
            <div className='grid grid-cols-1 gap-5 sm:grid-cols-2 place-items-center'>
              {getSum(data, 'value') > 0 ? (
                <DataPieChart data={data} color={color} />
              ) : (
                <div className='flex flex-col items-center mt-5'>
                  <img
                    src={EmptyDoughNut}
                    alt={'empty table'}
                    className={'w-48'}
                  />
                </div>
              )}
              <div className='mt-1 w-44'>
                {!loader &&
                  data &&
                  data.map((data, index) => (
                    <div
                      key={index}
                      className='flex items-center p-2 mt-1 space-between'
                    >
                      <div className={'flex items-center'}>
                        <div
                          className='w-5 h-5 rounded-sm'
                          style={{ background: COLORS[index] }}
                        />
                        <div className='ltr:ml-2 rtl:mr-2 text-xs font-medium font-inter'>
                          {t(data.name)}
                        </div>
                      </div>
                      <div className='text-xs font-normal font-inter'>
                        {data.percentage}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {loader && (
            <div>
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PieGraph.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

PieGraph.defaultProps = {
  data: [],
};

export default PieGraph;
