import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BlockContainer from './BlockContainer';
import AttributeAPIContainer from '../elements/AttributeAPIContainer';
import {
  updateOnSelectAttribute,
  updateOnChangeText,
} from '../../../utilities/utils';
import textIcon from '../../pages/builder/assets/images/icons/text.svg';
import TextCharInfo from '../elements/TextCharInfo';
import { Textarea } from 'evergreen-ui';

const getMaxTextLimit = (platformType) => {
  switch (platformType) {
    case 'instagram_messenger':
      return 1000;
    default:
      return 2000;
  }
};

const TextBlock = ({
  id,
  text,
  setText,
  save,
  type,
  saveBlock,
  attributeList,
  apiList,
  deleteBlock,
  sequences,
  copyBlock,
  moveBlock,
  saveLoader,
  hasWriteAccess,
  platformType,
}) => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [maxCount] = useState(getMaxTextLimit(platformType));

  return (
    <BlockContainer
      id={id}
      title='Text'
      subtitle='Send text messages to your customers.'
      link='https://docs.myalice.ai/automate-channels/chatbot-blocks/add-and-send-content-blocks#1-text-block'
      save={save}
      isLoading={saveLoader}
      icon={textIcon}
      handleSaveClick={() => saveBlock(id)}
      handleDelete={() => deleteBlock(id)}
      sequences={sequences}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <div>
        <AttributeAPIContainer
          attributeList={attributeList}
          showAttribute={showAttribute}
          setShowAttribute={setShowAttribute}
          apiList={apiList.filter((api) => api.return_type === type)}
          showAPI={showAPI}
          setShowAPI={setShowAPI}
          handleSelect={(value) => {
            (text + value).length + 2 <= maxCount &&
              updateOnSelectAttribute(
                value,
                showAttribute,
                showAPI,
                setShowAttribute,
                setShowAPI,
                setText,
                text,
                apiList
              );
          }}
        >
          <Textarea
            required={true}
            rows={3}
            value={text}
            disabled={!hasWriteAccess}
            onChange={(e) =>
              e.target.value.length <= maxCount &&
              updateOnChangeText(
                e.target.value,
                setShowAttribute,
                setShowAPI,
                setText
              )
            }
            className='block w-full mb-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-primary focus:outline-none focus:border-primary sm:text-sm cursor-text'
            placeholder='Write your message here'
          />
        </AttributeAPIContainer>
        <TextCharInfo data={text} count={maxCount} />
      </div>
    </BlockContainer>
  );
};

TextBlock.propTypes = {
  id: PropTypes.number.isRequired,
  save: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  saveBlock: PropTypes.func.isRequired,
  attributeList: PropTypes.arrayOf(PropTypes.string),
  apiList: PropTypes.arrayOf(PropTypes.object),
  deleteBlock: PropTypes.func.isRequired,
  moveBlock: PropTypes.func.isRequired,
  copyBlock: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  saveLoader: PropTypes.bool.isRequired,
  hasWriteAccess: PropTypes.bool.isRequired,
  platformType: PropTypes.string,
};

TextBlock.defaultState = {
  deleteBlock: (blockId, SequenceId) =>
    console.log('delete from default props', blockId, SequenceId),
};

export default TextBlock;
