import useTranslation from 'components/customHooks/useTranslation';
import { toast } from 'libraryV2/ui/use-toast';
import { useDispatch, useSelector } from 'react-redux';
import { IWebhookData } from '../components/webhookModal';
import { webhookOptions } from '../utils';

export const useWebhooks = () => {
  const { t, isRtlLanguage } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth);
  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );

  const searchQuery = useSelector(
    (state: any) => state?.settingsWebhook?.searchQuery
  );

  const updateSearchQuery = (query: string) => {
    dispatch.settingsWebhook.updateSearchQuery(query);
  };

  const isExternalDataLoading = useSelector(
    (state: any) =>
      state.loading.effects.settingsV2.fetchDatalabFormList ||
      state.loading.effects.settingsV2.fetchChannelList
  );

  const isWebhookLoading = useSelector(
    (state: any) => state.loading.effects.settingsWebhook.fetchWebhooks
  );

  const actionLoading = useSelector(
    (state: any) =>
      state.loading.effects.settingsWebhook.createWebhook ||
      state.loading.effects.settingsWebhook.editWebhook ||
      state.loading.effects.settingsWebhook.deleteWebhook ||
      state.loading.effects.settingsWebhook.bulkDeleteWebhook
  );

  const searchLoading = useSelector(
    (state: any) => state.loading.effects.settingsWebhook.searchWebhookList
  );

  const webhookList: IWebhookData[] = useSelector(
    (state: any) => state?.settingsWebhook?.webhookList
  );
  const webhookModalAction = useSelector(
    (state: any) => state?.settingsWebhook?.currentWebhookAction
  );
  const datalabFormList = useSelector(
    (state: any) => state?.settingsV2?.datalabFormList
  );

  /** Instead of calling the channelList GET API with every webhook page render, rather use the platformList loaded on dashboard. Saves a lot of fetch time :)  */
  // const channelList = useSelector(
  //   (state: any) => state?.settingsV2?.channelList
  // );
  const channelList = useSelector((state: any) => state.dashboard.platformList);

  const limit = useSelector((state: any) => state?.settingsWebhook?.limit);
  const offset = useSelector((state: any) => state?.settingsWebhook?.offset);
  const total = useSelector((state: any) => state?.settingsWebhook?.total);

  const setWebhookModalAction = (v: 'CREATE' | 'EDIT') =>
    dispatch.settingsWebhook.updateStateData({
      key: 'currentWebhookAction',
      value: v,
    });

  const getWebhookList = async (limit: number = 10, offset: number = 0) => {
    dispatch.settingsWebhook.fetchWebhooks({
      teamId: selectedProject?.id,
      limit,
      offset,
    });
  };

  const handleWebhookSearch = async (search = '') => {
    let response = await dispatch.settingsWebhook.searchWebhookList({
      teamId: selectedProject?.id,
      limit,
      offset,
      search,
    });
    return response;
  };

  const handleLimitUpdate = async (limit: number) => {
    await getWebhookList(limit, offset);
  };

  const handlePageChange = async (limit: number, offset: number) => {
    await getWebhookList(limit, offset);
  };

  const getChannelList = async () => {
    const teamId = selectedProject?.id;
    dispatch.settingsV2.fetchChannelList(teamId);
  };

  const getDatalabFormList = async () => {
    const teamId = selectedProject?.id;
    dispatch.settingsV2.fetchDatalabFormList(teamId);
  };

  const updateWebhookList = (webhookList: IWebhookData[]) => {
    dispatch.settingsWebhook.updateWebhookList(webhookList);
  };

  const clearWebhookData = () => {
    dispatch.settingsWebhook.clearState();
  };

  const createNewWebhook = async (replyData: IWebhookData) => {
    const selectedOption = webhookOptions.find(
      (option) => option.value === replyData.event
    );
    const filter_resource_type =
      selectedOption?.list === 'datalabFormList' ? 'datalab' : 'platform';

    let endpoint = replyData.endpoint;
    // Ensure the endpoint uses https by replacing 'http://' with 'https://' or adding 'https://' if missing
    // added to be aligned with the BE implementation
    if (!/^https:\/\//.test(endpoint)) {
      endpoint = endpoint.replace(/^http:\/\//, 'https://');
      if (!/^https:\/\//.test(endpoint)) {
        endpoint = `https://${endpoint}`;
      }
    }

    const formattedData = {
      event: replyData.event,
      endpoint,
      filter_resource_type,
      filter_resource_id: replyData.filter_resource_id,
    };

    const payload = {
      projectId: selectedProject?.id,
      data: formattedData,
    };
    return dispatch.settingsWebhook.createWebhook(payload);
  };

  const editWebhook = async (updatedWebhook: IWebhookData) => {
    const targetWebhook = webhookList.find((r) => r.id === updatedWebhook?.id);
    if (!targetWebhook) {
      return;
    }

    const selectedOption = webhookOptions.find(
      (option) => option.value === updatedWebhook.event
    );
    const filter_resource_type =
      selectedOption?.list === 'datalabFormList' ? 'datalab' : 'platform';

    let endpoint = updatedWebhook.endpoint;
    // Ensure the endpoint uses 'https' by replacing 'http://' with 'https://' or adding 'https://' if missing
    // added to be aligned with the BE implementation
    if (!/^https:\/\//.test(endpoint)) {
      endpoint = endpoint.replace(/^http:\/\//, 'https://');
      if (!/^https:\/\//.test(endpoint)) {
        endpoint = `https://${endpoint}`;
      }
    }

    const formattedData = {
      event: updatedWebhook.event,
      endpoint,
      filter_resource_type,
      filter_resource_id: updatedWebhook.filter_resource_id,
    };
    const payload = {
      projectId: selectedProject?.id,
      webhookId: targetWebhook.id,
      data: formattedData,
    };

    try {
      const result = await dispatch.settingsWebhook.editWebhook(payload);
      toast({
        duration: 3 * 1000,
        title: <p className='text-green-500'>{t('Saved Changes')}</p>,
        description: t('All changes has been saved successfully.'),
      });
      return result;
    } catch (error) {
      toast({
        duration: 3 * 1000,
        title: <p className='text-red-500'>{t('Unable to update Webhook')}</p>,
        description: t(
          `Something went wrong while updating Webhook. Please try again`
        ),
      });
      return { success: false };
    }
  };

  const deleteSelectedWebhook = async (webhookIds: number | number[]) => {
    if (!Array.isArray(webhookIds)) {
      webhookIds = [webhookIds];
    }
    const payload = {
      projectId: selectedProject?.id,
      webhookId: webhookIds,
    };

    try {
      const result = await dispatch.settingsWebhook.deleteWebhook(payload);

      if (result.success) {
        const updatedWebhookList = webhookList.filter(
          (webhook) => !webhookIds.includes(Number(webhook.id))
        );
        updateWebhookList(updatedWebhookList);
      }
      toast({
        duration: 3 * 1000,
        title: <p className='text-green-500'>{t('Delete Successful')}</p>,
        description: t('Webhook has been permanently deleted.'),
      });
      return result;
    } catch (error) {
      toast({
        duration: 3 * 1000,
        title: <p className='text-red-500'>{t('Unable to delete Webhook')}</p>,
        description: t(
          `Something went wrong while deleting Webhook. Please try again`
        ),
      });
      return { success: false };
    }
  };

  const bulkDeleteWebhook = async (webhookIds: number[]) => {
    const payload = {
      projectId: selectedProject?.id,
      selectedWebhooks: webhookIds,
    };

    try {
      const result = await dispatch.settingsWebhook.bulkDeleteWebhook(payload);

      if (result.success) {
        const updatedWebhookList = webhookList.filter(
          (webhook) => !webhookIds.includes(Number(webhook.id))
        );
        updateWebhookList(updatedWebhookList);
      }
      toast({
        duration: 3 * 1000,
        title: <p className='text-green-500'>{t('Delete Successful')}</p>,
        description: isRtlLanguage
          ? `${t('Webhooks has been deleted successfully.')} ${
              webhookIds?.length
            } ${t('webhooks successfully.')}`
          : `${webhookIds?.length} ${t(
              'Webhooks has been deleted successfully.'
            )}`,
      });
      return result;
    } catch (error) {
      toast({
        duration: 3 * 1000,
        title: <p className='text-red-500'>{t('Unable to delete Webhook')}</p>,
        description: `Something went wrong while deleting Webhook. Please try again`,
      });
      return { success: false };
    }
  };

  const fetchWebhookBots = async () => {
    await getWebhookList();
    // await getChannelList(); // Fetching from dashboard data
    await getDatalabFormList();
  };

  return {
    // Loading States
    isExternalDataLoading,
    isWebhookLoading,
    actionLoading,
    searchLoading,

    // User & Project Info
    userInfo,
    selectedProject,

    // Pagination Data
    limit,
    offset,
    total,

    // Core Data Lists
    webhookList,
    channelList,
    datalabFormList,

    // Modal State
    webhookModalAction,
    setWebhookModalAction,

    // Data Fetching Methods
    getWebhookList,
    getChannelList,
    getDatalabFormList,
    fetchWebhookBots,

    // CRUD Operations
    createNewWebhook,
    editWebhook,
    deleteSelectedWebhook,
    bulkDeleteWebhook,
    updateWebhookList,

    // Search & Pagination Handlers
    handleWebhookSearch,
    handleLimitUpdate,
    handlePageChange,

    // Utility Methods
    clearWebhookData,

    // Search Query
    searchQuery,
    updateSearchQuery,
  };
};

export default useWebhooks;
