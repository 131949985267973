import React from 'react';
import { Dialog, Button, TextInput } from 'evergreen-ui';
import { findAlertInfo } from '../../../../utilities/utils';
import { Link } from '@reach/router';
import errorIcon from '../../../pages/settings/assets/icons/errorIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  isShown: boolean;
  setShown: (data: boolean) => void;
  intent: 'warning' | 'success' | 'info' | 'danger';
  title?: string | number;
  description: string;
  onCancelCallback?: any;
  onDeleteCallback?: any;
  deleteTitle?: string;
  cancelTitle?: string;
  hasConfirmText?: boolean;
  hasLink?: boolean;
  confirmText?: 'CANCEL' | 'REMOVE' | 'REFUND';
}

const Modal: React.FC<Prop> = ({
  isShown,
  setShown,
  intent,
  title,
  description,
  onCancelCallback,
  onDeleteCallback,
  deleteTitle = 'Delete',
  cancelTitle = 'Cancel',
  hasConfirmText = false,
  hasLink = false,
  confirmText,
}) => {
  const { t } = useTranslation();
  const [confirmCheck, setConfirmCheck] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const handleAction = () => {
    if (hasConfirmText) {
      if (confirmCheck?.toUpperCase() === confirmText) {
        if (!!onDeleteCallback) {
          setShowError(false);
          onDeleteCallback();
          setShown(false);
          setConfirmCheck('');
        }
      } else {
        setShowError(true);
        setConfirmCheck('');
      }
    } else {
      if (!!onDeleteCallback) {
        onDeleteCallback();
        setShown(false);
      }
    }
  };
  return (
    <div>
      <Dialog
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        hasFooter={false}
        hasHeader={false}
        containerProps={{ className: 'rd-modal-style' }}
        shouldCloseOnOverlayClick={false}
      >
        <div className='flex align-start-item'>
          <img src={findAlertInfo(intent).icon} alt={'modal icon'} /> &nbsp;
          <p className='rd-modal-style__title'>&nbsp;{t(`${title ?? ''}`)}</p>
        </div>
        <p className='rd-modal-style__description'>{t(description)}</p>
        <br />
        {hasConfirmText && !hasLink && (
          <>
            <TextInput
              className='rd-input-style'
              isInvalid={showError}
              value={confirmCheck}
              placeholder={t(`Type {{${confirmText}}} Here`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setShowError(false);
                setConfirmCheck(e.target.value);
              }}
              marginLeft={50}
              width={312}
            />
            {!!showError && (
              <img
                src={errorIcon}
                alt='errorIcon'
                style={{ marginLeft: 336, marginBottom: 12, marginTop: -24 }}
              />
            )}

            {!!showError && (
              <p className='channel-description' style={{ color: '#EF4444' }}>
                {t(
                  'You have entered the wrong key. Please enter the valid key to complete the action.'
                )}
              </p>
            )}
            <br />
            <br />
          </>
        )}
        <div className='flex flex-end'>
          <Button
            className='alice-btn__minimal-black'
            marginRight={20}
            onClick={() => {
              if (!!onCancelCallback) {
                onCancelCallback();
              }
              setShown(false);
              setShowError(false);
            }}
          >
            {t(cancelTitle)}
          </Button>
          {hasLink ? (
            <Link to='/settings/marketplace'>
              <Button className={`alice-btn__${intent}`}>
                {t(deleteTitle)}
              </Button>
            </Link>
          ) : (
            <Button
              className={`alice-btn__${intent}`}
              onClick={() => handleAction()}
            >
              {t(deleteTitle)}
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Modal;
