import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import useTranslation from 'components/customHooks/useTranslation';

interface Prop {
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  currentValue: number;
  handleChange: (value: number) => void;
}

const RangeSlider: React.FC<Prop> = ({
  min = 0,
  max = 10,
  step = 2,
  currentValue,
  defaultValue = 0,
  handleChange,
}) => {
  const { isRtlLanguage } = useTranslation();
  const [value, setValue] = React.useState(currentValue);
  const SliderWithTooltip = createSliderWithTooltip(Slider);
  const customCircleStyle = isRtlLanguage
    ? { marginRight: -12 }
    : { marginLeft: -14 };
  React.useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  return (
    <div className='p-6'>
      <div className='flex items-center'>
        <span className='flex relative w-7 h-7' aria-hidden='true'>
          <span
            className='absolute w-full h-full bg-white border-2 rounded-full'
            onClick={() => {
              setValue(min);
              handleChange(min);
            }}
          />
        </span>
        <SliderWithTooltip
          min={min}
          max={max}
          step={step}
          value={value}
          reverse={isRtlLanguage}
          onChange={(value) => {
            setValue(value);
            handleChange(value);
          }}
          railStyle={{
            height: 2,
            background: '#E5E7EB',
          }}
          handleStyle={{
            zIndex: 100,
            borderRadius: 100,
            height: 28,
            width: 28,
            ...customCircleStyle,
            marginTop: -12,
            backgroundColor: '#04B25F',
            boxShadow: 'inset 0px 0px 0px 5px white',
            border: '3px solid #04B25F',
          }}
          trackStyle={{
            background: '#04B25F',
          }}
        />
        <span className='relative flex w-7 h-7' aria-hidden='true'>
          <span
            className='w-full h-full absolute rtl:-mr-6 ltr:right-6 ltr:mr-0.5 bg-white border-2 rounded-full'
            onClick={() => {
              setValue(max);
              handleChange(max);
            }}
          />
        </span>
      </div>
      <div className='flex mt-2 space-between'>
        <span className='ltr:ml-2.5 rtl:mr-2.5'>{min}</span>
        <span className='ltr:mr-8 rtl:ml-8'>{max}</span>
      </div>
    </div>
  );
};

export default RangeSlider;
