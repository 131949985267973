import { PlusIcon } from '@heroicons/react/20/solid';
import Shopify from 'assets/icons/component/Shopify';
import React from 'react';
import { connect } from 'react-redux';
import AliceLogo from './../../../../assets/icons/component/AliceLogo';

interface ShopifyBillingPublicComponentProps {
  location?: any;
  teamId?: string;
  signature?: string;
  confirmShopifySubscription: (payload: confirmPayload) => void;
}
interface confirmPayload {
  project_id: number;
  signature: string;
  charge_id: string;
  team_members?: number | null
}

const ShopifyBillingPublicComponent: React.FC<ShopifyBillingPublicComponentProps> =
  ({ location, teamId, signature, confirmShopifySubscription }) => {
    React.useEffect(() => {
      setTimeout(() => {
        const params = location.search.split('?')[1].split('&');
        let chargeId = '';
        let team_members = null
        params.forEach((p: string) => {
          if (p.includes('charge_id')) {
            chargeId = p.split('=')[1];
          }
          if (p.includes('team_members')) {
            team_members = p.split('=')[1];
          }
        });
        if (chargeId && teamId && signature) {
          const payload: confirmPayload = {
            project_id: parseInt(teamId),
            signature: signature?.toString(),
            charge_id: chargeId,
            team_members: team_members,
          };
          confirmShopifySubscription(payload);
        }
      }, 1500);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div className='flex flex-col items-center justify-center w-screen h-screen'>
        <div className='flex items-center space-x-2'>
          <AliceLogo width={120} />{' '}
          <PlusIcon className='w-6 h-6 text-gray-500' />
          <Shopify width={110} />
        </div>
        <p className='mt-2 font-sans text-xs font-medium text-gray-700 uppercase'>
          Verifying Payment...
        </p>
      </div>
    );
  };

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({});

const mapDispatch = (dispatch: any) => ({
  confirmShopifySubscription: (payload: confirmPayload) =>
    dispatch.billing.confirmShopifySubscription(payload),
});

export default connect(mapState, mapDispatch)(ShopifyBillingPublicComponent);
