import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface, WhatsappMessageTemplateProps } from 'index';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { CompaignPostData } from '../interface';
import Modal from './content/Modal';
import WhatsappTemplateDetailsModal from 'pages/inbox/components/middlebar/components/chatbox/WhatsappTemplateDetailsModal';
import { WhatsappTemplate } from 'pages/inbox/inboxInterface';
import EnterPhoneNumber from './EnterPhoneNumber';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface testMessageProps {
  phone: string;
  template_id: string | number;
  platform_id: string | number;
}

interface TemplateProps {
  channelId: number;
  templateId: string;
}

interface Props {
  sendTestMessage: (payload: { projectId: number; data: testMessageProps }) => {
    success: boolean;
    data: string;
  };
  templateId: string;
  campaignData: CompaignPostData;
  selectedProject: SelectedTeamInterface;
  templateList: WhatsappMessageTemplateProps[] | null;
  fetchWhatsappTemplateDetails: (payload: TemplateProps) => Promise<string[]>;
}

const defaultData = {
  id: 'defaultId',
  name: 'Default name',
  header: {
    type: 'text',
    value: 'Hello there,',
  },
  body: 'Template preview will show here',
  status: 'approved',
  category: 'default',
  language: 'english',
};

const SendTextMessage: React.FC<Props> = ({
  sendTestMessage,
  campaignData,
  selectedProject,
  templateId,
  templateList,
  fetchWhatsappTemplateDetails,
}) => {
  const { t } = useTranslation();
  const currentTime = new Date();
  const { currentPartnerInfo } = usePartner();
  const isWhiteLabelPartner = currentPartnerInfo?.type === 'white-label';
  const maxAllowedTestMessageCount = isWhiteLabelPartner ? 20 : 10;
  const keyName = 'testMessage';
  const [phone, setPhone] = React.useState('');
  const [openSendTemplateModal, setOpenSendTemplateModal] =
    React.useState(false);
  const [variables, setVariables] = React.useState<string[]>([]);
  const [attributeData, setAttributeData] = React.useState({});

  const [messageCount, setMessageCount] = React.useState(
    localStorage.getItem(keyName) &&
      // @ts-ignore
      currentTime.getTime() < JSON.parse(localStorage.getItem(keyName)).expiry
      ? // @ts-ignore
        JSON.parse(localStorage.getItem(keyName)).value
      : 0
  );
  const [step, setStep] = React.useState(0);
  const ref: any = React.useRef();
  const [dynamicButtonVariableName, setDynamicButtonVariableName] =
    React.useState('');

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<WhatsappTemplate>(defaultData);

  const setWithExpiry = (key: string, value: number, ttl: number) => {
    const item = {
      value: value,
      expiry: currentTime.getTime() + ttl,
    };
    setMessageCount(value);
    localStorage.setItem(key, JSON.stringify(item));
  };

  const submitTemplateMessage = async () => {
    const startTime = moment(currentTime);
    const endTime = moment('23:59', 'HH:mm');
    var duration = endTime.diff(startTime, 'seconds');
    // @ts-ignore
    const data = JSON.parse(localStorage.getItem(keyName));

    if (data) {
      if (currentTime.getTime() > data.expiry) {
        localStorage.removeItem(keyName);
        return;
      }
      if (data.value >= maxAllowedTestMessageCount) return;
    }

    const payload = {
      projectId: selectedProject.id,
      data: {
        phone: phone.includes('+') ? phone : `+${phone}`,
        template_id: campaignData.template_id,
        platform_id: campaignData.channel_details.id,
        attribute_data: attributeData,
      },
    };

    const res = await sendTestMessage(payload);

    if (res.success) {
      toaster.success('Test message successfully sent');
      setOpenSendTemplateModal(false);

      if (data) {
        setWithExpiry(keyName, data.value + 1, duration * 1000);
      } else {
        setWithExpiry(keyName, 1, duration * 1000);
      }
    } else {
      toaster.danger('Failed to send test message');
      setOpenSendTemplateModal(true);
    }
  };

  const setInitialAttribute = () => {
    const initialValue = {};
    variables?.reduce((obj, item) => {
      return {
        ...obj,
        [item]: null,
      };
    }, initialValue);
    setAttributeData(initialValue);

    if (!!selectedTemplate) {
      const header_image =
        selectedTemplate?.header?.type === 'image'
          ? selectedTemplate?.header?.value
          : null;
      if (!!header_image)
        setAttributeData({ ...attributeData, image: header_image });
    }
  };

  React.useEffect(() => {
    setInitialAttribute();
    // eslint-disable-next-line
  }, [variables]);

  const renderPhoneNumberInputView = () => {
    return (
      <EnterPhoneNumber
        maxAllowedTestMessageCount={maxAllowedTestMessageCount}
        phone={phone}
        templateId={templateId}
        campaignData={campaignData}
        messageCount={messageCount}
        templateList={templateList}
        setStep={setStep}
        setPhone={setPhone}
        setVariables={setVariables}
        setAttributeData={setAttributeData}
        setSelectedTemplate={setSelectedTemplate}
        setOpenSendTemplateModal={setOpenSendTemplateModal}
        setDynamicButtonVariableName={setDynamicButtonVariableName}
      />
    );
  };

  const renderSetMessageTemplateVariableView = () => {
    return (
      <div className=''>
        <WhatsappTemplateDetailsModal
          variables={variables}
          attributeData={attributeData}
          selectedTemplate={selectedTemplate}
          dynamicButtonVariableName={dynamicButtonVariableName}
          setStep={setStep}
          setAttributeData={setAttributeData}
          submitTemplateMessage={submitTemplateMessage}
          setIsOpenWhatsappMessage={setOpenSendTemplateModal}
          setInputValue={() => {}}
        />
      </div>
    );
  };

  return (
    <div className='w-full max-w-sm' ref={ref} id='send-test-message'>
      <>
        <button
          className={`
                group inline-flex items-center rounded-md bg-orange-700 py-2
                text-sm text-white hover:text-opacity-100 focus:outline-none
                focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          onClick={() => {
            setPhone('');
            setOpenSendTemplateModal(true);
            setStep(0);
          }}
        >
          <span className='text-blue-500 cursor-pointer'>
            {t('Send a Test Message')}
          </span>
        </button>

        <Modal
          open={openSendTemplateModal}
          setOpen={setOpenSendTemplateModal}
          overlayCss='bg-[#6B7280] bg-opacity-40'
          disableMaxHeightWidth={true}
        >
          <div className='bg-white relative'>
            {step === 0 && renderPhoneNumberInputView()}
            {step === 2 && renderSetMessageTemplateVariableView()}
          </div>
        </Modal>
      </>
    </div>
  );
};

const mspState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  sendTestMessage: (payload: { projectId: number; data: testMessageProps }) =>
    dispatch.broadcast.sendTestMessage(payload),
  fetchWhatsappTemplateDetails: (payload: TemplateProps) =>
    dispatch.inbox.fetchWhatsappTemplateDetails(payload),
});

export default connect(mspState, mapDispatch)(SendTextMessage);
