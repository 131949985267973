/**
 * Created by shuvo on 13/8/21.
 */
import React, { useState } from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from '../../../../../utilities/utils';
import ConfirmationModal from '../../../../../library/modal/ConfirmationModal';
import { navigate } from '@reach/router';
import { Alert, Link } from '../../../../../library';
import CouponAdd from './CouponAdd';
import dayjs from 'dayjs';
import useTranslation from 'components/customHooks/useTranslation';

export interface couponProps {
  success: boolean;
  dataSource: {
    id: string;
    object: string;
    amount_off: number;
    created: number;
    currency: string;
    duration: string;
    duration_in_months: number;
    livemode: boolean;
    max_redemptions: number;
    metadata: {
      test: string;
    };
    name: string;
    percent_off: number;
    redeem_by: number;
    times_redeemed: number;
    valid: boolean;
  };
}

interface Props {
  plans: Array<any>;
  trialRemaining: number;
  hasCard: boolean;
  currentPlan: any;
  selectedProject: any;
  createSubscription: Function;
  createSubscriptionForShopify: Function;
  handleSubscriptionUpdate: Function;
  handleCoupon: (coupon: string) => couponProps | null;
  biilingInterval: string;
  hasCoupon?: boolean;
  currentMarketPlace?: string | undefined;
  fetchDetails: () => void;
}

type intentType = 'downgrade' | 'upgrade' | 'current';

const PlanSelection: React.FC<Props> = ({
  plans,
  trialRemaining,
  hasCard,
  currentPlan,
  selectedProject,
  createSubscription,
  createSubscriptionForShopify,
  handleSubscriptionUpdate,
  handleCoupon,
  biilingInterval,
  hasCoupon = true,
  currentMarketPlace,
  fetchDetails,
}) => {
  /*
    TODO: The logic for annualBillingEnabled needs to be changed.
          Right now, we'll always see the annual Billing switch to be enabled.
          But, if I have a monthly plan activated, this should not be enabled.
          TLDR: this should be true is plan is free or a yearly plan

     TODO: Coupon Code not being sent to BE (the check is working) for the API config.handleStripeSubscription
     TODO: Update the histories and billingHistories APIs. Keep only one of them, and use this new url
           'projects/<int:project_id>/billing-history'
  */
  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(
    biilingInterval !== 'month'
  );

  const { t } = useTranslation();

  const [selectedPlan, setSelectedPlan] = useState(currentPlan?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [cancelNotification, setCancelNotification] = useState(false);
  const [isCancelOrDowngradeShown, setIsCancelOrDowngradeShown] =
    useState(false);
  const [isPaymentShown, setIsPaymentShown] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPaymentFailure, setIsPaymentFailure] = useState(false);

  let newPlan = plans.find((plan) => plan.id === selectedPlan);
  const changeType =
    currentPlan.price === newPlan?.price
      ? 'cancel'
      : parseInt(currentPlan.price) < parseInt(newPlan?.price)
      ? 'upgrade'
      : 'downgrade';
  const [couponCode, setCouponCode] = React.useState('');
  const [couponDiscount, setCouponDiscount] =
    React.useState<couponProps | null>(null);
  const billingInterval = annualBillingEnabled ? 'year' : 'month';

  const shouldShowAnnualBillingSwitch: boolean =
    currentPlan.plan_type !== 'enterprise' &&
    currentPlan.plan_type !== 'appsumo' &&
    currentPlan.interval !== 'year' &&
    !selectedProject?.is_downgrading;

  const Badge: React.FC<{
    title: string;
    intent?: intentType;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  }> = ({ title, intent = 'current', onClick }) => {
    const getIntentClasses = () => {
      switch (intent) {
        case 'upgrade':
          return ' text-white bg-green-600 cursor-pointer';
        case 'downgrade':
          return 'text-red-500 cursor-pointer';
        case 'current':
        default:
          return 'bg-green-100 text-green-800 cursor-not-allowed';
      }
    };
    return (
      <div
        role={'button'}
        onClick={onClick}
        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${getIntentClasses()}`}
      >
        {title}
        {/*todo */}
        {/*convert it into a library component - see tailwind UI for reference, this is customised for this component copy wont work well*/}
      </div>
    );
  };

  const AnnualBillingSwitch = () => {
    return (
      <Switch.Group as='div' className='flex items-center'>
        <Switch
          checked={annualBillingEnabled}
          onChange={setAnnualBillingEnabled}
          className={classNames(
            annualBillingEnabled ? 'bg-primary' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-hover transition-colors ease-in-out duration-200'
          )}
        >
          <span
            aria-hidden='true'
            className={classNames(
              annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
              'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
        <Switch.Label as='span' className='ml-3'>
          <span className='text-sm font-medium text-gray-900'>
            Annual billing{' '}
          </span>
          <span className='text-sm text-gray-500 underline'>(Save 20%)</span>
        </Switch.Label>
      </Switch.Group>
    );
  };

  const RenderAlerts = () => {
    const maxTeamMemberText: string =
      currentPlan?.max_team_members === -1
        ? 'and add unlimited members in your team.'
        : `and add up to ${currentPlan?.max_team_members} Members in your Team.`;

    return (
      <>
        <div>
          <h2
            id='plan-heading'
            className='text-lg font-medium leading-6 text-gray-900'
          >
            {t('Current Plan')}
          </h2>

          {currentPlan.plan_type !== 'appsumo' && (
            <p className='mt-1 text-sm text-gray-500'>
              {t(`You can upgrade to any plan instantly, your balance will be
              adjusted accordingly. If you want to downgrade, it will be applied
              after the end of the billing cycle.`)}
            </p>
          )}
          {selectedProject.is_downgrading && (
            <Alert
              intent='warning'
              title='Your account will be downgraded soon!'
              description={`After the end of the billing cycle, ${dayjs(
                selectedProject?.next_billing_time * 1000 || ''
              )}, your account will be downgraded! You can reach our support team at support@myalice.ai to keep the subscription`}
              className='my-3'
            />
          )}

          {!selectedProject.is_downgrading &&
            currentPlan.plan_type === 'free' &&
            !trialRemaining && (
              <Alert
                intent='warning'
                title='Upgrade to a paid plan to use all the pro features.'
                description="Your business is attracting so many customers and it's only natural for you to upgrade to our pro features. More power is waiting for you! Find the best suited plan for you from below!"
                className='my-3'
              />
            )}

          {!selectedProject.is_downgrading &&
            trialRemaining > 0 &&
            currentPlan.plan_type !== 'enterprise' &&
            currentPlan.plan_type !== 'appsumo' && (
              <Alert
                intent='info'
                title="You're in trial. Free perks for you!"
                description={`Your free trial ends in ${trialRemaining} days. Upgrade to a paid plan to keep using all the features. 🤟`}
                className='my-3'
              />
            )}

          {currentPlan.plan_type === 'enterprise' && (
            <Alert
              intent='success'
              title={t("You're enjoying the Enterprise Plan.")}
              className='my-3'
            >
              <p>
                {t(`Enterprise plan gives you the custom package that best suits
                your business! If you want any change in your plan, don't
                hesitate to`)}{' '}
                <Link
                  url='https://airtable.com/shrvMCwEUGQU7TvRR'
                  fontWeight='normal'
                  showUnderline={true}
                  openInNewTab={true}
                >
                  {t('contact us.')}
                </Link>
              </p>
            </Alert>
          )}

          {currentPlan.plan_type === 'appsumo' && (
            <Alert
              intent='success'
              title="You're enjoying AppSumo's Business Plan!"
              className='my-3'
            >
              <p>
                Your subscription started from{' '}
                {selectedProject?.subscription_anchor_date}. In this plan, you
                can interact with up to {currentPlan?.max_mau} monthly active
                users,
                {maxTeamMemberText}. To enjoy more features, you can upgrade
                your plan by clicking{' '}
                <Link
                  url='https://appsumo.com/products/myalice/'
                  fontWeight='normal'
                  showUnderline={true}
                  openInNewTab={true}
                >
                  here.
                </Link>
              </p>
            </Alert>
          )}
        </div>
      </>
    );
  };

  const fetchPrice = (priceParams: string) => {
    let localPrice = parseFloat(priceParams);
    let couponAmount =
      couponDiscount &&
      couponDiscount.dataSource &&
      couponDiscount.dataSource.percent_off > 0
        ? couponDiscount.dataSource.percent_off
        : 0;
    if (!!couponAmount) {
      localPrice = localPrice - localPrice * (couponAmount / 100);
    }
    return localPrice.toFixed(2);
  };

  const RenderPlans = () => {
    return (
      <div className='border rounded-md'>
        <div className='grid grid-cols-4 gap-4 px-2 py-1 border-b auto-rows-max'>
          <p className='px-2 py-1 font-bold text-gray-500'>Package</p>
          <p className='px-2 py-1 font-bold text-gray-500'>Price</p>
          <p className='px-2 py-1 font-bold text-gray-500'>MAU</p>
          <p className='px-2 py-1 font-bold text-gray-500'>Action</p>
        </div>
        <div className='divide-y divide-gray-200'>
          {plans
            .filter(
              (plan) =>
                plan.interval === billingInterval || plan.plan_type === 'free'
            )
            .map((plan, planIdx) => (
              <div
                className='grid grid-cols-4 gap-4 px-2 py-2 auto-rows-max'
                key={planIdx}
              >
                <div className='px-2 py-1 bold text-primary'>
                  {plan.name || 'N/A'}
                </div>
                <div className='px-2 py-1'>
                  <span className='font-medium uppercase '>
                    {fetchPrice(plan.price)} {plan.currency || 'USD'}
                  </span>
                  <span className='text-gray-500 capitalize'>
                    /{billingInterval}
                  </span>
                </div>
                <div className='px-2 py-1'>
                  <span className='font-medium'>{plan.max_mau}</span>{' '}
                  <span className='font-normal text-gray-500'>Max</span>
                </div>
                <div className='px-2 py-1'>
                  {currentPlan.id === plan.id && (
                    <Badge intent={'current'} title={'Current Plan'} />
                  )}
                  {currentPlan.id !== plan.id &&
                    (parseFloat(currentPlan.price) > parseFloat(plan.price) ? (
                      <Badge
                        onClick={() => {
                          setSelectedPlan(plan.id);
                          setTimeout(() => {
                            if (hasCard) {
                              setIsCancelOrDowngradeShown(true);
                            } else {
                              setIsPaymentShown(true);
                            }
                          }, 100);
                        }}
                        intent={'downgrade'}
                        title={
                          plan.plan_type === 'free' ? 'Cancel' : 'Downgrade'
                        }
                      />
                    ) : (
                      <Badge
                        onClick={() => {
                          setSelectedPlan(plan.id);
                          setTimeout(() => {
                            if (hasCard) {
                              setIsShown(true);
                            } else {
                              setIsPaymentShown(true);
                            }
                          }, 100);
                        }}
                        intent={'upgrade'}
                        title={'Upgrade'}
                      />
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const handleCreateSubscription = async () => {
    setIsLoading(true);
    const data = {
      plan_id: selectedPlan,
      action: changeType,
      project_id: selectedProject.id,
      coupon: couponCode,
    };
    let res;
    if (isShopify) {
      res = await createSubscriptionForShopify(data);
    } else {
      res = await createSubscription(data);
    }
    return res;
  };

  const renderConfirmModals = () => {
    return (
      <>
        <ConfirmationModal
          intent='success'
          open={isShown}
          handleClose={() => setIsShown(false)}
          title={`Are you sure want to ${changeType} to ${newPlan?.name}?`}
          description={`You have requested ${changeType} from your current package. Please be sure before your confirmation.`}
          cancelText='Cancel'
          confirmText='Confirm'
          isConfirmButtonLoading={isLoading}
          handleConfirm={async () => {
            const result = await handleCreateSubscription();
            if (result?.status) {
              setIsLoading(false);
              setIsShown(false);
              setIsPaymentShown(false);
              if (!isShopify) {
                setIsPaymentSuccess(true);
                await handleSubscriptionUpdate();
                currentPlan = plans.find((plan) => plan.id === selectedPlan);
              }
              if (!result.confirmation_url) {
                fetchDetails();
              }
            } else {
              setIsLoading(false);
              setIsShown(false);
              setIsPaymentShown(false);
              if (!isShopify) {
                setIsPaymentFailure(true);
              }
              if (!result.confirmation_url) {
                fetchDetails();
              }
            }
          }}
          handleCancel={() => {
            setSelectedPlan(currentPlan.id);
            setIsShown(false);
          }}
        />

        <ConfirmationModal
          intent='warning'
          open={isCancelOrDowngradeShown}
          handleClose={() => setIsCancelOrDowngradeShown(false)}
          title={`Is there anything we can do change your decision?`}
          description={`You plan won't be downgraded or cancelled immediately. After the end of the billing cycle, it will be cancelled or downgraded automatically.`}
          cancelText='Cancel'
          confirmText='Confirm'
          isConfirmButtonLoading={isLoading}
          handleConfirm={async () => {
            const result = await handleCreateSubscription();
            if (result.status) {
              setIsCancelOrDowngradeShown(false);
              setIsLoading(false);
              if (!isShopify) {
                setCancelNotification(true);
              }
              if (isShopify && !result.confirmation_url) {
                fetchDetails();
              }
            }
          }}
          handleCancel={() => {
            setIsCancelOrDowngradeShown(false);
          }}
        />

        <ConfirmationModal
          intent={isShopify ? 'success' : 'danger'}
          open={isPaymentShown}
          handleClose={() => setIsPaymentShown(false)}
          title={
            isShopify
              ? `Are you sure want to ${changeType} to ${newPlan?.name}?`
              : 'You do not have any payment method yet.'
          }
          description={
            isShopify
              ? `You have requested ${changeType} from your current package. Please be sure before your confirmation.`
              : 'You have requested a from your current package. But you did not add any payment method yet. To continue, please add a payment method first.'
          }
          cancelText='Cancel'
          confirmText={isShopify ? 'Confirm' : 'Add a Card'}
          handleConfirm={async () => {
            if (isShopify) {
              const result = await handleCreateSubscription();

              if (result?.status) {
                setIsPaymentShown(false);
                if (!result.confirmation_url) {
                  fetchDetails();
                }
              } else {
                setIsPaymentShown(false);
                if (isShopify && !result.confirmation_url) {
                  fetchDetails();
                }
              }
            } else {
              setIsPaymentShown(false);
              navigate('#paymentMethod');
            }
          }}
          isConfirmButtonLoading={isLoading}
          handleCancel={() => setIsPaymentShown(false)}
        />

        <ConfirmationModal
          intent='success'
          iconLocation='center'
          open={isPaymentSuccess}
          handleClose={() => setIsPaymentSuccess(false)}
          title='Payment Successful'
          description='We wish we had words to describe how happy we are to see you become a part of our paid plans! Rock on!'
          confirmText='Go back to your dashboard'
          handleConfirm={() => {
            setIsPaymentSuccess(false);
          }}
        />

        <ConfirmationModal
          intent='success'
          iconLocation='center'
          open={cancelNotification}
          handleClose={() => setCancelNotification(false)}
          title='MyAlice team is notifiied!'
          description='MyAlice team will reach you to confirm your cancellation/downgrade. Meanwhile keep using the features you have subscribed to.'
          confirmText='Go back to your dashboard'
          handleConfirm={() => {
            setCancelNotification(false);
          }}
        />

        <ConfirmationModal
          intent='danger'
          iconLocation='center'
          open={isPaymentFailure}
          handleClose={() => setIsPaymentFailure(false)}
          title='Something went wrong'
          description='We could not process your request. Our team is checking on the issue and will get back. Meanwhile you can try again.'
          cancelText='Try Again'
          handleCancel={() => {
            setIsPaymentFailure(false);
          }}
        />
      </>
    );
  };

  const isShopify = currentMarketPlace === 'shopify';
  return (
    <section aria-labelledby='plan-heading'>
      <div className='shadow sm:rounded-md sm:overflow-hidden'>
        <div className='px-4 py-6 space-y-6 bg-white sm:p-6'>
          <RenderAlerts />
          {!selectedProject.is_downgrading &&
            currentPlan.plan_type !== 'enterprise' &&
            currentPlan.plan_type !== 'appsumo' && <RenderPlans />}
          {shouldShowAnnualBillingSwitch && <AnnualBillingSwitch />}
        </div>

        {!selectedProject.is_downgrading &&
          currentPlan.plan_type !== 'enterprise' &&
          currentPlan.plan_type !== 'appsumo' &&
          hasCoupon && (
            <CouponAdd
              couponCode={couponCode}
              handleCouponChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCouponCode(e.target.value)
              }
              couponDiscount={couponDiscount}
              handleCouponCheck={async () => {
                const data = await handleCoupon(couponCode);
                setCouponDiscount(data);
              }}
            />
          )}
      </div>
      {renderConfirmModals()}
    </section>
  );
};

export default PlanSelection;
