import React from 'react';
import Modal from './Modal';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { connect } from 'react-redux';
import { subscriptionDetailsType, subscriptionUpdateData } from '../interface';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';

interface changeBillingCycleProps {
  setOpenBillingCycleModal: (value: boolean) => void;
  subscriptionDetails: subscriptionDetailsType;
  initiateSubscriptionPlanUpdate: (payload: {
    teamId: number;
    data: subscriptionUpdateData;
  }) => void;
  selectedProject: SelectedTeamInterface;
  updateIsBillingAnnually: (value: boolean) => void;
  isBillingAnnually: boolean;
}

const ChangeBillingCycleModal: React.FC<changeBillingCycleProps> = ({
  setOpenBillingCycleModal,
  subscriptionDetails,
  initiateSubscriptionPlanUpdate,
  selectedProject,
  updateIsBillingAnnually,
  isBillingAnnually,
}) => {
  const { t } = useTranslation();
  const changeBillingCycle = (cycle: string) => {
    navigate(
      `/settings/billing/upgrade?plan_type=billing-cycle&cycle=${cycle}`
    );
  };
  const changeToMonthly = () => {
    return (
      <div className='p-4 flex flex-col justify-center items-center'>
        <InformationCircleIcon className='w-8 h-8 p-1 text-blue-400 bg-blue-50 rounded-3xl my-1' />
        <p className='text-gray-900 text-lg leading-6 font-medium my-2'>Pay Monthly</p>
        <p className='text-sm text-gray-500 mb-5 text-center'>
          {t(`You are about to change the billing cycle of your current package from
          Yearly to Monthly. Costs will be adjusted based on your configuration.
          Do you wish to proceed?`)}
        </p>
        <button
          type='reset'
          className='bg-primary border border-primary rounded-md w-2/3 h-10 mb-3 text-white text-base font-medium'
          onClick={() => changeBillingCycle('monthly')}
        >
          {t('Yes, I want to pay monthly')}
        </button>
        <button
          type='reset'
          className='border border-gray-300 rounded-md w-2/3 h-10 text-gray-600 text-base font-medium'
          onClick={() => setOpenBillingCycleModal(false)}
        >
          {t('Cancel')}
        </button>
      </div>
    );
  };
  const changeToYearly = () => {
    return (
      <div className='p-[24px] flex flex-col justify-center items-center'>
        <InformationCircleIcon className='w-8 h-8 p-1 text-blue-400 bg-blue-50 rounded-3xl my-1' />
        <p className='text-gray-900 text-lg leading-6 font-medium my-2'>Pay Yearly</p>
        <p className='text-sm text-gray-500 mb-5 text-centerr'>
          {t(`You are about to change the billing cycle of your current package from
          Monthly to Yearly. Costs will be adjusted based on your configuration.
          Do you wish to proceed?`)}
        </p>
        <button
          onClick={() => changeBillingCycle('yearly')}
          type='reset'
          className='bg-primary border border-primary rounded-md w-2/3 h-10 mb-3 text-white text-base font-medium'
        >
          {t('Yes, I want to pay Yearly')}
        </button>
        <button
          type='reset'
          className='border border-gray-300 rounded-md w-2/3 h-10 text-gray-600 text-base font-medium'
          onClick={() => setOpenBillingCycleModal(false)}
        >
          {t('Cancel')}
        </button>
      </div>
    );
  };
  return (
    <Modal>
      {subscriptionDetails.project_billing_info.subscription_plan
        .billing_cycle === 'monthly'
        ? changeToYearly()
        : changeToMonthly()}
    </Modal>
  );
};

const mapState = (state: any) => ({
  subscriptionDetails: state.newBilling.subscriptionDetails,
  selectedProject: state.dashboard.selectedProject,
  isBillingAnnually: state.newBilling.isBillingAnnually,
});

const mapDispatch = (dispatch: any) => ({
  initiateSubscriptionPlanUpdate: (payload: {
    teamId: number;
    data: subscriptionUpdateData;
  }) => dispatch.newBilling.initiateSubscriptionPlanUpdate(payload),
  updateIsBillingAnnually: dispatch.newBilling.updateIsBillingAnnually,
});

export default connect(mapState, mapDispatch)(ChangeBillingCycleModal);
