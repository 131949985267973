import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Base85Conversion } from '../utils/base85encoder';

const useRenderJinjaCode = () => {
  const dispatch = useDispatch();
  const selectedProjectId = useSelector(
    (state: any) => state?.dashboard?.selectedProject?.id
  );
  const [sampleData, setSampleData] = useState<Record<string, any> | null>(
    null
  );
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const renderCode = async (): Promise<boolean> => {
    setIsloading(true);
    const response = await dispatch.workflowAutomation.renderJinjaCompiledCode({
      code: Base85Conversion.encodeToBase85(code),
      projectId: selectedProjectId,
      sampleData,
    });

    if (response?.success) {
      setOutput(response?.data);
      setErrorMsg(
        !!response?.data
          ? ''
          : 'Unable to render the output. Please check your template for errors or missing variables.'
      );
    } else {
      setOutput('');
      setErrorMsg(response?.data);
    }
    setIsloading(false);
    return response?.success;
  };

  return {
    code,
    setCode,
    output,
    setOutput,
    errorMsg,
    renderCode,
    setErrorMsg,
    sampleData,
    isLoading,
    setSampleData,
  };
};

export default useRenderJinjaCode;
