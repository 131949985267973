/**
 * Delete this file after new billing plans are released.
*/
import React from 'react';
import { Alert, Link } from '../../../../../library';

export interface couponProps {
    success: boolean;
    dataSource: {
        id: string;
        object: string;
        amount_off: number;
        created: number;
        currency: string;
        duration: string;
        duration_in_months: number;
        livemode: boolean;
        max_redemptions: number;
        metadata: {
            test: string;
        };
        name: string;
        percent_off: number;
        redeem_by: number;
        times_redeemed: number;
        valid: boolean;
    };
}

interface Props {
    plans: Array<any>;
    trialRemaining: number;
    hasCard: boolean;
    currentPlan: any;
    selectedProject: any;
    createSubscription: Function;
    createSubscriptionForShopify: Function;
    handleSubscriptionUpdate: Function;
    handleCoupon: (coupon: string) => couponProps | null;
    biilingInterval: string;
    hasCoupon?: boolean;
    currentMarketPlace?: string | undefined;
    fetchDetails: () => void;
}


const PlanSelectionTemporary: React.FC<Props> = ({
    plans,
    trialRemaining,
    hasCard,
    currentPlan,
    selectedProject,
    createSubscription,
    createSubscriptionForShopify,
    handleSubscriptionUpdate,
    handleCoupon,
    biilingInterval,
    hasCoupon = true,
    currentMarketPlace,
    fetchDetails,
}) => {
    const RenderAlerts = () => {
        return (
            <>
                <div>
                    <Alert
                        intent='success'
                        title={`Subscription Plan: ${currentPlan.plan_type[0].toUpperCase() + currentPlan.plan_type.slice(1)}`}
                        className='my-3'
                    >
                        <p>
                            We’ve moved to a new{' '}
                            <Link
                                url='https://myalice.ai/pricing'
                                openInNewTab={true}
                            >
                                pricing
                            </Link>
                            {' '}model. If you want to migrate to the new plan,{' '}
                            <Link
                                url='https://www.myalice.ai/contact'
                                openInNewTab={true}
                            >
                                contact here
                            </Link>
                            .
                        </p>
                    </Alert>
                </div>
            </>
        );
    };

    return (
        <section aria-labelledby='plan-heading'>
            <div className='shadow sm:rounded-md sm:overflow-hidden'>
                <div className='px-4 py-6 space-y-6 bg-white sm:p-6'>
                    <RenderAlerts />
                </div>
            </div>
        </section>
    );
};

export default PlanSelectionTemporary;
