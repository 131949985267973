import React from 'react';
import VisaIcon from '../../../../old/assets/images/visa.svg';
import MastercardIcon from '../../../../old/assets/images/mastercard.svg';
import AmexIcon from '../../../../old/assets/images/amex.svg';
import DiscoverIcon from '../../../../old/assets/images/discover.svg';
import DinersclubIcon from '../../../../old/assets/images/diners-club.svg';
import JcbIcon from '../../../../old/assets/images/jcb.svg';
import UnionpayIcon from '../../../../old/assets/images/unionpay.svg';
import { TrashIcon } from '@heroicons/react/20/solid';

interface cardTypes {
  brand: string;
  expire: string;
  last4: string;
  isDefaultLoading: boolean;
  isDeleteLoading: boolean;
  isDefaultDisabled: boolean;
  isDeleteDisabled: boolean;
  onDefaultClick: () => void;
  onDeleteClick: () => void;
  isDefault: boolean;
};

const CreditCard: React.FC<cardTypes> = ({
  brand,
  expire,
  last4,
  isDefaultLoading,
  isDeleteLoading,
  isDefaultDisabled,
  isDeleteDisabled,
  onDefaultClick,
  onDeleteClick,
  isDefault,
}) => {
  const getCardIcon = (brand: string) => {
    switch (brand) {
      case 'amex':
        return AmexIcon;
      case 'diners':
        return DinersclubIcon;
      case 'discover':
        return DiscoverIcon;
      case 'jcb':
        return JcbIcon;
      case 'mastercard':
        return MastercardIcon;
      case 'unionpay':
        return UnionpayIcon;
      case 'visa':
        return VisaIcon;
      default:
        return VisaIcon;
    }
  };

  return (
    <div
      className={`relative w-full px-6 py-5 rounded-md bg-gray-50 sm:flex sm:items-start sm:justify-between mb-2 ${
        isDefault ? 'border border-green-600' : ''
      }`}
    >
      <h4 className='sr-only'>Card</h4>
      <div className=''>
        <img
          src={getCardIcon(brand)}
          alt={brand}
          className='w-12 h-8 sm:flex-shrink-0 sm:h-6 border px-[2px] mb-3 rounded-sm'
        />
        <div className=''>
          <div className='text-sm font-medium text-gray-900'>
            Ending with {last4}
          </div>
          <div className='mt-1 text-sm text-gray-600 sm:flex sm:items-center'>
            <div>Expires {expire}</div>
          </div>
          {!isDefault && (
            <TrashIcon
              className='absolute right-[24px] w-5 h-5 bottom-[20px] text-red-400 cursor-pointer'
              onClick={() => onDeleteClick()}
            />
          )}
        </div>
      </div>
      <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
        <div
          className={`text-blue-500 cursor-pointer rounded-[50%] border w-5 h-5 flex items-center justify-center ${
            isDefault ? 'bg-green-500' : 'bg-white'
          }`}
          onClick={() => onDefaultClick()}
        >
          <div className='bg-white rounded-[50%] w-2 h-2'></div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
