import React from 'react';
import Link from '../../library/link';
import { footerData } from '../../utilities/content';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  children?: any;
}

const Footer: React.FC<Props> = ({ children }) => {
  const { isPartnerRestricted } = usePartner();
  const shouldRenderFooter = !isPartnerRestricted(
    'Footer/function/Footer_text'
  );
  if (!shouldRenderFooter) {
    return null;
  }
  return (
    <footer className={`fixed bottom-0 mb-6`}>
      {!!children ? (
        children
      ) : (
        <p className='max-w-sm mt-auto text-center text-gray-600'>
          {`${footerData.company} ${footerData.text}`}
          <br /> Read about our
          <Link
            openInNewTab={true}
            url={footerData.privacy.link}
          >{` ${footerData.privacy.title}`}</Link>
        </p>
      )}
    </footer>
  );
};
export default Footer;
