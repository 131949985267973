/**
 * check this link if you want to know more about this encoding
 * @link  https://www.notion.so/myaliceai/Base85-Encoding-and-Decoding-4d46f8db71a84a5e9e07692de75027f2?pvs=4
 */
const BASE85_ALPHABET =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!#$%&()*+-;<=>?@^_`{|}~';
const BASE85_BASE = 85;

const encodeBlock = (value: number): string => {
  let encoded = '';
  for (let i = 0; i < 5; i++) {
    encoded = BASE85_ALPHABET[value % BASE85_BASE] + encoded;
    value = Math.floor(value / BASE85_BASE);
  }
  return encoded;
};

const decodeBlock = (encoded: string) => {
  let value = 0;
  for (let i = 0; i < 5; i++) {
    value = value * BASE85_BASE + BASE85_ALPHABET.indexOf(encoded[i]);
  }
  return value;
};

const encodeToBase85 = (input: string): string => {
  const bytes = new TextEncoder().encode(input);
  let encoded = '';
  let padding = (4 - (bytes.length % 4)) % 4;
  let value = 0;

  for (let i = 0; i < bytes.length; i += 4) {
    value = 0;
    for (let j = 0; j < 4; j++) {
      value = (value << 8) + (i + j < bytes.length ? bytes[i + j] : 0);
    }
    encoded += encodeBlock(value);
  }

  return encoded.substring(0, encoded.length - padding);
};

const decodeBase85 = (encoded: string): string => {
  let byteArray = [];
  let value = 0;

  let padded = encoded;
  while (padded.length % 5 !== 0) {
    padded += BASE85_ALPHABET[84];
  }

  for (let i = 0; i < padded.length; i += 5) {
    value = decodeBlock(padded.substring(i, i + 5));

    for (let j = 3; j >= 0; j--) {
      byteArray.push((value >> (j * 8)) & 0xff);
    }
  }

  const originalLength = (encoded.length * 4) / 5;
  return new TextDecoder().decode(
    new Uint8Array(byteArray.slice(0, originalLength))
  );
};

const isBase85Encoded = (str: string): boolean => {
  const rfc1924Base85Pattern = /^[0-9A-Za-z!#$%&()*+\-;<=>?@^_`{|}~]+$/;

  if (!rfc1924Base85Pattern.test(str)) {
    return false;
  }

  if (str.length < 5) {
    return false;
  }

  try {
    const decoded = decodeBase85(str);
    const reEncoded = encodeToBase85(decoded);

    if (reEncoded === str) {
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

export const Base85Conversion = {
  decodeBase85,
  encodeToBase85,
  isBase85Encoded,
};
