import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import { LoaderCircle } from 'lucide-react';
import { useMemo } from 'react';
import emptySearchIcon from '../../assets/icons/emptySearchIcon.svg';
import { Button } from './button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';

// Define the pagination props
interface DataTablePaginationProps<TData> {
  table?: ReactTable<TData>;
  limit: number;
  offset: number;
  totalRowCount: number;
  onLimitChange: (newLimit: number) => void;
  onPageChange: (newOffset: number) => void;
}

// Define the main props using conditional types
interface DataTablePropsBase<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  dataLoading?: boolean;
  rowSelection?: Record<string, boolean>;
  setRowSelection?: React.Dispatch<React.SetStateAction<{}>>;
}

// Define a type that enforces pagination props if hasPagination is true
type DataTableProps<TData, TValue> = DataTablePropsBase<TData, TValue> &
  (
    | { hasPagination?: false; paginationProps?: never }
    | {
        hasPagination: true;
        paginationProps: DataTablePaginationProps<TData>;
      }
  );

export function DataTable<TData, TValue>({
  columns,
  data,
  hasPagination = false,
  paginationProps,
  dataLoading,
  rowSelection,
  setRowSelection,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();

  // Workaround to handle slicing data when pagination is enabled and also to deal with the case when limit is not provided in API response e.g. GET Webhooks API for now...
  const paginatedData = useMemo(() => {
    if (hasPagination && paginationProps) {
      return data.slice(
        paginationProps.offset,
        paginationProps.offset + paginationProps.limit
      );
    }
    return data;
  }, [data, hasPagination, paginationProps]);

  const table = useReactTable({
    data: paginatedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    state: {
      rowSelection: rowSelection || {},
    },
    onRowSelectionChange: setRowSelection,
  });

  return (
    <>
      <div className='border rounded-md'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {dataLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='text-center h-[500px]'
                >
                  <div className='flex-grow h-full w-full flex justify-center items-center'>
                    <LoaderCircle
                      strokeWidth={2}
                      className='text-green-400 w-12 h-12 animate-spin'
                    />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='text-center h-[500px]'
                >
                  <div className='flex flex-col justify-center'>
                    <img
                      src={emptySearchIcon}
                      alt='emptySearchIcon'
                      className='h-20 mb-4'
                    />
                    <h3 className='mb-2 text-center text-textPrimary'>
                      {t('No Results Found')}
                    </h3>
                    <span className='subtitle-medium text-center'>
                      {t('Please search with different keyword.')}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {hasPagination && paginationProps ? (
          <TablePagination {...paginationProps} table={table} />
        ) : null}
      </div>
    </>
  );
}

export function TablePagination<TData>({
  table,
  limit,
  offset,
  totalRowCount,
  onLimitChange,
  onPageChange,
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation();
  const handleLimitChange = (newLimit: string) => {
    onLimitChange(Number(newLimit));
  };

  const handlePageChange = (newOffset: number) => {
    onPageChange(newOffset);
  };

  const currentPage = Math.ceil(offset / limit) + Number(!!totalRowCount);
  const totalPage = Math.ceil(totalRowCount / limit);

  const renderPageLimitSelectionMenu = () => {
    return (
      <div className='flex items-center rtl:gap-2 space-x-2 bg-white'>
        <p className='text-sm font-medium'>{t('Rows per page')}</p>
        <Select value={`${limit}`} onValueChange={handleLimitChange}>
          <SelectTrigger className='h-8 w-[70px] focus:border-primary focus:ring-0'>
            <SelectValue placeholder={`${limit}`} />
          </SelectTrigger>
          <SelectContent side='top' className='bg-white'>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem
                key={pageSize}
                value={`${pageSize}`}
                className='bg-white cursor-pointer'
              >
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between w-full px-2 py-3'>
      <div className='flex-1 text-sm text-muted-foreground subtitle_medium'>
        {table && table.getFilteredSelectedRowModel().rows.length} {t('of')}{' '}
        {totalRowCount} {t('row(s) selected.')}
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8 lg:rtl:space-x-0 rtl:gap-6'>
        {renderPageLimitSelectionMenu()}
        <div className='flex items-center justify-center text-sm font-medium'>
          {t('Page')} {currentPage} {t('of')} {totalPage}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex rtl:order-2'
            onClick={() => handlePageChange(0)}
            disabled={offset === 0}
          >
            <span className='sr-only'>Go to first page</span>
            <DoubleArrowLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-1'
            onClick={() => handlePageChange(offset - limit)}
            disabled={offset === 0}
          >
            <span className='sr-only'>{t('Go to previous page')}</span>
            <ChevronLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-[0]'
            onClick={() => handlePageChange(offset + limit)}
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to next page')}</span>
            <ChevronRightIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex rtl:order-first'
            onClick={() =>
              handlePageChange((Math.ceil(totalRowCount / limit) - 1) * limit)
            }
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to last page')}</span>
            <DoubleArrowRightIcon className='w-4 h-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
