import React from 'react';
import DefaultAvatar from '../../../../assets/images/defaultAvatar.svg';
interface Prop {
  onClick?: any;
  showStatus?: boolean;
  statusSize?: string;
  statusColor?: string;
  src: any;
  size: string;
}

const AvatarComponent: React.FC<Prop> = ({
  onClick,
  showStatus = false,
  statusColor,
  statusSize,
  src,
  size,
}) => {
  return (
    <div className='relative inline-block has-tooltip'>
      <img
        className={`${size} inline-block object-cover rounded-full hover:visible`}
        src={!!src ? src : DefaultAvatar}
        alt='Profile'
      />
      {!!showStatus && (
        <span
          className={`absolute -bottom-1 right-0 inline-block ${statusSize} ${statusColor} border-2 border-white rounded-full`}
        ></span>
      )}
    </div>
  );
};

export default AvatarComponent;
