import React from 'react';
import SwitchWrapper from './SwitchWrapper';

interface NotificationListProps {
  status: boolean;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({
  title,
  description,
  status,
  disabled = false,
  onChange,
}) => {
  return (
    <li>
      <div className='flex items-center px-4 py-4 sm:px-6'>
        <div className='flex items-center flex-1 min-w-0'>
          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
            <div>
              <p className='text-base font-medium text-gray-700 truncate'>
                {title}
              </p>
              <p className='flex items-center mt-2 text-sm text-gray-500'>
                {description}
              </p>
            </div>
          </div>
        </div>
        <div>
          <SwitchWrapper
            disabled={disabled}
            checked={status}
            onChange={onChange}
          />
        </div>
      </div>
    </li>
  );
};

export default NotificationList;
