import useTranslation from 'components/customHooks/useTranslation';
import {
  Menu,
  React,
  Discount,
  CheckIcon,
  RemoveTag,
  SearchBar,
  Transition,
  useActions,
  useSelector,
  PhoneVariable,
  ConditionHeader,
  UtilityContents,
  ChevronDownIcon,
  ChevronRightIcon,
  ConditionDropDown,
  AddTagsOnCustomer,
  WhatsappTemplatePreview,
  AddTagOnOrder,
  RemoveTagOnCustomer,
  RemoveTagOnOrder,
} from 'pages/automationWorkflow/export';
import {
  IExecutorTag,
  DelayTimingDataTypes,
} from 'pages/automationWorkflow/interface';
import AssignVariablesV2 from './AssignVariableV2';

const ActionsView = () => {
  // Fetch data from the Redux store
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );
  const {
    channelList,
    executorTagList,
    whatsappTemplateList,
    selectedSaveActionsData,
    selectTemporaryExecutor,
    renderingChangeUIOfActions,
    selectedTemporaryExecutorDetailsTag,
  } = workflowAutomationData;

  // Extract required action functions from the custom hook
  const {
    selectExecutor,
    handleExecutorClick,
    saveSelectedDelayValue,
    handleUpdateSelectedChannel,
    handleSelectWhatsappTemplate,
    updateConditionallyRenderingActionsUI,
  } = useActions();

  const { t } = useTranslation();

  // Destructure properties to improve readability
  // Specify the type for timeOfDelay or provide a default value
  const {
    general: { delay: { timeOfDelay = '', valueOfDelay = '' } = {} } = {},
    whatsapp: { selectedTemplate = null, selectedChannelId = '' } = {},
  } = selectedSaveActionsData;

  const getSelectedChannel = () => {
    const filterList = !channelList
      ? []
      : channelList.filter(
          (channel: { label: string; id: string }) =>
            channel?.id === selectedChannelId
        );
    return !!filterList && filterList?.length > 0 ? filterList[0] : null;
  };

  const selectedChannel = getSelectedChannel();

  // Function to get the selected delay dropdown time
  const handleSelectedDelayDropdownTime = () => {
    const selectedValue = UtilityContents.DelayTimingData.filter(
      (option) => option?.value === timeOfDelay
    );

    return selectedValue[0];
  };

  // Function to render the main actions view
  const renderActionsView = () => {
    return (
      <>
        <div className='p-3 sticky top-0 z-20 w-full'>
          <SearchBar
            getSearchResult={(data) => console.log(data)}
            searchPlaceholder='Search...'
          />
        </div>
        {renderingExecutorList()}
      </>
    );
  };

  // Function to render the list of executors
  const renderingExecutorList = () => {
    return (
      <div
        className='grid grid-cols-5 grid-flow-col'
        style={{ height: 'calc(100vh - 149px)' }}
      >
        <div className='col-span-2 border-r'>
          {executorTagList.map((comparator: IExecutorTag, index: number) => (
            <div className='border-b p-2 ' key={index}>
              <div
                className='flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md'
                onClick={() => selectExecutor(comparator?.code)}
              >
                <div className='flex items-center'>
                  <h3 className='ml-3 text-gray-700 font-medium text-base'>
                    {comparator?.name}
                  </h3>
                </div>
                <div>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='col-span-3'>{renderingExecutorDetails()}</div>
      </div>
    );
  };

  // Function to render executor details
  const renderingExecutorDetails = () => {
    return selectTemporaryExecutor.map((executor: any, index: number) => (
      <div className='border-b p-2 ' key={index}>
        <div
          className='flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md'
          onClick={() => handleExecutorClick(executor)}
        >
          <div className='flex'>
            <div>
              <img src={executor?.component?.component_icon} alt='' />
            </div>
            <div className='ml-4'>
              <h3 className='text-gray-700 font-semibold text-base mb-1'>
                {executor?.name}
              </h3>
              {executor?.description !== '' && (
                <p className='text-gray-500 text-sm'>{executor?.description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  // Function to render the delay view
  const renderDelayView = () => {
    return (
      <div>
        <ConditionHeader
          title={selectedTemporaryExecutorDetailsTag?.name}
          previousUIAction={() =>
            updateConditionallyRenderingActionsUI('actionElement')
          }
        />
        <div
          className='bg-gray-50 p-4 overflow-auto'
          style={{ height: 'calc(100vh - 216px)' }}
        >
          <div className='px-4 py-2 bg-white rounded'>
            <h4 className='text-gray-600 font-medium text-sm mb-2'>
              {selectedTemporaryExecutorDetailsTag?.data?.col_1_label_name}
            </h4>
            <div className='relative'>
              <input
                className='w-full border border-gray-300 rounded-md bg-white py-2 px-4 text-sm text-gray-700 placeholder:regular focus:ring-primary focus:border-primary'
                type='number'
                placeholder='Enter value (e.g. 4)'
                value={valueOfDelay}
                onChange={(e) =>
                  saveSelectedDelayValue('value', e.target.value)
                }
              />
              <div className='absolute right-0 py-2.5 px-4 border-l top-0'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex items-center text-gray-500 text-sm'>
                      {handleSelectedDelayDropdownTime()?.label ??
                        selectedSaveActionsData?.general?.delay?.timeOfDelay}
                      <ChevronDownIcon
                        className='-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100'
                        aria-hidden='true'
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute border-white right-0 mt-2.5 w-56 origin-top-right  rounded-md bg-white shadow-lg focus:outline-none'>
                      <div className=''>
                        {UtilityContents.DelayTimingData.map(
                          (option: DelayTimingDataTypes, index: number) => (
                            <Menu.Item key={index}>
                              {({ active }: { active: boolean }) => (
                                <div
                                  className={`${
                                    active ||
                                    selectedSaveActionsData?.general?.delay
                                      ?.timeOfDelay === option?.value
                                      ? 'text-gray-600 font-medium bg-gray-50'
                                      : 'text-gray-600 '
                                  } px-4 py-2 my-1 text-sm capitalize cursor-pointer flex justify-between items-center`}
                                  onClick={() =>
                                    saveSelectedDelayValue(
                                      'time',
                                      option?.value
                                    )
                                  }
                                >
                                  {option?.label}
                                  {(active ||
                                    selectedSaveActionsData?.general?.delay
                                      ?.timeOfDelay === option?.value) && (
                                    <div>
                                      <CheckIcon className='w-5 h-5 text-primary' />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Menu.Item>
                          )
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Function to render the WhatsApp template view
  const renderWhatsappTemplateView = () => {
    return (
      <div>
        <ConditionHeader
          title={selectedTemporaryExecutorDetailsTag?.name}
          previousUIAction={() =>
            updateConditionallyRenderingActionsUI('actionElement')
          }
        />
        <div
          className='bg-gray-50 p-4 overflow-auto'
          style={{ height: 'calc(100vh - 216px)' }}
        >
          {/* Channels Dropdown */}
          <div className='px-4 py-2 bg-white rounded mb-6'>
            <h4 className='text-gray-600 font-medium text-sm mb-2'>
              WhatsApp Channel
            </h4>
            <div>
              <ConditionDropDown
                options={channelList}
                activeValue={
                  !!selectedChannel
                    ? selectedChannel
                    : { label: t('Select Whatsapp Channel') }
                }
                onChange={(data: { id: number; label: string }) =>
                  handleUpdateSelectedChannel(data?.id)
                }
              />
            </div>
          </div>
          {selectedChannel !== null && (
            <div className='px-4 py-2 bg-white rounded'>
              <h4 className='text-gray-600 font-medium text-sm mb-2'>
                {selectedTemporaryExecutorDetailsTag?.label}
              </h4>
              <div>
                <ConditionDropDown
                  options={whatsappTemplateList}
                  activeValue={
                    selectedTemplate === null
                      ? { label: t('Select whatsapp template'), id: '' }
                      : {
                          id: selectedTemplate?.id,
                          label: selectedTemplate?.label,
                        }
                  }
                  onChange={(data: {
                    id: string;
                    label: string;
                    body: string;
                  }) => handleSelectWhatsappTemplate(data)}
                />
              </div>
            </div>
          )}

          {selectedSaveActionsData?.whatsapp?.selectedTemplate !== null && (
            <>
              <PhoneVariable />
              <WhatsappTemplatePreview />
              {/* <AssignVariables /> */}
              <AssignVariablesV2 />
            </>
          )}
        </div>
      </div>
    );
  };

  //Renders the view based on the provided element type.
  const renderSidesheetChildElementView = (elementType: string) => {
    switch (elementType) {
      case 'actionElement':
        return renderActionsView();
      case 'add_delay':
        return renderDelayView();
      case 'whatsapp_template':
        return renderWhatsappTemplateView();
      case 'discount_forms':
        return <Discount />;
      case 'add_tags_on_customer':
        return <AddTagsOnCustomer />;
      case 'add_tags_on_order':
        return <AddTagOnOrder />;
      case 'remove_tags_from_customer':
        return <RemoveTagOnCustomer />;
      case 'remove_tags_from_order':
        return <RemoveTagOnOrder />;
      case 'remove_tags':
        return <RemoveTag />;
      default:
        return null;
    }
  };

  // Renders the sidesheet child element based on the current renderingChangeUIOfActions value
  return renderSidesheetChildElementView(renderingChangeUIOfActions);
};

export default ActionsView;
