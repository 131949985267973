import Button from 'library/button';
import { IAgentTicket } from '../interface';
import EmptyView from '../common/EmptyView';
import React, { useEffect, useState } from 'react';
import { getTimeDateFormatWithYear } from 'pages/inbox/utils/functions';
import { TableHearderForTicketData } from '../utilities/contents';
import useTranslation from 'components/customHooks/useTranslation';
import TextWithTooltip from 'library/text';
import ReactTooltip from 'react-tooltip';

interface Props {
  loading: boolean;
  unrepliedTicketCount: number;
  agentTicketData: IAgentTicket[] | null;
  redirectToTicket: (id: string | number) => void;
}

const AgentTicketTableView: React.FC<Props> = ({
  loading,
  agentTicketData,
  redirectToTicket,
  unrepliedTicketCount,
}) => {
  const { t } = useTranslation();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (agentTicketData) {
      setTotalPageNum(Math.ceil(agentTicketData.length / itemsPerPage));
    }
  }, [agentTicketData]);

  const paginatedData = agentTicketData?.slice(
    (currentPageNum - 1) * itemsPerPage,
    currentPageNum * itemsPerPage
  );

  const handlePageChange = (pageNum: number) => {
    setCurrentPageNum(pageNum);
  };
  const renderLoadingView = () => {
    return (
      <tbody className='divide-y divide-gray-200 bg-white animate-pulse'>
        {[...Array(4)].map((_, index) => (
          <tr key={index}>
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
              <div className='h-4 w-2/3 bg-slate-200 rounded' />
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='h-4 w-5 bg-slate-200 rounded' />
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='h-4 w-5 bg-slate-200 rounded' />
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='h-4 w-5 bg-slate-200 rounded' />
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
              <div className='h-4 w-5 bg-slate-200 rounded' />
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const emptyView = () => {
    return <EmptyView msg='No ticket available' />;
  };

  const renderResultsInfo = () => {
    const startItem = (currentPageNum - 1) * itemsPerPage + 1;
    const endItem = Math.min(
      currentPageNum * itemsPerPage,
      agentTicketData?.length ?? 0
    );

    return (
      <div className='text-sm flex leading-5 font-medium text-gray-700'>
        {t(
          'Showing {{' +
            startItem +
            '}} to {{' +
            endItem +
            '}} of {{' +
            (agentTicketData?.length ?? 0) +
            '}} results'
        )}
      </div>
    );
  };

  const mainView = () => {
    if (!agentTicketData || agentTicketData?.length < 1) {
      return emptyView();
    } else
      return (
        <div className='w-full rtl:text-right divide-y divide-gray-300'>
          <table className='w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50 '>
              <tr>
                {TableHearderForTicketData.map(
                  (value: string, index: number) => (
                    <th
                      scope='col'
                      key={index}
                      className={`px-6 py-3 ${
                        index + 1 === TableHearderForTicketData?.length
                          ? ' w-20'
                          : 'text-left'
                      } text-xs font-medium rtl:text-right text-gray-500`}
                    >
                      {t(value).toUpperCase()}
                    </th>
                  )
                )}
              </tr>
            </thead>
            {loading && renderLoadingView()}
            {!loading && (
              <tbody className='divide-y divide-gray-200 bg-white'>
                {paginatedData?.map((ticket, index) => (
                  <tr key={index}>
                    <td className='whitespace-nowrap rtl:text-right px-6 py-4 text-sm font-medium text-gray-900  sm:pl-6'>
                      <TextWithTooltip
                        text={ticket?.title}
                        dataFor={ticket?.title + '-' + index}
                        dataTip={ticket?.title}
                        className='text-gray-900'
                      />
                    </td>
                    <td className='whitespace-nowrap px-6 rtl:text-right py-4 text-sm text-gray-600'>
                      {getTimeDateFormatWithYear(ticket?.created_at)}
                    </td>
                    <td className='whitespace-nowrap rtl:text-right px-6 py-4 text-sm text-gray-600 '>
                      {ticket?.is_replied ? t('Replied') : t('Unreplied')}
                    </td>
                    <td className='whitespace-nowrap rtl:text-right px-6 py-4 text-sm text-gray-600'>
                      <span
                        className='whitespace-nowrap text-sm text-gray-600'
                        data-tip={ticket?.platform}
                        data-for={ticket?.platform + '-' + index}
                      >
                        {ticket?.platform.length <= 40
                          ? ticket?.platform
                          : ticket?.platform.slice(0, 40) + '...'}
                      </span>
                      {ticket?.platform.length > 40 && (
                        <ReactTooltip
                          id={ticket?.platform + '-' + index}
                          type='dark'
                          effect='solid'
                          backgroundColor='#4B5563'
                          multiline={true}
                          place={'top'}
                          className='w-auto break-words whitespace-pre-wrap sm:text-sm'
                        />
                      )}
                    </td>
                    <td className='whitespace-nowrap  px-6 py-4 text-sm text-gray-600 text-left w-auto'>
                      <span
                        className='text-sm rtl:text-right font-normal text-blue-400 cursor-pointer'
                        onClick={() => redirectToTicket(ticket?.id)}
                      >
                        {t('View Ticket')}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={4}
                    className='whitespace-nowrap px-6 py-4 text-sm text-gray-600'
                  >
                    {renderResultsInfo()}
                  </td>
                  <td className='whitespace-nowrap pl-6 pr-4 py-4 text-sm text-gray-600'>
                    {currentPageNum > 1 && (
                      <Button
                        intent='default'
                        className='ltr:mr-2 rtl:ml-2'
                        isDisabled={currentPageNum === 1}
                        onClick={() => handlePageChange(currentPageNum - 1)}
                      >
                        {t('Prev')}
                      </Button>
                    )}
                    {totalPageNum > 1 && currentPageNum < totalPageNum && (
                      <Button
                        intent='default'
                        onClick={() => handlePageChange(currentPageNum + 1)}
                      >
                        {t('Next')}
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      );
  };

  return (
    <div className='rounded-lg bg-white shadow divide-y'>
      <div className='py-4 px-6'>
        <span className='text-base font-medium text-[#111827]'>
          {t('All Pending Tickets')}{' '}
          {!!unrepliedTicketCount ? `(${unrepliedTicketCount})` : ''}
        </span>
        <span className='text-xs font-normal text-gray-600 block mt-1'>
          {t('Report all tickets that are currently pending.')}
        </span>
      </div>
      <div className='overflow-hidden rounded-b-lg sm:rounded-b-lg'>
        {mainView()}
      </div>
    </div>
  );
};

export default AgentTicketTableView;
