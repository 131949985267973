import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import React, { Fragment } from 'react';
import { classNames } from '../../../../../utilities/utils';
import { sequenceProps } from '../../../interface';

interface Props {
  selectedSequence?: { id: number; title: string };
  hasWriteAccess: boolean;
  sequences: sequenceProps[];
  className?: string;
  handleSelectedSequence: (sequenceName: string, sequenceId: number) => void;
}

const SequenceDropDown: React.FC<Props> = ({
  hasWriteAccess,
  className,
  selectedSequence,
  sequences,
  handleSelectedSequence,
}) => {
  const { t } = useTranslation();
  const getSequenceList = (id: number, value: object, title: string) => {
    return (
      <Listbox.Option
        key={id}
        className={({ active }) =>
          classNames(
            active ? 'bg-gray-200' : 'text-gray-900',
            'cursor-default select-none relative py-2 pl-3 pr-9'
          )
        }
        value={value}
      >
        {({ selected, active }) => (
          <>
            <span
              className={classNames(
                selected ? 'font-semibold' : 'font-normal',
                'block truncate'
              )}
            >
              {title?.length > 30 ? title.substring(0, 30) + '...' : title}
            </span>
            {selected ? (
              <span
                className={classNames(
                  active ? 'text-gray-900' : 'text-gray-900',
                  'absolute inset-y-0 ltr:right-0 rtl:left-4 flex items-center ltr:pr-4'
                )}
              >
                <CheckIcon className='w-5 h-5' aria-hidden='true' />
              </span>
            ) : (
              ''
            )}
          </>
        )}
      </Listbox.Option>
    );
  };
  return (
    <Listbox
      disabled={!hasWriteAccess}
      value={selectedSequence}
      onChange={(option: any) => {
        handleSelectedSequence(option?.title, option?.id);
      }}
    >
      {({ open }) => (
        <div>
          <Listbox.Button
            className={`w-full h-10 py-2 ltr:pl-3 ltr:pr-10 rtl:pr-3 rtl:pl-12 ltr:text-left rounded text-gray-500 bg-white border border-gray-300 shadow-sm cursor-pointer focus:ring-primary focus:border-primary sm:text-sm ${
              !!className ? className : ''
            } ${hasWriteAccess ? '' : 'bg-gray-200 cursor-not-allowed'}`}
          >
            <span className='block ltr:pl-4 rtl:text-right rtl:pr-4 font-medium text-gray-700 truncate'>
              {selectedSequence?.title
                ? selectedSequence?.title?.length > 20
                  ? selectedSequence?.title.substring(0, 20) + '...'
                  : selectedSequence?.title
                : t('Select sequence')}
            </span>
            <span className='absolute inset-y-0 ltr:right-0 rtl:left-2 flex items-center ltr:pr-2 pointer-events-none'>
              <ChevronUpDownIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              static
              className='relative z-40 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            >
              {sequences.map((val: any, index) =>
                getSequenceList(index, val, val.title)
              )}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

SequenceDropDown.defaultProps = {
  hasWriteAccess: false,
};
export default SequenceDropDown;
