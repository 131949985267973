import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'library';
import { classNames, handleImageUpload } from 'utilities/utils';
import { ImageUploadField } from 'components';
import GroupCrudMemeberList from './MemeberList';
import AssignSmartTicket from './AssignSmartTicket';
import AgentStatus from './AgentStatus';
import { connect } from 'react-redux';
import UserListBox from './UserListBox';
import { toaster } from 'evergreen-ui';
import AlertModal from 'library/modal/AlertModal';
import useTranslation from 'components/customHooks/useTranslation';

interface RenderHeaderProps {
  currentGroup: GroupDetailsCardInterface | null;
  setGroup: (value: GroupDetailsCardInterface | null) => void;
}

interface GroupsCRUDProps {
  currentGroup: GroupDetailsCardInterface | null;
  teamId: number;
  createTeamLoading: boolean;
  updateTeamLoading: boolean;
  setGroup: (value: GroupDetailsCardInterface | null) => void;
  fetchTeamUsers: (teamId: number) => Promise<GroupMembers[]>;
  createTeamGroup: (
    teamId: number,
    data: GroupDetailsCardInterface
  ) => Promise<boolean>;
  updateTeamGroup: (
    teamId: number,
    data: GroupDetailsCardInterface
  ) => Promise<boolean>;
  deleteTeamGroup: (teamId: number, groupId: number) => Promise<boolean>;
}

const GroupsCRUD: React.FC<GroupsCRUDProps> = ({
  currentGroup,
  teamId,
  createTeamLoading,
  updateTeamLoading,
  setGroup,
  fetchTeamUsers,
  createTeamGroup,
  updateTeamGroup,
  deleteTeamGroup,
}) => {
  const { t } = useTranslation();
  const [currentGroupState, setCurrentGroupState] =
    React.useState(currentGroup);
  const [users, setUsers] = React.useState<GroupMembers[]>([]);
  const [removedMembers, setRemoveMember] = React.useState<number[]>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const fetchUser = async () => {
    const data = await fetchTeamUsers(teamId);
    setUsers(data);
  };

  const findNonExistMember = () => {
    let tempUser: GroupMembers[] = [...users];
    if (currentGroupState && currentGroupState.members.length > 0) {
      currentGroupState.members.forEach((u) => {
        tempUser = tempUser.filter((tempUser) => tempUser.id !== u.id);
      });
    }
    return tempUser;
  };

  React.useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (currentGroup) {
      setCurrentGroupState(currentGroup);
    }
  }, [currentGroup]);

  const handleImageChange = (image: string) => {
    if (currentGroupState) {
      setCurrentGroupState({ ...currentGroupState, image: image });
    }
  };

  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentGroupState) {
      setCurrentGroupState({
        ...currentGroupState,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnSmartTicketChange = (checked: boolean) => {
    if (currentGroupState) {
      setCurrentGroupState({
        ...currentGroupState,
        is_smart: checked,
      });
    }
  };

  const handleAgentStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentGroupState) {
      setCurrentGroupState({
        ...currentGroupState,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleMemberAdd = (user: GroupMembers) => {
    if (currentGroupState) {
      setCurrentGroupState({
        ...currentGroupState,
        members: [...currentGroupState.members, user],
        is_smart: currentGroupState.is_smart ?? false,
      });
    }
  };

  const handleMemberRemove = (id: number, action: 'add' | 'remove') => {
    if (currentGroupState) {
      if (action === 'add') {
        setRemoveMember([...removedMembers, id]);
      } else if (action === 'remove') {
        setRemoveMember(removedMembers.filter((elemId) => elemId !== id));
      }
      // let localMembers = currentGroupState.members.filter((e) => e.id !== id);
      // setCurrentGroupState({
      //   ...currentGroupState,
      //   members: localMembers,
      //   is_smart:
      //     localMembers.length !== 0 ? currentGroupState.is_smart : false,
      // });
    }
  };

  const handleGroupDelete = async () => {
    if (currentGroupState && currentGroupState.id) {
      const status = await deleteTeamGroup(teamId, currentGroupState.id || -1);
      if (status) {
        setGroup(null);
        toaster.success(t('Group Deleted Successfully'));
      }
    }
  };

  const processMemberData = () => {
    if (currentGroupState) {
      let data = {
        ...currentGroupState,
        members: currentGroupState.members.filter(
          (e) => removedMembers.indexOf(e.id) === -1
        ),
      };
      setCurrentGroupState(data);
      return data;
    }
    return null;
  };
  const renderHeader = ({ currentGroup, setGroup }: RenderHeaderProps) => (
    <div className='flex items-center justify-between'>
      <div>
        <p className='text-lg font-medium text-gray-700'>
          {currentGroup && !!currentGroup.id
            ? t(`Edit {{${currentGroup.name}}}`)
            : t('Create a new group')}
        </p>
        <p className='font-normal text-gray-500'>
          {t(
            `${!!currentGroup && currentGroup.id ? 'Manage' : 'Add'} {{${
              currentGroup?.name || 'Group'
            }}} Information`
          )}
        </p>
      </div>
      <Button
        onClick={() => setGroup(null)}
        intent='minimal'
        className='hover:bg-gray-50'
      >
        <XMarkIcon className='w-5 h-5' />
      </Button>
    </div>
  );

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (currentGroupState) {
      let processedData = processMemberData();
      if (processedData) {
        if (!currentGroupState.id) {
          // create
          const status = await createTeamGroup(teamId, processedData);
          if (status) {
            setGroup(null);
            toaster.success(t('Group Created Successfully'));
          }
        } else {
          //update
          const status = await updateTeamGroup(teamId, processedData);
          if (status) {
            setGroup(null);
            toaster.success(t('Group Updated Successfully'));
          }
        }
      }
    }
  };

  return (
    <>
      {renderHeader({ currentGroup, setGroup })}
      <div className='w-full max-w-lg mt-4 space-y-4'>
        <label className='block space-y-3 text-sm font-medium text-gray-700'>
          <span>{t('Group Image')}</span>
        </label>
        <ImageUploadField
          image={currentGroupState?.image || ''}
          handleDrop={(image) => handleImageUpload(image, handleImageChange)}
          handleClear={() => handleImageChange('')}
        />
        {/* cannot wrap ImageUploadField in label */}

        <label className='block space-y-2 text-sm font-medium text-gray-700'>
          <span>{t('Group Name')}</span>
          <div className='relative mt-1 rounded-md shadow-sm'>
            <input
              type='text'
              name='name'
              placeholder={t('Provide group name')}
              value={currentGroupState?.name || ''}
              onChange={handleInputChange}
              autoComplete='name'
              className='block w-full border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
            />
          </div>
        </label>

        <UserListBox
          users={findNonExistMember()}
          onUserAdd={(user: GroupMembers) => handleMemberAdd(user)}
        />

        <label className='block space-y-2 text-sm font-medium text-gray-700'>
          <span>{t('Current Members')}</span>
        </label>
        {currentGroupState?.members?.length === 0 && (
          <p className='text-sm text-gray-500 '>{t('No Members Added Yet')}</p>
        )}
        {!!currentGroupState && currentGroupState?.members?.length > 0 && (
          <GroupCrudMemeberList
            members={currentGroupState.members}
            removedMembers={removedMembers}
            onMemberRemove={handleMemberRemove}
          />
        )}
        <AssignSmartTicket
          currentGroupState={currentGroupState}
          onChange={handleOnSmartTicketChange}
        />
        {currentGroupState?.is_smart && (
          <AgentStatus
            currentGroupState={currentGroupState}
            handleAgentStatusChange={handleAgentStatusChange}
          />
        )}
        <hr />
        <div
          className={classNames(
            'flex items-center',
            !!currentGroupState && !!currentGroupState.id
              ? 'justify-between'
              : 'justify-end'
          )}
        >
          {!!currentGroupState && !!currentGroupState.id && (
            <Button
              onClick={toggleDeleteModal}
              intent='minimal'
              size='sm'
              className='text-red-500'
            >
              {t('Delete This Group')}
            </Button>
          )}
          <Button
            isDisabled={
              !currentGroupState ||
              currentGroupState.members.length === 0 ||
              !currentGroupState.name.trim() ||
              createTeamLoading ||
              updateTeamLoading
            }
            intent='primary'
            onClick={handleSubmit}
          >
            {currentGroupState && currentGroupState.id
              ? t('Save Changes')
              : t('Create Group')}
          </Button>
        </div>
        <AlertModal
          isShown={showDeleteModal}
          intent='danger'
          onToggle={toggleDeleteModal}
          onConfirm={handleGroupDelete}
        />
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  teamId: state.dashboard.selectedProject.id,
  createTeamLoading: state.loading.effects.settings.createTeamGroup,
  updateTeamLoading: state.loading.effects.settings.updateTeamGroup,
});

const mapDispatch = (dispatch: any) => ({
  fetchTeamUsers: (teamId: number) => dispatch.settings.fetchTeamUsers(teamId),
  createTeamGroup: (teamId: number, data: GroupDetailsCardInterface) =>
    dispatch.settings.createTeamGroup({ teamId, data }),
  updateTeamGroup: (teamId: number, data: GroupDetailsCardInterface) =>
    dispatch.settings.updateTeamGroup({ teamId, data }),
  deleteTeamGroup: (teamId: number, groupId: number) =>
    dispatch.settings.deleteTeamGroup({ teamId, groupId }),
});

const GroupsCRUDContainer = connect(mapState, mapDispatch)(GroupsCRUD);

export default GroupsCRUDContainer;
