import useDynamicIntegration from 'pages/integration/hooks/useDynamicIntegration';
import {
  IDynamicIntegrationListProps,
  RearrangedListItem,
} from 'pages/integration/interface';
import { useEffect, useState } from 'react';
import CollapsableComponent from '../../common/components/CollapsableComponent';
import FeatureFlag from 'components/higherOrderComponents/FeatureFlag';
import BlankModal from 'library/modal/BlankModal';
import DynamicForm from './DynamicFormComponent';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';

interface IFormData {
  [key: string]: string | number | string[];
}

const DynamicIntegrationChannels = () => {
  const { t } = useTranslation();
  const { fetchDynamicIntegrationList, createDynamicChannel } =
    useDynamicIntegration();
  const [isMounted, setMounted] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [channelList, setChannelList] = useState<RearrangedListItem[] | []>([]);
  const [selectedItem, setSelectedItem] =
    useState<IDynamicIntegrationListProps | null>(null);
  const [formData, setFormData] = useState<IFormData>({});
  const [isSubmitAble, setIsSubmitAble] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const checkIfRequiredFieldHasValue = () => {
    const integrationFields = selectedItem?.fields;

    if (integrationFields) {
      const allRequiredFields = integrationFields
        .filter((field) => field?.is_required)
        .map((field) => field?.name);

      const isAllRequiredFieldContainsValue = allRequiredFields.every(
        (fieldName) => {
          const fieldValue = formData[fieldName];
          return !(
            !fieldValue ||
            (Array.isArray(fieldValue) && fieldValue.length === 0)
          );
        }
      );
      setIsSubmitAble(isAllRequiredFieldContainsValue);
    }
  };

  const handleFieldChange = (
    fieldName: string,
    value: string | number | string[]
  ) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const fetchChannelList = async () => {
    const list: RearrangedListItem[] = await fetchDynamicIntegrationList();
    setChannelList(list);
  };

  useEffect(() => {
    checkIfRequiredFieldHasValue();
    //eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
      fetchChannelList();
    }

    return () => {
      setMounted(false);
    };
    //eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setFormData({});
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const res = await createDynamicChannel({
      item: { ...selectedItem },
      data: { ...formData },
    });
    if (res.success) {
      handleClose();
      navigate('/integrations/integrated');
    }

    setSubmitLoading(false);
  };

  const renderChannelsCardView = (items: IDynamicIntegrationListProps[]) => {
    return (
      <>
        {items.map((item, index) => (
          <FeatureFlag
            allowedEnvironment={['production', 'staging', 'dev']}
            key={index}
          >
            <div
              className='p-6 border border-gray-200 rounded cursor-pointer w-fit'
              onClick={() => {
                setSelectedItem(item);
                setModalOpen(true);
              }}
              key={index}
            >
              <div className='flex items-center'>
                <img
                  src={item?.logo}
                  alt={'icon_box'}
                  className='w-[32px] h-[32px] rounded-md'
                />
                <p className='ml-2.5 mr-2.5 font-medium text-lg'>
                  {item?.title?.length > 25
                    ? item?.title.substring(0, 25) + '...'
                    : item?.title}
                </p>
              </div>
              <p className='mt-4 text-sm text-gray-500 cursor-pointer'>
                {t(item?.description)}
              </p>
            </div>
          </FeatureFlag>
        ))}
      </>
    );
  };

  const renderIntegrationModal = () => {
    return (
      <>
        {!!selectedItem && (
          <BlankModal
            open={isModalOpen}
            title={`${t('Connect')} ${selectedItem?.title}`}
            headerIcon={
              <img
                src={selectedItem?.logo}
                className='w-5 h-5 rounded'
                alt={'logo'}
              />
            }
            handleClose={() => handleClose()}
            submitButtonText={
              t(selectedItem?.options?.confirm_button) || t('Submit')
            }
            cancelButtonText={
              t(selectedItem?.options?.cancel_button) || t('Cancel')
            }
            documentationLink={
              selectedItem?.options?.documentation_link ||
              'https://docs.myalice.ai'
            }
            disableSubmitButton={!isSubmitAble}
            suggestionText={t('Need Help?') + ' '}
            linkText={t('Read Our Guide')}
            loading={submitLoading}
            onSubmitButtonClick={() => {
              handleSubmit();
            }}
          >
            <DynamicForm
              fields={selectedItem?.fields}
              onFieldChange={handleFieldChange}
              formData={formData}
            />
          </BlankModal>
        )}
      </>
    );
  };

  return (
    <>
      {!!channelList &&
        channelList.map((channel: RearrangedListItem, index: number) => (
          <CollapsableComponent
            header={channel?.section}
            cardNumber={3}
            key={index}
          >
            {!!channel?.items && renderChannelsCardView(channel?.items)}
          </CollapsableComponent>
        ))}
      {renderIntegrationModal()}
    </>
  );
};

export default DynamicIntegrationChannels;
