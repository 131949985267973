import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import EmailIntegrationSteps from './EmailIntegrationSteps';
import { SelectedTeamInterface } from 'index';
import { XMarkIcon } from '@heroicons/react/20/solid';
import emailIconSet from '../../../../../assets/icons/channelIconSet/email.svg';
import { channelCreateAPiResponseType } from 'pages/integration/interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedProject: SelectedTeamInterface;
  handleChannelEdit: (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) => Promise<boolean>;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const EmailIntegrationModal: React.FC<Props> = ({
  open,
  handleClose,
  selectedProject,
  handleChannelEdit,
  createChannelIntegrations,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className='w-[650px] h-auto relative transform overflow-hidden rounded-lg bg-white
                text-left align-middle shadow-xl transition-all'
                >
                  <div className='flex items-center justify-start w-full px-4 py-3 border-b border-gray-200'>
                    <img
                      src={emailIconSet}
                      width={18}
                      height={18}
                      alt='social_channel'
                    />
                    <span className='ml-2 text-base font-semibold leading-6 text-gray-700'>
                      {t('Connect Email')}
                    </span>

                    <XMarkIcon
                      color='#6B7280'
                      height={20}
                      className='float-right ml-auto cursor-pointer'
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>

                  <EmailIntegrationSteps
                    projectId={selectedProject?.id}
                    createChannelIntegrations={createChannelIntegrations}
                    handleClose={() => {
                      handleClose();
                    }}
                    handleChannelEdit={handleChannelEdit}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
      hideToaster: true,
    }),
  handleChannelEdit: async (
    teamId: number,
    channelId: string,
    data: object,
    alertMessage: string
  ) =>
    dispatch.integration.editChannel({
      teamId,
      channelId,
      data,
      alertMessage,
      hideToaster: true,
    }),
});

export default connect(mapState, mapDispatch)(EmailIntegrationModal);
