import React, { useState } from 'react';
import { Fragment } from 'react';
import typing from '../../pages/builder/assets/images/icons/typing.svg';
import PropTypes from 'prop-types';
import {
  Square2StackIcon,
  EllipsisHorizontalIcon,
  ArrowsUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowRightCircleIcon,
  TrashIcon,
  MinusIcon,
} from '@heroicons/react/20/solid';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { Link, Button } from '../../../library/index';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../../utilities/utils';

import { Dialog, Combobox } from 'evergreen-ui';
import '../../assets/styles/blocks/container.scss';

const BlockContainer = ({
  title,
  id,
  link = 'https://docs.myalice.ai/automate-channels/chatbot-blocks',
  subtitle,
  icon,
  sequences,
  handleCopy,
  handleMove,
  handleDelete,
  children,
  save,
  isLoading,
  isExpandable = true,
  handleSaveClick,
  hasWriteAccess,
}) => {
  const [deleteShown, setDeleteShown] = useState(false);
  const [blockShown, setBlockShown] = useState(true);
  const [blockDetailsShown, setBlockDetailsShown] = useState(false);
  const [copyShown, setCopyShown] = useState(false);
  const [moveShown, setMoveShown] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState(0);

  const actionList = [
    { title: 'COPY', handleAction: setCopyShown },
    { title: 'MOVE', handleAction: setMoveShown },
    { title: 'DELETE', handleAction: setDeleteShown },
  ];

  const getActionIcon = (actionName) => {
    switch (actionName) {
      case 'COPY':
        return (
          <Square2StackIcon
            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
            aria-hidden='true'
          />
        );
      case 'MOVE':
        return (
          <ArrowRightCircleIcon
            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
            aria-hidden='true'
          />
        );
      case 'DELETE':
        return (
          <TrashIcon
            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
            aria-hidden='true'
          />
        );
      default:
        return '';
    }
  };

  return (
    <div className='relative flex flex-col w-full mb-5 bg-white border border-gray-100 cursor-default'>
      <div className='absolute -left-8'>
        <ArrowsUpDownIcon
          className='w-6 h-6 p-1 text-gray-600 bg-white border border-gray-200 rounded cursor-pointer cursor-move'
          aria-hidden='true'
        />
      </div>
      <div className='flex items-start justify-between px-4 py-3 bg-white cursor-default sm:px-6'>
        <div className='flex justify-start'>
          <div className='flex-shrink-0 mr-2'>
            <img className='w-6 h-6 rounded-full' src={icon} alt='' />
          </div>
          <p className='mr-1 text-sm font-medium text-gray-900 uppercase truncate'>
            {title}
          </p>
          {blockDetailsShown ? (
            <ChevronUpIcon
              onClick={() => setBlockDetailsShown(!blockDetailsShown)}
              className='w-5 h-5 cursor-pointer'
              aria-hidden='true'
            />
          ) : (
            <ChevronDownIcon
              onClick={() => setBlockDetailsShown(!blockDetailsShown)}
              className='w-5 h-5 cursor-pointer'
              aria-hidden='true'
            />
          )}
        </div>
        <div className='flex flex-row'>
          {hasWriteAccess && !save && (
            <span
              onClick={() => handleSaveClick()}
              className='inline-flex items-center px-2.5 rounded-md text-sm font-medium bg-green-100 text-green-800 cursor-pointer'
            >
              Save Changes
            </span>
          )}
          {!!children && isExpandable && blockShown ? (
            <MinusIcon
              onClick={() => setBlockShown(!blockShown)}
              className='w-6 h-6 p-1 ml-2 text-gray-600 bg-white border border-gray-200 rounded cursor-pointer'
              aria-hidden='true'
            />
          ) : !!children ? (
            <ArrowsPointingOutIcon
              onClick={() => setBlockShown(!blockShown)}
              className='w-6 h-6 p-1 ml-2 text-gray-600 bg-white border border-gray-200 rounded cursor-pointer'
              aria-hidden='true'
            />
          ) : (
            ''
          )}
          {hasWriteAccess && (
            <Menu as='div' className='relative inline-block text-left'>
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className='flex items-center mx-auto text-gray-400 rounded-full hover:text-gray-600 focus:outline-none'>
                      <span className='sr-only'>Open options</span>
                      <EllipsisHorizontalIcon
                        className='w-6 h-6 p-1 ml-2 text-gray-600 bg-white border border-gray-200 rounded'
                        aria-hidden='true'
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      static
                      className='absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    >
                      <div className='py-1'>
                        {actionList.map((elem, i) => (
                          <Menu.Item key={i}>
                            {({ active }) => (
                              <Button
                                onClick={() => elem.handleAction(true)}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900 border-none w-full'
                                    : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm border-none w-full'
                                )}
                              >
                                {getActionIcon(elem.title)}
                                {elem.title}
                              </Button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          )}
        </div>
      </div>
      {blockDetailsShown && (
        <div className='px-4 py-3 text-sm text-gray-500 border-t bg-gray-50 sm:px-6'>
          {subtitle}&nbsp;
          <Link openInNewTab={true} url={link} fontWeight='normal'>
            Learn More &rarr;
          </Link>
        </div>
      )}
      {!!children && blockShown && isExpandable && (
        <div className='p-4 border-t'>{children}</div>
      )}
      <Dialog
        isShown={deleteShown}
        title='Delete Block'
        intent='danger'
        onCloseComplete={() => {
          setDeleteShown(false);
        }}
        onConfirm={() => {
          handleDelete();
          setDeleteShown(false);
        }}
        confirmLabel={'Confirm'}
      >
        <p>Are you sure you want to delete this block?</p>
      </Dialog>
      <Dialog
        isShown={copyShown}
        title='Select a sequence to copy this block'
        onCloseComplete={() => setCopyShown(false)}
        confirmLabel='Confirm'
        onConfirm={() => {
          handleCopy(id, selectedSequence);
          setCopyShown(false);
        }}
      >
        <Combobox
          openOnFocus
          width='100%'
          items={sequences}
          itemToString={(item) => (item ? item.title : '')}
          onChange={(selected) => selected && setSelectedSequence(selected.id)}
          placeholder='Sequence'
        />
      </Dialog>
      <Dialog
        isShown={moveShown}
        title='Select a sequence to move this block'
        onCloseComplete={() => setMoveShown(false)}
        confirmLabel='Confirm'
        onConfirm={() => {
          handleMove(id, selectedSequence);
          setMoveShown(false);
        }}
      >
        <Combobox
          openOnFocus
          width='100%'
          items={sequences}
          itemToString={(item) => (item ? item.title : '')}
          onChange={(selected) => selected && setSelectedSequence(selected.id)}
          placeholder='Sequence'
        />
      </Dialog>
    </div>
  );
};

BlockContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleCopy: PropTypes.func,
  handleMove: PropTypes.func,
  handleDelete: PropTypes.func,
  children: PropTypes.element,
  save: PropTypes.bool,
  handleSaveClick: PropTypes.func,
  icon: PropTypes.string,
  hasWriteAccess: PropTypes.bool,
  isExpandable: PropTypes.bool,
};

BlockContainer.defaultProps = {
  save: false,
  handleSaveClick: () => console.log('clicked on block container save button'),
  handleDelete: () =>
    console.log('delete clicked from block container default props'),
  icon: typing,
  hasWriteAccess: false,
};

export default BlockContainer;
