import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';
import {
  ICustomerCartInfo,
  IEcommerceProductData,
  IProductApiResponse,
  IProductQueryParam,
  IVariation,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { ProductRecommendLimit } from 'pages/inbox/utils/contents';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Modal from './common/Modal';
import NoStoreView from './common/NoStoreView';
import ProductList from './common/ProductList';
import VariationView from './VariationView';
import { toast } from 'libraryV2/ui/use-toast';

interface Props {
  openModal: boolean;
  products: IEcommerceProductData[];
  selectedTicket: TicketInterface;
  selectedTeam: SelectedTeamInterface;
  customerCart: ICustomerCartInfo;
  showProductView: boolean;
  customerHasCart: boolean;
  toggleRightBar: () => void;
  getCustomerInfo: (customerId: number) => Promise<void>;
  fetchVariations: (
    teamId: number,
    producId: string | number,
    params?: any
  ) => Promise<IVariation[]>;
  fetchProductData: (
    teamId: string,
    params: IProductQueryParam
  ) => Promise<IProductApiResponse>;
  updateStateData: (key: string, value: any) => void;
  recommendProducts: (
    customerId: number,
    products: IEcommerceProductData[]
  ) => Promise<boolean>;
  updateCart: (
    customerId: number,
    products: IEcommerceProductData[]
  ) => Promise<void>;
  fetchCartLink: (customerId: number) => Promise<string>;
  updateOpenShopifyModal: (payload: boolean) => void;
  selectedProject: SelectedTeamInterface;
  setShowRightbar: (payload: boolean) => void;
}

const Ecommerce: React.FC<Props> = ({
  products,
  openModal,
  updateCart,
  selectedTeam,
  customerCart,
  fetchCartLink,
  selectedTicket,
  toggleRightBar,
  getCustomerInfo,
  customerHasCart,
  fetchVariations,
  updateStateData,
  fetchProductData,
  recommendProducts,
  showProductView,
  updateOpenShopifyModal,
  selectedProject,
  setShowRightbar,
}) => {
  const [variationList, setVariationlist] = useState<any[]>([]); //map by variant id
  const [isAddMoreItem, setAddMoreItem] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cartLink, setCartLink] = useState<string>('');
  const [productList, setProductList] = useState<IEcommerceProductData[]>([]);
  const [productParams, setProductParams] = useState({
    search: '',
    offset: 9,
    limit: 10,
  });
  const [isEndOfList, setEndOfList] = useState<boolean>(false);
  const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false);
  const selectedAliceStoreId = useSelector(
    (state: any) => state.ecommerce.selectedAliceStoreId
  );
  const selectedEcommerceType = useSelector(
    (state: any) => state.ecommerce.selectedEcommerceType
  );

  useEffect(() => {
    const getUserInfo = async () => {
      let customerId = selectedTicket?.customer_id;
      if (!!customerId) await getCustomerInfo(customerId);
      setLoading(false);
    };
    if (openModal) {
      setLoading(true);
      setVariationlist([]);
      getUserInfo();
    }

    //eslint-disable-next-line
  }, [openModal]);

  const handleOpenModal = (val: boolean) => {
    updateStateData('openModal', val);
    toggleRightBar();
    updateStateData('showProductView', false);
  };

  const handleProductRecommendation = async (
    products: IEcommerceProductData[]
  ) => {
    if (await recommendProducts(selectedTicket?.customer_id, products)) {
      toaster.success('Product recommended successfully');
    }
  };

  const handleCartDataChanges = async (products: IEcommerceProductData[]) => {
    await updateCart(selectedTicket?.customer_id, products);
    await getCustomerInfo(selectedTicket?.customer_id);
  };

  const getVariation = async (productId: number | string) => {
    if (variationList !== undefined && variationList[productId]) {
      return variationList[productId];
    } else {
      const res: any = await fetchVariations(selectedTeam?.id, productId, {
        alice_store_id: selectedAliceStoreId,
      });
      if (res.success) {
        variationList[productId] = [...res.data];
        setVariationlist(variationList);
        return [...res.data];
      } else {
        return [];
      }
    }
  };

  const updateCartData = async (customerCartData: ICustomerCartInfo) => {
    await handleCartDataChanges([...customerCartData.alice_cart]);
  };

  const handleAddToCart = async (selectedProducts: IEcommerceProductData[]) => {
    await handleCartDataChanges(selectedProducts);
    updateStateData('showProductView', false);
    setAddToCartLoading(false);
  };

  const navigateToIntegration = () => {
    handleOpenModal(false);
    navigate('/integrations/available-integrations');
  };

  const handleUpdateProductData = async () => {
    await handleCartDataChanges([...customerCart.alice_cart]);
    setAddMoreItem(false);
  };

  const handleCheckout = async () => {
    await handleUpdateProductData();
    if (
      selectedTeam?.has_ecommerce_connected &&
      selectedEcommerceType === 'woocommerce'
    ) {
      updateStateData('openWooCommerceModal', true);
    } else if (selectedEcommerceType === 'shopify') {
      updateOpenShopifyModal(true);
    } else if (selectedEcommerceType === 'salla') {
      updateStateData('openSallaModal', true);
    } else if (selectedEcommerceType === 'zid') {
      updateStateData('openZidModal', true);
    }
    updateStateData('openModal', false);
    updateStateData('showProductView', false);
    setShowRightbar(true);
  };

  const handleAddItemToCart = async (
    selectedProducts: IEcommerceProductData[]
  ) => {
    let oldData = { ...customerCart };
    oldData.alice_cart = [...selectedProducts];
    await updateCartData({ ...oldData });
    setAddMoreItem(false);
    setAddToCartLoading(false);
    updateStateData('showProductView', false);
  };

  const getCartLink = async () => {
    if (cartLink !== '') {
      return cartLink;
    } else {
      const link = await fetchCartLink(selectedTicket?.customer_id);
      setCartLink(link);
      return link;
    }
  };

  const fetchProductOnScroll = async () => {
    if (!isEndOfList) {
      let offsetValue: string | number = '';
      if (
        selectedTeam?.has_ecommerce_connected &&
        ['woocommerce', 'salla'].includes(selectedTeam?.ecommerce_type)
      ) {
        offsetValue = productParams.offset;
      } else if (selectedTeam?.has_ecommerce_connected) {
        offsetValue = productList[productList.length - 1]?.cursor;
      }

      const productData: IProductApiResponse = await fetchProductData(
        selectedTeam?.id.toString(),
        {
          alice_store_id: selectedAliceStoreId,
          offset: offsetValue,
          limit: 10,
        }
      );
      if (productData.success) {
        if (productData.data.length < 1) {
          setEndOfList(true);
          toaster.notify('No more products could be found');
        } else {
          setProductList([...productList, ...productData.data]);
          setProductParams({
            ...productParams,
            ...{ offset: productParams.offset + 10 },
          });
        }
      } else {
        setEndOfList(true);
        toast({
          title: 'Product Fetching Failed',
          description: 'No more products could be found',
          toastType: 'destructive',
        });
      }
    }
    return true;
  };

  const searchProducts = async (query: string) => {
    let limit = query === '' || selectedEcommerceType === 'shopify' ? 10 : 50;

    const productData: IProductApiResponse = await fetchProductData(
      selectedTeam?.id.toString(),
      {
        alice_store_id: selectedAliceStoreId,
        search: query,
        limit: limit,
      }
    );
    if (productData.success) {
      setProductList([...productData.data]);
    }
    setProductParams({
      ...productParams,
      ...{ offset: 10 },
    });
    setEndOfList(false);
    return true;
  };

  const shouldShowVariationView = () => {
    return (
      customerHasCart &&
      customerCart.alice_cart.length > 0 &&
      !isAddMoreItem &&
      !showProductView
    );
  };

  const loadingView = () => {
    return <CircleLoader size='md' />;
  };

  const getModalView = () => {
    if (loading) return loadingView();
    return !!selectedAliceStoreId ? (
      shouldShowVariationView() ? (
        <VariationView
          products={products}
          customerCart={customerCart}
          getCartLink={getCartLink}
          getVariations={getVariation}
          updateCartInfo={updateCartData}
          setClose={() => {
            handleOpenModal(false);
          }}
          handleAddMoreItem={() => setAddMoreItem(true)}
          onCheckout={handleCheckout}
        />
      ) : (
        <ProductList
          products={productList}
          storeType={selectedEcommerceType}
          addToCartLoading={addToCartLoading}
          platformType={selectedTicket?.customer_platform_type}
          searchProducts={(query: string) => searchProducts(query)}
          hasCart={customerHasCart}
          handleFetchProductOnScroll={() => fetchProductOnScroll()}
          selectedList={customerHasCart ? customerCart.alice_cart : []}
          handleSelectionListChange={(
            selectedProducts: IEcommerceProductData[]
          ) => handleAddToCart(selectedProducts)}
          setAddToCartLoading={setAddToCartLoading}
          handleRecommendation={handleProductRecommendation}
          handleAddItemToCart={handleAddItemToCart}
          handleBackToVariant={() => {
            updateStateData('showProductView', false);
            setAddMoreItem(false);
          }}
          setClose={() => {
            handleOpenModal(false);
          }}
          recommendationLimit={
            ProductRecommendLimit(selectedTicket?.customer_platform_type).limit
          }
        />
      )
    ) : (
      <NoStoreView
        handleConnectClick={navigateToIntegration}
        setClose={() => {
          handleOpenModal(false);
        }}
      />
    );
  };

  return (
    <Modal
      open={openModal}
      setOpen={handleOpenModal}
      overflow={customerHasCart ? 'overflow-visible' : 'overflow-hidden'}
    >
      {getModalView()}
    </Modal>
  );
};

const mapState = (state: any) => ({
  products: state.ecommerce.products,
  customerCart: state.ecommerce.cart,
  openModal: state.ecommerce.openModal,
  selectedTicket: state.inbox.selectedTicket,
  selectedTeam: state.dashboard.selectedProject,
  customerHasCart: state.ecommerce.customerHasCart,
  selectedProject: state.dashboard.selectedProject,
  showProductView: state.ecommerce.showProductView,
});

const mapDispatch = (dispatch: any) => ({
  updateStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),

  fetchProductData: (teamId: string, params: IProductQueryParam) =>
    dispatch.ecommerce.fetchProducts({ teamId, params }),

  recommendProducts: (customerId: number, products: IEcommerceProductData[]) =>
    dispatch.ecommerce.recommendProducts({
      customerId,
      selectedProductData: products,
    }),
  updateCart: (customerId: number, products: IEcommerceProductData[]) =>
    dispatch.ecommerce.updateCart({
      customerId,
      cartProducts: products,
    }),
  getCustomerInfo: (customerId: number) =>
    dispatch.ecommerce.fetchCustomerInfo(customerId),
  fetchVariations: (teamId: number, productId: string | number, params?: any) =>
    dispatch.ecommerce.fetchVariation({ teamId, productId, params }),
  fetchCartLink: (customerId: number) =>
    dispatch.ecommerce.fetchCartLink(customerId),
  updateOpenShopifyModal: (payload: boolean) =>
    dispatch.ecommerce.updateOpenShopifyModal(payload),
});

export default connect(mapState, mapDispatch)(Ecommerce);
