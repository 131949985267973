import React from 'react';
import CopyText from '../../../../../components/elements/redesign/CopyText';

interface Prop {
  icon: string;
  title: string;
  description?: string;
  address?: EcomAddressProps;
}

interface EcomAddressProps {
  address_one: string;
  address_two?: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
}

const OrderCardSummaryList: React.FC<Prop> = ({
  icon,
  title,
  description,
  address,
}) => {
  const getAddressString = () => {
    let addressString = '';
    const excludedKeys = [
      'first_name',
      'last_name',
      'company',
      'email',
      'phone',
    ];
    if (!!address) {
      for (const key in address) {
        if (
          address.hasOwnProperty(key) &&
          address[key] !== '' &&
          !excludedKeys.includes(key)
        ) {
          addressString += `${address[key]}, `;
        }
      }
    }
    return addressString.trim().slice(0, -1);
  };

  const getNameString = () => {
    let nameString = '';
    if (!!address?.first_name && address?.first_name !== '')
      nameString += address.first_name + ' ';
    if (!!address?.last_name && address?.last_name !== '')
      nameString += address.last_name + ' ';
    nameString = nameString.trim();
    return nameString !== '' ? `Name: ${nameString}` : nameString;
  };

  const getEmailString = () =>
    !!address?.email && address?.email !== '' ? `Email: ${address.email}` : '';

  const getPhoneString = () =>
    !!address?.phone && address?.phone !== '' ? `Phone: ${address.phone}` : '';

  const getAddressCopyString = () =>
    [getAddressString(), getNameString(), getEmailString(), getPhoneString()]
      .filter(str => !!str && str.length > 0)
      .join('\n');

  return (
    <div className='flex align-flex-start mb-10px'>
      <img src={icon} width={26} alt='bill' />
      <div className='flex flex-column ml-5px'>
        <p className='order-right-bar__order-card__header-title'>
          {title}&nbsp;
          <CopyText height={24} textToCopy={description || getAddressCopyString()} />
        </p>
        {!!address ? (
          <p className='order-right-bar__order-card__header-subtitle'>
            <p>{getAddressString()}</p>
            <p>{getNameString()}</p>
            <p>{getEmailString()}</p>
            <p>{getPhoneString()}</p>
          </p>
        ) : (
          <p className='order-right-bar__order-card__header-subtitle'>
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default OrderCardSummaryList;
