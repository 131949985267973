import React from 'react';
import Introduction from './Introduction';
import WhatsappSetting from '../../common/pages/WhatsappSetting';
import ChannelSetupStatus from '../../common/pages/ChannelSetupStatus';

import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { SideSheet } from '../../../../../library';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

interface Props {
  isOpen: boolean;
  callbackUrl?: string;
  selectedProject: selectedProjectProps;
  integratedNlpList: { id: string; name: string; provider: string }[];

  //functions
  handleClose: () => void;
  setIsSideSheetOpen: (val: string) => void;
  getIntegratedNlpList: (teamId: number) => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
}

const initialSettingData = {
  title: '',
  is_published: false,
  connected_nlp_integration_id: '',
};

const WhatsappSideSheet: React.FC<Props> = ({
  isOpen,
  callbackUrl,
  handleClose,
  selectedProject,
  integratedNlpList,
  setIsSideSheetOpen,
  getIntegratedNlpList,
  createChannelIntegrations,
}) => {
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [whatsappSettingData, setWhatsappSettingData] =
    React.useState(initialSettingData);

  //functions

  const clearState = () => {
    setWhatsappSettingData(initialSettingData);
    setStep(1);
    setLoading(false);
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2: {
        setLoading(true);
        let payloadData = {
          title: whatsappSettingData.title,
          is_published: whatsappSettingData.is_published,
          connected_nlp_integration_id:
            whatsappSettingData.connected_nlp_integration_id,
        };
        let res = await createChannelIntegrations(
          'whatsapp_messenger',
          selectedProject.id,
          payloadData
        );
        if (res.status === 200) {
          setLoading(false);
          setStep(3);
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            res.status === 500
              ? 'Something went wrong while integrating the channel. Try again later.'
              : res.data.error
          );
        }
        break;
      }
      case 3:
        setIsSideSheetOpen('');
        !!callbackUrl
          ? navigate(callbackUrl)
          : navigate(`/integrations/integrated`);
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    let flag = false;
    const mandatoryKeys = ['title'];

    mandatoryKeys.forEach((items) => {
      if (!(whatsappSettingData && whatsappSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 2:
        return checkFinishButton();
      default:
        return false;
    }
  };

  const getHeaderInfo = (step: number) => {
    switch (step) {
      case 1:
        return {
          title: 'Connect Whatsapp Messenger with MyAlice',
        };
      case 2:
        return {
          title: 'Whatsapp Settings',
        };
      default:
        return {
          title: '',
        };
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getIntegratedNlpList(selectedProject.id);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <SideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
          clearState();
        }}
        closeOnExternalClick={false}
        disableConfirm={checkConfirmButtons()}
        title={getHeaderInfo(step).title}
        confirmText={step === 2 ? 'Finish' : step === 3 ? 'Exit' : 'Next'}
        cancelText={'Back'}
        handleCancel={() => handleCancel(step)}
        handleConfirm={handleConfirm}
        hasLeftActionElement={false}
        hideCancel={step === 1 || step === 3}
      >
        {step === 1 && <Introduction />}
        {step === 2 && (
          <>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-20'>
                <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
                <span>creating channel...</span>
              </div>
            ) : (
              <WhatsappSetting
                success={success}
                errorMessage={errorMessage}
                whatsappSettingData={whatsappSettingData}
                integratedNlpList={integratedNlpList}
                handleChange={(key: string, value: string | string[]) => {
                  setWhatsappSettingData({
                    ...whatsappSettingData,
                    [key]: value,
                  });
                }}
              />
            )}
          </>
        )}
        {step === 3 && (
          <ChannelSetupStatus
            title='Our team has received your request!'
            subTitle='We will contact you shortly to complete the process'
          />
        )}
      </SideSheet>
    </>
  );
};

const mapState = (state: any) => ({
  integratedNlpList: state.integration.integratedNlpList,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.integration.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
});

export default connect(mapState, mapDispatch)(WhatsappSideSheet);
