import { Redirect, navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';
import LanguageDropDown from 'library/language/languageDropDown';
import { Component } from 'react';
import { connect } from 'react-redux';
import { authData } from '../../utilities/content';
import { isValidEmail } from '../../utilities/utils';
import AuthHeader from './components/AuthHeader';
import ForgetPasswordFormSection from './components/ForgetPasswordFormSection';
import LoginFooter from './components/LoginFooter';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
    };
  }
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleResetRequestSubmit = () => {
    if (!isValidEmail(this.state.email)) {
      toaster.danger('Invalid Email.');
      return;
    }
    this.setState({ loading: true });
    this.props
      .resetRequest({
        email: this.state.email,
      })
      .then((success) => {
        if (success) {
          toaster.success('Please check your email for further instruction!');
          this.setState({ email: '', loading: false });
          navigate(`/reset-verify`);
        } else {
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const isAuthenticated = this.props.auth.access !== '';
    return isAuthenticated ? (
      <Redirect from='/' to='/dashboard' noThrow />
    ) : (
      <div className='flex flex-col justify-center min-h-screen py-12 bg-white sm:px-6 lg:px-8'>
        <div className=' absolute top-[5vh] right-[24px]'>
          <LanguageDropDown />
        </div>
        <AuthHeader
          title={authData.forget.title}
          subtitle={authData.forget.subtitle}
        />
        <ForgetPasswordFormSection
          handleChange={this.handleChange}
          email={this.state.email}
          loading={this.state.loading}
          handleSubmit={() => this.handleResetRequestSubmit()}
          content={authData}
        />
        <LoginFooter />
      </div>
    );
  }
}

const mapState = (state) => ({
  auth: state.auth,
});

const mapDispatch = (dispatch) => ({
  resetRequest: (payload) => dispatch.auth.resetRequest(payload),
});

export default connect(mapState, mapDispatch)(ForgetPassword);
