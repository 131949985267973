import React, { memo, forwardRef } from 'react';

interface Prop {
  buttonData: buttonDataProps[];
  size?: sizeProps;
  hasFocusRing?: boolean;
  containerClassName?: string;

  [key: string]: any;
}

type sizeProps = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface buttonDataProps {
  id?: number;
  name?: string;
  icon?: React.ReactNode;
  iconPlacement?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  [key: string]: any;
}

const getSize = (size: sizeProps) => {
  switch (size) {
    case 'xs':
      return 'px-2.5 py-1.5 text-xs';
    case 'sm':
      return 'px-3 py-1.5 text-sm';
    case 'lg':
      return 'px-4 py-2 text-base';
    case 'xl':
      return 'px-6 py-3 text-base';
    case 'md':
    default:
      return 'px-4 py-2 text-sm';
  }
};

const getIconClassName = (
  size: sizeProps,
  name = '',
  iconPlacement = 'left'
) => {
  switch (size) {
    case 'xs':
      if (name) {
        if (iconPlacement === 'left') {
          return '-ml-0.5 mr-1 h-4 w-4';
        } else {
          return '-mr-0.5 ml-1 h-4 w-4';
        }
      } else {
        return 'h-4 w-4';
      }
    case 'sm':
      if (name) {
        if (iconPlacement === 'left') {
          return '-ml-1 mr-1.5 h-5 w-5';
        } else {
          return '-mr-1 ml-1.5 h-5 w-5';
        }
      } else {
        return 'h-5 w-5';
      }
    case 'lg':
      if (name) {
        if (iconPlacement === 'left') {
          return '-ml-1 mr-2 h-5 w-5';
        } else {
          return '-mr-1 ml-2 h-5 w-5';
        }
      } else {
        return 'h-5 w-5';
      }
    case 'xl':
      if (name) {
        if (iconPlacement === 'left') {
          return '-ml-1 mr-3 h-6 w-6';
        } else {
          return '-mr-1 ml-3 h-6 w-6';
        }
      } else {
        return 'h-6 w-6';
      }

    case 'md':
    default:
      if (name) {
        if (iconPlacement === 'left') {
          return '-ml-1 mr-1 h-5 w-5';
        } else {
          return '-mr-1 ml-1 h-5 w-5';
        }
      } else {
        return 'h-5 w-5';
      }
  }
};

const ButtonGroup: React.FC<Prop> = forwardRef(
  (
    {
      buttonData,
      size = 'md',
      hasFocusRing = false,
      containerClassName = '',
      ...rest
    },
    ref: any
  ) => (
    <span
      ref={ref}
      className={`relative z-0 inline-flex shadow-sm rounded-md ${
        !!containerClassName ? containerClassName : ''
      }`}
      {...rest}
    >
      {!!buttonData.length &&
        buttonData
          .filter((button) => !!button.name || !!button.icon)
          .map(
            (
              {
                id,
                name,
                iconPlacement,
                icon,
                isLoading,
                isDisabled,
                className,
                ...rest
              }: buttonDataProps,
              index: number
            ) => {
              return (
                <button
                  type='button'
                  key={index}
                  disabled={!!isLoading || !!isDisabled}
                  id={!!id ? id.toString() : ''}
                  className={`${
                    index > 0 ? '-ml-px ' : ' '
                  }relative inline-flex items-center ${getSize(size)} ${
                    index === 0 ? 'rounded-l-md' : ''
                  } border ${
                    index ===
                    buttonData.filter(
                      (button) => !!button.name || !!button.icon
                    ).length -
                      1
                      ? 'rounded-r-md'
                      : ''
                  } font-medium focus:z-10 focus:outline-none ${
                    hasFocusRing
                      ? 'focus:ring-1 focus:ring-primary focus:border-primary'
                      : ''
                  } ${
                    !!isDisabled || !!isLoading
                      ? 'bg-gray-200 hover:bg-gray-300 cursor-not-allowed text-gray-400'
                      : !!className
                      ? className
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                  {...rest}
                >
                  {/*default icon placement is left || handles this condition*/}
                  {!!icon && (!iconPlacement || iconPlacement === 'left') && (
                    <span
                      data-testid='svg-element'
                      className={getIconClassName(size, name, iconPlacement)}
                    >
                      {icon}
                    </span>
                  )}
                  {!!name && name}
                  {!!icon && !!iconPlacement && iconPlacement === 'right' && (
                    <span
                      data-testid='svg-element'
                      className={getIconClassName(size, name, iconPlacement)}
                    >
                      {icon}
                    </span>
                  )}
                </button>
              );
            }
          )}
    </span>
  )
);

export default memo(ButtonGroup);
