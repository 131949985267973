import React from 'react';
import Footer from './Footer';
import facebookLogo from '../../../assets/images/facebookLogo.svg';

import { XCircleIcon } from '@heroicons/react/20/solid';
import { channelInfo } from '../../../../../utilities/utils';
import {
  facebookPageListProps,
  instagramPagesListProps,
} from 'pages/integration/interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  pageList: facebookPageListProps[] | instagramPagesListProps[];
  selectedPage: facebookPageListProps | instagramPagesListProps;
  setSelectedPage: (value: any) => void;
  platformType?: string;
  success?: boolean;
}

const SelectablPages: React.FC<Props> = ({
  pageList,
  selectedPage,
  setSelectedPage,
  platformType,
  success = true,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='p-6 pb-32 overflow-y-auto'>
        <div className='border rounded'>
          {!!pageList &&
            pageList.map(
              (
                page: facebookPageListProps | instagramPagesListProps,
                index: number
              ) => (
                <div
                  key={index}
                  className={`flex justify-between py-3 border-t ${
                    selectedPage.name === page.name
                      ? 'bg-green-200'
                      : page.is_connected
                      ? 'bg-gray-100'
                      : ''
                  }`}
                >
                  <span className='flex'>
                    <input
                      name='notification-method'
                      type='radio'
                      onChange={() => setSelectedPage(page)}
                      disabled={page.is_connected}
                      checked={
                        selectedPage?.name.length &&
                        selectedPage.name === page.name
                          ? true
                          : false
                      }
                      className='w-4 h-4 mx-4 mt-2 border-gray-300 text-primary focus:ring-primary'
                    />
                    <img
                      className='inline-block w-8 h-8 rounded-full'
                      src={page.avatar.length ? page.avatar : facebookLogo}
                      alt=''
                      onClick={
                        !page.is_connected
                          ? () => setSelectedPage(page)
                          : undefined
                      }
                    />
                    <label
                      htmlFor={page.name}
                      className='block mt-1.5 ltr:ml-3 rtl:mr-3 text-sm font-medium text-gray-700'
                      onClick={
                        !page.is_connected
                          ? () => setSelectedPage(page)
                          : undefined
                      }
                    >
                      {page.name}
                    </label>
                  </span>
                  {page?.is_connected && (
                    <span className='inline-flex items-center px-3 ltr:mr-3 rtl:ml-3 text-center text-green-800 bg-green-100 rounded cursor-pointer'>
                      {t('Connected')}
                    </span>
                  )}
                </div>
              )
            )}
        </div>
        {platformType === 'instagram_messenger' && (
          <div className='p-6 border-t'>
            <label
              htmlFor='nlp_selection'
              className='block mb-1 text-sm font-medium text-gray-700'
            >
              {t('Allow Permissions')}*
            </label>
            <p className='mt-2 text-sm text-gray-500'>
              1. Go to Instagram <b>Settings</b> &#8594; <b>Privacy</b> &#8594;{' '}
              <b>Messages</b>.
            </p>
            <p className='mt-2 text-sm text-gray-500'>
              {t('2. At the bottom of the page, turn on the toggle for')}{' '}
              <b>"{'Allow access to messages'}".</b>
            </p>
          </div>
        )}
        {!success && platformType === 'instagram_messenger' && (
          <div className='flex justify-center p-6 border-t'>
            <div className='justify-center'>
              <XCircleIcon
                className='w-5 h-5 text-red-500'
                aria-hidden='true'
              />
            </div>
            <p className='ml-2 text-sm text-red-600'>
              {t('We Failed to establish a connection with Instagram.')}
              {t('Please try again with the required permissions.')}
              <br />• Be the <b>owner</b> or <b>admin</b> of a Facebook page.{' '}
              <br />• Select the <b>Instagram Business Page</b> connected to a{' '}
              <b>{t('Facebook Business Page')}</b>. <br />• Turn on the toggle
              for <b>"{'Allow access to messages'}"</b>{' '}
              {t('from Instagram Settings')}.
            </p>
          </div>
        )}
      </div>
      <Footer channelName={channelInfo(platformType).title} />
    </>
  );
};
export default SelectablPages;
