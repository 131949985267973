import {
  useSelector,
  UtilityFunctions,
  ChevronRightIcon,
  useTriggerActions,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import { ITriggerList } from 'pages/automationWorkflow/interface';

const ChannelsDataView = () => {
  const { selectedTrigger } = useSelector(
    (state: any) => state.workflowAutomation
  );

  const { triggerTagList } = useAutomationWorkflow();
  const { handleSelectedTrigger } = useTriggerActions();

  return (
    <>
      {!triggerTagList.length && (
        <div className='flex items-center justify-center h-full'>
          <p className='text-base text-gray-500'>Select channel first.</p>
        </div>
      )}

      {triggerTagList.length > 0 &&
        triggerTagList?.map((trigger: ITriggerList, index: number) => (
          <div
            key={index}
            className='p-2 border-b'
            onClick={() => handleSelectedTrigger(trigger)}
          >
            <div
              className={`${
                selectedTrigger?.trigger_code === trigger?.trigger_code
                  ? 'bg-gray-100'
                  : 'bg-white'
              } flex items-center justify-between p-4 rounded-md cursor-pointer hover:bg-gray-100`}
            >
              <div className='flex items-center w-full'>
                {trigger?.trigger_icon && (
                  <div className='flex items-center justify-center w-16 rounded-md h-14'>
                    <img
                      src={trigger?.trigger_icon}
                      alt={trigger?.trigger_name}
                    />
                  </div>
                )}
                {!trigger?.trigger_icon && (
                  <div className='flex items-center justify-center w-16 bg-indigo-100 rounded-md h-14'>
                    {UtilityFunctions.getTriggerIcon(trigger?.trigger_code)}
                  </div>
                )}

                <div className='w-full ml-3'>
                  <h3 className='text-base font-medium text-gray-700 capitalize'>
                    {trigger?.trigger_name}
                  </h3>
                  <p className='text-sm text-gray-500'>
                    {trigger?.trigger_description}
                  </p>
                </div>
              </div>
              {trigger?.trigger_code === 'order_status_change' && (
                <div>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default ChannelsDataView;
