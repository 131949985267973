import React from 'react';
import Button from '../../../library/button';
import channleImages from '../assets/images/allChannelImage.svg';

interface Props {
  handleHasViewedIntegrations: () => void;
}

const InitialIntegration: React.FC<Props> = ({
  handleHasViewedIntegrations,
}) => {
  return (
    <div className='flex items-center justify-center h-full'>
      <div className='grid w-2/3 grid-cols-2 gap-5 my-6 place-items-center h-4/6'>
        <div className='h-full mt-12'>
          <h1 className='text-2xl font-extrabold text-gray-900'>
            Integrations
          </h1>
          <h2 className='mt-4 mb-3 text-lg font-semibold text-gray-700'>
            More Channels. More Customers. More Sales.
          </h2>
          <p className='text-sm text-gray-700'>
            MyAlice integrates with your favorite social channels, e-commerce
            marketplaces, NLP platforms and custom APIs.
          </p>
          <br />
          <p className='text-sm text-gray-700'>
            Turn your helpdesk into a sales powerhouse.
          </p>
          <br />
          <div className='text-sm text-gray-700'>
            <p>1. Bring your social channels under one inbox.</p>
            <p>2. Connect your e-commerce marketplace.</p>
            <p>3. Add live chat on your website and mobile app.</p>
          </div>
          <br />
          <br />
          <Button
            isDisabled={false}
            intent='primary'
            onClick={() => handleHasViewedIntegrations()}
            size={'sm'}
          >
            Explore Integrations
          </Button>

          {/* will be added when content is ready */}
          {/* <Button
            isDisabled={false}
            intent='default'
            onClick={() => handleHasViewedIntegrations()}
            size={'sm'}
            className='ml-3'
          >
            Watch Video
          </Button> */}
        </div>
        <div className='w-full h-full bg-transparent'>
          <img src={channleImages} alt={'icon_box'} />
        </div>
      </div>
    </div>
  );
};

export default InitialIntegration;
