import React from 'react';
import { connect } from 'react-redux';
import OptionDropdown from './OptionDropDown';
import { ChartBarIcon } from '@heroicons/react/20/solid';
import EmptyList from '../assets/emptyList.svg';
import { selectedProjectProps } from 'pages/integration/interface';
import Spinner from '../component/Spinner';
import { UBroadcastProperty, UGetInfoSvgTooptipIcon } from 'utilities/content';
import DeleteModal from './DeleteModal';
import { BroadcastListPayload, BroadcastProperty } from '../interface';
import { PlatformInterface } from 'index';
import { UtimeForTeamTimeZone } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  platformList: PlatformInterface[];
  broadcastList: BroadcastProperty[];
  broadcastListLoading: boolean;
  fetchBroadcastList: (
    payload: BroadcastListPayload
  ) => Promise<BroadcastProperty[]>;
  selectedProject: selectedProjectProps;
  broadcastCount: number;
  pageCount: number;
  setPageCount: (payload: number) => void;
  limit: number;
  setLimit: (payload: number) => void;
  offset: number;
  setOffset: (payload: number) => void;
  setIsEdit: (payload: boolean) => void;
  setBroadcastId: (payload: string) => void;
  setOpenCreateBroadcast: (payload: boolean) => void;
  setOpenDetailsModal: (payload: boolean) => void;
  setIsDetails: (payload: boolean) => void;
}

const BroadcastList: React.FC<Props> = ({
  platformList,
  broadcastList,
  broadcastListLoading,
  fetchBroadcastList,
  selectedProject,
  broadcastCount,
  pageCount,
  setPageCount,
  limit,
  setLimit,
  offset,
  setOffset,
  setIsEdit,
  setBroadcastId,
  setOpenCreateBroadcast,
  setOpenDetailsModal,
  setIsDetails,
}) => {
  const { t } = useTranslation();
  const [selectedBroadcastId, setSelectedBroadcastId] = React.useState<
    string | null
  >(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [broadcastData, setBroadcastData] =
    React.useState<BroadcastProperty | null>(null);
  let perPageData = UBroadcastProperty.perPageData;

  const renderBroadcastStatusView = (status: string) => {
    switch (status) {
      case `${UBroadcastProperty.broadcastStatus.scheduled.value}`:
        return (
          <div
            className={`py-1 px-1 ${UBroadcastProperty.broadcastStatus.scheduled.backgroundColor} ${UBroadcastProperty.broadcastStatus.scheduled.textColor} w-24 rounded-[15px] text-center capitalize`}
          >
            {t(UBroadcastProperty.broadcastStatus.scheduled.value)}
          </div>
        );
      case `${UBroadcastProperty.broadcastStatus.draft.value}`:
        return (
          <div
            className={`py-1 px-1 ${UBroadcastProperty.broadcastStatus.draft.backgroundColor} ${UBroadcastProperty.broadcastStatus.draft.textColor} text-gray-800 w-14 rounded-[15px] capitalize text-center`}
          >
            {t(UBroadcastProperty.broadcastStatus.draft.value)}
          </div>
        );
      case `${UBroadcastProperty.broadcastStatus.ongoing.value}`:
        return (
          <div
            className={`py-1 px-1 ${UBroadcastProperty.broadcastStatus.ongoing.backgroundColor} ${UBroadcastProperty.broadcastStatus.ongoing.textColor} w-20 rounded-[15px] capitalize text-center`}
          >
            {t(UBroadcastProperty.broadcastStatus.ongoing.value)}
          </div>
        );
      case `${UBroadcastProperty.broadcastStatus.completed.value}`:
        return (
          <div
            className={`py-1 px-1 ${UBroadcastProperty.broadcastStatus.completed.backgroundColor} ${UBroadcastProperty.broadcastStatus.completed.textColor} w-24 rounded-[15px] capitalize text-center`}
          >
            {t(UBroadcastProperty.broadcastStatus.completed.value)}
          </div>
        );
      case `${UBroadcastProperty.broadcastStatus.failed.value}`:
        return (
          <div
            className={`py-1 px-1 ${UBroadcastProperty.broadcastStatus.failed.backgroundColor} ${UBroadcastProperty.broadcastStatus.failed.textColor} w-16 rounded-[15px] capitalize text-center`}
          >
            {t(UBroadcastProperty.broadcastStatus.failed.value)}
          </div>
        );
      default:
        return (
          <div className='py-1 px-3 bg-red-500 text-white w-14 rounded-[15px] text-center'>
            - -
          </div>
        );
    }
  };

  const loadBroadcast = async (limit: number, offset: number, type: string) => {
    setLimit(limit);
    setOffset(offset);

    let payload = {
      limit: limit,
      offset: offset,
      projectId: selectedProject.id,
    };

    await fetchBroadcastList(payload);

    if (type === 'next') {
      setPageCount(pageCount + 1);
    } else {
      setPageCount(pageCount - 1);
    }
  };

  const showLimit = (pageCount: number) => {
    if (pageCount === 0 && broadcastList?.length <= perPageData) {
      return broadcastList?.length;
    } else if (pageCount !== 0 && broadcastList?.length < perPageData) {
      return offset + broadcastList.length;
    } else {
      return limit;
    }
  };

  const pagination = () => {
    return (
      <>
        {broadcastList && broadcastList?.length > 0 && (
          <div className='flex items-center w-full px-6 py-3 bg-white border border-t border-gray-200 rounded-b-md'>
            <div className='flex items-center w-full'>
              <div className='w-1/2'>
                <p className='text-sm text-gray-700'>
                  {t('Showing')}{' '}
                  <span className='font-medium'>
                    {pageCount === 0 ? 1 : offset + 1}
                  </span>{' '}
                  {t('to')}{' '}
                  <span className='font-medium'>{showLimit(pageCount)}</span>{' '}
                  {t('of')}{' '}
                  <span className='font-medium'>{broadcastCount}</span>{' '}
                  {t('results')}
                </p>
              </div>
              <div className='flex w-1/2'>
                <div className='flex justify-end w-full'>
                  {pageCount !== 0 && (
                    <button
                      className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white
                px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
                  pageCount === 0 ? 'bg-gray-100 cursor-not-allowed' : ''
                }`}
                      onClick={() => {
                        if (pageCount === 0) return;
                        loadBroadcast(
                          limit - perPageData,
                          offset - perPageData,
                          'prev'
                        );
                      }}
                    >
                      {t('Previous')}
                    </button>
                  )}
                  {broadcastList?.length >= perPageData && (
                    <button
                      className={`relative ml-3 inline-flex items-center rounded-md
                    border border-gray-300 bg-white px-4 py-2 text-sm font-medium
                  text-gray-700 hover:bg-gray-50 ${
                    broadcastList?.length < perPageData
                      ? 'cursor-not-allowed bg-gray-100'
                      : ''
                  }`}
                      onClick={() => {
                        if (broadcastList?.length < perPageData) return;
                        loadBroadcast(
                          limit + perPageData,
                          offset + perPageData,
                          'next'
                        );
                      }}
                    >
                      {t('Next')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const statusCheckForAction = (
    status: string,
    broadcastId: string,
    broadcastData: BroadcastProperty
  ) => {
    switch (status) {
      case 'scheduled':
        return (
          <OptionDropdown
            status={status}
            setIsOpen={setIsOpen}
            setSelectedBroadcastId={setSelectedBroadcastId}
            broadcastId={broadcastId}
            setIsEdit={setIsEdit}
            setBroadcastId={setBroadcastId}
            setOpenCreateBroadcast={setOpenCreateBroadcast}
            broadcastData={broadcastData}
            setBroadcastData={setBroadcastData}
          />
        );
      case 'draft':
        return (
          <OptionDropdown
            status={status}
            setIsOpen={setIsOpen}
            setSelectedBroadcastId={setSelectedBroadcastId}
            broadcastId={broadcastId}
            setIsEdit={setIsEdit}
            setBroadcastId={setBroadcastId}
            setOpenCreateBroadcast={setOpenCreateBroadcast}
            broadcastData={broadcastData}
            setBroadcastData={setBroadcastData}
          />
        );
      case 'ongoing':
        return (
          <div
            className='ltr:ml-3 rtl:mr-3'
            onClick={() => {
              setOpenDetailsModal(true);
              setBroadcastId(broadcastId);
              setIsDetails(true);
            }}
          >
            <ChartBarIcon className='w-5 h-5 text-gray-500 cursor-pointer' />
          </div>
        );
      case 'completed':
        return (
          <div
            className='ltr:ml-3 rtl:mr-3'
            onClick={() => {
              setOpenDetailsModal(true);
              setBroadcastId(broadcastId);
              setIsDetails(true);
            }}
          >
            <ChartBarIcon className='w-5 h-5 text-gray-500 cursor-pointer' />
          </div>
        );
      case 'failed':
        return (
          <OptionDropdown
            status={status}
            setIsOpen={setIsOpen}
            setSelectedBroadcastId={setSelectedBroadcastId}
            broadcastId={broadcastId}
            setIsEdit={setIsEdit}
            setBroadcastId={setBroadcastId}
            setOpenCreateBroadcast={setOpenCreateBroadcast}
            broadcastData={broadcastData}
            setBroadcastData={setBroadcastData}
          />
        );
      default:
        return (
          <div className='py-1 px-3 bg-red-500 text-white w-14 rounded-[15px] text-center'>
            - -
          </div>
        );
    }
  };

  const getTemplateTitle = (broadcastData: BroadcastProperty) => {
    const allTemplates = platformList?.find(
      (item) => item?.secondary_id === broadcastData?.channel_details?.phone
    );

    if (allTemplates) {
      let templateList = allTemplates?.whatsapp_message_templates?.filter(
        (template) => template?.status === 'approved'
      );

      return templateList?.filter(
        (item) => item?.id === broadcastData?.template_id
      )[0]?.name;
    }
    return '';
  };

  const renderTableHeader = () => {
    return (
      <>
        <th
          scope='col'
          className='w-[190px] text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
        >
          <span className='h-full px-6 py-3 inline-block w-full'>
            {t('Broadcast Name')}
          </span>
        </th>
        <th
          scope='col'
          className='text-xs font-medium tracking-wider text-gray-500'
        >
          <span className='flex justify-center uppercase ltr:text-left rtl:text-right h-full px-6 py-3'>
            {t('Status')}
          </span>
        </th>
        <th
          scope='col'
          className='w-[200px] text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
        >
          <span className='h-full px-6 py-3 inline-block w-full'>
            {t('Channel')}
          </span>
        </th>
        <th
          scope='col'
          className='w-[220px] text-xs font-medium tracking-wider text-gray-500'
        >
          <span className='uppercase flex align-center ltr:text-left rtl:text-right h-full px-6 py-3'>
            {t('Broadcast time')}&nbsp;
            {UGetInfoSvgTooptipIcon(
              t(`The broadcast time is displayed in your team's timezone`) +
                `(GMT ${selectedProject?.timezone_offset})`
            )}
          </span>
        </th>
        <th
          scope='col'
          className='text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
        >
          <span className='h-full px-6 py-3 inline-block w-full'>
            {t('Scheduled')}
          </span>
        </th>
        <th
          scope='col'
          className='text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
        >
          <span className='h-full px-6 py-3 inline-block w-full'>
            {t('Delivered')}
          </span>
        </th>
        <th
          scope='col'
          className='text-xs font-medium tracking-wider text-gray-500'
        >
          <span className='uppercase flex align-center ltr:text-left rtl:text-right h-full px-6 py-3'>
            {t('Sales')}&nbsp;
            {UGetInfoSvgTooptipIcon(
              t('Total value of the orders placed by the broadcast recipients'),
              '',
              'left-[-115px]',
              ''
            )}
          </span>
        </th>
        <th
          scope='col'
          className='text-xs font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 uppercase'
        >
          <span className='h-full px-6 py-3 inline-block w-full'>
            {t('Action')}
          </span>
        </th>
      </>
    );
  };

  const renderBroadcastValue = (broadcastList: BroadcastProperty[]) => {
    return broadcastList.map((broadcast, index) => {
      return (
        <tr key={index}>
          <td className='w-[190px] px-6 py-4 text-sm font-medium text-gray-700 whitespace-nowrap capitalize rtl:text-right'>
            <p className='w-42 truncate'>
              {broadcast?.title}
              <span className='block text-xs leading-4 text-gray-500 w-[130px] truncate'>
                {getTemplateTitle(broadcast)}
              </span>
            </p>
          </td>
          <td className='flex justify-center px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
            {renderBroadcastStatusView(broadcast?.status)}
          </td>
          <td className='w-[200px] px-6 rtl:text-right py-4 text-sm text-gray-700 whitespace-nowrap'>
            <p className='truncate'>
              {broadcast?.channel_details?.title}
              <span className='block text-gray-500'>
                {broadcast?.channel_details?.phone &&
                  '(' + broadcast?.channel_details?.phone + ')'}
              </span>
            </p>
          </td>
          <td className='w-[220px] rtl:text-right px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
            {UtimeForTeamTimeZone(broadcast?.scheduled_time, selectedProject)}
          </td>
          <td className='px-6 py-4 rtl:text-right text-sm text-gray-500 whitespace-nowrap capitalize'>
            {broadcast?.total_audience_data}
          </td>
          <td className='px-6 py-4 rtl:text-right text-sm text-gray-500 whitespace-nowrap capitalize'>
            {broadcast?.sent_count !== 0 ? (
              <p className='flex flex-col'>
                <span>
                  {(
                    (broadcast?.sent_count / broadcast?.total_count) *
                    100
                  ).toFixed(0)}
                  %
                </span>
                <span>{broadcast?.sent_count}</span>
              </p>
            ) : (
              '- -'
            )}
          </td>
          <td className='px-6 rtl:text-right py-4 text-sm text-gray-500 whitespace-nowrap capitalize'>
            {!!broadcast?.total_sale ? broadcast?.total_sale : '- -'}
          </td>
          <td className='px-6 py-4 rtl:text-right text-sm text-gray-500 whitespace-nowrap'>
            {statusCheckForAction(broadcast?.status, broadcast?.id, broadcast)}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className='mb-5 ' data-testid='broadcastlist'>
        <div className='border border-gray-200 broadcast-table-body rounded-t-md'>
          <table className='w-full divide-y divide-gray-200'>
            <thead className='sticky bg-gray-50'>
              <tr>{renderTableHeader()}</tr>
            </thead>
            {!broadcastListLoading && (
              <tbody className='block overflow-y-auto bg-white divide-y divide-gray-200'>
                {broadcastList && broadcastList?.length > 0 ? (
                  renderBroadcastValue(broadcastList)
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <div className='w-full h-full flex flex-col items-center justify-center min-h-[500px]'>
                        <img src={EmptyList} className='w-40 mb-2' alt='' />
                        <p className='text-sm text-gray-400'>
                          Create a broadcast to view data here
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
            {broadcastListLoading && (
              <tbody className='bg-white divide-y divide-gray-200'>
                <tr>
                  <td colSpan={8}>
                    <div className='min-h-[500px] flex items-center justify-center'>
                      <Spinner
                        color='border-r-transparent border-t-green-500 border-b-green-500 border-l-green-500'
                        size='w-16 h-16'
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {!broadcastListLoading && <>{pagination()}</>}
      </div>
      <DeleteModal
        broadcastId={selectedBroadcastId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        broadcastData={broadcastData}
        setBroadcastData={setBroadcastData}
      />
    </>
  );
};

const mapState = (state: any) => ({
  platformList: state.dashboard.platformList,
  broadcastList: state.broadcast.broadcastList,
  broadcastListLoading: state.loading.effects.broadcast.fetchBroadcastList,
  selectedProject: state.dashboard.selectedProject,
  broadcastCount: state.broadcast.broadcastCount,
});

const mapDispatch = (dispatch: any) => ({
  fetchBroadcastList: (payload: BroadcastListPayload) =>
    dispatch.broadcast.fetchBroadcastList(payload),
});

export default connect(mapState, mapDispatch)(BroadcastList);
