import React from 'react';
import ProductSelection from './screens/ProductSelection';
import DeliveryInformation from './screens/DeliveryInformation';
import ShopifyUpdateSummary from './screens/ShopifyUpdateSummary';
import { connect } from 'react-redux';
import UpdateOrder from './screens/UpdateOrder';
import errorStateIcon from '../../../assets/images/ecommerceAssets/OrderCreateError.svg';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface Prop {
  step: number;
  customerId: number;
  fetchCart: (customerId: number) => Promise<any>;
  updateOrderAPI: () => Promise<any>;
  orderId?: string;
  fetchOrder: (teamId: number, orderId: string) => Promise<any>;
  projectId: number;
  calculateUpdateOrderBegin: (
    customerId: number,
    orderId: string
  ) => Promise<any>;
  customerData: any;
  finishCalculationApi: () => Promise<any>;
  eComOrderId?: string | number;
}
const Wrapper: React.FC<Prop> = ({
  step,
  customerId,
  fetchCart,
  updateOrderAPI,
  orderId,
  fetchOrder,
  projectId,
  calculateUpdateOrderBegin,
  customerData,
  finishCalculationApi,
  eComOrderId,
}) => {
  type apiStateType = 'loading' | 'success' | 'error';
  const [apiState, setAPIState] = React.useState<apiStateType>('loading');

  const handleCalculationBegin = async (
    customerId: number,
    orderId: string,
    projectId: number
  ) => {
    let response = await calculateUpdateOrderBegin(customerId, orderId);
    if (response?.success) {
      await fetchOrder(projectId, orderId);
      setAPIState('success');
    } else {
      setAPIState('error');
    }
  };

  React.useEffect(() => {
    if (orderId && customerId && projectId) {
      handleCalculationBegin(customerId, orderId, projectId);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {apiState === 'success' ? (
        <>
          {step === 1 && (
            <ProductSelection orderId={orderId} customerId={customerId} />
          )}
          {step === 2 && (
            <DeliveryInformation
              orderId={orderId}
              customerId={customerId}
              finishCalculationApi={finishCalculationApi}
            />
          )}
          {step === 3 && <ShopifyUpdateSummary />}
          {step === 4 && (
            <UpdateOrder
              updateOrderAPI={updateOrderAPI}
              eComOrderId={eComOrderId}
            />
          )}
        </>
      ) : apiState === 'error' ? (
        <>
          <div className='flex items-center justify-center h-96 flex-column'>
            <img src={errorStateIcon} alt={apiState} width={200} />
            <p>{'Cannot update this order! Please try again.'}</p>
          </div>
        </>
      ) : (
        <>
          <div className='flex items-center justify-center h-96 flex-column'>
            <ArrowPathIcon className='w-10 h-10 text-primary hover:text-primary-hover animate-reverse-spin' />
            <div>
              <p>{'Fetching order information...'}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
  customerData: state.crmEcommerce.customerData,
});

const mapDispatch = (dispatch: any) => ({
  fetchCart: (customerId: number) =>
    dispatch.crmEcommerce.fetchCart(customerId),
  fetchOrder: (projectId: number, orderId: string) =>
    dispatch.crmEcommerce.fetchOrder({
      projectId,
      orderId,
    }),
});

const WrapperContainer = connect(mapState, mapDispatch)(Wrapper);
export default WrapperContainer;
