import {
  React,
  PlayIcon,
  useCallback,
  useAutomationWorkflow,
} from '../../../../export';

import { NodeType } from '../../../../interface';

interface Props {
  id: string;
  data: any;
}

const TriggerButton: React.FC<Props> = ({ id, data }) => {
  const { automationNodes, updatePrimitiveStateData } = useAutomationWorkflow();

  const handleOnClick = useCallback(
    (nodeType: NodeType) => {
      updatePrimitiveStateData({ key: 'showSlider', value: true });
      updatePrimitiveStateData({
        key: 'selectedNodeType',
        value: nodeType,
      });
      updatePrimitiveStateData({ key: 'selectedNodeId', value: id });
    },
    // eslint-disable-next-line
    [automationNodes]
  );

  return (
    <div className='w-auto h-auto p-2 bg-white rounded-lg drop-shadow-lg'>
      <div
        className='flex w-full'
        onClick={() => handleOnClick(NodeType.TRIGGER)}
      >
        <div className='w-5 h-5 '>
          <PlayIcon />
        </div>
        <label>Select Trigger</label>
      </div>
    </div>
  );
};

export default TriggerButton;
