import React from 'react';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  status: string;
}

const OrderStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const renderOrderStatus = (status: string) => {
    switch (status ? status.toLowerCase() : '') {
      case 'processing':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-blue-800 bg-blue-200 font-medium capitalize rounded-md'
          >
            {t('Processing')}
          </p>
        );
      case 'cancled':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-blue-800 bg-blue-200 font-medium capitalize rounded-md'
          >
            {t('cancled')}
          </p>
        );
      case 'completed':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-[#065F46] bg-green-200 font-medium capitalize rounded-md'
          >
            {t('completed')}
          </p>
        );
      case 'hold':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-yellow-800 bg-yellow-200 font-medium capitalize rounded-md'
          >
            {t('on hold')}
          </p>
        );
      case 'voided':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-blue-800 bg-blue-200 font-medium capitalize rounded-md'
          >
            {t('Voided')}
          </p>
        );
      case 'draft':
      case 'refunded':
      case 'pending':
      case 'unfulfilled':
      case 'under_review':
      case 'open':
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-gray-800 bg-gray-200 font-medium capitalize rounded-md'
          >
            {t(status?.toLowerCase())}
          </p>
        );
      default:
        return (
          <p
            data-testid='order-status'
            className='px-2 py-1 text-gray-800 bg-gray-200 font-medium capitalize rounded-md'
          >
            --
          </p>
        );
    }
  };
  return renderOrderStatus(status);
};

export default OrderStatus;
