import {
  React,
  Tooltip,
  Listbox,
  XMarkIcon,
  Transition,
  ChevronDownIcon,
} from 'pages/automationWorkflow/export';

type Props = {
  options: any[];
  selectedOptions: any[];
  setSelectedOptions: (selected: any) => void;
  removeSelectedDisplayOption: (selectedItem: string) => void;
};

const MultipleConditionDropdown: React.FC<Props> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  removeSelectedDisplayOption,
}) => {
  return (
    <Listbox value={selectedOptions} onChange={setSelectedOptions} multiple>
      {({ open }) => (
        <>
          <div className='relative'>
            <Listbox.Button
              className={`${
                open ? 'border-primary' : 'border-gray-300'
              } relative py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border  rounded-md cursor-pointer focus:outline-none`}
            >
              Select options...
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronDownIcon
                  className='w-4 h-4 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              leave='transition duration-100 ease-in'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {options?.length === 0 && (
                  <div className='text-gray-600 text-sm py-2 px-3 my-1 text-center'>
                    Item not found
                  </div>
                )}
                {options.length > 0 &&
                  options.map((option: any, index: number) => (
                    <Listbox.Option
                      key={index}
                      className='cursor-pointer select-none relative py-2 px-3 my-1 text-gray-600 text-sm hover:bg-gray-100'
                      value={option}
                    >
                      <div className='flex items-center'>
                        <input
                          type='checkbox'
                          readOnly
                          checked={(selectedOptions ?? []).some(
                            (item) => item?.value === option?.value
                          )}
                          className={`${
                            (selectedOptions ?? []).some(
                              (item) => item?.value === option?.component_code
                            )
                              ? 'text-gray-300'
                              : 'text-green-500'
                          } flex items-center pl-3 rounded w-4 h-4 border border-gray-300`}
                        />

                        <span className='block truncate ml-2'>
                          {option?.label}
                        </span>
                      </div>
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
          <div>
            {selectedOptions?.length !== 0 && (
              <div className='mt-2 grid grid-cols-2 gap-2'>
                {selectedOptions?.map(
                  (option: { label: string; value: string }, index: number) => (
                    <div
                      key={index}
                      className='flex items-center justify-between bg-gray-100 rounded-xl py-1 px-4 col-span-1'
                    >
                      <div className='text-gray-800 font-medium text-sm'>
                        <Tooltip text={option?.label}>
                          {option?.label.length > 15
                            ? option?.label.substring(0, 10) + '...'
                            : option?.label}
                        </Tooltip>
                      </div>
                      <div
                        className='cursor-pointer'
                        onClick={() =>
                          removeSelectedDisplayOption(option?.value)
                        }
                      >
                        <XMarkIcon className='h-5 w-5 text-gray-800' />
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
};

export default MultipleConditionDropdown;
