import React from 'react';

import { Button } from 'libraryV2/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { EllipsisIcon } from 'lucide-react';
import {
  ConversationBlockAttachmentDataInterface,
  CustomerInfoInterface,
  CustomerInfoType,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import Linkify from 'react-linkify';
import Avatar from '../components/conversation/Avatar';
import MessageStatus from '../components/conversation/MessageStatus';
import Time from '../components/conversation/Time';
import DateBar from './DateBar';
import EmailDropdown from './EmailDropdown';

interface Props {
  blockData: ConversationBlockAttachmentDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  enableDateBar: boolean;
  report?: any;
  meta: CustomerInfoInterface;
  customerInfo: CustomerInfoType;
  conversationId: string;
  subject: string;
}

const EmailAttachmentBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  enableDateBar,
  report,
  meta,
  customerInfo,
  conversationId,
  subject,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const iframeRef = React.useRef(null);
  const repliesIframeRef = React.useRef(null);

  const handleHeight = (isReplyBlock = false) => {
    const iframe: any = isReplyBlock
      ? repliesIframeRef.current
      : iframeRef.current;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const height = iframeDocument.body.scrollHeight;
    iframe.style.height = `${height + 20}px`;
  };

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
        return 'text-gray-900';
      case 'gmail':
        return 'text-[#3B82F6]';
      default:
        return 'text-green-500';
    }
  };

  const extractfileName = (url: string) => {
    const start = url?.lastIndexOf('/') + 1;
    const end = url?.lastIndexOf('_');
    const fileName = url?.substring(start, end);
    const fileType = url?.substring(url.lastIndexOf('.') + 1);

    if (fileName?.length > 25) {
      return fileName?.substring(0, 25) + '...' + fileType;
    } else {
      return fileName + '.' + fileType;
    }
  };

  const getEmailDetailsInfo = () => {
    const isCustomer = source === 'customer';
    const senderName = isCustomer
      ? customerInfo?.full_name
      : meta?.platform?.name;
    const senderEmail = isCustomer
      ? customerInfo?.primary_id
      : meta?.platform?.primary_id;
    const receiverName = isCustomer
      ? meta?.platform?.name
      : customerInfo?.full_name;
    const receiverEmail = isCustomer
      ? meta?.platform?.primary_id
      : customerInfo?.primary_id;

    return { senderName, senderEmail, receiverName, receiverEmail };
  };

  const renderAttachmentLink = (url: string, index: number) => {
    const handleAttachmentClick = () => {
      window.open(url, '_blank');
    };
    return (
      <div className='flex mb-3' key={index}>
        <button
          onClick={handleAttachmentClick}
          className='border h-auto w-[288px] rounded
          content-center border-gray-200 hover:bg-gray-200 p-2 bg-gray-100 flex justify-start'
        >
          <p className='text-sm text-blue-500 mr-2.5 text-left font-medium'>
            {extractfileName(url)}
          </p>
        </button>
      </div>
    );
  };

  const renderEmailPayload = () => {
    const currentMailText = blockData?.payload || '';
    if (!currentMailText) {
      return null;
    }
    return (
      <div className='max-w-[90%] border border-gray-200 rounded-xl ltr:rounded-tl-[4px] rtl:rounded-tr-[4px]'>
        <iframe
          ref={iframeRef}
          onLoad={() => handleHeight()}
          title='HTML content'
          srcDoc={currentMailText}
          style={{
            width: '100%',
            border: 'none',
          }}
        />
      </div>
    );
  };

  const renderEmailReplyBlock = () => {
    const replyData = blockData?.text ?? '';
    if (!replyData) {
      return null;
    }
    return (
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger asChild>
          <TooltipProviderCustomised
            content={isOpen ? 'Hide expanded content' : 'Show trimmed content.'}
          >
            <Button
              onClick={() => setIsOpen((o) => !o)}
              variant='ghost'
              className='w-fit mt-2 h-0 rounded-full px-2 bg-zinc-200 text-zinc-500'
            >
              <EllipsisIcon className='h-6 w-6 text-zinc-500' />
            </Button>
          </TooltipProviderCustomised>
        </CollapsibleTrigger>
        <CollapsibleContent className='space-y-2'>
          <iframe
            ref={repliesIframeRef}
            onLoad={() => handleHeight(true)}
            title='Email Reply content'
            srcDoc={replyData}
            style={{
              width: '100%',
              border: 'none',
              backgroundColor: '#f9fafb',
              borderRadius: '8px',
              padding: '8px',
            }}
          />
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderFileAttachmentView = () => {
    return (
      <>
        <>
          {blockData?.success ? (
            <>
              {['admin', 'bot'].includes(source) ? (
                <p
                  data-testid='text-message-block'
                  className={`w-auto h-auto ${
                    !!blockData?.success ? 'text-gray-900' : 'text-gray-500'
                  } sm:text-sm break-words whitespace-pre-wrap`}
                >
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-link'
                        href={decoratedHref}
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {blockData?.text}
                  </Linkify>
                </p>
              ) : (
                renderEmailPayload()
              )}
            </>
          ) : (
            <p className='w-auto h-auto text-gray-500 break-words whitespace-pre-wrap sm:text-sm'>
              {blockData?.error}
            </p>
          )}
        </>
        <p className='text-gray-500 text-sm w-full mb-2 font-medium mt-[12px]'>
          Attachments:
        </p>
        <hr className='w-[99%] py-2' />
        {!!blockData.urls &&
          blockData.urls.map((url, index: number) => {
            return renderAttachmentLink(url, index);
          })}
        {renderEmailReplyBlock()}
      </>
    );
  };

  return (
    <>
      <div className='flex w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9]'>
        <Avatar avatar={avatar} />
        <div className='w-full'>
          <div className='flex items-center w-full'>
            <div
              className={`mr-2 my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
            >
              {name}
            </div>
            <Time time={time} isMerged={false} />
            {['admin', 'bot', 'gmail'].includes(source) && (
              <MessageStatus
                success={isSent}
                isMerged={false}
                report={report}
              />
            )}
            <EmailDropdown
              senderName={getEmailDetailsInfo().senderName}
              receiverName={getEmailDetailsInfo().receiverName}
              senderEmail={getEmailDetailsInfo().senderEmail}
              receiverEmail={getEmailDetailsInfo().receiverEmail}
              date={time}
              subject={subject}
              emailInfo={!!blockData?.email_data ? blockData?.email_data : null}
              conversationId={conversationId}
            />
          </div>
          {renderFileAttachmentView()}
        </div>
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default EmailAttachmentBlock;
