import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import useDebounce from 'components/customHooks/useDebounce';
import { useEffect, useRef, useState, forwardRef, ForwardedRef } from 'react';

interface Props {
  isApiSearch?: boolean;
  onChange: (searchQuery: string) => void;
}

interface SearchFieldRef {
  focus(): void;
}

const SearchField: React.FC<Props> = forwardRef<SearchFieldRef, Props>(
  ({ isApiSearch = false, onChange }, ref) => {
    const [query, setQuery] = useState<string>('');
    const textFieldRef = useRef<HTMLInputElement>(null);
    const serachDebounce = useDebounce(query, isApiSearch ? 500 : 0);

    useEffect(() => {
      onChange(query);
      //eslint-disable-next-line
    }, [serachDebounce]);

    // Forward the ref to the input element
    useEffect(() => {
      if (!!ref) {
        (ref as ForwardedRef<SearchFieldRef>).current = {
          focus: () => {
            if (textFieldRef.current) {
              textFieldRef.current.focus();
            }
          },
        };
      }
    }, [ref]);

    return (
      <div className='px-2 py-1 border-b border-gray-100'>
        <div className='relative mt-2 rounded-md shadow-sm'>
          <div className='pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-2 flex items-center pl-3 mr-2'>
            <MagnifyingGlassIcon className='text-gray-500 sm:text-sm h-4 w-4' />
          </div>
          <input
            ref={textFieldRef}
            type='text'
            name='search'
            id='search'
            className='block w-full rounded-md border-0 py-1.5 pl-7 ltr:pr-20 rtl:pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
            placeholder='search'
            onChange={(e) => setQuery(e.target.value)}
            autoComplete='off'
          />
        </div>
      </div>
    );
  }
);

export default SearchField;
