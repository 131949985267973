import { Modal } from 'library';
import {
  React,
  Button,
  useSelector,
  ArrowLeftIcon,
  EditablePencilIcon,
  SwitchWithRightLabel,
  useAutomationWorkflow,
  EditableTextComponent,
  useCreateWorkflowServices,
  useUpdateWorkflowServices,
} from '../../../../export';

const ReactWorkflowHeader = () => {
  const [isOpenConfirmBackModal, setIsOpenConfirmBackModal] =
    React.useState(false);
  const {
    automationNodes,
    clearStateData,
    isNewWorkFlowActive,
    updatePrimitiveStateData,
    automationFlowDisplayViewType,
  } = useAutomationWorkflow();

  const {
    automationName,
    isAutomationEditable,
    selectedAutomationId,
    selectedUserActionFlowData,
  } = useSelector((state: any) => state.workflowAutomation);

  const { isUpdateSelectedAutomationFlowLoading } = useUpdateWorkflowServices();

  const {
    createAutomationFlow,
    updateSelectedAutomationData,
    isCreateAutomationFlowLoading,
  } = useCreateWorkflowServices();

  const renderAutomationWorkflowCreateHeaderView = () => {
    return (
      <>
        <div className='flex items-center justify-center'>
          <>
            <Button
              className='py-2 pl-2 pr-2 bg-green-100'
              icon={<ArrowLeftIcon className='h-5 text-green-500' />}
              onClick={() => {
                const isDataInAutomationNodes = automationNodes.filter(
                  (node: any) =>
                    node.type === 'trigger-node' ||
                    node.type === 'condition-node' ||
                    node.type === 'action-node'
                );
                if (isDataInAutomationNodes) {
                  setIsOpenConfirmBackModal(true);
                } else {
                  updatePrimitiveStateData({
                    key: 'automationFlowDisplayViewType',
                    value: 'dashboard',
                  });
                  clearStateData();
                }
              }}
            />
            <EditableTextComponent
              editableName={automationName}
              editIcon={<EditablePencilIcon />}
              handleOnEnterPress={(value: string) => {
                updatePrimitiveStateData({
                  key: 'automationName',
                  value: value.length !== 0 ? value : 'Untitled Automation',
                });
                if (isAutomationEditable) {
                  updatePrimitiveStateData({
                    key: 'selectedUserActionFlowData',
                    value: {
                      ...selectedUserActionFlowData,
                      name: value,
                    },
                  });
                }
              }}
            />
          </>
        </div>
        <div className='flex items-center justify-center'>
          <div className='mr-6'>
            <SwitchWithRightLabel
              isEnabled={isNewWorkFlowActive}
              label={isNewWorkFlowActive ? 'active' : 'inactive'}
              onChange={() => {
                updatePrimitiveStateData({
                  key: 'isNewWorkFlowActive',
                  value: !isNewWorkFlowActive,
                });
                if (isAutomationEditable) {
                  updatePrimitiveStateData({
                    key: 'selectedUserActionFlowData',
                    value: {
                      ...selectedUserActionFlowData,
                      is_active: !selectedUserActionFlowData?.is_active,
                    },
                  });
                }
              }}
            />
          </div>
          <Button
            intent='primary'
            className='py-2 pl-2 pr-2'
            onClick={() => {
              if (isAutomationEditable && selectedAutomationId !== null) {
                updateSelectedAutomationData(selectedAutomationId);
              } else {
                createAutomationFlow();
              }
            }}
            isLoading={
              isCreateAutomationFlowLoading ||
              isUpdateSelectedAutomationFlowLoading
            }
          >
            {isAutomationEditable ? 'Update Automation' : 'Save Automation'}
          </Button>
        </div>
      </>
    );
  };

  const renderConfirmBackChildrenView = () => {
    return (
      <div className='text-gray-500 text-sm p-4'>
        Please save before leaving this page, otherwise you will lose your
        unsaved modifications.
      </div>
    );
  };

  return (
    <>
      <div className='absolute top-0 z-10 flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
        {automationFlowDisplayViewType === 'createWorkflow' &&
          renderAutomationWorkflowCreateHeaderView()}
      </div>
      {isOpenConfirmBackModal && (
        <Modal
          open={isOpenConfirmBackModal}
          handleClose={() => {
            updatePrimitiveStateData({
              key: 'automationFlowDisplayViewType',
              value: 'dashboard',
            });
            clearStateData();
            setIsOpenConfirmBackModal(false);
          }}
          confirmText={`Save Workflow`}
          cancelText={`Continue without save`}
          handleCancel={() => {
            updatePrimitiveStateData({
              key: 'automationFlowDisplayViewType',
              value: 'dashboard',
            });
            clearStateData();
            setIsOpenConfirmBackModal(false);
          }}
          handleConfirm={() => {
            if (isAutomationEditable && selectedAutomationId !== null) {
              updateSelectedAutomationData(selectedAutomationId);
            } else {
              createAutomationFlow();
            }
          }}
          children={renderConfirmBackChildrenView()}
          title='Save Automation?'
          width='w-[400px]'
          height='auto'
          description=''
        />
      )}
    </>
  );
};

export default ReactWorkflowHeader;
