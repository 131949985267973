import { navigate } from '@reach/router';
import {
  ProjectsRolesDataType,
  SelectedTeamInterface,
  UserPropertyType,
} from 'index';
import { connect } from 'react-redux';
import disabledImage from '../assets/images/disabledImage.svg';

interface Props {
  setSelectedProject: (payload: SelectedTeamInterface) => void;
  updateSelectedProject: (payload: SelectedTeamInterface) => void;
  fetchDashboardCardData: (payload: number) => void;
  fetchPlatformList: (payload: number) => void;
  teams: SelectedTeamInterface[];
  selectedProject: SelectedTeamInterface;
  agentAccess: ProjectsRolesDataType[];
  fetchAgentAccess: (payload: number) => ProjectsRolesDataType[];
  auth: UserPropertyType;
  fetchAccess: (payload: { teamId: number; userId: number }) => void;
  logoutAPI: () => void;
}

const DisabledScreen: React.FC<Props> = ({
  setSelectedProject,
  updateSelectedProject,
  fetchDashboardCardData,
  fetchPlatformList,
  teams,
  auth,
  logoutAPI,
}) => {
  const changeToTeam = async () => {
    let mainData: any = teams.find((team) => !team.is_access_disabled);
    setSelectedProject(mainData);
    updateSelectedProject(mainData);
    if (mainData) {
      await fetchPlatformList(mainData.id);
      fetchDashboardCardData(mainData.id);
    }
    navigate('/');
  };

  return (
    <div className='flex w-full h-full justify-center items-center'>
      <div className='w-[95%] h-[95%] rounded border bg-white p-5 flex justify-center items-center flex-col'>
        <div className='w-[500px] h-[400px] flex flex-col items-center justify-center'>
          <img src={disabledImage} alt='' className='w-40 h-40 mb-5' />
          <p className='text-sm text-gray-500 mb-2 max-w-[300px]'>
            You do not have access to this team. Please contact team creator to
            access this team.
          </p>
          {teams.length > 1 ? (
            <button
              className='p-2 text-sm border rounded text-gray-500 cursor-pointer mb-5'
              onClick={() => changeToTeam()}
            >
              Get back to previous team
            </button>
          ) : (
            <button
              className='p-2 text-sm border rounded text-gray-500 cursor-pointer mb-5'
              onClick={() => logoutAPI()}
            >
              Login with a different account
            </button>
          )}
          <div className='border-b border-gray-200 w-full mb-3'>{''}</div>
          <p className='text-gray-500 text-sm mb-1'>
            You are currently logged in as{' '}
            <span className='font-bold'>{auth.email}</span>
          </p>
          <div className='text-gray-500 text-sm'>
            If you think something is not right, then{' '}
            <a
              href='https://www.myalice.ai/support'
              target='_blank'
              rel='noreferrer'
            >
              <span className='text-blue-500'>contact support</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  teams: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  agentAccess: state.dashboard.agentAccess,
  auth: state.auth,
});
const mapDispatch = (dispatch: any) => ({
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.updateSelectedProject(project),
  updateSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.settings.setSelectedProject(project),
  fetchPlatformList: (teamId: number) =>
    dispatch.dashboard.fetchPlatformList(teamId),
  fetchDashboardCardData: (teamId: number) =>
    dispatch.dashboard.fetchDashboardCardData(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchAgentAccess: (projectId: number) =>
    dispatch.dashboard.fetchAgentAccess(projectId),
  fetchAccess: (payload: { teamId: number; userId: number }) =>
    dispatch.dashboard.fetchAccess(payload),
  logoutAPI: () => dispatch.auth.logoutAPI(),
});

export default connect(mapState, mapDispatch)(DisabledScreen);
