import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

type Props = {
  errorMessage: string;
  setBetterdocsUrl: (url: string) => void;
  setBetterDocsName: (name: string) => void;
  betterdocsUrl?: string;
  betterdocsName?: string;
};
const BetterDocsConnector: React.FC<Props> = ({
  setBetterdocsUrl,
  setBetterDocsName,
  errorMessage,
  betterdocsUrl,
  betterdocsName,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  return (
    <div className='p-6'>
      <p className='text-gray-700 font-md text-md'>Title*</p>
      <div className='flex mt-1 rounded-md'>
        <input
          value={betterdocsName}
          onChange={(e) => setBetterDocsName(e.target.value)}
          type='text'
          className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-md mb-3 focus:ring-primary focus:border-primary sm:text-sm'
          placeholder={t('Name')}
        />
      </div>
      <p className='text-gray-700 font-md text-md'>Betterdocs Url*</p>
      <div className='flex mt-1 rounded-md'>
        <span className='inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm'>
          https://
        </span>
        <input
          value={betterdocsUrl}
          onChange={(e) => setBetterdocsUrl(e.target.value)}
          type='text'
          className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md focus:ring-primary focus:border-primary sm:text-sm'
          placeholder='example.com'
        />
      </div>
      {errorMessage && (
        <div className='mt-3 font-sm text-red-600'>{errorMessage}</div>
      )}
      <div className='mt-3 font-sm text-gray-500'>
        {t(
          ` Following information is required to integrate your BetterDocs account with ${partnerName}. You can view ${partnerName} documentation to learn more about this.`
        )}
      </div>
    </div>
  );
};

export default BetterDocsConnector;
