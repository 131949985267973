import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { Modal } from 'library';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
  open: boolean;
  setOpenCreateModal: (payload: boolean) => void;
  createTags: (projectId: TagsPayload) => {
    success: boolean;
    data: {
      success: boolean;
      error: string;
    };
  };
  selectedProject: SelectedTeamInterface;
  isEdit: boolean;
  setIsEdit: (payload: boolean) => void;
  editTag: (payload: {
    projectId: number;
    tagId: number | null;
    data: { name: string };
  }) => {
    success: boolean;
    data: {
      success: boolean;
      error: string;
    };
  };
  editData: { id: number | null; name: string };
  setEditData: (payload: { id: number | null; name: string }) => void;
}

interface TagsPayload {
  teamId: number;
  data: {
    name: string;
  };
}

const CreateTags: React.FC<Props> = ({
  open,
  setOpenCreateModal,
  createTags,
  selectedProject,
  isEdit,
  setIsEdit,
  editTag,
  editData,
  setEditData,
}) => {
  const { t } = useTranslation();
  const [tagName, setTagName] = React.useState(isEdit ? editData.name : '');
  const [error, setError] = React.useState<string | null>(null);

  const handleTags = async () => {
    if (isEdit) {
      const editDataPaylaod = {
        projectId: selectedProject.id,
        tagId: editData.id,
        data: {
          name: tagName,
        },
      };

      const res = await editTag(editDataPaylaod);

      if (res.success) {
        toaster.success(t('Tag edited successfully'));
        setOpenCreateModal(false);
        setEditData({ id: null, name: '' });
        setIsEdit(false);
      } else {
        setError(res.data.error);
        // setOpenCreateModal(false);
        // setEditData({ id: null, name: '' });
        // setIsEdit(false);
      }
    } else {
      const payload = {
        teamId: selectedProject.id,
        data: {
          name: tagName,
        },
      };

      const res = await createTags(payload);

      if (res.success) {
        toaster.success(t('Tag added successfully.'));
        setOpenCreateModal(false);
      } else {
        setError(res.data.error);
      }
    }
  };

  const disableHandle = () => {
    if (isEdit) {
      return editData.name !== tagName && tagName.length === 0;
    } else {
      return tagName.length < 1 ? true : false;
    }
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setIsEdit(false);
    setEditData({ id: null, name: '' });
    setError(null);
  };

  React.useEffect(() => {
    if (isEdit) {
      setTagName(editData.name);
    } else {
      setTagName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      title={t(isEdit ? 'Edit Tag' : 'Create Tag')}
      description=' '
      height='110px'
      width='w-[450px]'
      confirmText={t('Save')}
      cancelText={t('Cancel')}
      disableConfirm={disableHandle()}
      isConfirmButtonLoading={false}
      handleClose={() => handleClose()}
      handleConfirm={() => handleTags()}
      handleCancel={() => handleClose()}
    >
      <div className='p-4'>
        <div className='relative mt-1 mb-2 rounded-md shadow-sm'>
          <input
            type='text'
            maxLength={80}
            name='tag'
            id='tag'
            autoComplete='off'
            className={`block w-full rounded-md
            pr-[4.5rem] text-gray-500 placeholder-gray-400
            focus:outline-none  sm:text-sm ${
              error
                ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
            }`}
            placeholder={t('Enter a tag name e.g. refund')}
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value);
              setError(null);
            }}
          />
          <div className='absolute flex items-center px-2 py-1 text-green-800 bg-green-100 rounded-md pointer-events-none top-2 right-2'>
            {tagName.length}/80
          </div>
        </div>
        <p className='text-red-500 text-[14px] font-[400]'>{error && error}</p>
      </div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  createTags: (payload: TagsPayload) => dispatch.settings.createTags(payload),
  editTag: (payload: {
    projectId: number;
    tagId: number | null;
    data: { name: string };
  }) => dispatch.settings.editTag(payload),
});

export default connect(mapState, mapDispatch)(CreateTags);
