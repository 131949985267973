import React from 'react';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import BillingSummary from './component/BillingSummary';
import PaymentModal from './component/payment/PaymentModal';
import { connect } from 'react-redux';
import {
  couponResponse,
  subscriptionDetailsType,
  availablePlansProperty,
  subscriptionUpdateData,
  billingInfoType,
  subscriptionPlanUpdateType,
} from './interface';
import PaymentCycle from './component/payment/PaymentCycle';
import { getQueryParameters } from '../../utilities/utils';
import RemoveTeamMemberModal from './component/RemoveTeamMemberModal';
import TeamUpdateModal from './component/TeamUpdateModal';
import BillingCycleUpdateModal from './component/BillingCycleUpdateModal';
import { plansProperty } from '../../utilities/content';
import { UserPropertyType } from 'index';
import PlanAddonCard from './component/PlanAddonCard';
import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';

interface upgradeType {
  fetchPaymentDetails: () => void;
  subscriptionDetails: subscriptionDetailsType;
  isUpdateTeamMember: boolean;
  updateTeamMemberStatus: (payload: boolean) => void;
  availablePlans: availablePlansProperty[];
  updateTeamMemberCount: (value: number) => void;
  initiateSubscriptionPlanUpdate: (payload: {
    teamId: number;
    data: subscriptionUpdateData;
  }) => void;
  selectedProject: SelectedTeamInterface;
  isBillingAnnually: boolean;
  teamMemberCount: number;
  updateIsBillingAnnually: (payload: boolean) => void;
  fetchSubscriptionDetails: (teamId: number) => void;
  billingInfo: billingInfoType;
  subscriptionPlanUpdate: subscriptionPlanUpdateType;
  fetchAvailablePlans: () => void;
  fetchHistories: (teamId: number) => void;
  fetchEstimation: (payload: number) => void;
  createSubscription: (payload: any) => {
    status: boolean;
    marketPlace: string;
  };
  cardInfo: cardType[];
  paymentMethod: string;
  shopifyBilling: (payload: any) => {
    status: boolean;
    marketPlace: string;
    confirmation_url: string;
  };
  auth: UserPropertyType;
}

interface cardType {
  brand: string;
  last4: string;
  pm_id: string;
  country: string;
  default: boolean;
  exp_year: number;
  exp_month: number;
}

const UpgradeBilling: React.FC<upgradeType> = ({
  fetchPaymentDetails,
  subscriptionDetails,
  availablePlans,
  updateTeamMemberCount,
  initiateSubscriptionPlanUpdate,
  selectedProject,
  isBillingAnnually,
  teamMemberCount,
  updateIsBillingAnnually,
  fetchSubscriptionDetails,
  billingInfo,
  subscriptionPlanUpdate,
  cardInfo,
  paymentMethod,
  createSubscription,
  fetchAvailablePlans,
  fetchHistories,
  fetchEstimation,
  shopifyBilling,
  auth,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const [showPaymentForm, setShowPaymentForm] = React.useState(false);
  const [isPromoApplied, setIsPromoApplied] = React.useState(false);
  const [couponProperty, setCouponProperty] =
    React.useState<couponResponse | null>(null);
  const planType = getQueryParameters('plan_type');
  const [disableCheckout, setDisableCheckout] = React.useState(false);
  const [teamMemberRemoveCondition, setTeamMemberRemoveCondition] =
    React.useState(false);
  const [showTeamUpdateModal, setShowTeamUpdateModal] = React.useState(false);
  const [teamUpdateStep, setTeamUpdateState] = React.useState(1);
  const [showBillingCycleUpdateModal, setShowBillingCycleUpdateModal] =
    React.useState(false);
  const [billingCycleUpdateStep, setBillingCycleUpdateStep] = React.useState(1);
  const [paymentModalStep, setPaymentModalStep] = React.useState(1);
  const projectBillingInfo = subscriptionDetails?.project_billing_info;

  const getPlanName = () => {
    if (
      [
        plansProperty.premium_plan.name,
        plansProperty.business_plan.name,
      ].includes(planType)
    ) {
      return planType;
    } else {
      return projectBillingInfo?.subscription_plan?.name;
    }
  };

  const initiateBilling = () => {
    const data = {
      plan: getPlanName(),
      team_members:
        planType === plansProperty.business_plan.name
          ? Math.max(
              projectBillingInfo?.max_team_members,
              plansProperty.business_plan.min_team_member
            )
          : planType === plansProperty.premium_plan.name
          ? Math.max(
              projectBillingInfo?.max_team_members,
              plansProperty.premium_plan.min_team_member
            )
          : projectBillingInfo?.max_team_members,
      is_billed_annually: isBillingAnnually || false,
    };
    const payload = {
      teamId: selectedProject.id,
      data: data,
    };
    initiateSubscriptionPlanUpdate(payload);
  };

  React.useEffect(() => {
    fetchPaymentDetails();
    fetchSubscriptionDetails(selectedProject.id);

    if (planType === 'update-team' || planType === 'billing-cycle') {
      updateTeamMemberCount(projectBillingInfo?.max_team_members);
    } else if (planType === plansProperty.business_plan.name) {
      updateTeamMemberCount(
        Math.max(
          projectBillingInfo?.max_team_members,
          plansProperty.business_plan.min_team_member
        )
      );
    } else {
      updateTeamMemberCount(
        Math.max(
          projectBillingInfo?.max_team_members,
          plansProperty.premium_plan.min_team_member
        )
      );
    }

    if (planType === 'billing-cycle') {
      const cycle = getQueryParameters('cycle');
      if (cycle === 'yearly') {
        updateIsBillingAnnually(true);
        initiateBilling();
      } else {
        updateIsBillingAnnually(false);
        initiateBilling();
      }
    } else if (planType === 'update-team') {
      setDisableCheckout(true);
      updateIsBillingAnnually(
        projectBillingInfo?.subscription_plan?.billing_cycle === 'yearly'
      );
      initiateBilling();
    } else {
      initiateBilling();
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (getQueryParameters('plan_type') === 'update-team') {
      if (subscriptionPlanUpdate.change_in_team_size === 0) {
        setDisableCheckout(true);
      }
    }
  }, [subscriptionPlanUpdate]);

  const breadCrumb = (page: string) => {
    switch (page) {
      case 'update-team':
        return 'Update Team Size';
      case 'billing-cycle':
        return 'Change Billing Cycle';
      case 'business':
        return 'Upgrade to Business Plan';
      case 'premium':
      default:
        return 'Upgrade to Premium Plan';
    }
  };

  const handleSubscription = async () => {
    if (
      selectedProject.has_ecommerce_connected &&
      selectedProject.ecommerce_type === 'shopify' &&
      (cardInfo.length === 0 ||
        subscriptionDetails?.project_billing_info.payment_handler === 'shopify')
    ) {
      if (planType === 'update-team') {
        setShowTeamUpdateModal(true);
        setTeamUpdateState(1);
      } else if (planType === 'billing-cycle') {
        setShowBillingCycleUpdateModal(true);
        setBillingCycleUpdateStep(1);
      } else {
        setShowPaymentForm(true);
        setPaymentModalStep(2);
      }

      const subscriptionPayloadShopify = {
        action: 'upgrade',
        plan: getPlanName(),
        is_billed_annually: isBillingAnnually,
        team_members: teamMemberCount,
        project_id: selectedProject.id,
        total_cost: subscriptionPlanUpdate.total_cost,
        //  discount_amount: null,
      };

      const res = await shopifyBilling(subscriptionPayloadShopify);
      if (res.status) {
        setTimeout(() => {
          if (planType === 'update-team') {
            setTeamUpdateState(2);
          } else if (planType === 'billing-cycle') {
            setBillingCycleUpdateStep(2);
          } else {
            setPaymentModalStep(3);
          }
        }, 5000);
      } else {
        setTimeout(() => {
          if (planType === 'update-team') {
            setTeamUpdateState(3);
          } else if (planType === 'billing-cycle') {
            setBillingCycleUpdateStep(3);
          } else {
            setShowPaymentForm(false);
            toaster.danger('Something went wrong during subscription', {
              duration: 3,
            });
          }
        }, 5000);
      }
    } else {
      if (planType === 'update-team') {
        setShowTeamUpdateModal(true);
        setTeamUpdateState(1);
      } else if (planType === 'billing-cycle') {
        setShowBillingCycleUpdateModal(true);
        setBillingCycleUpdateStep(1);
      } else {
        setShowPaymentForm(true);
        setPaymentModalStep(2);
      }

      const subscriptionPayload = {
        action: 'upgrade',
        plan: getPlanName(),
        is_billed_annually: isBillingAnnually,
        team_members: teamMemberCount,
        project_id: selectedProject.id,
        total_cost: subscriptionPlanUpdate.total_cost,
        payment_method: paymentMethod,
        coupon_code: isPromoApplied ? couponProperty?.name : null,
      };

      const response = await createSubscription(subscriptionPayload);

      if (response.status) {
        fetchSubscriptionDetails(selectedProject.id);
        fetchAvailablePlans();
        fetchPaymentDetails();
        fetchHistories(selectedProject.id);
        fetchEstimation(selectedProject.id);
        setTimeout(() => {
          if (planType === 'update-team') {
            setTeamUpdateState(2);
          } else if (planType === 'billing-cycle') {
            setBillingCycleUpdateStep(2);
          } else {
            setPaymentModalStep(3);
          }
        }, 5000);
      } else {
        setTimeout(() => {
          if (planType === 'update-team') {
            setTeamUpdateState(3);
          } else if (planType === 'billing-cycle') {
            setBillingCycleUpdateStep(3);
          } else {
            setShowPaymentForm(false);
            toaster.danger('Something went wrong during subscription', {
              duration: 3,
            });
          }
        }, 5000);
      }
    }
  };

  return (
    <>
      <div className='mt-5'>
        <div className='flex align-center mb-3'>
          <a href='/settings/billing'>
            {isRtlLanguage ? (
              <ArrowLongRightIcon className='w-10 h-5 cursor-pointer' />
            ) : (
              <ArrowLongLeftIcon className='w-10 h-5 cursor-pointer' />
            )}
          </a>
          <p className='text-lg text-gray-900 font-medium'>
            {t(breadCrumb(planType))}
          </p>
        </div>
        <div className='flex gap-5'>
          <div className='w-[25%]'>
            {planType === 'billing-cycle' ? (
              <PaymentCycle />
            ) : (
              <PlanAddonCard
                plan={
                  availablePlans.filter(
                    (plan) => plan.name === getPlanName()
                  )[0]
                }
                setDisableCheckout={setDisableCheckout}
                setTeamMemberRemoveCondition={setTeamMemberRemoveCondition}
              />
            )}
          </div>
          <div className='w-[75%]'>
            <BillingSummary
              setShowPaymentForm={setShowPaymentForm}
              isPromoApplied={isPromoApplied}
              setIsPromoApplied={setIsPromoApplied}
              couponProperty={couponProperty}
              setCouponProperty={setCouponProperty}
              disableCheckout={disableCheckout}
              setDisableCheckout={setDisableCheckout}
              handleSubscription={handleSubscription}
              subscriptionDetails={subscriptionDetails}
              initiateSubscriptionPlanUpdate={initiateSubscriptionPlanUpdate}
            />
          </div>
        </div>
      </div>
      {showPaymentForm && (
        <PaymentModal
          setShowPaymentForm={setShowPaymentForm}
          isPromoApplied={isPromoApplied}
          couponProperty={couponProperty}
          billingInfo={billingInfo}
          disableCheckout={disableCheckout}
          setDisableCheckout={setDisableCheckout}
          planType={planType}
          paymentModalStep={paymentModalStep}
          setPaymentModalStep={setPaymentModalStep}
        />
      )}
      {teamMemberRemoveCondition && (
        <RemoveTeamMemberModal
          setTeamMemberRemoveCondition={setTeamMemberRemoveCondition}
        />
      )}
      {showTeamUpdateModal && (
        <TeamUpdateModal
          step={teamUpdateStep}
          setShowTeamUpdateModal={setShowTeamUpdateModal}
          handleSubscription={handleSubscription}
        />
      )}
      {showBillingCycleUpdateModal && (
        <BillingCycleUpdateModal
          step={billingCycleUpdateStep}
          setShowBillingCycleUpdateModal={setShowBillingCycleUpdateModal}
          handleSubscription={handleSubscription}
          billingCycle={isBillingAnnually}
        />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  subscriptionDetails: state.newBilling.subscriptionDetails,
  isUpdateTeamMember: state.newBilling.isUpdateTeamMember,
  availablePlans: state.newBilling.availablePlans,
  selectedProject: state.dashboard.selectedProject,
  isBillingAnnually: state.newBilling.isBillingAnnually,
  teamMemberCount: state.newBilling.teamMemberCount,
  billingInfo: state.newBilling.billingInfo,
  subscriptionPlanUpdate: state.newBilling.subscriptionPlanUpdate,
  cardInfo: state.newBilling.cardInfo,
  paymentMethod: state.newBilling.paymentMethod,
  auth: state.auth,
});
const mapDispatch = (dispatch: any) => ({
  fetchPaymentDetails: () => dispatch.newBilling.fetchPaymentDetails(),

  updateTeamMemberCount: dispatch.newBilling.updateTeamMemberCount,
  initiateSubscriptionPlanUpdate: (payload: any) =>
    dispatch.newBilling.initiateSubscriptionPlanUpdate(payload),
  updateIsBillingAnnually: (payload: boolean) =>
    dispatch.newBilling.updateIsBillingAnnually(payload),
  fetchSubscriptionDetails: (teamId: number) =>
    dispatch.newBilling.fetchSubscriptionDetails(teamId),
  fetchAvailablePlans: () => dispatch.newBilling.fetchAvailablePlans(),
  fetchHistories: (teamId: number) =>
    dispatch.newBilling.fetchHistories(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  fetchEstimation: (payload: number) =>
    dispatch.newBilling.fetchEstimatedCost(payload),
  createSubscription: (payload: any) =>
    dispatch.newBilling.createSubscription(payload),
  shopifyBilling: (payload: any) =>
    dispatch.newBilling.createSubscriptionForShopify(payload),
});

export default connect(mapState, mapDispatch)(UpgradeBilling);
