import React from 'react';
import Linkify from 'react-linkify';

import Avatar from '../components/conversation/Avatar';
import DateBar from './DateBar';

import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  ButtonDataType,
  ConversationBlockQuickReplyDataInterface,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';

interface Props {
  blockData: ConversationBlockQuickReplyDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
}

const QuickReplyBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
}) => {
  const { t } = useTranslation();
  const { rowDirection, elementDirection, isZigzagView } =
    useMessageBlocksAction({ source });
  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderQuickReplyTextView = () => {
    return (
      <p
        className={`w-auto h-auto text-gray-900 break-words whitespace-pre-wrap sm:text-sm`}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {blockData.success ? blockData?.title : blockData.error}
        </Linkify>
      </p>
    );
  };

  const getButtons = (button: ButtonDataType) => {
    return (
      <button
        key={button?.id}
        className='border w-auto h-8 py-1.5 px-2 bg-[#FAFAFA]
      content-center cursor-not-allowed hover:bg-gray-100 rounded-lg truncate
      text-xs font-medium	text-gray-900'
      >
        {button?.title}
      </button>
    );
  };

  const buttons = blockData?.buttons?.filter((button) => !!button?.title) || [];
  const gridColsClass = cn({
    'grid-cols-4': buttons.length >= 4,
    'grid-cols-3': buttons.length === 3,
    'grid-cols-2': buttons.length === 2,
    'grid-cols-1': buttons.length === 1,
  });

  return (
    <>
      {!isMergeable && (
        <div
          className={`flex w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9] ${rowDirection}`}
        >
          <Avatar avatar={avatar} />
          <div data-testid='quick-reply-block' className='max-w-[60%]'>
            <div className={`flex gap-2 ${rowDirection}`}>
              <div
                className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {t(name)}
              </div>
            </div>
            {/* Quick Reply Block */}
            <div className={`flex ${elementDirection} gap-0.5`}>
              <ConversationBlockWrapper
                time={time}
                success={isSent}
                isMerged={false}
                report={report}
                source={source}
                isFirstElement={!isMergeable}
                conversationType={blockData?.type}
              >
                {!!blockData.title && renderQuickReplyTextView()}
              </ConversationBlockWrapper>
              <div
                className={`flex w-auto ${rowDirection} ${
                  isZigzagView
                    ? 'ltr:justify-end rtl:justify-start'
                    : 'justify-start'
                } `}
              >
                <div
                  className={`grid content-center gap-1.5 pt-1 ${gridColsClass}`}
                >
                  {buttons.map((eachButton) => getButtons(eachButton))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!!isMergeable && (
        <div
          className={`flex w-full h-auto pt-0.5 px-16 pb-1.5 hover:bg-[#F8F8F9] ${elementDirection}`}
        >
          {/* Quick Reply Block */}
          <div className={`max-w-[60%] flex ${elementDirection}`}>
            <ConversationBlockWrapper
              time={time}
              success={isSent}
              isMerged={false}
              report={report}
              source={source}
              isFirstElement={!isMergeable}
              conversationType={blockData?.type}
            >
              {!!blockData.title && renderQuickReplyTextView()}
            </ConversationBlockWrapper>
            <div
              className={`flex w-auto ${rowDirection} ${
                isZigzagView
                  ? 'ltr:justify-end rtl:justify-start'
                  : 'justify-start'
              }`}
            >
              <div
                className={`grid content-center gap-1.5 pt-1 ${gridColsClass}`}
              >
                {!!blockData?.buttons &&
                  blockData.buttons
                    .filter((button) => !!button?.title)
                    .map((eachButton) => getButtons(eachButton))}
              </div>
            </div>
          </div>
        </div>
      )}
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default QuickReplyBlock;
