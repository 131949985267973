import React from 'react';
import RangeSlider from '../components/RangeSlider';
import { createNlpRequestProps } from '../../../interface';
import { XCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';

interface Props {
  success?: boolean;
  errorMessage?: string;
  nlpInfo: createNlpRequestProps;
  handleChange: (key: string, value: string | number) => void;
}

const IntntSetting: React.FC<Props> = ({
  success = true,
  errorMessage = 'Something went wrong while creating NLP channle. Try again',
  nlpInfo,
  handleChange,
}) => {
  const { t } = useTranslation();
  const { currentPartnerInfo } = usePartner();
  const partnerName = currentPartnerInfo?.shortName ?? 'MyAlice';
  return (
    <>
      {!success && (
        <div className='flex justify-center gap-2 p-6'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='text-sm text-red-600'>{t(errorMessage ?? '')}</p>
        </div>
      )}
      <div className='p-6'>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-700'
          >
            {t('Title')}*
          </label>
          <input
            type='text'
            placeholder='My intnt app'
            value={nlpInfo?.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='name'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
        </div>
      </div>
      <div className='border-t'>
        <label
          htmlFor='nlp_confidence'
          className='block mt-6 ltr:ml-6 rtl:mr-6 text-sm font-medium text-gray-700'
        >
          {t(`NLP Response With Confidence.`)} {nlpInfo.confidence}
        </label>
        <RangeSlider
          min={0}
          max={1}
          step={0.1}
          currentValue={nlpInfo.confidence}
          defaultValue={0}
          handleChange={(value) => handleChange('confidence', value)}
        />
        <p className='px-6 mt-2 text-sm text-gray-500'>
          {t(
            `Minimum confidence value for ${partnerName} Automation to respond to the message. Ideally you want to keep the confidence >0.9 if you have low amount of training data. If you have high amount of training data, we recommend you to keep it in between 0.7-0.9.`
          )}
        </p>
      </div>
    </>
  );
};
export default IntntSetting;
