import React from 'react';

export const useSourceSelection = (
  initialSelectedIds: number[],
  onSourceChange: (ids: number[]) => void
) => {
  const [selectedIds, setSelectedIds] = React.useState<number[]>(
    initialSelectedIds || []
  );

  React.useEffect(() => {
    setSelectedIds(initialSelectedIds || []);
  }, [initialSelectedIds]);

  const toggleSource = React.useCallback(
    (sourceId: number) => {
      const newSelectedIds = (selectedIds || []).includes(sourceId)
        ? (selectedIds || []).filter((id) => id !== sourceId)
        : [...(selectedIds || []), sourceId];

      setSelectedIds(newSelectedIds);
      onSourceChange(newSelectedIds);
    },
    [selectedIds, onSourceChange]
  );

  return {
    selectedIds: selectedIds || [],
    toggleSource,
  };
};
