import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Badge } from 'libraryV2/ui/badge';
import { XCircleIcon } from 'lucide-react';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';

interface VariableBadgeProps {
  staticTxt?: string;
  selectedVariable: string | null;
  removeVariable: () => void;
}

const VariableBadge: React.FC<VariableBadgeProps> = ({
  selectedVariable,
  removeVariable,
  staticTxt = '',
}) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  useEffect(() => {
    if (textRef.current) {
      const isTextTruncated =
        textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsTruncated(isTextTruncated);
    }
  }, [selectedVariable]);

  const renderUrlView = useCallback(
    () => (
      <TooltipProviderCustomised content={staticTxt} side='top'>
        <span className='text-xs font-semibold text-gray-700 w-20 mt-1 inline-block truncate mr-1'>
          {staticTxt}
        </span>
      </TooltipProviderCustomised>
    ),
    [staticTxt]
  );

  const handleRemoveClick = useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation();
      removeVariable();
    },
    [removeVariable]
  );

  return (
    <div className='w-full flex justify-start items-center'>
      {!!staticTxt && renderUrlView()}

      {selectedVariable ? (
        <TooltipProviderCustomised
          content={isTruncated ? `{{${selectedVariable}}}` : ''}
          side='top'
        >
          <Badge className='bg-indigo-50 text-indigo-400 hover:bg-indigo-100 py-0 z-50 max-w-[70%] mr-auto flex'>
            <span ref={textRef} className='max-w-full truncate inline-block'>
              {`{{${selectedVariable}}}`}
            </span>

            <XCircleIcon
              className={cn(
                'inline-block ml-2 z-50',
                isTruncated ? 'h-5 w-5' : 'h-4 w-4'
              )}
              onClick={handleRemoveClick}
            />
          </Badge>
        </TooltipProviderCustomised>
      ) : (
        'Select variables'
      )}
    </div>
  );
};

export default VariableBadge;
