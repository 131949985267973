import { FileText, PlayCircleIcon } from 'lucide-react';
import {
  ISavedReplyApiResponse,
  PdfFileIcon,
} from 'pages/settings/savedReplies/utils';
import React from 'react';

interface Props {
  savedReply: ISavedReplyApiResponse;
  platformWarningMessage: string;
}

const SavedReplyPreview: React.FC<Props> = ({
  savedReply,
  platformWarningMessage,
}) => {
  const checkVariables = (value: string) => {
    if (value.includes('|')) {
      return value.slice(0, value.indexOf('|')).trim();
    }
    return value;
  };

  const renderTemplateBody = (text: string) => {
    const textSplite = text.split(/[,.\s]/);
    const bodyText = textSplite.map((item: any) => {
      if (item.startsWith('{{') && item.endsWith('}}')) {
        return ` <span id=${checkVariables(item.slice(2, -2))}
       class='px-1 py-1 bg-green-100 rounded-md text-green-800'>
            ${checkVariables(item.slice(2, -2))}</span>`;
      } else {
        return ` ${item}`;
      }
    });
    return (
      <p
        className={`text-gray-500 break-words font-normal
         text-[14px] leading-7 mb-2`}
        dangerouslySetInnerHTML={{ __html: bodyText.join(' ') }}
      />
    );
  };

  const renderSingleAttachmentView = (
    attachment: ISavedReplyApiResponse['attachments'][0]
  ) => {
    switch (attachment.type) {
      case 'image': {
        return (
          <div
            key={attachment.id}
            className='h-24 w-24 cursor-pointer rounded-md border border-zinc-200'
          >
            <img
              src={attachment.url}
              className='h-full w-full rounded-md object-cover'
              alt={attachment.name}
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div
            key={attachment.id}
            className='h-24 relative w-24 grid rounded-lg border border-zinc-200'
          >
            <div className='relative cursor-pointer  w-full mx-auto'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-full w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md max-h-24 w-full'>
                <source src={attachment.url} />
              </video>
            </div>
          </div>
        );
      }
      case 'file': {
        return (
          <div
            key={attachment.id}
            className='h-24 group relative w-24 grid rounded-lg border border-zinc-200'
          >
            <div
              key={attachment.id}
              className='h-24 w-24 justify-center items-center flex-col flex cursor-pointer rounded-md'
            >
              {attachment.name.endsWith('.pdf') ? (
                <PdfFileIcon />
              ) : (
                <FileText className='w-20 h-20 text-zinc-900' />
              )}
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className='absolute top-0 ltr:right-[-285px]  max-h-[280px] rtl:left-[-285px] w-[270px]'>
      <div className='relative  max-h-[280px] w-[270px] overflow-y-auto h-auto bg-white rounded-md border border-gray-200 p-2'>
        {platformWarningMessage && (
          <p className='text-blue-600 sticky -top-2 text-xss whitespace-pre-line leading-4 bg-white py-2 z-10'>
            {platformWarningMessage}
          </p>
        )}
        <div className='whitespace-pre-line break-words'>
          {renderTemplateBody(savedReply?.text)}
        </div>
        <div className='w-full flex flex-wrap gap-2'>
          {savedReply?.attachments.map((v) => renderSingleAttachmentView(v))}
        </div>
      </div>
    </div>
  );
};

export default SavedReplyPreview;
