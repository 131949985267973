import React from 'react';
import { CopyBlock, solarizedLight } from 'react-code-blocks';
import { applicationResponseDataProps } from '../../../interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  platfromData: applicationResponseDataProps;
}

const PluginScript: React.FC<Props> = ({ platfromData }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='p-6'>
        <p className='text-sm text-gray-500'>
          {t(
            'Copy this code and put this code snippet inside script tag in the footer.'
          )}
        </p>
      </div>
      <div className='p-6 border-t'>
        <CopyBlock
          text={`platformId: '${platfromData?.id || ''}',
primaryId: '${platfromData?.primary_id || ''}',
token: '${platfromData?.access_token || ''}'`}
          theme={solarizedLight}
          codeBlock
        />
      </div>
    </div>
  );
};
export default PluginScript;
