import React from 'react';
import { Avatar, Tooltip } from 'evergreen-ui';
import {
  fetchPriorityIcon,
  getUpdatedPlatformIconOutlined,
} from '../../../../utilities/utils';
import defaultAvatar from '../../../../assets/images/defaultAvatar.svg';
import lockIcon from '../../../assets/images/convo_lock.svg';
import unlockIcon from '../../../assets/images/convo_unlock.svg';
import dayJs from 'dayjs';
import { getPlatformInfoTitle } from '../../../../utilities/utils';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayJs.extend(relativeTime);
dayJs.extend(updateLocale);

dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Right Now',
    m: 'Right Now',
    mm: '%d min ago',
    h: 'an hour ago',
    hh: '%d hr',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

interface props {
  selectedProject: { id: number };
  id: number;
  currentTicket: any;
  handleCurrentTicket: () => void;
  customerData: any;
  agents: any;
  locked: boolean;
  priority: string;
  cardText: string;
  cardTimestamp: any;
  ticketPlatform: any;
  groups: any;
  isReplied: boolean;
}

const TicketCard: React.FC<props> = ({
  selectedProject,
  id,
  currentTicket,
  handleCurrentTicket,
  customerData,
  agents,
  locked,
  priority,
  cardText,
  cardTimestamp,
  ticketPlatform,
  groups,
  isReplied,
}) => {
  const excludedProjectId = 1226;
  return (
    <div
      className={
        id === currentTicket?.id
          ? 'ticket-card ticket-card__active'
          : 'ticket-card'
      }
      onClick={() => handleCurrentTicket()}
    >
      <div className='flex justify-between'>
        <div className='ticket-card__content-left-info'>
          <div className='ticket-card__content__avatar-container'>
            <Avatar
              className='border-tint3-1px'
              src={!!customerData.avatar ? customerData.avatar : defaultAvatar}
              name={
                !!customerData.full_name
                  ? customerData.full_name
                  : 'Anonymous User'
              }
              size={40}
              marginRight={10}
            />
            {agents.length > 0 && (
              <Tooltip content={`${agents[0].full_name}`}>
                <Avatar
                  name={agents[0].full_name}
                  src={agents[0].avatar}
                  size={26}
                  className='ticket-card__content__avatar-container-platform'
                />
              </Tooltip>
            )}
            {groups.length > 0 && (
              <Tooltip content={`${groups[0].name}`}>
                <Avatar
                  name={groups[0].name}
                  size={26}
                  className='ticket-card__content__avatar-container-platform'
                />
              </Tooltip>
            )}
          </div>
          <div className='ticket-card__content-left-info__user-info'>
            <p
              className={`ticket-card__content-left-info-title ${
                isReplied ? '' : 'font-bold'
              }`}
            >
              {customerData.full_name.length > 15
                ? customerData.full_name.slice(0, 15) + '...'
                : customerData.full_name || 'Anonymous'}
            </p>
            {selectedProject.id !== excludedProjectId && (
              <p
                className={`ticket-card__content-left-info-subtitle ${
                  isReplied ? '' : 'font-medium'
                }`}
              >
                {cardText.length > 20 ? cardText.slice(0, 20) + '...' : cardText}
              </p>
            )}
            <div className='ticket-card__content-left-info__platform-info'>
              <img
                src={getUpdatedPlatformIconOutlined(
                  ticketPlatform?.type || 'facebook_messenger'
                )}
                width={12}
                height={12}
                loading='lazy'
                alt='channel'
              />
              &nbsp;
              <p className='ticket-card__content-left-info-subtitle'>
                {getPlatformInfoTitle(ticketPlatform).length > 15
                  ? getPlatformInfoTitle(ticketPlatform).slice(0, 15) + '...'
                  : getPlatformInfoTitle(ticketPlatform)}
              </p>
            </div>
          </div>
        </div>
        <div className='ticket-card__content-right-info'>
          {!!cardTimestamp && (
            <p className='ticket-card__content-right-info__time'>
              {dayJs(cardTimestamp * 1000).fromNow(true)}
            </p>
          )}
          <div className='ticket-card__content-right-info__flags'>
            {locked ? (
              <img src={lockIcon} width={16} alt={'lock conversation'} />
            ) : (
              <img src={unlockIcon} width={16} alt={'unlock conversation'} />
            )}
            <img
              src={fetchPriorityIcon(priority.toLowerCase())}
              alt='priority'
              width={16}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
