import React from 'react';
import { connect } from 'react-redux';
import CustomerInformation from './CustomerInformation';
import OrderSummary from './OrderSummary';
import ConfigShipping from './ConfigShipping';
import {
  SallaAPIErrorInterface,
  ICustomerCartInfo,
  ResponseModalDataInterface,
  OrderFormInformationType,
} from 'pages/inbox/inboxInterface';
import { getCurrentOrderFormInformationValue } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';
import { Badge } from 'libraryV2/ui/badge';
import { Button } from 'libraryV2/ui/button';
import { XIcon } from 'lucide-react';

interface PaymentMethodProps {
  description: string;
  is_enabled: boolean;
  method_id: string;
  method_title: string;
}

interface ShippingMethodProps {
  method_id: string;
  method_title: string;
}

interface Props {
  projectId: number;
  currencySymbol: string;
  customerCart: ICustomerCartInfo;
  responseModalData: ResponseModalDataInterface;
  orderFormInformation: OrderFormInformationType;
  sendCheckoutLink: (data: string) => void;
  setShowRightbar: (value: boolean) => void;
  setOpenResponseModal: (data: boolean) => void;
  setShowResponseModalLoader: (data: boolean) => void;
  updateEcommerceStateData: (key: string, value: any) => void;
  fetchSallaCoupons: (projectId: number) => Promise<void>;
  setResponseModalData: (data: ResponseModalDataInterface) => void;
  fetchPaymentMethods: (
    projectId: number
  ) => Promise<PaymentMethodProps[] | SallaAPIErrorInterface>;
  fetchShippingMethods: (
    projectId: number
  ) => Promise<ShippingMethodProps[] | SallaAPIErrorInterface>;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: any;
  }) => void;
}

const SallaOrderCreation: React.FC<Props> = ({
  projectId,
  customerCart,
  currencySymbol,
  responseModalData,
  orderFormInformation,
  setShowRightbar,
  sendCheckoutLink,
  fetchPaymentMethods,
  fetchShippingMethods,
  setOpenResponseModal,
  setResponseModalData,
  fetchSallaCoupons,
  updateEcommerceStateData,
  setShowResponseModalLoader,
  updateOrderInformationState,
}) => {
  const { t } = useTranslation();
  const initialOrderInformation = {
    paymentMethod: '',
    paymentStatus: '',
    deliveryType: '',
    couponOrDiscount: '',
    sallaSubTotal: 0,
    sallaDiscount: 0,
    sallaShippingCost: 0,
    sallaTotal: 0,
  };

  const initialSallaOrderInformation = {
    customer_id: customerCart?.customer_id,
    billing_address: customerCart?.billing_address,
    shipping_address: customerCart?.shipping_address,
    SallaOrderInformation: initialOrderInformation,
    sameAsShippingAddress: true,
  };
  const [step, setStep] = React.useState(0);
  const [SallaOrderFormInformation, setSallaOrderInformation] =
    React.useState<OrderFormInformationType>(initialSallaOrderInformation);
  const initialCustomerBillingInformation = customerCart?.billing_address;

  const handleSetSallaObjectInformation = (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => {
    let currentOrderInformation = getCurrentOrderFormInformationValue(
      orderFormInformation,
      customerCart?.customer_id
    );

    let updatedData = {
      ...currentOrderInformation[data.objectKeyName],
      [data.childObjectKeyName]: data.value,
    };

    if (
      data.objectKeyName === 'shipping_address' &&
      SallaOrderFormInformation.sameAsShippingAddress
    ) {
      handleSetSallaPropertyInformation([
        {
          key: 'shipping_address',
          value: updatedData,
        },
        {
          key: 'billing_address',
          value: updatedData,
        },
      ]);
    } else {
      updateOrderInformationState({
        key: customerCart.customer_id,
        data: {
          ...currentOrderInformation,
          [data.objectKeyName]: updatedData,
        },
      });

      setSallaOrderInformation({
        ...currentOrderInformation,
        [data.objectKeyName]: updatedData,
      });
    }
  };

  // updateEcommerceStateData('orderFormInformation', {});

  const handleSetSallaPropertyInformation = (
    data: {
      key: string;
      value: any;
    }[]
  ) => {
    let updatedData = SallaOrderFormInformation;
    data.forEach((item) => {
      updatedData[item.key] = item.value;
    });

    updateOrderInformationState({
      key: customerCart.customer_id,
      data: updatedData,
    });

    setSallaOrderInformation(updatedData);
  };

  const fetchOrderMethods = async () => {
    await fetchPaymentMethods(projectId);
    await fetchShippingMethods(projectId);
    await fetchSallaCoupons(projectId);
  };

  React.useEffect(() => {
    fetchOrderMethods();

    if (!orderFormInformation?.hasOwnProperty(customerCart?.customer_id)) {
      let data: OrderFormInformationType = {
        ...customerCart,
      };
      data.sameAsShippingAddress = true;
      data.SallaOrderInformation = initialOrderInformation;

      updateOrderInformationState({
        key: customerCart.customer_id,
        data: data,
      });
    } else {
      const orderInformationObj = getCurrentOrderFormInformationValue(
        orderFormInformation,
        customerCart?.customer_id
      );

      if (
        !!orderInformationObj &&
        !!orderInformationObj?.shipping_address?.city
      ) {
        orderInformationObj.shipping_address.city = '';
      }

      if (
        !!orderInformationObj &&
        !!orderInformationObj?.billing_address?.city
      ) {
        orderInformationObj.billing_address.city = '';
      }

      setSallaOrderInformation(orderInformationObj);
    }
    // eslint-disable-next-line
  }, []);

  const renderHeaderView = (title: string, step: string) => {
    return (
      <>
        <div className='flex justify-between items-center px-4 py-[13px] border-b border-gray-200'>
          <div className='flex items-center gap-2'>
            <span className='text-base font-semibold leading-5 text-gray-800'>
              {title}
            </span>
            <Badge variant={'outline'}>{step}</Badge>
          </div>
          <Button
            variant={'ghost'}
            onClick={() => {
              updateEcommerceStateData('showDiscardAlert', true);
            }}
          >
            <XIcon className='w-5 h-5 text-gray-800' />
          </Button>
        </div>
      </>
    );
  };

  const renderModalHeaderView = (step: number) => {
    switch (step) {
      case 2:
        return renderHeaderView(t('Create Order'), '2/3');
      case 3:
        return renderHeaderView(t('Order Summary'), '3/3');
      default:
        return renderHeaderView(t('Create Order'), '1/3');
    }
  };

  const renderSallaOrderAmountsView = (
    step: number,
    orderInformation = SallaOrderFormInformation.SallaOrderInformation
  ) => {
    let discountTextColor = !!orderInformation.SallaDiscount
      ? 'text-[#DC2626]'
      : 'text-gray-900';

    return (
      <div
        className={`${
          step === 3
            ? 'border border-transparent border-t-gray-200 py-3'
            : 'pb-3'
        } text-xs leading-5 flex flex-col gap-2 text-gray-500`}
      >
        <p className='flex space-between'>
          <span>{t('Subtotal')}</span>
          <span className='font-xs leading-4 text-gray-900'>
            {currencySymbol}
            {orderInformation.sallaSubTotal
              ? orderInformation.sallaSubTotal
              : 0}
          </span>
        </p>
        <p className='flex space-between'>
          <span>Shipping</span>
          <span className='font-xs leading-4 text-gray-900'>
            {currencySymbol}
            {orderInformation.sallaShippingCost
              ? orderInformation.sallaShippingCost
              : 0}
          </span>
        </p>
        <p className={`flex space-between ${step === 2 ? ' pb-2' : ''}`}>
          <span>{t('Total Discount')}</span>
          <span className={`${discountTextColor} font-xs leading-4`}>
            {!!orderInformation.sallaDiscount && (
              <>
                -{currencySymbol}
                {orderInformation.sallaDiscount}
              </>
            )}
            {!orderInformation?.sallaDiscount && `${currencySymbol}0`}
          </span>
        </p>
        <p
          className={`flex space-between ${
            step === 2 ? 'border border-transparent border-t-gray-200 pt-3' : ''
          }`}
        >
          <span className='text-gray-900 text-xs font-normal'>
            {t('Total')}
          </span>
          <span
            className={`text-[#038E4C] font-semibold leading-4 ${
              step === 2 ? 'text-sm' : 'text-xs'
            }`}
          >
            {currencySymbol}
            {orderInformation.sallaTotal
              ? orderInformation.sallaTotal.toFixed(2)
              : 0}
          </span>
        </p>
      </div>
    );
  };

  const showProductStep = (step: number) => {
    switch (step) {
      case 2:
        return (
          <ConfigShipping
            orderInformation={SallaOrderFormInformation?.SallaOrderInformation}
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(2)}
            renderSallaOrderAmountsView={renderSallaOrderAmountsView}
            handleSetSallaObjectInformation={handleSetSallaObjectInformation}
            handleSetSallaPropertyInformation={
              handleSetSallaPropertyInformation
            }
          />
        );
      case 3:
        return (
          <OrderSummary
            orderInformation={SallaOrderFormInformation?.SallaOrderInformation}
            billingInformation={SallaOrderFormInformation?.billing_address}
            shippingInformation={SallaOrderFormInformation?.shipping_address}
            sameAsShippingAddress={
              SallaOrderFormInformation?.sameAsShippingAddress
            }
            responseModalData={responseModalData}
            sendCheckoutLink={sendCheckoutLink}
            setOpenResponseModal={setOpenResponseModal}
            setResponseModalData={setResponseModalData}
            setShowResponseModalLoader={setShowResponseModalLoader}
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(3)}
            renderSallaOrderAmountsView={renderSallaOrderAmountsView}
          />
        );
      default:
        return (
          <CustomerInformation
            billingInformation={SallaOrderFormInformation?.billing_address}
            shippingInformation={SallaOrderFormInformation?.shipping_address}
            sameAsShippingAddress={
              SallaOrderFormInformation?.sameAsShippingAddress
            }
            initialCustomerBillingInformation={
              initialCustomerBillingInformation
            }
            setStep={setStep}
            setShowRightbar={setShowRightbar}
            modalHeader={renderModalHeaderView(1)}
            handleSetSallaObjectInformation={handleSetSallaObjectInformation}
            handleSetSallaPropertyInformation={
              handleSetSallaPropertyInformation
            }
          />
        );
    }
  };
  return (
    <div className='h-full w-[25%] ltr:border-l rtl:border-r fixed right-0 bg-white '>
      <div>{!!SallaOrderFormInformation ? showProductStep(step) : ''}</div>
    </div>
  );
};

const mapState = (state: any) => ({
  projectId: state.dashboard?.selectedProject?.id || null,
  customerCart: state.ecommerce.cart,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
  orderFormInformation: state.ecommerce.orderFormInformation,
});

const mapDispatch = (dispatch: any) => ({
  updateEcommerceStateData: (key: string, value: any) =>
    dispatch.ecommerce.updateEcommerceStateData({ key, value }),
  fetchPaymentMethods: (projectId: number) =>
    dispatch.ecommerce.fetchPaymentMethods({ projectId }),
  fetchShippingMethods: (projectId: number) =>
    dispatch.ecommerce.fetchShippingMethods({ projectId }),
  fetchSallaCoupons: (projectId: number) =>
    dispatch.ecommerce.fetchSallaCoupons({ projectId }),
  updateOrderInformationState: (payload: { key: number | string; data: any }) =>
    dispatch.ecommerce.updateOrderFormInformation(payload),
});

export default connect(mapState, mapDispatch)(SallaOrderCreation);
