import React, { Fragment, useState } from 'react';
import { toaster } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../assets/uploadIcon.svg';
import Spinner from './Spinner';
import { CSVProperty } from '../interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  file: string;
  csvFileName: string;
  handleDrop: (payload: any) => void;
  handleClear: () => void;
  setCsvFileName: (data: string) => void;
  fileUploadError: string | CSVProperty;
}

const CsvFileUploader: React.FC<Props> = ({
  file,
  csvFileName,
  fileUploadError,
  handleDrop,
  handleClear,
  setCsvFileName,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv',
    onDrop: (files) => {
      setCsvFileName(files[0]?.name);
      setLoading(true);
      handleDrop(files[0]);
      setTimeout(() => setLoading(false), 1500);
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: 'File too large. Maximum file size is 7MB',
        duration: 1,
      });
    },
    multiple: false,
    maxSize: 7340032,
  });

  return (
    <section className='flex flex-col items-center justify-center'>
      <div
        {...getRootProps()}
        className={`flex flex-col justify-center items-center p-5 border-dashed
        ${
          fileUploadError
            ? 'border-2 border-red-500'
            : 'border-2 border-gray-300'
        } w-[500px] h-[250px] cursor-pointer`}
      >
        {!file && !loading && (
          <Fragment>
            <input {...getInputProps()} />
            <div className=''>
              <img src={uploadIcon} alt='cloud' />
            </div>
            <p className='mb-2 text-gray-600'>{t('Upload from CSV')}</p>
            <p className='text-gray-400 align-center text-center'>
              {t('Your file must contain a phone number with a')} <br />
              {t('country code e.g., (+1) and template message')} <br />
              {t('variables as column headers..')}
            </p>
          </Fragment>
        )}

        {loading && (
          <>
            <Spinner
              color='border-r-transparent border-t-[#36C17F] border-b-[#36C17F] border-[#36C17F] border-4'
              size='w-[72px] h-[72px] mt-0'
            />
            <p className='absolute top-[250px] w-max left-[50%]'>
              <span className='relative left-[-50%]'>
                {t('Uploading')} {!!csvFileName && csvFileName}
              </span>
            </p>
          </>
        )}

        {file && !loading && (
          <>
            <button
              className='inline-flex items-center px-4 py-2 font-bold rounded bg-grey-light hover:bg-grey text-grey-darkest'
              onClick={() => handleClear()}
            >
              <span>{t('Delete')}</span>
            </button>
            <p>{file}</p>
          </>
        )}
      </div>
      {fileUploadError !== '' && (
        <p className='mt-4 text-red-500'>
          {t('An error occurred. Make sure you are uploading a CSV file.')}
        </p>
      )}
    </section>
  );
};

export default CsvFileUploader;
