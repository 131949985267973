import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface Prop {
  title: string;
  value: string | undefined;
  unit?: string;
  titleInfo: string;
  hasBorderTop?: boolean;
  hasBorderBottom?: boolean;
}

const CustomerInformationCard: React.FC<Prop> = ({
  title,
  value,
  unit,
  titleInfo,
  hasBorderBottom = true,
  hasBorderTop = true,
}) => {
  return (
    <div
      className={`${hasBorderTop ? 'border-t' : ''} ${
        hasBorderBottom ? 'border-b' : ''
      } p-4`}
    >
      <p className='flex items-center font-sans text-sm font-medium text-gray-500'>
        {title}{' '}
        <span className='ml-2' title={titleInfo || ''}>
          <InformationCircleIcon className='w-4 h-4' />
        </span>
      </p>
      <div className='flex items-end mt-2'>
        <p className='font-sans text-2xl font-semibold leading-5 text-gray-900'>
          {value}
        </p>
        {!!unit && (
          <p className='flex items-center ml-2 font-sans text-sm font-medium leading-4 text-gray-500'>
            {unit}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomerInformationCard;
