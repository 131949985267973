import React from 'react';
import Button from '../../../../library/button';
import useInboxDatalab from 'pages/inbox/hooks/useInboxDatalab';
import useTranslation from '../../../../components/customHooks/useTranslation';

import { navigate } from '@reach/router';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { EmptyStateSadIcon } from '../../assets/iconComponent/EmptyStateIcon';

import InboxDatalabForm from './InboxDatalabForm';

interface Props {
  handleRightbarAppearance: (value: boolean) => void;
}

const DatalabFieldIndex: React.FC<Props> = ({ handleRightbarAppearance }) => {
  const {
    inboxDatalabData,
    updateSelectedNav,
    fetchSelectedDatalabData,
    isFetchedDatalabFieldsLoading,
    clearStatesExceptDatalabListAndCustomerEntry,
  } = useInboxDatalab();

  const { t } = useTranslation();

  const renderDatalabRightbarTopView = () => {
    return (
      <div className='flex items-center h-16 p-3 border-b space-between'>
        {/* loading state view */}
        {isFetchedDatalabFieldsLoading && (
          <div className='w-full max-w-sm py-4 mx-auto'>
            <div className='flex items-center space-x-4 animate-pulse'>
              <div className='flex-1 py-1 space-y-6'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-8 col-span-2 bg-gray-200 rounded' />
                  <div className='h-8 bg-gray-200 rounded' />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isFetchedDatalabFieldsLoading && (
          <>
            <p className='text-base font-semibold'>
              {t(inboxDatalabData?.selectedDatalabData?.title) || ''}
            </p>
            <button
              className='text-base font-semibold text-gray-700'
              onClick={() => {
                clearStatesExceptDatalabListAndCustomerEntry();
              }}
            >
              &#x2715;
            </button>
          </>
        )}
      </div>
    );
  };

  const renderDatalabRightbarMiddleViewLoader = () => {
    return (
      <>
        {[...Array(5)].map((item) => (
          <div key={item} className='w-full max-w-sm'>
            <div className='flex items-center animate-pulse'>
              <div className='flex-1 py-1 mt-2'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-6 col-span-2 bg-gray-200 rounded' />
                  <div className='h-6 bg-gray-200 rounded' />
                </div>
                <div className='mt-2 mb-2'>
                  <div className='h-16 bg-gray-200 rounded' />
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderDatalabRightbarMiddleEmptyView = () => {
    return (
      <div className='flex flex-col items-center justify-center h-full'>
        <EmptyStateSadIcon />
        <span className='mt-4 text-sm text-gray-500'>
          {t('Oops! there are no fields found')}.
          <br /> {t('Better create new field in datalab')}.
        </span>
        <Button
          size='sm'
          intent='primary'
          className='mt-4 rtl:gap-2'
          icon={<ArrowTopRightOnSquareIcon />}
          onClick={() => {
            updateSelectedNav(6);
            navigate(`data-lab/edit/${inboxDatalabData.selectedDatalabId}`);
          }}
        >
          {t('Create New Field')}
        </Button>
      </div>
    );
  };

  const renderDatalabRightbarMiddleView = () => {
    return (
      <div className='h-[83vh] create-wooCoomerce-order px-3 overflow-y-scroll pb-11 mt-4'>
        {/* check and render rigth bar loading view */}
        {isFetchedDatalabFieldsLoading &&
          renderDatalabRightbarMiddleViewLoader()}

        {/* check and render empty rightbar view */}
        {inboxDatalabData?.selectedDatalabFields.length === 0 &&
          renderDatalabRightbarMiddleEmptyView()}

        {/* check and render datalab field view view */}

        {!isFetchedDatalabFieldsLoading &&
          inboxDatalabData?.selectedDatalabData && (
            // <DatalabFieldsView
            //   datalabFields={inboxDatalabData?.selectedDatalabFields}
            //   shouldDisableField={inboxDatalabData?.isDatalabFieldDisabled}
            //   isFetchDatalabFieldsLoading={isFetchedDatalabFieldsLoading}
            //   handleInboxDatalabFieldsValueChange={handleInboxDatalabFieldsValue}
            // />
            <InboxDatalabForm
              formAction={inboxDatalabData?.formAction}
              datalabShape={inboxDatalabData?.selectedDatalabData}
              datalabEntries={inboxDatalabData?.selectedDatalabEntry ?? {}}
            />
          )}
      </div>
    );
  };

  React.useEffect(() => {
    if (!!inboxDatalabData?.selectedDatalabId) fetchSelectedDatalabData();
    // eslint-disable-next-line
  }, [inboxDatalabData?.selectedDatalabId]);

  return (
    <div className='h-full w-[23.6%] fixed ltr:right-0 rtl:left-0 bg-white'>
      {/* top view */}
      {renderDatalabRightbarTopView()}
      {/* Middle view */}
      {renderDatalabRightbarMiddleView()}
    </div>
  );
};

export default DatalabFieldIndex;
