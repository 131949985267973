import React from 'react';
import { platinum_color } from 'pages/datalab/export';

interface ILabelSeparatorProps {
  label: string;
  borderColor?: string;
  labelColor?: string;
}

const LabelSeparator: React.FC<ILabelSeparatorProps> = ({
  label,
  borderColor = platinum_color,
  labelColor = '#71717A',
}) => {
  const lineStyle = {
    border: `1px solid ${borderColor}`,
    height: '1px',
    width: '100%',
  };

  const spanStyle = {
    backgroundColor: 'transparent',
    padding: '0 5px',
    color: labelColor,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={spanStyle}>{label}</span>
      <span style={lineStyle}></span>
    </div>
  );
};

export default LabelSeparator;
