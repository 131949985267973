import React from 'react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Modal from '../../../components/globalComponents/Modal';
import { EstimatedCostType } from 'index';
import { getALLChannelData } from 'utilities/utils';
import { connect } from 'react-redux';

interface Props {
  handleCloseShowUsage: () => void;
  billingEstimation: EstimatedCostType;
  fetchEstimatedCost: (payload: number) => void;
  selectedProject: selectedProjectProps;
}

const SessionUsageModal: React.FC<Props> = ({
  handleCloseShowUsage,
  billingEstimation,
  fetchEstimatedCost,
  selectedProject,
}) => {
  const [billingInfo, setBillingInfo] =
    React.useState<EstimatedCostType | null>(null);

  React.useEffect(() => {
    setBillingInfo(billingEstimation);
  }, [billingEstimation]);

  React.useEffect(() => {
    fetchEstimatedCost(selectedProject.id);
    // eslint-disable-next-line
  }, []);

  const notice = () => {
    return (
      <div className='bg-[#EFF6FF] p-3 flex items-center gap-3 rounded-md'>
        <div>
          <InformationCircleIcon className='h-5 w-5 text-blue-500' />
        </div>
        <p className='text-blue-500 text-[12px]'>
          We will use your available credits to pay your estimated bill.
          If your available credits do not cover the bill, you will be
          charged on your regular payment method.
        </p>
      </div>
    );
  };
  const sessionItems = () => {
    const info = billingInfo?.sessions_usage;
    return info && info?.length > 0
      ? info.map((info, index) => {
          return (
            <div
              className='flex pb-2 pt-2 justify-between items-align'
              key={index}
            >
              <img
                src={
                  getALLChannelData.filter(
                    (channel) => channel.platform_type === info.type
                  )[0].icon
                }
                alt='logo'
                className='h-4'
              />
              <p className='w-[60%] text-gray-400 text-[12px]'>{info.title}</p>
              <div className='w-[30%] text-gray-400 text-[12px] flex justify-end'>
                <p>{info.monthly_chatbot_sessions}</p>
              </div>
            </div>
          );
        })
      : null;
  };

  return (
    <Modal>
      <div className='bg-white p-5 rounded-md'>
        <p className='text-gray-900 text-base font-bold'>
          Sessions Usage Details
        </p>
        <div className='absolute top-[20px] right-[10px] w-6 h-6'>
          <XMarkIcon
            className='w-4 h-4 cursor-pointer'
            onClick={() => handleCloseShowUsage()}
          />
        </div>
        <div className='relative bg-gray-50 p-3 rounded-md border border-gray-200 mt-3 mb-3 h-auto'>
          {sessionItems()}
          <div className='border-t border-gray-300'>
            <div className='flex'>
              <div className='flex w-full border-b border-gray-200 pb-3 pt-3'>
                <div className='w-1/2'>
                  <p className='text-gray-500 text-sm'>Total Session</p>
                </div>
                <div className='flex flex-end w-1/2'>
                  <p className='text-gray-700 text-sm font-base'>
                    {billingInfo?.total_sessions}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex'>
              <div className='flex w-full border-b border-gray-200 pb-3 pt-3'>
                <div className='w-1/2'>
                  <p className='text-gray-500 text-sm'>Free Session</p>
                </div>
                <div className='flex flex-end w-1/2'>
                  <p className='text-gray-700 text-sm font-base'>
                    {billingInfo?.free_sessions}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex'>
              <div className='flex w-full border-b border-gray-200 pb-3 pt-3'>
                <div className='w-1/2'>
                  <p className='text-gray-500 text-sm'>Extra Session</p>
                </div>
                <div className='flex flex-end w-1/2'>
                  <p className='text-gray-700 text-sm font-base'>
                    {billingInfo?.extra_sessions}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {notice()}
      </div>
    </Modal>
  );
};

const mapState = (state: any) => ({
  billingEstimation: state.newBilling.billingEstimation,
  selectedProject: state.dashboard.selectedProject,
});

const mapDispatch = (dispatch: any) => ({
  fetchEstimatedCost: (projectId: number) =>
    dispatch.newBilling.fetchEstimatedCost(projectId),
});

export default connect(mapState, mapDispatch)(SessionUsageModal);
