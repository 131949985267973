import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from '../../../library';
import useTranslation from 'components/customHooks/useTranslation';
import usePartner from 'components/customHooks/usePartner';
const ForgetPasswordFormSection = ({
  email,
  handleChange,
  content,
  loading,
  handleSubmit,
}) => {
  const { authT } = useTranslation();
  const { isPartnerRestricted } = usePartner();
  const shouldHideSupportLink = isPartnerRestricted(
    'ForgetPasswordFormSection/node/reset-password-help'
  );
  return (
    <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='space-y-6'>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            {authT('Email address')}
          </label>
          <div className='mt-1'>
            <input
              type='email'
              name='email'
              autoComplete='email'
              placeholder='name@company.com'
              value={email}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              required
              className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
            />
          </div>
        </div>

        <div>
          <Button
            onClick={() => handleSubmit()}
            isFullWidth={true}
            size='sm'
            intent='primary'
          >
            {authT(content.forget.forgetButtonTitle)}
          </Button>
        </div>

        {shouldHideSupportLink ? null : (
          <div
            id='reset-password-help'
            className='flex items-center justify-center'
          >
            <div className='text-sm'>
              {authT(content.contactInfo.text)} &nbsp;
              <Link url={content.contactInfo.link}>
                {authT(content.contactInfo.linkTitle)}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
ForgetPasswordFormSection.propTypes = {
  email: PropTypes.string,
  content: PropTypes.any,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default ForgetPasswordFormSection;
