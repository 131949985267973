import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { getCurrentOrderFormInformationValue } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';
import {
  CreateDraftOrderProperty,
  OrderFormInformationType,
} from 'pages/inbox/inboxInterface.d';

interface Props {
  setCreateDraftOrder: (
    CreateDraftOrderProperty: CreateDraftOrderProperty
  ) => void;
  createDraftOrder: CreateDraftOrderProperty;
  handleChange: (value: any) => void;
  orderFormInformation: OrderFormInformationType;
  customerInfo: any;
  updateOrderInformationState: (payload: {
    key: number | string;
    data: any;
  }) => void;
}

const DiscountTypeDropdown: React.FC<Props> = ({
  setCreateDraftOrder,
  createDraftOrder,
  handleChange,
  orderFormInformation,
  customerInfo,
  updateOrderInformationState,
}) => {
  const { t } = useTranslation();
  const discountTypes = [
    {
      type: 'PERCENTAGE',
      value: 'PERCENTAGE',
    },
    {
      type: 'FIXED_AMOUNT',
      value: 'FIXED_AMOUNT',
    },
  ];

  const renderListBoxButtonView = () => {
    return (
      <Listbox.Button
        className='relative w-full cursor-default rounded-lg
          bg-white py-2 pl-3 pr-10 text-left border border-gray-200 focus:outline-none
          focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white
          focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
      >
        <span className='block truncate'>
          {createDraftOrder?.discount_type
            ? createDraftOrder?.discount_type
            : t('Select Discount Type')}
        </span>
        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronDownIcon
            className='h-5 w-5 text-gray-400'
            aria-hidden='true'
          />
        </span>
      </Listbox.Button>
    );
  };
  const renderListBoxOptionsView = () => {
    return (
      <Listbox.Options
        className='absolute mt-1 max-h-60 w-full overflow-auto
            rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5
            focus:outline-none sm:text-sm z-10'
      >
        {discountTypes?.map((discountType, typeIdx) => (
          <Listbox.Option
            key={typeIdx}
            className={({ active }) =>
              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                active ? 'bg-[#04B25F] text-white' : 'text-gray-900'
              }`
            }
            value={discountType?.value}
          >
            {({ selected }) => (
              <>
                <span
                  className={`block truncate ${
                    selected ? 'font-medium' : 'font-normal'
                  }`}
                >
                  {discountType?.value}
                </span>
                {selected ? (
                  <span
                    className='absolute inset-y-0 left-0
                        flex items-center pl-3 text-green-600'
                  >
                    <CheckIcon className='h-5 w-5' aria-hidden='true' />
                  </span>
                ) : null}
              </>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  };

  return (
    <div className='w-[70%]'>
      <Listbox
        value={createDraftOrder?.discount_type}
        onChange={(value) => {
          updateOrderInformationState({
            key: customerInfo?.customer_id,
            data: {
              ...getCurrentOrderFormInformationValue(
                orderFormInformation,
                customerInfo?.customer_id
              ),
              discount_type: value,
            },
          });
        }}
      >
        <div className='relative mt-1'>
          {renderListBoxButtonView()}
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            {renderListBoxOptionsView()}
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

const mapState = (state: any) => ({
  orderFormInformation: state.ecommerce.orderFormInformation,
  customerInfo: state.ecommerce.cart,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(DiscountTypeDropdown);
