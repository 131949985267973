import MastercardIcon from '../../../../old/assets/images/mastercard.svg';
import AmexIcon from '../../../../old/assets/images/amex.svg';
import VisaIcon from '../../../../old/assets/images/visa.svg';
import DinersclubIcon from '../../../../old/assets/images/diners-club.svg';
import CardSection from './CardSection';
import useTranslation from 'components/customHooks/useTranslation';

const PaymentMethodAdd = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='flex gap-3 mb-3 items-center'>
        <p className='text-sm text-gray-400'>{t('Credit card')}</p>
        <div className='flex gap-2'>
          <div className='py-.5 px-1.5 border rounded'>
            <img
              src={VisaIcon}
              alt=''
              className='w-auto h-8 sm:flex-shrink-0 sm:h-6'
            />
          </div>

          <div className='py-.5 px-1.5 border rounded'>
            <img
              src={MastercardIcon}
              alt=''
              className='w-auto h-8 sm:flex-shrink-0 sm:h-6'
            />
          </div>
          <div className='py-.5 px-1.5 border rounded'>
            <img
              src={AmexIcon}
              alt=''
              className='w-auto h-8 sm:flex-shrink-0 sm:h-6'
            />
          </div>
          <div className='py-.5 px-1.5 border rounded'>
            <img
              src={DinersclubIcon}
              alt=''
              className='w-auto h-8 sm:flex-shrink-0 sm:h-6'
            />
          </div>
        </div>
      </div>
      <p className='text-sm text-gray-700 rtl:text-right'>
        {t('Payment Information')}
      </p>
      <div className='mb-3 border-none rounded-md w-full text-gray-500 flex'>
        <CardSection disabled={false} />
      </div>
    </>
  );
};

export default PaymentMethodAdd;
