import React from 'react';
import {
  classNames,
  feedChannels,
  emailChannel,
} from '../../../../../../utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

interface Props {
  activeTab: string;
  selectedTicket: TicketInterface;
  chevronIconStatus?: string;
  shouldShowChevronIcon?: boolean;
  updateSateData: (key: string, value: string) => void;
  handleEmailBoxHeight?: (chevronIconStatus: string) => void;
  ticketIsResolvedStatus: number;
  selectedQueueType: string;
  ticketActions: TicketActionInterface[];
}

const messengerChannelNavElements = ['Chat', 'Note'];
const feedChannelNavElements = ['Comment', 'Private Reply'];
const emailChannelNavElements = ['Email', 'Note'];
const closedTicketNavElements = ['Note'];
const botTicketNavElements = ['Chat'];
const editCommentNavElements = ['Edit Comment'];

export const ChatboxNavElements: React.FC<Props> = ({
  activeTab,
  selectedTicket,
  updateSateData,
  selectedQueueType,
  chevronIconStatus,
  handleEmailBoxHeight,
  shouldShowChevronIcon,
  ticketIsResolvedStatus,
  ticketActions,
}) => {
  const { t } = useTranslation();
  // const isDirectMessageAllowed = ticketActions.find(
  //   (item) => item.action === 'direct_message'
  // )?.is_allowed;
  let navElements =
    activeTab === 'edit-comment'
      ? editCommentNavElements
      : selectedQueueType === 'bot'
      ? botTicketNavElements
      : ticketIsResolvedStatus === 1 || selectedTicket?.is_resolved
      ? closedTicketNavElements
      : feedChannels.includes(selectedTicket?.customer_platform_type)
      ? feedChannelNavElements
      : emailChannel.includes(selectedTicket?.customer_platform_type)
      ? emailChannelNavElements
      : messengerChannelNavElements;

  React.useEffect(() => {
    if (
      selectedQueueType !== 'bot' &&
      (ticketIsResolvedStatus === 1 || selectedTicket?.is_resolved)
    ) {
      updateSateData('chatboxActiveNav', 'Note');
    }
    // eslint-disable-next-line
  }, [ticketIsResolvedStatus]);

  const handleNavClick = (tab: any) => {
    if (selectedQueueType !== 'bot' && activeTab !== 'edit-comment')
      updateSateData('chatboxActiveNav', tab);
  };

  return (
    <div data-testid='middle-chat-box-nav-bar'>
      <div className='flex items-center justify-between mx-5'>
        <nav className='flex items-center h-8 bg-transparent' aria-label='Tabs'>
          {navElements.map((tab: any, index) => (
            <div
              key={index}
              onClick={() => handleNavClick(tab)}
              className={classNames(
                tab === activeTab
                  ? 'bg-none text-primary'
                  : 'text-gray-500 hover:text-gray-700',
                'mr-4 relative font-medium text-xs rounded-md h-8 flex items-center',
                selectedQueueType === 'bot'
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              )}
              aria-current={tab === activeTab ? 'page' : undefined}
            >
              <span>{t(tab)}</span>
              <span
                aria-hidden='true'
                className={classNames(
                  tab === activeTab ? 'bg-primary' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-px'
                )}
              />
            </div>
          ))}
        </nav>
        {activeTab === 'Email' && shouldShowChevronIcon && (
          <div
            className={`rounded hover:cursor-pointer ${
              chevronIconStatus === 'up' ? 'bg-gray-200' : 'hover:bg-gray-100 '
            }`}
          >
            {chevronIconStatus === 'down' && (
              <ChevronDownIcon
                className='w-5 h-5 text-gray-400'
                onClick={() => {
                  if (!!handleEmailBoxHeight) handleEmailBoxHeight('down');
                }}
              />
            )}
            {chevronIconStatus === 'up' && (
              <ChevronUpIcon
                className='w-5 h-5 text-gray-400'
                onClick={() => {
                  if (!!handleEmailBoxHeight) handleEmailBoxHeight('up');
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatboxNavElements;
