import {
  React,
  // Button,
  AutomationDashboard,
  useAutomationWorkflow,
  AutomationWorkflow,
  AutomationHistory,
  AutomationLog,
} from './export';

interface Props {}

const WorkflowAutomation: React.FC<Props> = () => {
  const { automationFlowDisplayViewType } = useAutomationWorkflow();
  return (
    <div className='h-[100vh] w-full flex justify-center items-center'>
      {automationFlowDisplayViewType === 'dashboard' && <AutomationDashboard />}
      {automationFlowDisplayViewType === 'createWorkflow' && (
        <AutomationWorkflow />
      )}
      {automationFlowDisplayViewType === 'viewHistories' && (
        <AutomationHistory />
      )}
      {automationFlowDisplayViewType === 'viewLog' && <AutomationLog />}
    </div>
  );
};

export default WorkflowAutomation;
