import React from 'react';
import SearchProduct from '../../eCommerceOrderCreate/components/SearchProduct';
import { useDebounce } from '../../../../../../../components';
import ProductLists from '../../eCommerceOrderCreate/components/ProductLists';
import SelectedProductLists from '../components/SelectedProductLists';
import ProductState from '../../eCommerceOrderCreate/components/ProductState';
import { connect } from 'react-redux';
import {
  CustomerDataProps,
  ProductDataProps,
  SelectedProductDataProps,
  VariantProps,
  VariantListProps,
} from '../../eCommerceOrderCreate/interfaces';

interface Prop {
  customerData?: CustomerDataProps;
  productData: ProductDataProps[];
  customerId: number;
  teamId: number;
  cartLoading: boolean;
  fetchProductList: (
    teamId: number,
    search: string,
    offset: string,
    limit: number,
    isScrolled: boolean
  ) => Promise<Boolean>;
  productListLoading: boolean;
  shouldScrollNext: boolean;
  updateCart: (
    customerId: number,
    cartProducts: SelectedProductDataProps[]
  ) => void;
  fetchShopifyProductVariant: (teamId: number, productId: number) => void;
  isVariantLoading: boolean;
  variantLists: VariantProps[];
  noProductsFound: boolean;
  currencySymbol: string;
}

const ProductSelection: React.FC<Prop> = ({
  customerData,
  customerId,
  teamId,
  cartLoading,
  fetchProductList,
  productListLoading,
  shouldScrollNext,
  productData,
  updateCart,
  fetchShopifyProductVariant,
  isVariantLoading,
  variantLists,
  noProductsFound,
  currencySymbol,
}) => {
  const [search, setSearch] = React.useState('');
  const [productListOffset, setProductListOffset] = React.useState('');
  const [scrollLoading, setScrollLoading] = React.useState(false);

  const debouncedSearch = useDebounce(search, 1000);
  const limit: number = 3;

  const handleProductSelect = (product: any) => {
    const updatedProduct = {
      ...product,
      variant_id: '',
      variant_attributes: [],
      quantity: 1,
      total_cost: product.unit_price,
    };
    if (!!customerData) {
      //if product has variation we will allow else we will check if its in alice_cart
      if (
        product.has_variations ||
        (!product.has_variations &&
          customerData?.alice_cart.filter(
            (p) => p.product_id === product.product_id
          ).length === 0)
      )
        updateCart(customerId, [...customerData?.alice_cart, updatedProduct]);
      fetchShopifyProductVariant(teamId, product.product_id);
    }
  };

  const handleProductSearch = async () => {
    setProductListOffset('');
    await fetchProductList(
      teamId,
      debouncedSearch,
      productListOffset,
      limit,
      false
    );
  };

  React.useEffect(() => {
    handleProductSearch();
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const handleLoadProductScroll = async () => {
    setScrollLoading(true);
    const newOffset = productData[productData.length - 1]?.cursor;
    const res: Boolean = await fetchProductList(
      teamId,
      debouncedSearch,
      newOffset,
      limit,
      true
    );
    setScrollLoading(false);
    if (res) {
      setProductListOffset(newOffset);
    }
  };

  const handleRemoveCartProduct = (
    product: SelectedProductDataProps,
    index: number
  ) => {
    const cart = JSON.parse(JSON.stringify(customerData?.alice_cart || []));
    let newData: SelectedProductDataProps[] = [];
    for (let i = 0; i < cart.length; i++) {
      if (i !== index) {
        newData = [...newData, cart[i]];
      }
    }
    if (newData) {
      updateCart(customerId, newData);
    }
  };

  const handleVariantSelect = (
    option: VariantListProps,
    productId: string | number,
    index: number
  ) => {
    const newCustomerData: SelectedProductDataProps[] | undefined =
      customerData?.alice_cart.map((product, i) => {
        if (product.product_id === productId && i === index) {
          product.variant_attributes = option.attribute_list;
          product.variant_attributes_string = option.attribute_string;
          product.variant_id = option.variant_id;
          product.unit_price = option.unit_price;
        }
        return product;
      });
    if (newCustomerData) {
      updateCart(customerId, newCustomerData);
    }
  };

  const handleProductQtyChange = (
    product: SelectedProductDataProps,
    qty: number,
    index: number
  ) => {
    const newCustomerData: SelectedProductDataProps[] | undefined =
      customerData?.alice_cart.map((p: SelectedProductDataProps, i: number) => {
        if (p.product_id === product.product_id && i === index) {
          p.quantity = qty;
          p.total_cost = p.unit_price * qty;
        }
        return p;
      });
    if (newCustomerData) {
      updateCart(customerId, newCustomerData);
    }
  };

  return (
    <div>
      <SearchProduct search={search} setSearch={setSearch} />
      {!!debouncedSearch && (
        <p className='px-6 my-2 font-sans text-xs font-medium text-gray-300'>
          Showing Result for "{debouncedSearch}"
        </p>
      )}
      {(cartLoading || (!scrollLoading && productListLoading)) && (
        <ProductState state={'loading'} />
      )}

      {!cartLoading && (scrollLoading || !productListLoading) && (
        <>
          {productData.length === 0 ? (
            <ProductState state={'no-result'} />
          ) : (
            <ProductLists
              productData={productData}
              isProductSelected={
                !!customerData && customerData?.alice_cart?.length > 0
              }
              handleProductSelect={handleProductSelect}
              shouldScrollNext={shouldScrollNext}
              handleLoadProductScroll={handleLoadProductScroll}
              noProductsFound={noProductsFound}
              productListLoading={productListLoading}
              currencySymbol={currencySymbol}
            />
          )}
          <SelectedProductLists
            productData={customerData?.alice_cart || []}
            handleRemoveCartProduct={handleRemoveCartProduct}
            fetchVariant={(productId: number) => {
              fetchShopifyProductVariant(teamId, productId);
            }}
            variantLists={variantLists}
            isVariantLoading={isVariantLoading}
            handleProductQtyChange={handleProductQtyChange}
            handleVariantSelect={handleVariantSelect}
            currencySymbol={currencySymbol}
          />
        </>
      )}
    </div>
  );
};

const mapState = (state: any) => ({
  customerData: state.crmEcommerce.customerData,
  productData: state.crmEcommerce.productData,
  cartLoading: state.loading.effects.crmEcommerce.fetchCart,
  teamId: state.dashboard.selectedProject.id,
  productListLoading: state.loading.effects.crmEcommerce.fetchProductList,
  shouldScrollNext: state.crmEcommerce.shouldScrollNext,
  isVariantLoading:
    state.loading.effects.crmEcommerce.fetchShopifyProductVariant,
  variantLists: state.crmEcommerce.variantLists,
  noProductsFound: state.crmEcommerce.noProductsFound,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({
  fetchShopifyProductVariant: (teamId: number, productId: number) =>
    dispatch.crmEcommerce.fetchShopifyProductVariant({ teamId, productId }),
  fetchProductList: (
    teamId: number,
    search: string,
    offset: string,
    limit: number,
    isScrolled: boolean
  ) =>
    dispatch.crmEcommerce.fetchProductList({
      teamId,
      search,
      offset,
      limit,
      isScrolled,
    }),
  updateCart: (customerId: number, cartProducts: SelectedProductDataProps[]) =>
    dispatch.crmEcommerce.updateCart({
      customerId,
      cartProducts,
    }),
});

const ProductSelectionContainer = connect(
  mapState,
  mapDispatch
)(ProductSelection);
export default ProductSelectionContainer;
