import React, { useRef } from 'react';
import BroadcastModal from './BroadcastModal';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { toaster } from 'evergreen-ui';
import { AddCreditsPayload, AddCreditsResponsePayload } from '../interface';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

interface Props {
  isOpen: boolean;
  setOpen: (payload: boolean) => void;
  handleAddCredits: (payload: AddCreditsPayload) => AddCreditsResponsePayload;
  projectId: number;
  card: string;
}

const AddCredits: React.FC<Props> = ({
  isOpen,
  setOpen,
  handleAddCredits,
  projectId,
  card,
}) => {
  const [credit, setCredit] = React.useState<number | null>(null);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isAddCreditApiInProgress, setIsAddCreditApiInProgress] =
    React.useState<boolean>(false);
  const creditInput = useRef(null);

  const addCredits = async () => {
    setIsError(false);
    setButtonDisabled(true);
    setIsAddCreditApiInProgress(true);
    const addCreditsPayload = {
      credit_amount: credit,
      project_id: projectId,
    };
    const res = await handleAddCredits(addCreditsPayload);
    if (res.success) {
      setOpen(false);
      toaster.success('Credits added successfully.', {
        description: (
          <p className='text-primary text-sm'>
            ${credit} was added as WhatsApp credits to your account. Your total
            balance is ${res.dataSource.available_credits}
          </p>
        ),
        duration: 3,
      });
    } else {
      setIsError(true);
    }
    setIsAddCreditApiInProgress(false);
    setCredit(null);
  };

  const renderTitleAndDescription = () => {
    return (
      <>
        <div className='flex items-center'>
          <div className='w-[50%] flex justify-start'>
            <p className='text-gray-900 text-base font-medium'>Add Credits</p>
          </div>
          <div className='w-[50%] flex justify-end'>
            <XMarkIcon
              className='w-5 h-5 text-gray-700 cursor-pointer'
              onClick={() => {
                setOpen(false);
                setCredit(0);
              }}
            />
          </div>
        </div>

        <div className='flex items-center my-2'>
          <p className='text-gray-500 text-sm font-normal'>
            Please add at least US$30 worth of credits. You will be charged from
            your card ending with {card}.
          </p>
        </div>

        <div className='flex text-sm leading-5 mb-5 align-center'>
          <a
            href={`https://www.myalice.ai/pricing`}
            target={'_blank'}
            rel='noreferrer'
            className='flex justify-start align-center cursor-pointer'
          >
            <span className='text-[#0078CF]'>
              Calculate your estimated cost here&nbsp;
            </span>
            <span>
              <ArrowTopRightOnSquareIcon className='w-4 h-4 text-gray-500' />
            </span>
          </a>
        </div>
      </>
    );
  };

  const renderInputField = () => {
    return (
      <>
        <div className='relative'>
          <input
            ref={creditInput}
            value={credit ? credit : ''}
            onChange={(e) => {
              let creditAmount = !!e.target.value
                ? parseFloat(e.target.value)
                : 0;
              setCredit(creditAmount);

              if (creditAmount < 30) {
                setButtonDisabled(true);
              } else {
                setButtonDisabled(false);
              }
            }}
            placeholder='Enter an amount'
            type='number'
            className={`flex w-full my-3 rounded-md text-gray-500 text-sm border border-gray-300 pl-[24px]
                ${
                  isError &&
                  'border-red-300 mb-1 focus:ring-red-300 focus:border-red-400'
                }`}
          />
          <span className='absolute top-2.5 left-3 text-gray-500 w-2.5'>$</span>
        </div>
        {isError && (
          <p className='text-sm leading-5 text-red-500 mb-3'>
            Failed to add credits. Please try again. If the issue
            persists,&nbsp;
            <a
              href='https://www.myalice.ai/support'
              rel='noreferrer'
              target='_blank'
              className='text-red-900 underline'
            >
              contact support
            </a>
          </p>
        )}
      </>
    );
  };

  return (
    <BroadcastModal
      width={'w-[376px]'}
      height={'h-auto'}
      isOpen={isOpen}
      disableFullScreen={true}
    >
      <div className='rounded-md'>
        <div className='mp-2 border border-b rounded-md'>
          <div className='p-5'>
            {renderTitleAndDescription()}

            {renderInputField()}

            <button
              type='submit'
              className={`capitalize py-1 border text-center w-full rounded-md disabled:cursor-not-allowed flex align-center justify-center ${
                buttonDisabled && !isAddCreditApiInProgress
                  ? 'bg-gray-200 text-gray-500 border-gray-300'
                  : 'text-white bg-[#04B25F] border-green-500'
              }`}
              onClick={() => addCredits()}
              disabled={buttonDisabled}
            >
              {!isAddCreditApiInProgress && 'Add Credits'}

              {isAddCreditApiInProgress && (
                <div className='flex justify-center align-center'>
                  <div
                    className={`border-r-transparent border-t-white border-white
                              border-l-white animate-spin inline-block h-4 w-4 border-2
                                rounded-full`}
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                  <span>&nbsp; Adding Credits</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </BroadcastModal>
  );
};

export default AddCredits;
