import React from 'react';
import { Menu } from '@headlessui/react';
import { TrashIcon, PencilIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../../utilities/utils';

interface Props {
  handleRemove: () => void;
  handleEdit: () => void;
}

const OthersCardMenuItems: React.FC<Props> = ({ handleRemove, handleEdit }) => {
  return (
    <>
      <div className='py-1'>
        <Menu.Item>
          {({ active }) => (
            <span
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm'
              )}
              onClick={() => handleEdit()}
            >
              <PencilIcon
                className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
              Edit
            </span>
          )}
        </Menu.Item>
      </div>
      <div className='py-1'>
        <Menu.Item>
          {({ active }) => (
            <span
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm'
              )}
              onClick={() => handleRemove()}
            >
              <TrashIcon
                className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                aria-hidden='true'
              />
              Remove
            </span>
          )}
        </Menu.Item>
      </div>
    </>
  );
};
export default OthersCardMenuItems;
