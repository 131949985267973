import React from 'react';

type colorProps = 'light' | 'dark' | 'primary';
type positionProps = 'top' | 'right' | 'bottom' | 'left';

interface Prop {
  text?: string;
  position?: positionProps;
  children: React.ReactNode;
  color?: colorProps;
  width?: number;
}

const Tooltip: React.FC<Prop> = ({
  text,
  position = 'top',
  children,
  color = 'light',
  width = 64,
}) => {
  const [tooltipStatus, setTooltipStatus] = React.useState(0);

  const getTextColor = (color: colorProps) => {
    switch (color) {
      case 'dark':
      case 'primary':
        return 'white';
      case 'light':
      default:
        return 'gray-800';
    }
  };

  const getBackgroundColor = (color: colorProps) => {
    switch (color) {
      case 'dark':
        return 'gray-800';
      case 'primary':
        return 'primary';
      case 'light':
      default:
        return 'white';
    }
  };

  const getBoxPosition = (position: positionProps) => {
    switch (position) {
      case 'top':
        return 'transform -translate-x-1/2 -translate-y-full';
      case 'right':
        return 'transform -translate-y-1/4';
      case 'left':
        return 'transform -translate-x-full -translate-y-1/4';
      case 'bottom':
      default:
        return 'transform -translate-x-1/2 translate-y-1/2';
    }
  };

  return (
    <div
      className='relative inline-flex cursor-pointer'
      onMouseEnter={() => setTooltipStatus(1)}
      onMouseLeave={() => setTooltipStatus(0)}
    >
      {children}
      {tooltipStatus === 1 && (
        <div className='relative'>
          <div
            className={`absolute top-0 z-50 w-${width} p-2 -mt-1 text-sm leading-tight text-${getTextColor(
              color
            )} bg-${getBackgroundColor(color)} ${getBoxPosition(
              position
            )} rounded-md shadow-lg`}
          >
            {text}
          </div>
          {/*<svg className={`absolute z-50 w-6 h-6 text-${getBackgroundColor(color)} ${getArrowPosition(position)} fill-current stroke-current`} width="8" height="8">
                  <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
              </svg>*/}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
