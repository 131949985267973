import React from 'react';
import { Avatar } from 'evergreen-ui';

interface Prop {
  data: dataProps[];
}

interface dataProps {
  id: number;
  avatar: string;
  full_name: string | '';
}

const AvatarGroup: React.FC<Prop> = ({ data }) => {
  return (
    <div className='team-user-settings__table-group'>
      {data.length > 2 ? (
        <>
          {data.slice(0, 2).map((member) => (
            <Avatar
              key={member.id}
              src={member.avatar}
              name={member.full_name}
              size={32}
            />
          ))}
          <Avatar
            isSolid
            color='yellow'
            name={`+ ${data.length - 2}`}
            size={32}
          />
        </>
      ) : (
        data.map((member, index) => (
          <Avatar
            key={index}
            src={member.avatar}
            name={member.full_name}
            size={32}
          />
        ))
      )}
    </div>
  );
};

export default AvatarGroup;
