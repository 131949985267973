import { Tabs, TabsContent, TabsList, TabsTrigger } from 'libraryV2/ui/tabs';
import { Input } from 'libraryV2/ui/input';
import { cn } from 'libraryV2/utils';
import OptionRenderer from './OptionView';
import { convertToJinjaVariableStrings } from '../utils/functions';
import { useState, useMemo } from 'react';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';

interface DataTabsProps {
  sampleData: Record<string, any> | null;
  isLoading?: boolean;
}

const DataTabs: React.FC<DataTabsProps> = ({
  sampleData,
  isLoading = false,
}) => {
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState('variable');

  const options = useMemo(
    () => (sampleData ? convertToJinjaVariableStrings(sampleData) : []),
    [sampleData]
  );

  const filteredOptions = useMemo(() => {
    return options.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
  }, [options, query]);

  if (isLoading) {
    return (
      <div className='flex items-center justify-center p-28'>
        <CircleLoader size='md' />
      </div>
    );
  }

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab} className='w-full'>
      <TabsList className='grid w-auto grid-cols-2 bg-gray-100'>
        <TabsTrigger
          value='variable'
          className={cn(
            activeTab === 'variable' ? 'bg-white' : 'bg-transparent'
          )}
        >
          Variables
        </TabsTrigger>
        <TabsTrigger
          value='sample-data'
          className={cn(
            activeTab === 'sample-data' ? 'bg-white' : 'bg-transparent'
          )}
        >
          Sample Data
        </TabsTrigger>
      </TabsList>

      <TabsContent
        value='variable'
        className='w-full hover:ring-0 focus:ring-0'
      >
        {!!sampleData && (
          <>
            <Input
              placeholder='Search variables...'
              className='input-class mb-2'
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className='h-auto max-h-[500px] overflow-y-auto'>
              <OptionRenderer options={filteredOptions} />
            </div>
          </>
        )}
      </TabsContent>

      <TabsContent
        value='sample-data'
        className='w-full hover:ring-0 focus:ring-0'
      >
        <div className='h-auto max-h-[500px] overflow-y-auto'>
          {!!sampleData && (
            <pre className='whitespace-pre-wrap break-words'>
              {JSON.stringify(sampleData, null, 2)}
            </pre>
          )}
        </div>
      </TabsContent>
    </Tabs>
  );
};

export default DataTabs;
