import useTranslation from 'components/customHooks/useTranslation';
import { Spinner } from 'evergreen-ui';
import React from 'react';
import { Bar, BarChart, Cell, Tooltip, XAxis } from 'recharts';

interface Props {
  graphData: graphDataProps[];
  color: string;
  loader: boolean;
}
interface graphDataProps {
  name: string;
  value: string | number;
}

const DataBarChart: React.FC<Props> = ({ graphData, color, loader }) => {
  const { t } = useTranslation();
  const barColors = ['#36C17F', '#3B82F6', '#3B82F6', '#3B82F6'];

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className={`p-3 bg-white shadow transition-opacity duration-300 ${
            active ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {active && payload && payload.length ? (
            <>
              <p className='font-sans text-xs font-normal text-gray-500'>
                {t(payload[0].payload.name)}
              </p>
              <p className='font-sans text-base font-medium text-gray-700'>
                {t('Value')}: {payload[0].value}
              </p>
            </>
          ) : null}
        </div>
      );
    }

    return null;
  };
  return (
    <div className='reporting-container__user-card-container-elem-graph'>
      {!loader && graphData.length > 0 && (
        <BarChart width={190} height={120} data={graphData}>
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey='value' fill={color}>
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
            ))}
          </Bar>
          <XAxis
            dataKey='name'
            tickLine={false}
            tickFormatter={(value) => t(value)}
          />
        </BarChart>
      )}
      {!loader && graphData?.length === 0 && (
        <p className='w-1/2 mt-20 border-b-2 border-gray-900' />
      )}
      {loader && (
        <div
          className='flex justify-center align-center'
          style={{ height: 120 }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};
export default DataBarChart;
