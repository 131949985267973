import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../../utilities/utils';

interface Props {
  id?: number;
  hasWriteAccess?: boolean;
  isFullWidth?: boolean;
  options: any[];
  selectedValue: {};
  titleKeyName: string;
  valueKeyName?: string;
  placeHolder?: string;
  buttonClassName?: string;
  handleSelectedOption: (option: object) => void;
}

const DropDownList: React.FC<Props> = ({
  id,
  hasWriteAccess = true,
  isFullWidth = true,
  options,
  titleKeyName,
  valueKeyName,
  placeHolder,
  selectedValue,
  buttonClassName,
  handleSelectedOption,
}) => {
  let isDisabled = !hasWriteAccess || options.length === 0;

  const getOptionList = (id: number, value: object, title: string) => {
    return (
      <Listbox.Option
        key={id}
        className={({ active }) =>
          classNames(
            active ? 'bg-gray-200' : 'text-gray-900',
            'cursor-default select-none relative py-2 ltr:pl-3 rtl:pr-2 ltr:pr-9 rtl:pl-9'
          )
        }
        value={value}
      >
        {({ selected, active }) => (
          <>
            <span
              className={classNames(
                selected ? 'font-semibold' : 'font-normal',
                'block truncate rtl:text-right'
              )}
            >
              {title?.length > 30 ? title.substring(0, 30) + '...' : title}
            </span>
            {selected ? (
              <span
                className={classNames(
                  active ? 'text-gray-900' : 'text-gray-900',
                  'absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-4 rtl:pl-4'
                )}
              >
                <CheckIcon className='w-5 h-5' aria-hidden='true' />
              </span>
            ) : (
              ''
            )}
          </>
        )}
      </Listbox.Option>
    );
  };
  return (
    <div key={id}>
      <Listbox
        disabled={isDisabled}
        value={selectedValue}
        onChange={(option: any) => {
          handleSelectedOption(option);
        }}
      >
        {({ open }) => (
          <div className='relative w-full'>
            <Listbox.Button
              className={`${
                isDisabled
                  ? 'cursor-not-allowed text-gray-500 bg-gray-200'
                  : 'cursor-pointer text-gray-900'
              } relative ${
                isFullWidth ? 'w-full h-10' : ''
              }  border border-gray-300 rounded-md ltr:pl-3 rtl:pr-3 pr-10 py-2 text-left focus:outline-none focus:border-gray-300 sm:text-sm ${
                !!buttonClassName ? buttonClassName : ''
              }`}
            >
              <span className={`flex truncate text-sm`}>
                {selectedValue[`${titleKeyName}`]
                  ? selectedValue[`${titleKeyName}`]
                  : placeHolder}
              </span>
              <span className='absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2 pointer-events-none'>
                <ChevronUpDownIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className={`z-40 relative mt-3 ${
                  isFullWidth ? 'w-full' : ''
                } bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                  !!buttonClassName ? buttonClassName : ''
                }`}
              >
                {options?.map((val: any, index: number) =>
                  getOptionList(index, val, val[titleKeyName])
                )}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
};

export default DropDownList;
