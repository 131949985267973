import { useEffect, useRef } from 'react';

function useTimeout(callback: () => void, delay: number) {
  const savedCallback = useRef(callback);
  const timoeutId = useRef<number | null>(null);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === 0 || delay === null) {
      return;
    }

    timoeutId.current = window.setTimeout(() => savedCallback.current(), delay);
    return () => {
      if (timoeutId.current) {
        clearTimeout(timoeutId.current);
      }
    };
  }, [delay]);

  const clear = () => {
    if (timoeutId.current) {
      clearTimeout(timoeutId.current);
    }
  };

  return { clear };
}

export default useTimeout;
