import { EmptyFileIcon } from 'pages/inbox/assets/iconComponent/EmptyStateIcon';

const EmptyView = ({ msg = 'No File Found' }) => {
  return (
    <div className='p-20 flex flex-col justify-center items-center'>
      <EmptyFileIcon />
      <span className='block text-sm text-center font-normal text-gray-500 mt-2'>
        {msg}
      </span>
    </div>
  );
};

export default EmptyView;
