import { React, useRef } from '../../../../export';
interface Props {
  editableName: string;
  className?: string;
  editIcon: React.ReactNode;
  handleOnEnterPress: (value: string) => void;
}

const EditableTextComponent: React.FC<Props> = ({
  editIcon,
  className,
  editableName,
  handleOnEnterPress,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    if (isEditing && textRef.current) {
      setCursorToEnd(textRef.current);
    }
  }, [isEditing]);

  const setCursorToEnd = (element: HTMLDivElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleClick = () => {
    if (textRef.current) {
      textRef.current.focus();
      setIsEditing(true);
    }
  };

  const handleBlur = () => {
    const newText = textRef.current?.innerText || '';
    handleOnEnterPress(newText);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent new lines on Enter and Shift+Enter
    if (e.key === 'Enter' || (e.key === 'Enter' && e.shiftKey)) {
      e.preventDefault();
      const newText = textRef.current?.innerText || '';
      handleOnEnterPress(newText);
      setIsEditing(false);
    }
  };

  return (
    <div className='flex items-center'>
      <div
        role='textbox'
        ref={textRef}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        contentEditable={isEditing}
        dangerouslySetInnerHTML={{ __html: editableName }}
        className='ml-3 text-base font-semibold text-gray-700 bg-white border:none focus:border-none focus:ring-0 focus:outline-none'
      />

      <button onClick={handleClick} className='ml-2 cursor-pointer'>
        {editIcon}
      </button>
    </div>
  );
};

export default EditableTextComponent;
