import React from 'react';
import SelectedProductListItem from './SelectedProductListItem';
import {
  VariantListProps,
  VariantProps,
  ProductDataProps
} from '../../eCommerceOrderCreate/interfaces';

interface Prop {
  productData: ProductDataProps[];
  handleRemoveCartProduct: (product: ProductDataProps, index: number) => void;
  handleProductQtyChange: (
    proxduct: ProductDataProps,
    qty: number,
    index: number
  ) => void;
  fetchVariant: (productId: number) => void;
  isVariantLoading: boolean;
  variantLists: VariantProps[];
  handleVariantSelect: (
    option: VariantListProps,
    productId: string | number,
    index: number
  ) => void;
  currencySymbol: string;
}

const SelectedProductLists: React.FC<Prop> = ({
  productData,
  handleRemoveCartProduct,
  handleProductQtyChange,
  fetchVariant,
  isVariantLoading,
  variantLists,
  handleVariantSelect,
  currencySymbol,
}) => {
  const handleVariantFetch = async () => {
    const productQueue: (string | number)[] = [];
    productData.forEach((product: ProductDataProps) => {
      if (productQueue.indexOf(product.product_id)) {
        if (product.has_variations) {
          fetchVariant(parseInt(product.product_id.toString()));
          productQueue.push(product.product_id);
        }
      }
    });
  };

  React.useEffect(() => {
    handleVariantFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='px-6 pb-6 mt-4'>
      {productData.map((selectedProduct: ProductDataProps, index) => (
        <SelectedProductListItem
          key={index}
          index={index}
          handleRemove={handleRemoveCartProduct}
          product={selectedProduct}
          handleProductQtyChange={handleProductQtyChange}
          isVariantLoading={isVariantLoading}
          variantLists={variantLists}
          handleVariantSelect={handleVariantSelect}
          currencySymbol={currencySymbol}
        />
      ))}
    </div>
  );
};

export default SelectedProductLists;
