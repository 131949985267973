import useDebounce from 'components/customHooks/useDebounce';
import useTranslation from 'components/customHooks/useTranslation';
import { Input } from 'libraryV2/ui/input';
import { Label } from 'libraryV2/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';
import { useEffect } from 'react';
import { isValidUrl } from 'utilities/utils';
import useWebhooks from '../hooks/useWebhooks';
import { webhookOptions } from '../utils';
import WebhookSourceDropdown, { SourceList } from './WebhookSourceDropdown';
import { IWebhookData } from './webhookModal';

interface WebhookFormProps {
  webhookData: IWebhookData;
  setWebhookData: (data: IWebhookData) => void;
  error: string | null;
  setError: (error: string | null) => void;
}

interface WebhookOption {
  value: string;
  list: 'channelList' | 'formList';
  label: string;
}

export const WebhookForm = ({
  setWebhookData,
  webhookData,
  error,
  setError,
}: WebhookFormProps) => {
  const { t } = useTranslation();
  const { channelList, datalabFormList } = useWebhooks();

  const debouncedEndpoint = useDebounce(webhookData.endpoint, 200);
  useEffect(() => {
    if (debouncedEndpoint) {
      const isValid = isValidUrl(debouncedEndpoint);
      if (!isValid && debouncedEndpoint.length > 0) {
        setError('Please enter a valid URL.');
      } else {
        setError(null);
      }
    }
  }, [debouncedEndpoint, setError]);

  const getSourceList = (listType: WebhookOption['list']): SourceList => {
    return listType === 'channelList' ? channelList : datalabFormList;
  };

  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setWebhookData({ ...webhookData, endpoint: newValue });
  };

  const handleSourceChange = (newSourceIds: number[]) => {
    setWebhookData({
      ...webhookData,
      filter_resource_id: newSourceIds,
    });
  };

  const selectedOption = webhookOptions.find(
    (option) => option.value === webhookData.event
  );

  const sourceList = selectedOption
    ? getSourceList(selectedOption.list as 'channelList' | 'formList')
    : [];

  return (
    <div className='flex flex-col space-y-5'>
      {/* Webhook Type */}
      <div className='flex flex-col gap-y-2'>
        <Label htmlFor='webhook_type'>
          {t('Webhook Type')} <span className='text-red-500 text-sm'>*</span>
        </Label>
        <Select
          name='webhook_type'
          onValueChange={(value) =>
            setWebhookData({
              ...webhookData,
              event: value,
              filter_resource_id: [],
            })
          }
          value={webhookData.event}
        >
          <SelectTrigger className='w-full rtl:flex-row-reverse rtl:text-right border-gray-300 focus:ring-primary shadow-none'>
            <SelectValue
              placeholder={t('Select webhook type')}
              className='placeholder:text-textSecondary'
            />
          </SelectTrigger>
          <SelectContent className='bg-white rtl:text-right w-full max-h-60'>
            {webhookOptions.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
                className='hover:bg-gray-100 w-full rtl:flex-row-reverse rounded-md rtl:text-right'
              >
                {t(option.label)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Dynamic Source Field with Checklist */}
      {webhookData.event && sourceList.length > 0 && (
        <WebhookSourceDropdown
          sourceList={sourceList}
          sourceType={selectedOption?.list as 'channelList' | 'formList'}
          selectedSourceIds={webhookData.filter_resource_id}
          onSourceChange={handleSourceChange}
          placeholder='Select sources...'
        />
      )}

      {/* Webhook URL */}
      <div className='flex flex-col gap-1'>
        <Label htmlFor='webhook_url'>
          {t('Webhook URL')} <span className='text-red-500 text-sm'>*</span>
        </Label>
        <Input
          type='url'
          name='webhook_url'
          placeholder={t('Enter Webhook URL')}
          value={webhookData.endpoint}
          onChange={handleURLChange}
          className={`w-full border border-gray-200 shadow-none focus:outline-none focus:ring-primary focus:border-primary ${
            error
              ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 focus:border-green-500 focus:ring-green-500'
          }`}
        />
        <p className='text-red-500 text-sm font-normal'>{error && t(error)}</p>
      </div>
    </div>
  );
};
