import { useState, FC, useEffect } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../../../../libraryV2/ui/dropdown-menu';
import { Check, Flag } from 'lucide-react';
import { Separator } from 'libraryV2/ui/separator';
import { PRIORITY_OPTIONS } from '../../../../utils/contents';
import useMiddleBarHook from '../../../../hooks/useMiddleBarHook';
import TicketPriorityLabel from 'components/utilityComponent/TicketPriorityLabel';

const PriorityDropdown: FC = () => {
  const { selectedTicket, handleTicketPriority } = useMiddleBarHook();
  const { id, priority } = selectedTicket ?? {};
  const [selectedPriority, setSelectedPriority] = useState<
    string | number | null
  >(null);

  useEffect(() => {
    setSelectedPriority(priority);
    // eslint-disable-next-line
  }, [id]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='flex items-center px-2 space-x-2 bg-white border border-dashed rounded-md shadow-sm focus:outline-none focus:ring-none'>
        <Flag className='w-4 h-4' />
        {selectedPriority !== null && (
          <>
            <Separator
              orientation='vertical'
              className='h-3.5 mx-2 text-background-container'
            />
            <TicketPriorityLabel priority={selectedPriority} />
          </>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-40 bg-white rounded-md shadow-lg border-border'>
        {PRIORITY_OPTIONS.map((priority) => (
          <DropdownMenuItem
            key={priority.label}
            className='flex items-center justify-between py-1.5 px-3 cursor-pointer hover:bg-gray-100'
            onSelect={async () => {
              setSelectedPriority(priority.value);
              if (!!id) {
                await handleTicketPriority(id, priority.value);
              }
            }}
          >
            <TicketPriorityLabel priority={priority.value} />
            {selectedPriority === priority.value && (
              <Check className='w-4 h-4 text-gray-700' />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PriorityDropdown;
