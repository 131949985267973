import React from 'react';
import MethodOptions from '../../eCommerceOrderCreate/components/MethodOptions';
import { ShopifyShippingMethodProps } from '../../eCommerceOrderCreate/interfaces';
import errorIcon from '../../../../assets/images/ecommerceAssets/OrderCreateError.svg';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface Prop {
  handleChange: (key: string, value: any) => void;
  shippingMethod: ShopifyShippingMethodProps;
  shippingCost: number;
  shippingMethodList: ShopifyShippingMethodProps[];
  currencySymbol: string;
  calculateFinalShopifyDraftOrder: () => Promise<any>;
  handleOrderCalculation: () => Promise<void>;
}

const DeliveryPayment: React.FC<Prop> = ({
  shippingMethod,
  handleChange,
  shippingCost,
  shippingMethodList,
  currencySymbol,
  calculateFinalShopifyDraftOrder,
  handleOrderCalculation,
}) => {
  type apiStateType = 'loading' | 'success' | 'error';
  const [apiState, setAPIState] = React.useState<apiStateType>('success');
  const [errorMessage, setErrorMessage] = React.useState('');

  const recalculateDraftOrder = async () => {
    setAPIState('loading');
    let response = await calculateFinalShopifyDraftOrder();
    if (response.success) {
      await handleOrderCalculation();
      setAPIState('success');
    } else {
      setAPIState('error');
      setErrorMessage(response.data);
    }
  };
  const DeliveryShipmentMethod = () => {
    return (
      <div className='px-6 pt-4 pb-6 mt-0 border-b'>
        <div className='md:col-span-1'>
          <div className='flex flex-wrap px-0 sm:mb:0 space-between'>
            <div className='flex flex-start items-center'>
              <label className='inline-flex text-sm font-medium text-gray-700'>
                Shipping Method
              </label>
            </div>
            <div className='mt-4 sm:mt-0'>
              <div className='max-w-lg space-y-4'>
                <div className='relative flex items-center'>
                  <span className='inline-block text-xs text-gray-500'>
                    Not seeing the updated shipping methods?
                  </span>
                  <button onClick={recalculateDraftOrder}>
                    <span className='inline-block text-xs pl-1'>
                      Click here
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {apiState === 'success' && (
            <MethodOptions
              methodList={shippingMethodList}
              selectedMethod={shippingMethod}
              isEnabled={false}
              handleChange={(data: any) => {
                handleChange('shippingMethod', data);
                handleChange('shippingCost', data?.shipping_cost);
              }}
            />
          )}
          {apiState === 'error' && (
            <div className='flex items-center justify-center h-96 flex-column'>
              <img src={errorIcon} alt={apiState} width={200} />
              <p>{errorMessage}</p>
            </div>
          )}
          {apiState === 'loading' && (
            <div className='flex items-center justify-center h-64 flex-column'>
              <ArrowPathIcon className='w-10 h-10 text-primary hover:text-primary-hover animate-reverse-spin mt-10 mb-5' />
              <div>
                <p>Updating shipping methods!</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <DeliveryShipmentMethod />
      <div className='p-6 mt-10 border-b sm:mt-0'>
        <div>
          <label
            htmlFor='price'
            className='block text-sm font-medium text-gray-700'
          >
            Shipping Cost
          </label>
          <div className='relative mt-1 rounded-md shadow-sm w-1/2 mr-44'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <span className='text-gray-500 sm:text-sm'>{currencySymbol}</span>
            </div>
            <input
              type='number'
              value={shippingCost}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('shippingCost', e.target.value)
              }
              className='block w-full pr-12 border-gray-300 rounded-md outline-none focus:ring-primary focus:border-primary  pl-7 sm:text-sm'
              aria-describedby='price-currency'
              disabled={
                !(shippingMethod?.method_id === 'my_alice_flat_rate_shipping')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPayment;
