import React from 'react';
import Footer from '../../common/components/Footer';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {}

const Introduction: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className='relative'>
      <div className='p-6 '>
        <p className='text-base font-medium'>
          {t(
            'Acquired by Meta in 2015, Wit.ai makes it easy for developers to build a Siri-like speech interface for their apps or devices.'
          )}
        </p>
        <br />
        <p className='text-base font-medium'>
          {t('Integrating Wit.ai with Alice will allow you to:')}
        </p>
        <ul className='mt-3 ltr:ml-6 rtl:mr-6 text-base text-gray-800 list-disc list-outside'>
          <li className='my-2'>
            {t('Create NLP modules and connect them to your chatbots.')}
          </li>
          <li className='my-2'>
            {t('Help your bots understand human intents and keywords.')}
          </li>
          <li className='my-2'>
            {t(
              'Create advanced user journeys and automate repetitive queries.'
            )}
          </li>
          <li className='my-2'>
            {t('Deliver customer support in multiple languages.')}
          </li>
        </ul>
      </div>
      <Footer channelName='Wit.ai' />
    </div>
  );
};
export default Introduction;
