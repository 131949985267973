import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import { BroadcastProperty } from '../interface';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  status: string;
  setIsOpen: (payload: boolean) => void;
  setSelectedBroadcastId: (payload: string) => void;
  broadcastId: string;
  setIsEdit: (payload: boolean) => void;
  setBroadcastId: (payload: string) => void;
  setOpenCreateBroadcast: (payload: boolean) => void;
  broadcastData: BroadcastProperty;
  setBroadcastData: (payload: BroadcastProperty | null) => void;
}

const OptionDropdown: React.FC<Props> = ({
  status,
  setIsOpen,
  setSelectedBroadcastId,
  broadcastId,
  setIsEdit,
  setBroadcastId,
  setOpenCreateBroadcast,
  broadcastData,
  setBroadcastData,
}) => {
  const { t } = useTranslation();
  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className=''>
          <EllipsisHorizontalIcon className='w-6 h-6 text-gray-500 ltr:ml-3 rtl:mr-3 cursor-pointer' />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className=''>
            {(status === 'scheduled' ||
              status === 'draft' ||
              status === 'failed') && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm w-full ltr:text-left rtl:text-right'
                      )}
                      onClick={() => {
                        setIsEdit(true);
                        setBroadcastId(broadcastId);
                        setOpenCreateBroadcast(true);
                      }}
                    >
                      {status === 'failed' && t('Try Again')}
                      {status !== 'failed' && t('Edit')}
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-100 text-red-700' : 'text-red-700',
                        'block px-4 py-2 text-sm w-full ltr:text-left rtl:text-right'
                      )}
                      onClick={() => {
                        setSelectedBroadcastId(broadcastId);
                        setBroadcastData(broadcastData);
                        setIsOpen(true);
                      }}
                    >
                      {t('Delete')}
                    </button>
                  )}
                </Menu.Item>
              </>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default OptionDropdown;
