import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { ISallaAddress } from 'pages/inbox/inboxInterface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CityListDropDown from './CityListDropDown';
import CountryListDropdown from './CountryListDropdown';

interface CountryDetailsProps {
  id: number;
  name: string;
  code: string;
  mobile_code: string;
  capital: string;
}

interface CityDetailsProps {
  id: number;
  name: string;
}

interface Props {
  key: string;
  isBilling: boolean;
  billingInformation: ISallaAddress;
  shippingInformation: ISallaAddress;
  selectedTeam: SelectedTeamInterface;
  handleSetSallaObjectInformation: (data: {
    objectKeyName: string;
    childObjectKeyName: string;
    value: any;
  }) => void;
}

const OrderForm: React.FC<Props> = ({
  key,
  isBilling,
  selectedTeam,
  billingInformation,
  shippingInformation,
  handleSetSallaObjectInformation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState<CityDetailsProps[]>([]);
  const [countryList, setCountryList] = useState<CountryDetailsProps[]>([]);
  const [cityApiLoading, setCityApiLoading] = useState(false);
  const [countryApiLoading, setCountryApiLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] =
    useState<CountryDetailsProps | null>(null);
  const currentState = isBilling ? billingInformation : shippingInformation;
  const selectedAliceStoreId = useSelector(
    (state: any) => state.ecommerce.selectedAliceStoreId
  );
  const updateCityList = async () => {
    if (!selectedCountry || !selectedCountry.hasOwnProperty('id')) return;
    setCityApiLoading(true);
    const res = await dispatch.ecommerce.fetchEcommerceCityList({
      projectId: selectedTeam?.id,
      countryId: selectedCountry?.id,
      alice_store_id: selectedAliceStoreId,
    });
    if (!!res) {
      setCityList([...res]);
    }
    setCityApiLoading(false);
  };

  const updateCountryList = async () => {
    setCountryApiLoading(true);
    const res = await dispatch.ecommerce.fetchEcommerceCountryList({
      projectId: selectedTeam?.id,
      alice_store_id: selectedAliceStoreId,
    });
    if (!!res) {
      setCountryList([...res]);
    }
    if (!!currentState) {
      const countrySelected = res.filter(
        (country: CountryDetailsProps) =>
          country?.id === currentState?.country?.id
      );

      setSelectedCountry(
        !!countrySelected && countrySelected.length > 0
          ? countrySelected[0]
          : null
      );
    }
    setCountryApiLoading(false);
  };

  useEffect(() => {
    updateCountryList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!selectedCountry && selectedCountry.hasOwnProperty('id')) {
      setCityList([]);
      updateCityList();
    }
    //eslint-disable-next-line
  }, [selectedCountry]);

  const renderInputValuesView = (
    inputName: string,
    labelName: string,
    placeHolder: string
  ) => {
    return (
      <div key={inputName} className='flex flex-col w-full'>
        <label className='mb-1 capitalize text-gray-600 text-sm font-medium'>
          {t(labelName)}
          <span className='text-red-500'>
            {' '}
            {inputName === 'phone' && '(With Country Code)'} *
          </span>
        </label>
        <input
          type={inputName === 'phone' ? 'number' : 'text'}
          className='rounded-md border border-gray-300 focus:border-green-500
                focus:ring-1 focus:ring-green-500 py-1.5 px-2 mb-3'
          placeholder={t(placeHolder)}
          name={inputName}
          value={currentState[inputName]}
          onChange={(e) => {
            handleSetSallaObjectInformation({
              objectKeyName: isBilling ? 'billing_address' : 'shipping_address',
              childObjectKeyName: e.target.name,
              value: e.target.value,
            });
          }}
        />
      </div>
    );
  };

  const handleCountryChange = (value: any) => {
    if (!selectedCountry || value?.id !== selectedCountry?.id) {
      handleSetSallaObjectInformation({
        objectKeyName: 'shipping_address', // need to modify if billing address added in future
        childObjectKeyName: 'country',
        value,
      });
      setSelectedCountry(value);
      handleSetSallaObjectInformation({
        objectKeyName: 'shipping_address', // need to modify if billing address added in future
        childObjectKeyName: 'city',
        value: '',
      });
    }
  };

  const renderAddressFieldsView = () => {
    return (
      <div className='mb-3'>
        <p className='mb-3 capitalize text-gray-600 text-sm font-medium'>
          {t(isBilling ? 'Billing' : 'Shipping')} {t('Address')}
        </p>
        <div className='rounded-md border border-gray-200 p-2 relative'>
          <div className='mb-3'>
            <CountryListDropdown
              loading={countryApiLoading}
              selected={selectedCountry}
              options={countryList}
              handleChange={(value) => {
                handleCountryChange(value);
              }}
            />

            {/* City Dropdown */}
            <div className='w-full my-2'>
              <CityListDropDown
                loading={cityApiLoading}
                disable={!selectedCountry}
                placeholder='Search Your City'
                selected={currentState?.city}
                options={cityList}
                handleChange={(value) => {
                  handleSetSallaObjectInformation({
                    objectKeyName: 'shipping_address', // need to modify if billing address added in future
                    childObjectKeyName: 'city',
                    value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div key={key}>
      <p className=' capitalize text-gray-900 text-sm font-medium pb-3'>
        {isBilling ? '' : t('Shipping Information')}
      </p>

      {renderInputValuesView('first_name', 'First name', 'ex: John')}

      {renderInputValuesView('last_name', 'last name', 'ex: Doe')}

      {renderInputValuesView('email', 'Email', 'ex: john@email.com')}

      {renderInputValuesView(
        'phone',
        'Phone Number',
        'Phone number with country code'
      )}

      {renderAddressFieldsView()}

      {renderInputValuesView('address_one', 'Address', 'Enter your address')}
      {renderInputValuesView(
        'street_number',
        'Street Number',
        'Enter your street number'
      )}
      {renderInputValuesView('block', 'Block', 'Enter your block')}
      {renderInputValuesView('postcode', 'Post Code', 'Enter post code')}
    </div>
  );
};

export default OrderForm;
