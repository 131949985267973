import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';

interface Props {
  step: number;
}

const StatusBar: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <div className='flex gap-4 mb-4'>
      <div className='w-[33.3%] rtl:text-right'>
        <div
          className={`h-[4px] w-[100%] ${
            step === 1 ? 'bg-green-300' : 'bg-green-500'
          } mb-3 rounded-[15px]`}
        >
          {''}
        </div>
        {step > 1 ? (
          <p className='uppercase text-[#04B25F] font-semibold text-xs leading-4 mb-1'>
            {t('Done')}
          </p>
        ) : (
          <p className='uppercase text-green-300 font-semibold text-xs leading-4 mb-1'>
            {t('Step 1')}
          </p>
        )}
        <p className='text-gray-900 font-medium capitalize'>
          {t('Broadcast Details')}
        </p>
      </div>
      <div className='w-[33.33%] rtl:text-right'>
        <div
          className={`h-[4px] w-[100%] ${
            step === 2
              ? 'bg-green-300'
              : step === 3
              ? 'bg-green-500'
              : 'bg-gray-300'
          } mb-3 rounded-[15px]`}
        >
          {''}
        </div>
        {step > 2 ? (
          <p className='uppercase text-[#04B25F] font-semibold text-xs leading-4 mb-1'>
            {t('Done')}
          </p>
        ) : (
          <p
            className={`uppercase font-semibold text-xs leading-4 mb-1 ${
              step === 1 ? 'text-gray-500' : 'text-green-300'
            }`}
          >
            {t('Step 2')}
          </p>
        )}
        <p className='text-gray-900 font-medium capitalize'>
          {t('Select Audiences')}
        </p>
      </div>
      <div className='w-[33.33%] rtl:text-right'>
        <div
          className={`h-[4px] w-[100%] ${
            step === 3 ? 'bg-green-300' : 'bg-gray-300'
          } mb-3 rounded-[15px]`}
        >
          {''}
        </div>
        <p
          className={`uppercase font-semibold text-xs leading-4 mb-1 ${
            step < 3 ? 'text-gray-500' : 'text-green-300'
          }`}
        >
          {t('Step 3')}
        </p>
        <p className='text-gray-900 font-medium capitalize'>{t('Summary')}</p>
      </div>
    </div>
  );
};

export default StatusBar;
