import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const useEcommerceCustomer = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.loading);
  const ecommerceData = useSelector((state: any) => state.ecommerce);
  const dashboardData = useSelector((state: any) => state.dashboard);
  const inboxData = useSelector((state: any) => state.inbox);

  let teamId = dashboardData?.selectedProject?.id;
  let isConnectEcommerceCustomerLoading =
    loading.effects.ecommerce.connectEcommerceCustomer;
  let isfetchEcommerceCustomerListLoading =
    loading.effects.ecommerce.fetchEcommerceCustomerList;

  const fetchEcommerceCustomerList = async () => {
    console.log('called');
    let data = await dispatch.ecommerce.fetchEcommerceCustomerList({
      projectId: dashboardData?.selectedProject?.id,
      searchQuery: '',
    });

    if (data?.success) {
      dispatch.ecommerce.updateEcommerceStateData({
        key: 'ecommerceCustomerList',
        value: data?.dataSource,
      });
    } else {
      dispatch.ecommerce.updateEcommerceStateData({
        key: 'ecommerceCustomerList',
        value: [],
      });
    }
  };

  const handleCustomerInformationUpdate = () => {
    let customerInfo = inboxData?.customerInformation;
    dispatch.inbox.updateCustomerInformationState({
      ...customerInfo,
      is_linked_with_ecommerce_account: true,
    });
  };

  const handleCustomerClick = async (customer: IEcommerceCustomer) => {
    let customerId = inboxData?.selectedTicket?.customer_id;
    let ecommerceId = customer?.ecommerce_account_id;

    let response = await dispatch.ecommerce.connectEcommerceCustomer({
      teamId,
      customerId,
      ecommerceId,
    });

    if (response.success) {
      handleCustomerInformationUpdate();
      await dispatch.ecommerce.fetchCustomerOrder({
        customerId: customerId,
        orderId: null,
      });
    }
  };

  React.useEffect(() => {
    fetchEcommerceCustomerList();
    // eslint-disable-next-line
  }, []);

  return {
    ecommerceData,
    handleCustomerClick,
    isConnectEcommerceCustomerLoading,
    isfetchEcommerceCustomerListLoading,
  };
};

export default useEcommerceCustomer;
