import React, { useState } from 'react';
import IconCard from './IconCard';
import TextIcon from '../assets/images/icons/text.svg';
import TypingIcon from '../assets/images/icons/typing.svg';
import ImageIcon from '../assets/images/icons/image.svg';
import GalleryIcon from '../assets/images/icons/gallery.svg';
import ButtonsIcon from '../assets/images/icons/buttons.svg';
import AudioIcon from '../assets/images/icons/audio.svg';
import VideoIcon from '../assets/images/icons/video.svg';
import QuickRIcon from '../assets/images/icons/quickreply.svg';
import UserIcon from '../assets/images/icons/userinput.svg';
import PhoneIcon from '../assets/images/icons/phone.svg';
import EmailIcon from '../assets/images/icons/email.svg';
import EmailSendIcon from '../assets/images/icons/email-icon.svg';
import RedirectIcon from '../assets/images/icons/redirect.svg';
import MoreIcon from '../assets/images/icons/more_new.svg';
import SubscribeIcon from '../assets/images/icons/subcribe.svg';
import UnsubscribeIcon from '../assets/images/icons/unsubscribe.svg';
import LiveChatBubbleOvalLeftEllipsisIcon from '../assets/images/icons/livechat.svg';
import AttributeIcon from '../assets/images/icons/set_attribute.svg';
import LocationIcon from '../assets/images/icons/location.svg';
import TicketsIcon from '../assets/images/icons/ticket.svg';
import StoryIcon from '../assets/images/icons/story.svg';
import CSatIcon from '../assets/images/icons/csat.svg';
import DatalabIcon from '../assets/images/icons/datalab-icon.svg';
import PdfIcon from '../assets/images/icons/pdf.svg';
import { generateId } from '../../../../utilities/utils';
import ProductDiscoveryBlockIcon from '../assets/images/icons/product_discovery_block_icon.svg';
import WhatsAppRectangleGroupIcon from '../assets/images/icons/template.svg';

import AddToCartIcon from '../assets/images/icons/add_to_cart_block_icon.svg';
import PlaceOrderIcon from '../assets/images/icons/place_order_block_icon.svg';
import ViewCartIcon from '../assets/images/icons/view_cart_block_icon.svg';
import RemoveFromCartIcon from '../assets/images/icons/remove_from_cart_block_icon.svg';
import CouponDiscoveryIcon from '../assets/images/icons/coupon_block.svg';
import betterdocsIconSet from '../../../../assets/icons/channelIconSet/betterdocs.svg';

import MoreBlockDialog from './MoreBlockDialog';
import PropTypes from 'prop-types';

const BlockDock = ({
  blocks,
  selectedSequence,
  createBlock,
  defaultSequence,
  selectedPlatformType,
  selectedProject,
}) => {
  const [moreDialogVisible, setMoreDialogVisible] = useState(false);
  const addTextBlock = () => {
    const data = { text: '' };
    createBlock(selectedSequence, 'text', data);
    setMoreDialogVisible(false);
  };
  const addDelayBlock = () => {
    const data = {};
    createBlock(selectedSequence, 'delay', data);
    setMoreDialogVisible(false);
  };
  const addQuickReplyBlock = () => {
    const data = {
      title: selectedPlatformType === 'whatsapp_bsp' ? 'Show Details' : null,
      text: '',
      save: false,
      attribute: 'default', //string
      buttons: [],
    };
    createBlock(selectedSequence, 'quick_reply', data);
    setMoreDialogVisible(false);
  };
  const addButtonBlock = () => {
    const data = {
      text: '',
      buttons: [],
    };
    createBlock(selectedSequence, 'button', data);
    setMoreDialogVisible(false);
  };
  const addRedirectBlock = () => {
    const data = { sequence: defaultSequence };
    createBlock(selectedSequence, 'redirect', data);
    setMoreDialogVisible(false);
  };
  const addImageBlock = () => {
    const data = {
      sub_type: 'image', //string
      urls: [''], // array
      api: null,
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };

  const addPdfBlock = () => {
    const data = {
      sub_type: 'file', //string
      urls: [''], // array
      api: null,
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };

  const addAudioBlock = () => {
    const data = {
      sub_type: 'audio', //string
      urls: [''], // array
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };
  const addVideoBlock = () => {
    const data = {
      sub_type: 'video', //string
      urls: [''], // array
    }; // ;
    createBlock(selectedSequence, 'attachment', data);
    setMoreDialogVisible(false);
  };
  const addGalleryBlock = () => {
    const data = {
      image_aspect_ratio: 'square',
      elements: [],
    };
    createBlock(selectedSequence, 'gallery', data);
    setMoreDialogVisible(false);
  };
  const addUserInputBlock = () => {
    const data = {
      sub_type: 'basic',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addPhoneInputBlock = () => {
    const data = {
      sub_type: 'phone',
      text: '',
      attribute: 'default',
      phone_locale: [],
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addLocationBlock = () => {
    const data = {
      sub_type: 'location',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addEmailInputBlock = () => {
    const data = {
      sub_type: 'email',
      text: '',
      attribute: 'default',
    };
    createBlock(selectedSequence, 'input', data);
    setMoreDialogVisible(false);
  };
  const addStoryBlock = () => {
    const data = {
      questions: [
        {
          id: 1,
          main_text: '', // string
          fail_text: '', // string
          primary_entity: '', // string
          role: '', // string (optional)
          is_optional: false, // string
          attribute: `${selectedSequence}-1-${generateId(6)}`, // string
        },
      ],
    };
    createBlock(selectedSequence, 'story', data);
    setMoreDialogVisible(false);
  };
  const addCsatBlock = () => {
    const data = {
      text: '',
      sorting: 'descending',
      buttons: [],
    };
    createBlock(selectedSequence, 'csat', data);
    setMoreDialogVisible(false);
  };

  const addEmailSendBlock = () => {
    const data = {
      sender: '',
      to: [],
      cc: [],
      subject: '',
      body: '',
    };
    createBlock(selectedSequence, 'send_email', data);
    setMoreDialogVisible(false);
  };

  const addTicketBlock = () => {
    const data = {
      group_ids: [],
      agent_ids: [], // only during reading data
      priority: 0, // 0 or 1 or 2 for Low or Medium or High
      note: '',
      resolve_sequence_id: null,
    };
    createBlock(selectedSequence, 'ticket', data);
    setMoreDialogVisible(false);
  };
  const addSubscriptionBlock = () => {
    const data = {
      title: '',
      sequences: [],
    };
    createBlock(selectedSequence, 'subscribe', data);
    setMoreDialogVisible(false);
  };
  const addUnsubscribeBlock = () => {
    const data = {
      subscribe_block: null,
    };
    createBlock(selectedSequence, 'unsubscribe', data);
    setMoreDialogVisible(false);
  };
  const addLiveChatBlock = () => {
    const data = {
      text: '',
      button: {},
      timeout: 6,
    };
    createBlock(selectedSequence, 'live_chat', data);
    setMoreDialogVisible(false);
  };
  const addSetAttribute = () => {
    const data = {
      attribute: 'default',
      value: '',
      collection: [],
    };
    createBlock(selectedSequence, 'set_attribute', data);
    setMoreDialogVisible(false);
  };
  const addDataLabBlock = () => {
    const data = {
      lab_id: null,
      mappings: [],
    };
    createBlock(selectedSequence, 'lab_insert', data);
    setMoreDialogVisible(false);
  };
  const addProductDiscoveryBlock = () => {
    const data = {
      input_instructions: [],
      output_instructions: {},
      no_products_sequence_id: null,
    };
    createBlock(selectedSequence, 'product_discovery', data);
    setMoreDialogVisible(false);
  };

  // --> These blocks will be used again when needed
  const addToCartBlock = () => {
    const data = {
      collection: {
        product_id: '{{x_product_id}}',
        variant_id: '{{x_variant_id}}',
        quantity: 1,
      },
    };
    createBlock(selectedSequence, 'add_to_cart', data);
    setMoreDialogVisible(false);
  };
  const placeOrderBlock = () => {
    const data = {
      first_name: '{{first_name}}',
      last_name: '{{last_name}}',
      phone_number: '{{phone}}',
      email: '{{email}}',
      shipping_address: '{{address}}',
      shipping_id: '{{shipping_id}}',
      shipping_method: 'Flat Rate',
      shipping_cost: '0',
      payment_method: 'Cash on delivery',
      output_message:
        'Your order has been successfully placed!\n' +
        'Order ID: {{order_id}}\n' +
        'Payment Link: {{payment_link}}\n' +
        'Check Link: {{checkout_link}}',
    };
    createBlock(selectedSequence, 'place_order', data);
    setMoreDialogVisible(false);
  };
  const viewCartBlock = () => {
    const data = {
      title: '',
      subtitle: '',
      has_image: false,
      url: '',
      buttons: [],
    };
    createBlock(selectedSequence, 'view_cart', data);
    setMoreDialogVisible(false);
  };
  const removeFromCartBlock = () => {
    const data = {
      removable_cart_index: '{{x_removable_cart_index}}',
    };
    createBlock(selectedSequence, 'remove_from_cart', data);
    setMoreDialogVisible(false);
  };
  const addCouponBLock = () => {
    const data = {
      input_instructions: [],
      output_instructions: {},
    };
    createBlock(selectedSequence, 'coupon_block', data);
    setMoreDialogVisible(false);
  };

  const addBetterdocsBlock = () => {
    const data = {
      input_instructions: [],
      output_instructions: {},
      no_docs_sequence: null,
      no_docs_sequence_id: null,
    };
    createBlock(selectedSequence, 'betterdocs', data);
    setMoreDialogVisible(false);
  };

  const addWATemplateBlock = () => {
    const data = {
      template_id: '',
      name: 'Select Template',
    };
    createBlock(selectedSequence, 'template', data);
    setMoreDialogVisible(false);
  };

  const handleProductDiscoveryBlock = () => {
    if (
      selectedProject.has_ecommerce_connected &&
      (selectedProject.subscription_plan.plan_type === 'enterprise' ||
        selectedProject.subscription_plan.plan_type === 'business') &&
      selectedPlatformType !== 'whatsapp_messenger' &&
      selectedPlatformType !== 'telegram_messenger'
    ) {
      let data = [
        {
          name: 'Product Discovery',
          icon: ProductDiscoveryBlockIcon,
          handleClick: addProductDiscoveryBlock,
        },
      ];
      let orderAutomationBlocks = [
        {
          name: 'Add to Cart',
          icon: AddToCartIcon,
          handleClick: addToCartBlock,
        },
        {
          name: 'Place Order',
          icon: PlaceOrderIcon,
          handleClick: placeOrderBlock,
        },
        {
          name: 'View Cart',
          icon: ViewCartIcon,
          handleClick: viewCartBlock,
        },
        {
          name: 'Remove From Cart',
          icon: RemoveFromCartIcon,
          handleClick: removeFromCartBlock,
        },
      ];

      let updatedData = ['facebook_messenger', 'instagram_messenger'].includes(
        selectedPlatformType
      )
        ? [...data, ...orderAutomationBlocks]
        : data;

      if (selectedProject.ecommerce_platform_type !== 'shopify')
        updatedData = [
          ...updatedData,
          {
            name: 'Coupon Discovery',
            icon: CouponDiscoveryIcon,
            handleClick: addCouponBLock,
          },
        ];

      if (selectedPlatformType === 'whatsapp_bsp') {
        updatedData = [
          {
            name: 'Place Order',
            icon: PlaceOrderIcon,
            handleClick: placeOrderBlock,
          },
          {
            name: 'View Cart',
            icon: ViewCartIcon,
            handleClick: viewCartBlock,
          },
        ];
      }
      return {
        title: 'E-commerce Blocks',
        data: updatedData,
      };
    } else return {};
  };

  const handleBetterDocs = () => {
    if (
      !!selectedProject['has_betterdocs_connected'] &&
      selectedPlatformType !== 'whatsapp_messenger' &&
      selectedPlatformType !== 'telegram_messenger'
    ) {
      let data = [
        {
          name: 'BetterDocs',
          icon: betterdocsIconSet,
          handleClick: addBetterdocsBlock,
        },
      ];
      let orderAutomationBlocks = [];

      let updatedData = ['facebook_messenger', 'instagram_messenger'].includes(
        selectedPlatformType
      )
        ? [...data, ...orderAutomationBlocks]
        : data;
      return {
        title: 'Others',
        data: updatedData,
      };
    } else return {};
  };

  const handleCollectUserInformationBlocks = () => {
    let collectUserInformationBlocks = [
      {
        name: 'User Input',
        icon: UserIcon,
        handleClick: addUserInputBlock,
      },
      {
        name: 'Phone',
        icon: PhoneIcon,
        handleClick: addPhoneInputBlock,
      },
      {
        name: 'Email',
        icon: EmailIcon,
        handleClick: addEmailInputBlock,
      },
      {
        name: 'User Ratings',
        icon: CSatIcon,
        handleClick: addCsatBlock,
      },
    ];
    if (
      selectedPlatformType === 'whatsapp_bsp' ||
      selectedPlatformType === 'livechat_messenger'
    ) {
      collectUserInformationBlocks = [
        ...collectUserInformationBlocks,
        {
          name: 'Location',
          icon: LocationIcon,
          handleClick: addLocationBlock,
        },
      ];
    }
    if (
      ['enterprise', 'business', 'appsumo'].includes(
        selectedProject.subscription_plan?.plan_type
      )
    ) {
      let enterprisePlanBlocks = [
        {
          name: 'Datalab Entry',
          icon: DatalabIcon,
          handleClick: addDataLabBlock,
        },
      ];
      if (
        !['instagram_messenger', 'whatsapp_bsp'].includes(selectedPlatformType)
      ) {
        enterprisePlanBlocks = [
          {
            name: 'Story',
            icon: StoryIcon,
            handleClick: addStoryBlock,
          },
          ...enterprisePlanBlocks,
        ];
      }
      collectUserInformationBlocks = [
        ...collectUserInformationBlocks,
        ...enterprisePlanBlocks,
      ];
    }
    return collectUserInformationBlocks;
  };

  const dataMessenger = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataFeed = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Send Email',
      icon: EmailSendIcon,
      handleClick: addEmailSendBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
  ];

  const dataIGFeed = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Send Email',
      icon: EmailSendIcon,
      handleClick: addEmailSendBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
  ];

  const dataViber = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataWebChat = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Image/GIF',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataInstagramMessenger = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataTelegram = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataLine = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Quick Reply',
      icon: QuickRIcon,
      handleClick: addQuickReplyBlock,
    },
    {
      name: 'Buttons',
      icon: ButtonsIcon,
      handleClick: addButtonBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataALLWebChat = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataALLMessenger = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Delay',
          icon: TypingIcon,
          handleClick: addDelayBlock,
        },
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Live Chat',
          icon: LiveChatBubbleOvalLeftEllipsisIcon,
          handleClick: addLiveChatBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
    handleBetterDocs(),
  ];

  const dataALLViber = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'PDF',
          icon: PdfIcon,
          handleClick: addPdfBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataWhatsapp = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image/GIF',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'User Input',
      icon: UserIcon,
      handleClick: addUserInputBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataAllWhatsapp = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Delay',
          icon: TypingIcon,
          handleClick: addDelayBlock,
        },
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Live Chat',
          icon: LiveChatBubbleOvalLeftEllipsisIcon,
          handleClick: addLiveChatBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataALLInstagramMessenger = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
    handleBetterDocs(),
  ];

  const dataALLTelegram = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataALLLine = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'Quick Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataWhatsappBSP = [
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Image/GIF',
      icon: ImageIcon,
      handleClick: addImageBlock,
    },
    {
      name: 'User Input',
      icon: UserIcon,
      handleClick: addUserInputBlock,
    },
    {
      name: 'Gallery',
      icon: GalleryIcon,
      handleClick: addGalleryBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataAllWhatsappBSP = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Delay',
          icon: TypingIcon,
          handleClick: addDelayBlock,
        },
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
        {
          name: 'List Reply',
          icon: QuickRIcon,
          handleClick: addQuickReplyBlock,
        },
        {
          name: 'Gallery',
          icon: GalleryIcon,
          handleClick: addGalleryBlock,
        },
        {
          name: 'Buttons',
          icon: ButtonsIcon,
          handleClick: addButtonBlock,
        },
        {
          name: 'Image/GIF',
          icon: ImageIcon,
          handleClick: addImageBlock,
        },
        {
          name: 'PDF',
          icon: PdfIcon,
          handleClick: addPdfBlock,
        },
        {
          name: 'Video',
          icon: VideoIcon,
          handleClick: addVideoBlock,
        },
        {
          name: 'Audio',
          icon: AudioIcon,
          handleClick: addAudioBlock,
        },
        {
          name: 'Template',
          icon: WhatsAppRectangleGroupIcon,
          handleClick: addWATemplateBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: handleCollectUserInformationBlocks(),
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
      ],
    },
    handleProductDiscoveryBlock(),
  ];

  const dataEmail = [
    {
      name: 'Ticket',
      icon: TicketsIcon,
      handleClick: addTicketBlock,
    },
    {
      name: 'Text',
      icon: TextIcon,
      handleClick: addTextBlock,
    },
    {
      name: 'Send Email',
      icon: EmailSendIcon,
      handleClick: addEmailSendBlock,
    },
    {
      name: 'Redirect',
      icon: RedirectIcon,
      handleClick: addRedirectBlock,
    },
    {
      name: 'Set Attribute',
      icon: AttributeIcon,
      handleClick: addSetAttribute,
    },
    {
      name: 'More',
      icon: MoreIcon,
      handleClick: () => setMoreDialogVisible(true),
    },
  ];

  const dataAllEmail = [
    {
      title: 'Add and Send Content',
      data: [
        {
          name: 'Text',
          icon: TextIcon,
          handleClick: addTextBlock,
        },
      ],
    },
    {
      title: 'Collect User Information',
      data: [
        {
          name: 'User Ratings',
          icon: CSatIcon,
          handleClick: addCsatBlock,
        },
      ],
    },
    {
      title: 'Action',
      data: [
        {
          name: 'Redirect',
          icon: RedirectIcon,
          handleClick: addRedirectBlock,
        },
        {
          name: 'Set Attribute',
          icon: AttributeIcon,
          handleClick: addSetAttribute,
        },
        {
          name: 'Send Email',
          icon: EmailSendIcon,
          handleClick: addEmailSendBlock,
        },
        {
          name: 'Ticket',
          icon: TicketsIcon,
          handleClick: addTicketBlock,
        },
        {
          name: 'Subscribe',
          icon: SubscribeIcon,
          handleClick: addSubscriptionBlock,
        },
        {
          name: 'Unsubscribe',
          icon: UnsubscribeIcon,
          handleClick: addUnsubscribeBlock,
        },
      ],
    },
  ];

  let data, allData;
  switch (selectedPlatformType) {
    case 'facebook_feed':
      data = dataFeed;
      break;
    case 'instagram_feed':
      data = dataIGFeed;
      break;
    case 'facebook_messenger':
      data = dataMessenger;
      allData = dataALLMessenger;
      break;
    case 'viber_messenger':
      data = dataViber;
      allData = dataALLViber;
      break;
    case 'webchat':
    case 'app_messenger':
      data = dataWebChat;
      allData = dataALLWebChat;
      break;
    case 'whatsapp_messenger':
      data = dataWhatsapp;
      allData = dataAllWhatsapp;
      break;
    case 'instagram_messenger':
      data = dataInstagramMessenger;
      allData = dataALLInstagramMessenger;
      break;
    case 'telegram_messenger':
      data = dataTelegram;
      allData = dataALLTelegram;
      break;
    case 'line_messenger':
      data = dataLine;
      allData = dataALLLine;
      break;
    case 'whatsapp_bsp':
      data = dataWhatsappBSP;
      allData = dataAllWhatsappBSP;
      break;
    case 'livechat_messenger':
      data = dataWebChat; // webchat dataset used. create new dataset if needed
      allData = dataALLWebChat; // webchat dataset used. create new dataset if needed
      break;
    case 'custom_email':
    case 'gmail':
    case 'office365':
      data = dataEmail;
      allData = dataAllEmail;
      break;
    default:
      data = dataMessenger;
  }

  return (
    <div className='flex flex-col items-center bg-white border uppercase text-center text-indigo-900 text-base border-gray-300 fixed top-0 ltr:right-0 rtl:left-0 h-screen w-[130px]'>
      <h4 className='pt-[30px] pb-4'>Blocks</h4>
      <div className='builder-main__dock-container'>
        {data.map((d, i) => (
          <IconCard {...d} key={i} />
        ))}
      </div>

      <MoreBlockDialog
        visible={moreDialogVisible}
        setVisible={setMoreDialogVisible}
        data={allData}
      />
    </div>
  );
};
BlockDock.propTypes = {
  selectedProject: PropTypes.object,
};
export default BlockDock;
