import {
  DateField,
  DateTimeField,
  FileUploader,
  GroupsContainer,
  IFormFieldComponent,
  MultiSelect,
  NumberField,
  TextAreaField,
  TextField,
  TimeField,
  EmailField,
  UrlField,
  PhoneNumberField,
} from '../../export';

import SingleSelectField from '../formFields/SingleSelectField';

const SingleFormField: React.FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  if (data?.is_hidden) {
    return null;
  }
  const field = data;
  switch (field.type) {
    case 'text':
      return (
        <TextField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'textarea':
      return (
        <TextAreaField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'number':
      return (
        <NumberField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'date':
      return (
        <DateField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'url':
      return (
        <UrlField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'time':
      return (
        <TimeField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'datetime':
      return (
        <DateTimeField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );

    case 'phone':
      return (
        <PhoneNumberField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'email':
      return (
        <EmailField
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'singleselect':
      return (
        <SingleSelectField
          formActionType={formActionType}
          valuePath={valuePath}
          data={data}
        />
      );
    // return (
    //   <DatalabSingleSearchableSelectMenu valuePath={valuePath} data={field} />
    // );
    case 'multiselect':
      return (
        <MultiSelect
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    case 'group':
      return (
        <GroupsContainer
          formActionType={formActionType}
          groupData={field}
          valuePath={valuePath}
        />
      );
    case 'file':
    case 'image':
    case 'video':
    case 'audio':
      return (
        <FileUploader
          formActionType={formActionType}
          valuePath={valuePath}
          data={field}
        />
      );
    default:
      return null;
  }
};

export default SingleFormField;
