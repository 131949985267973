import React from 'react';
import PropTypes from 'prop-types';

const APIContentContainer = ({ title, subtitle, children }) => {
  return (
    <div
      className={'api-fuse-content__container'}
      id={`api-fuse-section-${title.toLowerCase().split(' ').join('-')}`}
    >
      <p className={'api-fuse-content__container__title'}>{title}</p>
      <p className={'api-fuse-content__container__subtitle'}>{subtitle}</p>
      {children}
    </div>
  );
};

APIContentContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

APIContentContainer.defaultProps = {
  title: 'Default',
  subtitle: 'provide a long valid description what it do',
};

export default APIContentContainer;
