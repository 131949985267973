import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';

const initialState = {
  holidayData: [],
};

export const holiday = {
  state: {
    ...initialState,
  },
  reducers: {
    updateHoliday(state, payload) {
      return { ...state, holidayData: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchHolidayList(teamId) {
      try {
        const res = await axios.get(config.settings.holidayList(teamId));
        if (res.status === 200) {
          dispatch.holiday.updateHoliday(
            res.data.dataSource?.vacation_data || []
          );
        }
        return true;
      } catch (err) {
        return false;
      }
    },

    async updateHolidayList(payload) {
      try {
        const res = await axios.patch(
          config.settings.holidayList(payload.teamId),
          payload.data
        );

        if (res.data.success) {
          dispatch.holiday.fetchHolidayList(payload.teamId);
          return res.data.success;
        }
      } catch (err) {}
    },
  }),
};
