import { LiveChatDataProps } from 'pages/integration/interface';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEffect, useRef, useState } from 'react';
import { Switch } from '@headlessui/react';
import { ArrowLeftIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import InputFiledWithLimit from '../../common/InputFieldWithLimit';
import { channelInfo, classNames } from 'utilities/utils';
import { useNavigate } from '@reach/router';
import { VerticalDotIcons } from '../../../assets/img/icons/svgIcons';
import DropDown from '../../common/DropDownWithButton';
import { visibilityOption } from '../../../utils/constent';
import ReactTooltip from 'react-tooltip';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  updateLiveChatProperty: (key: string, value: any) => void;
  liveChatData: LiveChatDataProps;
}
const ChannelComponent: React.FC<props> = ({
  updateLiveChatProperty,
  liveChatData,
}) => {
  const { t } = useTranslation();
  const [channelList, updateList] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editableChannel, setEditableChannel] = useState<any>({});
  const [changeSaveColor, setChangeSaveColor] = useState<boolean>(false);
  const [alreadyClickedForBack, setClickVal] = useState<boolean>(false);

  const divRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    updateList([
      ...(liveChatData.livechat_data.social_chat_options_list
        ? liveChatData.livechat_data.social_chat_options_list
        : []),
    ]);
    // eslint-disable-next-line
  }, [liveChatData.livechat_data.social_chat_options_list]);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedChannelList = reorder(
      channelList,
      result.source.index,
      result.destination.index
    );

    updateList([...updatedChannelList]);
    updateLiveChatProperty('social_chat_options_list', [...updatedChannelList]);
  };

  const isChannelActive = (id: number) => {
    const addedList = liveChatData.livechat_data['social_chat_options_list'];
    if (addedList) {
      let channel = addedList.filter((data: any) => data.id === id);

      return channel.length !== 0 ? channel[0]?.is_enabled : false;
    }

    return false;
  };

  const handleActiveChange = (channelData: any) => {
    const addedList: any =
      liveChatData.livechat_data['social_chat_options_list'];
    if (addedList?.length > 0) {
      const channel = addedList.filter(
        (data: any) => data.id === channelData.id
      );
      if (channel.length > 0) {
        channel[0].is_enabled = !channel[0].is_enabled;
        channel[0].visibility_option = channel[0].is_enabled
          ? 'always'
          : 'never';
      } else {
        addedList.push({
          id: channelData.id,
          type: channelData.type,
          name: channelData.title,
          is_enabled: true,
          visibility_option: 'always',
          label_text: `${channelInfo(channelData.type).title}`,
        });
      }
      updateLiveChatProperty('social_chat_options_list', [...addedList]);
    } else {
      const arr = [
        {
          id: channelData.id,
          type: channelData.type,
          name: channelData.title,
          is_enabled: true,
          visibility_option: 'always',
          label_text: `${channelInfo(channelData.type).title}`,
        },
      ];
      updateLiveChatProperty('social_chat_options_list', [...arr]);
    }
  };

  const handleEditValidation = (channelData: any) => {
    setEditableChannel(channelData);
    setIsEditing(true);
    setClickVal(false);
    setChangeSaveColor(false);
  };

  const getChannelList = () => {
    return channelList
      .filter((data: any) => !!data.is_connected)
      .map((data: any, i: number) => {
        return (
          <Draggable draggableId={`id-${i}`} index={i} key={i}>
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className='flex w-full items-center'>
                  {/* <img
                  src='https://s3-ap-southeast-1.amazonaws.com/stage-alice-v3/misc/60a731d8292111ed9327aabd51bd03e3.png'
                  width={20}
                  alt='6-dots'
                  className='mr-2'
                /> */}
                  <VerticalDotIcons width='24' height='24' />
                  <div className='flex items-center w-[95%] cursor-pointer px-4 py-3 rounded-lg  bg-white my-2 border border-gray-300 ml-2 hover:bg-[#F9FAFB] hover:shadow'>
                    <div
                      className='rounded-md  ltr:mr-2 rtl:ml-2'
                      onClick={() => {
                        handleEditValidation({ ...data });
                      }}
                    >
                      <img
                        src={channelInfo(data.type).image}
                        width={32}
                        height={32}
                        alt='social_channel'
                      />
                    </div>

                    <div
                      className='w-full'
                      onClick={() => {
                        handleEditValidation({ ...data });
                      }}
                    >
                      <p className='text-gray-600 flex font-medium'>
                        {channelInfo(data.type).title === 'Messenger'
                          ? 'Facebook Messenger'
                          : t(channelInfo(data.type).title)}
                      </p>
                      <p className='text-[#0078CF] w-[70%] flex truncate text-sm'>
                        {data.name}
                      </p>
                    </div>

                    <div className='ltr:ml-auto relative rtl:mr-auto z-50'>
                      <Switch
                        checked={isChannelActive(data.id)}
                        onChange={(e) => {
                          handleActiveChange(data);
                        }}
                        className={classNames(
                          isChannelActive(data.id)
                            ? 'bg-green-600'
                            : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                        )}
                      >
                        <span className='sr-only'>{t('Use setting')}</span>
                        <span
                          aria-hidden='true'
                          className={classNames(
                            isChannelActive(data.id)
                              ? 'ltr:translate-x-5 rtl:-translate-x-5'
                              : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Draggable>
        );
      });
  };

  const getVisibilityOptionName = (value: string) => {
    const selectedItem = visibilityOption.filter(
      (data) => data.value === value
    );
    return selectedItem[0].name;
  };

  const editPanel = () => {
    const handleSaveChanges = () => {
      const addedList: any =
        liveChatData.livechat_data['social_chat_options_list'];
      let updatedList = addedList.map((data: any) =>
        data.id === editableChannel.id ? editableChannel : data
      );
      updateLiveChatProperty('social_chat_options_list', [...updatedList]);
      setChangeSaveColor(false);
      setIsEditing(false);
    };

    return (
      <div className='rounded-md border  border-gray-200 mr-3'>
        <div className='flex rounded-t-md relative items-center p-4 w-full bg-gray-100 border-b border-gray-200'>
          <div
            ref={divRef}
            className='absolute top-5 left-10 w-0 h-3 bg-red-100'
            data-tip='tooltip'
            data-for='back_without_save_warning'
          ></div>
          <ArrowLeftIcon
            width={20}
            height={20}
            color='#4B5563'
            onClick={() => {
              if (changeSaveColor && !alreadyClickedForBack) {
                divRef.current?.click();
                setClickVal(true);
              } else {
                setIsEditing(false);
              }
            }}
          />
          <span className='font-medium text-gray-600 ml-2'>
            {channelInfo(editableChannel.type).title}
          </span>

          <button
            className={classNames(
              `ml-auto`,
              changeSaveColor ? 'text-[#0078CF]' : 'text-gray-400 '
            )}
            data-tip='tooltip'
            data-for='edit_not_saved_msg'
            onClick={() => {
              changeSaveColor && handleSaveChanges();
            }}
          >
            Save Changes
          </button>
        </div>
        <div className='mt-2'>
          <div className='p-4 '>
            <label
              htmlFor='c-visibility'
              className='block text-sm font-medium text-gray-700'
            >
              Channel Visibility
            </label>
            {/* <select
              id='c-visibility'
              name='c-visibility'
              className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              defaultValue=''
              value={editableChannel.visibility_option}
              onChange={(event) => {
                editableChannel.visibility_option = event.target.value;
                setEditableChannel({ ...editableChannel });
                setChangeSaveColor(true);
              }}
            >
              <option value={'always'}>Always</option>
              <option value={'inside_business_hours'}>
                Inside Business Hours
              </option>
              <option value={'outside_business_hours'}>
                Outside Business Hours
              </option>
              <option value={'never'}>Never</option>
            </select> */}

            <div className='mt-3 w-full'>
              <DropDown
                classes='w-full flex items-center  text-gray-600 hover:text-gray-600 focus:outline-none'
                align='left-0'
                width='w-full'
                isFullWidth={true}
                buttonComponent={
                  <div className='border w-full border-gray-300 rounded-md pl-3 pr-10 py-2 text-left focus:outline-none focus:border-gray-300 sm:text-sm'>
                    {getVisibilityOptionName(editableChannel.visibility_option)}
                    <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                      <ChevronUpDownIcon
                        className='w-5 h-5 text-gray-400'
                        aria-hidden='true'
                      />
                    </span>
                  </div>
                }
                options={visibilityOption}
                handleDataSelection={(data: any) => {
                  editableChannel.visibility_option = data.value;
                  setEditableChannel({ ...editableChannel });
                  setChangeSaveColor(true);
                }}
                value={editableChannel.visibility_option}
              />
            </div>
          </div>
          <div className='my-2 h-[1px] bg-gray-200 w-full'></div>
          <div className='p-4'>
            <InputFiledWithLimit
              limit={32}
              label={'Label Text'}
              placeHolder={
                channelInfo(editableChannel.type).title === 'Messenger'
                  ? 'FB Messenger'
                  : channelInfo(editableChannel.type).title
              }
              value={editableChannel.label_text}
              handleOnChange={(val: string) => {
                editableChannel.label_text = val;
                setEditableChannel({ ...editableChannel });
                setChangeSaveColor(true);
              }}
            />
          </div>
        </div>
        <ReactTooltip
          disable={!changeSaveColor || alreadyClickedForBack}
          delayShow={1}
          id='back_without_save_warning'
          aria-haspopup='true'
          effect='solid'
          place='top'
          event='click'
        >
          <span>Changes may not be saved yet. Click again to discard.</span>
        </ReactTooltip>
        <ReactTooltip
          disable={changeSaveColor}
          delayShow={1}
          id='edit_not_saved_msg'
          aria-haspopup='true'
          effect='solid'
        >
          <span>No changes made yet</span>
        </ReactTooltip>
      </div>
    );
  };

  return (
    <>
      <div>
        {!isEditing ? (
          <div className='rounded-md w-full border border-gray-200'>
            <div className='bg-gray-100 rounded-t-md w-full p-4 text-gray-600 text-base font-semibold border-b border-gray-200'>
              {t('Available Channels')}
            </div>
            <div className='mt-1 p-4'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable' direction='vertical'>
                  {(provided: any) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {getChannelList()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <p
                className='mt-2 text-[#0078CF] flex w-full font-medium cursor-pointer'
                onClick={() => {
                  navigate('/integrations/available-integrations');
                }}
              >
                {t('Add more channels')}
              </p>
            </div>
          </div>
        ) : (
          editPanel()
        )}
      </div>
    </>
  );
};

export default ChannelComponent;
