import { Dialog, Transition } from '@headlessui/react';
import useTranslation from 'components/customHooks/useTranslation';
import Button from 'library/button';
import { EmptyState } from 'pages/inbox/assets/iconComponent/SearchComponentIcons';
import { MagnifyingGlassIcon } from 'pages/inbox/assets/iconComponent/TopBarIcons';
import { Fragment, useState } from 'react';

interface Props {
  open: boolean;
  title: string;
  subTitle: string;
  buttonText: string;
  isDataEmpty?: boolean;
  isApplyDisabled?: boolean;
  searchPlaceholder?: string;
  children?: React.ReactNode;
  handleApply: () => void;
  handleCloseDialog: () => void;
  handleSearchKeyChange: (key: string) => void;
}

const BulkAssignTagSelector: React.FC<Props> = ({
  open,
  title,
  children,
  subTitle,
  buttonText,
  handleApply,
  isApplyDisabled,
  handleCloseDialog,
  isDataEmpty = false,
  handleSearchKeyChange,
  searchPlaceholder = 'search',
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const renderEmptySearch = () => {
    return (
      <div className='px-4 text-center py-14 sm:px-14'>
        <EmptyState className='mx-auto text-gray-400' aria-hidden='true' />
        <p className='mt-4 text-sm text-gray-500'>
          {t(`Nothing found related to {{${query}}}`)}
        </p>
      </div>
    );
  };
  const mainRenderView = () => {
    if (query !== '' && isDataEmpty) {
      return renderEmptySearch();
    } else {
      return <div className=' max-h-80 overflow-y-auto mt-2'>{children}</div>;
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleCloseDialog}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-20 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 overflow-y-auto'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-md bg-white  py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                {/* top view */}
                <span className='flex mb-1 text-base font-semibold text-gray-800 leading-6'>
                  {t(title)}
                </span>
                <span className='flex mb-2 text-sm font-normal text-gray-500 '>
                  {t(subTitle)}
                </span>

                <div className='flex items-center rounded-md shadow-sm ring-1 ring-gray-300 focus-within:ring-1 focus-within:ring-inset focus:border-0 focus-within:ring-primary  sm:max-w-md'>
                  <MagnifyingGlassIcon className='select-none items-center pl-3 mr-2 text-gray-500 sm:text-sm w-6 h-6' />
                  <input
                    type='text'
                    className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-500 focus:ring-0 sm:text-sm sm:leading-6'
                    placeholder={searchPlaceholder}
                    onChange={(e) => {
                      handleSearchKeyChange(e.target.value);
                      setQuery(e.target.value);
                    }}
                  />
                </div>

                {/* component view */}

                {mainRenderView()}

                {/* bottom view */}
                <div className='border-t border-gray-200 justify-between mt-5 flex items-center pt-4'>
                  <button
                    className='text-[#0078CF] text-sm font-medium leading-5'
                    onClick={() => handleCloseDialog()}
                  >
                    {t('Back to select')}
                  </button>
                  <Button
                    size='md'
                    isDisabled={isApplyDisabled}
                    intent='primary'
                    className=''
                    onClick={() => handleApply()}
                  >
                    {t(buttonText)}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BulkAssignTagSelector;
