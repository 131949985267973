import React from 'react';
import emptyState from './../../../../assets/images/emptyStateProductInteraction.svg';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import VariantSelection from '../../eCommerceOrderCreate/components/VariantSelection';
import { ButtonGroup } from '../../../../../../../library';
import {
  VariantProps,
  VariantListProps,
  ProductDataProps
} from '../../eCommerceOrderCreate/interfaces';
import {truncate} from './../../eCommerceOrderCreate/utils';

interface Prop {
  product: ProductDataProps;
  handleRemove: (product: ProductDataProps, index: number) => void;
  handleProductQtyChange: (
    product: ProductDataProps,
    qty: number,
    index: number
  ) => void;
  isVariantLoading: boolean;
  variantLists: VariantProps[];
  index: number;
  handleVariantSelect: (
    option: VariantListProps,
    productId: string | number,
    index: number
  ) => void;
  currencySymbol: string;
}

const SelectedProductListItem: React.FC<Prop> = ({
  product,
  handleRemove,
  handleProductQtyChange,
  isVariantLoading,
  variantLists,
  index,
  handleVariantSelect,
  currencySymbol,
}) => {
  const [variantCostState, setVariantCostState] = React.useState<string>(product.unit_price.toFixed(2));

  const handleQuantity = (type: 'increment' | 'decrement') => {
    if (type === 'increment') {
      handleProductQtyChange(product, product.quantity + 1, index);
    } else if (type === 'decrement' && product.quantity > 1) {
      handleProductQtyChange(product, product.quantity - 1, index);
    }
  };

  const buttonGroupData2 = [
    { icon: <ChevronLeftIcon />, onClick: () => handleQuantity('decrement') },
    {
      name:
        product.quantity > 9
          ? product.quantity.toString()
          : '0' + product.quantity,
      className: 'cursor-not-allowed',
    },
    { icon: <ChevronRightIcon />, onClick: () => handleQuantity('increment') },
  ];

  const getVariantList: any = () => {
    const currentItem = variantLists.filter(
      (variant: VariantProps) =>
        variant.id.toString() === product.product_id.toString()
    );
    if (currentItem.length > 0 && product.has_variations) {
      return currentItem[0].dataSource;
    }
    return [];
  };

  React.useEffect(() => {
    setVariantCostState(product.unit_price.toFixed(2));
  }, [product.unit_price]);

  return (
    <div className='block mt-4 border rounded-md cursor-default hover:bg-gray-50'>
      <div className='flex items-start px-4 py-4 sm:px-6'>
        <div className='flex items-center flex-1 min-w-0'>
          <div className='flex-shrink-0'>
            <img
              className='w-12 h-12 rounded-md'
              src={
                product.product_images.length > 0
                  ? product.product_images[0]
                  : emptyState
              }
              alt=''
            />
          </div>
          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-6 md:gap-4'>
            <div className='md:col-span-5'>
              <p className='text-sm font-medium truncate text-primary'>
                {product.product_name}
              </p>
              <p className='flex items-center mt-2 text-xs text-gray-500'>
                <span className='truncate'>
                  {product.has_variations
                    ? truncate(product.variant_attributes_string,
                      'Variant Not Selected', 80)
                    : 'No Variation Available'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='flex items-center'>
          <span className='inline-flex mx-4 items-center px-3 py-0.5 rounded-md text-xs font-medium bg-yellow-100 text-yellow-800'>
            Selected
          </span>
        </div>
        <div className='flex items-center'>
          <button
            onClick={() => {
              handleRemove(product, index);
            }}
            className='rounded-sm focus:outline-none hover:bg-gray-100 p-0.5'
          >
            <XMarkIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div className='flex flex-wrap items-center justify-between px-4 pb-4 sm:px-6'>
        <div className='flex flex-wrap items-center'>
          {product.has_variations && (
            <div className='w-full mr-0 sm:mr-2 sm:w-auto'>
              <VariantSelection
                options={getVariantList()}
                variantInfo={{
                  variant_id: product.variant_id,
                  variant_attributes_string: product.variant_attributes_string,
                }}
                handleVariantSelect={(value: VariantListProps) => {
                  handleVariantSelect(value, product.product_id, index);
                  setVariantCostState(value.unit_price.toFixed(2));
                }}
                isDisabled={isVariantLoading}
              />
            </div>
          )}
          <ButtonGroup buttonData={buttonGroupData2} size={'md'} />
        </div>
        <p className='w-full mt-2 sm:mt-0 sm:w-auto'>
          Price:
          <span className='inline-flex ml-2 items-center px-3 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800'>
            {currencySymbol}
            {(product.quantity * parseFloat(variantCostState)).toFixed(2)}
          </span>
        </p>
      </div>
    </div>
  );
};

export default SelectedProductListItem;
