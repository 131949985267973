import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toaster } from 'evergreen-ui';
import { exportCSV } from '../../../../utilities/utils';
import { isArray } from 'lodash';

const initialState = {
  fields: [],
  data: [],
  selectedLab: 0,
  total: 0,
};

export const labDetails = {
  state: {
    ...initialState,
  },
  reducers: {
    updateEntries(state, payload) {
      /*
      payload = {
          success: bool,
          dataSource: array
          count: number
       }
      * */

      if (!!payload.dataSource) {
        payload.dataSource.forEach((element) => {
          for (let key in element) {
            if (isArray(element[key])) {
              element[key] = JSON.stringify(element[key]);
            }
          }
        });
      }

      return { ...state, data: payload.dataSource, total: payload.count };
    },
    addSingleEntry(state, data) {
      /*
      data = {'1': value, '2': 'value', '3': 'value'}
      * */
      const dataLocal = [...state.data];
      dataLocal.pop();
      return { ...state, data: [data, ...dataLocal] };
    },
    updateSingleEntry(state, payload) {
      /*
      payload = {
          entryId: num,
          data: {'1': value, '2': 'value', '3': 'value'}
       }
      * */
      const data = state.data.map((d) => {
        if (d.id === payload.entryId) {
          return { ...payload.data };
        }
        return { ...d };
      });
      return { ...state, data };
    },
    removeSingleEntry(state, entryId) {
      /*
      entryId = num
      * */
      const data = state.data.filter((d) => d.id !== entryId);
      return { ...state, data };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchData(payload) {
      /*
      payload = {
      labId : num,
      limit: num
      offset: num
      conditions: string
      dadeRange: object,
      filter: boolean
       */
      try {
        let res;
        if (payload.filter) {
          res = await axios.get(
            `${config.datalab.entries(payload.labId)}?start=${
              payload.dateRange.startDate
            }&end=${[payload.dateRange.endDate]}${
              !!payload.conditions ? payload.conditions : ''
            }`
          );
        } else {
          res = await axios.get(
            `${config.datalab.entries(payload.labId)}?limit=${
              payload.limit
            }&offset=${payload.offset}&start=${
              payload.dateRange.startDate
            }&end=${[payload.dateRange.endDate]}${
              !!payload.conditions ? payload.conditions : ''
            }`
          );
        }
        if (res.status === 200 && res.data.success) {
          dispatch.labDetails.updateEntries(res.data);
        } else {
          toaster.danger('Failed to Fetch Data', {
            description: `We are failed to fetch data ! Try Again Later`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },

    async createEntry(payload) {
      /*
      payload = {
      labId : num,
      data: {'1': value, '2': 'value', '3': 'value'}
   }
       */
      try {
        const res = await axios.post(
          config.datalab.entries(payload.labId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.labDetails.addSingleEntry(res.data.dataSource);
          toaster.success('Entry Created Successfully');
        } else {
          toaster.danger('Failed to Fetch Data', {
            description: `We are failed to fetch data ! Try Again Later`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },

    async editEntry(payload) {
      /*
      payload = {
      labId : num,
      entryId: num,
      data: {'1': value, '2': 'value', '3': 'value'}
   }
       */
      try {
        const res = await axios.put(
          config.datalab.entriesAction(payload.labId, payload.entryId),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.labDetails.updateSingleEntry({
            entryId: payload.entryId,
            data: res.data.dataSource,
          });
          toaster.success('Entry Updated Successfully');
        } else {
          toaster.danger('Failed to Fetch Data', {
            description: `We are failed to fetch data ! Try Again Later`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },

    async deleteEntry(payload) {
      /*
      payload = {
      labId : num,
      entryId: num,
   }
       */
      try {
        const res = await axios.delete(
          config.datalab.entriesAction(payload.labId, payload.entryId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.labDetails.removeSingleEntry(payload.entryId);
          toaster.success('Entry Deleted Successfully');
        } else {
          toaster.danger('Failed to Fetch Data', {
            description: `We are failed to fetch data ! Try Again Later`,
          });
        }
      } catch (err) {
        toaster.danger('Exception Error', {
          description: `There are some exception error in the request`,
        });
      }
    },

    async exportDataLab(payload) {
      const { labId, startDate, endDate, conditions } = payload;
      try {
        const res = await axios.get(
          `${config.datalab.export(labId)}?start=${startDate}&end=${[endDate]}${
            !!conditions ? conditions : ''
          }`
        );
        if (res.status === 200) {
          exportCSV(res.data, `datalab--${labId}_export`);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async importDataLab(payload) {
      const { labId, file } = payload;
      try {
        const res = await axios.post(config.datalab.import(labId), file);
        if (res.status === 200 && res.data.success) {
          toaster.success('Imported Successfully');
          return true;
        } else {
          toaster.danger('Imported Failed');
          return false;
        }
      } catch (err) {
        console.log(err);
        toaster.danger('Imported Failed');
        return false;
      }
    },
  }),
};
