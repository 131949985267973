import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { toaster, Icon, Spinner, IconButton } from 'evergreen-ui';
import { useDropzone } from 'react-dropzone';

const PDFUploadField = ({
  file,
  handleDrop,
  handleClear,
  maxFileSize,
  isLoading = false,
}) => {
  const fileSizeInMb = parseFloat((maxFileSize / (1024 * 1024)).toFixed(2));
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    onDrop: (files) => {
      setLoading(true);
      handleDrop(files[0]);
      setTimeout(() => setLoading(false), 1500);
    },
    onDropRejected: () => {
      toaster.danger('Failed', {
        description: `File too large. Maximum file size is ${fileSizeInMb}MB`,
        duration: 1,
      });
    },
    multiple: false,
    maxSize: maxFileSize,
  });

  return (
    <section className='file-upload'>
      <div {...getRootProps()} className='file-upload__field'>
        {!file && !loading && !isLoading && (
          <Fragment>
            <input {...getInputProps()} />
            <Icon icon='document' size={24} />
            <p>Drag 'n' Drop your file here</p>
            <p>
              Allowed file type .pdf. Maximum size allowed {fileSizeInMb}MB.
            </p>
          </Fragment>
        )}

        {(loading || isLoading) && <Spinner />}

        {file && !loading && !isLoading && (
          <Fragment>
            <IconButton
              icon='cross'
              appearance='minimal'
              intent={'danger'}
              onClick={() => handleClear()}
            />
            <p>{file}</p>
          </Fragment>
        )}
      </div>
    </section>
  );
};

PDFUploadField.propTypes = {
  file: PropTypes.string,
  handleDrop: PropTypes.func,
  handleClear: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PDFUploadField;
