import React, { useState } from 'react';
import BlockContainer from './BlockContainer';
import InputOutputViewNavBar from '../elements/eCommerceBlock/InputOutputViewNavBar';
import BetterdocsInputInstruction from '../elements/othersBlocks/BetterdocsInputInstructuions';
import BetterdocsOutputInstruction from '../elements/othersBlocks/BetterdocsOutputInstruction';
import betterdocsIconSet from '../../../assets/icons/channelIconSet/betterdocs.svg';
import { toaster } from 'evergreen-ui';
import { BlocksData } from '../../../utilities/content';

interface Props {
  id: number;
  data: dataProps;
  save: boolean;
  type: string;
  labsData: [];
  platformType: string;
  subscriptionPlanType: string;
  attributeList: string[];
  sequences: sequenceProps[];
  saveLoader: boolean;
  saveBlock: (id: number) => void;
  hasWriteAccess: boolean;
  deleteBlock: (id: number) => void;
  copyBlock: () => void;
  moveBlock: () => void;
  updateBetterdocsBlockData: (
    payload: { name: string; value: string | number | boolean | null },
    changeKey: string,
    indexValue: number
  ) => void;
  handleBetterdocsBlockGalleryButton: (data: {}, actionType: string) => void;
}

interface sequenceProps {
  id: number;
  title: string;
  is_default: boolean;
}

interface dataProps {
  input_instructions: inputInstruction[];
  output_instructions: {
    display_image: boolean;
    title: string;
    subtitle: string;
    url: string;
    buttons: [];
  };
}

interface inputInstruction {
  name: string;
  type: string;
  value: string;
  default: string | number | null;
  verbose: string;
  isPinned: boolean;
}

const BetterdocsBlock: React.FC<Props> = ({
  id,
  save,
  sequences,
  saveLoader,
  saveBlock,
  hasWriteAccess,
  deleteBlock,
  copyBlock,
  moveBlock,
  data,
  attributeList,
  platformType,
  subscriptionPlanType,
  updateBetterdocsBlockData,
  type,
  labsData,
  handleBetterdocsBlockGalleryButton,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState({
    id: 0,
    name: 'Input Instructions',
  });
  const [selectedSequence, setSelectedSequence] = useState(
    data['no_docs_sequence']
  );
  const [showAttributeField, setShowAttributeField] = useState(null);
  const handleAttributePopOver = (val: any) => {
    setShowAttributeField(val);
  };
  const [search, setSearch] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  const handleTabChange = (tab: any) => {
    setCurrentTabIndex(tab);
  };

  const tabData = [
    { id: 0, name: 'Input Instructions' },
    { id: 1, name: 'Output View' },
  ];

  const validateDataOnSave = () => {
    if (data.input_instructions.length < 1) {
      toaster.danger(`Search can not be empty`, { duration: 2 });
      return;
    }
    // @ts-ignore
    if (data?.input_instructions[0].value.length < 1) {
      // @ts-ignore
      toaster.danger(
        `${data?.input_instructions[0].verbose} can not be empty`,
        { duration: 2 }
      );
      return;
    }
    if (!data?.output_instructions.title) {
      // @ts-ignore
      toaster.danger(`Title can not be empty`, { duration: 2 });
      return;
    }
    if (!data?.output_instructions.subtitle) {
      // @ts-ignore
      toaster.danger(`Subtitle can not be empty`, { duration: 2 });
      return;
    }

    if (
      data?.output_instructions.title &&
      data?.output_instructions.title.length < 1
    ) {
      // @ts-ignore
      toaster.danger(`Title can not be empty`, { duration: 2 });
      return;
    }

    if (
      data?.output_instructions.subtitle &&
      data?.output_instructions.subtitle.length < 1
    ) {
      // @ts-ignore
      toaster.danger(`Subtitle can not be empty`, { duration: 2 });
      return;
    }
    saveBlock(id);
  };

  const handleSelectedParamsInputValue = (
    id: any,
    name: string,
    value: string,
    changeKey: string
  ) => {
    let currentValue = value;
    let lastTwoChar = currentValue.slice(
      currentValue.length - 2,
      currentValue.length
    );
    if (lastTwoChar === '{{')
      setShowAttributeField(changeKey === 'input_instructions' ? id : name);
    else setShowAttributeField(null);

    updateBetterdocsBlockData({ name: name, value: value }, changeKey, id);
  };

  const handleSelectedParamsAttributeValue = (
    id: any,
    name: string,
    value: string | boolean,
    changeKey: string
  ) => {
    setShowAttributeField(null);
    let newValue =
      changeKey === 'input_instructions'
        ? data?.input_instructions[0]['value'] + `${value}}}`
        : data?.output_instructions[`${name}`] + `${value}}}`;
    updateBetterdocsBlockData({ name: name, value: newValue }, changeKey, id);
  };

  const updateSequence = (name: string, val: number) => {
    updateBetterdocsBlockData(
      { name: name, value: val },
      `no_docs_sequence`,
      0
    );
  };

  const updateDisplayImage = (
    id: any,
    name: string,
    value: boolean,
    changeKey: string
  ) => {
    updateBetterdocsBlockData(
      { name: name, value: value },
      changeKey,
      id
    );
  };

  return (
    <BlockContainer
      id={id}
      title='Betterdocs'
      subtitle='Block Details'
      save={save}
      isLoading={saveLoader}
      icon={betterdocsIconSet}
      sequences={sequences}
      handleSaveClick={() => validateDataOnSave()}
      handleDelete={() => deleteBlock(id)}
      handleCopy={copyBlock}
      handleMove={moveBlock}
      hasWriteAccess={hasWriteAccess}
    >
      <>
        <InputOutputViewNavBar
          tabData={tabData}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
        />
        <br />
        <div className='border-t border-gray-200 mb-2.5 w-full' />
        {currentTabIndex.id === 0 ? (
          <BetterdocsInputInstruction
            id={id}
            inputParameters={data?.input_instructions}
            hasWriteAccess={hasWriteAccess}
            attributeList={attributeList}
            showAttributeField={showAttributeField}
            sequences={sequences}
            platformType={platformType}
            selectedSequence={selectedSequence}
            setSelectedSequence={setSelectedSequence}
            setShowAttributeField={handleAttributePopOver}
            handleSelectedParamsInputValue={handleSelectedParamsInputValue}
            hasSequences={true}
            search={search}
            setSearch={setSearch}
            updateSequence={updateSequence}
            handleSelectedParamsAttributeValue={
              handleSelectedParamsAttributeValue
            }
            data={data}
          />
        ) : (
          <BetterdocsOutputInstruction
            blockId={id}
            type={type}
            sequences={sequences}
            platformType={platformType}
            subscriptionPlanType={subscriptionPlanType}
            labsData={labsData}
            buttons={data?.output_instructions.buttons}
            hasWriteAccess={hasWriteAccess}
            outputInstructions={data?.output_instructions}
            showAttributeField={showAttributeField}
            setShowAttributeField={handleAttributePopOver}
            handleSelectedParamsAttributeValue={
              handleSelectedParamsAttributeValue
            }
            handleSelectedParamsInputValue={handleSelectedParamsInputValue}
            handleBetterdocsBlockGalleryButton={
              handleBetterdocsBlockGalleryButton
            }
            showImage={data.output_instructions.display_image}
            title={title}
            setTitle={setTitle}
            subtitle={subtitle}
            setSubtitle={setSubtitle}
            outputParameters={BlocksData.betterdocsBlock.parameters}
            data={data}
            updateDisplayImage={updateDisplayImage}
          />
        )}
      </>
    </BlockContainer>
  );
};

export default BetterdocsBlock;
