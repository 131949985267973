import React from 'react';
import verifiedEmailIcon from '../assets/images/verified-email-icon.svg';
import { authData } from '../../../utilities/content';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import AuthHeader from './AuthHeader';
import { Button } from '../../../library';

const AlreadyVerifiedMail = ({ clearAlreadyVerifiedProps }) => {
  return (
    <div>
      <AuthHeader
        image={verifiedEmailIcon}
        title={authData.verifyRegister.alreadyVerifiedTitle}
        subtitle={authData.verifyRegister.alreadyVerifiedSubTitle}
        link={authData.registerInfo.link}
        linkText={authData.registerInfo.linkTitle}
        isLogo={false}
      />
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='px-4 py-4 sm:rounded-lg sm:px-10'>
          <div className='space-y-6'>
            <div>
              <Button
                intent='primary'
                isFullWidth={true}
                onClick={() => {
                  clearAlreadyVerifiedProps();
                  navigate(`/dashboard`);
                }}
              >
                {authData.verifyRegister.alreadyVerifiedButtonTitle}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AlreadyVerifiedMail.propTypes = {
  clearAlreadyVerifiedProps: PropTypes.func,
};
export default AlreadyVerifiedMail;
