import React, { useEffect } from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import {
  channelListProps,
  sequenceProps,
  IWhatsappTemplateButton,
} from 'pages/integration/interface';
import {
  ArrowRightIcon,
  InformationCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/solid';
import WhatsappTemplateButton from './WhatsappTemplateButton';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import { WhatsappTemplateButtonLimits } from 'pages/integration/utils/content';
import ReactTooltip from 'react-tooltip';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';

interface Props {
  actionType: WhatsappTemplateActionType;
  sequence: sequenceProps[];
  channelData?: channelListProps;
}

const WhatsappTemplateButtonBlock: React.FC<Props> = ({
  actionType,
  sequence,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const {
    whatsappTemplateState,
    deleteTemplateButton,
    addNewTemplateButton,
    updateTemplateButton,
  } = useWhatsappTemplateData();

  const buttonList: IWhatsappTemplateButton[] =
    whatsappTemplateState?.buttons || [];

  const isTemplateActionView = actionType === 'VIEW';

  const isAddButtonDisabled =
    whatsappTemplateState.buttons.length >=
    WhatsappTemplateButtonLimits.maxTotalButtonLimit;

  const shouldHideButtonSectionDescription =
    isTemplateActionView && buttonList.length === 0;

  const handleAddButton = () => {
    addNewTemplateButton();
  };

  const handleUpdateButtonTypeSelect = (
    buttonData: IWhatsappTemplateButton
  ) => {
    updateTemplateButton({
      ...buttonData,
    });
  };

  const handleDeleteButton = (buttonData: IWhatsappTemplateButton) => {
    deleteTemplateButton(buttonData.id);
  };

  return (
    <div
      className={`bg-white mt-2 shadow-sm rounded-md flex flex-col p-4 ${
        shouldHideButtonSectionDescription ? 'hidden' : 'visible'
      }`}
    >
      <div className='flex gap-1 flex-col'>
        <h3 className='block text-sm font-medium text-gray-700'>
          {t('Button Properties')}
        </h3>
        <div
          className={cn('flex bg-blue-50 gap-3 p-4 rounded-md', {
            hidden: isTemplateActionView,
          })}
        >
          <div>
            <InformationCircleIcon className='h-5 w-5 text-blue-400' />
          </div>
          <div className='leading-5 font-normal text-sm text-blue-800'>
            <p>
              {t(
                'You can add up to 10 buttons of different types together, with maximum of 1 Call Phone Number type, 2 URL type and 7 sequence type.'
              )}{' '}
              {t(
                'How you arrange the buttons can impact the template approval process! Add Sequence type buttons one after another.'
              )}{' '}
              {t(
                'For example, (Sequence, Sequence, URL, Call Phone Number) or (URL, Call Phone Number, Sequence, Sequence).'
              )}{' '}
              {t(
                'Avoid arrangements such as (Sequence, URL, Call Phone Number, Sequence). And 2 URL type buttons will always show one after another to the recipient.'
              )}
            </p>
            {/* currently hidden. will show it once we have tutorial ready. */}
            <a
              href='https://docs.myalice.ai/myalice-for-whatsapp/overview/whatsapp-message-templates-best-practices'
              target='_blank'
              className='font-medium hidden items-center gap-1'
              rel='noreferrer'
            >
              <p>{t('Watch Tutorial')}</p>
              <ArrowRightIcon className='h-4 w-4 rtl:rotate-180' />
            </a>
          </div>
        </div>
      </div>
      <div className={`${buttonList.length ? 'mt-4' : 'hidden'}`}>
        <div className='flex flex-col gap-3'>
          {buttonList?.map((singleButton) => (
            <div className='w-full'>
              <WhatsappTemplateButton
                actionType={actionType}
                sequenceList={sequence}
                buttonData={singleButton}
                handleUpdateButton={handleUpdateButtonTypeSelect}
                buttonList={whatsappTemplateState.buttons}
                handleDeleteButton={handleDeleteButton}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`${isTemplateActionView ? 'hidden' : ''} mt-4`}>
        <button
          data-tip
          data-for='whatsapp-template-add-button'
          onClick={handleAddButton}
          disabled={isAddButtonDisabled}
          className={`px-3 py-2 flex gap-2 items-center border rounded-md border-gray-300 ${
            isAddButtonDisabled
              ? 'cursor-not-allowed bg-gray-200 text-gray-500'
              : ''
          }`}
        >
          <PlusIcon className='w-4 h-4 text-gray-500 font-bold stroke-2' />
          <span className='text-sm font-medium text-gray-700'>
            {t('Add Buttons')}
          </span>
        </button>
        {/* @ts-ignore */}
        <ReactTooltip
          id='whatsapp-template-add-button'
          place='right'
          type='dark'
          effect='solid'
          disable={!isAddButtonDisabled}
          backgroundColor='#4B5563'
          multiline={true}
          className='max-w-[350px] sm:text-sm text-sm whitespace-pre-wrap break-words'
        >
          <span>
            {t(
              "You can't add more than 10 buttons. To add more, please delete one first."
            )}
          </span>
        </ReactTooltip>
      </div>
    </div>
  );
};
export default WhatsappTemplateButtonBlock;
