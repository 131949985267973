import React from 'react';
import { connect } from 'react-redux';
import CollapsableComponent from '../components/common/components/CollapsableComponent';
import ChannelIndex from '../components/integrated/ChannelIndex';
import NlpIndex from '../components/integrated/NlpIndex';
import EmptyPlatform from '../components/integrated/components/EmptyPlatform';

import { navigate } from '@reach/router';
import EcommerceIndex from '../components/integrated/EcommerceIndex';
import DynamicIntegratedChannels from '../components/integrated/components/dynamic';
import {
  IEcommerceChannelPreferencesProperty,
  IEcommerceChannelProperty,
  channelListProps,
  integratedOthersList,
  nlpInfoProps,
} from '../interface';
interface Props {
  selectedPlatformsList: string[];
  availablePlatformsList: string[];
  selectedProject: { id: number };
  integratedNlpList: nlpInfoProps[];
  integratedChannelList: channelListProps[];
  integratedOthersList: integratedOthersList[];
  integratedEcommerceList: IEcommerceChannelProperty | null;
  getIntegratedNlpList: (teamId: number) => boolean;
  getIntegratedChannelList: (teamId: number) => boolean;
  fetchMarketPlaceData: (teamId: number) => boolean;
  fetchMultiEcommerceData: (teamId: number) => boolean;
  fetchEcommerceChannelPreferences: (payload: any) => boolean;
  ecommerceChannelPreferencesList: IEcommerceChannelPreferencesProperty[];
  connectEcommercePlatform: (payload: any) => boolean;
  disconnectEcommercePlatform: (payload: any) => boolean;
}

export type integrationDataCheckerType = boolean | null;

const Integrated: React.FC<Props> = ({
  availablePlatformsList,
  selectedProject,
  getIntegratedNlpList,
  getIntegratedChannelList,
  integratedNlpList,
  integratedChannelList,
  integratedOthersList,
  fetchMarketPlaceData,
  fetchMultiEcommerceData,
  integratedEcommerceList,
  ecommerceChannelPreferencesList,
  fetchEcommerceChannelPreferences,
  connectEcommercePlatform,
  disconnectEcommercePlatform,
}) => {
  const [hasNlpData, setHasNlpData] =
    React.useState<integrationDataCheckerType>(null);
  const [hasChannelData, setHasChannelData] =
    React.useState<integrationDataCheckerType>(null);
  const [hasEcommerceData, setHasEcommerceData] =
    React.useState<integrationDataCheckerType>(null);

  const fetchIntegratedData = async () => {
    let nlpResponse = await getIntegratedNlpList(selectedProject.id);
    setHasNlpData(!!nlpResponse);

    let channelResponse = await getIntegratedChannelList(selectedProject.id);
    setHasChannelData(!!channelResponse);

    let multiEcommerceResponse = await fetchMultiEcommerceData(
      selectedProject.id
    );
    setHasEcommerceData(!!multiEcommerceResponse);
  };

  const filterChannelIntegratedList = (
    integratedChannelList: channelListProps[]
  ) => {
    return integratedChannelList.filter(
      (item) => item.platform_type !== 'whatsapp_messenger'
    );
  };

  React.useEffect(() => {
    fetchIntegratedData();
    // eslint-disable-next-line
  }, []);

  const getChildComponent = (platform: string) => {
    switch (platform) {
      case 'NLP':
        if (integratedNlpList.length !== 0)
          return <NlpIndex integratedNlpData={integratedNlpList} />;
        else return '';
      case 'Channel':
        if (integratedChannelList.length !== 0) {
          return (
            <ChannelIndex
              integratedChannleData={filterChannelIntegratedList(
                integratedChannelList
              )}
            />
          );
        } else return '';
      case 'E-commerce':
        if (integratedEcommerceList)
          return (
            <EcommerceIndex
              integratedEcommerceData={integratedEcommerceList}
              fetchEcommerceChannelPreferences={
                fetchEcommerceChannelPreferences
              }
              ecommerceChannelPreferencesList={ecommerceChannelPreferencesList}
              connectEcommercePlatform={connectEcommercePlatform}
              disconnectEcommercePlatform={disconnectEcommercePlatform}
            />
          );
        else return '';
      // case 'Others':
      //   if (integratedOthersList.length !== 0)
      //     return <OthersIndex integratedOthersData={integratedOthersList} />;
      //   else return '';
      default:
        return <p>{`create a ${platform} channel first`}</p>;
    }
  };

  const getChildComponentCount = (platform: string): number => {
    switch (platform) {
      case 'NLP':
        return integratedNlpList.length;
      case 'Channel':
        return integratedChannelList.length;
      case 'E-commerce':
        if (!integratedEcommerceList) return 0;
        return 1;
      case 'Others':
        return integratedOthersList.length;
      default:
        return 0;
    }
  };

  const getMainElement = () => {
    let hasIntegratedData = hasChannelData || hasNlpData || hasEcommerceData;

    switch (hasIntegratedData) {
      case true:
        return (
          <>
            {availablePlatformsList.map((platform, index) => (
              <div key={index} className='mb-6'>
                {getChildComponentCount(platform) !== 0 && (
                  <CollapsableComponent
                    header={platform}
                    cardNumber={3}
                    key={index}
                  >
                    {getChildComponent(platform)}
                  </CollapsableComponent>
                )}
              </div>
            ))}

            <DynamicIntegratedChannels />
          </>
        );
      case false:
        return (
          <EmptyPlatform
            handleOnclick={() =>
              navigate('/integrations/available-integrations')
            }
          />
        );
      default:
        return null;
    }
  };
  return <div className='h-auto px-8 py-4 bg-white'>{getMainElement()}</div>;
};

const mapState = (state: any) => ({
  selectedProject: state.dashboard.selectedProject,
  availablePlatformsList: state.integration.availablePlatformsList,
  integratedNlpList: state.integration.integratedNlpList,
  integratedChannelList: state.integration.integratedChannelList,
  integratedOthersList: state.integration.integratedOthersList,
  integratedEcommerceList: state.integration.integratedEcommerceList,
  ecommerceChannelPreferencesList:
    state.integration.ecommerceChannelPreferencesList,
});

const mapDispatch = (dispatch: any) => ({
  getIntegratedNlpList: (teamId: number) =>
    dispatch.integration.getIntegratedNlpList(teamId),
  getIntegratedChannelList: (teamId: number) =>
    dispatch.integration.getIntegratedChannelList(teamId),
  getIntegratedOthersList: (teamId: number) =>
    dispatch.integration.getIntegratedOthersList(teamId),
  updateStateData: (key: string, value: any) =>
    dispatch.integration.updateStateData({ key, value }),
  clearState: () => dispatch.integration.clearState(),
  fetchMarketPlaceData: (teamId: number) =>
    dispatch.integration.fetchMarketPlaceData(teamId),
  fetchMultiEcommerceData: (teamId: number) =>
    dispatch.integration.fetchMultiEcommerceData(teamId),
  fetchEcommerceChannelPreferences: (teamId: number, alice_store_id: string) =>
    dispatch.integration.fetchEcommerceChannelPreferences(teamId),
  connectEcommercePlatform: (payload: any) =>
    dispatch.integration.connectEcommercePlatform(payload),
  disconnectEcommercePlatform: (payload: any) =>
    dispatch.integration.disconnectEcommercePlatform(payload),
});

const IntegratedContainer = connect(mapState, mapDispatch)(Integrated);

export default IntegratedContainer;
