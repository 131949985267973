import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'library/helper/utils/class-names';
import { ScheduleTimeProps } from '../interface';

interface Props {
  type: string;
  data: { name: string; value: string; day: number }[];
  time: { hour: number[]; minute: number[]; others: string[] };
  selectedMonth: string;
  selectedValue: string | number | null;
  setScheduleTime: (payload: ScheduleTimeProps) => void;
  scheduleTime: ScheduleTimeProps;
}

const ScheduleDropdown: React.FC<Props> = ({
  data,
  type,
  time,
  selectedMonth,
  selectedValue,
  setScheduleTime,
  scheduleTime,
}) => {
  const date = new Date();
  let year = date.getFullYear();

  const daysOfMonth = () => {
    if (scheduleTime?.month) {
      return data.filter((item) => item.value === scheduleTime?.month)[0]?.day;
    } else {
      return data[0]?.day;
    }
  };

  const handleChange = (
    type: string | number,
    value: string | number | null
  ) => {
    switch (type) {
      case 'date':
        //@ts-ignore
        return setScheduleTime({ ...scheduleTime, date: value });
      case 'month':
        return setScheduleTime({ ...scheduleTime, month: value });
      case 'year':
        return setScheduleTime({ ...scheduleTime, year: value });
      case 'hour':
        return setScheduleTime({ ...scheduleTime, hour: value });
      case 'minute':
        return setScheduleTime({ ...scheduleTime, minute: value });
      case 'others':
        return setScheduleTime({ ...scheduleTime, others: value });
      default:
        return null;
    }
  };

  return (
    <Listbox
      value={selectedValue}
      onChange={(value) => handleChange(type, value)}
    >
      {({ open }) => (
        <>
          <div className='relative mt-1'>
            <Listbox.Button
              className='relative w-full cursor-default rounded-md text-xs
            border border-gray-300 bg-white py-2 pl-2 pr-8 text-left shadow-sm
            focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500'
            >
              <span className='block truncate w-[55px] uppercase text-gray-900 text-sm leading-5'>
                {type === 'minute' || type === 'hour' ? (
                  <>
                    {
                      // @ts-ignore
                      selectedValue < 10 ? '0' + selectedValue : selectedValue
                    }
                  </>
                ) : data.findIndex((item) => item.value === selectedValue) !==
                  -1 ? (
                  data.length > 0 &&
                  data[data.findIndex((item) => item.value === selectedValue)]
                    .name
                ) : (
                  selectedValue
                )}
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronDownIcon
                  className='h-4 w-4 text-gray-500'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {type === 'date' &&
                  [...Array(daysOfMonth())].map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={index + 1}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {index + 1}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                {type === 'month' &&
                  data.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9 uppercase'
                          )
                        }
                        value={item.value}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate uppercase'
                              )}
                            >
                              {item.name}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                {type === 'year' &&
                  [...Array(10)].map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={year + index}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {year + index}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                {type === 'hour' &&
                  time.hour.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item < 10 ? '0' + item : item}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                {type === 'minute' &&
                  time.minute.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item < 10 ? '0' + item : item}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
                {type === 'others' &&
                  time.others.map((item, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-green-500'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {item}
                            </span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-green-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ScheduleDropdown;
