import React from 'react';

interface Prop {
  top?: string;
  middle?: string;
  bottom?: string;
  text?: string;
  width?: number;
  height?: number;
}

const compName: React.FC<Prop> = ({
  top = '#FFD82F',
  middle = '#04B35F',
  bottom = '#01906D',
  text = '#1F2937',
  width = 373,
  height = 96,
}) => {
  return (
    <svg viewBox='0 0 373.1 96' width={width} height={height}>
      <g>
        <g>
          <path
            fill={bottom}
            d='M48.2,96.1c13.9-12.7,14.8-34.2,2.1-48S16.1,33.3,2.2,46c-0.7,0.7-1.4,1.3-2,2L48.2,96.1z'
          />
          <path
            fill={top}
            d='M0.2,48c13.3,13.2,34.7,13.2,48,0c3.5-3.5,23-25.1,0-48.1L0.2,48z'
          />
          <path
            fill={middle}
            d='M48.2,48l1-1C36.6,34.4,15.3,34.6,1.9,46.4L0.2,48l1.6,1.5C15.3,61.3,35.6,60.6,48.2,48z'
          />
          <path
            fill={bottom}
            d='M47.4,17.7c2.5,0,4.6-2,4.6-4.6s-2-4.6-4.6-4.6c-2.5,0-4.6,2-4.6,4.6l0,0C42.8,15.6,44.8,17.7,47.4,17.7    L47.4,17.7z'
          />
        </g>
        <g>
          <path
            fill={text}
            d='M81,27.2h11.4l0.1,7.5c2.3-5.2,6.9-8.2,12.6-8.2c6.4,0,11.1,3.1,13.2,8.4c2.7-5.3,7.5-8.4,13.6-8.4    c9.1,0,14.4,6.3,14.4,15.9v30.8h-11.8v-28c0-5.2-2.4-8.3-6.9-8.3c-5.2,0-8,3.2-8,10v26.3h-11.8v-28c0-5.2-2.4-8.3-6.9-8.3    c-5.2,0-8,3.2-8,10v26.3H81V27.2z'
          />
          <path
            fill={text}
            d='M163.8,27.2L174.2,59l9.9-31.8h12.2l-15.9,45.3c-3.6,10.1-8.3,16-18.5,16c-3,0-6.3-0.8-7.9-2.2v-9.7    c1.7,0.9,4.2,1.4,6,1.4c4.6,0,6.9-1.9,7.7-5.2l-16.8-45.6H163.8z'
          />
          <path
            fill={text}
            d='M235.2,73.1h-11.1l-0.1-7.7c-2.1,5.3-6.1,8.4-12.2,8.4c-9.1,0-14.7-6.3-14.7-13.7c0-12.6,11.2-14.3,20.4-15.1    l6-0.5v-1c0-4.7-4.5-7.1-10.9-7.1c-4.8,0-9.5,1.3-14,3.7l2.8-10.8c4.5-2.3,9.3-3,13.8-3c10.7,0,19.8,5,19.8,16.9V73.1z     M215.8,64.5c4.2,0,7.9-3.5,7.9-9.1v-3.2l-6.5,0.6c-5.5,0.5-8.1,2.2-8.1,5.9C209,62,211.1,64.5,215.8,64.5z'
          />
          <path fill={text} d='M244.5,7.5h11.8v65.6h-11.8V7.5z' />
          <path
            fill={text}
            d='M266.2,7.5h12v12.3h-12V7.5z M266.3,27.2h11.8v46h-11.8V27.2z'
          />
          <path
            fill={text}
            d='M311.7,36.8c-6.6,0-14.3,3.4-14.3,12.8c0,10.1,7.7,14.4,16,14.4c3.4,0,7.3-1.1,10-2.8v10.4    c-2.1,1.3-6.6,2.7-11.7,2.7c-11.2,0-26.2-6.1-26.2-24c0-18.9,16-24,26.4-24c5.7,0,9.5,1.3,12,3l-2.7,10.1    C318.6,37.9,315.3,36.8,311.7,36.8z'
          />
          <path
            fill={text}
            d='M354.1,74.3c-18.1,0-27.2-10.2-27.2-24.3c0-12.9,8.4-24,23.3-24c13,0,20.6,10.6,20.6,22.9    c0,1.6-0.1,3.9-0.4,5.4h-31.3c1.5,6.5,6.7,10.1,16.2,10.1c5.1,0,10.3-1.6,13.3-3.7v10.3C365.4,73.1,359,74.3,354.1,74.3z     M349.8,35.8c-5.9,0-9.8,3.9-10.8,10.2h20.3C358.6,39.9,355.1,35.8,349.8,35.8z'
          />
        </g>
      </g>
    </svg>
  );
};

export default compName;
