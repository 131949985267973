import React from 'react';
import Modal from './Modal';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { billingInfoType } from '../interface';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface updateBillingProps {
  setBillingUpdateModal: (value: boolean) => void;
  billingInformationUpdate: (payload: billingInfoType) => {
    success: boolean;
    error: string;
    data: any;
  };
  billingInfo: billingInfoType;
}

const UpdateBillingInfo: React.FC<updateBillingProps> = ({
  setBillingUpdateModal,
  billingInformationUpdate,
  billingInfo,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [billingInfos, setBillingInfo] = React.useState({
    full_name: billingInfo ? billingInfo.full_name : '',
    email: billingInfo ? billingInfo.email : '',
    billing_address: billingInfo ? billingInfo.billing_address : '',
  });

  const handleBillingInfo = ({ target }: any) => {
    setBillingInfo({
      ...billingInfos,
      [target.name]: target.value,
    });
  };

  const submitBillingInfo = async (e: any) => {
    e.preventDefault();
    const res = await billingInformationUpdate(billingInfos);
    if (res.success) {
      setStep(2);
    } else {
      toaster.danger(
        t('Something went wrong during updating billing information!'),
        {
          duration: 3,
        }
      );
    }
  };

  const getStateComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className='flex flex-col justify-center w-full align-center mb-3'>
              <p className='text-lg text-gray-900 font-bold'>
                {t('Update Billing Information')}
              </p>
              <XMarkIcon
                onClick={() => setBillingUpdateModal(false)}
                className='absolute right-[20px] w-5 h-5 top-[20px] text-gray-500 cursor-pointer'
              />
            </div>
            <form onSubmit={(e) => submitBillingInfo(e)}>
              <p className='text-base text-gray-400 mb-1 rtl:text-right'>
                {t('Billing information')}
              </p>
              <div className='mb-1'>
                <input
                  value={billingInfos.full_name}
                  onChange={(e) => handleBillingInfo(e)}
                  className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                  type='text'
                  placeholder={t(billingInfos.full_name || 'Name')}
                  name='full_name'
                />
              </div>
              <div className='mb-1'>
                <input
                  value={billingInfos.email}
                  onChange={(e) => handleBillingInfo(e)}
                  className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                  type='email'
                  placeholder={billingInfos.email || 'john@gmail.com'}
                  name='email'
                />
              </div>
              <div className='mb-5'>
                <input
                  value={billingInfos.billing_address}
                  onChange={(e) => handleBillingInfo(e)}
                  className='border border-gray-300 rounded w-full py-1.5 px-3 text-gray-500'
                  type='text'
                  placeholder={t(billingInfos.billing_address) || t('Address')}
                  name='billing_address'
                />
              </div>
              <button
                type='submit'
                value='Submit'
                className='bg-green-500 w-full p-2 rounded-md text-white mb-3'
              >
                {t('Update Changes')}
              </button>
            </form>
          </>
        );
      case 2:
        return (
          <>
            <div className='flex flex-col justify-center items-center'>
              <CheckCircleIcon className='h-8 w-8 text-green-500 bg-green-100 rounded-[50%] p-1 mb-3 p-1' />
              <p className='text-gray-900 gont-semibold text-lg'>
                Billing Information Updated
              </p>
              <p className='text-sm text-gray-400 mb-3 text-center'>
                You have successfully updated your billing information. Please
                contact support for further query
              </p>
              <a href='/settings/billing' className='w-full text-center'>
                {' '}
                <button
                  onClick={() => setBillingUpdateModal(false)}
                  type='reset'
                  className='p-2 border border-gray-300 rounded-md w-2/3 mb-3 text-white bg-green-500'
                >
                  {t('Continue')}
                </button>
              </a>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <Modal>
      <div className='p-5'>{getStateComponent()}</div>
    </Modal>
  );
};
const mapState = (state: any) => ({
  billingInfo: state.newBilling.billingInfo,
});
const mapDispatch = (dispatch: any) => ({
  billingInformationUpdate: (payload: billingInfoType) =>
    dispatch.newBilling.billingInformationUpdate(payload),
});

export default connect(mapState, mapDispatch)(UpdateBillingInfo);
