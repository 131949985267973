import { useSelector, useDispatch } from 'react-redux';

const useCommentEdit = () => {
  const dispatch = useDispatch();
  const inboxData = useSelector((state: any) => state.inbox);

  // Function to set comment edit mode
  const setCommentEditMode = (commentId: string, comment: string) => {
    // Update commentEdit and chatboxActiveNav in Redux store
    dispatch.inbox.updateSateData({
      key: 'commentEdit',
      value: {
        isEditing: true,
        conversationId: commentId,
        commentText: comment,
      },
    });

    dispatch.inbox.updateSateData({
      key: 'chatboxActiveNav',
      value: 'edit-comment',
    });
  };

  // Function to cancel comment edit mode
  const cancelCommentEditMode = () => {
    // Update commentEdit and chatboxActiveNav in Redux store
    dispatch.inbox.updateSateData({
      key: 'commentEdit',
      value: {
        isEditing: false,
        conversationId: '',
        commentText: '',
      },
    });

    dispatch.inbox.updateSateData({
      key: 'chatboxActiveNav',
      value: 'Comment',
    });
  };

  // Function to get the editable comment text
  const getEditableComment = () => {
    if (
      inboxData?.commentEdit?.isEditing &&
      !!inboxData?.commentEdit?.conversationId &&
      !!inboxData?.commentEdit?.commentText
    ) {
      return inboxData?.commentEdit?.commentText;
    } else return null;
  };

  // Function to update the comment
  const updateComment = async (text: string) => {
    //comment update logic here
    let payload = {
      text: text,
      action: 'edit',
      comment_id: inboxData?.commentEdit?.conversationId,
    };
    await dispatch.inbox.updateFeedCommentStatus(payload);
    cancelCommentEditMode();
  };

  return {
    commentEditData: inboxData?.commentEdit,
    cancelCommentEditMode,
    setCommentEditMode,
    getEditableComment,
    updateComment,
  };
};

export default useCommentEdit;
