import {
  cloneDeep,
  useSelector,
  useDispatch,
  UtilityContents,
  UtilityFunctions,
} from '../../../export';

import { INode, NodeType, NodeTypeAlias } from '../../../interface';

// type primitiveDataType = null | string | number | boolean;

const useReactFlowGraphServices = () => {
  const dispatch = useDispatch();
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );

  const {
    lastNodeId,
    selectedTrigger,
    automationEdges,
    selectedAction,
    automationNodes,
    selectedNodeId,
    selectedSourceHandle,
    selectedSaveActionsData,
    selectedSaveConditionList,
    selectedAutomationNodeEditableId,
  } = workflowAutomationData;

  const getXYPositionForNewNode = (
    parentNodePosition: any,
    selectedSourceHandle: null | string
  ) => {
    let newXPosition;
    let newYPosition = parentNodePosition[0]?.position.y + 50;

    if (selectedSourceHandle === null) {
      newXPosition = parentNodePosition[0]?.position.x - 50;
    } else if (selectedSourceHandle.split('_')[0] === 'success') {
      newXPosition = parentNodePosition[0]?.position.x - 180;
    } else {
      newXPosition = parentNodePosition[0]?.position.x + 180;
    }
    return { newXPosition, newYPosition };
  };
  // addEdge(), handleOnEdgeChanges(), handleOnEdgeDelete() functions is used to create, delete, update edges between nodes;
  const addEdge = (sourceId: string, targetId: string) => {
    let localAutomationEdges = cloneDeep(automationEdges);

    // Create a new edge connecting the parent node to the new node.
    let edgeProperty = {
      target: targetId,
      source: sourceId,
      type: 'custom-edge',
      style: UtilityContents.EdgeStyles,
      sourceHandle: selectedSourceHandle,
      targetHandle: `targetHandle_${targetId}`,
      markerEnd: UtilityContents.EdgeMarkendProperty,
      id: `reactflow__edge-${sourceId + selectedSourceHandle}-${
        targetId + `targetHandle_${targetId}`
      }`,
      action: selectedSourceHandle === null ? 'straight' : selectedAction,
    };

    dispatch.workflowAutomation.updateAutomationEdge([
      ...localAutomationEdges,
      edgeProperty,
    ]);
  };

  const handleOnEdgeChanges = (updatedEdges: any) => {
    dispatch.workflowAutomation.updateAutomationEdge(updatedEdges);
  };

  const handleOnEdgeDelete = (deletedEdge: any) => {
    // dispatch.workflowAutomation.updateAutomationEdge(updatedEdges);
  };

  // addTriggerNode(), addConditionNode(), addActionNode(), handleReactflowNodes() adds new node to the graph.
  const addTriggerNode = () => {
    let triggerNode: INode = {
      id: 'node-0',
      deletable: false,
      type: NodeType.TRIGGER,
      node_type: NodeTypeAlias.TRIGGER,
      position: { x: 0, y: 0 },
      data: selectedTrigger,
    };

    let initialActionConditionButton = {
      id: 'button',
      type: 'condition_action-button',
      position: {
        x: 50,
        y: 150,
      },
      data: { parentNodeId: 'node-0' }, // data definition have to add here according to BE data schema
    };
    // Dispatch actions to update the automation nodes and edges with the new data.
    dispatch.workflowAutomation.updateAutomationNode([
      triggerNode,
      initialActionConditionButton,
    ]);
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'lastNodeId',
      value: 'node-0',
    });
  };

  /**
   * This function is responsible for adding a new conddition node to an automation graph.
   */
  const addConditionNode = (localAutomationNodes: any[]) => {
    // Clone the existing automation nodes to avoid modifying the original data.

    // Find the position of the parent node based on the selectedNodeId.
    let parentNodePosition = automationNodes.filter(
      (nodes: any) => nodes.id === 'button'
    );

    let localLastNodeId =
      automationNodes[automationNodes.length - 2].id || lastNodeId;

    let { newXPosition, newYPosition } = getXYPositionForNewNode(
      parentNodePosition,
      selectedSourceHandle
    );

    localAutomationNodes = localAutomationNodes.filter(
      (nodes: INode) =>
        !['condition_action-button', 'trigger-button'].includes(nodes?.type)
    );

    if (selectedAutomationNodeEditableId === null) {
      // Generate unique IDs for the new node and edge.
      let newNodeId = UtilityFunctions.getNewNodeId(
        localLastNodeId || selectedNodeId
      );

      // Create a new node with random coordinates and a label.
      let dummyNodes: INode = {
        id: newNodeId,
        type: NodeType.CONDITION,
        node_type: NodeTypeAlias.CONDITION,
        position: {
          x: newXPosition,
          y: newYPosition,
        },
        data: selectedSaveConditionList,
        // data: UtilityContents.dummyConditionNodeData, // data definition have to add here according to BE data schema
      };

      // Dispatch actions to update the automation nodes and edges with the new data.
      dispatch.workflowAutomation.updateAutomationNode([
        ...localAutomationNodes,
        dummyNodes,
      ]);
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'lastNodeId',
        value: newNodeId,
      });

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSourceHandle',
        value: null,
      });

      addEdge(selectedNodeId, newNodeId);
    } else {
      let tempLocalAutomationNodes: any[] = [];
      localAutomationNodes.forEach((workflowNode: any) => {
        if (workflowNode?.id === selectedAutomationNodeEditableId) {
          tempLocalAutomationNodes.push({
            ...workflowNode,
            data: selectedSaveConditionList,
          });
        } else {
          tempLocalAutomationNodes.push(workflowNode);
        }
      });
      // Dispatch actions to update the automation nodes and edges with the new data.
      dispatch.workflowAutomation.updateAutomationNode(
        tempLocalAutomationNodes
      );
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedAutomationNodeEditableId',
        value: null,
      });
    }
  };

  const addActionNode = (localAutomationNodes: any[]) => {
    // Clone the existing automation nodes to avoid modifying the original data.
    // Find the position of the parent node based on the condition_action.
    let parentNodePosition = automationNodes.filter(
      (nodes: INode) => nodes.id === 'button'
    );

    let localLastNodeId =
      automationNodes[automationNodes.length - 2].id || lastNodeId;

    localAutomationNodes = localAutomationNodes.filter(
      (nodes: INode) =>
        !['condition_action-button', 'trigger-button'].includes(nodes?.type)
    );

    if (selectedAutomationNodeEditableId === null) {
      // Generate IDs for the new node
      let newNodeId = UtilityFunctions.getNewNodeId(
        localLastNodeId || selectedNodeId
      );

      let { newXPosition, newYPosition } = getXYPositionForNewNode(
        parentNodePosition,
        selectedSourceHandle
      );

      // Create a new node with random coordinates and a label.
      let dummyNodes: INode = {
        id: newNodeId,
        type: NodeType.ACTION,
        node_type: NodeTypeAlias.ACTION,
        position: {
          x: newXPosition,
          y: newYPosition,
        },
        data: selectedSaveActionsData, // data definition have to add here according to BE data schema
      };

      // Dispatch actions to update the automation nodes and edges with the new data.
      dispatch.workflowAutomation.updateAutomationNode([
        ...localAutomationNodes,
        dummyNodes,
      ]);

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'lastNodeId',
        value: newNodeId,
      });

      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedSourceHandle',
        value: null,
      });

      // Dispatch actions to update the automation nodes and edges with the new data.
      dispatch.workflowAutomation.updateAutomationNode([
        ...localAutomationNodes,
        dummyNodes,
      ]);
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'lastNodeId',
        value: newNodeId,
      });

      addEdge(selectedNodeId, newNodeId);
    } else {
      let tempLocalAutomationNodes: any[] = [];
      localAutomationNodes.forEach((workflowNode: any) => {
        if (workflowNode?.id === selectedAutomationNodeEditableId) {
          tempLocalAutomationNodes.push({
            ...workflowNode,
            data: selectedSaveActionsData,
          });
        } else {
          tempLocalAutomationNodes.push(workflowNode);
        }
      });
      // Dispatch actions to update the automation nodes and edges with the new data.
      dispatch.workflowAutomation.updateAutomationNode(
        tempLocalAutomationNodes
      );
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedAutomationNodeEditableId',
        value: null,
      });
    }
  };

  const handleReactflowNodes = (updatedNodes: any) => {
    dispatch.workflowAutomation.updateAutomationNode(updatedNodes);
  };

  /**
   * This function is responsible for adding a new node to an automation graph.
   */
  const addConditionOrActionButton = (
    parentNodeId: string,
    localAutomationNodes: any[],
    sourcehandleId?: string
  ) => {
    // Find the position of the parent node based on the selectedNodeId.
    let parentNodePosition = automationNodes.filter(
      (nodes: any) => nodes.id === parentNodeId
    );

    let xPosition = parentNodePosition[0]?.position.x + 50;

    if (!!sourcehandleId && sourcehandleId.startsWith('success')) {
      xPosition = parentNodePosition[0]?.position.x - 75;
    } else if (!!sourcehandleId && sourcehandleId.startsWith('fail')) {
      xPosition = parentNodePosition[0]?.position.x + 100;
    }

    // Create a new node with random coordinates and a label.
    let dummyNodes = {
      id: `button`,
      type: 'condition_action-button',
      position: {
        x: xPosition,
        y:
          parentNodePosition[0]?.position.y +
          (50 + parentNodePosition[0]?.height),
      },
      data: { parentNodeId: parentNodeId, sourceHandleId: sourcehandleId }, // data definition have to add here according to BE data schema
    };

    const indexOfButton = localAutomationNodes.findIndex(
      (obj) => obj.id === 'button'
    );

    if (indexOfButton !== -1) {
      localAutomationNodes[indexOfButton] = dummyNodes;
    } else {
      localAutomationNodes = [...localAutomationNodes, dummyNodes];
    }
    // Dispatch actions to update the automation nodes and edges with the new data.
    dispatch.workflowAutomation.updateAutomationNode([...localAutomationNodes]);

    // dispatch.workflowAutomation.updatePrimitiveStateData({
    //   key: 'lastNodeId',
    //   value: newNodeId,
    // });
  };

  //Node action functions
  // const handleNodeDelete = (nodeId: string) => {};

  // Function to determine if condition and action buttons should be rendered based on the parent node ID.
  const shouldRenderConditionAndActionButton = (
    parentNodeId: string,
    localAutomationNodes: any
  ) => {
    // Filter nodes based on the provided parent node ID.
    localAutomationNodes = localAutomationNodes.filter(
      (nodes: INode) => parentNodeId === nodes?.data?.parentNodeId
    );

    // If no nodes with the given parent ID exist, return true (to render condition and action buttons).
    // Otherwise, return false (to hide condition and action buttons).
    let shouldRender = localAutomationNodes.length === 0;
    return shouldRender;
  };

  //Remove previous conditions data
  const clearPreviousConditonSaveData = () => {
    dispatch.workflowAutomation.clearConditionData([]);
  };

  //Remove previous conditions data
  const clearPreviousActionsSaveData = () => {
    dispatch.workflowAutomation.clearActionData([]);
    dispatch.workflowAutomation.updateSelectedChannel(null);
  };

  // getSourceAndTargetHandles returns all the source,target handle ID list
  // const getSourceAndTargetHandles = (edges: IEdge[]): string[] => {
  //   const handles = automationEdges
  //     .map(({ sourceHandle, targetHandle } string) => [
  //       sourceHandle,
  //       targetHandle,
  //     ])
  //     .flat()
  //     .filter((handle: string): handle is string => handle !== null);

  //   return Array.from(new Set(handles));
  // };

  const updateSideSheetCloseAlertModal = (value: boolean) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'isOpenSideSheetCloseAlertModal',
      value: value,
    });
  };

  return {
    addEdge,
    addActionNode,
    addTriggerNode,
    addConditionNode,
    handleOnEdgeDelete,
    handleOnEdgeChanges,
    handleReactflowNodes,
    addConditionOrActionButton,
    clearPreviousActionsSaveData,
    clearPreviousConditonSaveData,
    updateSideSheetCloseAlertModal,
    shouldRenderConditionAndActionButton,
    ...workflowAutomationData,
  };
};

export default useReactFlowGraphServices;
