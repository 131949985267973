export interface ISLACreationRequestBody {
  name: string;
  description: string;
  platforms: Array<string | number>;
  policies: IPolicy[];
}

export interface ISLAData {
  id: number;
  project: IProject;
  name: string;
  description: string;
  is_active: boolean;
  platforms: Array<string | number>;
  created_by: ICreatedBy;
  policies: IPolicy[];
  reminders: IReminder[];
  escalations: IEscalation[];
}

interface IProject {
  id: number;
  name: string;
}

export interface IPlatform {
  id: number;
  title: string;
  platform_type: string;
}

interface ICreatedBy {
  id: number;
  email: string;
  avatar: string;
  full_name: string;
}

export enum Priority {
  Low = 0,
  Medium = 1,
  High = 2,
  Urgent = 3,
}
export interface IPolicy {
  priority: Priority;
  first_response_time: number;
  resolution_time: number;
  should_consider_business_hour: boolean;
}

export interface IReminder {
  priority: number[];
  target: 'first_response_time' | 'resolution_time' | '';
  time: number; // Time in minutes
  persons: Array<string | number>; // IDs of the persons involved
}

export interface IEscalation {
  priority: number[];
  target: 'first_response_time' | 'resolution_time' | '';
  time: number; // Time in minutes
  persons: Array<string | number>; // IDs of the persons involved
}
