import { InformationCircleIcon } from '@heroicons/react/24/solid';
import useGoogleAuthentication from 'library/helper/hooks/useGoogleAuthentication';
import NewAlertModal from 'library/modal/NewAlertModal';
import { channelListProps } from 'pages/integration/interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface Props {
  shouldOpen: boolean;
  setShouldOpen: (state: boolean) => void;
  channelData: channelListProps;
}
const GmailChannelReconnect: React.FC<Props> = ({
  shouldOpen,
  setShouldOpen,
  channelData,
}) => {
  const dispatch = useDispatch();
  const projectId = useSelector((st) => st?.dashboard?.selectedProject?.id);
  const [authCode, setAuthCode] = useState('');

  const handleReconnectGmailChannel = async () => {
    let requestData = {
      payloadData: { auth_code: authCode },
      alertMessage: `Connect ${channelData.name} has been successfully reconnected!`,
      teamId: projectId,
      channelId: channelData.id,
    };
    await dispatch.integration.reconnectChannel(requestData);
  };
  const googleAuthHandler = useGoogleAuthentication((code: string) => {
    setAuthCode(code);
    setShouldOpen(false);
  });
  const handleModalConfirm = () => {
    googleAuthHandler();
  };
  const rederInformationCardView = () => {
    return (
      <div className='flex justify-center px-4 py-4 mt-4 text-sm text-blue-800 border rounded-md bg-blue-50 border-grey-200'>
        <div className='justify-center'>
          <InformationCircleIcon
            className='w-5 h-5 text-blue-400'
            aria-hidden='true'
          />
        </div>
        <p className='ml-2 text-justify'>
          MyAlice's use and transfer of information received from Google APIs to
          any other app will adhere to{' '}
          <a
            href={`https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes`}
            target='_blank'
            className='text-blue-500 hover:text-blue-900'
            rel='noreferrer'
          >
            <b>Google API Services User Data Policy</b>
          </a>
          , including the Limited Use requirements.
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (authCode !== '') {
      handleReconnectGmailChannel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);
  return (
    <NewAlertModal
      isShown={shouldOpen}
      onClose={() => setShouldOpen(false)}
      confirmButtonTitle={'Reconnect'}
      onConfirm={handleModalConfirm}
      onCancelComplete={() => setShouldOpen(false)}
      title={''}
      shouldRenderDescriptionAsNode={true}
      description={rederInformationCardView()}
      intent='primary'
    />
  );
};
export default GmailChannelReconnect;
