import React from 'react';

interface Props {
  iconOnly?: boolean;
  text?: string;
  icon?: string;
  onClick: () => void;
}

const Button: React.FC<Props> = ({
  iconOnly = false,
  icon,
  text = '',
  onClick,
}) => {
  return (
    <>
      {iconOnly ? (
        <div onClick={onClick} className='cursor-pointer'>
          <img src={icon} alt='right-collapse' className='w-[35px] h-[35px]' />
        </div>
      ) : (
        <div onClick={onClick}>
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

export default Button;
