import useTranslation from 'components/customHooks/useTranslation';
import {
  Button,
  Icon,
  Spinner,
  Switch,
  TextInput,
  Textarea,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import ImageUploadField from '../../../../components/utilityComponent/ImageUploadField';
import { handleImageUpload } from '../../../../utilities/utils';
import ColorPicker from '../../../components/elements/ColorPicker';
import TextCharInfo from '../../../components/elements/TextCharInfo';

const RequiredStar = () => <span style={{ color: 'red' }}>*</span>;
const DataLabMaxLength = 150;
const DataLabForm = ({
  title,
  handleTitle,
  description,
  handleDescription,
  logo,
  handleLogo,
  type,
  handleType,
  fontColor,
  handleFontColor,
  buttonColor,
  handleButtonColor,
  buttonText,
  handleButtonText,
  buttonLoading,
  handleCreate,
  isEditing,
  webHookUrl,
  handleWebHookUrl,
}) => {
  const { t } = useTranslation();
  return (
    <div className='data-lab__form-container'>
      <div className='data-lab__form-container-item'>
        <label>
          {t('Title')}
          <RequiredStar />
        </label>
        <TextInput
          placeholder={t('Lab Title')}
          value={title}
          onChange={(e) => handleTitle(e.target.value)}
        />
      </div>
      <div className='data-lab__form-container-item'>
        <label>{t('Description (Optional)')}</label>
        <Textarea
          value={description}
          placeholder={t('Provide A valid Description')}
          onChange={(e) => {
            if (e.target.value.length <= DataLabMaxLength) {
              handleDescription(e.target.value);
            }
          }}
        />
        <TextCharInfo data={description} count={DataLabMaxLength} />
      </div>
      <div className='data-lab__form-container-item'>
        <label>{t('Image (Optional)')}</label>
        <ImageUploadField
          image={logo}
          handleDrop={(image) => handleImageUpload(image, handleLogo)}
          handleClear={() => handleLogo('')}
        />
      </div>
      <div className='flex-wrapper-3'>
        <div className='data-lab__form-container-item'>
          <label>{t('Form Type?')}</label>
          <Switch
            marginTop={10}
            marginBottom={10}
            height={24}
            checked={type}
            onChange={(e) => {
              handleType(e.target.checked);
              handleButtonColor('#007b65');
              handleFontColor('#eeeeee');
            }}
          />
        </div>
        {type && (
          <div className='data-lab__form-container-item'>
            <label>{t('Button Text Color?')}</label>
            <ColorPicker
              color={fontColor}
              handleChange={(color) => handleFontColor(color.hex)}
            />
          </div>
        )}
        {type && (
          <div className='data-lab__form-container-item'>
            <label>{t('Button Background Color?')}</label>
            <ColorPicker
              color={buttonColor}
              handleChange={(color) => handleButtonColor(color.hex)}
            />
          </div>
        )}
      </div>
      {type && (
        <div className='data-lab__form-container-item'>
          <label>
            {t('Enter Button Text')}
            <RequiredStar />
          </label>
          <TextInput
            placeholder={t('Button Text')}
            value={buttonText}
            onChange={(e) => handleButtonText(e.target.value)}
          />
        </div>
      )}
      <Button
        onClick={() => {
          handleCreate();
        }}
        disabled={buttonLoading}
        className='button-alice__gradient data-lab__custom-next-button'
        height={46}
      >
        {buttonLoading ? (
          <span className='button-loader'>
            <Spinner size={24} />
          </span>
        ) : (
          <span>
            {isEditing ? t('Update') : t('Create')} {t('Data Lab')}
            <Icon
              icon={'circle-arrow-right'}
              color='disabled'
              marginLeft={16}
            />
          </span>
        )}
      </Button>
    </div>
  );
};

DataLabForm.propTypes = {
  title: PropTypes.string,
  handleTitle: PropTypes.func,
  description: PropTypes.string,
  handleDescription: PropTypes.func,
  logo: PropTypes.string,
  handleLogo: PropTypes.func,
  type: PropTypes.bool,
  handleType: PropTypes.func,
  fontColor: PropTypes.string,
  handleFontColor: PropTypes.func,
  buttonColor: PropTypes.string,
  handleButtonColor: PropTypes.func,
  buttonText: PropTypes.string,
  handleButtonText: PropTypes.func,
  buttonLoading: PropTypes.bool,
  handleCreate: PropTypes.func,
  isEditing: PropTypes.bool,
  webHookUrl: PropTypes.string,
  handleWebHookUrl: PropTypes.func,
};
export default DataLabForm;
