import React from 'react';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { MappedColumnData } from '../interface';
import useTranslation from 'components/customHooks/useTranslation';

const unselectAttribute = [{ name: 'Not Mapped', value: 'not_mapped' }];

interface Props {
  setMappedData: (payload: MappedColumnData[]) => void;
  value: string | null;
  mappedData: MappedColumnData[] | null;
  columnName: string;
  id: number;
  attributes: {
    essential: { name: string; value: string }[];
    others: { name: string; value: string }[];
  } | null;
}

const ImportDropDown: React.FC<Props> = ({
  setMappedData,
  value,
  mappedData,
  columnName,
  id,
  attributes,
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = React.useState('');

  const dropdownPosition = (id: string) => {
    let button = document.getElementById(id);
    let modalElement = document.getElementById('broadcast-creation-modal');
    // @ts-ignore
    let modalToBodyDistance = modalElement.getBoundingClientRect();
    // @ts-ignore
    let bodyToButtonDistance = button.getBoundingClientRect();
    let postionvalue =
      bodyToButtonDistance.bottom - modalToBodyDistance.top + 4 + 'px';
    setPosition(postionvalue);
  };

  const getMappedAttributeName = (mappedAttribute: string) => {
    let valueName =
      attributes?.essential?.find(
        (item) => item.value !== 'not_mapped' && item.value === mappedAttribute
      ) &&
      attributes?.essential?.find(
        (item) => item.value === mappedAttribute && item.value !== 'not_mapped'
      )?.name;

    if (!valueName) {
      valueName =
        attributes?.others?.find(
          (item) =>
            item.value !== 'not_mapped' && item.value === mappedAttribute
        ) &&
        attributes?.others?.find(
          (item) =>
            item.value === mappedAttribute && item.value !== 'not_mapped'
        )?.name;
    }

    if (!!valueName) {
      return valueName;
    } else {
      return t('Not Mapped');
    }
  };

  const isValueMapped = (attribute: { name: string; value: string }) => {
    return !!mappedData?.find(
      (item) => item.mapped_attribute === attribute.value
    );
  };

  return (
    <div className='top-16 w-72'>
      <Listbox
        value={value}
        onChange={(value) => {
          let newRendervalue =
            mappedData &&
            mappedData.map((item) => {
              if (item.column_name === columnName) {
                return { ...item, mapped_attribute: value };
              }
              return item;
            });
          //@ts-ignore
          setMappedData([...newRendervalue]);
        }}
      >
        <div className='mt-1'>
          <Listbox.Button
            id={`${id}`}
            className='relative w-[200px] border border-gray-300 cursor-default rounded-lg
          bg-white py-1 ltr:pl-3 rtl:pr-3 ltr:pr-10 rtl:pl-10 ltr:text-left rtl:text-right focus:outline-none
          focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white
          focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
            onClick={(e: any) => {
              dropdownPosition(e.target.closest('button').id);
            }}
          >
            <span
              className={`block truncate font-normal ${
                value !== 'not_mapped' ? 'text-gray-900 ' : 'text-gray-500'
              }`}
            >
              {getMappedAttributeName(!!value ? value : 'not_mapped')}
            </span>
            <span className='pointer-events-none absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2'>
              <ChevronUpDownIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options
              style={{ top: `${position}` }}
              className={`absolute w-[200px]
              rounded-md py-1 text-base ring-1 ring-black ring-opacity-5
              focus:outline-none sm:text-sm bg-white z-10 shadow-lg h-[200px] overflow-auto`}
            >
              {unselectAttribute.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative select-none font-normal ltr:pl-2 rtl:pr-2 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900 '
                    }
                    `
                  }
                  value={person.value}
                  disabled={value === 'not_mapped'}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate text-sm leading-5 py-1 pl-4 pr-4 font-normal text-gray-900 ${
                          selected
                            ? 'cursor-not-allowed'
                            : 'hover:bg-green-500 hover:text-white'
                        }`}
                      >
                        {t(person.name)}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}

              <p className='px-4 py-1 border-b border-t border-gray-100 text-xs leading-5 uppercase text-gray-600 font-semibold'>
                {t('Essential')}
              </p>
              {attributes &&
                attributes?.essential.map((person, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `relative select-none py-1 pl-6 pr-4 font-normal text-gray-900 text-sm leading-5 ${
                        active ? '' : ''
                      } ${
                        isValueMapped(person)
                          ? 'cursor-not-allowed'
                          : 'hover:bg-green-500 hover:text-white '
                      }`
                    }
                    value={person.value}
                    disabled={isValueMapped(person) ? true : false}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate font-normal `}>
                          {person.name}
                        </span>
                        {isValueMapped(person) ? (
                          <span className='absolute inset-y-0 ltr:right-[5px] rtl:left-[5px] flex items-center ltr:pl-3 rtl:pr-3 text-amber-600'>
                            <CheckCircleIcon className='w-4 h-4 text-[#04A056]' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              <p className='px-4 py-1 border-t border-b border-gray-100 uppercase text-xs leading-5 text-gray-600 font-semibold'>
                {t('Others')}
              </p>
              <div>
                {attributes &&
                  attributes?.others.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative select-none py-1 pl-6 pr-4 text-sm leading-5 text-gray-900 ${
                          active ? '' : ''
                        }
                        ${
                          isValueMapped(person)
                            ? 'cursor-not-allowed'
                            : 'hover:bg-green-500 hover:text-white '
                        }`
                      }
                      value={person.value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate text-sm leading-5 font-normal ${
                              selected ? 'cursor-not-allowed' : ''
                            }`}
                          >
                            {person.name}
                          </span>
                          {isValueMapped(person) ? (
                            <span className='absolute inset-y-0 right-[5px] flex items-center pl-3 text-amber-600'>
                              <CheckCircleIcon className='w-4 h-4 text-[#04A056]' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ImportDropDown;
