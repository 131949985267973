interface Props {
  isValuesFilled: {
    titleFilled: boolean;
    dateFilled: boolean;
  };
  setDateFilledData: (payload: {
    titleFilled: boolean;
    dateFilled: boolean;
  }) => void;
  setShowHolidayCalender: (payload: boolean) => void;
  children: JSX.Element[] | JSX.Element;
}
const HolidayModal: React.FC<Props> = ({
  setShowHolidayCalender,
  setDateFilledData,
  children,
  isValuesFilled,
}) => {
  return (
    <div
      className='fixed z-20 inset-0 overflow-y-auto '
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 '>
        <div
          onClick={() => {
            setShowHolidayCalender(false);
            setDateFilledData({ ...isValuesFilled, dateFilled: true });
          }}
          className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
          aria-hidden='true'
        />
        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        ></span>
        <div
          className='relative inline-block align-bottom bg-white rounded-lg text-left
          shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[432px] sm:max-w-lg top-[-130px]'
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default HolidayModal;
