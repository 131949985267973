import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { PieChart, Pie, Tooltip, Cell, TooltipProps } from 'recharts';

interface Props {
  data: any;
  color: any;
}

const COLORS = ['#50AEF4', '#F43F5E', '#FBBF24', '#9CA3AF'];

const DataPieChart: React.FC<Props> = ({ data, color }) => {
  const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: '#fff',
            padding: '5px',
            border: '1px solid #ccc',
          }}
        >
          <p>{t(data.name)}</p>
          <p>
            {t(`Value`)}: {data.value.toString()}
          </p>
        </div>
      );
    }

    return null;
  };
  return (
    <PieChart width={280} height={280}>
      <Pie
        data={data}
        cx={140}
        cy={140}
        labelLine={false}
        innerRadius={60}
        outerRadius={120}
        fill={color}
        dataKey='value'
      >
        {!!data &&
          data.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
};

export default DataPieChart;
